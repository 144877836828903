import React from "react";
import masterCard from "assets/lookMyTicket/images/master.png";
import american from "assets/lookMyTicket/images/american.png";
//import paypal from "assets/lookMyTicket/images/paypal.png";

import upi from "assets/upi.png";
import visa from "assets/lookMyTicket/images/visa.png";
import rupay from "assets/lookMyTicket/images/rupay.png";
import pci from "assets/lookMyTicket/images/pci-logo.png";
//import IATA from "assets/img/Iata-logo.png";
//import TAAFI from "assets/lookMyTicket/images/tafi-logo-1.png";
//import IATA from "assets/lookMyTicket/images/iata-logo-1.png";
import IATA from "assets/lookMyTicket/images/home/iata.jpg";
import TAAFI from "assets/lookMyTicket/images/home/taafilogo.jpg";
import TAAI from "assets/lookMyTicket/images/home/taai.jpg";

import travlogy_logo from "assets/travlogy/images/travlogy_logo_white.png";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

const PageFooter = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    // const loadTawkChat = () => {
    //     window.Tawk_API.popup();
    // };

    // const loadTawkScript = () => {
    //     return new Promise((resolve) => {
    //         const script = document.createElement("script");
    //         script.async = true;
    //         script.src = 'https://embed.tawk.to/5808d25bc7829d0cd36a05d8/1f105kk9m';
    //         script.setAttribute('crossorigin', '*');
    //         const s0 = document.getElementsByTagName("script")[0];
    //         s0.parentNode.insertBefore(script, s0);
    //         script.onload = () => {
    //             resolve(true);
    //         };
    //         script.onerror = () => {
    //             resolve(false);
    //         };
    //     });
    // };

    // React.useEffect(() => {
    //     loadTawkScript().then(loaded => {
    //         if (!loaded) {
    //             return console.log("unable to communicate with Tawkto servers!");
    //         }
    //     });
    // }, []);

    return (
        <div className={classes.root}>
            <div className="footer__section">
                <div className="footer__left">
                    {footerLinks.map((value, index) => {
                        return (
                            <div className="ol-list" key={index}>
                                <div className="ol__header">{value.linkHeader}</div>
                                <ol>
                                    {value.links.map((link, index) => (
                                        <div key={index}>
                                            <li>
                                                <a onClick={() => history.push(link.url)} target="_blank">{link.title}</a>
                                            </li>
                                        </div>
                                    ))}
                                </ol>
                            </div>
                        );
                    })}
                    <div className="ol-list">
                        <div className="ol__header">Contact Info</div>
                        <ol>
                            <div>
                                <li>
                                    <a href={`mailto:${AppConstant.supportEmail}`}>{AppConstant.supportEmail}</a>
                                </li>

                                <li>
                                    <a href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContact}`}>+{AppConstant.countryDialCode} {AppConstant.supportContactView}</a>
                                </li>
                                <li>
                                    <a href={`https://payment.lookmyticket.com/`} target="_blank">Online Payment</a>
                                </li>
                                {/* <li>
                                    <a onClick={loadTawkChat}>Chat with us</a>
                                </li> */}
                            </div>
                        </ol>
                    </div>
                </div>
                <div className="footer__right">
                    <div className="ftr_social">
                        <div className="col-left" style={{ width: isMobile ? "100%" : "60%", marginBottom: isMobile && 13 }}>
                            <div className="title">PAYMENT MODE</div>
                            <div className="pay-card">
                                <img src={upi} alt="upi" />
                                <img src={masterCard} alt="masterCard" />
                                <img src={visa} alt="visa" />
                            </div>
                            <div className="pay-card">
                                <img src={american} alt="american" />
                                <img src={rupay} alt="rupay" />
                                <img src={pci} alt="pci" />
                            </div>
                        </div>
                        <div className="col-right" style={{ width: "40%" }}>
                            <img
                                src={IATA}
                                alt="IATA Agent"
                            />
                            <img
                                src={TAAFI}
                                alt="TAAFI"
                            />
                            <img
                                src={TAAI}
                                alt="TAAI"
                            />
                        </div>
                        {/* <div style={{ marginTop: "3.3rem" }}>
                            <div className="title">FOLLOW US ON</div>
                            <ul className="social-link">
                                <li>
                                    <a href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                        <a href="#">
                                        <i className="fab fa-telegram-plane"></i>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    {/* <div className="ftr_app-download">
                            <div className="title">
                                Download EaseMyTrip App
                            </div>
                            <div className="app-link">
                                <a href="#">
                                    <img src="#" alt="" />
                                </a>
                                <a href="#">
                                    <img src="#" alt="" />
                                </a>

                            </div>
                    </div> */}
                </div>
            </div>

            <div className="copyRight">
                <div></div>
                <p className="copyright-text">
                    Hand Crafted by <a href="http://travlogy.co" target="_blank"><img src={travlogy_logo} /></a>
                </p>

                {/* <img src={FooterImg} alt="" /> */}
            </div>
        </div>
    );
}

export default PageFooter;

const footerLinks = [
    {
        linkHeader: "OUR PRODUCTS",
        links: [
            {
                title: "Book Flight",
                url: "/flights",
            },
            // {
            //     title: "Book Flight",
            //     url: "/flights",
            // },
            {
                title: "Search Hotels",
                url: "/hotels",
            },
            {
                title: "Search Buses",
                url: "/buses",
            },
            // {
            //     title: "Hotel",
            //     url: "/flights",
            // },
            // {
            //     title: "Holiday Packages",
            //     url: "",
            // },
        ],
    },
    {
        linkHeader: "LMT INFO",
        links: [
            {
                title: "Terms & Conditions",
                url: "/termsandconditions",
            },
            {
                title: "Privacy Policy",
                url: "/privacypolicy",
            },
            {
                title: "About Us",
                url: "/aboutus",
            },
            {
                title: "Contact Us",
                url: "/contactus",
            }
        ],
    },
    // {
    //     linkHeader: "Contact Info",
    //     links: [
    //         {
    //             title: 'info@triumphhtravel.comy',
    //             url: "",
    //         },
    //         {
    //             title: '+91 1141 611 366',
    //             url: "",
    //         },
    //         {
    //             title: '+91 1141 611 366',
    //             url: "",
    //         },
    //         {
    //             title: '609, Vishwa Sadan Building, Janakpuri',
    //             url: "",
    //         },
    //         {
    //             title: 'District Centre, New Delhi - 110058',
    //             url: "",
    //         },
    //     ]
    // },
];

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        "& .why_us-div": {
            width: "100%",
            display: "flex",
            [theme.breakpoints.down(980)]: {
                flexDirection: "column",
            },
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.primary.lightGreenSec,
            "& .title": {
                fontSize: "22px",
                padding: "3%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.primary.defaultText,
                width: "23%",
                [theme.breakpoints.down(980)]: {
                    width: "93%",
                },
            },
            "& .whyUsContent": {
                width: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                [theme.breakpoints.down(980)]: {
                    width: "93%",
                    margin: "13px 0",
                },
                float: "left",
                padding: "0.3rem 1rem",
                "& .icon": {
                    marginRight: "0.5rem",
                    "& img": {
                        width: "45px",
                        height: "45px",
                    },
                },
                "& .whyUs__title": {
                    fontSize: "13px",
                    fontWeight: "700",
                    margin: "0",
                    [theme.breakpoints.down(980)]: {
                        fontSize: "16px",
                    },
                },
                "& .whyUs__desc": {
                    margin: "0",
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: 1.2,
                    [theme.breakpoints.down(980)]: {
                        fontSize: "13px",
                        fontWeight: "500",
                    },
                },
            },
        },

        "& .footer__section": {
            display: "flex",
            flexWrap: "wrap",
            [theme.breakpoints.down(980)]: {
                flexDirection: "column",
            },
            position: "relative",
            backgroundColor: theme.palette.primary.darkText,
            padding: "0.5rem 1.5rem",
            width: "100%",
            "& .footer__left": {
                width: "50%",
                [theme.breakpoints.down(980)]: {
                    flexDirection: "column",
                    width: "100%",
                },
                borderRight: "1px solid #505050",
                padding: "0 0.3rem",
                gap: "0.3rem",
                display: "flex",
                "& .ol-list": {
                    width: "100%",

                    float: "left",
                    color: "#fff",
                    "& .ol__header": {
                        marginTop: "1rem",
                        width: "100%",
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: theme.palette.font.primary,
                    },
                    "& ol": {
                        marginTop: "0.5rem",
                        listStyle: "none",
                        padding: "0",
                        color: theme.palette.primary.lightText,
                        transition: "all 0.8s ease 0s",
                        margin: "10px,0",
                        "& li": {
                            width: "100%",
                        },

                        "& li a": {
                            color: theme.palette.primary.lightText,
                            fontSize: "12px",
                            transition: "all 0.8s ease 0s",
                            fontFamily: theme.palette.font.primary,
                            cursor: "pointer",
                            "&:hover": {
                                textDecoration: "underline",
                                marginLeft: "6px",
                            },
                        },
                    },
                },
            },
            "& .footer__right": {
                width: "40%",
                //borderRight: `1px solid ${theme.palette.primary.lightGray}`,
                padding: "1rem 0",
                color: theme.palette.primary.lightText,
                flex: "wrap",
                display: "flex",
                justifyContent: "space-between",
                [theme.breakpoints.down(980)]: {
                    flexDirection: "column",
                    width: "100%",
                },
                "& .ftr_social": {
                    width: "100%",
                    position: "relative",
                    paddingLeft: "5rem",
                    display: 'flex',
                    // padding: "0 65px",
                    [theme.breakpoints.down(980)]: {
                        paddingLeft: "0.5rem",
                        flexDirection: "column",
                    },
                    "& .pay-card": {
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        // [theme.breakpoints.down(980)]: {
                        //    flexDirection: "column",
                        //  },
                        [theme.breakpoints.down(1026)]: {
                            flexWrap: "wrap",
                        },
                        gap: "0.5rem",
                        marginTop: "1.3rem",
                        "& img": {
                            width: "56px",
                            height: "39px",
                        },
                    },
                    "& .col-right": {
                        margin: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        //paddingLeft: 45,
                        maxWidth: 200,
                        [theme.breakpoints.down(960)]: {
                            margin: "5px 5px 5px -15px",
                        },

                        "& img": {
                            border: "4px solid #c2d87e",
                            borderRadius: "50%",
                            // width: "50%",

                            width: "80px",
                            height: "80px",

                            margin: "5px",
                        },
                    },
                    "& .social-link": {
                        display: "flex",
                        gap: "0.5rem",
                        padding: "0",
                        listStyle: "none",
                        "& li": {
                            position: "relative",
                            width: "30px",
                            height: "30px",
                            padding: "0.5rem",
                            borderRadius: `1px solid ${theme.palette.primary.lightText}`,
                            "& a": {
                                textDecoration: "none",
                                color: theme.palette.primary.lightText,
                                fontSize: "1.3rem",
                            },
                        },
                    },
                },
            },
        },
        "& .copyRight": {
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: theme.palette.primary.darkText,
            padding: "1rem 6rem",
            borderTop: `1px solid ${theme.palette.primary.lightGray}`,
            color: theme.palette.primary.lightText,
            "& .copyright-text": {
                fontSize: 9,
                "& img": {
                    height: 14,
                    margin: "0 0 0px 5px",
                },
            },
            // "& img": {
            //     paddingRight: "6rem",
            //     [theme.breakpoints.down(980)]: {
            //         paddingRight: "3rem",
            //         width: "20rem",
            //     },
            // },
        },
    },
}));
