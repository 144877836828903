import React from "react";
import { Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const TermsAndConditions = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const history = useHistory();

    const goToHomePage = () => {
        history.push("/");
    };

    return (
        <div
            className={classes.root}
            style={{
                marginBottom: 60,
                paddingLeft: isMobile ? 10 : "6%",
                paddingRight: isMobile && 10,
            }}
        >
            <Grid
                container
                spacing={isMobile ? 0 : 3}
                style={{ width: "100%" }}
                justifyContent="center"
            >
                {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}

                <Grid item xs={12} md={10}>
                    <Grid
                        container
                        spacing={1}
                        className={`${classes.firstCard} ${classes.Grid}`}
                    >
                        <Grid item md={12}>
                            <Typography
                                variant="h3"
                                className={classes.sectionHeader}
                                gutterBottom
                            >
                                TERMS & CONDITIONS:
                            </Typography>
                            <hr style={{ width: "100%" }} />
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Conditions of Use for Lookmyticket Website
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Lookmyticket Tours and Travels Private Limited, a business incorporated and existing in accordance with the laws of the Republic of India (collectively, "us," "we," or "our" or "Lookmyticket"), publishes and maintains this website at www.lookmyticket.com (the "Site"), including the mobile site and Smartphone App Platforms such as iOS, Android, and Windows. You agree to be governed by the terms and conditions set out below, as well as any additional guidelines and future amendments, by accessing or using the Site or by utilizing the Service on the Site. Any sub-site, webpage, or website you access through this site may have its own terms and conditions of use that are particular to that sub-site, webpage, or hyperlink, whether it belongs to a "affiliate" of Lookmyticket or not. The websites, WebPages, and hyperlinks may include any extra terms and conditions of use that are outlined in such websites, WebPages, and hyperlinks.
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                These Terms & Conditions of Use are subject to change or addition at any moment without prior notification by Lookmyticket. Any modifications to these terms and conditions of use or any other terms placed on this website take effect immediately upon posting. You must often check the "Terms & Conditions" page to remain up to date on any updates the "Site" may make.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Acceptance of these Terms and Conditions before using the Service
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                As an individual client, user, or beneficiary of this service, you and Lookmyticket Tours and Travels Private Limited (Lookmyticket), which has its registered office at #322, 3rd Floor,Global Business Park, Opposite Metro Mall, Zirakpur,Mohali (Punjab) - 140603, India are parties to the Terms and Conditions.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The price offered on {AppConstant.domainAlias} includes charges,
                                taxes (unless mentioned otherwise) and in some cases meals
                                (Breakfast/Lunch/Dinner). It doesn't comprise any personal
                                expenses or additional charges like seat selection fees or
                                addition of meals, etc.
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The only purpose of Lookmyticket's role as a "Intermediary" is to help clients find travel-related information, assess the availability of travel-related goods and services, make valid reservations or conduct other business with travel providers. You understand that Lookmyticket just serves as a limited agent for the service providers that advertise their offerings on the Lookmyticket platform and that Lookmyticket only acts as a middleman to let you access services.
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                As a result, Lookmyticket will not be held liable for any interruptions, changes, reschedules, delays, or cancellations of services provided by any person or organization (including airlines, hotels, activity providers, or other similar agencies) that you may engage or hire as a result of the information on the Site. Lookmyticket is not your last-mile service provider (though Lookmyticket may pass on such information to customers when requested to do so by any person providing such services).
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Other than refunds of convenience fees paid made to Lookmyticket for the services it provides to customers (subject to such refund requests being in compliance with these Terms and Conditions and with the terms of the applicable ticket / booking), Lookmyticket is not responsible for any refunds in connection with any services offered by any person on the Lookmyticket platform.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Utilizing the Website
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                To use this website, you represent and warrant that:
                            </Typography>

                            <ul className={classes.listText}>
                                <li>You are able to legally enter into these Terms of Use and establish a binding legal obligation.</li>
                                <li>You agree to abide by the Terms of Use when using this website.</li>
                                <li>You will only use this website to make legal bookings for you or another person on whose behalf you are able to do so lawfully.</li>
                                <li>You will make sure that the other person is aware of the Terms of Use, including any regulations or limitations, that apply to any reservations you make on their behalf.</li>
                                <li>You will include your true name (as it appears on your passport or another official ID), address, email address, and cell phone number. You will be entirely responsible for the information you give, and Lookmyticket will not be accountable for any mistakes or errors in the information you supply.</li>
                                <li>If you have an online account with this website, you are responsible for keeping your login information, including your login ID and password, secure. All information you provide on this website is truthful, accurate, current, and full.</li>
                                <li>Any use or abuse of your account by you or anyone else you are solely your responsibility.</li>
                            </ul>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Site's Communication Guidelines
                            </Typography>

                            <ul className={classes.listText}>
                                <li>You will receive an email from Lookmyticket after making a purchase on the site alerting you of the transaction's progress. The email will be sent to the email address you supplied, and Lookmyticket is not liable for its arrival in your inbox. You must use necessary caution and attention. To ensure that we can reach you successfully, it is your responsibility to submit the correct and accurate contact information, including your name (as it appears on your passport), email address, and phone number.</li>
                                <li>The consumer recognizes that Lookmyticket’s SMS (Short Messaging Service) is an extra service offered for their convenience. The current legislation does not require businesses to provide their clients SMS service notifications. For whatever reason, Lookmyticket is not responsible if you don't receive any SMS.</li>
                                <li>For any changes to flight schedules, cancellations, refunds, airline or hotel status, etc., Lookmyticket is not obligated to give information and assumes no obligation.</li>
                                <li>Any complaints about the service should be made in accordance with the grievance procedure outlined in this document.</li>
                            </ul>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Site and its Content
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You may only use this site for personal purposes. Any information or material from this site, including but not limited to any text, photos, music, video, or links, may not be copied, licensed, adjusted, distributed, exchanged, modified, sold, or transmitted for any commercial, business, or public purpose.
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You are given a limited, non-exclusive permission by Lookmyticket to access, use, and conduct transactions on this Site. You consent to not obstruct or attempt to obstruct this Site's operation in any way. Only logged-in users may have access to specific portions of the website. You might need to supply information and respond to questions in order to register as a member. Such inquiries and information must be addressed. You guarantee and indicate that all the information you give us about you and other people is truthful and correct.
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You acknowledge that we do not run, control, or give any kind of endorsement to any information, goods, or services found on the Internet other than those that are expressly identified as being provided by Lookmyticket. Additionally, you are aware that Lookmyticket cannot and does not guarantee or promise that any files made available for download on the Site are free of harmful code such as viruses or worms. You are in charge of putting procedures into place that meet your specific requirements and ensure that data entry and output are accurate.
                            </Typography>
                            <br/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Any additional information placed on this website, including product features and specifications that are described or shown there, may be added, changed, discontinued, removed, or suspended at any time by Lookmyticket without prior notice or responsibility.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Site Ownership
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The "Content" on this website, which includes but is not limited to music, video, text, graphics, software, icons, and similar items, is all copyrighted under international treaties and intellectual property laws. Except as provided below, you are not permitted to use or license the Content. You consent to abide by any guidelines governing how to use the Content that is posted on this website.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                This website contains a number of proprietary logos, service marks, and trademarks, whether they are owned or utilized by Lookmyticket or not. Lookmyticket is not offering you any permission to use any exclusive logos, service marks, or trademarks by displaying them on our site.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Any improper use of the Content may be in violation of civil, criminal, copyright, trademark, privacy, and publicity laws.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We or our suppliers and providers own all the material and information on this website, including but not limited to the cost and accessibility of travel services, as well as the technology platform on which it is provided.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You agree not to change, adapt copy, distribute, transmit, display, perform, reproduce, publish, license, make derivative works from, transfer, sell, or resell any information, software, products, or services obtained from or through this Website. You are permitted to make a small number of copies of your travel itinerary (and related documents) for travel or service reservations made through this Website.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Availability and Service Rules
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                It's possible that the goods and services you see on the website are unavailable where you live or in your country. The fact that such items and services are mentioned on the Site neither implies nor guarantees that they will be offered in your specific area at any given time. The items and services are also subject to availability; in the event that a product is unavailable or sold out when a transaction is completed on the Site, Lookmyticket shall not be liable.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The terms of service and regulations, including the carriage contract conditions, of each service provider apply to all the goods and services offered on the Website.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Before making a reservation with us, you must consult, familiarize yourself with, and follow by the terms of service and regulations of the specific service provider. Any violation of these service provider laws and regulations is not our responsibility.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Guidelines about Visa
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Please make sure you have a current visa for the nation you will be travelling to or through. Please confirm the relevant visa requirements with the airline and consulate.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Booking Procedure and Pricing Policy
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                All relevant government taxes are included in the final price that is shown on the website. Lookmyticket retains the right to cancel the ticket and keep the money paid as Cancellation Charges in the event of a rate rise when the airline issues the air ticket, a mistake in the amount caused by a server fault, or a problem with the bank's payment channel. To minimize any inconvenience, the difference in payment should be sent to Lookmyticket. after receiving notice from Lookmyticket. Before your reservation can be confirmed, you must pay the complete sum. When checking in, make sure the newborn is under 2 years old and has appropriate proof of age documentation. Also keep in mind that the infant must be accompanied by an adult who is at least 18 years old. There may only be one infant per adult in a reservation. The child must be under 24 months old for the full itinerary you are booking in order to qualify for infant rates. You must make a separate reservation for the infant if it is 24 months or older.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                When checking in, make sure the kid has legal identification that verifies their age, since all child tickets must be purchased for children between the ages of 2 and 12. Also keep in mind that children under the age of 18 must be accompanied by an adult who is at least 18 years old. The terms and conditions set out by the specific Airlines and/or Supplier shall also apply to all tickets and reservations supplied to the client. Flight cancellations or delays caused by airlines are not the company's fault. For the refund to be executed if a ticket is cancelled directly through an airline's website, office, or phone centre, the consumer must notify Lookmyticket.com. Please take note that Lookmyticket.com does not receive any alerts directly from airlines.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Policy for Flight Modification and Cancellation
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Every ticket made on Lookmyticket is subject to airline cancellation fees, which might change depending on the flight and booking class. For domestic and international plane tickets, Lookmyticket.com charges a small fee of Rs. 300 per passenger/per sector and Rs. 500 per passenger/per sector, respectively, as cancellation service costs. The cost of EMTC insurance is not refundable. The product premium with zero cancellation fees that was paid at the time of booking is not refundable. In all circumstances, convenience fees are not refundable. According to the individual airline's policy, some reservations may not be refundable. According to the individual airline's policy, some reservations cannot be partially cancelled.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Through the My Booking area or by sending an email to Lookmyticket, cancellations can be done online or offline. We help you make a variety of changes to reservations. You may in rare circumstances need to speak with the airline personally. The airline's policy, which differs depending on flight schedules and ticket class, may affect the amendment fees on Lookmyticket.com. Lookmyticket advises customers to contact the airlines directly to cancel tickets within 24 hours of the trip, and then submit a cancellation request on our website for reimbursements.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Customers must submit claims for any legitimate refunds, in accordance with the established regulations, for no-show or unused reservations within 90 days of the travel date in the case of air, train, taxi, and/or bus tickets, and/or the date of check-in in the case of hotel reservations. Requests made 90 days or more after the travel date or check-in date as stated above would not be eligible for a refund, and any unclaimed funds for such no-show or unused bookings would be declared lost.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Once a cancellation request is received, the refund will be executed as soon as possible. If you cancel directly with an airline, your refund will be handled after we deduct our service fee and any airline fees. All refunds must be handled pending processing by the appropriate airline or service provider. Please be aware that the convenience fee you paid when making your reservation is not refundable.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Amendment & Cancellation Policy for Holidays
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                A cancellation fee of 25% of the tour cost (if fully paid) will be assessed for cancellations made 60 days or more prior to the departure date. 50% of the tour price will be levied as cancellation fees for cancellations made within 30 days before departure. Before 15 days before to the scheduled departure, cancellations will incur fees equal to 100% of the tour cost.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Refund Procedure
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Only after receiving a cancellation request or claim from the consumer, travel agency, or corporate business through email or the My Booking area will Lookmyticket begin processing the reimbursement. After obtaining the refund money from the airlines, refunds for cancelled flight tickets will be promptly paid to the customer's payment method. Only after obtaining a refund request from the relevant airline, hotel, Transportation Company, or other source will Lookmyticket process it within 72 hours. Due to banking procedures, credit card refunds may take up to 72 hours, while net banking refunds may take up to 7 days. Once funds have been utilized for a transaction, they cannot be transferred back to your bank account. However, it may be applied to a future reservation with us.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                When an airline or hotel is declared insolvent or bankrupt, refunds may be given
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                In the event that an airline or hotel ceases business or declares bankruptcy, Lookmyticket shall not be obligated to make any reimbursements. Customers, clients, or agents may not hold Lookmyticket accountable for paying the refunds promised at the time of ticket purchase in circumstances where the airline or hotel suspends operations or declares bankruptcy. Lookmyticket may occasionally reimburse money to customers based on guarantees provided by airlines, hotels, and suppliers, but Lookmyticket has the right to recoup the money in the event that the airline or hotel goes out of business or declares bankruptcy.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Rights in Lookmyticket
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Any communications or materials you transmit to Lookmyticket through email or other means, including any questions, comments, data, ideas, or the like, are non-confidential and will be handled as such by Lookmyticket.
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                By signing this agreement, you renounce all claims that any use of this material violates your moral rights, privacy rights, proprietary or other property rights, right of publicity, right to credit for ideas or work, or any other right, including the right to approve how Lookmyticket uses this material.
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Any content provided to this site may be altered, broadcast, edited, copied, disclosed, performed, posted, published, sold, transmitted, or otherwise utilized by Lookmyticket globally and across all media indefinitely.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Others' rights
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You agree to use any message or communication services included on this site—such as message boards, chat rooms, blogs, access to mailing lists, etc.—only to send and receive appropriate and pertinent messages and information. You agree that you will not use the Site or any feature made accessible via it to, for example and not as a limitation:
                            </Typography>

                            <Gap10 />

                            <ul className={classes.listText}>
                                <li>Intimidate, stalk, harass, defame, or otherwise infringe against the legal rights (such as the privacy and publicity rights) of others.</li>
                                <li>Disseminate any libelous, slanderous, illegal, pornographic, or otherwise objectionable material or information</li>
                                <li>Unless the user owns or controls the rights thereto or has obtained all necessary consents thereto as may be needed by law, the user should not upload or attach files that include software or other content that is subject to intellectual property laws (or by rights of privacy and publicity).</li>
                                <li>Upload or attach files containing malware, damaged files, or any other similar programmes or software that might harm another person's computer functioning.</li>
                                <li>Remove any proprietary designations or labels, legal disclaimers, or author attributions from any submitted files.</li>
                                <li>falsify the source or origin of any software or other content in a file that has been uploaded</li>
                                <li>Downloading any file submitted by another member of a forum that the user knows, or reasonably should know, cannot be lawfully transmitted in such a manner is prohibited, as is advertising or offering to sell any products or services, conducting or forwarding surveys, competitions, or chain letters.</li>
                                <li>use this website or any of its contents for commercial gain; make a reservation that is speculative, false, fraudulent, or made in advance of demand; access, monitor, or copy any content or information from this website using any robot, spider, scraper, or other automated means for any purpose; violate any robot exclusion headers on this website; or evade, defeat to website, or otherwise interfere with security measures.</li>
                                <li>'frame','mirror', or otherwise incorporate any portion of this Website into any other website without our prior written consent; deep-link to any portion of this Website (including, without limitation, the purchase path for any travel services); or take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
                                <li>
                                    host, present, upload, change, release, send, update, or distribute the following:
                                    <ul>
                                        <li>I) Information to which the user has no legal authority but which belongs to another person.</li>
                                        <li>ii) Information that promotes money laundering or gambling is racially or ethnically offensive, pedophilic, dangerous to minors, hateful, or extremely detrimental to others' privacy. Information that violates any patent, trademark, copyright, or other proprietary rights is listed as item number iii.</li>
                                        <li>iv) Information that misleads the recipient regarding the source of the communication or conveys any material that is blatantly insulting or ominous in character.</li>
                                        <li>v) Information that endangers India's unity, integrity, defense, security, or sovereignty, cordial relations with other countries, public order, or that incites the commission of any crimes that are punishable by law, obstructs the investigation of such crimes, or insults any other country.</li>
                                        <li>
                                            vi) Information posing as another individual.
                                            <br/>
                                            Any travel or service bookings linked to your name, email address, or account may be cancelled by Lookmyticket, and any related Lookmyticket accounts may be closed, if you’re booking or account exhibits symptoms of fraud, abuse, or suspicious conduct. Additionally, Lookmyticket may preauthorize (or verify) your credit card. You can be responsible for financial losses to Lookmyticket, including litigation expenses and damages, if you have engaged in any fraudulent activity. Lookmyticket has the right to take any required legal action in this situation. Please get in touch with Lookmyticket Customer Service if you want to protest the cancellation of a reservation or the freezing or closing of an account.
                                            Lookmyticket has the right to take whatever measures are required to safeguard the security, safety, and integrity of its systems as well as the interests of its clients. In order to do this, Lookmyticket may take a number of actions to ensure the legitimacy, enforceability, and authenticity of orders submitted by you.
                                            If Lookmyticket determines, in its sole and absolute discretion, that the aforementioned transactions are not, or do not reasonably appear to be, authentic, enforceable, or valid, Lookmyticket may, at any time prior to the scheduled time of departure of the pertinent flight or prior to the anticipated date of travel to any property booked through Lookmyticket, cancel the aforementioned orders.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Transmitted Material
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Internet communications are never 100 percent safe or private. You realize that unless there is a specific notice stating that a particular communication (for example, credit card information) is encrypted, any message or information you submit to this site might be viewed or intercepted by others (sent in code). No particular obligations are placed on Lookmyticket as a result of your communication being sent to them.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The website's contents are protected by Lookmyticket's copyright. As a result, Lookmyticket retains all rights. Except insofar as such copying or printing is required to use the paid services offered, it is forbidden to copy all or part of the contents of this website without Lookmyticket's consent.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Deals, competitions, and interactions
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                This website may have competitions that ask you to submit materials or personal information in exchange for prizes. You must read and agree to the specific terms and conditions of each offer, competition, and engagement before participating.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                No Guarantees are Made and Disclaimer
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You agree that as an intermediary, Lookmyticket is not responsible for any third-party (suppliers or service providers) responsibilities related to pricing, quality, or other situations, whether to such subscribers or not. Use of the services and the website is solely at your own risk, as you expressly agree. It is your obligation to assess the truthfulness, completeness, and applicability of all statements made in any opinion or advice offered through the website or generally on the internet.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We can't guarantee that any service will operate without interruption or mistakes, or that errors on the website will be fixed. Any disputes about services offered on the Lookmyticket platform (such as those offered by hotels, airlines, etc.) should be directed at the appropriate third-party service provider; Lookmyticket disclaims all liability with regard to such claims.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The information on this website is given "as is," "as available," and "with all faults" without any explicit or implied warranties or assurances. This website's information, software, goods, and services are not endorsed or recommended by Lookmyticket, Lookmyticket Affiliates, or their respective suppliers, nor does its inclusion or provision of any products or services imply such endorsement or recommendation. There may be technical or typographical mistakes in the content on this website. At any time, Lookmyticket may make adjustments or enhancements. To the fullest extent permitted by applicable law, Lookmyticket expressly disclaims all implied warranties of merchantability with regard to the information and description of the hotel, air, or other travel products displayed on this website (including, without limitation, the pricing, photographs, list of amenities, general product descriptions, etc.). This information is provided by the respective suppliers, and Lookmyticket is not responsible for it. The accuracy of the hotel or other ratings presented on this website is not guaranteed by Lookmyticket, Lookmyticket Affiliates, or their respective suppliers. The ratings are only meant to be used as basic guides. The availability of particular goods and services is not guaranteed by Lookmyticket, Lookmyticket Affiliates, or their respective suppliers.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                This website may at any moment be improved or changed by Lookmyticket. In terms of correctness, accuracy, reliability, or any other aspect, Lookmyticket and its third-party suppliers, licensors, and partners do not guarantee or make any warranties regarding the use or the outcomes of using the services, the site, or other reference sites.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Although Lookmyticket makes every effort to ensure your complete satisfaction, it does not guarantee that the functions contained in this website will be error-free or uninterrupted, that bugs will be fixed, or that this website or the servers that make it available are free of viruses or other harmful components.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Insofar as the content on the site is sourced from other service providers including airlines, hotel owners, and tour operators, Lookmyticket does not guarantee the content's correctness, accuracy, reliability, or any other aspect of its usage or the outcome of using it.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You accept that only on the basis outlined in these terms and conditions is this Website made available to you. Certain factors outside of our reasonable control, such as, without limitation, the unavailability, in operation, or interruption of the Internet or other telecommunications services, or as a result of any maintenance or other service work carried out on this Website, may prevent your uninterrupted access to or use of this Website on this basis. In the event that you are unable to access or use the Site, Lookmyticket disclaims all liability and will not be held accountable for any resulting loss or harm.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Limitation of Liability and Indemnification
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You acknowledge that your use or misuse of the Services or the Site, any violation by you of this Agreement, or any breach of the representations, warranties, or covenants made by you herein will subject Lookmyticket, its affiliates, contractors, employees, officers, directors, and agents to claims, losses, damages, and liabilities, costs and expenses, including without limitation legal fees and expenses.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                In no event will Lookmyticket be liable to you for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation lost business opportunities, lost revenues, loss of anticipated profits, or any other monetary or non-monetary loss or damage of any nature whatsoever) resulting from or connected with I this agreement, (ii) the services, the site, or any reference site, or (iii) your use of or inability to use the service. Your whole obligation to Lookmyticket or any of its contractors, directors, employees, agents, third-party partners, licensors, or suppliers for any losses, damages, liabilities, and causes of action resulting from or connected with Any claims against Lookmyticket arising out of I this Agreement, (ii) any services rendered by Lookmyticket, (iii) your use of, or inability to use, the Services, the Site (including all Materials), any reference sites, or (iv) any other interactions with Lookmyticket, regardless of how they may have been caused and regardless of whether they arise in contract, tort (including negligence), warranty, or other grounds, shall not exceed the amount you have paid, if any, to Lookmyticket as convenience
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Privacy Policy
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Your privacy is important to Lookmyticket. To learn more about our procedures and to examine our current Privacy Policy, which also regulates how you use the website, please go here: https://www.lookmyticket.com/privacy-policy Lookmyticket's privacy statement
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Notice
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You may receive alerts and messages from Lookmyticket by email, normal mail, postings on the Site, or any other practical method. A notice to Lookmyticket shall be delivered by courier or registered mail to and Lookmyticket Tours and Travels Private Limited (Lookmyticket), which has its registered office at #322, 3rd Floor,Global Business Park, Opposite Metro Mall, Zirakpur,Mohali (Punjab) – 140603 ,India unless otherwise specified in this clause.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Waiver
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Any rights or provisions of this Agreement that are not exercised or enforced by Lookmyticket shall not be considered waived by Lookmyticket. Any waiver of any of the terms of this Agreement must be in writing and signed by Lookmyticket in order to be valid.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Arbitration
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Lookmyticket may choose to resolve any dispute, controversy, or claim arising out of or relating to this Agreement or the Service provided in connection with this Agreement by binding arbitration in accordance with the provisions of the Arbitration & Conciliation Act, 1996, as the acceptance terms of Use constitute a valid and binding agreement. By accepting the terms and conditions set out below, you consent to the Arbitration of any such disagreement, controversy, or claim, which shall be submitted to and determined by a Sole Arbitrator chosen and nominated by Lookmyticket. The arbitration will take place in In Punjaab, India, and any court with jurisdiction over the matter may enter judgement on the arbitral award. The arbitration shall be conducted in English.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                In Punjab, India, a court of competent jurisdiction may provide you or We any interim or preliminary remedy that is required to safeguard Lookmyticket's rights or property (or those of its agents, suppliers, and subcontractors) until the arbitration is over.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Governing law and the dispute forum
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You agree that any claim or disagreement you may have against Lookmyticket must be decided by a court in Mumbai, India, unless the parties otherwise agree or as stated in the arbitration section above. In order to resolve any such claims or disputes, you consent to submit to the personal jurisdiction of the courts situated in Mumbai, India. Without regard to any rules of conflict of laws, this Agreement shall be governed by Indian law.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                How to reach Lookmyticket for Service Related Issues
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                <b className="highlight">Level1</b> : Visit the 'Trips' area of your Lookmyticket, log in, and then choose the appropriate trip. Click "Contact Us," choose the problem, and then continue. You may reach us via phone at +91-869-966-5757/5858. (Along with your Trip ID)
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                <b className="highlight">Level2</b> : Within 72 hours of receiving the Level 1 grievance, please write to us at info@lookmyticket.com with the Trip ID if the resolution did not satisfy your expectations.
                            </Typography>
                        </Grid>

                        {/* <Grid
                            item
                            md={12}
                            className={classes.sectionTextGrid}
                            style={{ textAlign: "center" }}
                        >
                            <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                                <i className="fa fa-home"></i> Home
                            </a>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TermsAndConditions;

const Gap10 = () => {
    return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .highlight": {
            color: theme.palette.primary.darkOrange,
        },
    },
    Grid: {
        background: theme.palette.background.default,
        marginTop: "20px",
        boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
        padding: 30,
        borderRadius: 20,
    },
    sectionHeader: {
        color: `${theme.palette.primary.darkFadedText} !important`,
        fontWeight: 500,
        margin: "5px 0 30px",
    },
    sectionSubhead: {
        color: `${theme.palette.primary.defaultText} !important`,
        fontWeight: 400,
    },
    sectionText: {
        color: `${theme.palette.primary.davyGray} !important`,
        fontWeight: 400,
        lineHeight: "27px",
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.davyGray,
            fontWeight: 400,
            lineHeight: "27px",
        },
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.darkFadedText,
            fontWeight: 400,
            lineHeight: "27px",
        }
    },
    breadcrumbLink: {
        color: `${theme.palette.primary.active} !important`,
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.primary.pomegranate} !important`,
        },
    },
}));
