import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import image1 from "assets/rive/busanimation.riv";
import Rive from "rive-react";
import OaFormAlerts from "pages/components/OaFormAlerts";
import Button from "components/CustomButtons/Button";
import { apiCall } from "oautils/oaDataUtils";
import {
  setTimerInitialMinute,
  setTimerInitialSecond,
  setBusBookingInitialTime,
} from "redux/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import loaderImage from "assets/img/ajax-loader.gif";

const BusSearchProgressPage = (props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [size, setSize] = React.useState(0);
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);

  const theme = useTheme();
  const history = useHistory();

  const redirectToSearchEngine = () => {
    setTimeout(() => {
      history.push("/buses");
    }, 1500);
  };

  React.useEffect(() => {
    setSize(window.innerWidth);
    var params = {
      DateOfJourney: props.location.state.selectedDate,
      OriginId: props.location.state.departureCityId,
      DestinationId: props.location.state.destinationCityId,
    };
    WebApi.getBusSearchResult(
      params,
      (response) => {
        // console.log(response);
        if (
          response.success === true ||
          response.message === "No Result Found."
        ) {
          // console.log(response.data.BusSearchResult);
          props.setTimerInitialMinute(0);
          props.setTimerInitialSecond(0);
          props.setBusBookingInitialTime(new Date().getTime() + 15 * 60 * 1000);
          window.sessionStorage.setItem("service", "bus");
          history.push({
            pathname: "/bus/bus-result",
            state: {
              BusSearchResult: response.data.BusSearchResult,
              selectedDate: props.location.state.selectedDate,
              origin: props.location.state.departureCityName,
              destination: props.location.state.destinationCityName,
              searchParams: props.location.state,
            },
          });
        } else {
          setIsError(!response.success);
          setInfoText(response.message + ". redirecting home ...");
          // redirectToSearchEngine();
        }
      },
      (error) => {
        console.log("internal error line 74 : ", error);
        history.push({
          pathname: "/bus/bus-result",
          state: {
            BusSearchResult: [],
            selectedDate: props.location.state.selectedDate,
            origin: props.location.state.departureCityName,
            destination: props.location.state.destinationCityName,
            searchParams: props.location.state,
          },
        });
        // setInfoText("internal error! please contact admin");
        // setIsError(true);
        // redirectToSearchEngine();
      }
    );
  }, [isLoading]);

  return (
    <div className={classes.baseContainer}>
      <GridContainer className={classes.root}>
        {isError === true ? (
          <>
            <GridItem md={12}>
              <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                alertTitle={"Error"}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              />
            </GridItem>
            <GridItem
              md={12}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                backgroundColor: "transparent",
                marginTop: 50,
              }}
            >
              <Button
                className={classes.button}
                onClick={() => {
                  history.push("/buses");
                }}
              >
                Back to search
              </Button>
            </GridItem>{" "}
          </>
        ) : (
          <>
            <GridItem
              md={12}
              className="loader-image-div section-row justify-content-center align-items-center wt-100p"
            >
              <img src={loaderImage} />
              {/* <Rive src={image1} /> */}
            </GridItem>

            <GridItem className="loader-image-div section-row justify-content-center align-items-center wt-100p">
              <GridContainer>
                <GridItem
                  md={12}
                  xs={12}
                  align={"center"}
                  // flexDirection={size < 950 ? "row" : "column"}
                  style={{
                    paddingTop: 0,
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className={"destination"}
                >
                  Please Wait.....
                  <br />
                  While we're discovering the top Bus services for you.
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem
              md={12}
              className="section-row justify-content-center align-items-flex-start wt-100p ht-20vh itin-container"
            >
              <GridContainer>
                <GridItem
                  md={4}
                  xs={12}
                  align={size < 500 ? "center" : "right"}
                >
                  {props.location.state.departureCityName}
                </GridItem>
                <GridItem md={4} xs={12} align="center">
                  <div className="arrow-container" style={{ marginTop: 10 }}>
                    <span className="arrow-right"></span>
                  </div>
                  <div
                    style={{
                      color: theme.palette.secondary.grayDove,
                    }}
                    className="departure-date"
                  >
                    <span>
                      Departure:{" "}
                      {dateFnsFormat(
                        new Date(
                          props.location.state.selectedDate.split("-")[0],
                          props.location.state.selectedDate.split("-")[1] - 1,
                          props.location.state.selectedDate.split("-")[2]
                        ),
                        "do MMM yyyy"
                      )}
                    </span>
                  </div>
                </GridItem>
                <GridItem md={4} xs={12} align={size < 500 ? "center" : "left"}>
                  {props.location.state.destinationCityName}
                </GridItem>
              </GridContainer>
            </GridItem>
          </>
        )}
        {/* {props.location.state.departureCityId} {props.location.state.destinationCityId} */}
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
    setBusBookingInitialTime: (busBookingInitialTime) =>
      dispatch(setBusBookingInitialTime(busBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusSearchProgressPage);

const useStyles = makeStyles((theme) => ({
  baseContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  root: {
    maxWidth: 768,
    boxShadow: `0px 0px 3px 0px rgba(107,107,107,0.75)`,
    marginTop: 30,
    padding: "10px 0 40px",
    "& .loader-image-div": {
      padding: "50px 0 30px",
    },
    "& .section-row": {
      display: "flex",
      backgroundColor: "transparent",
    },
    "& .justify-content-center": {
      justifyContent: "center",
    },
    "& .align-items-flex-end": {
      alignItems: "flex-end",
    },
    "& .align-items-flex-start": {
      alignItems: "flex-start",
    },
    "& .align-items-center": {
      alignItems: "center",
    },
    "& .ht-20-vh": {
      height: "20vh",
    },
    "& .ht-10-vh": {
      height: "10vh",
    },
    "& .wt-100p": {
      width: "100%",
    },
    "& .wt-50p": {
      width: "50%",
      [theme.breakpoints.down(520)]: {
        width: "100%",
      },
    },
    "& .flex-wrap": {
      flexWrap: "wrap",
    },
    "& .itin-container": {
      [theme.breakpoints.down(768)]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .departure-date": {
      marginTop: 25,
      [theme.breakpoints.down(768)]: {
        marginTop: 10,
      },
    },
  },
  timeInfo: {
    color: theme.palette.secondary.grayDove,
    marginTop: 25,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  infoCenter: {
    [theme.breakpoints.down(767)]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
      marginTop: "25px",
    },
  },
  infoLeft: {
    textAlign: "center",
  },
  infoRight: {
    textAlign: "center",
    [theme.breakpoints.down(767)]: {
      marginTop: "25px !important",
    },
  },
  button: {
    "&:hover": {
      background: theme.palette.background.sunnyFestival,
    },
    background: theme.palette.background.sunnyFestival,
    borderRadius: 50,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    height: "29px",
    width: "196px",
  },
}));
