import React, { useState } from "react";
import { withRouter } from "react-router";
import { Grid, Typography,useMediaQuery } from "@material-ui/core";
import { makeStyles, withStyles,useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  bookingMenu: {
    background: theme.palette.primary.main,
    padding: "20px 9px",
    borderRadius: 5,
  },
  menuItems: {
    color: theme.palette.primary.lightText,
    justifyContent: "space-evenly",
    padding: "7px 0px",
    cursor: "pointer",
  },
  subMenuItems: {
    color: "white",
    justifyContent: "space-evenly",
    padding: "7px 0px",
    "&:hover": {
      paddingLeft: 10,
      transition: "all .5s",
    },
    cursor: "pointer",
  },
  subMenuText: {
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
  },
}));

const MuiAccordion = withStyles({
  root: {
    border: "0px",
    marginLeft:"15px",
    marginRight:"15px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginLeft:"15px",
      marginRight:"15px",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    margin: 0,
    borderRadius:"5px",
    minHeight: "auto",
    "&$expanded": {
      minHeight: 35,
      height: 35,
    },
    content: {
      "&$expanded": {
        margin: 0,
      },
    },
  },
  expanded: {},
}))(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    background: theme.palette.primary.main,
  },
}))(AccordionDetails);

const Menu = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);

  const handleChange = () =>  {
    if(isMobile){
      setExpanded(!expanded);
  }
  };

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleChange}
      style={{borderRadius:5}}
    >
      {isMobile && <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon style={{color:theme.palette.primary.contrastText}}/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      > <span style={{color:theme.palette.primary.contrastText,paddingLeft:10}}>Bookings</span></MuiAccordionSummary>}
      <MuiAccordionDetails>
        <Grid item md={12} xs={12} sm={12} style={{ padding: "0px 15px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.bookingMenu}
          >
            <Grid
              container
              item
              xs={12}
              className={classes.menuItems}
              style={{ paddingTop: 0 }}
              onClick={() => history.push("/b2c/mybookings")}
            >
              <Grid item xs={1}>
                <i className="fa fa-home" aria-hidden="true" />
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.text}>Dashboard</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.menuItems}
              onClick={() => history.push("/b2c/mybookings")}
            >
              <Grid item xs={1}>
                <i className="fa fa-globe" aria-hidden="true" />
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.text}>ALL BOOKING</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              className={classes.menuItems}
              style={{ paddingBottom: 0 }}
              onClick={() => history.push("/b2c/myprofile")}
            >
              <Grid item xs={1}>
                <i className="fa fa-user" aria-hidden="true" />
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.text}>MY PROFILE</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default withRouter(Menu);
