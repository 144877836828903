import React, {useState, useEffect} from 'react';
import OaCard from 'oahoc/OaCard';
import AddBeneficiaryForm from './AddBeneficiaryForm';

const AddBeneficiary = (props) => {
    useEffect(() =>{

    },[]);
    return (
        // <OaCard className="oa-card-rounded-top" style={{ minHeight: 200 }}>
        <div style={{   width:900 }}>

<AddBeneficiaryForm {...props}/>
        </div>
               
        // </OaCard>
    )
}

export default AddBeneficiary;
