
import React, { useEffect,useState } from "react"
import { Grid, Typography,useMediaQuery,Divider } from "@material-ui/core";
import { makeStyles, withStyles,useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import image3 from "assets/img/dummy-three.jpeg";
import GridContainer from 'components/Grid/GridContainer';


import { AppStateContext } from "layouts/AppStateProvider";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";

import { connect } from "react-redux";
import {setB2CUserDetails} from "redux/action";
import { withRouter } from "react-router-dom";




const useStyles = makeStyles((theme) => ({
 
  typo5:{
      color:theme.palette.primary.defaultText,
  
      fontSize: "13px",
      fontWeight: 300,
  },
  typo7:{
      color:theme.palette.secondary. darkGrayishBlue,
      fontWeight:500
  }
    
  }));
  const MuiAccordion = withStyles({
    root: {
      border: "0px",
    
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
      
      },
    },
    expanded: {},
  })(Accordion);
  
  const MuiAccordionSummary = withStyles({
    root: {
      backgroundColor: "rgb(247, 247, 247)",
      padding: 0,
      margin: 0,
     
      minHeight: "auto",
      "&$expanded": {
        minHeight: 35,
        height: 35,
      },
      content: {
        "&$expanded": {
          margin: 0,
        },
      },
    },
    expanded: {},
  })(AccordionSummary);
  
  const MuiAccordionDetails = withStyles((theme) => ({
    root: {
      padding: 0,
      background: "rgb(247, 247, 247)",
    },
  }))(AccordionDetails);

 
  

const Profile=(props)=>{
    const classes=useStyles()
    const { userProfile,setUserProfile } = React.useContext(AppStateContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [expanded, setExpanded] = useState(false);
  
    const handleChange = () =>  {
        if(isMobile){
            setExpanded(!expanded);
        }
     
    };


    useEffect(()=>{
        apiCall(WebApi.userDetails,{},(response)=>{
            setUserProfile(response.data)
          
        })
       
    },[])

  
   
    return(
       
        <GridContainer>
           {userProfile && <Grid item md={12} xs={12} style={{textAlign: 'center', border: `1px solid ${theme.palette.secondary.zanah}`, padding: '1px', borderRadius: '5px', background: theme.palette.background. whiteSmoke}}>
           <MuiAccordion
        expanded={expanded}
        onChange={handleChange}
      >
       {isMobile &&  <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:theme.palette.primary.defaultText}}/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
         <span style={{color:theme.palette.primary.defaultText,paddingLeft:10}}> { userProfile.profileDetails.firstName && userProfile.profileDetails.firstName}</span>  </MuiAccordionSummary>}
        <MuiAccordionDetails style={{paddingTop:isMobile?0:10}}>
               <GridContainer spacing={2}>
                   <Grid item md={12} xs={12} sm={12}>
                       <img src={image3} width="45px"/>
                   </Grid>
                   <Grid item md={12} xs={12} sm={12}>
                       <Typography className={classes.typo7}>{ userProfile.profileDetails.firstName && userProfile.profileDetails.firstName}</Typography>
                   </Grid>
                   <Grid item md={12} xs={12} sm={12}>
                       <Divider></Divider>
                   </Grid>
                   <GridContainer spacing={1} style={{marginBottom:30}}>
                       <Grid item md={12} xs={12} sm={12}>
                           <Typography className={classes.typo5} style={{marginTop:"10px"}}>{ userProfile.profileDetails.email && userProfile.profileDetails.email}</Typography>
                       </Grid>
                       <Grid item md={12} xs={12} sm={12}>
                       <Typography  className={classes.typo5}>Phone: { userProfile.profileDetails.mobileNumber && userProfile.profileDetails.mobileNumber}</Typography>
                       </Grid>
                   </GridContainer>
               </GridContainer>
                  
               </MuiAccordionDetails>
    </MuiAccordion>   
              </Grid>
       
}
        </GridContainer>
  
    )
}



export default Profile;