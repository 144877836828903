import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import SaveIcon from "@material-ui/icons/Save";

const OaFormSaveButton = (props) => {
  function getIcon() {
    return props.isSaving === undefined || !props.isSaving ? (
      <SaveIcon />
    ) : (
      <CircularProgress size="24px" />
    );
  }

  return (
    <FormControl>
      <Button
        style={{ ...props.style }}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        startIcon={getIcon()}
        disabled={props.isSaving}
      >
        {props.label ? props.label : "Save"}
      </Button>
    </FormControl>
  );
};

export default OaFormSaveButton;
