import React from "react";
import clsx from "clsx";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const OaAcceptTermsConditions = (props) => {
  const classes = useStyles();

  const [openTNC, setOpenTNC] = React.useState(false);
  const [openFareRules, setOpenFareRules] = React.useState(false);

  const handleTncOpen = () => {
    setOpenTNC(true);
  };

  const handleCloseTNC = () => {
    setOpenTNC(false);
  };

  const handleOpenPolicyContent = () => {
    setOpenFareRules(true);
  };

  const handleCloseFareRules = () => {
    setOpenFareRules(false);
  };

  const handleCloseSnackbar = () => {
    props.setSnackbarOpen(!props.snackbarOpen);
  };

  return (
    <div className={clsx(classes.root, "accept-tnc-root")}>
      <div className="accept-tnc-inner-wrap">
        <Checkbox
          value={props.value}
          name={props.name}
          onChange={props.onChange}
        />
        <label className="form-check-label payment-info-title">
          I have read and agreed with the{" "}
          <a onClick={handleTncOpen}>Terms & Conditions</a> and the{" "}
          <a onClick={handleOpenPolicyContent}>
            {props.showPrivacyPolicy ? "Privacy Policy" : "Fare Rules"}
          </a>
        </label>
      </div>
      {props.snackbarOpen && (
        <p style={{ color: "red" }}>Please accept terms and conditions!</p>
      )}

      <Snackbar
        open={props.snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        className="snackbar-classes"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <React.Fragment>
          <Alert severity="error">
            Please accept terms and conditions to proceed
          </Alert>
        </React.Fragment>
      </Snackbar>

      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={openTNC}
        onClose={handleCloseTNC}
        className={classes.tncDialog}
      >
        <DialogTitle>
          <div className="modal-close">
            <span className="icon-holder" onClick={handleCloseTNC}>
              <Close />
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="content-container-inner">
            <props.termsAndConditions />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={openFareRules}
        onClose={handleCloseFareRules}
      >
        <DialogTitle>
          <div className="modal-close">
            <span className="icon-holder" onClick={handleCloseFareRules}>
              <Close />
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="content-container-inner">
            {props.showPrivacyPolicy ? (
              <props.privacyPolicy />
            ) : (
              <props.pricingPolicy />
            )}
          </div>
        </DialogContent>
      </Dialog>
      <style>
        {`
                    .modal-close {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-start;
                    }
                    .modal-close .icon-holder {
                        cursor: pointer;
                    }
                `}
      </style>
    </div>
  );
};

export default OaAcceptTermsConditions;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCheckbox-root": {
      padding: 0,
      marginLeft: -2,
      marginRight: 8,
    },
    "& .accept-tnc-inner-wrap": {
      display: "flex",
      alignItems: "flex-start",
    },
    "& .form-check-label": {
      color: theme.palette.text.dark,
      "& a": {
        cursor: "pointer",
        color: theme.palette.text.danger,
      },
    },
    "& .snackbar-classes": {
      "& .MuiAlert-root": {
        MozBoxShadow: "0px 0px 2px 0px rgba(117,116,117,1)",
        WebkitBoxShadow: "0px 0px 2px 0px rgba(117,116,117,1)",
        boxShadow: "0px 0px 2px 0px rgba(117,116,117,1)",
        borderRadius: 4,
      },
    },
  },
  tncDialog: {
    "& .modal-close": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      "& .icon-holder": {
        cursor: "pointer",
      },
    },
  },
}));
