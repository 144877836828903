import React from "react";
import Invoice from "pages/voucher/Invoice";
import InvoiceFlight from "pages/voucher/InvoiceFlight";
import InvoiceBus from "pages/voucher/InvoiceBus";
import InvoiceDMT from "pages/voucher/InvoiceDMT";
import InvoiceHotel from "pages/voucher/InvoiceHotel";
import InvoiceRecharge from "pages/voucher/InvoiceRecharge";

import OaCard from "oahoc/OaCard";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InvoiceInsurance from "./InvoiceInsurance";
import InvoiceAadhaarPay from "./InvoiceAadhaarPay";

const Voucher = (props) => {
  const goBack = () => {
    // console.log('here');
    props.history.goBack();
  };

  const render = () => {
    // console.log("props.match.params.type ", props.match.params.type);
    switch (props.match.params.type) {
      case "flight-agent":
        return (
          <InvoiceFlight query={props.match.params} type="agent_invoice" />
        );

      case "flight-user":
        return <InvoiceFlight query={props.match.params} type="user_invoice" />;
      case "dmt-without-charges":
        return (
          <InvoiceDMT query={props.match.params} type="dmt_without_charges" />
        );
      case "dmt-with-charges":
        return (
          <InvoiceDMT query={props.match.params} type="dmt_with_charges" />
        );
      case "recharge":
        return <InvoiceRecharge query={props.match.params} type="recharge" />;
      case "bus-agent":
        return <InvoiceBus query={props.match.params} type="agent_invoice" />;
      case "insurance-agent":
        return (
          <InvoiceInsurance
            query={props.match.params}
            type="insurance_invoice"
          />
        );
      case "bus-user":
        return <InvoiceBus query={props.match.params} type="user_invoice" />;
      case "hotel-agent":
        return <InvoiceHotel query={props.match.params} type="agent_invoice" />;
      case "hotel-user":
        return <InvoiceHotel query={props.match.params} type="user_invoice" />;
      case "insurance-user":
        return (
          <InvoiceInsurance query={props.match.params} type="user_invoice" />
        );
      case "aadhaarpay-agent":
        return (
          <InvoiceAadhaarPay query={props.match.params} type="agent_invoice" />
        );
      case "aadhaarpay-user":
        return (
          <InvoiceAadhaarPay query={props.match.params} type="user_invoice" />
        );
      default:
        return <Invoice query={props.match.params} />;
    }
  };

  return (
    <OaCard className={"oa-card-primary"}>
      <Paper elevation={16} className={"oa-tabs-primary"}>
        <Tabs indicatorColor="secondary" textColor="primary" value="voucher">
          {/* <Tab label="Voucher" value="voucher" onClick={goBack} /> */}
        </Tabs>
      </Paper>
      {render()}
    </OaCard>
  );
};

export default withRouter(Voucher);
