import React, { useState } from "react";
import { Grid, Typography, Divider, useMediaQuery, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { AppConstant } from "appConstant";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  Grid: {

    background: theme.palette.background.default,
    marginTop: "50px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,

  },
  firstCard: {
    "& .MuiTypography-root": {
      color: theme.palette.primary.defaultText,
    },
  },
  sectionHeader: {
    color: `${theme.palette.secondary.darkShadedGray} !important`,
    fontWeight: 500,
    margin: "20px 0 30px"
  },
  sectionSubhead: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 500,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
  },
  sectionTextGrid: {
    margin: "5px 0"
  },
  collapsibleContainer: {
    border: "none !important",
    boxShadow: "none !important",
  }
}));

const Faqs = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root} style={{ marginBottom: 60, paddingLeft: isMobile ? 10 : '6%', paddingRight: isMobile && 10 }}>
      <Grid container spacing={isMobile ? 0 : 3} style={{ width: "100%" }} justifyContent="center">

        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h2"
                style={{ fontSize: isMobile ? 20 : 24 }}
                className={classes.sectionHeader}
                gutterBottom
              >
                FAQs
              </Typography>
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    1.	How do I register on {AppConstant.domainAlias}?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    To register on {AppConstant.domainAlias}? click on register on the top right of this page. You can register using your Gmail ID and creating a password to login.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    2.	What should I do if I forget my password?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Under the login button there is an option to ‘Reset’ your password. Click on ‘Forgot Password’, submit your registered email ID and we will send you a link to reset the password on the email ID shared by you.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    3.	If I need any assistance or have any queries what can I do?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    We have a team of travel consultant to help you. You can give us a call at +91 9713265000 or write to us at {AppConstant.supportEmail}?
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    4.	What payment options does {AppConstant.name} have?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Currently we accept payments through Credit Card, Debit Card, Net Banking, UPI & Wallets.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    5.	How do I know that my card information is safe on your site?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    {AppConstant.name} is a Secure Site. Any information you enter when transacting over {AppConstant.name} is sent in a Secure Socket Layer (SSL) session and is encrypted to protect you against unintentional information disclosure to third parties. You will notice that when you move from the passenger information page to the payment page, the url changes from http:// to https://. This shows that you are now on a secure channel.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    6.	How do I know my reservation was booked?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    {AppConstant.name} will send you an SMS and email confirmation of your booking.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    7.	Do I need to confirm my flight reservation?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    There is no need to confirm your reservation. If you need any information, you can login to My Bookings or call our customer service team at the call center number mentioned above.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel8bh-content"
                  id="panel8bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    8.	How do I cancel a flight reservation?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    You can cancel booking online by visiting the MyBookings section on our website.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel9bh-content"
                  id="panel9bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    9.	Is there a cancellation policy for flights booked on {AppConstant.domainAlias}??
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Each airline has different cancellation charges. They are mentioned on the booking page at the time of making reservation. Check the Fare Rules for the booking to get complete information about the airline's cancellation policy. Over and above the airlines cancellation charges, {AppConstant.name} charges Rs.500 as standard cancellation charges for bookings cancelled through our call center. 
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel10bh-content"
                  id="panel10bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    10.	How will I get my e-ticket details?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Your e-ticket details will be sent to the email address you have provided during the booking process.
                    If at any time, you need to reprint your e-ticket, just login to My Bookings, select the flight and click the Print button.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel11bh-content"
                  id="panel11bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    11.	From where can my customer get their boarding pass?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    A customer can web-check in for their flight reservation from {AppConstant.name} website. The boarding pass will be sent on the registered email ID of the customer.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel12bh-content"
                  id="panel12bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    12.	What is the maximum number of seats that can be booked via {AppConstant.domainAlias}?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    You can book up to nine seats at a time.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel13bh-content"
                  id="panel13bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    13.	How do I cancel a hotel reservation?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    To amend a hotel reservation, please contact our call center at 9713265000 (all networks).
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel14bh-content"
                  id="panel14bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    14.	Will I be charged if I wish to cancel my hotel booking?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Yes, you have to Pay the Cancelations Charges, which is decided by the hotel.. However, the hotel concerned might charge a fee for cancellation. You can either check on your hotel voucher or call our call center for details.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel15bh-content"
                  id="panel15bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    15.	Does {AppConstant.name} operate the buses for which it sells the tickets?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    {AppConstant.name} does not operate any of the buses. We are a platform that facilitates bus ticket bookings. The buses are operated by various bus tour operators with whom we have an alliance.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel16bh-content"
                  id="panel16bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    16.	What are boarding points for a bus?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Boarding points are locations within the city from where you can board your bus. In large cities like Bangalore, Delhi, etc. there may be more than one boarding point for the same bus. You can choose the boarding point most convenient to you and board the bus from there.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel17bh-content"
                  id="panel17bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    17.	Why are different seats priced differently?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    The price varies as per the seat type. Some buses have both seats as well as sleeper berths in the same bus, and some buses also have both AC and non AC sections. As an approximate guide, seater seats are priced less than sleeper berths and non AC section is priced less than the AC section. For example, an AC sleeper berth will have a higher fare than a non AC sleeper berth in the same bus. Similarly, a sleeper berth will have a higher fare than a seater in the same bus.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel18bh-content"
                  id="panel18bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    18.	Can someone else travel on my bus ticket?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    No, someone else cannot travel on your bus ticket. You will need to present a photo identity proof (passport, driver's license, etc.) along with the bus-ticket to the bus conductor when boarding the bus. The name of the person on the bus ticket MUST BE the same as that on the photographic identity proof presented.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel19bh-content"
                  id="panel19bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    19.	If I miss my bus, am I eligible for a refund?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    No refund will be processed if you miss your bus. It will be considered as a No-Show.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel20bh-content"
                  id="panel20bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    20.	What if the bus I was supposed to travel in gets cancelled?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    If the bus tour operator cancels the bus, kindly note, the refund will be granted by the bus operator and will be processed to you by {AppConstant.name}.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel21bh-content"
                  id="panel21bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    21.	Can I modify my bus ticket booking?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    a.	To make changes to your bus ticket booking which involves change of passengers, partial cancellations, change in journey date, change in bus type, and change in timings, you will need to cancel your original ticket and make a new bus ticket booking.
                    b.	Changes which involve change in boarding point may be done in certain cases. Same is subject to the discretion of the bus operator and is not guaranteed. For all such changes, please call the bus operator directly at the phone number given on your e-ticket.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Accordion className={classes.collapsibleContainer} expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel22bh-content"
                  id="panel22bh-header"
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionSubhead}
                    gutterBottom
                  >
                    22.	Can I do a partial cancellation of the ticket?
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.sectionText}
                  >
                    Yes, you can do a partial cancellation of the ticket. However, partial cancellation (cancellation of one Sector) or segment change is not permitted for Discounted Return Fares. It’s all depend on the Airlines, Hotel, Bus company’s Rules..
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};
export default Faqs;
