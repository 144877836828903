import React, { useState, useEffect } from 'react'
import WebApi from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import { getLogoUrl } from 'oautils/oaImageUtils';
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaCard from 'oahoc/OaCard';
import { currencyFormatter } from 'oautils/oaCommonUtils';
import 'assets/css/print-portrait.css';
import { pdfDownload } from 'oautils/oaPdfUtils';
import Chip from '@material-ui/core/Chip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';

import { makeStyles, useTheme } from '@material-ui/core';
import { pdfWsDownload } from 'oautils/oaPdfUtils';
import { INSURANCE_WS_INVOICE_PDF_DOWNLOAD } from 'api/ApiConstants';

const useStyles = makeStyles(theme => ({
  tdHead: {
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.silverWhite,
  },

  tdCell: {
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
  },

  tdcellDividerTop: {
    borderWidth: "1px 0 0 0",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
  },

  tdcellPayment: {
    borderWidth: "1px 0 1px 0",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
  },

  tdcellPaymentBottom: {
    borderWidth: "0 0 1px 0",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
    textTransform: 'capitalize'
  }
}));

const InvoiceInsurance = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [iData, setIData] = useState({});
  const [showCharges, setShowCharges] = useState({});
  const theme = useTheme();
  const styles = useStyles();

  useEffect(() => {
    let id = props.query.ref;
    let type = props.type;
    let searchParams = {
      referenceNo: id
    }
    apiCall(WebApi.getVoucherInsurance, searchParams, (response) => {
      if (response.success === true) {
        setData(response.data);
      }
      setIsLoading(false);
      setIsError(response.success === false);
    });
  }, []);

  const printHandler = () => {
    window.print();
  }
  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
  }
  const downloadPdfHandler = (e) => {
    // e.target.setAttribute('disabled', 'disabled');
    // pdfDownload('section-to-print', 'p', 'invoice-' + props.query.ref)
    // e.target.removeAttribute('disabled');
    setIsLoading(true);
    pdfWsDownload(INSURANCE_WS_INVOICE_PDF_DOWNLOAD, { referenceNo: props.query.ref },
      "insurance-invoice-"+props.query.ref);
    setTimeout(() => { setIsLoading(false) }, 5000);
  }

  return (
    <div>
      {isLoading && <OaLoading />}
      {isError && <OaError />}
      {!isLoading && !isError &&
        <>
          <OaCard>
            <Chip icon={<PrintIcon />} label="Print" onClick={printHandler} color="primary" style={{ margin: "5px" }} />
            <Chip icon={<PictureAsPdfIcon />} label="PDF" onClick={downloadPdfHandler} color="primary" style={{ margin: "5px" }} />
            <div id="myMm" style={{ height: "1mm" }} />
            <div className={"section-to-print"} id="section-to-print" style={{ maxWidth: "900px" }}>
              <div style={{ border: `3px solid ${theme.palette.secondary.chineseGray}`, borderRadius: "5px" }}>
                <div style={{ margin: '5px' }}>
                  {data.invoiceHeader.psaLogo &&
                    <img src={getLogoUrl(data.invoiceHeader.psaLogo)} height="50" />
                  }
                </div>

                <div style={{ margin: '20px 5px 5px 5px' }}>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          <span style={{ fontWeight: "bold" }}>Invoice No: {data.transactionRef}</span>
                        </td>
                        <td>
                          <span style={{ fontWeight: "bold" }}>Invoice Date: </span> {data.transactionDate}
                        </td>
                        <td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{
                  backgroundColor: theme.palette.secondary.chineseGray,
                  fontSize: '1.2em',
                  fontWeight: 'bold',
                  padding: '10px 0',
                  textAlign: 'center'
                }}>
                  INVOICE
                </div>

                <div style={{ textAlign: 'right', padding: "5px" }}>
                  <div style={{ fontWeight: "bold" }}>{data.invoiceHeader.corporateName}</div>
                  <div>{data.invoiceHeader.address}</div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>TEL: </span> {data.invoiceHeader.landlineNumber}
                  </div>

                  <div>
                    <span style={{ fontWeight: "bold" }}>EMAIL: </span> {data.invoiceHeader.email}
                  </div>
                </div>

                <div style={{ align: "center", marginTop: "20px" }}>
                  <table width="100%" style={{ textAlign: "center" }} cellSpacing="0" cellPadding="0" border="1">
                    <thead>
                      <tr>
                        <th className={styles.tdHead}>Policy Name</th>
                        <th className={styles.tdHead}>Policy No.</th>
                        <th className={styles.tdHead}>Ref No.</th>
                        <th className={styles.tdHead}>Policy Start Date</th>
                        <th className={styles.tdHead}>Policy End Date</th>

                        <th className={styles.tdHead}>Status</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr >
                        <td className={styles.tdCell}>{data.planDescription}</td>
                        <td className={styles.tdCell}>{data.policyNumber}</td>
                        <td className={styles.tdCell}>{data.referenceNo}</td>
                        <td className={styles.tdCell}>{data.policyStartDate}</td>
                        <td className={styles.tdCell}>{data.policyEndDate}</td>
                        <td className={styles.tdCell}>{data.transactionStatus}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>



                <div style={{ marginTop: "20px" }}>
                  <table width="100%" cellSpacing="0" cellPadding="5" border="0">
                    <tbody>
                      <tr>
                        <td width="60%" className={styles.tdcellDividerTop}><br /></td>
                        <td className={styles.tdcellPayment}><span style={{ fontWeight: "bold" }}>Payment Details</span></td>
                        <td className={styles.tdcellPayment}>Amount (INR)</td>
                      </tr>
                      <tr>
                        <td><br /></td>
                        <td><span>Ticket Amount</span></td>
                        <td>{currencyFormatter(data.amount)}</td>
                      </tr>
                      <><tr>
                        <td><br /></td>
                        <td><span>Tax & other charges</span></td>
                        <td>{currencyFormatter(round(parseFloat(data.taxesAndFees), 2))}</td>
                      </tr>

                        <tr>
                          <td><br /></td>
                          <td className={styles.tdcellPaymentBottom}><span>Total</span></td>
                          <td className={styles.tdcellPaymentBottom}>{currencyFormatter(round(parseFloat(data.amount) + parseFloat(data.taxesAndFees), 2))}</td>
                        </tr></>

                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "20px", fontWeight: "bold", textAlign: "center" }}>
                  <br /><br /><br /><br /><br />
                  This is a computer generated invoice, hence signature is not required
                  <br />
                </div>
              </div>
            </div>
          </OaCard>
        </>
      }
    </div>
  )
}

export default InvoiceInsurance;
