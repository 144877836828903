// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import 'isomorphic-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import WebApi from "api/ApiConstants";
import {Formik, Form, ErrorMessage, Field } from 'formik';
import { InputAdornment, FormControl } from '@material-ui/core';
import {GET_FILE_UPLOAD} from "api/ApiConstants";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
}));


export const OaFormSelectComboField = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loadedList, setLoadedList] = React.useState([]);
  const loading = open && options.length === 0;

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    stringify: option => option[props.optionLabel[0]] + ' ' + option[props.optionValue], // what gets searched is defined here
  });

  function changeHandler(event, selectedObject) {
    //console.log(selectedObject); // this object should be returned to the parent
    if(props.changeHandler && selectedObject !== null){
      props.setFieldValue(props.name, selectedObject[props.optionValue]);
      props.changeHandler(event, selectedObject, props.setFieldValue);
    }
    if (selectedObject !== null) {
      props.setFieldValue(props.name, selectedObject[props.optionValue]);
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (loadedList.length === 0) {
        
           setOptions(props.options.map(row => row));
           setLoadedList(props.options);
       
      }
      if (active) {
        setOptions(loadedList.map(row => row));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, loadedList]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const getLabel =(option) => {
    if(Array.isArray(props.optionLabel)) {
      let label = props.optionLabel.reduce(function (total, num, index) {
        if(index == 1) {
          total = option[total]
        }
        if(index == props.optionLabel.length-1)
          return total + "("+ option[num] +")";
        else
        return total + ", " + option[num];
      });

      return String(label);
    } else
      return String(option[props.optionLabel]+ " (" + option[props.optionValue] + ")");
  }



  return (
    <React.Fragment>
      <FormControl fullWidth={true}>
      <Autocomplete
        id={props.name}
        name={props.name}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={changeHandler}
        getOptionSelected={(option, value) => option[props.optionValue] === value[props.optionLabel[0]]}
        getOptionLabel={option => {
          if(Array.isArray(props.optionLabel)) {
            let label = props.optionLabel.reduce(function (total, num, index) {
              if(index == 1) {
                total = option[total]
              }
              if(index == props.optionLabel.length-1)
                return total + "("+ option[num] +")";
              else
              return total + ", " + option[num];
            });

            return String(label);
          } else
            return String(option[props.optionLabel]+ " (" + option[props.optionValue] + ")");
        }}
        renderOption={(option) => (
          props.icon ? <>
            <Avatar alt={option.label} src={GET_FILE_UPLOAD + "?fileName="+option.icon+"&type=EMAIL_IMAGES"} className={classes.large}/>
            <span style={{paddingLeft:20}}>{String(option[props.optionLabel[0]])} </span>
          </> : getLabel(option)
        )}
        options={options}
        loading={loading}
        autoHighlight={true}
        filterOptions={filterOptions}
        renderInput={params => (
          <>
          <TextField
            {...params}
            label={props.label}
            // ref={props.name}
            fullWidth={true}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              name: params.name,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off'
            }}
          />
         
          </>
        )}
      />
      </FormControl>
    </React.Fragment>
  );
}

export default OaFormSelectComboField;
