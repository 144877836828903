import WebApi from "api/ApiConstants";

export async function submitDataToPhonepe(request) {
    return new Promise((resolve, reject) =>{
        WebApi.postPayGoalOrderTransact(request, (response) => {
            if (response.success) {
                submitForm(response.data.phonepe.data.instrumentResponse.redirectInfo.url);
            } else {
                console.error("error", response);
                reject("Internal error");
            }
        }, error => {
            console.error("error orderTransactPhonepe", error);
            reject("Internal error "+JSON.stringify(error));
        });
    });
}

const submitForm = (checkoutUrl) => {

    window.location.href = checkoutUrl;
};
