import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';
import {Formik, Form } from 'formik';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateField from 'oahoc/OaFormDateField';
import GridItem from "components/Grid/GridItem.js";
import OaFormSelectField from 'oahoc/OaFormSelectField';


const InsuranceReportSearchForm = (props) => {
    const [isLoading] = useState(false);
    const [] = useState(true);
    const [agencyId, setAgencyId] = useState(props.agencyId);

    useEffect(() => {
        if(isLoading ){
         
        }
    }, [isLoading]);

    useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId])

    return(        
    <>
        {isLoading && <OaLoading />}
        {!isLoading && 
        <>
        <h4 className={"oa-form-header"}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}} >Search Insurance Report</h4>
        <Formik     
           
           initialValues={{
            insuranceBookingFromDateDisplay: props.searchParams.insuranceBookingFromDateDisplay,
            insuranceBookingToDateDisplay: props.searchParams.insuranceBookingToDateDisplay,
            insuranceBookingFromDate: props.searchParams.insuranceBookingFromDate,
            insuranceBookingToDate: props.searchParams.insuranceBookingToDate,
            agencyId: props.searchParams.agencyId,
            bookingStatus:props.searchParams.bookingStatus
        }}
            onSubmit= {(searchParams, {}) => {
                props.setIsLoading(true);
                searchParams.insuranceBookingFromDate = dateFnsFormat(searchParams.insuranceBookingFromDateDisplay, 'dd-MM-yyyy');
                searchParams.insuranceBookingToDate = dateFnsFormat(searchParams.insuranceBookingToDateDisplay, 'dd-MM-yyyy');
                props.target(searchParams);
                props.setIsLoading(false);
            }}>
            {({ values, setFieldValue }) => (
                <Form>
                    <GridContainer>
                        <OaFormTextField name="hotelApplicationReference" label="Application Reference" md={4}/>
                        <OaFormTextField name="accountNo" label="Ticket No/PNR" md={4}/>


                        <OaFormDateField 
                            value={values.insuranceBookingFromDateDisplay}
                            selected={values.insuranceBookingFromDateDisplay}
                            onChange={date => setFieldValue('insuranceBookingFromDateDisplay', date)}
                            label="Insurance From Date"
                            name="insuranceBookingFromDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />


                        <OaFormDateField 
                            value={values.insuranceBookingToDateDisplay}
                            selected={values.insuranceBookingToDateDisplay}
                            onChange={date => setFieldValue('insuranceBookingToDateDisplay', date)}
                            label="Insurance To Date"
                            name="insuranceBookingToDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />

                        <GridItem  xs={12} sm={12} md={4}>
                            <OaFormSelectField 
                                  label="Status" 
                                  name="bookingStatus" 
                                  value={values.bookingStatus} 
                                  options={[
                                      {value: 'All', label: 'All'},
                                      {value: 'Confirmed', label: 'Confirmed'},
                                      {value: 'Cancelled', label: 'Cancelled'}
                                  ]}
                              />
                          </GridItem>

                    </GridContainer>
                    <Button color="primary" type="submit">Search</Button>
                </Form>
            )}
        </Formik>
        </>
        }
    </>
    )
}

export default InsuranceReportSearchForm;
