import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import MasterBalanceManagerNewCheque from './MasterBalanceManagerNewCheque';
import MasterBalanceManagerNewCash from './MasterBalanceManagerNewCash';
import MasterBalanceManagerNewEtransfer from './MasterBalanceManagerNewEtransfer';
import MasterBalanceManagerNewOnline from './MasterBalanceManagerNewOnline';
import { apiCall } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { makeStyles } from '@material-ui/styles';
import { localforageGetItem } from 'oautils/oaForageUtils';
import MasterBalanceManagerSent from './MasterBalanceManagerSent';

const useStyles = makeStyles(theme => ({
    balanceGrid: {
        [theme.breakpoints.down(1380)]: {
            "& .MuiTab-root": {
                minWidth: "135px !important",
            }
        }
    }
}));

export const MasterBalanceManagerNew = (props) => {

    const [response, setResponse] = useState({});
    const [bankList, setBankList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const classes = useStyles();
    const [userType, setUserType] = useState(null);
    let baseUrl = '/admin/management/balance/master/new/';
    let sectabs = [
        {name: 'chequedd', label: 'Cheque/DD', url: baseUrl + 'chequedd'},
        {name: 'etransfer', label: 'E-Transfer', url: baseUrl + 'etransfer'},
        {name: 'cash', label: 'Cash',  url: baseUrl + 'cash'},
        // (userType === "direct_agent" || userType === "agent") && {name: 'online', label: 'Instant Recharge',  url: baseUrl + 'online'},
    ];

    let defaultTab = 'chequedd';
    let queryVar = getQueryVar({...props, q: 'secTab'});
    let currentSecTab = getCurrentTab(queryVar, defaultTab);

    const getUserType = () => {
        localforageGetItem('user-details', function (err, value) {
        //   console.log(value)
            if(err == null && value!=null) {
                setUserType(value.userType);
                // setIsLoggedInUserId(value.userId);
            } else {
              console.log("err", err);
            }
        });
    }
    
      useEffect(() => {
        getUserType();
      }, [])

      
    useEffect(() => {
        if(isLoading){
            apiCall(WebApi.getReceiveBankList, {}, (response) => {
                let o = [];
                response.data.map( row => {
                    o.push({id: row.bankId, label: row.bankName, value: row.bankId});
                })
                setBankList(o);
                setResponse(response);
                setIsLoading(false);
                setIsError(response.success===false);
            });
        }
    }, [isLoading]);

    function renderTab(currentTab){
        switch(currentTab){
            case 'chequedd':
                return <MasterBalanceManagerNewCheque bankList={bankList} {...props}/>
            case 'cash':
                return <MasterBalanceManagerNewCash bankList={bankList} {...props}/>
            case 'etransfer':
                return <MasterBalanceManagerNewEtransfer bankList={bankList} {...props}/>
            case 'online':
                return <MasterBalanceManagerNewOnline bankList={bankList} {...props}/>
            default:
                return <OaError />
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.balanceGrid}>
                <OaCard>
                    <OaTabBar tabs={sectabs} currentTab={currentSecTab} type="secondary"/>
                    {isLoading && <OaLoading />}
                    {isError && <OaError details={response.message} />}
                    {!isLoading && !isError && renderTab(currentSecTab)}
                </OaCard>
            </GridItem>
        </GridContainer>
    )
}

export default MasterBalanceManagerNew;
