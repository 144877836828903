import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  withWidth,
  Popper,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { localforageGetItem } from "oautils/oaForageUtils";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import { setBusCities, setBusBookingInitialTime } from "redux/action";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { AiOutlineSwap } from "react-icons/ai";
import swiperIcon from "../../../../assets/lookMyTicket/images/icons/swipe_icon.svg";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import cx from "classnames";

function BusSection(props) {
  const busDepartureAutocompleteRef = useRef(null);
  const busDestinationAutocompleteRef = useRef(null);

  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  // const [data, setData] = useState({
  //   departure: "",
  //   destination: "",
  //   departureDate: new Date().toISOString(),
  //   class: "economy",
  // });

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const formikRef = React.useRef();
  const [corporateType, setCorporateType] = useState(null);

  let baseUrl = "/";

  let optionsBus = [
    {
      name: "busSearch",
      label: "SEARCH BUS",
      url: baseUrl + "buses",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "busMarkup",
      label: "MARK UP",
      url: "/admin/commission/bus",
      icon: <i className="material-icons">attach_money</i>,
    },
    // { name: 'busBank', label: 'BANK DETAILS', url: '/admin/management/bank-account', icon: (<i className="material-icons">%</i>) },
    {
      name: "busMoney",
      label: "ADD MONEY",
      url: "/admin/management/balance/master",
      icon: <i className="material-icons">post_add</i>,
    },
    {
      name: "busCancellation",
      label: "CANCELLATION",
      url: "/admin/booking-report/bus",
      icon: <i className="fas fa-ban"></i>,
    },
    // { name: 'searchHotel', label: 'DISCOUNTS', url: "#", disabled: true, icon: (<LocalOfferSharp style={{ fontSize: "27px" }} />) },
    // { name: 'searchHotel', label: 'COMMISSION', url: "#", disabled: true, icon: (<AccountBalanceWalletSharp style={{ fontSize: "27px" }} />) },
    {
      name: "busLedger",
      label: "LEDGER",
      url: "/admin/ledger",
      icon: <i className="material-icons">receipt_long</i>,
    },
    // { name: 'searchBus', label: 'MANAGE AGENT', url: "#", disabled: true, icon: (<FaceSharp style={{ fontSize: "27px" }} />) }
    {
      name: "busReport",
      label: "BUS REPORT",
      url: "/admin/booking-report/bus",
      icon: <i className="fas fa-bus"></i>,
    },
  ];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fetchMatchingCities = (searchParams) => {
    if (searchParams.length >= 3) {
      apiCall(
        WebApi.getMatchingBusCity,
        {
          cityId: "0",
          cityName: searchParams,
        },
        (response) => {
          if (response.success === true) {
            props.setBusCities(response.data.BusCities);
            // console.log("props", props);
            // console.log(response.data.BusCities);
            // setIsLoading(false);
          } else {
            setIsError(response.success);
            setInfoText(response.message);
          }
        }
      );
    }
  };

  useEffect(() => {
    localforageGetItem("user-details", function(err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
  }, []);

  const customPopper = function(props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom-start"
        style={{}}
      />
    );
  };

  const swapDepartureDestination = () => {
    // console.log("swapping ");
    const temp = formikRef.current.values.DestinationId;
    formikRef.current.setFieldValue(
      "DestinationId",
      formikRef.current.values.OriginId
    );
    formikRef.current.setFieldValue("OriginId", temp);
  };

  const classes = useStyles();

  useEffect(() => {
    if (isLoading) {
      const length = props.busCities ? Object.keys(props.busCities).length : 0;
      // console.log(length);
      if (length > 0) {
        setIsLoading(false);
      } else {
        apiCall(WebApi.getBusCity, {}, (response) => {
          if (response.success === true) {
            props.setBusCities(response.data.BusCities);
            // console.log(response.data.BusCities,"BusCities");
            setIsLoading(false);
          } else {
            setIsError(response.success);
            setInfoText(response.message);
          }
        });
      }
    }
  }, [isLoading]);

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    props.history.push({
      pathname: url,
    });
  };

  return (
    <Box className={classes.root} mx={4}>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          OriginId: "",
          DestinationId: "",
          DateOfJourney: new Date(),
          BusType: "economy",
        }}
        validationSchema={Yup.object().shape({
          OriginId: Yup.string().required("Origin is required"),
          DestinationId: Yup.string().required("Destination is required"),
        })}
        onSubmit={(values) => {
          if (values.OriginId.CityId > 0 && values.DestinationId.CityId > 0) {
            var params = {
              departureCityId: values.OriginId.CityId,
              departureCityName: values.OriginId.CityName,
              destinationCityId: values.DestinationId.CityId,
              destinationCityName: values.DestinationId.CityName,
              selectedDate: dateFnsFormat(values.DateOfJourney, "yyyy-MM-dd"),
            };
            props.setBusBookingInitialTime(null);
            window.sessionStorage.removeItem("service");
            props.history.push({
              pathname: "/bus/search-progress",
              state: params,
            });
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className={cx(classes.BusSection_busDet, "form-card")}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="inputs-container-main"
                >
                  <Grid
                    item
                    sm={2}
                    className="location-input"
                    style={{ paddingLeft: 25, paddingBottom: 3 }}
                  >
                    {props.busCities && (
                      <>
                        {/* <label>From</label> */}
                        <Autocomplete
                          className="autocomplete"
                          fullWidth
                          PopperComponent={customPopper}
                          name="OriginId"
                          options={props.busCities || []}
                          getOptionLabel={(option) => option.CityName || ""}
                          // options={busDepartureList}
                          // getOptionLabel={(o) => o.title || ""}
                          value={values.OriginId}
                          openOnFocus
                          getOptionSelected={(option, value) => {
                            return option.CityName == value.CityName;
                          }}
                          onKeyUp={(event) =>
                            fetchMatchingCities(event.target.value)
                          }
                          onChange={(event, newValue) => {
                            // busDestinationAutocompleteRef.current.focus();
                            // setData({ ...data, departure: newValue });
                            setFieldValue("OriginId", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              placeholder="Source City"
                              inputRef={busDepartureAutocompleteRef}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password"
                              }}
                            />
                          )}
                          disablePortal={true}
                        />
                      </>
                    )}

                    <ErrorMessage
                      name="OriginId"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item sm={1} className="swap-icon">
                    <IconButton
                      onClick={swapDepartureDestination}
                      aria-label="delete"
                      className={classes.swapIconButton}
                    >
                      <img src={swiperIcon} alt="" />
                    </IconButton>
                  </Grid>

                  <Grid
                    item
                    sm={2}
                    className="location-input arrival-input"
                    style={{ paddingLeft: 10, paddingBottom: 3 }}
                  >
                    {props.busCities && (
                      <>
                        {/* <label>Arrival</label> */}
                        <Autocomplete
                          fullWidth
                          className="autocomplete"
                          PopperComponent={customPopper}
                          name="DestinationId"
                          // options={busDestinationList}
                          // getOptionLabel={(o) => o.title || ""}
                          options={props.busCities || []}
                          getOptionLabel={(option) => option.CityName || ""}
                          value={values.DestinationId}
                          getOptionSelected={(option, value) => {
                            return option.CityName == value.CityName;
                          }}
                          onKeyUp={(event) =>
                            fetchMatchingCities(event.target.value)
                          }
                          onChange={(event, newValue) => {
                            // setDepartureDateDialogOpen(true);
                            // setData({ ...data, destination: newValue });
                            setFieldValue("DestinationId", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              // label="Arrival"
                              inputRef={busDestinationAutocompleteRef}
                              placeholder="Destination City"
                              inputProps={{
                                ...params.inputProps,
                                autocomplete: "new-password",
                              }}
                            />
                          )}
                          disablePortal={true}
                        />
                      </>
                    )}
                    <ErrorMessage
                      name="DestinationId"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item sm={2} className="date-input">
                      
                      <OaOutlinedDatePicker
                        showDisabledMonthNavigation
                        name="DateOfJourney"
                        className={classes.checkinDatePicker}
                        minDate={startDate}
                        customInput={
                          <TextField id="standard-basic" variant="standard" />
                        }
                        value={values.DateOfJourney}
                        onChange={(e, v) => {
                          busDestinationAutocompleteRef.current.blur();
                          setDepartureDateDialogOpen(false);
                          // console.log(
                          //   document.getElementById("searchActionButton")
                          // );
                          // document.getElementById("searchActionButton").focus();
                          // setData({ ...data, departureDate: e });
                          setFieldValue("DateOfJourney", e);
                        }}

                        KeyboardButtonProps={{
                          onFocus: (e) => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setDepartureDateDialogOpen(false);
                          },
                        }}
                        InputProps={{
                          onFocus: () => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        monthsShown={2}
                        dateFormat="dd MMM yyyy"
                        //labeltxt="DEPARTURE DATE"
                        selected={values.DateOfJourney}
                        showinput={true}
                       
                      />

                      <ErrorMessage
                        name="DateOfJourney"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  <Grid item sm={3}>
                    <Box className={classes.BusSection_actions}>
                      <Box className={classes.searchButtonBox}>
                        <Button
                          id="searchActionButton"
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          <i className="fas fa-search"></i>
                          SEARCH BUS
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {userType &&
                userType == "agent" &&
                corporateType &&
                corporateType != "corporate" && (
                  <Box className={classes.BusSection_quicklinks}>
                    {optionsBus &&
                      optionsBus.map((val, ind) => (
                        <div
                          item
                          className="quick-link-item"
                          key={ind}
                          onClick={() => openQuickLink(val.url)}
                        >
                          <div className="qlink-icon-wrapper">{val.icon}</div>
                          <div className="qlink-label-wrapper">{val.label}</div>
                        </div>
                      ))}
                  </Box>
                )}
            </Grid>

            {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

const mapDispatchToProp = (dispatch) => {
  return {
    setBusCities: (busCities) => dispatch(setBusCities(busCities)),
    setBusBookingInitialTime: (busBookingInitialTime) =>
      dispatch(setBusBookingInitialTime(busBookingInitialTime)),
  };
};

const mapStateToProp = (state, prop) => {
  return {
    busCities: state.bus.busCities,
  };
};

export default withRouter(
  connect(
    mapStateToProp,
    mapDispatchToProp
  )(withWidth()(BusSection))
);

const busDepartureList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Hyderabad", value: "hyderabad" },
  { title: "Bangalore", value: "bangalore" },
  { title: "Chennai", value: "chennai" },
];

const busDestinationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Hyderabad", value: "hyderabad" },
  { title: "Bangalore", value: "bangalore" },
  { title: "Chennai", value: "chennai" },
];


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 35,
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.defaultText,
    },
    "& .oa-form-element-card": {
      border: `0px ${theme.palette.primary.disabled}`,
      width: "100%",
      padding: 6,
      borderRadius: 5,
      overflow: "hidden",
      display: 'flex',
      flexDirection: 'column',
  },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
      fontSize:"16px !important",
    },
    "& .MuiAutocomplete-input ": {
      marginBottom: 6,
    },
    "& .form-card": {
      boxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      MozBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      WebkitBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
    },

    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    minWidth: 860,
    [theme.breakpoints.down(900)]: {
      minWidth: "100%",
      maxWidth: "100%",
      width: "100%",
    },
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
      marginRight: 0,
      padding: "0 25px",
      margin: "0 !important",
    },
    "& .location-input": {
      paddingBottom: "5px",
      // borderRight: '2px solid #eee',
      [theme.breakpoints.down(960)]: {
        "&.arrival-input .MuiFormControl-root": {
          marginTop: -25,
        },
      },
      [theme.breakpoints.down(900)]: {
        width: "40%",
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },

    "& .inputs-container-main": {
      height: "66px",
      background: "#fff",
      borderRadius: "4px",
      boxShadow: "4px 4px 6px #c2d87e",
    },
    "& .MuiBox-root": {
      [theme.breakpoints.between(599, 900)]: {
        padding: "0 70px",
      },
    },
    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      [theme.breakpoints.down(440)]: {
        margin: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  "& .swap-icon": {
    paddingLeft: 20,
    swapIconButton: {
      "& .MuiIconButton-label": {
        // color: theme.palette.primary.lightText,
        background: theme.palette.primary.lightText,
        borderRadius: 50,
        padding: 0,
      },
      "&.MuiIconButton-root": {
        padding: 0,
      },
    },
  },

  locationIcon: {
    color: theme.palette.primary.defaultText,
  },
  departureDate: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInputAdornment-positionEnd": {
      width: 25
    }
  },
  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.primary.defaultText,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.lightText,
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.light,
    },
  },
  searchButtonBox: {
    width: "284px",
    height: "65px",
    marginBottom: "10px",
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      borderRadius: "0 4px 4px 0",
      backgroundColor: theme.palette.secondary.darkOrange,
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
        fontSize: "16px",
      },
    },
    "& i": {
      marginRight: "16px",
    },
  },
  BusSection_actions: {},
  BusSection_busDet: {
    "& label": {
      fontSize: 12,
      color: theme.palette.primary.defaultText,
      textTransform: "uppercase",
      fontWeight: 500,
    },
    "& .autocomplete": {
      width: "200px",
      padding: 0,
    },

    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    //  padding: "0 10px",
    "& .date-input": {
      // paddingTop: 8,
      paddingLeft: 10,
      paddingRight: 10,
      "& .MuiSvgIcon-root": {
        // color:theme.palette.secondary.darkOrange,
        color: theme.palette.secondary.darkText,
      },
      [theme.breakpoints.down(960)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    "& .MuiGrid-grid-xs-12": {
      [theme.breakpoints.down(600)]: {
        minWidth: "100%",
      },
    },
  },
  BusSection_quicklinks: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
      boxShadow: "4px 4px 4px #c2d87e",
      padding: "0 10px",
      width: 90,
      color: theme.palette.primary.defaultText,
      borderRadius: 4,
      cursor: "pointer",
      border: `solid 1px ${theme.palette.secondary.lightText}`,
      "&:hover": {
        backgroundColor: theme.palette.secondary.darkOrange,
        color: theme.palette.primary.lightText,
        border: `solid 1px ${theme.palette.secondary.darkOrange}`,
      },
      "& .qlink-icon-wrapper": {
        textAlign: "center",
        "& i": {
          fontSize: 15,
        },
        "& .material-icons": {
          fontSize: 17,
        },
      },
      "& .qlink-label-wrapper": {
        fontSize: 10,
        textAlign: "center",
      },
    },
  },
}));
