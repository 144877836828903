import React, { useState, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import OaFormSelectField from "oahoc/OaFormSelectField";

import { Formik, Form, ErrorMessage } from "formik";

import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";

import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaFormSaveButton from "oahoc/OaFormSaveButton";
import FormControl from "@material-ui/core/FormControl";
import OaFormSelectComboField from "oahoc/OaFormSelectComboField";
import OaAlert from "oahoc/OaAlert";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import OaFormImageButton from "oahoc/OaFormImageButton";

import OaCard from "oahoc/OaCard";
import { AppConstant } from "appConstant";
import { useTheme } from "@material-ui/core";


const RefundForm = (props) => {
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const theme=useTheme()
  const [mobile, setMobile] = useState("");
  const [ackno, setAckNo] = useState("");
  const [referenceno, setRefrenceno] = useState("");
  const [isRefund, setIsRefund] = useState(false);

  const [isAlertPrompt, setIsAlertPrompt] = useState(false);
  const [alertCallbackParam, setAlertCallbackParam] = useState({});
  const [alertAdditionalData, setAlertAdditionalData] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [primaryButtonText, setPrimaryButtonText] = useState("");
  const [secondaryButtonText, setSecondaryButtonText] = useState("");
  const [dialogRef, setDialogRef] = useState("");
  const [hideSecondaryButton, setHideSecondaryButton] = useState(false);

  const [isRefundable, setIsRefundable] = useState(false);

  useEffect(() => {
    if (isLoading) {
      if (props.location.state) {
        setMobile(props.location.state.data.mobile);
        setAckNo(props.location.state.data.ackno);
        setRefrenceno(props.location.state.data.referenceno);

        if (props.location.state.data.txnStatusCode === 5) {
          setIsRefundable(true);
        }
      } else if (props.location.query) {
        setMobile(props.location.query.data.mobile);
        setAckNo(props.location.query.data.ackno);
        setRefrenceno(props.location.query.data.referenceno);

        if (props.location.query.data.txnStatusCode === 5) {
          setIsRefundable(true);
        }
      }
      setIsLoading(false);
    }
    // console.log("isLoading");
    // console.log(isLoading);
  }, [isLoading]);

  function transactionHelper(buttonText, alertCallbackParam, additionalData) {
    // console.log(additionalData);
    setIsAlertPrompt(false);
    if (buttonText == "YES") {
      setIsSaving(false);
      setIsError(false);
      setIsSuccess(false);
    } else if (buttonText == "NO") {
      setIsSaving(false);
      setIsError(false);
      setIsSuccess(false);
    }
  }

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }

  function sendOTP(mobile, ackno, reference) {
    setIsError(false);
    setIsSuccess(false);

    if (!mobile) {
      setAlertTitle(AppConstant.name);
      setHideSecondaryButton(true);
      setAlertCallbackParam(alertCallbackParam);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
      setDialogRef("enterMobile");
      setAlertMsg("Please enter mobile number");
    } else {
      apiCall(
        WebApi.sendDMTRefundOtp,
        { mobile: mobile, ackno: ackno, refernce: reference },
        (response) => {
          setIsError(!response.success);
          setIsSuccess(response.success);

          setIsSaving(false);
          setInfoText(response.message);
          setAlertTitle("Error sending");

          if (response.success && response.data) {
            setAlertTitle("Request processed successfull");
            setIsError(!response.data.success);
            setIsSuccess(response.data.success);
            setInfoText(response.data.message);
          }

          setTimeout(() => {
            setIsSaving(false);
            setIsError(false);
            setIsSuccess(false);
          }, 5000);
        }
      );
    }
  }
  return (
    <>
      <GridContainer>
        <GridItem md={9}>
          <h4
            className={"oa-form-header"}
            onClick={collapse}
            style={{ transform: " scale(0.85)", transformOrigin: "left" }}
          >
            Enter Transaction Details
          </h4>
        </GridItem>
        {/* <GridItem md={3} >
                <FormControl>
                        <OaFormImageButton color="primary" 
                        icon={(<KeyboardReturnIcon style={{fontSize: "24px"}}/>)}  style={{width:200}}
                        onClick={()=>{backToBeneficaryList()}}
                        label={'Beneficiary List'}></OaFormImageButton>
                        </FormControl>
                </GridItem> */}
      </GridContainer>

      <OaAlert
        isPrompt={isAlertPrompt}
        callback={transactionHelper}
        callbackParam={alertCallbackParam}
        msg={alertMsg}
        title={alertTitle}
        additionalData={alertAdditionalData}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        togglePrompt={() => {
          setIsAlertPrompt(!isAlertPrompt);
        }}
      ></OaAlert>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        alertTitle={alertTitle}
      />

      {!isLoading && (
        <Formik
          initialValues={{
            mobile: mobile,
            ackno: ackno,
            reference: referenceno,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(
            searchParams,
            { setSubmitting, resetForm, enableReinitialize }
          ) => {
            setIsError(false);
            setIsSuccess(false);
            setIsSaving(true);

            if (
              !searchParams.reference &&
              (!searchParams.ackno || !searchParams.mobile)
            ) {
              setIsAlertPrompt(true);
              setAlertMsg(
                "Reference or combination of acknowledgement and mobile is required"
              );
              return;
            }

            if (isRefund && !searchParams.otp) {
              setIsAlertPrompt(true);
              setAlertMsg("Please enter OTP");
              return;
            }

            if (isRefund) {
              apiCall(WebApi.dmtRefundTransact, searchParams, (response) => {
                // setIsError(!response.success);
                // setIsSuccess(response.success);
                // setSubmitting(false);
                setIsSaving(false);

                // setInfoText('Please recheck entered data');
                // setAlertTitle('Error refunding');

                if (response.success && response.data) {
                  setIsError(!response.data.status);
                  setIsSuccess(response.data.status);
                  setInfoText(response.data.message);

                  if (response.data.status === true) {
                    // console.log(response.data.data);
                    setIsRefundable(false);
                    setIsRefund(false);
                    setAlertTitle("Refund Successful");
                    setInfoText(response.data.data.message);
                  }
                }
              });
            } else {
              apiCall(WebApi.dmtQueryTransact, searchParams, (response) => {
                setIsSaving(false);
                //     setIsError(true);
                //     setIsSuccess(false);
                //    setInfoText('Please recheck entered data');
                //    setAlertTitle('Error querying');

                // console.log("testingnm....1");
                if (response.success && response.data) {
                  // console.log("testingnm....2");
                  setIsError(!response.data.status);
                  setIsSuccess(response.data.status);
                  setInfoText(response.data.message);

                  if (response.data.status === true) {
                    // console.log(response.data.data);
                    setAlertTitle("Current Status");
                    setInfoText(response.data.data.message);
                  }
                }
              });
              // apiCall(WebApi.dmtQueryTransact, searchParams, (response) => {
              //     setIsError(!response.success);
              //     setIsSuccess(response.success);
              //    // setSubmitting(false);
              //     setIsSaving(false);
              //     console.log('response11');
              //     console.log(response);
              //     setInfoText('Please recheck entered data');
              //     setAlertTitle('Error querying');

              //     if(response.success && response.data){
              //         setIsError(!response.data.status);
              //         setIsSuccess(response.data.status);
              //         setInfoText(response.data.message);

              //         if(response.data.status === true) {
              //             console.log(response.data.data)
              //             setAlertTitle('queried successfully');
              //             setInfoText(response.data.data.message);
              //         }
              //     }

              // });
            }
          }}
        >
          {({
            values,
            isSubmitting,
            setFieldValue,
            enableReinitialize,
            resetForm,
          }) => (
            <Form>
              <GridContainer>
                <GridItem md={4}>
                  <OaFormTextField
                    style={{ backgroundColor: theme.palette.background.brightWhisper }}
                    className="input-landing-page"
                    name="reference"
                    label="Reference Number"
                  />
                  <ErrorMessage
                    name="reference"
                    component="div"
                    className="error"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem md={1} style={{ marginTop: 10 }}>
                  or
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem md={4}>
                  <OaFormTextField
                    style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                    className="input-landing-page"
                    name="mobile"
                    label="Sender Mobile Number"
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="error"
                  />
                </GridItem>
                <GridItem md={4}>
                  <OaFormTextField
                    style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                    className="input-landing-page"
                    name="ackno"
                    label="Acknowledgement Number"
                  />
                  <ErrorMessage
                    name="ackno"
                    component="div"
                    className="error"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer style={{ marginTop: 20, marginBottom: 20 }}>
                <GridItem>
                  <FormControl>
                    <Button
                      className="button-landing-page"
                      color="primary"
                      type="submit"
                      disabled={isSaving}
                      style={{ width: 200 }}
                      onClick={() => setIsRefund(false)}
                    >
                      Query Transaction
                    </Button>
                  </FormControl>
                  {isRefundable && (
                    <FormControl>
                      <Button
                        className="button-landing-page"
                        color="primary"
                        type="button"
                        disabled={isSaving}
                        style={{ width: 200 }}
                        onClick={() => {
                          setIsRefund(true);
                          sendOTP(
                            values.mobile,
                            values.ackno,
                            values.reference
                          );
                        }}
                      >
                        Refund Transaction
                      </Button>
                    </FormControl>
                  )}
                  {isRefund && (
                    <FormControl>
                      <Button
                        className="button-landing-page"
                        color="secondary"
                        type="button"
                        disabled={isSaving}
                        style={{ width: 200 }}
                        onClick={() => sendOTP(values.mobile)}
                      >
                        Re-Send OTP
                      </Button>
                    </FormControl>
                  )}
                </GridItem>
              </GridContainer>

              {isRefund && (
                <GridContainer>
                  <GridItem md={4}>
                    <OaFormTextField
                      style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                      className="input-landing-page"
                      name="otp"
                      label="OTP"
                    />
                    <ErrorMessage
                      name="otp"
                      component="div"
                      className="error"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <Button
                        className="button-landing-page"
                        color="primary"
                        type="submit"
                        disabled={isSaving}
                        style={{ width: 200 }}
                      >
                        Confirm
                      </Button>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              )}
            </Form>
          )}
        </Formik>
      )}
      {/* <Link to={{ pathname: '/admin/moneytransfer/verify-otp', query: { mobile: '8744027274' } }}>
        
                Verify OTP
        </Link> */}
    </>
  );
};

export default RefundForm;
