import React from "react";
import { Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const PrivacyPolicy = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const history = useHistory();

    const goToHomePage = () => {
        history.push("/");
    };

    return (
        <div
            className={classes.root}
            style={{
                marginBottom: 60,
                paddingLeft: isMobile ? 10 : "6%",
                paddingRight: isMobile && 10,
            }}
        >
            <Grid
                container
                spacing={isMobile ? 0 : 3}
                style={{ width: "100%" }}
                justifyContent="center"
            >
                {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}

                <Grid item xs={12} md={10}>
                    <Grid
                        container
                        spacing={1}
                        className={`${classes.firstCard} ${classes.Grid}`}
                    >
                        <Grid item md={12}>
                            <Typography
                                variant="h3"
                                className={classes.sectionHeader}
                                gutterBottom
                            >
                                PRIVACY POLICY:
                            </Typography>
                            <hr style={{ width: "100%" }} />
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Lookmyticket Privacy Policy
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We appreciate the confidence you place in us and understand how crucial safe transactions and information privacy are. This privacy statement explains how Lookmyticket Tours and Travels Private Limited, its affiliates, and group companies that offer travel-related services and leisure activities (collectively referred to as "Services," "Lookmyticket Tours and Travels," "us," or "we"), collect, use, share, and otherwise process your personal information through the websites www.lookmyticket.com, its sub-domains, mobile applications, and m-sites (hereinafter, collectively referred to as "Booking Platform.").
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You expressly agree to be governed by the terms and circumstances of this Privacy Policy, the Terms of Use, and any relevant Services' terms and conditions by accessing the Booking Platform, giving your information there, or using our services made available there. Your personal information will be handled and kept mostly in India, which may have different data protection legislation than the nation in where you are now based. You agree to the transfer of information outside of your country of residency by using the Booking Platform and our Services. Please do not use or use our Booking Platform or Services if you do not agree to the Conditions of Use or the terms of this Privacy Policy.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We advise you to carefully read this Policy and to frequently check this page to examine any updates we may make.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Collection
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                If you use travel services, for example, we may obtain your personal information from you directly. We may also obtain your personal information when you make it publicly available, such as when you post about us publicly on social media, or when you download, install, or use any of our Services. Additionally, we could get data about you from other sources (e.g., our travel partners). We may also develop information about you, such as recordings of our contacts with you, or we may combine information you have given us with information we have acquired from other sources, such as social media sites and other organizations that gather data. Any personally identifiable information you voluntarily disclose online is not the responsibility of Lookmyticket Tours and Travels.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The actions of third parties who may give Lookmyticket Tours and Travels information about you are not covered by this privacy statement. Among the data we could gather include, but are not restricted to:
                            </Typography>
                            
                            <ul className={classes.listText}>
                                <li>Information you give us when you join up, register, or use our booking platform, such as your user name or login information, email address, or phone number (s)</li>
                                <li>Booking details, such as your trip details, Trip ID, PNR details, reservations, fellow passengers, and travel preferences, are included.</li>
                                <li>demographic data, including gender, nationality, age, and birth date</li>
                            </ul>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Position data that identifies the exact location of your device in space
                            </Typography>

                            <ul className={classes.listText}>
                                <li>Information on purchases and payments, such as records of the costs and purchases of travel services, invoice records, payment records, payment methods, names of cardholders or account holders, payment amounts, and payment dates</li>
                                <li>Data, such as picture files, documents, etc., that you want to keep on our servers and that was either made by you or by a third party.</li>
                                <li>Information on any other travelers you have booked tickets for using the Booking Platform, including any of your fellow travelers. In this situation, you must affirm and guarantee that each co-passenger or traveler for whom a reservation has been made has given their consent for the information you submit with us to be shared with the relevant service provider (s)</li>
                                <li>Some sensitive personal information, such as your health information, Covid 19 vaccination status, certificate, or other similar information that is strictly necessary to ensure safety from the spread of communicable diseases as required by local regulatory requirements, may be processed by us if you wish to avail of any services that require us to process the certificate/vaccination status.</li>
                                <li>The kind of device you are using, your I.P address, your internet service provider, your browsing history, your device identity, your mobile operator, your WI-FI connection, your service set identifier (or "SSID"), your international mobile subscriber identity (or "IMSI"), and your international mobile equipment identity, among other things, are all additional information that you may share with us or that may be collected from you while you visit, access, or use our booking platform or contact us for query resolution.</li>
                            </ul>

                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We will gather and store your personal information, such as your name, contact information, email address, communication address, date of birth, gender, zip code, lifestyle details, demographic information, and employment information, if you sign up for our loyalty programme or participate in one of our third-party loyalty programmes. This information is provided to us by you or a third-party business partner that runs platforms where you can earn loyalty points for purchasing services or offerings, and/or a third-party business partner. Additionally, we will compile data on your interactions with the Booking Platform and other platforms used by our business partners. You will be subject to their privacy rules when a third-party business partner gets your personal information directly from you. We ask that you read their privacy policies before providing any information since we will not be held liable for their privacy practices or the information included in them.
                            </Typography>

                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Generally, you are not required to identify yourself or provide any personal information in order to explore the Booking Platform. You are no longer anonymous to us after you provide us your personal information. We try to make it clear which fields are necessary and which ones are optional. By electing not to utilize a certain Booking Platform service or feature, you may always select not to share information. Our main objective in gathering your personal data is to give you a secure, effective, seamless, and personalized experience. This enables us to tailor our Booking Platform to fit your specific demands and give services and features that are most likely to satisfy them. Additionally, we will only use the data we gather for legitimate, contractual, commercial, and legal purposes.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We kindly ask you not to ever submit critical personal information, such as your debit/credit card PIN, net-banking password, or mobile banking password, if you get an email or a call purporting to be from Lookmyticket Tours and Travels. At no point does Lookmyticket Tours and Travels or our partners get in touch with you asking for such information. If you've already shared such information, notify the relevant law enforcement agency right away.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Use
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                To fulfill your request for Services, we utilize the personal data you give. Among the uses for which we put your data are:
                            </Typography>

                            <ul className={classes.listText}>
                                <li>Create a user account for you, handle and confirm your reservation, and communicate with you about the services you've asked for in order to provide you with the required services (e.g., query resolution, regarding the feedback, etc.)</li>
                                <li>Analyze your preferences for travel or reservations and provide coupons, discounts, and other incentives.</li>
                                <li>Use emails, text messages, or instant messaging apps like WhatsApp and Telegram to transmit any updates to your transactions, bookings, or other arrangements.</li>
                                <li>Make sure of your identity by sending confirmation emails.</li>
                                <li>Send you marketing messages about goods and services that could interest you</li>
                                <li>Check voice systems for quality and monitoring needs, or to raise our degree of security.</li>
                                <li>Supply you with information about our Services on our booking platform or conduct surveys.</li>
                                <li>Maintenance and enhancement of the booking platform, customization of the customer experience, and internal training</li>
                                <li>Safeguard your data and the booking platform.</li>
                                <li>Manage our IT systems, including detecting and reducing fraudulent behavior, adhering to the law, and enhancing our Services.</li>
                                <li>Exercising a legal right or responsibility, including complying with requests and legal requirements,</li>
                            </ul>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Wherever necessary, we will request your consent before gaining access to your SMS, contacts in your address book, camera, photo gallery, location, and device information: In order to I improve your platform experience and (ii) give you access to the goods and services offered on the Booking Platform, we may send you communications regarding your orders or other goods and services. You are aware that if permission is not given to us, it can impair your access to the Services. On occasion, we may invite you to participate in surveys run by us or a separate market research firm. Your personal information, contact information, gender, date of birth, demographic data (such as your pin code, age, or income level), interests, details about your home or lifestyle, and other information of this nature may be requested in these surveys. The survey may entail the gathering of audio or video data. The only need for taking part in these surveys is your own volition. We use this information to personalize your visit to our booking platform, showing you stuff we believe you'll find interesting and displaying content in accordance with your choices. You give us permission to process your personal information by giving it to us or by taking part in such surveys.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Cookies
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                On some sites of the Booking Platform, we employ data collecting tools like "cookies" to gather information about user behavior, track the success of our marketing campaigns, and foster a sense of security and confidence. Cookies are little files that are downloaded to your computer's hard disc and help us provide our services. We maintain separate records for page visits, clicks, and search keywords used for ad personalization targeting. Although we cannot individually identify you through the use of these cookies, the third parties who offer them might be able to. We provide some services that can only be accessed by using a "cookie." Additionally, we employ cookies so that you have to input your password less frequently while logged in. Additionally, cookies might assist us in delivering content that is customized to your interests. The majority of cookies are "session cookies," which means that they are removed from your hard drive at the conclusion of a session. If your browser allows, you are always free to refuse or delete our cookies, albeit doing so may prevent you from using some services of the Booking Platform and force you to input your password more frequently when logged in. Additionally, third-party "cookies" or other similar devices may be included on some pages of the Booking Platform.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We have no control over how third parties utilize cookies. For marketing and analytical purposes, we employ third-party partner cookies from companies like Google Analytics. We can better understand how visitors utilize the site thanks to Google Analytics. Visit <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a> to learn more about how Google uses your personal data. Here is a link where you may choose not to use Google Analytics. The usage of cookies can be restricted at the browser level, but doing so may restrict your ability to utilize some features or functionalities on the Services.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                For more information, including a detailed explanation of what cookies are, the many categories of cookies used on the Services, and instructions on how to modify or remove them, please read our Cookie Policy.
                            </Typography>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Please see our Cookie Policy for more information
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Legal justification for handling your personal data
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We could use these places to process your personal data:
                            </Typography>
                            <Gap10/>
                            <ul className={classes.listText}>
                                <li>You've provided your approval (this legal basis is only used in relation to processing that is entirely voluntary, it is not used for processing that is necessary for meeting our obligations, required under applicable law or for legitimate interests)</li>
                                <li>Processing is required to fulfill a legal obligation or serve our legitimate interests.</li>
                                <li>The relevant law requires processing.</li>
                                <li>Processing is required for immediate action, such as in situations involving medical crises, natural catastrophes, and breaches of law and order. Processing is also required for fair reasons, such whistleblowers, mergers and acquisitions, etc.</li>
                                <li>Processing is required when it's required by law or when it's essential to protect our legitimate interests.</li>
                            </ul>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Sharing
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                To give you access to their services and products, we could share or disclose your personal information within our group companies, with our affiliates, or with other third parties. Unless you specifically opt out, these organizations and their affiliates may market to you as a result of such sharing.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Here are several situations where we could
                            </Typography>
                            <Gap10/>
                            <ul className={classes.listText}>
                                <li>Together with any pertinent third-party service providers, such as an airline, hotel, or activity service provider, who offer the final services and may get in touch with you further regarding the services you've ordered. Before providing them with any information, you should study their Privacy Policy and Terms of Use to see how they handle your personal information.</li>
                                <li>With our data processors, such as cloud service providers, payment gateways, customer service providers, survey distributors, or any other third party, who act as our agents in providing storage and other related services to you on our behalf, in order to prevent, detect, mitigate, and investigate fraudulent or illegal activities connected to our Services or to prosecute criminal offences or carry out criminal penalties, including preventing and detecting such activities.</li>
                                <li>When you use our Activities, we may, with your permission, communicate with third-party advertising service providers for purposes of online behavioral advertising or otherwise and carry out associated services. These third-party advertising organizations frequently utilize cookies and other technologies to gauge how effective websites and applications are as well as to comprehend user booking behavior. We also work with third-party sources to help segment this data.</li>
                                <li>With independent professional advisers (such accountants, auditors, or attorneys), subject to legally obligatory secrecy requirements.</li>
                                <li>With the firms in our group or affiliates that require access to or knowledge of such information in connection with the provision of Services.</li>
                                <li>If obliged to do so by law or in the good faith belief that such disclosure is reasonably necessary to response to subpoenas, court orders, or other legal process, with government agencies or other authorized law enforcement agencies.</li>
                                <li>In the event that we sell or otherwise transfer all or any relevant portion of our company or assets to any relevant third party acquirer(s) (including in the event of a reorganization, dissolution or liquidation). In the event that such a transaction takes place, the other company (or the new merged entity) will be obligated to abide by this privacy policy with regard to your personal data.</li>
                                <li>When we share your personal information with them for processing, we adhere to the necessary security precautions to keep it safe. Additionally, where relevant, we obligate these third parties with the proper contracts and demand that they only use your personal information for those purposes, keep it secure, and take the necessary security precautions.</li>
                                <li>You give us permission to pass the information we collect back to the partner, which may include third-party service providers whose services are embedded into and/or appear within the Services, when you use a co-branded service (a service run with a partner of Lookmyticket Tours and Travels), register on or otherwise provide information on a co-branded site, etc.</li>
                                <li>We may provide our partners with information about you, including your name, email address, and other identifiers. In addition, our partners may: I gather data directly from your device, including your IP address, device ID, advertising ID, and details about your browser or operating system; (ii) combine user data about you obtained from Lookmyticket Tours and Travels with data about you obtained from other websites or services; and (iii) set or recognize a special cookie on your browser.</li>
                            </ul>
                            <Gap10/>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Here are several situations where we could
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Links to other sites
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                This privacy statement only applies to the Booking Platform. Links to other websites may be available on our booking platform. Please consult the privacy policies of any other third-party websites or applications you use to see how they handle your personal data.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Security Measures
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We have implemented appropriate security methods and processes in accordance with industry standards to protect your personal information from loss, misuse, and unauthorized access or disclosure. We provide the usage of a secure server whenever you modify or access the information associated with your account. Information communication is not entirely secure for reasons beyond of our control. In order to guard against hacking and the spread of viruses, we implement industry standard security measures and regularly update our systems. However, users of the Booking Platform acknowledge that data transmission over the internet and the World Wide Web cannot always be guaranteed to be 100% secure. As a result, there will always be certain inherent risks associated with using the Booking Platform. Users are in charge of protecting their account's login and password information. Any security breach that occurs on a third-party platform that you choose to use for your transactions is not our responsibility.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Use of data involving children
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Only those who are eligible to enter into a binding contract under the Indian Contract Act of 1872 are permitted to use our booking platform. Children under the age of 18 are not subject to our knowing solicitations or collection of their personal information. You warrant that you have the right to do so and that you have given us permission to use any personal information on children under the age of 18 that you have provided with us in line with this Privacy Policy.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Retaining Data
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We save your personal data for no longer than is necessary to fulfill the purposes for which it was obtained or as mandated by any relevant laws. Under some circumstances, Lookmyticket Tours and Travels retains online data connected to online behavioral advertising for no longer than 365 days before it expires. If the same user later views or interacts with an advertisement, email, the services, or a channel, the 365-day window may start over. If there is a legal requirement for the retention of the data, if we are required by law to comply with any applicable statutory or regulatory retention requirements, if we believe it may be required to stop fraud or future abuse, to allow Lookmyticket Tours and Travels to defend itself against legal claims, to exercise its legal rights, or for any other lawful reason, we may keep your personal data. Your information could still be kept by us in anonymised form for analytical and research reasons.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Your Rights
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We make every effort to ensure that the personal information you provide to us is accurate and, if needed, maintained up to date, and that any information that you advise us is erroneous (in light of the reasons for which it is processed) is either deleted or corrected. Through the features offered on the booking platform, you may easily view, modify, and update your personal information. By entering into our website and viewing the profile and settings areas, you can remove certain non-essential information or cancel your user account. Alternatively, you may email us at <b>info@lookmyticket.com</b> Tours and Travels.com if you need our help with these requests.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Consent
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You consent to the collection, use, storage, disclosure, and other processing of your information (including sensitive personal information) on the Booking Platform in accordance with this Privacy Policy by using our Booking Platform or by supplying your information there. When you give us someone else's personal information or make a reservation on their behalf, you warrant that you have their consent to do so and that we may use and disclose such information in line with our privacy statement.
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You grant us (and our other corporate entities, affiliates, partners, technology partners, marketing channels, business partners, and other third parties) permission to contact you via SMS, instant messaging apps, call, and/or email You have the choice to revoke your previously given consent by sending us a letter at <b>info@lookmyticket.com</b>. In the subject line of your letter, please include "for withdrawal of consent." Before responding to such requests, we shall verify them. Please be aware, though, that revoking consent will not be effective in the past and will only be permitted under the conditions outlined in this privacy statement, any relevant terms of use, and legal requirements. If you revoke the consent you have granted us under this privacy policy, it could be difficult for you to use the booking platform or we might be unable to provide the services we believe need such information.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Choice/Opt-Out
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                After creating an account, we provide all users the option to choose whether or not they want to receive non-essential (promotional, marketing-related) emails from us on behalf of our partners. Please unsubscribe from our promotional messages by clicking the unsubscribe link in the email or contacting us in writing at <b>info@lookmyticket.com</b> if you do not want to receive them.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Alterations to This Privacy Statement
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Please review our Privacy Policy often for updates. In order to reflect changes to our information practices, we may amend this privacy statement. When obliged to do so by applicable law, we will notify you of important changes by publishing the date our Privacy Policy was last amended, posting a notice on our Booking Platform, or sending you an email. You will be assumed to have accepted the Privacy Policy if you continue to access or use any of the Services.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Contact us
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Please email us at <b>info@lookmyticket.com</b> if you have any questions, concerns, or complaints about how your personal information is collected or used in accordance with this privacy statement.
                            </Typography>
                        </Grid>

                        {/* <Grid
                            item
                            md={12}
                            className={classes.sectionTextGrid}
                            style={{ textAlign: "center" }}
                        >
                            <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                                <i className="fa fa-home"></i> Home
                            </a>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default PrivacyPolicy;

const Gap10 = () => {
    return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .highlight": {
            color: theme.palette.primary.darkOrange,
        },
    },
    Grid: {
        background: theme.palette.background.default,
        marginTop: "20px",
        boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
        padding: 30,
        borderRadius: 20,
    },
    sectionHeader: {
        color: `${theme.palette.primary.darkFadedText} !important`,
        fontWeight: 500,
        margin: "5px 0 30px",
    },
    sectionSubhead: {
        color: `${theme.palette.primary.defaultText} !important`,
        fontWeight: 400,
    },
    sectionText: {
        color: `${theme.palette.primary.davyGray} !important`,
        fontWeight: 400,
        lineHeight: "27px",
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.davyGray,
            fontWeight: 400,
            lineHeight: "27px",
        },
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.darkFadedText,
            fontWeight: 400,
            lineHeight: "27px",
        }
    },
    breadcrumbLink: {
        color: `${theme.palette.primary.active} !important`,
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.primary.pomegranate} !important`,
        },
    },
}));
