import React from 'react'
import { apiCallByApiName } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { Button } from '@material-ui/core';
import download from 'downloadjs';

const OaButtonXls = (props) => {

    function downloadXLS(){
        apiCallByApiName(WebApi.doDownload, props.api, props.searchParams, (response) => {
            const fileName = response.headers["content-disposition"].split('=')[1];
            let url = window.URL.createObjectURL(response.data);
					let a = document.createElement('a');
					a.href = url;
					a.download = fileName;
					a.click();
             //download(response,  fileName, "application/vnd.ms-excel")
        });
    }

    return (
        <>
            <Button variant="contained" size="small" onClick={()=>{downloadXLS()}}>XLS</Button>
        </>
    )
}

export default OaButtonXls;
