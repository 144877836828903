import React from "react";
import dateFnsFormat from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme} from "@material-ui/core";

export const FlightShareDetailView = (props) => {

  const useStyles = makeStyles((theme) => ({
    f12b: {
      fontSize: "12px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      }
    }
  }));

  const classes = useStyles();
  const theme = useTheme();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [shareOnwOpen, setShareOnwOpen] = React.useState(false);

  function getEmailText() {
    let shareText = "";
    if (props.segment && props.segment[0]) {
      let currentSegment = props.segment[0];
      let lastSegment = props.segment[props.segment.length - 1];

      let departAirport = currentSegment.departDetails.airportCode;
      let depDateRaw = new Date(currentSegment.departDetails.dateTime);
      let departDate = dateFnsFormat(depDateRaw, "dd MMM");
      let arrivalAirport = lastSegment.arriveDetails.airportCode;

      shareText += "mailto:?subject=Flight Details " +
      departAirport +
      " to " +
      arrivalAirport +
      " " +
      departDate +
      "&body=" +
      getShareText()
      
    }
    return shareText;
  }

  function getShareText() {
    let shareText = "";
    if (props.segment && props.segment[0]) {
      let segments = props.segment;
      let currentSegment = segments[0];
      let lastSegment = segments[segments.length - 1];
      let stops = segments.length - 1;

      let airlineName = currentSegment.carrierDetails.carrierName;
      let flightCode = currentSegment.carrierDetails.carrierCode;
      let airlineCode = flightCode + " " + currentSegment.carrierDetails.flightNumber;

      let departAirport = currentSegment.departDetails.airportCode;
      let depDateRaw = new Date(currentSegment.departDetails.dateTime);
      let departDate = dateFnsFormat(depDateRaw, "dd MMM");
      let departTime = dateFnsFormat(depDateRaw, "HH:mm");
      let departDay = days[depDateRaw.getDay()];

      let arrDateRaw = new Date(lastSegment.arriveDetails.dateTime);
      let arrivalDay = days[arrDateRaw.getDay()];
      let arrivalDate = dateFnsFormat(arrDateRaw, "dd MMM");
      let arrivalTime = dateFnsFormat(arrDateRaw, "HH:mm");
      let arrivalAirport = lastSegment.arriveDetails.airportCode;

      let totalJourneyTime = props.journeyTime;
      let journeyHours = Math.floor(totalJourneyTime / 60);
      let journeyMins = Math.floor(totalJourneyTime % 60);


      shareText = airlineName + " " + airlineCode + "; ";
      shareText +=
        departAirport +
        " to " +
        arrivalAirport +
        "; Departure: " +
        departDate +
        ", " +
        departDay +
        " " +
        departTime +
        "; ";
      shareText +=
        "Arrival: " + arrivalDate + ", " + arrivalDay + " " + arrivalTime + "; ";
      shareText +=
        "Journey Time: " +
        journeyHours +
        " hr " +
        journeyMins +
        " min; " +
        (stops > 0
          ? " " + stops + (stops > 1 ? " stops; " : "stop; ")
          : " non-stop; ");

      if (stops > 0) {
        props.segment &&
          props.segment.map((value, indxxx) => {
            if (indxxx > 0) {
              shareText +=
                "layover " + Math.floor(value.layover / (24 * 60)) > 0
                  ? Math.floor(value.layover / (24 * 60)) +
                  "day" +
                  (Math.floor(value.layover / (24 * 60)) > 1 ? "s " : " ")
                  : "";
              shareText +=
                Math.floor(value.layover / 60) +
                " hrs " +
                Math.floor(value.layover % 60) +
                " min layover at " +
                value.departDetails.airportName +
                " (" +
                value.departDetails.airportCode +
                ") ";
            }
          });
      }
    }
    return shareText;
  }

  const shareViaWhatsapp = () => {
    setShareOnwOpen(false);
    const anch = document.createElement("a");
    anch.href = "https://api.whatsapp.com/send?text=" + getShareText();
    anch.dataAction = "share/whatsapp/share";
    anch.target = "_blank";
    anch.click();
  };

  const shareViaEmail = () => {
    setShareOnwOpen(false);
    const anch = document.createElement("a");
    anch.href = getEmailText();
    anch.target = "_blank";
    anch.click();
  };


  return (
    <div className="share-div">
      <div
        className="share-dropdown-toggle-button"
        onClick={() => setShareOnwOpen(!shareOnwOpen)}>
        <span className={classes.f12b}>
          Share <i className="fas fa-share"></i>
        </span>
      </div>
      <div
        className="share-dropdown"
        style={{
          display:
            shareOnwOpen && shareOnwOpen == true ? "flex" : "none",
        }}>
        <span
          className="share-button"
          onClick={() => shareViaWhatsapp()}>
          <i className="fab fa-whatsapp"></i>
        </span>
        <span
          className="share-button"
          onClick={() => shareViaEmail()}>
          <i className="fas fa-envelope"></i>
        </span>
      </div>
    </div>
  )
}