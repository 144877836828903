import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import dateFnsFormat from "date-fns/format";

import {
  Grid,
  Box,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import FlightModify from "./FlightModify";

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      //backgroundColor: theme.palette.secondary.main,
      background: theme.palette.buttons.tertiary,
      width: "100%",
      maxWidth: "1440px !important",
      
      height: "80px",
      // padding: "16px 0",
      color: theme.palette.primary.lightText,
      position: "sticky",
      top: "60px",
      zIndex: "100",
      "& .MuiFormControlLabel-label": {
        color: theme.palette.primary.lightText,
      },
    },
    rootMod: {
      backgroundColor: theme.palette.primary.main,
      //theme.palette.secondary.main,
      
      width: "100%",
      height: "auto",
      padding: "16px 60px",
      color: theme.palette.primary.lightText,
      position: "sticky",
      top: "0",
      zIndex: "102",
      marginBottom:'13px',
      [theme.breakpoints.down(1400)]: {
        padding: "12px",
      },
    },
    flightSearchElements: {
      margin: "auto",
      maxWidth: "1200px",
      padding: "16px",
      [theme.breakpoints.down(600)]: {
        padding: "4px",
      },
    },
    flightSearchElement_title: {
      height: "16px",
      marginBottom: "8px",
      "& span": {
        fontSize: "12px",
        fontWeight: "600",
        [theme.breakpoints.down(1080)]: {
          fontSize: "8px",
        },
      },
    },
    flightSearchElement_value: {
      height: "24px",
      "& span": {
        fontSize: "20px",
        fontWeight: "400",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(600)]: {
          fontSize: "12px",
        },
      },
      "& .MuiInputBase-root": {
        color:theme.palette.primary.lightText,
        "& .MuiInputBase-input": {
          fontSize: "20px",
          fontWeight: "400",
          [theme.breakpoints.down(1080)]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down(600)]: {
            fontSize: "12px",
          },
        },
      },
    },

    modifyButton: {
      [theme.breakpoints.down(1620)]: {
        display: "none",
      },
    },

    flightSearchCTA: {
      height: "40px",
      width: "160px",
      backgroundColor: theme.palette.primary.lightText,
      borderRadius: "4px",
      cursor: "pointer",
      display: "grid",
      placeItems: "center",

      "& span": {
        width: "100%",
        fontSize: "20px",
        fontWeight: "600",
        color: theme.palette.primary.defaultText,
        textAlign: "center",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(600)]: {
          fontSize: "12px",
        },
      },
    },
  };
});

const FlightSearchHeaderMultiCity = (props) => {
  const classes = useStyles();

  const [isModifying, setIsModifying] = useState(false);
  const [currSec, setCurrSec] = useState(0);

  const isBreakpoint = useMediaQuery('(max-width:1619px)');

  const updateModify = () => {
    // UPDATE STATE
    setIsModifying(false);
  };
  const cancelModify = () => {
    setIsModifying(false);
    props.isCancelModify(true);
  };

  useEffect(() => {
    setIsModifying(props.isModifyingVal);
  }, [props.isModifyingVal]);

  return isModifying ? (
    <div className={classes.rootMod}>
      <FlightModify
        multiCity={true}
        cancelModify={cancelModify}
        updateModify={updateModify}
      />
    </div>
  ) : (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.flightSearchElements}
      >
        <Grid item style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <IconButton
                size="medium"
                color="inherit"
                onClick={() => setCurrSec((currSec + 2) % 3)}
              >
                <ChevronLeftIcon fontSize="medium" color="inherit" />
              </IconButton>
            </div>
            <>
              {flightSearchHeaderMultCityDetails[currSec].map((e, i) => (
                <div
                  className={classes.flightSearchElement}
                  onClick={() => setIsModifying(!isModifying)}
                >
                  <div className={classes.flightSearchElement_title}>
                    <span>{e.title}</span>
                  </div>
                  <div className={classes.flightSearchElement_value}>
                    <span>{e.value}</span>
                  </div>
                </div>
              ))}
            </>
            <div>
              <IconButton size="medium" color="inherit">
                <ChevronRightIcon
                  fontSize="medium"
                  color="inherit"
                  onClick={() => setCurrSec((currSec + 1) % 3)}
                />
              </IconButton>
            </div>
            <div
              onClick={() => setIsModifying(!isModifying)}
              className={classes.modifyButton}
            >
              <div className={classes.flightSearchCTA}>
                <span>Modify</span>
              </div>
            </div>
          </div>
          {/* <Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box>asd</Box>
              <Box>asd</Box>
              <Box>asd</Box>
              <Grid item>
                <ChevronLeftIcon />
              </Grid>

              {flightSearchHeaderMultCityDetails[currSec].map((e, i) => (
                <Grid
                  item
                  className={classes.flightSearchElement}
                  onClick={() => setIsModifying(!isModifying)}
                >
                  <div className={classes.flightSearchElement_title}>
                    <span>{e.title}</span>
                  </div>
                  <div className={classes.flightSearchElement_value}>
                    <span>{e.value}</span>
                  </div>
                </Grid>
              ))}

              <Grid item>
                <ChevronRightIcon />
              </Grid> 
            </Grid>
          </Box> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default FlightSearchHeaderMultiCity;

const flightSearchHeaderMultCityDetails = [
  [
    { title: "From", value: "Bangalore (BLR)" },
    { title: "To", value: "Chennai (MAA)" },
    { title: "Depart", value: "Mon, 01 Dec 2021" },
  ],
  [
    { title: "From", value: "Hyderabad (HYD)" },
    { title: "To", value: "Mumbai (BMB)" },
    { title: "Depart", value: "Mon, 02 Dec 2021" },
  ],
  [
    { title: "From", value: "Kolkata (KKR)" },
    { title: "To", value: "Delhi (DLH)" },
    { title: "Depart", value: "Mon, 03 Dec 2021" },
  ],
];
