import React, { useState, useEffect } from 'react';

import AgentListSearchForm from './AgentListSearchForm';
import AgentListSearchResult from './AgentListSearchResult';
import OaCard from 'oahoc/OaCard';


const AgentList = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useState({'agentStatus': 1});
    const [tableKey, setTableKey] = useState(0);

    function agencyListLoader(params) {
        let agentStatus = props.currentTab === 'inactive' ? 0 : 1;
        setSearchParams({...searchParams, ...params, 'agentStatus': agentStatus});
        setTableKey(tableKey + 1);
    }

    useEffect(() => {
        let agentStatus = props.currentTab === 'inactive' ? 0 : 1;
        setSearchParams({...searchParams, 'agentStatus': agentStatus});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props.currentTab]);

    return (
        <>
            {!isLoading &&
                <>
                    <OaCard>
                        <AgentListSearchForm target={agencyListLoader} />
                    </OaCard>
                    <OaCard>
                        <AgentListSearchResult key={tableKey} searchParams={searchParams} />
                    </OaCard>
                </>
            }
            
        </>
    )
}

export default AgentList;