import React, { useState, useEffect } from 'react';
import {withRouter} from 'react-router-dom';
// import image1 from 'assets/img/geebaku-loading.gif';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import dateFnsFormat from 'date-fns/format';
import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';
import {Divider, Grid, Button} from '@material-ui/core'

const BusPriceChanged = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
   
    const [isLoading, setIsLoading] = useState(true);
   

    // useEffect(() => {
    //   }, [isLoading]);

    const noOnClick = () =>{
        props.history.push(
            {
              pathname : '/buses',
            });
    }

    const yesOnClick = () =>{
        props.history.push(
            {
              pathname : '/bus/bus-ticket',
            });
    }


    return (
        <GridContainer style={{height:'100vh'}}>
            {/* <GridItem md={12} style={{ height: "50vh", width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-end", "backgroundColor": "transparent" }}>
                <img  src={image1}  width="200"/>
            </GridItem> */}
            <GridItem md={12} style={{ height: "60%", width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-start", "backgroundColor": "transparent" }}>
                <GridContainer> 
                    <GridItem md={12}>
                        <div style={{ fontSize:'20px',marginBottom:'0.8rem',marginTop:'6rem', width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-end", "backgroundColor": "transparent", fontWeight:'bold'}} > 
                            The fares for the Bus(s) you have selected has changed. Please check the fares and continue.
                        </div>
                        <Divider style={{marginBottom:'2rem'}}/>
                        <GridContainer>
                            <GridItem md={2}>
                                <div style={{fontWeight:'bold',fontSize:'16px', marginBottom:'4%'}}><u>Bus Travells</u></div>
                                <div style={{fontWeight:'450'}}>Poppin Travells</div>
                            </GridItem>
                            <GridItem md={1}>
                                <div style={{fontWeight:'bold',fontSize:'16px'}}><u>Departure</u></div>
                                <div style={{fontWeight:'450', marginTop:'11%'}}>12:20</div>
                            </GridItem>
                            <GridItem md={1}>
                                <div style={{fontWeight:'bold',fontSize:'16px'}}><u>Arrival</u></div>
                                <div style={{fontWeight:'450', marginTop:'11%'}}>13:25</div>
                            </GridItem>
                            <GridItem md={2}>
                                <div style={{fontWeight:'bold',fontSize:'16px', marginBottom:'4%'}}><u>Date</u></div>
                                <div style={{fontWeight:'450'}}>26/11/2020</div>
                            </GridItem>
                            <GridItem md={2}>
                                <div style={{fontWeight:'bold',fontSize:'16px', marginBottom:'4%'}}><u>Origin</u>n</div>
                                <div style={{fontWeight:'450'}}>Bangalore</div>
                            </GridItem>     
                            <GridItem md={2}>
                                <div style={{fontWeight:'bold',fontSize:'16px', marginBottom:'4%'}}><u>Destination</u></div>
                                <div style={{fontWeight:'450'}}>Hyderabad</div>
                            </GridItem>
                            <GridItem md={2}>
                                <div style={{fontWeight:'bold',fontSize:'16px', marginBottom:'3%'}}><u>Fare + Tax (Rs.)</u></div>
                                <div style={{fontWeight:'450'}}>
                                    <Button style={{padding:0, background:'#ececec'}}>1150.00</Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <Divider style={{marginBottom:'1rem',marginTop:'2rem'}}/>
                        <div style={{marginBottom:'0.2rem',marginTop:'1.5rem', width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-end", "backgroundColor": "transparent", fontWeight:'400', fontSize:'16px'}} > 
                            Actual Fare: Rs. 1150.00 (Rs. 1000.00 + Rs. 150.00 (Fees and Taxes))
                        </div>
                        <div style={{width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-end", "backgroundColor": "transparent"}} > 
                            <Button style={{ padding: '3px 15px', background:'#ececec'}}>New Fare : Rs. 1090.00</Button>
                        </div>
                        <Divider style={{marginBottom:'0.2rem',marginTop:'1rem'}}/>
                        <div style={{ fontSize:'20px',marginBottom:'0.8rem',marginTop:'1rem', width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-end", "backgroundColor": "transparent", fontWeight:'bold'}} > 
                            Do you want to continue booking?
                        </div>
                        <div style={{width: "100%", "display": "flex", "justifyContent": "center", "alignItems": "flex-end", "backgroundColor": "transparent"}} > 
                            <Button variant="contained" color="primary" onClick={yesOnClick}>Yes</Button>
                            <Button style={{ background:'#ececec', marginLeft:'1rem'}} onClick={noOnClick}>No</Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )
}

export default withRouter(BusPriceChanged);
