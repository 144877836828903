import React from "react";
import PassengerCount from "./PassengerCounter";
import WebApi from "api/ApiConstants";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import swiperIcon from "assets/lookMyTicket/images/icons/swipe_icon.svg";
import OaPopperAutocomplete from "oahoc/OaPopperAutocomplete";
import OaPopperDatepicker from "oahoc/OaPopperDatepicker";
import FLightMultiCitySection from "./FlightMultiCitySection";
import { ErrorMessage } from "formik";
import {
  Box,
  Grid,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  FormControl,
  Button,
  Popper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";

const FlightItinSection = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = React.useState(true);
  const [domesticCarriers, setDomesticCarriers] = React.useState([]);
  const [internationalCarriers, setInternationalCarriers] = React.useState([]);
  const [fromCountry, setFromCountry] = React.useState("");
  const [toCountry, setToCountry] = React.useState("");
  const [carrier, setCarrier] = React.useState(domesticCarriers);
  const [dropdownTraveler, setDropdownTraveler] = React.useState(false);
  const [airportList, setAirportList] = React.useState(props.popularcities);
  const [fetchingAirports, setFetchingAirports] = React.useState(false);
  let totalTravelers = 0;

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      props.formikref.current.setFieldValue(
        "adultPax",
        Number(props.formikref.current.values.adultPax) + val
      );
    }
    if (type === "child") {
      props.formikref.current.setFieldValue(
        "childPax",
        Number(props.formikref.current.values.childPax) + val
      );
    }
    if (type === "infant") {
      props.formikref.current.setFieldValue(
        "infantPax",
        Number(props.formikref.current.values.infantPax) + val
      );
    }
  };

  const getAirlineData = () => {
    WebApi.getAirports(
      {},
      (response) => {
        if (response.success === true) {
          setDomesticCarriers(response.data.domCarriers);
          setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          props.setiserror(true);
        }
      },
      (error) => console.log("getAirports error", error)
    );
  };

  const fetchAirports = (searchParams) => {
    setFetchingAirports(true);
    if (searchParams.length >= 3) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams.trim(),
        },
        (response) => {
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        },
        (error) => console.log("fetchAirportList error", error)
      );
    }
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const renderOptionsComp = (optionsData, propes) => {
    return (
      <div selected={propes.selected} className="ac-options-list">
        <span>
          <p className="ac-option-left">
            {optionsData.cityName} ({optionsData.airportCode})
          </p>
          <p className="ac-option-right">{optionsData.countryCode}</p>
        </span>
        <p>{optionsData.airportDesc}</p>
      </div>
    );
  };

  const pushBtn = (array, index) => {
    const initObj = {
      // destination: props.popularcities[2].airportCode,
      // destinationObj: props.popularcities[2],
      // origin: props.popularcities[5].airportCode,
      // originObj: props.popularcities[5],
      // destination: props.values.sectors[props.values.sectors.length - 1].origin,
      // destinationObj: props.values.sectors[props.values.sectors.length - 1].originObj,
      origin: props.values.sectors[props.values.sectors.length - 1].destination,
      originObj:
        props.values.sectors[props.values.sectors.length - 1].destinationObj,
      destination: "",
      destinationObj: null,
      // origin:"",
      // originObj: "",
      departDate: dateFnsFormat(
        props.values.sectors[0].departDateDisplay,
        "yyyy-MM-dd"
      ),
      departDateDisplay: props.values.sectors[0].departDateDisplay,
    };
    array.push(initObj);
  };

  const onChangeReturnDateDisplay = (e, v) => {
    props.setfieldvalue(`returnDateDisplay`, e);
    props.setfieldvalue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
  };

  const toggleRoundTrip = () => {
    if (props.values.mode === "ONE") {
      props.setfieldvalue("mode", "ROUND");
      props.settripmode("ROUND");
    } else {
      props.setfieldvalue("mode", "ONE");
      props.settripmode("ONE");
    }
  };

  React.useEffect(() => {
    if (!domesticCarriers || !internationalCarriers) {
      getAirlineData();
    }
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(domesticCarriers);
      } else {
        setCarrier(internationalCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  React.useEffect(() => {
    if (props.values.mode == "MULTICITY" && props.values.sectors.length === 1) {
      pushBtn(props.arrayhelpers, 0);
    }
  }, [props.values.mode]);

  React.useEffect(() => {
    // console.log("fetchingAirports",fetchingAirports)
    // console.log("airportList",airportList)
    if (airportList && airportList.length > 0) {
      setFetchingAirports(false);
    }
  }, [airportList]);

  return (
    <div className={classes.itinRoot}>
      <div className="itin-card">
        <div className="itin-row">
          <div className="itin-col-50">
            <div className="inner-row">
              <div className="itin-col-50 hover location-wrapper border-right">
                <OaPopperAutocomplete
                  loading={fetchingAirports}
                  className="autocomplete"
                  name={`sectors[0].originObj`}
                  PopperComponent={customPopperTo}
                  options={airportList || []}
                  getOptionLabel={(o) =>
                    o ? `${o.cityName} ${o.airportCode}` : ""
                  }
                  value={props.values.sectors[0].originObj}
                  // value={''}
                  openOnFocus
                  getOptionSelected={(option, value) =>
                    option.cityName === value.cityName
                  }
                  onKeyUp={(event) => {
                    fetchAirports(event.target.value.trim());
                  }}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setAirportList(props.popularcities);
                    } else {
                      changeHandler("from", newValue?.countryCode);
                      props.setfieldvalue(`sectors[0].originObj`, newValue);
                      props.setfieldvalue(
                        `sectors[0].origin`,
                        newValue.airportCode
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      color="secondary"
                      inputProps={{
                        ...params.inputProps,
                        autocomplete: "new-password",
                      }}
                    />
                  )}
                  renderOption={(optionsData, propes) =>
                    renderOptionsComp(optionsData, propes)
                  }
                  disablePortal={true}
                  secondarytxt={
                    props.values.sectors[0].originObj &&
                    `[${props.values.sectors[0].originObj.airportCode}] ${props.values.sectors[0].originObj.airportDesc} ${props.values.sectors[0].originObj.countryCode}`
                  }
                  labeltxt="FROM"
                  inputtext={props.values.sectors[0].originObj?.cityName}
                  clearonfocus={"true"}
                />
              </div>
              {props.values.mode !== "MULTICITY" && (
                <div className="itin-swapper-col">
                  <IconButton
                    onClick={() => {
                      const originTemp = {
                        str: props.values.sectors[0].origin,
                        obj: props.values.sectors[0].originObj,
                      };
                      props.setfieldvalue(
                        `sectors[0].originObj`,
                        props.values.sectors[0].destinationObj
                      );
                      props.setfieldvalue(
                        `sectors[0].origin`,
                        props.values.sectors[0].destination
                      );
                      props.setfieldvalue(
                        `sectors[0].destinationObj`,
                        originTemp.obj
                      );
                      props.setfieldvalue(
                        `sectors[0].destination`,
                        originTemp.str
                      );
                    }}
                    aria-label="delete"
                    className="swap-icon-button"
                  >
                    <img src={swiperIcon} alt="" />
                  </IconButton>
                </div>
              )}
              <div className="itin-col-50 hover location-wrapper pdl-15 border-right">
                <OaPopperAutocomplete
                  loading={fetchingAirports}
                  className="autocomplete"
                  name={`sectors[0].destinationObj`}
                  PopperComponent={customPopperFrom}
                  options={airportList || []}
                  getOptionLabel={(o) =>
                    o ? `${o.cityName} ${o.airportCode}` : ""
                  }
                  value={props.values.sectors[0].destinationObj || ""}
                  openOnFocus
                  getOptionSelected={(option, value) =>
                    option.airportCode == value.airportCode
                  }
                  onKeyUp={(event) => fetchAirports(event.target.value.trim())}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setAirportList(props.popularcities);
                    } else {
                      changeHandler("from", newValue?.countryCode);
                      props.setfieldvalue(
                        `sectors[0].destinationObj`,
                        newValue
                      );
                      props.setfieldvalue(
                        `sectors[0].destination`,
                        newValue.airportCode
                      );
                      if (props.values.mode === "MULTICITY") {
                        props.setfieldvalue(`sectors[1].originObj`, newValue);
                        props.setfieldvalue(
                          `sectors[1].origin`,
                          newValue.airportCode
                        );
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      color="secondary"
                      inputProps={{
                        ...params.inputProps,
                        autocomplete: "new-password",
                      }}
                    />
                  )}
                  renderOption={(optionsData, propes) =>
                    renderOptionsComp(optionsData, propes)
                  }
                  disablePortal={true}
                  secondarytxt={
                    props.values.sectors[0].destinationObj &&
                    `[${props.values.sectors[0].destinationObj.airportCode}] ${props.values.sectors[0].destinationObj.airportDesc} ${props.values.sectors[0].destinationObj.countryCode}`
                  }
                  labeltxt="TO"
                  inputtext={props.values.sectors[0].destinationObj?.cityName}
                  clearonfocus={"true"}
                />
              </div>
            </div>
          </div>

          <div className="itin-col-50">
            <div className="inner-row">
              <div className="itin-col-25 hover date-wrapper border-right">
                <OaOutlinedDatePicker
                  showDisabledMonthNavigation
                  className="date-picker"
                  name={`sectors[0].departDateDisplay`}
                  minDate={new Date()}
                  customInput={
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      fullWidth={isMobileScreen}
                    />
                  }
                  selected={props.values.sectors[0].departDateDisplay}
                  onChange={(e, v) => {
                    //   console.log("props.values.mode " + props.values.mode);
                    if (props.values.mode === "MULTICITY") {
                      for (let i = 0; i < props.values.sectors.length; i++) {
                        const t1 = new Date(e).getTime();
                        const t2 = new Date(
                          props.values.sectors[i].departDateDisplay
                        ).getTime();
                        if (t2 < t1) {
                          props.setfieldvalue(
                            `sectors[${i}].departDateDisplay`,
                            e
                          );
                          props.setfieldvalue(
                            `sectors[${i}].departDate`,
                            dateFnsFormat(e, "yyyy-MM-dd")
                          );
                        }
                      }
                    } else {
                      props.setfieldvalue(`sectors[0].departDateDisplay`, e);
                      props.setfieldvalue(
                        `sectors[0].departDate`,
                        dateFnsFormat(e, "yyyy-MM-dd")
                      );
                    }
                    if (Math.abs(props.values.returnDateDisplay) < Math.abs(e)) {
                      props.setfieldvalue(`returnDateDisplay`, e);
                      props.setfieldvalue(
                        `returnDate`,
                        dateFnsFormat(e, "yyyy-MM-dd")
                      );
                    }
                  }}
                  monthsShown={2}
                  dateFormat="dd MMM yyyy"
                  labeltxt="DEPARTURE DATE"
                  secondarytxt={
                    days[props.values.sectors[0].departDateDisplay.getDay()]
                  }
                  showinput={false}
                />
              </div>
              {props.values.mode !== "MULTICITY" && (
                <div className="itin-col-25 hover date-wrapper border-right toggler-div">
                  <OaOutlinedDatePicker
                    showDisabledMonthNavigation
                    className="date-picker"
                    name={`returnDateDisplay`}
                    minDate={props.values.sectors[0].departDateDisplay}
                    customInput={
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        fullWidth={isMobileScreen}
                      />
                    }
                    selected={props.values.returnDateDisplay}
                    onChange={onChangeReturnDateDisplay}
                    monthsShown={2}
                    dateFormat="dd MMM yyyy"
                    labeltxt="RETURN DATE"
                    secondarytxt={
                      props.values.mode == "ROUND" ||
                      props.values.mode == "ROUND-SP"
                        ? days[props.values.returnDateDisplay.getDay()]
                        : ""
                    }
                    showinput={false}
                    disabledtxt={null}
                  />
                  {props.values.mode === "ROUND" && (
                    <span
                      className="oa-form-label-btn"
                      onClick={toggleRoundTrip}
                    >
                      <Close />
                    </span>
                  )}
                  {props.values.mode === "ONE" && (
                    <div
                      className="return-date-overlay"
                      onClick={toggleRoundTrip}
                    ></div>
                  )}
                </div>
              )}
              <div className="itin-col-25 hover pax-wrapper border-right">
                <div className="pax-input-div">
                  <div
                    className="passenger_div"
                    onClick={() => setDropdownTraveler(!dropdownTraveler)}
                  >
                    <p className="trvl">TRAVELLER & CLASS</p>
                    <p className="trvl-count">
                      <span className="trval-tnum">
                        {
                          (totalTravelers =
                            Number(props.values.adultPax) +
                            Number(props.values.childPax) +
                            Number(props.values.infantPax))
                        }
                      </span>
                      <span className="trval-txt">Traveller(s)</span>{" "}
                      <i className="fas fa-chevron-down"></i>
                    </p>
                    <p className="trvl-class">
                      {props.values.preferredclass === "E"
                        ? "Economy"
                        : props.values.preferredclass === "ER"
                        ? "Premium Economy"
                        : props.values.preferredclass === "B"
                        ? "Business"
                        : ""}
                    </p>
                  </div>

                  {dropdownTraveler && (
                    <div className="passenger_div-show">
                      <Grid
                        item
                        sm={12}
                        className={classes.FlightSection_passdet}
                      >
                        <Grid
                          className={classes.gridRespColPassDet}
                          container
                          direction="column"
                          justifyContent="space-evenly"
                          alignItems="center"
                          spacing={2}
                        >
                          <Box className="counter-box">
                            <PassengerCount
                              title="Adults"
                              type="adult"
                              val={props.values.adultPax}
                              setVal={modifyPassengerCount}
                              name="adultPax"
                              totalPax={
                                props.values.adultPax +
                                props.values.childPax +
                                props.values.infantPax
                              }
                            />
                            <ErrorMessage
                              name="adultPax"
                              component="div"
                              className="error"
                            />
                          </Box>
                          <Box className="counter-box">
                            <PassengerCount
                              title="Children"
                              type="child"
                              val={props.values.childPax}
                              setVal={modifyPassengerCount}
                              name="childPax"
                              totalPax={
                                props.values.adultPax +
                                props.values.childPax +
                                props.values.infantPax
                              }
                            />
                            <ErrorMessage
                              name="childPax"
                              component="div"
                              className="error"
                            />
                          </Box>
                          <Box className="counter-box">
                            <PassengerCount
                              title="Infants"
                              type="infant"
                              val={props.values.infantPax}
                              setVal={modifyPassengerCount}
                              name="infantPax"
                              totalPax={
                                props.values.adultPax +
                                props.values.childPax +
                                props.values.infantPax
                              }
                            />
                            <ErrorMessage
                              name="noInfant"
                              component="div"
                              className="error"
                            />
                          </Box>
                        </Grid>

                        <div className="cabinType-dev">
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="cabinType"
                              value={props.values.preferredclass}
                              name="preferredclass"
                              onChange={(e, v) =>
                                v && props.setfieldvalue("preferredclass", v)
                              }
                            >
                              <FormControlLabel
                                value="E"
                                control={<Radio />}
                                label="Economy"
                              />
                              <FormControlLabel
                                value="ER"
                                control={<Radio />}
                                label="Premium Economy"
                              />
                              <FormControlLabel
                                value="B"
                                control={<Radio />}
                                label="Business"
                              />
                            </RadioGroup>
                            <ErrorMessage
                              name="preferredclass"
                              component="div"
                              className="error"
                            />
                          </FormControl>
                          <Button
                            onClick={() =>
                              setDropdownTraveler(!dropdownTraveler)
                            }
                          >
                            Done
                          </Button>
                        </div>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
              {props.values.mode === "MULTICITY" && (
                <div className="itin-col-25"></div>
              )}
              <div className="itin-col-25 hover non-muct-action-wrapper">
                {props.values.mode !== "MULTICITY" && (
                  <Button className="search-button" type="submit">
                    SEARCH
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        {props.values.mode === "MULTICITY" &&
          props.values.sectors
            .slice(1, props.values.sectors.length)
            .map((sector, indexs) => (
              <FLightMultiCitySection
                indx={indexs}
                arrayhelpers={props.arrayhelpers}
                setfieldvalue={props.setfieldvalue}
                values={props.values}
                popularcities={props.popularcities}
                ismobilescreen={isMobileScreen}
                days={days}
                key={indexs}
                startDate={props.startDate}
              />
            ))}
      </div>
    </div>
  );
};

export default FlightItinSection;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const customPopperFrom = function(props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={departAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const customPopperTo = function(props) {
  return <Popper {...props} placement="bottom" style={{}} />;
};

const useStyles = makeStyles((theme) => ({
  itinRoot: {
    "& .itin-card": {
      boxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      MozBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      WebkitBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      width: "100%",
      backgroundColor: theme.palette.background.light,
      borderRadius: 5,
      position: "relative",
    },
    "& .itin-row": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      height: 100,
    },
    "& .inner-row": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    "& .itin-swapper-col": {
      position: "relative",
      height: "100%",
      "& .swap-icon-button": {
        position: "absolute",
        top: 35,
        left: -15,
        "& .MuiIconButton-label": {
          padding: 0,
        },
        "&.MuiIconButton-root": {
          borderRadius: 50,
          padding: 0,
        },
      },
    },
    "& .itin-col-25": {
      width: "25%",
    },
    "& .itin-col-40": {
      width: "40%",
    },
    "& .itin-col-50": {
      width: "50%",
    },
    "& .itin-col-60": {
      width: "60%",
    },
    "& .hover": {
      "&:hover": {
        background: theme.palette.background.rgba_primary,
      },
    },
    "& .pdl-15": {
      paddingLeft: 15,
    },
    "& .border-right": {
      borderRight: `solid 0.5px ${theme.palette.primary.disabled}`,
    },
    "& .border-top": {
      borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    },
    "& .location-wrapper": {
      "& .oa-form-element-card": {
        padding: 15,
        height: 100,
      },
      "& .oa-form-element-label": {
        fontSize: 13,
        fontWeight: 400,
      },
      "& .oa-form-element-primary": {
        fontSize: 22,
        fontWeight: 600,
        margin: "4px 0 3px",
      },
      "& .oa-form-element-secondary": {
        fontWeight: 400,
        fontSize: 11.5,
      },
    },
    "& .date-wrapper": {
      "& .oa-form-element-card": {
        height: 100,
        padding: 15,
        border: "none !important",
      },
      "& .oa-form-element-label": {
        fontSize: 13,
        fontWeight: 400,
      },
      "& .oa-form-element-primary": {
        fontSize: 12,
        fontWeight: 400,
        margin: "0 0 3px",
        "& .big": {
          fontSize: 24,
          fontWeight: 600,
          marginRight: 4,
        },
        "& .icon-wrap": {
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.lightGray,
            fontSize: 19,
          },
        },
        "& .MuiInputBase-input": {
          fontWeight: 600,
          fontSize: "17px !important",
          color: theme.palette.primary.defaultText,
        },
      },
      "& .oa-form-element-secondary": {
        fontWeight: 400,
        fontSize: 11.5,
        marginTop: -3,
      },
    },
    "& .pax-wrapper": {
      "& .pax-input-div": {
        position: "relative",
        height: 100
      },
      "& .passenger_div-show": {
        position: "absolute",
        zIndex: "9999",
        width: "190px",
        height: "auto",
        left: "0",
        top: "90px",
        borderRadius: "4px",
        padding: "10px",
        background: "#fff",
        boxSizing: "content-box",
        boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
        paddingTop: 20,

        "& .cabinType-dev": {
          marginTop: "13px",
          padding: "0 10px",
          borderTop: "solid 2px #eee",
          "& .MuiFormControlLabel-root": {
            margin: "5px 0",
          },
          "& .MuiFormControlLabel-label": {
            marginLeft: "6px",
            color: "#000",
            fontSize: "13px",
          },
          "& .MuiButton-root": {
            width: "100%",
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
          },
          "& .Mui-checked": {
            color: theme.palette.secondary.main,
          },
        },
      },

      "& .passenger_div": {
        padding: "15px 15px 11px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        cursor: "pointer",
        "& p": {
          margin: 0,
          textTransform: "capitalize",
        },

        "& .trvl": {
          fontSize: "12px",
          fontWeight: "400",
          marginTop: "0.23rem",
          paddingBottom: "0.3rem",
          textTransform: "uppercase",
        },
        "& .trvl-count": {
          color: theme.palette.primary.defaultText,
          "& span": {
            margin: "0 0.1rem 0 0",
          },
          "& .trval-tnum": {
            fontSize: "25px",
            fontWeight: "700",
          },
          "& .trval-txt": {
            fontWeight: "600",
          },
        },
        "& .trvl-class": {
          fontSize: 12,
        },
      },
    },
    "& .non-muct-action-wrapper": {
      position: "relative",
      height: 100,
      "& .search-button": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: "pointer",
        background: theme.palette.secondary.main,
        borderRadius: "0 5px 5px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        color: theme.palette.secondary.contrastText,
        width: "100%",
        "&:hover": {
          background: theme.palette.secondary.sub,
        },
      },
    },
    "& .muct-action-wrapper": {
      position: "absolute",
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 20px",
    },
    "& .multicity-search-btn": {
      background: theme.palette.secondary.main,
      width: 130,
      padding: "12px 0",
      color: theme.palette.secondary.contrastText,
      border: theme.palette.secondary.main,
    },
    "& .multicity-sector-add": {
      background: theme.palette.background.light,
      border: `solid 1px ${theme.palette.secondary.main}`,
      width: 130,
      borderRadius: 3,
      padding: "12px 0",
      color: theme.palette.secondary.main,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 20,
    },
    "& .multicity-sector-remove": {
      cursor: "pointer",
      marginLeft: 20,
      height: 20,
      width: 20,
      background: theme.palette.primary.lightGray,
      color: theme.palette.primary.lightText,
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiSvgIcon-root": {
        fontSize: 14,
      },
    },
    "& .toggler-div": {
      position: "relative",
    },
    "& .oa-form-label-btn": {
      cursor: "pointer",
      position: "absolute",
      top: 15,
      right: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      background: theme.palette.buttons.disabled,
      padding: 2,
      "& .MuiSvgIcon-root": {
        fontSize: "0.8rem",
        color: theme.palette.buttons.disabledContrastText,
      },
    },
    "& .return-date-overlay": {
      position: "absolute",
      background: "rgba(255,255,255,0.8)",
      top: 0,
      border: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
}));
