import React, {useState} from 'react';

import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';

import {Formik, Form} from 'formik';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';
//import OaFormTextField from 'oahoc/OaFormTextField';
import Collapse from '@material-ui/core/Collapse';

const B2bBalanceManagerUpdateSearchForm = (props) => {
    const [collapsed, setCollapsed] = useState(true);

    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    return(
        <>
        <h4 className={"oa-form-header"} onClick={collapse}>Search</h4>
        <Collapse in={collapsed}>
        <Formik 
            initialValues={{
                agencyId: props.searchParams.agencyId,
                status: props.searchParams.status,
                dateFromDisplay: props.searchParams.dateFromDisplay,
                dateToDisplay: props.searchParams.dateToDisplay
            }}
            onSubmit= {(searchParams, {setSubmitting, resetForm}) => {
                props.setIsLoading(true);
                searchParams.dateFrom = dateFnsFormat(searchParams.dateFromDisplay, 'dd-MM-yyyy');
                searchParams.dateTo = dateFnsFormat(searchParams.dateToDisplay,   'dd-MM-yyyy');
                searchParams.agencyId = props.agencyId;
                props.target(searchParams);
                // console.log(searchParams);
                props.setIsLoading(false);
            }}>
            {({ values, isSubmitting, setFieldValue, handleChange, initialValues }) => (
                <Form>
                    <GridContainer>
                        {/* <OaFormTextField name="agencyName" label="Agency name" md={4}/>
                        <OaFormTextField name="agencyId" label="Agency ID" md={4} />
                        <OaFormTextField name="transactionNo" label="Transaction Number" md={4} /> */}
                        <OaFormSelectField 
                            label="Status" 
                            name="status" 
                            value={values.status}
                            options={[
                                {id: 'all', value: ' ', label: 'All'},
                                {id: 'pending', value: 'P', label: 'Pending'},
                                {id: 'aborted', value: 'A', label: 'Aborted'},
                                {id : 'processed', value: 'S', label: 'Processed'},
                            ]}
                            md={4}
                        />
                        <OaFormDateField 
                            value={values.dateFromDisplay} 
                            selected={values.dateFromDisplay}
                            onChange={date => setFieldValue('dateFromDisplay', date)}
                            label="From"
                            name="dateFromDisplay"
                            md={4}
                        />
                        <OaFormDateField 
                            value={values.dateToDisplay}
                            selected={values.dateToDisplay}
                            onChange={date => setFieldValue('dateToDisplay', date)}
                            label="To"
                            name="dateToDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />

                    </GridContainer>
                    <Button color="primary" type="submit">Search</Button>
                </Form>
            )}
        </Formik>
        </Collapse>
        </>
    )
}

export default B2bBalanceManagerUpdateSearchForm;
