import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import { useTheme } from '@material-ui/core';

const OaAgentDetailMenuButton = (props) => {

    function clicked(url){
        //console.log(url);
    }
    const theme = useTheme()
    return (
        <Button color="primary" disableElevation
            component={Link}
            to={props.url}
            style={{margin: '0 0 0 0', borderLeft: `1px solid ${theme.palette.secondary.chineseGray}`, padding: "5px 10px 5px 10px", borderRadius: 0}} 
            startIcon={props.icon}
        >
            {props.label}
        </Button>

    )
}

export default OaAgentDetailMenuButton;
