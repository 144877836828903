import React, { useEffect, useState } from "react";
import OaFormAlerts from "pages/components/OaFormAlerts";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import OaLoading from "pages/components/OaLoading";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { localforageGetItem } from "oautils/oaForageUtils";
import WebApi, { POST_PAYGOAL_API_CALLBACK } from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { submitDataToPaygoal } from "pages/module/paymentgateway/Paygoal";
import { submitDataToRazorpay } from "pages/module/paymentgateway/Razorpay";
import PaymentOptions from "pages/module/flight/compact/checkinReview/PaymentOptions";
import { submitDataToCcAvenue } from "pages/module/paymentgateway/CcAvenue";
import { EXT_REDIRECT_SERVER } from "api/ApiConstants";
import { submitDataToPayU } from "pages/module/paymentgateway/PayU";

const MasterPaymentOptions = (props) => {
  const intiatingMsg = "Initiating Payment ...";
  const payProcessMsg =
    "Hold on for a minute!<br/>We’ll get everything done for you";

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [responseMessage, setResponseMessage] = useState(payProcessMsg);
  const [paymentModes, setPaymentModes] = useState(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [paymentTab, setPaymentTab] = useState(0);
  const [retryPayment, setRetryPayment] = useState(false);
  // const [paymentModeList, setPaymentModeList] = useState([]);

  const classes = useStyles();
  const theme = useTheme();

  async function showRazorPayWindow(trnResponse) {
    // setIsSaving(true);
    setRetryPayment(false);

    let travellers = [
      {
        nameReference: userDetails.title,
      },
    ];
    let deliveryAddress = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      mobileNumber: userDetails.mobileNumber,
    };

    trnResponse.travelers = travellers;
    trnResponse.deliveryAddress = deliveryAddress;
    trnResponse.userType = userDetails.userTypeAbv;
    trnResponse.amountDue = props.amount;
    trnResponse.transactionId = trnResponse.txnId;
    trnResponse.uniqueTransactionId = trnResponse.encTxnId;
    trnResponse.paymentMode = selectedPaymentMethod;

    let filteredValue =
      selectedPaymentMode &&
      selectedPaymentMode.gateway &&
      selectedPaymentMode.gateway.filter((value) => {
        return value.method === selectedPaymentMethod;
      });

    let paymentOptions = filteredValue[0];

    let request = {
      paymentOptions: paymentOptions,
      trnResponse: trnResponse,
      selectedPaymentMethod: selectedPaymentMethod,
      theme: theme,
      trnType: "wallet-recharge",
      // trnType: "wallet-topup",
      // checkoutUrl: "https://checkout.razorpay.com/v1/checkout.js"
      checkoutUrl: trnResponse.paymentUrl,
    };

    var response = await submitDataToRazorpay(request);
    setIsSaving(false);
    setIsError(response.isError);
    setIsSuccess(response.isSucess);
    setInfoText(response.infoText);
    setRetryPayment(response.retryPayment);
    setResponseMessage(response.responseMessage);

    // if (response.isSucess) {
    //   submitData(response.transactionId);
    // }
  }

  async function showPaygoalWindow(trnResponse) {
    const request = {
      amount: props.amount,
      encTxnId: trnResponse.encTxnId,
      EncTxnId: trnResponse.encTxnId,
      redirectUrl: "http://oari.co:8080/lmt/flight/payment-status/", //http://oari.co:8080/emt/#/flight/payment-status/http://localhost:3000/psa_web/flight/payment-status/
      siteUrl: POST_PAYGOAL_API_CALLBACK,
      txnId: trnResponse.txnId,
      paymentMode: "PayGoal",
      failUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      checkoutUrl: trnResponse.paymentUrl,
    };
    setIsSaving(false);
    submitDataToPaygoal(request);
  }

  async function showCcAvenueWindow(trnResponse) {
    const request = {
      amount: props.amount,
      encTxnId: trnResponse.encTxnId,
      EncTxnId: trnResponse.encTxnId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=ccavenue",
      siteUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      txnId: trnResponse.txnId,
      paymentMode: "CCAvenue",
      failUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      checkoutUrl: trnResponse.paymentUrl,
    };
    setIsSaving(false);
    submitDataToCcAvenue(request);
  }

  async function showPayUWindow(trnResponse) {
    const request = {
      amount: props.amount,
      encTxnId: trnResponse.encTxnId,
      EncTxnId: trnResponse.encTxnId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=payu",
      siteUrl: EXT_REDIRECT_SERVER + "flight/payment-status/",
      txnId: trnResponse.txnId,
      paymentMode: "PayU",
      trnType: "wallet-recharge",
      checkoutUrl: trnResponse.paymentUrl,
    };
    submitDataToPayU(request);
  }

  const displayPaymentWindow = async (trnResponse) => {
    setResponseMessage(intiatingMsg);

    if (selectedPaymentMode.method === "razorpay") {
      showRazorPayWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "paygoal") {
      showPaygoalWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "ccavenue") {
      showCcAvenueWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "payu") {
      showPayUWindow(trnResponse);
    }
  };

  const processPayment = () => {
    if (userDetails) {
      setIsSaving(true);
      new Promise((resolve, reject) => {
        WebApi.getWalletTopupTxn(
          {
            type: "wallet-recharge",
            topupAmount: props.amount,
            paymentMode: selectedPaymentMode?.method,
            gateway: {
              charge: 0.0,
              charge_type: "A",
              methodOwnChargeHandle: true,
            },
          },
          (response) => {
            resolve(response);
          },
          (error) => {
            console.log("trn create error", error);
            reject(error);
          }
        );
      }).then((response) => {
        if (
          response.success &&
          response.data.txnId != "" &&
          response.data.txnId != null
        ) {
          displayPaymentWindow(response.data);
        } else {
          setInfoText("Failed to create transaction. Please retry");
          setIsError(true);
          console.log("error creating transaction", response);
          setIsSaving(false);
        }
      });
    }
  };

  const switchPaymentTabs = (event, index) => { 
    setPaymentTab(index);
    setSelectedPaymentMode(paymentModes[index])
    if (paymentModes[index].method == "cash") {
      setSelectedPaymentMethod("cashPayment");
    } else {
      for (let gate of paymentModes[index]?.gateway) {
        if (gate.selected) {
          setSelectedPaymentMethod(gate.method);
        }
      }
    }
  };

  useEffect(() => {
     setIsLoading(true)
    // if (isLoading) {
      localforageGetItem("user-id", function(err, value) {
        apiCall(
          WebApi.getAgentExtendedDetails,
          { agentId: value },
          (response) => {
            // setIsLoading(false);
            if (response.success === true) {
              setData(response.data);
            } else {
              setIsError(true);
            }
          }
        );
      });

      localforageGetItem("user-details", function(err, details) {
        if (details != null) {
          setUserDetails(details);
          apiCall(
            WebApi.getCompanyPGOptions,
            {
              partnerId: details.partnerId,
              serviceId: 10,
              // serviceId: 1,
            },
            (response) => {
              // setPaymentModes(response.data.slice(1));
              response.data[0].selected = true;
              setPaymentModes(response.data);
              setIsLoading(false);
            }
          );
        }
      });
    // }
  }, []);

  React.useEffect(() => {
    if (paymentModes) {
      for (let [payModeIdx, payMode] of paymentModes.entries()) {
        if (payMode.selected) {
          setSelectedPaymentMode(payMode);
          setPaymentTab(payModeIdx);
          // if (payMode.method == "cash") {
          //   setSelectedPaymentMethod("cashPayment");
          // } else {
            for (let gate of payMode?.gateway) {
              if (gate.selected) {
                setSelectedPaymentMethod(gate.method);
              }
            }
          // }
        }
      }
    }
  }, [paymentModes]);

  // console.log(paymentModeList,"selectedPaymentMode")
  // console.log(selectedPaymentMethod,"selectedPaymentMode")
  // console.log(paymentModes,"selectedPaymentMode")

  return (
    <div className={classes.root}>
      <div className="section">
        {isSaving && (
          <div className={classes.loadingRoot}>
            <OaLoading />
          </div>
        )}
        <Grid container>
          <Grid item>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
          <label className="payment-opt-label">Payment Options</label>
            <PaymentOptions
              paymenttab={paymentTab}
              switchpaymenttabs={switchPaymentTabs}
              paymentmodes={paymentModes}
              isloading={isLoading}
              data={data}
              selectedpaymentmethod={selectedPaymentMethod}
              setselectedpaymentmethod={setSelectedPaymentMethod}
              grandtotal={props.amount}
              setisloading={setIsLoading}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container className="button-holder">
        {props.amount > 0 ?(
          <button className="action-button" onClick={() => processPayment()}>
            {retryPayment == true ? "Retry payment " : "Recharge"} <Rs />{" "}
            {props.amount}
          </button>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};

export default MasterPaymentOptions;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .tab-container": {
      "& .MuiTab-wrapper ": {
        alignItems: "baseline",
        [theme.breakpoints.down(560)]: {
          alignItems: "center",
        },
      },
      "& .MuiTabs-scrollable": {
        minHeight: 300,
        "& .MuiButtonBase-root.MuiTab-root": {
          margin: "0 0 10px",
          "&.Mui-selected": {
            background: theme.palette.background.rgba_primary,
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: theme.palette.background.rgba_primary_active,
        },
      },
    },
    "& .tab-panel-container": {
      "& .MuiBox-root": {
        padding: "0 36px",
      },
      "& .tab-content-div": {
        width: "100%",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .section": {
      marginTop: 20,
      marginBottom: 20,
      paddingLeft: "3vw",
      background: theme.palette.background.light,
      [theme.breakpoints.down(960)]: {
        paddingRight: 10,
        paddingLeft: 10,
      },

      "& .payment-opt-label":{
        color:'#000',
        display:'block',
        marginBottom:'1.3rem',
      },
    },
    "& .payment-method": {
      cursor: "pointer",
      margin: "15px 0",
      padding: "5px 20px",
    },
    "& .pay-method-title": {
      fontSize: 20,
      fontWeight: 600,
    },
    "& .payment-method-info": {
      fontSize: 12,
      fontWeight: 500,
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
    },
    "& .action-button": {
      background: theme.palette.primary.main,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.primary.contrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: 19,
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
  },
  loadingRoot: {
    position: "absolute",
    width: "100%",
    // height:'100%',
    zIndex: 999,
    left: 23,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "rgba(0,0,0,0.09)",
    minHeight: 386.66,
  },
}));

const Rs = () => {
  return <span style={{ fontFamily: "Roboto" }}>₹</span>;
};

// isSaving ? (
//   <div className={classes.loadingRoot}>
//     <OaLoading />
//   </div>
// ) :
