import React from "react";
import pln from "assets/lookMyTicket/images/plane.png";
import money from "assets/lookMyTicket/images/money.png";
import vol from "assets/lookMyTicket/images/noise.png";
import customer from "assets/lookMyTicket/images/woman.png";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const FooterSection = () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(760));
    return (
        <div className={classes.root}>
            <div className="why-us-outer-container">
                <div className="why_us-div">
                    <div className="title">Why Book With Us?</div>
                    <div className="caption-div">
                        <div className="caption-content">
                            {whyUsData.map((val, index) => {
                                return (
                                    <div className="whyUsContent" key={index}>
                                        {/* <div className="icon">
                                        <img src={val.img} alt="" />
                                    </div> */}
                                        <div>
                                            <h6 className="whyUs__title">{val.name}</h6>
                                            <p className="whyUs__desc">{val.desc}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="why_us-div border-top">
                    <div className="whyUsContent w-100">
                        <div>
                            <h6 className="whyUs__title">
                                Book Flights, Hotels, Holiday Packages and Bus Ticket with
                                Lookmyticket
                            </h6>
                            <p className="whyUs__desc">
                                Are you looking for the cheapest flight deals, hotel
                                reservations, holiday packages and bus tickets? You don't need
                                to go anywhere else. LookMyTicket brings you the best deals for
                                any kind of travel related services. Whether you are traveling
                                for a business event or a family trip with easy-to-use
                                arrangements, we offer you the convenience of the best range of
                                flight, hotel and holiday package services anywhere in India
                                without compromising on quality.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default FooterSection;
const whyUsData = [
    {
        name: "Full Financial Protection ",
        img: pln,
        desc:
            "At LookMyTicket, the financial security of our passengers is our foremost responsibility. Our highly experienced security team, keeping passenger data secure is their top priority. ",
    },
    {
        name: "Value for Money ",
        img: money,
        desc:
            "Lookmyticket always ensures the lowest rates on flight booking, holiday packages and hotel bookings. ",
    },
    {
        name: "Quick Response To Queries ",
        img: vol,
        desc:
            "Any travel queries are handled promptly by our experienced support team. We take full care of your travel convenience ",
    },
    {
        name: "Easy to Compare & Buy Flight Tickets ",
        img: customer,
        desc:
            "Low prices you can trust on. No any hidden charges. Save money with LookMyTicket. Book cheap flight tickets with LookmyTicket at the lowest airfares. ",
    },
];



const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20,
        [theme.breakpoints.down(980)]: {
            marginTop: 10,
        },
        position: "relative",
        // "& .why-us-outer-container": {
        //     backgroundImage: `url(${FooterHeadBg})`,
        //     backgroundSize: 'contain',
        //     backgroundPosition: 'center'
        // },
        "& .why_us-div": {
            width: "100%",
            display: "flex",
            paddingTop: 10,
            paddingBottom: 10,
            [theme.breakpoints.down(980)]: {
                flexDirection: "column",
            },
            // alignItems: "center",
            justifyContent: "space-between",
            //backgroundImage: `url(${bg2})`,
            //backgroundHeight: "100% 100px",
            backgroundColor: theme.palette.primary.main,
            "& .title": {
                fontSize: "22px",
                padding: "0 3%",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                color: theme.palette.primary.defaultText,
                width: "25%",
                [theme.breakpoints.down(980)]: {
                    width: "93%",
                },
            },
            "& .caption-div": {
                display: "flex",
                flexDirection: "column",
            },
            "& .caption-content": {
                display: "flex",
                justifyContent: "space-around",
                [theme.breakpoints.down(650)]: {
                    flexWrap: "wrap",
                },
            },
            "& .w-100": {
                width: "100% !important",
            },
            "& .whyUsContent": {
                width: "25%",
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                [theme.breakpoints.down(980)]: {
                    width: "93%",
                    margin: "13px 0",
                },
                [theme.breakpoints.down(650)]: {
                    width: "100% !important",
                    margin: "13px 0",
                    flexWrap: "wrap !important",
                },
                float: "left",
                padding: "0.3rem 1rem",
                "& .icon": {
                    marginRight: "0.5rem",
                    "& img": {
                        width: "45px",
                        height: "45px",
                    },
                },
                "& .whyUs__title": {
                    fontSize: "13px",
                    fontWeight: "700",
                    // margin: "1rem 0",
                    color: theme.palette.primary.defaultText,
                    [theme.breakpoints.down(980)]: {
                        fontSize: "16px",
                    },
                },
                "& .whyUs__desc": {
                    margin: "0",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: 1.2,
                    color: theme.palette.primary.defaultText,
                    [theme.breakpoints.down(980)]: {
                        fontSize: "13px",
                        fontWeight: "500",
                    },
                },
            },
            "&.border-top": {
                borderTop: `solid 1px ${theme.palette.primary.lightText}`,
            },
        },
    },
}));
