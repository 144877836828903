import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function LeafletMap(props) {
  const initMap = () => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    map.on("fullscreenchange", handleOnToggleFullscreen);
  };
  React.useEffect(() => {
    if(props.hotels.length > 0 && (props.hotels[0].latitude !== "" && props.hotels[0].latitude !== undefined) &&
    props.hotels.length > 0 && (props.hotels[0].longitude !== "" && props.hotels[0].longitude !== undefined)) {
      initMap();
    }
  }, []);
  const mapRef = React.useRef();

  function handleOnToggleFullscreen() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    // console.log(`Fullscreen: ${map.isFullscreen() ? "yes" : "no"}`);
  }

  // console.log(props.hotels);

  return (
    props.hotels.length > 0 && (props.hotels[0].latitude !== "" && props.hotels[0].latitude !== undefined) &&
    props.hotels.length > 0 && (props.hotels[0].longitude !== "" && props.hotels[0].longitude !== undefined) &&
    <Map
      ref={mapRef}
      fullscreenControl={true}
      center={{
        lat:  props.hotels[0].latitude,
        lng: props.hotels[0].longitude,
      }}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: "300px", width: "100%", zIndex: 0 }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.hotels &&
        props.hotels.map((hotel, idx) => (
          <Marker position={{ lat: hotel.latitude, lng: hotel.longitude }} key={idx}>
            <Popup>{hotel.hotelName}</Popup>
          </Marker>
        ))}
    </Map>
  );
}
