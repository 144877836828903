import React from "react";

import Container from "@material-ui/core/Container";
import { Grid, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppConstant } from "appConstant";

import ContactCard from "components/HotelPage/HotelComponents/ContactCard/ContactCard";

const HotelBookingFormPage = ({ location, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hotelPriceRoundOff = AppConstant.hotelPriceRoundOff;

  const TitleComp = (props) => {
    return (
      <div className="section-title-wrapper">
        <span className="heading-icon">
          <i className={props.fa} />
        </span>
        <Typography className="heading-text">{props.title}</Typography>
      </div>
    );
  };

  const { params } = location;

  const totalCost = (selectedRooms) => {
    let total = 0;
    selectedRooms.map((room) => {
      total = total + room.price;
    });
    return hotelPriceRoundOff ? total.toFixed(0) : total.toLocaleString('en-IN');
    //return total.toFixed(2);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <Container maxWidth="lg"> */}
      {/* <Grid container spacing={4} className={classes.root}> */}
      <Grid container spacing={3}>
        <Grid
          item
          md={8}
          style={{
            // background: `linear-gradient(0deg, ${theme.palette.background.light}, ${theme.palette.background.light}), ${theme.palette.background.light}`,
            padding: "0 13px",
            background: "#eee",
          }}
        >
          <ContactCard
            locationProps={location}
            hotelInfoProps={props}
            traceId={props.history.location.state.traceId} //trace ID added
            hotelInfo={location.state.hotelInfo}
            hotelSearch={props.hotelSearch}
            selectedRooms={location.state.selectedRooms}
            totalFare={totalCost(location.state.selectedRooms)}
            hotelCode={location.state.hotelCode}
            tnc={props.tnc}
            ppc={props.ppc}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.infoGrid}>
          <Grid
            container
            className={classes.sectionContainer}
          // style={{ padding: 15 }}
          >
            <Grid item md={12} sm={12} xs={12}>
              {/* <Typography
                style={{
                  color: "#007A92",
                  fontSize: 20,
                  marginBottom: 15,
                  fontWeight: 500,
                }}
              >
                {" "}
                HOTEL INFO
              </Typography> */}

              <div
                className="section-header"
              // style={{
              //   borderBottom: "1px solid rgba(0,0,0,0.125)",
              // }}
              >
                <TitleComp fa="fa fa-building" title={`Hotel Details`} />
              </div>
              <Typography
                className={classes.hotelName}
                style={{ padding: 15, paddingBottom: 0 }}
              >
                {" "}
                {location.state.hotelInfo.hotelDetails.hotelName}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "0px 15px" }}
            >
              <Grid item>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {" "}
                  Check-In:
                </Typography>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {props.hotelSearch.checkInDate}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {" "}
                  Check-Out:
                </Typography>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {props.hotelSearch.checkOutDate}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12} style={{ marginTop: 20 }}>
              <Divider />
            </Grid>
            <Grid
              container
              style={{
                background: theme.palette.background.disabled,
                padding: "10px 15px",
                borderBottom: `none`,
                flexDirection: "row",
                display: "flex",
                width: "100%",
              }}
            >
              {/* <Grid item md={12} sm={12} xs={12}>
                <Typography className={classes.hotelInfo}>Price</Typography>
              </Grid> */}
              <Grid item md={6} sm={6} xs={6}>
                <Typography className={classes.hotelName}>
                  Total Costs
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Typography className={classes.hotelName} align="right">{`${props.hotelSearch.preferredCurrency === "INR"
                  ? "₹"
                  : props.hotelSearch.preferredCurrency
                  } ${totalCost(location.state.selectedRooms)}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
            style={{ padding: matches ? "3rem 1rem 1rem" : 10 }}
          >
            <Grid item xs={12} md={3}>
              <Typography
                component="p"
                variant="body2"
                className={classes.title}
              >
                {location.state.hotelInfo.hotelDetails.hotelName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <div
                className={classes.heading__container}
                style={{ display: isMobile ? "flex" : "" }}
              >
                <Grid item xs={5}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.subHeading}
                  >
                    Check-In:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.heading}
                  >
                    {props.hotelSearch.checkInDate}
                  </Typography>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <div
                className={classes.heading__container}
                style={{ display: isMobile ? "flex" : "" }}
              >
                <Grid item xs={5} md={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.subHeading}
                  >
                    Check-Out:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.heading}
                  >
                    {props.hotelSearch.checkOutDate}
                  </Typography>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <div style={{ display: isMobile ? "flex" : "" }}>
                <Grid item xs={5} xs={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.subHeading}
                  >
                    Guests:
                  </Typography>
                </Grid>
                <Grid item xs={7} md={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.heading}
                  >
                    {props.hotelSearch.roomGuests.reduce((total, guest) => {
                      return total + parseInt(guest.noOfAdults);
                    }, 0)}{" "}
                    Adults,{" "}
                    {props.hotelSearch.roomGuests.reduce((total, guest) => {
                      return total + parseInt(guest.noOfChild);
                    }, 0)}{" "}
                    Child
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} md={2}>
              <div style={{ display: isMobile ? "flex" : "" }}>
                <Grid item xs={5} xs={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.subHeading}
                  >
                    Total Cost:
                  </Typography>
                </Grid>
                <Grid item xs={7} md={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.heading}
                  >
                    {`${props.hotelSearch.preferredCurrency} ${totalCost(
                      location.state.selectedRooms
                    )}`}
                  </Typography>
                </Grid>
              </div>
            </Grid> */}

      {/* <Grid item xs={12} md={3}>
              <div
                className={classes.costContainer}
                style={{ display: isMobile ? "flex" : "" }}
              >
                <Grid item xs={5} md={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.totalCost}
                  >
                    Total Cost:
                  </Typography>
                </Grid>
                <Grid item xs={7} md={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.cost}
                    style={{ fontSize: isMobile ? 18 : 22 }}
                  >
                    {}
                    {`${props.hotelSearch.preferredCurrency} ${totalCost(
                      location.state.selectedRooms
                    )}`}
                  </Typography>
                </Grid>
              </div>
            </Grid> */}
      {/* </Grid>

          <Grid item xs={12}>
            <Typography
              component="h5"
              variant="h6"
              className={classes.sectionHeading}
            >
              Guest Details
            </Typography>
          </Grid> */}
      {/* <div
            style={{ padding: "0 .8rem", width: "100%", marginBottom: "1rem" }}
          >
          
          </div> */}
      {/* </Grid> */}
      {/* </Container> */}
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

export default withRouter(connect(mapStateToProps)(HotelBookingFormPage));

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    background: theme.palette.background.light,
    borderRadius: "0.25rem",
    border: "1px solid rgba(0,0,0,0.125)",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
    marginBottom: 25,
    "& .section-header": {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      marginBottom: 5,
    },
    "& .section-title-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .heading-icon": {
      borderRadius: "50%",
      background: theme.palette.background.disabled,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "& i": {
        fontSize: 16,
        color: theme.palette.text.secondary,
      },
    },
    "& .heading-text": {
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  "& .section-title-wrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .heading-icon": {
    borderRadius: "50%",
    background: theme.palette.background.disabled,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    "& i": {
      fontSize: 16,
      color: theme.palette.text.secondary,
    },
  },
  "& .heading-text": {
    textTransform: "uppercase",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  root: {
    padding: theme.spacing(0, 0, 3),
    backgroundColor: theme.palette.background.selago,
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.lightText,
    },
    [theme.breakpoints.down(960)]: {
      "& .divider": {
        display: "none",
      },
    },
    "& .section-container": {
      background: theme.palette.background.light,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      marginBottom: 25,
    },
  },

  header: {
    backgroundColor: theme.palette.background.carulean,
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.lightText,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "500",
    letterSpacing: theme.typography.pxToRem(1.33),
    textTransform: "uppercase",
    color: theme.palette.primary.lightText,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0),
      fontSize: theme.typography.pxToRem(20),
      textTransform: "capitalize",
      color: theme.palette.primary.defaultText,
    },
  },
  // [theme.breakpoints.down(960)]: {
  divider: {
    display: "none",
  },
  // },
  //
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "500",
    letterSpacing: theme.typography.pxToRem(1.33),
    textTransform: "uppercase",
    color: theme.palette.primary.lightText,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(15),
      textTransform: "capitalize",
      color: theme.palette.primary.defaultText,
    },
  },
  subHeading: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.83),
    lineHeight: theme.typography.pxToRem(10),
    textTransform: "uppercase",
    color: theme.palette.secondary.aegean,
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      display: "block",
      marginBottom: theme.spacing(1),
      color: theme.palette.secondary.sharkGray,
    },
  },
  heading__container: {
    margin: theme.spacing(1, 0, 1),
  },
  costContainer: {
    backgroundColor: theme.palette.background.peach,
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.lightText,
      textAlign: "right",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      textAlign: "left",
    },
  },
  totalCost: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: theme.typography.pxToRem(43),
    letterSpacing: theme.typography.pxToRem(2),
    color: theme.palette.primary.lightText,
    borderRadius: theme.typography.pxToRem(2),
    marginRight: theme.spacing(0.8),
    [theme.breakpoints.up("md")]: {
      display: "block",
      color: theme.palette.secondary.charcoal,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  cost: {
    fontWeight: 500,
    fontSize: 22,
    textAlign: "center",
    lineHeight: theme.typography.pxToRem(43),
    letterSpacing: theme.typography.pxToRem(1.9),
    color: theme.palette.primary.lightText,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(30),
      letterSpacing: theme.typography.pxToRem(1.9),
      color: theme.palette.background.peach,
    },
  },
  sectionHeading: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(1.2366),
    color: theme.palette.background.peach,
    textTransform: "uppercase",
    marginBottom: theme.typography.pxToRem(4),
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: theme.typography.pxToRem(0.97),
    color: theme.palette.secondary.charcoal,
    textTransform: "uppercase",
  },
  caption: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.secondary.charcoal,
  },

  agent__label: {
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: theme.typography.pxToRem(0.8833),
    color: theme.palette.secondary.sharkGray,
    marginBottom: theme.spacing(0.5),
    textTransform: "uppercase",
  },
  agent__value: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(1.2366),
    textTransform: "uppercase",
    color: theme.palette.secondary.charcoal,
    marginBottom: theme.spacing(3),
  },
  hotelInfo: {
    fontSize: 15,
    color: theme.palette.primary.blackPearl,
    // marginBottom: 5,
  },
  hotelName: {
    fontSize: 18,
    color: theme.palette.primary.blackPearl,
    // marginBottom: 10,
    fontWeight: 500,
  },
  infoGrid: {
    padding: "0 30px",
    background: "#eee",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
}));
