import React, { useRef, useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import Grid from "@material-ui/core/Grid";
import login from "assets/EasyTravelsOnline/img/templogin.jpg";
import { localforageGetItem } from "oautils/oaForageUtils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  carouselCaption: {
    marginTop: 50,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 15vw",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
    [theme.breakpoints.down(600)]: {
      padding: "16px 10vw",
    },
    [theme.breakpoints.down(484)]: {
      padding: "12px 6vw",
    },
  },

  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 40,
    color: theme.palette.secondary.contrastText,
    padding: 20,
    lineHeight: "50px",
    textAlign: "center",
    zIndex: 1,
    [theme.breakpoints.down(600)]: {
      padding: "16px 10vw",
      fontSize: 34,
      lineHeight: "40px",
    },
    [theme.breakpoints.down(484)]: {
      padding: "12px 6vw",
      fontSize: 30,
      lineHeight: "30px",
    },
  },
  text1: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    letterSpacing: "0.025em",
    color:theme.palette.secondary.contrastText,
    padding: 20,
    zIndex: 1,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(484)]: {
      fontSize: 14,
    },
  },

  slideBody: {
    width: "100%",
    height: 431,
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    backgroundPosition: "center !important",
    flexWrap: "nowrap",
    [theme.breakpoints.down(960)]: {
      height: "40vw !important",
    }
  },

  carouselContainer: {
    minHeight: 431,
    "& .rec-slider-container": {
      margin: "0 0 !important",
    },
    [theme.breakpoints.down(960)]: {
      minHeight: 80,
    }
  }

}));

export default function LpCarousel(props) {
  const classes = useStyles();
  const itemsPerPage = 1;
  const isMobile = props.isMobile;

  const carouselRef = useRef(null);

  const [items, setPromos] = useState(null);
  let resetTimeout;

  useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      value && setPromos(value.promos);
    });
  }, []);
  // console.log("items *********** : " + JSON.stringify(items));
  return (
    <div className={classes.carouselContainer}>
      <Carousel
        ref={carouselRef}
        enableAutoPlay
        pagination={false}
        autoPlaySpeed={2500} // same time
        showArrows={false}
        onNextEnd={({ index }) => {
          if (resetTimeout) clearTimeout(resetTimeout);
          if (index + itemsPerPage === items.length) {
            resetTimeout = setTimeout(() => {
              if (carouselRef.current) carouselRef.current.goTo(0);
            }, 2500); // same time
          }
        }}
        itemsToShow={itemsPerPage}
      >
        {items &&
          items.map((promo, idx) => (
            <Grid
              container
              justifyContent="flex-start"
              style={{
                background:
                  promo.imagePath !== null
                    ? "url(" +
                      GET_FILE_UPLOAD +
                      "?fileName=" +
                      promo.imagePath +
                      "&type=PROMO_IMAGES)"
                    : "url(" + login + ")"
                // background: `url(${GET_FILE_UPLOAD + "?fileName="+promo.imagePath+"&type=PROMO_IMAGES"})`,
              }}
              className={classes.slideBody}
              key={idx}
            >
              <Grid
                container
                item
                xl={12}
                md={12}
                xs={12}
                justifyContent="center"
                alignItems="flex-start"
                style={{
                  flexBasis: "100%",
                  maxWidth: "100%",
                  position: "relative",
                }}
              >
                <div className={classes.carouselCaption}>
                  <div className={classes.text}>{promo.title}</div>
                  <div className={classes.text1}>{promo.subTitle}</div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "rgba(0, 0, 0, 0.2)",
                  }}
                ></div>
              </Grid>
            </Grid>
          ))}
      </Carousel>
    </div>
  );
}
