import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { Grid, IconButton, useTheme } from "@material-ui/core";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import FilterListIcon from "@material-ui/icons/FilterList";
import FlightSearchFilter from "./flightSearchFilterInt";
import funnel from "assets/icons/funnel.png";
import { Close } from "@material-ui/icons";

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <React.Fragment>
        <Button
          onClick={() => setOpen(true)}
          className={classes.filterButton}
          disabled={props.disabled || false}
        >
          <>
            {/* <Typography
              style={{
                padding: "8px 24px",
                textTransform: "none",
                fontWeight: 700,
              }}
            >
              Add Filter
            </Typography> */}
            <img src={funnel} />
          </>
        </Button>
        {/* <Drawer
          open={open}
          onClose={() => setOpen(false)}
          className="flight-filter-drawer-root"
        > */}
        <Grid
          className="flight-filter-drawer-root"
          style={{
            display: !open && "none",
            position: "fixed",
            height: "100vh",
            top: 0,
            zIndex: 100,
            backgroundColor: "#fff",
          }}
        >
          {/* {list()} */}
          <div className="drawer-close-btn-holder">
            <IconButton
              onClick={() => setOpen(false)}
              className="drawer-close-button"
            >
              <Close />
            </IconButton>
          </div>
          <FlightSearchFilter {...props} />
          <Grid
            onClick={() => setOpen(false)}
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            className={classes.applyBtn}
          >
            APPLY
          </Grid>
        </Grid>

        {/* </Drawer> */}
      </React.Fragment>
      <style>
        {`
          .flight-filter-drawer-root .MuiPaper-root.MuiDrawer-paper {
            position: relative;
          }
          .drawer-close-btn-holder {
            width: 100%;
            text-align: left;
            display: flex;
            justify-content: flex-end;
            padding: 2px;
          }
          .drawer-close-button {
           
            cursor: pointer;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            z-index: 3;
          }
          .drawer-close-button:hover {
            color: ${theme.palette.secondary.main}
          }
          @media all and (max-width: 1499px) and (min-width: 992px) {
            .drawer-close-button {
              display: flex;
            }
          }
        `}
      </style>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  applyBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontWeight: 700,
    height: 50,
    alignItems: "center",
  },
  fullList: {
    width: "auto",
  },
  filterButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 30px 30px 0",
    width: 160,
    // marginTop: 30,
    [theme.breakpoints.down(400)]: {
      width: 100,
      fontSize: "16px",
    },
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.sub,
    },
  },
  /*filterRoot: {
    position: "relative",
    "& .drawer-close-btn-holder": {
      width: "100%",

      display: "flex",
      justifyContent: "flex-end",
      padding: 2,
    },
    "& .drawer-close-button": {
      cursor: "pointer",
      borderRadius: "50%",
      width: 35,
      height: 35,
      zIndex: 3,
    },
    "& .drawer-close-button:hover": {
      color: `${theme.palette.secondary.main}`,
    },
  },*/
}));
