import React, { useState, useEffect } from "react";
import useStyles from "components/Navbars/for-mobile/NavBarMobileStyles";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import cx from "classnames";

const NavBarMobile = ({ menuList }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar className={classes.root}>
      <Container className={classes.mobileNavContainer}>
        <Box className={classes.mobileNavItems}>
          {menuList.map((menu, index) => (
            <Box
              className={cx(
                classes.mobileNavItem,
                history.location.pathname == menu.path
                  ? classes.activeMenuItem
                  : history.location.pathname === "/" && index === 0
                  ? classes.activeMenuItem
                  : ""
              )}
              key={index}
            >
              <button onClick={menu.handleClick} className="mobile-nav-button">
                <div className="mobile-nav-menu-icon">
                  {menu.inactiveIcon ? (
                    <img
                      style={{ width: 30, height: 30 }}
                      src={
                        history.location.pathname == menu.path
                          ? menu.icon
                          : history.location.pathname === "/" && index === 0
                          ? menu.icon
                          : menu.inactiveIcon
                      }
                    />
                  ) : (
                    <img src={menu.icon} style={{ width: 30, height: 30 }} />
                  )}
                </div>
                <div
                  className="mobile-nav-menu-label"
                  style={{
                    color:
                      history.location.pathname == menu.path
                        ? "#DF3049"
                        : history.location.pathname === "/" && index === 0
                        ? "#DF3049"
                        : "black",
                    fontWeight: 700,
                  }}
                >
                  {menu.label}
                </div>
              </button>
            </Box>
          ))}
        </Box>
      </Container>
    </AppBar>
  );
};

export default NavBarMobile;
