import React, { useState, useEffect, useRef, useContext } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {
  Box,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Popper,
} from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Grid, Link, Button } from "@material-ui/core";
import dateFnsFormat from "date-fns/format";
import "assets/css/MainContent.css";
import "assets/css/css-variable.css";

import { localforageGetItem } from "oautils/oaForageUtils";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
//import _ from "lodash";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.darkText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
      fontSize: "18px !important",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
    },
    "& .MuiSelect-root": {
      padding: "10px 0",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
    "& .oa-form-element-card": { border: "none", margin: "0 !important" },
    "& .oa-form-element-label": {
      fontSize: 12,
      color: theme.palette.primary.lightGray,
      fontWeight: "900 !important",
      paddingLeft: 10,
      // textAlign:'center',
    },
    // autocomplete popper style
    "& .oa-form-element-primary .MuiAutocomplete-popper": {
      position: "absolute",
      width: "calc(100vw - 72px) !important",
      left: 36,
      "& .MuiAutocomplete-paper": {
        "& .MuiAutocomplete-listbox": {
          width: "100% !important",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .input-label": {
      fontSize: 12,
      color: theme.palette.primary.lightGray,
      fontWeight: "900 !important",
    },
    "& .form-group": {
      // margin: "10px 5px",
      padding: "10px",
      [theme.breakpoints.down(950)]: {
        padding: "10px 10px",
      },

      borderRight: "solid 0.5px #D8D8D8",
      cursor: "pointer",
      "& .oa-form-element-card": {
        border: "none",
        marginTop: 10,
        paddingLeft: 0,
      },
      "&:hover": {
        background: theme.palette.background.rgba_primary,
      },
      "& button": {},
      "& label": {
        fontWeight: 500,
        color: theme.palette.primary.lightGray,
        textTransform: "uppercase",
      },

      "& .input-element": {
        marginTop: "8px",
        padding: "10px 0 !important",
        "& .MuiToggleButton-sizeSmall": {
          height: 30,
          border: "none",
          marginRight: 10,
          padding: "5px 20px",
        },
        "& .Mui-selected": {
          backgroundColor: "#fff",
          marginRight: 10,
          borderRadius: 24,
          height: 30,
        },
      },
      "& .input-element--dropdown": {
        "& .input-element--textField": {
          display: "flex",
        },
        "& input": {
          width: 30,
          fontSize: "25px !important",
          // paddingTop: 5,
          paddingBottom: 5,
          // borderBottom: "1px solid #000",
          marginRight: 5,
        },
        "& p": {
          fontWeight: 700,
          minWidth: 200,
          [theme.breakpoints.down(1100)]: {
            fontSize: 11,
          },
        },
        "& span": {
          fontWeight: 700,
          fontSize: 18,
        },
      },
      "& .MuiButton-contained": {
        color: theme.palette.primary.lightText,
        backgroundColor: theme.palette.secondary.main,
        height: "100%",
        width: "100%",

        "&:hover, &:focus": {
          // backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "& .age-input": {
      position: "relative",
      cursor: "pointer",
      "& .passengerInput": {
        fontWeight: 700,
      },
    },
    "& .ageModal": {
      position: "absolute",
      backgroundColor: "#fff",
      zIndex: 999,
      boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
      borderRadius: 4,
      width: 200,
      top: 57,
      left: 10,
      zIndex: 999,
      padding: "20px 0px",
      "& .ageModal__form": {
        width: "100%",
        padding: "0px 20px",
      },
      "& .ageModal__submitBtn": {
        marginTop: 10,
        width: "100%",
        height: 25,
        "& .MuiButton-label": {
          fontSize: 14,
        },
      },
      "& .ageModal__passengerContainer": {
        height: 35,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 8,
        // marginBottom: 8,
        paddingLeft: 10,
        "& .MuiInputBase-input": {
          fontSize: "14px !important",
          border: "1px solid #dcdcdc",
          borderRadius: 5,
          padding: "2px 0px",
          paddingLeft: 7,
          paddingBottom: 0,
        },
        "& .passengerInput": {
          width: 40,
        },
        "& label": {
          fontWeight: 700,
        },
      },
      "& .ageModal__paxCountContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "0 20px",
        marginBottom: 15,
      },
      "& .ageModal__ageLabel": {
        display: "flex",
        justifyContent: "flex-end",
        borderTop: "solid 2px #eee",
        fontWeight: 500,
        paddingTop: 10,
        color: "#7E7979",
        fontSize: 12,
      },
      "& .ageModal__paxCountLabel": {
        fontSize: 14,
        fontWeight: 600,
      },
      "& .ageModal__paxCountInput": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #dcdcdc",
        borderRadius: 4,
        "& .ageModal__inputButton": {
          "& .MuiSvgIcon-root": {
            fontSize: "12px",
          },
          "& .MuiIconButton-root": {
            padding: 5,
          },
        },
        "& .ageModal__inputValue": {
          width: "30px",
          borderLeft: "1px solid #dcdcdc",
          fontFamily: theme.palette.font.primary,
          borderRight: "1px solid #dcdcdc",
          backgroundColor: "#f6f9ff",
          textAlign: "center",
          "& .MuiTypography-h6": {
            fontSize: "13px",
            fontFamily: theme.palette.font.primary,
            paddingTop: "3px",
          },
        },
      },
    },
  },

  InsuranceSectionContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    padding: 10,
    border:"1px solid #b1b1b1",
  },

  searchButtonBox: {
    width: "100%",
    height: "100%",
    // paddingBottom:'10px',
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      padding: 0,
      backgroundColor: theme.palette.secondary.darkOrange,
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
        fontSize: "18px",
        paddingBottom: "0.1rem",
      },
    },
  },

  InsuranceSection_radiogroup: {
    display: "flex",
    justifyContent: "space-between !important",
    flexWrap: "nowrap",
    flexDirection: "row",
    "& .MuiFormGroup-root": {
      marginBottom: "10px",
    },
    "& .MuiGrid-item": {
      width: "100%",
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
    "& .MuiRadio-root": {
      display: "none",
    },
    "& .MuiFormControlLabel-root": {
      width: "100%",
      justifyContent: "center",
      margin: 0,
      height: 27,
      color: "#000",
    },
    "& .MuiTypography-body1": {
      fontSize: 12,
    },

    "& .MuiFormControlLabel-root:has(.Mui-checked)": {
      width: "100%",
      backgroundColor: theme.palette.secondary.darkOrange,
      borderRadius: 4,
      "& .MuiFormControlLabel-label": {
        color: "#fff",
        fontWeight: "400",
        fontSize: 12,
      },
    },

    [theme.breakpoints.down(440)]: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  gridRespCol: {
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
    },
  },

  rowUpper: {
    // borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
    padding: "5px 0",
    marginBottom: "13px",
    justifyContent: "center",
  },

  InsuranceSection_searchBtn: {
    padding: "0px",

    "& .MuiGrid-spacing-xs-2": {
      width: "100%",
      margin: "-8px 0",
    },
    "& .search-btn-col": {
      width: "100%",
      height: "65px",
      padding: "22px",
      overflow: "hidden",
      backgroundColor: theme.palette.secondary.darkOrange,
      margin: 0,
      marginTop: "9.3px",
      borderRadius: "4px ",
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  InsuranceInfoGridRoot: {
    background: theme.palette.primary.lightText,

    padding: "1rem  0 10px 0",
    width: "100%",

    // borderRadius:'4px',
    "& .insurance-info-wrapper": { width: "100%" },

    "& label": {
      fontSize: 12,
      // fontFamily:theme.palette.font.primary,
      color: theme.palette.primary.darkText,
      textAlign: "center",
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiAutocomplete-listbox": {
      // width:'500px',
    },
    "& .MuiAutocomplete-popupIndicator .MuiIconButton-label": {
      color: theme.palette.primary.darkText,
    },

    "& .MuiAutocomplete": {
      textAlign: "center",
    },

    "& .grid-input-field": {
      maxWidth: "100%",
      flexBasis: "100%",
      padding: "6px 10px",
      margin: "0 0 10px 0px",
      borderRadius: "4px",
      background: theme.palette.background.whiteGreen,
      border: `2px solid ${theme.palette.background.lightYellowGreen}`,
    },
  },
}));

const MobileInsuranceSections = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [departDateDialogOpen, setDepartDateDialogOpen] = useState(false);
  const [dropdownAge, setDropdownAge] = useState(false);
  const [passCount, setPassCount] = useState(1);
  const [userType, setUserType] = useState();

  const [paxCount, setPaxCount] = useState();
  const [val, setVal] = useState();
  const { result: results, request, session_id } = props.history?.location?.state;

  const reqStartDate = new Date(request?.travelStartDate)
  const reqEndDate = new Date(request?.travelEndDate)

  const formikRef = useRef();
  const classes = useStyles();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const tripList = [
    { val: "SINGLE", text: "Single Trip" },
    { val: "MULTI", text: "Annual Multi Trip" },
  ];
  const diffDays = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      val === 1
        ? formikRef.current.values.paxAge.push(18)
        : formikRef.current.values.paxAge.pop();
      formikRef.current.setFieldValue(
        "adultPax",
        Number(formikRef.current.values.adultPax) + val
      );
    }
    if (type === "child") {
      formikRef.current.setFieldValue(
        "childPax",
        Number(formikRef.current.values.childPax) + val
      );
      val === 1
        ? formikRef.current.values.paxAge.push(17)
        : formikRef.current.values.paxAge.pop();
    }
    if (type === "infant") {
      formikRef.current.setFieldValue(
        "infantPax",
        Number(formikRef.current.values.infantPax) + val
      );
      val === 1
        ? formikRef.current.values.paxAge.push(2)
        : formikRef.current.values.paxAge.pop();
    }
    formikRef.current.setFieldValue(
      "paxCount",
      formikRef.current.values.paxAge.length
    );
  };

  const incrCount = () => setVal(1);
  const decrCount = () => setVal(-1);

  const customPopperFrom = function(props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };
  const customPopperTo = function(props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={arriveAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };



  return (
    <Box className={classes.root} m={4}>
      {/* oaFormAlert  */}
      {/* -----------  */}
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          // paxAge: Array(1).fill(18),
          paxAge: request.paxAge || "",
          paxAgeDef: 18,
          paxCount: request.paxCount||1,
          adultPax:1,
          childPax: 0,
          infantPax: 0,
          planCategory:request.planCategory || 1,
          planCoverage: request.planCoverage ||4,
          planType:request.planType || 1,
          travelStartDate:reqStartDate|| startDate,
          travelEndDate:reqEndDate || endDate,
          // travelStartDate:startDate,
          // travelEndDate:endDate,
          duration: 1,
        }}
        validationSchema={Yup.object().shape({
          paxAge: Yup.string().required("Enter Age"),
        })}
        onSubmit={(searchParams, { setSubmitting, resetForm }) => {
          localforageGetItem("user-details", function(err, details) {
            if (details != null) {
              // console.log("user-details", details);
              searchParams.partnerId = details.partnerId;
              searchParams.clientId = details.userId;
              searchParams.clientPassword = "";
              searchParams.clientType = details.userType;
              switch (details.userTypeAbv) {
                case "S": {
                  searchParams.clientType = "Corporate";
                  break;
                }
                case "G": {
                  searchParams.clientType = "Normal";
                  break;
                }
                default: {
                  searchParams.clientType = "Normal";
                  break;
                }
              }
            }
          });
          setSubmitting(true);
          props.history.push({
            pathname: "/insurance/search",
            state: {
              searchParams,
            },
          });
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          handleChange,
          errors,
          touched,
        }) => (
          <Form className={classes.InsuranceSectionFormContainer}>
            {/* -----------  */}
            {/* oaFormAlert  */}

            <Grid container className={classes.InsuranceSectionContainer}>
              <Grid
                container
                justifyContent="space-between"
                className={classes.rowUpper}
              >
                <Grid item xs={12}>
                  <Box>
                    <RadioGroup
                      className={classes.InsuranceSection_radiogroup}
                      value={values.planType === 1 ? "SINGLE" : "MULTI"}
                      name={`mode`}
                      onChange={(e) => {
                        //console.log("plantype", e.target.value);
                        if (e.target.value === "SINGLE") {
                          setFieldValue("planType", 1);
                        }
                        if (e.target.value === "MULTI") {
                          setFieldValue("planType", 2);
                        }
                      }}
                    >
                      {tripList.map((a) => (
                        <FormControlLabel
                          key={a.val}
                          value={a.val}
                          control={<Radio />}
                          label={a.text}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="insurance"
                      component="div"
                      className="error"
                    />
                  </Box>
                </Grid>
              </Grid>

              <div className="insurance-info-wrapper" style={{ width: "100%" }}>
                <Grid className={classes.InsuranceInfoGridRoot}>
                  <Grid container>
                    {/* DESTINATION */}
                    <Grid item xs={12} className="grid-input-field">
                      <label className="input-label">DESTINATION</label>
                      <div className="input-element">
                        <Select
                          label="Destination"
                          name="planCoverage"
                          value={values.planType == 1 ? values.planCoverage : 3}
                          onChange={(event) =>
                            setFieldValue("planCoverage", event.target.value)
                          }
                          variant="outlined"
                          fullWidth
                          inputProps={{ IconComponent: () => null }}
                        >
                          {values.planType == 1 &&
                            planCoverageOptions.map((val, ind) => (
                              <MenuItem value={val.value} key={ind}>
                                {val.label}
                              </MenuItem>
                            ))}
                          {values.planType == 2 &&
                            planCoverageOptions2.map((val, ind) => (
                              <MenuItem value={val.value} key={ind}>
                                {val.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                      <ErrorMessage
                        name="planCoverage"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    {/* INSURANCE CATEGORY */}
                    <Grid item xs={12} className="grid-input-field">
                      <label className="input-label">INSURANCE CATEGORY</label>
                      <div className="input-element">
                        <Select
                          name="planCategory"
                          value={values.planCategory}
                          onChange={(event) =>
                            setFieldValue("planCategory", event.target.value)
                          }
                          variant="outlined"
                          fullWidth
                          inputProps={{ IconComponent: () => null }}
                        >
                          {planCategoryOptions.map((val, ind) => (
                            <MenuItem value={val.value} key={ind}>
                              {val.lable}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <ErrorMessage
                        name="planCoverage"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    {/* DEPARTURE CATEGORY */}
                    <Grid item xs={12} className="grid-input-field form-group">
                      <label className="input-label">DEPARTURE</label>
                      <OaOutlinedDatePicker
                        showDisabledMonthNavigation
                        name={`travelStartDate`}
                        // minDate={startDate}
                        minDate={startDate}
                        customInput={
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            fullWidth={true}
                          />
                        }
                        selected={values.travelStartDate}
                        onChange={(e, v) => {
                          setDepartDateDialogOpen(false);
                          setFieldValue(`travelStartDate`, e);
                          if (
                            new Date(v).getTime() >
                            new Date(values.travelStartDate).getTime()
                          ) {
                            values.travelStartDate = new Date(
                              new Date(v).getTime() + 24 * 60 * 60 * 1000
                            ).toISOString();
                          }
                          setFieldValue(`travelEndDate`, e);
                          if (
                            new Date(v).getTime() >
                            new Date(values.travelEndDate).getTime()
                          ) {
                            values.travelEndDate = new Date(
                              new Date(v).getTime() + 24 * 60 * 60 * 1000
                            ).toISOString();
                          }
                        }}
                        monthsShown={1}
                        dateFormat="dd MMM yyyy"
                        showinput={true}
                        // secondarytxt={
                        //   days[values.sectorArray[0].onwardDateDisplay.getDay()]
                        // }
                      />
                      <ErrorMessage
                        name={`travelStartDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    {/* RETURN CATEGORY */}
                    {values.planType == 1 && (
                      <Grid
                        item
                        xs={12}
                        className="grid-input-field form-group"
                      >
                        <label className="input-label">RETURN</label>

                        <OaOutlinedDatePicker
                          showDisabledMonthNavigation
                          name={`travelEndDate`}
                          // minDate={startDate}
                          minDate={values.travelStartDate}
                          customInput={
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              // fullWidth={isMobileScreen}
                            />
                          }
                          selected={values.travelEndDate}
                          onChange={(e, v) => {
                            setDepartDateDialogOpen(false);
                            setFieldValue(`travelEndDate`, e);
                            if (
                              new Date(v).getTime() >
                              new Date(values.travelEndDate).getTime()
                            ) {
                              values.travelEndDate = new Date(
                                new Date(v).getTime() + 24 * 60 * 60 * 1000
                              ).toISOString();
                            }
                          }}
                          monthsShown={1}
                          dateFormat="dd MMM yyyy"
                          // secondarytxt={
                          //   days[values.sectorArray[0].onwardDateDisplay.getDay()]
                          // }
                          showinput={true}
                        />
                        <ErrorMessage
                          name="travelEndDate"
                          component="div"
                          className="error"
                        />
                      </Grid>
                    )}

                    {/* NO OF PASS CATEGORY */}
                    <Grid item xs={12} className="grid-input-field">
                      <label className="input-label">PAX & DURATION</label>
                      <div
                        className=" age-input "
                        style={{ position: "relative" }}
                      >
                        <Box
                          className="input-element--textField"
                          onClick={() => setDropdownAge(true)}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p>
                            <span>{values.paxCount}</span>{" "}
                            {values.paxCount > 1 ? "Passengers" : "Passenger "}{" "}
                            & <br></br>
                            Duration of{" "}
                            <span>
                              {diffDays(
                                values.travelStartDate,
                                values.travelEndDate
                              )}
                            </span>{" "}
                            {diffDays(
                              values.travelStartDate,
                              values.travelEndDate
                            ) > 1
                              ? "days "
                              : "day  "}
                          </p>
                          <i className="fas fa-chevron-down"></i>
                        </Box>

                        {dropdownAge && (
                          <Grid
                            item
                            className="ageModal"
                            container
                            direction="column"
                            justifyContent="space-evenly"
                            alignItems="center"
                            spacing={2}
                            style={{ position: "absolute" }}
                          >
                            <Box className="ageModal__paxCountContainer">
                              <div className="ageModal__paxCountLabel">
                                Pax Count
                              </div>
                              <Box className="ageModal__paxCountInput">
                                <Box className="ageModal__inputButton">
                                  <IconButton
                                    aria-label="decrement"
                                    onClick={(e) => {
                                      let value = +formikRef.current.values
                                        .paxCount;
                                      if (value === 0) return;
                                      if (value > 1) value--;

                                      setFieldValue("paxCount", value);
                                      setFieldValue(
                                        "paxAge",
                                        Array(value).fill(18)
                                      );
                                    }}
                                  >
                                    <RemoveIcon
                                      className="icon"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Box>
                                <Box className="ageModal__inputValue">
                                  <Typography variant="h6">
                                    {formikRef.current.values.paxCount}
                                  </Typography>
                                </Box>
                                <Box className="ageModal__inputButton">
                                  <IconButton
                                    aria-label="increament"
                                    onClick={(e) => {
                                      let value = +formikRef.current.values
                                        .paxCount;

                                      if (value > 0 && value < 6) {
                                        //console.log(value);
                                        value++;
                                      }

                                      setFieldValue("paxCount", value);
                                      setFieldValue(
                                        "paxAge",
                                        Array(value).fill(18)
                                      );
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>

                            <form
                              // onSubmit={handleSubmit}
                              className="ageModal__form"
                            >
                              <div className="ageModal__ageLabel">
                                Age(0.5-70)
                              </div>
                              {Array.from({
                                length: formikRef.current.values.paxCount,
                              }).map((inputField, ind) => (
                                <div
                                  key={ind + 1}
                                  className="ageModal__passengerContainer"
                                >
                                  <label>Pax {ind + 1} </label>
                                  <TextField
                                    name={`paxAge[${ind}]`}
                                    className="passengerInput"
                                    type="number"
                                    defaultValue={values.paxAge[ind] || 18}
                                    InputProps={{
                                      max: 70,
                                      min: 1,
                                      maxLength: 2,
                                      disableUnderline: true,
                                    }}
                                    onChange={(e) => {
                                      if (!e.target.value) return;
                                      var value = parseInt(e.target.value);
                                      if (value > 70) value = 70;
                                      if (value < 0.5) value = 1;

                                      setFieldValue(`paxAge[${ind}]`, value);
                                    }}
                                  />
                                </div>
                              ))}
                              <Button
                                className="ageModal__submitBtn"
                                variant="contained"
                                color="primary"
                                type="submit"
                                // endIcon={<Icon>send</Icon>}
                                onClick={() => {
                                  setDropdownAge(false);
                                }}
                              >
                                Save
                              </Button>
                            </form>
                          </Grid>
                        )}
                      </div>
                      <ErrorMessage
                        name="paxCount"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      className={classes.InsuranceSection_searchBtn}
                    >
                      <Grid
                        className={classes.gridRespCol}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item className="search-btn-col">
                          <Box className={classes.searchButtonBox}>
                            <Button type="submit">Search</Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default MobileInsuranceSections;

const planCoverageOptions = [
  { value: 4, label: "Domestic" },
  { value: 1, label: "US" },
  { value: 2, label: "Non-US" },
  { value: 5, label: "Asia" },
  { value: 6, label: "Canada" },
  { value: 7, label: "Australia" },
  { value: 8, label: "Schenegen Countries" },
];

const planCoverageOptions2 = [{ value: 3, label: "Worlwide" }];
const planCategoryOptions = [
  { value: 1, lable: "Domestic Travel Policy" },
  { value: 2, lable: "Overseas Travel Insurance" },
  { value: 3, lable: "Student Overseas Insurance" },
  { value: 4, lable: "Schengen Overseas Insurance" },
  { value: 5, lable: "Inbound Travel Policy" },
  { value: 6, lable: "Cancellation Insurance" },
];
