import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OaCard from 'oahoc/OaCard';
import { Formik, Form, ErrorMessage } from 'formik';
import OaFormRadio from 'oahoc/OaFormRadio';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';
import OaTabBar from 'oahoc/OaTabBar';
import {scrollToTop} from 'oautils/oaCommonUtils';
import dateFnsFormat from 'date-fns/format';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';

import OaFormSelectComboField from 'oahoc/OaFormSelectComboField';
import * as Yup from 'yup';
import {localforageGetItem} from 'oautils/oaForageUtils';
import OaFormTextField from 'oahoc/OaFormTextField';


const GroupBooking = (props) => {

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [airports, setAirports] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [intlCarriers, setIntlCarriers] = useState([]);
    const [domCarriers, setDomCarriers] = useState([]);
    const [passenger, setPassanger] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOneway, setIsOneway] = useState(false);
    const [isReturn, setIsReturn] = useState(true);
    const [fromCountry, setFromCountry] = useState('');
    const [toCountry, setToCountry] = useState('');
    

    let tabs = [
      { name: 'group-booking', label: 'Group Booking', url: '/admin/group-booking' },
    ];
    let currentTab = 'group-booking';

    const [collapsed, setCollapsed] = useState(true);
    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

  useEffect(() => {
    apiCall(WebApi.getAirports, {}, (response) => {
        if(response.success === true) {
            setAirports(response.data.airports);
            
            setDomCarriers(response.data.domCarriers);
            setIntlCarriers(response.data.intlCarriers);

            // let passenger = [];
            // for (let index = 0; index < 100; index++) {
            //   passenger.push({'key': index, 'value':index})
            // }
            
            // setPassanger(passenger);
            setIsLoading(false);
        } else {
            setIsError(true);
        }
    });
}, [isLoading]);

useEffect(()=>{
  if(!isLoading) {
    if(fromCountry == 'IN' && toCountry == 'IN') {
      setCarriers(domCarriers);
    } else if(fromCountry != '' && toCountry != '') {
      setCarriers(intlCarriers);
    }
  }
},[fromCountry, toCountry]);


  function changeHandler(event, selectedObject) {
     const id = event.currentTarget.id;
    
     if(id.includes("from")) {
      setFromCountry(selectedObject.countryCode);
     } else if(id.includes("to")) {
      setToCountry(selectedObject.countryCode);
     }
  }

    return(
        <>
        <OaCard className={'oa-card-primary'}>
          <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
         
          <GridItem xs={12} sm={12} md={12} lg={10}> 
          <OaCard>
            <OaFormAlerts 
                    isSaving={isSaving} 
                    isSuccess={isSuccess} 
                    isError={isError} 
                    infoText={infoText} 
                    setIsError={setIsError} 
                    setIsSuccess={setIsSuccess} 
                />
            <Formik 
                initialValues={{ 
                    fromAirport: '',
                    toAirport: '',
                    onwardDate: dateFnsFormat(new Date(), 'dd-MM-yyyy'),
                    onwardDateDisplay: new Date(),
                    returnDate: dateFnsFormat(new Date(), 'dd-MM-yyyy'),
                    returnDateDisplay: new Date(),
                    noAdult: '',
                    noChild: '',
                    noInfant: '',
                }}
                validationSchema={Yup.object().shape({
                    fromAirport: Yup.string().required('From Airport is required'),
                    toAirport: Yup.string().required('To Airport is required'),
                    noAdult: Yup.number().required('Number of Adult is required'),
                    noChild: Yup.number(),
                    noInfant: Yup.number(),
                    // prefAirlines: Yup.array.required('Atleast one preference required')
                })}
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                  setIsError(false);
                  setIsSuccess(false);
                  setSubmitting(true);
                  setIsSaving(true);
                  scrollToTop(0);
                  searchParams.fromDate = dateFnsFormat(searchParams.onwardDateDisplay, 'dd-MM-yyyy');
                  searchParams.toDate = dateFnsFormat(searchParams.returnDateDisplay, 'dd-MM-yyyy');
                 
                  searchParams.createdon = new Date();
                  searchParams.isOneway = isOneway;
                  searchParams.isReturn = isReturn;
                  // console.log(searchParams);


                  localforageGetItem('user-details', function (err, value) {

                    // console.log(value)

                    let data = "<html><head><style>td{padding:5px;}</style></head><body><table>";
                      data += "<tr><td colspan='5'>Hi Team</td></tr>";
                      data += "<tr><td colspan='5'></td></tr>";
                      data += "<tr><td colspan='5'>Please find below flight ticket group booking request, from our client.</td></tr>";
                      data += "<tr><td colspan='5'></td></tr>";
                      data += "<tr><td colspan='5'></td></tr>";
                      data += "<tr><td>From</td><td>"+searchParams.fromAirport+"</td><td>To</td><td>"+searchParams.toAirport+"</td><td></td></tr>";
                      
                      if(searchParams.isOneway) {
                        data += "<tr><td>Request Type</td><td>Oneway</td><td></td><td></td><td></td></tr>";
                        data += "<tr><td>Departure Date</td><td>"+String(searchParams.onwardDateDisplay).substring(0, 16)+"</td><td></td><td></td><td></td></tr>";
                      } else {
                        data += "<tr><td>Request Type</td><td>Return</td><td></td><td></td><td></td></tr>";
                        data += "<tr><td>Departure Date</td><td>"+String(searchParams.onwardDateDisplay).substring(0, 16)+"</td><td>Return Date</td><td>"+String(searchParams.returnDateDisplay).substring(0, 16)+"</td><td></td></tr>";
                      }

                      data += "<tr><td>No of Adult</td><td>"+searchParams.noAdult+"</td><td>No of Child</td><td>"+(searchParams.noChild ? searchParams.noChild : 0)+"</td><td>No of Infant</td><td>"+(searchParams.noInfant ? searchParams.noInfant : 0)+"</td></tr>";
                      data += "<tr><td>Preferred Airline</td><td>"+searchParams.prefAirlines+"</td><td></td><td></td><td></td></tr>";
                      data += "<tr><td colspan='5'></td></tr>";
                      data += "<tr><td colspan='5'></td></tr>";
                      data += "<tr><td>Thanks</td><td></td><td></td><td></td><td></td></tr>";
                      data += "<tr><td colspan='5'>"+value.corpname+" (GBK-"+value.userId+")</td></tr>";
                      data += "</table></body></html> ";

                    apiCall(WebApi.emailETicket, {'email': value.emailDistributor ? value.emailDistributor : value.email, 'body': data, 'subject': 'Flight ticket group booking request'}, function(response){
                      if(response.success === true){
                          setIsError(false);
                          setIsSuccess(true);
                          setIsSaving(false);
                          setInfoText('Thank you, Request registered successfully');
                      }else{
                          setIsError(true);
                          setIsSaving(false);
                          setInfoText('Sorry, Server busy try after sometime');
                      }
                  });
              });
          }}
                
                >
                {({ values, isSubmitting, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>
                      
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Group Details</h4>
 
                        <GridContainer>
                            <GridItem xs={6}>
                              <OaFormRadio label="One Way" name="isOneway" value="isOneway" checked={isOneway} onChange={()=>{setIsOneway(!isOneway);setIsReturn(!isReturn);}}/> 
                              <ErrorMessage name="isOneway" component="div" className="error" />
                            </GridItem>
                            <GridItem xs={6}>
                              <OaFormRadio label="Round Trip" name="isReturn" value="isReturn" checked={isReturn} onChange={()=>{setIsOneway(!isOneway);setIsReturn(!isReturn);}}/> 
                              <ErrorMessage name="isReturn" component="div" className="error" />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem  xs={6}>
                              <OaFormDateField 
                                    value={values.onwardDateDisplay}
                                    selected={values.onwardDateDisplay}
                                    onChange={date => setFieldValue('onwardDateDisplay', date)}
                                    label="Departure Date*"
                                    name="onwardDateDisplay"
                                    disablePast={true}
                                    disableFuture={false}
                              />
                              
                              <ErrorMessage name="onwardDate" component="div" className="error" />
                            </GridItem>

                            {isReturn && 
                              <GridItem  xs={6}>
                                <OaFormDateField 
                                      value={values.returnDateDisplay}
                                      selected={values.returnDateDisplay}
                                      onChange={date => setFieldValue('returnDateDisplay', date)}
                                      label="Return Date*"
                                      name="returnDateDisplay"
                                      disablePast={true}
                                      disableFuture={false}
                                />
                                <ErrorMessage name="returnDate" component="div" className="error" />
                            </GridItem>
                            }
                            
                        </GridContainer>

                        <GridContainer>

                            <GridItem  xs={6}>
                            <OaFormSelectComboField 
                                label="From Airport*"
                                name="fromAirport"
                                value={values.fromAirport}
                                options={airports}
                                optionValue={'cityName'} 
                                optionLabel={['cityName', 'countryCode', 'airportCode']}
                                required={true}
                                setFieldValue ={setFieldValue}
                                changeHandler={changeHandler}
                            />
                              <ErrorMessage name="fromAirport" component="div" className="error" />
                            </GridItem>
                            <GridItem  xs={6}>
                            <OaFormSelectComboField 
                                label="To Airport*"
                                name="toAirport"
                                value={values.toAirport}
                                options={airports}
                                optionValue={'cityName'} 
                                optionLabel={['cityName', 'countryCode', 'airportCode']}
                                required={true}
                                setFieldValue ={setFieldValue}
                                changeHandler={changeHandler}
                            />
                              <ErrorMessage name="toAirport" component="div" className="error"/>
                            </GridItem> 
                          </GridContainer>
                        
                        <GridContainer>
                          <GridItem  xs={4}>
                            <OaFormTextField required={true} label="Number of Adults" name="noAdult"/>
                            {/* <OaFormSelectField 
                                label="Number of Adults*"
                                name="noAdult"
                                value={values.noAdult}
                                options={passenger}
                                optionValue={'value'}
                                optionLabel={'key'}
                            /> */}
                            <ErrorMessage name="noAdult" component="div" className="error" />
                          </GridItem>
                        
                          <GridItem  xs={4}>
                            <OaFormTextField  label="Number of Childs" name="noChild"/>
                            {/* <OaFormSelectField 
                              label="Number of Childs*"
                              name="noChild"
                              value={values.noChild}
                              options={passenger}
                              optionValue={'value'}
                              optionLabel={'key'}
                            /> */}
                            <ErrorMessage name="noChild" component="div" className="error" />
                          </GridItem>

                          <GridItem  xs={4}>
                          <OaFormTextField  label="Number of Infants" name="noInfant"/>
                            {/* <OaFormSelectField 
                              label="Number of Infants*"
                              name="noInfant"
                              value={values.noInfant}
                              options={passenger}
                              optionValue={'value'}
                              optionLabel={'key'}
                            /> */}
                            <ErrorMessage name="noInfant" component="div" className="error" />
                          </GridItem>
                              
                        </GridContainer>

                        <GridContainer>
                        <GridItem  xs={6}>
                          <OaFormSelectComboField 
                                  label="Preffred Airlines*"
                                  name="prefAirlines"
                                  value={values.prefAirlines}
                                  options={carriers}
                                  optionValue={'description'} 
                                  optionLabel={[ 'description', 'name']}
                                  required={true}
                                  setFieldValue ={setFieldValue}
                              />
                                <ErrorMessage name="prefAirlines" component="div" className="error" />
                            </GridItem>
                        </GridContainer>
                      <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} label={'Submit'} inReset={resetForm}/>
                    </Form>
                )}
            </Formik>
            </OaCard>
          </GridItem>  
       
        </OaCard>
        </>
    )
}

export default GroupBooking;
