import React from "react"
import GridContainer from "components/Grid/GridContainer";
import { Button, Divider, Grid, Typography,useMediaQuery } from "@material-ui/core";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import Carousel, { consts } from "react-elastic-carousel";
import image from "assets/img/noti-bg1.jpg";
import image1 from "assets/img/noti-bg2.jpg";
import image2 from "assets/img/noti-bg3.jpg"
import safe from "assets/img/safe.png";
import loan from "assets/img/loan.png";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "./table.css"



function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? <ArrowBackIosIcon/> : <ArrowForwardIosIcon/>
    return (
      
      <p onClick={onClick} disabled={isEdge}  className="arrow-crousel-offer">
        {pointer}
      </p>
    )
  }
  
const useStyles = makeStyles((theme) => ({
    Grid: {
        marginLeft: "120px",
        marginRight: "120px",
        background: theme.palette.background.light,
        marginTop: "50px",
        boxShadow: `0px 0px 15px -1px ${theme.palette.background.lightFadedGray}`,
        padding: 30,
        borderRadius: 20,
        [theme.breakpoints.down('sm')]:{
          marginLeft: "10px !important",
          marginRight: "10px !important",
        },
      },
      typo1:{

        paddingLeft: "2%",marginBottom:"0.5rem",fontSize:"30px",color:theme.palette.primary.grayishBlue,fontWeight:500,
        [theme.breakpoints.down('sm')]:{
          fontSize:"20px", 
        }
      },
      typo2:{
      
        paddingLeft: "2%",marginBottom:"0.5rem",
        [theme.breakpoints.down('sm')]:{
          fontSize:"0.78rem", 
        }
      }
}))

const OfferPage=()=>{
    const classes=useStyles()
    
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
      <GridContainer className={classes.Grid}>
          <Grid item md={12}>
          <Typography className={classes.typo1}>
          A Perfect Holiday in Safest Hands
           </Typography>
           <Typography className={classes.typo2}>
           Experience unique holidaying with us. We believe in creating memories rather than just travelling. Come explore the thrill in holidaying.
          </Typography>
          </Grid>
        <Carousel
          itemsToShow={isMobile?1:3}
          // enableSwipe={true}
          // showArrows={false}
          // enableAutoPlay
          itemPadding={[0, 10, 0, 0]}
          pagination={false}
          renderArrow={myArrow}
        >
        
              <div
              style={{
                backgroundImage: "url(" + image + ")",
                width: "100%",
                height: "30vh",
                borderRadius:10,
              }}
            >
              <Grid container alignItems="center" justifyContent="center" alignContent="center" style={{marginTop:"20%",paddingLeft:15}}>
                 <Grid item md={3} >
                     <img src={safe}></img>
                 </Grid>
                 <Grid item md={9}>
                     
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:17,fontWeight:700,letterSpacing:4}}>Safe Travel</Typography>
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:12}}>Sanatized Cabs and Hotel Room</Typography>
                    
                 </Grid>
              </Grid>
            </div>

            
            <div
              style={{
                backgroundImage: "url(" + image1 + ")",
                width: "100%",
                height: "30vh",
                borderRadius:10,
              }}
            >
              <Grid container alignItems="center" justifyContent="center" alignContent="center" style={{marginTop:"20%",paddingLeft:15}}>
                 <Grid item md={3} >
                     <img src={safe}></img>
                 </Grid>
                 <Grid item md={9}>
                     
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:17,fontWeight:700,letterSpacing:4}}>Customized Holiday</Typography>
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:12}}>Tailor made holiday packages</Typography>
                    
                 </Grid>
              </Grid>
            </div>


            <div
              style={{
                backgroundImage: "url(" + image2 + ")",
                width: "100%",
                height: "30vh",
                borderRadius:10,
              }}
            >
              <Grid container alignItems="center" justifyContent="center" alignContent="center" style={{marginTop:"20%",paddingLeft:15}}>
                 <Grid item md={3} >
                     <img src={loan}></img>
                 </Grid>
                 <Grid item md={9}>
                     
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:17,fontWeight:700,letterSpacing:4}}>EMI Options Available</Typography>
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:12}}>Book your holiday with easy EMI's </Typography>
                    
                 </Grid>
              </Grid>
            </div>


            <div
              style={{
                backgroundImage: "url(" + image + ")",
                width: "100%",
                height: "30vh",
                borderRadius:10,
              }}
            >
              <Grid container alignItems="center" justifyContent="center" alignContent="center" style={{marginTop:"20%",paddingLeft:15}}>
                 <Grid item md={3} >
                     <img src={safe}></img>
                 </Grid>
                 <Grid item md={9}>
                     
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:17,fontWeight:700,letterSpacing:4}}>Safe Travel</Typography>
                         <Typography style={{color:theme.palette.primary.lightText,fontSize:12}}>Sanatized Cabs and Hotel Room</Typography>
                    
                 </Grid>
              </Grid>
            </div>


         
        </Carousel>
      </GridContainer>
    );
}

export default OfferPage;