import React, { useEffect } from "react";
import { makeStyles, Grid, Button, Typography } from "@material-ui/core";
import FlightSearchFilter from "./flightSearchFilterInt";
import MultiCitySection from "./MultiCitySection";
import FlightSearchHeader from "./FlightSearchHeader";
import FlightSearchHeaderMultiCity from "./FlightSearchHeaderMultiCity";
import OaLoading from "pages/components/OaLoading";
import NonMUIIntFlightCard from "./NonMUIIntFlightCard";
import FilterDrawer from "./FilterDrawerInt";
import PlaceholderView from "./view/PlaceholderView";
import LazyLoad from "react-lazyload";
import modify from "assets/icons/modify.png";
import FlightSearchSortInt from "./FlightSearchSortInt";
import clsx from "clsx";
import { ToggleButton } from "@material-ui/lab";
import { ErrorOutlineRounded } from "@material-ui/icons";

const FlightSearchComRes = (props) => {
  const propState = props.history.location.state;
  const tripMode = propState?.request?.mode;
  const classes = useStyles();
  const width = window.innerWidth;
  const breakpoint = 980;

  const [isModifying, setIsModifying] = React.useState(false);
  const [combineResponse, setCombineResponse] = React.useState(
    propState?.combineResp
  );
  const [selectedFareId, setSelectedFareId] = React.useState(null);
  const [isReturnSelected, setIsReturnSelected] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateCombinedResponse, setUpdateCombinedResponse] = React.useState(
    propState?.combineResp
  );
  const [showNetFare, setShowNetFare] = React.useState(false);

  let intitvals = propState.requestObj;
  useEffect(() => {
    window.sessionStorage.removeItem("fetching");
  }, []);

  const cancelModify = (isCancelModify) => {
    if (isCancelModify) {
      setIsModifying(false);
    }
  };

  const onChangeComResp = (comResp) => {
    // console.log("combined response updated", comResp);
    setCombineResponse(comResp);
  };

  const toggleShowNetFare = (flag) => {
    setShowNetFare(flag);
  };
  const toggleNetFareDisplay = () => {
    toggleShowNetFare(!showNetFare);
  };

  React.useEffect(() => {
    // console.log("Flight search result combined response props", propState);
    setCombineResponse(propState?.combineResp);
    // console.log(tripMode);
    // console.log(propState?.combineResp);
  }, []);
  const NoResultView = () => {
    return (
      <Grid container direction="row" className={classes.noResultContainer}>
        <Grid item className={classes.noResults}>
          <ErrorOutlineRounded />
          <Typography>No Results found</Typography>
        </Grid>
      </Grid>
    );
  };
  const isResults = propState?.combineResp?.length > 0;

  return isResults ? (
    <div className={classes.root}>
      {tripMode && tripMode === "MULTI-CITY" ? (
        <FlightSearchHeaderMultiCity
          isModifyingVal={isModifying}
          isCancelModify={(val) => cancelModify(val)}
          showNetFare={showNetFare}
          toggleShowNetFare={toggleShowNetFare}
          tripMode={tripMode}
          {...props}
        />
      ) : (
        <FlightSearchHeader
          isModifyingVal={isModifying}
          isCancelModify={(val) => cancelModify(val)}
          showNetFare={showNetFare}
          toggleShowNetFare={toggleShowNetFare}
          tripMode={tripMode}
          {...props}
        />
      )}

      <div style={{ margin: "5px 0" }}>
        <Grid
          container
          direction="row"
          className={classes.searchResultContainer}
        >
          <Grid item className={classes.FilterGridItem}>
            {props.history.location.state?.combineResp && (
              <FlightSearchFilter
                tripMode={tripMode}
                combineresp={props.history.location.state.combineResp}
                setmodifiedresponse={onChangeComResp}
                filters={props.history.location.state.filters}
                setIsLoading={setIsLoading}
              />
            )}
          </Grid>

          <Grid item style={{ flexGrow: 1 }}>
            <div className={classes.FilterDrawer}>
              {props.history.location.state?.combineResp && (
                <FilterDrawer
                  tripMode={tripMode}
                  combineresp={props.history.location.state.combineResp}
                  setmodifiedresponse={onChangeComResp}
                  filters={props.history.location.state.filters}
                  setIsLoading={setIsLoading}
                />
              )}
              <div
                className={classes.modifyButton}
                onClick={() => setIsModifying(!isModifying)}
              >
                <div className={classes.flightSearchCTA}>
                  <span>{isModifying ? "x" : <img src={modify} />}</span>
                </div>
              </div>
            </div>
            {tripMode && tripMode === "MULTI-CITY" ? <MultiCitySection /> : ""}
            {width > breakpoint && intitvals.partnerId === 1002 && (
              <Grid
                item
                className={clsx(classes.flightSearchElement, "horz-flex")}
                md={12}
                xs={12}
              >
                <ToggleButton
                  value={true}
                  selected={showNetFare}
                  onChange={toggleNetFareDisplay}
                  title={showNetFare ? "hide net fare" : "show net fare"}
                  className={"tnf-button " + (showNetFare ? "active" : "")}
                >
                  {showNetFare ? "HNF" : "SNF"}
                </ToggleButton>
              </Grid>
            )}

            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.separator}></div>

                <div className={classes.sortContainer}>
                  <FlightSearchSortInt
                    tripMode={tripMode}
                    // data={props.history?.location?.state?.combineResp}
                    data={combineResponse}
                    setModifiedResponse={onChangeComResp}
                    filters={props.history?.location?.state?.filters}
                    showNetFare={showNetFare}
                    setIsLoading={setIsLoading}
                    type="onward"
                    {...props}
                  />
                  {/*tripMode === "ROUND" && (
                    <FlightSearchSortInt
                      tripMode={tripMode}
                      data={props.history?.location?.state?.combineResp}
                      setModifiedResponse={onChangeComResp}
                      filters={props.history?.location?.state?.filters}
                      showNetFare={showNetFare}
                      setIsLoading={setIsLoading}
                      type="return"
                      {...props}
                    />
                  )*/}
                </div>
                <div className={classes.searchResultColumn}>
                  {combineResponse && combineResponse.length > 0 ? (
                    combineResponse.map((value, index) => (
                      <>
                        {/* {width < breakpoint ? */}
                        <LazyLoad
                          once={true}
                          key={index}
                          height={200}
                          //offset={[50, 0]}
                          placeholder={<PlaceholderView />}
                          debounce={10}
                          unmountIfInvisible={true}
                        >
                          <NonMUIIntFlightCard
                            fullWidth={true}
                            selected={selectedFareId}
                            flightData={value}
                            setselectdfareid={(val) => setSelectedFareId(val)}
                            tripMode={tripMode}
                            request={
                              props?.history?.location?.state?.requestObj
                            }
                            isloading={(flag) => setIsLoading(flag)}
                            sessionid={
                              props?.history?.location?.state?.sessionId
                            }
                            showNetFare={showNetFare}
                          />
                        </LazyLoad>

                        {/* :
                        <NonMUIIntFlightCard
                          fullWidth={true}
                          selected={selectedFareId}
                          flightData={value}
                          setselectdfareid={(val) => setSelectedFareId(val)}
                          tripMode={tripMode}
                          request={props?.history?.location?.state?.requestObj}
                          isloading={(flag) => setIsLoading(flag)}
                          sessionid={props?.history?.location?.state?.sessionId}
                          showNetFare={showNetFare}
                        />

                      } */}
                      </>
                    ))
                  ) : (
                    <NoResultView />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {isLoading == true && <LoaderView comloader={props.comloader} />}
    </div>
  ) : (
    <div className={classes.root}>
      <FlightSearchHeader
        isModifyingVal={isModifying}
        isCancelModify={(val) => cancelModify(val)}
        showNetFare={showNetFare}
        toggleShowNetFare={toggleShowNetFare}
        tripMode={tripMode}
        {...props}
      />

      {width < breakpoint && (
        <div className={classes.FilterDrawer}>
          {propState?.combineResp && (
            <FilterDrawer
              tripMode={tripMode}
              combineresp={propState?.combineResp}
              setmodifiedresponse={onChangeComResp}
              filters={propState?.filters}
              setIsLoading={setIsLoading}
              disabled={true}
            />
          )}
          <div
            className={classes.modifyButton}
            onClick={() => setIsModifying(!isModifying)}
          >
            <div className={classes.flightSearchCTA}>
              <span>{isModifying ? "x" : <img src={modify} />}</span>
            </div>
          </div>
        </div>
      )}

      <div style={{ margin: "6px 0" }}>
        <NoResultView />
      </div>
    </div>
  );
};

export default FlightSearchComRes;

const LoaderView = (props) => {
  return (
    <div className="loader-view">
      {/* <OaLoading loadertext="please wait ..." /> */}
      <div>
        <img src={props.comloader} />
      </div>
      <div>
        <span className="loader-text">Please wait</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      // backgroundColor: theme.palette.background.light,
      // paddingLeft: 10,
      // paddingRight: 10,
      paddingBottom: "20px",
      width: 1440,
      [theme.breakpoints.down(1440)]: {
        width: "100%",
      },
      "& .loader-view": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 103,
        background: "rgba(0,0,0,0.5)",
        "& img": {
          height: "7rem",
        },
        "& .loader-text": {
          color: theme.palette.buttons.default,
          fontWeight: 600,
          textAlign: "center",
        },
      },
    },
    FilterGridItem: {
      minWidth: 250,
      maxWidth: 250,
      marginTop: 5,
      marginLeft: 5,
      [theme.breakpoints.down(1080)]: {
        // display: tripMode
        display: "none",
      },
      // FilterGridItem: {},
    },
    FilterDrawer: {
      width: "100%",
      zindex: "101",
      marginBottom: "6px",
      [theme.breakpoints.up(1080)]: {
        display: "none",
      },
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 5,
      [theme.breakpoints.down(960)]: {
        marginBottom: 0,
        marginTop: 0,
      },
    },

    flightSearchCTA: {
      height: "40px",
      width: "160px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "30px 0 0 30px",
      cursor: "pointer",
      display: "grid",
      placeItems: "center",
      [theme.breakpoints.down(400)]: {
        width: "100px",
        fontSize: "16px",
      },
      // padding: "17px 13px 34px",
      "& span": {
        width: "100%",
        fontSize: "20px",
        fontWeight: "600",
        color: theme.palette.primary.contrastText,
        textAlign: "center",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
      },
    },
    modifyButton: {
      [theme.breakpoints.up(1620)]: {
        display: "none",
      },
    },

    searchResultColumn: {
      padding: "0 5px",
      display: "flex",
      flexDirection: "column !important",
      flexWrap: "nowrap !important",
    },
    searchResultContainer: {
      flexWrap: "nowrap",
      minHeight: "80vh",
      width: "100%",
    },
    noResultContainer: {
      flexWrap: "nowrap",
      width: "100%",

      minHeight: "calc(80vh - 100px)",
      justifyContent: "center",
      alignItems: "center",
    },
    noResults: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    separator: {
      display: "none",
      [theme.breakpoints.down(400)]: {
        display: "block",
        borderTop: `1px solid ${theme.palette.primary.main}`,
        margin: "10px 0",
        width: "100%",
      },
    },
    sortContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 5px",
      "& select": {
        border: `solid 0.5px #aaaaaa`,
        borderRadius: 3,
        color: "#333",
        padding: "2px 3px",
      },
      [theme.breakpoints.down(400)]: {
        justifyContent: "space-between",
      },
    },
    separator: {
      display: "none",
      [theme.breakpoints.down(400)]: {
        display: "block",
        borderTop: `1px solid ${theme.palette.primary.main}`,
        margin: "10px 0",
        width: "100%",
      },
    },
    sortContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      //background: theme.palette.primary.main,
      [theme.breakpoints.down(1280)]: {
        padding: "10px 5px",
      },
      [theme.breakpoints.down(400)]: {
        justifyContent: "space-between",
      },
    },
    flightSearchElement: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 10px",
      marginBottom: 5,
      "&.horz-flex": {
        display: "flex",
      },
      [theme.breakpoints.down(550)]: {
        "&.horz-flex": {
          gridColumn: "1/span 3",
          width: "100% !important",
          justifyContent: "center",
          borderTop: `solid 0.1px`,
          margin: "2px 0 -5px 0",
          padding: "5px 0 0 0",
        },
      },
      "& .tnf-button": {
        fontWeight: 600,
        fontFamily: theme.palette.font.secondary,
        color: "auto",
        padding: "0 5px !important",
        background: theme.palette.buttons.default,
        color: theme.palette.buttons.defaultContrast,
        "&.active": {
          color: theme.palette.buttons.default,
          background: theme.palette.buttons.defaultContrast,
        },
      },
    },
  };
});
