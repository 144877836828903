// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import 'isomorphic-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import WebApi from "api/ApiConstants";
import {Formik, Form, ErrorMessage, Field } from 'formik';

export const OaFormFieldAgentList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loadedList, setLoadedList] = React.useState([]);
  const loading = open && options.length === 0;

  let agencyId = React.createRef();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    stringify: option => option.agencyName + ' ' + option.agencyId, // what gets searched is defined here
  });

  function changeHandler(event, selectedObject) {
    //console.log(selectedObject); // this object should be returned to the parent
    if(props.changeHandler && selectedObject !== null){
      props.changeHandler(selectedObject);
    }
    if (selectedObject !== null) {
      props.setFieldValue("agencyId", selectedObject.agencyId);
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (loadedList.length === 0) {
        await WebApi.getAgentListForDropdown({}, (response) => {
          setOptions(response.data.map(row => row));
          setLoadedList(response.data);
        })
      }
      if (active) {
        setOptions(loadedList.map(row => row));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, loadedList]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Autocomplete
        id="agencyId"
        /*fullWidth={true}*/
        name="agencyId"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={changeHandler}
        getOptionSelected={(option, value) => option.agencyId === value.agencyName}
        getOptionLabel={option => String(option.agencyName + " (" + option.userIdentity + ")")}
        options={options}
        loading={loading}
        autoHighlight={true}
        autoComplete={true}
        filterOptions={filterOptions}
        renderInput={params => (
          <>
          <TextField
            {...params}
            label={props.label}
            ref={agencyId}
            fullWidth={true}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              name: 'agencyId',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password"
            }}
          />
          <ErrorMessage name="agencyId" component="div" className="error"/>
          </>
        )}
      />
    </React.Fragment>
  );
}

export default OaFormFieldAgentList;
{/**
onChange={ (e, value) => props.setFieldValue("agencyId", value.agencyId) } 
*/}
