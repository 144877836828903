import React, {useEffect, useState} from 'react';
import OaTable from 'components/OaTable'
import WebApi, {DOWNLOAD_DMT_PDF, DOWNLOAD_DMT_XLS} from "api/ApiConstants";
import {currencyFormatter} from 'oautils/oaCommonUtils';
import OaButtonPDF from 'pages/components/OaButtonPDF';
import OaButtonXls from 'pages/components/OaButtonXls';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import {withRouter} from 'react-router-dom';
import {localforageSetItem, localforageGetItem} from 'oautils/oaForageUtils';
import _ from 'lodash';
import MoneyTransferDetail from './MonetTransferDetail';

const MoneyTransferSearchResult = (props) => {

    function onSelectMenu(event, data){
        switch(event){
            case "Invoice With Out Operator Charges":
                props.history.push('/admin/voucher/dmt-without-charges/' + data.dmtReferenceNo);
            break;
            case "Invoice With Operator Charges":
                props.history.push('/admin/voucher/dmt-with-charges/' + data.dmtReferenceNo);
            break;
            case "Refund Transaction":
                props.history.push('/dmt/transaction/' + data.serialNo);
            break;  
        }
    }

    const dropDownMenu = [
        "Invoice With Out Operator Charges",
        "Invoice With Operator Charges",
        "Refund Transaction"
    ];

    const dropDownMenu1 = [
        "Invoice With Out Operator Charges",
        "Invoice With Operator Charges"
    ];

    function round(value, decimals) {
        return Number(Math.round(value +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
    }
    
    useEffect(() => {
            localforageGetItem('user-details', function (err, value) {
                if(err == null && value!=null){
                    if(value.userType === "distributor" ||  value.userType === "company"){
                        
                    }
                    if(value.userType === "agent"){
                     
                    }
                }
            });
    }, [])

    const [total, setTotal] = useState(0);

    const doTotal = (amt) => {
        let totalAmt = total + amt;
        setTotal(totalAmt);
        return amt;
    }

    let cols = [];
    cols.push( {
        Header: '#',
        Cell: props => <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{props.index + 1}</div>,
        width: 35,
        filterable: false
    },{
        Header: "Transaction ID",
        accessor: "serialNo",
        width: 200,
        Cell: props => (
                <div style={{height: 44,verticalAlign:'middle'}}>
                    <CustomDropdown
                        buttonText ={props.value}
                        buttonProps={{
                            fullWidth: true,
                            style: { height: 32,padding:4 },
                            color: "rose"
                        }}
                        onClick={(event) => {
                            onSelectMenu(event, props.original);
                        }}
                        dropPlacement="top-start"
                        dropdownList={props.original.transactionStatusCode === 5 ? dropDownMenu : dropDownMenu1}
                    />
                </div>
        )
    },{
        Header: "Tx Date",
        width: 210,
        accessor: "transactionDate",
        filterable: false,
        Cell: props => (<div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{props.value}</div>)
    });
    
    if(props.agencyId === undefined){
        cols.push({
            Header: "Mobile",
            accessor: "mobile",
            width: 100,
            Cell: props => (<div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{props.value}</div>)
        },{
            Header: "Reference No",
            width: 130,
            accessor: "dmtReferenceNo",
            Cell: props => (<div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{props.value}</div>)
        });
    }
    
    cols.push(
    {
        Header: "Acknowledgement No",
        accessor: "dmtAcknowledgementNo",
        width: 170,
        Cell: props => (<div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{props.value}</div>)
    },
    {
        Header: "Status",
        width: 150,
        filterable: false,
        Cell: props => (
            // <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{ props.original.transactionStatus}</div>
            <MoneyTransferDetail status={props.original.transactionStatus} detail={props.original.detail}></MoneyTransferDetail>
        ),
        Footer: info => <span style={{fontWeight: "bold"}}>Transactions: {info.data.length}</span>
    },
    
    {
        Header: "Transfer Amount (INR)",
        width: 150,
        filterable: false,
        Cell: props => (
            <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{ round(parseFloat(props.original.amount),2)}</div>
        ),
        Footer: info => <span style={{fontWeight: "bold",display: "block", textAlign: "right"}}>{currencyFormatter(_.sum(_.map(info.data, d => d._original.amount-d._original.refundAmount)))}</span>
    },{
        Header: "Operator Charges (INR)",
        filterable: false,
        Cell: props => (
            <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{ round(parseFloat(props.original.companyCharges) + 
                parseFloat(props.original.rnfiCharges) + parseFloat(props.original.distributorCharges),2)}</div>
        ),
        Footer: info => <span style={{fontWeight: "bold",display: "block", textAlign: "right"}}>{currencyFormatter(_.sum(_.map(info.data, d => d._original.companyCharges - d._original.refundCompanyCharges + d._original.rnfiCharges - d._original.refundRnfiCharges + d._original.distributorCharges-d._original.refundDistributorCharges)))}</span>
    }
    ,{
        Header: "Agent Charges (INR)",
        filterable: false,
        Cell: props => (
            <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{ round(parseFloat(props.original.agentCharges) ,2)}</div>
        ),
        Footer: info => <span style={{fontWeight: "bold",display: "block", textAlign: "right"}}>{currencyFormatter(_.sum(_.map(info.data, d => d._original.agentCharges-d._original.refundAgentCharges)))}</span>
    },{
        Header: "Total Amount (INR)	",
        filterable: false,
        Cell: props => (<div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>{currencyFormatter(round(parseFloat(props.original.amount) + parseFloat(props.original.totalCommission),2))}</div>
        ),
        Footer: info => <span style={{fontWeight: "bold",display: "block", textAlign: "right"}}>{currencyFormatter(_.sum(_.map(info.data, d => d._original.amount-d._original.refundAmount+d._original.totalCommission - d._original.refundTotalCommission)))}</span>
});
    
    return(
        <>
            <OaButtonPDF api={DOWNLOAD_DMT_PDF} searchParams={props.searchParams} />
            <OaButtonXls api={DOWNLOAD_DMT_XLS} searchParams={props.searchParams} />
            <OaTable
                columns={cols}
                search = {props.searchParams}
                loader = {WebApi.getDmtReport}
                filterable = {true}
                rows
            />
        </>     
    )
}

export default withRouter(MoneyTransferSearchResult);
