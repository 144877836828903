import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  MenuItem,
  Select,
  Collapse,
  Grid,
  makeStyles,
} from "@material-ui/core";
import LeftFilter from "components/HotelPage/HotelComponents/LeftNavFilter/LeftFilter";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import OaCard from "oahoc/OaCard";

const useStyles = makeStyles((theme) => ({
  textInputCard: {
    "& .MuiFormControl-root": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      // paddingBottom: 8,
      marginTop: -5,
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      color: theme.palette.primary.blue,
      fontSize: 14,
    },
    "& .MuiInputBase-root": {
      borderBottom: "none",
      "& .MuiIconButton-label .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-colorSecondary.MuiInput-underline:after": {
      borderBottomColor: theme.palette.secondary.main,
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
      borderBottom: "none",
    },
    "& .MuiInput-underline": {
      borderBottom: "none",
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      padding: "5px 0 12px",
    },
    "& .MuiAutocomplete-clearIndicator": {
      visibility: "visible",
    },
  },
}));

export default function LeftNavFilter(props) {
  const [checkedValues, setCheckedValues] = useState([]);
  const hotelFilters = props?.filters;
  const hotelLocation = [];
  const hotelName = [];
  const [hotelSelected, setHotelSelected] = useState(null);
  const classes = useStyles();
  const nameFilterInput = useRef();
  const [filters, setFilters] = useState({
    price: false,
    rating: false,
    locality: false,
  });
  const [priceRange, setPriceRange] = useState([]);
  const [rating, setRating] = useState([]);
  const [locality, setLocality] = useState([]);

  // filter

  useEffect(() => {
    let filteredData = props.hotelSearchResult;

    if (filters.price) {
      filteredData = filterByPrice(priceRange, filteredData);
    }
    if (filters.rating) {
      filteredData = filterByRating(rating, filteredData);
    }
    if (filters.locality) {
      filteredData = filterByLocality(locality, filteredData);
    }

    props.setFilteredData({ data: filteredData });
  }, [priceRange, rating, locality]);

  const filterByPrice = (priceRange, arrayData) => {
    let filteredData = null;
    if (priceRange.length > 0) {
      filteredData = arrayData.filter((data) => {
        let flag = false;
        for (let i = 0; i < priceRange.length; i++) {
          const ref = hotelFilters.rates.find(
            (rate) => rate.labelString.replaceAll("INR", "₹") === priceRange[i]
          );
          flag =
            data.price.commissionCharge.grossProductPrice >= ref.minRate &&
            data.price.commissionCharge.grossProductPrice <= ref.maxRate;
          /* switch (priceRange[i]) {
            case "Upto INR 2000":
              flag =
                data.price.commissionCharge.grossProductPrice >= 0 &&
                data.price.commissionCharge.grossProductPrice <= 2000;
              break;
            case "INR 2001 - INR 4000":
              flag =
                data.price.commissionCharge.grossProductPrice >= 2001 &&
                data.price.commissionCharge.grossProductPrice <= 4000;
              break;
            case "INR 4001 - INR 6000":
              flag =
                data.price.commissionCharge.grossProductPrice >= 4001 &&
                data.price.commissionCharge.grossProductPrice <= 6000;
              break;
            case "INR 6001 - INR 8000+":
              flag = data.price.commissionCharge.grossProductPrice >= 6001;
              break;
            default:
              break;
          } */
          if (flag) break;
        }
        return flag;
      });
    } else {
      filteredData = arrayData;
    }

    return filteredData;
  };
  const filterByRating = (rating, arrayData) => {
    let filteredData = null;
    if (rating.length > 0) {
      filteredData = arrayData.filter((data) => {
        return rating.includes(Number(data.starRating));
        /* let flag = false;
        for (let i = 0; i < rating.length; i++) {
          switch (rating[i]) {
            case "5":
              flag = data.starRating == "5";
              break;
            case "4":
              flag = data.starRating == "4";
              break;
            case "3":
              flag = data.starRating == "3";
              break;
            case "2":
              flag = data.starRating == "2";
              break;
            case "1":
              flag = data.starRating == "1";
              break;
            default:
              break;
          }
          if (flag) break;
        }
        return flag; */
      });
    } else {
      filteredData = arrayData;
    }

    return filteredData;
  };
  const filterByLocality = (locality, arrayData) => {
    let filteredData = null;
    if (locality.length > 0) {
      filteredData = arrayData.filter((data) => {
        let flag = false;
        for (let i = 0; i < locality.length; i++) {
          flag = data.hotelAddress.includes(locality[i]);
          if (flag) break;
        }
        return flag;
      });
    } else {
      filteredData = arrayData;
    }

    return filteredData;
  };

  props.hotelSearchResult.map((hotel) => {
    const arr = hotel.hotelAddress.split(",").map((s) => s.trim());
    if (!hotelLocation.includes(arr[1])) {
      if (arr[1]) {
        hotelLocation.push(arr[1]);
      }
    }
    // if (!hotelLocation.includes(hotel.hotelAddress.split(",")[1])) {
    //   hotelLocation.push(hotel.hotelAddress.split(",")[1]);
    // }

    if (!hotelName.includes(hotel.hotelName)) {
      hotelName.push(hotel);
    }
  });

  function handleSelect(checkedName, type) {
    if (type === "Price Range") {
      let priceArr = [];
      if (priceRange.includes(checkedName)) {
        priceArr = priceRange.filter((price) => price !== checkedName);
      } else {
        priceArr = priceRange.concat(checkedName);
      }

      setPriceRange(priceArr);
      if (priceArr.length > 0) {
        setFilters({ ...filters, price: true });
      } else {
        setFilters({ ...filters, price: false });
      }
    }
    if (type === "Star Rating") {
      let ratingArr = [];
      if (rating.includes(checkedName)) {
        ratingArr = rating.filter((rate) => rate !== checkedName);
      } else {
        ratingArr = rating.concat(checkedName);
      }
      setRating(ratingArr);
      if (ratingArr.length > 0) {
        setFilters({ ...filters, rating: true });
      } else {
        setFilters({ ...filters, rating: false });
      }
    }
    if (type === "Locality") {
      let localityArr = [];
      if (locality.includes(checkedName)) {
        localityArr = locality.filter((locality) => locality !== checkedName);
      } else {
        localityArr = locality.concat(checkedName);
      }
      setLocality(localityArr);
      if (localityArr.length > 0) {
        setFilters({ ...filters, locality: true });
      } else {
        setFilters({ ...filters, locality: false });
      }
    }

    const newNames = checkedValues?.some(
      (value) => value.checkedName === checkedName
    )
      ? checkedValues?.filter((name) => {
        return name.checkedName !== checkedName;
      })
      : [...(checkedValues ?? []), { checkedName, type }];
    setCheckedValues(newNames);

    // filterPriceData(newNames);
    props.setFilterChanged(true);
    if (newNames.length === 0) {
      props.setIsFiltered(false);
    } else {
      props.setIsFiltered(true);
    }
    return newNames;
  }

  const filterPriceData = (newNames) => {
    let data = props.hotelSearchResult;
    let filteredData = [];
    filteredData = data.filter(dataFilter, { newNames: newNames });
    // console.log(newNames);

    props.setFilteredData({ data: filteredData });
  };

  function dataFilter(data) {
    let isPricePass = true;
    let isLocationPass = true;
    let isRatingPass = true;
    let isHotelNamePass = true;

    for (let i = 0; i < this.newNames.length; i++) {
      if (this.newNames[i].type == "Price Range") {
        if (this.newNames[i].checkedName == "Upto INR 2000") {
          if (
            data.price.commissionCharge.grossProductPrice >= 0 &&
            data.price.commissionCharge.grossProductPrice <= 2000
          )
            isPricePass = true;
          else isPricePass = false;
        } else if (this.newNames[i].checkedName == "INR 2001 - INR 4000") {
          if (
            data.price.commissionCharge.grossProductPrice >= 2001 &&
            data.price.commissionCharge.grossProductPrice <= 4000
          )
            isPricePass = true;
          else isPricePass = false;
        } else if (this.newNames[i].checkedName == "INR 4001 - INR 6000") {
          if (
            data.price.commissionCharge.grossProductPrice >= 4001 &&
            data.price.commissionCharge.grossProductPrice <= 6000
          )
            isPricePass = true;
          else isPricePass = false;
        } else if (this.newNames[i].checkedName == "INR 6001 - INR 8000") {
          if (data.price.commissionCharge.grossProductPrice > 6000)
            isPricePass = true;
          else isPricePass = false;
        }
      }
      if (this.newNames[i].type === "Star Rating") {
        if (this.newNames[i].checkedName == "5") {
          if (data.starRating == "5") isRatingPass = true;
          else isRatingPass = false;
        } else if (this.newNames[i].checkedName == "4") {
          if (data.starRating == "4") isRatingPass = true;
          else isRatingPass = false;
        } else if (this.newNames[i].checkedName == "3") {
          if (data.starRating == "3") isRatingPass = true;
          else isRatingPass = false;
        } else if (this.newNames[i].checkedName == "2") {
          if (data.starRating == "2") isRatingPass = true;
          else isRatingPass = false;
        } else if (this.newNames[i].checkedName == "1") {
          if (data.starRating == "1") isRatingPass = true;
          else isRatingPass = false;
        }
      }

      if (this.newNames[i].type == "Locality") {
        if (data.hotelAddress.includes(this.newNames[i].checkedName))
          isLocationPass = true;
        else isLocationPass = false;
      }
    }
    if (isPricePass && isLocationPass && isRatingPass && isHotelNamePass) {
      return true;
    }
  }

  const filterNameData = (filteredNameObject) => {
    let data = props.hotelSearchResult;

    if (filteredNameObject != null) {
      let filteredData = data.filter((val) => {
        return val.hotelCode == filteredNameObject.hotelCode;
      });
      // console.log("filterNameData", filteredData);
      props.setFilteredData({ data: filteredData });
      props.setIsFiltered(true);
    }
  };

  useEffect(() => {
    const close = nameFilterInput.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    close.addEventListener("click", () => {
      props.setIsFiltered(false);
    });
  }, []);

  // console.log({hotelLocation})

  return (
    <Grid container>
      <Grid item xs={12} style={{ padding: "0 0 0 0" }}>
        <OaCard className={classes.textInputCard}>
          <Autocomplete
            name={`hotelName`}
            options={props.hotelSearchResult || []}
            getOptionLabel={(o) => o.hotelName}
            getOptionSelected={(option, value) =>
              setHotelSelected(option.hotelName)
            }
            value={hotelSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                color="secondary"
                placeholder="Hotel Name"
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: `10 !important` },
                }}
              />
            )}
            onChange={(event, newValue) => filterNameData(newValue, event)}
            onKeyUp={(event) => {
              // console.log(event.target.value)
              if (event.target.value == "") {
                props.setIsFiltered(false);
              }
            }}
            ref={nameFilterInput}
          />
        </OaCard>
      </Grid>
      <Grid item xs={12} md={12} style={{ padding: "0 0 0 0" }}>
        {/*<LeftFilter
          {...props}
          data={["5", "4", "3", "2", "1"]}
          label={"Star Rating"}
          type="rating"
          handleSelect={handleSelect}
          checkedValues={checkedValues}
        ></LeftFilter>*/}
        <LeftFilter
          {...props}
          data={hotelFilters.stars.sort().reverse()}
          label={"Star Rating"}
          type="rating"
          handleSelect={handleSelect}
          checkedValues={checkedValues}
        ></LeftFilter>
      </Grid>

      <Grid item xs={12} style={{ padding: "0 0 0 0" }}>
        {/*<LeftFilter
          {...props}
          data={[
            "Upto INR 2000",
            "INR 2001 - INR 4000",
            "INR 4001 - INR 6000",
            "INR 6001 - INR 8000+",
          ]}
          label={"Price Range"}
          handleSelect={handleSelect}
          checkedValues={checkedValues}
        ></LeftFilter>*/}
        <LeftFilter
          {...props}
          data={hotelFilters.rates.map((rate) =>
            rate.labelString.replaceAll("INR", "₹")
          )}
          label={"Price Range"}
          handleSelect={handleSelect}
          checkedValues={checkedValues}
        ></LeftFilter>
      </Grid>

      <Grid item xs={12} style={{ padding: "0 0 0 0" }}>
        <LeftFilter
          {...props}
          data={hotelLocation}
          label={"Locality"}
          handleSelect={handleSelect}
          checkedValues={checkedValues}
        ></LeftFilter>
      </Grid>
    </Grid>
  );
}
