import React from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Grid, Typography } from "@material-ui/core";
import { COLORS } from "assets/css/CssConstants";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer";
import { withRouter } from "react-router";
import FlightSearchHeader from "./FlightSearchHeader";
import dateFnsFormat from "date-fns/format";
import OaLoading from "pages/components/OaLoading";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "9px",
        padding: 6,
      },
    },
  },
  heading: {
    marginTop: 2,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginRight: 10,
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  text1: {
    color: "blue",
    textDecoration: "underline",
    fontSize: "small",
    cursor: "pointer",
  },
  text2: {
    color: theme.palette.primary.lightText,
    background: theme.palette.background.rosePink,
    padding: 3,
    fontSize: "small",
  },
  dot: {
    height: 15,
    width: 15,
    background: theme.palette.primary.main,
    borderRadius: "50%",
  },
  flightDetail: {
    border: `1px Solid ${theme.palette.background.lightgray}`,
    padding: "30px 30px 15px 30px",
    borderRadius: 5,
  },
  text3: {
    fontSize: "smaller",
    color: theme.palette.primary.defaultText,
  },
  text4: {
    fontSize: "small",
  },
  text5: {
    fontSize: "x-large",
    fontWeight: "bold",
  },
  text6: {
    color: theme.palette.primary.defaultText,
    fontSize: "small",
  },
}));

const FlightDetailCard = (props) => {
  const classes = useStyles();
  // console.log(props)
  const { flightDetails } = props;
  return flightDetails ? (
    <div>
      <div style={{ borderRadius: 0 }} className={classes.root}>
        <CardBody>
          <Grid container spacing={2} className="detailContainer">
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <Grid item xs={6} md={6}>
                  <Typography variant="h6" className={classes.heading}>
                    Flight Details
                  </Typography>
                </Grid> */}
              {/* <Grid item xs={6} md={6} container justifyContent="flex-end">
                  {!props.isHideBackButton &&
                    <Button className={classes.button} onClick={() => props.history.goBack()}>Back to Search Page</Button>}

                </Grid> */}
            </Grid>
            <hr style={{ width: "100%", margin: 0 }} />
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: 12 }}
            >
              <Grid item xs={12} md={!props.isHideBackButton ? 4 : 12}>
                <Typography>
                  {`${flightDetails.originAirportCode}  -  ${
                    flightDetails.destinationAirportCode
                  } ${flightDetails.arrivalDay}, ${dateFnsFormat(
                    new Date(flightDetails.departureDate),
                    "do MMM yyyy"
                  )}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {/* <span className={classes.text1}>Baggage and Fare Rules</span>{" "} */}
                  <span className={classes.text2}>
                    {flightDetails.isRefundable == true
                      ? "REFUNDABLE"
                      : "NON-REFUNDABLE"}
                  </span>
                </Typography>{" "}
              </Grid>
            </Grid>
            <GridContainer>
              <Card style={{ padding: 20 }}>
                <GridContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6} md="auto">
                    <img
                      src={
                        BASE_URL_IMAGE_VIEW + "agency/view-file" +
                        "?fileName=" +
                        flightDetails.carrierCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      height={50}
                    />
                    <Typography className={classes.text3}>
                      {" "}
                      {flightDetails.airlineName}{" "}
                    </Typography>
                    <Typography
                      className={classes.text3}
                    >{`(${flightDetails.carrierCode}-${flightDetails.flightNo})`}</Typography>
                  </Grid>
                  <Grid item xs={6} md="auto">
                    <Typography
                      className={classes.text4}
                    >{`Departure `}</Typography>
                    <Typography className={classes.text4}>{`${
                      flightDetails.arrivalDay
                    } ${dateFnsFormat(
                      new Date(flightDetails.departureDate),
                      "do MMM yyyy"
                    )}`}</Typography>
                    <Typography
                      className={classes.text5}
                    >{`${flightDetails.originAirportCode} ${flightDetails.flightStartTime}`}</Typography>
                    <Typography className={classes.text3}>
                      {" "}
                      {flightDetails.flightOriginCity}
                    </Typography>
                    <Typography
                      className={classes.text3}
                    >{`( Terminal ${flightDetails.flightOriginTerminal} )`}</Typography>
                  </Grid>
                  <Grid item xs={6} md="auto">
                    <Typography className={classes.text5}>
                      {flightDetails.flightDuration}
                    </Typography>
                    <Typography className={classes.text3}>
                      Flight Duration
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md="auto">
                    <Typography
                      className={classes.text4}
                    >{`Arrival `}</Typography>
                    <Typography className={classes.text4}>{`${
                      flightDetails.departureDay
                    } ${dateFnsFormat(
                      new Date(flightDetails.arrivalDate),
                      "do MMM yyyy"
                    )}`}</Typography>
                    <Typography
                      className={classes.text5}
                    >{`${flightDetails.destinationAirportCode} ${flightDetails.flightLandingTime}`}</Typography>
                    <Typography className={classes.text3}>
                      {" "}
                      {flightDetails.flightDesitnationCity}
                    </Typography>
                    <Typography
                      className={classes.text3}
                    >{`( Terminal ${flightDetails.flightDestinationTerminal} )`}</Typography>
                  </Grid>
                </GridContainer>
              </Card>
            </GridContainer>
          </Grid>
        </CardBody>
      </div>
    </div>
  ) : (
    <OaLoading />
  );
};

export default withRouter(FlightDetailCard);
