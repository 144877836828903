import React, { useState, useEffect } from "react";
import logo from "assets/lookMyTicket/images/logo/logo.png";
// import bus from "assets/img/bus.png";
import dateFnsFormat from "date-fns/format";
// import { apiCall } from "components/vendor/Oari/oautils/oaDataUtils";
// import WebApi from "api/ApiConstants";
import "assets/css/BusTicket.css";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import { makeStyles, useTheme } from "@material-ui/core";

const InsuranceTicketResult = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  const [basefare, setBasefare] = useState(0.0);
  const [taxes, setTaxes] = useState(0.0);
  const [gst, setGst] = useState(0.0);
  const [total, setTotal] = useState(0.0);

  const { isAddressHide, isFareHide, isPerPassenger, isGSTHide } = props.state;

  useEffect(() => {
    setRenderData(props.data);
  }, [props]);

  const setRenderData = (data) => {
    let _basefare = 0;
    let _taxes = 0;
    let _gst = 0;
    let _total = 0;

    // console.log("props.passenger " , props.passenger);

    setBasefare(_basefare);
    setTaxes(
      _taxes +
        parseFloat(
          isPerPassenger
            ? props.markup /
                data.GetBookingDetailResult.Itinerary.Passenger.length
            : props.markup
        )
    );
    setGst(_gst);
    setTotal(
      _total +
        parseFloat(
          isPerPassenger
            ? props.markup /
                data.GetBookingDetailResult.Itinerary.Passenger.length
            : props.markup
        )
    );
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div
      // style={{ borderBottom: `2px solid ${theme.palette.secondary.fireBrick}` }}
      // className="print-view-style"
      className={classes.root}
    >
      {/* ========= Header n Insurance Voucher */}
      <section className={"section-header"}>
        <div
        // style={{
        //   maxWidth: "1140px",
        //   width: "100%",
        //   paddingRight: "15px",
        //   paddingLeft: "15px",
        //   marginRight: "auto",
        //   marginLeft: "auto",
        //   boxSizing: "border-box",
        // }}
        >
          <div className="top-section">
            <div>
              {/* <img src={logo} style={{height: '50px', float: 'left', verticalAlign: 'middle', borderStyle: 'none'}} /> */}
              {props.data.GetBookingDetailResult?.agencyInfo &&
              props.data.GetBookingDetailResult?.agencyInfo?.logo ? (
                <img
                  src={
                    GET_FILE_UPLOAD +
                    "?fileName=" +
                    props.data?.GetBookingDetailResult?.agencyInfo?.logo +
                    "&type=AGENT_LOGO&loggedInUserId=" +
                    props.data.GetBookingDetailResult?.agencyInfo?.agencyId
                  }
                  alt="fight icon"
                  className="agencyInfo-logo"
                />
              ) : (
                <img src={logo} className="agencyInfo-logo" />
              )}
            </div>
            <div className="titleContainer">
              <h3>
                <b
                  style={{
                    verticalAlign: "middle",
                    // fontWeight: "bolder",
                    boxSizing: "border-box",
                  }}
                >
                  Insurance Voucher
                </b>
              </h3>
            </div>
            {!isAddressHide && (
              <div className="addressContainer">
                <div style={{ float: "right", boxSizing: "border-box" }}>
                  <h5>
                    <b>
                      {props.data.GetBookingDetailResult?.agencyInfo
                        ?.agencyName || "OARI AGENCY"}
                    </b>
                  </h5>
                  <span className="address-text">
                    {props.data.GetBookingDetailResult?.agencyInfo?.address ||
                      "ADDRESS"}
                    ,{" "}
                    {props.data.GetBookingDetailResult?.agencyInfo?.city ||
                      "CITY"}
                    ,{" "}
                    {props.data.GetBookingDetailResult?.agencyInfo?.state ||
                      "STATE"}
                  </span>
                  <br />
                  <span className="address-text">
                    {props.data?.GetBookingDetailResult?.agencyInfo?.email ||
                      "EMAIL"}
                  </span>
                  <br />
                  <span className="address-text">
                    {props.data?.GetBookingDetailResult?.agencyInfo?.mobileNo ||
                      "923923392"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* =================INSURANCE ID============= */}
      <div>
        <div>
          <div>
            <div className={"section"}>
              <div className="insurance-id-section">
                <span className="text">INSURANCE ID</span>
                <br />
                <h5>
                  <b>{props.data?.insuranceId}</b>
                </h5>
              </div>
              <div>
                <span className="text">Booking Reference</span>
                <br />
                <h5>
                  <b>{props.data?.paxInfo[0].referenceId}</b>
                </h5>
              </div>
              <div>
                <span className="text">Policy Number</span>
                <br />
                <h5>
                  <b>{props.data?.paxInfo[0].policyNo || "AAAXXX"}</b>
                </h5>
              </div>
              <div>
                <span className="text">Issue Date</span>
                <br />
                <h5>
                  <b>
                    {/* {dateFnsFormat(
                      new Date(
                        props.data?.GetBookingDetailResult.Itinerary.InvoiceCreatedOn
                      ),
                      "do MMM yyyy"
                    )} */}
                    {props.data?.createdOn
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}
                  </b>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ paddingBottom: "0px ", paddingTop: "0px " }}>
        <div
          style={{
            maxWidth: "1140px",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              backgroundColor: theme.palette.secondary.fireBrick,
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                padding: "1rem ",
                display: "flex",
                flexWrap: "wrap",
                marginRight: "-15px",
                marginLeft: "-15px",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  boxSizing: "border-box",
                }}
              >
                <b
                  style={{
                    fontSize: "80%",
                    fontWeight: 400,
                    color: theme.palette.primary.citrusOrange,
                  }}
                >
                  Departure
                </b>
                <br />
                <h4
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: "1.5rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {props.data?.GetBookingDetailResult.Itinerary.Origin}
                  </b>
                </h4>
                <h5
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: "1.25rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  {dateFnsFormat(
                    new Date(
                      props.data?.GetBookingDetailResult.Itinerary.DepartureTime
                    ),
                    "hh:m a"
                  )}
                </h5>
                <span
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: "80%",
                    fontWeight: 400,
                  }}
                >
                  {dateFnsFormat(
                    new Date(
                      props.data.GetBookingDetailResult.Itinerary.DepartureTime
                    ),
                    "do MMM yyyy"
                  )}
                </span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  boxSizing: "border-box",
                }}
              >
                <img src={bus} style={{ height: "117px" }} />
              </div>
              <div
                style={{
                  textAlign: "right",
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  boxSizing: "border-box",
                }}
              >
                <b
                  style={{
                    fontSize: "80%",
                    fontWeight: "bolder",
                    color: theme.palette.primary.citrusOrange,
                    boxSizing: "border-box",
                  }}
                >
                  Arrival
                </b>
                <br />
                <h4
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: "1.5rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {props.data.GetBookingDetailResult.Itinerary.Destination}
                  </b>
                </h4>
                <h5
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: "1.25rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  {dateFnsFormat(
                    new Date(
                      props.data.GetBookingDetailResult.Itinerary.ArrivalTime
                    ),
                    "hh:m a"
                  )}
                </h5>
                <span
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: "80%",
                    fontWeight: 400,
                    boxSizing: "border-box",
                  }}
                >
                  {dateFnsFormat(
                    new Date(
                      props.data.GetBookingDetailResult.Itinerary.ArrivalTime
                    ),
                    "do MMM yyyy"
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div style={{ paddingTop: "0px ", paddingBottom: "1rem " }}>
        <div
          style={{
            maxWidth: "1140px",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              backgroundColor: theme.palette.background.snowWhite,
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginRight: "-15px",
                marginLeft: "-15px",
                padding: "1rem ",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  textAlign: "left",
                  boxSizing: "border-box",
                }}
              >
                <span style={{ fontSize: "80%", fontWeight: 400 }}>
                  ID Card Type
                </span>
                <br />
                <h5
                  style={{
                    fontSize: "1.25rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {props.data?.GetBookingDetailResult?.Itinerary?.Passenger[0]
                      ?.IdType || "MW/42422"}
                  </b>
                </h5>
              </div>
              <div
                style={{
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  textAlign: "center",
                  boxSizing: "border-box",
                }}
              >
                <span style={{ fontSize: "80%", fontWeight: 400 }}>
                  ID Card Number
                </span>
                <br />
                <h5
                  style={{
                    fontSize: "1.25rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {props.data?.GetBookingDetailResult?.Itinerary?.Passenger[0]
                      ?.IdNumber || "222222222222"}
                  </b>
                </h5>
              </div>
              <div
                style={{
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  textAlign: "right",
                  boxSizing: "border-box",
                }}
              >
                <span style={{ fontSize: "80%", fontWeight: 400 }}>
                  Service ID
                </span>
                <br />
                <h5
                  style={{
                    fontSize: "1.25rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {props.data?.GetBookingDetailResult?.Itinerary
                      ?.InvoiceNumber || "MW/2223/1857"}
                  </b>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ============== Insurance Details========== */}
      <div className="insurance-details">
        <div
        // style={{
        //   maxWidth: "1140px",
        //   width: "100%",
        //   paddingRight: "15px",
        //   paddingLeft: "15px",
        //   marginRight: "auto",
        //   marginLeft: "auto",
        //   boxSizing: "border-box",
        // }}
        >
          <h5
          // style={{
          //   color: theme.palette.secondary.fireBrick,
          //   fontSize: "1.25rem",
          //   marginBottom: ".5rem",
          //   fontWeight: 500,
          //   lineHeight: "1.2",
          //   marginTop: 0,
          //   boxSizing: "border-box",
          // }}
          >
            <b>Insurance Details</b>
          </h5>

          <div className="section">
            <div className="h5-teg">
              <b className="text">Plan Name</b>
              <br />
              <h6 className="h6-teg">
                <b>
                  {/* {props.data.GetBookingDetailResult.Itinerary.TravelName} */}
                  {props.data.planName}
                </b>
                <br />
                <span className="text">
                  {/* {props.data.GetBookingDetailResult.Itinerary.BusType} */}
                  BUS TYPE
                </span>
              </h6>
            </div>
            <div
            // style={{
            //   flex: "0 0 66.666667%",
            //   maxWidth: "66.666667%",
            //   position: "relative",
            //   width: "100%",
            //   paddingRight: "15px",
            //   paddingLeft: "15px",
            //   textAlign: "left",
            //   boxSizing: "border-box",
            // }}
            >
              <b className="text">Plan Type</b>
              <br />
              <h6
              // style={{
              //   fontSize: "1rem",
              //   marginBottom: ".5rem",
              //   fontWeight: 500,
              //   lineHeight: "1.2",
              //   marginTop: 0,
              //   boxSizing: "border-box",
              // }}
              >
                <b>
                  {props.data.planType}
                  {/* CITY LOCATION */}
                </b>
              </h6>
            </div>
            <div
            // style={{
            //   flex: "0 0 33.333333%",
            //   maxWidth: "33.333333%",
            //   position: "relative",
            //   width: "100%",
            //   paddingRight: "15px",
            //   paddingLeft: "15px",
            //   textAlign: "left",
            //   boxSizing: "border-box",
            // }}
            >
              <b className="text">Policy Start Date</b>
              <br />
              <h6
              // style={{
              //   fontSize: "1rem",
              //   marginBottom: ".5rem",
              //   fontWeight: 500,
              //   lineHeight: "1.2",
              //   marginTop: 0,
              //   boxSizing: "border-box",
              // }}
              >
                <b>
                  {dateFnsFormat(
                    new Date(props.data.policyStartDate),
                    "hh:mm a"
                  )}
                </b>
              </h6>
            </div>
            <div
            // style={{
            //   flex: "0 0 33.333333%",
            //   maxWidth: "33.333333%",
            //   position: "relative",
            //   width: "100%",
            //   paddingRight: "15px",
            //   paddingLeft: "15px",
            //   textAlign: "left",
            //   boxSizing: "border-box",
            // }}
            >
              <b style={{ fontSize: "80%", fontWeight: 400 }}>
                Policy End Date
              </b>
              <br />
              <h6
              // style={{
              //   fontSize: "1rem",
              //   marginBottom: ".5rem",
              //   fontWeight: 500,
              //   lineHeight: "1.2",
              //   marginTop: 0,
              //   boxSizing: "border-box",
              // }}
              >
                <b>
                  {dateFnsFormat(new Date(props.data.policyEndDate), "hh:mm a")}
                </b>
              </h6>
            </div>

            {/* {props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails
              .CityPointContactNumber && (
              <div
                style={{
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  textAlign: "left",
                  boxSizing: "border-box",
                }}
              >
                <b style={{ fontSize: "80%", fontWeight: 400 }}>
                  Helpline Number
                </b>
                <br />
                <h6
                  style={{
                    fontSize: "1rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {
                      props.data.GetBookingDetailResult.Itinerary
                        .BoardingPointdetails.CityPointContactNumber
                    }
                  </b>
                </h6>
              </div>
            )}
            {props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails
              .CityPointAddress && (
              <div
                style={{
                  flex: "0 0 33.333333%",
                  maxWidth: "33.333333%",
                  position: "relative",
                  width: "100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  textAlign: "left",
                  boxSizing: "border-box",
                }}
              >
                <b style={{ fontSize: "80%", fontWeight: 400 }}>Address</b>
                <br />
                <h6
                  style={{
                    fontSize: "1rem",
                    marginBottom: ".5rem",
                    fontWeight: 500,
                    lineHeight: "1.2",
                    marginTop: 0,
                    boxSizing: "border-box",
                  }}
                >
                  <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                    {
                      props.data.GetBookingDetailResult.Itinerary
                        .BoardingPointdetails.CityPointAddress
                    }
                  </b>
                </h6>
              </div>
            )} */}
          </div>
        </div>
      </div>

      {/* {!isGSTHide && props.data.GetBookingDetailResult.gstDetail && (
        <div style={{ paddingBottom: "0px ", paddingTop: "0px " }}>
          <div
            style={{
              maxWidth: "1140px",
              width: "100%",
              paddingRight: "15px",
              paddingLeft: "15px",
              marginRight: "auto",
              marginLeft: "auto",
              boxSizing: "border-box",
            }}
          >
            <h5
              style={{
                color: theme.palette.secondary.fireBrick,
                fontSize: "1.25rem",
                marginBottom: ".5rem",
                fontWeight: 500,
                lineHeight: "1.2",
                marginTop: 0,
                boxSizing: "border-box",
              }}
            >
              <b style={{ fontWeight: "bolder", boxSizing: "border-box" }}>
                GST Details
              </b>
            </h5>
            <table
              style={{
                border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                width: "100%",
                marginBottom: "1rem",
                color: theme.palette.secondary.darkGrayishBlue,
                borderCollapse: "collapse",
                borderBottomWidth: "2px",
              }}
            >
              <thead
                style={{
                  borderBottomWidth: "2px",
                  verticalAlign: "bottom",
                  borderBottom: `2px solid ${theme.palette.secondary.pattensBlue}`,
                }}
              >
                <tr>
                  <th
                    style={{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.background.blackishBlue,
                      borderBottomWidth: "2px",
                      verticalAlign: "bottom",
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "inherit",
                    }}
                  >
                    GST Number
                  </th>
                  <th
                    style={{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.background.blackishBlue,
                      borderBottomWidth: "2px",
                      verticalAlign: "bottom",
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "inherit",
                    }}
                  >
                    Company Name
                  </th>
                  <th
                    style={{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.background.blackishBlue,
                      borderBottomWidth: "2px",
                      verticalAlign: "bottom",
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "inherit",
                    }}
                  >
                    Contact Number
                  </th>
                  <th
                    style={{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.background.blackishBlue,
                      borderBottomWidth: "2px",
                      verticalAlign: "bottom",
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "inherit",
                    }}
                  >
                    Company Email
                  </th>
                  <th
                    style={{
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.background.blackishBlue,
                      borderBottomWidth: "2px",
                      verticalAlign: "bottom",
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "inherit",
                    }}
                  >
                    Company Address
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                    }}
                  >
                    {props.data.GetBookingDetailResult.gstDetail.gstNumber}
                  </th>
                  <td
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                    }}
                  >
                    {props.data.GetBookingDetailResult.gstDetail.gstCompany}
                  </td>
                  <td
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                    }}
                  >
                    {props.data.GetBookingDetailResult.gstDetail.gstMobile}
                  </td>
                  <td
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                    }}
                  >
                    {props.data.GetBookingDetailResult.gstDetail.gstEmail}
                  </td>
                  <td
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                    }}
                  >
                    {props.data.GetBookingDetailResult.gstDetail.gstAddress}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )} */}
      {/* ========== Traveller Details=========== */}
      <div className="traveller-details">
        <div
        // style={{
        //   maxWidth: "1140px",
        //   width: "100%",
        //   paddingRight: "15px",
        //   paddingLeft: "15px",
        //   marginRight: "auto",
        //   marginLeft: "auto",
        //   boxSizing: "border-box",
        // }}
        >
          <h5 className="h5-teg">
            <b>Traveller Details</b>
          </h5>
          <table
          // style={{
          //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
          //   width: "100%",
          //   marginBottom: "1rem",
          //   color: theme.palette.secondary.darkGrayishBlue,
          //   borderCollapse: "collapse",
          //   borderBottomWidth: "2px",
          // }}
          >
            <thead
            // style={{
            //   borderBottomWidth: "2px",
            //   verticalAlign: "bottom",
            //   borderBottom: `2px solid ${theme.palette.secondary.pattensBlue}`,
            // }}
            >
              <tr>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  #
                </th>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  Name
                </th>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  Age
                </th>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  Gender
                </th>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  Policy Number
                </th>
              </tr>
            </thead>
            <tbody>
              {!isPerPassenger ? (
                <>
                  {props.data.paxInfo &&
                    props.data.paxInfo.map((passenger, passengerIndex) => (
                      <tr key={passengerIndex}>
                        <th
                        // style={{
                        // border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                        // padding: ".75rem",
                        // }}
                        >
                          {passengerIndex + 1}
                        </th>
                        <td
                        // style={{
                        //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                        //   padding: ".75rem",
                        // }}
                        >
                          {passenger.title} {passenger.firstName}{" "}
                          {passenger.lastName}
                        </td>
                        <td
                        // style={{
                        //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                        //   padding: ".75rem",
                        // }}
                        >
                          {getAge(passenger.dOB)}
                          {/* {passenger.Age} */}
                        </td>
                        <td
                        // style={{
                        //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                        //   padding: ".75rem",
                        // }}
                        >
                          {/* {passenger.Gender === 1
                            ? "Male"
                            : passenger.Gender === 2
                            ? "Female"
                            : "Other"} */}
                          {passenger.gender}
                        </td>
                        <td
                        // style={{
                        //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                        //   padding: ".75rem",
                        // }}
                        >
                          {/* {passenger.Seat.SeatName} */}
                          AAAXXX
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  <tr>
                    <th
                    // style={{
                    //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                    //   padding: ".75rem",
                    // }}
                    >
                      {1}
                    </th>
                    <td
                    // style={{
                    //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                    //   padding: ".75rem",
                    // }}
                    >
                      {props.paxInfo[0].Title} {props.paxInfo[0].firstName}{" "}
                      {props.paxInfo[0].lastName}
                    </td>
                    <td
                    // style={{
                    //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                    //   padding: ".75rem",
                    // }}
                    >
                      {/* {props.passenger.Age} */}
                      70
                    </td>
                    <td
                    // style={{
                    //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                    //   padding: ".75rem",
                    // }}
                    >
                      {/* {props.passenger.Gender === 1
                        ? "Male"
                        : props.passenger.Gender === 2
                        ? "Female"
                        : "Other"} */}
                      Male
                    </td>
                    <td
                    // style={{
                    //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                    //   padding: ".75rem",
                    // }}
                    >
                      {/* {props.passenger.Seat.SeatName} */}
                      123231
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  Mobile No:
                </th>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  {/* {
                    props.data.GetBookingDetailResult.Itinerary.Passenger[0]
                      .Phoneno
                  } */}
                  {props.data.paxInfo[0].phoneNumber}
                </th>
                <br></br>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                >
                  Email Id:
                </th>
                <th
                // style={{
                //   color: theme.palette.primary.contrastText,
                //   backgroundColor: theme.palette.background.blackishBlue,
                //   borderBottomWidth: "2px",
                //   verticalAlign: "bottom",
                //   padding: ".75rem",
                //   textAlign: "inherit",
                // }}
                // colSpan={2}
                >
                  {/* {
                    props.data.GetBookingDetailResult.Itinerary.Passenger[0]
                      .Email
                  } */}
                  <div className="emailId">{props.data.paxInfo[0].emailId}</div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* =========Fare Details============== */}
      {!isFareHide && (
        <div className="fare-details">
          <div
          // style={{
          //   maxWidth: "1140px",
          //   width: "100%",
          //   paddingRight: "15px",
          //   paddingLeft: "15px",
          //   marginRight: "auto",
          //   marginLeft: "auto",
          //   boxSizing: "border-box",
          // }}
          >
            <h5 className="h5-teg">
              <b>Fare Details</b>
            </h5>
            <table
            // style={{
            //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
            //   width: "100%",
            //   marginBottom: "1rem",
            //   color: theme.palette.secondary.darkGrayishBlue,
            //   borderCollapse: "collapse",
            //   borderBottomWidth: "2px",
            // }}
            >
              <tbody>
                <tr>
                  <th
                  // style={{
                  //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                  //   padding: ".75rem",
                  //   textAlign: "left",
                  // }}
                  >
                    Base Fare
                  </th>
                  <th>
                    {/* {basefare.toFixed(2)}/- */}
                    {props.data.paxInfo[0].commissionCharge.grossProductPrice}
                  </th>
                </tr>
                {/* <tr>
                  <th
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "left",
                    }}
                  >
                    Other Charges
                  </th>
                  <th
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "right",
                    }}
                  >
                    {props.data.paxInfo[0].commissionCharge.agentMarkup}
                   
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "left",
                    }}
                  >
                    GST
                  </th>
                   <th
                    style={{
                      border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                      padding: ".75rem",
                      textAlign: "right",
                    }}
                  >
                    
                    0.00
                  </th>
                </tr> */}
              </tbody>
              <tbody>
                <tr>
                  <th
                  // style={{
                  //   color: theme.palette.primary.contrastText,
                  //   backgroundColor: theme.palette.background.blackishBlue,
                  //   borderBottomWidth: "2px",
                  //   verticalAlign: "bottom",
                  //   border: `1px solid ${theme.palette.secondary.pattensBlue}`,
                  //   padding: ".75rem",
                  //   textAlign: "inherit",
                  // }}
                  >
                    Total Fare
                  </th>
                  <th>
                    {/* {total.toFixed(2)}/- */}
                    {props.data.paxInfo[0].commissionCharge.grossProductPrice}
                    {/* {props.data.paxInfo[0].price.publishedPriceRoundedOff} */}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* <div style={{paddingTop: '0px ', paddingBottom: '3rem '}}>
        <div style={{maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box'}}>
          <h5 style={{color: '#c22229 ', fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box'}}><b style={{fontWeight: 'bolder', boxSizing: 'border-box'}}>Cancellation Policy</b></h5>
          <table style={{border: '1px solid #dee2e6', width: '100%', marginBottom: '1rem', color: '#212529', borderCollapse: 'collapse', borderBottomWidth: '2px'}}>
            <thead style={{borderBottomWidth: '2px', verticalAlign: 'bottom', borderBottom: '2px solid #dee2e6'}}>
              <tr>
                <th style={{color: '#fff', backgroundColor: '#343a40', borderBottomWidth: '2px', verticalAlign: 'bottom', border: '1px solid #dee2e6', padding: '.75rem', textAlign: 'inherit'}}>Cancellation Time</th>
                <th style={{color: '#fff', backgroundColor: '#343a40', borderBottomWidth: '2px', verticalAlign: 'bottom', border: '1px solid #dee2e6', padding: '.75rem', textAlign: 'inherit'}}>Charge Type</th>
                <th style={{color: '#fff', backgroundColor: '#343a40', borderBottomWidth: '2px', verticalAlign: 'bottom', border: '1px solid #dee2e6', padding: '.75rem', textAlign: 'inherit'}}>Cancellation Charge</th>
              </tr>
            </thead>
            <tbody>
              {props.data.GetBookingDetailResult.Itinerary.CancelPolicy && props.data.GetBookingDetailResult.Itinerary.CancelPolicy.map((policy, policyIndex)=>
                <tr key={policyIndex}>
                  <td style={{border: '1px solid #dee2e6', padding: '.75rem'}}>{policy.PolicyString}</td>
                  <td style={{border: '1px solid #dee2e6', padding: '.75rem'}}>{policy.CancellationChargeType === 1 ? 'Amount': policy.CancellationChargeType === 2 ? 'Percentage':'Nights'}</td>
                  <td style={{border: '1px solid #dee2e6', padding: '.75rem'}}>{policy.CancellationCharge}</td>
                </tr>
              )}
            </tbody>
          </table>
          <b style={{fontWeight: 'bolder', boxSizing: 'border-box'}}>Note: For Special Vehicle / Service / Long Week-end/ Festival Time / Strike / Others, Cancellation Policy May or May Not be Applicable</b>
        </div>
      </div> */}

      {/*========================= Terms And Conditions================  */}
      <div className="t-and-c">
        <div
        // style={{
        //   maxWidth: "1140px",
        //   width: "100%",
        //   paddingRight: "15px",
        //   paddingLeft: "15px",
        //   marginRight: "auto",
        //   marginLeft: "auto",
        //   boxSizing: "border-box",
        // }}
        >
          <b>Terms And Conditions</b>
          <br />
          <p>
            a. Refund policy mentioned above is indicative. The actual
            cancellation charges are determinedby bus operators and bus
            providers at the actual time of cancellation. Paytm has no role
            ingoverning cancellation charges
          </p>
          <p>
            b. Cancellation charges are calculated on the actual fare of the
            ticket, if any discount couponsare used while purchasing the ticket,
            the discounted value would be used to calculate the refundamount
            when a ticket is cancelled
          </p>
        </div>
      </div>
    </div>
  );
};

export default InsuranceTicketResult;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    position: "relative",
    borderBottom: `2px solid ${theme.palette.secondary.fireBrick}`,
    width: "100%",

    "& .h5-teg": {
      color: theme.palette.secondary.fireBrick,
      fontSize: "1.25rem",
      marginBottom: ".5rem",
      fontWeight: 500,
      lineHeight: "1.2",
      marginTop: 0,
      boxSizing: "border-box",
    },
    "& .h6-teg": {
      fontSize: "1rem",
      marginBottom: ".5rem",
      fontWeight: 500,
      lineHeight: "1.2",
      marginTop: 0,
      boxSizing: "border-box",
      // [theme.breakpoints.down("md")]: {
      //   fontSize: "1.6rem",
      // },
    },
    "& .text": {
      fontSize: "86%",
      fontWeight: 400,
    },
    "& .section": {
      display: "flex",
      // flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(900)]: {
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: 5,
      },
    },

    "& .section-header": {
      paddingBottom: "0px ",
      padding: "2rem 1rem",
      display: "block",
      [theme.breakpoints.down(460)]: {
        paddingLeft: 0,
      },

      "& .top-section": {
        display: "flex",
        // flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down(460)]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
        "& .titleContainer": {
          [theme.breakpoints.down(900)]: {
            textAlign: "center",
          },
        },
        "& .addressContainer": {
          [theme.breakpoints.down(900)]: {
            "& h5": {
              textAlign: "left !important",
            },
            "& .address-text": {
              float: "unset ",
            },
          },
        },
        // [theme.breakpoints.down("md")]: {
        //   flexDirection: "column",
        // },
        "& .agencyInfo-logo": {
          height: "50px",
          // float: "left",
          // verticalAlign: "middle",
          borderStyle: "none",
        },
        "& h3": {
          fontSize: "1.75rem",
          marginBottom: ".5rem",
          fontWeight: 500,
          lineHeight: "1.2",
          marginTop: 0,
          [theme.breakpoints.down(370)]: {
            fontSize: 12,
          },
        },
        "& h5": {
          fontSize: "1.25rem",
          marginBottom: ".5rem",
          fontWeight: 500,
          lineHeight: "1.2",
          marginTop: 0,
          textAlign: "right",
        },

        "& .address-text": {
          color: theme.palette.primary.lightGrayishBlue,
          float: "right ",
          fontWeight: "bold",
        },
      },
    },

    "& .insurance-id-section": {
      margin: "20px 0",

      "& h5": {
        ntSize: "1.25rem",
        marginBottom: ".5rem",
        fontWeight: 500,
        lineHeight: "1.2",
        marginTop: 0,
        boxSizing: "border-box",
      },
    },

    "& .insurance-details": {
      paddingTop: "0px ",
      paddingBottom: "1rem ",
      // [theme.breakpoints.down("md")]: {
      //   textAlign:'center',
      // },
    },

    "& .traveller-details": {
      // [theme.breakpoints.down("md")]: {
      //   textAlign:'center',
      // },
      "& .emailId": {
        [theme.breakpoints.down(450)]: {
          width: 140,
        },
        [theme.breakpoints.down(395)]: {
          width: 120,
        },
        [theme.breakpoints.down(380)]: {
          width: 95,
        },
      },

      "& table": {
        border: `1px solid ${theme.palette.secondary.pattensBlue}`,
        width: "100%",
        marginBottom: "1rem",
        color: theme.palette.secondary.darkGrayishBlue,
        borderCollapse: "collapse",
        borderBottomWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        "& thead": {
          borderBottomWidth: "2px",
          verticalAlign: "bottom",
          borderBottom: `2px solid ${theme.palette.secondary.pattensBlue}`,
        },
        "& th": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.background.blackishBlue,
          borderBottomWidth: "2px",
          verticalAlign: "bottom",
          border: `1px solid ${theme.palette.secondary.pattensBlue}`,
          padding: ".75rem",
          textAlign: "inherit",
          [theme.breakpoints.down("sm")]: {
            padding: 0,
          },
        },
        "& tbody": {
          "& td": {
            border: `1px solid ${theme.palette.secondary.pattensBlue}`,
            padding: ".75rem",
            [theme.breakpoints.down("sm")]: {
              padding: 0,
            },
          },
        },
      },
    },

    "& .fare-details": {
      "& table": {
        border: `1px solid ${theme.palette.secondary.pattensBlue}`,
        width: "100%",
        marginBottom: "1rem",
        color: theme.palette.secondary.darkGrayishBlue,
        borderCollapse: "collapse",
        borderBottomWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        "& thead": {
          borderBottomWidth: "2px",
          verticalAlign: "bottom",
          borderBottom: `2px solid ${theme.palette.secondary.pattensBlue}`,
        },
        "& th": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.background.blackishBlue,
          borderBottomWidth: "2px",
          verticalAlign: "bottom",
          border: `1px solid ${theme.palette.secondary.pattensBlue}`,
          padding: ".75rem",
          textAlign: "inherit",
          // [theme.breakpoints.down("sm")]: {
          //   padding:0,
          // },
        },
        "& tbody": {
          "& td": {
            border: `1px solid ${theme.palette.secondary.pattensBlue}`,
            padding: ".75rem",
            // [theme.breakpoints.down("sm")]: {
            //   padding:0,
            // },
          },
        },
      },
    },
    "& .t-and-c": {
      paddingTop: "0px ",
      paddingBottom: "3rem",
      "& b": {
        color: theme.palette.secondary.fireBrick,
        fontWeight: "bolder",
        boxSizing: "border-box",
      },
    },
  },
}));
