import React from "react";
import * as Yup from "yup";
import WebApi from "api/ApiConstants";
import { Button, InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import InvalidCard from "assets/icons/cc/invalid-card.png";
import AmexCard from "assets/icons/cc/american.png";
import MasterCard from "assets/icons/cc/master.png";
import RupayCard from "assets/icons/cc/rupay.png";
import VisaCard from "assets/icons/cc/visa.png";
import { PayuCheckout } from "./PayuCheckout";

const PayuCard = (props) => {
    const classes = useStyles();
    const formikRef = React.useRef();

    const [cardData, setCardData] = React.useState(null);
    const [ccExpiry, setCcExpiry] = React.useState(null);
    const [ccBrand, setCcBrand] = React.useState(InvalidCard);
    const [ccNumberString, setCcNumberString] = React.useState("");

    const initialValues = {
        txnid: "11460",
        productinfo: "bus-booking",
        amount: "100.00",
        email: "test@gmail.com",
        phone: "9876543210",
        firstname: "Vincent",
        lastname: "Rabha",
        pg: "",
        bankcode: "",
        ccnum: "",
        ccname: "",
        ccvv: "",
        ccexpmon: "",
        ccexpyr: "",
        surl: "http://localhost:3000/lmt",
        furl: "http://localhost:3000/lmt"
    };

    const validationSchema = Yup.object().shape({
        // ccnum: Yup.number().required("card number cannot be blank"),
        ccvv: Yup.number().required("security code is required"),
        ccexpmon: Yup.number().required("valid thru date is required"),
        ccexpyr: Yup.number().required("valid thru year is required"),
        ccname: Yup.string().required("Name of card holder is required")
    });

    const CcBrand = () => (
        <span>
            <img src={ccBrand} className="cc-brand"/>
        </span>
    );

    const validateCard = (cardNum) => {
        console.log(cardNum);
        WebApi.payuCardValidation({ cardNum: cardNum }, response => {
            console.log("card validation response", response);
            if(response.success) {
                setCardData(response.data);
            }
        }, error => {
            console.error("error validating card", error);
        });
    };

    const formatExpiryString = (e) => {
        var inputChar = String.fromCharCode(e.keyCode);
        var code = e.keyCode;
        var allowedKeys = [8];
        if (allowedKeys.indexOf(code) !== -1) {
          return;
        }
      
        let temp = e.target.value.replace(
          /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
        ).replace(
          /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
        ).replace(
          /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
        ).replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
        ).replace(
          /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
        ).replace(
          /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
        ).replace(
          /\/\//g, '/' // Prevent entering more than 1 `/`
        );
        setCcExpiry(temp);
    };

    const formatCcString = (value) => {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []

        for (var i = 0; i < match.length; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    const handleCcInputBlur = (e) => {
        validateCard(e.target.value);
    };

    const handleCcNumChange = (e) => {
        e.target.value = e.target.value.replace(/[^\d\/]|^[\/]*$/g, '');
        setCcNumberString(formatCcString(e.target.value));
        if(e.target.value.length > 12) {
            validateCard(e.target.value.replaceAll(" ", ""));
        }
    };

    const handleCcExpiryChange = (e) => {
        // console.log("cc expiry", e.target.value);
        setCcExpiry(e.target.value);
    };

    const setCcExpiryDate = (e) => {
        if(e.target.value?.split("/") && formikRef.current) {
            let temp = e.target.value.split("/");
            formikRef.current.setFieldValue("ccexpmon", temp[0]);
            formikRef.current.setFieldValue("ccexpyr", temp[1]);
        }
    };

    const handleCcvvChange = (e) => {
        if(formikRef.current && e.target.value.length < 5) {
            formikRef.current.setFieldValue("ccvv", e.target.value);
        }
    };

    const handleCcNameChange = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("ccname", e.target.value);
        }
    };

    const onSubmitCheckoutForm = (payload, { setSubmitting }) => {
        payload.ccnum = ccNumberString.replaceAll(" ", "");
        payload.bankcode = cardData.cardType;
        payload.pg = cardData.cardCategory;
        WebApi.payuCardCheckout(payload, response => {
            console.log("checkout response", response);
            if(response.success) {
                const request = response.data;
                // PayuCheckout(request, "https://test.payu.in/_payment");
            }
        }, error => {
            console.error("card checkout error", error);
        });
    };

    React.useEffect(() => {
        if(cardData) {
            switch(cardData.cardType) {
                case "MAST": {
                    setCcBrand(MasterCard);
                    break;
                }
                case "VISA": {
                    setCcBrand(VisaCard);
                    break;
                }
                case "AMEX": {
                    setCcBrand(AmexCard);
                    break;
                }
                default: {
                    setCcBrand(InvalidCard);
                    break;
                }
            }
        }
    }, [cardData]);

    return (
        <div className={classes.root}>
            <div className="checkout-form-container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(payload, { setSubmitting }) => onSubmitCheckoutForm(payload, { setSubmitting })}
                    innerRef={formikRef}
                >
                    {({ values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className="checkout-form-wrapper">
                                <div className="form-row">
                                    <h4>Amount Payable: ₹ {values.amount}</h4>
                                </div>
                                <div className="form-row">
                                    <label>Card Number</label>
                                    <TextField
                                        name="ccnum"
                                        value={ccNumberString}
                                        onChange={handleCcNumChange}
                                        onBlur={handleCcInputBlur}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><CcBrand /></InputAdornment>,
                                        }}
                                    />
                                    <ErrorMessage
                                        name="ccnum"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                                <div className="form-row flex-box">
                                    <div className="form-col p50">
                                        <label>Valid thru (eg. 12/2026)</label>
                                        <TextField
                                            value={ccExpiry || ""}
                                            onChange={handleCcExpiryChange}
                                            onKeyUp={formatExpiryString}
                                            onBlur={setCcExpiryDate}
                                            variant="outlined"
                                            placeholder="mm/yyyy"
                                            fullWidth
                                        />
                                        <ErrorMessage
                                            name="ccexpmon"
                                            component="div"
                                            className="error"
                                        />
                                    </div>
                                    <div className="form-col p50">
                                        <label>CVV</label>
                                        <TextField
                                            value={values.ccvv}
                                            name="ccvv"
                                            onChange={handleCcvvChange}
                                            variant="outlined"
                                            placeholder="eg. 123"
                                            fullWidth
                                        />
                                        <ErrorMessage
                                            name="ccvv"
                                            component="div"
                                            className="error"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label>Name as in card</label>
                                    <TextField
                                        name="ccname"
                                        value={values.ccname}
                                        onChange={handleCcNameChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <ErrorMessage
                                        name="ccname"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                                <div className="form-row form-action">
                                    <Button type="submit">
                                        Pay ₹ {values.amount}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default PayuCard;

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        margin: 0,
        "& .checkout-form-container": {
            display: "flex",
            justifyContent: "center",
            padding: 20
        },
        "& .checkout-form-wrapper": {
            width: 350,
            padding: "20px 20px 15px",
            borderRadius: 5,
            boxShadow: "0px 0px 5px 0px rgba(120,119,120,1)"
        },
        "& .form-row": {
            margin: "5px 0"
        },
        "& .form-action": {
            marginTop: 15,
            display: "flex",
            justifyContent: "center",
            "& button": {
                background: theme.palette.buttons.secondary,
                color: theme.palette.buttons.secondaryContrastText
            }
        },
        "& label, h4": {
            color: theme.palette.text.dark,
            fontWeight: 500
        },
        "& .cc-brand": {
            height: 20
        },
        "& .flex-box": {
            display: "flex",
            gap: 10
        },
        "& .p100": {
            width: "100%"
        },
        "& .p50": {
            width: "50%"
        }
    }
}));
