import React from "react";

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import { makeStyles } from "@material-ui/core/styles";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  starRating: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(20),
  },
  starRatingBorder: {
    fontSize: theme.typography.pxToRem(20),
  },
  tripAdviserRating: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
  },
}));

const StarRating = ({ rating, tripAdviserRating }) => {
  const classes = useStyles();

  const roundOffRating = Math.round(rating);
  const remainingRating = 5 - roundOffRating;
  const stars = [];
  for (let i = 0; i < roundOffRating; i++) {
    if (tripAdviserRating) {
      stars.push(<RadioButtonChecked className={classes.tripAdviserRating} />);
    } else {
      stars.push(<StarIcon key={i} className={classes.starRating} />);
    }
  }
  for (let i = 0; i < remainingRating; i++) {
    if (tripAdviserRating) {
      stars.push(
        <RadioButtonUnchecked className={classes.tripAdviserRating} />
      );
    } else {
      stars.push(
        <StarBorderIcon key={i} className={classes.starRatingBorder} />
      );
    }
  }

  return stars.map((star, ind) => <span key={ind}>{star}</span>);
};

export default StarRating;
