import React, {useEffect, useState} from 'react';
import OaCard from 'oahoc/OaCard';
import OaTable from 'components/OaTable';
import WebApi from "api/ApiConstants";
import Button from "components/CustomButtons/Button.js";
import {withRouter} from 'react-router-dom';

const CommissionAgentList = (props) => {

    //console.log(props);

    const [searchParams, setSearchParams] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if(props.agencyId === undefined){
            setSearchParams({requestFlag: props.tab,requestedDiscountClass:"A"});
        }else{
            setSearchParams({requestFlag: props.tab,agentId: props.agencyId,requestedDiscountClass:"A"});
        }
        
        setIsLoading(false);
    }, [props.tab]);

    let cols = [];

    function editHandler(data){
        props.history.push({
            pathname: '/admin/management/commission/edit/flight/' + props.tab + '/' + props.agencyId,
            row: { data: data.original }
        })
    }
        
    if(props.agencyId != undefined){
        cols.push(
            {
                Header: '#',
                Cell: props => (
                    <div style={{height: 28}}>{ props.index + 1}</div>
                 ),
                width: 35,
                filterable: false
            },{
                Header: "Carrier",
                accessor: "carrierName",
                width: 200,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },
            {
                Header: "Start Date",
                accessor: "startDate",
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "End Date",
                accessor: "endDate",
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },
            {
                Header: "Fixed Discount",
                accessor: "fixedDiscount",
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },
            {
                Header: "Variable Discount",
                accessor: "variableDiscount",
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },
            {
                Header: "Action",
                width: 130,
                Cell: props => (
                    <div style={{height: 28,verticalAlign:'middle'}}>
                        <Button 
                         color="primary"
                         type="submit" size="sm"
                         
                         muiClasses={{
                            label: "",
                          }} onClick={e => editHandler(props)}>Edit</Button>
                    </div>
                )
            }
        )
    }else{
        cols.push(
            {
                Header: '#',
                Cell: props => (
                    <div style={{height: 28}}>{ props.index + 1}</div>
                 ),
                width: 35,
                filterable: false
            },{
                Header: "Carrier",
                accessor: "carrierName",
                width: 200,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "From",
                accessor: "origin",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "To",
                accessor: "destination",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            }
            ,{
                Header: "Start Date",
                accessor: "startDate",
                width: 200,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "End Date",
                accessor: "endDate",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "Fixed Discount",
                accessor: "fixedDiscount",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "Variable Discount",
                accessor: "variableDiscount",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "Booking Class",
                accessor: "bookingClass",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },
            {
                Header: "Class Exception",
                accessor: "classException",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },
            {
                Header: "Origin Exception",
                accessor: "originException",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            },{
                Header: "Destination Exception",
                accessor: "destinationException",
                width: 160,
                Cell: props => (
                         <div style={{height: 28}}>{ props.value}</div>
                )
            } 
        );
    }
    return (
        <>
        {!isLoading && 
        <OaCard>
            <OaTable
                columns={cols}
                filterable={false}
                loader={WebApi.getAgentDiscount}
                search={searchParams}   
            />
        </OaCard>
        }
        </>
    )
}

export default withRouter(CommissionAgentList);
