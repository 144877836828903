import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import { ErrorMessage, FieldArray } from "formik";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const OaFormikErrorAlert = (props) => {
  const classes = useStyles();
  const [isErrorOpen, setIsErrorOpen] = React.useState(false);
  
  React.useEffect(()=>{
    setIsErrorOpen(true)
  },[props.touched])

  return (
    <div className={classes.root}>
      <Collapse in={isErrorOpen}>
        <ErrorMessage name={props.name}>
          {(errorMsg) => {
            let ErrorMessage = errorMsg;
            switch (typeof ErrorMessage) {
              case "object":
                JSON.stringify(ErrorMessage)
                break;
              default:
                break;
            }
            return (
              <div className="error-msgBox">
                <div className="error-container">
                  <Alert
                    variant="filled"
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please Check the form again {ErrorMessage}
                  </Alert>
                </div>
              </div>
            );
          }}
        </ErrorMessage>
      </Collapse>
    </div>
  );
};

export default OaFormikErrorAlert;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .error-msgBox": {
      width: "60%",
      // height:'100vh',
      bottom: 0,
      position: "fixed",
      // position:'absolute',
      top: "10%",
      left: "20%",
      padding: "6px 13px",
      zIndex: 999,
      margin: "10px 0",
      "& .error-container": {
        position: "relative",
        width: "100%",
        padding: "6px 13px",
        margin: "10px 0",
      },
    },
  },
}));
