import React, { useState, useEffect } from "react";
import OaCard from "oahoc/OaCard";
import RefundForm from "./RefundForm";

const Refund = (props) => {
  useEffect(() => {}, []);
  return (
    // <OaCard className="oa-card-rounded-top" style={{ minHeight: 200 }}>
      <RefundForm {...props} />
    // </OaCard>
  );
};

export default Refund;
