import React from "react";
import dateFnsFormat from "date-fns/format";
import OaSwitchInput from "oahoc/OaSwitchInput";
import CardBody from "components/Card/CardBody";
import CustomDatePicker from "oahoc/CustomDatePicker";
import WebApi from "api/ApiConstants";
import {
  Box,
  Grid,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Popper,
  ListItemText,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import {
  setDomesticCarriers,
  setInternationalCarriers,
  setAirports,
} from "redux/action";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";

import { allAirlines, lccAirlines, gdsAirlines } from "api/ApiConstants";


const FlightModify = (propes) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const propState = history?.location?.state;
  const [tripType, setTripType] = React.useState("ONE");
  const [travelers, setTravelers] = React.useState({
    child: propState?.requestObj?.childPax,
    adult: propState?.requestObj?.adultPax,
    infant: propState?.requestObj?.infantPax,
  });
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedAirLine, setSelectedAirLine] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(
    new Date(propState?.requestObj?.returnDate)
  );
  const [isFetching, setIsFetching] = React.useState(false);
  const [tripMode, setTripMode] = React.useState("ONE");
  const [airportList, setAirportList] = React.useState(popularCities);
  const departAutocompleteEl = React.useRef(null);
  const arriveAutocompleteEl = React.useRef(null);

  const [fromCountry, setFromCountry] = React.useState("");
  const [toCountry, setToCountry] = React.useState("");

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [carrier, setCarrier] = React.useState(propes.domCarriers);
  const [userType, setUserType] = React.useState(null);
  const [corporateType, setCorporateType] = React.useState(null);
  const [airlineSelectOpen, setAirlineSelectOpen] = React.useState(false);
  const [clientType, setClientType] = React.useState(null);
  const [partnerId, setPartnerId] = React.useState(null);

  const formikRef = React.useRef();

  const calenderMonth = isMobileScreen ? 1 : 2;
  const getAirlineData = () => {
    if (
      propes.domCarriers === undefined ||
      propes.intlCarriers === undefined ||
      propes.airports === undefined
    ) {
      apiCall(WebApi.getAirports, {}, (response) => {
        if (response.success === true) {
          propes.setAirports(response.data.airports);
          propes.setDomesticCarriers(response.data.domCarriers);
          propes.setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      });
    }
  };

  const fetchAirports = (searchParams) => {
    if (searchParams.length >= 3) {
      setIsFetching(true);
      apiCall(
        WebApi.fetchAirportList,
        {
          airportCode: searchParams,
        },
        (response) => {
          // console.log("response", response);
          setIsFetching(false);
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        }
      );
    }
  };

  const IconButtonStyle = {
    padding: "1px 6px",
    fontSize: 14,
    background: theme.palette.primary.gainsboro,
    color: theme.palette.primary.defaultText,
    margin: "0px 3px",
  };

  const handleTripTypeChange = (event) => {
    // console.log("tripType", event.target.value);
    setTripType(event.target.value);
  };

  const addRemoveTravelerHandler = (person, value) => {
    switch (person) {
      case "child": {
        return setTravelers((prev) => ({
          ...travelers,
          child: prev.child + value,
        }));
      }
      case "adult": {
        return setTravelers((prev) => ({
          ...travelers,
          adult: prev.adult + value,
        }));
      }
      case "infant": {
        return setTravelers((prev) => ({
          ...travelers,
          infant: prev.infant + value,
        }));
      }
      default:
        return;
    }
  };

  const handleClassChange = (event) => setSelectedClass(event.target.value);
  const handleAirLineChange = (event) => setSelectedAirLine(event.target.value);
  const handleSubmitTravelData = (values) => {
    //console.log("object", values);
    values.adultPax = travelers?.adult;
    values.childPax = travelers?.child;
    values.infantPax = travelers?.infant;
    window.sessionStorage.setItem("fetching", true);
    history.push({
      pathname: "/flight/search-progress",
      state: values,
    });
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const submitModify = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
    propes.updateModify(false);
  };

  const validateSectorsForNonMulticity = () => {
    if (formikRef.current) {
      if (
        formikRef.current.values.mode !== "MULTICITY" &&
        formikRef.current.values.sectors.length > 1
      ) {
        formikRef.current.setFieldValue(
          `sectors`,
          formikRef.current.values.sectors.slice(0, 1)
        );
      }
    }
  };

  const onChangeTripMode = (event) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("mode", event.target.value);
    }
    setTripMode(event.target.value);
  };

  React.useEffect(() => {
    propes.initialvalues.mode == "ONE" &&
      setEndDate(propes.initialvalues.returnDateDisplay);
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        // console.log("user details", details);
        setUserType(details.userType);
        setCorporateType(details.corporateType);
        setPartnerId(details.partnerId);

        switch (details.userTypeAbv) {
          case "S": {
            setClientType("Corporate");
            break;
          }
          case "G": {
            setClientType("Normal");
            break;
          }
          default: {
            setClientType("Normal");
            break;
          }
        }
      } else {
        history.push("/logout");
      }
    });
  }, []);

  React.useEffect(() => {
    getAirlineData();
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(propes.domCarriers);
      } else {
        setCarrier(propes.intlCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  React.useEffect(() => {
    validateSectorsForNonMulticity();
  }, [tripMode]);

  return (
    <>
      <Box className={classes.root}>
        <Container>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Formik
                innerRef={formikRef}
                initialValues={propes.initialvalues}
                // enableReinitialize
                onSubmit={(values) => {
                  handleSubmitTravelData(values);
                }}
                render={(props) => (
                  <Form onSubmit={props.handleSubmit} className="f-12">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="mode"
                        value={props.values.mode}
                        onChange={onChangeTripMode}
                        className={classes.radioGroup}
                      >
                        <FormControlLabel
                          value="ONE"
                          control={<Radio />}
                          label="One-way"
                        />
                        <FormControlLabel
                          value="ROUND"
                          control={<Radio />}
                          label="Round"
                        />
                        <FormControlLabel
                          value="MULTICITY"
                          control={<Radio />}
                          label="Multi-City"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="form-contents">
                      <FieldArray name="sectors">
                        {({ push, remove }) => (
                          <CardBody className="cardContainer">
                            {props.values.mode !== "MULTICITY" && (
                              <div>
                                <Grid
                                  container
                                  className={classes.modifycontainer}
                                  style={{
                                    // marginBottom: 10,
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className="form-group"
                                  >
                                    <label>From</label>
                                    <Autocomplete
                                      name={`sectors[0].originObj`}
                                      options={airportList || []}
                                      value={
                                        props.values.sectors[0].originObj || ""
                                      }
                                      PopperComponent={customPopperFrom}
                                      getOptionLabel={(o) =>
                                        o
                                          ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                          : ""
                                      }
                                      getOptionSelected={(option, value) =>
                                        option.airportCode == value.airportCode
                                      }
                                      onKeyUp={(event) =>
                                        fetchAirports(event.target.value)
                                      }
                                      onChange={(event, newValue, reason) => {
                                        // console.log("origin obj", newValue);
                                        if (reason === "clear") {
                                          setAirportList(popularCities);
                                        } else {
                                          changeHandler(
                                            "from",
                                            newValue?.countryCode
                                          );
                                          // arriveAutocompleteEl.current.focus();

                                          // setData({ ...data, from: newValue });
                                          props.setFieldValue(
                                            `sectors[0].originObj`,
                                            newValue
                                          );
                                          props.setFieldValue(
                                            `sectors[0].origin`,
                                            newValue.airportCode
                                          );
                                          // !dataError.from &&
                                          // arriveAutocompleteEl.current.focus();
                                        }
                                      }}
                                      ref={departAutocompleteEl}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          // fullWidth
                                          placeholder="Departure Airport"
                                        />
                                      )}
                                      renderOption={(optionsData, propes) =>
                                        renderOptionsComp(optionsData, propes)
                                      }
                                      forcePopupIcon={false}
                                    />
                                    <ErrorMessage
                                      name={`sectors[0].originObj`}
                                      component="div"
                                      className="error"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className="form-group"
                                  >
                                    <label>To</label>
                                    <Autocomplete
                                      name={`sectors[0].destinationObj`}
                                      options={airportList || []}
                                      getOptionLabel={(o) =>
                                        o
                                          ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                          : ""
                                      }
                                      PopperComponent={customPopperTo}
                                      value={
                                        props.values.sectors[0]
                                          .destinationObj || ""
                                      }
                                      getOptionSelected={(option, value) =>
                                        option.airportCode == value.airportCode
                                      }
                                      onKeyUp={(event) =>
                                        fetchAirports(event.target.value)
                                      }
                                      onChange={(event, newValue, reason) => {
                                        // console.log(newValue);
                                        if (reason === "clear") {
                                          setAirportList(popularCities);
                                        } else {
                                          changeHandler(
                                            "to",
                                            newValue?.countryCode
                                          );
                                          // arriveAutocompleteEl.current.focus();

                                          // setData({ ...data, from: newValue });
                                          props.setFieldValue(
                                            `sectors[0].destinationObj`,
                                            newValue
                                          );
                                          props.setFieldValue(
                                            `sectors[0].destination`,
                                            newValue.airportCode
                                          );
                                          // !dataError.from &&
                                          //   arriveAutocompleteEl.current.focus();
                                        }
                                      }}
                                      ref={arriveAutocompleteEl}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          variant="outlined"
                                          placeholder="Arrival Airport"
                                        />
                                      )}
                                      renderOption={(optionsData, propes) =>
                                        renderOptionsComp(optionsData, propes)
                                      }
                                      forcePopupIcon={false}
                                    />
                                    <ErrorMessage
                                      name={`sectors[0].destinationObj`}
                                      component="div"
                                      className="error"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className="form-group input-date-picker"
                                  >
                                    <label>Departure</label>
                                    <CustomDatePicker
                                      showDisabledMonthNavigation
                                      name={`sectors[0].departDateDisplay`}
                                      minDate={startDate}
                                      customInput={
                                        <TextField
                                          id="standard-basic"
                                          variant="outlined"
                                          fullWidth
                                        />
                                      }
                                      selected={
                                        props.values.sectors[0]
                                          .departDateDisplay
                                      }
                                      onChange={(date) => {
                                        props.setFieldValue(
                                          `sectors[0].departDateDisplay`,
                                          date
                                        );
                                        props.setFieldValue(
                                          `sectors[0].departDate`,
                                          dateFnsFormat(date, "yyyy-MM-dd")
                                        );
                                        setEndDate(date);
                                        // setStartDate(date);
                                      }}
                                      monthsShown={calenderMonth}
                                      dateFormat="dd MMM yyyy"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className="form-group input-date-picker"
                                  >
                                    <label>Arrival</label>
                                    <CustomDatePicker
                                      showDisabledMonthNavigation
                                      minDate={endDate}
                                      disabled={props.values.mode === "ONE"}
                                      customInput={
                                        <TextField
                                          id="standard-basic"
                                          variant="outlined"
                                          fullWidth
                                          disabled={props.values.mode === "ONE"}
                                        />
                                      }
                                      selected={
                                        props.values.mode === "ROUND" && endDate
                                      }
                                      onChange={(date) => {
                                        setEndDate(date);
                                        props.setFieldValue(
                                          "returnDateDisplay",
                                          date
                                        );
                                        props.setFieldValue(
                                          "returnDate",
                                          dateFnsFormat(date, "yyyy-MM-dd")
                                        );
                                      }}
                                      monthsShown={calenderMonth}
                                      dateFormat="dd MMM yyyy"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {props.values.mode === "MULTICITY" &&
                              props.values.sectors.map((data, index) => (
                                <div key={index}>
                                  <Grid
                                    container
                                    className={classes.modifycontainer}
                                    style={{
                                      //marginBottom: 10,
                                      gap: 8,
                                      flexWrap: "nowrap",
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={3}
                                      xs={12}
                                      className="form-group"
                                    >
                                      <label>From</label>
                                      <Autocomplete
                                        PopperComponent={customPopperFrom}
                                        name={`sectors[${index}].originObj`}
                                        options={airportList || []}
                                        value={
                                          props.values.sectors[index]
                                            .originObj || ""
                                        }
                                        getOptionLabel={(o) =>
                                          o
                                            ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                            : ""
                                        }
                                        getOptionSelected={(option, value) =>
                                          option.airportCode ==
                                          value.airportCode
                                        }
                                        onKeyUp={(event) =>
                                          fetchAirports(event.target.value)
                                        }
                                        onChange={(event, newValue, reason) => {
                                          // console.log(newValue);
                                          if (reason === "clear") {
                                            setAirportList(popularCities);
                                          } else {
                                            changeHandler(
                                              "from",
                                              newValue?.countryCode
                                            );
                                            // arriveAutocompleteEl.current.focus();

                                            // setData({ ...data, from: newValue });
                                            props.setFieldValue(
                                              `sectors[${index}].originObj`,
                                              newValue
                                            );
                                            props.setFieldValue(
                                              `sectors[${index}].origin`,
                                              newValue.airportCode
                                            );
                                            // !dataError.from &&
                                            // arriveAutocompleteEl.current.focus();
                                          }
                                        }}
                                        ref={departAutocompleteEl}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Departure Airport"
                                            variant="outlined"
                                          />
                                        )}
                                        renderOption={(optionsData, propes) =>
                                          renderOptionsComp(optionsData, propes)
                                        }
                                        disablePortal={true}
                                        forcePopupIcon={false}
                                      />
                                      <ErrorMessage
                                        name={`sectors[${index}].originObj`}
                                        component="div"
                                        className="error"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={3}
                                      xs={12}
                                      className="form-group"
                                    >
                                      <label>To</label>
                                      <Autocomplete
                                        PopperComponent={customPopperTo}
                                        name={`sectors[${index}].destinationObj`}
                                        options={airportList || []}
                                        getOptionLabel={(o) =>
                                          o
                                            ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                            : ""
                                        }
                                        value={
                                          props.values.sectors[index]
                                            .destinationObj || ""
                                        }
                                        getOptionSelected={(option, value) =>
                                          option.airportCode ==
                                          value.airportCode
                                        }
                                        onKeyUp={(event) =>
                                          fetchAirports(event.target.value)
                                        }
                                        onChange={(event, newValue, reason) => {
                                          // console.log(newValue);
                                          if (reason === "clear") {
                                            setAirportList(popularCities);
                                          } else {
                                            changeHandler(
                                              "to",
                                              newValue?.countryCode
                                            );
                                            // arriveAutocompleteEl.current.focus();

                                            // setData({ ...data, from: newValue });
                                            props.setFieldValue(
                                              `sectors[${index}].destinationObj`,
                                              newValue
                                            );
                                            props.setFieldValue(
                                              `sectors[${index}].destination`,
                                              newValue.airportCode
                                            );
                                            // !dataError.from &&
                                            //   arriveAutocompleteEl.current.focus();
                                          }
                                        }}
                                        ref={arriveAutocompleteEl}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Arrival Airport"
                                            variant="outlined"
                                          />
                                        )}
                                        renderOption={(optionsData, propes) =>
                                          renderOptionsComp(optionsData, propes)
                                        }
                                        disablePortal={true}
                                        forcePopupIcon={false}
                                      />
                                      <ErrorMessage
                                        name={`sectors[${index}].destinationObj`}
                                        component="div"
                                        className="error"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={3}
                                      xs={12}
                                      className="form-group input-date-picker"
                                    >
                                      <label>Departure</label>
                                      <CustomDatePicker
                                        showDisabledMonthNavigation
                                        name={`sectors[${index}].departDateDisplay`}
                                        minDate={
                                          props.values.sectors[index]
                                            .departDateDisplay
                                        }
                                        customInput={
                                          <TextField
                                            id="standard-basic"
                                            variant="outlined"
                                            fullWidth
                                          />
                                        }
                                        selected={
                                          props.values.sectors[index]
                                            .departDateDisplay
                                        }
                                        onChange={(date) => {
                                          props.setFieldValue(
                                            `sectors[${index}].departDateDisplay`,
                                            date
                                          );
                                          props.setFieldValue(
                                            `sectors[${index}].departDate`,
                                            dateFnsFormat(date, "yyyy-MM-dd")
                                          );
                                          setEndDate(date);
                                          // setStartDate(date);
                                        }}
                                        monthsShown={calenderMonth}
                                        dateFormat="dd MMM yyyy"
                                      />
                                    </Grid>
                                    <Grid
                                      className={
                                        classes.modifycontainer + " form-group"
                                      }
                                      style={{
                                        //marginBottom: 15,
                                        gap: 8,
                                        // flexWrap: "nowrap",
                                      }}
                                      item
                                      md={3}
                                      xs={12}
                                    >
                                      <Grid className="form-group">
                                        {props.values.mode === "MULTICITY" ? (
                                          index ===
                                          props.values.sectors.length - 1 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginTop: 30,
                                                // paddingTop:
                                                //   isMobileScreen && 12,
                                                gap: 10,
                                              }}
                                            >
                                              {index < 4 && (
                                                <Button
                                                  onClick={() =>
                                                    push({
                                                      originObj:
                                                        index > 0
                                                          ? props.values
                                                            .sectors[index]
                                                            .destinationObj
                                                          : props.values
                                                            .sectors[0]
                                                            .destinationObj,
                                                      origin:
                                                        index > 0
                                                          ? props.values
                                                            .sectors[index]
                                                            .destinationObj
                                                            .airportCode
                                                          : props.values
                                                            .sectors[0]
                                                            .destinationObj
                                                            .airportCode,
                                                      destinationObj:
                                                        props.values.sectors[0]
                                                          .originObj,
                                                      destination:
                                                        props.values.sectors[0]
                                                          .originObj
                                                          .airportCode,
                                                      departDateDisplay:
                                                        index > 0
                                                          ? props.values
                                                            .sectors[index]
                                                            .departDateDisplay
                                                          : props.values
                                                            .sectors[0]
                                                            .departDateDisplay,
                                                      departDate:
                                                        index > 0
                                                          ? dateFnsFormat(
                                                            props.values
                                                              .sectors[index]
                                                              .departDateDisplay,
                                                            "yyyy-MM-dd"
                                                          )
                                                          : dateFnsFormat(
                                                            props.values
                                                              .sectors[0]
                                                              .departDateDisplay,
                                                            "yyyy-MM-dd"
                                                          ),
                                                    })
                                                  }
                                                  variant="contained"
                                                  color="primary"
                                                  style={{ width: "50%" }}
                                                >
                                                  Add
                                                </Button>
                                              )}
                                              {index !== 0 && (
                                                <Button
                                                  onClick={() => remove(index)}
                                                  variant="contained"
                                                  color="primary"
                                                  style={{ width: "50%" }}
                                                >
                                                  Remove
                                                </Button>
                                              )}
                                            </div>
                                          )
                                        ) : (
                                          <CustomDatePicker
                                            showDisabledMonthNavigation
                                            minDate={endDate}
                                            disabled={
                                              props.values.mode === "ONE"
                                            }
                                            customInput={
                                              <TextField
                                                id="standard-basic"
                                                variant="outlined"
                                                fullWidth
                                                disabled={
                                                  props.values.mode === "ONE"
                                                }
                                              />
                                            }
                                            selected={
                                              props.values.mode === "ROUND" &&
                                              endDate
                                            }
                                            onChange={(date) => {
                                              setEndDate(date);
                                              props.setFieldValue(
                                                "returnDateDisplay",
                                                date
                                              );
                                              props.setFieldValue(
                                                "returnDate",
                                                dateFnsFormat(
                                                  date,
                                                  "yyyy-MM-dd"
                                                )
                                              );
                                            }}
                                            monthsShown={calenderMonth}
                                            dateFormat="dd-MMM-yyyy"
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}
                            <Grid
                              className={classes.modifycontainer}
                              container
                              style={{
                                //marginBottom: 10,
                                flexWrap: "nowrap",
                              }}
                            >
                              <Grid
                                item
                                md={2}
                                xs={12}
                                className="form-group"
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: 5,
                                  minWidth: 250,
                                  // paddingLeft: 5,
                                  // paddingRight: 5,
                                  // border: "1px solid #d3d3d3",
                                  // marginTop: 17,
                                  maxHeight: 60,
                                }}
                              >
                                {" "}
                                <label>Travelers</label>
                                <OaSwitchInput
                                  labeltxt="TRAVELERS"
                                  iconbuttonstyle={IconButtonStyle}
                                  remadt={() => {
                                    if (travelers.adult === 0) return;
                                    addRemoveTravelerHandler("adult", -1);
                                  }}
                                  addadt={() => {
                                    addRemoveTravelerHandler("adult", 1);
                                  }}
                                  remchd={() => {
                                    if (travelers.child === 0) return;
                                    addRemoveTravelerHandler("child", -1);
                                  }}
                                  addchd={() => {
                                    addRemoveTravelerHandler("child", 1);
                                  }}
                                  reminf={() => {
                                    if (travelers.infant === 0) return;
                                    addRemoveTravelerHandler("infant", -1);
                                  }}
                                  addinf={() => {
                                    addRemoveTravelerHandler("infant", 1);
                                  }}
                                  travelers={travelers}
                                />
                              </Grid>
                              <Grid item md={2} xs={12} className="form-group">
                                <label>Class</label>
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    value={props.values.preferredclass}
                                    // onChange={handleClassChange}
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "preferredclass",
                                        event.target.value
                                      )
                                    }
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"E"}>Economy</MenuItem>
                                    <MenuItem value={"ER"}>
                                      Premium Economy
                                    </MenuItem>
                                    <MenuItem value={"B"}>Business</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={2} xs={12} className="form-group">
                                <label>Stops</label>
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    value={props.values.isNonStop}
                                    // onChange={handleClassChange}
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "isNonStop",
                                        event.target.value
                                      )
                                    }
                                  >

                                    <MenuItem value={true}>Stop</MenuItem>
                                    <MenuItem value={false}>Non Stop</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={2} xs={12} className="form-group">
                                <label>Airlines</label>
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    multiple
                                    fullWidth
                                    name="airline"
                                    open={airlineSelectOpen}
                                    value={props.values.preferredAirline}
                                    onChange={(e) => {
                                      // setData({ ...data, airlines: e.target.value });
                                      // setDataError({ ...dataError, airlines: false });
                                      // console.log(e.target.value);
                                      props.setFieldValue(
                                        `preferredAirline`,
                                        e.target.value
                                      );
                                      // setAirlineSelectOpen(false);
                                    }}
                                    renderValue={(selected) => {
                                      let initialValue = "";

                                      if (
                                        selected.includes(
                                          allAirlines
                                        )
                                      ) {
                                        initialValue =
                                          initialValue + ", " + "All";
                                      }
                                      if (
                                        selected.includes(
                                          lccAirlines
                                        )
                                      ) {
                                        initialValue =
                                          initialValue + ", " + "LCC Only";
                                      }
                                      if (selected.includes(gdsAirlines)) {
                                        initialValue =
                                          initialValue + ", " + "GDS Only";
                                      }
                                      initialValue =
                                        carrier &&
                                        carrier.reduce(
                                          (previousValue, currentValue) =>
                                            selected.includes(currentValue.name)
                                              ? previousValue +
                                              ", " +
                                              currentValue.description
                                              : previousValue,
                                          initialValue
                                        );

                                      return (
                                        initialValue &&
                                        initialValue.substring(
                                          1,
                                          initialValue.length - 1
                                        )
                                      );
                                    }}
                                    onOpen={() => setAirlineSelectOpen(true)}
                                    onClose={() => {
                                      // setDataError({
                                      //   ...dataError,
                                      //   airlines: true,
                                      // });

                                      setAirlineSelectOpen(false);
                                    }}
                                  >
                                    <MenuItem
                                      key={
                                        allAirlines
                                      }
                                      value={
                                        allAirlines
                                      }
                                      checked
                                    >
                                      <ListItemText primary={"All"} />
                                      <Checkbox
                                        checked={
                                          props.values.preferredAirline.indexOf(
                                            allAirlines
                                          ) > -1
                                        }
                                      />
                                    </MenuItem>

                                    <MenuItem
                                      key={
                                        lccAirlines
                                      }
                                      value={
                                        lccAirlines
                                      }
                                    >
                                      <ListItemText primary={"LCC Only"} />
                                      <Checkbox
                                        checked={
                                          props.values.preferredAirline.indexOf(
                                            lccAirlines
                                          ) > -1
                                        }
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      key={gdsAirlines}
                                      value={gdsAirlines}
                                    >
                                      <ListItemText primary={"GDS Only"} />
                                      <Checkbox
                                        checked={
                                          props.values.preferredAirline.indexOf(
                                            gdsAirlines
                                          ) > -1
                                        }
                                      />
                                    </MenuItem>

                                    {carrier &&
                                      carrier.map((a) => (
                                        <MenuItem key={a.name} value={a.name}>
                                          <ListItemText
                                            primary={a.description}
                                          />
                                          <Checkbox
                                            checked={
                                              props.values.preferredAirline.indexOf(
                                                a.name
                                              ) > -1
                                            }
                                          />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {props.values.preferredAirline.length ===
                                    0 && (
                                      <FormHelperText>
                                        Please choose an airline
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.modifyActions}
                              >
                                <a
                                  className={classes.action_reset}
                                  onClick={propes.cancelModify}
                                >
                                  <span>Cancel</span>
                                </a>
                                <a
                                  item
                                  className={classes.action_update}
                                  onClick={submitModify}
                                >
                                  <span>Search</span>
                                </a>
                              </Grid>
                            </Grid>
                          </CardBody>
                        )}
                      </FieldArray>
                    </div>
                  </Form>
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapPropsToState = (state, props) => {
  return {
    domCarriers: state.flight.domCarriers,
    intlCarriers: state.flight.intlCarriers,
    airports: state.flight.airports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDomesticCarriers: (domCarriers) =>
      dispatch(setDomesticCarriers(domCarriers)),
    setInternationalCarriers: (intlCarriers) =>
      dispatch(setInternationalCarriers(intlCarriers)),
    setAirports: (airports) => dispatch(setAirports(airports)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(FlightModify);

const renderOptionsComp = (optionsData, propes) => {
  // console.log("render options", optionsData);
  // console.log("render options props", propes);
  return (
    <li
      selected={propes.selected}
      className="ac-options-list"
      style={{ width: 250, overflow: "hidden" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <span className="ac-option-left">
          {optionsData.cityName} ({optionsData.airportCode})
        </span>
        <span className="ac-option-right">{optionsData.countryCode}</span>
      </div>
      <p style={{ whiteSpace: "nowrap" }}>{optionsData.airportDesc}</p>
    </li>
  );
};

const customPopperFrom = function (props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={departAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const customPopperTo = function (props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={arriveAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const flightWayList = [
  { val: "ONE", text: "One Way" },
  { val: "ROUND", text: "Round Trip" },
  { val: "ROUNDSP", text: "Round Trip Special" },
  { val: "MULTICITY", text: "Multi City" },
];

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const bkClass = [
  { value: "None", label: "Select Class", type: "default-null" },
  { value: "E", label: "Economy", type: "value" },
  { value: "ER", label: "Premium Economy", type: "value" },
  { value: "B", label: "Business", type: "value" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: "15px 0px",
    minHeight: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    textAlign: "left",
    "& .cardContainer": {
      padding: 0,
    },
    "& .passenger-value": {
      color: "#000000 !important",
    },
    "& h1": {
      textShadow: "-1px -1px 25px rgb(0 0 0 / 10%)",
      color: "#000000",
      fontWeight: 700,
      marginBottom: "1.5rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "4.5rem",
        marginTop: 0,
      },
      [theme.breakpoints.down(1500)]: {
        fontSize: "3.3rem",
        marginTop: 0,
        marginBottom: "0.5rem",
      },
    },
    "& .react-datepicker__month-container": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.background.primaryContrast,
    },
    "& .MuiAutocomplete-listbox": {
      overflowY: "auto !important",
      width: "320px !important",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.background.rgba_primary,
        borderRadius: 8,
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: theme.palette.background.rgba_primary_active,
        },
      },
      "& .ac-options-list": {
        overflow: "hidden",
        width: "100%",
        "& span": {
          marginBottom: 0,
          color: theme.palette.primary.darkFadedText,
          whiteSpace: "nowrap",
        },
        "& div": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          flexWrap: "nowrap",
          "& .ac-option-left": {
            textAlign: "left",
            color: theme.palette.primary.darkText,
            fontWeight: 600,
            flexGrow: 1,
          },
          "& .ac-option-right": {
            textAlign: "right",
            color: theme.palette.primary.darkFadedText,
          },
        },
      },
    },
    "& .react-datepicker-popper": {
      zIndex: 9,
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .input-date-picker": {
      "& .MuiOutlinedInput-input": {
        height: 22.2,
      },
    },
    "& .form-group": {
      margin: "8px 5px",
    },
  },
  radioGroup: {
    "& .MuiFormControlLabel-label, .MuiIconButton-label": {
      color: theme.palette.background.primaryContrast,
      //  fontWeight: 600,
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      "& .MuiIconButton-label": {
        color: theme.palette.background.secondary,
      }
    },
  },
  modifyActions: {
    margin: "8px 5px",
    marginTop: 35,
    display: "flex",
    justifyContent: "end",
    // height: "40px",
    alignItems: "center",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "700",
    gap: "20px",
    // [theme.breakpoints.down(1080)]: {
    //   fontSize: "12px",
    //   height: "30px",
    // },
  },
  modifycontainer: {
    display: "flex",
    [theme.breakpoints.down(700)]: {
      display: "initial",
    },
  },

  action_reset: {
    height: 40,
    cursor: "pointer",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "4px",
    padding: "3px 8px",
    display: "table",
    "& span": {
      display: "table-cell",
      verticalAlign: "middle",
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& span": {
        color: theme.palette.primary.contrastText,
      },
    },
    // [theme.breakpoints.down(1620)]: {
    //   display: "none",
    // },
  },
  action_update: {
    height: 40,
    cursor: "pointer",
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "4px",
    padding: "3px 8px",
    display: "table",
    "& span": {
      display: "table-cell",
      verticalAlign: "middle",
    },
    "&:hover": {
      backgroundColor: "transparent",
      "& span": {
        color: theme.palette.secondary.main,
      },
    },
  },

  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.background.darkDefault,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.background.primary,

      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.primary,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiToggleButton-sizeSmall": {
      height: 24,
      minWidth: 75,
      [theme.breakpoints.down(840)]: {
        minWidth: 0,
      },
      [theme.breakpoints.down(614)]: {
        minWidth: 75,
      },
      [theme.breakpoints.down(358)]: {
        marginBottom: 20,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      [theme.breakpoints.down(358)]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
}));
