import React, {useState, useEffect} from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import {localforageGetItem} from 'oautils/oaForageUtils';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Grid } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CreateIcon from '@material-ui/icons/Create';
import PaymentIcon from '@material-ui/icons/Payment';
import FlightIcon from '@material-ui/icons/Flight';
import MoneyIcon from '@material-ui/icons/Money';

import OaAgentDetailMenuButton from 'oahoc/OaAgentDetailMenuButton';
import OaSwitch from 'oahoc/OaSwitch';
import { useTheme } from '@material-ui/core';


export const AgentDetails = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        localforageGetItem('agency-list-1', function(error, slist){
            let list = JSON.parse(slist);
            if(!error && slist){
                let data = list.find(o => o.agencyId === props.agencyId);
                setData(data);
                setIsLoading(false);
                setIsError(error !== null);
            }else{
                setIsError(true);
                setIsLoading(false);
            }
        });

    }, [isLoading, props.agencyId]);
    const theme=useTheme()
    return (
        <Grid>
            <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                {isLoading && (<Skeleton variant="rect" width={368} height={105} />)}

                {!isLoading && (
                    <div style={{verticalAlign: "top"}}>
                        {isError && (<h5>Agency ID: {props.agencyId}</h5>)}

                        {!isError && !isLoading && ( 
                            <div style={{color:theme.palette.secondary.sub}}>
                                <div style={{fontWeight: "bold"}}>{data.agencyName} ({data.agencyId})</div>
                                <div>{data.agentName}</div>
                                <div>{data.address} {data.state} {data.pincode}</div>
                                <div><PhoneIcon style={{ fontSize: 14, verticalAlign: "middle" }}/> {data.phone}</div>
                                <div><EmailIcon style={{ fontSize: 14, verticalAlign: "middle" }}/> {data.email}</div>
                            </div>
                        )}
                    </div>
                )}
                </GridItem>
                <GridItem  xs={12} sm={12} md={8}>
                    <div style={{verticalAlign: "top"}}>
                        <div>
                            {/*
                            <OaAgentDetailMenuButton icon={<PersonIcon />} label="Profile"  url={`/admin/user/agents/account/${props.agencyId}`}/>
                            <OaAgentDetailMenuButton icon={<CreateIcon />} label="Change Password" url={`/admin/user/agents/change-password/${props.agencyId}`}/>
                            */}
                            <OaAgentDetailMenuButton icon={<MoneyIcon />} label="GST Details" url={`/admin/management/gst-details/${props.agencyId}`}/>

                            <OaAgentDetailMenuButton icon={<AccountBalanceWalletIcon />} label="Agent Funds" url={`/admin/management/balance/b2b/funds/${props.agencyId}`}/>
                            <OaAgentDetailMenuButton icon={<LibraryBooksIcon />} label="Ledger" url={`/admin/ledger/${props.agencyId}`}/>
                            <OaAgentDetailMenuButton icon={<PaymentIcon />} label="Deposit Request" url={`/admin/management/balance/b2b/update/${props.agencyId}`} />

                            <OaAgentDetailMenuButton icon={<FlightIcon />} label="Flight" url={`/admin/report/flight/${props.agencyId}`} />
                            <OaAgentDetailMenuButton 
                                icon={<MoneyIcon />} 
                                label="Commission" 
                                url={`/admin/management/commission/agent/flight/domestic/${props.agencyId}`} 
                            />
                        </div>
                        <div>
                            <Grid component="label" container alignItems="center">
                                <Grid item><OaSwitch label={'General'} checked={true} disabled={false}></OaSwitch></Grid>
                                <Grid item><OaSwitch label={'DMT'} checked={false} disabled={true}></OaSwitch></Grid>
                                <Grid item><OaSwitch label={'Recharge'} checked={true} disabled={false}></OaSwitch></Grid>
                                <Grid item><OaSwitch label={'IRCTC'} checked={false} disabled={true}></OaSwitch></Grid>
                            </Grid>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </Grid>

    )
    

}

export default React.memo(AgentDetails);