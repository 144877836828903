import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import OaCard from 'oahoc/OaCard';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormPasswordField from 'oahoc/OaFormPasswordField';
import Collapse from '@material-ui/core/Collapse';
import OaFormSelectField from 'oahoc/OaFormSelectField';

import OaFormFileUpload from 'oahoc/OaFormFileUploadNew';

import {scrollToTop} from 'oautils/oaCommonUtils';

import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';
import OaFormRadio from 'oahoc/OaFormRadio';

import axios from 'axios';
import * as Yup from 'yup';
import { Typography } from '@material-ui/core';
import { AppConstant } from "appConstant";



const PartnerRegistration = (props) => {

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [gstCities, setGstCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isIATARegister, setIsIATARegister] = useState(false);
    const [isGSTAvailable, setIsGSTAvailable] = useState(false);
    const [imageReinitialize, setImageReinitialize] = useState(false);

    const [commissionTypes, setCommissionTypes] = useState([]);

    const [uploadedPanImageName, setUploadedPanImageName] = useState('');
    const [uploadedAddressImageName, setUploadedAddressImageName] = useState('');
    const [uploadedPanImageNameBack, setUploadedPanImageNameBack] = useState('');
    const [uploadedAddressImageNameBack, setUploadedAddressImageNameBack] = useState('');
    const [uploadedLogoImageName, setUploadedLogoImageName] = useState('');
    const [uploadedGSTImageName, setUploadedGSTImageName] = useState('')
    const [uploadedIATAImageName, setUploadedIATAImageName] = useState('')

    const [isPanCardImage, setIsPanCardImage] = useState(false);
    const [isAadhaarCardImage, setIsAadhaarCardImage] = useState(false);

    let tabs = [
      { name: 'agent-registration', label: 'Register Agent', url: '/user/agent-registration' },
    ];
    let currentTab = 'agent-registration';

    const [collapsed, setCollapsed] = useState(true);
    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    const stateOnChange=function(value){
      for (let i = 0; i < states.length; i++) {
          if (states[i].stateName == value) {
              setCities(states[i].cities);
          }
      }
  }

  const stateOnChangeGST = function (value) {
    for (let i = 0; i < states.length; i++) {
      if (states[i].stateId == value) {
        setGstCities(states[i].cities);
      }
    }
  }

  useEffect(() => {
    if(isLoading){
        apiCall(WebApi.getStateCity, {}, (response) => {
            if(response.success) {
                setStates(response.data);

                apiCall(WebApi.getCommissionTypes, {}, (response) => {
                  if(response.success) {
                      setCommissionTypes(response.data);
                      setIsLoading(false);
                  } else {
                      setIsError(true);
                  }
                });

                // setIsLoading(false);
            } else {
                setIsError(true);
            }
        });
    }
}, [isLoading]);

    return(
        <>
         <div style={{paddingLeft:"14%",paddingRight:"14%"}}>
          <OaCard>
          <GridItem xs={12} sm={12} md={12}> 

            <OaFormAlerts 
                    isSaving={isSaving} 
                    isSuccess={isSuccess} 
                    isError={isError} 
                    infoText={infoText} 
                    setIsError={setIsError} 
                    setIsSuccess={setIsSuccess} 
                />

            <Formik 
                initialValues={{ 
                    userName: '',
                    panNumber: '',
                    firstName: '',
                    lastName: '',
                    gender: '',
                    partnerName: "",
                    companyName: '',
                    address: '',
                    state: '',
                    city: '',
                    pincode: '',
                    phoneNumber: '',
                    mobileNumber: '',
                    website: '',
                    // emailIndividual: '',
                    //confirmemailIndividual: '',
                    emailAdmin: '',
                    confirmemailAdmin: '',
                    officeSpaceType:'',
                    commissionUserId: '',
                    referSaleperson:"",
                    referByDistributor:"",
                    distributorId:"",
                    salePersonId:"",
                    howdidhear:"",
                }}
                validationSchema={Yup.object().shape({
                  userName: Yup.string().required('User Name is required').matches(/^[A-Za-z]+$/, "Invalid Username"),
                  panNumber: Yup.string()
                    .required('PAN is required')
                    .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
                  firstName: Yup.string().required('First Name is required').matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid First Name"),
                  lastName: Yup.string().required('Last Name is required')
                  .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid Last Name"),
                  gender: Yup.string().required('Gender is required'),
                  partnerName: Yup.string().required("Partner name is required"),
                  companyName: Yup.string().required('Company name is required'),
                  address: Yup.string().required('Office address is required'),
                  state: Yup.string().required('State is required'),
                  city: Yup.string().required("City is required"),
                  commissionUserId: Yup.string().required("Commission group is required"),
                  phoneNumber: Yup.string().matches(phoneRegex, 'Invalid phone number'),
                  mobileNumber: Yup.string()
                      .required("Mobile Number is required")
                      .matches(phoneRegex, 'Invalid mobile number'),
                  // password: Yup.string()
                  // .required("Password is required"),
                  // confirmpassword: Yup.string()
                  // .required("Confirmation password is required")
                 // .oneOf([Yup.ref('password'), null], "Confirm password doesn't match"),
                  pincode: Yup.string()
                    .required("PIN code is required")
                    .matches(/^[0-9]{6}$/, "Invalid PIN code"),
                  website: Yup.string()
                      .url("Invalid website address"),
                  // emailIndividual: Yup.string()    
                  //     .required("Email is required")
                  //     .email("Invalid Email"),
                  // confirmemailIndividual: Yup.string()
                  //     .required("Confirmation email is required")
                  //     .email("Invliad Email")
                  //     .oneOf([Yup.ref('emailIndividual'), null], "Confirm email doesn't match") ,
                  emailAdmin: Yup.string()    
                      .required("Admin Email is required")
                      .email("Invalid Email"),
                  confirmemailAdmin: Yup.string()
                      .required("Confirmation admin email is required")
                      .email("Invliad Email")
                      .oneOf([Yup.ref('emailAdmin'), null], "Confirm admin email doesn't match"),
                  officeSpaceType: Yup.string().required('Office space type is required'),
                        
                })}
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                  setIsPanCardImage(false);
                  setIsAadhaarCardImage(false);
                  if(!uploadedPanImageName){
                    setIsPanCardImage(true);
                    return false;
                  }

                  if(!uploadedAddressImageName){
                    setIsAadhaarCardImage(true);
                    return false;
                  }

                  setIsError(false);
                  setIsSuccess(false);
                  setSubmitting(true);
                 
                  scrollToTop(0);
                  
                  searchParams.country = "India";

                  searchParams.logoImage = uploadedLogoImageName;
                  searchParams.panImage = uploadedPanImageName;
                  searchParams.aadhaarCardImage = uploadedAddressImageName;
                  searchParams.panImageBack = uploadedPanImageNameBack;
                  searchParams.aadhaarCardImageBack = uploadedAddressImageNameBack;
                  searchParams.gstImage = uploadedGSTImageName;
                  searchParams.iataImage = uploadedIATAImageName;
                  
                  searchParams.isIATARegister =isIATARegister;
                  // console.log("The search params are ", searchParams);
                  setIsSaving(true);
                  apiCall(WebApi.registerPartner, searchParams, (response) => {
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        setInfoText(response.message);
                        if(response.success === true) {
                            setInfoText('Distributor successfully created, your distributor will get a confirmation mail on activation. Thank you.');
                            enableReinitialize=true;
                            resetForm(true);
                            setImageReinitialize(true);
                        }
                    });
              }}
                
                >
                {({ values, isSubmitting, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>
                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Login Details</h4>

                        <GridContainer>
                            <GridItem  md={6}>
                              <OaFormTextField name="userName" label="User Name*" />
                              <ErrorMessage name="userName" component="div" className="error" />
                            </GridItem>
                            <GridItem  md={6}>
                              <OaFormTextField name="panNumber" label="PAN*" />  
                              <ErrorMessage name="panNumber" component="div" className="error"/>
                            </GridItem> 
                           
                          </GridContainer>
                      </OaCard>

                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Ownership Details</h4>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormTextField label="First Name*" name="firstName" />
                            <ErrorMessage name="firstName" component="div" className="error" />
                          </GridItem>  
                          <GridItem  md={6}>
                            <OaFormTextField label="Last Name*" name="lastName"  />  
                            <ErrorMessage name="lastName" component="div" className="error" />
                          </GridItem>          
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6} xs={12}>
                            <OaFormSelectField 
                                  label="Gender*" 
                                  name="gender" 
                                  value={values.gender} 
                                  options={[
                                      {value: 'M', label: 'Male'},
                                      {value: 'F', label: 'Female'},
                                      {value: 'T', label: 'Transgender'}
                                  ]}
      
                              />
                              <ErrorMessage name="gender" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                      </OaCard>

                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Communication Details</h4>
                        <GridContainer>
                        
                        <OaFormTextField label="Partner Name*" name="partnerName" md={12} />
                        <GridItem  md={5}>
                            <ErrorMessage name="partnerName" component="div" className="error" />
                          </GridItem>
                          <OaFormTextField label="Company Name*" name="companyName" md={12} />
                          <GridItem md={5}>
                            <ErrorMessage name="companyName" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <OaFormTextField label="Office Address*" name="address" md={12}/>
                          <GridItem md={5}>
                            <ErrorMessage name="address" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormSelectField 
                                label="State*"
                                name="state"
                                value={values.gstState}
                                options={states}
                                optionValue={'stateName'}
                                optionLabel={'stateName'}
                                onChange={(value) => {
                                    setFieldValue('state',  value);
                                    stateOnChange(value)
                                }}
                            />
                            <ErrorMessage name="state" component="div" className="error" />
                          </GridItem>
                        
                          <GridItem  md={6}>
                            <OaFormSelectField 
                              label="City*"
                              name="city"
                              value={values.gstCity}
                              options={cities}
                              optionValue={'cityName'}
                              optionLabel={'cityName'}
                            />
                            <ErrorMessage name="city" component="div" className="error" />
                          </GridItem>
                              
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormTextField label="PIN Code*" name="pincode"/>
                            <ErrorMessage name="pincode" component="div" className="error" />
                          </GridItem>
                          <GridItem  md={6}>
                            <OaFormTextField label="Phone Number" name="phoneNumber" />
                            <ErrorMessage name="phoneNumber" component="div" className="error" />
                          </GridItem>
                          
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormTextField label="Mobile Number*" name="mobileNumber"/>
                            <ErrorMessage name="mobileNumber" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField label="Website(E.g..https://www.google.com)" name="website" />
                            <ErrorMessage name="website" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        
                        <GridContainer>
                          <GridItem md={6}>
                            <OaFormTextField name="emailAdmin" label="Admin Email*"/>
                            <ErrorMessage name="emailAdmin" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField label="Confirm Admin Email*" name="confirmemailAdmin" />
                            <ErrorMessage name="confirmemailAdmin" component="div" className="error" />
                          </GridItem>
                          <GridItem  md={6}>
                              <OaFormSelectField 
                                    label="Office Space*" 
                                    name="officeSpaceType" 
                                    value={values.officeSpaceType} 
                                    options={[
                                        {value: 'rented', label: 'Rented'},
                                        {value: 'owned', label: 'Owned'}
                                    ]}
                                />
                                <ErrorMessage name="officeSpaceType" component="div" className="error" />
                            </GridItem>
                            <GridItem md={6}>
                              <OaFormRadio label="IATA Registration" name="isIATARegister" value="isIATARegister" checked={isIATARegister} onChange={()=>{setIsIATARegister(!isIATARegister);}}/> 
                              <ErrorMessage name="isIATARegister" component="div" className="error" />
                            </GridItem>
                        </GridContainer>
                        {
                        isIATARegister &&
                        <GridContainer>

                          <GridItem md={6}>
                            <OaFormTextField label="IATA Registeration Number" name="iataRegisterationNumber" />
                            <ErrorMessage name="iataRegisterationNumber" component="div" className="error" />
                          </GridItem>
                          <OaFormFileUpload
                            label={"IATA DOCUMENT"}
                            type="IATA_IMAGES"
                            setUploadedImageName={setUploadedIATAImageName}
                            imageReinitialize={imageReinitialize}
                          />
                        </GridContainer>
                      }
                      </OaCard>

                      <OaCard>

                      <h4 className={"oa-form-header"} onClick={collapse} style={{ transform: ' scale(0.85)', transformOrigin: 'left' }}>GST Details</h4>
                      <GridContainer>
                        <GridItem md={6}>
                          <OaFormRadio label="GST Details Available " name="isGSTAvailable" value="isGSTAvailable" checked={isGSTAvailable} onChange={() => { setIsGSTAvailable(!isGSTAvailable); }} />
                          <ErrorMessage name="isGSTAvailable" component="div" className="error" />
                        </GridItem>
                      </GridContainer>
                      {
                        isGSTAvailable &&
                        <GridContainer>
                          <GridItem md={6}>
                            <OaFormTextField required={true} label="GSTIN" name="gstNumber" />
                            <ErrorMessage name="gstNumber" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField required={true} label="GST Name" name="gstName" />
                            <ErrorMessage name="gstName" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField required={true} label="GST Phone Number" name="gstMobile" />
                            <ErrorMessage name="gstMobile" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField required={true} label="GST Email" name="gstEmail" />
                            <ErrorMessage name="gstEmail" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormSelectField
                              label="Agency GST State"
                              name="gstState"
                              value={values.gstState}
                              options={states}
                              optionValue={'stateId'}
                              optionLabel={'stateName'}
                              onChange={value => {
                                setFieldValue('gstState', value);
                                stateOnChangeGST(value)
                              }}

                            />
                            <ErrorMessage name="gstState" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormSelectField
                              label="Agency GST City"
                              name="gstCity"
                              value={values.gstCity}
                              options={gstCities}
                              optionValue={'cityId'}
                              optionLabel={'cityName'}

                            />
                            <ErrorMessage name="gstCity" component="div" className="error" />
                          </GridItem>
                          {/* <ErrorMessage name="gstEmail" component="div" className="gstCity"/> */}
                          <GridItem md={6}>
                            <OaFormTextField required={true} label="GST Pincode" name="gstPincode" />
                            <ErrorMessage name="gstPincode" component="div" classname="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField required={true} label="GST Address" name="gstAddress" multiline={true} rows="5" />
                            <ErrorMessage name="gstAddress" component="div" classname="error" />
                          </GridItem>

                          <OaFormFileUpload
                            label={"GST DOCUMENT"}
                            type="GST_IMAGES"
                            setUploadedImageName={setUploadedGSTImageName}
                            imageReinitialize={imageReinitialize}
                          />
                        </GridContainer>
                      }


                    </OaCard>
                      
                      {/* <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>SMTP Details</h4>
                        <GridContainer>
                          <GridItem md={6}>
                            <OaFormTextField label="Email*" name="emailIndividual" />
                            <ErrorMessage name="emailIndividual" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField label="Confirm Email*" name="confirmemailIndividual" />
                            <ErrorMessage name="confirmemailIndividual" component="div" className="error" />
                          </GridItem>         
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                              <OaFormTextField label="SMTP Address" name="smtpAddress" />
                              <ErrorMessage name="smtpAddress" component="div" className="error" />
                            </GridItem>
                            <GridItem md={6}>
                              <OaFormTextField label="SMTP Password" name="smtpPassword"  /> 
                              <ErrorMessage name="smtpPassword" component="div" className="error" />
                            </GridItem>
                            <GridItem  md={6}>
                              <OaFormTextField label="SMTP Port" name="smtpPort"  />
                              <ErrorMessage name="smtpPort" component="div" className="error" />
                            </GridItem>
                            <GridItem md={6}>
                              <OaFormTextField label="Subdomain" name="subdomain"  /> 
                              <ErrorMessage name="subdomain" component="div" className="error" />
                            </GridItem>
                        </GridContainer>
                      </OaCard> */}

                    <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Commission Group</h4>
                        <GridContainer>
                        <GridItem  md={6}>
                            <OaFormSelectField 
                              label="Commission Group*"
                              name="commissionUserId"
                              value={values.commissionUserId}
                               options={[
                                {value: 'platanium', label: 'platanium'},
                                {value: 'gold', label: 'gold'},
                                {value: 'silver', label: 'silver'},
                                {value: 'bronze', label: 'bronze'},
                                
                            ]}
                              
                            />
                            <ErrorMessage name="commissionUserId" component="div" className="error" />
                            </GridItem>
                          </GridContainer>
                      </OaCard>
                      
                      <OaCard>
                        <OaCard>
                        <GridContainer>
                            <GridItem md={6}>
                          <OaFormFileUpload 
                            label={"PAN CARD FRONT"}
                            type="PAN_CARD" 
                            setUploadedImageName={setUploadedPanImageName}
                            imageReinitialize={imageReinitialize}
                          />
                          </GridItem>
                          <GridItem md={6}>
                          <OaFormFileUpload 
                            label={"PAN CARD BACK"}
                            type="PAN_CARD" 
                            setUploadedImageName={setUploadedPanImageNameBack}
                            imageReinitialize={imageReinitialize}
                          />
                          </GridItem>
                          </GridContainer>
                        </OaCard>
                        <Collapse in={isPanCardImage}>
                          <div name="panImage" className="error">PAN Card is required for KYC</div>
                        </Collapse>
                        
                        <OaCard>
                          <GridContainer>
                            <GridItem md={6}> 
                              <OaFormFileUpload 
                                label={"ADDRESS PROOF FRONT"}
                                type="AADHAR_CARD" 
                                setUploadedImageName={setUploadedAddressImageName}
                                imageReinitialize={imageReinitialize}
                              />
                            </GridItem>
                            <GridItem md={6}>
                              <OaFormFileUpload 
                                  label={"ADDRESS PROOF BACK"}
                                  type="AADHAR_CARD" 
                                  setUploadedImageName={setUploadedAddressImageNameBack}
                                  imageReinitialize={imageReinitialize}
                                />
                            </GridItem>
                          </GridContainer>
                          
                        </OaCard>
                        <Collapse in={isAadhaarCardImage}>
                          <div name="aadhaarCardImage" className="error">Address Proof is required for KYC</div>
                        </Collapse>
                       
                      </OaCard>
                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Other Details</h4>

                        <GridContainer>
                            <GridContainer>
                            <GridItem  md={6}>

                            <OaFormSelectField 
                                  label="Were you referred by saleperson ?"
                                  name="referSaleperson" 
                                  value={values.referSaleperson} 
                                  options={[
                                      {value: 'yes', label: 'Yes'},
                                      {value: 'no', label: 'No'},
                                      
                                  ]}
      
                              />
                                
                              <ErrorMessage name="referSaleperson" component="div" className="error" />
                            </GridItem>
                            {values.referSaleperson==="yes" &&<>
                            <GridItem  md={6}>
                            <OaFormTextField  name="salePersonId" placeholder="Enter Sales PersonId(ex:BUZZ0003)" />
                            </GridItem>
                            </>}
                            </GridContainer>
                            <GridContainer>
                            <GridItem  md={6}>

                            <OaFormSelectField 
                                  label="Were you referred by distributor ?"
                                  name="referByDistributor" 
                                  value={values.referByDistributor} 
                                  options={[
                                      {value: 'yes', label: 'Yes'},
                                      {value: 'no', label: 'No'},
                                      
                                  ]}
      
                              />
                                
                              <ErrorMessage name="referByDistributor" component="div" className="error" />
                            </GridItem>
                            {values.referByDistributor==="yes" &&<>
                            <GridItem  md={6}>
                            <OaFormTextField  name="distributorId" placeholder="Enter DistributorId(ex:BUZZ0003)" />
                            </GridItem>
                            </>}
                           
                            </GridContainer>
                            <GridItem md={6}>
                                {/* <Typography style={{fontSize:14,marginTop:10}}>How did you hear about buzzholidayz ?</Typography> */}
                            <OaFormSelectField 
                                  label={`How did you hear about ${AppConstant.name}?`}
                                  name="howdidhear" 
                                  value={values.howdidhear} 
                                  options={[
                                      {value: 'Search Engine', label: 'Search Engine'},
                                      {value: 'Google Ads', label: 'Google Ads'},
                                      {value: 'Facebook Ads', label: 'Facebook Ads'},
                                      {value: 'Youtube Ads', label: 'Youtube Ads'},
                                      {value: 'Other paid social media advertising', label: 'Other paid social media advertising'},
                                      {value: 'Twitter post', label: 'Twitter post'},
                                      {value: 'Instagram post/story', label: 'Instagram post/story'},
                                      {value: 'Other social media', label: 'Other social media'},
                                      {value: 'Email', label: 'Email'},
                                      {value: 'Radio', label: 'Radio'},
                                      {value: 'TV', label: 'TV'},
                                      {value: 'Newspaper', label: 'Newspaper'},
                                      
                                      
                                      
                                      
                                  ]}
      
                              />
                            </GridItem>
                            <ErrorMessage name="howdidhear" component="div" className="error" />
                          </GridContainer>
                      </OaCard>
                      <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} label={'Register'} inReset={resetForm}/>
                    </Form>
                )}
            </Formik>
          </GridItem>  
        </OaCard>
        </div>
        </>
    )
}

export default PartnerRegistration;
