import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import AirlineSeatReclineExtraRoundedIcon from "@material-ui/icons/AirlineSeatReclineExtraRounded";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import RestaurantRoundedIcon from "@material-ui/icons/RestaurantRounded";
import RotateLeftRoundedIcon from "@material-ui/icons/RotateLeftRounded";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import FlightSearchHeader from "./FlightSearchHeader";
import {
  Grid,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PropTypes from "prop-types";
import dateFnsFormat from "date-fns/format";
import { BASE_URL_UPLOAD } from "api/ApiConstants";
import { COLORS } from "assets/css/CssConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { EmailIcon, WhatsappIcon } from "react-share";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "48%",
    margin: "8px 0",
    backgroundColor: theme.palette.background.darkerFadedGray,
    border: `solid 1px ${theme.palette.primary.faddedRed}`,
  },
  NonMUIFlightCard: {
    minWidth: "530px",
    height: "85px",
    backgroundColor: theme.palette.background.darkerFadedGray,
    padding: "6px",
    margin: "6px",

    [theme.breakpoints.down(1080)]: {
      minWidth: "360px",
    },
  },
  cardContent: {
    display: "grid",
    //gridTemplateColumns: "repeat(4,auto)",
    // gridTemplateRows: "1fr",
    gap: "6px",
    justifyContent: "space-between",
    [theme.breakpoints.down(1080)]: {
      gridTemplateColumns: "repeat(2,auto)",
      gridTemplateRows: "repeat(2,auto)",
    },
  },
  /// 1 --------------------
  airlineDetails: {
    display: "flex",

    [theme.breakpoints.down(1080)]: {
      minWidth: "360px",
    },
  },
  cardContent: {
    display: "grid",
    //gridTemplateColumns: "repeat(4,auto)",
    // gridTemplateRows: "1fr",
    gap: "6px",
    justifyContent: "space-between",
    [theme.breakpoints.down(1080)]: {
      gridTemplateColumns: "repeat(2,auto)",
      gridTemplateRows: "repeat(2,auto)",
    },
  },
  /// 1 --------------------
  airlineDetails: {
    display: "flex",

    //backgroundColor: theme.palette.primary.faddedRed,
    [theme.breakpoints.down(1080)]: {
      gridRow: "2 / span 1",
      gridColumn: "1 / span 1",
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      alignItems: "center",
    },
    width: 150,
    height: 100,
    padding: 5,
  },

  airline_image: {
    width: "fit-content",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "40px",
      [theme.breakpoints.down(1080)]: {
        height: "24px",
        width: "24px",
      },
    },
  },

  airline_title: {
    width: "fit-content",
    margin: "4px 0",
  },
  airline_detailButton: {
    cursor: "pointer",
    "& span": {
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "20px",
      padding: "4px 8px",
    },
    // maxWidth: "72px",
  },

  /// 2 --------------------
  flightDetails: {
    //backgroundColor: theme.palette.background.faddedGreen,
    display: "flex",
    //flexDirection: "column",
    gap: "10px",

    [theme.breakpoints.down(1080)]: {
      gridRow: "1 / span 1",
      gridColumn: "1 / span 1",
      gap: "12px",
    },
    width: 650,
    height: 100,
    padding: 5,
    [theme.breakpoints.down(1240)]: {
      width: 295,
    },
  },
  flightDetails_Timings: {
    display: "flex",
    flexDirection: "row",
    gap: "70px",
    [theme.breakpoints.down(1240)]: {
      gap: "6px",
    },
  },
  departure: {},
  duration: {
    textAlign: "center",
  },
  arrival: {},
  flightDetails_Itinaries: {
    display: "flex",
    gridTemplateColumns: "repeat(2,1fr)",
    gridTemplateRows: "repeat(2,1fr)",
    paddingLeft: 120,
    "& svg": {
      height: "12px",
      [theme.breakpoints.down(1080)]: {
        height: "8px",
      },
    },
  },
  flightDetails_Share: {
    marginTop: -20,
    display: "flex",
    justifyContent: "flex-end",
    "& .share-dropdown-toggle-button": {
      cursor: "pointer",
      borderBottom: `solid 0.5px ${theme.palette.primary.gray}`,
    },
    "& .share-div": {
      marginTop: 5,
      position: "relative",
    },
    "& .share-dropdown": {
      position: "absolute",
      top: -36,
      left: -40,
      borderRadius: 3,
      padding: "2px 15px",
      backgroundColor: theme.palette.background.silverWhite,
      "& i": {
        color: theme.palette.primary.lightText,
      },
    },
    "& .share-button": {
      margin: "2px 8px",
      cursor: "pointer",
      "& i": {
        fontSize: 18,
      },
    },
  },

  /// 3 --------------------
  priceDetails: {
    // backgroundColor: theme.palette.background.faddedBlue,
    [theme.breakpoints.down(1080)]: {
      gridRow: "1 / span 2",
      gridColumn: "2 / span 3",
    },
    width: 140,
    padding: "4px 5px",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down(1290)]: {
      width: 110,
    },
  },
  FlightPricesLabel: {
    marginBottom: "6px",
    "& .MuiFormControlLabel-label": {
      "& p": {
        margin: 0,
        padding: 0,
        fontSize: "16px",
        fontWeight: "700",
        marginBottom: "-10px",
        color: theme.palette.primary.defaultText,
        [theme.breakpoints.down(1320)]: {
          fontSize: "12px",
        },
      },
      "& span": {
        margin: 0,
        padding: 0,
        fontSize: "8px",
        color: theme.palette.primary.defaultText,
        [theme.breakpoints.down(1320)]: {
          fontSize: "6px",
        },
      },
    },
  },

  // -----------------------------

  moreDetailsContent: {
    padding: "4px 12px",
  },
  moreDetailNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: "20px 0",
    "& > div": {
      cursor: "pointer",
    },
  },
  activeMoreDetailNav: {
    fontWeight: 700,
    position: "relative",
    "&::after": {
      position: "absolute",
      display: "block",
      content: "''",
      top: "24px",
      left: 0,
      height: "2px",
      width: "80px",
      borderRadius: "2px",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  notActiveMoreDetailNav: {
    fontWeight: 500,
  },

  moreDetailBody: {},
  // ----
  flightDetailElements: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  flightDetailElement: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flightDetailLayover: {
    margin: "0 0 15px",
    "& span": {
      padding: "4px 12px",
      backgroundColor: `${theme.palette.secondary.main}20`,
      borderRadius: "6px",
      margin: "6px 12px",
    },
  },
  // ----
  FareBreakupTreeView: {
    height: "auto",
    flexGrow: 1,
    maxWidth: 400,
    "& li .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& li .MuiCollapse-wrapperInner .MuiTypography-root": {
      fontSize: "12px",
    },
    "& > li:last-child .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "700",
    },
  },
  // ----
  fareRulesElement: {
    display: "flex",
    flexDirection: "row",
  },
  fareRule_title: {
    marginBottom: "8px",
  },
  fareRule_desc: {
    "& span": {
      lineHeight: "1em !important",
    },
  },
  // ----
  baggageDetailsElements: {},
  baggageDetailsElement: {},
  baggageLocation: {},
  baggageContent: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    margin: "12px 0",
  },
  baggageFlex: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
  },

  // -----------------------------
  f24b: {
    fontSize: "24px",
    fontWeight: 700,
    [theme.breakpoints.down(1240)]: {
      fontSize: "20px",
    },
  },
  f20b: {
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.down(1080)]: {
      fontSize: "16px",
    },
  },
  f16b: {
    fontSize: "16px",
    fontWeight: 700,
    [theme.breakpoints.down(1080)]: {
      fontSize: "12px",
    },
  },
  f12b: {
    fontSize: "12px",
    fontWeight: 700,
    [theme.breakpoints.down(1080)]: {
      fontSize: "10px",
    },
  },
  flighttAmmenitiesDetails_Itinaries: {
    margin: "-5px -6px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      height: "12px",
    },
  },
  f12r: {
    fontSize: "12px",
    fontWeight: 500,
    [theme.breakpoints.down(1080)]: {
      fontSize: "9px",
    },
  },
  f8r: {
    fontSize: "8px",
    fontWeight: 500,
    [theme.breakpoints.down(1080)]: {
      fontSize: "6px",
    },
  },
  showMoreButton: {
    cursor: "pointer",
    "& span": {
      fontSize: 9,
      fontWeight: 500,
      float: "right",
      textDecoration: "underline",
    },
  },
  onwardAction: {
    "& .MuiGrid-item": {
      padding: "10px 25px 0 0",
    },
  },
  onwardBookNowAction: {
    background: theme.palette.buttons.bookNowBtn,
    color: theme.palette.primary.lightText,
    cursor: "not-allowed",
    padding: "9px 20px",
    borderRadius: "6px",
    cursor: "pointer",
    "& p": {
      textAlign: "center",
      fontWeight: "700",
    },
  },
}));

const FlightCard = ({
  fullWidth,
  selected,
  flightData,
  getSelectedFlight,
  tripMode,
  setfdbookdata,
}) => {
  const classes = useStyles();
  const [shareOnwOpen, setShareOnwOpen] = useState(false);
  const [showMoreFares, setShowMoreFares] = useState(false);
  const [isIdSelected, setIsIdSelected] = useState(false);
  const [selectedFareId, setSelectedFareId] = useState(null);
  const [selectedFlightFareId, setSelectedFlightFareId] = useState(null);
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  // console.log("flightData",flightData);
  const setSelectedFlight = (event) => {};

  const shareViaWhatsapp = () => {
    const anch = document.createElement("a");
    anch.href = "https://api.whatsapp.com/send?text=" + shareText;
    anch.dataAction = "share/whatsapp/share";
    anch.target = "_blank";
    anch.click();
  };

  const shareViaEmail = () => {
    const anch = document.createElement("a");
    anch.href =
      "mailto:?subject=Flight Details " +
      departAirport +
      " to " +
      arrivalAirport +
      " " +
      departDate +
      "&body=" +
      shareText;
    anch.target = "_blank";
    anch.click();
  };

  const stops = 0;

  let stopAirports = "";
  const totalJourneyTime = flightData.journeyDuration;
  const flightTime = flightData.flightDuration;

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const flightCode = flightData.carrierCode;
  const airlineCode = flightCode + " " + flightData.flightNo;
  const airlineName = flightData.carrierName;
  const depDateRaw = new Date(flightData.depDate);
  const departDay = days[depDateRaw.getDay()];
  const departDate = dateFnsFormat(depDateRaw, "dd MMM");
  const departTime = flightData.depTime;
  const departAirport = flightData.originAirportCode;

  const arrDateRaw = new Date(flightData.arrDate);
  const arrivalDay = days[arrDateRaw.getDay()];
  const arrivalDate = dateFnsFormat(arrDateRaw, "dd MMM");
  const arrivalTime = flightData.arrTime;
  const arrivalAirport = flightData.destinationAirportCode;
  const availableSeats = flightData.remSeat;

  let shareText = airlineName + " " + airlineCode + "; ";
  shareText +=
    departAirport +
    " to " +
    arrivalAirport +
    "; Departure: " +
    departDate +
    ", " +
    departDay +
    " " +
    departTime +
    "; ";
  shareText +=
    "Arrival: " + arrivalDate + ", " + arrivalDay + " " + arrivalTime + "; ";
  shareText +=
    "Journey Time: " +
    totalJourneyTime +
    "; " +
    (stops > 0
      ? " " + stops + (stops > 1 ? " stops; " : "stop; ")
      : " non-stop; ");

  return (
    <div className={classes.root} style={{ width: fullWidth ? "100%" : "50%" }}>
      <div className={classes.NonMUIFlightCard}>
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
          style={{ width: "100%" }}
        />
        <div
          className={classes.cardContent}
          style={{
            gridTemplateColumns:
              "repeat(" + (tripMode == "ONE" ? 4 : 3) + ",auto)",
          }}
        >
          {/* 1 ------------------------ */}
          <div className={classes.airlineDetails}>
            <div className={classes.airline_image}>
              <img
                src={
                  image_base +
                  "?fileName=" +
                  flightCode +
                  ".png&type=AIRLINE_IMAGES"
                }
                alt="Airline Image"
              />
              <div className={classes.f12r}>{airlineCode}</div>
            </div>
            <div className={classes.airline_title}>
              <div className={classes.f20b}>{airlineName}</div>
            </div>
            {/* <div
                            className={classes.airline_detailButton}
                            onClick={() => setMoreDetailsOpen(!moreDetailsOpen)}
                        >
                            <span className={classes.f8r}>More Details {moreDetailsOpen ? "-" : "+"}</span>
                        </div> */}
          </div>

          {/* 2 ------------------------ */}
          <div className={classes.flightDetails}>
            <div className={classes.flightDetails_Timings}>
              <div className={classes.departure}>
                <div className={clsx(classes.f24b)}>{departTime}</div>
                <div className={clsx(classes.f12r)}>
                  {departDay}, {departDate} - {departAirport}
                </div>
              </div>
              <div className={classes.duration}>
                <div className={clsx(classes.f12r)}>{totalJourneyTime}</div>
                <div className={clsx(classes.f8r)}>
                  {stops > 0
                    ? stops +
                      " stop" +
                      (stops > 1 ? "s " : " ") +
                      "- " +
                      stopAirports
                    : "Non-Stop"}
                </div>
              </div>
              <div className={classes.arrival}>
                <div className={clsx(classes.f24b)}>{arrivalTime}</div>
                <div className={clsx(classes.f12r)}>
                  {arrivalDay}, {arrivalDate} - {arrivalAirport}
                </div>
              </div>
            </div>
            <div className={classes.flightDetails_Itinaries}>
              <div>
                <AirlineSeatReclineExtraRoundedIcon />
                <span
                  className={clsx(
                    classes.FlightAmmenitiesDetailsDesc,
                    classes.f12r
                  )}
                >
                  {availableSeats}
                </span>
              </div>
              <div>
                <LocalMallRoundedIcon />
                <span
                  className={clsx(
                    classes.FlightAmmenitiesDetailsDesc,
                    classes.f12r
                  )}
                >
                  15 kg
                </span>
              </div>
              {/* <div>
                                <RestaurantRoundedIcon />
                                <span
                                    className={clsx(
                                        classes.FlightAmmenitiesDetailsDesc,
                                        classes.f12r
                                    )}
                                >
                                    Free meals
                                </span>
                            </div> */}
              {/* <div>
                                <RotateLeftRoundedIcon />
                                <span
                                    className={clsx(
                                        classes.FlightAmmenitiesDetailsDesc,
                                        classes.f12r
                                    )}
                                >
                                    Refundable
                                </span>
                            </div> */}
            </div>
            {/* <div className={classes.flightDetails_Share}>
                            <div className="share-div">
                                <div
                                    className="share-dropdown-toggle-button"
                                    onClick={() => setShareOnwOpen(!shareOnwOpen)}
                                >
                                    <span className={classes.f12b}>
                                        Share <i className="fas fa-share"></i>
                                    </span>
                                </div>
                                <div className="share-dropdown" style={{ display: shareOnwOpen && shareOnwOpen == true ? "flex" : "none" }}>
                                    <span className="share-button"
                                        onClick={() => shareViaWhatsapp()}
                                    >
                                        <i className="fab fa-whatsapp"></i>
                                    </span>

                                    <span className="share-button"
                                        onClick={() => shareViaEmail()}
                                    >
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                            </div>
                        </div> */}
          </div>

          {/* 3 ------------------------ */}
          <div className={classes.priceDetails}>
            <p style={{ fontSize: 17, fontWeight: 700 }}>
              Rs. {flightData.dealCost}
            </p>
            <div className={classes.flighttAmmenitiesDetails_Itinaries}>
              <RotateLeftRoundedIcon />
              <span
                className={clsx(classes.FlightAmmenitiesDetails, classes.f12r)}
              >
                Refundable
              </span>
            </div>
          </div>

          {/* 4 ------------------------ */}
          {tripMode && tripMode == "ONE" && (
            <div className={classes.onwardAction}>
              <Grid item>
                <Box
                  className={classes.onwardBookNowAction}
                  onClick={() => setfdbookdata(true)}
                  style={{
                    background: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    Book Now
                  </Typography>
                </Box>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlightCard;

const PRICE_LIST = [
  { value: "1", price: "₹5,881.00", label: "Economy, Refundable" },
  { value: "2", price: "₹5,881.00", label: "Economy, Refundable" },
  { value: "3", price: "₹5,881.00", label: "Economy, Refundable" },
];
const radioStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: `${theme.palette.secondary.main}40`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${theme.palette.secondary.main}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.secondary.main}90`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: `${theme.palette.secondary.main}10`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.secondary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.secondary.main}90`,
    },
  },
}));
function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      ref={props.inref}
      {...props}
    />
  );
}
function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="secondary">
            {labelInfo}
          </Typography>
        </div>
      }
      {...other}
    />
  );
}
StyledTreeItem.propTypes = {
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};
const useTreeItemStyles = makeStyles((theme) => {
  //console.log(theme);
  return {
    root: {
      color: theme.palette.action.hover,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    content: {
      color: theme.palette.action.hover,
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  };
});
