import React, { useEffect, useState } from "react";
import OaTable from "components/OaTable";
import WebApi, {
  DOWNLOAD_FLIGHT_PDF,
  DOWNLOAD_FLIGHT_XLS,
} from "api/ApiConstants";
import { currencyFormatter } from "oautils/oaCommonUtils";
import OaButtonPDF from "pages/components/OaButtonPDF";
import OaButtonXls from "pages/components/OaButtonXls";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { withRouter } from "react-router-dom";
import { localforageSetItem, localforageGetItem } from "oautils/oaForageUtils";
import _ from "lodash";
import { makeStyles, Modal } from "@material-ui/core";
// import Itinerary from './Itinerary';
import Itinerary from "pages/b2c/Itinerary";
import { Close } from "@material-ui/icons";
import { apiCall } from "oautils/oaDataUtils";
import MyBookings from "pages/b2c/MyBookings";

const useStyles = makeStyles((theme) => ({
  modalRoot: {
    padding: 20,
    "& .oa-modal-content": {
      background: theme.palette.background.light,
      borderRadius: 5,
    },
    "& .oa-modal-header": {
      display: "flex",
      alignItems: "center",
      position: "relative",
      padding: "0 25px",
    },
    "& .oa-modal-header-text": {
      fontWeight: 500,
    },
    "& .oa-modal-close": {
      cursor: "pointer",
      position: "absolute",
      top: 10,
      right: 10,
    },
    "& .oa-modal-body": {},
  },
}));

const FlightSearchResult = (props) => {
  /**
   * window.open('http://oari.co:8080/psa_web/viewAgentInvoice.do?param='+data.uniqueIdentifier, 'sharer', 'toolbar=1,status=0,width=800,height=600');
   *
   * window.open('http://oari.co:8080/psa_web/viewUserInvoice.do?param='+data.uniqueIdentifier, 'sharer', 'toolbar=1,status=0,width=800,height=600');
   *
   */

  const [openCanModal, setOpenCanModal] = useState(false);
  const [itinData, setItinData] = useState(null);
  const [dropDownMenu, setDropDownMenu] = useState([]);
  const [loggedInUserId, setIsLoggedInUserId] = useState(0);
  const [userType, setUserType] = useState("agent");

  const classes = useStyles();

  function onSelectMenu(event, data) {
    switch (event) {
      case "Ticket":
        //props.history.push('/admin/booking-report/flight/ticket/' + data.flightApplicationId);
        props.history.push(
          "/admin/booking-report/flight/ticket/" + data.flightApplicationId
        );
        break;
      case "My Invoice":
        props.history.push(
          "/admin/voucher/flight-agent/" + data.flightApplicationId
        );
        break;
      case "Invoice":
        props.history.push(
          "/admin/voucher/flight-agent/" + data.flightApplicationId
        );
        break;
      case "Customer Invoice":
        props.history.push(
          "/admin/voucher/flight-user/" + data.flightApplicationId
        );
        break;
      case "Details":
        props.history.push(
          "/admin/booking-report/flight/details/" + data.flightApplicationId
        );
        break;
      case "Reschedule":
        break;
      case "Cancel":
        // window.gotoFlightTicketDetails(data.flightApplicationId);
        openCancellation(data);
        break;
      default:
        break;
    }
  }

  const getUserType = () => {
    localforageGetItem("user-details", function(err, value) {
      if (err == null && value != null) {
        setUserType(value.userType);
        populateDropdownMenu(value.userType);
        setIsLoggedInUserId(value.userId);
      }
    });
  };

  const populateDropdownMenu = (userType) => {
    if (userType) {
      const tempArray = ["Ticket"];
      if (userType === "distributor" || userType === "company") {
        tempArray.push("Invoice");
      }
      if (userType === "agent") {
        tempArray.push("My Invoice");
        tempArray.push("Customer Invoice");
        tempArray.push("Reschedule");
        tempArray.push("Cancel");
        tempArray.push("Details");
      }
      setDropDownMenu(tempArray);
    }
  };

  useEffect(() => {
    getUserType();
  }, []);

  const openCancellation = (dataParams) => {
    // apiCall(WebApi.b2BFlightDetailForCancellation, {
    //     refId: dataParams.flightApplicationId,
    //     type: ""
    // }, (response) => {
    //     console.log("dropdownmenu 2", dropDownMenu);
    //     if (response.success) {
    //         setItinData(response.data);
    //         setOpenCanModal(true);
    //     } else {
    //         console.log(response.message);
    //     }
    // });

    // console.log("dataParams ", dataParams);

    props.history.push({
      pathname: "/b2c/mybookings",
      state: {
        loggedInUserId: loggedInUserId,
        transactionId: dataParams.flightApplicationId,
        refId: dataParams.flightApplicationId,
        userType: userType,
        trType: 1,
      },
    });
  };

  const closeCancellation = () => {
    // console.log("dropdownmenu 3", dropDownMenu);
    setOpenCanModal(false);
    // console.log("dropdownmenu 4", dropDownMenu);
    populateDropdownMenu();
    // console.log("dropdownmenu 5", dropDownMenu);
  };

  const [total, setTotal] = useState(0);

  const doTotal = (amt) => {
    // console.log(amt);
    let totalAmt = total + amt;
    setTotal(totalAmt);
    //return totalAmt;
    return amt;
  };

  let cols = [];
  cols.push(
    {
      Header: "#",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.index + 1}
        </div>
      ),
      width: 35,
      filterable: false,
    },
    {
      Header: "Transaction ID",
      accessor: "flightApplicationReferenceId",
      width: 130,
      Cell: (props) => (
        <div style={{ height: 44, verticalAlign: "middle" }}>
          <CustomDropdown
            buttonText={props.value}
            buttonProps={{
              fullWidth: true,
              style: { height: 32, padding: 4 },
              color: "rose",
            }}
            onClick={(event) => {
              onSelectMenu(event, props.original);
            }}
            dropPlacement="top-start"
            dropdownList={dropDownMenu}
          />
        </div>
      ),
    },
    {
      Header: "Tx Date",
      width: 210,
      accessor: "flightTransactionCreatedOnDate",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    }
  );

  if (props.agencyId === undefined) {
    cols.push(
      {
        Header: "Agency ID",
        accessor: "userIdentity",
        width: 100,
        Cell: (props) => (
          <div
            style={{
              height: 44,
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            {props.value}
          </div>
        ),
      },
      {
        Header: "Agency Name",
        width: 200,
        accessor: "agencyName",
        Cell: (props) => (
          <div
            style={{
              height: 44,
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            {props.value}
          </div>
        ),
      }
    );
  }

  cols.push(
    {
      Header: "Airline",
      accessor: "airlineIATA",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    },
    {
      Header: "Sector",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.flightOrigin +
            " - " +
            props.original.flightDestination}
        </div>
      ),
    },
    {
      Header: "Trip Type",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.flightJourneyType == "O" ? "oneway" : "return"}
        </div>
      ),
    },
    {
      Header: "PNR",
      width: 150,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.flightPNR}
        </div>
      ),
    },
    {
      Header: "Passenger Name(s)",
      width: 210,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.paxName.split(",")[0]}
        </div>
      ),
    },
    {
      Header: "Travel Date",
      width: 210,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.flightTravelDate}
        </div>
      ),
    },
    {
      Header: "Phone",
      accessor: "paxPhone",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "flightTicketStatus",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
      Footer: (props) => (
        <div style={{ fontWeight: "bold", textAlign: "right" }}>
          Total ({props.data.length} rows)
        </div>
      ),
    },
    {
      Header: "Base Fare",
      accessor: "flightBaseFare",
      Cell: (props) => (
        <div
          style={{
            height: 44,
            textAlign: "right",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          {currencyFormatter(props.value)}
        </div>
      ),
      Footer: (values, rows) => {
        return (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            {currencyFormatter(
              _.sum(_.map(values.data, (d) => d.flightBaseFare))
            )}
          </div>
        );
      },
    },
    {
      Header: "Taxes",
      accessor: "fightFareTax",
      Cell: (props) => (
        <div
          style={{
            height: 44,
            textAlign: "right",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          {currencyFormatter(props.value)}
        </div>
      ),
      Footer: (values, rows) => {
        return (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            {currencyFormatter(
              _.sum(_.map(values.data, (d) => d.fightFareTax))
            )}
          </div>
        );
      },
    },
    {
      Header: "Agent Comm.",
      size: 160,
      accessor: "flightAgentCommission",
      Cell: (props) => (
        <div
          style={{
            height: 44,
            textAlign: "right",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          {currencyFormatter(props.value)}
        </div>
      ),
      Footer: (values, rows) => {
        return (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            {currencyFormatter(
              _.sum(_.map(values.data, (d) => d.flightAgentCommission))
            )}
          </div>
        );
      },
    },
    {
      Header: "Total",
      accessor: "flightSumTotal",
      Cell: (props) => (
        <div
          style={{
            height: 44,
            textAlign: "right",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          {currencyFormatter(props.value)}
        </div>
      ),
      Footer: (values, rows) => {
        return (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            {currencyFormatter(
              _.sum(_.map(values.data, (d) => d.flightSumTotal))
            )}
          </div>
        );
      },
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    }
  );

  return (
    <>
      {/* <div>{JSON.stringify(props.searchParams)}</div> */}
      <OaButtonPDF
        api={DOWNLOAD_FLIGHT_PDF}
        searchParams={props.searchParams}
      />
      <OaButtonXls
        api={DOWNLOAD_FLIGHT_XLS}
        searchParams={props.searchParams}
      />
      <OaTable
        columns={cols}
        search={props.searchParams}
        loader={WebApi.getFlightReport}
        filterable={false}
        rows
      />

      <Modal
        open={openCanModal}
        onClose={() => closeCancellation()}
        className={classes.modalRoot}
      >
        <div className="oa-modal-content">
          <div className="oa-modal-header">
            <h3 className="oa-modal-header-text">Cancellation</h3>
            <a className="oa-modal-close" onClick={() => closeCancellation()}>
              <Close />
            </a>
          </div>
          <div className="oa-modal-body">
            {/* <Itinerary data={itinData}/> */}
            <MyBookings {...props} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(FlightSearchResult);
