import { SET_USER_TYPE } from "redux/action";


export const usertype=(state={},action)=>{
      
    switch (action.type) {
        case SET_USER_TYPE:
          return action.payload
        default:
          return state;
      }
}
