import React, { useState,useContext } from "react";
import { Grid, Typography, Button, Divider,useMediaQuery } from "@material-ui/core";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonIcon from "@material-ui/icons/Person";

import GridContainer from "components/Grid/GridContainer.js";
import OaFormTextField from "oahoc/OaFormTextField";
import Captcha from "demos-react-captcha";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import { useHistory} from "react-router-dom";
import { AppStateContext } from "layouts/AppStateProvider";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 60,paddingLeft:'6%',
    [theme.breakpoints.down("sm")]:{
      paddingLeft:10,
      paddingRight:10,
    },
    "& .MuiInputBase-input": {
      minHeight: 35
    },
  },
  
 
  Grid: {

    background: "#fff",
    marginTop: "50px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,

  },
  firstCard: {
    "& .MuiTypography-root": {
      color: "#000",
    },
  },
  Button: {
    padding: ".5rem 1.5rem",
    border: "none" /* borderRadius: '30px', */,
    background: "#F89605 !important",
    letterSpacing: "1px",
    color: "#000",
    cursor: "pointer",
    textTransform: "none",
    textShadow: "none",
    "&:hover": {
      color: "#667",
      backgroundColor: "#F89605",
    },
    "&:disabled": {
      color: "#000",
      backgroundColor: "rgba(232, 232, 232, 0.69) !important",
    },
  },
  span: {
    color: "#23527c",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  },
}));

const ForgotPassword = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { routes, setRoutes } = useContext(AppStateContext);
  const classes = useStyles();
  const history = useHistory();
  const onChange=(value)=> {
    // console.log(value);
    setCaptcha(value)
    return value
  }
  // console.log(captcha)

  const goToSignIn = () => {
    props.history.push('/b2c/signin')
  }

  return (
    <div className={classes.root} style={{ }}>
      <Grid container spacing={isMobile?0:3} style={{width:"100%"}}>
        <Grid item xs={12} md={5}>
          <Grid
            container
            spacing={isMobile?0:1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h5"
                style={{ fontSize: isMobile?20:24, color: "#000" }}
                gutterBottom
              >
               Desire more for your travel?
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
              Sign in to make your travel planning and bookings quick and easy.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
              Print e-tickets
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
              Check your booking history
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
              Easily manage cancellations & refunds
              </Typography>
            </Grid>
           
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="h4"
                style={{ fontSize:isMobile?20: 24, color: "#000", lineHeight: 1 }}
                gutterBottom
              >
                Don't have an Account?
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Button className={classes.Button} onClick={()=>props.history.push('/b2c/register')}>Sign up for one now!</Button>
            </Grid>
          
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container 
            xs={12}
            md={10}
            spacing={isMobile?0:1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography variant="h5" style={{ fontSize: isMobile?20:25, color: "#000" }}>
                Desire more for your travel?
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="h5"
                style={{ fontSize: "14px", color: "#000" }}
              >
                Create an account with us!
              </Typography>
            </Grid>
            <Grid item md={12}>
            <OaFormAlerts
                                      isSaving={isSaving}
                                      isSuccess={isSuccess}
                                      isError={isError}
                                      infoText={infoText}
                                      setIsError={setIsError}
                                      setIsSuccess={setIsSuccess}
                                     
                                    />
            </Grid>
            <Grid container>
            <Formik
              initialValues={{
                username: "",
              
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required("username/email is required"),
             
              })}
              onSubmit={(
                searchParams,
                { setSubmitting, resetForm, enableReinitialize }
              ) => {
                
                setIsSaving(true);
                apiCall(WebApi.resendPasswordB2C, searchParams, (response) => {
                  setAlertTitle("Error in retrieving new password!");
                  setSubmitting(false);
                  setIsSaving(false);
                  if (response != null && response.success === true) {
                    
                    setInfoText(response.message);
                    setAlertTitle("Successfully retrieved new password!");
                    setIsError(false);
                    setIsSuccess(true);
                    // setTimeout(() => { props.history.push("/b2c/signin"); }, 6000);

                  } else if (response != null) {
                    setAlertTitle("Error retrieving new password!");
                    setInfoText(response.message);
                    setIsError(true);
                    setIsSuccess(false);
                  }
                });
              }}
            >
              {({ values, errors, touched, handleChange, setFieldValue }) => (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: 50,
                    }}
                  >
                    <GridContainer xs={12} spacing={isMobile?0:1}>
                      <Grid item md={12}>
                        <Grid container>
                          <PersonIcon />&nbsp;&nbsp;
                          <Typography
                            variant="caption"
                            style={{ marginTop: 3 }}
                          >
                            Username or Email id
                          </Typography>
                        </Grid>

                        <OaFormTextField fullWidth required={true} name="username" />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error"
                        />
                      </Grid>

                      <Grid item md={12}>
                      <Captcha onChange={onChange} placeholder="Enter captcha" />
                      </Grid>
                      

                      {/*{captcha &&  */}<Grid item md={12} container justifyContent="flex-start">
                        <Grid container alignItems="center">
                          <Grid item md={6}>
                            <Button
                              type="submit"
                              className={classes.Button}
                              style={{ fontFamily: "Poppins", width: "185px"}}
                              disabled={captcha ? "" :"disabled"}
                            >
                              Get New Password
                            </Button>
                          </Grid>
                          <Grid item md={6}>
                            <Button
                              className={classes.Button}
                              style={{ fontFamily: "Poppins", width: "185px"}}
                              onClick={goToSignIn}
                            >
                              Sign in
                            </Button>
                          </Grid>
                        </Grid>
                        
                        </Grid>{/* } */}
                    </GridContainer>
                  </div>
                  {/* {console.log("values: ", values)}
                  {errors && _.isString(errors) && touched && _.isArray(touched) && (
                    <div className="field-error">{errors}</div>
                  )}
                  <div
                    className={"row"}
                    style={{ background: "white", zIndex: "200" }}
                  >
                    <div className={"col-12"}>
                      <code>
                        <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                      </code>
                    </div>
                    <div className={"col-12"}>
                      <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                    </div>
                    <div className={"col-12"}>
                      <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                    </div>
                  </div> */}
                </Form>
              )}
            </Formik>
          </Grid>
          </Grid>
         
        </Grid>
      </Grid>
    </div>
  );
};
export default ForgotPassword;
