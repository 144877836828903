import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Popper,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import PassengerCount from "./PassengerCounter";

import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import {
  setDomesticCarriers,
  setInternationalCarriers,
  setAirports,
} from "redux/action";
import { withRouter } from "react-router";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";

import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { COLORS, FONTS } from "assets/css/CssConstants";
import _ from "lodash";
import { allAirlines,lccAirlines,gdsAirlines } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 5px",
    "& .MuiFormLabel-root": {
      color: COLORS.DEFAULT_TEXT_DARK,
    },
    "& .MuiInputBase-input": {
      color: COLORS.DEFAULT_TEXT_DARK,
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      [theme.breakpoints.down(440)]: {
        borderBottom: `solid 1px ${COLORS.DEFAULT_TEXT_DARK} !important`,
      },
    },
    "& .multicity-action-container": {
      [theme.breakpoints.down(600)]: {
        padding: "10px 15px"
      }
    }
  },
  FlightSection_flightway: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      justifyContent: "space-between",
      "& .MuiFormControlLabel-label": {
        color: COLORS.DEFAULT_TEXT_DARK,
        fontWeight: "400",
        fontSize: 12,
      },
    },
  },
  FlightSection_flightdet: {
    
    "& .grid-upper-container": {
      paddingTop: 15,
      [theme.breakpoints.down(440)]: {
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-5": {
          maxWidth: "100%",
          flexBasis: "100%",
          paddingRight: 20,
        }
      },
    },
    "& .itin-airports": {
      paddingLeft: 25,
      "& label": {
        fontSize: 14,
        color: COLORS.DEFAULT_TEXT_DARK,
        fontWeight: 600,
      },
      "& .vertical-dash-wrapper": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .vertical-dash": {
          borderLeft: `solid 2px ${COLORS.DEFAULT_TEXT_DARK}`,
          height: 65,
          [theme.breakpoints.down(440)]: {
            display: "none",
          }
        }
      },
      [theme.breakpoints.down(680)]: {
        maxWidth: "100%",
        flexBasis: "100%",
      }
    },
    "& .itin-dates": {
      "& label": {
        fontSize: 14,
        color: COLORS.DEFAULT_TEXT_DARK,
        fontWeight: 600,
      },
      "& .left-dashed-item": {
        borderLeft: `solid 2px ${COLORS.DEFAULT_TEXT_DARK}`,
        paddingLeft: 10,
        [theme.breakpoints.down(680)]: {
          border: 0,
          maxWidth: "50%",
          flexBasis: "50%",
          paddingLeft: "4vw",
        },
        [theme.breakpoints.down(440)]: {
          paddingLeft: 25,
        }
      },
      "& .MuiIconButton-label": {
        color: COLORS.HIGHLIGHT_COLOR_OR,
      },
      [theme.breakpoints.down(680)]: {
        maxWidth: "100%",
        flexBasis: "100%",
        marginTop: 30,
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiAutocomplete-popupIndicator .MuiIconButton-label": {
      color: COLORS.DEFAULT_TEXT_DARK,
    },
  },
  FlightSection_passdet: {
    paddingTop: 15,
    "& .MuiBox-root span": {
      color: `${COLORS.DEFAULT_TEXT_DARK} !important`,
      fontWeight: 600,
    },
    "& .MuiBox-root .MuiGrid-container": {
      backgroundColor: "none",
      borderBottom: `solid 0.5px ${COLORS.QUARTERNARY_BLACK}`,
      paddingBottom: 5,
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginTop: 5,
    }
  },
  FlightSection_actions: {
    paddingTop: 37,
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },

  toggleBox: {
    "& .MuiToggleButton-root": {
      color: "#000",
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#792779ee",

      "&:hover, &:focus": {
        backgroundColor: "#792779ee",
      },
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: "#ffffff66",
    },
    "& .MuiToggleButton-sizeSmall": {
      height: 24,
      minWidth: 75,
      [theme.breakpoints.down(840)]: {
        minWidth: 0,
      },
      [theme.breakpoints.down(614)]: {
        minWidth: 75,
      },
      [theme.breakpoints.down(358)]: {
        marginBottom: 20,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      [theme.breakpoints.down(358)]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  searchButtonBox: {
    width: "80px",
    height: "30px",
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      "& .MuiButton-label": {
        color: "#000",
      },
    },
  },
  swapIconButton: {
    "& .MuiIconButton-label": {
      color: COLORS.DEFAULT_TEXT_LIGHT,
      background: COLORS.HIGHLIGHT_COLOR_OR,
      borderRadius: 50,
      padding: 2,
    },
  },
  departDatePicker: {
    "& .MuiIconButton-label": {
      color: COLORS.DEFAULT_TEXT_DARK,
    },
  },
  returnDatePicker: {
    "& .MuiIconButton-label": {
      color: COLORS.DEFAULT_TEXT_DARK,
    },
  },
  airlineSelect: {
    width: "100%",
    borderBottom: `solid 0.5px ${COLORS.QUARTERNARY_BLACK}`,
    marginTop: 4,
    "& .MuiSelect-icon": {
      color: COLORS.DEFAULT_TEXT_DARK,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontSize: 20,
    },
    [theme.breakpoints.down(840)]: {
      marginLeft: 10,
    },
  },
  FlightSection_radiogroup: {
    display: "flex",
    justifyContent: "space-between !important",
    flexWrap: "nowrap",
    flexDirection: "row",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: COLORS.HIGHLIGHT_COLOR_OR,
    },
    [theme.breakpoints.down(440)]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    // [theme.breakpoints.down(600)]: {
    //   display: "grid",
    //   gridTemplateColumns: "repeat(1, 1fr)",
    // },
  },
  gridRespCol: {
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
    },
  },
  gridRespColPassDet: {
    [theme.breakpoints.down(400)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
    }
  },
  rowUpper: {
    borderBottom: `solid 0.5px ${COLORS.QUINARY_BLACK}`,
    padding: "0 25px",
  },
  rowLower: {
    borderTop: `solid 0.5px ${COLORS.QUINARY_BLACK}`,
    padding: "5px 25px 15px",
    justifyContent: "space-between",
    marginTop: 15,
    "& .airline-select-container": {
      paddingTop: 6,
      [theme.breakpoints.down(600)]: {
        maxWidth: "100%",
        flexBasis: "100%",
        marginTop: 20,
      }
    },
    "& .row-lower-grid-container-inner": {
      margin: 0,
      // [theme.breakpoints.down(840)]: {
      //   justifyContent: "center",
      //   alignItems: "center",
      // },
    },
  },
  rowBottom: {
    borderTop: `solid 0.5px ${COLORS.QUINARY_BLACK}`,
    padding: "5px 25px 15px",
    marginTop: 15,
    "& .quick-links-container": {
      padding: "10px 0",
      justifyContent: "flex-start",
      [theme.breakpoints.down(600)]: {
        flexWrap: "wrap",
        justifyContent: "center",
      },
      "& .quick-link-item": {
        margin: 5,
        border: `solid 1px ${COLORS.BTN_BG_PRIMARY}`,
        padding: "3px 10px",
        color: COLORS.DEFAULT_TEXT_ACTIVE,
        borderRadius: 4,
        cursor: "pointer",
        textAlign: "center",
        width: 122,
        "&:hover": {
          border: `solid 1px ${COLORS.ACTIVE_MENU_COLOR}`,
          color: COLORS.ACTIVE_MENU_COLOR,
        },
        "& .qlink-icon-wrapper": {
          textAlign: "center",
        },
        "& .qlink-label-wrapper": {
          fontSize: 10,
        },
      },
    }
  },
  FlightSection_searchBtn: {
    "& .search-btn-col": {
      paddingTop: 35,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    }
  },
  flightInfoGridRoot: {
    [theme.breakpoints.down(960)]: {
      height: "auto",
    },
    // [theme.breakpoints.down(680)]: {
    //   height: 168,
    // },
    [theme.breakpoints.down(440)]: {
      height: "auto",
    },
  }
}));

const FlightSection = (props) => {
  const departAutocompleteEl = useRef(null);
  const departAutocompleteElText = useRef(null);
  const arriveAutocompleteEl = useRef(null);
  const arriveAutocompleteElText = useRef(null);

  const [departDateDialogOpen, setDepartDateDialogOpen] = useState(false);
  const [returnDateDialogOpen, setReturnDateDialogOpen] = useState(false);
  const [airlineSelectOpen, setAirlineSelectOpen] = useState(false);

  const customPopperFrom = function (props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };
  const customPopperTo = function (props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={arriveAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };

  // const [data, setData] = useState({
  //   flightWay: "one-way",
  //   from: null,
  //   to: null,
  //   depart: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
  //   return: new Date(
  //     new Date().getTime() + 2 * 24 * 60 * 60 * 1000
  //   ).toISOString(),
  //   airlines: "",
  //   adultCount: 1,
  //   childCount: 0,
  //   infantCount: 0,
  //   nonStop: "non-stop",
  //   class: "economy",
  // });
  // const [dataError, setDataError] = useState({
  //   from: false,
  //   to: false,
  //   airlines: false,
  // });

  // useEffect(() => {
  //   console.log("useEffect - datechange");
  //   if (
  //     !data.return ||
  //     new Date(data.return).getTime() < new Date(data.depart).getTime()
  //   ) {
  //     setData(() => ({
  //       ...data,
  //       return: new Date(
  //         new Date(data.depart).getTime() + 24 * 60 * 60 * 1000
  //       ).toISOString(),
  //     }));
  //     console.log(data);
  //   }
  // }, [data.depart, data.flightWay]);

  const classes = useStyles();
  // const showReturn =
  //   values.flightWay === "round-trip" || values.flightWay === "round-trip-sp";

  // const swapDepartArrive = () => {
  //   console.log("swapping ");
  //   setData((prevState) => ({
  //     ...data,
  //     from: prevState.to,
  //     to: prevState.from,
  //   }));
  // };

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      formikRef.current.setFieldValue(
        "noAdult",
        Number(formikRef.current.values.noAdult) + val
      );
    }
    if (type === "child") {
      formikRef.current.setFieldValue(
        "noChild",
        Number(formikRef.current.values.noChild) + val
      );
    }
    if (type === "infant") {
      formikRef.current.setFieldValue(
        "noInfant",
        Number(formikRef.current.values.noInfant) + val
      );
    }
    // console.log(data.adultCount, data.childCount, data.infantCount);
  };

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [carrier, setCarrier] = useState(props.domCarriers);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);
  const [airportList, setAirportList] = useState(null);

  const formikRef = React.useRef();

  useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
  }, []);

  const openQuicklink = (quicklinktype) => {

    switch(quicklinktype) {

      case "flights-report": {
        props.history.push("/admin/booking-report/flight");
        break;
      }

      case "travel-calendar": {
       window.openPage("travel-calendar");
        break;
      }

      case "cancellation": {
        props.history.push("/admin/booking-report/flight");
        break;
      }

      case "group-booking": {
        props.history.push("/admin/groupbooking");
        break;
      }

      case "airlines-contact": {
        window.openPage("airlines-contact");
        break;
      }

      case "web-checkin": {
        window.openPage("web-checkin");
        break;
      }
    }
  }

  const getAirlineData = () => {
    if (
      props.domCarriers === undefined ||
      props.intlCarriers === undefined ||
      props.airports === undefined
    ) {
      apiCall(WebApi.getAirports, {}, (response) => {
        if (response.success === true) {
          props.setAirports(response.data.airports);
          props.setDomesticCarriers(response.data.domCarriers);
          props.setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      });
    }
  };

  const fetchAirports = (searchParams) => {

    if (searchParams.length >=3 ) {
        apiCall(WebApi.fetchAirportList, {
            airportCode:searchParams
        }, (response) => {
            console.log("response", response);

            if (response.success == true) {
                setAirportList(response.data.airports);
            }
        });
    }
}

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  useEffect(() => {
    getAirlineData();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(props.domCarriers);
      } else {
        setCarrier(props.intlCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  // const submitForm = () => {
  //   alert("Searching Flights");
  // };

  return (
    <Box className={classes.root} m={4}>
      {/* oaFormAlert  */}
      {/* -----------  */}

      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          // flightWay: "one-way",
          // from: "",
          // to: "",
          // depart: new Date().toISOString(),
          // return: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
          // airlines: "all",
          // adultCount: 1,
          // childCount: 0,
          // infantCount: 0,
          // nonStop: "non-stop",
          // class: "economy",
          sectorArray: [
            {
              fromAirport: "",
              toAirport: "",

              onwardDateDisplay: new Date(),
              returnDateDisplay: new Date(
                new Date().setDate(new Date().getDate() + 1)
              ),
            },
          ],
          // onwardDate: new Date(),
          // returnDate: new Date(new Date().setDate(new Date().getDate() + 1)),
          flightWay: "one-way",
          noAdult: "1",
          noChild: "0",
          noInfant: "0",
          cabinType: "E",
          airline: [allAirlines],
          // isNonStop: "non-stop",
          isNonStop: false,
        }}
        validationSchema={Yup.object().shape({
          sectorArray: Yup.array().of(
            Yup.object().shape({
              fromAirport: Yup.string()
                .typeError("From Airport is required")
                .required("From Airport is required"),
              toAirport: Yup.string()
                .typeError("To Airport is required")
                .required("To Airport is required"),
            })
          ),
          noAdult: Yup.number().required("Number of Adult is required"),
          noChild: Yup.number(),
          noInfant: Yup.number(),
          cabinType: Yup.string().required("Class is required"),
          airline: Yup.array().min(1, "Select at least 1 airline"),
        })}
        onSubmit={(values) => {
          localforageGetItem("user-details", function (err, details) {
            // console.log(details);
            values.sectorArray = values.sectorArray.map((value, index) => {
              return {
                ...value,
                fromAirport: value.fromAirport.airportCode,
                toAirport: value.toAirport.airportCode,
                fromAirportCity:
                  value.fromAirport.cityName +
                  " ( " +
                  value.fromAirport.airportCode +
                  " )",
                toAirportCity:
                  value.toAirport.cityName +
                  " ( " +
                  value.toAirport.airportCode +
                  " )",
              };
            });
            values.userId = details.userId;
            values.username = details.userName;
            values.partnerId = details.partnerId;
            values.psaModule = details.userType === "agent" ? "Y" : "N";
            values.userType = details.userTypeAbv;

            // console.log(values);
            window.openPageNew("searchProgressNew.do", values);
          });
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            {/* -----------  */}
            {/* oaFormAlert  */}
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid container justify="space-between" className={classes.rowUpper}>
                <Grid item>
                  <Box className={classes.FlightSection_flightway}>
                    <RadioGroup
                      className={classes.FlightSection_radiogroup}
                      value={values.flightWay}
                      name={`flightWay`}
                      onChange={(e) => {
                        setFieldValue(`flightWay`, e.target.value);
                        if (values.sectorArray.length > 1) {
                          // setFieldValue('sectorArray', )

                          values.sectorArray.length = 1;
                        }
                      }}
                    >
                      {flightWayList.map((a) => (
                        <FormControlLabel
                          key={a.val}
                          value={a.val}
                          control={<Radio color="secondary" />}
                          label={a.text}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="flightWay"
                      component="div"
                      className="error"
                    />
                  </Box>
                </Grid>

                <Grid item></Grid>
              </Grid>
              <FieldArray
                name="sectorArray"
                render={(arrayHelpers) => (
                  <>
                    {values.sectorArray &&
                      values.sectorArray.length > 0 &&
                      values.sectorArray.map((sector, index) => {
                        return (
                          <Grid className={classes.flightInfoGridRoot}>
                            <Box className={classes.FlightSection_flightdet}>


                              <Box my={2}>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="flex-start"
                                  spacing={2}
                                >
                                  <Grid container justify="space-evenly" className="grid-upper-container">
                                    <Grid item sm={6} className="itin-airports">
                                      <Grid container justify="space-evenly">
                                        <Grid item xs={5} sm={5}>
                                          <label>From</label>
                                          <Autocomplete
                                            fullWidth
                                            PopperComponent={customPopperFrom}
                                            name={`sectorArray[${index}].fromAirport`}
                                            // options={props.airports || []}
                                            options={airportList || []}
                                            getOptionLabel={(o) =>
                                              o
                                                ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                                : ""
                                            }
                                            value={
                                              values.sectorArray[index].fromAirport ||
                                              ""
                                            }
                                            getOptionSelected={(option, value) =>
                                              option.airportCode == value.airportCode
                                            }
                                            onKeyUp={(event) => fetchAirports(event.target.value)}
                                            onChange={(event, newValue) => {
                                              // console.log(newValue);
                                              changeHandler(
                                                "from",
                                                newValue?.countryCode
                                              );
                                              arriveAutocompleteEl.current.focus();

                                              // setData({ ...data, from: newValue });
                                              setFieldValue(
                                                `sectorArray[${index}].fromAirport`,
                                                newValue
                                              );
                                              // !dataError.from &&
                                              //   arriveAutocompleteEl.current.focus();
                                            }}
                                            ref={departAutocompleteEl}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                color="secondary"
                                                // label="Depart From"
                                              // error={
                                              //   errors?.sectorArray?.[index]
                                              //     ?.fromAirport
                                              //     ? true
                                              //     : false
                                              // }
                                              // helperText={
                                              //   errors?.sectorArray?.[index]
                                              //     .fromAirport &&
                                              //   "Choose an Airport From"
                                              // }
                                              // ref={departAutocompleteEl}
                                              // inputRef={departAutocompleteEl}
                                              placeholder="Departure Airport"
                                              />
                                            )}
                                            disablePortal={true}
                                          />

                                          <ErrorMessage
                                            name={`sectorArray[${index}].fromAirport`}
                                            component="div"
                                            className="error"
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={2}
                                          style={{ textAlign: "center", position: "relative" }}
                                        >
                                          <div className="vertical-dash-wrapper">
                                            <div className="vertical-dash"></div>
                                          </div>
                                          <IconButton
                                            onClick={() => {
                                              const fromAirportTemp =
                                                values.sectorArray[index].fromAirport;
                                              // const b = {
                                              //   ...values.sectorArray[index].toAirport,
                                              // };
                                              // console.log(
                                              //   "fromAirportTemp" +
                                              //     JSON.stringify(fromAirportTemp)
                                              // );
                                              setFieldValue(
                                                `sectorArray[${index}].fromAirport`,
                                                values.sectorArray[index].toAirport
                                              );
                                              setFieldValue(
                                                `sectorArray[${index}].toAirport`,
                                                fromAirportTemp
                                              );
                                            }}
                                            aria-label="delete"
                                            className={classes.swapIconButton}
                                          >
                                            <SwapHorizIcon />
                                          </IconButton>
                                        </Grid>
                                        <Grid item xs={5} sm={5}>
                                        <label>To</label>
                                          <Autocomplete
                                            name={`sectorArray[${index}].toAirport`}
                                            PopperComponent={customPopperTo}
                                            // options={props.airports || []}
                                            options={airportList || []}
                                            getOptionLabel={(o) =>
                                              o
                                                ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                                : ""
                                            }
                                            value={
                                              values.sectorArray[index].toAirport ||
                                              ""
                                            }
                                            openOnFocus
                                            getOptionSelected={(option, value) => {
                                              return (
                                                option.airportCode ==
                                                value.airportCode
                                              );
                                            }}
                                            onKeyUp={(event) => fetchAirports(event.target.value)}
                                            onChange={(event, newValue) => {
                                              // console.log(newValue);
                                              changeHandler(
                                                "to",
                                                newValue?.countryCode
                                              );
                                              arriveAutocompleteEl.current.blur();
                                              setDepartDateDialogOpen(true);

                                              // setData({ ...data, to: newValue });
                                              setFieldValue(
                                                `sectorArray[${index}].toAirport`,
                                                newValue
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                color="secondary"
                                                // label="Arrive To"
                                                // error={
                                                //   errors?.sectorArray?.[0].toAirport
                                                //     ? true
                                                //     : false
                                                // }
                                                // helperText={
                                                //   errors?.sectorArray?.[0].toAirport &&
                                                //   "Choose an Airport To"
                                                // }
                                                inputRef={arriveAutocompleteEl}
                                                placeholder="Arrival Airport"
                                              />
                                            )}
                                            disablePortal={true}
                                          />

                                          <ErrorMessage
                                            name={`sectorArray[${index}].toAirport`}
                                            component="div"
                                            className="error"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item sm={6} className="itin-dates">
                                      <Grid container justify="space-evenly">
                                        <Grid item xs={5} sm={5} className="left-dashed-item">
                                          <label>Depart</label>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              KeyboardButtonProps={{
                                                onFocus: (e) => {
                                                  setDepartDateDialogOpen(true);
                                                },
                                              }}
                                              PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                  setDepartDateDialogOpen(false);
                                                },
                                              }}
                                              InputProps={{
                                                onFocus: () => {
                                                  setDepartDateDialogOpen(true);
                                                },
                                              }}
                                              name={`sectorArray[${index}].onwardDateDisplay`}
                                              open={departDateDialogOpen}
                                              onClose={() =>
                                                setDepartDateDialogOpen(false)
                                              }
                                              onOpen={() =>
                                                setDepartDateDialogOpen(true)
                                              }
                                              disablePast
                                              id="departDateEl"
                                              className={classes.departDatePicker}
                                              fullWidth
                                              color="secondary"
                                              disableToolbar
                                              variant="inline"
                                              format="dd-MM-yyyy"
                                              margin="normal"
                                              // label="Depart"
                                              // value={data.depart}
                                              value={
                                                values.sectorArray[index]
                                                  .onwardDateDisplay
                                              }
                                              onChange={(e, v) => {
                                                // console.log("value", v);
                                                // console.log("event", e);
                                                // setData({ ...data, depart: new Date(v).toISOString() });
                                                setDepartDateDialogOpen(false);
                                                if (
                                                  values.flightWay === "round-trip" ||
                                                  values.flightWay === "round-trip-sp"
                                                ) {
                                                  setReturnDateDialogOpen(true);
                                                } else {
                                                  // setAirlineSelectOpen(true);
                                                }

                                                setFieldValue(
                                                  `sectorArray[${index}].onwardDateDisplay`,
                                                  e
                                                );
                                                if (
                                                  new Date(v).getTime() >
                                                  new Date(
                                                    values.sectorArray[
                                                      index
                                                    ].onwardDateDisplay
                                                  ).getTime()
                                                ) {
                                                  values.sectorArray[
                                                    index
                                                  ].onwardDateDisplay = new Date(
                                                    new Date(v).getTime() +
                                                    24 * 60 * 60 * 1000
                                                  ).toISOString();
                                                }
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                          <ErrorMessage
                                            name={`sectorArray[${index}].onwardDateDisplay`}
                                            component="div"
                                            className="error"
                                          />
                                        </Grid>

                                        <Grid item xs={5} sm={5} className="left-dashed-item">
                                          <Grid item>
                                            <label>Return</label>
                                            {(values.flightWay === "round-trip" ||
                                            values.flightWay === "round-trip-sp") ? (
                                              <>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <KeyboardDatePicker
                                                      name={`sectorArray[${index}].returnDateDisplay`}
                                                      open={returnDateDialogOpen}
                                                      onClose={() =>
                                                        setReturnDateDialogOpen(false)
                                                      }
                                                      onOpen={() =>
                                                        setReturnDateDialogOpen(true)
                                                      }
                                                      minDate={
                                                        values.sectorArray[index]
                                                          .onwardDateDisplay
                                                      }
                                                      className={classes.returnDatePicker}
                                                      fullWidth
                                                      color="secondary"
                                                      disableToolbar
                                                      variant="inline"
                                                      format="dd-MM-yyyy"
                                                      margin="normal"
                                                      // label="Return"
                                                      // value={data.return}
                                                      value={
                                                        values.sectorArray[index]
                                                          .returnDateDisplay
                                                      }
                                                      onChange={(e, v) => {
                                                        // setData({ ...data, return: v });
                                                        setFieldValue(
                                                          `sectorArray[${index}].returnDateDisplay`,
                                                          e
                                                        );
                                                        setReturnDateDialogOpen(false);
                                                        // setAirlineSelectOpen(true);
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                  <ErrorMessage
                                                    name={`sectorArray[${index}].onwardDateDisplay`}
                                                    component="div"
                                                    className="error"
                                                  />
                                              </>
                                            ) : 
                                            <div className="return-save-message">
                                              <span>Book a round trip to save more</span>
                                            </div>}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </Grid>

                                {values.flightWay === "multi-city" && (
                                  <Grid container className="multicity-action-container">
                                    <Grid item xs={6} lg={3}>
                                      <Button
                                        style={{
                                          backgroundColor: COLORS.THEME_COLOR,
                                          color: "white",
                                          width: 100,
                                          marginTop: 10,
                                          fontSize: "8px",
                                        }}
                                        position="right"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            fromAirport: "",
                                            toAirport: "",
                                            onwardDateDisplay: new Date(),
                                          });
                                        }}
                                      >
                                        + Add Sector
                                      </Button>
                                    </Grid>
                                    {values.sectorArray.length > 1 && (
                                      <Grid item xs={6} lg={3}>
                                        <Button
                                          style={{
                                            border: `1px solid ${COLORS.THEME_COLOR}`,
                                            color: COLORS.DEFAULT_TEXT_ACTIVE,
                                            width: 100,
                                            marginTop: 10,
                                            fontSize: "8px",
                                          }}
                                          position="right"
                                          onClick={() => {
                                            arrayHelpers.pop();
                                          }}
                                        >
                                          - Remove Sector
                                        </Button>
                                      </Grid>)}
                                  </Grid>
                                )}
                              </Box>
                            </Box>

                          </Grid>
                        );
                      })}
                  </>
                )}
              />

              <Grid container className={classes.rowLower}>
                <Grid container className="row-lower-grid-container-inner">
                <Grid item sm={8} className={classes.FlightSection_passdet}>
                  <Grid
                    className={classes.gridRespColPassDet}
                    container
                    direction={props.width === "xs" ? "column" : "row"}
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box>
                      <PassengerCount
                        title="Adults (12+ years)"
                        type="adult"
                        val={values.noAdult}
                        setVal={modifyPassengerCount}
                        name="noAdult"
                      />
                      <ErrorMessage
                        name="noAdult"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box>
                      <PassengerCount
                        title="Children (2 - 12 years)"
                        type="child"
                        val={values.noChild}
                        setVal={modifyPassengerCount}
                        name="noChild"
                      />
                      <ErrorMessage
                        name="noChild"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box>
                      <PassengerCount
                        title="Infants (0 - 2 years)"
                        type="infant"
                        val={values.noInfant}
                        setVal={modifyPassengerCount}
                        name="noInfant"
                      />
                      <ErrorMessage
                        name="noInfant"
                        component="div"
                        className="error"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item sm={4} className="airline-select-container">
                  <Box>
                    <FormControl
                      color="secondary"
                      className={classes.airlineSelect}
                      error={values.airline.length === 0}
                    >
                      <InputLabel>Airlines</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        name="airline"
                        open={airlineSelectOpen}
                        // value={data.airlines}
                        value={values.airline}
                        onChange={(e) => {
                          // setData({ ...data, airlines: e.target.value });
                          // setDataError({ ...dataError, airlines: false });
                          // console.log(e.target.value);
                          setFieldValue(
                            `airline`,
                            e.target.value
                          );
                          // setAirlineSelectOpen(false);
                        }}
                        renderValue={(selected) => {
                          let initialValue = "";

                          if (
                            selected.includes(
                              allAirlines
                            )
                          ) {
                            initialValue =
                              initialValue + ", " + "All";
                          }
                          if (
                            selected.includes(
                              lccAirlines
                            )
                          ) {
                            initialValue =
                              initialValue + ", " + "LCC Only";
                          }
                          if (selected.includes(gdsAirlines)) {
                            initialValue =
                              initialValue + ", " + "GDS Only";
                          }
                          initialValue =
                            carrier &&
                            carrier.reduce(
                              (previousValue, currentValue) =>
                                selected.includes(
                                  currentValue.name
                                )
                                  ? previousValue +
                                  ", " +
                                  currentValue.description
                                  : previousValue,
                              initialValue
                            );

                          return (
                            initialValue &&
                            initialValue.substring(
                              1,
                              initialValue.length - 1
                            )
                          );
                        }}
                        onOpen={() =>
                          setAirlineSelectOpen(true)
                        }
                        onClose={() => {
                          // setDataError({
                          //   ...dataError,
                          //   airlines: true,
                          // });

                          setAirlineSelectOpen(false);
                        }}
                      >
                        <MenuItem
                          key={allAirlines}
                          value={allAirlines}
                        >
                          <ListItemText primary={"All"} />
                          <Checkbox
                            checked={
                              values.airline.indexOf(
                                allAirlines
                              ) > -1
                            }
                          />
                        </MenuItem>

                        <MenuItem
                          key={lccAirlines}
                          value={lccAirlines}
                        >
                          <ListItemText primary={"LCC Only"} />
                          <Checkbox
                            checked={
                              values.airline.indexOf(
                                lccAirlines
                              ) > -1
                            }
                          />
                        </MenuItem>
                        <MenuItem key={gdsAirlines} value={gdsAirlines}>
                          <ListItemText primary={"GDS Only"} />
                          <Checkbox
                            checked={
                              values.airline.indexOf(gdsAirlines) >
                              -1
                            }
                          />
                        </MenuItem>

                        {carrier &&
                          carrier.map((a) => (
                            <MenuItem
                              key={a.name}
                              value={a.name}
                            >
                              <ListItemText
                                primary={a.description}
                              />
                              <Checkbox
                                checked={
                                  values.airline.indexOf(
                                    a.name
                                  ) > -1
                                }
                              />
                            </MenuItem>
                          ))}
                      </Select>
                      {values.airline.length === 0 && (
                        <FormHelperText>
                          Please choose an airline
                        </FormHelperText>
                      )}
                    </FormControl>
                    <ErrorMessage
                      name="airline"
                      component="div"
                      className="error"
                    />
                  </Box>
                </Grid>
                </Grid>
                
                <Grid container className="row-lower-grid-container-inner">
                <Grid item sm={9} className={classes.FlightSection_actions}>
                  <Grid
                    className={classes.gridRespCol}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Box className={classes.toggleBox}>
                        <ToggleButtonGroup
                          name="isNonStop"
                          size="small"
                          // value={data.nonStop}
                          value={values.isNonStop}
                          exclusive
                          // onChange={(e, v) => v && setData({ ...data, nonStop: v })}
                          onChange={(e, v) => setFieldValue("isNonStop", v)}
                        >
                          <ToggleButton value={true}>
                            Stop
                          </ToggleButton>
                          <ToggleButton value={false}>
                            Non-Stop
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <ErrorMessage
                          name="isNonStop"
                          component="div"
                          className="error"
                        />
                      </Box>
                    </Grid>

                    <Grid item>
                      <Box className={classes.toggleBox}>
                        <ToggleButtonGroup
                          name="cabinType"
                          size="small"
                          // value={data.class}
                          value={values.cabinType}
                          exclusive
                          // onChange={(e, v) => v && setData({ ...data, class: v })}
                          onChange={(e, v) => v && setFieldValue("cabinType", v)}
                        >
                          <ToggleButton value="E">
                            Economy
                          </ToggleButton>
                          <ToggleButton value="ER">
                            Premium Economy
                          </ToggleButton>
                          <ToggleButton value="B">
                            Business
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <ErrorMessage
                          name="cabinType"
                          component="div"
                          className="error"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={3} className={classes.FlightSection_searchBtn}>
                  <Grid
                    className={classes.gridRespCol}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item className="search-btn-col">
                      <Box className={classes.searchButtonBox}>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          // onClick={submitForm}
                          type="submit"
                        >
                          Search
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
                
              </Grid>

              {corporateType && corporateType != "corporate" && 
              <Grid container className={classes.rowBottom}>
                <Grid container className="quick-links-container">
                {userType && userType == "agent" &&
                  <Grid item className="quick-link-item" onClick={() => openQuicklink("flights-report")}>
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-plane"></i>
                    </div>
                    <div className="qlink-label-wrapper">FLIGHTS REPORT</div>
                  </Grid>}

                  {userType && userType == "agent" &&
                  <Grid item className="quick-link-item" onClick={() => openQuicklink("travel-calendar")}>
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-calendar"></i>
                    </div>
                    <div className="qlink-label-wrapper">TRAVEL CALENDER</div>
                  </Grid>}

                  {userType && userType == "agent" &&
                  <Grid item className="quick-link-item" onClick={() => openQuicklink("cancellation")}>
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-ban"></i>
                    </div>
                    <div className="qlink-label-wrapper">CANCELLATION</div>
                  </Grid>}

                  <Grid item className="quick-link-item" onClick={() => openQuicklink("web-checkin")}>
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-globe"></i>
                    </div>
                    <div className="qlink-label-wrapper">WEB CHECK IN</div>
                  </Grid>

                  <Grid item className="quick-link-item" onClick={() => openQuicklink("airlines-contact")}>
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="qlink-label-wrapper">AIRLINES CONTACT</div>
                  </Grid>

                  <Grid item className="quick-link-item" onClick={() => openQuicklink("group-booking")}>
                    <div className="qlink-icon-wrapper">
                      <i className="fas fa-users"></i>
                    </div>
                    <div className="qlink-label-wrapper">GROUP BOOKING</div>
                  </Grid>
                </Grid>
              </Grid>}
            </Grid>

            {/* {console.log("values: ", values)}
            {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const mapPropsToState = (state, props) => {
  return {
    domCarriers: state.flight.domCarriers,
    intlCarriers: state.flight.intlCarriers,
    airports: state.flight.airports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDomesticCarriers: (domCarriers) =>
      dispatch(setDomesticCarriers(domCarriers)),
    setInternationalCarriers: (intlCarriers) =>
      dispatch(setInternationalCarriers(intlCarriers)),
    setAirports: (airports) => dispatch(setAirports(airports)),
  };
};

export default withRouter(
  connect(
    mapPropsToState,
    mapDispatchToProps
  )(FlightSection)
);

const flightWayList = [
  { val: "one-way", text: "One Way" },
  { val: "round-trip", text: "Round Trip" },
  { val: "round-trip-sp", text: "Round Trip Special" },
  { val: "multi-city", text: "Multi City" },
];
// const airlinesList = [
//   { name: "all", description: "All" },
//   { name: "gds-only", description: "GDS Only" },
//   { name: "lcc-only", description: "LCC Only" },
//   { name: "air-asia", description: "Air Asia" },
// ];
// const departFromList = [
//   { title: "Mumbai", value: "mumbai" },
//   { title: "Hyderabad", value: "hyderabad" },
//   { title: "Bangalore", value: "bangalore" },
//   { title: "Chennai", value: "chennai" },
// ];
// const arriveToList = [
//   { title: "Mumbai", value: "mumbai" },
//   { title: "Hyderabad", value: "hyderabad" },
//   { title: "Bangalore", value: "bangalore" },
//   { title: "Chennai", value: "chennai" },
// ];
