import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonIcon from "@material-ui/icons/Person";

import GridContainer from "components/Grid/GridContainer.js";
import OaFormTextField from "oahoc/OaStandardTextField";
import LockIcon from "@material-ui/icons/Lock";
import Captcha from "demos-react-captcha";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import { Flight as FlightReport } from "pages/report/flight/Flight";
import PublishIcon from "@material-ui/icons/Publish";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import FlightMarkup from "pages/management/commission-and-markup/flight/Markup";
import { MoneyTransfer } from "pages/report/moneytransfer/MoneyTransfer";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { RechargeReport } from "pages/report/recharge/RechargeReport";
import RechargeCommissionAndCharge from "pages/module/recharge/commission/CommissionAndCharge";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import { BusReport } from "pages/report/bus/BusReport";
import BusCommissionAndCharge from "pages/module/bus/commission/CommissionAndCharge";
import { useHistory } from "react-router-dom";
import HotelIcon from "@material-ui/icons/Hotel";
import { HotelReport } from "pages/report/hotel/HotelReport";
import HotelCommissionAndCharge from "pages/module/hotel/commission/CommissionAndCharge";
import { AppStateContext } from "layouts/AppStateProvider";
import { localforageSetItem } from "oautils/oaForageUtils";
import OaPasswordToggleInput from "oahoc/OaPasswordToggleInput";
// import _ from "lodash";

const SingIn = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { routes, setRoutes } = useContext(AppStateContext);
  const classes = useStyles();
  const history = useHistory();
  const formikRef = React.useRef();
  const onChange = (value) => {
    // console.log(value);
    setCaptcha(value);
    return value;
  };
  // console.log(captcha)

  const signIn = (searchParams, setSubmitting) => {
    setIsSaving(true);
    apiCall(WebApi.postLoginB2C, searchParams, (response) => {
      setAlertTitle("Error logging");
      setSubmitting(false);
      setIsSaving(false);

      if (response != null && response.success) {
        setIsError(false);
        setIsSuccess(true);
        setAlertTitle("Success logging");
        setInfoText(response.message);

        localforageSetItem("user-id", response.data.userId);
        localforageSetItem("access-key", response.accesskey);
        localforageSetItem("user-details", response.data, () => {
          if (routes[1].views.length === 0) {
            const tempRoutes = [...routes];
            response.data.serviceOption.map((options) => {
              if (options.serviceValue === "flights") {
                tempRoutes[1].views.push({
                  path: "/booking-report/flight",
                  name: "Flight",
                  icon: PublishIcon,
                  component: FlightReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/markup/flight",
                  name: "Flight Markup",
                  icon: MoneyOffIcon,
                  component: FlightMarkup,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "moneytransfer") {
                tempRoutes[1].views.push({
                  path: "/booking-report/moneytransfer",
                  name: "DMT",
                  icon: AttachMoneyOutlinedIcon,
                  component: MoneyTransfer,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "recharge") {
                tempRoutes[1].views.push({
                  path: "/booking-report/recharge",
                  name: "Recharge",
                  icon: PhoneAndroidIcon,
                  component: RechargeReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/commission/recharge",
                  name: "Recharge Markup",
                  icon: PhoneAndroidIcon,
                  component: RechargeCommissionAndCharge,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "buses") {
                tempRoutes[1].views.push({
                  path: "/booking-report/bus",
                  name: "Bus",
                  icon: DirectionsBusIcon,
                  component: BusReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/commission/bus",
                  name: "Bus Markup",
                  icon: DirectionsBusIcon,
                  component: BusCommissionAndCharge,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "hotels") {
                tempRoutes[1].views.push({
                  path: "/booking-report/hotel",
                  name: "Hotel",
                  icon: HotelIcon,
                  component: HotelReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/commission/hotel",
                  name: "Hotel Markup",
                  icon: HotelIcon,
                  component: HotelCommissionAndCharge,
                  layout: "/admin",
                });
              }
            });
            setRoutes(tempRoutes);
          }
          history.push("/flights");
        });
      } else if (response != null) {
        setAlertTitle("Error logging");
        setInfoText(response.message);
        setIsError(true);
        setIsSuccess(false);
      }
    });
  };

  const goToForgotPassword = () => {
    history.push("/b2c/forgotpassword");
  };

  const onChangePassword = (event) => {
    console.log("event", event);
    if (formikRef.current) {
      formikRef.current.setFieldValue("password", event.target.value);
    }
  };
  // console.log(routes);
  return (
    <div className={classes.root} style={{}}>
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%" }}
        justifyContent="center"
      >
        <Grid item xs={12} md={6}>
          <Grid
            container
            xs={12}
            md={10}
            spacing={isMobile ? 0 : 1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h5"
                style={{ fontSize: isMobile ? 20 : 25, color: "#000" }}
              >
                Desire more for your travel?
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="h5"
                style={{ fontSize: "14px", color: "#000" }}
              >
                Create an account with us!
              </Typography>
            </Grid>
            <Grid item md={12}>
              <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              />
            </Grid>
            <Grid container>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  username: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string().required("username is required"),
                  password: Yup.string().required("password is required"),
                })}
                onSubmit={(
                  searchParams,
                  { setSubmitting, resetForm, enableReinitialize }
                ) => signIn(searchParams, setSubmitting)}
              >
                {({
                  values,
                  setFieldValue,
                  enableReinitialize,
                  resetForm,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 50,
                      }}
                    >
                      <GridContainer spacing={isMobile ? 0 : 1}>
                        <Grid item md={12} sm={12} xs={12}>
                          <OaFormTextField
                            name="username"
                            label="Username or Email"
                            adornPosition="right"
                            adornmentIcon={<PersonIcon />}
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="error"
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginBottom: 15 }}
                        >
                          {/* <OaFormTextField
                            isPasswordField={true}
                            name="password"
                            label="Password"
                            type="password"
                            adornPosition="right"
                            adornmentIcon={<LockIcon />}
                          /> */}
                          <OaPasswordToggleInput
                            name="password"
                            label="Password"
                            value={values.password}
                            onChange={onChangePassword}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error"
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginBottom: 15 }}
                          className={classes.captchaGrid}
                        >
                          <Grid container>
                            <Grid
                              item
                              md={6}
                              style={{ margin: "0 10px 10px 0" }}
                            >
                              <Captcha
                                onChange={onChange}
                                placeholder="Enter captcha"
                              />
                            </Grid>
                            <Grid item md={6} className="captcha-message">
                              <p style={{color: captcha ? "green" : "red"}}>
                                {captcha
                                  ? "Captcha Valid! please proceed to login"
                                  : "please enter the characters displayed in the image correctly"}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* {captcha &&  <Grid item md={12} container justifyContent="flex-start">
                        
                        <Button
                          type="submit"
                          className={classes.Button}
                          style={{ fontFamily: "Montserrat"}}
                        >
                          Submit
                        </Button>
                      </Grid>} */}
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                        >
                          <Grid container style={{ flexWrap: "wrap" }}>
                            <Grid
                              item
                              md={6}
                              sm={6}
                              xs={12}
                              style={{ padding: 5 }}
                            >
                              <button
                                type="submit"
                                className={classes.Button}
                                style={{
                                  fontFamily: "Poppins",
                                  width: "100%",
                                  color: "#fff",
                                }}
                                disabled={captcha ? "" : "disabled"}
                              >
                                Sign in
                              </button>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              sm={6}
                              xs={12}
                              style={{ padding: 5 }}
                            >
                              <button
                                className={classes.Button}
                                style={{ fontFamily: "Poppins", width: "100%" }}
                                onClick={goToForgotPassword}
                              >
                                Forgot Password?
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </GridContainer>
                    </div>

                    {/* {console.log("values: ", values)}
            {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <Grid
            container
            spacing={isMobile ? 0 : 1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h5"
                style={{ fontSize: isMobile ? 20 : 24, color: "#000" }}
                gutterBottom
              >
                Desire more for your travel?
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Sign in to make your travel planning and bookings quick and
                easy.
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">Print e-tickets</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Check your booking history
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Easily manage cancellations & refunds
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h4"
                style={{
                  fontSize: isMobile ? 20 : 24,
                  color: "#000",
                  lineHeight: 1,
                }}
                gutterBottom
              >
                Don't have an Account?
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Button
                className={classes.Button}
                onClick={() => history.push("/b2c/register")}
              >
                Sign up for one now!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SingIn;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 60,
    minHeight: "62vh",
    "& .MuiInputBase-input": {
      minHeight: 35
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .oapt-input .MuiInputBase-input:focus-within + .oapt-btn-wrap i": {
      color: theme.palette.primary.main,
    },
    "& .oapt-btn": {
      "& i": {
        color: "#000",
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },

  Grid: {
    background: "#fff",
    marginTop: "50px",
    // boxShadow: "0px 0px 15px -1px #989898",
    padding: 30,
    borderRadius: 20,
  },
  firstCard: {
    "& .MuiTypography-root": {
      color: "#000",
    },
  },
  Button: {
    padding: ".5rem 1.5rem",
    border: "none" /* borderRadius: '30px', */,
    background: theme.palette.buttons.secondary,
    letterSpacing: "1px",
    color: theme.palette.secondary.contrastText,
    cursor: "pointer",
    textTransform: "none",
    textShadow: "none",
    fontWeight: 500,
    borderRadius: 4,
    height: 35,
    "&:hover": {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.active,
    },
    "&:disabled": {
      color: theme.palette.secondary.darkText,
      backgroundColor: theme.palette.secondary.disabled,
    },
  },
  span: {
    color: "#23527c",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  },
  captchaGrid: {
    "& canvas": {
      position: "relative !important",
    },
    "& .captcha-message": {
      display: "flex",
      alignItems: "center",
      "& p": {
        fontWeight: 500,
      },
    },
  },
}));
