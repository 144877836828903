import React from "react";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import WebApi from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";
import { localforageClear, localforageSetItem } from "oautils/oaForageUtils";
import OaLoading from "./OaLoading";
import { Error } from "@material-ui/icons";

const OaLogout = (props) => {
  const classes = useStyles();

  const logOut = () => {
    localforageGetItem("user-details", function(err, value) {
      if (value) {
        // value.userTypeAbv
        localforageClear(() => {
          WebApi.getAgentInfo({ userType: "G" }, (response) => {
            if (response != null && response.success === true) {
              localforageClear(() => {
                localforageSetItem("user-id", 0);

                localforageSetItem("user-details", response.data, () => {
                  localforageSetItem("access-key", response.accesskey, () => {
                    if (
                      value.userType == "agent" ||
                      value.userType == "direct_agent"
                    ) {
                      props.history.push("/auth/login-page");
                    } else if (value.corporateType == "corporate") {
                      props.history.push("/corporate_login");
                    }
                    // window.location.reload();
                  });
                });
              });
            }
          });
        });
      } else {
        props.history.push("/flights")
      }
    });
  };

  React.useEffect(() => {
      // logOut();
      // props.history.push("/auth/login-page");
  }, []);

  return (
    <div className={classes.root}>
      <div className="icon-holder">
        <Error />
      </div>
      <Typography className="info-holder">
        Session expired. Please wait ...<br/>
        If this page does not redirect you back, please click <a onClick={() => logOut()}>here</a>
      </Typography>
    </div>
  );
};

export default OaLogout;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .icon-holder": {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
        fontSize: "8rem",
      },
    },
    "& .info-holder": {
      fontFamily: theme.palette.font.primary,
      fontWeight: 500,
      marginTop: 10,
      color: "auto",
      textAlign: 'center',
      "& a": {
        cursor: "pointer",
        color: theme.palette.primary.success,
      },
    },
  },
}));
