import React, {useState} from 'react';

import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';

import {Formik, Form} from 'formik';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';
import Collapse from '@material-ui/core/Collapse';

const LedgerSearchForm = (props) => {
    const [collapsed, setCollapsed] = useState(true);

    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    return(
        <>
        {/* {console.log('Ledger Search Form rendered')} */}
        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}} >Search Ledger</h4>
        <Collapse in={collapsed}>
        <Formik 
            initialValues={{
                dateFromDisplay: props.searchParams.dateFromDisplay,
                dateToDisplay: props.searchParams.dateToDisplay,
                type: props.searchParams.type,
                agencyId: props.searchParams.agencyId
            }}
            onSubmit= {(searchParams, {setSubmitting, resetForm}) => {
                // console.log(searchParams);
                searchParams.dateFrom = dateFnsFormat(searchParams.dateFromDisplay, 'dd-MM-yyyy');
                searchParams.dateTo = dateFnsFormat(searchParams.dateToDisplay,   'dd-MM-yyyy');
                props.target(searchParams);
                props.setIsLoading(false);
            }}>
            {({ values, isSubmitting, setFieldValue, handleChange, initialValues }) => (
                <Form>
                    <GridContainer>
                        <OaFormDateField 
                            value={values.dateFromDisplay} 
                            selected={values.dateFromDisplay}
                            onChange={date => setFieldValue('dateFromDisplay', date)}
                            label="From"
                            name="dateFromDisplay"
                            md={4}
                        />
                        <OaFormDateField 
                            value={values.dateToDisplay}
                            selected={values.dateToDisplay}
                            onChange={date => setFieldValue('dateToDisplay', date)}
                            label="To"
                            name="dateToDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />
                        <OaFormSelectField 
                            label="Status" 
                            name="type" 
                            value={values.type} 
                            options={[
                                {value: ' ', label: 'Both'},
                                {value: 'credit', label: 'Credit'},
                                {value: 'debit', label: 'Debit'}
                            ]}
                            md={4}
                        />
                    </GridContainer>
                    <Button color="primary" type="submit">Search</Button>
                </Form>
            )}
        </Formik>
        </Collapse>
        </>
    )
}

export default LedgerSearchForm;