import React from "react";

const OaAmountInWords = (props) => {
    const [amountInWords, setAmountInWords] = React.useState("");
    const [amount, setAmount] = React.useState(null);
    const [decAmount, setDecAmount] = React.useState(null);
    const [append, setAppend] = React.useState(" only");

    const getOneTensPlace = (num, str) => {
        if(!amount) { return }
        let numStr = num + "";
        return num > 0 
            ? (num > 19 
                ? b[Number(numStr[0])] + " " + a[numStr[1]] 
                : a[num]) + ((amount+"").length > 3 && str ? str : "") 
            : "";
    };

    const getHdPlace = (num, str) => {
        return num > 0 ? a[num] + str : "";
    };

    const getAmountInWords = () => {
        let onPlace = "", hdPlace = "", thPlace = "", lkPlace = "", crPlace = "";
        let numStr = amount + "";
        switch (numStr.length) {
            case 1:
            case 2: {
                onPlace = getOneTensPlace(amount);
                break;
            }
            case 3: {
                onPlace = getOneTensPlace(Number(numStr[1]+""+numStr[2]));
                hdPlace = getHdPlace(Number(numStr[0]), " hundred and ");
                break;
            }
            case 4: {
                onPlace = getOneTensPlace(Number(numStr[2]+""+numStr[3]));
                hdPlace = getHdPlace(Number(numStr[1]), " hundred and ");
                thPlace = getOneTensPlace(Number(numStr[0]), " thousand ");
                break;
            }
            case 5: {
                onPlace = getOneTensPlace(Number(numStr[3]+""+numStr[4]));
                hdPlace = getHdPlace(Number(numStr[2]), " hundred and ");
                thPlace = getOneTensPlace(Number(numStr[0]+""+numStr[1]), " thousand ");
                break;
            }
            case 6: {
                onPlace = getOneTensPlace(Number(numStr[4]+""+numStr[5]));
                hdPlace = getHdPlace(Number(numStr[3]), " hundred and ");
                thPlace = getOneTensPlace(Number(numStr[1]+""+numStr[2]), " thousand ");
                lkPlace = getOneTensPlace(Number(numStr[0]), " lakh ");
                break;
            }
            case 7: {
                onPlace = getOneTensPlace(Number(numStr[5]+""+numStr[6]));
                hdPlace = getHdPlace(Number(numStr[4]), " hundred and ");
                thPlace = getOneTensPlace(Number(numStr[2]+""+numStr[3]), " thousand ");
                lkPlace = getOneTensPlace(Number(numStr[0]+""+numStr[1]), " lakh ");
                break;
            }
            case 8: {
                onPlace = getOneTensPlace(Number(numStr[6]+""+numStr[7]));
                hdPlace = getHdPlace(Number(numStr[5]), " hundred and ");
                thPlace = getOneTensPlace(Number(numStr[3]+""+numStr[4]), " thousand ");
                lkPlace = getOneTensPlace(Number(numStr[1]+""+numStr[2]), " lakh ");
                crPlace = getOneTensPlace(Number(numStr[0]), " crore ");
                break;
            }
            case 9: {
                onPlace = getOneTensPlace(Number(numStr[7]+""+numStr[8]));
                hdPlace = getHdPlace(Number(numStr[6]), " hundred and ");
                thPlace = getOneTensPlace(Number(numStr[4]+""+numStr[5]),  " thousand ");
                lkPlace = getOneTensPlace(Number(numStr[2]+""+numStr[3]), " lakh ");
                crPlace = getOneTensPlace(Number(numStr[0]+""+numStr[1]), " crore ");
                break;
            }
        }
        setAmountInWords(crPlace+lkPlace+thPlace+hdPlace+onPlace);
    };

    const getAppend = () => {
        let str = " only";
        if(decAmount > 0) {
            str = ", "+getOneTensPlace(decAmount) + " paise only";
        }
        setAppend(str);
    };

    React.useEffect(() => {
        if(props.num) {
            setAmount(props.num);
            setDecAmount(0);
            if((props.num+"").split(".")) {
                let amtParts = (props.num+"").split(".");
                setAmount(Number(amtParts[0]));
                setDecAmount(Number(amtParts[1]));
            }
        } else {
            setAmount(null);
            setDecAmount(null);
            setAmountInWords("");
            setAppend(" only");
        }
    }, [props.num]);

    React.useEffect(() => {
        if(amount) getAmountInWords();
    }, [amount]);

    React.useEffect(() => {
        if(decAmount) getAppend();
    }, [decAmount]);
    
    return(
        <span className="amount-in-words">{props.num && amountInWords}{props.num && append}</span>
    );
};

export default OaAmountInWords;

const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
];

const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
];
