import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from "date-fns/format";
import { Formik, Form } from "formik";
import OaLoading from "pages/components/OaLoading";
import OaFormTextField from "oahoc/OaFormTextField";
import OaFormDateField from "oahoc/OaFormDateField";
import GridItem from "components/Grid/GridItem.js";
import OaFormSelectField from "oahoc/OaFormSelectField";

const HotelReportSearchForm = (props) => {
  const [isLoading] = useState(false);
  const [] = useState(true);
  const [agencyId, setAgencyId] = useState(props.agencyId);

  useEffect(() => {
    if (isLoading) {
    }
  }, [isLoading]);

  useEffect(() => {
    setAgencyId(props.agencyId);
  }, [props.agencyId]);

  return (
    <>
      {isLoading && <OaLoading />}
      {!isLoading && (
        <>
          <h4
            className={"oa-form-header"}
            style={{ transform: " scale(0.85)", transformOrigin: "left" }}
          >
            Search Hotel Report
          </h4>
          <Formik
            initialValues={{
              hotelBookingFromDateDisplay:
                props.searchParams.hotelBookingFromDateDisplay,
              hotelBookingToDateDisplay:
                props.searchParams.hotelBookingToDateDisplay,
              hotelBookingFromDate: props.searchParams.hotelBookingFromDate,
              hotelBookingToDate: props.searchParams.hotelBookingToDate,
              agencyId: props.searchParams.agencyId,
              bookingStatus: props.searchParams.bookingStatus,
            }}
            onSubmit={(searchParams, {}) => {
              props.setIsLoading(true);
              searchParams.hotelBookingFromDate = dateFnsFormat(
                searchParams.hotelBookingFromDateDisplay,
                "dd-MM-yyyy"
              );
              searchParams.hotelBookingToDate = dateFnsFormat(
                searchParams.hotelBookingToDateDisplay,
                "dd-MM-yyyy"
              );
              searchParams.isHotelTransaction = true;
              props.target(searchParams);
              props.setIsLoading(false);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <GridContainer>
                  <OaFormTextField
                    name="hotelApplicationReference"
                    label="Application Reference"
                    md={4}
                  />
                  <OaFormTextField
                    name="accountNo"
                    label="Ticket No/PNR"
                    md={4}
                  />

                  <OaFormDateField
                    value={values.hotelBookingFromDateDisplay}
                    selected={values.hotelBookingFromDateDisplay}
                    onChange={(date) =>
                      setFieldValue("hotelBookingFromDateDisplay", date)
                    }
                    label="Hotel From Date"
                    name="hotelBookingFromDateDisplay"
                    minDate={values.dateFromDisplay}
                    md={4}
                  />

                  <OaFormDateField
                    value={values.hotelBookingToDateDisplay}
                    selected={values.hotelBookingToDateDisplay}
                    onChange={(date) =>
                      setFieldValue("hotelBookingToDateDisplay", date)
                    }
                    label="Hotel To Date"
                    name="hotelBookingToDateDisplay"
                    minDate={values.dateFromDisplay}
                    md={4}
                  />

                  <GridItem xs={12} sm={12} md={4}>
                    <OaFormSelectField
                      label="Status"
                      name="bookingStatus"
                      value={values.bookingStatus}
                      options={[
                        { value: "All", label: "All" },
                        { value: "Confirmed", label: "Confirmed" },
                        { value: "Cancelled", label: "Cancelled" },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
                <Button color="primary" type="submit">
                  Search
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default HotelReportSearchForm;
