import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import WebApi from "api/ApiConstants";
import image1 from "assets/rive/hotelanimation.riv";
import Rive from "rive-react";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  setHotelSearchResult,
  setTimerInitialMinute,
  setTimerInitialSecond,
  setInsuranceBookingInitialTime,
} from "redux/action";

const InsuranceSearchProgressPage = (props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(true);

  const { searchParams } = props.history.location.state;

  const redirectToSearchEngine = () => {
    setTimeout(() => {
      props.history.push("/insurances");
    }, 1500);
  };

  React.useEffect(() => {
    WebApi.getInsuranceSearchDetails(
      searchParams,
      (response) => {
        // setSubmitting(false);
        //if (response.isSuccess) {
          // console.log(response);
          props.setInsuranceBookingInitialTime(
            new Date().getTime() + 15 * 60 * 1000
          );
          window.sessionStorage.setItem("service", "insurance");
          props.history.push({
            pathname: "/insurance/result",
            state: {
              session_id: response.sessionId,
              result: response.result,
              request: response.searchRequest,
            },
          });
        // } else {
        //   setInfoText("Request Failed!");
        //   setIsError(true);
        //   redirectToSearchEngine();
        // }
      },
      (error) => {
        console.log("internal error", error);
        setInfoText("internal error! please contact admin");
        setIsError(true);
        redirectToSearchEngine();
      }
    );
  }, [isLoading]);

  return (
    <GridContainer style={{ height: "60vh" }}>
      <GridItem md={12}>
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
      </GridItem>
      <GridItem
        md={12}
        style={{
          height: "20vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          backgroundColor: "transparent",
        }}
      >
        {/* <img src={ajaxLoader}/> */}
        <Rive src={image1} />
      </GridItem>
      <GridItem
        md={12}
        style={{
          height: "20vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          backgroundColor: "transparent",
        }}
      >
        <GridContainer
          justifyContent="center"
          style={{
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          Insurance Search
        </GridContainer>
      </GridItem>
      {/* {props.hotelSearch.departureCityId} {props.hotelSearch.destinationCityId} */}
    </GridContainer>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelSearchResult: (hotelSearchResult) =>
      dispatch(setHotelSearchResult(hotelSearchResult)),
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
    setInsuranceBookingInitialTime: (insuranceBookingInitialTime) =>
      dispatch(setInsuranceBookingInitialTime(insuranceBookingInitialTime)),
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InsuranceSearchProgressPage)
);
