import React, { useState, useEffect } from 'react'
import OaCard from 'oahoc/OaCard';
import { getAgencyId } from 'oautils/oaCommonUtils.js';
import OaTabBar from 'oahoc/OaTabBar';
import AgentDetails from 'pages/agent/AgentDetails';

import AadhaarpayReportSearchForm from 'pages/report/aadhaarpay/AadhaarpayReportSearchForm';
import AadhaarpayReportSearchResult from 'pages/report/aadhaarpay/AadhaarpayReportSearchResult';
import dateFnsFormat from 'date-fns/format';

export const AadhaarpayReport = (props) => {

    const [agencyId, setAgencyId] = useState(getAgencyId(props));
    const [isLoading, setIsLoading] = useState(true);

    let d = new Date;
    let fromDate = d; //.setDate(d.getDate() - 7);
    let today = new Date();

    let initialParams = {
        agencyId: agencyId,
        aadhaarpayBookingFromDateDisplay: fromDate,
        aadhaarpayBookingToDateDisplay: today,
        aadhaarpayBookingFromDate: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
        aadhaarpayBookingToDate: dateFnsFormat(today, 'dd-MM-yyyy'),
        accountNo: '',
        bookingStatus: 'All',
        type: 'aadhaarpay'
    };

    const [searchParams, setSearchParams] = useState(initialParams);
    const [tableKey, setTableKey] = useState(0);

    function aadhaarpayReportLoader(searchParams) {
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    let tabs = [
        { name: 'aadhaarpay', label: 'Aadhaar Pay', url: '/admin/booking-report/aadhaarpay' },
    ];

    let currentTab = 'aadhaarpay';

    useEffect(() => {
        let aId = getAgencyId(props)
        setAgencyId(aId);
        setSearchParams({ ...searchParams, agencyId: aId });
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (
        <>
            {!isLoading &&
                <OaCard className={'oa-card-primary'}>
                    <OaTabBar tabs={tabs} currentTab={currentTab} type='primary' />
                    {agencyId &&
                        (
                            <OaCard>
                                <AgentDetails agencyId={agencyId} />
                            </OaCard>
                        )
                    }
                    <OaCard>
                        <AadhaarpayReportSearchForm
                            target={aadhaarpayReportLoader}
                            agencyId={agencyId}
                            searchParams={searchParams}
                            setIsLoading={setIsLoading}
                        />
                    </OaCard>

                    <OaCard>
                        <AadhaarpayReportSearchResult key={tableKey} searchParams={searchParams} agencyId={agencyId} target={aadhaarpayReportLoader} />
                    </OaCard>
                </OaCard>
            }
        </>
    )
}
export default AadhaarpayReport;
