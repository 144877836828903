import React, { useState } from "react";
import OaLoading from "pages/components/OaLoading";
import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  ErrorOutlineRounded,
  CheckCircle,
  List,
  ListAltOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import WebApi from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import OaFormAlerts from "pages/components/OaFormAlerts";
import FlightItinerary from "./checkinReview/FlightItinerary";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: 10,
      },
    },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "50px 0",
      "& .MuiSvgIcon-root": {
        fontSize: "10vw",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 20,
      },
    },
    "& .action-group": {
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));
const FlightPaymentStatus = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [refId, setRefId] = React.useState(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const history = useHistory();
  const [flightData, setFlightData] = React.useState(null);
  const [state, setState] = React.useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });

  const printTicket = () => {
    history.push({
      pathname:
        "/print/flightticket/" +
        history.location.state.Tid +
        "/" +
        state?.isAddressHide +
        "/" +
        state?.isFareHide +
        "/" +
        state?.isPerPassenger +
        "/" +
        state?.isGSTHide,
    });
  }

  function getTransactionStatus(data) {
    if (
      data.bookingStatus.toLowerCase() === "success" ||
      data.bookingStatus.toLowerCase() === "successtkd"
    ) {
      return true;
    }
    return false;
  }

  React.useEffect(() => {
    setIsLoading(true);
    if (props.match.params.tid) {
      WebApi.getTicketData(
        { transactionId: props.match.params.tid },
        (response) => {
          if (response.success == true && getTransactionStatus(response.data)) {
            setRefId(response.data.bookingRef);
            setPaymentStatus(response.data.bookingStatus);
            setBookingStatus(true);
            setFlightData(response.data);
          } else {
            setPaymentStatus(response.data.bookingStatus);
            setBookingStatus(false);
          }
          setIsLoading(false);
        },
        (error) => {
          setInfoText("Internal error");
          setIsError(true);
          setBookingStatus(false);
          setIsLoading(false);
          setPaymentStatus("Failed");
          console.log("error", error);
        }
      );
    } else {
      alert("invalid/missing reference id!");
    }
  }, [props.match.params.tid]);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        {/* <OaLoading loadertext="please wait ..." /> */}
        <div>
          <img src={props.comloader} />
        </div>
        <div>
          <span className="loader-text">please wait</span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {!isLoading && bookingStatus && (
        <>
          <div className="section-container" style={{ paddingBottom: "8px" }}>
            <div className="icon-group">
              <CheckCircle />
              <Typography>
                Reference id : <b>{refId}</b>
              </Typography>
              <Typography>
                Status : <b>{paymentStatus}</b>
              </Typography>
              <Typography>
                Booking Successful. Please click the button below to go to ticket
                details.
              </Typography>
            </div>
            <div className="action-group">
              <Button
                onClick={() =>
                  props.history.push(
                    "/print/flightticket/" + props.match.params.tid
                  )
                }
              >
                <ListAltOutlined /> Ticket
              </Button>
            </div>
          </div>

          <div className="section-container flex-start">
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
            <div className="itin-section">
              <div className="action-group">
                {/*flightData?.bookingStatus != "SUCCESS" && flightData?.bookingStatus != "SUCCESSCan" && */
                  flightData?.bookingStatus == "SuccessTkd" &&
                  <Button
                    onClick={printTicket}
                  >
                    <PrintOutlined /> Print
                  </Button>}

                {/* <Button
                            onClick={emailETicket}
                        >
                            <EmailOutlined/> Email
                        </Button>

                        <Button
                            onClick={downloadEticket}
                        >
                            <CloudDownloadOutlined/> Download
                        </Button> */}
              </div>

              <div className="itin-contents">
                <FlightItinerary
                  flightdata={flightData}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {!isLoading && !bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <ErrorOutlineRounded style={{ color: "orange" }} />
            <Typography>
              Booking Failed. Please check after some time or contact
              administrator with reference id.
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="section-container">
          {" "}
          <LoaderView />
        </div>
      )}
    </div>
  );
};

export default FlightPaymentStatus;
