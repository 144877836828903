import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import driver from "assets/img/driver.png";
import available from "assets/img/available.png";
import booked from "assets/img/booked.png";
import ladies from "assets/img/only-for-ladies.png";
import selected from "assets/img/selected.png";
import gents from "assets/img/only-for-gents.png";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import BusSeat from "components/BusResult/BusSeat";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";
import { AppStateContext } from "layouts/AppStateProvider";
import { currencyFormatter } from "oautils/oaCommonUtils";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import OaAlert from "oahoc/OaAlert";

const BusSeatLayout = (props) => {
  const classes = useStyles();

  const [boardingPointId, setBoardingPointId] = useState(-1);
  const [droppingPointId, setDroppingPointId] = useState(-1);

  const [boardingPointIdList, setBoardingPointIdList] = useState([]);
  const [droppingPointIdList, setDroppingPointIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [maxRowNo, setMaxRowNo] = useState(0);
  const [maxColumnNo, setMaxColumnNo] = useState(0);

  const [seatLayout, setSeatLayout] = useState({});
  const [totalFare, setTotalFare] = useState(0.0);

  const [selectedSeatIndex, setSelectedSeatIndex] = useState([]);
  const [value, setValue] = useState(0);
  const { SetMessage } = React.useContext(AppStateContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  const [age, setAge] = useState("");
  const handleChange11 = (event) => {
    // console.log("return");
    setBoardingPointId(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isLoading) {
      var params = {
        ResultIndex: props.ResultIndex,
        TraceId: props.TraceId,
      };

      updateSeatLayout(params);
      /*
    WebApi.getBusBoardingPointDetails( params, (response) => {
          // console.log(response)
          if(response.success) {
              setBoardingPointIdList([...response.data.GetBusRouteDetailResult.BoardingPointsDetails]);
              setDroppingPointIdList([...response.data.GetBusRouteDetailResult.DroppingPointsDetails]);
              if(response.data.GetBusRouteDetailResult.BoardingPointsDetails && response.data.GetBusRouteDetailResult.BoardingPointsDetails.length === 1) {
                  setBoardingPointId(response.data.GetBusRouteDetailResult.BoardingPointsDetails[0].CityPointIndex);
              }
              if(response.data.GetBusRouteDetailResult.DroppingPointsDetails && response.data.GetBusRouteDetailResult.DroppingPointsDetails.length === 1) {
                  setDroppingPointId(response.data.GetBusRouteDetailResult.DroppingPointsDetails[0].CityPointIndex);
              }
              updateSeatLayout(params)
          } else {
              setBoardingPointIdList([...props.BoardingPointsDetails]);
              setDroppingPointIdList([...props.DroppingPointsDetails]);
              if(props.BoardingPointsDetails && props.BoardingPointsDetails.length === 1) {
                  setBoardingPointId(props.BoardingPointsDetails[0].CityPointIndex);
              }
              if(props.DroppingPointsDetails && props.DroppingPointsDetails.length === 1) {
                  setDroppingPointId(props.DroppingPointsDetails[0].CityPointIndex);
              }
              setIsError(true);
              setInfoText(response.message)
              updateSeatLayout(params)
          }
      });*/

      //      setBoardingPointIdList([...props.BoardingPointsDetails]);
      //      setDroppingPointIdList([...props.DroppingPointsDetails]);

      /* if (
        props.BoardingPointsDetails &&
        props.BoardingPointsDetails.length === 1
      ) {
        setBoardingPointId(props.BoardingPointsDetails[0].CityPointIndex);
      }

      if (
        props.DroppingPointsDetails &&
        props.DroppingPointsDetails.length === 1
      ) {
        setDroppingPointId(props.DroppingPointsDetails[0].CityPointIndex);
      }
      */
      // updateSeatLayout(params);
    }
  }, [isLoading]);

  const updateSeatLayout = (params) => {
    // apiCall(WebApi.getBusSeatLayout, params, (response) => {
    //   // console.log(response);
    //   if (response.success === true) {
    //     //  console.log(response.data.GetBusSeatLayOutResult.SeatLayoutDetails.SeatLayout)
    //     setSeatLayout(
    //       response.data.GetBusSeatLayOutResult.SeatLayoutDetails.SeatLayout
    //     );

    //     let maxCol = 0,
    //       maxRow = 0;
    //     response.data.GetBusSeatLayOutResult.SeatLayoutDetails.SeatLayout.SeatDetails.map(
    //       (value) => {
    //         value.map((rowCol) => {
    //           if (parseInt(rowCol.ColumnNo) > maxCol) {
    //             maxCol = parseInt(rowCol.ColumnNo);
    //           }

    //           if (parseInt(rowCol.RowNo) > maxRow) {
    //             maxRow = parseInt(rowCol.RowNo);
    //           }
    //         });
    //       }
    //     );

    //     setMaxRowNo(maxRow);
    //     setMaxColumnNo(maxCol);
    //     setIsLoading(false);
    //   } else {
    //     setIsError(true);
    //     setInfoText(response.message);
    //   }
    // });

    WebApi.getBusSeatLayout(params, (response) => {
      if (response.success === true) {
        setBoardingPointIdList([
          ...response.data.GetBusSeatLayOutResult.BoardingPointsDetails,
        ]);
        setDroppingPointIdList([
          ...response.data.GetBusSeatLayOutResult.DroppingPointsDetails,
        ]);
        if (
          response.data.GetBusSeatLayOutResult.BoardingPointsDetails &&
          response.data.GetBusSeatLayOutResult.BoardingPointsDetails.length ===
            1
        ) {
          setBoardingPointId(
            response.data.GetBusSeatLayOutResult.BoardingPointsDetails[0]
              .CityPointIndex
          );
        }
        if (
          response.data.GetBusSeatLayOutResult.DroppingPointsDetails &&
          response.data.GetBusSeatLayOutResult.DroppingPointsDetails.length ===
            1
        ) {
          setDroppingPointId(
            response.data.GetBusSeatLayOutResult.DroppingPointsDetails[0]
              .CityPointIndex
          );
        }
        if (
          props.BoardingPointsDetails &&
          props.BoardingPointsDetails.length === 1
        ) {
          setBoardingPointId(props.BoardingPointsDetails[0].CityPointIndex);
        }

        if (
          props.DroppingPointsDetails &&
          props.DroppingPointsDetails.length === 1
        ) {
          setDroppingPointId(props.DroppingPointsDetails[0].CityPointIndex);
        }

        setSeatLayout(
          response.data.GetBusSeatLayOutResult.SeatLayoutDetails.SeatLayout
        );

        let maxCol = 0,
          maxRow = 0;
        response.data.GetBusSeatLayOutResult.SeatLayoutDetails.SeatLayout.SeatDetails.map(
          (value) => {
            value.map((rowCol) => {
              if (parseInt(rowCol.ColumnNo) > maxCol) {
                maxCol = parseInt(rowCol.ColumnNo);
              }

              if (parseInt(rowCol.RowNo) > maxRow) {
                maxRow = parseInt(rowCol.RowNo);
              }
            });
          }
        );
        setMaxRowNo(maxRow);
        setMaxColumnNo(maxCol);
        setIsLoading(false);
      } else {
        setIsError(true);
        setInfoText(response.message);
      }
    });
  };

  const getSeatData = (x, y) => {
    var seat = null;
    var oldSeatHeight = null;

    seatLayout.SeatDetails.map((value) => {
      value.map((rowCol) => {
        if (
          parseInt(rowCol.RowNo) === x &&
          parseInt(rowCol.ColumnNo) === y &&
          !rowCol.IsUpper
        ) {
          seat = rowCol;
        }

        if (
          parseInt(rowCol.RowNo) === x &&
          parseInt(rowCol.ColumnNo) === y - 1
        ) {
          oldSeatHeight = rowCol.Height;
        }
      });
    });

    if (oldSeatHeight === 2 && seat === null) {
      seat = "ESC";
    }

    // console.log(seat);
    return seat;
  };

  const getUpperSeatData = (x, y) => {
    var seat = null;
    var oldSeatHeight = null;

    seatLayout.SeatDetails.map((value) => {
      value.map((rowCol) => {
        if (
          parseInt(rowCol.RowNo) === x &&
          parseInt(rowCol.ColumnNo) === y &&
          rowCol.IsUpper
        ) {
          seat = rowCol;
        }

        if (
          parseInt(rowCol.RowNo) === x &&
          parseInt(rowCol.ColumnNo) === y - 1
        ) {
          oldSeatHeight = rowCol.Height;
        }
      });
    });

    if (oldSeatHeight === 2 && seat === null) {
      seat = "ESC";
    }

    // console.log(seat);
    return seat;
  };
  const bookOnClick = () => {
    if (boardingPointId === -1 || droppingPointId === -1) {
      // alert('Please select boarding and droping point')
      //SetMessage("Please select boarding and droping point");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
      setAlertMsg("Please select boarding and droping point");
      setAlertTitle("Boarding & Droping selection");
    } else if (selectedSeatIndex.length === 0) {
      // alert('Please select seat')
      //SetMessage("Please select seat");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
      setAlertMsg("Please select atleast 1 seat!!!");
      setAlertTitle("Select seat");
    } else {
      const boardingPoint = boardingPointIdList.filter(
        (item) => item.CityPointIndex == boardingPointId
      )[0];
      const droppingPoint = droppingPointIdList.filter(
        (item) => item.CityPointIndex == droppingPointId
      )[0];
      var params = {
        ResultIndex: props.ResultIndex,
        TraceId: props.TraceId,
        BoardingPointId: boardingPointId,
        DroppingPointId: droppingPointId,
        selectedSeatIndex: selectedSeatIndex,
        TravelName: props.TravelName,
        BusType: props.BusType,
        BoardingPointsCity: `${boardingPoint.CityPointName}`,
        DepartureTime: props.DepartureTime,
        durationInMinute: props.durationInMinute,
        AvailableSeats: props.AvailableSeats,
        DroppingPointsCity: `${droppingPoint.CityPointName}`,
        ArrivalTime: props.ArrivalTime,
        IDProofRequired: props.IdProofRequired,
        boardingPoint: boardingPoint,
        droppingPoint: droppingPoint,
        RouteId: props.RouteId,
        searchParams: props.searchParams,
      };

      history.push({
        pathname: "/bus/passenger-details",
        state: params,
      });
    }
  };
  // console.log("droppingPointIdList propssssss",props)
  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item md={6}>
        <Grid container direction="row">
          <Grid item md={12}>
            {isError && (
              <Grid item md={12} xs={12}>
                <OaAlert
                  //callback={transactionHelper}
                  isPrompt={isAlertPrompt}
                  //msg={isError}
                  //title={infoText}
                  msg={alertMsg}
                  title={alertTitle}
                  isError={isError}
                  primaryButtonText={primaryButtonText}
                  secondaryButtonText={secondaryButtonText}
                  togglePrompt={() => {
                    setIsAlertPrompt(!isAlertPrompt);
                  }}
                ></OaAlert>
                {/*                         
                  // <OaFormAlerts
                  //   isError={isError}
                  //   infoText={infoText}
                  //   setIsError={setIsError}
                  // /> */}
              </Grid>
            )}
            <Grid container direction="row" spacing={2}>
              <Grid item md={6} className="start-stop-location-select">
                <Grid
                  container
                  direction="row"
                  className={classes.busSelectPoints}
                >
                  <Grid item md={12}>
                    <span className="search-result-layout-point">
                      Boarding Point
                    </span>
                  </Grid>
                  <Grid item md={12} style={{ paddingLeft: 10 }}>
                    <select
                      className="search-result-layout-point-select"
                      id="boardingPointId"
                      value={boardingPointId}
                      onChange={(event) =>
                        setBoardingPointId(event.target.value)
                      }
                    >
                      <option value={"-1"} disabled>
                        {boardingPointIdList.length > 0
                          ? "Select Boarding Point"
                          : "Loading..."}
                      </option>
                      {boardingPointIdList &&
                        boardingPointIdList.map((value, index) => {
                          return (
                            <option value={value.CityPointIndex} key={index}>
                              {value.CityPointLocation}, {value.CityPointName}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} className="start-stop-location-select">
                <Grid
                  container
                  direction="row"
                  className={classes.busSelectPoints}
                >
                  <Grid item md={12}>
                    <span className="search-result-layout-point">
                      Dropping Point
                    </span>
                  </Grid>
                  <Grid item md={12} style={{ paddingLeft: 10 }}>
                    <select
                      className="search-result-layout-point-select"
                      id="droppingPointId"
                      value={droppingPointId}
                      onChange={(event) =>
                        setDroppingPointId(event.target.value)
                      }
                    >
                      <option value={"-1"} disabled>
                        {droppingPointIdList.length > 0
                          ? "Select Dropping Point"
                          : "Loading..."}
                      </option>
                      {droppingPointIdList &&
                        droppingPointIdList.map((value, index) => (
                          <option value={value.CityPointIndex} key={index}>
                            {value.CityPointLocation}, {value.CityPointName}
                          </option>
                        ))}
                    </select>
                    {props.IsDropPointMandatory &&
                      "*Dropping point is mandatory"}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} align="center" className="seats-selected">
              <span className="search-result-layout-selected-seat-count">
                {selectedSeatIndex.length}
              </span>
              <span className="search-result-layout-selected-seat-label1">
                Seat(s) Selected {selectedSeatIndex.length > 0 ? ":" : ""}{" "}
                {selectedSeatIndex.map((e) => e.SeatName).join(",")}
              </span>
            </Grid>
            <Grid container>
              <Grid item md={2} xs={0}></Grid>
              <Grid
                item
                md={6}
                xs={12}
                style={{
                  padding: 15,
                  paddingBottom: 4,
                  background: "#eee",
                  borderRadius: 10,
                }}
              >
                {selectedSeatIndex.length > 0 && (
                  <Grid container align="center">
                    <Grid item md={6} xs={6} align="left">
                      <span className="search-result-layout-selected-seat-label">
                        Seat No
                      </span>{" "}
                    </Grid>
                    <Grid item md={6} xs={6} align="right">
                      <span className="search-result-layout-selected-seat-label">
                        Price
                      </span>
                    </Grid>
                  </Grid>
                )}
                {selectedSeatIndex.map((value, index) => {
                  return (
                    <Grid
                      container
                      align="center"
                      key={index}
                      style={{
                        borderBottom: "1px solid #fff",
                        paddingBottom: 5,
                      }}
                    >
                      <Grid item md={6} xs={6} align="left">
                        <span className="search-result-layout-selected-seat-label1">
                          {value.SeatName}{" "}
                        </span>{" "}
                      </Grid>
                      <Grid item md={6} xs={6} align="right">
                        <span
                          className="search-result-layout-selected-seat-label1"
                          style={{ fontFamily: "Roboto" }}
                        >
                          {currencyFormatter(
                            value.Price.CommissionCharge.grossProductPrice
                          )}
                        </span>
                      </Grid>
                      {/* {props.isSNF && <Grid item md={12} align="right"><span className="result-item-commission">&#x20B9;({(value.Price.CommissionCharge.grossProductPrice-value.Price.CommissionCharge.agentIncome).toFixed(2)} Net Cost + {value.Price.CommissionCharge.agentIncome} commission)</span></Grid>} */}
                    </Grid>
                  );
                })}
                <Grid
                  container
                  align="center"
                  direction="row"
                  className="search-result-div"
                >
                  <Grid
                    item
                    md={6}
                    xs={6}
                    align="left"
                    style={{ marginTop: 5 }}
                  >
                    <span className="search-result-layout-selected-seat-label">
                      Total Cost:
                    </span>
                  </Grid>
                  <Grid item md={6} xs={6} align="right">
                    <span
                      className="search-result-layout-selected-seat-price"
                      style={{ fontFamily: "Roboto" }}
                    >
                      {currencyFormatter(totalFare.toFixed(2))}
                    </span>
                  </Grid>
                  {props.isSNF && (
                    <Grid item md={12} xs={12} align="right">
                      <span className="result-item-commission">
                        (&#x20B9;
                        {selectedSeatIndex
                          .reduce(function(accumulator, currentValue) {
                            return (
                              accumulator +
                              (currentValue.Price.CommissionCharge
                                .grossProductPrice -
                                currentValue.Price.CommissionCharge.agentIncome)
                            );
                          }, 0)
                          .toFixed(2)}{" "}
                        Net Cost + &#x20B9;
                        {selectedSeatIndex
                          .reduce(function(accumulator, currentValue) {
                            return (
                              accumulator +
                              currentValue.Price.CommissionCharge.agentIncome
                            );
                          }, 0)
                          .toFixed(2)}{" "}
                        commission)
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sx={6}>
        <Grid container direction="row">
          <Grid item md={12} xs={12}>
            <span className="search-result-layout-label">Select Bus Seats</span>
          </Grid>
          <Grid item md={12} xs={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="LOWER DECK" {...a11yProps(0)} />
              <Tab label="UPPER DECK" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className="search-result-layout">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  style={{ height: `100%`, padding: 10 }}
                >
                  <Grid item xs={1}>
                    <img src={driver} width={38} height={38} />
                  </Grid>
                  {/* <Grid item xs={11}>
                                    {!isLoading  ? seatLayout.SeatDetails.map((seatDetail, key) => {
                                        return <Grid container direction="row" key={key}>
                                        {
                                            seatDetail.map((value, index) => {
                                                return <BusSeat key={index} data={value} setSelectedSeatIndex={setSelectedSeatIndex} selectedSeatIndex={selectedSeatIndex} MaxSeatsPerTicket={props.MaxSeatsPerTicket} setTotalFare={setTotalFare} totalFare={totalFare}></BusSeat>
                                                
                                            
                                            })
                                        }
                                        </Grid>
                                    }) : <LinearProgress />}
                                </Grid> */}

                  <Grid item xs={11} style={{ paddingLeft: isMobile ? 23 : 0 }}>
                    {!isLoading ? (
                      <div className="bus-seat-container">
                        {[...Array(maxRowNo + 1)].map((x, key) => (
                          <Grid
                            container
                            direction="row"
                            key={key}
                            className="sets-row"
                          >
                            {[...Array(maxColumnNo + 1)].map((y, index) => {
                              return (
                                <BusSeat
                                  key={index}
                                  data={getSeatData(key, index)}
                                  setSelectedSeatIndex={setSelectedSeatIndex}
                                  selectedSeatIndex={selectedSeatIndex}
                                  MaxSeatsPerTicket={props.MaxSeatsPerTicket}
                                  setTotalFare={setTotalFare}
                                  totalFare={totalFare}
                                />
                              );
                            })}
                          </Grid>
                        ))}
                      </div>
                    ) : (
                      <LinearProgress />
                    )}
                  </Grid>
                </Grid>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className="search-result-layout">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  style={{ height: `100%`, padding: 10 }}
                >
                  <Grid item xs={1}>
                    <img src={driver} width={38} height={38} />
                  </Grid>
                  <Grid item xs={11}>
                    {!isLoading ? (
                      <div className="bus-seat-container">
                        {[...Array(maxRowNo + 1)].map((x, key) => (
                          <Grid
                            container
                            direction="row"
                            key={key}
                            className="sets-row"
                          >
                            {[...Array(maxColumnNo + 1)].map((y, index) => {
                              return (
                                <BusSeat
                                  key={index}
                                  data={getUpperSeatData(key, index)}
                                  setSelectedSeatIndex={setSelectedSeatIndex}
                                  selectedSeatIndex={selectedSeatIndex}
                                  MaxSeatsPerTicket={props.MaxSeatsPerTicket}
                                  setTotalFare={setTotalFare}
                                  totalFare={totalFare}
                                ></BusSeat>
                              );
                            })}
                          </Grid>
                        ))}
                      </div>
                    ) : (
                      <LinearProgress />
                    )}
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid
              container
              direction="row"
              style={{ marginTop: 10, marginBottom: 6 }}
              spacing={2}
              xs={12}
              justifyContent="flex-end"
            >
              <Grid item>
                <Grid container direction="row">
                  <Grid item md={12}>
                    <img src={available} width={33} height={33} />
                    <Typography style={{ fontSize: 10 }}>Available</Typography>
                  </Grid>
                  {/* <Grid item md={12}>
                    <span className="search-result-layout-chair-label">
                      Available
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <Grid item md={12}>
                    <img src={booked} width={33} height={33} />
                    <Typography style={{ fontSize: 10 }}>Booked</Typography>
                  </Grid>
                  {/* <Grid item md={12}>
                    <span className="search-result-layout-chair-label">
                      Booked
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <Grid item md={12}>
                    <img src={selected} width={33} height={33} />
                    <Typography style={{ fontSize: 10 }}>Selected</Typography>
                  </Grid>
                  {/* <Grid item md={12}>
                    <span className="search-result-layout-chair-label">
                      Selected
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <Grid item md={12}>
                    <img src={ladies} width={33} height={33} />
                    <Typography style={{ fontSize: 10 }}>Ladies</Typography>
                  </Grid>
                  {/* <Grid item md={12}>
                    <span className="search-result-layout-chair-label">
                      Ladies
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    <img src={gents} width={33} height={33} />
                    <Typography style={{ fontSize: 10 }}>Gents</Typography>
                  </Grid>
                  {/* <Grid item md={12}>
                    <span className="search-result-layout-chair-label">
                      Gents
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              className="result-item-select-button"
              align="right"
              onClick={() => bookOnClick()}
              mt={2}
            >
              Book Seats
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusSeatLayout;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box
        p={1}
        style={{
          width: "100%",
          boxSizing: "border-box",
          // overflowX:'scroll',
        }}
      >
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .seats-selected": {
      marginTop: "6%",
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    "& .search-result-div": {
      [theme.breakpoints.down("sm")]: {
        // padding:'0 10px',
        marginBottom: 10,
      },
    },
    "& .search-result-layout-point": {
      margin: "0 9px",
    },
    "& .search-result-layout": {
      width: "100%",
      boxSizing: "border-box",
      overflowX: "scroll",
      // [theme.breakpoints.down("sm")]:{
      //   paddingLeft:'16px',

      // }
    },

    "& .bus-seat-container": {
      // width:"160%",
      // display:'flex',
      // overflowX:'scroll',
    },
    "& .sets-row": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "160%",
        overflowX: "auto",
      },

      // display:'flex',
      //  flexDirection:'column',
    },
    "& .start-stop-location-select": {
      [theme.breakpoints.down(768)]: {
        margin: "3px 0",
        padding: 0,
      },
    },
  },
  busSelectPoints: {
    height: 50,
    [theme.breakpoints.down(760)]: {
      height: 20,
    },
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      height: 28,
    },
  },
}));
