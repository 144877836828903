import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Seats from "./seats/Seats";
import Baggage from "./baggage/Baggage";
import Meals from "assets/icons/Meal.png";
import Baggages from "assets/icons/baggages.png";
import Seat from "assets/icons/seat.png";
import Meal from "./meal/Meal";
import Carousel from "react-elastic-carousel";
import { makeStyles } from "@material-ui/core/styles";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import {
  AccessTimeOutlined,
  AirlineSeatReclineExtra,
} from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {
        <Box style={{ visibility: value === index ? "visible" : "hidden" }}>
          {children}
        </Box>
      }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SeatMealBag = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tripModeValue, setTripModeValue] = React.useState(0);
  const [currentSector, setCurrentSector] = React.useState(0);

  const [onwSegmentLen, setOnwSegmentLen] = React.useState(
    history.location?.state?.onwardResp.segment.length
  );
  const [retSegmentLen, setRetSegmentLen] = React.useState(0);
  const tripMode = history.location?.state?.requestObj?.mode;
  const servLimit =
    history.location?.state?.requestObj?.adultPax +
    history.location?.state?.requestObj?.childPax;
  // console.log("pax req",history.location?.state?.requestObj);
  const [onwardSeat, setOnwardSeat] = React.useState(
    history.location?.state?.onwardResp?.additionalServices?.seatMapping
  );
  const [returnSeat, setReturnSeat] = React.useState(null);
  const [additionals, setAdditionals] = React.useState([]);

  const handleChange = (event, newValue) => {
    //console.log("newValue ", newValue);
    setValue(newValue);
    setCurrentSector(0);
  };

  const handleTripmodeChange = (event, newValue) => {
    setTripModeValue(newValue);
    setValue(0);
    setCurrentSector(0);
  };

  const handleCurrentSectorChange = (event, newValue) => {
    setCurrentSector(newValue);
  };

  const checkAdditionals = (mode) => {
    if (
      mode == "onward" &&
      (history.location?.state?.onwardResp?.additionalServices?.seatMapping
        ?.length > 0 ||
        history.location?.state?.onwardResp?.additionalServices?.meal?.length >
        1 ||
        history.location?.state?.onwardResp?.additionalServices?.baggage
          ?.length > 1)
    ) {
      return true;
    }

    if (
      mode == "return" &&
      (history.location?.state?.returnResp?.additionalServices?.seatMapping
        ?.length > 0 ||
        history.location?.state?.returnResp?.additionalServices?.meal?.length >
        1 ||
        history.location?.state?.returnResp?.additionalServices?.baggage
          ?.length > 1)
    ) {
      return true;
    }

    return false;
  };

  const initAdditionalServices = () => {
    let adds = [];

    if (
      history.location.state.onwardResp.additionalServices &&
      checkAdditionals("onward")
    ) {
      let onwardAdds = [];
      if (
        history.location?.state?.onwardResp?.additionalServices?.seatMapping
          ?.length > 2
      ) {
        let segments = [];

        history.location?.state?.onwardResp?.segment &&
          history.location.state.onwardResp.segment.map((segVal, segIdx) => {
            // console.log("segVal", segVal);
            // console.log("segVal == ", history.location?.state?.onwardResp?.segment);
            segments.push({
              // depApt: segVal.departDetails.airportName,
              // arrApt: segVal.arriveDetails.airportName,
              // depAptCode: segVal.departDetails.airportCode,
              // arrAptCode: segVal.arriveDetails.airportCode,

              depAptCode:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportCode,
              arrAptCode:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportCode,
              depApt:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportName,
              arrApt:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportName,
              contents:
                history.location.state.onwardResp.additionalServices
                  .seatMapping,
            });
          });

        onwardAdds.push({
          label: "Seats",
          data: segments,
          isReturn: false,
        });
      }

      if (
        history.location?.state?.onwardResp?.additionalServices?.meal?.length >
        1
      ) {
        let segments = [];

        history.location?.state?.onwardResp?.segment &&
          history.location.state.onwardResp.segment.map((segVal, segIdx) => {
            //  console.log("segVal", segVal);
            segments.push({
              // depApt: segVal.departDetails.airportName,
              // arrApt: segVal.arriveDetails.airportName,
              // depAptCode: segVal.departDetails.airportCode,
              // arrAptCode: segVal.arriveDetails.airportCode,
              depAptCode:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportCode,
              arrAptCode:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportCode,
              depApt:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportName,
              arrApt:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportName,
              contents:
                history.location.state.onwardResp.additionalServices.meal,
            });
          });

        onwardAdds.push({
          label: "Meal",
          data: segments,
          isReturn: false,
        });
      }
      if (
        history.location?.state?.onwardResp?.additionalServices?.baggage
          ?.length > 1
      ) {
        let segments = [];

        // history.location?.state?.onwardResp?.segment &&
        //   history.location.state.onwardResp.segment.map((segVal, segIdx) => {
        //     console.log("segVal", segVal);
        //     segments.push({
        //       depApt: segVal.departDetails.airportName,
        //       arrApt: segVal.arriveDetails.airportName,
        //       depAptCode: segVal.departDetails.airportCode,
        //       arrAptCode: segVal.arriveDetails.airportCode,

        //       contents:
        //         history.location.state.onwardResp.additionalServices.meal,
        //     });
        //   });
        // onwardAdds.push({
        //   label: "Baggage",
        //   data: segments,
        //   isReturn: false,
        // });
        onwardAdds.push({
          label: "Baggage",
          data: [
            {
              depAptCode:
                history.location.state.onwardResp.segment[0].departDetails
                  .airportCode,
              arrAptCode:
                history.location.state.onwardResp.segment[
                  history.location.state.onwardResp.segment.length - 1
                ].arriveDetails.airportCode,
              depApt:
                history.location.state.onwardResp.segment[0].departDetails
                  .airportName,
              arrApt:
                history.location.state.onwardResp.segment[
                  history.location.state.onwardResp.segment.length - 1
                ].arriveDetails.airportName,
              contents:
                history.location.state.onwardResp.additionalServices.baggage,
            },
          ],
          isReturn: false,
        });
      }

      adds.push({
        tripMode: "ONE",
        label: "Onward",
        data: onwardAdds,
      });
    }

    if (
      (tripMode == "ROUND" || tripMode == "ROUND-SP") &&
      history?.location?.state?.returnResp?.additionalServices &&
      checkAdditionals("return")
    ) {
      setRetSegmentLen(history.location?.state?.returnResp.segment.length);
      setReturnSeat(
        history.location?.state?.returnResp?.additionalServices?.seatMapping
      );

      let returnAdds = [];

      if (
        history?.location?.state?.returnResp?.additionalServices?.seatMapping
          ?.length > 0
      ) {
        let segments = [];

        history.location?.state?.returnResp?.segment &&
          history.location.state.returnResp.segment.map((segVal, segIdx) => {
            segments.push({
              // depApt: segVal.departDetails.airportName,
              // arrApt: segVal.arriveDetails.airportName,
              // depAptCode: segVal.departDetails.airportCode,
              // arrAptCode: segVal.arriveDetails.airportCode,
              depAptCode:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportCode,
              arrAptCode:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportCode,
              depApt:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportName,
              arrApt:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportName,
              contents:
                history.location.state.returnResp.additionalServices
                  .seatMapping,
            });
          });

        returnAdds.push({
          label: "Seats",
          data: segments,
          isReturn: true,
        });
      }

      if (
        history?.location?.state?.returnResp?.additionalServices?.meal?.length >
        1
      ) {
        let segments = [];

        history.location?.state?.returnResp?.segment &&
          history.location.state.returnResp.segment.map((segVal, segIdx) => {
            segments.push({
              // depApt: segVal.departDetails.airportName,
              // arrApt: segVal.arriveDetails.airportName,
              // depAptCode: segVal.departDetails.airportCode,
              // arrAptCode: segVal.arriveDetails.airportCode,
              depAptCode:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportCode,
              arrAptCode:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportCode,
              depApt:
                history.location.state.onwardResp.segment[segIdx].departDetails
                  .airportName,
              arrApt:
                history.location.state.onwardResp.segment[segIdx].arriveDetails
                  .airportName,
              contents:
                history.location.state.returnResp.additionalServices.meal,
            });
          });

        returnAdds.push({
          label: "Meal",
          data: segments,
          isReturn: true,
        });
      }

      if (
        history?.location?.state?.returnResp?.additionalServices?.baggage
          ?.length > 1
      ) {
        returnAdds.push({
          label: "Baggage",
          data: [
            {
              depAptCode:
                history.location.state.returnResp.segment[0].departDetails
                  .airportCode,
              arrAptCode:
                history.location.state.returnResp.segment[
                  history.location.state.returnResp.segment.length - 1
                ].arriveDetails.airportCode,
              depApt:
                history.location.state.returnResp.segment[0].departDetails
                  .airportName,
              arrApt:
                history.location.state.returnResp.segment[
                  history.location.state.returnResp.segment.length - 1
                ].arriveDetails.airportName,
              contents:
                history.location.state.returnResp.additionalServices.baggage,
            },
          ],
          isReturn: true,
        });
      }

      adds.push({
        tripMode: "ROUND",
        label: "Return",
        data: returnAdds,
      });
    }

    setAdditionals(adds);
  };

  React.useEffect(() => {
    initAdditionalServices();
  }, []);
  // console.log(additionals);

  return (
    additionals && (
      <div className={classes.root}>
        {/* {additionals?.length > 0 && additionals.map((val, index) =>
                <div className="section-title" key={index}>
                    <Typography className="heading">Seats Meal and Baggage</Typography>
                </div>
            )}     */}

        {additionals?.length > 0 &&
          additionals.map(
            (val, index) =>
              index === tripModeValue && (
                <div className="SeatMealBaggagetabsContainer">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    className="as-tabs"
                  >
                    {val.data.map((addVal, indx) => (
                      <Tab
                        label={addVal.label}
                        icon={
                          <img
                            src={
                              addVal.label === "Seats"
                                ? Seat
                                : addVal.label === "Meal"
                                  ? Meals
                                  : Baggages
                            }
                            className="addOnIcon"
                          />
                        }
                        key={indx}
                        {...a11yProps(indx)}
                      />
                    ))}
                  </Tabs>
                  <Tabs
                    value={tripModeValue}
                    onChange={handleTripmodeChange}
                    aria-label="tripmode tabs"
                    className="tripmode-tabs"
                  >
                    {additionals?.length > 0 &&
                      additionals.map((val, index) => (
                        <Tab
                          label={val.label}
                          {...a11yProps(index)}
                          key={index}
                        />
                      ))}
                  </Tabs>
                  {val.data.map((addVal, indx) => (
                    <>
                      <Tabs
                        value={currentSector}
                        onChange={handleCurrentSectorChange}
                        aria-label="sector tabs"
                        className="sec-tabs"
                      >
                        {indx === value &&
                          addVal?.data?.map((elm, idx) => (
                            <Tab
                              label={elm.depApt + " - " + elm.arrApt}
                              key={idx}
                              {...a11yProps(idx)}
                            />
                          ))}
                        {/* {addVal?.data?.map((elm, idx) => (
                          <Tab
                            label={elm.depApt + " - " + elm.arrApt}
                            key={idx}
                            {...a11yProps(idx)}
                          />
                        ))} */}
                      </Tabs>
                    </>
                  ))}

                  <TabPanel
                    value={tripModeValue}
                    index={index}
                    key={index}
                    className="tripmode-tabpanel"
                  >
                    <AppBar
                      position="sticky"
                      style={{
                        background: "#fff",
                        width: "100%",
                        minHeight: 415,
                        boxShadow: "none",
                        color: "#000",
                        zIndex: 0,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {val.data.map((addVal, indx) => (
                        <>
                          <TabPanel
                            value={value}
                            index={indx}
                            key={indx}
                            className="additionals-tabpanel"
                          >
                            <div>
                              <div className="slider__container">
                                {addVal?.data?.map((elm, idx) => (
                                  <TabPanel
                                    value={currentSector}
                                    index={idx}
                                    key={idx}
                                    className="sector-tabpanel"
                                  >
                                    {addVal.label == "Seats" &&
                                      elm?.contents && (
                                        <Seats
                                          {...props}
                                          data={elm.contents}
                                          segidx={idx}
                                          servlimit={servLimit}
                                          isreturn={addVal.isReturn}
                                        />
                                      )}

                                    {addVal.label == "Meal" &&
                                      elm?.contents && (
                                        <Meal
                                          {...props}
                                          data={elm?.contents}
                                          servlimit={servLimit}
                                          segidx={idx}
                                          isreturn={addVal.isReturn}
                                        />
                                      )}

                                    {addVal.label == "Baggage" &&
                                      elm?.contents && (
                                        <Baggage
                                          {...props}
                                          data={elm?.contents}
                                          servlimit={servLimit}
                                          segidx={idx}
                                          isreturn={addVal.isReturn}
                                        />
                                      )}
                                  </TabPanel>
                                ))}
                              </div>
                            </div>
                          </TabPanel>
                        </>
                      ))}
                    </AppBar>
                  </TabPanel>
                </div>
              )
          )}
      </div>
    )
  );
};

export default SeatMealBag;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .arrive": {
      margin: "0",
      marginLeft: "0%",
      marginTop: "3%",
      display: "inline-block",
      position: "relative",
      borderLeft: "6px solid green",
      paddingLeft: "6px",
      fontSize: "1.3rem",
      color: "black",
    },
    "& .arrive span": {
      display: "inline-block",
      margin: "0 3px",
    },
    "& .slider__item": {
      width: "100%",
    },
    "& .SeatMealBaggagetabsContainer": {
      "& .slider__container": {
        [theme.breakpoints.down(700)]: {
          marginTop: 65,
        },
      },
      position: "relative",
      "& .addOnIcon": {
        height: 20,
        width: 20,
        marginRight: 10,
      },
      "& .MuiTab-wrapper": {
        fontWeight: 700,
      },
    },
    "& .tripmode-tabs": {
      minHeight: 35,
      position: "absolute",
      top: 10,
      [theme.breakpoints.down(700)]: {
        top: 50,
        left: 0,
        zIndex: 20,
      },
      right: 0,
      "& .MuiTabs-root": {},
      "& .MuiTabs-scroller": {
        height: 30,
      },
      "& .MuiTab-root": {
        background: theme.palette.buttons.disabled,
        color: theme.palette.primary.lightText,
        borderRadius: 20,
        height: 30,
        marginRight: 5,
        minWidth: 100,
        width: 100,
        minHeight: "auto",
        "&.Mui-selected": {
          background: theme.palette.primary.main,
          color: theme.palette.primary.lightText,
        },
      },
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    "& .as-tabs": {
      width: "100%",
      borderBottom: "1px solid #eee",
      marginBottom: 5,
      "& .MuiTab-root": {
        minWidth: 100,
        // background: "red",
      },
      "& .MuiTabs-indicator": {
        background: theme.palette.primary.main,
      },
      "& .MuiTab-wrapper": {
        flexDirection: "row",
      },
      "& .MuiTab-labelIcon": {
        minHeight: 32,
      },
    },
    "& .sec-tabs": {
      position: "absolute",
      top: 52,
      zIndex: 5,
      left: 0,
      minHeight: 35,
      [theme.breakpoints.down(700)]: {
        top: 85,
      },
      "& .MuiTab-wrapper": {
        marginTop: 1,
      },

      "& .Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        borderRadius: 20,
        color: "#fff",
      },
      "& .MuiTabs-indicator": {
        // display: "none",
        background: "transparent",
        // background: theme.palette.secondary.main,
      },
      "& .MuiTab-root": {
        // background: "red",
        // height: 45,

        fontSize: 12,
        borderRadius: 20,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 30,
        height: 30,
        minWidth: 100,
        backgroundColor: "#ddd",
        fontWeight: 500,
        marginRight: 10,
        // width: 150,
      },
      "& .MuiTabs-scroller": {
        // backgroundColor: "green",
        // borderRadius: 20,
        minHeight: 30,
        height: 30,
      },
    },
    "& .plane": {
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#b1b1b1",
        // background: theme.palette.primary.active,
        borderRadius: 8,
        opacity: "0.5",
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: "#b1b1b1",
          // background: theme.palette.background.rgba_primary_active,
        },
      },
    },
    "& .additionals-tabpanel": {
      // minHeight: "30vh",
      width: "100%",
    },

    "& .rec-carousel": {
      position: "relative",
      padding: "0 30px",
      [theme.breakpoints.down(992)]: {
        padding: "0 5px",
      },
    },
    "& .rec-arrow": {
      position: "absolute",
      zIndex: 1,
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
      "&:hover": {
        background: "none !important",
        color: theme.palette.buttons.secondary,
      },
      "&.rec-arrow-left": {
        left: -20,
      },
      "&.rec-arrow-right": {
        right: -20,
      },
    },
  },
}));
