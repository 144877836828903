import React from "react";
import {
  Grid,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip,
  Modal,
  IconButton,
  Collapse,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import dateFnsFormat from "date-fns/format";
import OaFormAlerts from "pages/components/OaFormAlerts";
import clsx from "clsx";
import { useHistory, withRouter } from "react-router-dom";
import {
  AirlineSeatReclineNormalRounded,
  CloseOutlined,
} from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import PropTypes from "prop-types";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import { AppConstant } from "appConstant";
import OaNetFare from "pages/components/OaNetFare";
import FlightDetailsCardMobile from "./view/FlightDetailsCardMobile";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
// import demoReprice from "pages/module/flight/JSON_SAMPLE_RESPONSES/international/int_flight_oneway_repricing.json";
import OaAlert from "oahoc/OaAlert";

const NonMUIIntFlightCard = (props) => {
  // console.log(props.flightData.fares[0]);
  const width = window.innerWidth;
  const breakpoint = 980;
  const [openDiv, setOpenDiv] = React.useState({
    openOnwardDetails: true,
    openReturnDetails: true,
    openFareDetails: true,
    openBaggageDetails: true,
  });
  const [showMoreFares, setShowMoreFares] = React.useState(false);
  const [moreDetailsOpen, setMoreDetailsOpen] = React.useState(false);
  const [shareOnwOpen, setShareOnwOpen] = React.useState(false);
  const [moreDetailNavState, setMoreDetailNavState] = React.useState(0);
  const [selectedFareId, setSelectedFareId] = React.useState(null);
  const [selectedFareDetail, setSelectedFareDetail] = React.useState(
    props.flightData.fares[0]
  );
  const [fareRules, setFareRules] = React.useState(
    props.flightData.fares[0].rule
  );

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  const [journeyHoursOnw, setJourneyHoursOnw] = React.useState(0);
  const [journeyMinsOnw, setJourneyMinsOnw] = React.useState(0);

  const [journeyHoursRet, setJourneyHoursRet] = React.useState(0);
  const [journeyMinsRet, setJourneyMinsRet] = React.useState(0);

  const useStyles = getStyles(props.tripMode, showMoreFares, props.showNetFare);

  const classes = useStyles();
  const history = useHistory();
  const radioRef = React.useRef();
  const theme = useTheme();
  const isMdSmDevice = useMediaQuery(theme.breakpoints.down(960));

  const moreActionDiv = React.useRef();
  const carrierDetailsDiv = React.useRef();
  const carrierDetailsDivRet = React.useRef();
  const pdInitHt = props.tripMode === "ONE" ? 92 : 92;

  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  const sessionId = props?.sessionid;

  const segmentsOnw = props.flightData.onwardResp;
  const fareDetails = props.flightData.fares;
  const stopsOnw = segmentsOnw.length - 1;

  let segmentsRet = props.flightData.returnResp;
  let stopsRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? segmentsRet.length - 1
      : 0;

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const flightCodeOnw = segmentsOnw[0].carrierDetails.carrierCode;
  const airlineCodeOnw =
    flightCodeOnw + " " + segmentsOnw[0].carrierDetails.flightNumber;
  const airlineNameOnw = segmentsOnw[0].carrierDetails.carrierName;

  const flightCodeRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? segmentsRet[0].carrierDetails.carrierCode
      : "";
  const airlineCodeRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? flightCodeRet + " " + segmentsRet[0].carrierDetails.flightNumber
      : "";
  const airlineNameRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? segmentsRet[0].carrierDetails.carrierName
      : "";

  const depDateRawOnw = new Date(segmentsOnw[0].departDetails.dateTime);
  const departDayOnw = days[depDateRawOnw.getDay()];
  const departDateOnw = dateFnsFormat(depDateRawOnw, "dd MMM");
  const departTimeOnw = dateFnsFormat(depDateRawOnw, "HH:mm");
  const departAirportOnw = segmentsOnw[0].departDetails.airportCode;

  const lsOnw = segmentsOnw.length - 1;

  const arrDateRawOnw = new Date(segmentsOnw[lsOnw].arriveDetails.dateTime);
  const arrivalDayOnw = days[arrDateRawOnw.getDay()];
  const arrivalDateOnw = dateFnsFormat(arrDateRawOnw, "dd MMM");
  const arrivalTimeOnw = dateFnsFormat(arrDateRawOnw, "HH:mm");
  const arrivalAirportOnw = segmentsOnw[lsOnw].arriveDetails.airportCode;

  const depDateRawRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? new Date(segmentsRet[0].departDetails.dateTime)
      : new Date();
  const departDayRet = days[depDateRawRet.getDay()];
  const departDateRet = dateFnsFormat(depDateRawRet, "dd MMM");
  const departTimeRet = dateFnsFormat(depDateRawRet, "HH:mm");
  const departAirportRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? segmentsRet[0].departDetails.airportCode
      : "";

  const lsRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? segmentsRet.length - 1
      : 0;

  const arrDateRawRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? new Date(segmentsRet[lsRet].arriveDetails.dateTime)
      : new Date();
  const arrivalDayRet = days[arrDateRawRet.getDay()];
  const arrivalDateRet = dateFnsFormat(arrDateRawRet, "dd MMM");
  const arrivalTimeRet = dateFnsFormat(arrDateRawRet, "HH:mm");
  const arrivalAirportRet =
    props.tripMode === "ROUND" && segmentsRet != null
      ? segmentsRet[lsRet].arriveDetails.airportCode
      : "";

  let shareText = "";

  let stopAirportsOnw = "";
  let stopAirportsRet = "";

  let journeyTimeOnw = 0;
  let journeyTimeRet = 0;

  const getFlightFareRules = () => {
    props.isloading(true);
    const params = {
      onwardFareId: selectedFareDetail.fareId,
      returnFareId: props.tripMode === "ROUND" ? selectedFareDetail.fareId : "",
      sessionId: sessionId,
    };
    setIsLoading(true);
    WebApi.getFlightFareRules(
      params,
      (response) => {
        if (response.isSuccess) {
          setFareRules(response?.combineResp[0]?.fares[0].rule);
          setMoreDetailsOpen(true);

          props.isloading(false);
          setIsLoading(false);
        } else {
          props.isloading(false);
          setIsLoading(false);
        }
      },
      (error) => {
        console.log("fare rules error", error);
        props.isloading(false);
      }
    );
  };

  const toggleShowMoreFares = (showState) => {
    setShowMoreFares(showState);
  };

  const toggleMoreDetails = (event, moreDetailsOpenState) => {
    selectedFareDetail && setSelectedFareId(selectedFareDetail.fareId);

    if (moreDetailsOpenState) {
      getFlightFareRules();
      isMdSmDevice && setMoreDetailsOpen(isMdSmDevice);
    } else {
      setMoreDetailsOpen(moreDetailsOpenState);
    }
  };

  const toggleFareDetails = () => {
    const params = {
      onwardFareId: selectedFareDetail.fareId,
      returnFareId: props.tripMode === "ROUND" ? selectedFareDetail.fareId : "",
      sessionId: sessionId,
    };

    // WebApi.getFlightFareRules(
    //   params,
    //   (response) => {
    //     if (response.isSuccess) {
    //       setFareRules(response?.combineResp[0]?.fares[0].rule);
    //       setIsLoading(false);
    //     } else {
    //       setIsLoading(false);
    //     }
    //   },
    //   (error) => console.log("internal error", error)
    // );
  };

  const shareViaWhatsapp = () => {
    const anch = document.createElement("a");
    anch.href = "https://api.whatsapp.com/send?text=" + shareText;
    anch.dataAction = "share/whatsapp/share";
    anch.target = "_blank";
    anch.click();
  };

  const shareViaEmail = () => {
    const anch = document.createElement("a");
    anch.href =
      "mailto:?subject=Flight Details " +
      departAirportOnw +
      " to " +
      arrivalAirportOnw +
      " " +
      departDateOnw +
      "&body=" +
      shareText;
    anch.target = "_blank";
    anch.click();
  };

  const bookFlight = (repricingResponse) => {
    const paxInfo = {
      adultPax: [],
      childPax: [],
      infantPax: [],
      country_dial_code: "+91",
      phoneno: "",
      emailUser: "",
      gstCompany: "",
      gstCompanyAddress: "",
      gstCompanyNumber: "",
      gstEmail: "",
      gstNumber: "",
      discontCoupon: "",
      discontCouponAmount: 0,
      hasGst: false,
      isDomestic: props?.request?.isDomestic,
      adultCount: props?.request?.adultPax,
      childCount: props?.request?.childPax,
      infantCount: props?.request?.infantPax,
      seatStatus: [],
      mealStatus: [],
      baggStatus: [],
      seatStatusRt: [],
      mealStatusRt: [],
      baggStatusRt: [],
      additionalFare: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
      additionalFareRt: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
    };

    repricingResponse.combineResp[0].onwardResp.map((val, idx) => {
      paxInfo.seatStatus.push({ seatsFull: false });
      paxInfo.mealStatus.push({ mealsFull: false });
      idx == 0 && paxInfo.baggStatus.push({ baggsFull: false });
    });

    props.tripMode === "ROUND" &&
      repricingResponse.combineResp[0].returnResp.map((val, idx) => {
        paxInfo.seatStatusRt.push({ seatsFull: false });
        paxInfo.mealStatusRt.push({ mealsFull: false });
        idx == 0 && paxInfo.baggStatusRt.push({ baggsFull: false });
      });

    for (var i = 0; i < props.request.adultPax; i++) {
      const additionalOne = [];
      repricingResponse.combineResp[0].onwardResp &&
        repricingResponse.combineResp[0].onwardResp.map((val, idx) => {
          additionalOne.push({
            frequentAirline: val.carrierDetails.carrierCode,
            frequentAirlineName: val.carrierDetails.carrierName,
            airlineNo: val.carrierDetails.flightNumber,
            frequentFlyerNo: "",
            meal: null,
            baggage: null,
            seat: null,
          });
        });

      const additionalRet = [];
      props.tripMode === "ROUND" &&
        repricingResponse.combineResp[0].returnResp &&
        repricingResponse.combineResp[0].returnResp.map((val, idx) => {
          additionalRet.push({
            frequentAirline: val.carrierDetails.carrierCode,
            frequentAirlineName: val.carrierDetails.carrierName,
            airlineNo: val.carrierDetails.flightNumber,
            frequentFlyerNo: "",
            meal: null,
            baggage: null,
            seat: null,
          });
        });

      paxInfo.adultPax.push({
        initial: null,
        firstname: "",
        surname: "",
        visatype: "",
        passportno: "",
        passportidate: null,
        passportexpire: null,
        passporticountry: "",
        passporticobj: { name: "India", dial_code: "+91", code: "IN" },
        dob: "",
        dobDisplay: null,
        paxSave: false,
        additionalOnward: additionalOne,
        additionalReturn: additionalRet,
      });
    }

    if (props.request.childPax > 0) {
      for (var i = 0; i < props.request.childPax; i++) {
        const additionalOne = [];
        repricingResponse.combineResp[0].onwardResp &&
          repricingResponse.combineResp[0].onwardResp.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        const additionalRet = [];
        props.tripMode === "ROUND" &&
          repricingResponse.combineResp[0].returnResp &&
          repricingResponse.combineResp[0].returnResp.map((val, idx) => {
            additionalRet.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.childPax.push({
          initial: null,
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: additionalRet,
        });
      }
    }

    if (props.request.infantPax > 0) {
      for (var i = 0; i < props.request.infantPax; i++) {
        const additionalOne = [];
        repricingResponse.combineResp[0].onwardResp &&
          repricingResponse.combineResp[0].onwardResp.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        const additionalRet = [];
        props.tripMode === "ROUND" &&
          repricingResponse.combineResp[0].returnResp &&
          repricingResponse.combineResp[0].returnResp.map((val, idx) => {
            additionalRet.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.infantPax.push({
          initial: null,
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: additionalRet,
        });
      }
    }

    const onwResp = {
      segment: repricingResponse?.combineResp[0]?.onwardResp,
      fares: repricingResponse?.combineResp[0]?.fares,
      additionalServices:
        repricingResponse?.combineResp[0]?.owAdditionalServices,
      journeyTime: parseInt(
        repricingResponse?.combineResp[0]?.onwardJourneyTime
      ),
    };

    const retResp = {
      segment: repricingResponse?.combineResp[0]?.returnResp,
      fares: [],
      additionalServices:
        repricingResponse?.combineResp[0]?.rtAdditionalServices,
      journeyTime: parseInt(
        repricingResponse?.combineResp[0]?.returnJourneyTime
      ),
    };

    history.push({
      pathname: "/flight/booking",
      state: {
        request: repricingResponse?.request,
        requestObj: props?.history?.location?.state?.requestObj,
        onwardResp: onwResp,
        returnResp: retResp,
        oneSelectedFareId: selectedFareId,
        retSelectedFareId: null,
        sessionId: repricingResponse?.sessionId,
        availSessionId: sessionId,
        paxInfo: paxInfo,
        couponObj: { voucher_code: "", voucher_value: 0, description: "" },
        totalPayable: 0,
      },
    });
  };

  const rePrice = () => {
    let defaultSelectedFareId = selectedFareId
      ? selectedFareId
      : props.flightData.fares[0].fareId;

    // console.log(defaultSelectedFareId);

    if (defaultSelectedFareId != null) {
      const params = {
        onwardFareId: defaultSelectedFareId,
        returnFareId: "",
        sessionId: sessionId,
      };

      props.isloading(true);

      WebApi.getFlightRepricing(
        params,
        (response) => {
          setIsLoading(false);
          props.isloading(false);

          if (response.isSuccess === true) {
            if (response.error == "Fare difference") {
              window.confirm("fares have changed, do you want to continue?") &&
                bookFlight(response);
            } else {
              bookFlight(response);
            }
          } else {
            //setIsError(!response.isSuccess);
            // setInfoText("Error on repricing! please retry booking.");
            // alert("Error on repricing! please choose another flight.");
            setIsError(true);
            setPrimaryButtonText("OK");
            setIsAlertPrompt(true);
            setAlertMsg(
              "This fare is not available. Please choose another flight!"
            );
            setAlertTitle("Fare not available");
          }
        },
        (error) => console.log("error", error)
      );
    } else {
      setInfoText("Please select fare");
      setIsError(true);
    }
  };

  const onClickFareId = (event) => {
    setSelectedFareId(event.target.value);
  };

  React.useEffect(() => {
    if (selectedFareId) {
      setSelectedFareDetail(
        fareDetails.filter((v) => {
          return v.fareId == selectedFareId;
        })[0]
      );
      props.setselectdfareid(selectedFareId);
      // toggleFareDetails();
    }
  }, [selectedFareId]);

  React.useEffect(() => {
    if (props.flightData.fares) {
      setSelectedFareId(props.flightData.fares[0].fareId);
    }
  }, [props.flightData]);

  React.useEffect(() => {
    if (props.flightData) {
      journeyTimeOnw = parseInt(props.flightData.onwardJourneyTime);
      // console.log("journey time", journeyTimeOnw);

      segmentsOnw &&
        segmentsOnw.map((value, index) => {
          // journeyTimeOnw += parseInt(value.flightTime) + parseInt(value.layover);
          if (index > 0) {
            const sep = segmentsOnw.length - 1 === index ? "" : ", ";
            stopAirportsOnw += value.departDetails.airportCode + sep;
          }
          // console.log(stopAirportsOnw);
          // stopsOnw > 0 && console.log("stopAirportsOnw",stopAirportsOnw);
        });

      setJourneyHoursOnw(Math.floor(journeyTimeOnw / 60));
      setJourneyMinsOnw(Math.floor(journeyTimeOnw % 60));

      if (props.tripMode === "ROUND") {
        journeyTimeRet = parseInt(props.flightData.returnJourneyTime);
        segmentsRet &&
          segmentsRet.map((value, index) => {
            // journeyTimeRet += parseInt(value.flightTime) + parseInt(value.layover);

            if (index > 0) {
              const sep = segmentsRet.length - 1 === index ? "" : ", ";
              stopAirportsRet += value.departDetails.airportCode + sep;
            }
          });

        setJourneyHoursRet(Math.floor(journeyTimeRet / 60));
        setJourneyMinsRet(Math.floor(journeyTimeRet % 60));
      }
    }
  }, [props.flightData]);

  const FareDetailComponent = () => {
    return (
      <TreeView
        className={classes.FareBreakupTreeView}
        defaultExpanded={["3"]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 4 }} />}
      >
        <StyledTreeItem
          nodeId="1"
          labelText="Base Fare"
          labelInfo={"₹ " + selectedFareDetail.baseFare}
        />
        <StyledTreeItem
          nodeId="2"
          labelText="Airline Tax and Fee"
          labelInfo={"₹ " + selectedFareDetail.tax}
        >
          {/* <StyledTreeItem
        nodeId="3"
        labelText="Airline GST"
        labelInfo={"₹ " + fareDetails.gstOnCommission}
      />
      <StyledTreeItem
        nodeId="4"
        labelText="Management Fee"
        labelInfo="₹ 61"
      />
      <StyledTreeItem
        nodeId="5"
        labelText="Other Taxes"
        labelInfo="₹ 61"
      /> */}
        </StyledTreeItem>
        <StyledTreeItem
          nodeId="2"
          labelText="Convenience Fee"
          labelInfo={"₹ " + selectedFareDetail.tmarkup}
        ></StyledTreeItem>
        {/* <StyledTreeItem
      nodeId="6"
      labelText="Total"
      labelInfo="₹ 5,724"
    >
      <StyledTreeItem
        nodeId="7"
        labelText="Commission"
        labelInfo="₹ 54"
      />
      <StyledTreeItem
        nodeId="8"
        labelText="Markup"
        labelInfo="₹ 54"
      />
      <StyledTreeItem
        nodeId="9"
        labelText="TDS"
        labelInfo="₹ 54"
      />
      <StyledTreeItem
        nodeId="10"
        labelText="Net Price"
        labelInfo="₹ 54"
      />
    </StyledTreeItem> */}
        <StyledTreeItem
          nodeId="11"
          labelText="Grand Total"
          labelInfo={`₹ ${selectedFareDetail.baseFare +
            selectedFareDetail.tax +
            selectedFareDetail.tmarkup}`}
        />
      </TreeView>
    );
  };

  const BaggageComponent = ({ value }) => {
    return (
      <div className={classes.baggageDetailsElement}>
        <div className={clsx(classes.baggageLocation, classes.f12b)}>
          {value.departDetails.airportName} to {value.arriveDetails.airportName}
        </div>
        <div className={classes.baggageContent}>
          <div className={classes.baggageFlex}>
            <div>
              <BusinessCenterRoundedIcon fontSize="small" />
            </div>
            <div>
              <div className={classes.f16b}>Cabin</div>
              <div className={classes.f12r}>7kg / person</div>
            </div>
          </div>
          <div className={classes.baggageFlex}>
            <div>
              <LocalMallIcon fontSize="small" />
            </div>
            <div>
              <div className={classes.f16b}>Check-in</div>
              <div className={classes.f12r}>
                {selectedFareDetail && selectedFareDetail.baggage} / person
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MoreDetailsView = () => {
    return (
      <div className={classes.moreDetailsContent}>
        <div className={classes.moreDetailNav}>
          <div
            className={clsx(
              moreDetailNavState === 0
                ? classes.activeMoreDetailNav
                : classes.notActiveMoreDetailNav
            )}
            onClick={() => setMoreDetailNavState(0)}
          >
            Flight Details
          </div>
          <div
            className={clsx(
              moreDetailNavState === 1
                ? classes.activeMoreDetailNav
                : classes.notActiveMoreDetailNav
            )}
            onClick={() => setMoreDetailNavState(1)}
          >
            Fare Details
          </div>
          <div
            className={clsx(
              moreDetailNavState === 2
                ? classes.activeMoreDetailNav
                : classes.notActiveMoreDetailNav
            )}
            onClick={() => setMoreDetailNavState(2)}
          >
            Fare Rules
          </div>
          {width > breakpoint && (
            <div
              className={clsx(
                moreDetailNavState === 3
                  ? classes.activeMoreDetailNav
                  : classes.notActiveMoreDetailNav
              )}
              onClick={() => setMoreDetailNavState(3)}
            >
              Baggage Details
            </div>
          )}
        </div>
        <div className={classes.moreDetailBody}>
          {/* Flight Details */}
          {moreDetailNavState === 0 && (
            <div className={classes.flightDetailElements}>
              {width > breakpoint ? (
                <table className="table1">
                  <tr className="tr1 gray thead1">
                    <th className="th1">
                      <p className="p1">S.No</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Flight No</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Departure</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Arrival</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Flight Time</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Layover Time</p>
                    </th>
                    {width < breakpoint && (
                      <th className="th1">
                        <p className="p1">Baggage</p>
                      </th>
                    )}
                  </tr>
                  {segmentsOnw.map((value, index) => (
                    <tr key={index}>
                      <td className="td1">{index + 1}</td>
                      <td className="td1">
                        <img
                          style={{ width: "25px", height: "25px" }}
                          src={
                            image_base +
                            "?fileName=" +
                            value.carrierDetails.carrierCode +
                            ".png&type=AIRLINE_IMAGES"
                          }
                        ></img>
                        <div className={classes.f12b}>
                          {value.carrierDetails.carrierName}
                        </div>
                        <div className={classes.f8r}>
                          {value.carrierDetails.carrierCode}-
                          {value.carrierDetails.flightNumber}
                        </div>
                      </td>
                      <td className="td1">
                        <span
                          className={classes.f16b}
                          style={{ marginRight: "6px" }}
                        >
                          {dateFnsFormat(
                            new Date(value.departDetails.dateTime),
                            "HH:mm"
                          )}
                        </span>
                        <span className={classes.f12r}>
                          {
                            days[
                            new Date(value.departDetails.dateTime).getDay()
                            ]
                          }
                          ,
                          {dateFnsFormat(
                            new Date(value.departDetails.dateTime),
                            "dd MMM"
                          )}
                        </span>
                        <div>
                          <span className={classes.f14b}>
                            {value.departDetails.airportName}
                          </span>
                          <span className={classes.f12r}>
                            {value.departureAirportName} (
                            {value.departDetails.airportCode})
                          </span>
                        </div>
                        <div>
                          <span className={classes.f12r}>
                            Terminal {value.departDetails.terminal}
                          </span>
                        </div>
                      </td>
                      <td className="td1">
                        <span
                          className={classes.f16b}
                          style={{ marginRight: "6px" }}
                        >
                          {dateFnsFormat(
                            new Date(value.arriveDetails.dateTime),
                            "HH:mm"
                          )}
                        </span>
                        <span className={classes.f12r}>
                          {
                            days[
                            new Date(value.arriveDetails.dateTime).getDay()
                            ]
                          }
                          ,
                          {dateFnsFormat(
                            new Date(value.arriveDetails.dateTime),
                            "dd MMM"
                          )}
                        </span>
                        <div>
                          <span className={classes.f14b}>
                            {value.arriveDetails.airportName} (
                            {value.arriveDetails.airportCode})
                          </span>
                        </div>
                        <div>
                          <span className={classes.f12r}>
                            Terminal {value.arriveDetails.terminal}
                          </span>
                        </div>
                      </td>
                      <td className="td1">
                        <span>
                          {Math.floor(value.flightTime / (24 * 60)) > 0
                            ? Math.floor(value.flightTime / (24 * 60)) + "d "
                            : ""}
                          &nbsp;
                          {Math.floor(value.flightTime / 60) <= 9
                            ? "0" + Math.floor(value.flightTime / 60)
                            : Math.floor(value.flightTime / 60)}
                          h &nbsp;
                          {Math.floor(value.flightTime % 60) <= 9
                            ? "0" + Math.floor(value.flightTime % 60)
                            : Math.floor(value.flightTime % 60)}
                        </span>
                      </td>
                      <td className="td1">
                        {value.layover && value.layover > 0 ? (
                          <div className={classes.flightDetailLayover}>
                            <div className={classes.f10r}>
                              <span>
                                {Math.floor(value.layover / (24 * 60)) > 0
                                  ? Math.floor(value.layover / (24 * 60)) +
                                  "day" +
                                  (Math.floor(value.layover / (24 * 60)) > 1
                                    ? "s "
                                    : " ")
                                  : ""}
                                &nbsp;
                                {Math.floor(value.layover / 60)} hrs{" "}
                                {Math.floor(value.layover % 60)} min
                              </span>
                            </div>
                          </div>
                        ) : (
                          <span>{value.layover}</span>
                        )}
                      </td>
                      {width < breakpoint && (
                        <td className="td1">
                          <div className={classes.baggageDetailsElements}>
                            <div
                              className={classes.baggageDetailsElement}
                              key={index}
                            >
                              <div
                                className={clsx(
                                  classes.baggageLocation,
                                  classes.f12b
                                )}
                              >
                                {value.departDetails.airportName} to{" "}
                                {value.arriveDetails.airportName}
                              </div>
                              <div className={classes.baggageContent}>
                                <div className={classes.baggageFlex}>
                                  <div>
                                    <BusinessCenterRoundedIcon fontSize="small" />
                                  </div>
                                  <div>
                                    <div className={classes.f16b}>Cabin</div>
                                    <div className={classes.f12r}>
                                      7kg / person
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.baggageFlex}>
                                  <div>
                                    <LocalMallIcon fontSize="small" />
                                  </div>
                                  <div>
                                    <div className={classes.f16b}>Check-in</div>
                                    <div className={classes.f12r}>
                                      {selectedFareDetail &&
                                        selectedFareDetail.baggage}{" "}
                                      / person
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </table>
              ) : (
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.labelContainer}
                  >
                    <Grid item className={classes.elementLabel}>
                      <span className={classes.elementLabelText}>
                        {segmentsOnw[0].departDetails.airportName} -{" "}
                        {
                          segmentsOnw[segmentsOnw.length - 1].arriveDetails
                            .airportName
                        }
                      </span>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="clear price"
                        size="small"
                        onClick={() =>
                          setOpenDiv({
                            ...openDiv,
                            openOnwardDetails: !openDiv?.openOnwardDetails,
                          })
                        }
                        className={classes.collapseIcon}
                      >
                        {openDiv?.openOnwardDetails == true ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={openDiv?.openOnwardDetails} timeout={0}>
                    {segmentsOnw.map((value, index) => (
                      <FlightDetailsCardMobile value={value} key={index} />
                    ))}
                  </Collapse>
                </Grid>
              )}
              {width > breakpoint && <br />}
              {props.tripMode === "ROUND" && (
                <div>
                  {width > breakpoint ? (
                    <table className="table1">
                      <tr className="tr1 gray thead1">
                        <th className="th1">
                          <p className="p1">S.No</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Flight No</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Departure</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Arrival</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Flight Time</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Layover Time</p>
                        </th>
                        {width < breakpoint && (
                          <th className="th1">
                            <p className="p1">Baggage</p>
                          </th>
                        )}
                      </tr>
                      {segmentsRet?.map((value, index) => (
                        <tr key={index}>
                          <td className="td1">{index + 1}</td>
                          <td className="td1">
                            <img
                              style={{ width: "25px", height: "25px" }}
                              src={
                                image_base +
                                "?fileName=" +
                                value.carrierDetails.carrierCode +
                                ".png&type=AIRLINE_IMAGES"
                              }
                            ></img>
                            <div className={classes.f12b}>
                              {value.carrierDetails.carrierName}
                            </div>
                            <div className={classes.f8r}>
                              {value.carrierDetails.carrierCode}-
                              {value.carrierDetails.flightNumber}
                            </div>
                          </td>
                          <td className="td1">
                            <span
                              className={classes.f16b}
                              style={{ marginRight: "6px" }}
                            >
                              {dateFnsFormat(
                                new Date(value.departDetails.dateTime),
                                "HH:mm"
                              )}
                            </span>
                            <span className={classes.f12r}>
                              {
                                days[
                                new Date(
                                  value.departDetails.dateTime
                                ).getDay()
                                ]
                              }
                              ,
                              {dateFnsFormat(
                                new Date(value.departDetails.dateTime),
                                "dd MMM"
                              )}
                            </span>
                            <div>
                              <span className={classes.f14b}>
                                {value.departDetails.airportName}
                              </span>
                              <span className={classes.f12r}>
                                {value.departureAirportName} (
                                {value.departDetails.airportCode})
                              </span>
                            </div>
                            <div>
                              <span className={classes.f12r}>
                                Terminal {value.departDetails.terminal}
                              </span>
                            </div>
                          </td>
                          <td className="td1">
                            <span
                              className={classes.f16b}
                              style={{ marginRight: "6px" }}
                            >
                              {dateFnsFormat(
                                new Date(value.arriveDetails.dateTime),
                                "HH:mm"
                              )}
                            </span>
                            <span className={classes.f12r}>
                              {
                                days[
                                new Date(
                                  value.arriveDetails.dateTime
                                ).getDay()
                                ]
                              }
                              ,
                              {dateFnsFormat(
                                new Date(value.arriveDetails.dateTime),
                                "dd MMM"
                              )}
                            </span>
                            <div>
                              <span className={classes.f14b}>
                                {value.arriveDetails.airportName} (
                                {value.arriveDetails.airportCode})
                              </span>
                            </div>
                            <div>
                              <span className={classes.f12r}>
                                Terminal {value.arriveDetails.terminal}
                              </span>
                            </div>
                          </td>
                          <td className="td1">
                            <span>
                              {Math.floor(value.flightTime / (24 * 60)) > 0
                                ? Math.floor(value.flightTime / (24 * 60)) +
                                "d "
                                : ""}
                              &nbsp;
                              {Math.floor(value.flightTime / 60) <= 9
                                ? "0" + Math.floor(value.flightTime / 60)
                                : Math.floor(value.flightTime / 60)}
                              h &nbsp;
                              {Math.floor(value.flightTime % 60) <= 9
                                ? "0" + Math.floor(value.flightTime % 60)
                                : Math.floor(value.flightTime % 60)}
                            </span>
                          </td>
                          <td className="td1">
                            {value.layover && value.layover > 0 ? (
                              <div className={classes.flightDetailLayover}>
                                <div className={classes.f10r}>
                                  <span>
                                    {Math.floor(value.layover / (24 * 60)) > 0
                                      ? Math.floor(value.layover / (24 * 60)) +
                                      "day" +
                                      (Math.floor(value.layover / (24 * 60)) >
                                        1
                                        ? "s "
                                        : " ")
                                      : ""}
                                    &nbsp;
                                    {Math.floor(value.layover / 60)} hrs{" "}
                                    {Math.floor(value.layover % 60)} min
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <span>{value.layover}</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  ) : (
                    <Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.labelContainer}
                      >
                        <Grid item className={classes.elementLabel}>
                          <span className={classes.elementLabelText}>
                            {segmentsRet[0].departDetails.airportName} -{" "}
                            {
                              segmentsRet[segmentsRet.length - 1].arriveDetails
                                .airportName
                            }
                          </span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear price"
                            size="small"
                            onClick={() =>
                              setOpenDiv({
                                ...openDiv,
                                openReturnDetails: !openDiv?.openReturnDetails,
                              })
                            }
                            className={classes.collapseIcon}
                          >
                            {openDiv?.openReturnDetails == true ? (
                              <ArrowDropUp />
                            ) : (
                              <ArrowDropDown />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Collapse in={openDiv?.openReturnDetails} timeout={0}>
                        {segmentsRet.map((value) => (
                          <FlightDetailsCardMobile value={value} />
                        ))}
                      </Collapse>
                    </Grid>
                  )}
                </div>
              )}
            </div>
          )}
          {/* Fare Details */}
          {moreDetailNavState === 1 && (
            <div className={classes.fareDetailElements}>
              {width > breakpoint ? (
                <div className={classes.fareDetailElement}>
                  <FareDetailComponent />
                </div>
              ) : (
                <Grid>
                  <Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.labelContainer}
                    >
                      <Grid item className={classes.elementLabel}>
                        <span className={classes.elementLabelText}>
                          Fare Details
                        </span>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="clear price"
                          size="small"
                          onClick={() =>
                            setOpenDiv({
                              ...openDiv,
                              openFareDetails: !openDiv?.openFareDetails,
                            })
                          }
                          className={classes.collapseIcon}
                        >
                          {openDiv?.openFareDetails == true ? (
                            <ArrowDropUp />
                          ) : (
                            <ArrowDropDown />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Collapse in={openDiv?.openFareDetails} timeout={0}>
                      <FareDetailComponent />
                    </Collapse>
                  </Grid>
                  <Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.baggageLabelContainer}
                    >
                      <Grid item className={classes.elementLabel}>
                        <span className={classes.elementLabelText}>
                          Baggage Details
                        </span>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="clear price"
                          size="small"
                          onClick={() =>
                            setOpenDiv({
                              ...openDiv,
                              openBaggageDetails: !openDiv?.openBaggageDetails,
                            })
                          }
                          className={classes.collapseIcon}
                        >
                          {openDiv?.openBaggageDetails == true ? (
                            <ArrowDropUp />
                          ) : (
                            <ArrowDropDown />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Collapse in={openDiv?.openBaggageDetails} timeout={0}>
                      <div className={classes.baggageDetailsElements}>
                        {segmentsOnw.map((value, index) => (
                          <BaggageComponent key={index} value={value} />
                        ))}
                        {props.tripMode === "ROUND" &&
                          segmentsRet?.map((value, index) => (
                            <BaggageComponent key={index} value={value} />
                          ))}
                      </div>
                    </Collapse>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
          {/* Fare Rules */}
          {moreDetailNavState === 2 && (
            <div className={classes.fareRulesElements}>
              <div
                className={
                  classes.fareRulesElement
                } /*style={{ maxWidth: props.tripMode == "ROUND" || props.tripMode == "ROUND-SP" ? "35vw" : "50vw" }}*/
              >
                <FareRuleComponent fareRules={fareRules} />
              </div>
            </div>
          )}
          {/* Baggage Details */}
          {moreDetailNavState === 3 && (
            <div className={classes.baggageDetailsElements}>
              {segmentsOnw.map((value, index) => (
                <BaggageComponent key={index} value={value} />
              ))}
              {props.tripMode === "ROUND" &&
                segmentsRet?.map((value, index) => (
                  <BaggageComponent key={index} value={value} />
                ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classes.root}
      style={{ width: props.fullWidth ? "100%" : "50%" }}
    >
      <div className={classes.NonMUIFlightCard}>
        {/* <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
          style={{ width: "100%" }}
        /> */}
        {isError && (
          <div item md={12} xs={12}>
            <OaAlert
              //callback={transactionHelper}
              isPrompt={isAlertPrompt}
              //msg={isError}
              //title={infoText}
              msg={alertMsg}
              title={alertTitle}
              isError={isError}
              primaryButtonText={primaryButtonText}
              secondaryButtonText={secondaryButtonText}
              togglePrompt={() => {
                setIsAlertPrompt(!isAlertPrompt);
              }}
            ></OaAlert>
          </div>
        )}
        <div className="card-grid">
          <Grid className="card-flightDetails">
            {" "}
            <div className="card-col ct-onw">
              <div className="card-content">
                {/* 1 ------------------------ */}
                <div className={classes.airlineDetails}>
                  <div className="carrier-details-div" ref={carrierDetailsDiv}>
                    <div className={classes.airline_image}>
                      <img
                        src={
                          image_base +
                          "?fileName=" +
                          flightCodeOnw +
                          ".png&type=AIRLINE_IMAGES"
                        }
                        alt=""
                      />
                    </div>
                    <div className={classes.airline_title}>
                      <div className={classes.f14b}>
                        {airlineCodeOnw} -{" "}
                        <span className={classes.seatsLeft}>
                          ({fareDetails?.[0]?.bookingClass})
                        </span>
                        {/* ({fareDetails?.[0]?.bookingClass}) */}
                      </div>
                      <div className={classes.f12r}>{airlineNameOnw}</div>
                    </div>
                  </div>
                </div>

                {/* 2 ------------------------ */}
                <div className={classes.flightDetails}>
                  <div className={classes.flightDetails_Timings}>
                    <div className={classes.departure}>
                      <div className={clsx(classes.f24b, classes.leftText)}>
                        {departTimeOnw}
                      </div>
                      <div className={clsx(classes.f12r)}>
                        {departDayOnw}, {departDateOnw} [{departAirportOnw}]
                      </div>
                    </div>
                    <div className={classes.duration}>
                      <div className={clsx(classes.f12r)}>
                        {journeyHoursOnw} hr {journeyMinsOnw} min
                      </div>
                      <div className={clsx(classes.f8r)}>
                        {stopsOnw > 0
                          ? // stopsOnw +
                          //   " stop" +
                          //   (stopsOnw > 1 ? "s " : " ") +
                          "Via " +
                          `${segmentsOnw
                            .map((value, index) => {
                              // journeyTimeOnw += parseInt(value.flightTime) + parseInt(value.layover);
                              if (index > 0) {
                                const sep =
                                  segmentsOnw.length - 1 === index
                                    ? ""
                                    : "- ";
                                stopAirportsOnw +=
                                  value.departDetails.airportCode + sep;
                                return stopAirportsOnw;
                              }
                            })
                            .join("")}`
                          : "Non-Stop"}
                      </div>
                      <div className={clsx(classes.f8r, classes.seatsLeft)}>
                        {fareDetails?.[0]?.availableSeats} Seats Left
                      </div>
                    </div>
                    <div className={classes.arrival}>
                      <div className={clsx(classes.f24b)}>{arrivalTimeOnw}</div>
                      <div className={clsx(classes.f12r)}>
                        {arrivalDayOnw}, {arrivalDateOnw} [{arrivalAirportOnw}]
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.tripMode === "ROUND" && (
              <div className="card-col ct-ret">
                <div className="border"></div>
                <div className="card-content">
                  {/* 1 ------------------------ */}
                  <div className={classes.airlineDetails}>
                    <div
                      className="carrier-details-div"
                      ref={carrierDetailsDivRet}
                    >
                      <div className={classes.airline_image}>
                        <img
                          src={
                            image_base +
                            "?fileName=" +
                            flightCodeRet +
                            ".png&type=AIRLINE_IMAGES"
                          }
                          alt=""
                        />
                        {/* <div className={classes.f12r}>
                          &nbsp;&nbsp;{airlineCodeRet}
                        </div> */}
                      </div>
                      <div className={classes.airline_title}>
                        <div className={classes.f14b}>{airlineCodeRet}</div>
                        <div className={classes.f14b}>{airlineNameRet}</div>
                      </div>
                    </div>
                  </div>

                  {/* 2 ------------------------ */}
                  <div className={classes.flightDetails}>
                    <div className={classes.flightDetails_Timings}>
                      <div className={classes.departure}>
                        <div className={clsx(classes.f24b, classes.leftText)}>
                          {departTimeRet}
                        </div>
                        <div className={clsx(classes.f12r)}>
                          {departDayRet}, {departDateRet} [{departAirportRet}]
                        </div>
                      </div>
                      <div className={classes.duration}>
                        <div className={clsx(classes.f12r)}>
                          {journeyHoursRet} hr {journeyMinsRet} min
                        </div>
                        <div className={clsx(classes.f8r)}>
                          {stopsRet > 0
                            ? stopsRet +
                            " stop" +
                            (stopsRet > 1 ? "s " : " ") +
                            "- " +
                            stopAirportsRet
                            : "Non-Stop"}
                        </div>
                      </div>
                      <div className={classes.arrival}>
                        <div className={clsx(classes.f24b)}>
                          {arrivalTimeRet}
                        </div>
                        <div className={clsx(classes.f12r)}>
                          {arrivalDayRet}, {arrivalDateRet} [{arrivalAirportRet}
                          ]
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Grid>

          <div className="card-col ct-bac">
            <div className="card-content">
              <div className={classes.priceDetails}>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedFareId}
                    // defaultValue={"non-stop"}
                    // radio-button default setting
                    aria-label="flight stops"
                    name="flight-stop-radios"
                  >
                    {fareDetails &&
                      fareDetails.length > 0 &&
                      fareDetails.map((s, i) => (
                        <FormControlLabel
                          className={classes.FlightPricesLabel}
                          value={s.fareId}
                          control={<FilterRadio />}
                          label={
                            <>
                              <p>
                                {/* {console.log("fareDetail", s)} */}
                                {"₹"} {s.baseFare + s.tax + s.tmarkup}
                                {props.tripMode !== "ROUND" && (
                                  <>
                                    <Tooltip
                                      title={
                                        s.availableSeats + " seats available"
                                      }
                                    >
                                      <span className="price-seat">
                                        <AirlineSeatReclineNormalRounded />
                                      </span>
                                    </Tooltip>
                                    {s.isRefundable == "true" ? (
                                      <Tooltip title="Refundable">
                                        <span className="price-refundable">
                                          R
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Non-Refundable">
                                        <span
                                          className="price-refundable"
                                          style={{
                                            textDecoration:
                                              "line-through solid red 2px",
                                            marginBottom: 5,
                                          }}
                                        >
                                          R
                                        </span>
                                      </Tooltip>
                                    )}
                                    <Tooltip
                                      title={
                                        s.baggage != null ? s.baggage : "15kg"
                                      }
                                    >
                                      <span className="price-baggage">
                                        <LocalMallRoundedIcon />{" "}
                                      </span>
                                    </Tooltip>
                                  </>
                                )}
                              </p>
                              <span className="fare-type">
                                {s.fareType &&
                                  s.fareType != null &&
                                  s.fareType != ""
                                  ? s.fareType
                                  : "Published Fare"}
                              </span>
                              <br />
                              <OaNetFare
                                fareData={s}
                                showNetFare={props.showNetFare}
                              />
                            </>
                          }
                          onClick={onClickFareId}
                          checked={selectedFareId === s.fareId}
                          key={i}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
                {fareDetails && fareDetails.length > 2 ? (
                  <div
                    className={classes.showMoreButton}
                    onClick={() => toggleShowMoreFares(!showMoreFares)}
                    style={{
                      position: showMoreFares === true ? "" : "absolute",
                    }}
                  >
                    <span>show {showMoreFares ? "less" : "more"}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="book-now-action-group">
                <Grid item>
                  <Box
                    className={classes.onwardBookNowAction}
                    id={`button_${props.buttonIndex}`}
                    value-id={props.flightData.fares[0]}
                    onClick={rePrice}
                  // onClick={(x) => console.log()}
                  // style={{
                  //   background:
                  //     props.selected != null && selectedFareId == props.selected
                  //       ? theme.palette.primary.main
                  //       : theme.palette.secondary.sharkGray,
                  //   cursor:
                  //     props.selected != null && selectedFareId == props.selected
                  //       ? "pointer"
                  //       : "not-allowed",
                  //   background: theme.palette.secondary.main,
                  //   cursor: "pointer",
                  // }}
                  >
                    {/* <div id={`button_${props.buttonIndex}`}></div> */}
                    <Typography>Book Now</Typography>
                  </Box>
                  <div className="more-action-div" ref={moreActionDiv}>
                    <div
                      className={classes.airline_detailButton}
                      onClick={(event) => {
                        toggleMoreDetails(event, !moreDetailsOpen);
                      }}
                    >
                      <span className={classes.f8r}>
                        More Details {moreDetailsOpen ? "-" : "+"}
                      </span>
                    </div>
                    {props.tripMode !== "ROUND" && (
                      <div className={classes.flightDetails_Share}>
                        <div className="share-div">
                          <div
                            className="share-dropdown-toggle-button"
                            onClick={() => setShareOnwOpen(!shareOnwOpen)}
                          >
                            <span className={classes.f12b}>
                              Share <i className="fas fa-share"></i>
                            </span>
                          </div>
                          <div
                            className="share-dropdown"
                            style={{
                              display:
                                shareOnwOpen && shareOnwOpen == true
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <span
                              className="share-button"
                              onClick={() => shareViaWhatsapp()}
                            >
                              <i className="fab fa-whatsapp"></i>
                            </span>

                            <span
                              className="share-button"
                              onClick={() => shareViaEmail()}
                            >
                              <i className="fas fa-envelope"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* Onward Start */}
          {moreDetailsOpen && width > breakpoint && <MoreDetailsView />}
          {width < breakpoint && (
            <Dialog
              open={moreDetailsOpen}
              onClose={() => setMoreDetailsOpen(false)}
              fullScreen
            >
              <Box sx={style}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {" "}
                  <IconButton onClick={() => setMoreDetailsOpen(false)}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <MoreDetailsView />
              </Box>
            </Dialog>
          )}
          {/* Onward End */}
        </div>
      </div>
    </div>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 10,
  boxShadow: 24,
  p: 2,
};
export default withRouter(NonMUIIntFlightCard);

const FareRuleComponent = (props) => {
  return (
    <ul>
      {props.fareRules &&
        props.fareRules.split("|").map((rule, ind) => {
          if (rule != "" && rule != null) {
            const uriDecoded = decodeURIComponent(rule);
            return (
              <li
                key={ind}
                dangerouslySetInnerHTML={{
                  __html: uriDecoded.replaceAll("+", " "),
                }}
              ></li>
            );
          } else {
            return "";
          }
        })}
    </ul>
  );
};

const radioStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      /*backgroundColor: "transparent",*/
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: `${theme.palette.primary.main}40`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: `${theme.palette.primary.main}10`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
  },
}));

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      ref={props.inref}
      {...props}
    />
  );
}

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="secondary">
            {labelInfo}
          </Typography>
        </div>
      }
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useTreeItemStyles = makeStyles((theme) => {
  //console.log(theme);
  return {
    root: {
      color: theme.palette.action.hover,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    content: {
      color: theme.palette.action.hover,
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  };
});

const getStyles = (tripMode, showMoreFares, showNetFare) => {
  return makeStyles((theme) => ({
    root: {
      boxSizing: "border-box",
      borderRadius: "6px",
      backgroundColor: theme.palette.background.light,
      width: "48%",
      boxShadow: "0 0 10px rgba(0,0,0,0.25)",
      "& .flex-dir-col": {
        flexDirection: "column !important",
        alignItems: "flex-start !important",
      },
      "& .card-grid": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        // gap: 10,
        // justifyContent:
        //   tripMode == "ONE" ? "flex-start" : "space-between",
        width: "100%",
        "& .card-flightDetails": {
          width: "60%",
          padding: "0px 30px",

          [theme.breakpoints.down(950)]: {
            width: "65%",
            // padding: 0,
            // padding: tripMode == "ROUND" && "0 40px",
          },
          [theme.breakpoints.down(925)]: {
            width: "100%",
            padding: 0,
            // padding: tripMode == "ROUND" && "0 40px",
          },
        },
        [theme.breakpoints.down(890)]: {
          flexDirection: tripMode == "ROUND" ? "column" : "row",
          padding: tripMode == "ROUND" && "0 40px",
        },
        [theme.breakpoints.down(650)]: {
          flexDirection: "column",
          padding: "0 20px",
          paddingTop: 20,
          // padding: tripMode == "ROUND" && "0 40px",
        },

        [theme.breakpoints.down(420)]: {
          padding: "0px",
        },
      },
      "& .card-col": {
        padding: 0,
        margin: 0,
        "&.ct-onw": {
          width: "100%",
          // width: tripMode == "ONE" ? "60%" : "35%",
          // [theme.breakpoints.down(1200)]: {
          //   width: tripMode == "ROUND" && "310px",
          // },
          // [theme.breakpoints.down(900)]: {
          //   width: tripMode == "ONE" && "60%",
          // },
          // [theme.breakpoints.down(890)]: {
          //   width: "100%",
          // },
        },
        "&.ct-ret": {
          width: "100%",
          "& .border": {
            border: "1px solid #E5E4E2",
            margin: "0 auto",
            paddingLeft: 20,
            width: "100%",
            marginTop: 2,
            marginBottom: 5,
            marginRight: "10%",
          },
          [theme.breakpoints.down(1200)]: {
            // width: tripMode == "ROUND" && "310px",
          },
          [theme.breakpoints.down(890)]: {
            width: "100%",
          },
        },
        "&.ct-bac": {
          width: tripMode == "ONE" ? "40%" : "30%",
          [theme.breakpoints.down(1500)]: {
            // width: tripMode == "ROUND" && "20%",
            // minWidth: 210,
          },
          // [theme.breakpoints.down(1200)]: {
          //   width: tripMode == "ROUND" && "20%",
          //   minWidth: 210,
          // },
          [theme.breakpoints.down(900)]: {
            width: tripMode == "ONE" && "40%",
          },
          [theme.breakpoints.down(890)]: {
            width: "100%",
          },
        },
      },
      "& .card-content": {
        // display: "grid",
        // padding: 15,
        // width: "100%",
        // gap: "6px",
        // justifyContent: "space-between",
        // gridTemplateColumns: "repeat(2,auto)",
        // backgroundColor: theme.palette.background.darkerFadedGray,
        // // boxShadow: `0px 0px 5px 0px rgba(145,143,145,0.6)`,
        // [theme.breakpoints.down(1080)]: {
        //   gridTemplateColumns: "repeat(2,auto)",
        //   gridTemplateRows: "repeat(2,auto)",
        // },

        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // [theme.breakpoints.down(550)]: {
        //   display: "grid",
        //   gridTemplateColumns: "repeat(2,1fr)",
        // },
      },
      "& .book-now-action-group": {
        minWidth: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 15,
      },
    },
    NonMUIFlightCard: {
      minHeight: "116px",
      maxWidth: "100%",
      minWidth: "100%",
      boxShadow: `0px 0px 5px 0px rgba(145,143,145,0.6)`,
      padding: "6px",
      margin: "7px 0",
    },

    /// 1 --------------------
    airlineDetails: {
      // backgroundColor: theme.palette.background.rgba_primary,
      // width: tripMode === "ONE" ? "unset" : 100,
      // [theme.breakpoints.down(1080)]: {
      //   gridRow: "2 / span 1",
      //   gridColumn: "1 / span 1",
      //   display: "flex",
      //   flexDirection: "row",
      //   gap: "16px",
      //   alignItems: "center",
      // },
      // width: 90,
      padding: 5,
      width: tripMode == "ONE" ? 250 : "auto",

      //minWidth: 150,
      [theme.breakpoints.down(720)]: {
        width: "30%",
      },
      [theme.breakpoints.down(400)]: {
        width: "30%",
      },
      [theme.breakpoints.down(1500)]: {
        // width: tripMode == "ROUND" && 120,
        // minWidth: 100,
      },
      "& .carrier-details-div": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down(400)]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      },
      "& .more-action-div": {
        marginTop: 15,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
      },
    },

    airline_image: {
      width: "fit-content",
      display: "flex",
      marginRight: 10,
      marginRight: "5px",
      "& img": {
        height: "25px",
        width: "25px",
        [theme.breakpoints.down(1080)]: {
          height: "24px",
          width: "24px",
        },
      },
    },

    airline_title: {
      width: "fit-content",
      margin: "4px 0",
    },
    airline_detailButton: {
      cursor: "pointer",
      marginRight: 10,
      marginTop: 5,
      // display: "flex",
      // justifyContent: "flex-end",
      "& span": {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: "20px",
        padding: "4px 8px",
      },
      minWidth: 90,
      // maxWidth: "72px",
    },

    /// 2 --------------------
    flightDetails: {
      // backgroundColor: theme.palette.background.rgba_secondary,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      //gap: "20px",
      textAlign: "center",

      // [theme.breakpoints.down(1080)]: {
      //   gridRow: "1 / span 1",
      //   gridColumn: "1 / span 1",
      //   gap: "12px",
      // },
      width: tripMode == "ONE" ? 360 : 320,
      [theme.breakpoints.down(1500)]: {
        // width: tripMode == "ROUND" && 220,
        // minWidth: 220,
      },
      padding: 5,
      [theme.breakpoints.down(890)]: {
        width: "70%",
      },
      [theme.breakpoints.down(380)]: {
        width: "70%",
        //minWidth: 180,
      },
    },

    flightDetails_Timings: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      // [theme.breakpoints.down(1240)]: {
      //   gap: "6px",
      // },
    },
    departure: {
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        textAlign: "left",
      },
    },
    duration: {
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        textAlign: "left",
      },
    },
    arrival: {
      textAlign: "right",
      // [theme.breakpoints.down(960)]: {
      //   textAlign: "left",
      // },
    },
    flightDetails_Itinaries: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateRows: "repeat(1,1fr)",
      "& svg": {
        height: "12px",
        [theme.breakpoints.down(1080)]: {
          height: "8px",
        },
      },
      "& .add-service-item": {
        display: "flex",
        alignItems: "center",
        "& span": {
          whiteSpace: "nowrap",
          overflowX: "hidden",
        },
      },
    },
    flightDetails_Share: {
      marginTop: 0,
      display: "flex",
      justifyContent: "flex-start",
      "& .share-dropdown-toggle-button": {
        cursor: "pointer",
        borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
      },
      "& .share-div": {
        marginTop: 5,
        position: "relative",
      },
      "& .share-dropdown": {
        position: "absolute",
        top: -36,
        left: -40,
        borderRadius: 3,
        padding: "2px 15px",
        backgroundColor: theme.palette.primary.disabled,
        "& i": {
          color: theme.palette.primary.main,
        },
      },
      "& .share-button": {
        margin: "2px 8px",
        cursor: "pointer",
        "& i": {
          fontSize: 18,
        },
      },
    },

    /// 3 --------------------
    priceDetails: {
      // backgroundColor: theme.palette.background.rgba_tertiary,
      height: showMoreFares ? "auto" : showNetFare ? 156 : 116,
      width: tripMode == "ROUND" ? 160 : 165,
      padding: "0 5px",
      position: "relative",
      overflow: "hidden",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      // [theme.breakpoints.down(690)]: {
      //   height: 90,
      // },
      [theme.breakpoints.down(1080)]: {
        gridRow: "1 / span 2",
        gridColumn: "2 / span 3",
      },
      [theme.breakpoints.down(670)]: {
        minHeight: "93px !important",
        height: showMoreFares ? "auto" : showNetFare ? 152 : 104,
      },
      [theme.breakpoints.down(1590)]: {
        width: 160,
      },
    },
    FlightPricesLabel: {
      marginBottom: "12px",
      alignItems: "flex-start",
      "& .MuiTypography-root.MuiTypography-body1": {
        maxHeight: 62,
        // overflowY: "hidden",
        overflowX: "show",
        lineHeight: 1.25,
        width: 130,
      },
      "&.MuiFormControlLabel-root": {
        alignItems: "flex-start",
      },
      "& .MuiFormControlLabel-label": {
        padding: "5px 0 0 0",
        "& p": {
          margin: 0,
          padding: 0,
          fontSize: "18px",
          fontWeight: "700",
          marginBottom: "-10px",
          color: theme.palette.primary.darkText,
          position: "relative",
          "& span": {
            fontSize: 16,
            marginLeft: 15,
            position: "absolute",
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
            "&.price-seat": {
              top: 1,
              left: 67,
            },
            "&.price-refundable": {
              top: -1,
              left: 85,
            },
            "&.price-baggage": {
              top: 0,
              left: 97,
            },
          },
          [theme.breakpoints.down(690)]: {
            fontSize: "12px",
          },
        },
        "& span.fare-type": {
          margin: 0,
          padding: 0,
          fontSize: "10px",
          fontWeight: 600,
          color: theme.palette.text.secondary,
          whiteSpace: "nowrap",
          display: "inline-block",
          marginTop: 9,
        },
      },
      "& .nf-root": {
        marginLeft: -5,
        [theme.breakpoints.down(992)]: {
          marginLeft: 0,
        },
      },
      "& .nf-span": {
        fontSize: "0.7rem",
        [theme.breakpoints.down(992)]: {
          marginLeft: 0,
          fontSize: 9,
        },
      },
      "& .add-sign": {
        margin: "0 4px",
      },
      "& .rupee-sign": {
        marginLeft: 6,
        marginRight: 3,
      },
      "& .base-fare": {
        color: theme.palette.primary.danger,
      },
      "& .earning": {
        color: theme.palette.primary.success,
      },
    },

    // -----------------------------

    moreDetailsContent: {
      padding: "4px 12px",
      width: "100%",
      overflowX: "auto",
      [theme.breakpoints.down("sm")]: {
        // width: 336,
      },
      [theme.breakpoints.down(480)]: {
        padding: 0,
        // fontSize: "6px",
      },
      [theme.breakpoints.down(400)]: {
        height: "calc(100% - 65px)",
        width: "100%",
        overflowX: "hidden",
      },
      [theme.breakpoints.down(283)]: {
        // width: 273,
      },
    },
    moreDetailNav: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      margin: "20px 0 0",
      "& > div": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("sm")]: {
        // width: 463,
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        width: "100%",
        margin: 0,
      },
    },
    activeMoreDetailNav: {
      fontWeight: 700,
      position: "relative",
      width: 120,
      // [theme.breakpoints.down("sm")]: {
      //   width:"100%",
      // },
      color: theme.palette.primary.contrastText,
      border: `solid 1px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      padding: "2px 6px",
      "&::after": {
        position: "absolute",
        display: "block",
        content: "''",
        top: "24px",
        left: 0,
        height: "2px",
        width: 120,
        // [theme.breakpoints.down("sm")]: {
        //   width:190,
        // },
        borderRadius: "2px",
        // backgroundColor: theme.palette.primary.main,
      },
    },
    notActiveMoreDetailNav: {
      fontWeight: 500,
      width: 120,
      padding: "2px 6px",
      border: `solid 1px ${theme.palette.primary.fadedLight}`,
    },

    moreDetailBody: {
      border: `solid 1px ${theme.palette.quarternary.main}`,
      padding: "10px 15px",
      width: "100%",
      minHeight: 200,
      [theme.breakpoints.down(480)]: {
        padding: 0,
      },
      [theme.breakpoints.down("sm")]: {
        // width: "346%",
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        width: "100%",
        height: "calc(100% - 27px)",
        overflowX: "scroll",
      },
    },
    collapseIcon: {
      marginTop: -5,
    },
    labelContainer: {
      padding: "10px 0",
      borderBottom: `2px solid ${theme.palette.background.lightGray}`,
    },
    baggageLabelContainer: {
      padding: "10px 0",
      borderBottom: `2px solid ${theme.palette.background.lightGray}`,
      borderTop: `1px solid ${theme.palette.background.lightGray}`,
    },
    elementLabel: {
      fontSize: "14px",
      fontWeight: "600",
      textAlign: "center",
      margin: "0",
    },
    elementLabelText: { color: theme.palette.primary.darkText, padding: 5 },
    // ----
    flightDetailElements: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down(400)]: {
        height: "100%",
        width: "100%",
      },
    },
    flightDetailElement: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    flightDetailLayover: {
      margin: "0 0 15px",
      "& span": {
        padding: "4px 12px",
        backgroundColor: `${theme.palette.primary.main}20`,
        borderRadius: "6px",
        margin: "6px 12px",
      },
    },
    // ----
    FareBreakupTreeView: {
      height: "auto",
      flexGrow: 1,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        width: 290,
      },
      [theme.breakpoints.down(400)]: {
        // padding: "0px 10px",
        width: "100%",
        marginBottom: 20,
        "& li .MuiTreeItem-content": {
          display: "block",
        },
      },
      "& li .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
      },
      "& li .MuiCollapse-wrapperInner .MuiTypography-root": {
        fontSize: "12px",
      },
      "& > li:last-child .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    // ----
    fareRulesElements: { height: "100%" },
    fareRulesElement: {
      display: "flex",
      flexDirection: "row",
      maxHeight: 300,
      maxWidth: "100%",
      overflowY: "auto",
      "& ul": { padding: 0 },
      "& li": {
        wordWrap: "break-word",
      },
      [theme.breakpoints.down(980)]: {
        // width: 380,
        height: "100%",
        maxHeight: "100%",
      },
      [theme.breakpoints.down(400)]: {
        padding: "0px 10px",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      },
      "& .row": {
        marginRight: -15,
        marginLeft: -15,
        display: "flex",
      },
      "& .col-xs-12": {
        width: "100%",
      },
      "& .col-lg-6, .col-md-6": {
        width: "50%",
        [theme.breakpoints.down(992)]: {
          width: "100%",
        },
      },
      "& .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        float: "left",
      },
      "& .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        position: "relative",
        minHeight: 1,
        paddingRight: 15,
        paddingLeft: 15,
      },
      [theme.breakpoints.down(992)]: {
        "& .col-md-6": {
          width: "50%",
        },
        "& .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9": {
          float: "left",
        },
      },
      "& .table-bordered": {
        border: "1px solid #CDCDCD",
      },
      "& .table": {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 20,
      },
      "& table": {
        backgroundColor: "transparent",
      },
      "& table": {
        borderCollapse: "collapse",
        borderSpacing: 0,
        display: "table",
        boxSizing: "border-box",
        textIndent: "initial",
        borderSpacing: 2,
        borderColor: "gray",
      },
      "& .table-striped>tbody>tr:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& tr": {
        display: "table-row",
        verticalAlign: "inherit",
        borderColor: "inherit",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th": {
        border: "1px solid #CDCDCD",
      },
      "& td, th": {
        padding: 0,
      },
      "& .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th": {
        padding: 8,
        lineHeight: 1.42857143,
        verticalAlign: "top",
        borderTop: "1px solid #CDCDCD",
      },
      "& td": {
        display: "table-cell",
        verticalAlign: "inherit",
      },
    },
    fareRule_title: {
      marginBottom: "8px",
    },
    fareRule_desc: {
      "& span": {
        lineHeight: "1em !important",
      },
    },
    // ----
    baggageDetailsElements: {
      [theme.breakpoints.down(400)]: {
        paddingTop: 10,
        // borderTop: `2px solid ${theme.palette.background.lightGray}`,
      },
    },
    baggageDetailsElement: {
      [theme.breakpoints.down(400)]: {
        padding: 5,
      },
    },
    baggageLocation: {},
    baggageContent: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      margin: "12px 0",
      [theme.breakpoints.down(400)]: {
        justifyContent: "flex-start",
        alignItems: "center",
      },
    },

    baggageFlex: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
    },

    // -----------------------------
    f24b: {
      fontSize: "20px",
      fontWeight: 700,
      [theme.breakpoints.down(450)]: {
        fontSize: "20px",
      },
    },
    leftText: { textAlign: "left" },
    rightText: { textAlign: "right" },
    f20b: {
      fontSize: "20px",
      fontWeight: 700,
      [theme.breakpoints.down(450)]: {
        fontSize: "16px",
      },
    },
    f16b: {
      fontSize: "16px",
      fontWeight: 700,
      [theme.breakpoints.down(450)]: {
        fontSize: "12px",
      },
    },
    f14b: {
      fontSize: "14px",
      fontWeight: 700,
      [theme.breakpoints.down(450)]: {
        fontSize: "12px",
      },
    },
    f12b: {
      fontSize: "12px",
      fontWeight: 700,
      [theme.breakpoints.down(450)]: {
        fontSize: "10px",
      },
    },
    f12r: {
      fontSize: "12px",
      fontWeight: 500,
      [theme.breakpoints.down(450)]: {
        fontSize: "11px",
      },
    },
    f10r: {
      fontSize: "10.5px",
      fontWeight: 500,
      [theme.breakpoints.down(450)]: {
        fontSize: "6px",
      },
    },
    f8r: {
      fontSize: "10px",
      fontWeight: 500,
      [theme.breakpoints.down(450)]: {
        fontSize: "10px",
      },
    },
    seatsLeft: {
      color: "#DF3049",
      fontWeight: 500,
    },
    pl5: {
      paddingLeft: "5px !important",
    },
    showMoreButton: {
      cursor: "pointer",
      // top: "78px",
      top: "unset",
      bottom: 0,
      right: "10px",
      left: tripMode == "ROUND" ? "unset" : "95px",
      right: tripMode == "ROUND" ? "10px" : "unset",
      [theme.breakpoints.down(550)]: {
        left: tripMode == "ROUND" ? "unset" : "100px",
      },
      "& span": {
        fontSize: 12,
        fontWeight: 500,
        float: "right",
        textDecoration: "underline",
      },
    },
    onwardAction: {
      "& .MuiGrid-item": {
        padding: "25px 25px 0 0",
        [theme.breakpoints.down(1314)]: {
          padding: "30px 15px 0 0",
        },
        [theme.breakpoints.down(1172)]: {
          padding: "20px 8px 0 0",
        },
      },
    },
    onwardBookNowAction: {
      boxShadow: "0 10px 10px -7px rgba(0,0,0,0.40)",
      width: "100%",
      // backgroundColor: theme.palette.secondary.darkOrange,
      // backgroundColor: theme.palette.primary.sharkGray,
      background: theme.palette.buttons.bookNowBtn,
      // cursor: "pointer",
      color: theme.palette.primary.lightText,
      cursor: "not-allowed",
      //padding: "12px 24px",
      padding: "10px 10px",
      borderRadius: "6px",
      cursor: "pointer",
      "& p": {
        textAlign: "center",
        fontWeight: "700",
        [theme.breakpoints.down(1120)]: {
          fontSize: 13,
        },
      },
      [theme.breakpoints.down(1161)]: {
        padding: "8px 10px",
      },
    },
  }));
};
