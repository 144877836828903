import React, { Component} from 'react';
import ReactTable from "react-table";
import WebApi from "api/ApiConstants";
import {Link} from 'react-router-dom';

/**
 * Opening balance = balance - credit + debit
 */
class BankAccounthResult extends Component {

   
    constructor(props){
        super(props);
        this.state = {
            data: [],
            tabledata: [],
            rowCount: 4,
            loading: false,
            finishedLoading: false,
            noDataText: " "
        }
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
    })

    filterGreaterThan(filter, row){
        return row[filter.id] >= filter.value;
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
    }

    componentDidMount() {
        // this.tableLoader();
    }

    // tableLoader() {
    //     WebApi.getMasterSentRequestList(this.props.searchParams, (resp) => {
    //         if(resp != null && resp.success){
    //             var tableData = [];
    //             var data = [];
    //             if(resp.data.hasOwnProperty("tableData")){
    //                 tableData = resp.data.tableData;
    //                 data = resp.data;
    //             }else{
    //                 tableData = resp.data;
    //             }

    //             this.setState({tabledata: tableData});
    //             this.setState({data: data});
    //             this.setState({
    //                 rowCount: tableData.length === 0 ? 4 : tableData.length,
    //                 noDataText: tableData.length === 0 ? "No results" : tableData.length,
    //                 loading: false,
    //                 finishedLoading: true
    //             });

    //         } 
    //         },(error)=>{
    //             this.setState({
    //                 data: [],
    //                 rowCount: 4,
    //                 // noDataText: "Something went wrong", //@TODO: for production environment
    //                 noDataText: "ERROR ", // + error.response.data.status + ": " + error.response.data.error,
    //                 loading: false,
    //                 finishedLoading: true
    //             });
    //             // console.log(error);
    //     });
    // }

    render(){
        //console.log(this.props);
        return(
            <>
            <ReactTable

                columns={[
                    {
                        Header: "Id",
                        accessor: "bankId",
                        width: 80
                    },{
                        Header: "Bank Name",
                        accessor: "bankName",
                        width: 160
                    },{
                        Header: "Account Name",
                        accessor: "accountName",
                        width: 200
                    },{
                        Header: "Account Number",
                        accessor: "accountNumber",
                        width: 200
                    },{
                        Header: "branchName",
                        accessor: "branchName"
                    },{
                        Header: "ifscCode",
                        accessor: "ifscCode",
                        width: 120
                    },{
                        Header: "remarks",
                        accessor: "remarks"
                    },{
                        Header: "Active",
                        accessor: "isActive",
                        width: 80
                    }
                ]}
                data={this.props.bankList}
                defaultFilterMethod={this.filterMethod}
                filterable={true}
                showPagination={false}
                minRows={this.state.rowCount}
                loading= {this.state.loading}
                pageSize={this.state.rowCount}
                noDataText={this.state.noDataText}       
            />
            </>
        )
    }
}

export default BankAccounthResult;
