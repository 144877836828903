import React, { useState, useEffect } from "react";

import { useMediaQuery } from "@material-ui/core";
import { currencyFormatter } from "oautils/oaCommonUtils";
import GridContainer from "components/Grid/GridContainer";
import { Divider, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import GridItem from "components/Grid/GridItem";
import closeBtn from "assets/TripBouquet/images/icons/closeButton.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import * as Yup from "yup";
import ListItemText from "@material-ui/core/ListItemText";
import { Button, Grid, TextField } from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import { Link } from "react-router-dom";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import Collapse from "@material-ui/core/Collapse";
// import image1 from "assets/img/camel.png";
import destination from "assets/css/destination.css";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import { SERVER_URL } from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { ErrorMessage, Form, Formik } from "formik";
import { Send } from "@material-ui/icons";
import { styles } from "material-ui-pickers/wrappers/InlineWrapper";
import { AppConstant } from "appConstant";
import OaPopperAlert from "oahoc/OaPopperAlert";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiGrid-spacing-xs-2": {
      width: "100%",
      paddingTop: 7,
    },
  },
  root1: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  typo: {
    fontSize: "24px",
    color: theme.palette.primary.pink,
  },
  typo1: {
    fontSize: "10px",
    paddingTop: "17px",
  },
  typo2: {},
  typo3: {
    color: theme.palette.primary.Byzantine,
    fontSize: "18px",
    fontWeight: 400,
  },
  typo4: {
    fontSize: "11px",
    paddingTop: "10px",
  },
  typo5: {
    fontSize: "15px",
    fontWeight: 600,
  },
  typo6: {
    fontSize: "13px",
  },
  Button: {
    backgroundColor: theme.palette.background.cyanBlue,
    fontSize: "10px",
    textTransform: "none",
    color: theme.palette.primary.lightText,
    "&:hover": {
      backgroundColor: theme.palette.background.cyanBlue,
      fontSize: "10px",
      textTransform: "none",
      color: theme.palette.primary.lightText,
    },
  },
  para: {
    position: "absolute",
    fontSize: 20,
    // top: "20px",
    // left: "20px",
    color: "white",
    transition: "all .5s",
    // visibility: "hidden",
    opacity: 0,
    "&:hover": {
      visibility: "visible",
      opacity: 1,
    },
  },
  appbar: {
    position: "static",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    top: 95,
    background: theme.palette.background.light,
    padding: 6,
    paddingBottom: 0,
    marginBottom: 5,
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
    [theme.breakpoints.up("xl")]: {
      // top: 80,
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 20,
  },
  modalContainer: {
    height: "100vh",
    width: "100vw",
    padding: 20,

    // backgroundColor: "rgba(0, 0, 0, 0.3)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: 500,
    // height: 300,
    backgroundColor: "white",
    boxShadow: "0px 0px 7px 0px rgba(148,146,148,1)",
    borderRadius: 10,
    zIndex: 20,
    padding: 10,
    paddingTop: 40,
    position: "relative",
    "& .closeBtn": {
      height: 30,
      width: 30,
      zIndex: 10,
      position: "absolute",
      right: 20,
      top: 10,
      cursor: "pointer",
    },
  },
  contactFormRoot: {
    "& .contact-form-container": {
      // "& .contact-form-row": {
      //   display: "grid",
      //   // gap: "10px",
      //   "& b": {
      //     color: theme.palette.primary.danger,
      //   },
      //   "& .MuiOutlinedInput-notchedOutline": {
      //     borderRadius: 0,
      //   },
      //   // "& label": {
      //   //     color: theme.palette.primary.darkText,
      //   //     "& b": {
      //   //         color: theme.palette.primary.danger,
      //   //     }
      //   // },
      //   // "& .MuiInput-underline": {
      //   //     borderBottom: `solid 1px ${theme.palette.secondary.main}`
      //   // },
      //   // "& .MuiInput-underline:before": {
      //   //     borderBottom: `solid 1px ${theme.palette.secondary.main}`
      //   // },
      //   // "& .MuiInput-underline:after": {
      //   //     borderBottom: `solid 1px ${theme.palette.secondary.active}`
      //   // },
      // },
      // "& .row-100": {
      //   gridTemplateColumns: "100%",
      // },
      // "& .row-50": {
      //   gridTemplateColumns: "65% 35%",
      //   [theme.breakpoints.down(600)]: {
      //     gridTemplateColumns: "100%",
      //   },
      // },
      // "& .flex-column": {
      //   display: "flex",
      //   flexDirection: "column",
      // },
      // "& .contact-form-item": {
      //   gridColumn: "1 / span 2",
      //   padding: 10,
      //   "&.action-item": {
      //     [theme.breakpoints.down(960)]: {
      //       textAlign: "center",
      //     },
      //   },
      // },
      "& .contact-form-item-left": {
        // height: 150,
      },
      // "& .contact-form-item-right": {
      //   gridColumn: "2 / span 1",
      //   padding: "0 0 0 0",
      //   [theme.breakpoints.down(600)]: {
      //     gridColumn: "1 / span 2",
      //   },
      //   "& .contact-form-group": {
      //     padding: "0 0 10px 10px",
      //     "& button": {
      //       width: "100%",
      //       color: theme.palette.primary.lightText,

      //       background: theme.palette.secondary.main, //theme.palette.secondary.main,
      //       padding: 10,
      //       borderRadius: 0,
      //       "&.MuiButton-contained": {
      //         boxShadow: "none",
      //         color: theme.palette.primary.lightText,
      //       },
      //       "& .MuiButton-label": {
      //         color: theme.palette.primary.lightText,
      //       },
      //     },
      //   },
      // },
      "& .action-item button": {
        width: 140,
        color: theme.palette.secondary.lightText,
        background: theme.palette.secondary.main,
      },
      "& .contact-form-group": {
        textAlign: "left",
        marginBottom: 2,
      },
    },
  },
}));

const HotelPackageDetail = (props) => {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedIndexCategory, setSelectedIndexCategory] = useState(0);
  const [selectedCategoryData, setSelectedCategoryData] = useState();
  // const [selectedCategory, setSelectedCategory] = useState(0);
  const [packageCategories, setPackageCategories] = React.useState([]);
  const [packageCategoriesData, setPackageCategoriesData] = React.useState();
  const [open, setOpen] = React.useState(true);
  const [sortbyopen, SetSortbyopen] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [showModal, setShowModal] = React.useState(
    AppConstant.holidaysFormShow
  );

  const [categoryDetail, setCategoryDetail] = React.useState([]);
  const [categoryname, setCategoryName] = React.useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [severity, setSeverity] = React.useState({
    issuccess: false,
    iserror: false,
    isinfo: false,
    iswarning: false
  });
  const [alertMessage, setAlertMessage] = React.useState("");

  // console.log(packageCategoriesData);

  function capitalize(s) {
    // console.log(first)
    return s[0].toUpperCase() + s.slice(1);
  }

  const handleClickCategory = (index, categoryName) => {
    // console.log("first");
    if (selectedIndexCategory === index) {
      setSelectedIndexCategory("");
    } else {
      setSelectedIndexCategory(index);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const sortHandleClick = () => {
    SetSortbyopen(!sortbyopen);
  };

  useEffect(() => {}, []);

  const handleListItemClick = (event, indexx, index, categoryId) => {
    setSelectedIndexCategory(indexx);
    // console.log(index);
    setSelectedIndex(index);
    apiCall(
      WebApi.getPackageCategoryDetails,
      { categoryId: categoryId },
      (response) => {
        // console.log("hello",response)

        if (response.data) setPackageCategoriesData(response.data);
        if (response.data) setPackageCategories(response.data.packages);
        //  console.log(response.data)
      }
    );
  };

  // const handleClick=()=>{
  //   props.history.push("packageinfo");
  // }

  function PackageDetails(data, packageType, categoryId, packageCategoryId) {
    // console.log(data, packageType, categoryId, packageCategoryId);
    // return;
    props.history.push("/packages/packageinfo", {
      data,
      packageType,
      categoryId,
      packageCategoryId,
    });

    // alert(data);
  }

  let selectedData = {};

  useEffect(() => {
    apiCall(WebApi.getPackageCategory, {}, (response) => {
      if (response.data) {
        // console.log(response.data, "categories");
        // console.log(response.data[0]);
        setCategories(response.data);
        setCategoryName(response?.data[0]?.packageTitle);
        setSelectedCategoryData(response.data[0]);
        selectedData = response.data[0];
        response.data.map((data, index) => {
          // console.log(data, "packageCategories");
          if (data.packageType == "D") {
            setCategoryDetail(data.packagesCategories);
          }
        });

        apiCall(
          WebApi.getPackageCategoryDetails,
          // { categoryId: props.location.state.name },
          {
            categoryId:
              response?.data[0]?.packagesCategories[0]?.packageCategoryId ?? 0,
          },
          (responsee) => {
            if (responsee.data) setPackageCategoriesData(responsee.data);
            if (responsee.data) setPackageCategories(responsee.data?.packages);
          }
        );
      }
    });
  }, []);
  // console.log(categoryDetail)
  // console.log( " package type ",props.location.state.packageType)
  // console.log(" index " ,props.location.state.index)
  // console.log("package name ", props.location.state.name);

  // console.log(selectedCategoryData);

  return (
    <GridContainer
      // style={{ background: "#f5f5f5", paddingTop: 20 }}

      className={classes.root}
      style={{
        // paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "white",
        paddingLeft: isMobile && 10,
        paddingRight: isMobile && 10,
      }}
    >
      {showModal && (
        <div className={classes.modalContainer}>
          <div className={classes.formContainer}>
            <img
              onClick={() => setShowModal(false)}
              className="closeBtn"
              src={closeBtn}
            />
            <div className={classes.contactFormRoot}>
              {/* <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              /> */}

              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  phone: "",
                  email: "",
                  message: "",
                }}
                validationSchema={Yup.object().shape({
                  firstname: Yup.string().required("First Name is Required"),
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("e-Mail address is Required"),
                  message: Yup.string().required("Message/Comment is Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  // console.log("values", values);

                  setSubmitting(true);
                  apiCall(WebApi.addConcatus, values, (response) => {
                    if(response.success === true) {
                      setAlertMessage("Sent your query successfully! We'll reply you soon.");
                      setSeverity(prvState => ({
                        ...prvState,
                        issuccess: true
                      }));
                      setShowModal(false);
                    } else {
                      setAlertMessage("Failed to send your query!");
                      setSeverity(prvState => ({
                        ...prvState,
                        iserror: true
                      }));
                    }
                  });
                }}
              >
                {({ values, errors, touched, handleChange, setFieldValue }) => (
                  <Form>
                    <div className="contact-form-container">
                      <div className="contact-form-row row-50">
                        <div className="contact-form-item-left flex-column">
                          <div className="">
                            {/* <label>Comment or Message <b>*</b></label> */}
                            <TextField
                              name="message"
                              value={values.message}
                              onChange={(event) => {
                                setFieldValue("message", event.target.value);
                              }}
                              multiline
                              rows={11}
                              fullWidth
                              placeholder="Enter Your Message/Comment"
                              variant="outlined"
                              label="Comment or Message (mandatory)"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="contact-form-item-right flex-column">
                          <div className="contact-form-group">
                            {/* <label>First Name <b>*</b></label> */}
                            <TextField
                              name="firstname"
                              value={values.firstname}
                              onChange={(event) => {
                                setFieldValue("firstname", event.target.value);
                              }}
                              fullWidth
                              placeholder="Enter First Name"
                              variant="outlined"
                              label="First Name (mandatory)"
                            />
                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className="error"
                            />
                          </div>

                          <div className="contact-form-group">
                            {/* <label>Last Name</label> */}
                            <TextField
                              name="lastname"
                              value={values.lastname}
                              onChange={(event) => {
                                setFieldValue("lastname", event.target.value);
                              }}
                              fullWidth
                              placeholder="Enter Last Name"
                              variant="outlined"
                              label="Last Name"
                            />
                          </div>
                          <div className="contact-form-group">
                            {/* <label>Phone</label> */}
                            <TextField
                              name="phone"
                              value={values.phone}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value);
                              }}
                              type="number"
                              fullWidth
                              placeholder="Enter Phone Number"
                              variant="outlined"
                              label="Phone"
                            />
                          </div>
                          <div className="contact-form-group">
                            {/* <label>Email <b>*</b></label> */}
                            <TextField
                              name="email"
                              value={values.email}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                              }}
                              fullWidth
                              placeholder="Enter e-Mail Address"
                              variant="outlined"
                              label="Email (mandatory)"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          </div>
                          <div className="contact-form-group">
                            <Button
                              variant="contained"
                              endIcon={<Send />}
                              type="submit"
                            >
                              Send
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <style>{`
            .oa-navbar-sticky {
                z-index: 1001;
                color:'#000'
            }
            `}</style> */}
            </div>
          </div>
        </div>
      )}
      <GridContainer>
        <AppBar
          // position="sticky"
          style={{ boxShadow: "0px 0px 5px -2px #757575", width: "100%" }}
          className={classes.appbar}
        >
          <Grid className={classes.titleContainer}>
            <GridContainer
              style={{
                marginBottom: 10,
                width: 1440,
              }}
            >
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Typography
                  style={{
                    color: theme.palette.primary.defaultDark,
                    fontSize: 15,
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  onClick={() => props.history.push("/")}
                >
                  Home
                </Typography>
                <Typography
                  style={{
                    color: theme.palette.secondary.crimson,
                    fontSize: 15,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  Destinations
                </Typography>
              </Breadcrumbs>
            </GridContainer>
          </Grid>
        </AppBar>
        <Grid item md={3} xs={12} style={{ marginTop: 20 }}>
          <GridContainer spacing={3}>
            {categories &&
              categories?.map(
                (category, indexx) =>
                  category.packageTitle !== null && (
                    <Grid item md={12} xs={12}>
                      <Card
                        style={{
                          // marginLeft: isMobile ? 0 : "25px",
                          marginRight: isMobile ? 0 : "25px",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          // console.log(category.packageTitle);
                          setSelectedCategoryData(category);
                          setCategoryName(category.packageTitle);
                          handleClickCategory(indexx);
                        }}
                      >
                        <List
                          disablePadding
                          component="nav"
                          className={classes.root1}
                        >
                          <ListItem button onClick={handleClick}>
                            <ListItemText
                              primary={category?.packageTitle
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.map(
                                  (s) =>
                                    s.charAt(0).toUpperCase() + s.substring(1)
                                )
                                .join(" ")}
                            />
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse
                            in={indexx === selectedIndexCategory}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Divider />
                            <List component="div" disablePadding>
                              {category?.packagesCategories &&
                                category?.packagesCategories?.map(
                                  (value, index) => {
                                    return (
                                      <ListItem
                                        button
                                        selected={
                                          // packageCategoriesData?.packageCategoryName ===
                                          //   value.packageCategoryName.split(",")[0]
                                          packageCategoriesData?.packageCategoryId ==
                                            value.packageCategoryId &&
                                          selectedIndex === index
                                        }
                                        onClick={(event) => {
                                          // console.log(
                                          //   packageCategoriesData?.packageCategoryName,
                                          //   value.packageCategoryName.split(",")[0]
                                          // );
                                          // console.log(
                                          //   packageCategoriesData?.packageCategoryId,
                                          //   value.packageCategoryId
                                          // );
                                          event.stopPropagation();
                                          handleListItemClick(
                                            event,
                                            indexx,
                                            index,
                                            value.packageCategoryId
                                          );
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <span style={{ fontSize: "13px" }}>
                                              {
                                                value.packageCategoryName.split(
                                                  ","
                                                )[0]
                                              }
                                            </span>
                                          }
                                        />
                                      </ListItem>
                                    );
                                  }
                                )}
                            </List>
                          </Collapse>
                        </List>
                      </Card>
                    </Grid>
                  )
              )}

            {/* <Grid item md={12}>
              <Card
                style={{
                  marginLeft: "25px",
                  marginRight: "25px",
                  borderRadius: "10px",
                }}
              >
                <List disablePadding component="nav" className={classes.root1}>
                  <ListItem button onClick={sortHandleClick}>
                    <ListItemText primary="Indian Destination" />
                    {sortbyopen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={sortbyopen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        // selected={selectedIndex === index}
                        // onClick={(event) =>
                        //   handleListItemClick(event, index, value.packageCategoryId)
                        // }
                      >
                        <ListItemSecondaryAction>
                          <Checkbox
                            className={classes.Checkbox}
                            edge="start"
                            // onChange={props.handleBusTypeToggle(value)}
                            // checked={props.busType.indexOf(value) !== -1}
                          />
                        </ListItemSecondaryAction>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "13px" }}>
                              Malaysia holiday package
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
              </Card>
            </Grid> */}
          </GridContainer>
        </Grid>
        <Grid item md={9} style={{ marginTop: 20 }} xs={12}>
          <Grid container spacing={isMobile ? 0 : 2}>
            {packageCategories &&
              packageCategories?.map((category, index) => {
                return (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ marginBottom: isMobile && 10 }}
                  >
                    <GridContainer>
                      <Grid
                        item
                        className="destination-box"
                        onClick={() => {
                          // console.log(categoryname);
                          // console.log(category.packagesCategories);
                          PackageDetails(
                            category.packageId,
                            categoryname,
                            packageCategories,
                            props?.location?.state?.packageType
                          );
                        }}
                      >
                        <img
                          src={
                            SERVER_URL +
                            "packageadmin/ImageReaderArticle?path=/" +
                            selectedCategoryData?.packageType +
                            "/" +
                            // props?.location?.state?.name +
                            packageCategoriesData.packageCategoryId +
                            "/" +
                            category.packageId +
                            "/" +
                            category.packageImage
                          }
                          width="100%"
                        />
                        <p>
                          {" "}
                          {category.duration.toalDay}D/
                          {category.duration.totalNight}N
                        </p>
                        <div className="destination-main-text">
                          <p>
                            {" "}
                            {category.packageName
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (s) =>
                                  s.charAt(0).toUpperCase() + s.substring(1)
                              )
                              .join(" ")}
                          </p>
                          {/* <strike>₹22,999.00</strike> */}
                          <span style={{ fontFamily: "Roboto" }}>
                            {currencyFormatter(category.priceStartingFrom)}
                          </span>
                        </div>
                      </Grid>
                    </GridContainer>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </GridContainer>
      <OaPopperAlert
        severity={severity}
        setseverity={setSeverity}
        alertmessage={alertMessage}
      />
    </GridContainer>
  );
};

export default HotelPackageDetail;
