import React, { useEffect, createContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import dashRoutes from "routes";
import { AppConstant } from "appConstant";

export const AppStateContext = createContext();

export default function AppStateProvider({ children }) {
  const [message, SetMessage] = React.useState();
  const [error, SetError] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState();
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const [timerInitialMinute, SetTimerInitialMinute] = React.useState(0);
  const [timerInitialSecond, SetTimerInitialSecond] = React.useState(0);
  const [routes, setRoutes] = React.useState(dashRoutes);
  const [secLogo, setSecLogo] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState();
  const [userType, setUserTypeb2c] = React.useState();

  const state = {
    SetMessage,
    SetError,
    setConfirmation,
    isConfirmed,
    setOpen,
    setIsConfirmed,
    SetTimerInitialMinute,
    SetTimerInitialSecond,
    timerInitialMinute,
    timerInitialSecond,
    secLogo,
    setSecLogo,
    routes,
    setRoutes,
    userProfile,
    setUserProfile,
    userType,
    setUserTypeb2c
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirmation = () => {
    setIsConfirmed(true);
    setOpen(false);
  };

  useEffect(() => {
    if (message !== undefined) setOpen(true);
  }, [message]);

  useEffect(() => {
    if (error !== undefined) setOpen(true);
  }, [error]);

  return (
    <>
      <AppStateContext.Provider value={state}>
        {children}
      </AppStateContext.Provider>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{AppConstant.domainName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message ? message : confirmation ? confirmation : error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {confirmation ? "Cancel" : "OK"}
          </Button>
          {confirmation && (
            <Button onClick={handleConfirmation} color="primary" autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
