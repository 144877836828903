import React from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AirAsia from "assets/img/AirAsiaThumbnail.jpg";
import AirIndia from "assets/img/AirIndiaThumbnail.JPG";
import GoAir from "assets/img/GoAirThumbnail.JPG";
import Indigo from "assets/img/IndiGoThumbnail.JPG";
import SpiceJet from "assets/img/SpiceJetThumbnail.JPG";
import Vistara from "assets/img/VistaraThumbnail.JPG";
import AkasaAir from "assets/img/AkasaAir.png";
import AllianceAir from "assets/img/AllianceAir.png";
import FlyBig from "assets/img/FlyBig.png";
import { COLORS } from "assets/css/CssConstants";

const useStyles = makeStyles(theme => ({
    heading: {
        fontWeight: 600,
        fontSize: 20,
        textAlign: "center",
        marginTop:"25px"
    },
    cell: {
        verticalAlign: "middle",
        padding: "1rem",
        textAlign: "center",
    },
    supportText: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    contact: {
        fontWeight: 600,
    },
}));

const AirlineContact = () => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Grid container style={{ marginTop: 65, padding: "15px 5%" }}>
            <Grid item xs={12}>
                <Typography className={classes.heading}>Airline Contacts</Typography>
            </Grid>

            <Grid item xs={12}>
                <table
                    cellSpacing={0}
                    cellPadding={0}
                    className="table"
                    style={{ width: "100%", marginTop: 20 }}
                >
                    <tbody>
                        <tr style={{ background: theme.palette.primary.main, height: 30 }}>
                            <th style={{ color: theme.palette.primary.lightText }}>Airline</th>
                            <th style={{ color: theme.palette.primary.lightText  }}>Customer Support</th>
                            <th style={{ color: theme.palette.primary.lightText  }}>Telephone</th>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={AirAsia} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                    Air Asia Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    +91 80 6766 2222
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={AirIndia} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                    Air India Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    0124 264 1407
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={GoAir} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                    GoAir Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    1800 210 0999
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={Indigo} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                    Indigo Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    0124-6173838, +91-9910383838
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={SpiceJet} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                    SpiceJet Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    +91-9871803333, +91-9654003333
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={Vistara} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                    Vistara Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    +91-9289228888
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={AllianceAir} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                   Alliance Air Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    +91-4442554255
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={FlyBig} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                   FlyBig Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    +91-9910655655
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.cell}>
                                <img src={AkasaAir} height={100} width={230} />
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.supportText}>
                                   Akasa Air Support
                                </Typography>
                            </td>
                            <td className={classes.cell}>
                                <Typography className={classes.contact}>
                                    +91-9606112131
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Grid>
        </Grid>
    );
};

export default AirlineContact;