import React, { useState, useEffect } from 'react';
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import { apiCall } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import MarkupTable from './MarkupTable';


const FlightMarkup = (props) => {

    const [errorText, setErrorText] = useState('');
    const [markupList, setMarkupList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    let tabs = [
        { name: 'flight', label: 'Flight Markup', url: '/admin/markup/flight' },
    ];
    let currentTab = 'flight';

    useEffect(() => {
        if (isLoading) {
            apiCall(WebApi.getFlightMarkupList, {}, (response) => {
                setMarkupList(response.data);
                setIsLoading(false);
                setIsError(response.success === false);
                setErrorText(response.message);
            });
        }
    }, [isLoading]);

    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary' />
            {isLoading && <OaLoading />}
            {isError && <OaError details={errorText} />}
            {!isLoading && !isError && <MarkupTable markupList={markupList} />}
        </OaCard>
    )
}

export default FlightMarkup;