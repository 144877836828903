import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { CircularProgress, TextField, FormControl , Checkbox,FormControlLabel, Divider, useTheme} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import WebApi from "api/ApiConstants";
import FlightTicketResult from "pages/report/flight/print/FlightTicketResultPrint";
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import { apiCall } from 'oautils/oaDataUtils';

import OaFormAlerts from 'pages/components/OaFormAlerts';
import Send from '@material-ui/icons/Send';
import Save from '@material-ui/icons/Save';
import { useHistory } from "react-router-dom";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import {getQueryVar} from 'oautils/oaCommonUtils';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import {pdfDownload, getImageData} from 'oautils/oaPdfUtils';

import {scrollToTop} from 'oautils/oaCommonUtils';

import 'assets/css/print-portrait.css';
//import 'assets/css/FlightTicket.css';

const useStyles = makeStyles(styles);

function FlightTicket(props) {

    const history = useHistory();

    
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');

    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isUpdateMarkup, setIsUpdateMarkup] = useState(false);
    const [markup, setMarkup] = useState(0);
    const [markupTemp, setMarkupTemp] = useState(0);
    const [isSendEmail, setIsSendEmail] = useState(false);
    const [receiverEmail, setReceiverEmail] = useState("");
    const [state, setState] = useState({
        isAddressHide: false,
        isFareHide: false,
        isPerPassenger: false,
        isGSTHide: false,
      });
    const ticketId = getQueryVar({...props, q: 'ticketId'});

    const isAdd = getQueryVar({...props, q: 'isAddress'});
    const isFare = getQueryVar({...props, q: 'isFare'});
    const isPerPsg= getQueryVar({...props, q: 'isPerPassenger'});
    const isGST= getQueryVar({...props, q: 'isGST'});

    const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
    };

    const getTicketData = (transactionId) => {
        return new Promise((resolve, reject) => {
            WebApi.getTicketData({"transactionId": transactionId} , (response) => {
                if(response !== null && response.success){
                    resolve(response.data);
                }
            },(error)=>{
                reject([]);
                // console.log(error);
            })
        })
    }

    function getIcon() {
        return !isSaving ? <Send /> : <CircularProgress size="24px"/>
    }

    function getIcon1() {
        return !isSaving ? <Save /> : <CircularProgress size="24px"/>
    }
   
    useEffect(() => {
        setLoading(true);
        if( ticketId > 0) {
            getTicketData(ticketId).then( function (data){
                setData(data);
                setReceiverEmail(data.agentInfo ? data.agentInfo.agentEmail:'');
                setLoading(false);
                setMarkup(data.flightSummary.agentMarkup);
                setMarkupTemp(data.flightSummary.agentMarkup);

                setState({isAddressHide:isAdd === 'true', isFareHide:isFare === 'true', isPerPassenger:isPerPsg === 'true', isGSTHide: isGST === 'true'});
                setTimeout(() => {
                    window.print();
                    window.addEventListener("afterprint", history.goBack());
                }, 1000);   
            });
        }
    }, []);

    function printClick() {
        window.print();
    }

    const classes = useStyles();

    const {isAddressHide, isFareHide, isPerPassenger, isGSTHide} = state;

    const handleMarkupSave = () => {
        setIsSaving(true);
        const markup1 = markup == ''?0:markup;
        setMarkupTemp(markup1);
        apiCall(WebApi.doMarkupUpdate, {'markup': markup1, 'tid': ticketId}, function(response){
            if(response.success === true){
                setIsError(false);
                setIsSuccess(true);
                setIsSaving(false);
                setInfoText(response.message);
                scrollToTop(0);
            } else {
                setIsError(true);
                setIsSaving(false);
                setInfoText(response.message);
                scrollToTop(0);
            }
        });
    };

    function downloadETicket(e){
        e.target.setAttribute('disabled', 'disabled');
        pdfDownload('section-to-print', 'p', 'eticket-' + ticketId)
        e.target.removeAttribute('disabled');
    }

    function sendEmail() {
        setIsSaving(true);
        getImageData('section-to-print', 'p', function(imageData){
            apiCall(WebApi.emailETicket, {'email': receiverEmail, 'body': "<!DOCTYPE html><html><body><img src='"+imageData+"'/></body></html> "}, function(response){
                if(response.success === true){
                    setIsError(false);
                    setIsSuccess(true);
                    setIsSaving(false);
                    setInfoText('Send email request registered');
                }else{
                    setIsError(true);
                    setIsSaving(false);
                    setInfoText('Email server busy try after sometime');
                }
            });
        });
    }

    
const theme=useTheme();
return (
    <>
    {isLoading ? 
        <div style = {{"display":"flex","justifyContent":"center","alignItems":"center", "height": "-webkit-fill-available"}}>
            <CircularProgress disableShrink />
        </div>
     : <>
     
     <OaFormAlerts 
        isSaving={isSaving} 
        isSuccess={isSuccess} 
        isError={isError} 
        infoText={infoText} 
        setIsError={setIsError} 
        setIsSuccess={setIsSuccess} 
    />
     <OaCard className="section-not-to-print" >
     <FormControlLabel
         control={<Checkbox color="primary" checked={isAddressHide} onChange={handleChange('isAddressHide')} value="isAddressHide"/>}
         label="Hide Address"
     />
     <FormControlLabel
         control={<Checkbox color="primary" checked={isFareHide} onChange={handleChange('isFareHide')} value="isFareHide"/>}
         label="Hide Fare"
     />

    <FormControlLabel
        control={<Checkbox color="primary" checked={isGSTHide} onChange={handleChange('isGSTHide')} value="isGSTHide"/>}
        label="Hide GST"
    />

     <FormControlLabel
         control={<Checkbox color="primary" checked={isPerPassenger} onChange={handleChange('isPerPassenger')} value="isPerPassenger"/>}
         label="Passenger Wise" 
     />
        
         <Button color="primary" onClick={printClick} className={classes.registerButton}>Print ETicket</Button>
         {/* <Button color="primary" onClick={downloadETicket} className={classes.registerButton}>Download ETicket</Button> */}
 
         {/* <a href="mailto:dev@oari.co?Subject=Booking%20Ticket%20Confirmation" target="_top"> */}
         {/* <Button color="primary" onClick={()=>{setIsSendEmail(!isSendEmail); setIsUpdateMarkup(false);}} className={classes.registerButton}>Email</Button> */}
         {/* </a> */}
         
   
     <Button color="primary" onClick={()=>{setIsUpdateMarkup(!isUpdateMarkup); setIsSendEmail(false)}} className={classes.registerButton}>Update Markup</Button>
     {isUpdateMarkup && 
         <GridContainer >
             <GridContainer justifyContent="center" >
                 <GridItem ><h4 style={{color:theme.palette.secondary.lightIndigo}}>UPDATE MARKUP</h4></GridItem>
             </GridContainer>
             <GridContainer justifyContent="center">
                 <GridItem >
                     <FormControl fullWidth={true}>
                         <TextField variant="outlined"  name="markup" label="Markup Value" md={4}  value={markup} onChange={event => {
                             const { value } = event.target;
                             setMarkup(value);
                         }}/>
                     </FormControl>
                 </GridItem>
                 <GridItem >
                     <Button color="primary" className={classes.registerButton}  endIcon={getIcon1()} disabled={isSaving} onClick={handleMarkupSave}>UPDATE</Button>
                 </GridItem>
             </GridContainer>
     </GridContainer>
     } 

     {isSendEmail && 
         <GridContainer >
             <GridContainer justifyContent="center" >
                 <GridItem ><h4 style={{color:theme.palette.secondary.lightIndigo}}>SEND EMAIL</h4></GridItem>
             </GridContainer>
             <GridContainer justifyContent="center">
                 <GridItem >
                     <FormControl fullWidth={true}>
                         <TextField variant="outlined"  name="receiverEmail" label="Receiver Email" md={4}  value={receiverEmail} onChange={event => {
                             const { value } = event.target;
                             setReceiverEmail(value);
                         }}/>
                     </FormControl>
                 </GridItem>
                 <GridItem >
                     <Button color="primary" onClick={sendEmail} className={classes.registerButton}  endIcon={getIcon()} disabled={isSaving}>SEND</Button>
                 </GridItem>
             </GridContainer>
     </GridContainer>
     } 
     </OaCard>
   
                <div id="section-to-print" className="section-to-print">
                    {
                        data.onwardJourney &&
                        <>
                        {
                            isPerPassenger ? (data.passengers && data.passengers.map((passenger, passengerindex) => {
                                return (
                                <FlightTicketResult data={data} state={state} passenger={passenger} passengerindex={passengerindex} key={passengerindex} 
                                    markup={markupTemp} journey={data.onwardJourney} journeyType={'onward'}></FlightTicketResult>);
                            })) :<FlightTicketResult data={data} state={state} markup={markupTemp} journey={data.onwardJourney} journeyType={'onward'}></FlightTicketResult>
                        }
                        </>
                    }
              
                    {
                        data.returnJourney.length > 0 && 
                        <>
                        {
                            isPerPassenger ? (data.passengers && data.passengers.map((passenger, passengerindex) => {
                                return (
                                <><FlightTicketResult data={data} state={state} passenger={passenger} passengerindex={passengerindex} 
                                    key={passengerindex} markup={markupTemp} journey={data.returnJourney} journeyType={'return'}></FlightTicketResult>
                                   
                                    </>);
                            })) :<FlightTicketResult data={data} state={state} markup={markupTemp} journey={data.returnJourney} journeyType={'return'}></FlightTicketResult>
                        }
                        </>
                    }

                
                </div></>
    }    
    </>
)
}

export default FlightTicket