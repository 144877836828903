import React from "react";
import CustomDatePicker from "oahoc/CustomDatePicker";
import dateFnsFormat from "date-fns/format";
import { makeStyles } from "@material-ui/styles";
import { CalendarToday } from "@material-ui/icons";

const OaPopperDatepicker = props => {

    const classes = useStyles();
    const rootRef = React.useRef();
    const [datePickerOpen, setDatePickerOpen] = React.useState(false);

    const toggleReturn = () => {
        if(props.settogglert !== undefined) {
            props.settogglert(true);
        }
    }

    const outOfContexArea = (event) => {
        if (rootRef.current && !rootRef.current.contains(event.target)) {
            setDatePickerOpen(false);
        }
    }

    // React.useEffect(() => {
    //     document.addEventListener('mousedown', outOfContexArea);

    //     return () => {
    //         document.removeEventListener('mousedown', outOfContexArea);
    //     }
    // }, []);

    return (
        <div className={classes.root} ref={rootRef}>
            <div className="oa-form-element-card" onClick={() => setDatePickerOpen(!datePickerOpen)}>
                <div className="oa-form-element-label">
                    <span>{props.labeltxt}</span>
                </div>
                <div className="oa-form-element-primary">
                    <span className="icon-wrap">
                        <CalendarToday/>
                    </span>
                    {props.disabledtxt != undefined && props.disabledtxt != null && props.showinput == false ?
                    <div className="disabled-text" onClick={toggleReturn}>
                        {props.disabledtxt}
                    </div> :
                    <>
                    <div className="enabled-text">
                        <span className="big">
                            {dateFnsFormat(props.selected, 'd')}
                        </span> {dateFnsFormat(props.selected, 'MMM')}'{dateFnsFormat(props.selected, 'yyyy')}
                    </div>
                    <CustomDatePicker {...props}
                        open={datePickerOpen}
                        onOpenChange={setDatePickerOpen}
                    />
                    </>
                    }
                </div>
                <div className="oa-form-element-secondary">
                    {props.secondarytxt}
                </div>
            </div>
        </div>
    );
}

export default OaPopperDatepicker;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        position: "relative",
        "& .date-picker": {
            display: 'none',
        },
        "& .oa-form-element-card": {
            width: "100%",
            padding: 6,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            height: 40
        },
        "& .oa-form-element-label": {
            color: theme.palette.primary.darkGray,
            fontWeight: 400,
            [theme.breakpoints.down(1500)]: {
                fontSize: '12px',
            },
        },
        "& .oa-form-element-primary": {
            marginTop: -2,
            color: theme.palette.primary.defaultText,
            position: 'relative',
            "& .react-datepicker-wrapper": {
                padding: 0,
                border: 0,
                height: 0,
            },
            "& .react-datepicker": {
                display: 'flex'
            },
            "& .icon-wrap": {
                position: 'absolute',
                right: 0,
                top: 2
            }
        },
        "& .oa-form-element-secondary": {
            whiteSpace: "nowrap",
            color: theme.palette.primary.defaultText,
            fontWeight: 400,
            [theme.breakpoints.down(1500)]: {
                fontSize: '12px',
            },
        },
        "& .disabled-text":{
            width: '100%',
            position: "absolute",
            left: 0,
            right: 0,
            bottom: -60,
            top: -40,
            display: "flex",
            alignItems: "center",
            padding: "10px 0 0"
        },
        "& .MuiInputBase-input": {
            fontWeight: 400,
            color: theme.palette.primary.defaultText,
            padding: "3px 0 2px",
        },
        "& .MuiInput-underline:before": {
            borderBottom: 'none',
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: 'none',
        },
        "& .MuiInput-underline:after": {
            borderBottom: 'none',
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child": {
            padding: "2px 0",
        },
        "& .react-datepicker-popper": {
            zIndex: 9,
        },
    }
}));
