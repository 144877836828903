import React, { useState } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDown from "assets/img/arrowdown.png";
import ArrowUp from "assets/img/arrowup.png";
import {
  Collapse,
  useMediaQuery,
  List,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import { AppConstant } from "appConstant";

import "components/HotelPage/HotelComponents/LeftNavFilter/LeftFilter.css";
import Rating from "@material-ui/lab/Rating";
import OaCard from "oahoc/OaCard";

export default function LeftNavFilter(props) {
  const [checkedValues, setCheckedValues] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [priceRangeVisible, setPriceRangeVisible] = useState(
    AppConstant.hotelFilterOpen
  );

  //console.log(props.data);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiList-root": {
        height: "23px !important",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "12px",
      },
      "& .MuiFormControlLabel-root": {
        //margin: "2px 0",
      },

      "& .MuiListItem-gutters": {
        padding: 0,
        marginLeft: 5,
      },
      "& .MuiCheckbox-root": {
        padding: "0px 0",
      },
      "& .MuiTypography-root": {
        fontSize: 11,
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.darkText,
      },
      "& .MuiRating-root": {
        fontSize: "1.1rem",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <OaCard>
        <div onClick={() => setPriceRangeVisible(!priceRangeVisible)}>
          <span className="left-nav-select" style={{ cursor: "pointer" }}>
            {props.label}
            {priceRangeVisible ? (
              <img src={ArrowUp} style={{ float: "right" }} />
            ) : (
              <img src={ArrowDown} style={{ float: "right" }} />
            )}
          </span>
        </div>
        <Collapse in={priceRangeVisible}>
          {props.data.map((name, index) => (
            <List
              component="div"
              key={index}
              disablePadding
              className={classes.root}
              // style={{ height: 26 }}
              style={{ height: "fit-content", minHeight: 25 }}
            >
              <FormControlLabel
                control={<Checkbox name="checkedC" />}
                label={
                  props.type === "rating" ? (
                    <Rating
                      name="read-only"
                      key={index}
                      value={name}
                      readOnly
                      sx={{
                        fontSize: "1rem",
                      }}
                    />
                  ) : (
                    // <div></div>
                    <ListItem>
                      <ListItemText>{name}</ListItemText>
                    </ListItem>
                  )
                }
                checked={props.checkedValues.some(
                  (value) => value.checkedName === name
                )}
                onChange={() => props.handleSelect(name, props.label)}
              />
            </List>
          ))}
        </Collapse>
      </OaCard>
    </div>
  );
}
