import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  withWidth,
  Popper,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import { setBusCities } from "redux/action";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { localforageGetItem } from "oautils/oaForageUtils";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.sub,
    },
    "& .MuiInputBase-input": {
      color:  theme.palette.secondary.sub,
    },
    // "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
    //   borderBottom: "none",
    // },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none"
    },
    minWidth: 860,
    [theme.breakpoints.down(768)]: {
      minWidth: "100%",
      maxWidth: "100%",
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
      padding: "0 25px",
      margin: "0 !important",
    },
  },
  swapIconButton: {
    "& .MuiIconButton-label": {
      color:  theme.palette.primary.lightText,
      background: theme.palette.primary.orange,
      borderRadius: 50,
      padding: 2,
    },
  },
  locationIcon: {
    color:  theme.palette.secondary.sub,
  },
  departureDate: {
    "& .MuiIconButton-label": {
      color:  theme.palette.secondary.sub,
    },
  },
  toggleBox: {
    "& .MuiToggleButton-root": {
      color:  theme.palette.primary.defaultText,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.lightText,
      backgroundColor:  theme.palette.secondary.darkBlue,
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.default,
    },
  },
  searchButtonBox: {
    width: 80,
    height: 30,
    "& .MuiButton-root": {
      // width: "100%",
      height: "100%",
      "& .MuiButton-label": {
        color:theme.palette.primary.defaultText,
      },
    },
  },
  BusSection_actions: {
    display: "flex",
    justifyContent: "end",
    marginTop: 10,
    // borderTop: `solid 0.5px ${COLORS.QUINARY_BLACK}`,
    padding: "15px 20px 10px",
  },
  BusSection_busDet: {
    "& .date-input": {
      paddingTop: 13,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.orange,
      }
    },
    "& .MuiGrid-grid-xs-12": {
      [theme.breakpoints.down(600)]: {
        minWidth: "100%",
      },
    },
  },
  BusSection_quicklinks: {
    borderTop: `solid 0.5px ${theme.palette.secondary.sheerLavender}`,
    padding: "10px 25px 0",
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
        border: `solid 1px ${theme.palette.primary.aqua}`,
        padding: "3px 10px",
        color: theme.palette.primary.aqua,
        borderRadius: 4,
        cursor: "pointer",
        width: 97,
        "&:hover": {
          border: `solid 1px ${theme.palette.secondary.active}`,
          color: theme.palette.secondary.active,
        },
        "& .qlink-icon-wrapper": {
          textAlign: "center",
          "& i": {
            fontSize: 15,
          },
          "& .material-icons": {
            fontSize: 17,
          }
        },
        "& .qlink-label-wrapper": {
          fontSize: 10,
          textAlign: "center",
        },
    }
  },
}));

function BusSection(props) {
  const busDepartureAutocompleteRef = useRef(null);
  const busDestinationAutocompleteRef = useRef(null);

  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  // const [data, setData] = useState({
  //   departure: "",
  //   destination: "",
  //   departureDate: new Date().toISOString(),
  //   class: "economy",
  // });

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const formikRef = React.useRef();
  const [corporateType, setCorporateType] = useState(null);

  let baseUrl = "/";

  let optionsBus = [
    {
      name: "busSearch",
      label: "SEARCH BUS",
      url: baseUrl + "buses",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "busMarkup",
      label: "MARK UP",
      url: "/admin/commission/bus",
      icon: <i className="material-icons">attach_money</i>,
    },
    // { name: 'busBank', label: 'BANK DETAILS', url: '/admin/management/bank-account', icon: (<i className="material-icons">%</i>) },
    {
      name: "busMoney",
      label: "ADD MONEY",
      url: "/admin/management/balance/master",
      icon: <i className="material-icons">post_add</i>,
    },
    {
      name: "busCancellation",
      label: "CANCELLATION",
      url: "/admin/booking-report/bus",
      icon: <i className="fas fa-ban"></i>,
    },
    // { name: 'searchHotel', label: 'DISCOUNTS', url: "#", disabled: true, icon: (<LocalOfferSharp style={{ fontSize: "27px" }} />) },
    // { name: 'searchHotel', label: 'COMMISSION', url: "#", disabled: true, icon: (<AccountBalanceWalletSharp style={{ fontSize: "27px" }} />) },
    {
      name: "busLedger",
      label: "LEDGER",
      url: "/admin/ledger",
      icon: <i className="material-icons">receipt_long</i>,
    },
    // { name: 'searchBus', label: 'MANAGE AGENT', url: "#", disabled: true, icon: (<FaceSharp style={{ fontSize: "27px" }} />) }
    {
      name: "busReport",
      label: "BUS REPORT",
      url: "/admin/booking-report/bus",
      icon: <i className="fas fa-bus"></i>,
    },
  ];

  useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
  }, []);

  const customPopper = function(props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom-start"
        style={{}}
      />
    );
  };

  const swapDepartureDestination = () => {
    // console.log("swapping ");
    const temp = formikRef.current.values.DestinationId;
    formikRef.current.setFieldValue(
      "DestinationId",
      formikRef.current.values.OriginId
    );
    formikRef.current.setFieldValue("OriginId", temp);
  };

  const classes = useStyles();

  useEffect(() => {
    if (isLoading) {
      const length = props.busCities ? Object.keys(props.busCities).length : 0;

      // console.log(length);
      if (length > 0) {
        setIsLoading(false);
      } else {
        apiCall(WebApi.getBusCity, {}, (response) => {
          if (response.success === true) {
            props.setBusCities(response.data.BusCities);

            // console.log(response.data.BusCities);
            setIsLoading(false);
          } else {
            setIsError(response.success);
            setInfoText(response.message);
          }
        });
      }
    }
  }, [isLoading]);

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    props.history.push({
      pathname: url,
    });
  }

  return (
    <Box className={classes.root} mx={4}>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          OriginId: "",
          DestinationId: "",
          DateOfJourney: new Date(),
          BusType: "economy",
        }}
        validationSchema={Yup.object().shape({
          OriginId: Yup.string().required("Origin is required"),
          DestinationId: Yup.string().required("Destination is required"),
        })}
        onSubmit={(values) => {
          if (values.OriginId.CityId > 0 && values.DestinationId.CityId > 0) {
            var params = {
              departureCityId: values.OriginId.CityId,
              departureCityName: values.OriginId.CityName,
              destinationCityId: values.DestinationId.CityId,
              destinationCityName: values.DestinationId.CityName,
              selectedDate: dateFnsFormat(values.DateOfJourney, "yyyy-MM-dd"),
            };

            props.history.push({
              pathname: "/bus/search-progress",
              state: params,
            });
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className={classes.BusSection_busDet}>
                <Grid
                  container
                  direction={props.width === "xs" ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item  xs={12} sm={5} md={3} className="location-input">
                    {props.busCities && (
                      <Autocomplete
                        fullWidth
                        PopperComponent={customPopper}
                        name="OriginId"
                        options={props.busCities || []}
                        getOptionLabel={(option) => option.CityName}
                        // options={busDepartureList}
                        // getOptionLabel={(o) => o.title || ""}
                        value={values.OriginId}
                        openOnFocus
                        getOptionSelected={(option, value) => {
                          return option.CityName == value.CityName;
                        }}
                        onChange={(event, newValue) => {
                          busDestinationAutocompleteRef.current.focus();
                          // setData({ ...data, departure: newValue });
                          setFieldValue("OriginId", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Departure"
                            inputRef={busDepartureAutocompleteRef}
                          />
                        )}
                        disablePortal={true}
                      />
                    )}

                    <ErrorMessage
                      name="OriginId"
                      component="div"
                      className="error"
                    />
                  </Grid>
                  
                  <Grid
                    item
                    container
                    xs={2}
                    style={{ justifyContent: "center" }}
                    className="swap-icon"
                  >
                    <IconButton
                      onClick={swapDepartureDestination}
                      aria-label="delete"
                      className={classes.swapIconButton}
                    >
                      <SwapHorizIcon />
                    </IconButton>
                  </Grid>
                  
                  <Grid item  xs={12} sm={5} md={3} className="location-input">
                    {props.busCities && (
                      <Autocomplete
                        fullWidth
                        PopperComponent={customPopper}
                        name="DestinationId"
                        // options={busDestinationList}
                        // getOptionLabel={(o) => o.title || ""}
                        options={props.busCities || []}
                        getOptionLabel={(option) => option.CityName}
                        value={values.DestinationId}
                        getOptionSelected={(option, value) => {
                          return option.CityName == value.CityName;
                        }}
                        onChange={(event, newValue) => {
                          setDepartureDateDialogOpen(true);
                          // setData({ ...data, destination: newValue });
                          setFieldValue("DestinationId", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Arrival"
                            inputRef={busDestinationAutocompleteRef}
                          />
                        )}
                        disablePortal={true}
                      />
                    )}
                    <ErrorMessage
                      name="DestinationId"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} md={4} className="date-input">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        KeyboardButtonProps={{
                          onFocus: (e) => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setDepartureDateDialogOpen(false);
                          },
                        }}
                        InputProps={{
                          onFocus: () => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        name="DateOfJourney"
                        open={departureDateDialogOpen}
                        onClose={() => setDepartureDateDialogOpen(false)}
                        onOpen={() => setDepartureDateDialogOpen(true)}
                        disablePast
                        className={classes.departureDate}
                        color="secondary"
                        disableToolbar
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        label="Departure Date"
                        value={values.DateOfJourney}
                        onChange={(e, v) => {
                          busDestinationAutocompleteRef.current.blur();
                          setDepartureDateDialogOpen(false);
                          // console.log(
                          //   document.getElementById("searchActionButton")
                          // );
                          // document.getElementById("searchActionButton").focus();
                          // setData({ ...data, departureDate: e });
                          setFieldValue("DateOfJourney", e);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessage
                      name="DateOfJourney"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>
              </Box>
              
              <Box className={classes.BusSection_actions}>
                <Box className={classes.searchButtonBox}>
                      <Button
                        id="searchActionButton"
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Search
                      </Button>
                    </Box>
              </Box>

              {userType && userType == "agent" && corporateType && corporateType != "corporate" && 
              <Box className={classes.BusSection_quicklinks}>
                {optionsBus && optionsBus.map((val, ind) => (
                    <div item className="quick-link-item" key={ind} onClick={() => openQuickLink(val.url)}>
                      <div className="qlink-icon-wrapper">
                        {val.icon}
                      </div>
                      <div className="qlink-label-wrapper">{val.label}</div>
                    </div>
                  ))}
              </Box>}
            </Grid>

            {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

const mapDispatchToProp = (dispatch) => {
  return {
    setBusCities: (busCities) => dispatch(setBusCities(busCities)),
  };
};

const mapStateToProp = (state, prop) => {
  return {
    busCities: state.bus.busCities,
  };
};

export default withRouter(
  connect(
    mapStateToProp,
    mapDispatchToProp
  )(withWidth()(BusSection))
);

const busDepartureList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Hyderabad", value: "hyderabad" },
  { title: "Bangalore", value: "bangalore" },
  { title: "Chennai", value: "chennai" },
];

const busDestinationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Hyderabad", value: "hyderabad" },
  { title: "Bangalore", value: "bangalore" },
  { title: "Chennai", value: "chennai" },
];
