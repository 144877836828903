import React, {useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import OaSaving from 'pages/components/OaSaving';

const OaFormAlerts = (props) => {

    const closeSuccessBar = () => {
        props.setIsSuccess(false);
    }

    const closeErrorBar = () => {
        props.setIsError(false);
    }

    return (
        <>
            <Collapse in={props.isSuccess} onClose={closeSuccessBar}>
                <Alert onClose={closeSuccessBar} severity="success" elevation={6} variant="filled" style={{marginBottom: "10px", color:'white'}}>
                    <AlertTitle style={{ color:'white'}}>{props.alertTitle ? props.alertTitle :'Success'}</AlertTitle>
                    {props.infoText}
                </Alert>
            </Collapse>
            <Collapse in={props.isError} onClose={closeErrorBar}>
                <Alert onClose={closeErrorBar} severity="error" variant="filled"  style={{marginBottom: "10px", color:'white'}} elevation={6}>
                    <AlertTitle style={{ color:'white'}}>{props.alertTitle ? props.alertTitle :'Error'}</AlertTitle>
                    {props.infoText}
                </Alert>
            </Collapse>
        </>
    )
}

export default OaFormAlerts;
