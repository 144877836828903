import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import image1 from "assets/img/geebaku-loading.gif";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import dateFnsFormat from "date-fns/format";
import WebApi, {
  POST_PAYGOAL_API_CALLBACK,
  EXT_REDIRECT_SERVER,
} from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { Divider, Grid, Button, useTheme } from "@material-ui/core";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { connect } from "react-redux";
// import { AppStateContext } from "layouts/AppStateProvider";
import { setTimerInitialMinute, setTimerInitialSecond } from "redux/action";
import { localforageGetItem } from "oautils/oaForageUtils";
import image1 from "assets/rive/hotelanimation.riv";
import Rive from "rive-react";
import { submitDataToPaygoal } from "pages/module/paymentgateway/Paygoal";
import { submitDataToRazorpay } from "pages/module/paymentgateway/Razorpay";
import { submitDataToCcAvenue } from "pages/module/paymentgateway/CcAvenue";
import { localforageSetItem } from "oautils/oaForageUtils";
import { submitDataToPayU } from "pages/module/paymentgateway/PayU";
import { submitDataToPhonepe } from "pages/module/paymentgateway/Phonepe";
import { POST_PHONEPE_API_CALLBACK } from "api/ApiConstants";

const HotelPaymentProgressPage = ({ location, history, ...props }) => {
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [priceChangedStop, setPriceChangedStop] = useState(false);
  const [blockData, setBlockData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [retryPayment, setRetryPayment] = useState(false);

  const [responseMessage, setResponseMessage] = useState(
    "Blocking room, Please wait"
  );

  const {
    values,
    hotelInfo,
    hotelSearch,
    totalFare,
    selectedRooms,
    hotelCode,
    traceId,
  } = location.state;

  // console.log("hotelInfo", hotelInfo);
  // console.log("hotelInfo", selectedRooms);
  // console.log("hotelSearch", hotelSearch);
  // console.log(props.hotelRoomParam);
  // console.log(selectedRooms[0].categoryId);
  // console.log("TRACE ID:", traceId);
  // console.log("props ", props);

  const param = {
    guestNationality: hotelSearch.guestNationality,
    hotelCode: hotelCode,
    hotelName: hotelInfo.hotelDetails.hotelName,
    hotelRoomsDetails: [],
    noOfRooms: hotelSearch.noOfRooms.toString(),
    resultIndex: props.hotelRoomParam.resultIndex,
    isVoucherBooking: true,
    //isIslandHopper:"false",
    isIslandHopper: props.hotelRoomParam.searchData.isIslandHopper,
    traceId: traceId, //trace ID added
    categoryId: selectedRooms[0].categoryId,
    paymentMode: "",
    checkInDate: hotelSearch.checkInDateDisplay,
    checkOutDate: hotelSearch.checkOutDateDisplay,
    countryCode: hotelSearch.countryCode,
    cityId: hotelSearch.cityId,
    cityName: hotelSearch.cityObj.cityName,
  };

  values.room.map((value, index) => {
    selectedRooms[index].roomInfo.hotelPassenger = [];
    if (selectedRooms[index].roomInfo.smokingPreference === "NoPreference") {
      selectedRooms[index].roomInfo.smokingPreference = 0;
    } else if (selectedRooms[index].roomInfo.smokingPreference === "Smoking") {
      selectedRooms[index].roomInfo.smokingPreference = 1;
    } else if (
      selectedRooms[index].roomInfo.smokingPreference === "NonSmoking"
    ) {
      selectedRooms[index].roomInfo.smokingPreference = 2;
    } else if (selectedRooms[index].roomInfo.smokingPreference === "Either") {
      selectedRooms[index].roomInfo.smokingPreference = 3;
    }
    value.adult &&
      value.adult.map((ad, secIndex) => {
        const temp = {
          age: ad.age.toString(),
          dob: ad?.dobDisplay,
          email: values.email,
          firstName: ad.firstName,
          lastName: ad.lastName,
          leadPassenger: secIndex === 0 ? "true" : "false",
          middlename: "",
          pAN: values.pan.toString(),
          passportExpDate: ad.expiryDate,
          passportIssueDate: ad.issueDate,
          passportNo: ad.passportNo,
          paxType: 1,
          phoneno: values.mobile.toString(),
          tidRef: 0,
          title: ad.title,
        };
        selectedRooms[index].roomInfo.hotelPassenger.push(temp);
      });
    value.child &&
      value.child.map((ch, secIndex) => {
        const temp = {
          age: ch.age.toString(),
          dob: ch?.dobDisplay,
          email: values.email,
          firstName: ch.firstName,
          lastName: ch.lastName,
          leadPassenger: "false",
          middlename: "",
          pAN: values.pan.toString(),
          passportExpDate: ch.expiryDate,
          passportIssueDate: ch.issueDate,
          passportNo: ch.passportNo,
          paxType: 2,
          phoneno: values.mobile.toString(),
          tidRef: 0,
          title: ch.title,
        };
        selectedRooms[index].roomInfo.hotelPassenger.push(temp);
      });
    param.hotelRoomsDetails.push(selectedRooms[index].roomInfo);
  });

  const saveTrnParamsToLocal = (paramsToSave) => {
    paramsToSave.values = values;
    paramsToSave.hotelSearch = hotelSearch;
    paramsToSave.hotelInfo = hotelInfo;
    paramsToSave.totalFare = totalFare;

    return new Promise((resolve, reject) => {
      localforageSetItem("hotel-transact", paramsToSave, () => {
        resolve();
      });
    });
  };

  async function showRazorPayWindow(trnResponse) {
    // setIsSaving(true);
    setRetryPayment(false);

    if (
      param.hotelRoomsDetails &&
      param.hotelRoomsDetails[0].hotelPassenger &&
      param.hotelRoomsDetails[0].hotelPassenger[0]
    ) {
      trnResponse.travelers = [
        { nameReference: param.hotelRoomsDetails[0]?.hotelPassenger[0]?.title },
      ];
      trnResponse.deliveryAddress = {
        firstName: param.hotelRoomsDetails[0]?.hotelPassenger[0]?.firstName,
        lastName: param.hotelRoomsDetails[0]?.hotelPassenger[0]?.lastName,
        email: param.hotelRoomsDetails[0]?.hotelPassenger[0]?.email,
        mobileNumber: param.hotelRoomsDetails[0]?.hotelPassenger[0]?.phoneno,
        phoneno: "",
        address: "",
      };

      // console.log(leadPassenger)
      trnResponse.amountDue = param.hotelRoomsDetails.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.price.commissionCharge.grossProductPrice,
        0
      );
    }
    let request = {
      paymentOptions: values.pgOption,
      trnResponse: trnResponse,
      selectedPaymentMethod: values.pgOption.method,
      theme: theme,
      trnType: "hotel-reservation",
      checkoutUrl: trnResponse.paymentUrl,
    };
    var response = await submitDataToRazorpay(request);
    setIsSaving(false);
    setIsError(response.isError);
    setIsSuccess(response.isSucess);
    setInfoText(response.infoText);
    setRetryPayment(response.retryPayment);
    setResponseMessage(response.responseMessage);

    if (response.isSucess) {
      submitData(response.orderId);
    } else {
      history.push(`/hotel/payment-status/${param.txnId}`);
    }
  }

  async function showPaygoalWindow(trnResponse) {
    trnResponse.amountDue = param.hotelRoomsDetails.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.price.commissionCharge.grossProductPrice,
      0
    );
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      // redirectUrl: `https://www.lookmyticket.com/lmt/hotel/payment-status/${trnResponse.transactionId}/success`,//http://oari.co:8080/emt/#/flight/payment-status/http://localhost:3000/psa_web/flight/payment-status/
      redirectUrl: `${EXT_REDIRECT_SERVER}hotel/hotelpaymentprogress/`, //http://oari.co:8080/emt/#/flight/payment-status/http://localhost:3000/psa_web/flight/payment-status/

      siteUrl: POST_PAYGOAL_API_CALLBACK,
      txnId: trnResponse.transactionId,
      paymentMode: "PayGoal",
      failUrl: `${EXT_REDIRECT_SERVER}hotel/payment-status/`,
      checkoutUrl: trnResponse.paymentUrl,
    };

    saveTrnParamsToLocal(param).then(() => {
      submitDataToPaygoal(request);
    });
  }

  async function showCcAvenueWindow(trnResponse) {
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=ccavenue",
      siteUrl: `${EXT_REDIRECT_SERVER}hotel/hotelpaymentprogress/`,
      txnId: trnResponse.transactionId,
      paymentMode: "CCAvenue",
      failUrl: `${EXT_REDIRECT_SERVER}hotel/payment-status/`,
      checkoutUrl: trnResponse.paymentUrl,
    };
    saveTrnParamsToLocal(param).then(() => {
      submitDataToCcAvenue(request);
    });
  }

  async function showPayUWindow(trnResponse) {
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=payu",
      siteUrl: `${EXT_REDIRECT_SERVER}hotel/hotelpaymentprogress/`,
      txnId: trnResponse.transactionId,
      paymentMode: "PayU",
      failUrl: `${EXT_REDIRECT_SERVER}hotel/payment-status/`,
      trnType: "hotel-reservation",
      checkoutUrl: trnResponse.paymentUrl,
    };
    saveTrnParamsToLocal(param).then(() => {
      submitDataToPayU(request);
    });
  }

  async function showPhonepeWindow(trnResponse) {
    saveTrnParamsToLocal(param);
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=phonepe",
      siteUrl: `${EXT_REDIRECT_SERVER}hotel/hotelpaymentprogress/`,
      failUrl: POST_PHONEPE_API_CALLBACK,
      txnId: trnResponse.transactionId,
      paymentMode: "phonepe",
      trnType: "hotel-reservation",
      checkoutUrl: trnResponse.paymentUrl,
      mobileNumber: param.hotelRoomsDetails[0]?.hotelPassenger[0]?.phoneno,
    };
    submitDataToPhonepe(request);
  }

  const processWalletTransaction = () => {
    submitData(null);
  };

  const processPayment = (userDetails) => {
    param.paymentMode = values.pgOption.method;
    WebApi.getHotelCreateTransaction(
      param,
      (response) => {
        if (response.success) {
          param.txnId = response.data.txnId;
          param.encTxnId = response.data.encTxnId;

          response.data.userDetails = userDetails;
          response.data.paymentMode = values.pgOption.method;
          response.data.transactionId = response.data.txnId;
          response.data.uniqueTransactionId = response.data.encTxnId;
          response.data.userType = userDetails.userTypeAbv;

          switch (values.payMode) {
            case "razorpay": {
              showRazorPayWindow(response.data);
              break;
            }

            case "paygoal": {
              showPaygoalWindow(response.data);
              break;
            }

            case "ccavenue": {
              showCcAvenueWindow(response.data);
              break;
            }

            case "cash": {
              userDetails.userTypeAbv == "S" && processWalletTransaction();
              break;
            }

            case "payu": {
              showPayUWindow(response.data);
              break;
            }
            case "phonepe": {
              showPhonepeWindow(response.data);
              break;
            }
          }
        } else {
          console.log("getHotelCreateTransaction failed", response);
          history.push(`/hotel/payment-status/0/failed`);
        }
      },
      (error) => {
        console.log("getHotelCreateTransaction error", error);
        history.push(`/hotel/payment-status/0/failed`);
      }
    );
  };

  useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-details", function (err, value) {
        if (value) {
          setUserType(value.userType ? value.userType : "b2c_user");
          // param.userType = value.userTypeAbv;

          if (
            param.hotelRoomsDetails &&
            param.hotelRoomsDetails[0].hotelPassenger &&
            param.hotelRoomsDetails[0].hotelPassenger[0]
          ) {
            processPayment(value);
          } else {
            console.log("param empty", param);
          }
        } else {
          console.log("failed to get user detail", err);
        }
      });
    }
  }, [isLoading]);

  const submitData = (orderId) => {
    param.orderId = orderId;
    // console.log("Block Request ", param);
    WebApi.getHotelBlock(
      param,
      (response) => {
        if (response.success === true) {
          // console.log(response.data);
          if (response.data.blockRoomResult.availabilityType === "Available") {
            setIsError(true);
            setInfoText("Cannot book online. Please contact operations team");
          } else {
            param.isPackageFare = response.data.blockRoomResult.isPackageFare;
            param.isDepartureDetailsMandatory = response.data.blockRoomResult.isDepartureDetailsMandatory;

            // response.data.blockRoomResult.isCancellationPolicyChanged = true;
            if (
              response.data.blockRoomResult.isCancellationPolicyChanged ===
              "true"
            ) {
              response.data.blockRoomResult.hotelRoomsDetails.map(
                (room, index) => {
                  param.hotelRoomsDetails[index].cancellationPolicies =
                    room.cancellationPolicies;
                  param.hotelRoomsDetails[index].cancellationPolicy =
                    room.cancellationPolicy;
                }
              );
            }
            if (response.data.blockRoomResult.isHotelPolicyChanged === "true") {
              param.hotelNorms = response.data.blockRoomResult.hotelNorms;
            }
            if (response.data.blockRoomResult.isPriceChanged === "true") {
              setBlockData(response.data.blockRoomResult);
              setResponseMessage(
                "Room blocked successfully, price changed. Please confirm for booking room on changed price"
              );
              response.data.blockRoomResult.hotelRoomsDetails.map(
                (room, index) => {
                  param.hotelRoomsDetails[index].price = { ...room.price };
                }
              );
              setPriceChangedStop(true);
            } else {
              bookRoom();
            }
          }
        } else {
          setIsError(true);
          setInfoText(response.message);

          setResponseMessage("Booking failed due to an error.");
          setTimeout(() => {
            history.push("/hotels");
          }, 5000);
        }
      },
      (error) => {
        console.log("getHotelBlock error", error);
        history.push(`/hotel/payment-status/${param.txnId}/failed`);
      }
    );
  };

  // console.log(param);

  const bookRoom = () => {
    setPriceChangedStop(false);
    setResponseMessage(
      "Room blocked successfully, please wait booking room now"
    );

    console.log("param ", param);
    //param.packageFare = true;
    WebApi.getHotelBook(
      param,
      (response) => {
        // console.log(response);
        if (response.success === true) {
          // console.log(response.data);
          // response.data.blockRoomResult.isCancellationPolicyChanged = true;
          if (response.data.bookResult.isCancellationPolicyChanged === "true") {
            response.data.bookResult.hotelRoomsDetails.map((room, index) => {
              param.hotelRoomsDetails[index].cancellationPolicies =
                room.cancellationPolicies;
              param.hotelRoomsDetails[index].cancellationPolicy =
                room.cancellationPolicy;
            });
          }
          if (response.data.bookResult.isHotelPolicyChanged === "true") {
            param.hotelNorms = response.data.bookResult.hotelNorms;
          }
          if (response.data.bookResult.isPriceChanged === "true") {
            setBlockData(response.data.bookResult);
            setResponseMessage(
              "Room blocked successfully, price changed. Please confirm for booking room on changed price"
            );
            response.data.bookResult.hotelRoomsDetails.map((room, index) => {
              param.hotelRoomsDetails[index].price = { ...room.price };
            });
            setPriceChangedStop(true);
          } else {
            WebApi.getHotelBookDetails(
              {
                traceId: response.data.bookResult.traceId,
                bookingId: response.data.bookResult.bookingId,
                confirmationNo: response.data.bookResult.confirmationNo,
                firstName: values.room[0].adult[0].firstName,
                lastName: values.room[0].adult[0].lastName,
              },
              (response) => {
                setIsSuccess(true);
                setInfoText(response.message);
                // console.log(response.data);
                if (response.success === true) {
                  if (
                    response.data.getBookingDetailResult.isPriceChanged ===
                    "true"
                  ) {
                    //  setBlockData(response.data.bookResult);
                    setResponseMessage(
                      "Price changed. Please confirm for booking room on changed price"
                    );
                    response.data.getBookingDetailResult.hotelRoomsDetails.map(
                      (room, index) => {
                        param.hotelRoomsDetails[index].price = {
                          ...room.price,
                        };
                      }
                    );
                    setPriceChangedStop(true);
                  } else {
                    props.setTimerInitialMinute(0);
                    props.setTimerInitialSecond(0);
                    console.log(response.data.getBookingDetailResult, "01");
                    console.log(hotelSearch, "01");
                    console.log(values, "01");
                    history.push({
                      pathname: `/hotel/payment-status/${param.txnId}/success`,
                      state: {
                        bookingDetails: response.data.getBookingDetailResult,
                        hotelSearch: hotelSearch,
                        values: values,
                      },
                    });
                  }
                } else {
                  setIsError(true);
                  setInfoText(response.message);

                  setResponseMessage("Booking failed due to an error");
                  setTimeout(() => {
                    history.push(`/hotel/payment-status/${param.txnId}/failed`);
                  }, 5000);
                }
              },
              (error) => {
                console.log("getHotelBookDetails error", error);
                history.push(`/hotel/payment-status/${param.txnId}/failed`);
              }
            );
          }
        } else {
          setIsError(true);
          setInfoText(response.message);

          setResponseMessage(
            "Error occured. please wait, we are redirecting you back"
          );
          setTimeout(() => {
            history.push(`/hotel/payment-status/${param.txnId}/failed`);
          }, 5000);
        }
      },
      (error) => {
        console.log("getHotelBook error", error);
        history.push(`/hotel/payment-status/${param.txnId}/failed`);
      }
    );
  };

  return (
    <GridContainer style={{ height: "60vh" }}>
      {!priceChangedStop ? (
        <>
          <GridItem md={12}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
          </GridItem>
          <GridItem
            md={12}
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              backgroundColor: "transparent",
            }}
          >
            <Rive src={image1} />
          </GridItem>
          <GridItem
            md={12}
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              backgroundColor: "transparent",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "500" }}>
              {responseMessage}
            </span>
          </GridItem>
        </>
      ) : (
        <>
          <GridItem md={12}>
            <div
              style={{
                fontSize: "20px",
                marginBottom: "0.8rem",
                marginTop: "6rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "500",
              }}
            >
              The fares for the Room(s) you have selected has changed. Please
              check the fares and continue.
            </div>
            <Divider style={{ marginBottom: "2rem" }} />
            <GridContainer>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Hotel Booking</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  {hotelInfo.hotelDetails.hotelName}
                </div>
              </GridItem>
              <GridItem md={1}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <u>Departure</u>
                </div>
                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                  {hotelSearch.checkOutDate}
                </div>
              </GridItem>
              <GridItem md={1}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <u>Arrival</u>
                </div>
                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                  {hotelInfo.checkInDate}
                </div>
              </GridItem>
              {/* <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Date</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  {blockData.DepartureTime}
                </div>
              </GridItem> */}
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>City</u>n
                </div>
                <div style={{ fontWeight: "450" }}>{hotelSearch.city}</div>
              </GridItem>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Destination</u>
                </div>
                <div style={{ fontWeight: "450" }}>Hyderabad</div>
              </GridItem>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "3%",
                  }}
                >
                  <u>Fare + Tax (Rs.)</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  <Button style={{ padding: 0, background: "#ececec" }}>
                    {totalFare}
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
            <Divider style={{ marginBottom: "1rem", marginTop: "2rem" }} />
            <div
              style={{
                marginBottom: "0.2rem",
                marginTop: "1.5rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Actual Fare: Rs. {totalFare}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <Button style={{ padding: "3px 15px", background: "#ececec" }}>
                New Fare : Rs.{" "}
                {param.hotelRoomsDetails.reduce((total, room) => {
                  return (
                    total +
                    parseInt(room.price.commissionCharge.grossProductPrice)
                  );
                }, 0) * hotelSearch.noOfNights}
              </Button>
            </div>
            <Divider style={{ marginBottom: "0.2rem", marginTop: "1rem" }} />
            <div
              style={{
                fontSize: "20px",
                marginBottom: "0.8rem",
                marginTop: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "500",
              }}
            >
              Do you want to continue booking?
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <Button variant="contained" color="primary" onClick={bookRoom}>
                Yes
              </Button>
              <Button
                style={{ background: "#ececec", marginLeft: "1rem" }}
                onClick={() => history.push("/hotel/search")}
              >
                No
              </Button>
            </div>
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelRoomParam: state.hotel.hotelRoomParam,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HotelPaymentProgressPage)
);
