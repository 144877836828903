import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { MenuItem, Popper, Select, TextField, Button, Grid } from "@material-ui/core";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { VisaConsulates } from "./VisaConstants";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { Autocomplete } from "@material-ui/lab";
import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';

const useStyles = makeStyles(theme => ({

    root: {
        minWidth: 860,
        padding: "20px 32px",
        fontFamily: FONTS.PRIMARY_FONT,
        "& .header-wrapper": {
            textAlign: "center",
            borderBottom: `solid 0.5px ${theme.palette.secondary. silver}`,
        },
        "& .content-wrapper": {
            borderTop: `solid 0.5px ${theme.palette.secondary.chineseGray}`,
            paddingTop: 15,
        },
        "& .form-row": {
            margin: "15px 0",
            padding: "5px 15px",
        },
        "& .form-item": {
            width: "100%",
            padding: "0 15px",
            [theme.breakpoints.down(960)]: {
                marginBottom: 20,
            },
        },
        "& .select-col": {
            "& .MuiInput-root": {
                marginTop: 3,
            },
        },
        "& label": {
            color: theme.palette.primary.darkText,
        },
        "& .action-wrapper": {
            justifyContent: "center",
            "& .MuiButton-containedPrimary": {
                color:theme.palette.primary.darkText,
            },
        }
    }
}));

const VisaSection = (props) => {

    const classes = useStyles();

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [consulates, setConsulates] = useState(null);

    const customPopper = function (props) {
        // console.log("props", props);
        return (
            <Popper
                {...props}
                // anchorEl={departAutocompleteEl.current.children[0]}
                placement="bottom-start"
                style={{}}
            />
        );
    };

    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

    return (
        <div className={classes.root}>
            <div className="header-wrapper">
                <h3>VISA REQUIREMENTS</h3>
                <p>Just fill the below fields and we'll email the visa requirements to you.</p>
            </div>

            <div className="content-wrapper">
                <div className="form-wrapper">
                    <OaFormAlerts
                        isSaving={isSaving}
                        isSuccess={isSuccess}
                        isError={isError}
                        infoText={infoText}
                        setIsError={setIsError}
                        setIsSuccess={setIsSuccess}
                    />
                    <Formik
                        initialValues={{
                            country: "",
                            consulate: "",
                            visaType: "",
                            fullName: "",
                            companyName: "",
                            email: "",
                            phone: "",
                        }}
                        validationSchema={
                            Yup.object().shape({
                                country: Yup.string().required("Country is required"),
                                consulate: Yup.string().required("Consulate is required"),
                                visaType: Yup.string().required("Visa Type is required"),
                                fullName: Yup.string().required("Full Name is required"),
                                email: Yup.string().email().required("Email id is required"),
                                phone: Yup.string().required("Mobile Number is required").matches(phoneRegex, 'Invalid mobile number'),
                            })
                        }
                        onSubmit={(values,  {setSubmitting}) => {
                            // console.log("form values", values);
                            setIsError(false);
                            setIsSuccess(false);
                            setSubmitting(true);
                            setIsSaving(true);

                            apiCall(WebApi.addVisaEnquiry, values, (response) => {
                                setIsError(!response.success);
                                setIsSuccess(response.success);
                                setSubmitting(false);
                                setIsSaving(false);
                                setInfoText(response.message);
                            });
                        }}
                    >
                        {({ values, errors, touched, handleChange, setFieldValue }) =>
                            <Form>
                                <div className="form-contents">
                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm={12} md={4} className="form-item">
                                            <label>Country</label>
                                            <Autocomplete
                                                fullWidth
                                                PopperComponent={customPopper}
                                                name="country"
                                                options={VisaConsulates || []}
                                                getOptionLabel={(option) => option.name}
                                                // value={values.country}
                                                getOptionSelected={(option, value) => {
                                                    return option.name == value.name;
                                                }}
                                                onChange={(event, newValue) => {
                                                    setConsulates(newValue.consulate);
                                                    setFieldValue("country", newValue.name);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        color="secondary"
                                                        placeholder="search country"
                                                    />
                                                )}
                                                disablePortal={true}
                                            />
                                            <ErrorMessage
                                                name="country"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} className="form-item select-col">
                                            <label>Consulate</label>
                                            <Select
                                                fullWidth
                                                name="consulate"
                                                onChange={event => {
                                                    setFieldValue('consulate', event.target.value)
                                                }}
                                                value={values.consulate}
                                            >
                                                {consulates && consulates.map((val, ind) =>
                                                <MenuItem key={ind} value={val.FK_CONSULATE}>{val.FK_CONSULATE}</MenuItem>)}
                                            </Select>
                                            <ErrorMessage
                                                name="consulate"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} className="form-item select-col">
                                            <label>Visa Type</label>
                                            <Select
                                                fullWidth
                                                name="visaType"
                                                onChange={event => {
                                                    setFieldValue('visaType', event.target.value)
                                                }}
                                                value={values.visaType}
                                                placeholder="select visatype"
                                            >
                                                <MenuItem key="" value="">Select</MenuItem>
                                                <MenuItem
                                                    key="employment-visa"
                                                    value="employment-visa"
                                                >Employment Visa</MenuItem>
                                                <MenuItem
                                                    key="business-visa"
                                                    value="business-visa"
                                                >Business Visa</MenuItem>
                                                <MenuItem
                                                    key="project-visa"
                                                    value="project-visa"
                                                >Project Visa</MenuItem>
                                                <MenuItem
                                                    key="x-entry-visa"
                                                    value="x-entry-visa"
                                                >“X”/ Entry Visa</MenuItem>
                                                <MenuItem
                                                    key="tourist-visa"
                                                    value="tourist-visa"
                                                >Tourist Visa</MenuItem>
                                                <MenuItem
                                                    key="research-visa"
                                                    value="research-visa"
                                                >Research Visa</MenuItem>
                                                <MenuItem
                                                    key="transit-visa"
                                                    value="transit-visa"
                                                >Transit Visa</MenuItem>
                                                <MenuItem
                                                    key="conference-visa"
                                                    value="conference-visa"
                                                >Conference Visa</MenuItem>
                                                <MenuItem
                                                    key="medical-visa"
                                                    value="medical-visa"
                                                >Medical Visa</MenuItem>
                                            </Select>
                                            <ErrorMessage
                                                name="visaType"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm={12} md={6} className="form-item">
                                            <label>Full Name</label>
                                            <TextField
                                                fullWidth
                                                name="fullName"
                                                value={values.fullName}
                                                onChange={event => setFieldValue("fullName", event.target.value)}
                                                placeholder="Enter Full Name"
                                            />
                                            <ErrorMessage
                                                name="fullName"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} className="form-item">
                                            <label>Company Name</label>
                                            <TextField
                                                fullWidth
                                                value={values.companyName}
                                                onChange={event => setFieldValue("companyName", event.target.value)}
                                                placeholder="Enter Company Name"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm={12} md={6} className="form-item">
                                            <label>Email id</label>
                                            <TextField
                                                fullWidth
                                                name="email"
                                                value={values.email}
                                                onChange={event => setFieldValue("email", event.target.value)}
                                                placeholder="Enter e-mail id"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} className="form-item">
                                            <label>Mobile Number</label>
                                            <TextField
                                                fullWidth
                                                name="phone"
                                                value={values.phone}
                                                onChange={event => setFieldValue("phone", event.target.value)}
                                                placeholder="Enter Mobile Number"
                                            />
                                            <ErrorMessage
                                                name="phone"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row action-wrapper">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </div>
                            </Form>}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default VisaSection;