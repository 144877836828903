import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppConstant } from "appConstant";
import { useTheme } from "@material-ui/core";

export default function OaAlert(props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.isPrompt);
  }, [props.isPrompt]);

  const handleSecondaryButton = () => {
    if (props.togglePrompt) {
      props.togglePrompt();
    }

    if (props.callback) {
      props.callback(
        "NO",
        props.callbackParam,
        props.additionalData,
        props.dialogRef
      );
    }
  };

  const handlePrimaryButton = () => {
    if (props.togglePrompt) {
      props.togglePrompt();
    }

    if (props.callback) {
      // console.log(props)
      props.callback(
        "YES",
        props.callbackParam,
        props.additionalData,
        props.dialogRef
      );
    }
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleSecondaryButton}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.title ? props.title : AppConstant.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.msg ? props.msg : "Are you sure?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          background: theme.palette.primary.fadedLight,
          color: theme.palette.primary.lightText,
        }}
      >
        {!props.hideSecondaryButton && (
          <Button onClick={handleSecondaryButton} color="danger">
            {props.secondaryButtonText ? props.secondaryButtonText : "Cancel"}
          </Button>
        )}
        {!props.hidePrimaryButton && (
          <Button onClick={handlePrimaryButton} color="primary">
            {props.primaryButtonText ? props.primaryButtonText : "OK"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
