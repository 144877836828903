import React, { useEffect, useState } from "react";
import OaTable from "components/OaTable";
import WebApi, {
    DOWNLOAD_RECHARGE_PDF,
    DOWNLOAD_RECHARGE_XLS
} from "api/ApiConstants";
import { currencyFormatter } from "oautils/oaCommonUtils";
import OaButtonPDF from "pages/components/OaButtonPDF";
import OaButtonXls from "pages/components/OaButtonXls";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { useHistory } from "react-router-dom";
import { localforageSetItem, localforageGetItem } from "oautils/oaForageUtils";
import _ from "lodash";
import { apiCall } from "oautils/oaDataUtils";
import GridContainer from "components/Grid/GridContainer";

import OaFormAlerts from "pages/components/OaFormAlerts";
import GridItem from "components/Grid/GridItem";

import Checkbox from "@material-ui/core/Checkbox";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

const AepsReportSearchResult = props => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [passengers, setPassengers] = useState([]);
    const [cancellationPolicy, setCancellationPolicy] = useState([]);
    const [cancellationChargePerSeat, setCancellationChargePerSeat] = useState(0);
    const [tid, setTid] = useState(0);
    const [open, setOpen] = useState(false);
    const [allSeatSelected, setAllSeatSelected] = useState(true);

    const [remarks, setRemarks] = useState("");

    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
        setPassengers([]);
    };

    const handleConfirmClose = () => {
        let _seatIds = [];
        passengers.map(value => {
            if (value.checked) _seatIds.push(value.SeatId);
        });

        if (remarks.length == 0) {
            alert("Please add remarks");
        } else if (_seatIds.length == 0) {
            alert("Please select atleast one seat");
        } else {
            setIsLoading(true);
            apiCall(
                WebApi.doAadhaarpayBookingCancel,
                { tid: tid, seatIds: _seatIds, remarks: remarks },
                response => {
                    setIsLoading(false);
                    setOpen(false);
                    if (response.success) {
                        setInfoText(response.message);
                        setIsSuccess(true);

                        setTimeout(() => {
                            props.target(props.searchParams);
                        }, 5000);
                    } else {
                        setIsError(true);
                        setInfoText(response.message);
                    }
                }
            );
        }
    };

    function onSelectMenu(event, data) {
        switch (event) {
            case "User Invoice":
                history.push(
                    "/admin/voucher/aadhaarpay-user/" + data.transactionId
                );
                break;
            case "Agent Invoice":
                history.push(
                    "/admin/voucher/aadhaarpay-agent/" + data.transactionId
                );
                break;

            case "Current Status":

                apiCall(
                    WebApi.aadhaarPayStatus,
                    { tid: data.transactionId },
                    response => {
                        setIsLoading(false);

                        if (response.success) {
                            setInfoText(response.message);
                            setIsSuccess(true);
                        } else {
                            setIsError(true);
                            setInfoText(response.message);
                        }
                    }
                );


                break;

        }
    }

    const dropDownMenu = ["User Invoice", "Agent Invoice", "Current Status"];

    function round(value, decimals) {
        return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
            decimals
        );
    }

    useEffect(() => {
        localforageGetItem("user-details", function (err, value) {
            if (err == null && value != null) {
                if (value.userType === "distributor" || value.userType === "company") {
                }
                if (value.userType === "agent") {
                }
            }
        });
    }, []);

    const [total, setTotal] = useState(0);

    const doTotal = amt => {
        let totalAmt = total + amt;
        setTotal(totalAmt);
        return amt;
    };

    let cols = [];
    cols.push(
        {
            Header: "#",
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {props.index + 1}
                </div>
            ),
            width: 35,
            filterable: false
        },
        {
            Header: "Transaction ID",
            accessor: "aadhaarpayId",
            width: 200,
            Cell: props => (
                <div style={{ height: 44, verticalAlign: "middle" }}>
                    <CustomDropdown
                        buttonText={props.value}
                        buttonProps={{
                            fullWidth: true,
                            style: { height: 32, padding: 4 },
                            color: "rose"
                        }}
                        onClick={event => {
                            onSelectMenu(event, props.original);
                        }}
                        dropPlacement="bottom-start"
                        dropdownList={dropDownMenu}
                    />
                </div>
            )
        },
        {
            Header: "Tx Date",
            width: 210,
            accessor: "transactionDate",
            filterable: false,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {props.value}
                </div>
            )
        }

        // ,

        // {
        //   Header: "Agent",
        //   accessor: "agencyName",
        //   width: 150,
        //   filterable: false,
        //   Cell: (props) => (
        //     <div
        //       style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        //     >
        //       {props.value}
        //     </div>
        //   ),
        // }
    );

    cols.push(
        {
            Header: "Aadhaar",
            accessor: "aadhaarNo",
            width: 150,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {props.value}
                </div>
            )
        },
        {
            Header: "Acknowledgement No",
            accessor: "aadhaarpayAcknowledgementNo",
            width: 170,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {props.value}
                </div>
            )
        },
        {
            Header: "Status",
            width: 150,
            accessor: "transactionStatus",
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {props.value}
                </div>
            ),
            Footer: info => (
                <span style={{ fontWeight: "bold" }}>
                    Transactions: {info.data.length}
                </span>
            )
        },
        {
            Header: "Settlement",
            width: 150,
            accessor: "settlementStatus",
            Cell: (props) => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {props.original.transactionStatus === "1" ? props.value : "n/a"}
                </div>
            )
        },
        {
            Header: "Transaction Amount (INR)",
            width: 150,
            filterable: false,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {round(parseFloat(props.original.amount), 2)}
                </div>
            ),
            Footer: info => (
                <span
                    style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
                >
                    {currencyFormatter(_.sum(_.map(info.data, d => d._original.amount)))}
                </span>
            )
        },
        {
            Header: "Supplier Share (INR)",
            filterable: false,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {round(
                        parseFloat(props.original.companyCommissionCharges) +
                        parseFloat(props.original.supplierCharges) +
                        parseFloat(props.original.distributorCommissionCharges),
                        2
                    )}
                </div>
            ),
            Footer: info => (
                <span
                    style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
                >
                    {currencyFormatter(
                        _.sum(
                            _.map(
                                info.data,
                                d =>
                                    d._original.companyCommissionCharges +
                                    d._original.supplierCharges +
                                    d._original.distributorCommissionCharges
                            )
                        )
                    )}
                </span>
            )
        },
        {
            Header: "Agent Share (INR)",
            filterable: false,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {round(parseFloat(props.original.agentCommissionCharges), 2)}
                </div>
            ),
            Footer: info => (
                <span
                    style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
                >
                    {currencyFormatter(
                        _.sum(_.map(info.data, d => d._original.agentCommissionCharges))
                    )}
                </span>
            )
        },
        {
            Header: "Total Amount (INR)	",
            filterable: false,
            Cell: props => (
                <div
                    style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
                >
                    {currencyFormatter(round(parseFloat(props.original.productPrice), 2))}
                </div>
            ),
            Footer: info => (
                <span
                    style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
                >
                    {currencyFormatter(
                        _.sum(_.map(info.data, d => d._original.productPrice))
                    )}
                </span>
            )
        }
    );

    const checkOnClick = SeatId => {
        alert("Only full cancellation is allowed at this moment");
        // let _passengers = [...passengers];
        // let _allSeatSelected = true;
        // let _index = _passengers.findIndex(x => x.SeatId === SeatId);

        // _passengers[_index].checked = !_passengers[_index].checked;

        // setPassengers(_passengers);
        // _passengers.map((value, key) => {
        //     if (!value.checked && !value.isCancelled) {
        //         _allSeatSelected = false;
        //     }
        // })

        // if (allSeatSelected !== _allSeatSelected) setAllSeatSelected(_allSeatSelected);
    };

    const checkAllOnClick = () => {
        alert("Only full cancellation is allowed at this moment");
        // let _passengers = [...passengers];

        // _passengers.map((value, index) => {
        //     value.checked = !allSeatSelected
        // })

        // setPassengers(_passengers);
        // setAllSeatSelected(!allSeatSelected);
    };

    return (
        <>
            <GridContainer>
                <GridItem md={12}>
                    <OaFormAlerts
                        isSaving={isSaving}
                        isSuccess={isSuccess}
                        isError={isError}
                        infoText={infoText}
                        setIsError={setIsError}
                        setIsSuccess={setIsSuccess}
                    />
                </GridItem>
            </GridContainer>
            {/* <OaButtonPDF api={DOWNLOAD_RECHARGE_PDF} searchParams={props.searchParams} />
            <OaButtonXls api={DOWNLOAD_RECHARGE_XLS} searchParams={props.searchParams} /> */}
            <OaTable
                columns={cols}
                search={props.searchParams}
                loader={WebApi.getAadhaarpayReport}
                filterable={true}
                rows
            />
        </>
    );
};

export default AepsReportSearchResult;
