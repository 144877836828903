import React, { useState } from "react";
import { Card, Divider, Grid, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import Profile from "./component/Profile";
import Menu from "pages/b2c/component/Menu";
import image from "assets/img/dummy-logo-four.png";
import image2 from "assets/img/gst.jpg";
import EditProfile from "./EditProfile";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import GstModal from "./component/GstModal";
import AddTraveler from "./component/AddTraveler";
import { AppStateContext } from "layouts/AppStateProvider";
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    paddingTop: "40px",
    paddingBottom: "40px",
    [theme.breakpoints.down("sm")]:{
      maxWidth: "100%",
    }
  },
  typo: {
    fontSize: "17px",
    paddingLeft: "25px",
    paddingTop: "10px",
    color: "grey !important",
    fontWeight: "300 !important",
    marginBottom: "0.5rem",
    marginTop: 0,
  },
  Grid: {
    background: "#fff",
    textAlign: "center",
    border: "1px solid #bfb6b6",
    borderRadius: "5px",
  },
  griditem1: {
    background: "#fff",
    margin: "20px",
    border: "1px solid #dcdcdc",
    borderRadius: "5px",
    boxShadow: "0px 0px 6px -1px #b3b3b3",
    [theme.breakpoints.down('sm')]:{
      margin: 5,
    }
  },

  img: {
    width: "60px",
    paddingBottom: "10px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  griditem2: {
    background: "#e2e2e2",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 10px",
  },
  typo2: {
    fontSize: "13px",
    color: "grey",
    fontWeight: 500,
  },
  Grid2: {
    padding: "20px",
    textAlign: "left",
    alignItems: "center",
    "& .fa": {
      marginRight: 9,
    },
    [theme.breakpoints.down("sm")]:{
      padding:5,
    }
  },
  typo3: {
    fontSize: "20px",
    fontWeight: "300",
  },
  typo4: {
    fontSize: "12px",
    color: "#848282",
    fontWeight: 300,
  },
  shortName: {
    fontSize: "15px",
    fontWeight: 300,
    width: "31px",
    height: "31px",
    border: "1px solid",
    padding: "4px",
    borderRadius: "50%",
    marginRight: "10px",
    display: "inline-block",
  },
  person: {
    fontSize: "16px",
    fontWeight: "300",
  },
  Grid3: {
    alignItems: "center",
    marginBottom: "6px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  grid:{
    [theme.breakpoints.down("sm")]:{
      margin:"15px"
    }
  }
}));

const MyProfile = () => {
  const classes = useStyles();
  const [value, setValue] = useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [open1, setOpen1] = React.useState(false);
  const [openTraveller, setOpenTraveller] = React.useState(false);
  const { userProfile } = React.useContext(AppStateContext);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen1 = () => {
  //   setOpen1(true);
  // };

  // const handleOpenTraveller = () => {
  //   setOpenTraveller(true);
  // };

  // const handleClose1 = () => {
  //   setOpen1(false);
  // };

  // const handleCloseTraveller = () => {
  //   setOpenTraveller(false);
  // };

  const handleClick = () => {
    setValue(true);
  };

  return (
    <div className={classes.root}>
      <GridContainer>
        <GridContainer style={{ paddingLeft: "15px" }}>
          <Typography style={{ fontSize: "13px", color: "#3a3939" }}>
            Dashboard /
          </Typography>
          <Typography
            style={{ fontSize: "13px", color: "#3a3939", fontWeight: 600 }}
          >
            My Bookings 1
          </Typography>
        </GridContainer>
        <Grid item md={3} xs={12}>
          <Menu />
          <div style={{ marginLeft: 15, marginRight: 15, marginTop: 10 }}>
            <Profile />
          </div>
        </Grid>

        <Grid item md={9} xs={12} className={classes.grid}>
          <GridContainer style={{ background: "#d8d8d8", borderRadius: "5px" }}>
            <Grid item>
              <Typography className={classes.typo}>MY ACCOUNT</Typography>
            </Grid>

            {value ? (
              <GridContainer className={classes.Grid}>
                <Grid item md={12} xs={12}>
                  <EditProfile showProfile={setValue} />
                </Grid>
              </GridContainer>
            ) : (
              <GridContainer className={classes.Grid}>
                
               {userProfile &&<Grid item md={12} xs={12} className={classes.griditem1}>
                  <Grid item md={12} className={classes.griditem2}>
                    <Typography align="left" className={classes.typo2}>
                      MY PROFILE
                    </Typography>
                  </Grid>
                  <GridContainer
                    justifyContent="space-between"
                    className={classes.Grid2}
                  >
                    <Grid item md={1}>
                      <img src={image} className={classes.img} />
                    </Grid>
                    <Grid item md={4}>
                      <Typography className={classes.typo3}>
                        {userProfile.profileDetails.firstName}
                      </Typography>
                      <Typography className={classes.typo4}>
                        <span>
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          {userProfile.profileDetails.email}
                        </span>
                      </Typography>

                      <span className={classes.typo4}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        {userProfile.profileDetails.mobileNumber}
                      </span>
                    </Grid>

                    <Grid item md={6}>
                      <Typography align="right">
                        {" "}
                        {/* <i
                          
                          className="fa fa-pencil edit-profile-icon"
                          aria-hidden="true"
                        
                        ></i> */}
                        <EditIcon 
                        onClick={handleClick}
                        style={{
                            // background: "#2b5a85",
                            // color: "#fff",
                            // textAlign: "center",
                            // fontSize: "14px",
                            // padding: "9px",
                            // borderRadius: "50%",
                            // cursor: "pointer",
                          }}/>
                      </Typography>
                    </Grid>
                  </GridContainer>
                </Grid> }

               {/* {userProfile.gstDetails && <Grid
                  item
                  md={12}
                  className={classes.griditem1}
                  style={{ marginTop: 5 }}
                >  
  
   
   
   
     <Grid item md={12} className={classes.griditem2}>
                    <Typography align="left" className={classes.typo2}>
                      GST DETAILS
                    </Typography>
                  </Grid>
                  <GridContainer
                    justifyContent="space-between"
                    className={classes.Grid2}
                  >
                    <Grid item md={1}>
                      <img src={image2} width="90px" />
                    </Grid>
                    <Grid item md={6}>
                      <Typography className={classes.typo3}>
                        {userProfile.gstDetails.companyName}
                      </Typography>
                      <Typography className={classes.typo4}>
                        <span>
                          <i className="fa fa-list" aria-hidden="true"></i>
                       {userProfile.gstDetails.gstNo}
                        </span>
                      </Typography>
                      <Typography className={classes.typo4}>
                        <span>
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                          {userProfile.gstDetails.email}
                        </span>
                      </Typography>
                      <Typography className={classes.typo4}>
                        <span>
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          {userProfile.gstDetails.mobileNo}
                        </span>
                      </Typography>
                      <Typography className={classes.typo4}>
                        <span>
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                         {userProfile.gstDetails.address}
                        </span>
                      </Typography>

                      <span className={classes.typo4}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        {userProfile.gstDetails.mobileNo}
                      </span>
                    </Grid>

                    <Grid item md={4}>
                      <GridContainer justifyContent="flex-end">
                        <Typography align="right">
                          {" "}
                          <i
                            onClick={handleOpen}
                            className="fa fa-pencil edit-profile-icon"
                            aria-hidden="true"
                            style={{
                              background: "#2b5a85",
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "14px",
                              padding: "9px",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Typography>
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          //  className={classes.modal}
                          style={{ marginTop: 40 }}
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                        >
                          <Fade in={open}>
                            <GstModal setOpen={setOpen}  />
                          </Fade>
                        </Modal>

                        <Typography align="right">
                          {" "}
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            style={{
                              background: "#2b5a85",
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "14px",
                              padding: "9px",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Typography>
                      </GridContainer>
                    </Grid>
                  </GridContainer>
                </Grid>} */}
                

                {/* <Grid
                  item
                  md={12}
                  className={classes.griditem1}
                  style={{ marginTop: 5 }}
                >
                  <Grid item md={12} className={classes.griditem2}>
                    <Typography align="left" className={classes.typo2}>
                      SAVED TRAVELLERS
                    </Typography>
                  </Grid>
                  <GridContainer className={classes.Grid2}>
                    <Grid item md={12}>
                      <GridContainer>
                        <Grid item md={1}>
                          <i
                            className="fa fa-users people"
                            aria-hidden="true"
                            style={{
                              fontSize: "26px",
                              padding: "13px",
                              color: "#908d8d",
                              border: "1px solid #908d8d",
                              borderRadius: "50%",
                            }}
                          ></i>
                        </Grid>
                        <Grid item md={11}>
                          <GridContainer>
                            <Grid item md={8}>
                              <GridContainer className={classes.Grid3}>
                                <Grid item md={1} xs={12}>
                                  <span className={classes.shortName}>GK</span>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                  <Typography className={classes.person}>
                                    Mr Gautam Kh
                                  </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <GridContainer>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-pencil edit-profile-icon"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                  </GridContainer>
                                </Grid>
                              </GridContainer>
                              <GridContainer className={classes.Grid3}>
                                <Grid item md={1} xs={12}>
                                  <span className={classes.shortName}>GK</span>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                  <Typography className={classes.person}>
                                    Mr Gautam Kh
                                  </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <GridContainer>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-pencil edit-profile-icon"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                  </GridContainer>
                                </Grid>
                              </GridContainer>
                              <GridContainer className={classes.Grid3}>
                                <Grid item md={1} xs={12}>
                                  <span className={classes.shortName}>GK</span>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                  <Typography className={classes.person}>
                                    Mr Gautam Kh
                                  </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <GridContainer>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-pencil edit-profile-icon"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                  </GridContainer>
                                </Grid>
                              </GridContainer>
                              <GridContainer className={classes.Grid3}>
                                <Grid item md={1} xs={12}>
                                  <span className={classes.shortName}>GK</span>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                  <Typography className={classes.person}>
                                    Mr Gautam Kh
                                  </Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <GridContainer>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-pencil edit-profile-icon"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                    <Typography align="right">
                                      {" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{
                                          background: "#2b5a85",
                                          color: "#fff",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "9px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Typography>
                                  </GridContainer>
                                </Grid>
                              </GridContainer>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography align="right">
                                {" "}
                                <i
                                  onClick={handleOpenTraveller}
                                  className="fa fa-pencil edit-profile-icon"
                                  aria-hidden="true"
                                  style={{
                                    background: "#2b5a85",
                                    color: "#fff",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    padding: "9px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                ></i>
                                <Modal
                                  aria-labelledby="transition-modal-title"
                                  aria-describedby="transition-modal-description"
                                  //  className={classes.modal}
                                  style={{ marginTop: 40 }}
                                  open={openTraveller}
                                  onClose={handleCloseTraveller}
                                  closeAfterTransition
                                  BackdropComponent={Backdrop}
                                  BackdropProps={{
                                    timeout: 500,
                                  }}
                                >
                                  <Fade in={openTraveller}>
                                    <AddTraveler setOpenTraveller={setOpenTraveller}/>
                                  </Fade>
                                </Modal>
                              </Typography>
                            </Grid>
                          </GridContainer>
                        </Grid>
                      </GridContainer>
                    </Grid> 
                  </GridContainer>
                </Grid>*/}
              </GridContainer>
            )}
          </GridContainer>
        </Grid>
      </GridContainer>
    </div>
  );
};

export default MyProfile;
