import localforage from "localforage";

export const localforageSetItem = (key, value, callback) => {
  key = window.appNameContext + "-" + key;

  localforage.setItem(key, value, () => {
    if (callback) callback();
  });
};

export const localforageGetItem = (key, callback) => {
  key = window.appNameContext + "-" + key;

  localforage.getItem(key, (err, value) => {
    if (callback) callback(err, value);
  });
};

export const localforageClear = (callback) => {
  var userIdkey = window.appNameContext + "-" + "user-id";
  var userDetailskey = window.appNameContext + "-" + "user-details";
  var userAccesskey = window.appNameContext + "-" + "access-key";

  localforage
    .removeItem(userIdkey)
    .then(function() {
      localforage.removeItem(userDetailskey).then(function() {
        localforage.removeItem(userAccesskey).then(function() {
          if (callback) callback();
        });
      });
    })
    .catch(function(err) {});
};

export const localforageGetItemAsync = async (key, callback) => {
  key = window.appNameContext + "-" + key;

  return await localforage.getItem(key);
};

export const localforageSetDSAItem = (key, value, callback) => {
  key = "dsa-" + key;

  localforage.setItem(key, value, () => {
    if (callback) callback();
  });
};

export const localforageGetDSAItem = (key, callback) => {
  key = "dsa-" + key;

  localforage.getItem(key, (err, value) => {
    if (callback) callback(err, value);
  });
};

export const localforageClearDSA = (callback) => {
  var userIdkey = "dsa-" + "user-id";
  var userDetailskey = "dsa-" + "user-details";
  var userAccesskey = "dsa-" + "access-key";

  localforage
    .removeItem(userIdkey)
    .then(function() {
      localforage.removeItem(userDetailskey).then(function() {
        localforage.removeItem(userAccesskey).then(function() {
          if (callback) callback();
        });
      });
    })
    .catch(function(err) {});
};

export const localforageClearTrnParam = (tryKey) => {
  var param = window.appNameContext + "-" + tryKey;

  return localforage.removeItem(param);
};
