import OaTable from "components/OaTable";
import React, { useState, useEffect } from "react";
import WebApi from "api/ApiConstants";
import { useHistory, withRouter } from "react-router";
import { COLORS } from "assets/css/CssConstants";
import FlightSearchHeader from "./FlightSearchHeader";
// import { Button } from "@material-ui/core";
import {
  Grid,
  Typography,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  useTheme,
} from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import image from "assets/img/tabel-background.jpg";
import { apiCall } from "oautils/oaDataUtils";
import departure from "assets/img/departure.png";
import arrival from "assets/img/arrval1.png";
import FlightCard from "./FlightCard";

const useStyles = makeStyles((theme) => ({
  uiRoot: {
    minHeight: 450,
  },
  root1: {
    "& .MuiButton-root": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "9px",
      },
    },
    "& .oa-card-body": {
      padding: "0 20px"
    },
  },
  Button: {
    marginLeft: 25,
    borderRadius: 50,
    height: 35,
  },
  datepicker: {
    background: theme.palette.primary.lightText,
    height: 35,
  },
  formControl: {
    minWidth: "-webkit-fill-available",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    color: theme.palette.background.lightGreen,
    fontSize: "50px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Grid1: {
    marginBottom: 20,
    //marginTop: "10%",
    [theme.breakpoints.down("xs")]: {
      // marginTop:"10%",
    },
  },
  dialogCloseButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `solid 1px ${theme.palette.primary.main}`,
    width: "120px",
    "&:hover": {
      color: theme.palette.primary.main,
      border: `solid 1px ${theme.palette.primary.main}`
    }
  },
  dialogCnfButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `solid 1px ${theme.palette.primary.main}`,
    width: "120px",
    "&:hover": {
      color: theme.palette.primary.main,
      border: `solid 1px ${theme.palette.primary.main}`
    }
  },
  hr: {
    color: theme.palette.background.lightGreen,
    width: "17%",
    border: "1px solid",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      width: "12%",
    },
  },

  root: {
    marginTop: 80,
    "& .rt-resizable-header": {
      borderRight: "1px solid #f2f2f2",
    },
    "& .rt-tr-group": {
      background: "#f2f2f2",
      borderRadius: 50,
    },
    "& .ReactTable.-striped .rt-tr.-odd": {
      background: "transparent",
      "&:hover": {
        background: "transparent",
      },
    },
    "& .ReactTable .rt-tbody .rt-td": {
      borderBottom: "none",
      borderLeft: "none",
      borderRight: "1px solid #ccc",

      "&:last-child": {
        borderRight: "none",

        //  borderLeft:"none"
      },
      "&:hover": {
        background: "transparent",
      },
    },
    "& .ReactTable .rt-tbody:last-child": {
      borderRight: "none",
      //  borderLeft:"none"
    },
    "& .ReactTable .rt-tbody:first-child": {
      borderLeft: "none",
    },
    "& .ReactTable .rt-tbody .rt-tr-group": {
      marginBottom: 20,
    },
    "& .ReactTable .rt-th, .ReactTable .rt-td": {
      margin: "inherit",
      paddingTop: 13,
    },
    "& .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow)": {
      "&:hover": {
        background: "transparent",
      },
    },

  },
  dialogMain: {
    "& .MuiDialogTitle-root": {
      borderBottom: `solid 1px ${theme.palette.primary.shadedLightText}`
    },
    "& .MuiTypography-root.MuiTypography-h6": {
      fontWeight: 600,
    },
    "& .MuiDialog-paperWidthSm": {
      width: "400px",
      // height: "190px",
    },
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SpecialOfferTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  //  const [selectedDate, handleDateChange] = useState(props.location.state.date);
  const [open, setOpen] = useState(false);
  const [bookNowData, setBookNowData] = useState({});
  const [noOfAdults, setNoOfAdults] = useState("1");
  const [noOfChildren, setNoOfChildren] = useState("0");
  const [noOfInfants, setNoOfInfants] = useState("0");
  const [dealData, setDealData] = useState([]);
  const [headerData, setHeaderData] = useState({});
  const [isModifying, setIsModifying] = useState(false);
  const history = useHistory();

  const getDealData = (dac, oac) => {
    apiCall(WebApi.getSelectedAvailableSectors,
      { destinationAirportCode: dac, originAirportCode: oac }, response => {
        // console.log("response", response);

        if (response.success) {
          setDealData(response.data);
        }
      });
  }


  useEffect(() => {
    // console.log("props hot deal details (PNRS)", props);
    getDealData(
      history.location.state.data.arivalAirportCode,
      history.location.state.data.departureAirportCode
    );

  }, []);

  let cols = [];
  cols.push(
    {
      Header: "Travel Date",
      accessor: "travelDate",
      width: 130,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          <button
            style={{
              height: 38,
              width: 38,
              fontSize: 22,
              fontWeight: 700,
              color: theme.palette.primary.active,
              borderRadius: "50%",
              background: theme.palette.background.light,
              border: "0px",
              boxShadow: "1px 1px 1px 1px #706969",
            }}
          >
            {moment(props.value).format("DD")}
          </button>{" "}
          {moment(props.value).format("MMM")}{" "}
          {moment(props.value).format("YYYY")}
        </div>
      ),
    },
    {
      Header: "Sector",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {`${props.original.originAirportCode} - ${props.original.destinationAirportCode}`}
        </div>
      ),
    },
    {
      Header: "Airline",
      accessor: "logo",
      Cell: (props) => (
        <img
          src={
            GET_FILE_UPLOAD +
            "?fileName=" +
            "BMF"
            // props.original.carrierCode 
            + ".png&type=AIRLINE_IMAGES"
          }
          height={50}
        />
      ),
    },
    {
      Header: "Flight No.",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {`BMF - ${props.original.flightNo}`}
          {/* ${props.original.carrierCode} */}
        </div>
      ),
    },
    {
      Header: (
        <div>
          <img src={departure} width="35px" style={{ marginTop: -10 }} />
        </div>
      ),
      accessor: "depTime",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    },
    {
      Header: (
        <div>
          <img src={arrival} width="35px" style={{ marginTop: -10 }} />
        </div>
      ),
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.arrTime}{" "}
          {/* <span
             style={{
               border: "1px solid #80CC4B",
               borderWidth: "medium",
               borderRadius: 10,
               padding: 6,
             }}
           >
             {props.original.remSeat}
           </span> */}
        </div>
      ),
    },
    {
      Header: "Availibility",
      accessor: "remSeat",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.remSeat}
        </div>
      ),
    },
    {
      Header: "Deal",
      accessor: "dealCost",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          ₹{props.value}
          {/* .toFixed(2)} */}
        </div>
      ),
    },
    {
      Header: "Book Here",
      width: 150,
      Cell: (props) => (
        <Button
          style={{
            height: 33,
            // display: "table-cell",
            verticalAlign: "middle",
            color: theme.palette.primary.main,
            borderRadius: 41,
            boxShadow: `2px 2px 2px 2px ${theme.palette.secondary.contrastText.lightShadow}`,
            border: "3px solid #81cc4d",
            background: "transparent",
            padding: "0px 19px 0px 19px",
          }}
          className={classes.buttonFlight}
          onClick={() => {
            setBookNowData(props.original);
            handleClickOpen();
          }}
        >
          Book Now
        </Button>
      ),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    history.push("/fd/hotdeals/flightdetails", {
      noOfAdults,
      noOfChildren,
      noOfInfants,
      offerDetails: bookNowData,
      ismodifying: isModifying,
    });
    setOpen(false);
  };

  const toggleFlightSelectOne = (value, data) => { }

  return (
    <div className={classes.uiRoot}>
      {dealData && dealData.length > 0 &&
        <FlightSearchHeader
          data={dealData[0]}
          ismodifying={isModifying}
          setismodifying={flag => setIsModifying(flag)}
          {...props}
        />}
      <Card
        style={{
          boxShadow: "none",
          //backgroundImage: "url(" + image + ")",
          backgroundSize: "100%",
          marginTop: 0,
          backgroundRepeat: "no-repeat",
        }}
        className={classes.root1}
      >

        <CardBody>
          <Grid container item className={classes.Grid1}>
            <Grid item xs={12} className={classes.root}>
              {dealData && dealData.map((val, ind) =>
                <FlightCard
                  fullWidth={true}
                  flightData={val}
                  selected={""}
                  getSelectedFlight={(value, data) => toggleFlightSelectOne(value, data)}
                  tripMode="ONE"
                  setfdbookdata={flag => {
                    if (flag === true) {
                      setBookNowData(val);
                      handleClickOpen();
                    }
                  }}
                  key={ind}
                />
              )}

            </Grid>
            <Dialog
              className={classes.dialogMain}
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {"Select Travellers"}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="From"
                      variant="standard"
                      disabled
                      value={bookNowData.originAirportCode}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="To"
                      variant="standard"
                      disabled
                      value={bookNowData.destinationAirportCode}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                  <TextField
                     label="Departure"
                     variant="outlined"
                     disabled
                     value={moment(selectedDate).format("DD-MMM-YYYY")}
                     InputLabelProps={{ shrink: true }}
                   />
                </Grid> */}

                  <Grid item xs={4}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Adult[12+]
                      </InputLabel>
                      <Select
                        value={noOfAdults}
                        onChange={(event) => setNoOfAdults(event.target.value)}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Adult[12+]"
                        fullWidth
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Child[2-11]
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={noOfChildren}
                        onChange={(event) => setNoOfChildren(event.target.value)}
                        label="Child[2-11]"
                        fullWidth
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Infant[0-2]
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={noOfInfants}
                        onChange={(event) => setNoOfInfants(event.target.value)}
                        label="Infant[0-2]"
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button className={classes.dialogCloseButton} onClick={handleClose} >
                  Close
                </Button>
                <Button className={classes.dialogCnfButton} onClick={handleConfirm}>
                  Re-Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(SpecialOfferTable);
