import React from "react";

const OaNetFare = (props) => {
    //console.log("props.fareData?.tdiscount ", props.fareData?.tdiscount);

    return (
        props.showNetFare ?
            <span className="nf-root">
                <span className="nf-span rupee-sign">₹</span>
                (<span className="nf-span base-fare" title="Net Fare">
                    {(parseFloat(props.fareData?.baseFare) + parseFloat(props.fareData?.tax) - (parseFloat(props.fareData?.tdiscount)).toFixed(2))}
                </span>
                <span className="nf-span add-sign">+</span>
                <span className="nf-span earning" title="Commission + Markup">
                    {(parseFloat(props.fareData?.tdiscount) + parseFloat(props.fareData?.tmarkup)).toFixed(2)}
                </span>)
            </span> :
            <></>
    );
};

export default OaNetFare;
