import React from "react";
import { IconButton, TextField, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";

const OaPasswordToggleInput = (props) => {
    const classes = useStyles();
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const EyeIcon = (iconProp) => {
        return iconProp.visibility ? 
        <i className="fa fa-eye-slash" aria-hidden="true"></i> :
        <i className="fa fa-eye" aria-hidden="true"></i>;
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return(
        <>
            <Helmet>
                <script src="https://kit.fontawesome.com/eb67f5d49a.js" crossorigin="anonymous"></script>
            </Helmet>
            <div className={classes.root}>
                <div className="oapt-input-wrapper">
                    <div className="oapt-input">
                        <TextField
                            {...props}
                            fullWidth
                            type={isPasswordVisible ? "text" : "password"}
                        />
                    </div>
                    <div className="oapt-btn-wrap">
                        <IconButton className="oapt-btn" 
                            onClick={togglePasswordVisibility}
                        >
                            <EyeIcon visibility={isPasswordVisible}/>
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OaPasswordToggleInput;

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        margin: 0,
        width: "100%",
        "& .oapt-input-wrapper": {
            width: "100%",
            display: "flex",
            flexWrap: "nowrap",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
        },
        "& .oapt-input": {
            flexGrow: 1,
            "& .MuiInput-underline:before": {
                borderBottom: "none !important"
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none !important"
            },
            "& .MuiInput-underline:after": {
                borderBottom: "none !important"
            }
        },
        "& .oapt-btn-wrap": {
            width: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
        },
        "& .oapt-btn": {
            position: "absolute",
            left: 0,
            right: 0,
            bottom: -8,
            cursor: "pointer",
            "& i": {
                fontSize: 15
            }
        }
    }
}));
