import React, {useState} from 'react';
import Collapse from '@material-ui/core/Collapse';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from 'components/Grid/GridItem';
import styled from 'styled-components'
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core';


const BeneficiaryListHeading = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const theme=useTheme()
    const BeneficiaryListHeader = styled(GridContainer)`
        background-color: rgba(150,150,150);
        color: #FFFFFF;
        height: 50px;
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 0 10px;
    
    `
    const TopHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 10px;
        padding: 0 10px;
    `
// console.log(props.data);
    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    return (
        <div className="bene-list-root">
        <style>{`
        .bene-list-root {
            width: 100%;
        }
        @media all and (max-width: 959px) {
            .bene-list-root {
                padding: 20px;
            }
        }
        `}</style>
        <TopHeader>
            <div>
                <h4 className={"oa-form-header"} onClick={collapse}  
                    style={{transform: ' scale(0.85)', transformOrigin: 'left', color: theme.palette.background.peach}}>
                        Sender Phone : {props.mobile}, Sender Name : {props.data.fname} {props.data.lname}
                </h4>
            </div>
            <div>
                <Link to={{ pathname: '/moneytransfer/transaction-list', query: { mobile: props.mobile,data:props.data} }}>
                    <Button color="primary" style={{width:150}}>Transaction History</Button>
                </Link>
                <Link to={{ pathname: '/moneytransfer/add-beneficiary', query: { mobile: props.mobile,data:props.data} }}>
                    <Button color="primary" style={{width:150}}>Add Beneficiary</Button>
                </Link>
            </div>
        
        </TopHeader>
       
            <BeneficiaryListHeader>
                {
                    props.data.limit ? 
                    <GridItem md={6}>
                        Total Limit : ₹ {props.data.limit} 
                    </GridItem>
                    :
                    <GridItem md={6}>
                        Total Limit: ₹ {props.data.bank1_limit+props.data.bank2_limit > 25000 ? 25000: props.data.bank1_limit+props.data.bank2_limit}

                        {/* Total Limit: ₹ {props.data.bank1_limit+props.data.bank2_limit+props.data.bank3_limit > 25000 ? 25000: props.data.bank1_limit+props.data.bank2_limit+props.data.bank3_limit} */}
                    </GridItem>
                }
                    {/* <GridItem md={5}>
                        Available Limit : ₹ 50000.00 (Wallet:0.00 / BC : 50000.00) (hard coded data)
                    </GridItem>
                    <GridItem md={2}>
                        Used Limit : ₹ 2500.00 (hard coded data)
                    </GridItem>
                    <GridItem md={2}>
                        State: Non-KYC (hard coded data)
                    </GridItem>                */}
            </BeneficiaryListHeader>
        </div>
       
    )
}

export default BeneficiaryListHeading;
