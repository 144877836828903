import React, { Component } from 'react'
import ReactTable from "react-table";
import { Dialog, DialogActions, DialogContent, Button, Slide } from '@material-ui/core';
import OaCard from 'oahoc/OaCard';
import WebApi, {DOWNLOAD_AGENT_PDF, DOWNLOAD_AGENT_XLS} from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import localforage from 'localforage';
import AgentDetails from './AgentDetails';
import OaButtonPDF from 'pages/components/OaButtonPDF';
import OaButtonXls from 'pages/components/OaButtonXls';
import {currencyFormatter} from 'oautils/oaCommonUtils'
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Build from "@material-ui/icons/Build";
import {localforageSetItem} from 'oautils/oaForageUtils';
export default class AgentListSearchResult extends Component {

    constructor(props){
        super(props);
        this.state = {
            dialogIsOpen: false,
            data: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " "
        }
    }    
    
    clickedRow = {};

    filterGreaterThan(filter, row){
        return row[filter.id] >= filter.value;
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                if(column.Header === 'Agency Name' && rowInfo.original.agentStatus === 1){
                    this.clickedRow = rowInfo.row;
                    this.dialogOpen();
                }
            }
        } 
    }

    dialogOpen = () => {
        this.setState({ dialogIsOpen: true })
    }

    dialogClose = () => {
        this.setState({ dialogIsOpen: false })
    }

    componentDidMount() {  
        this.tableLoader();
    }

    tableLoader() {
        let doStore = (Object.keys(this.props.searchParams).length <= 2);
        let storekey = 'agency-list-' + this.props.searchParams.agentStatus;
        apiCall(WebApi.getAgentList, this.props.searchParams, (response) => {
            let finishedLoadingMessage = response.data.length === 0 ? "No results" : response.data.length;
            if (response.success === false) finishedLoadingMessage = response.message;
            // console.log(this.props.searchParams);
            if(doStore && response.success === true){
                localforageSetItem(storekey, JSON.stringify(response.data), () => {});
            }
            this.setState({
                data: response.data,
                rowCount: response.data.length === 0 ? 4 : response.data.length,
                noDataText: finishedLoadingMessage,
                loading: false,
                finishedLoading: true
            });
            
        })
    }

    render() {
        return (
            <>
                <OaButtonPDF api={DOWNLOAD_AGENT_PDF} searchParams={this.props.searchParams} />
                <OaButtonXls api={DOWNLOAD_AGENT_XLS} searchParams={this.props.searchParams} />
                <ReactTable
                    defaultFilterMethod={this.filterMethod}
                    data={this.state.data}
                    filterable={true}
                    columns={[
                       
                        {
                            Header: "Agency Name",
                            accessor: "agencyName",
                            Cell: props => props.original.agentStatus === 1 
                                && 
                                    <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                        <div style={{display: 'inline-block'}}>
                                        <span  className={'oa-row-action-primary'}>{props.value}</span>
                                        </div>
                                    </div>   
                                || 
                                    <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                        <div style={{display: 'inline-block'}}>
                                            <span>{props.value}</span>
                                        </div>
                                    </div>,
                            width: 250
                        }, {
                            Header: "ID",
                            accessor: "agencyId",
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>
                                    {props.value}
                                </div>
                            ),
                            width: 60
                        },{
                            Header: "Phone",
                            accessor: "phone",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                        {props.value}
                                    </div>
                                </div>
                            ),
                            width: 100
                        },{
                            Header: "Address",
                            accessor: "address",
                            Cell: props => (<div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}><div style={{display: 'inline-block'}}>{props.value}, {props.original.city}, {props.original.state}</div></div>)
                        },{
                            Header: "PAN",
                            accessor: "panNumber",
                            width: 110,
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                        {props.value}
                                    </div>
                                </div>   
                            )
                        }, {
                            Header: "Balance",
                            accessor: "balance",
                            filterMethod: this.filterGreaterThan,
                            width: 150,
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44, width:150}}>
                                    <div style={{display: 'inline-block', width:`100%`, textAlign: 'right'}}>
                                        {currencyFormatter(props.value)}
                                    </div>
                                </div>   
                            )
                        }
                    ]}
                    showPagination={false}
                    minRows={this.state.rowCount}
                    loading= {this.state.loading}
                    pageSize={this.state.rowCount}
                    className="-striped -highlight"
                    getTdProps={this.onRowClick}
                    noDataText={this.state.noDataText}
                />
                <Dialog 
                    open={this.state.dialogIsOpen} 
                    onClose={this.dialogClose} 
                    aria-labelledby="form-dialog-title" 
                    TransitionComponent={Slide} 
                    transitionDuration={100}
                    fullWidth={true}
                    maxWidth="md"
                >   
                    <DialogContent > 
                            <OaCard>
                                <AgentDetails data={this.clickedRow} hideDetails={true} agencyId={this.clickedRow.agencyId}/>
                            </OaCard>
                            <DialogActions>
                                <Button onClick={this.dialogClose} color="secondary">Ok</Button>
                            </DialogActions>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}


{/**
                    <OaButtonPDF api={DOWNLOAD_AGENT_PDF} searchParams={this.props.searchParams} />
                <OaButtonXls api={DOWNLOAD_AGENT_XLS} searchParams={this.props.searchParams} />
    
            <Button variant="contained" size="small"  onClick={()=>{this.download("PDF")}}>PDF</Button>
            <Button variant="contained" size="small"  onClick={()=>{this.download("XLS")}}>XLS</Button>s
    download=(type)=>{
        if(type == 'PDF') {
            apiCallByApiName(WebApi.doDownload, DOWNLOAD_AGENT_PDF, this.props.searchParams, (response) => {
                download(response, "test.pdf", "application/pdf")
            })
        } else {
            apiCallByApiName(WebApi.doDownload, DOWNLOAD_AGENT_XLS, this.props.searchParams, (response) => {
                download(response, "test.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        }
    }


                       <DialogContent style={{width: "600px"}} >
                        <OaCard>
                            <h5 style={{fontWeight: "bold"}}>
                                {this.clickedRow.agencyName}
                            </h5>
                            <ul className="oa-profile-action-list">
                                <li>
                                    <Link to={`/admin/agent/account/profile/${this.clickedRow.agencyId}`}>  
                                        Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/ledger/${this.clickedRow.agencyId}`}>
                                        Ledger
                                    </Link>
                                </li>
                                <li><Link to={"#"}>Change Password</Link></li>
                                <li><Link to={"#"}>Deposit Requests</Link></li>
                                <li><Link to={`/admin/report/flight/${this.clickedRow.agencyId}`}>Flight Report</Link></li>
                            </ul>
                        </OaCard>
                    </DialogContent>
*/}