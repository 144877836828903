import React, { useState, useEffect } from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';
import { Formik, Form } from 'formik';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateField from 'oahoc/OaFormDateField';
import GridItem from "components/Grid/GridItem.js";
import OaFormSelectField from 'oahoc/OaFormSelectField';

const AadhaarpayReportSearchForm = (props) => {
    const [isLoading] = useState(false);

    const [agencyId, setAgencyId] = useState(props.agencyId);

    useEffect(() => {
        if (isLoading) {

        }
    }, [isLoading]);

    useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId])

    return (

        <>
            {isLoading && <OaLoading />}
            {!isLoading && (
                <>
                    <h4
                        className={"oa-form-header"}
                        style={{ transform: " scale(0.85)", transformOrigin: "left" }}
                    >
                        Search AadhaarPay Report
                    </h4>
                    <Formik
                        initialValues={{
                            aadhaarpayBookingFromDateDisplay:
                                props.searchParams.aadhaarpayBookingFromDateDisplay,
                            aadhaarpayBookingToDateDisplay:
                                props.searchParams.aadhaarpayBookingToDateDisplay,
                            aadhaarpayBookingFromDate: props.searchParams.aadhaarpayBookingFromDate,
                            aadhaarpayBookingToDate: props.searchParams.aadhaarpayBookingToDate,
                            agencyId: props.searchParams.agencyId,
                            bookingStatus: props.searchParams.bookingStatus,
                            type: 'aadhaarpay'
                        }}
                        onSubmit={(searchParams, { }) => {
                            props.setIsLoading(true);
                            searchParams.aadhaarpayBookingFromDate = dateFnsFormat(
                                searchParams.aadhaarpayBookingFromDateDisplay,
                                "dd-MM-yyyy"
                            );
                            searchParams.aadhaarpayBookingToDate = dateFnsFormat(
                                searchParams.aadhaarpayBookingToDateDisplay,
                                "dd-MM-yyyy"
                            );
                            props.target(searchParams);
                            props.setIsLoading(false);
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <GridContainer>
                                    <OaFormTextField
                                        name="aadhaarpayApplicationReference"
                                        label="Application Reference"
                                        md={4}
                                    />
                                    <OaFormTextField
                                        name="accountNo"
                                        label="Ack No"
                                        md={4}
                                    />

                                    <OaFormDateField
                                        value={values.aadhaarpayBookingFromDateDisplay}
                                        selected={values.aadhaarpayBookingFromDateDisplay}
                                        onChange={(date) =>
                                            setFieldValue("aadhaarpayBookingFromDateDisplay", date)
                                        }
                                        label="AadhaarPay From Date"
                                        name="aadhaarpayBookingFromDateDisplay"
                                        minDate={values.dateFromDisplay}
                                        md={4}
                                    />

                                    <OaFormDateField
                                        value={values.aadhaarpayBookingToDateDisplay}
                                        selected={values.aadhaarpayBookingToDateDisplay}
                                        onChange={(date) =>
                                            setFieldValue("aadhaarpayBookingToDateDisplay", date)
                                        }
                                        label="AadhaarPay To Date"
                                        name="aadhaarpayBookingToDateDisplay"
                                        minDate={values.dateFromDisplay}
                                        md={4}
                                    />

                                    <GridItem xs={12} sm={12} md={4}>
                                        <OaFormSelectField
                                            label="Status"
                                            name="bookingStatus"
                                            value={values.bookingStatus}
                                            options={[
                                                { value: "All", label: "All" },
                                                { value: "1", label: "Success" },
                                                { value: "0", label: "Failed" },
                                            ]}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <Button color="primary" type="submit">
                                    Search
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>

    )
}

export default AadhaarpayReportSearchForm;
