import React from 'react';
import { Grid, Typography, TextField, FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox, Collapse, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { BsBank } from 'react-icons/bs'


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    formGroup: {
      padding: 10,
      margin: "0.3rem 0.5rem",
    },
    inputSection: {
      marginTop: 10,
      marginBottom: 10
    },
   
  },
}));



const GstDetails = () => {
  const classes = useStyles();

  const [isTrue, setIsTrue] = React.useState(false);

  return (
    <div>
      <Grid container className={""}>
        <Grid item md={12}>
          <div className={""}>
          <FormControl component="fieldset" className={classes.formControl}>
            {/* <FormLabel component="legend">
            <Typography>
              <span style={{ fontWeight: "bold", margin: '0.5rem' }}><BsBank /></span>
              GST 
            </Typography>
            </FormLabel> */}
            <FormGroup>
              <FormControlLabel
                label={
                  <Typography>
                    <span style={{ fontWeight: "bold", margin: '0.5rem' }}><BsBank /></span> GST 
                </Typography>
                }
                control={<Checkbox checked={isTrue} onChange={(e)=> setIsTrue(e.target.checked)} name="Yes" />}
              />
              {/* <FormControlLabel
                control={<Checkbox checked={isTrue} onChange={(e)=> setIsTrue(e.target.checked))} name="yes" />}
                label="yes"
              /> */}
            </FormGroup>
          </FormControl>
           </div>
      </Grid>
       
      </Grid>
      {/* <form className={classes.root} noValidate autoComplete="off">
        <div className="input__grp">
          <TextField id="standard-basic" label="GST Number" />
          <TextField id="standard-basic" label="Company Name" />
          <TextField id="standard-basic" label="Company Contact" />
        </div>
        <div className="input__grp">
          <TextField id="standard-basic" label="Company Address" />
          <TextField id="standard-basic" label="Company Email" />
        </div>
      </form> */}

      <Collapse in={isTrue}>
        <Grid container className={classes.inputSection}>
          <Grid item md={12}>
            <Box className={classes.paxInputSection}>
              <Grid container style={{ padding: "5px 20px",gap:"1rem" }}>

                <Grid item md={3}>
                  <div className={classes.formGroup}>
                    <TextField
                      name="gstNumber"
                      placeholder="GST Number"
                      fullWidth
                    // value={values.gstNumber}
                    // onChange={(e) => {
                    //     setFieldValue("gstNumber", e.target.value);
                    // }}
                    // onBlur={() => saveToProps(values)}
                    />
                  </div>
                </Grid>

                <Grid item md={3}>
                  <div className={classes.formGroup}>
                    <TextField
                      name="gstCompany"
                      placeholder="Company Name"
                      fullWidth
                    // value={values.gstCompany}
                    // onChange={(e) => {
                    //     setFieldValue("gstCompany", e.target.value);
                    // }}
                    // onBlur={() => saveToProps(values)}
                    />
                  </div>
                </Grid>

                <Grid item md={3}>
                  <div className={classes.formGroup}>
                    <TextField
                      name="gstCompanyNumber"
                      placeholder="Company Contact"
                      fullWidth
                    // value={values.gstCompanyNumber}
                    // onChange={(e) => {
                    //     setFieldValue("gstCompanyNumber", e.target.value);
                    // }}
                    // onBlur={() => saveToProps(values)}
                    />
                  </div>
                </Grid>

                <Grid item md={3}>
                  <div className={classes.formGroup}>
                    <TextField
                      name="gstCompanyAddress"
                      placeholder="Company Address"
                      fullWidth
                    // value={values.gstCompanyAddress}
                    // onChange={(e) => {
                    //     setFieldValue("gstCompanyAddress", e.target.value);
                    // }}
                    // onBlur={() => saveToProps(values)}
                    />
                  </div>
                </Grid>

                <Grid item md={3}>
                  <div className={classes.formGroup}>
                    <TextField
                      name="gstEmail"
                      placeholder="Company Email"
                      fullWidth
                    // value={values.gstEmail}
                    // onChange={(e) => {
                    //     setFieldValue("gstEmail", e.target.value);
                    // }}
                    // onBlur={() => saveToProps(values)}
                    />
                  </div>
                </Grid>


                {/* <Grid item md={3}>
                  <div className={clsx(classes.formGroup)}
                    style={{ display: "flex", flexWrap: "nowrap", cursor: "pointer", paddingTop: 20 }}
                    onClick={() => saveGstInfo(values)}
                  >
                    <span style={{ marginRight: 5, fontSize: 12 }}>
                      <i className={"fas fa-save"}></i>
                    </span>
                    <span style={{ cursor: "pointer", fontSize: 12, fontWeight: 400 }}>
                      Save GST info for future use
                    </span>
                  </div>
                </Grid> */}

              </Grid>

            </Box>


          </Grid>
        </Grid>
      </Collapse>

    </div>
  )
}

export default GstDetails