import React, { useState, useEffect } from "react";
import {
    makeStyles,
  } from "@material-ui/core"
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";

  const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        padding:'20px 0',

        "& .res-title":{
         fontSize:25,
        },

        "& .offer-wrapper":{
          width:'100%',
          display:'flex',
          flexWrap:'wrap',
          gap:"30px",

          "& .left-sec":{
            width:'63%',
            [theme.breakpoints.down("sm")]: {
              width:'100%',
            
            },
              "& .valid-n-pcode":{
                display:'flex',
                [theme.breakpoints.down("sm")]: {
                  gap:'23px',
                 flexDirection:'column',
                },
                justifyContent:'space-between',
                "& .book-period":{
                    width:'26%',
                    [theme.breakpoints.down("sm")]: {
                      width:'100%', 
                    },
                    height: '73px',
                    border:'1px solid #d5d5d5',
                    padding:'15px 12px',
                    textAlign:'center',
                    "& p":{
                        margin:0,
                        fontSize:16,
                        "&:nth-child(2)":{
                          color:theme.palette.primary.info,
                        }
                    }
                },

                "& .ticket-box":{
                  position:'relative',
                  marginRight:23,
                  width:'30%',
                  [theme.breakpoints.down("sm")]: {
                    width:'100%',
                    marginRight:0,
                  },
                  borderRadius:9, 
                  backgroundColor:theme.palette.primary.info,
                  padding:'13.6px 12px',
                  textAlign:'center',
                  "& p":{
                    margin:0,
                    color:theme.palette.primary.lightText,
                    fontSize:16,
                    "&.p-c":{
                      paddingBottom:10,
                      borderBottom:`2px dashed ${theme.palette.primary.lightText}`
                    },
                    "&.p-v":{
                      marginTop:10,
                    }
                    // fontSize:16,
                },
                "& .t-circle":{
                    width:23,
                    height:23,
                    top:'36%',
                    position:'absolute',
                    borderRadius:'100%',
                    backgroundColor:"#fff",
                  },

                  "& .circle-left":{
                    left:'-10px',
                  
                  },
                  "& .circle-right":{
                    right:'-10px',
                  }

                }

              },

              "& .details-box":{
                marginTop:13,
                border:'1px solid rgb(225, 225, 225)',
                "& .d-title":{
                    fontSize:20,
                    padding:'10px 15px',
                    backgroundColor:'rgb(225, 225, 225)',
                    margin:0,
                    borderBottom:'1px solid rgb(225, 225, 225)',
                },

                "& .d-content":{
                    padding:10,
                   "& ul":{
                    position:'relative',
                    margin:0,
                    paddingLeft:15,
                    // listStyle:'none',
                     "& li":{
                        fontSize:13,
                        padding:'5px 0 5px 0',
                        // "&::before":{
                        //     content: "•",
                        //     color:'red',
                        //     display:"inline-block", 
                        //     width:"1em",
                        //     marginLeft:"-1em",
                        // }
                     },
                   },
                }
              }
          },

        
          "& .right-sec":{
            "& .offer-img":{
               width:'336px',
               height:'349px',
               margin:'auto',
               "& img":{
                width:"100%",
                height:'100%',
               }
            }
          }



        }

    }

  }))




const OfferDetails = (props) => {
  const classes = useStyles();
//   const history = useHistory();
  const [offerValue, setOfferValue] = useState(props.location.state.val);
  
//console.log(offerValue,"offerValue");






  return (
    <div className={classes.root}>
        <div className="container">
             <h2 className="res-title">Register and Get Discount on Booking First Flight with Us</h2>
            <div className="offer-wrapper">
                <div className="left-sec">
                    <div className="valid-n-pcode">
                        <div className="book-period">
                            <p>BOOKING PERIOD</p>
                            <p>{offerValue.validTo}</p>
                        </div>
                        <div className="ticket-box">
                          <div className="t-circle circle-left"></div>
                            <p className="p-c">PROMO CODE</p>
                            <p  className="p-v">{offerValue.promoCode}</p>
                          <div className="t-circle circle-right"></div>
                        </div>
                    </div>

                    <div className="details-box">
                        <h3 className="d-title">Description</h3>
                        <div className="d-content">
                            {offerValue.description}
                        </div>
                    </div>
                    <div className="details-box">
                        <h3 className="d-title">Offer Details</h3>
                        <div className="d-content">
                         
                            <ul>
                            {
                            offerValue.offerDetails?.split(" /$#/ ").map((data) => (
                              <li>{data??""}</li>
                            ))
                          }  
                            </ul>
                        </div>
                    </div>
                    <div className="details-box">
                        <h3 className="d-title">What You Get ?</h3>
                        <div className="d-content">
                         
                            <ul>
                            {
                            offerValue.whatDoYouGet?.split(" /$#/ ").map((data) => (
                              <li>{data??""}</li>
                            ))
                          }  
                            </ul>
                        </div>
                    </div>
                    <div className="details-box">
                        <h3 className="d-title">How do you get it ? </h3>
                        <div className="d-content">
                         
                            <ul>
                            {
                            offerValue.howDoYouGet?.split(" /$#/ ").map((data) => (
                              <li>{data??""}</li>
                            ))
                          }  
                            </ul>
                        </div>
                    </div>
                    <div className="details-box">
                        <h3 className="d-title">Terms & Conditions</h3>
                        <div className="d-content">
                         
                            <ul>
                            {
                            offerValue.termsCondition?.split(" /$#/ ").map((data) => (
                              <li>{data??""}</li>
                            ))
                          }  
                            </ul>
                        </div>
                    </div>
                    <div className="details-box">
                        <h3 className="d-title">Cancellation Terms Conditions</h3>
                        <div className="d-content">                        
                            <ul>
                            {
                            offerValue.termsCondition?.split(" /$#/ ").map((data) => (
                              <li>{data??""}</li>
                            ))
                          }  
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="right-sec">
                    <div className="offer-img">
                        <img  src={
                BASE_URL_IMAGE_VIEW +
                `agency/view-file?fileName=${offerValue.promoImage}&type=PROMO_IMAGES`
              } alt="img" />
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default OfferDetails
