import React, {useState, useEffect} from 'react'
import OaCard from 'oahoc/OaCard';
import {getAgencyId} from 'oautils/oaCommonUtils.js';
import OaTabBar from 'oahoc/OaTabBar';
import AgentDetails from 'pages/agent/AgentDetails';

import InsuranceReportSearchForm from 'pages/report/insurance/InsuranceReportSearchForm';
import InsuranceReportSearchResult from 'pages/report/insurance/InsuranceReportSearchResult';
import dateFnsFormat from 'date-fns/format';

export const InsuranceReport = (props) => {

    const [agencyId, setAgencyId] = useState(getAgencyId(props));
    const [isLoading, setIsLoading] = useState(true);

    let d = new Date;
    let fromDate = d; //.setDate(d.getDate() - 7);
    let today = new Date();

    let initialParams = {
        // agencyId: agencyId,
        agencyId: "",
        insuranceBookingFromDateDisplay: fromDate,
        insuranceBookingToDateDisplay: today,
        insuranceApplicationReference:"",
        insuranceBookingFromDate: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
        insuranceBookingToDate: dateFnsFormat(today, 'dd-MM-yyyy'),
        accountNo:'',
        bookingStatus: '',
        type: ""
    };

    const [searchParams, setSearchParams] = useState(initialParams);
    const [tableKey, setTableKey] = useState(0);
    
    function insuranceReportLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    let tabs = [
        { name: 'insurance', label: 'Insurance', url: '/admin/booking-report/insurance' },
    ];

    let currentTab = 'insurance';

    useEffect(() => {
        let aId = getAgencyId(props) 
        setAgencyId(aId);
        setSearchParams({...searchParams, agencyId: aId});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (
        <>
        {!isLoading &&
            <OaCard className={'oa-card-primary'}>
                <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
                {agencyId && 
                    (
                        <OaCard>
                            <AgentDetails agencyId={agencyId} />
                        </OaCard>
                    )
                }
                <OaCard>
                    <InsuranceReportSearchForm 
                        target={insuranceReportLoader} 
                        agencyId={agencyId}
                        searchParams={searchParams}
                        setIsLoading={setIsLoading}
                    />
                </OaCard>

                <OaCard>
                    <InsuranceReportSearchResult key={tableKey} searchParams={searchParams} agencyId={agencyId} target={insuranceReportLoader} />
                </OaCard>
            </OaCard>
        }
        </>
    )
}
export default InsuranceReport;
