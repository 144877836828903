import React from "react";
import WebApi from "api/ApiConstants";
import FlightItinerary from "./FlightItinerary";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { CheckCircle, PrintOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";
import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem, localforageSetItem } from "oautils/oaForageUtils";

const PaymentSuccess = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [refId, setRefId] = React.useState(history.location.state.Tid);
  const [flightData, setFlightData] = React.useState(null);
  const [state, setState] = React.useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });
  const [userDetails, setUserDetails] = React.useState(null);
  const isMounted = React.useRef(false);

  const printTicket = () => {
    history.push({
      pathname:
        "/print/flightticket/" +
        history.location.state.Tid +
        "/" +
        state?.isAddressHide +
        "/" +
        state?.isFareHide +
        "/" +
        state?.isPerPassenger +
        "/" +
        state?.isGSTHide,
    });
  };

  const downloadEticket = (event) => {
    event.target.setAttribute("disabled", "disabled");
    pdfDownload(
      "section-to-print",
      "p",
      "eticket-" + history.location.state.Tid
    );
    event.target.removeAttribute("disabled");
  };

  const emailETicket = () => {
    flightData &&
      getImageData("section-to-print", "p", function (imageData) {
        apiCall(
          WebApi.emailETicket,
          {
            email: flightData.emailId,
            body:
              "<!DOCTYPE html><html><body><img src='" +
              imageData +
              "'/></body></html> ",
          },
          function (response) {
            if (response.success === true) {
              setIsError(false);
              setIsSuccess(true);
              setIsSaving(false);
              setInfoText("Send email request registered");
            } else {
              setIsError(true);
              setIsSaving(false);
              setInfoText("Email server busy try after sometime");
            }
          }
        );
      });
  };

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function (err, value) {
      if (value) {
        setUserDetails(value);
      }
    });
  };

  const refreshBalanceBalance = (usrDetails) => {
    apiCall(WebApi.getDashboardData, {}, function (response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      localforageSetItem("user-details", {
        ...usrDetails,
        cashBalance: mainAccountBalance,
      });
    });
  };

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current) getValuesFromLocalStorage();
  }, [isMounted.current]);

  React.useEffect(() => {
    if (userDetails) {
      let tid = history.location.state.Tid
        ? history.location.state.Tid
        : props.match.params?.tid;
      WebApi.getTicketData(
        { transactionId: tid },
        (response) => {
          // console.log("response", response);
          if (response.success == true) {
            setRefId(response.data.bookingRef);
            setFlightData(response.data);
            if (userDetails?.partnerId === 1002) {
              refreshBalanceBalance(userDetails);
            }
          }
        },
        (error) => console.log("error", error)
      );
    }
  }, [userDetails]);

  return (
    <div className={classes.root}>
      <div className="section-container" style={{ width: "100%" }}>
        <div>
          <div className="icon-group">
            <CheckCircle />
            <Typography>
              Reference id: <b>{refId}</b>
            </Typography>
            <Typography className="ticketDetailsText">
              Booking Successful.{" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push("/print/flightticket/" + history.location.state.Tid)
                }
              >
                Go to ticket details
              </a>
            </Typography>
          </div>
        </div>

        <div className="flex-start">
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
          <div className="itin-section">
            <div className="action-group">
              {/*flightData?.bookingStatus != "SUCCESS" && flightData?.bookingStatus != "SUCCESSCan" && */
                flightData?.bookingStatus == "SuccessTkd" && (
                  <Button onClick={printTicket}>
                    <PrintOutlined /> Print
                  </Button>
                )}

              {/* <Button
                            onClick={emailETicket}
                        >
                            <EmailOutlined/> Email
                        </Button>

                        <Button
                            onClick={downloadEticket}
                        >
                            <CloudDownloadOutlined/> Download
                        </Button> */}
            </div>

            <div className="itin-contents">
              <FlightItinerary flightdata={flightData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .ticketDetailsText": {
      [theme.breakpoints.down(980)]: {
        fontSize: 15,
      },
    },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "&.flex-start": {
        alignItems: "flex-start",
      },
    },
    "& .itin-section": {
      padding: 0,
      width: "100%",
      "& .itin-contents": {
        padding: "10px 15px",
      },
    },
    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "25px 0",
      [theme.breakpoints.down(420)]: {
        marginLeft: 10,
      },
      [theme.breakpoints.down(350)]: {
        marginLeft: 0,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "5rem",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 20,
      },
    },
    "& .action-group": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 10,
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: "2px 6px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: 5,
      },
    },
  },
}));
