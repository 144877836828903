export const SET_FLIGHT_DOMESTIC_CARRIERS = "SET_FLIGHT_DOMESTIC_CARRIERS";
export const SET_FLIGHT_INTERNATIONAL_CARRIERS =
  "SET_FLIGHT_INTERNATIONAL_CARRIERS";

export const SET_FLIGHT_AIRPORTS = "SET_FLIGHT_AIRPORTS";

export function setDomesticCarriers(payload) {
  return {
    type: SET_FLIGHT_DOMESTIC_CARRIERS,
    payload,
  };
}

export function setInternationalCarriers(payload) {
  return {
    type: SET_FLIGHT_INTERNATIONAL_CARRIERS,
    payload,
  };
}

export function setAirports(payload) {
  return {
    type: SET_FLIGHT_AIRPORTS,
    payload,
  };
}
