import React from "react";
import dateFnsFormat from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";

const FlightDetailsCardMobile = ({ value }) => {
  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";

  const theme = useTheme();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const useStyles = makeStyles((theme) => ({
    flightDetailElements: {
      width: "calc(100% - 10px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      margin: 5,
      borderRadius: 10,
      border: `2px solid ${theme.palette.background.lightGray}`,
      // boxShadow: `1px 1px 1px 1px ${theme.palette.background.lightGray}`,
      padding: 10,
    },
    flightDetailElement: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      margin: "5px 0",
    },
    label: {
      flex: 3,
      fontSize: "12px",
      fontWeight: 700,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      },
    },
    content: {
      flex: 9,
      display: "flex",
      // flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
      "& span, div": {
        marginRight: 5,
        fontSize: 11
      }
    },
    flightNumberContent: {
      flex: 9,
      display: "flex",

      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    flightNo: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 10,
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.flightDetailElements}>
      <div className={classes.flightDetailElement}>
        <p className={classes.label}>Flight No:</p>
        <div className={classes.flightNumberContent}>
          <img
            style={{ width: "35px", height: "35px" }}
            src={
              image_base +
              "?fileName=" +
              value.carrierDetails.carrierCode +
              ".png&type=AIRLINE_IMAGES"
            }
          ></img>
          <div className={classes.flightNo}>
            <div>{value.carrierDetails.carrierName}</div>
            <div>
              {value.carrierDetails.carrierCode}-
              {value.carrierDetails.flightNumber}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.flightDetailElement}>
        <p className={classes.label}>Departure:</p>
        <div className={classes.content}>
          <span>
            {dateFnsFormat(new Date(value.departDetails.dateTime), "HH:mm")}
          </span>
          <span>
            {days[new Date(value.departDetails.dateTime).getDay()]},&nbsp;
            {dateFnsFormat(new Date(value.departDetails.dateTime), "dd MMM")}
          </span>
          <div>
            {value.departDetails.airportName} ({value.departDetails.airportCode}
            )
            {value.departDetails.terminal != "" && (
              <span>Terminal {value.departDetails.terminal}</span>
            )}
          </div>
        </div>
      </div>
      <div className={classes.flightDetailElement}>
        <p className={classes.label}>Arrival:</p>
        <div className={classes.content}>
          <span>
            {dateFnsFormat(new Date(value.arriveDetails.dateTime), "HH:mm")}
          </span>
          <span>
            {days[new Date(value.arriveDetails.dateTime).getDay()]},
            {dateFnsFormat(new Date(value.arriveDetails.dateTime), "dd MMM")}
          </span>
          <div>
            {value.arriveDetails.airportName} ({value.arriveDetails.airportCode}
            )
            {value.arriveDetails.terminal != "" && (
              <span>Terminal {value.arriveDetails.terminal}</span>
            )}
          </div>
        </div>
      </div>
      <div className={classes.flightDetailElement}>
        <p className={classes.label}>Flight Time:</p>
        <div className={classes.content}>
          {" "}
          {Math.floor(value.flightTime / (24 * 60)) > 0
            ? Math.floor(value.flightTime / (24 * 60)) + "d "
            : ""}
          &nbsp;
          {Math.floor(value.flightTime / 60) <= 9
            ? "0" + Math.floor(value.flightTime / 60)
            : Math.floor(value.flightTime / 60)}
          h &nbsp;
          {Math.floor(value.flightTime % 60) <= 9
            ? "0" + Math.floor(value.flightTime % 60)
            : Math.floor(value.flightTime % 60)}
        </div>
      </div>

      <div className={classes.flightDetailElement}>
        <p className={classes.label}>Layover Time:</p>
        <div className={classes.content}>
          {value.layover && value.layover > 0 ? (
            <span>
              {Math.floor(value.layover / (24 * 60)) > 0
                ? Math.floor(value.layover / (24 * 60)) +
                  "day" +
                  (Math.floor(value.layover / (24 * 60)) > 1 ? "s " : " ")
                : ""}
              &nbsp;
              {Math.floor(value.layover / 60)} hrs{" "}
              {Math.floor(value.layover % 60)} min
            </span>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsCardMobile;
