import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Select,
  IconButton,
  Popper,
  MenuItem,
  ListItemText,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { Formik, Form, ErrorMessage, FieldArray, useFormikContext } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { COLORS } from "assets/css/CssConstants";
import DateFnsUtils from "@date-io/date-fns";
import dateFnsFormat from "date-fns/format";
import { allAirlines,lccAirlines,gdsAirlines } from "api/ApiConstants";

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {},
    radioGroupFlex: {
      flexDirection: "row",
      gap: "2vw",
      [theme.breakpoints.down(600)]: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: "0",
        rowGap: "0",
        columnGap: "2vw",
      },
      "& span.MuiFormControlLabel-label": {
        [theme.breakpoints.down(1080)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(800)]: {
          fontSize: "12px",
        },
      },
    },

    flightSearchElement: {},
    flightSearchElement_title: {
      height: "16px",
      marginBottom: "0",
      padding: "0 15px",
      "& span": {
        fontSize: "12px",
        fontWeight: "600",
        [theme.breakpoints.down(1080)]: {
          fontSize: "8px",
        },
      },
    },
    flightSearchElement_value: {
      height: "24px",
      padding: "0 15px",
      "& span": {
        fontSize: "20px",
        fontWeight: "400",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(800)]: {
          fontSize: "12px",
        },
      },
      "& .MuiInputBase-root": {
        color: theme.palette.primary.lightText,
        "& .MuiInputBase-input": {
          fontSize: "20px",
          fontWeight: "400",
          [theme.breakpoints.down(1080)]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down(800)]: {
            fontSize: "12px",
          },
        },
        borderBottom: `1px solid  ${theme.palette.primary.lightText}`,
        "& :hover:not(.Mui-disabled):before": {
          borderBottom: `2px solid  ${theme.palette.primary.lightText}`,
        },
        "& :before": {
          transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: `1px solid  ${theme.palette.primary.lightText}`,
        },
        "& :after": {
          transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          borderBottom: `2px solid  ${theme.palette.primary.active}`,
        },
        "& .MuiButtonBase-root": {
          color: theme.palette.primary.lightText,
        },
        "& .MuiSelect-icon": {
          color: theme.palette.primary.lightText,
        }
      },
    },

    modifyActions: {
      justifyContent: "end",
      height: "40px",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "700",
      gap: "20px",
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
        height: "30px",
      },
    },
    action_reset: {
      cursor: "pointer",
      border:`2px solid ${theme.palette.primary.lightText}`,
      borderRadius: "4px",
      padding: "4px",
      display: "table",
      "& span": {
        display: "table-cell",
        verticalAlign: "middle",
      },
    },
    action_update: {
      cursor: "pointer",
      color: theme.palette.primary.defaultText,
      backgroundColor: theme.palette.primary.lightText,
      borderRadius: "4px",
      padding: "4px",
      display: "table",
      "& span": {
        display: "table-cell",
        verticalAlign: "middle",
      },
    },

    inputDetailsGrid: {
      [theme.breakpoints.down(600)]: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: "0",
        rowGap: "2vw",
        columnGap: "2vw",
      },
    },

    sectorButton: {
      cursor: "pointer",
      border: `2px solid ${theme.palette.primary.lightText}`,
      borderRadius: "4px",
      padding: "6px 24px",
      [theme.breakpoints.down(1080)]: {
        padding: "4px 20px",
      },
      [theme.breakpoints.down(800)]: {
        padding: "2px 16px",
        marginTop: "6px",
      },
      display: "table",
      "& span": {
        display: "table-cell",
        verticalAlign: "middle",
        fontSize: "12px",
        fontWeight: "400",
        [theme.breakpoints.down(1080)]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(800)]: {
          fontSize: "8px",
        },
      },
    },
    flightSearchElement_swap: {
      textAlign: "center",
      paddingTop: 15,
      paddingBottom: 0,
    },
    swapIconButton: {
      "& .MuiIconButton-label": {
        color: theme.palette.primary.lightText,
      },
    },
  };
});

const FlightModify = ({ multiCity, cancelModify, updateModify, arrayModify, props }) => {
  const classes = useStyles();

  const [departDateDialogOpen, setDepartDateDialogOpen] = useState(false);
  const [returnDateDialogOpen, setReturnDateDialogOpen] = useState(false);
  const [airlineSelectOpen, setAirlineSelectOpen] = useState(false);
  
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [carrier, setCarrier] = useState(null);

  const [airports, setAirports] = useState(undefined);
  const [domesticCarriers, setDomesticCarriers] = useState(undefined);
  const [internationalCarriers, setInternationalCarriers] = useState(undefined);

  const [departAirportObj, setDepartAirportObj] = useState(null);
  const [arrivalAirportObj, setArrivalAirportObj] = useState(null);

  const formikRef = useRef();
  const departAutocompleteEl = useRef(null);
  const arriveAutocompleteEl = useRef(null);

  const customPopperFrom = function(props) {
    //console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };
  const customPopperTo = function(props) {
    //console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={arriveAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };

  // const propState = props.history.location.state;

  const getAirlineData = () => {
    if (
      airports === undefined ||
      domesticCarriers === undefined ||
      internationalCarriers === undefined
    ) {
      apiCall(WebApi.getAirports, {}, (response) => {
        if (response.success) {
          setAirports(response.data.airports);
          setDomesticCarriers(response.data.domCarriers);
          setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);

          const depAirport = response?.data?.airports?.filter(val => {return val.airportCode == arrayModify?.depAirportCode})[0];
          const arrAirport = response?.data?.airports?.filter(val => {return val.airportCode == arrayModify?.arrAirportCode})[0];
          setDepartAirportObj(depAirport);
          setArrivalAirportObj(arrAirport);

          // console.log("depAirport", depAirport);
          // console.log("arrAirport", arrAirport);
        } else {
          //setIsError(true);
        }
      });
    }
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  useEffect(() => {
    getAirlineData();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(props.domCarriers);
      } else {
        setCarrier(props.intlCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  const flightInputDetailGridItem = (
    <Grid item>
      <Grid container direction="row" className={classes.inputDetailsGrid}>
        {flightSearchHeaderDetails1.map((e, i) => (
          <Grid item xs={6} sm={3} className={classes.flightSearchElement}>
            <div className={classes.flightSearchElement_title}>
              <span>{e.title}</span>
            </div>
            <div className={classes.flightSearchElement_value}>
              <TextField
                color="secondary"
                id={`TextFieldMod_${e.title}`}
                value={e.value}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const flightMulticityInputDetailGridItem = flightSearchMulticityHeaderDetails1.map(
    (v, i1) => (
      <Grid item>
        <Grid container direction="row" className={classes.inputDetailsGrid}>
          {v.map((e, i2) => (
            <Grid item xs={6} sm={3} className={classes.flightSearchElement}>
              <div className={classes.flightSearchElement_title}>
                <span>{e.title}</span>
              </div>
              <div className={classes.flightSearchElement_value}>
                <TextField
                  color="secondary"
                  id={`TextFieldMod_${e.title}`}
                  value={e.value}
                />
              </div>
            </Grid>
          ))}
          <Grid item>
            <div className={classes.sectorButton}>
              <span>
                {i1 === v.length - 1 ? "+ Add Sector" : "- Remove Sector"}
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  );

  const submitModify = () => {
    
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
    updateModify(false);
  }

  return (
    <div className={classes.root}>
      {/* oaFormAlert start */}
      <Grid
        container
        direction="column"
        alignItems="stretch"
        style={{ gap: "24px" }}
      >
        <Grid item>
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
      </Grid>
      {/* oaFormAlert end */}

      <Formik
        innerRef={formikRef}
        initialValues={{
          sectors: [
            {
              origin: { airportCode:arrayModify?.arrAirportCode },
              destination: { airportCode:arrayModify?.depAirportCode },
              departDate: dateFnsFormat(arrayModify?.depDateRaw, "yyyy-MM-dd"),
              arrivalDate: dateFnsFormat(arrayModify?.arrDateRaw, "yyyy-MM-dd"),
            },
          ],
          mode: arrayModify?.request?.mode,
          adultPax: arrayModify?.request?.adultPax,
          childPax: arrayModify?.request?.childPax,
          infantPax: arrayModify?.request?.infantPax,
          preferredClass: arrayModify?.request?.preferredclass,
          preferredAirline: [allAirlines],
          // isNonStop: "non-stop",
          isNonStop: false,
        }}
        onSubmit={(values) => {

          localforageGetItem("user-details", function(err, details) {
            // console.log(details);
            // console.log("values",values);
            // props.history.push({
            //   pathname: "/flight/search-progress",
            //   state: values,
            // });
            props.cancelModify();
          });

        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) =>(
        
        <Form>
          <Grid
          container
          direction="column"
          alignItems="stretch"
          style={{ gap: "24px" }}
        >
          {/* Flight trip mode section start */}
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup
                  value={values.mode}
                  aria-label="flight stops"
                  name="mode"
                  className={classes.radioGroupFlex}
                  onChange={(e) => {
                    setFieldValue(`mode`, e.target.value);
                    if (values.sectors.length > 1) {
                      // setFieldValue('sectorArray', )

                      values.sectors.length = 1;
                    }
                  }}
                >
                  {FLIGHT_WAY.map((s, i) => (
                    <FormControlLabel
                      key={s.value}
                      value={s.value}
                      control={<FilterRadio />}
                      label={s.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* Flight trip mode section end */}

            {multiCity
            ? flightMulticityInputDetailGridItem
            :
            <>
              <FieldArray
                name="sectors"
                render={(arrayHelpers) => (
                  <>
                    {values.sectors &&
                      values.sectors.length > 0 &&
                      values.sectors.map((sector, index) => {
                        return (
                          <Grid item key={index}>
                            <Grid container className={classes.inputDetailsGrid}>

                              <Grid item xs={12} sm={6}>
                                <Grid container justifyContent="space-evenly">

                                  <Grid item xs={12} sm={5} className={classes.flightSearchElement}>

                                    <div className={classes.flightSearchElement_title}>From</div>

                                    <div className={classes.flightSearchElement_value}>
                                      {departAirportObj && 
                                      <Autocomplete
                                        fullWidth
                                        PopperComponent={customPopperFrom}
                                        name={`sectors[${index}].origin`}
                                        options={airports || []}
                                        getOptionLabel={(o) =>
                                          o
                                            ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                            : ""
                                        }
                                        // value={
                                        //   values.sectors[index].origin ||
                                        //   ""
                                        // }
                                        defaultValue={departAirportObj}
                                        getOptionSelected={(option, value) =>
                                          option.airportCode == value.airportCode
                                        }
                                        onChange={(event, newValue) => {
                                          // console.log(newValue);
                                          changeHandler(
                                            "from",
                                            newValue?.countryCode
                                          );
                                          arriveAutocompleteEl.current.focus();
                                          setFieldValue(
                                            `sectors[${index}].origin`,
                                            newValue
                                          );
                                        }}
                                        ref={departAutocompleteEl}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            color="secondary"
                                          />
                                        )}
                                        disablePortal={true}
                                      />}
                                      <ErrorMessage
                                        name={`sectors[${index}].origin`}
                                        component="div"
                                        className="error"
                                      />
                                    </div>

                                  </Grid>

                                  <Grid item xs={12} sm={2} className={classes.flightSearchElement_swap}>

                                    <IconButton
                                      onClick={() => {
                                        const fromAirportTemp =
                                          values.sectors[index].origin;
                                        // const b = {
                                        //   ...values.sectorArray[index].toAirport,
                                        // };
                                        // console.log(
                                        //   "fromAirportTemp" +
                                        //   JSON.stringify(fromAirportTemp)
                                        // );
                                        setFieldValue(
                                          `sectors[${index}].origin`,
                                          values.sectors[index].destination
                                        );
                                        setFieldValue(
                                          `sectors[${index}].destination`,
                                          fromAirportTemp
                                        );
                                      }}
                                      aria-label="delete"
                                      className={classes.swapIconButton}
                                    >
                                      <SwapHorizIcon />
                                    </IconButton>

                                  </Grid>

                                  <Grid item xs={12} sm={5} className={classes.flightSearchElement}>

                                    <div className={classes.flightSearchElement_title}>To</div>

                                    <div className={classes.flightSearchElement_value}>
                                      {arrivalAirportObj && 
                                      <Autocomplete
                                        fullWidth
                                        PopperComponent={customPopperTo}
                                        name={`sectors[${index}].destination`}
                                        options={airports || []}
                                        getOptionLabel={(o) =>
                                          o
                                            ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                            : ""
                                        }
                                        // value={
                                        //   values.sectors[index].destination ||
                                        //   ""
                                        // }
                                        defaultValue={arrivalAirportObj}
                                        getOptionSelected={(option, value) =>
                                          option.airportCode == value.airportCode
                                        }
                                        onChange={(event, newValue) => {
                                          // console.log(newValue);
                                          changeHandler(
                                            "to",
                                            newValue?.countryCode
                                          );
                                          arriveAutocompleteEl.current.blur();
                                          setDepartDateDialogOpen(true);
                                          setFieldValue(
                                            `sectors[${index}].destination`,
                                            newValue
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            color="secondary"
                                            inputRef={arriveAutocompleteEl}
                                          />
                                        )}
                                        disablePortal={true}
                                      />}
                                      <ErrorMessage
                                        name={`sectors[${index}].destination`}
                                        component="div"
                                        className="error"
                                      />
                                    </div>

                                  </Grid>

                                </Grid>
                              </Grid>
                              
                              <Grid item xs={12} sm={6}>
                                <Grid container justifyContent="flex-start">

                                  <Grid item xs={12} sm={5} className={classes.flightSearchElement}>
                                  
                                    <div className={classes.flightSearchElement_title}>Depart</div>

                                    <div className={classes.flightSearchElement_value}>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <KeyboardDatePicker
                                          KeyboardButtonProps={{
                                            onFocus: (e) => {
                                              setDepartDateDialogOpen(true);
                                            },
                                          }}
                                          PopoverProps={{
                                            disableRestoreFocus: true,
                                            onClose: () => {
                                              setDepartDateDialogOpen(false);
                                            },
                                          }}
                                          InputProps={{
                                            onFocus: () => {
                                              setDepartDateDialogOpen(true);
                                            },
                                          }}
                                          name={`sectors[${index}].departDate`}
                                          open={departDateDialogOpen}
                                          onClose={() =>
                                            setDepartDateDialogOpen(false)
                                          }
                                          onOpen={() =>
                                            setDepartDateDialogOpen(true)
                                          }
                                          disablePast
                                          id="departDateEl"
                                          className={classes.departDatePicker}
                                          fullWidth
                                          color="secondary"
                                          disableToolbar
                                          variant="inline"
                                          format="dd-MM-yyyy"
                                          margin="normal"
                                          // value={data.depart}
                                          value={values.sectors[index].departDate}
                                          onChange={(e, v) => {
                                            // console.log("value", v);
                                            // console.log("event", e);
                                            // setData({ ...data, depart: new Date(v).toISOString() });
                                            setDepartDateDialogOpen(false);
                                            if (
                                              values.mode === "ROUND" ||
                                              values.mode === "ROUND-SP"
                                            ) {
                                              setReturnDateDialogOpen(true);
                                            } else {
                                              // setAirlineSelectOpen(true);
                                            }
                                            
                                            const depDate = dateFnsFormat(e, "yyyy-MM-dd");
                                            setFieldValue(`sectors[${index}].departDate`,depDate);
                                            
                                            if (
                                              new Date(v).getTime() >
                                              new Date(
                                                values.sectors[
                                                  index
                                                ].departDate
                                              ).getTime()
                                            ) {
                                              values.sectors[
                                                index
                                              ].departDate = new Date(
                                                new Date(v).getTime() +
                                                24 * 60 * 60 * 1000
                                              ).toISOString();
                                            }
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                      <ErrorMessage
                                        name={`sectors[${index}].departDate`}
                                        component="div"
                                        className="error"
                                      />
                                    </div>

                                  </Grid>
                                  
                                  {(values.mode === "ROUND" || values.mode === "ROUND-SP") && (
                                  <Grid item xs={12} sm={5} className={classes.flightSearchElement}>

                                    <div className={classes.flightSearchElement_title}>Arrival</div>

                                    <div className={classes.flightSearchElement_value}>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <KeyboardDatePicker
                                          name={`sectors[${index}].arrivalDate`}
                                          open={returnDateDialogOpen}
                                          onClose={() =>
                                            setReturnDateDialogOpen(false)
                                          }
                                          onOpen={() =>
                                            setReturnDateDialogOpen(true)
                                          }
                                          minDate={
                                            values.sectors[index].departDate
                                          }
                                          className={classes.returnDatePicker}
                                          fullWidth
                                          color="secondary"
                                          disableToolbar
                                          variant="inline"
                                          format="dd-MM-yyyy"
                                          margin="normal"
                                          // value={data.return}
                                          value={
                                            values.sectors[index].arrivalDate
                                          }
                                          onChange={(e, v) => {
                                            // setData({ ...data, return: v });
                                            const arrDate = dateFnsFormat(e, "yyyy-MM-dd");
                                            setFieldValue(`sectors[${index}].arrivalDate`,arrDate);
                                            setReturnDateDialogOpen(false);
                                            // setAirlineSelectOpen(true);
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                      <ErrorMessage
                                        name={`sectors[${index}].arrivalDate`}
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                  </Grid>
                                  )}

                                </Grid>
                              </Grid>
                              
                            </Grid>
                          </Grid>
                        );
                      })}
                  </>
                )}
              />

            </>}

            <Grid item>
              <Grid container direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={values.isNonStop}
                      aria-label="flight stops"
                      name="isNonStop"
                      className={classes.radioGroupFlex}
                      onChange={(e) => {
                        setFieldValue(`isNonStop`, e.target.value);
                      }}
                    >
                      {FLIGHT_STOPS.map((s, i) => (
                        <FormControlLabel
                          value={s.value}
                          control={<FilterRadio />}
                          label={s.label}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="isNonStop"
                      component="div"
                      className="error"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl component="fieldset" error={values.preferredAirline.length === 0}>
                    <RadioGroup
                      value={values.preferredClass}
                      aria-label="preferred class"
                      name="preferredClass"
                      className={classes.radioGroupFlex}
                      onChange={(e) => {
                        setFieldValue(`preferredClass`, e.target.value);
                      }}
                    >
                      {FLIGHT_CLASS.map((s, i) => (
                        <FormControlLabel
                          value={s.value}
                          control={<FilterRadio />}
                          label={s.label}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="preferredClass"
                      component="div"
                      className="error"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    direction="row"
                    className={classes.inputDetailsGrid}
                  >
                    <Grid item xs={6} className={classes.flightSearchElement}>
                      <div className={classes.flightSearchElement_title}>
                        <span>Airlines</span>
                      </div>

                      <div className={classes.flightSearchElement_value}>
                        <TextField 
                          value={flightSearchHeaderDetails2.value}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} className={classes.flightSearchElement}>
                      <div className={classes.flightSearchElement_title}>
                        <span>Airlines</span>
                      </div>
                      
                      <div className={classes.flightSearchElement_value}>
                        <FormControl
                          color="secondary"
                          className={classes.airlineSelect}
                          error={values.preferredAirline.length === 0}
                          style={{
                            width: "100%"
                          }}
                        >
                          <Select
                            multiple
                            name="preferredAirline"
                            open={airlineSelectOpen}
                            value={values.preferredAirline}
                            onChange={(e) => {
                              // setData({ ...data, airlines: e.target.value });
                              // setDataError({ ...dataError, airlines: false });
                              // console.log(e.target.value);
                              setFieldValue(
                                `preferredAirline`,
                                e.target.value
                              );
                              // setAirlineSelectOpen(false);
                            }}
                            renderValue={(selected) => {
                              let initialValue = "";
                              
                              if (
                                selected.includes(
                                  allAirlines
                                )
                              ) {
                                initialValue = initialValue + ", " + "All";
                              }
                              if (
                                selected.includes(
                                  lccAirlines
                                )
                              ) {
                                initialValue = initialValue + ", " + "LCC Only";
                              }
                              if (selected.includes(gdsAirlines)) {
                                initialValue = initialValue + ", " + "GDS Only";
                              }
                              initialValue = carrier && carrier.reduce(
                                (previousValue, currentValue) =>
                                selected.includes(
                                  currentValue.name
                                )
                                ? previousValue +
                                ", " +
                                currentValue.description
                                : previousValue,
                                initialValue
                              );
                              
                              return (
                                initialValue &&
                                initialValue.substring(1, initialValue.length - 1));
                            }}
                            onOpen={() =>
                              setAirlineSelectOpen(true)
                            }
                            onClose={() => {
                              // setDataError({
                              //   ...dataError,
                              //   airlines: true,
                              // });

                              setAirlineSelectOpen(false);
                            }}
                          >
                            <MenuItem
                              key={allAirlines}
                              value={allAirlines}
                            >
                              <ListItemText primary={"All"} />
                              <Checkbox
                                checked={
                                  values.preferredAirline.indexOf(
                                    allAirlines
                                  ) > -1
                                }
                              />
                            </MenuItem>
                            
                            <MenuItem
                              key={lccAirlines}
                              value={lccAirlines}
                            >
                              <ListItemText primary={"LCC Only"} />
                              <Checkbox
                                checked={
                                  values.preferredAirline.indexOf(
                                    lccAirlines
                                  ) > -1
                                }
                              />
                            </MenuItem>
                            <MenuItem key={gdsAirlines} value={gdsAirlines}>
                              <ListItemText primary={"GDS Only"} />
                              <Checkbox checked={values.preferredAirline.indexOf(gdsAirlines) > -1}/>
                            </MenuItem>

                            {carrier && carrier.map((a) => (
                            <MenuItem
                              key={a.name}
                              value={a.name}
                            >
                              <ListItemText primary={a.description}/>
                              <Checkbox checked={values.preferredAirline.indexOf(a.name) > -1}/>
                            </MenuItem>
                            ))}
                          </Select>
                          {values.preferredAirline.length === 0 && (
                            <FormHelperText>
                              Please select an airline
                            </FormHelperText>
                          )}
                        </FormControl>
                        <ErrorMessage
                          name="airline"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" className={classes.modifyActions}>
                <Grid
                  item
                  className={classes.action_reset}
                  onClick={cancelModify}
                >
                  <span>Cancel</span>
                </Grid>
                <Grid
                  item
                  className={classes.action_update}
                  onClick={submitModify}
                >
                  <span>Update</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>

        )}
      </Formik>
    </div>
  );
};

export default FlightModify;

const flightSearchHeaderDetails1 = [
  { title: "From", value: "Bangalore (BLR)" },
  { title: "To", value: "Hyderabad (HYD)" },
  { title: "Depart", value: "Mon, 14 Dec 2021" },
  { title: "Arrival", value: "Tue, 15 Dec 2021" },
];
const flightSearchMulticityHeaderDetails1 = [
  [
    { title: "From", value: "Bangalore (BLR)" },
    { title: "To", value: "Chennai (MAA)" },
    { title: "Depart", value: "Mon, 14 Dec 2021" },
  ],
  [
    { title: "From", value: "Hyderabad (HYD)" },
    { title: "To", value: "Mumbai (BMB)" },
    { title: "Depart", value: "Thu, 17 Dec 2021" },
  ],
  [
    { title: "From", value: "Kolkata (KKR)" },
    { title: "To", value: "Delhi (DLH)" },
    { title: "Depart", value: "Sun, 20 Dec 2021" },
  ],
];
const flightSearchHeaderDetails2 = { title: "Passengers", value: "2 Adults, 1 Child" };
const FLIGHT_WAY = [
  { value: "ONE", label: "One Way" },
  { value: "ROUND", label: "Round Trip" },
  { value: "ROUND-SP", label: "Round Trip Special" },
  { value: "MULTI-CITY", label: "Multi City" },
];
const FLIGHT_STOPS = [
  { value: true, label: "Stop" },
  { value: false, label: "Non Stop" },
];
const FLIGHT_CLASS = [
  { value: "E", label: "Economy" },
  { value: "B", label: "Business" },
];

const radioStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: `1px auto ${theme.palette.secondary.contrastText}`,
        outlineOffset: 1,
      },
      "input:hover ~ &": {
        backgroundColor: `${theme.palette.secondary.contrastText}90`,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: `${theme.palette.secondary.contrastText}10`,
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.secondary.contrastText,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: `radial-gradient(${theme.palette.secondary.main},${theme.palette.secondary.main} 28%,transparent 32%)`,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: `${theme.palette.secondary.contrastText}90`,
      },
    },
  };
});

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      {...props}
    />
  );
}
