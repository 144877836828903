import React, { Component } from 'react'
import ReactTable from "react-table";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import OaAlert from 'oahoc/OaAlert';
import {currencyFormatter} from 'oautils/oaCommonUtils';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class TransactionResponseListTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            dialogIsOpen: false,
            data: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " ",
            isAlertPrompt: false,
            alertCallback: '',
            alertCallbackParam: {},
            alertMsg:'',
            serialNo:props.serialNo
        }
    } 

    componentDidMount() {  
        this.tableLoader();
        this.interval = setInterval(() => {
            let isStop = true;
            this.state.data.forEach(element => {
                if(element.txnStatusCode !== 1 && element.txnStatusCode !== 5) {
                    isStop = false
                }
            });
            // console.log('isStop');
            // console.log(isStop);
            if(isStop) {
                clearInterval(this.interval);
            } else {
                this.tableLoader();
            }
        }, 1000 * 60);
    }

    dropDownMenu = [
        "Refund Transaction"
    ];

     onSelectMenu(event, data){
        switch(event){
            case "Refund Transaction":
                this.props.history.push('/moneytransfer/refund',{data:data} );
            break;  
        }
    }

    onSelectPrintMenu(event, data){
        switch(event){
            case "Invoice With Out Operator Charges":
                this.props.history.push('/admin/voucher/dmt-without-charges/' + data.serialno);
            break;
            case "Invoice With Operator Charges":
                this.props.history.push('/admin/voucher/dmt-with-charges/' + data.serialno);
            break;
        }
    }

    tableLoader() {
        // console.log(this.props.mobile)
        apiCall(WebApi.dmtQueryTransactSerial, {serialNo:this.props.serialNo}, (response) => {
            let finishedLoadingMessage = response.data.length === 0 ? "No results" : response.data.length;
            if (response.success === false) finishedLoadingMessage = response.message;
            // console.log('response')
            // console.log(response)
            if(response.success === true && response.data) {
                // console.log(response.data)
                this.setState({
                    data: response.data,
                    rowCount: response.data.length === 0 ? 4 : response.data.length,
                    noDataText: finishedLoadingMessage,
                    loading: false,
                    finishedLoading: true,
                });
            }
        })
    }
   
    render() {
        const TransactionTable = styled(ReactTable)`
          
        `
        
        return (
            <>
                <OaAlert isPrompt={this.state.isAlertPrompt} callback={this.state.alertCallback} callbackParam={this.state.alertCallbackParam} msg={this.state.alertMsg} togglePrompt={() => this.setState({isAlertPrompt: !this.state.isAlertPrompt})}></OaAlert>
                <TransactionTable
                    defaultFilterMethod={this.filterMethod}
                    data={this.state.data}
                    
                    columns={[
                    {
                            Header: "Reference No",
                            accessor: "referenceno",
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>
                                    {props.value}
                                </div>
                            ),
                            width: 150
                        },     {
                            Header: "Acknowledgement No",
                            accessor: "ackno",
                            width: 180  ,
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    {props.original.txnStatusCode === 5 ? 
                                        <CustomDropdown
                                            buttonText ={props.value}
                                            buttonProps={{
                                                fullWidth: true,
                                                style: { height: 32,padding:4 },
                                                color: "rose"
                                            }}
                                            onClick={(event) => {
                                                this.onSelectMenu(event, props.original);
                                            }}
                                            dropPlacement="top-start"
                                            dropdownList={ this.dropDownMenu }
                                        /> : 
                                        props.value}
                                    
                                </div>
                        )
                        },{
                            Header: "Serial No",
                            accessor: "serialno",
                            width: 220  ,
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>
                                    {props.original.txnStatusCode === 1 ? 
                                        <CustomDropdown
                                            buttonText ={ props.value}
                                            buttonProps={{
                                                fullWidth: true,
                                                style: { height: 32,padding:4 },
                                                color: "rose"
                                            }}
                                            onClick={(event) => {
                                                this.onSelectPrintMenu(event, props.original);
                                            }}
                                            dropPlacement="top-start"
                                            dropdownList={[
                                                "Invoice With Out Operator Charges",
                                                "Invoice With Operator Charges"
                                            ]}
                                        />
                                    :
                                        props.value
                                }
                                </div>
                            ),

                        },{
                            Header: "Amount",
                            accessor: "amount",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                    {currencyFormatter(props.value)}
                                    </div>
                                </div>
                            ),
                            width: 150
                        },{
                            Header: "Commission",
                            accessor: "commission",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                    {currencyFormatter(props.value)}
                                    </div>
                                </div>
                            ),
                            width: 150
                        },{
                            Header: "Status",
                            accessor: "txnStatus",
                            Cell: props => (
                                <>
                                 {(props.original.txnStatusCode !== 1 && props.original.txnStatusCode !== 5)? 
                                    <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                        Amount deducted from wallet. {props.value}. Transaction will be updated soon.
                                    </div> : 
                                    <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                        {props.value}
                                    </div>
                                }
                                </>
                            ),
                            
                        }
                    
                    ]}
                    showPagination={false}
                    minRows={this.state.rowCount}
                    loading= {this.state.loading}
                    pageSize={this.state.rowCount}
                    className="-striped -highlight"
                    getTdProps={this.onRowClick}
                    noDataText={this.state.noDataText}
                />
   
            </>
        )
    }
}
