import React, { useEffect, useState } from "react";
import { COLORS, FONTS } from "assets/css/CssConstants";
import GlassmorphicSearchBox from "components/Search/GlassmorphicSearchBox";
import { Switch, Route } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";
import { makeStyles } from "@material-ui/styles";
import bastarBg from "assets/img/bastar-bg.png";
import { Typography } from "@material-ui/core";
import LpCarousel from "components/Search/LpCarousel";
//import WebApi from "api/ApiConstants";
//import { apiCall } from "oautils/oaDataUtils";

const useStyles = makeStyles(theme => ({

    root: {
        boxSizing: "border-box",

        "& .landingpage-section-container": {
            transition: "background .3s,border .3s,border-radius .3s,box-shadow .3s",
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            position: "relative",
            display: "block",
        },
        "& .m70": {
            marginTop: -70,
        },
        flexCardWrapper: {
            textAlign: "center",
            margin: "0 0 100px 0",
        },
        flexCard: {
            display: "-webkit-box",
            display: "-moz-box",
            display: "-ms-flexbox",
            display: "-webkit-flex",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
            "& .react-photo-gallery--gallery img": {
                width: 200,
            }
        },
        flexCardParallex: {
            display: "-webkit-box",
            display: "-moz-box",
            display: "-ms-flexbox",
            display: "-webkit-flex",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 400,
            backgroundImage: `url(${bastarBg})`,
            backgroundRepeat: "norepeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            position: "relative",
            color: COLORS.DEFAULT_TEXT_LIGHT,
            fontFamily: FONTS.PRIMARY_FONT,
            "& .text-upper-wrapper": {
                zIndex: 1,
            },
            "& .text-upper-wrapper h1": {
                fontSize: 67,
                fontWeight: 500,
                lineHeight: 1,
                "& span": {
                    fontWeight: 100,
                },
                [theme.breakpoints.down(960)]: {
                    fontSize: 36,
                }
            },
            "& .text-lower-wrapper": {
                zIndex: 1,
                "& p": {
                    fontSize: 13,
                    color: COLORS.DEFAULT_TEXT_LIGHT,
                }
            },
            "& .button-lower-wrapper": {
                zIndex: 1,
                marginTop: 35,
                "& a div": {
                    backgroundColor: COLORS.BTN_BG_PRIMARY,
                    fontSize: 20,
                    fontWeight: 400,
                    color: COLORS.DEFAULT_TEXT_LIGHT,
                    padding: "15px 30px",
                    borderRadius: 25,
                    cursor: "pointer",
                    transition: "transform .2s",
                    "&:hover": {
                        transform: "scale(1.2)",
                    },
                }
            },
            "& .overlay": {
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: "rgba(0,0,0,0.6)",
            },
            [theme.breakpoints.down(600)]: {
                height: "auto",
                padding: "10px 15px 35px",
            },
        },
    }
}));

const PostLoginLandingPage = (props) => {
    const classes = useStyles();

    const [userType, setUserType] = useState(null);

    const getValuesFromLocalStorage = () => {
        localforageGetItem("user-details", function (err, value) {
            if (value) {
                // console.log("user type",value.userType);
                setUserType(value.userType);
            }
        });
    };

    useEffect(() => {

        getValuesFromLocalStorage();

    }, []);

    return (
        <div className={classes.rootContainer}>
            <div className="landing-page-container">
                <section className="landing-page-section-container">
                    <LpCarousel />
                </section>

                <section className="landingpage-section-container">
                    <Switch>
                        <Route
                            path="/corporate/landingpage/:priTab/:secTab/:serialNo"
                            exact
                            // component={SearchManager}
                            component={GlassmorphicSearchBox}
                        />
                        <Route
                            path="/corporate/landingpage/:priTab/:secTab"
                            exact
                            // component={SearchManager}
                            component={GlassmorphicSearchBox}
                        />
                        <Route
                            path="/corporate/landingpage/:priTab"
                            exact
                            // component={SearchManager}
                            component={GlassmorphicSearchBox}
                        />
                        <Route
                            path="/corporate/landingpage"

                            // component={SearchManager}
                            component={GlassmorphicSearchBox}
                        />
                    </Switch>
                </section>

                <section className="landingpage-section-container">
                    <div className={classes.flexCardWrapper}>
                        <div className={classes.flexCardParallex}>
                            <div className="text-upper-wrapper">
                                <h1>BASTAR, <span>Chhattisgarh</span></h1>
                            </div>
                            <div className="text-lower-wrapper">
                                <Typography>
                                    Bastar District is located in Chhattishgarh in the central parts of India. Bastar District, before splitting districts, was one of the largest districts in India.
                                </Typography>
                            </div>
                            <div className="button-lower-wrapper">
                                <a href="#" onClick={() => props.history.push({ pathname: "/b2c/stours_section" })}>
                                    <div>More Info..</div>
                                </a>
                            </div>
                            <div className="overlay"></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PostLoginLandingPage;