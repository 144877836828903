import React, {useState, useRef} from 'react'
import {Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OaFormPasswordField from 'oahoc/OaFormPasswordField';

import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaCard from 'oahoc/OaCard';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import OaTabBar from 'oahoc/OaTabBar';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 
import {  Redirect } from "react-router-dom";

const ChangePassword = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');

    let tabs = [
        { name: 'change-password', label: 'Change Password', url: '/admin/user/change-password' },
    ];
    let currentTab = 'change-password';

    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} >
                    <OaCard> 
                        <OaFormAlerts 
                            isSaving={isSaving} 
                            isSuccess={isSuccess} 
                            isError={isError} 
                            infoText={infoText} 
                            setIsError={setIsError} 
                            setIsSuccess={setIsSuccess} 
                        />
                        <Formik 
                            initialValues={{currentPassword:"", newPassword: "", newPasswordConfirmation:""}}
                            validationSchema={Yup.object().shape({
                                currentPassword: Yup.string().required('Current password is required'),
                                newPassword: Yup.string().required('Password is required'),
                                newPasswordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'New Password and Confirm Password do not match')
                            })}
                            
                            onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                                setIsSaving(true);
                                apiCall(WebApi.updateAgencyPassword, searchParams, (response) => {
                                    setIsError(!response.success);
                                    setIsSuccess(response.success);
                                    setSubmitting(false);
                                    setIsSaving(false);
                                    setInfoText(response.message);
                                    if(response.success === true) {
                                        enableReinitialize=true;
                                        resetForm(true);
                                    }
                                });
                                }
                            }
                        >
                            {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                                <Form>
                                    <OaFormPasswordField required={true} label="Current Password" name="currentPassword"/>
                                    <ErrorMessage name="currentPassword" component="div" className="error"/>
                                    <OaFormPasswordField required={true} label="New Password" name="newPassword"/>
                                    <ErrorMessage name="newPassword" component="div" className="error"/>
                                    <OaFormPasswordField required={true} label="Confirm Password" name="newPasswordConfirmation"/>
                                    <ErrorMessage name="newPasswordConfirmation" component="div" className="error"/>
                                    <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}/>
                                </Form>
                            )}
                        </Formik>
                    </OaCard>
               </GridItem> 
            </GridContainer>
            {isSuccess && <Redirect to="/auth/login-page" />}
        </OaCard> 
    )
}

export default ChangePassword;
