import React, { useState, useEffect } from 'react';
import OaCard from 'oahoc/OaCard';
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from 'oautils/oaCommonUtils.js';
import OaTabBar from 'oahoc/OaTabBar';
import AgentDetails from 'pages/agent/AgentDetails';

import MoneyTransferSearchForm from 'pages/report/moneytransfer/MoneyTransferSearchForm';
import MoneyTransferSearchResult from 'pages/report/moneytransfer/MoneyTransferSearchResult';
import dateFnsFormat from 'date-fns/format';

export const MoneyTransfer = (props) => {
  const [agencyId, setAgencyId] = useState(getAgencyId(props));
  const [isLoading, setIsLoading] = useState(true);

  let d = new Date();
  let fromDate = d; //d.setDate(d.getDate() - 7);
  let today = new Date();

  let initialParams = {
    agencyId: agencyId,
    dmtBookingFromDateDisplay: fromDate,
    dmtBookingToDateDisplay: today,
    dmtBookingFromDate: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
    dmtBookingToDate: dateFnsFormat(today, 'dd-MM-yyyy'),
    mobile: '',
  };

  const [searchParams, setSearchParams] = useState(initialParams);
  const [tableKey, setTableKey] = useState(0);

  function dmtReportLoader(searchParams) {
    setSearchParams(searchParams);
    setTableKey(tableKey + 1);
  }

  let tabs = [
    { name: 'dmt', label: 'DMT', url: '/admin/booking-report/moneytransfer' },
  ];

  let currentTab = 'dmt';

  useEffect(() => {
    let aId = getAgencyId(props);
    setAgencyId(aId);
    setSearchParams({ ...searchParams, agencyId: aId });
    setTableKey(tableKey + 1);
    setIsLoading(false);
  }, [props]);

  return (
    <>
      {!isLoading && (
        <OaCard className={'oa-card-primary'}>
          <OaTabBar tabs={tabs} currentTab={currentTab} type='primary' />
          {agencyId && (
            <OaCard>
              <AgentDetails agencyId={agencyId} />
            </OaCard>
          )}
          <OaCard>
            <MoneyTransferSearchForm
              target={dmtReportLoader}
              agencyId={agencyId}
              searchParams={searchParams}
              setIsLoading={setIsLoading}
            />
          </OaCard>

          <OaCard>
            <MoneyTransferSearchResult
              key={tableKey}
              searchParams={searchParams}
              agencyId={agencyId}
            />
          </OaCard>
        </OaCard>
      )}
    </>
  );
};
export default MoneyTransfer;
