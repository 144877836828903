import React, {useState, useEffect} from 'react';

import OaCard from 'oahoc/OaCard';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import TransactionListHeading from './TransactionListHeading';
import TransactionListTable from './TransactionListTable';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { makeStyles } from '@material-ui/core';

const TransactionList = (props) => {
    const [data, setData] = useState({});
    const [mobile, setMobile] = useState('');
    const [isloading, setIsloading] = useState(false);
    const classes = useStyles();

    const tabs = [
        {name: 'transactionList', label: 'Transaction List', url: '/admin/moneytransfer/transaction-list'},
    ];
    let currentTab = getCurrentTab(queryVar, 'transactionList');
    const queryVar = getQueryVar({...props, q: 'priTab'});
   
    useEffect(() =>{
        if(props.location.query.data ){
            setMobile(props.location.query.mobile);
            setData(props.location.query.data);
            setIsloading(true);
        } else {
            apiCall(WebApi.getDMTSender, {mobile: props.location.query.mobile}, (response) => {
                if(response.success === true && response.data){
                    if(response.data.status === true) {
                        setMobile(props.location.query.mobile);
                        setData(response.data.data);
                        setIsloading(true);
                    }
                }
            });
        }
    },[isloading]);

    return (
        < >
            {isloading && 
            <>
                <TransactionListHeading {...props} mobile={mobile} data={data}/>
                <TransactionListTable {...props} mobile={mobile}  data={data}/>
            </>
            }
        </>
    )
}
export default TransactionList

const useStyles = makeStyles(theme => ({
    rootRt: {
        "& .rt-tbody":  {
            height: 129, 
            overflow: 'auto'
        }
    }
}));
