import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.LookMyTicket.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { FooterSection } from "components/LandingPage/LookMyTicket/FooterSection";
import Container from "@material-ui/core/Container";

import HotelSearchResultPage from "pages/module/hotel/HotelSearchResultPage";
import HotelBookingFormPage from "pages/module/hotel/HotelBookingFormPage";
import HotelDetailPage from "pages/module/hotel/HotelDetailPage";
import HotelBookingReview from "pages/module/hotel/HotelBookingReview";
import HotelPaymentProgressPage from "pages/module/hotel/HotelPaymentProgressPage";
import HotelVoucher from "pages/module/hotel/HotelVoucher";
import companylogo from "assets/lookMyTicket/images/logo/logo.png";
import HotelPaymentStatus from "pages/module/hotel/HotelPaymentStatus";
import ExtRedirHotelBookProgress from "pages/module/hotel/ExtRedirHotelBookProgress";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import HotelSearchProcessPage from "pages/module/hotel/HotelSearchProcessPage";
import PricingPolicy from "components/LandingPage/LookMyTicket/siteinfo/PricingPolicy";
import TermsAndConditions from "components/LandingPage/LookMyTicket/siteinfo/TermsAndConditions";

const useStyles = makeStyles(styles);

export default function HotelLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const addClasses = additionalStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const sessionStartTime = useSelector(
    (state) => state.hotel.hotelBookingInitialTime
  );

  useEffect(() => {
    if (sessionStartTime) {
      let now = new Date().getTime();
      let distance = sessionStartTime - now;

      let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((distance % (1000 * 60)) / 1000);
      if (minute <= 0 && second <= 0) {
        history.push("/");
      }
    }
  });

  return (
    <div className={clsx(classes.wrapper, addClasses.rootWrapper)}>
      <PostLoginNavBar
        isTimerShow={true}
        {...rest}
        isSearchPage={true}
        showServices={true}
      />
      <Container fixed className="inner-container">
        <div className={"inner-wrapper"}>
          <Switch>
            <Route
              path="/hotel/search"
              exact
              component={HotelSearchProcessPage}
            />
            <Route
              path="/hotel/searchResult"
              exact
              component={HotelSearchResultPage}
            />
            <Route
              path="/hotel/HotelDetail"
              exact
              component={HotelDetailPage}
            />
            <Route
              path="/hotel/bookingForm"
              exact
            >
              <HotelBookingFormPage tnc={TermsAndConditions} ppc={PricingPolicy} {...props}/>
            </Route>
            <Route
              path="/hotel/reviewbooking"
              exact
              component={HotelBookingReview}
            />
            <Route
              path="/hotel/hotelpaymentprogress/:transactionId"
              component={ExtRedirHotelBookProgress}
            />
            <Route
              exact
              path="/hotel/hotelpaymentprogress"
              component={HotelPaymentProgressPage}
            />
            <Route
              path="/hotel/hotelvoucher"
              render={(prop) => (
                <HotelVoucher companylogo={companylogo} {...props} />
              )}
            />
            <Route
              path="/hotel/payment-status/:tid/:status"
              component={HotelPaymentStatus}
            />
          </Switch>
        </div>
      </Container>
      {/* <PageFooter white {...props} /> */}
      <FooterSection />
    </div>
  );
}

const additionalStyles = makeStyles((theme) => ({
  rootWrapper: {
    // backgroundColor: theme.palette.background.default,
    "& .inner-wrapper": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .inner-container": {
      marginBottom: 20,
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: "1236px",
      width: "100%",
      minHeight: "70vh",
      [theme.breakpoints.down(980)]: {
        padding: "10px 10px 10px 10px",
      },
    },
  },
}));
