import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "180px",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "0.5rem",
    marginBottom: "13px",
  },
  mainBox: {
    // width: "155px",
    // borderRadius: "8px",
    // backgroundColor: "#ffffff66",
    // marginTop: 3,
    borderRadius: "4px",
    border: "1px solid #dcdcdc",
    display: "flex",
    [theme.breakpoints.down(840)]: {
      width: 100,
    },
    [theme.breakpoints.down(400)]: {
      width: "50vw",
    },

    "& .MuiIconButton-root": {
      width: "26px",
      height: "31px",
      border: 0,
      background: "none",
      fontSize: "12px",
      cursor: "pointer",
      color: "#000",
      fontFamily: theme.palette.font.primary,
    },
  },

  incrButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  countVal: {
    width: "40px",
    borderLeft: "1px solid #dcdcdc",
    fontFamily: theme.palette.font.primary,
    borderRight: "1px solid #dcdcdc",
    backgroundColor: "#f6f9ff",
    textAlign: "center",
    "& .MuiTypography-h6": {
      fontSize: "13px",
      fontFamily: theme.palette.font.primary,
      paddingTop: "8px",
      // fontSize: "1em",
    },
  },
  decrButton: {
    "& .MuiSvgIcon-root": {
      // height: 12,
      fontSize: "12px",
    },
  },
  label: {
    fontFamily: theme.palette.font.primary,
    display: "block",
    "& p": {
      fontSize: "14px",
      fontWeight: "600",
      margin: 0,
      lineHeight: "1.2",
      textAlign: "center",
      "& span": {
        display: "block",
        fontSize: "11px",
      },
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
      width: "40vw",
    },
  },
}));

function PassengerCount({ title, type, val, setVal, totalPax }) {
  const classes = useStyles();

  const incrCount = () => setVal(type, 1);
  const decrCount = () => setVal(type, -1);

  return (
    <div className={classes.root}>
      <span className={classes.label}>
        {title === "Adults" ? (
          <p>
            Adult<span>(18+ years)</span>
          </p>
        ) : title === "Children" ? (
          <p>
            Children<span>(2-12 years)</span>
          </p>
        ) : (
          <p>
            Infants<span>(0-2 years)</span>
          </p>
        )}
      </span>
      <Box className={classes.mainBox}>
        {/* <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          
        </Grid> */}

        <Box className={classes.incrButton}>
          <IconButton
            aria-label="decrement"
            onClick={decrCount}
            disabled={val <= (type === "adult" ? 1 : 0)}
          >
            <RemoveIcon className="icon" fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.countVal}>
          <Typography variant="h6">{val}</Typography>
        </Box>
        <Box className={classes.decrButton}>
          <IconButton
            aria-label="increament"
            onClick={incrCount}
            disabled={totalPax >= 9}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
}

export default PassengerCount;
