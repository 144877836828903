import React from 'react'
import GridItem from "components/Grid/GridItem.js";
import { Checkbox, FormControl } from "@material-ui/core";
import { Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const GreenCheckbox = withStyles({
    root: {
      color: "#ED4722",
      '&$checked': {
        color: "#ED4722",
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);


const OaFormRadio = (props) => {
    let useGrid = props.md !== undefined;

    function field(){
        return(
           <FormControl fullWidth={true}>
            <FormControlLabel  control={
                <Field {...props}  
                    type="checkbox"
                    as={GreenCheckbox} 
                    name={props.name}
                    checked={props.checked} onChange={()=>{props.onChange()}}
                />}
                label={props.label}
                />
            </FormControl> 
        )
    }
    return (
        <>
            {!useGrid && field()}
            {useGrid &&         
                (
                    <GridItem xs={12} sm={12} md={props.md}>
                        {field()}
                    </GridItem>
                )
            }
        </>
    )
}

export default OaFormRadio;