import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button"
import { Typography,useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Grid: {
    padding: 20,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    paddingBottom: 20,
  },
  input: {
    fontSize: "14px",
    lineHeight: "1.42857",

    backgroundColor: "white",
    border: `1px solid ${theme.palette.secondary.chineseGray}`,
    color: "#999 !important",
    fontSize: "15px !important",
    width: "100%",
    borderColor: theme.palette.secondary. periglacialBlue,
    boxShadow: "none",
    borderRadius: "4px",
    height: "44px",
    padding: "10px",
  },

  button: {
    background: theme.palette.background.navyBlue,
   
    border: `1px solid ${theme.palette.background.navyBlue}`,
    padding: "7px 17px",
    marginRight: "10px",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 300,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background:  theme.palette.background.navyBlue,
    },
  },
  select: {
    fontSize: "14px",
    lineHeight: "1.42857",
    color: "#999 !important",
    backgroundColor: "white",
    border: `1px solid ${theme.palette.secondary.chineseGray}`,
    display: "block",
    width: "100%",
    borderColor: theme.palette.secondary. periglacialBlue,
    boxShadow: "none",
    borderRadius: "4px",
    height: "44px",
    padding: "10px",
  },
  heading: {
    fontWeight: "500 !important",
    lineHeight: "1.1 !important",
    color: theme.palette.secondary.darkGrayishBlue,
  },
  typo: {
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "1.42857",
    color: theme.palette.primary.sub,
    marginBottom: "5px",
    fontWeight:500
  },
  typo3:{
    fontSize: '1rem', fontWeight: 700, lineHeight: 1, color: 'gray', textShadow:`0 1px 0 ${theme.palette.priamry.contrastText}`,cursor:"pointer"
  }
}));

const AddTraveler = (props) => {
  const classes = useStyles();
  // console.log(props)
  const theme=useTheme();
  const initialValues = {
    title: "",
    firstname: "",
    middlename: "",
    lastname: "",
    dob: "",
   
    
  };
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("required"),
    // surname: Yup.string().required("required"),
    // email: Yup.string().required("required"),
  });

  const onSubmit = (values) => {
    // console.log(values);
  };

  return (
    <div>
      <GridContainer justifyContent="center" alignItems="center">
        <Grid item md={5} className={classes.paper}>
          <GridContainer
            justifyContent="space-between"
            style={{ padding: 20, borderBottom: `1px solid ${theme.palette.secondary. pattensBlue}` }}
          >
            <Grid item>
                <Typography className={classes.heading}>ADD TRAVELLER</Typography>
             </Grid>
            <Grid item>
            <Typography className={classes.typo3} onClick={()=>props.setOpenTraveller(false)} >X</Typography>
            </Grid>
          </GridContainer>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, status, touched }) => (
              <Form>
                <GridContainer  className={classes.Grid}>
                  <Grid item md={12}>
                      <GridContainer justifyContent="space-evenly" alignItems="center" >
                          <Grid item>
                          <Field type="radio" name="picked" value="Two" />
                              <span>ADULT</span>
                          </Grid>
                          <Grid item>
                          <Field type="radio" name="picked" value="Two" />
                              <span>CHILD</span>
                          </Grid>
                          <Grid item>
                          <Field type="radio" name="picked" value="Two" />
                              <span>INFANT</span>
                          </Grid>
                     <Grid item md={12} style={{paddingTop:15}}>
                              <Typography className={classes.typo}>TITLE*</Typography>
                              <Field as="select" name="title" className={classes.select}>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="else">else</option>
                                </Field>
                              <ErrorMessage name="title" />
                          </Grid>

                          <Grid item md={12} style={{paddingTop:15}}>
                              <Typography className={classes.typo}>FIRST NAME</Typography>
                              <Field type="text" name="firstname" className={classes.input} />
                              <ErrorMessage name="firstname" />
                          </Grid>

                          <Grid item md={12} style={{paddingTop:15}}>
                              <Typography className={classes.typo}> MIDDLE NAME</Typography>
                              <Field type="text" name="middlename" className={classes.input} />
                              <ErrorMessage name="middlename" />
                          </Grid>
                          <Grid item md={12} style={{paddingTop:15}}>
                              <Typography className={classes.typo}>LAST NAME</Typography>
                              <Field type="text" name="lastname" className={classes.input} />
                              <ErrorMessage name="lastname" />
                          </Grid>
                          <Grid item md={12} style={{paddingTop:15}}>
                              <Typography className={classes.typo}>DATE OF BIRTH*</Typography>
                              <Field type="date" name="dob" className={classes.input} />
                              <ErrorMessage name="dob" />
                          </Grid>
                          <Grid item container justifyContent="flex-start" style={{paddingTop:15}}>
                          <Button className={classes.button} type="submit">submit</Button>
                          </Grid>
                          
                      </GridContainer>
                  </Grid>

                  </GridContainer>
                
              </Form>
            )}
          </Formik>
        </Grid>
      </GridContainer>
    </div>
  );
};
export default AddTraveler;
