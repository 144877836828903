import React from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";

import Amadeus_IMG from "../../../assets/lookMyTicket/images/home/Amdeus.jpg";
import Galileo_IMG from "../../../assets/lookMyTicket/images/home/galileo.jpg";
import IATA_IMG from "../../../assets/lookMyTicket/images/home/iata.jpg";
import TAAFI_IMG from "../../../assets/lookMyTicket/images/home/taafilogo.jpg";
import TAAI_IMG from "../../../assets/lookMyTicket/images/home/taai.jpg";


//import Carousel from "react-elastic-carousel";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "3rem 0rem",
    marginTop: "20px",
    position: "relative",
    backgroundColor: theme.palette.background.whiteSmoke,
    //border: "1px solid",
    // borderRadius: "10px",
    "& .heading-styles": {
      textAlign: "center",
      fontSize: "2rem",
      fontWeight: "600",
      padding: "10px",
      marginTop: "10px",
      [theme.breakpoints.down(980)]: {
        fontSize: "1.5rem",
        marginTop: "20px",
      },
    },
    "& .imageGrid": {
      marginLeft: 10,
      marginRight: 10,
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      [theme.breakpoints.down(980)]: {
        // flexDirection: "column",
        marginBottom: 10,
        marginLeft: 15,
        marginRight: 15,
      },
    },
    // padding: "10px",
  },
  carouselItem: {
    // width: 200,
    // height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    "& img": {
      // height: 80,
      width: "200px",
      border: "4px solid #c2d87e",
      borderRadius: "50%",
      padding: "4px",
      [theme.breakpoints.down(980)]: {
        marginTop: "10px",
        marginBottom: "20px",
        padding: "4px",
        width: "90%",
      }
    },
  },
}));

const CAROUSEL_ITEMS = [
  {
    id: "01",
    image: Amadeus_IMG,
  },
  {
    id: "02",
    image: Galileo_IMG,
  },
  {
    id: "03",
    image: IATA_IMG,
  },
  {
    id: "04",
    image: TAAFI_IMG,
  },
  {
    id: "05",
    image: TAAI_IMG,
  },
];

const Partners = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>

        <div className="heading-styles" >Our Technology Partners</div>

        <div className="imageGrid">
          {CAROUSEL_ITEMS.map((item, idx) => (
            <div className={classes.carouselItem} key={idx}>
              <img src={item.image} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Partners;
