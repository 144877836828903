import { SET_B2C_FLIGHT_LIST } from "redux/action";

export const flightList = (state = {}, action) => {
  switch (action.type) {
    case SET_B2C_FLIGHT_LIST:
      state = Object.assign({}, state);
      state.flightList = action.payload;
      return state;
    default:
      return state;
  }
};
