import React, { useState, useEffect } from "react";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OfferCard from "./OfferCard";
import PropTypes from "prop-types";
import {
  AppBar,
  makeStyles,
  Tab,
  Tabs,
  Box,
  Typography,
} from "@material-ui/core";
import { Link ,useHistory } from "react-router-dom";

// for mui tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} style={{ width: "100%", height: "100%",}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// for mui tab /

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    "& .offer-cards": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexWrap:'wrap',
      gap: "16px",
      padding: "30px 0",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        // flexWrap:'wrap'
        //  flexDirection: "column",
      },
    },

    "& .tabPanel-sec":{
      maxWidth:'100%'
    },

    "& .o-details-link":{
      color:'inherit',
      cursor:'pointer',
    },

    "& .tab-panel": {
      maxWidth: "100%",
      // height: "100%",
    },
    "& .PrivateTabIndicator": {
      color: theme.palette.secondary.main,
      // backgroundColor:theme.palette..
    },

    "& .MuiTab-root": {
      [theme.breakpoints.up("sm")]: {
        minWidth: "0px",
      },
    },

    "& .MuiTab-textColorInherit.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}));

const AllOffers = () => {
  const classes = useStyles();
  const [offers, setOffers] = useState([]);
  const [Tabvalue, setTabValue] = useState(0);
  const history = useHistory();


  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      apiCall(
        WebApi.getExclusiveOffer,
        {
          callFrom: "web",
          offerCategory: "",
        },
        (resp) => {
          if (resp != null && resp.success) {
            // console.log(resp.data, "get pop");
            setOffers(resp.data);
            // setDataIs(true);
          } else {
            console.log("error resp null", resp);
          }
        }
      );
    }
    return () => (mounted = false);
  }, []);

  return (
    <div className="container">
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "#fff",
            margin: "1rem 0",
            boxShadow: "none",
          }}
        >
          <Tabs
            value={Tabvalue}
            onChange={handleChangeTab}
            aria-label="simple tabs example"
            // TabIndicatorProps={{style: {height:'6px',marginTop:'10px'}}}
          >
            {offers &&
              offers.map((elm, idx) => (
                <Tab label={elm.categoryName} {...a11yProps(idx)} />
              ))}
          </Tabs>
        </AppBar>

        <div className="tabPanel-sec">
        {offers &&
          offers.map((cat, idx) => {
            return (
              <TabPanel
                value={Tabvalue}
                index={idx || 0}
                key={idx}
                className="tab-panel"
              >
                <div className="offer-cards">
                  {cat?.exclusiveOffers?.map((val, index) => (
                    //  <Link  key={index} to="/exclusive-offers/offer-details" className="o-details-link">
                    //  </Link>
                    <div key={index} onClick={()=>history.push("/exclusive-offers/offer-details", {
                      val,
                    })} className="o-details-link">
                      <OfferCard offer={val} />
                    </div>
                  ))}
                </div>
              </TabPanel>
            );
          })}
        </div>

      </div>
    </div>
  );
};

export default AllOffers;
