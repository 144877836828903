/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container";
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import cardimage1 from "assets/img/cardimage1.png"
import cardimage2 from "assets/img/cardimage2.png"
import cardimage3 from "assets/img/cardimage3.png";
import imagediv from "assets/img/Rectangle 15.png";
import imagediv1 from "assets/img/Rectangle 14.png"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root1: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  typo: {
    color: theme.palette.secondary.neon,
    fontSize: "19px",
    marginTop: "20%",
    fontWeight: "bold",
  },
  typo1: {
    fontSize: "12px",
    lineHeight: "25px",

    color: theme.palette.secondary.steel,
  },
  typo2: {
    fontSize: "12px",
    lineHeight: "25px",

    color: theme.palette.secondary.crimson,
    marginTop: "30px",
  },
  typo3: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 15,
  },
  grid: {
    marginTop: 10,
    color: theme.palette.secondary.crimson,
  },
  typo4: {
    color: theme.palette.secondary.steel,
    fontSize: 14,
    marginTop: 7,
  },
  heading: {
    color: theme.palette.primary.shadow,
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 140,
    },
  },
  couponText: {
    fontWeight: "bold",
    color: theme.palette.secondary.darkBlack,
    fontSize: 17,
  },
  offerText: {
    fontSize: "19px",
    lineHeight: "1.375em",
    color: theme.palette.secondary.koalaGray,
    fontWeight: 600,
  },
}));

export default function BannerArea(props) {
  const classes = useStyles();
  const classes1=useStyles1();
  const { fluid, white, rtlActive } = props;
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true
  // };

  

  return (
    <div>
      <Container style={{ marginBottom: "60px" }} maxWidth={false}>
        <GridContainer justifyContent="center">
          <GridItem>
            <img src={require("assets/img/logo 2.png")} width="220px" />
            <Typography align="center" className={classes1.offerText}>
              Offers
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {[...Array(3)].map((e, i) => (
            <GridItem md={4}>
              <Card>
                <GridContainer>
                  <Grid item md={5} xs={12}>
                    <img
                      src={require("assets/img/image1.png")}
                      width="103px"
                      className={classes1.img}
                    />
                  </Grid>
                  <GridItem md={7}>
                    <Typography className={classes1.couponText}>
                      Coupon Code:23CSD
                    </Typography>
                    <Typography className={classes1.typo1}>
                      use this code & get 25% off
                    </Typography>
                    <Typography className={classes1.typo2}>
                      valid till 12 March 2021
                    </Typography>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          ))}
        </GridContainer>

        <GridContainer>
          <Card style={{ marginLeft: 15, marginRight: 15 }}>
            <GridContainer>
              <Grid md={8}>
                <img src={require("assets/img/image3.png")} width="100%" />
              </Grid>
              <GridItem md={4}>
                <Typography className={classes1.typo}>
                  Plan your Family trip this weekend and get luxury benifit
                </Typography>
                <Typography className={classes1.typo3}>
                  10% off during DMSM credit card
                </Typography>
              </GridItem>
            </GridContainer>
          </Card>

          <GridContainer style={{ paddingTop: 60 }}>
            <GridContainer>
              <GridItem md={12}>
                <Typography variant="h4" className={classes1.heading}>
                  Blogs
                </Typography>
              </GridItem>
            </GridContainer>
            <GridItem md={4}>
              <Card>
                <GridContainer>
                  <Grid item md={6}>
                    <img src={cardimage1} width="100%" />
                  </Grid>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <Typography className={classes1.typo4}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum finibus laoreet lacinia. Duis vitae orci
                      aliquam, faucibus enim et, luctus nisl.
                    </Typography>
                  </GridItem>
                </GridContainer>
                <GridItem className={classes1.grid}>12-March-2020</GridItem>
              </Card>
            </GridItem>
            <GridItem md={4}>
              <Card>
                <GridContainer>
                  <Grid item md={6}>
                    <img src={cardimage2} width="100%" />
                  </Grid>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <Typography className={classes1.typo4}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum finibus laoreet lacinia. Duis vitae orci
                      aliquam, faucibus enim et, luctus nisl.
                    </Typography>
                  </GridItem>
                </GridContainer>
                <GridItem className={classes1.grid}>12-March-2020</GridItem>
              </Card>
            </GridItem>
            <GridItem md={4}>
              <Card>
                <GridContainer>
                  <Grid item md={6}>
                    <img src={cardimage3} width="100%" />
                  </Grid>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <Typography className={classes1.typo4}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum finibus laoreet lacinia. Duis vitae orci
                      aliquam, faucibus enim et, luctus nisl.
                    </Typography>
                  </GridItem>
                </GridContainer>
                <GridItem className={classes1.grid}>12-March-2020</GridItem>
              </Card>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </Container>
    </div>
  );
}
