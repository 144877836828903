import {
  SET_HOTEL_COUNTRY,
  SET_HOTEL_CITY,
  SET_HOTEL_SEARCH,
  SET_HOTEL_SEARCH_RESULT,
  SET_HOTEL_INFO,
  SET_HOTEL_DETAILS,
  SET_HOTEL_ROOM_PARAM,
  SET_TIMER_INITIAL_MINUTE,
  SET_TIMER_INITIAL_SECOND,
  SET_BUS_BOOKING_INITIAL_TIME,
  SET_FLIGHT_BOOKING_INITIAL_TIME,
  SET_HOTEL_BOOKING_INITIAL_TIME,
  SET_INSURANCE_BOOKING_INITIAL_TIME,
  SET_HOTEL_FILTERS,
} from "redux/action";

export const hotel = (state = {}, action) => {
  switch (action.type) {
    case SET_HOTEL_COUNTRY:
      state = Object.assign({}, state);
      state.hotelCountry = action.payload;
      return state;

    case SET_HOTEL_CITY:
      state = Object.assign({}, state);
      state.hotelCity = action.payload;
      return state;

    case SET_HOTEL_SEARCH:
      state = Object.assign({}, state);
      state.hotelSearch = action.payload;
      return state;

    case SET_HOTEL_SEARCH_RESULT:
      state = Object.assign({}, state);
      state.hotelSearchResult = action.payload;
      return state;

    case SET_HOTEL_FILTERS:
      state = Object.assign({}, state);
      state.hotelFilters = action.payload;
      return state;

    case SET_HOTEL_INFO:
      state = Object.assign({}, state);
      state.hotelInfo = action.payload;
      return state;

    case SET_HOTEL_DETAILS:
      state = Object.assign({}, state);
      state.hotelDetails = action.payload;
      return state;

    case SET_HOTEL_ROOM_PARAM:
      state = Object.assign({}, state);
      state.hotelRoomParam = action.payload;
      return state;

    case SET_TIMER_INITIAL_MINUTE:
      state = Object.assign({}, state);
      state.timerInitialMinute = action.payload;
      return state;

    case SET_TIMER_INITIAL_SECOND:
      state = Object.assign({}, state);
      state.timerInitialSecond = action.payload;
      return state;

    case SET_BUS_BOOKING_INITIAL_TIME:
      state = Object.assign({}, state);
      state.busBookingInitialTime = action.payload;
      return state;

    case SET_FLIGHT_BOOKING_INITIAL_TIME:
      state = Object.assign({}, state);
      state.flightBookingInitialTime = action.payload;
      return state;

    case SET_HOTEL_BOOKING_INITIAL_TIME:
      state = Object.assign({}, state);
      state.hotelBookingInitialTime = action.payload;
      return state;

    case SET_INSURANCE_BOOKING_INITIAL_TIME:
      state = Object.assign({}, state);
      state.insuranceBookingInitialTime = action.payload;
      return state;

    default:
      return state;
  }
};
