import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import {
    localforageSetItem,
    localforageClear,
    localforageGetItemAsync,
    localforageGetItem,
} from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import { setUserType } from "redux/action";
import { AppStateContext } from "layouts/AppStateProvider";
import WebApi from "api/ApiConstants";
import LoginSection from "../LoginSection";
import HomePageNavbar from "../HomePageNavbar";
import OffersSection from "../OffersSection";
import TopFlightRoutes from "../TopFlightRoutes";
import { FooterSection } from "../FooterSection";
import SearchEngineBg from "assets/lookMyTicket/images/home/search-background-image.png";

const B2bLoginPage = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [usertype, setUsertype] = useState(null);
    const { setUserTypeb2c } = React.useContext(AppStateContext);
    const [corporateType, setCorporateType] = useState(null);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [showServices, setShowServices] = useState(
        props.hideServices ? props.hideServices : props.showServices
    );

    useEffect(() => {
        (async () => {
            const userDetails = await localforageGetItemAsync("user-details");
            if (!userDetails) {
                setIsLoading(true);
                // console.log("##### user-details not found ##");
                WebApi.getAgentInfo({ userType: "G" }, (response) => {
                    if (response != null && response.success === true) {
                        localforageClear(() => {
                            localforageSetItem("user-id", 0);

                            localforageSetItem("user-details", response.data, () => {
                                dispatch(setUserType(response.data));
                                localforageSetItem("access-key", response.accesskey, () => {
                                    setIsLoading(false);
                                });
                            });
                        });
                    }
                });
            } else {
                dispatch(setUserType(userDetails));
            }
        })();

    }, [isLoading]);

    const getValuesFromLocalStorage = () => {
        localforageGetItem("user-details", function (err, value) {
            // console.log("typ check",value)
            if (value) {

                if (value.userTypeAbv == "S") {
                    setIsLoggedIn(true);
                }

                setShowServices(
                    value.userType == "distributor"
                        ? false
                        : showServices != null
                            ? showServices
                            : true
                );

                setServiceOptions(value.serviceOption);

                setUsertype(value.userType);
                setUserTypeb2c(value.userType)
                setCorporateType(value.corporateType);
            }
        });
    };

    useEffect(() => {
        getValuesFromLocalStorage();
    }, []);

    const width = window.innerWidth;
    const breakpoint = 980;

    return (
        <div className={classes.root}>
            <HomePageNavbar />
            <div className="search__section">
                <div className="login__Div">
                    <LoginSection />
                </div>
            </div>
            {/* <OffersSection />
            <TopFlightRoutes /> */}
            <FooterSection />
        </div>
    );
};

export default B2bLoginPage;

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        margin: 0,
        "& .temp-container": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& img": {
                width: "100%",
                maxWidth: 1280,
            },
        },

        "& .search__section": {
            width: "100%",
            minHeight: "230px",
            position: "relative",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.lightYellowGreen,
            paddingTop: 30,
            backgroundImage: `url(${SearchEngineBg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            minHeight: 359,
            [theme.breakpoints.down(1450)]: {
                minHeight: 371,
                backgroundSize: 'cover'
            },
            "& h3": {
                textAlign: "center",
                color: "#fff",
                fontWeight: 400,
                marginTop: '2.3rem',
                marginBottom: 0,
                fontSize: '2rem',
                // color: ColorPaleete,
                color: theme.palette.primary.darkText,


            },
        },
        "& .search__box": {
            width: '1200px',
            minHeight: '130px',
            padding: '10px 0',
            [theme.breakpoints.down(980)]: {
                width: '100%',
            },
            [theme.breakpoints.down(1026)]: {
                width: '100%',
            },
        },

        "& .login__Div": {
            float: 'right',
            position: 'relative',
        }
    },
}));
