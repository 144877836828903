import React, { Component } from 'react'
import ReactTable from "react-table";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import OaAlert from 'oahoc/OaAlert';
import {currencyFormatter} from 'oautils/oaCommonUtils';

export default class TransactionListTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            dialogIsOpen: false,
            data: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " ",
            isAlertPrompt: false,
            alertCallback: '',
            alertCallbackParam: {},
            alertMsg:''
        }
    } 

    componentDidMount() {  
        this.tableLoader();
    }

    tableLoader() {
        // console.log(this.props.mobile)
        apiCall(WebApi.dmtTransactList, {mobile:this.props.mobile}, (response) => {
            let finishedLoadingMessage = response.data.length === 0 ? "No results" : response.data.length;
            if (response.success === false) finishedLoadingMessage = response.message;

            if(response.data.status && response.data.data) {
                // console.log(response.data.data)
                this.setState({
                    data: response.data.data,
                    rowCount: response.data.data.length === 0 ? 4 : response.data.data.length,
                    noDataText: finishedLoadingMessage,
                    loading: false,
                    finishedLoading: true,
                });
            }
        })
    }


    onSelectPrintMenu(event, data){
        switch(event){
            case "Invoice With Out Operator Charges":
                this.props.history.push('/admin/voucher/dmt-without-charges/' + data.serialno);
            break;
            case "Invoice With Operator Charges":
                this.props.history.push('/admin/voucher/dmt-with-charges/' + data.serialno);
            break;
        }
    }


     onSelectMenu(event, dataParam){
        switch(event) {
            case "Current Status":
                this.props.history.push('/moneytransfer/refund/', {mobile: this.props.mobile, data:dataParam });
                break;
            case "Refund Transaction":
                this.props.history.push('/moneytransfer/refund/', {mobile: this.props.mobile, data:dataParam });
                break;
        }
    }

    deleteBeneficiary(bene_id) {
        this.setState({isAlertPrompt: true});
        this.setState({alertCallback: this.deleteBeneficiaryHelper});
        this.setState({alertCallbackParam: {bene_id:bene_id, mobile: this.props.mobile}});
        this.setState({alertMsg: 'Are you sure, you want to delete this beneficiary?'});
    }

    deleteBeneficiaryHelper=(buttonText, alertCallbackParam) =>{
    //    console.log(alertCallbackParam);
    //    console.log(buttonText);
        if(buttonText == 'YES') {
            apiCall(WebApi.deleteDMTBeneficiary, {beneid:alertCallbackParam.bene_id, mobile: alertCallbackParam.mobile}, (response) => {
                if(response.success === false && response.data){
                    if(response.data.status === true) {
                        this.tableLoader();

                        this.setState({isAlertPrompt: true});
                        this.setState({alertCallback: null});
                        this.setState({alertMsg: response.data.message});
                    } else {
                        this.setState({isAlertPrompt: true});
                        this.setState({alertCallback: null});
                        this.setState({alertMsg: response.data.message});
                    }
                } else {
                    alert(response.message);
                    
                }
            });
        } else if(buttonText == 'NO') {
        }
    }

     dropDownMenu = [
        "Current Status", "Refund Transaction"
    ];

    dropDownMenu1 = [
        "Current Status"
    ];
   
    render() {
        const TransactionTable = styled(ReactTable)`
            margin-top: 50px;
        `
        
        return (
            <>
                <OaAlert isPrompt={this.state.isAlertPrompt} callback={this.state.alertCallback} callbackParam={this.state.alertCallbackParam} msg={this.state.alertMsg} togglePrompt={() => this.setState({isAlertPrompt: !this.state.isAlertPrompt})}></OaAlert>
                <TransactionTable
                    defaultFilterMethod={this.filterMethod}
                    data={this.state.data}
                    filterable={true}
                    columns={[
                    {
                            Header: "Reference No",
                            accessor: "referenceno",
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>
                                    {props.value}
                                </div>
                            ),
                            width: 150
                        },     {
                            Header: "Acknowledgement No",
                            accessor: "ackno",
                           
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <CustomDropdown
                                        buttonText ={ props.value}
                                        buttonProps={{
                                            fullWidth: true,
                                            style: { height: 32,padding:4 },
                                            color: "rose"
                                        }}
                                        onClick={(event) => {
                                            this.onSelectMenu(event, props.original);
                                        }}
                                        dropPlacement="top-start"
                                        dropdownList={props.original.txnStatusCode === 5 ? this.dropDownMenu : this.dropDownMenu1}
                                    />
                                </div>
                        )
                        },{
                            Header: "Serial No",
                            accessor: "serialno",
                            width: 250  ,
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>

                                    {props.original.txnStatusCode === 1 ? 
                                        <CustomDropdown
                                            buttonText ={ props.value}
                                            buttonProps={{
                                                fullWidth: true,
                                                style: { height: 32,padding:4 },
                                                color: "rose"
                                            }}
                                            onClick={(event) => {
                                                this.onSelectPrintMenu(event, props.original);
                                            }}
                                            dropPlacement="top-start"
                                            dropdownList={[
                                                "Invoice With Out Operator Charges",
                                                "Invoice With Operator Charges"
                                            ]}
                                        />
                                    :
                                        props.value
                                }
                                    
                                </div>
                            ),

                        },{
                            Header: "Amount",
                            accessor: "amount",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                    {currencyFormatter(props.value)}
                                    </div>
                                </div>
                            ),
                            width: 150
                        },{
                            Header: "Commission",
                            accessor: "commission",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                    {currencyFormatter(props.value)}
                                    </div>
                                </div>
                            ),
                            width: 150
                        },{
                            Header: "Status",
                            accessor: "txnStatus",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    {props.value}
                                </div>
                            ),
                            width: 150
                        }
                    
                    ]}
                    showPagination={false}
                    minRows={this.state.rowCount}
                    loading= {this.state.loading}
                    pageSize={this.state.rowCount}
                    className="-striped -highlight"
                    getTdProps={this.onRowClick}
                    noDataText={this.state.noDataText}
                />
                <style>{`
                .ReactTable {
                    margin-top: 10px;
                }
                .rt-tbody {
                    height: 160px;
                    overflow: auto;
                }
                `}</style>
            </>
        )
    }
}
