import React, { useState } from "react";
import OaLoading from "pages/components/OaLoading";
import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  ErrorOutlineRounded,
  CheckCircle,
  List,
  ListAltOutlined,
} from "@material-ui/icons";
import WebApi from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import HotelItinerary from "./hotelinfo/HotelItinary";

const HotelPaymentStatus = (props) => {
  console.log(props);

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [hotelData, setHotelData] = React.useState(null);
  const [refId, setRefId] = React.useState(0);
  const [trnCreationError, setTrnCreationError] = React.useState(false);

  const goToVoucherPrint = () => {
    // return props.history.push("/print/hotelticket/" + props.match.params.tid);
    props.history.push("/hotel/hotelvoucher", {
      bookingDetails: props?.history?.location?.state?.bookingDetails,
      hotelSearch: props?.history?.location?.state?.hotelSearch,
      values: props?.history?.location?.state?.values,
    });
  };

  React.useEffect(() => {
    setIsLoading(true);
    setRefId(props.match.params.tid);
    console.log("props ", props)
    setRefId(props.match.params.tid);
    if (props?.match?.params?.status.toLowerCase() === "success") {
      // if (props?.match?.params?.status.slice(0, 7) == "success") {
      setIsLoading(false);
      setBookingStatus(true);
      setPaymentStatus("Success");
    } else {
      //  props?.match?.params?.tid == 0  && 
      setTrnCreationError(true);
      setIsLoading(false);
      setBookingStatus(false);
      setPaymentStatus("Failed");
    }
  }, [props.match.params.tid, props.match.params.status]);

  React.useEffect(() => {
    if (props?.match?.params?.status === "success") {
      setIsLoading(true);

      WebApi.getHotelBookDetails(
        { tid: props.match.params.tid },
        (response) => {
          // console.log("response", response);
          if (response.success) {
            setRefId(response.data.getBookingDetailResult.bookingRefNo);
            setHotelData(response.data);
            setIsLoading(false);
            setBookingStatus(true);
            // console.log(response.data);
          } else {
            setIsLoading(false);
            setBookingStatus(false);
          }
        },
        (error) => {
          console.log("error", error);
          setIsLoading(false);
          setBookingStatus(false);
        }
      );
    }
    else {
      setIsLoading(false);
      setBookingStatus(false);
    }
  }, []);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        <OaLoading loadertext="please wait ..." />
      </div>
    );
  };

  return (
    <div className={classes.root}>{console.log(isLoading, bookingStatus)}
      {!isLoading && bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <CheckCircle />
            <Typography>
              Reference id : <b>{refId}</b>
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>

            <Typography>
              Booking Successful. Please click the button below to go to voucher
              details.
            </Typography>
          </div>

          <div className="action-group">
            <Button onClick={goToVoucherPrint}>
              <ListAltOutlined /> Voucher
            </Button>
          </div>
          <HotelItinerary hotelData={hotelData.getBookingDetailResult} />
        </div>
      )}

      {!isLoading && !bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <ErrorOutlineRounded style={{ color: "orange" }} />
            <Typography>
              Booking Failed. Please check after some time
              {!trnCreationError &&
                ` or contact administrator with reference id (${refId})`}
              .
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="section-container">
          {" "}
          <LoaderView />
        </div>
      )}
    </div>
  );
};

export default HotelPaymentStatus;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: 10,
      },
    },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: "60vh"
    },
    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      // margin: "50px 0",
      "& .MuiSvgIcon-root": {
        fontSize: "8vw",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 10,
      },
    },
    "& .action-group": {
      marginTop: 10,
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));
