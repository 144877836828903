import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';
import {Formik, Form } from 'formik';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateField from 'oahoc/OaFormDateField';

const RechargeReportSearchForm = (props) => {
    const [isLoading] = useState(false);
    const [] = useState(true);
    const [agencyId, setAgencyId] = useState(props.agencyId);

    useEffect(() => {
        if(isLoading ){
         
        }
    }, [isLoading]);

    useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId])

    return(        
    <>
        {isLoading && <OaLoading />}
        {!isLoading && 
        <>
        <h4 className={"oa-form-header"}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}} >Search Recharge Report</h4>
        <Formik     
            initialValues={{
                rechargeBookingFromDateDisplay: props.searchParams.rechargeBookingFromDateDisplay,
                rechargeBookingToDateDisplay: props.searchParams.rechargeBookingToDateDisplay,
                rechargeBookingFromDate: props.searchParams.rechargeBookingFromDate,
                rechargeBookingToDate: props.searchParams.rechargeBookingToDate,
                agencyId: props.searchParams.agencyId
            }}
            onSubmit= {(searchParams, {}) => {
                props.setIsLoading(true);
                searchParams.rechargeBookingFromDate = dateFnsFormat(searchParams.rechargeBookingFromDateDisplay, 'dd-MM-yyyy');
                searchParams.rechargeBookingToDate = dateFnsFormat(searchParams.rechargeBookingToDateDisplay, 'dd-MM-yyyy');
                props.target(searchParams);
                props.setIsLoading(false);
            }}>
            {({ values, setFieldValue }) => (
                <Form>
                    <GridContainer>
                        <OaFormTextField name="rechargeApplicationReference" label="Application Reference" md={4}/>
                        <OaFormTextField name="accountNo" label="Mobile/Account Number" md={4}/>


                        <OaFormDateField 
                            value={values.rechargeBookingFromDateDisplay}
                            selected={values.rechargeBookingFromDateDisplay}
                            onChange={date => setFieldValue('rechargeBookingFromDateDisplay', date)}
                            label="Recharge From Date"
                            name="rechargeBookingFromDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />


                        <OaFormDateField 
                            value={values.rechargeBookingToDateDisplay}
                            selected={values.rechargeBookingToDateDisplay}
                            onChange={date => setFieldValue('rechargeBookingToDateDisplay', date)}
                            label="Recharge To Date"
                            name="rechargeBookingToDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />

                    </GridContainer>
                    <Button color="primary" type="submit">Search</Button>
                </Form>
            )}
        </Formik>
        </>
        }
    </>
    )
}

export default RechargeReportSearchForm;