import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
//import ScrollToTopComp from "components/LandingPage/LookMyTicket/ScrollToTopComp";
import HomePage from "components/LandingPage/LookMyTicket/HomePage";
import AboutUs from "components/LandingPage/LookMyTicket/siteinfo/AboutUsPage";
import ContactUs from "components/LandingPage/LookMyTicket/siteinfo/ContactUsPage";
import TermsAndConditions from "components/LandingPage/LookMyTicket/siteinfo/TermsAndConditions";
import PrivacyPolicy from "components/LandingPage/LookMyTicket/siteinfo/PrivacyPolicy";
import { Route, Switch } from "react-router-dom";
import { FooterSection } from "components/LandingPage/LookMyTicket/FooterSection";
import LandingPageBase from "components/LandingPage/LookMyTicket/LandingPageBase";
import HomePageNavbar from "components/LandingPage/LookMyTicket/HomePageNavbar";
import WebCheckIn from "pages/module/flight/webCheckIn";
import AirlineContact from "pages/module/flight/airlineContact";
import PayuCard from "pages/module/paymentgateway/PayuCheckout/PayuCard";

const useStyles = makeStyles(styles);

const width = window.innerWidth;
const breakpoint = 980;


export default function Pages(props) {

  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div className={classes.wrapper}>
      <HomePageNavbar isloading={isLoading} />
      <Switch>
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/termsandconditions" component={TermsAndConditions} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/webcheckin" component={WebCheckIn} />
        <Route path="/airlinecontacts" component={AirlineContact} />
        <Route path="/payudemo" component={PayuCard} />
        <Route component={LandingPageBase} />
      </Switch>
      <FooterSection />
      {/* <ScrollToTopComp /> */}
    </div>
  );
}
