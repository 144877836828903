import React, {useState, useEffect} from 'react';
import WebApi from "api/ApiConstants";
import BankAccountCard from 'pages/management/bank-account-details/BankAccountCard';
import OaCard from 'oahoc/OaCard';
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaTabBar from 'oahoc/OaTabBar';
import { apiCall } from 'oautils/oaDataUtils';
import BankAccountResult from 'pages/management/bank-account-details/BankAccountResult';

const BankAccountList = (props) => {

    const [response, setResponse] = useState({});
    const [bankList, setBankList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if(isLoading){
            apiCall(WebApi.getBankList, {}, (response) => {
                setResponse(response);
                setBankList(response.data);
                setIsLoading(false);
                setIsError(response.success===false);
            });
        }
    }, [isLoading]);

    return (
        <>
            {isLoading && <OaLoading />}
            {isError && <OaError details={response.message} />}
            {!isLoading && !isError && 
            <BankAccountCard bankList={bankList}/>
            }
        </>
    )
}
export default BankAccountList;