import React from 'react'

const Seat = (props) => {
    const [isChecked , setIsChecked] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [classString, setClassString] = React.useState("");

    React.useEffect(()=>{
        if(props.seat.availablityType === '1') {
            var selectedSeats = [...props.selected, ...props.selectedrt];
            let check = selectedSeats.includes(props.seat) ? true : false;
            setIsChecked(check);
            
            // if(
            //     (props.isreturn && props.seatsfullrt && !props.selectedrt.includes(props.seat)) || 
            //     (props.seatsfull && !props.selected.includes(props.seat))
            // ) {
            //     setIsDisabled(true);
            //     setClassString("selection-restricted");
            // } else {
            //     setIsDisabled(false);
            //     setClassString("");
            // }
        } else {
            setIsDisabled(true);
        }
        
    },[props.selected, props.selectedrt, /*props.seatsfull, props.seatsfullrt*/]);

    React.useEffect(() =>{
        setInitialSeatState();
    },[]);

    const onSeatClick = ($event) =>{
        props.selectedseat($event, props.seat, props.isreturn)
    }

    function setInitialSeatState() {
        if (props.seat.availablityType === '1') {
            if (props.paxinfo?.seatStatus[props.segidx]?.seatsFull) {
                if (props.selected.includes(props.seat)) {
                    setIsChecked(true);
                } 
                // else {
                //     setIsDisabled(true);
                //     setClassString("selection-restricted");
                // }
            } else if(props.isreturn && props.paxinfo?.seatStatusRt[props.segidx]?.seatsFull) {
                if(props.selectedrt.includes(props.seat)) {
                    setIsChecked(true);
                }
            } else {
                setIsChecked(false);
            }
        } else {
            setIsDisabled(true);
        }
    }

    return (
        <li className="seat" key={`${props.segidx}_${props.seat.code}_${props.isreturn}`}>
            <input
                type="checkbox"
                id={`${props.segidx}_${props.seat.code}_${props.isreturn}`}
                onChange={onSeatClick}
                checked={isChecked}
                disabled={isDisabled}
                className={classString}
            />
            {
                props?.seat?.price === 0 ?
                    <label htmlFor={`${props.segidx}_${props.seat.code}_${props.isreturn}`} style={{ background: 'rgb(80, 227, 194)' }}>{props.seat.code}</label> :
                    <label htmlFor={`${props.segidx}_${props.seat.code}_${props.isreturn}`}>{props.seat.code}</label>
            }
            <div 
                className={`show-info abs-${props?.seat?.seatNo}`}
            >
                <span>
                    {
                        props?.seat?.seatType === '1' ? "Window Seat" : props?.seat?.seatType === '3' ? "Middle Seat" : "Aisle Seat"
                    }
                </span>|
                <span>{props?.seat?.price}</span>₹
            </div>
        </li>
    )
}

export default Seat
