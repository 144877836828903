import React, {useState} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { Formik, Form } from 'formik';
import OaFormTextField from 'oahoc/OaFormTextField';
import Collapse from '@material-ui/core/Collapse';

const AgentListSearchForm = (props) => {
    const [collapsed, setCollapsed] = useState(true);

    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }
    return(
        <>
        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Search Agents</h4>
        <Collapse in={collapsed}>
        <Formik 
            initialValues={{ 
                agencyName: '',
                agencyId: '',
                panNo: '',
                email: '',
                agentMobileNo: ''
            }}
            onSubmit= {(searchParams, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                props.target(searchParams);
                setTimeout(() => {
                    setSubmitting(false);
                }, 500);
            }}>
            {({ values, isSubmitting, setFieldValue }) => (
                <Form>
                    <GridContainer>
                        <OaFormTextField name="agencyName" label="Agency name" md={4}/>
                        <OaFormTextField name="agencyId" label="Agency ID"  md={4}/>
                        <OaFormTextField name="panNo" label="PAN"  md={4}/>
                        <OaFormTextField name="email" label="Email"  md={4}/>
                        {/*<OaFormTextField name="agentMobileNo" label="Phone"  md={4}/>*/}
                    </GridContainer>
                    <Button color="primary" type="submit" disabled={isSubmitting}>Search</Button>
                </Form>
            )}
        </Formik>
        </Collapse>
        </>
    )
}

export default React.memo(AgentListSearchForm);