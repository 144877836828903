import React, {useState, useEffect} from 'react'
import LedgerSearchForm from 'pages/report/ledger/LedgerSearchForm';
import LedgerSearchResult from 'pages/report/ledger/LedgerSearchResult';
import AgentDetails from 'pages/agent/AgentDetails';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import {getQueryVar, getCurrentTab, getAgencyId} from 'oautils/oaCommonUtils.js';

import dateFnsFormat from 'date-fns/format';
import { useTheme } from '@material-ui/core';


export const Ledger = (props) => {

    const [agencyId, setAgencyId] = useState(getAgencyId(props));
    const [isLoading, setIsLoading] = useState(true);
    const theme=useTheme()
    let d = new Date;
   // let fromDate = d.setDate(d.getDate() - 30);
    let fromDate = d;
    let today = new Date();

    let tabs = [
        { name: 'ledger', label: 'Ledger', url: '/admin/ledger' },
    ];
    let currentTab = 'ledger';

    let initialParams = {
        agencyId: agencyId,
        dateFromDisplay: today,
        dateToDisplay: today,
        dateFrom: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
        dateTo: dateFnsFormat(today, 'dd-MM-yyyy'),
        type: ' '
    };
    const [tableKey, setTableKey] = useState(0);
    const [searchParams, setSearchParams] = useState(initialParams);

    
    function ledgerReportLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    useEffect(() => {
        let aId = getAgencyId(props)
        setAgencyId(aId);
        setSearchParams({...searchParams, agencyId: aId});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (
        <>
        {!isLoading &&
            <OaCard className={'oa-card-primary'}>
                <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
                {agencyId && 
                    (
                        <OaCard>
                            <AgentDetails agencyId={agencyId} />
                        </OaCard>
                    )
                }
                <OaCard> 
                    <LedgerSearchForm 
                        target={ledgerReportLoader} 
                        agencyId={agencyId} 
                        searchParams={searchParams}
                        setIsLoading={setIsLoading}
                        />
                </OaCard>
                    <OaCard>
                        <LedgerSearchResult key={tableKey} searchParams={searchParams} theme={theme}/>
                    </OaCard> 
            </OaCard> 
        }
        </>
    )
}
export default Ledger;