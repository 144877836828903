import React from "react";

import Grid from "@material-ui/core/Grid";
import { Divider, Typography } from "@material-ui/core";
import image1 from "assets/img/Hotelassets/Rectangle1.png";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import image from "assets/img/Hotelassets/Breakfast.png";
import image2 from "assets/img/Hotelassets/wifi.png";
import WifiIcon from "@material-ui/icons/Wifi";
import Button from "components/CustomButtons/Button";
import { Icon } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import OaLoading from "pages/components/OaLoading";
import { AppConstant } from "appConstant";

export const Rooms = ({
  hotelSearch,
  roomInfo,
  selectedRooms,
  setSelectedRooms,
  roomCombination,
  setRoomCombination,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const hotelPriceRoundOff = AppConstant.hotelPriceRoundOff;

  console.log("hotelPriceRoundOff ", hotelPriceRoundOff);
  const getFacilityIcon = (description) => {
    switch (description) {
      case "Dry cleaning/laundry service":
        return "dry_cleaning";

      case "Covered parking":
        return "local_parking";

      case "Wheelchair accessible (may have limitations)":
        return "accessible";

      case "Luggage storage":
        return "luggage";

      case "24-hour front desk":
        return "supervisor_account";

      case "Wheelchair-accessible registration desk":
        return "accessible";

      case "Wheelchair-accessible concierge desk":
        return "accessible";

      case "Breakfast available (surcharge)":
        return "free_breakfast";

      case "Free WiFi ":
        return "wifi";

      case "Library":
        return "local_library";

      case "Designated smoking areas":
        return "smoking_rooms";

      case "Barbecue grill(s)":
        return "outdoor_grill";

      case "Garden":
        return "yard";

      case "In-room accessibility":
        return "room_service";

      case "Designated smoking areas (fines apply)":
        return "smoking_rooms";

      case "Stair-free path to entrance":
        return "stairs";

      case "Laundry facilities":
        return "dry_cleaning";

      case "Free newspapers in lobby":
        return "feed";

      case "Tours/ticket assistance":
        return "confirmation_number";

      case "Elevator":
        return "elevator";

      case "Free self parking":
        return "local_parking";

      case "Terrace":
        return "deck";

      case "Concierge services":
        return "child_care";

      case "Newspapers in lobby (surcharge)":
        return "feed";

      case "Bar":
        return "local_bar";

      case "gym":
        return "fitness_center";

      case "restaurant":
        return "hotel";

      case "Reception hall":
        return "meeting_room";

      case "meeting_room":
        return "meeting_room";

      case "Free breakfast":
        return "free_breakfast";

      case "Smoke-free property":
        return "smoke_free";

      case "Accessible bathroom":
        return "wc";

      case "Coffee/tea in common areas":
        return "local_cafe";

      case "Shopping mall on site":
        return "add_shopping_cart";

      case "Safe-deposit box at front desk":
        return "account_balance_wallet";

      case "Television in common areas":
        return "tv";

      default:
        return "error_outline";
    }
  };

  const getFacilityText = (description) => {
    switch (description) {
      case "Dry cleaning/laundry service":
        return "Dry Clean";

      case "Covered parking":
        return "Covered Parking";

      case "Wheelchair accessible (may have limitations)":
        return "Wheelchair";

      case "Luggage storage":
        return "Luggage";

      case "24-hour front desk":
        return "Front Desk always";

      case "Wheelchair-accessible registration desk":
        return "Wheelchair";

      case "Wheelchair-accessible concierge desk":
        return "Wheelchair";

      case "Breakfast available (surcharge)":
        return "Breakfast";

      case "Free WiFi ":
        return "WiFi";

      case "Library":
        return "Library";

      case "Designated smoking areas":
        return "Smoking Areas";

      case "Barbecue grill(s)":
        return "Barbecue";

      case "Garden":
        return "Garden";

      case "In-room accessibility":
        return "Room Service";

      case "Designated smoking areas (fines apply)":
        return "Smoking Areas";

      case "Stair-free path to entrance":
        return "Stair Free";

      case "Laundry facilities":
        return "Laundry";

      case "Free newspapers in lobby":
        return "Newspapers";

      case "Tours/ticket assistance":
        return "Tours Assistance";

      case "Elevator":
        return "Elevator";

      case "Free self parking":
        return "Free Parking";

      case "Terrace":
        return "Terrace";

      case "Concierge services":
        return "Concierge services";

      case "Newspapers in lobby (surcharge)":
        return "Newspapers";

      case "Bar":
        return "Bar";

      case "gym":
        return "Gym";

      case "restaurant":
        return "Restaurant";

      case "Reception hall":
        return "Reception hall";

      case "meeting_room":
        return "Meeting Room";

      case "Free breakfast":
        return "Free Breakfast";

      case "Smoke-free property":
        return "Smoke Free";

      case "Accessible bathroom":
        return "Accessible Bathroom";

      case "Shopping mall on site":
        return "Shopping Mall";

      case "Safe-deposit box at front desk":
        return "Safe Deposite";

      case "Coffee/tea in common areas":
        return "Coffee/tea in common areas";

      case "Television in common areas":
        return "Common Television";

      default:
        return description;
    }
  };

  const addRoom = (name, price, code, roomIndex, room) => {
    // let data = [...selectedRooms.room];
    // data.push({
    //   name: name,
    //   price: hotelSearch.noOfNights * price,
    //   countryCode: code,
    //   roomIndex,
    //   roomInfo: room,
    // });

    const data = checkNAddCombinationRoom(name, price, code, roomIndex, room);
    if (parseInt(hotelSearch?.noOfRooms) > selectedRooms?.room.length) {
      setSelectedRooms({ room: data });
    }
    // window.scrollTo(0, 300);
  };

  const checkNAddCombinationRoom = (name, price, code, roomIndex, room) => {
    let data = [...selectedRooms.room];

    if (
      roomInfo.roomCombinations &&
      roomInfo.roomCombinations.infoSource === "FixedCombination"
    ) {
      if (roomCombination.length === 0) {
        setRoomCombination(roomInfo.roomCombinations.roomCombination);
      }

      for (
        let index = 0;
        index < roomInfo.roomCombinations.roomCombination.length;
        index++
      ) {
        const combination = roomInfo.roomCombinations.roomCombination[index];

        for (let index = 0; index < combination.roomIndex.length; index++) {
          const element = combination.roomIndex[index];

          if (element == roomIndex) {
            for (
              let indexSec = 0;
              indexSec < combination.roomIndex.length;
              indexSec++
            ) {
              let roomIndexTemp = combination.roomIndex[indexSec];
              // console.log("######### element ##########");
              // // console.log(element);

              const roomData = getRoomData(roomIndexTemp);
              // console.log(roomData.categoryId);
              data.push({
                name: roomData.roomTypeName,
                price: roomData.price.commissionCharge.grossProductPrice, //roomData.price.offeredPriceRoundedOff
                countryCode: roomData.price.currencyCode,
                roomIndex: roomIndexTemp,
                roomInfo: roomData,
                categoryId: roomData.categoryId,
              });
            }

            return data;
          }
        }
      }
    } else {
      // console.log("######### categoryId 0 ##########", room);
      data.push({
        name: name,
        price: price,
        countryCode: code,
        roomIndex,
        roomInfo: room,
        categoryId: room.categoryId,
      });

      return data;
    }
  };

  const getRoomData = (roomIndex) => {
    for (let index = 0; index < roomInfo.hotelRoomsDetails.length; index++) {
      const room = roomInfo.hotelRoomsDetails[index];

      if (room.roomIndex == roomIndex) {
        return room;
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        style={{
          paddingLeft: isMobile ? 5 : 15,
          paddingRight: isMobile ? 5 : 15,
        }}
      >
        {roomInfo && // 👈 null and undefined check
          Object.keys(roomInfo).length === 0 &&
          Object.getPrototypeOf(roomInfo) === Object.prototype && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <OaLoading />
            </div>
          )}
        {roomInfo.roomCombinations &&
          roomInfo.roomCombinations &&
          roomInfo.roomCombinations.infoSource === "FixedCombination" && (
            <Card className={classes.card}>
              <CardBody>
                <Grid container>
                  <Grid item>
                    <Typography
                      style={{
                        color: "#EC6161",
                        fontSize: 18,
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      NOTE: ROOM COMBINATIONS ARE FIXED
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                  {
                    roomInfo.roomCombinations.roomCombination.map((combination) => (
                      <Grid item>
                        {combination.roomIndex.join("|")}
                      </Grid>
                    ))
                  }
                </Grid> */}
                </Grid>
              </CardBody>
            </Card>
          )}
        {/* {console.log(roomInfo.hotelRoomsDetails)} */}
        {roomInfo.hotelRoomsDetails &&
          roomInfo.hotelRoomsDetails.map((room, ind) => (
            <Card className={classes.card} key={ind}>
              <CardBody>
                <Grid container>
                  <Grid item md={8}>
                    <Typography className={classes.typosup}>
                      {room.roomTypeName}
                    </Typography>
                    {isMobile && <Divider style={{ margin: 10 }} />}
                    <Grid item>
                      <Typography className={classes.textInc}>
                        Amenities :
                      </Typography>
                      {room.amenities.map((amenity, idx) => (
                        <Typography className={classes.data1} key={idx}>
                          {amenity}
                        </Typography>
                      ))}
                    </Grid>
                    {/* <Grid Item>
                        <Typography>Room Size:419 sq.ft |</Typography>
                      </Grid>
                      <Grid Item>
                        <Typography>Bed Type:King Type |</Typography>
                      </Grid>
                      <Grid Item>
                        <Typography>24 Hours Room service</Typography>
                      </Grid> */}
                    {/* <Grid item md={5}>
                        <Card className={classes.facilityCard}>
                          <CardBody>
                            <Grid item xs={12}>
                              <Typography>Amenities</Typography>
                            </Grid>
                            <Grid container spacing={4}>
                              {room.amenities[0].split(",").map((amenity) => (
                                <Grid item>
                                  <Icon>{getFacilityIcon(amenity)}</Icon>
                                  <Typography>
                                    {getFacilityText(amenity)}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          </CardBody>
                        </Card>
                      </Grid>
                    </Grid> */}
                  </Grid>
                  {isMobile && (
                    <Grid item xs={12}>
                      <Divider style={{ margin: 10 }} />
                    </Grid>
                  )}
                  <Grid item md={4} style={{ width: isMobile && "100%" }}>
                    {/* <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{ marginLeft: isMobile ? "0px" : "60%" }}
                      > */}
                    {/* <Card
                          style={{ width: 230 }}
                          className={classes.priceCard}
                        >
                          <CardBody> */}
                    {/* <Grid container> */}
                    <Grid
                      item
                      style={{ textAlign: isMobile ? "left" : "right" }}
                    >
                      <Typography className={classes.data}>
                        {isMobile && "Rate:- "}

                        {room.price.currencyCode === "INR"
                          ? "₹"
                          : room.price.currencyCode}
                        {hotelPriceRoundOff
                          ? room.price.commissionCharge.grossProductPrice.toFixed(
                            0
                          )
                          : room.price.commissionCharge.grossProductPrice.toLocaleString('en-IN')
                        }
                      </Typography>

                      {/* <Typography className={classes.data1}>
                              Service fee:{0}
                            </Typography>
                            <Typography className={classes.data1}>
                              Occupancy and Taxes:{0}
                            </Typography> */}
                      {/* <Typography className={classes.textInc}>
                              Inclusion :
                            </Typography> */}
                      {/* {room.amenities.map((amenity) => (
                              <Typography className={classes.data1}>
                                {amenity}
                              </Typography>
                            ))} */}
                      {/* </Grid>
                    <Grid item md={6}> */}
                      <Button
                        onClick={() =>
                          addRoom(
                            room.roomTypeName,
                            room.price.commissionCharge.grossProductPrice,
                            room.price.currencyCode,
                            room.roomIndex,
                            room
                          )
                        }
                        style={{
                          width: isMobile && "100%",
                          background: theme.palette.buttons.tertiary,
                          color: theme.palette.buttons.tertiaryContrastText,
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  {/* </CardBody>
                        </Card> */}
                </Grid>
                {/* </Grid> */}
                {/* </Grid>
                </Grid> */}

                {/* map according to data in array */}
              </CardBody>
            </Card>
          ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  data: {
    fontSize: "20px",
    color: theme.palette.background.peach,
  },
  data1: {
    fontSize: "14px",
    color: theme.palette.secondary.charcoal,
    fontWeight: "500",
  },
  textInc: {
    fontSize: "12px",
    color: theme.palette.secondary.sharkGray,
    fontWeight: "500",
  },
  card: {
    height: "auto",
  },
  typosup: {
    fontSize: "18px",
    color: theme.palette.background.carulean,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  facilityCard: {
    boxShadow: "none",
    background: theme.palette.background.lightFadedGray,
  },
  priceCard: {
    boxShadow: "none",
  },
}));
