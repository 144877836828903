import React from "react";
import OaCard from 'oahoc/OaCard';
import {getAgencyId} from 'oautils/oaCommonUtils.js';
import OaTabBar from 'oahoc/OaTabBar';
import AgentDetails from 'pages/agent/AgentDetails';
import dateFnsFormat from "date-fns/format";
import PanApplicationReportSearchResult from "./PanApplicationReportSearchResult";
import PanApplicationReportSearchForm from "./PanApplicationReportSearchForm";

const PanApplicationReport = (props) => {

    const [agencyId, setAgencyId] = React.useState(getAgencyId(props));
    const [isLoading, setIsLoading] = React.useState(true);

    let d = new Date;
    let fromDate = d; //.setDate(d.getDate() - 7);
    let today = new Date();

    let initialParams = {
        agentId: agencyId,
        fromDateDisplay: fromDate,
        toDateDisplay: today,
        fromDate: dateFnsFormat(fromDate, 'yyyy-MM-dd'),
        toDate: dateFnsFormat(today, 'yyyy-MM-dd'),
        appliType:'',
        ackNo: '',
        appliStatus: 'All'
    };

    const [searchParams, setSearchParams] = React.useState(initialParams);
    const [tableKey, setTableKey] = React.useState(0);

    function panApplicationReportLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    let tabs = [
        { name: 'pan', label: 'NSDL PAN', url: '/admin/transaction-report/pan' },
    ];

    let currentTab = 'pan';

    React.useEffect(() => {
        let aId = getAgencyId(props)
        setAgencyId(aId);
        setSearchParams({...searchParams, agencyId: aId});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (!isLoading &&
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            {agencyId && 
                (
                    <OaCard>
                        <AgentDetails agencyId={agencyId} />
                    </OaCard>
                )
            }
            <OaCard>
                <PanApplicationReportSearchForm 
                    target={panApplicationReportLoader} 
                    agencyId={agencyId}
                    searchParams={searchParams}
                    setIsLoading={setIsLoading}
                />
            </OaCard>

            <OaCard>
                <PanApplicationReportSearchResult key={tableKey} searchParams={searchParams} agencyId={agencyId} target={panApplicationReportLoader} />
            </OaCard>
        </OaCard>
    );
};

export default PanApplicationReport;
