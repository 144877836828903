export const getQueryVar = (props) => {
  let queryVar =
    props.match === undefined ? undefined : props.match.params[props.q];
  return queryVar;
};

export const getCurrentTab = (queryVar, defaultTab) => {
  let currentTab = queryVar === undefined ? defaultTab : queryVar;
  return currentTab;
};

export const getTabName = () => {
  var url = window.location.href;

  if (url.indexOf("flights") > -1) return "flights";
  else if (url.indexOf("buses") > -1) return "buses";
  else if (url.indexOf("hotels") > -1) return "hotels";

  return false;
};

export const getAgencyId = (props) => {
  let qAgencyId =
    props.match === undefined && props.match.params === undefined
      ? undefined
      : props.match.params.agencyId;
  return qAgencyId;
};

export const scrollToTop = (offsetTop) => {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }
  setTimeout(function () {
    window.focus();
    window.scrollTo(0, 0);
  }, 2);
};

export const currencyFormatter = (amount) => {
  let formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return formatter.format(amount);
};

export const multiCurrencyFormatter = (amount, currency) => {

  let formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  if (currency === "Dolor") {
    formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  return formatter.format(amount);
};

export const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const inWords = (num) => {
  //console.log (("000000000" + num));

  const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];


  if ((num = num.toString()).length > 9) return "overflow";
  const n = ("000000000" + num)
    .substring(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

  //console.log("N ",n[1] + ","+n[2] +","+ n[3] + ","+n[4]+ ","+n[5]);
  if (!n) return;
  let str = "";
  str +=
    n[1] !== 0 && n[1] !== "00"
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] !== 0 && n[2] !== "00"
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] !== 0 && n[3] !== "00"
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] !== 0 && n[4] !== "0"
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] !== 0
      ? (str !== "" && n[5] !== 0 && n[5] !== "00" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      "only "
      : "";
  return str;
};
