import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    "& .autocomplete": {
      padding: "10px 18px",
    },
    "& .autocomplete-wrapper": {
      position: "absolute",
      top: 30,
      left: 20,
      width: 300,
      zIndex: 8,
      background: theme.palette.background.light,
      boxShadow: "2px 2px 10px 0px rgba(135,133,135,1)",
      MozBoxShadow: "2px 2px 10px 0px rgba(135,133,135,1)",
      WebkitBoxShadow: "2px 2px 10px 0px rgba(135,133,135,1)",
      [theme.breakpoints.down(992)]: {
        width: "90vw",
      },
    },
    "& .hidden": {
      display: "none",
    },
    "& .show": {
      display: "block",
    },
    "& .oa-form-element-card": {
      width: "100%",
      padding: 6,
      borderRadius: 5,
      overflow: "hidden",
      cursor: "pointer",
    },
    "& .oa-form-element-label": {
      color: theme.palette.primary.darkGray,
      fontWeight: 400,
      [theme.breakpoints.down(1500)]: {
        fontSize: "12px !important",
      },
    },
    "& .oa-form-element-primary": {
      marginTop: -2,
      color: theme.palette.primary.defaultText,
    },
    "& .oa-form-element-secondary": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: theme.palette.primary.defaultText,
      fontWeight: 400,
      fontSize: 11,
      [theme.breakpoints.down(630)]: {
        whiteSpace: "normal",
      },
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      color: theme.palette.primary.darkText,
    },
    "& .MuiAutocomplete-listbox": {
      overflowY: "auto !important",
      position: "absolute",
      left: 0,
      right: -36,
      background: theme.palette.background.light,
      boxShadow: "2px 8px 10px 0px rgba(135,133,135,1)",
      MozBoxShadow: "2px 8px 10px 0px rgba(135,133,135,1)",
      WebkitBoxShadow: "2px 8px 10px 0px rgba(135,133,135,1)",
      top: 0,
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.background.rgba_primary,
        borderRadius: 8,
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: theme.palette.background.rgba_primary_active,
        },
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child": {
      padding: "2px 0",
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      paddingRight: 28,
    },
    "& .ac-options-list": {
      overflow: "hidden",
      width: "100%",
      "& p": {
        marginBottom: 0,
        color: theme.palette.primary.darkFadedText,
        whiteSpace: "nowrap",
      },
      "& span": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        flexWrap: "nowrap",
        "& .ac-option-left": {
          textAlign: "left",
          color: theme.palette.primary.darkText,
          fontWeight: 600,
          flexGrow: 1,
        },
        "& .ac-option-right": {
          textAlign: "right",
          color: theme.palette.primary.darkFadedText,
        },
      },
    },
    "& .empty-text": {
      color: theme.palette.primary.disabled,
      [theme.breakpoints.down(1100)]: {
        fontSize: 12,
      },
    },
  },
}));

const OaPopperAutocomplete = (props) => {
  const classes = useStyles();
  const rootRef = React.useRef();
  const acRef = React.useRef();
  const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(props.value);

  const outOfContexArea = (event) => {
    if (rootRef.current && !rootRef.current.contains(event.target)) {
      setOpenAutoComplete(false);
    }
  };

  const togglePopper = (flag) => {
    setOpenAutoComplete(flag);
  };

  const clearInput = (event) => {
    if (props.clearonfocus !== undefined && props.clearonfocus == "true") {
      setInputValue({
        airportId: 0,
        cityName: "",
        airportCode: "",
        countryCode: "",
        citySearch: "",
        airportDesc: "",
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", outOfContexArea);

    return () => {
      document.removeEventListener("mousedown", outOfContexArea);
    };
  }, []);

  React.useEffect(() => {
    if (openAutoComplete) {
      acRef.current.focus();
    }
  }, [openAutoComplete]);

  return (
    <div className={classes.root} ref={rootRef}>
      <div
        className="oa-form-element-card"
        onClick={() => togglePopper(!openAutoComplete)}
      >
        <div className="oa-form-element-label">
          <span>{props.labeltxt}</span>
        </div>
        <div className="oa-form-element-primary">
          {props.inputtext !== "" ? (
            props.inputtext
          ) : props.type == "depart" ? (
            <span className="empty-text">Departure City</span>
          ) : (
            <span className="empty-text">Destination City</span>
          )}
        </div>
        <div
          className="oa-form-element-secondary"
          style={{
            height: props.inputtext !== "" ? "auto" : 20,
            // minWidth: 100,
          }}
        >
          {props.secondarytxt}
        </div>
      </div>

      <div
        className={`autocomplete-wrapper ${
          openAutoComplete ? "show" : "hidden"
        }`}
      >
        <Autocomplete
          {...props}
          // value={inputValue}
          value={""}
          open={openAutoComplete}
          onClose={() => togglePopper(false)}
          renderInput={(params) => (
            <TextField {...params} inputRef={acRef} onFocus={clearInput} />
          )}
        />
      </div>
    </div>
  );
};

export default OaPopperAutocomplete;
