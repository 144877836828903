export const SET_HOTEL_COUNTRY = "SET_HOTEL_COUNTRY";
export const SET_HOTEL_CITY = "SET_HOTEL_CITY";
export const SET_HOTEL_SEARCH = "SET_HOTEL_SEARCH";
export const SET_HOTEL_SEARCH_RESULT = "SET_HOTEL_SEARCH_RESULT";
export const SET_HOTEL_FILTERS = "SET_HOTEL_FILTERS";
export const SET_HOTEL_INFO = "SET_HOTEL_INFO";
export const SET_HOTEL_DETAILS = "SET_HOTEL_DETAILS";
export const SET_HOTEL_ROOM_PARAM = "SET_HOTEL_ROOM_PARAM";
export const SET_TIMER_INITIAL_MINUTE = "SET_TIMER_INITIAL_MINUTE";
export const SET_TIMER_INITIAL_SECOND = "SET_TIMER_INITIAL_SECOND";
export const SET_BUS_BOOKING_INITIAL_TIME = "SET_BUS_BOOKING_INITIAL_TIME";
export const SET_FLIGHT_BOOKING_INITIAL_TIME =
  "SET_FLIGHT_BOOKING_INITIAL_TIME";
export const SET_HOTEL_BOOKING_INITIAL_TIME = "SET_HOTEL_BOOKING_INITIAL_TIME";
export const SET_INSURANCE_BOOKING_INITIAL_TIME =
  "SET_INSURANCE_BOOKING_INITIAL_TIME";

export function setHotelCountry(payload) {
  return {
    type: SET_HOTEL_COUNTRY,
    payload,
  };
}

export function setHotelCity(payload) {
  return {
    type: SET_HOTEL_CITY,
    payload,
  };
}

export function setHotelSearch(payload) {
  return {
    type: SET_HOTEL_SEARCH,
    payload,
  };
}

export function setHotelSearchResult(payload) {
  return {
    type: SET_HOTEL_SEARCH_RESULT,
    payload,
  };
}
export function setHotelFilters(payload) {
  return {
    type: SET_HOTEL_FILTERS,
    payload,
  };
}

export function setHotelInfo(payload) {
  return {
    type: SET_HOTEL_INFO,
    payload,
  };
}

export function setHotelDetails(payload) {
  return {
    type: SET_HOTEL_DETAILS,
    payload,
  };
}

export function setHotelRoomParam(payload) {
  return {
    type: SET_HOTEL_ROOM_PARAM,
    payload,
  };
}

export function setTimerInitialMinute(payload) {
  return {
    type: SET_TIMER_INITIAL_MINUTE,
    payload,
  };
}

export function setTimerInitialSecond(payload) {
  return {
    type: SET_TIMER_INITIAL_SECOND,
    payload,
  };
}

export function setBusBookingInitialTime(payload) {
  return {
    type: SET_BUS_BOOKING_INITIAL_TIME,
    payload,
  };
}

export function setFlightBookingInitialTime(payload) {
  return {
    type: SET_FLIGHT_BOOKING_INITIAL_TIME,
    payload,
  };
}

export function setHotelBookingInitialTime(payload) {
  return {
    type: SET_HOTEL_BOOKING_INITIAL_TIME,
    payload,
  };
}

export function setInsuranceBookingInitialTime(payload) {
  return {
    type: SET_INSURANCE_BOOKING_INITIAL_TIME,
    payload,
  };
}
