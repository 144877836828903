import React from 'react';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import OaError from 'pages/components/OaError';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import MasterBalanceManagerNew from './MasterBalanceManagerNew';
import MasterBalanceManagerSent from './MasterBalanceManagerSent';

const MasterBalanceManager = (props) => {

    let baseUrl = '/admin/management/balance/master/';
    let tabs = [
        { name: 'new', label: 'New Fund Request', url: baseUrl + 'new' },
        { name: 'sent', label: 'Fund Request Log', url: baseUrl + 'sent' }
    ];

    let defaultTab = 'new';
    let queryVar = getQueryVar({...props, q: 'priTab'});
    let currentTab = getCurrentTab(queryVar, defaultTab);

    function renderTab(currentTab){
        switch(currentTab){
            case 'new':
                return <MasterBalanceManagerNew {...props}/>
            case 'sent':
                return <MasterBalanceManagerSent {...props}/>
            default:
                return <OaError />
        }
    }
    
    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            {renderTab(currentTab)}
        </OaCard>
    )
}

export default MasterBalanceManager;
