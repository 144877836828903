import React from "react";
import { makeStyles } from "@material-ui/core";
import { LocalMallRounded } from "@material-ui/icons";

const StrikeThroughLocalMall = props => {
    const classes = useStyles();

    return (
        <span className={classes.root}>
            <LocalMallRounded/>
            <span className="strike-through"></span>
        </span>
    );
};

export default StrikeThroughLocalMall;

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        "& .strike-through": {
            position: "absolute",
            top: 8,
            right: 3,
            left: 3,
            height: 1,
            background: "red",
            transform: "rotate(45deg)"
        }
    }
}));
