import React from 'react';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import OaError from 'pages/components/OaError';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import BankAccountForm from "./BankAccountForm.js"
import BankAccountList from "./BankAccountList.js"

const AddBankAccount = (props) => {

    let baseUrl = '/admin/management/add-bank-account/';
    let tabs = [
        { name: 'list', label: 'Bank Account List', url: baseUrl + 'list' },
        { name: 'form', label: 'Add Bank Account', url: baseUrl + 'form' }
        
    ];

    let defaultTab = 'list';
    let queryVar = getQueryVar({...props, q: 'priTab'});
    let currentTab = getCurrentTab(queryVar, defaultTab);

    function renderTab(currentTab){
        switch(currentTab){
            case 'form':
                return <BankAccountForm {...props}/>
            case 'list':
                return <BankAccountList {...props}/>
            default:
                return <OaError />
        }
    }
    
    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            {renderTab(currentTab)}
        </OaCard>
    )
}

export default AddBankAccount;