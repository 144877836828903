import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import dateFnsFormat from "date-fns/format";

import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlightModify from "./FlightModify";
import { COLORS } from "assets/css/CssConstants";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  //console.log(theme);
  return {
    root: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      // height: "80px",
      padding: "10px 60px",
      color: theme.palette.primary.contrastText,
      position: "sticky",
      top: "130px",
      zIndex: "8",
      "&.oa-navbar-sticky": {
        top: 85,
      },
      "& .MuiFormControlLabel-label": {
        color: theme.palette.primary.lightText,
      },
      [theme.breakpoints.down(1400)]: {
        padding: "12px",
      },
    },
    rootMod: {
      backgroundColor: theme.palette.primary.main,
      //theme.palette.secondary.main,
      width: "100%",
      height: "auto",
      padding: "16px 60px",
      color:  theme.palette.primary.lightText,
      position: "sticky",
      top: "60px",
      zIndex: "8",
      [theme.breakpoints.down(1400)]: {
        padding: "12px",
      },
    },
    flightSearchElements: {
      
      alignItems:"center",
      [theme.breakpoints.down(960)]: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
      },
      [theme.breakpoints.down(600)]: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    },
    flightSearchElement: {
      display:"flex",
      gap: "25px",
    },
    flightSearchElement_title: {
      height: "16px",
      marginBottom: "8px",
      "& span": {
        fontSize: "20px",
        fontWeight: "500",
        [theme.breakpoints.down(1080)]: {
          fontSize: "8px",
        },
      },
    },
    flightSearchElement_value: {
      height: "24px",
      "& span": {
        fontSize: "24px",
        fontWeight: "500",
        [theme.breakpoints.down(1290)]: {
          fontSize: "16px",
        },
      },
      "& .MuiInputBase-root": {
        color:  theme.palette.primary.lightText,
        "& .MuiInputBase-input": {
          fontSize: "20px",
          fontWeight: "400",
          [theme.breakpoints.down(1080)]: {
            fontSize: "16px",
          },
        },
      },
    },

    modifyButton: {
      // [theme.breakpoints.down(1620)]: {
      //   display: "none",
      // },
    },
    flightSearchCTA: {
      height: "37px",
      width: "122px",
      backgroundColor:  theme.palette.primary.lightText,
      borderRadius: "4px",
      cursor: "pointer",

      "& span": {
        fontSize: "20px",
        fontWeight: "600",
        color:  theme.palette.primary.defaultText,
        textAlign: "center",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
      },
    },
  };
});

const radioStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: `1px auto ${theme.palette.secondary.contrastText}`,
        outlineOffset: 1,
      },
      "input:hover ~ &": {
        backgroundColor: `${theme.palette.secondary.contrastText}90`,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: `${theme.palette.secondary.contrastText}10`,
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.secondary.contrastText,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: `radial-gradient(${theme.palette.secondary.main},${theme.palette.secondary.main} 28%,transparent 32%)`,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: `${theme.palette.secondary.contrastText}90`,
      },
    },
  };
});

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      {...props}
    />
  );
}

const FlightSearchHeader = (props) => {
  const classes = useStyles();
  const oaNavBarRootRef = useRef();
  const [isModifying, setIsModifying] = useState(props.ismodifying);
  const isBreakpoint = useMediaQuery('(max-width:1619px)');
  const [flightSearchHeaderDetails, setFlightSearchHeaderDetails] = useState(null);

  const depAirportName = props.data && props.data?.originAirport;
  const depAirportCode =  props.data && props.data?.originAirportCode;
  ;
  const arrivalAirportName = props.data && props.data?.destinationAirport;
  const arrAirportCode = props.data && props.data?.destinationAirportCode;
  const [arrayModify, setArrayModify] = useState({})
  

  // useEffect(() => {
  //   const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //   //const propState = props.history.location.state;



  //   const segments0 = props?.data;
  //   // const request = propState?.request;
  //   // const adultPax = request?.adultPax;
  //   // const childPax = request?.childPax;
  //   // const infantPax = request?.infantPax;
  //   // const paxDet = adultPax+" Adult"+(adultPax > 1 ? "s " : " ")+(childPax > 0 ? ", "+childPax+" Child" : "")+(infantPax > 0 ? ", "+infantPax+" Infant"+(infantPax>1 ? "s":"") : "");

  //   // const prClass = request?.preferredclassName=== "E" ? "Economy" : "Business";

  //   // const lastSegIndex = segments0 && segments0.length - 1;

  //   //const depAirportName = props.data && props.data?.originAirport;
  //   //const depAirportCode = props.data && props.data?.originAirportCode;
  //   // const depDateRaw = props.data && new Date(props.data.depDate);
  //   // const departDate = props.data && dateFnsFormat(depDateRaw, "dd MMM yyyy");
  //   // const departDay = props.data && days[depDateRaw.getDay()];

  //   //let arrAirportName = props.data && props.data?.destinationAirport;
  //   //let arrAirportCode = props.data && props.data?.destinationAirportCode;
  //   // let arrDateRaw = props.data && new Date(props.data.arrDate);
  //   // let arrDate = props.data && dateFnsFormat(arrDateRaw, "dd MMM yyyy");
  //   // let arrDay = props.data && days[arrDateRaw.getDay()];

  //   let headerArray = null;

  //   if (segments0 != null && segments0 != undefined) {
  //     headerArray = [
  //       { value: depAirportName + " (" + depAirportCode + ")" },
  //       { title: "To", value: arrAirportName + " (" + arrAirportCode + ")" },
  //       // { title: "Depart", value: departDate + ", " + departDay },
  //       // { title: "Arrival", value: arrDay + ", " + arrDate },
  //       // { title: "Passengers", value: paxDet },
  //       // { title: "Class", value: prClass },
  //     ];
  //   }

  //   setFlightSearchHeaderDetails(headerArray);

  //   setArrayModify({
  //     "depAirportName": depAirportName,
  //     "depAirportCode": depAirportCode,
  //     // "depDateRaw": depDateRaw,
  //     // "departDate": departDate,
  //     // "departDay": departDay,
  //     "arrAirportName": arrAirportName,
  //     "arrAirportCode": arrAirportCode,
  //     // "arrDateRaw": arrDateRaw,
  //     // "arrivalDate": arrDate,
  //     // "arrivalDay": arrDay,
  //     "mode": "ONE",
  //     "adultPax": 1,
  //     "childPax": 0,
  //     "infantPax": 0,
  //     "preferredClass": "E",
  //   });

  //   // const tripMode = propState?.request?.mode;

  //   // tripMode && console.log("TRIPMODE", tripMode);

  //   // if (tripMode && (tripMode === "ROUND" || tripMode === "ROUND-SP") && responseReturn !== null) {
  //   //   const segments0Ret = responseReturn?.orides && responseReturn.orides[0] && responseReturn.orides[0].flightSegments;

  //   //   if (segments0Ret) {

  //   //     const lastSegIndexRet = segments0Ret && segments0Ret.length - 1;

  //   //     arrAirportName = segments0Ret && segments0Ret[lastSegIndexRet]?.arrivalAirportName;
  //   //     arrAirportCode = segments0Ret && segments0Ret[lastSegIndexRet]?.arrivalAirportCode;
  //   //     arrDateRaw = segments0Ret && new Date(segments0Ret[lastSegIndexRet].arrivalDateTime);
  //   //     arrDate = segments0Ret && dateFnsFormat(arrDateRaw, "dd MMM yyyy");
  //   //     arrDay = segments0Ret && days[arrDateRaw.getDay()];

  //   //     const headerArrayRet = [
  //   //       { title: "From", value: depAirportName+" ("+depAirportCode+")" },
  //   //       { title: "To", value: arrAirportName+" ("+arrAirportCode+")" },
  //   //       { title: "Depart", value: departDay+", "+departDate },
  //   //       { title: "Arrival", value: arrDay+", "+arrDate },
  //   //       { title: "Passengers", value: paxDet },
  //   //       { title: "Class", value: prClass },
  //   //     ];

  //   //     setFlightSearchHeaderDetails(headerArrayRet);

  //   //     setArrayModify({
  //   //       "depAirportName": depAirportName,
  //   //       "depAirportCode": depAirportCode,
  //   //       "depDateRaw": depDateRaw,
  //   //       "departDate": departDate,
  //   //       "departDay": departDay,
  //   //       "arrAirportName": arrAirportName,
  //   //       "arrAirportCode": arrAirportCode,
  //   //       "arrDateRaw": arrDateRaw,
  //   //       "arrivalDate": arrDate,
  //   //       "arrivalDay": arrDay,
  //   //     });

  //   //   }

  //   // }

  // }, []);

  const updateModify = (updateFlag) => {
    // UPDATE STATE
    setIsModifying(updateFlag);
  };
  const cancelModify = () => {
    setIsModifying(false);
    props.isCancelModify(true);
  };

  // useEffect(() => {
  //   setIsModifying(props.isModifyingVal);
  // }, [props.isModifyingVal]);
  useEffect(() => {

    window.addEventListener('scroll', (event) => {

      if (window.scrollY >= 60) {
        oaNavBarRootRef && oaNavBarRootRef.current && oaNavBarRootRef.current.classList.add("oa-navbar-sticky");

      } else {
        oaNavBarRootRef && oaNavBarRootRef.current && oaNavBarRootRef.current.classList.remove("oa-navbar-sticky");

      }
    });

  }, []);
  return props.ismodifying ? (
    <div className={classes.rootMod}>
      <FlightModify
        multiCity={false}
        cancelModify={props.setismodifying(false)}
        updateModify={(updateFlag) => props.setismodifying(updateFlag)}
        arrayModify={arrayModify}
        props={props}
      />
    </div>
  ) : (
    <div className={classes.root} ref={oaNavBarRootRef}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.flightSearchElements}
      >
        <>
          
            <Grid
              item
              className={classes.flightSearchElement}
              onClick={() => props.setismodifying(!props.ismodifying)}
             
              style={{dislay: "flex", justifyContent: "flex-start", alignItems: "center"}}
            // xs={6}
            // sm={4}
            // md={2}
            >
              
              <div className={classes.flightSearchElement_value}>
                <span>{depAirportName}</span>
              </div>

              <div className={classes.flightSearchElement_title}>
                <span>To</span>
              </div>

              <div className={classes.flightSearchElement_value}>
                <span>{arrivalAirportName}</span>
              </div>
            </Grid>
          <Grid item className={classes.modifyButton}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.flightSearchCTA}
              // onClick={() => props.setismodifying(!props.ismodifying)}
              onClick={() => {
                props.history.goBack();
              }}
            >
              <span>Modify</span>
            </Grid>
          </Grid>
        </>
      </Grid>
    </div>
  );
};

export default withRouter(FlightSearchHeader);

// const flightSearchHeaderDetails = [
//   { title: "From", value: "Bangalore (BLR)" },
//   { title: "To", value: "Hyderabad (HYD)" },
//   { title: "Depart", value: "Mon, 14 Dec 2021" },
//   { title: "Arrival", value: "Tue, 15 Dec 2021" },
//   { title: "Passengers", value: "2 Adults, 1 Child" },
//   { title: "Class", value: "Economy" },
// ];
const flightWay = [
  { value: "one-way", label: "One way" },
  { value: "round-trip", label: "Round trip" },
  { value: "round-trip-sp", label: "Round trip special" },
  { value: "multi-city", label: "Multi city" },
];
const FLIGHT_STOPS = [
  { value: "stop", label: "Stop" },
  { value: "non-stop", label: "Non Stop" },
];
const FLIGHT_CLASS = [
  { value: "economy", label: "Economy" },
  { value: "business", label: "Business" },
];

{
  /* <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ padding: "24px 120px 36px 72px" }}
        >
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue="non-stop-only"
                aria-label="flight stops"
                name="flight-stop-radios"
                style={{ flexDirection: "row", gap: "24px" }}
              >
                {flightWay.map((s, i) => (
                  <FormControlLabel
                    value={s.value}
                    control={<FilterRadio />}
                    label={s.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue="non-stop-only"
                aria-label="flight stops"
                name="flight-stop-radios"
                style={{ flexDirection: "row", gap: "24px" }}
              >
                {FLIGHT_STOPS.map((s, i) => (
                  <FormControlLabel
                    value={s.value}
                    control={<FilterRadio />}
                    label={s.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue="non-stop-only"
                aria-label="flight stops"
                name="flight-stop-radios"
                style={{ flexDirection: "row", gap: "24px" }}
              >
                {FLIGHT_CLASS.map((s, i) => (
                  <FormControlLabel
                    value={s.value}
                    control={<FilterRadio />}
                    label={s.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          className={classes.flightSearchElements}
        >
          <>
            {flightSearchHeaderDetails.map((e, i) => (
              <Grid item className={classes.flightSearchElement}>
                <div className={classes.flightSearchElement_title}>
                  <span>{e.title}</span>
                </div>
                <div className={classes.flightSearchElement_value}>
                  <TextField
                    color="secondary"
                    id={`TextFieldMod_${e.title}`}
                    value={e.value}
                  />
                </div>
              </Grid>
            ))}
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.flightSearchCTA}
                onClick={() => setIsModifying(!isModifying)}
              >
                <span>Update</span>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid> */
}
