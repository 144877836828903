import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const MoneyTransferDetail = (props) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        {props.detail.length > 0 ?
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                {props.status}
            </Button>
            : ''}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">Transation Details</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.detail.length > 0 ? 
                    <table style={{borderCollapse: 'collapse'}}>
                        <tr style={{border: `1px solid black`}}>
                            <th style={{border: `1px solid black`, padding:10}}>Reference No</th><th style={{border: `1px solid black`, padding:10}}>Acknowledgement No</th><th style={{border: `1px solid black`, padding:10}}>Amount</th><th style={{border: `1px solid black`, padding:10}}>Status</th>
                        </tr>
                        {props.detail && props.detail.map((data, index) => {
                            return (<tr style={{border: `1px solid black`}}>
                                <td style={{border: `1px solid black`, padding:10}}>{data.dmtReferenceNo}</td><td style={{border: `1px solid black`, padding:10}}>{data.dmtAcknowledgementNo}</td><td style={{border: `1px solid black`, padding:10}}>{data.amount}</td><td style={{border: `1px solid black`, padding:10}}>{data.transactionStatus}</td>
                            </tr>)
                        })}
                    </table> : 
                    'No details' }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog> 
      </>
    )
}

export default MoneyTransferDetail;