import React from "react"; 
import OaTable from "components/OaTable";
import OaButtonPDF from "pages/components/OaButtonPDF";
import OaButtonXls from "pages/components/OaButtonXls";
import WebApi, { DOWNLOAD_RECHARGE_XLS, DOWNLOAD_RECHARGE_PDF } from "api/ApiConstants";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

const PanApplicationReportSearchResult = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [panData, setPanData] = React.useState(null);

    const getApplicationType = (appliType) => {
        return appliTypes.filter(v => { return v.value == appliType})[0]?.label;
    };

    const onSelectMenu = (event, data) => {};

    let cols = [];
    cols.push({
        Header: '#',
        Cell: props => <div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.index + 1}</div>,
        width: 35,
        filterable: false
    }, {
        Header: "Ref ID",
        accessor: "txnId",
        width: 100,
        Cell: props => (
            <div style={{ height: 44, verticalAlign: 'middle' }}>
                <CustomDropdown
                    buttonText={props.value}
                    buttonProps={{
                        fullWidth: true,
                        style: { height: 32, padding: 4 },
                        color: "rose"
                    }}
                    onClick={(event) => {
                        onSelectMenu(event, props.original);
                    }}
                    dropPlacement="bottom-start"
                    dropdownList={dropDownMenu}
                />
            </div>
        )
    }, {
        Header: "Tx Date",
        width: 150,
        accessor: "transDate",
        filterable: false,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Ack No",
        accessor: "ackNo",
        width: 160,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Type",
        accessor: "applicationType",
        width: 170,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>
            {getApplicationType(props.value)}
            </div>)
    }, {
        Header: "Amount",
        accessor: "amountDue",
        width: 80,
        filterable: false,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    },  {
        Header: "Commission",
        accessor: "agentComm",
        width: 110,
        filterable: false,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Markup",
        accessor: "agentMarkup",
        width: 80,
        filterable: false,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Status",
        accessor: "appliStatus",
        width: 80,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Token",
        accessor: "applicantDto.tokenNo",
        width: 70,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Applicant",
        accessor: "applicant",
        width: 160,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Email",
        accessor: "otherDetails.emailId",
        width: 130,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    }, {
        Header: "Phone",
        accessor: "otherDetails.telOrMobNo",
        width: 100,
        Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>{props.value}</div>)
    });
    
    return (
        <>
            <OaButtonPDF api={DOWNLOAD_RECHARGE_PDF} searchParams={props.searchParams} />
            <OaButtonXls api={DOWNLOAD_RECHARGE_XLS} searchParams={props.searchParams} />
            <OaTable
                columns={cols}
                search={props.searchParams}
                loader={WebApi.nsdlGetTransactionReport}
                filterable={true}
                rows
            />
        </>
    );
};

export default PanApplicationReportSearchResult;

const dropDownMenu = [
    "User Invoice",  "Agent Invoice", "Print Acknowledgement"
];

const appliTypes = [
    { label: "New PAN - Indian Citizen (Form 49A)", value: "A" },
    { label: "New PAN - Foreign Citizen (Form 49AA)", value: "AA" },
    { label: "Changes or Correction in existing PAN Data/Reprint of PAN Card (No changes in existing PAN Data", value: "CR" }
];

const appliCategories = [
    { label: "Individual", value: "A" },
    { label: "Association of Persons", value: "AA" },
    { label: "BODY OF INDIVIDUALS", value: "B" },
    { label: "COMPANY", value: "C" },
    { label: "TRUST", value: "T" },
    { label: "LIMITED LIABILITY PARTNERSHIP", value: "E" },
    { label: "FIRM", value: "F" },
    { label: "GOVERNMENT", value: "G" },
    { label: "HINDU UNDIVIDED FAMILY", value: "H" },
    { label: "ARTIFICIAL JURIDICAL PERSON", value: "J" },
    { label: "LOCAL AUTHORITY", value: "L" }
];
