import React, { Component } from 'react'
import ReactTable from "react-table";
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import OaAlert from 'oahoc/OaAlert';

class BeneficiaryListTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            dialogIsOpen: false,
            data: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " ",
            isAlertPrompt: false,
            alertCallback: '',
            alertCallbackParam: {},
            alertMsg:''
        }
    } 

    componentDidMount() {  
        this.tableLoader();
    }

    tableLoader() {
        apiCall(WebApi.getDMTBeneficiary, {mobile:this.props.mobile}, (response) => {
            let finishedLoadingMessage = response.data.length === 0 ? "No beneficiary added" : response.data.length;
            if (response.success === false) finishedLoadingMessage = response.message;

            if(response.data.status && response.data.data) {
                this.setState({
                    data: response.data.data,
                    rowCount: response.data.data.length === 0 ? 4 : response.data.data.length,
                    noDataText: finishedLoadingMessage,
                    loading: false,
                    finishedLoading: true,
                });
            }
        })
    }


     onSelectMenu(event, dataParam){
        
        switch(event) {
            case "Send Money":
                this.props.history.push('/moneytransfer/transaction', {mobile: this.props.mobile, data:dataParam, maxLimit:(typeof(this.props.data.limit) === 'undefined'?
                    ((this.props.data.bank1_limit+this.props.data.bank2_limit)>25000?25000:(this.props.data.bank1_limit+this.props.data.bank2_limit))
                    :this.props.data.limit), rimitterData:this.props.data});

                    // this.props.history.push('/moneytransfer/transaction/', {mobile: this.props.mobile, data:dataParam, maxLimit:(typeof(this.props.data.limit) === 'undefined'?
                    // ((this.props.data.bank1_limit+this.props.data.bank2_limit+(dataParam.paytm ? this.props.data.bank3_limit:0))>25000?25000:(this.props.data.bank1_limit+this.props.data.bank2_limit+(dataParam.paytm ? this.props.data.bank3_limit:0)))
                    // :this.props.data.limit), rimitterData:this.props.data});
                break;
            case "Delete Beneficiary":
                this.deleteBeneficiary(dataParam.bene_id);
                break;
        }
    }

    deleteBeneficiary(bene_id) {
        this.setState({isAlertPrompt: true});
        this.setState({alertCallback: this.deleteBeneficiaryHelper});
        this.setState({alertCallbackParam: {bene_id:bene_id, mobile: this.props.mobile}});
        this.setState({alertMsg: 'Are you sure, you want to delete this beneficiary?'});
    }

    deleteBeneficiaryHelper=(buttonText, alertCallbackParam) =>{
        if(buttonText == 'YES') {
            apiCall(WebApi.deleteDMTBeneficiary, {beneid:alertCallbackParam.bene_id, mobile: alertCallbackParam.mobile}, (response) => {
                if(response.success === true && response.data){
                    if(response.data.status === true) {
                        this.tableLoader();

                        this.setState({isAlertPrompt: true});
                        this.setState({alertCallback: null});
                        this.setState({alertMsg: response.data.message});
                    } else {
                        this.setState({isAlertPrompt: true});
                        this.setState({alertCallback: null});
                        this.setState({alertMsg: response.data.message});
                    }
                } else {
                    alert(response.message);
                    
                }
            });
        } else if(buttonText == 'NO') {
        }
    }

     dropDownMenu = [
        "Send Money","Delete Beneficiary"
    ];
   
    render() {
        const BeneficiaryTable = styled(ReactTable)`
            margin-top: 50px;
        `
        
        return (
            <div className="bene-list-root">
            <style>{`
            .bene-list-root {
                width: 100%;
            }
            @media all and (max-width: 959px) {
                .bene-list-root {
                    padding: 20px;
                    overflow: scroll;
                    width: 100vw;
                }
            }
            `}</style>
                <OaAlert isPrompt={this.state.isAlertPrompt} callback={this.state.alertCallback} callbackParam={this.state.alertCallbackParam} msg={this.state.alertMsg} togglePrompt={() => this.setState({isAlertPrompt: !this.state.isAlertPrompt})}></OaAlert>
                <BeneficiaryTable
                    defaultFilterMethod={this.filterMethod}
                    data={this.state.data}
                    filterable={true}
                    columns={[
                    {
                            Header: "Beneficiary Name",
                            accessor: "name",
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>
                                    {props.value}
                                </div>
                            ),
                            width: 250
                        },     {
                            Header: "Action",
                            accessor: "bene_id",
                            width: 150  ,
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <CustomDropdown
                                        buttonText ={'DMT-' + props.value}
                                        buttonProps={{
                                            fullWidth: true,
                                            style: { height: 32,padding:4 ,width:'100%'},
                                            color: "rose",
                                            size:"large"
                                        }}
                                        onClick={(event) => {
                                            this.onSelectMenu(event, props.original);
                                        }}
                                        dropPlacement="top-start"
                                        dropdownList={this.dropDownMenu}
                                    />
                                </div>
                        )
                        },{
                            Header: "Bank Name",
                            accessor: "bankname",
                            Cell: props => (
                                <div style={{height: 44,display: 'table-cell',verticalAlign:'middle'}}>
                                    {props.value}
                                </div>
                            ),

                        },{
                            Header: "Account Number",
                            accessor: "accno",
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                        {props.value}
                                    </div>
                                </div>
                            ),
                            width: 250
                        }, {
                            Header: "IFSC",
                            accessor: "ifsc",
                            width: 150  ,
                            Cell: props => (
                                <div style={{display: 'table-cell',verticalAlign:'middle',height: 44}}>
                                    <div style={{display: 'inline-block'}}>
                                        {props.value}
                                    </div>
                                </div>
                            )
                        },
                    
                    ]}
                    showPagination={false}
                    minRows={this.state.rowCount}
                    loading= {this.state.loading}
                    pageSize={this.state.rowCount}
                    className="-striped -highlight"
                    getTdProps={this.onRowClick}
                    noDataText={this.state.noDataText}
                />
                <style>{`
                .ReactTable {
                    margin-top: 10px;
                }
                .rt-tbody {
                    height: 160px;
                    overflow: auto;
                }
                `}</style>
            </div>
        )
    }
}
export default withRouter(BeneficiaryListTable);
