import React, { useState } from "react";
import { Popper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .oa-form-element-card": {
      border: `solid 1px ${theme.palette.primary.disabled}`,
      width: "100%",
      padding: 6,
      borderRadius: 5,
      overflow: "hidden",
    },
    "& .oa-form-element-label": {
      color: theme.palette.primary.darkGray,
      fontWeight: 400,
      [theme.breakpoints.down(1500)]: {
        fontSize: "12px !important",
      },
    },
    "& .oa-form-element-primary": {
      marginTop: -2,
    },
    "& .oa-form-element-secondary": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: theme.palette.primary.darkGray,
      fontWeight: 400,
      [theme.breakpoints.down(1500)]: {
        fontSize: "12px !important",
      },
      [theme.breakpoints.down(630)]: {
        whiteSpace: "normal",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "26px !important",
      fontWeight: 400,
      color: theme.palette.primary.darkText,
      [theme.breakpoints.down(1500)]: {
        fontSize: "22px !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      overflowY: "auto !important",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#000",
        borderRadius: 8,
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: "#000",
        },
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child": {
      padding: "2px 0",
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      paddingRight: 28,
      //   marginLeft: 10,
    },
    "& .ac-options-list": {
      overflow: "hidden",
      width: "100%",
      "& p": {
        marginBottom: 0,
        color: theme.palette.primary.darkFadedText,
        whiteSpace: "nowrap",
      },
      "& span": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        flexWrap: "nowrap",
        "& .ac-option-left": {
          textAlign: "left",
          color: theme.palette.primary.darkText,
          fontWeight: 600,
          flexGrow: 1,
        },
        "& .ac-option-right": {
          textAlign: "right",
          color: theme.palette.primary.darkFadedText,
        },
      },
    },
  },
}));

const OaOutlinedAutoComplete = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="oa-form-element-card">
        <div className="oa-form-element-label">
          <span>{props.labeltxt}</span>
        </div>
        <div className="oa-form-element-primary">
          <Autocomplete {...props} />
        </div>
        <div className="oa-form-element-secondary">{props.secondarytxt}</div>
      </div>
    </div>
  );
};

export default OaOutlinedAutoComplete;
