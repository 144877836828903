import React, { useState } from "react";
import { Popper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import CustomDatePicker from "oahoc/CustomDatePicker";

const OaOutlinedDatePicker = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className="oa-form-element-card">
                <div className="oa-form-element-label">
                    <span>{props.labeltxt}</span>
                </div>
                <div className="oa-form-element-primary">
                    {props.disabledtxt != undefined && props.disabledtxt != null && props.showinput == false ?
                    <div className="disabled-text">
                        {props.disabledtxt}
                    </div> :
                    <CustomDatePicker {...props}/>}
                </div>
                <div className="oa-form-element-secondary">
                    {props.secondarytxt}
                </div>
            </div>
        </div>
    );
}

export default OaOutlinedDatePicker;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& .oa-form-element-card": {
            border: `solid 1px ${theme.palette.primary.disabled}`,
            width: "100%",
            padding: 6,
            borderRadius: 5,
            overflow: "hidden",
            display: 'flex',
            flexDirection: 'column',
        },
        "& .oa-form-element-label": {
            color: theme.palette.primary.darkGray,
            fontWeight: 400,
            height: '26.5%',
            [theme.breakpoints.down(1500)]: {
                fontSize: '12px !important',
            },
        },
        "& .oa-form-element-primary": {
            marginTop: -4,
            height: '47%',
        },
        "& .oa-form-element-secondary": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: theme.palette.primary.darkGray,
            fontWeight: 400,
            height: '26.5%',
            [theme.breakpoints.down(1500)]: {
                fontSize: '12px !important',
            },
        },
        "& .disabled-text":{
            margin: "21px 0 21px",
            width: 160,
            [theme.breakpoints.down(1500)]: {
                margin: '18px 0',
            },
            [theme.breakpoints.down(630)]: {
                margin: '14px 0 15px',
            },
        },
        "& .MuiInputBase-input": {
            fontSize: 26,
            fontWeight: 400,
            color: theme.palette.primary.darkText,
            padding: "3px 0 2px",
            [theme.breakpoints.down(1500)]: {
                fontSize: 22,
            },
        },
        "& .MuiInput-underline:before": {
            borderBottom: 'none',
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: 'none',
        },
        "& .MuiInput-underline:after": {
            borderBottom: 'none',
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child": {
            padding: "2px 0",
        },
        "& .react-datepicker-popper": {
            zIndex: 9,
        },
    }
}));
