import React, { useEffect, useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Grid, Typography } from "@material-ui/core";
import { COLORS } from "assets/css/CssConstants";
import { makeStyles } from "@material-ui/core/styles";
import { currencyFormatter } from 'oautils/oaCommonUtils';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.active,
    margimTop:-25,
  },
}));

const FareBreakupCard = ({ flightDetails, adult, child, infant }) => {
  // console.log("flightDetails", flightDetails);
  const classes = useStyles();
  const [basefare, setBasefare] = useState(0.00);
  const [taxes, setTaxes] = useState(0.00);
  const [otherCharges, setOtherCharges] = useState(0.00);

  useEffect(() => {
    // console.log("flightDetails", flightDetails);
    setBasefare(
      flightDetails.farebreakup.adultFarebreakup.basefare * adult +
      flightDetails.farebreakup.childFarebreakup.basefare * child +
      flightDetails.farebreakup.infantFarebreakup.basefare * infant
    );
    setTaxes(
      flightDetails.farebreakup.adultFarebreakup.tax * adult +
      flightDetails.farebreakup.childFarebreakup.tax * child +
      flightDetails.farebreakup.infantFarebreakup.tax * infant
    );
    setOtherCharges(
      flightDetails.farebreakup.adultFarebreakup.otherCharges * adult +
      flightDetails.farebreakup.childFarebreakup.otherCharges * child +
      flightDetails.farebreakup.infantFarebreakup.otherCharges * infant
    );
  }, []);

  const totalAmount = () => {
    let sum = 0;
    sum =
      sum +
      flightDetails.adultTotalfare * adult +
      flightDetails.childTotalFare * child +
      flightDetails.infantTotalfare * infant +
      flightDetails.farebreakup.airportTax +
      flightDetails.farebreakup.cuteCharges;
    return sum.toFixed(2);
  };

  return (
    <Card style={{ borderRadius: 0, marginTop: 0, marginLeft:"2px" }}>
      <CardBody>
        <Grid container spacing={2}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.heading}>
                Fare Breakup
              </Typography>
            </Grid>
            <hr style={{ width: "100%" }} />
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography>Base Fare</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                <Typography>=</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography>{currencyFormatter(basefare)}</Typography>
              </Grid>
            </Grid>
            {/* {adult>0 && <Grid
               container
               item
               direction="row"
               justifyContent="space-between"
               alignItems="center"
             >
               <Grid item xs={6} style={{ textAlign: "left" }}>
                 <Typography>Adult</Typography>
               </Grid>
               <Grid item xs={2} style={{ textAlign: "center" }}>
                 <Typography>=</Typography>
               </Grid>
               <Grid item xs={4} style={{ textAlign: "right" }}>
                 <Typography>{`${flightDetails.adultTotalfare} X ${adult}`}</Typography>
               </Grid>
             </Grid> }
            {child >0 && <Grid
               container
               item
               direction="row"
               justifyContent="space-between"
               alignItems="center"
             >
               <Grid item xs={6} style={{ textAlign: "left" }}>
                 <Typography>Child</Typography>
               </Grid>
               <Grid item xs={2} style={{ textAlign: "center" }}>
                 <Typography>=</Typography>
               </Grid>
               <Grid item xs={4} style={{ textAlign: "right" }}>
                 <Typography>{`${flightDetails.childTotalFare} X ${child}`}</Typography>
               </Grid>
             </Grid>}
             {infant >0 && <Grid
               container
               item
               direction="row"
               justifyContent="space-between"
               alignItems="center"
             >
               <Grid item xs={6} style={{ textAlign: "left" }}>
                 <Typography>Infant</Typography>
               </Grid>
               <Grid item xs={2} style={{ textAlign: "center" }}>
                 <Typography>=</Typography>
               </Grid>
               <Grid item xs={4} style={{ textAlign: "right" }}>
                 <Typography>{`${flightDetails.infantTotalfare} X ${infant}`}</Typography>
               </Grid>
             </Grid> } */}
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography>Taxes</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                <Typography>=</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography>{currencyFormatter(taxes)}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography>Other charges</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                <Typography>=</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography>{currencyFormatter(otherCharges)}</Typography>
              </Grid>
            </Grid>


            <hr style={{ width: "100%" }} />
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography>Amount</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                <Typography>=</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography>{`${totalAmount()}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default FareBreakupCard;