import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@material-ui/core";
import WebApi, {
  POST_PAYGOAL_API_CALLBACK,
  EXT_REDIRECT_SERVER,
} from "api/ApiConstants";
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem } from "oautils/oaForageUtils";
import { submitDataToPaygoal } from "../paymentgateway/Paygoal";
import { submitDataToRazorpay } from "../paymentgateway/Razorpay";
import { submitDataToCcAvenue } from "pages/module/paymentgateway/CcAvenue";
import { submitDataToPayU } from "pages/module/paymentgateway/PayU";
import InsurancePaymentOptions from "./InsurancePaymentOptions";
//import InsurancePaymentOptions from "./InsurancePaymentOptionsOld";
import OaLoading from "pages/components/OaLoading";

const InsuranceReview = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [userType, setUserType] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    null
  );
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState(null);
  const [selectedPaymentModeName, setSelectedPaymentModeName] = React.useState(
    null
  );
  const [selectedPaymentGateway, setSelectedPaymentGateway] = React.useState(
    null
  );
  const [paymentGateway, setPaymentGateway] = useState();
  const [paymentModes, setPaymentModes] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);

  const [expanded, setExpanded] = useState("panel1");
  const [payModeTabIndex, setPayModeTabIndex] = React.useState(0);
  const [userDetails, setUserDetails] = useState();
  const [travDetail, setTravDetails] = useState();
  const [trnId, setTrnId] = useState();
  const [trnType, setTrnType] = useState();

  const [totalAmt, setTotalAmt] = useState();
  const [amountDue, setAmountDue] = useState();

  const [clientType, setClientType] = React.useState(null);
  const [agentDetails, setAgentDetails] = useState();
  const [pymtOnProcess, setPymtOnProcess] = React.useState(false);

  const {
    values: propData,
    result: planData,
    request,
    session_id,
  } = props.history.location.state;
  const [grandTotal, setGrandTotal] = useState();

  // EXPAND PASSENGER INFO OR NO
  const handleChangee = (panel, event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // setGrandTotal(
    //   planData.premuimList[0].publishPrice -
    //     planData.premuimList[0].commissionCharge.agentIncome +
    //     planData.premuimList[0].commissionCharge.agentTDSOnIncome
    // );

    setGrandTotal(planData.premuimList[0]?.commissionCharge.grossProductPrice);
  }, []);

  // PAYMENT GATEWAYS API CALL
  useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-details", function(err, value) {
        if (value) {
          setUserType(value.userType ? value.userType : "b2c_user");
          setAgentDetails(value);

          setClientType(value.userTypeAbv);

          setTrnType("insurance-booking");
          apiCall(
            WebApi.getCompanyPGOptions,
            {
              partnerId: value.partnerId,
              serviceId: 4,
            },
            (response) => {
              // console.log("##### response.data ###")

              // console.log(response.data);
              response.data.map((value) => {
                value.gateway &&
                  value.gateway.map((data) => {
                    if (data.selected === true) {
                      // console.log(data.method);
                      setSelectedPaymentMethod(data.method);
                    }
                  });
              });
              setPaymentModes(response.data);
            }
          );
        }
      });
    }
  }, []);

  async function showPaygoalWindow(trnResponse) {
    let request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      trnType: "insurance-booking",
      // redirectUrl: `http://localhost:8084/http://oari.co:8080/insurance/booking/${trnResponse.transactionId}`,
      redirectUrl: EXT_REDIRECT_SERVER + "insurance/booking/",
      siteUrl: POST_PAYGOAL_API_CALLBACK,
      txnId: trnResponse.transactionId,
      failUrl: EXT_REDIRECT_SERVER + "insurance/payment-status/",
      paymentMode: trnResponse.paymentMode,
      checkoutUrl: trnResponse.paymentUrl,
    };

    submitDataToPaygoal(request);
    setPymtOnProcess(true);
  }

  async function showRazorPayWindow(data) {
    let request = {
      paymentOptions: data.pgOptions,
      trnResponse: data,
      trnType: "insurance-booking",
      selectedPaymentMethod: selectedPaymentMethod,
      theme: theme,
      checkoutUrl: data.paymentUrl,
    };

    var response = await submitDataToRazorpay(request);

    if (response.isSucess) {
      setGrandTotal(response.amountDue);
      setAmountDue(response.amountDue);
      setTrnId(response.transactionId);
      setTrnType("insurance-booking");
      submitData(response.transactionId);
    } else {
      props.history.push(`/insurance/payment-status/${response.transactionId}`);
    }
  }

  async function showCcAvenueWindow(trnResponse) {
    const request = {
      amount: grandTotal,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=ccavenue",
      siteUrl: EXT_REDIRECT_SERVER + "insurance/booking/",
      txnId: trnResponse.transactionId,
      paymentMode: "CCAvenue",
      trnType: "insurance-booking",
      failUrl: EXT_REDIRECT_SERVER + "insurance/payment-status/",
      checkoutUrl: trnResponse.paymentUrl,
    };
    submitDataToCcAvenue(request);
    setPymtOnProcess(true);
  }

  async function showPayUWindow(trnResponse) {
    const request = {
      amount: grandTotal,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=payu",
      siteUrl: EXT_REDIRECT_SERVER + "insurance/booking/",
      txnId: trnResponse.transactionId,
      paymentMode: "PayU",
      failUrl: EXT_REDIRECT_SERVER + "insurance/payment-status/",
      trnType: "insurance-booking",
      checkoutUrl: trnResponse.paymentUrl,
    };
    submitDataToPayU(request);
    setPymtOnProcess(true);
  }

  // WHEN PAYMMENT SUCCCCESFULL
  const submitData = (tid) => {
    props.history.push({
      pathname: `/insurance/booking/${tid}`,
      state: {
        // ...propState,
        tid: tid,
        trnType: trnType,
      },
    });
  };

  // SETTING TRAVELER DATA
  useEffect(() => {
    setTravDetails(propData.travelers);
  }, []);

  // CALL TRANSACATION API
  const proceedHandler = () => {
    // console.log(selectedPaymentMethod); //upi, card
    // console.log(selectedPaymentMode); //razorpay obj

    let pgOptions = selectedPaymentMode.gateway.filter(
      (fil) => fil.method === selectedPaymentMethod
    );

    // return;
    apiCall(
      WebApi.getCreateInsuranceTransaction,
      {
        clientType: clientType,
        ipAddress: "172.16.100.22",
        //loggedInUserId: loggedInUserId,
        paymentMode: selectedPaymentMode.method,
        // paymentMode: "razorpay",
        sessionId: session_id,
        premiumId: planData.premuimList[0].premiumId,
        sid: planData.sid,
        travelers: travDetail,
      },
      (response) => {
        let data = {
          status: response.message,
          session_id: session_id,
          userId: userDetails.userId,
          userType: userDetails.userType,
          deliveryAddress: {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            address: userDetails.address,
            cityName: userDetails.cityName,
            stateName: userDetails.stateName,
            postalCode: userDetails.postalCode,
            countryName: userDetails.countryName,
            landLineNumber: userDetails.landLineNumber,
            mobileNumber: userDetails.mobileNumber,
            travelerEmail: userDetails.travelerEmail,
            travelerMobileNumber: userDetails.travelerMobileNumber,
            email: userDetails.email,
          },
          travelers: travDetail,
          discontCoupon: null,
          discontCouponAmount: 0,
          convenienceFees: 0,
          requestedIpAddress: "202.168.145.55",
          paymentMode: selectedPaymentMethod,
          amountDue: response.data.amountDue,
          transactionId: response.data.txnId,
          uniqueTransactionId: response.data.encTxnId,
          pgOptions: pgOptions[0],
          paymentUrl: response.data.paymentUrl,
        };

        if (response.message === "success" && response.data.txnId != "") {
          setGrandTotal(response.data.amountDue);
          // console.log("response.amountDue " , response.data.amountDue);
          if (selectedPaymentMode.method === "cash") {
            // console.log(response.data.txnId);
            submitData(response.data.txnId);
          } else {
            displayPaymentWindow(data);
          }
        } else {
          // setInfoText("Failed to create transaction. Please retry");
          // setIsError(true);
          console.log("error creating transaction", response);
        }
      }
    );
  };

  const displayPaymentWindow = async (trnResponse) => {
    // setResponseMessage(intiatingMsg);
    if (selectedPaymentMode.method === "razorpay") {
      showRazorPayWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "paygoal") {
      showPaygoalWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "ccavenue") {
      showCcAvenueWindow(trnResponse);
    }
    if (selectedPaymentMode.method === "payu") {
      showPayUWindow(trnResponse);
    }
  };

  // SET USER DETAILS FROM LOCAL STORAGE
  useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-details", function(err, value) {
        if (value) {
          setUserType(value.userType ? value.userType : "b2c_user");
          // param.userType = value.userTypeAbv;
          setUserDetails(value);
        } else {
          console.log("failed to get user detail", err);
        }
      });
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className="insuranceReview-container">
        <Grid className="insuranceReview">
          <Grid className="insuranceReview__planDetails">
            <Grid className="plan">
              <Grid className="plansUpper">
                <Grid className="planNameContainer">
                  <Box className="planTitle">{planData.planName}</Box>
                  <Box>India</Box>
                </Grid>
                <Box className="plansDateContainer">
                  <Grid className="planDates">
                    <Box style={{ marginRight: 10 }} className="startDate">
                      Start: {planData.policyStartDate.split("T")[0]}
                    </Box>
                    <Box className="endDate">
                      End: {planData.policyEndDate.split("T")[0]}
                    </Box>
                  </Grid>
                </Box>
              </Grid>
              <Grid className="plansLower">
                <Grid className="planDetails">
                  <Box
                    onClick={() => {
                      props.history.push("/insurances");
                    }}
                    style={{ marginRight: 10 }}
                  >
                    Choose another plan
                  </Box>
                </Grid>
                <Box>No of Pax:- {request.paxCount}</Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="insuranceReview__passengersDetails">
            <Grid className="insuranceReview__passengersDetails--header">
              PASSENGER DETAILS
            </Grid>
            {propData.travelers.map((result, ind) => (
              <Accordion
                expanded={expanded === `panel${ind + 1}`}
                onChange={(event, newExpanded) =>
                  handleChangee(`panel${ind + 1}`, event, newExpanded)
                }
                key={ind}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  {" "}
                  <Grid className="insuranceReview__passenger--header">
                    PASSENGER {ind + 1}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid className="insuranceReview__passenger">
                    <Grid className="insuranceReview__passenger--container">
                      <Grid className="insuranceReview__passenger--subContainer">
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box>Insured Name:</Box>
                          <Box className="insuranceReview__passenger--value">
                            {`${result.title} ${result.firstName} ${result.lastName}`}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Relation to Insured:
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.relationShipToInsured}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            DOB:
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.dob}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            State
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.state}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            PinCode
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.pinCode}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Email
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.emailId}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid className="insuranceReview__passenger--subContainer">
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Beneficiary Name:
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {`${result.beneficiaryName} ${result.beneficiaryLastName} `}
                            {/* {result.beneficiaryName.firstName}
                          {result.beneficiaryName.lastName} */}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Relation to Beneficiary:
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.relationToBeneficiary}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Insured Gender:
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.gender}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Major Destination
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.majorDestination}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Address
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.addressLine1}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            City
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.city}
                          </Box>
                        </Grid>
                        <Grid className="insuranceReview__passenger--inputField">
                          <Box className="insuranceReview__passenger--label">
                            Mobile
                          </Box>
                          <Box className="insuranceReview__passenger--value">
                            {result.state}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
        <Grid className="detailTotal">
          <Box className="detailTotal__header">PRICE</Box>
          <Box className="detailTotal__ageDetails">
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Age Group</Box>
              <Box className="detailTotal__price">Price</Box>
            </Box>
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">
                {planData.premuimList[0].minAge} -{" "}
                {planData.premuimList[0].maxAge} Years x ({request.paxCount})
              </Box>
              <Box className="detailTotal__price">
                ₹{planData.premuimList[0].commissionCharge.grossProductPrice}
              </Box>
            </Box>
          </Box>
          <Box className="detailTotal__subTotals">
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Total</Box>
              <Box className="detailTotal__price">
                ₹
                {(
                  planData.premuimList[0].commissionCharge.grossProductPrice -
                  (planData.premuimList[0].commissionCharge.agentIncome +
                    planData.premuimList[0].commissionCharge.agentMarkup)
                ).toFixed(2)}
              </Box>
            </Box>
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Commission</Box>
              <Box className="detailTotal__price">
                ₹
                {planData.premuimList[0].commissionCharge.agentIncome +
                  planData.premuimList[0].commissionCharge.agentMarkup}
              </Box>
            </Box>
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Tds</Box>
              <Box className="detailTotal__price">
                ₹{planData.premuimList[0].commissionCharge.agentTDSOnIncome}
              </Box>
            </Box>
          </Box>
          <Box className="detailTotal__grandTotal">
            <Box className="detailTotal__label">GrandTotal</Box>
            <Box className="detailTotal__price">INR {grandTotal}</Box>
          </Box>
        </Grid>
      </div>

      <Grid
        className="insuranceReview__paymentDetails insuranceReview"
        style={{ marginTop: 10 }}
      >
        <Grid className="insuranceReview__paymentDetails--header">
          PAYMENT OPTIONS
        </Grid>
        <Grid className="insuranceReview__paymentDetails--container">
          <InsurancePaymentOptions
            paymentmodes={paymentModes}
            setselectedpaymentmode={setSelectedPaymentMode}
            isloading={isLoading}
            totalfare={grandTotal}
            data={agentDetails}
            setisloading={setIsLoading}
            selectedpaymentmethod={selectedPaymentMethod}
            setselectedpaymentmethod={setSelectedPaymentMethod}
            paymodetabindex={payModeTabIndex}
            setpaymodetabindex={setPayModeTabIndex}
          />
        </Grid>
      </Grid>
      <Grid className="insuranceReview__proceedToPay insuranceReview">
        <Button
          onClick={proceedHandler}
          className="insuranceReview__proceedToPay--btn"
        >
          Proceed to Pay
        </Button>
      </Grid>
      {pymtOnProcess && <LoaderDiv />}
    </div>
  );
};

export default InsuranceReview;

const LoaderDiv = () => {
  return (
    <div className="loader-div">
      <OaLoading loadertext="redirecting to payment gateway. please wait ..." />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "1236px",
    margin: "0 auto",
    position: "relative",
    padding: 10,
    "& .insuranceReview-container": {
      display: "flex",
      gap: "16px",
      [theme.breakpoints.down(760)]: {
        flexWrap: "wrap",
      },
    },

    "& .insuranceReview": {
      width: "80%",
      [theme.breakpoints.down(760)]: {
        width: "100%",
      },
      "& .insuranceReview__planDetails": {
        "& .plan": {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: "#f9f9f9",
          // borderRadius: 20,
          padding: 20,
          marginBottom: 5,
        },
        "& .planNameContainer": {
          [theme.breakpoints.down(600)]: {
            width: "50%",
          },
        },
        "& .plansDateContainer": {
          [theme.breakpoints.down(600)]: {
            width: "50%",
          },
        },
        "& .plansUpper": {
          display: "flex",
          marginBottom: 10,
          justifyContent: "space-between",
          "& .planTitle": {
            fontSize: 20,
            fontWeight: 700,
            color: theme.palette.primary.main,
          },
          "& .planDates": {
            display: "flex",
            fontSize: 14,
            fontWeight: 500,
            [theme.breakpoints.down(600)]: {
              flexDirection: "column",
              width: "100%",
              textAlign: "end",
            },
            // textDecoration: "underline",
            "& .startDate": {
              marginRight: 10,
              [theme.breakpoints.down(600)]: {
                marginRight: "0px !important",
              },
            },
          },
        },
        "& .plansLower": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 14,
          fontWeight: 500,

          "& .planDetails": {
            display: "flex",
            cursor: "pointer",
            textDecoration: "underline",
          },
        },
      },
      "& .insuranceReview__passengersDetails": {
        border: "1px solid #e0e0e0",
        "& .MuiAccordionSummary-expandIcon": {
          // backgroundColor: theme.palette.primary.main,
          // borderRadius: 0,
        },
        "& .MuiIconButton-root": {
          padding: 8.5,
          position: "absolute",
          right: 20,
        },
        "& .MuiAccordion-root": {
          width: "100%",
          margin: 0,
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "5px 0px",
          },

          "& .MuiAccordionSummary-root": {
            padding: 0,
            height: 50,
          },
          "& .MuiAccordionDetails-root": {
            padding: 0,
          },
        },
        "& .insuranceReview__passengersDetails--header": {
          fontSize: 16,
          fontWeight: 500,
          border: "1px solid rgba(0,0,0,0.125)",
          boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
          padding: 10,
          color: theme.palette.text.secondary,
          // backgroundColor: theme.palette.primary.main,
        },
        "& .insuranceReview__passenger--header": {
          width: "100%",
          fontSize: 16,
          padding: "10px 20px",
          fontWeight: 500,
          // backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.secondary,
          borderBottom: "2px solid rgba(0,0,0,0.125)",
          boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
          borderTop: "2px solid rgba(0,0,0,0.125)",
        },
        "& .insuranceReview__passenger": {
          //   display: "flex",
          marginTop: 10,
          width: "100%",

          "& .insuranceReview__passenger--container": {
            display: "flex",
            margin: 20,
            backgroundColor: theme.palette.background.overlay,
            borderRadius: 5,
            "& .insuranceReview__passenger--subContainer": {
              padding: 20,
              width: "50%",
              "& .insuranceReview__passenger--inputField": {
                marginBottom: 10,
                "& .insuranceReview__passenger--label": {},
                "& .insuranceReview__passenger--value": {
                  fontSize: 16,
                  fontWeight: 500,
                  wordWrap: "break-word",
                },
              },
            },
          },
        },
      },
    },
    "& .detailTotal": {
      // marginLeft: 20,
      //   backgroundColor: "#434",
      width: "20%",
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      [theme.breakpoints.down(760)]: {
        width: "100%",
      },
      height: "100%",
      // border: "1px solid #e0e0e0",
      "& .detailTotal__header": {
        fontSize: 17,
        fontWeight: 500,
        // backgroundColor: theme.palette.primary.main,
        padding: 10,
        color: theme.palette.text.secondary,
        borderBottom: "2px solid rgba(0,0,0,0.125)",
        // borderTop: "2px solid rgba(0,0,0,0.125)",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
        padding: 10,
      },
      "& .detailTotal__label": {
        fontSize: 16,
        fontWeight: 500,
      },
      "& .detailTotal__price": {
        fontSize: 16,
        fontWeight: 500,
      },
      "& .detailTotal__subTotals": {
        padding: "5px 10px",
        backgroundColor: "#f9f9f9",
      },
      "& .detailTotal__ageDetails": {
        padding: "5px 10px",
        backgroundColor: "#fff",
      },
      "& .detailTotal__subTotal": {
        fontSize: 14,
        display: "flex",
        marginTop: 5,
        justifyContent: "space-between",
      },
      "& .detailTotal__subTotal": {
        fontSize: 14,
        display: "flex",
        marginTop: 5,
        justifyContent: "space-between",
      },
      "& .detailTotal__grandTotal": {
        fontSize: 16,
        fontWeight: 500,
        display: "flex",
        padding: 10,
        justifyContent: "space-between",
        backgroundColor: "#e0e0e0",
      },
    },

    "& .insuranceReview__paymentDetails": {
      border: "1px solid #e0e0e0",
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      width: "79%",
      [theme.breakpoints.down(1300)]: {
        width: "78.8%",
      },
      [theme.breakpoints.down(1150)]: {
        width: "78.7%",
      },
      [theme.breakpoints.down(900)]: {
        width: "78.5%",
      },
      [theme.breakpoints.down(860)]: {
        width: "78%",
      },

      "& .insuranceReview__paymentDetails--header": {
        width: "100%",
        fontSize: 16,
        padding: "10px 20px",
        fontWeight: 500,

        // backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        borderBottom: "2px solid rgba(0,0,0,0.125)",
        // borderTop: "2px solid rgba(0,0,0,0.125)",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      },
      "& .insuranceReview__paymentDetails--container": {
        padding: 20,
        // display: "flex",
        "& .insuranceReview__radio--header": {
          fontSize: 16,
          fontWeight: 500,
          marginTop: 35,
        },
        "& .insuranceReview__radio": {
          paddingLeft: 20,
        },
      },
    },
    "& .insuranceReview__proceedToPay": {
      display: "flex",
      justifyContent: "flex-end",
      width: "79%",
      [theme.breakpoints.down(1300)]: {
        width: "78.8%",
      },
      [theme.breakpoints.down(1150)]: {
        width: "78.7%",
      },
      [theme.breakpoints.down(900)]: {
        width: "78.5%",
      },
      [theme.breakpoints.down(860)]: {
        width: "78%",
      },

      "& .insuranceReview__proceedToPay--btn": {
        marginTop: 5,
        fontSize: 15,
        fontWeight: 500,
        background: theme.palette.buttons.bookNowBtn,
        color: theme.palette.primary.lightText,
      },
    },
    "& .loader-div": {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      background: "rgba(0, 0, 0, 0.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiCircularProgress-colorPrimary": {
        "& svg": {
          color: theme.palette.secondary.main,
        },
      },
      "& .loading-text": {
        color: theme.palette.secondary.main,
      },
    },
  },
  tabDiv: {
    "& .MuiTab-textColorInherit": {
      background: `${theme.palette.primary.disabled} !important`,
      color: `${theme.palette.primary.darkText} !important`,
      borderRadius: "8px 8px 0 0",
      "&.Mui-selected": {
        background: `${theme.palette.background.primary} !important`,
        color: `${theme.palette.primary.lightText} !important`,
      },
    },
  },
}));
