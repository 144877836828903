import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import image1 from "assets/img/geebaku-loading.gif";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
// import { AppStateContext } from "layouts/AppStateProvider";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import image1 from "assets/rive/hotelanimation.riv";
import Rive from "rive-react";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import {
  setHotelSearchResult,
  setTimerInitialMinute,
  setTimerInitialSecond,
} from "redux/action";

import OaFormAlerts from "pages/components/OaFormAlerts";
import ajaxLoader from "../../../assets/insurance/ajax-loader.gif";
import { localforageGetItem } from "oautils/oaForageUtils";

const InsuranceBookingProcess = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState();

  const params = useParams();
  let { tid } = params;

  console.log(tid);

  useEffect(() => {
    localforageGetItem("user-details", function (err, value) {
      if (err == null && value != null) {
        setLoggedInUserId(value.userId);
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      WebApi.getInsuranceBookingDetails(
        {
          tid: tid,
          EndUserIp: "",
          TokenId: "",
          TraceId: "",
          bookingId: 0,
          loggedInUserId: loggedInUserId,
        },
        (response) => {
          if (response.message == "success") {
            props.history.push({
              pathname: `/insurance/booking/payment-success`,
              state: {
                // ...propState,
                data: response.data,
                tid: tid,
              },
            });
          } else {
            props.history.push({
              pathname: "/insurance/booking/payment-failed",
              state: {
                // ...propState,
                data: response.data,
                tid: tid,
              },
            });
          }
        },
        (error) => console.log("error", error)
      );
    }, "7000");

    // return () => {
    //   clearInterval(intervalID);
    // };
  }, []);

  return (
    <GridContainer style={{ height: "60vh" }}>
      <GridItem md={12}>
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
      </GridItem>
      <GridItem
        md={12}
        style={{
          height: "20vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          backgroundColor: "transparent",
        }}
      >
        {/* <img src={ajaxLoader}/> */}
        <Rive src={image1} />
      </GridItem>
      <GridItem
        md={12}
        style={{
          height: "20vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          backgroundColor: "transparent",
        }}
      >
        <GridContainer
          justifyContent="center"
          style={{
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          Insurance Getting Booked...
        </GridContainer>
      </GridItem>
      {/* {props.hotelSearch.departureCityId} {props.hotelSearch.destinationCityId} */}
    </GridContainer>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelSearchResult: (hotelSearchResult) =>
      dispatch(setHotelSearchResult(hotelSearchResult)),
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InsuranceBookingProcess)
);
