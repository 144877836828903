import React, { useEffect, useState } from "react";


import { Box, Grid, makeStyles, AppBar, Tabs, Tab } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getQueryVar, getCurrentTab } from "oautils/oaCommonUtils.js";
import { setUserType, usertype } from "redux/action";
import WebApi from "api/ApiConstants";
import {
    localforageSetItem,
    localforageClear,
    localforageGetItemAsync,
} from "oautils/oaForageUtils";
import FlightRoundedIcon from "@material-ui/icons/FlightRounded";
import DirectionsBusRoundedIcon from "@material-ui/icons/DirectionsBusRounded";
import ApartmentRoundedIcon from "@material-ui/icons/ApartmentRounded";
import { COLORS } from "assets/EasyTravelsOnline/css/CssConstants";
import FlightSection from "./GlassmorphicSearchBoxComponents/FlightSection";
import BusSection from "./GlassmorphicSearchBoxComponents/BusSection";
import HotelSection from "./GlassmorphicSearchBoxComponents/HotelSection";
import VisaSection from "./GlassmorphicSearchBoxComponents/VisaSection";
import VisaRoundedIcon from "@material-ui/icons/DescriptionRounded";
import { MonetizationOnRounded } from "@material-ui/icons";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import DMTSection from "./GlassmorphicSearchBoxComponents/DMTSection"

const useStyles = makeStyles(theme => ({
    searchboxroot: {},

    tabWrapper: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .tab-head": {
            position: "absolute",
            top: -74,
            left: 0,
            right: 0,
            "& .tab-head-appbar": {
                background: "none",
                boxShadow: "none"
            },
            "& .MuiTabs-indicator": {
                display: "none",
            },
            "& .MuiTabs-flexContainer": {
                justifyContent: "center",
                "& .MuiTab-root": {
                    [theme.breakpoints.down(826)]: {
                        minWidth: "75px !important",
                    },
                    [theme.breakpoints.down(800)]: {
                        border: "none !important",
                    },
                    [theme.breakpoints.down(440)]: {
                        minHeight: "55px !important",
                        minWidth: "46px !important",
                    },
                },
            },
            "& .MuiTab-root.MuiButtonBase-root": {
                backgroundColor: COLORS.BTN_BG_LIGHT,
                color: COLORS.PRIMARY_COLOR,
                margin: "1px 0.7px 1px",
                width: 150,
                [theme.breakpoints.down(826)]: {
                    width: 75,
                },
                [theme.breakpoints.down(440)]: {
                    width: 46,
                    margin: "18px 0.7px 1px",
                    "& .MuiTab-wrapper": {
                        fontSize: 10,
                        "& .MuiSvgIcon-root": {
                            fontSize: 16,
                        },
                    }
                },
            },
            "& .MuiTab-textColorSecondary.Mui-selected": {
                backgroundColor: COLORS.BTN_BG_PRIMARY,
                color: COLORS.DEFAULT_TEXT_LIGHT,
            },
        },
        "& .tab-content-body": {
            width: "100%",
            position: "relative",
            "& .tab-content-wrapper-bg": {
                background: COLORS.DARK_BG_COLOR,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            "& .tab-content-wrapper": {
                background: COLORS.PRIMARY_BG_LIGHT,
                padding: "5px 0",
                // margin: "0 5vw",
                maxWidth: 1280,
            }
        }
    }

}));

const tabPanelUseStyles = makeStyles((theme) => ({
    tabpanel_box: {
        [theme.breakpoints.up(600)]: {
            margin: "5px",
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const tabPanelClasses = tabPanelUseStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={tabPanelClasses.tabpanel_box}>{children}</Box>
            )}
        </div>
    );
}

const GlassmorphicSearchBox = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const defaultTab = "flight";
    const queryVar = getQueryVar({ ...props, q: "priTab" });
    const currentTabName = getCurrentTab(queryVar, defaultTab);
    const [isLoading, setIsLoading] = useState(false);
    const userTypeInfo = useSelector((state) => state.usertype);

    const getCurrentTabIndex = (key) => {
        switch (key) {
            case "flights":
                return 0;

            case "hotels":
                return 1;

            case "buses":
                return 2;

            case "moneytransfer":
                return 4;

            case "visas":
                return 3;

            default:
                return 0;
        }
    };

    useEffect(() => {
        (async () => {
            const userDetails = await localforageGetItemAsync("user-details");

            if (!userDetails) {
                setIsLoading(true);
                // console.log("##### user-details not found ##");
                WebApi.getAgentInfo({ userType: "G" }, (response) => {
                    if (response != null && response.success === true) {
                        localforageClear(() => {
                            localforageSetItem("user-id", 0);

                            localforageSetItem("user-details", response.data, () => {
                                dispatch(setUserType(response.data));
                                localforageSetItem("access-key", response.accesskey, () => {
                                    setIsLoading(false);
                                });
                            });
                        });
                    }
                });
            } else {
                dispatch(setUserType(userDetails));
            }
        })();
    }, [isLoading]);

    const [inputData, setInputData] = useState({
        currentTab: getCurrentTabIndex(currentTabName),
    });

    return (
        <div className={classes.searchboxroot}>
            <div className={classes.tabWrapper}>
                <div className="tab-head">
                    <AppBar
                        position="static"
                        color="default"
                        className="tab-head-appbar"
                    >
                        <Tabs
                            value={inputData.currentTab}
                            onChange={(e, i) => {
                                setInputData({ ...inputData, currentTab: i });
                                if (i == 0) props.history.push("/landingpage/flights");
                                if (i == 1) props.history.push("/landingpage/hotels");
                                if (i == 2) props.history.push("/landingpage/buses");
                                if (i == 3) props.history.push("/landingpage/visas");
                                if (i == 4) props.history.push("/landingpage/moneytransfer");
                            }}
                            indicatorColor="secondary"
                            textColor="secondary"
                            className={classes.tabs}
                        // TabIndicatorProps={}
                        >
                            <Tab label="Flights" icon={<FlightRoundedIcon />} />
                            <Tab label="Hotels" icon={<ApartmentRoundedIcon />} />
                            <Tab label="Bus" icon={<DirectionsBusRoundedIcon />} />
                            <Tab label="Visa" icon={<VisaRoundedIcon />} />
                            {
                                userTypeInfo.partnerId === 1002 &&
                                <Tab label="DMT" icon={<MonetizationOnRounded />} />
                            }
                        </Tabs>
                    </AppBar>
                </div>

                <div className="tab-content-body">
                    <div className="tab-content-wrapper-bg">
                        <div className="tab-content-wrapper">
                            <TabPanel value={inputData.currentTab} index={0}>
                                <FlightSection />
                            </TabPanel>
                            <TabPanel value={inputData.currentTab} index={1}>
                                <HotelSection />
                            </TabPanel>
                            <TabPanel value={inputData.currentTab} index={2}>
                                <BusSection />
                            </TabPanel>
                            <TabPanel value={inputData.currentTab} index={3}>
                                <VisaSection />
                            </TabPanel>
                            {
                                userTypeInfo.partnerId === 1002 &&
                                <TabPanel value={inputData.currentTab} index={4}>
                                    <DMTSection {...props}/>
                                </TabPanel>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default GlassmorphicSearchBox;

const airlinesList = [
    { val: "all", text: "All" },
    { val: "gds-only", text: "GDS Only" },
    { val: "lcc-only", text: "LCC Only" },
    { val: "air-asia", text: "Air Asia" },
];
