import React, {useState, useEffect} from 'react';
import OaCard from 'oahoc/OaCard';
import RefundForm from './RefundForm';

const Refund = (props) => {
    useEffect(() =>{

    },[]);
    return (
        <OaCard >
            <RefundForm {...props}/>
        </OaCard>
    )
}

export default Refund;
