import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AirAsia from "assets/img/AirAsiaThumbnail.jpg";
import AirIndia from "assets/img/AirIndiaThumbnail.JPG";
import GoAir from "assets/img/GoAirThumbnail.JPG";
import Indigo from "assets/img/IndiGoThumbnail.JPG";
import SpiceJet from "assets/img/SpiceJetThumbnail.JPG";
import Vistara from "assets/img/VistaraThumbnail.JPG";
import AkasaAir from "assets/img/AkasaAir.png";
import AllianceAir from "assets/img/AllianceAir.png";
import FlyBig from "assets/img/FlyBig.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    heading: {
        fontWeight: 600,
        fontSize: 17,
        textAlign: "center",
        marginTop: "25px",
    },
    flight: {
        fontWeight: 600,
        fontSize: 17,
        textAlign: "center",
        marginBottom: 20,
    },
    rootContainer:{
        padding: "15px 10%",
        justifyContent: "space-evenly",
    },
    columns:{
        margin: "15px",
        padding:"15px"
    },
});

const WebCheckIn = () => {
    const classes = useStyles();
    return (
        <Grid container style={{ marginTop: 65, padding: "15px 5%" }}>
            <Grid item xs={12}>
                <Typography className={classes.heading}>Web Check-in</Typography>
                <Grid container className={classes.rootContainer}>
                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://www.airasia.com/check-in/en/gb"
                            target="_blank"
                        >
                            {" "}
                            <div >
                                <img src={AirAsia} width={230} height={100} />

                                <Typography className={classes.flight}>
                                    Air Asia Web Check-in
                                </Typography>
                            </div>
                        </a>
                    </Grid>

                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}> 
                        <a
                            rel="noopener noreferrer"
                            href="https://www.airindia.in/web-check-in.htm"
                            target="_blank"
                        >
                            {" "}  <div >
                                <img src={AirIndia} width={230} height={100} />
                                <Typography className={classes.flight}>
                                    Air India Web Check-in
                                </Typography></div> </a>

                    </Grid>


                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://www.goair.in/plan-my-trip/web-check-in"
                            target="_blank"
                        ><div >
                                <img
                                    src={GoAir}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                    GoAir Web Check-in
                                </Typography></div></a>

                    </Grid>


                    {" "}
                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://www.goindigo.in/how-to-check-in.html"
                            target="_blank"
                        ><div> 
                                <img
                                    src={Indigo}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                    Indigo Web Check-in
                                </Typography>
                            </div></a>
                    </Grid>



                    {" "}
                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}> 
                        <a
                            rel="noopener noreferrer"
                            href="https://book.spicejet.com/SearchWebCheckin.aspx"
                            target="_blank"
                        > <div >
                                <img
                                    src={SpiceJet}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                    SpiceJet Web Check-in
                                </Typography>
                            </div></a>

                    </Grid>


                    {" "}
                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://www.airvistara.com/in/en"
                            target="_blank"
                        > <div>
                                <img
                                    src={Vistara}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                    Vistara Web Check-in
                                </Typography>
                            </div></a>
                    </Grid>


                    {" "}
                    <Grid  className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://www.akasaair.com/in/en"
                            target="_blank"
                        > <div > 
                                <img
                                    src={AkasaAir}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                   Akasa Air Web Check-in
                                </Typography>
                            </div></a>
                    </Grid>


                    {" "}
                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://flybig.paxlinks.com/web-checkin"
                            target="_blank"
                        > <div >
                                <img
                                    src={FlyBig}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                   FlyBig Web Check-in
                                </Typography>
                            </div></a>
                    </Grid>


                    {" "}
                    <Grid className={classes.columns} item xs={12} md={4} sm={12} lg={3}>
                        <a
                            rel="noopener noreferrer"
                            href="https://www.allianceair.in/en"
                            target="_blank"
                        > <div >
                                <img
                                    src={AllianceAir}
                                    width={230}
                                    height={100}
                                    style={{ marginLeft: "7%" }}
                                />
                                <Typography className={classes.flight}>
                                Alliance Air Web Check-in
                                </Typography>
                            </div></a>
                    </Grid>


       
                </Grid>
            </Grid>
        </Grid>
    );
};

export default WebCheckIn;
