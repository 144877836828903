import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import axios from "axios";
import { localforageGetItem } from "oautils/oaForageUtils";

export const getImageData = (elementId, orientation, callback) => {
  let element = document.getElementById(elementId);
  let width = element.offsetWidth;
  let height = element.offsetHeight;
  let imgData = null;

  let paperWidth = orientation === "p" ? 760 : 1060;
  let scale = paperWidth / width; // scale image to width that fits in page
  html2canvas(element, {
    scale: 1.5,
    allowTaint: true,
    useCORS: true,
  }).then((canvas) => {
    imgData = canvas.toDataURL("image/png", 1.0);
    callback(imgData);
  });
};

export const pdfDownload = (elementId, orientation, filename) => {
  getImageData(elementId, orientation, function(imageData) {
    let pdf = new jsPDF(orientation, "mm");
    pdf.addImage(imageData, "PNG", 0, 0, 200, 180);
    pdf.save(filename + ".pdf");
  });
};

export const pdfWsDownload = (apiUrl, searchParams, defaultFileName) => {
  localforageGetItem("user-id", function(err, value) {
    if (value) {
      searchParams.loggedInUserId = value;
      axios({
        method: "POST",
        url: apiUrl,
        data: searchParams,
        responseType: "blob",
      }).then((response) => {
        let fileName = response.headers["content-disposition"]
          ? response.headers["content-disposition"].split("filename=")[1]
          : defaultFileName;
        const tempurl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = tempurl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
        }, 2000);
      });
    } else {
      console.error("localforage error", err);
    }
  });
};
