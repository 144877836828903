import React from "react";
import image1 from "assets/rive/busanimation.riv";
import Rive from "rive-react";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import { Divider, Grid, Button } from "@material-ui/core";
import { localforageGetItem, localforageClearTrnParam } from "oautils/oaForageUtils";

const ExtRedirBusBookProgress = (props) => {
    const history = useHistory();

    const [isSaving, setIsSaving] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [infoText, setInfoText] = React.useState("");
    const [priceChangedStop, setPriceChangedStop] = React.useState(false);
    const [blockData, setBlockData] = React.useState({});
    const [responseMessage, setResponseMessage] = React.useState("Blocking seat, Please wait");

    const getTrnParams = () => {
        return new Promise((resolve, reject) => {
            localforageGetItem("bus-transact", (error, trnParams) => {
                if(trnParams) {
                    resolve(trnParams);
                } else {
                    console.log("error getting params", error);
                    reject(error);
                }
            });
        })
    }

    const clearTrnParams = () => {
        return new Promise((resolve, reject) => {
            localforageClearTrnParam("bus-transact")
            .then(() => resolve())
            .catch((error) => reject(error));
        });
    }

    const submitData = (param) => {
        WebApi.doBusSeatBlock(
            param,
            (response) => {
                if (response.success) {
                    // console.log("success");

                    if (response.data.BlockResult.IsPriceChanged) {
                        setBlockData(response.data.BlockResult);
                        setResponseMessage(
                            "Seat blocked successfully, price changed. Please confirm for booking seat on changed price"
                        );
                        setPriceChangedStop(true);
                    } else {
                        bookSeat(param);
                    }
                } else {
                    setIsError(true);
                    setInfoText(response.message);

                    setResponseMessage("Error occured. please wait");
                    setTimeout(() => {
                        // window.history.back();
                        history.push(`/bus/payment-status/${param.txnId}/failed`);
                    }, 5000);
                }
            },
            (error) => {
                console.log("doBusSeatBlock error", error);
                history.push(`/bus/payment-status/${param.txnId}/failed`);
            }
        );
    };

    const bookSeat = (param) => {
        setPriceChangedStop(false);
        setResponseMessage(
            "Seat blocked successfully, please wait booking seat now"
        );

        WebApi.doBusSeatBook(
            param,
            (response) => {
                // console.log(response);
                if (response.success) {
                    setResponseMessage(
                        "Seat booked successfully, please wait for ticket details"
                    );

                    clearTrnParams().then(() => {
                        WebApi.getBusSeatBookingDetails(
                            {
                                TraceId: response.data.BookResult.TraceId,
                                BusId: response.data.BookResult.BusId,
                            },
                            (response) => {
                                setIsSuccess(true);
                                setInfoText(response.message);
                                if (response.success === true) {
                                    history.push({
                                        pathname: `/bus/payment-status/${param.txnId}/success`,
                                        state: response.data,
                                    });
                                } else {
                                    setIsError(true);
                                    setInfoText(response.message);
    
                                    setResponseMessage("Error occured. please wait");
                                    setTimeout(() => {
                                        // window.history.back();
                                        history.push(`/bus/payment-status/${param.txnId}/failed`);
                                    }, 5000);
                                }
                            },
                            (error) => {
                                console.log("getBusSeatBookingDetails error", error);
                                history.push(`/bus/payment-status/${param.txnId}/failed`);
                            }
                        );
                    });
                } else {
                    setIsError(true);
                    setInfoText(response.message);

                    setResponseMessage("Error occured. please wait");
                    setTimeout(() => {
                        // window.history.back();
                        history.push(`/bus/payment-status/${param.txnId}/failed`);
                    }, 6000);
                }
            },
            (error) => {
                console.log("doBusSeatBook error", error);
                history.push(`/bus/payment-status/${param.txnId}/failed`);
            }
        );
    };

    React.useEffect(() => {
        getTrnParams().then(trnParams => {
            trnParams.orderId = props?.match?.params?.transactionId;
            // console.log("trnParams", trnParams);
            submitData(trnParams);
        });
    }, []);

    return (
        <Grid container style={{ minHeight: "60vh" }}>
            <Grid item md={12}></Grid>

            {!priceChangedStop ? (
                <>
                    <Grid item md={12}>
                        <OaFormAlerts
                            isSaving={isSaving}
                            isSuccess={isSuccess}
                            isError={isError}
                            infoText={infoText}
                            setIsError={setIsError}
                            setIsSuccess={setIsSuccess}
                        />
                    </Grid>
                    <Grid item
                        md={12}
                        style={{
                            height: "20vh",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            backgroundColor: "transparent",
                        }}
                    >
                        {/* <img src={image1} width="100" /> */}
                        <Rive src={image1} />
                    </Grid>
                    <Grid item
                        md={12}
                        style={{
                            height: "20vh",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            backgroundColor: "transparent",
                        }}
                    >
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>
                            {responseMessage}
                        </span>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item md={12}>
                        <div
                            style={{
                                fontSize: "20px",
                                marginBottom: "0.8rem",
                                marginTop: "6rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                backgroundColor: "transparent",
                                fontWeight: "bold",
                            }}
                        >
                            The fares for the Bus(s) you have selected has changed. Please
                            check the fares and continue.
                        </div>
                        <Divider style={{ marginBottom: "2rem" }} />
                        <Grid container>
                            <Grid item md={2}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "4%",
                                    }}
                                >
                                    <u>Bus Travells</u>
                                </div>
                                <div style={{ fontWeight: "450" }}>{blockData.TravelName}</div>
                            </Grid>
                            <Grid item md={1}>
                                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                                    <u>Departure</u>
                                </div>
                                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                                    {blockData.DepartureTime}
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                                    <u>Arrival</u>
                                </div>
                                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                                    {blockData.ArrivalTime}
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "4%",
                                    }}
                                >
                                    <u>Date</u>
                                </div>
                                <div style={{ fontWeight: "450" }}>
                                    {blockData.DepartureTime}
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "4%",
                                    }}
                                >
                                    <u>Origin</u>n
                                </div>
                                <div style={{ fontWeight: "450" }}>Bangalore</div>
                            </Grid>
                            <Grid item md={2}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "4%",
                                    }}
                                >
                                    <u>Destination</u>
                                </div>
                                <div style={{ fontWeight: "450" }}>Hyderabad</div>
                            </Grid>
                            <Grid item md={2}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "3%",
                                    }}
                                >
                                    <u>Fare + Tax (Rs.)</u>
                                </div>
                                <div style={{ fontWeight: "450" }}>
                                    <Button style={{ padding: 0, background: "#ececec" }}>
                                        1150.00
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginBottom: "1rem", marginTop: "2rem" }} />
                        <div
                            style={{
                                marginBottom: "0.2rem",
                                marginTop: "1.5rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                backgroundColor: "transparent",
                                fontWeight: "400",
                                fontSize: "16px",
                            }}
                        >
                            Actual Fare: Rs. 1150.00 (Rs. 1000.00 + Rs. 150.00 (Fees and
                            Taxes))
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                backgroundColor: "transparent",
                            }}
                        >
                            <Button style={{ padding: "3px 15px", background: "#ececec" }}>
                                New Fare : Rs. 1090.00
                            </Button>
                        </div>
                        <Divider style={{ marginBottom: "0.2rem", marginTop: "1rem" }} />
                        <div
                            style={{
                                fontSize: "20px",
                                marginBottom: "0.8rem",
                                marginTop: "1rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                backgroundColor: "transparent",
                                fontWeight: "bold",
                            }}
                        >
                            Do you want to continue booking?
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                backgroundColor: "transparent",
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={bookSeat}>
                                Yes
                            </Button>
                            <Button
                                style={{ background: "#ececec", marginLeft: "1rem" }}
                                onClick={window.history.back()}
                            >
                                No
                            </Button>
                        </div>
                    </Grid>
                </>
            )}

            {/* {props.location.state.departureCityId} {props.location.state.destinationCityId} */}
        </Grid>
    );
};

export default ExtRedirBusBookProgress;
