import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Link } from "react-router-dom";
import { theme } from "Theme";
import Hidden from "@material-ui/core/Hidden";

const styles = (theme) => ({
  root: {
    backgroundColor: "transparent",
    // paddingLeft: 57,
    // paddingRight: 75,
    display: "flex",
    justifyContent: "left",
    marginTop: -30,
    "& .MuiTabs-root":{
      "&:hover":{
        color:"white"
      }
    },
    [theme.breakpoints.down('sm')]:{
      marginTop: 0,
    }
  
  },

  firstIcon: {
    height: 40,
  },

  iconLabelWrapper: {
    flexDirection: "row",
    justifyContent: "space-around",
    // color: "black",
    textAlign: "right",
    marginLeft: -14,
    marginTop: 5,
    fontSize: 12,
    "&:hover": {
      color: "white",
    },
    [theme.breakpoints.down('sm')]:{
      marginLeft: 0,
    }
  },
  iconLabelWrapperActive: {
    flexDirection: "row",
    justifyContent: "space-around",
    color: "white",
    textAlign: "right",
    marginLeft: -14,
    marginTop: 5,
    fontSize: 12,
    "&:hover": {
      color: "white",
    },
    [theme.breakpoints.down('sm')]:{
      marginLeft: 0,
    }
  },

  tabStyle: {
    height: 79,
    backgroundColor: "white",
    marginRight: 10,
    color: "black",
    borderRadius: "10px 10px 0px 0px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.darkBlue,
      color: "white",
    },
    [theme.breakpoints.down('sm')]:{
      marginRight: 0,
    }
  
  },
  activeTabStyle: {
    height: 79,
    backgroundColor: theme.palette.secondary.darkBlue,
    marginRight: 10,
    color: "white",
    borderRadius: "10px 10px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      height: 1,
    },
    [theme.breakpoints.down('sm')]:{
      marginRight: 0,
    }
  },
});

class OaIconTabBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.currentTab, hoverTab: "" };
  }

  handleChange = (event, value) => {
    // console.log(value);
    this.setState({ value });

    // if (value == "flight") {
    //   if (typeof window !== "undefined" && window.openPage) {
    //     window.openPage("flight");
    //   }
    // }
  };

  handleMouseHover(e, name) {
    if (this.state.hoverTab == name) {
      this.setState({ hoverTab: "" });
    } else {
      this.setState({
        hoverTab: e.currentTarget.href.split("/")[
          e.currentTarget.href.split("/").length - 1
        ],
      });
    }
  }

  render() {
    const { classes } = this.props;

    // console.log(window.innerWidth)

    return (
      <div className={classes.root}>
        <Tabs
          value={this.state.value}
          textColor="primary"
          centered
          variant="fullWidth"
          TabIndicatorProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
          onChange={this.handleChange}
        >
          {this.props.tabs.map((row, id) => {
            return (
              <Tab
                onMouseEnter={(e) => this.handleMouseHover(e, row.name)}
                onMouseLeave={(e) => this.handleMouseHover(e, row.name)}
                // onChange={this.handleChange}
                key={id}
                value={row.name}
                to={row.url}
                component={Link}
                disabled={row.disabled}
                classes={{
                  wrapper:
                    this.state.value == row.name
                      ? classes.iconLabelWrapperActive
                      : classes.iconLabelWrapper,
                  labelContainer: classes.labelContainer,
                  labelIcon: classes.firstIcon,
                  indicator: classes.indicator,
                }}
                icon={
                  this.state.value == row.name ||
                  this.state.hoverTab == row.name
                    ? row.iconover
                    : row.icon
                }
                label={<Hidden smDown>{row.label}</Hidden>}
                className={
                  this.state.value == row.name
                    ? classes.activeTabStyle
                    : classes.tabStyle
                }
              />
            );
          })}
        </Tabs>
      </div>
    );
  }
}

OaIconTabBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OaIconTabBar);
