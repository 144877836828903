import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Grid,
    Typography,
    Slide,
    Tab,
    Tabs,
    AppBar,
    Box,
} from "@material-ui/core";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { useHistory, withRouter } from "react-router-dom";
import { AppStateContext } from "layouts/AppStateProvider";
import CorpLoginBgImage from "assets/EasyTravelsOnline/img/corp_login_bg.jpg";
import { getQueryVar, getCurrentTab } from "oautils/oaCommonUtils.js";
import LoginForm from "./LoginForm";
import { AppConstant } from "appConstant";
import { localforageGetItem } from "oautils/oaForageUtils";

const Login = (props) => {

    const classes = useStyles();
    const defaultTab = "agent";
    const queryVar = getQueryVar({ ...props, q: "priTab" });
    const currentTabName = getCurrentTab(queryVar, defaultTab);

    const getCurrentTabIndex = (key) => {
        switch (key) {
            case "agent":
                return 0;

            case "distributor":
                return 1;

            case "admin":
                return 2;

            default:
                return 0;
        }
    };

    const [inputData, setInputData] = useState({
        currentTab: getCurrentTabIndex(currentTabName),
    });

    return (
        <div className={classes.loginPageRoot}>
            <div className="image-container" style={{ backgroundImage: `url(${CorpLoginBgImage})`, height: "100vh" }}>
                <div className="bg-overlay"></div>
                
                <div className="breadcrumbs-wrapper">
                    <span className="home-link"
                        onClick={() => {props.history.push("/flights")}}
                    >
                        <i className="fas fa-home"></i> Home
                    </span>
                    <span>{" > "}</span>
                    <span>Partner Login</span>
                </div>
                <div className="login-page-contents-wrapper">
                    <div className="promo-caption-wrapper">
                        <Grid container className="promo-caption-flex-wrapper">
                            <Grid item className="promo-caption-top">
                                <span>We Guarantee the</span>
                            </Grid>
                            <Grid item className="promo-caption-mid">
                                <span>Lowest Price</span>
                            </Grid>
                            <Grid item className="promo-caption-bottom">
                                <span>Welcome to Easy Travels, your one stop sourcing platform for, all booking services.</span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="login-form-wrapper">
                        <Grid container className="login-form-inner-container">
                            <Grid item className="login-form-tabs-wrapper">
                                <Box className="tabs-nav-wrapper">
                                    <AppBar
                                        position="static"
                                        color="default"
                                        className="login-tabs-appbar"
                                    >
                                        <Tabs
                                            value={inputData.currentTab}
                                            onChange={(e, i) =>
                                                setInputData({ ...inputData, currentTab: i })
                                            }
                                            className="login-tabs"
                                        >
                                            <Tab label="Agent" />

                                            <Tab label="Distributor" />

                                            <Tab label="Admin" />
                                        </Tabs>
                                    </AppBar>
                                </Box>

                                <Box className="tabs-content-wrapper">
                                    <TabPanel value={inputData.currentTab} index={0}>
                                        <LoginForm className="login-form" currentTab={inputData.currentTab} history={props.history}/>
                                    </TabPanel>

                                    <TabPanel value={inputData.currentTab} index={1}>
                                        <LoginForm className="login-form" currentTab={inputData.currentTab}/>
                                    </TabPanel>

                                    <TabPanel value={inputData.currentTab} index={2}>
                                        <LoginForm className="login-form" currentTab={inputData.currentTab}/>
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;

const useStyles = makeStyles(theme => ({

    loginPageRoot: {
        "& .image-container": {
            position: "relative",
            padding: 0,
            margin: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            "& .breadcrumbs-wrapper": {
                position: "absolute",
                top: 10,
                left: 10,
                color: theme.palette.primary.lightText,
                "& .home-link": {
                    cursor: "pointer",
                    "&:hover": {
                        color: theme.palette.primary.info,
                    }
                },
                "& span": {
                    marginLeft: 5,
                },
            },
            "& .login-page-contents-wrapper": {
                fontFamily: theme.palette.font.secondary,
                display: "flex",
                justifyContent: "space-around",
                height: "100%",
                paddingTop: 150,
                "& .MuiGrid-item": {
                    zIndex: 1,
                },
                [theme.breakpoints.down(1024)]: {
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: 40,
                },
                "& .promo-caption-wrapper": {
                    width: "50%",
                    color: theme.palette.primary.lightText,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    "& .promo-caption-flex-wrapper": {
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "100px 0 0 18vw",
                        "& .MuiGrid-item": {
                            margin: "12px 0",
                        },
                        "& .promo-caption-top span": {
                            fontSize: "1.5rem",
                        },
                        "& .promo-caption-mid span": {
                            fontSize: "3.5rem",
                        },
                        "& .promo-caption-bottom span": {
                            fontSize: "1rem",
                        },
                        [theme.breakpoints.down(1024)]: {
                            padding: 0,
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 30,
                            "& .promo-caption-top span": {
                                fontSize: "1rem"
                            },
                            "& .promo-caption-mid span": {
                                fontSize: "2rem"
                            },
                            "& .promo-caption-bottom span": {
                                fontSize: "0.8rem",
                                textAlign: "center",
                                padding: "0 10px",
                            }
                        },
                        [theme.breakpoints.down(446)]: {
                            "& .promo-caption-top span": {
                                display: "none",
                            },
                            "& .promo-caption-mid span": {
                                display: "none",
                            },
                            "& .promo-caption-bottom span": {
                                display: "none",
                            }
                        },
                    },
                    [theme.breakpoints.down(1024)]: {
                        width: "100%",
                    }
                },

                "& .login-form-wrapper": {
                    width: "50%",
                    "& .login-form-inner-container": {
                        "& .login-form-tabs-wrapper": {
                            backgroundColor: theme.palette.background.light,
                            padding: "6px 0",
                            borderRadius: 6,
                            "& .MuiPaper-elevation4": {
                                boxShadow: "none",
                            },
                            "& .MuiTabs-fixed": {
                                backgroundColor: theme.palette.background.light,
                                overflow: "visible",
                            },
                            "& .MuiTabs-flexContainer": {
                                backgroundColor: theme.palette.background.light,
                                // [theme.breakpoints.down(769)]: {
                                //     justifyContent: "center",
                                // }
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: theme.palette.secondary.main,
                                height: 48,
                                transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s",
                                transform: "translate3d(0px, 0px, 0px)",
                                boxShadow: "0 16px 26px -10px rgb(215 156 253), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(244 67 54 / 20%)!important",
                                borderRadius: 4,
                                // [theme.breakpoints.down(769)]: {
                                //     display: "none",
                                // }
                            },
                            "& .MuiTab-root": {
                                width: "10vw",
                                minHeight: 38,
                                backgroundColor: theme.palette.background.light,
                                marginTop: 5,
                                overflow: "visible",
                                [theme.breakpoints.down(769)]: {
                                    width: 100,
                                },
                                [theme.breakpoints.down(345)]: {
                                    width: 80,
                                    "& .MuiTab-wrapper": {
                                        fontSize: 10,
                                    },
                                },
                            },
                            "& .Mui-selected": {
                                // backgroundColor: COLORS.BTN_BG_PRIMARY,
                                "& span": {
                                    color: theme.palette.primary.lightText,
                                    zIndex: 1,
                                },
                                // [theme.breakpoints.down(769)]: {
                                //     backgroundColor: theme.palette.primary.lightText,
                                // }
                            },
                            "& .MuiTouchRipple-root": {
                                display: "none",
                            },
                            "& .tabs-content-wrapper": {
                                minHeight: 285,
                                [theme.breakpoints.down(1024)]: {
                                    minHeight: 310,
                                    width: 400,
                                },
                                [theme.breakpoints.down(446)]: {
                                    width: 310,
                                    "& .MuiBox-root": {
                                        margin: 0,
                                    },
                                },
                                [theme.breakpoints.down(345)]: {
                                    width: 275,
                                }
                            },
                        },
                        [theme.breakpoints.down(1024)]: {
                            justifyContent: "center",
                            alignItems: "center",
                        }
                    },
                    [theme.breakpoints.down(1024)]: {
                        width: "100%",
                    }
                }
            },

            "& .bg-overlay": {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
            }
        }
    },
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box m={4}>{children}</Box>}
        </div>
    );
}
