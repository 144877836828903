import { makeStyles } from "@material-ui/core";
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { AppConstant } from "appConstant";

const OaTimerAlert = ({ open, handleClick, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.popupRoot}
    >
      <DialogTitle id="alert-dialog-title">{AppConstant.domainName}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Session Expired!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color="primary">
          Go to Home
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OaTimerAlert;

const useStyles = makeStyles((theme) => ({
  popupRoot: {
    "& .MuiDialogContentText-root": {
      textAlign: "center",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
}));
