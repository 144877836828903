import React, {useState, useRef, useEffect} from 'react';
import {Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputAdornment } from '@material-ui/core';
import dateFnsFormat from 'date-fns/format';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateField from 'oahoc/OaFormDateField';
import OaFormFileUpload from 'oahoc/OaFormFileUpload';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaCard from 'oahoc/OaCard';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {scrollToTop} from 'oautils/oaCommonUtils';
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaTabBar from 'oahoc/OaTabBar';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 
import {getQueryVar, getAgencyId} from 'oautils/oaCommonUtils';  

import AgentDetails from 'pages/agent/AgentDetails';
import { localforageGetItem } from 'oautils/oaForageUtils';

const GstDetails = (props) => {
    
    const [response, setResponse] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [gstDetails, setGstDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);

    let agentId = getQueryVar({...props, q: 'agentId'});
    
    let tabs = [
        { name: 'gst-details', label: 'GST Details', url: '/admin/management/gst-details'+(agentId>0?'/'+agentId:'') },
    ];
    let currentTab = 'gst-details';

    useEffect(() => {
        setIsLoading(true);
        if(agentId && agentId > 0) {
            localforageGetItem('user-id', function (err, value) {
                if(value == agentId) {
                    setIsDisabled(false);
                }
            });
        } else {
            setIsDisabled(false);
        }
    }, [props]);

    useEffect(() => {
        if(isLoading){
            apiCall(WebApi.getStateCity, {}, (response) => {
                if(response.success === true) {
                    setStates(response.data);
                } else {
                    setIsError(true);
                }
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (states) {
            apiCall(WebApi.getGstDetails, {'agentId': agentId}, (response) => {
                if(response.success === true) {
                    stateOnChange(response.data.gstState);
                    setGstDetails(response.data);
                    setIsLoading(false);
                } else {
                    setIsError(false);
                    setIsLoading(false);
                }
            });
        }
      }, [states, agentId]);

      

    const stateOnChange=function(value){
        for (let i = 0; i < states.length; i++) {
            if (states[i].stateId == value) {
                setCities(states[i].cities);
            }
        }
    }

    return (

        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            {isLoading && <OaLoading />}
            {isError && <OaError details={response.message} />}
            {!isLoading && !isError && 
            <> 
            <GridContainer>
                {agentId && 
                    (
                        <GridItem xs={12} sm={12} md={12}>
                            <OaCard>
                                <AgentDetails agencyId={agentId} />
                            </OaCard>
                        </GridItem>
                    )
                }
                <GridItem xs={12} sm={12} md={6} >
            <OaCard> 
            <OaFormAlerts 
                isSaving={isSaving} 
                isSuccess={isSuccess} 
                isError={isError} 
                infoText={infoText} 
                setIsError={setIsError} 
                setIsSuccess={setIsSuccess} 
            />
            <Formik 
                initialValues={{gstNumber: gstDetails.gstNumber, gstName: gstDetails.gstName, gstMobile: gstDetails.gstMobile, gstEmail: gstDetails.gstEmail, gstState: gstDetails.gstState, gstCity: gstDetails.gstCity, gstPincode: gstDetails.gstPincode, gstAddress: gstDetails.gstAddress}}
                validationSchema={Yup.object().shape({
                    gstNumber:Yup.string().required('GSTIN is required'),
                    gstName:Yup.string().required('GST Name is required'),
                    gstMobile:Yup.string().required('GST Phone Number is required'),
                    gstEmail:Yup.string().required('GST Email is required'),
                    gstState:Yup.string().required('Please select a State'),
                    gstCity:Yup.string().required('Please select a City'),
                    gstPincode:Yup.string().required('GST Pincode is required'),
                    gstAddress:Yup.string().required('GST Address is required'),
                })}
                
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                    if(agentId > 0) {
                        searchParams.agentId = agentId;
                    }
                    setIsSaving(true);
                    apiCall(WebApi.updateGstDetails, searchParams, (response) => {
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        setInfoText(response.message);
                        if(response.success === true) {
                            enableReinitialize=true;
                           // resetForm(true);
                        }
                    });
                }}
            >

                {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>
                        <OaFormTextField required={true} label="GSTIN" name="gstNumber" disabled={isDisabled}/>
                        <ErrorMessage name="gstNumber" component="div" className="error"/>
                        <OaFormTextField required={true} label="GST Name" name="gstName" disabled={isDisabled}/>
                        <ErrorMessage name="gstName" component="div" className="error"/>
                        <OaFormTextField required={true} label="GST Phone Number" name="gstMobile" disabled={isDisabled}/>
                        <ErrorMessage name="gstMobile" component="div" className="error"/>
                        <OaFormTextField required={true} label="GST Email" name="gstEmail" disabled={isDisabled}/>
                        <ErrorMessage name="gstEmail" component="div" className="error"/>
                        <OaFormSelectField 
                            label="Agency GST State"
                            name="gstState"
                            value={values.gstState}
                            options={states}
                            optionValue={'stateId'}
                            optionLabel={'stateName'}
                            onChange={value => {
                                setFieldValue('gstState', value);
                                stateOnChange(value)
                            }}
                            disabled={isDisabled}
                        />
                        <ErrorMessage name="gstState" component="div" className="error"/>
                        <OaFormSelectField 
                            label="Agency GST City"
                            name="gstCity"
                            value={values.gstCity}
                            options={cities}
                            optionValue={'cityId'}
                            optionLabel={'cityName'}
                            disabled={isDisabled}
                        />
                         <ErrorMessage name="gstCity" component="div" className="error"/>
                        <ErrorMessage name="gstEmail" component="div" className="gstCity"/>
                        <OaFormTextField required={true} label="GST Pincode" name="gstPincode" disabled={isDisabled}/>
                        <ErrorMessage name="gstPincode" component="div" classname="error"/>
                        <OaFormTextField required={true} label="GST Address" name="gstAddress" multiline={true} rows="5" disabled={isDisabled}/>
                        <ErrorMessage name="gstAddress" component="div" classname="error"/>
                        {!isDisabled &&
                            <OaFormSaveButton  label={'Update'} isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}/>
                        }
                    </Form>
                )}
            </Formik>
            </OaCard>
            </GridItem> 
            </GridContainer>
        </>
            }
        </OaCard>
       
    )
}

export default GstDetails;