import React, { useState, useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/CustomButtons/Button.js';
import dateFnsFormat from 'date-fns/format';
import { Formik, Form } from 'formik';
import OaFormFieldAgentList from 'components/OaFormFieldAgentList';
import WebApi from 'api/ApiConstants';
import { apiCall } from 'oautils/oaDataUtils';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';

const MoneyTransferSearchForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(true);
  const [agencyId, setAgencyId] = useState(props.agencyId);

  useEffect(() => {
    if (isLoading) {
    }
  }, [isLoading]);

  useEffect(() => {
    setAgencyId(props.agencyId);
  }, [props.agencyId]);

  return (
    <>
      {isLoading && <OaLoading />}
      {!isLoading && (
        <>
          <h4
            className={'oa-form-header'}
            style={{ transform: ' scale(0.85)', transformOrigin: 'left' }}
          >
            Search DMT Report
          </h4>
          <Formik
            initialValues={{
              dmtBookingFromDateDisplay:
                props.searchParams.dmtBookingFromDateDisplay,
              dmtBookingToDateDisplay:
                props.searchParams.dmtBookingToDateDisplay,
              dmtBookingFromDate: props.searchParams.dmtBookingFromDate,
              dmtBookingToDate: props.searchParams.dmtBookingToDate,
              agencyId: props.searchParams.agencyId,
            }}
            onSubmit={(searchParams, { setSubmitting, resetForm }) => {
              props.setIsLoading(true);
              // console.log(searchParams);

              searchParams.dmtBookingFromDate = dateFnsFormat(
                searchParams.dmtBookingFromDateDisplay,
                'dd-MM-yyyy',
              );
              searchParams.dmtBookingToDate = dateFnsFormat(
                searchParams.dmtBookingToDateDisplay,
                'dd-MM-yyyy',
              );
              // console.log(searchParams);
              props.target(searchParams);
              props.setIsLoading(false);
            }}
          >
            {({ values, isSubmitting, setFieldValue, handleChange }) => (
              <Form>
                <GridContainer>
                  <OaFormTextField
                    name='dmtApplicationReference'
                    label='Application Reference'
                    md={4}
                  />
                  <OaFormTextField name='mobile' label='Mobile Number' md={4} />

                  <OaFormDateField
                    value={values.dmtBookingFromDateDisplay}
                    selected={values.dmtBookingFromDateDisplay}
                    onChange={(date) =>
                      setFieldValue('dmtBookingFromDateDisplay', date)
                    }
                    label='Money Transfer From Date'
                    name='dmtBookingFromDateDisplay'
                    minDate={values.dateFromDisplay}
                    md={4}
                  />

                  <OaFormDateField
                    value={values.dmtBookingToDateDisplay}
                    selected={values.dmtBookingToDateDisplay}
                    onChange={(date) =>
                      setFieldValue('dmtBookingToDateDisplay', date)
                    }
                    label='Money Transfer To Date'
                    name='dmtBookingToDateDisplay'
                    minDate={values.dateFromDisplay}
                    md={4}
                  />

                  {/* {!agencyId &&
                        <GridItem xs={12} sm={12} md={4}> 
                        <FormControl fullWidth={true}>
                            <OaFormFieldAgentList label="Booked by" setFieldValue ={setFieldValue} />
                        </FormControl>
                        </GridItem>
                        } */}
                </GridContainer>
                <Button color='primary' type='submit'>
                  Search
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default MoneyTransferSearchForm;
