import React, {useState, useEffect} from 'react';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import {getQueryVar, getCurrentTab, getAgencyId } from 'oautils/oaCommonUtils.js';
import AgentDetails from 'pages/agent/AgentDetails';

import CommissionAgentList from './CommissionAgentList';

const CommissionAgent = (props) => {

    const [currentTab, setCurrentTab] = useState('domestic');
    const [tableKey, setTableKey] = useState(0);
    const [agencyId, setAgencyId] = useState(getAgencyId(props));

    let baseUrl = '/admin/management/commission/agent/flight/';

    const getAgencyIdUrl = agencyId => {
        return agencyId === undefined ? '' : agencyId;
    }
    let agencyIdUrl = getAgencyIdUrl(agencyId);
    
    let tabs = [
        { name: 'domestic', label: 'Domestic', url: baseUrl + 'domestic/' + agencyIdUrl},
        { name: 'international', label: 'International', url: baseUrl + 'international/' + agencyIdUrl },
        { name: 'soto', label: 'SOTO', url: baseUrl + 'soto/' + agencyIdUrl },
        { name: 'codeshare-international', label: 'Codeshare International', url: baseUrl + 'codeshare-international/' + agencyIdUrl },
        { name: 'codeshare-soto', label: 'Codeshare SOTO', url: baseUrl + 'codeshare-soto/' + agencyIdUrl }
    ];


    useEffect(() => {
        let aId = getAgencyId(props)
        setAgencyId(aId);
        let defaultTab = 'domestic';
        let queryVar = getQueryVar({...props, q: 'priTab'});
        let tab = getCurrentTab(queryVar, defaultTab);
        setCurrentTab(tab);
        setTableKey(tableKey + 1);
    }, [props]);

    return (
        <OaCard className={'oa-card-primary'}>
            {/* <div>{JSON.stringify(props)}</div> */}
            {agencyId && 
                (
                    <OaCard>
                        <AgentDetails agencyId={agencyId} />
                    </OaCard>
                )
            }
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            <CommissionAgentList tab={currentTab} key={tableKey} agencyId={agencyId}/>
        </OaCard>
    )
}

export default CommissionAgent;
