import { SET_B2C_HOTEL_LIST } from "redux/action";

export const hotelList = (state = {}, action) => {
  switch (action.type) {
    case SET_B2C_HOTEL_LIST:
      state = Object.assign({}, state);
      state.hotelList = action.payload;
      return state;
    default:
      return state;
  }
};