import React, { useState } from "react";
import DatePicker,{range} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from 'date-fns';
import { makeStyles } from "@material-ui/core";

const DatePickerWithCustomHeader = (props) => {
  // const [startDate, setStartDate] = useState(new Date());
  // const [yearsArray, setYearsArray] = useState(null);

  const classes = useStyles();

  // console.log(props)
  const endYear = new Date(props?.maxDate).getFullYear() || new Date().getFullYear();
  const startYear = new Date(props?.minDate).getFullYear() || 1900;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className={classes.root}>
      <DatePicker
        {...props}
        // dateFormat="dd/MM/YYYY"
        // withPortal
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="dp-nav-btn dp-nav-btn-dec">
              {"<"}
            </a>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <a onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="dp-nav-btn dp-nav-btn-dec">
              {">"}
            </a>
          </div>
        )}
        selected={props?.selected}
        onChange={(date) => props?.onChange(date)}
      />
    </div>

  );
};

export default DatePickerWithCustomHeader;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .react-datepicker__input-container": {
      // marginTop:'4px',
      "& input": {
        width: "130px",
        padding: '12px 10px',
        fontSize: '13px',
        fontWeight: 300,
        // border:'1px solid rgba(0, 0, 0, 0.33)',
        borderRadius: '4px',
      }
    },
    "& .react-datepicker-popper": {
      zIndex: 99,
    },
    "& .dp-nav-btn": {
      cursor: "pointer",
      color: theme.palette.primary.darkText,
      border: `solid 0.5px ${theme.palette.background.dark}`,
      padding: "0 10px",
      fontWeight: 600
    }
  },

}));
