import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import image1 from "assets/img/geebaku-loading.gif";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import {
  Divider,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import "assets/css/bus-passenger-review.css";

import OaFormAlerts from "pages/components/OaFormAlerts";

import { makeStyles } from "@material-ui/core/styles";
import { AppStateContext } from "layouts/AppStateProvider";

const BusPassengerReview = (props) => {
  console.log(props.location.state.Passenger);
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [fareBreakup, setFareBreakup] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const {
    setConfirmation,
    isConfirmed,
    setIsConfirmed,
    setOpen,
  } = React.useContext(AppStateContext);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isConfirmed) {
      setIsConfirmed(false);

      history.push({
        pathname: "/bus/bus-payment-progress",
        state: props.location.state,
      });
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isLoading) {
      let TotalSeatPrice = 0;
      let TotalGst = 0;
      let TotalSupplierCharge = 0;

      props.location.state.Passenger.map((passenger, index) => {
        let publishedPriceAfterCommissions =
          passenger.Seat.Price.CommissionCharge.grossProductPrice;
        let publishedPrice = passenger.Seat.Price.PublishedPrice;
        let gst = 0;

        if (passenger.Seat.Price.GST.TaxableAmount > 0) {
          gst =
            gst +
            passenger.Seat.Price.GST.CGSTAmount +
            passenger.Seat.Price.GST.SGSTAmount +
            passenger.Seat.Price.GST.IGSTAmount +
            passenger.Seat.Price.GST.CessAmount;
        }

        TotalSeatPrice = TotalSeatPrice + publishedPrice;
        TotalSupplierCharge =
          TotalSupplierCharge + publishedPriceAfterCommissions - publishedPrice;
        TotalGst = TotalGst + gst;
      });

      setFareBreakup({
        TotalSeatPrice: TotalSeatPrice,
        TotalSupplierCharge: TotalSupplierCharge,
        TotalGst: TotalGst,
        passengerCount: props.location.state.Passenger.length,
      });
      // setGrossTotal(TotalSeatPrice+TotalSupplierCharge+TotalGst);
      setIsLoading(false);
    }
  }, [isLoading]);
  // console.log(props)

  const getDuration = (diffTime) => {
    const diffHrs = Math.ceil(diffTime / 60);
    const diffMin = Math.ceil(diffTime % 60);
    return diffHrs + "h " + diffMin + "m";
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        md={12}
        style={{
          maxWidth: "1236px",
          backgroundColor: "#eeeeee",
        }}
      >
        <Grid
          container
          className="page-content-box"
          style={{
            marginTop: 50,
            marginBottom: 50,
            maxWidth: "1236px",
            backgroundColor: "#eeeeee",
            margin: "0 auto",
            justifyContent: "space-between",
            // paddingBottom: 5,
          }}
        >
          <Grid item md={12}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
          </Grid>
          <Grid item md={8} className="traveler-details">
            <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid
                style={{
                  // border: "1px solid rgba(0,0,0,0.125)",
                  // padding: 20,
                  width: "100%",
                }}
                className={classes.sectionContainer}
              >
                <Grid className="section-header" item md={12}>
                  <span
                    style={{
                      marginBottom: 0,
                      textTransform: "uppercase",
                      fontWeight: 500,
                      backgroundColor: theme.palette.background.default,
                      fontSize: 18,
                      // color: theme.palette.text.secondary,
                    }}
                  >
                    TRAVELLER DETAILS
                  </span>
                </Grid>
                <Grid item md={12} style={{ width: "100%", padding: 10 }}>
                  {props.location.state.Passenger.map((passenger, index) => {
                    return (
                      <Grid
                        container
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={index}
                        spacing={1}
                      >
                        <Grid
                          item
                          md={4}
                          sm={4}
                          xs={4}
                          className="passenger-info-label"
                        >
                          Passenger {index + 1}
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={8}
                          xs={8}
                          className="passenger-info-text"
                        >
                          {passenger.Title} {passenger.FirstName}{" "}
                          {passenger.MiddleName} {passenger.LastName}
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sm={4}
                          xs={4}
                          className="passenger-info-label"
                        >
                          Seat#
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={8}
                          xs={8}
                          className="passenger-info-text"
                        >
                          {passenger.Seat.SeatName}
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sm={4}
                          xs={4}
                          className="passenger-info-label"
                        >
                          Age
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={8}
                          xs={8}
                          className="passenger-info-text"
                        >
                          {passenger.Age}
                        </Grid>
                      </Grid>
                    );
                  })}

                  <Grid
                    container
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={4}
                      className="passenger-info-label"
                    >
                      {/* <Grid item md={4} xs={8} className="passenger idType"> */}
                      {props.location.state.IdType}
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={8}
                      style={{ paddingLeft: 3 }}
                      className="passenger-info-text"
                    >
                      {/* <Grid item md={6} xs={3} className="passenger-name"> */}
                      {props.location.state.IdNumber}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.sectionContainer}>
                <Grid
                  item
                  md={12}
                  style={{
                    marginBottom: 0,
                    textTransform: "uppercase",
                    fontWeight: 500,
                    backgroundColor: theme.palette.background.default,
                    fontSize: 18,
                    // color: theme.palette.text.secondary,
                  }}
                  className="section-header"
                >
                  <span>CONTACT INFORMATION</span>
                </Grid>

                <Grid
                  item
                  md={12}
                  style={{ width: "100%", padding: "0px 10px" }}
                >
                  <Grid
                    container
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={4}
                      className="passenger-info-label"
                    >
                      {/* <Grid item md={4} xs={8} className="passenger"> */}
                      Contact Number
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={8}
                      className="passenger-info-text"
                    >
                      {/* <Grid item md={8} xs={3} className="passenger-name"> */}
                      {props.location.state.Phoneno}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} style={{ width: "100%", padding: 10 }}>
                  <Grid
                    container
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={4}
                      className="passenger-info-label"
                    >
                      {/* <Grid item md={4} xs={6} className="passenger"> */}
                      Email
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={8}
                      xs={8}
                      className="passenger-info-text"
                    >
                      {/* <Grid
                      item
                      md={8}
                      xs={6}
                      className="passenger-name"
                      style={{ textAlign: isMobile && "right" }}
                    > */}
                      {props.location.state.Email}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {props.location.state.gstNo && (
                <>
                  <Grid item md={12} style={{ marginTop: 23 }}>
                    <span className="section-title">Company GST Details</span>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    style={{ display: isMobile ? "flex" : "", gap: "10px" }}
                  >
                    <Grid
                      container
                      style={{ paddingTop: 10, paddingBottom: 5 }}
                    >
                      <Grid item md={3} xs={12} sm={12}>
                        <span className="passenger-field-label">
                          GST NUMBER
                        </span>
                      </Grid>
                      <Grid item md={3} xs={12} sm={12}>
                        <span className="passenger-field-label">NAME</span>
                      </Grid>
                      <Grid item md={2} xs={12} sm={12}>
                        <span className="passenger-field-label">ADDRESS</span>
                      </Grid>
                      <Grid item md={2} xs={12} sm={12}>
                        <span className="passenger-field-label">CONTACT</span>
                      </Grid>
                      <Grid item md={2} xs={12} sm={12}>
                        <span className="passenger-field-label">EMAIL</span>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{ paddingTop: 5, paddingBottom: 10 }}
                      className="form-group form-check"
                    >
                      <Grid item md={3} sm={2} xs={12}>
                        {props.location.state.gstNo}
                      </Grid>
                      <Grid item md={3} xs={12} sm={12}>
                        {props.location.state.gstName}
                      </Grid>
                      <Grid item md={2} xs={12} sm={12}>
                        {props.location.state.gstAddress}
                      </Grid>
                      <Grid item md={2} xs={12} sm={12}>
                        {props.location.state.gstMobile}
                      </Grid>
                      <Grid item md={2} xs={12} sm={12}>
                        {props.location.state.gstEmail}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                className={classes.sectionContainer}
                style={{ padding: 10 }}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "",
                  }}
                >
                  <span className="section-title">TRAVEL DATE</span>
                </Grid>

                <Grid item md={12} style={{ marginBottom: 10 }}>
                  <Grid container>
                    <Grid item></Grid>
                    <Grid item></Grid>
                  </Grid>
                  <span className="sub-title-text">
                    {dateFnsFormat(
                      new Date(props.location.state.DepartureTime),
                      "do MMM yyyy, hh:mm a"
                    )}
                  </span>
                </Grid>

                <Grid item md={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={4} align="left">
                      <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
                        <Grid item xs={12}>
                          <span className="result-item-travel-name total-fare ">
                            {props.location.state.TravelName}
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          <span className="result-item-bus-type">
                            {props.location.state.BusType}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} align="center">
                      <Grid
                        container
                        direction="row"
                        style={{ marginTop: 5, marginBottom: 5 }}
                      >
                        <Grid item xs={4} md={4}>
                          <Grid
                            container
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            <Grid item xs={12} align="right">
                              <span className="dest-txt">
                                {props.location.state.BoardingPointsCity}
                              </span>
                            </Grid>
                            <Grid item xs={12} align="right">
                              <span className="dest-txt">
                                {dateFnsFormat(
                                  new Date(props.location.state.DepartureTime),
                                  "hh:mm a"
                                )}
                              </span>
                              <br />
                              <span className="date-txt">
                                (
                                {dateFnsFormat(
                                  new Date(props.location.state.DepartureTime),
                                  "do-MMM-yyyy"
                                )}
                                )
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Grid
                            container
                            style={{ paddingLeft: 20, paddingRight: 20 }}
                          >
                            <Grid item xs={12}>
                              <span className="result-item-duration">
                                {getDuration(
                                  props.location.state.durationInMinute
                                )}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <div align="center">
                                <div className="arrow-container">
                                  <span className="arrow-right"></span>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12}></Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <Grid
                            container
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            <Grid item xs={12} align="left">
                              <span className="dest-txt">
                                {props.location.state.DroppingPointsCity}
                              </span>
                            </Grid>
                            <Grid item xs={12} align="left">
                              <span className="dest-txt">
                                {dateFnsFormat(
                                  new Date(props.location.state.ArrivalTime),
                                  "hh:mm a"
                                )}
                              </span>
                              <br />
                              <span className="date-txt">
                                (
                                {dateFnsFormat(
                                  new Date(props.location.state.ArrivalTime),
                                  "do-MMM-yyyy"
                                )}
                                )
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={4}
            style={{
              // backgroundColor: theme.palette.background.rgba_primary,
              borderRadius: 10,
              // padding: 10,
              maxWidth: "49%",
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,0.125)",
              boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
              height: "100%",
            }}
            className="fareBreakup"
          >
            <Grid
              container
              style={
                {
                  // marginTop: 5,
                  // marginBottom: 5,
                }
              }
            >
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  borderBottom: "1px solid rgba(0,0,0,0.125)",
                  height: 35,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 15,
                  backgroundColor: theme.palette.background.default,
                }}
              >
                <span
                  className="fare-breakup-text"
                  style={{
                    marginBottom: 0,
                    textTransform: "uppercase",
                    fontWeight: 500,
                    backgroundColor: theme.palette.background.default,
                    // color: theme.palette.text.secondary,
                  }}
                >
                  FARE BREAK UP
                </span>
              </Grid>

              {!isLoading && fareBreakup && (
                <>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      padding: "0px 15px",
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
                      <Grid item md={4} xs={6} className="fare-text-left">
                        Base Fare
                      </Grid>
                      <Grid item md={4} xs={3} className="fare-text-right">
                        {fareBreakup.passengerCount} X{" "}
                        {fareBreakup.TotalSeatPrice /
                          fareBreakup.passengerCount}
                      </Grid>
                      <Grid item md={4} xs={2} className="fare-text-right">
                        {fareBreakup.TotalSeatPrice.toFixed(2)}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      padding: "0px 15px",
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
                      <Grid item md={4} xs={6} className="fare-text-left">
                        Other Charges
                      </Grid>
                      <Grid item md={4} xs={3} className="fare-text-right">
                        {fareBreakup.passengerCount} X{" "}
                        {fareBreakup.TotalSupplierCharge.toFixed(2) /
                          fareBreakup.passengerCount}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={2}
                        className="fare-text-right"
                        // style={{ marginLeft: isMobile && 5 }}
                      >
                        {fareBreakup.TotalSupplierCharge}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      padding: "0px 15px",
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
                      <Grid item md={4} xs={6} className="fare-text-left">
                        GST
                      </Grid>
                      <Grid item md={4} xs={3} className="fare-text-right">
                        {fareBreakup.passengerCount} X{" "}
                        {fareBreakup.TotalGst / fareBreakup.passengerCount}
                      </Grid>
                      <Grid item md={4} xs={2} className="fare-text-right">
                        {fareBreakup.TotalGst}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Grid
                      container
                      style={{
                        // marginTop: 5,
                        // marginBottom: 5,
                        background: theme.palette.background.disabled,
                        padding: "10px 15px",
                        borderBottom: `none`,
                        color: theme.palette.primary.dark,
                      }}
                    >
                      <Grid
                        item
                        md={6}
                        xs={6}
                        className="grand-total-fare"
                        style={{ color: theme.palette.primary.defaultText }}
                      >
                        Total Fare
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={6}
                        className="grand-total-fare"
                        align="right"
                      >
                        <GridContainer
                          justifyContent="flex-end"
                          className={classes.text1}
                          style={{ color: theme.palette.primary.defaultText }}
                        >
                          <i className="fas fa-rupee-sign"></i>
                          <GridItem
                            style={{
                              paddingLeft: "5px",
                              color: theme.palette.primary.defaultText,
                            }}
                          >
                            {(
                              fareBreakup.TotalSeatPrice +
                              fareBreakup.TotalSupplierCharge +
                              fareBreakup.TotalGst
                            ).toFixed(2)}
                          </GridItem>
                        </GridContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item md={12}>
                                <Grid container style={{marginTop: 5, marginBottom: 5}}>
                                    <Grid item md={6} className="grand-total-fare">
                                        Grand Total Fare
                                    </Grid>
                                    <Grid item md={6} className="grand-total-fare" align="right">
                                        3257
                                    </Grid>
                                </Grid>
                            </Grid> */}
                </>
              )}

              {/* <Grid item md={12}>
                <Divider></Divider>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          className="btn-group"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <Button
            // className="result-item-select-button"
            style={{
              background: theme.palette.buttons.tertiary,
              color: theme.palette.secondary.contrastText,
              width: "160px",
              height: "45px",
            }}
            onClick={() => {
              setConfirmation("Are you sure want to proceed?");
              setOpen(true);
            }}
          >
            Pay
          </Button>

          <Button
            // className="result-item-select-button"
            style={{
              marginLeft: 50,
              background: theme.palette.primary.disabled,
              color: theme.palette.primary.defaultText,
              width: "160px",
              height: "45px",
            }}
            onClick={() => {
              history.push("/buses");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusPassengerReview;

const useStyles = makeStyles((theme) => ({
  text1: {
    marginLeft: "15px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "24%",
    },
  },

  root: {
    // paddingLeft: "15px",
    // paddingRight: "15px",
    "& .fareBreakup": {
      [theme.breakpoints.down(960)]: {
        marginTop: 15,
        maxWidth: "100% !important",
      },
    },
    "& .traveler-details": {
      // backgroundColor: theme.palette.background.rgba_primary,
      borderRadius: 10,
      padding: 10,
      paddingTop: 0,

      "& .MuiGrid-container": {
        [theme.breakpoints.down("sm")]: {
          // justifyContent: "center",
        },
      },
      "& .idType": {
        [theme.breakpoints.down("sm")]: {
          marginRight: 26,
        },
      },
    },

    "& .arrow-container": {
      [theme.breakpoints.down("sm")]: {
        width: 69,
      },
    },
    "& .date-txt": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
        display: "block",
        textAlign: "left",
        marginTop: "-9px",
      },
    },
    "& .dest-txt ": {
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "left",
      },
    },
    "& .fare-breakup-text": {
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
      },
    },

    "& .btn-group": {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  sectionContainer: {
    background: theme.palette.background.light,
    borderRadius: "0.25rem",
    border: "1px solid rgba(0,0,0,0.125)",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
    marginBottom: 25,
    width: "100%",
    "& .section-header": {
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      width: "100%",
      padding: 10,
    },
  },
}));
