import React from "react";
import { Button, Dialog, DialogActions, DialogContent, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import styled from "styled-components";

const OaPopperAlert = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [severity, setSeverity] = React.useState(null);
    const [openPopper, setOpenPopper] = React.useState(false);

    const setOnClose = () => {
        setOpenPopper(false);
        props.setseverity({
            issuccess: false,
            iserror: false,
            isinfo: false,
            iswarning: false
        });
    };

    const openAlert = (severity) => {
        setSeverity(severity);
        setOpenPopper(true);
    };

    const initAlert = () => {
        switch(true) {
            case props.severity.iserror: {
                openAlert("error");
                break;
            }
            case props.severity.issuccess: {
                openAlert("success");
                break;
            }
            case props.severity.isinfo: {
                openAlert("info");
                break;
            }
            case props.severity.iswarning: {
                openAlert("warning");
                break;
            }
            default: {
                setOpenPopper(false); 
                break;
            }
        }
    };

    React.useEffect(() => {
        if(props.severity?.issuccess || props.severity?.iserror || props.severity?.isinfo || props.serverity?.iswarning) {
            initAlert();
        }
    }, [props.severity]);

    return(
        <Dialog
            fullScreen={fullScreen}
            open={openPopper}
            onClose={setOnClose}
        >
            <DialogContent>
                <StyledContainer theme={theme}>
                    {severity === "success" && <span><CheckCircleIcon className="success"/></span>} 
                    {severity === "info" && <span><InfoIcon className="info"/></span>} 
                    {severity === "warning" && <span><WarningIcon className="warning"/></span>} 
                    {severity === "error" && <span><ErrorIcon className="error"/></span>} 
                    {props?.alertmessage}
                </StyledContainer>
            </DialogContent>
            <DialogActions>
                <ButtonContainer theme={theme}>
                    <Button onClick={setOnClose} color="primary" autoFocus>
                        OK
                    </Button>
                </ButtonContainer>
            </DialogActions>
        </Dialog>
    );
};

export default OaPopperAlert;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    span {
        display: flex;
        align-items: center;
        & .MuiSvgIcon-root {
            font-size: 1.2rem;
            &.success { color: ${props => props.theme.palette.text.success}; }
            &.info { color: ${props => props.theme.palette.text.info}; }
            &.warning { color: ${props => props.theme.palette.text.warning}; }
            &.error { color: ${props => props.theme.palette.text.danger}; }
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    .MuiButton-root {
        width: 40px;
        height: 25px;
        background: ${props => props.theme.palette.buttons.secondary};
        color: ${props => props.theme.palette.buttons.secondaryContrastText};
        &:hover {
            background: ${props => props.theme.palette.buttons.secondary};
            color: ${props => props.theme.palette.buttons.secondaryContrastText};
        }
    }
`;
