import React, {useState, useEffect} from 'react';
import {localforageGetItem} from 'oautils/oaForageUtils';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Grid, Paper, useTheme } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import {currencyFormatter} from 'oautils/oaCommonUtils';

import {Formik, Form} from 'formik';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import * as Yup from 'yup';
import {scrollToTop} from 'oautils/oaCommonUtils';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";


export const AgentDetails = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [user, setUser] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
       localforageGetItem('user-details', function(error, value){
            if(!error && value){
                // console.log(props.data);
                setData(props.data);;
                setUser(value.title + " "+ value.firstName + " "+ value.lastName);
                if(props.data.status === 'pending')
                    setStatus('P');

                else if(props.data.status === 'aborted')
                    setStatus('A');

                else 
                    setStatus('S');

                setIsLoading(false);
                setIsError(error !== null);

                // console.log(data.status);
            }else{
                setIsError(true);
                setIsLoading(false);
            }
        });
    }, [isLoading]);
    const theme=useTheme()
    return (
        <>
        {!isLoading && <Grid>
            <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                {isLoading && (<Skeleton variant="rect" width={368} height={105} />)}

                {!isLoading && (
                    <div style={{verticalAlign: "top"}}>
                        {isError && (<h5>Tx No.: {props.uuid}</h5>)}

                        {!isError && !isLoading && ( 
                            <div style={{color: theme.palette.secondary.sub}}>
                                 <div style={{fontWeight: "bold"}}>{data.agentName} ({data.uuid})</div>
                                <div>Domain : {data.agentName}</div>
                                <div>Amount : {currencyFormatter(data.amount)}</div> 
                                <div>Processed By : {user}</div>
                            </div>
                        )}
                    </div>
                )}
                </GridItem>
                <GridItem  xs={12} sm={12} md={8}>
               
            <OaFormAlerts 
                isSaving={isSaving} 
                isSuccess={isSuccess} 
                isError={isError} 
                infoText={infoText} 
                setIsError={setIsError} 
                setIsSuccess={setIsSuccess} 
            /> 
                        
            <Formik 
                initialValues={{
                    CompanyRemarks: "",
                    status:status,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                    setIsError(false);
                    setIsSuccess(false);
                    setSubmitting(true);
                    setIsSaving(true);
                    scrollToTop(0);
                    
                    searchParams.uuid= data.uuid;
                    // console.log(searchParams);
                    apiCall(WebApi.updateDepositStatus, searchParams, (response) => {
                    //apiCall(WebApi.getError, searchParams, (response) => {
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        setInfoText(response.message);
                        if(response.success === true) {
                            // setInfoText('Status updated successfully');
                            enableReinitialize=true;
                            resetForm(true);
                        }
                    });
            }}>
                {({ values, isSubmitting, setFieldValue, enableReinitialize, resetForm }) => (
                <Form>
                       <OaFormSelectField 
                            label="Status" 
                            name="status" 
                            value={values.status}
                            options={[
                                {id: 'pending', value: 'P', label: 'Pending'},
                                {id: 'aborted', value: 'A', label: 'Aborted'},
                                {id : 'processed', value: 'S', label: 'Processed'},
                            ]}
                            md={8}
                           
                        />
                        
                        <OaFormTextField name="CompanyRemarks" label="Remarks" multiline={true} rows="3" md={8}/>

                                    
                       <div style={{marginLeft:15}}>
                        <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}/>
                    </div>
                    
                </Form>
            )}
        </Formik>
                </GridItem>
            </GridContainer>
        </Grid>}
        </>

    )
    

}

export default AgentDetails;