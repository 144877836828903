import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTimerInitialMinute, setTimerInitialSecond } from "redux/action";

const OaTimerDestroyer = (props) => {
  const [corpIdentity, setCorpIdentity] = useState("old");
  useEffect(() => {
    // console.log("########### destroyer running ################");
    props.setTimerInitialMinute(0);
    props.setTimerInitialSecond(0);
    setCorpIdentity("updated");
  }, []);
  return null;
};

function mapDispatchToProps(dispatch) {
  return {
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(OaTimerDestroyer);
