import React, {useState, useEffect} from 'react';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {currencyFormatter} from 'oautils/oaCommonUtils';
import OaCardGridItem from 'oahoc/OaCardGridItem';
import OaDisplayHeader from 'oahoc/OaDisplayHeader';
import { useTheme } from '@material-ui/core';

export default function OaDmtConfirmationAlert(props) {
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(true);
  const styles = theme => ({
    tdHead: {
      borderWidth: "1px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.chineseGray,
      backgroundColor: theme.palette.background.silverWhite,
    },
    tdSummaryHead: {
      borderWidth: "1px 1px 1px 1px",
      borderStyle: "solid",
      borderColor:theme.palette.primary.lightText,
      backgroundColor: theme.palette.background.asparagus,
      fontSize:18,
      height:44,
      color: theme.palette.primary.lightText,
    },
  
    tdCell: {
      borderWidth: "1px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.chineseGray,
      backgroundColor:theme.palette.primary.lightText,
    },
  
    tdcellDividerTop: {
      borderWidth: "1px 0 0 0",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.chineseGray,
      backgroundColor: theme.palette.primary.lightText,
    },
  
    tdcellPayment: {
      borderWidth: "1px 0 1px 0",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.chineseGray,
      backgroundColor: theme.palette.primary.lightText,
    },
  
    tdcellPaymentBottom: {
      borderWidth: "0 0 1px 0",
      borderStyle: "solid",
      borderColor: theme.palette.secondary.chineseGray,
      backgroundColor: theme.palette.primary.lightText,
      textTransform: 'capitalize'
    }
  })
  useEffect(() =>{
    setEnable(props.isPrompt)
    setOpen(props.isPrompt);
  },[props.isPrompt]);

  const handleSecondaryButton = () => {
    if(props.togglePrompt) {
      props.togglePrompt();
    } 
    
    if(props.callback) {
      props.callback('NO', props.callbackParam,props.additionalData,props.dialogRef);
    }
  };

  const handlePrimaryButton = () => {
    setEnable(false);
    if(props.togglePrompt) {
      props.togglePrompt();
    } 

    if(props.callback) {
      // console.log(props)
       props.callback('YES', props.callbackParam,props.additionalData,props.dialogRef);
    }
  };
  const theme=useTheme();
  return (
    <Dialog
      open={open}
      onClose={handleSecondaryButton}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md">
      <DialogContent style={{minWidth: 600}}>
        <OaCardGridItem md="12">
          <OaDisplayHeader>
              Confirm Transaction
          </OaDisplayHeader>
        </OaCardGridItem>
        <OaCardGridItem md="12">
          <div style={{align: "center", marginTop: "20px"}} >
              <table width="100%" style={{textAlign: "center"}} cellSpacing="0" cellPadding="5" border="0">
                {
                  props.additionalData.header && 
                  <thead>
                    <tr>
                      <th style={styles.tdHead}>Sl.No</th>
                      <th style={styles.tdHead}>{props.additionalData.header.field1Label}</th>
                      <th style={styles.tdHead}></th>
                      <th style={styles.tdHead}>{props.additionalData.header.field2Label}</th>
                      <th style={styles.tdHead}></th>
                      <th style={styles.tdHead}>{props.additionalData.header.field3Label}</th>
                    </tr>
                  </thead>
                }
                
                <tbody>
                {props.additionalData.transactions && props.additionalData.transactions.map((value, index) => {
                  return <tr key={index}>
                    <td style={styles.tdCell}>{index+1}</td>
                    <td style={styles.tdCell}>{currencyFormatter(value.actualAmount)}</td>
                    <td style={styles.tdCell}>+</td>
                    <td style={styles.tdCell}>{currencyFormatter(value.serviceCharge)}</td>
                    <td style={styles.tdCell}>=</td>
                    <td style={styles.tdCell}>{currencyFormatter(value.total)}</td>
                  </tr>
                  })}                
                </tbody>
              </table>
            </div>
        </OaCardGridItem>
      </DialogContent>
      <DialogActions style={{background: theme.palette.background.silverWhite, color: theme.palette.primary.lightText,}}>
        {!props.hideSecondaryButton && 
            <Button onClick={handleSecondaryButton} color="primary" >
              {props.secondaryButtonText ? props.secondaryButtonText : 'Cancel'}
            </Button>
        } 
        {!props.hidePrimaryButton && enable == false ? 
          <Button  color="danger1" disabled >
            {props.primaryButtonText ? props.primaryButtonText : 'OK'}
          </Button>
          :
          <Button onClick={handlePrimaryButton} color="primary"  >
            {props.primaryButtonText ? props.primaryButtonText : 'OK'}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
