import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core';

const OaDisplayHeader = (props) => {
    const theme = useTheme();
    return (
        <Paper elevation={0} style={{background: theme.palette.background.lightShadeGray, padding: "1em", color: theme.palette.primary.lightText, textAlign: "center"}}>
            <div style={{fontSize: "2em"}}>{props.children}</div>
        </Paper>
    )
}

export default OaDisplayHeader
