import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import MealCard from "./mealCard";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 160,
    width: "100%",
    padding: "0.3rem",
    // display: 'flex',
    // flexWrap:'wrap',
    // flexDirection: 'row',
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",

    "& .meal__container": {
      borderTop: "1px solid #e1e1e1",
      height: "380px",
      overflowY: "scroll",
      marginTop: 30,
      //display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: 80,
      [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.down(800)]: {
        marginTop: 5,
      },
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#b1b1b1",
        borderRadius: 8,
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: "#b1b1b1",
        },
      },
    },
  },
}));

const Meal = (props) => {
  const classes = useStyles();
  const [currTime, setCurrTime] = React.useState(new Date());
  const [incDisable, setIncDisable] = React.useState(false);
  const [paxDetail, setPaxDetail] = React.useState(props.paxinfo);
  const [selectedMeal, setSelectedMeal] = React.useState([]);
  const [selectedMealRt, setSelectedMealRt] = React.useState([]);

  const PaxNum = props.servlimit;

  React.useEffect(() => {
    let mealsSelected = 0;

    if (props.isreturn) {
      paxDetail?.adultPax &&
        paxDetail.adultPax.map((pax) => {
          pax.additionalReturn &&
            pax.additionalReturn.map((val, addIdx) => {
              if (addIdx == props.segidx && val.meal != null) {
                mealsSelected++;
              }
            });
        });

      if (paxDetail.childPax.length > 0) {
        paxDetail.childPax.map((pax) => {
          pax.additionalReturn &&
            pax.additionalReturn.map((val, addIdx) => {
              if (addIdx == props.segidx && val.meal != null) {
                mealsSelected++;
              }
            });
        });
      }

      if (mealsSelected == PaxNum) {
        paxDetail.mealStatusRt[props.segidx].mealsFull = true;
        setIncDisable(true);
      }

      props.setpaxinfo(paxDetail, "return");
    } else {
      paxDetail.adultPax &&
        paxDetail.adultPax.map((pax) => {
          pax.additionalOnward &&
            pax.additionalOnward.map((val, addIdx) => {
              if (addIdx == props.segidx && val.meal != null) {
                mealsSelected++;
              }
            });
        });

      if (paxDetail.childPax.length > 0) {
        paxDetail.childPax &&
          paxDetail.childPax.map((pax) => {
            pax.additionalOnward &&
              pax.additionalOnward.map((val, addIdx) => {
                if (addIdx == props.segidx && val.meal != null) {
                  mealsSelected++;
                }
              });
          });
      }

      if (mealsSelected == PaxNum) {
        paxDetail.mealStatus[props.segidx].mealsFull = true;
        setIncDisable(true);
      }

      props.setpaxinfo(paxDetail, "onward");
    }
  }, [paxDetail]);

  React.useEffect(() => {
    populateMeal();
  }, []);

  const populateMeal = () => {
    var entries = [...props.paxinfo.adultPax, ...props.paxinfo.childPax];
    let meals = [];
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of props.isreturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (val.meal !== null) {
          if (meals.includes(val.meal)) {
            for (let [ind, meal] of meals.entries()) {
              if (meal.code === val.meal.code) {
                meal.qty++;
              }
            }
          } else {
            val.meal.qty = 1;
            meals.push(val.meal);
          }
        }
      }
    }
    if (props.isreturn) {
      setSelectedMealRt(meals);
    } else {
      setSelectedMeal(meals);
    }
  };

  const addSelectedMeal = (entries, paxInfo, data, isReturn) => {
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of isReturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (addIdx == props.segidx && val.meal == null) {
          val.meal = data;
          if (isReturn) {
            paxInfo.additionalFareRt.meal =
              parseFloat(paxInfo.additionalFareRt.meal) +
              parseFloat(data.price);
          } else {
            paxInfo.additionalFare.meal =
              parseFloat(paxInfo.additionalFare.meal) + parseFloat(data.price);
          }
          setPaxDetail(paxInfo);
          return;
        }
      }
    }
  };

  const removeSelectedMeal = (entries, paxInfo, data, isReturn) => {
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of isReturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (
          addIdx == props.segidx &&
          val.meal != null &&
          val.meal.code == data.code
        ) {
          val.meal = null;
          if (isReturn) {
            paxInfo.additionalFareRt.meal =
              parseFloat(paxInfo.additionalFareRt.meal) > 0
                ? parseFloat(paxInfo.additionalFareRt.meal) -
                  parseFloat(data.price)
                : 0;
          } else {
            paxInfo.additionalFare.meal =
              parseFloat(paxInfo.additionalFare.meal) > 0
                ? parseFloat(paxInfo.additionalFare.meal) -
                  parseFloat(data.price)
                : 0;
          }
          paxInfo.mealStatus[props.segidx].mealsFull = false;
          setPaxDetail(paxInfo);
          setIncDisable(false);
          return;
        }
      }
    }
  };

  const onSelectMeal = (data, flag) => {
    let paxInfo = { ...paxDetail };
    var pax = [...paxInfo.adultPax, ...paxInfo.childPax];

    if (data != null) {
      if (props.isreturn == true) {
        if (flag == true) {
          addSelectedMeal(pax, paxInfo, data, true);
        } else {
          removeSelectedMeal(pax, paxInfo, data, true);
        }
      } else {
        if (flag == true) {
          addSelectedMeal(pax, paxInfo, data, false);
        } else {
          removeSelectedMeal(pax, paxInfo, data, false);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className="meal__container">
        {props.data &&
          props.data.length > 0 &&
          props.data.map(
            (item, idx) =>
              idx != 0 && (
                <MealCard
                  data={item}
                  key={idx}
                  setselectedmeal={(val, flag) => onSelectMeal(val, flag)}
                  disableinc={incDisable}
                  selectedmeal={selectedMeal}
                  selectedmealrt={selectedMealRt}
                  isreturn={props.isreturn}
                />
              )
          )}
      </div>
    </div>
  );
};

export default Meal;
