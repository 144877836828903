import WebApi from "api/ApiConstants";
import { EXT_REDIRECT_SERVER } from "api/ApiConstants";
import { AppConstant } from "appConstant";
// import { createBrowserHistory } from "history";

const pgImageUrl = "https://i.imgur.com/R4v3rRp.png";

export async function submitDataToRazorpay(request) {
  //paymentOptions,trnResponse,selectedPaymentMethod,theme
  const res = await loadScript(request.checkoutUrl);

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const amountDue = request.trnResponse.amountDue;
  const method = request.paymentOptions.method;
  const charge =
    request.paymentOptions.charge_type == "A"
      ? request.paymentOptions.charge
      : amountDue * request.paymentOptions.charge * 0.01;
  console.log("request.trnType ", request.trnType);

  const issuers = [];
  request.paymentOptions.pgBanks &&
    request.paymentOptions.pgBanks.length > 0 &&
    request.paymentOptions.pgBanks.map((val) => {
      issuers.push(val.bankCode);
    });

  const instruments = [];
  if (method === "netbanking") {
    instruments.push({
      method: "netbanking",
      banks: issuers,
    });
  } else if (method === "card") {
    instruments.push({
      method: "card",
      issuers: issuers,
    });
  } else if (method === "wallet") {
    instruments.push({
      method: "wallet",
    });
  } else if (method === "upi") {
    instruments.push({
      method: "upi",
    });
  } else if (method === "emi") {
    instruments.push({
      method: "emi",
    });
  }

  return new Promise((resolve) => {
    WebApi.getRazorPayOrderTransact(
      {
        type: request.trnType,
        amount: amountDue,
        method: request.selectedPaymentMethod,
        charge: charge,
        title: request.trnResponse.travelers[0].nameReference,
        firstName: request.trnResponse.deliveryAddress.firstName,
        lastName: request.trnResponse.deliveryAddress.lastName,
        email: request.trnResponse.deliveryAddress.email,
        phoneno: request.trnResponse.deliveryAddress.mobileNumber,
        userType: request.trnResponse.userType,
        address: request.trnResponse.deliveryAddress.address,
        txnId: request.trnResponse.transactionId,
        EncTxnId: request.trnResponse.uniqueTransactionId,
        paymentMode: request.trnResponse.paymentMode,
      },
      (response) => {
        if (response.success === true) {
          submitData(
            response,
            instruments,
            request.trnResponse,
            request.theme,
            request.selectedPaymentMethod,
            resolve,
            request.trnType
          );
        } else {
          resolve({
            isError: true,
            isSucess: false,
            infoText: "Unable to create transaction",
            retryPayment: false,
            responseMessage: "Unable to create transaction",
            transactionId: null,
          });
        }
      },
      (error) => console.log("order transact error", error)
    );
  });
}

function submitData(
  response,
  instruments,
  trnResponse,
  theme,
  selectedPaymentMethod,
  resolve,
  trnType
) {
  console.log(trnResponse, "TRNREPONSE");
  const {
    currency,
    orderId,
    amount,
    name,
    mobile,
    email,
    address,
    pgClientId,
  } = response.data;
  const options = {
    key: pgClientId, // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: AppConstant.name,
    description: trnType,
    image: AppConstant.brandUrl,
    order_id: orderId,
    modal: {
      ondismiss: function() {
        // console.log("Checkout form closed");
        // console.log(window.location.href.split("/"));
        const output = window.location.href;
        // console.log(output);
        if (output.includes("hotel")) {
          // window.location.assign(
          //   `http://localhost:3000/hotel/payment-status/${trnResponse.transactionId}/failed`
          // );
          window.location.assign(
            `${EXT_REDIRECT_SERVER}hotel/payment-status/${trnResponse.transactionId}/failed`
          );
        } else if (output.includes("flight")) {
          // window.location.reload();
          window.location.assign(
            `${EXT_REDIRECT_SERVER}flight/payment-status/${trnResponse.transactionId}`
          );
        } else {
          window.location.assign(
            `${EXT_REDIRECT_SERVER}bus/payment-status/${trnResponse.transactionId}/failed`
          );
        }
      },
    },
    handler: async function(resp) {
      const data = {
        orderId: orderId,
        pgTransactionId: resp.razorpay_payment_id,
        orderAmount: amount,
        userId: resp.userId,
        pgResponse: JSON.stringify(resp),
        txnId: trnResponse.uniqueTransactionId,
      };
      WebApi.getRazorPaySuccessTransact(
        data,
        (respns) => {
          const res = respns.data;
          if (res.status === "success") {
            resolve({
              isError: false,
              isSucess: true,
              infoText: "Payment successful",
              retryPayment: false,
              responseMessage: "Payment successful",
              transactionId: trnResponse.transactionId,
              orderId: orderId,
            });
          } else {
            resolve({
              isError: true,
              isSucess: false,
              infoText: "Payment failed",
              retryPayment: false,
              responseMessage: "Payment failed",
              transactionId: null,
            });
          }
        },
        (error) => console.log("success trnsact error", error)
      );
    },

    prefill: {
      name: name,
      email: email,
      contact: mobile,
    },
    notes: {
      address: address,
    },
    theme: {
      color: theme.palette.primary.main,
    },
    config: {
      display: {
        blocks: {
          banks: {
            name: "Pay using " + selectedPaymentMethod,
            instruments: instruments,
          },
        },
        sequence: ["block.banks"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();

  paymentObject.on("payment.failed", function(response) {
    const failedParam = {
      orderId: response.metadata.order_id,
      pgTransactionId: response.metadata.payment_id,
      pgResponse: JSON.stringify(response),
      errorSource: response.error.source,
      errorStep: response.error.step,
      errorDescription: response.error.description,
      errorCode: response.error.code,
      reason: response.error.reason,
    };
    WebApi.getRazorPayFailed(
      failedParam,
      (respon) => {
        resolve({
          isError: true,
          isSucess: false,
          infoText: "Payment failed",
          retryPayment: false,
          responseMessage: "Payment failed",
          transactionId: null,
        });
      },
      (error) => console.log("failed transact error", error)
    );

    switch (trnType) {
      case "flight-booking": {
        window.open(`/flight/payment-status/${trnResponse.transactionId}`);
        break;
      }

      case "hotel-reservation": {
        window.open(
          `/hotel/payment-status/${trnResponse.transactionId}/failed`
        );
        break;
      }

      case "bus-reservation": {
        window.open(`/bus/payment-status/${trnResponse.transactionId}/failed`);
        break;
      }
      case "insurance-booking": {
        window.open(
          `/insurance/payment-status/${trnResponse.transactionId}/failed`
        );
        break;
      }
      case "pan-application": {
        window.open(`/pan/payment-status/${trnResponse.transactionId}`);
        break;
      }
    }
  });
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
