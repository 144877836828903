import { SET_B2C_INSURANCE_LIST } from "redux/action";


export const insuranceList = (state = {}, action) => {
  switch (action.type) {
    case SET_B2C_INSURANCE_LIST:
      state = Object.assign({}, state);
      state.insuranceList = action.payload;
      return state;
    default:
      return state;
  }
}
