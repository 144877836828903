import React, { useState, useEffect } from "react";
import clsx from "clsx";

import {
  Grid,
  Box,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      width: "100%",
      height: "80px",
      padding: "16px 60px",
      color: theme.palette.primary.lightText,
      zIndex: "99",
      "& .MuiFormControlLabel-label": {
        color: theme.palette.primary.lightText,
      },
      [theme.breakpoints.down(1080)]: {
        padding: "12px 30px",
      },
    },
    flightBox: {
      color: theme.palette.primary.defaultText,
    },
    flightBoxCurrent: {
      backgroundColor: theme.palette.primary.lightText,
      borderRadius: "8px",
      boxShadow: "4px 4px 12px #00000020",
    },
    font16_700: {
      fontSize: "16px",
      fontWeight: "700",
      marginRight: "6px",
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
        marginRight: "4px",
      },
    },
    font16_400: {
      fontSize: "16px",
      fontWeight: "400",
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
  };
});

const MultiCitySection = (props) => {
  const classes = useStyles();
  const [currSec, setCurrSec] = useState(0);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        spacing={4}
        className={classes.flightBoxes}
      >
        {flightSearchHeaderMultCityDetails.map((sec, i) => (
          <Grid
            item
            className={clsx(
              classes.flightBox,
              i == currSec && classes.flightBoxCurrent
            )}
            onClick={() => setCurrSec(i)}
          >
            <Grid container direction="row" alignItems="center">
              <span className={clsx(classes.fromValue, classes.font16_700)}>
                {sec[0].value}{" "}
              </span>
              <ArrowForwardRoundedIcon />
              <span className={(clsx(classes.toValue), classes.font16_700)}>
                {sec[1].value}{" "}
              </span>
            </Grid>
            <div>
              <span className={clsx(classes.durationValue, classes.font16_400)}>
                {sec[2].value}
              </span>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MultiCitySection;

const flightSearchHeaderMultCityDetails = [
  [
    { title: "From", value: "Bangalore (BLR)" },
    { title: "To", value: "Chennai (MAA)" },
    { title: "Depart", value: "Mon, 01 Dec 2021" },
  ],
  [
    { title: "From", value: "Hyderabad (HYD)" },
    { title: "To", value: "Mumbai (BMB)" },
    { title: "Depart", value: "Mon, 02 Dec 2021" },
  ],
  [
    { title: "From", value: "Kolkata (KKR)" },
    { title: "To", value: "Delhi (DLH)" },
    { title: "Depart", value: "Mon, 03 Dec 2021" },
  ],
];
