import React, {useState, useEffect} from 'react';
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import {getQueryVar, getCurrentTab, getAgencyId} from 'oautils/oaCommonUtils.js';
import B2bBalanceManagerFunds from './B2bBalanceManagerFunds';
import B2bBalanceManagerUpdate from './B2bBalanceManagerUpdate';


export const B2bBalanceManager = (props) => {

    let baseUrl = '/admin/management/balance/b2b/';
    const [agencyId, setAgencyId] = useState(undefined);

    let tabs = [
        { name: 'funds', label: 'Agent Fund Management', url: baseUrl + 'funds' },
        { name: 'update', label: 'Agent Fund Request', url: baseUrl + 'update' }
    ];

    let defaultTab = 'funds';
    let queryVar = getQueryVar({...props, q: 'priTab'});
    let currentTab = getCurrentTab(queryVar, defaultTab);
    

    function renderTab (currentTab) {
        switch(currentTab){
            case "funds":
                return(<B2bBalanceManagerFunds agencyId={agencyId}/>);
            case "update":
            default:
                return(<B2bBalanceManagerUpdate agencyId={agencyId} />);
        }
    }

    useEffect(() => {
        let qAgencyId = getAgencyId(props);
        setAgencyId(qAgencyId);
    }, [agencyId, props]);

    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            {renderTab(currentTab)}
        </OaCard>
    )
}
export default B2bBalanceManager;