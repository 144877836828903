import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid, Button, Dialog, TransitionProps, TextField, InputAdornment, RadioGroup, Radio, FormControlLabel
} from "@material-ui/core";
import TopSectionBg from "assets/img/corporate/book-illustration.png";
import { COLORS, FONTS } from "assets/css/CssConstants";
import AccoIcon from "assets/img/corporate/acco-150x150.png";
import FlightIcon from "assets/img/corporate/airplane-150x150.png";
import CarIcon from "assets/img/corporate/car-150x150.png";
import RailIcon from "assets/img/corporate/rail-150x150.png";
import { ArrowRight, AccountCircle, Phone, Email, ArrowForward, ContactPhone, VpnKey, VpnKeyRounded, AssignmentInd, AssignmentIndRounded } from "@material-ui/icons";
import IntutiveBooking from "assets/img/corporate/intutivebooking.png";
import PromptResp from "assets/img/corporate/prompt-resp.png";
import { Formik, Form, ErrorMessage } from 'formik';
import OaFormAlerts from "pages/components/OaFormAlerts";
import * as Yup from "yup";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateFnsFormat from "date-fns/format";
import { AppConstant } from "appConstant";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        padding: 0,
        margin: 0,
        "& .MuiInputBase-input": {
            minHeight: 35
        },
        "& .page-container": {
            position: "relative",
        },
        "& .section-container": {
            padding: 0,
            margin: 0,
        },
        "& .top-section-bg": {
            backgroundImage: `url(${TopSectionBg})`,
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: 480,
        },
        "& .top-caption-wrapper": {
            "& h1": {
                color: theme.palette.secondary.main,
                fontSize: 70,
                fontWeight: 200,
                fontFamily: theme.palette.font.primary,
                "& span": {
                    fontWeight: 900,
                },
            }
        },
        "& .top-caption-grid": {
            padding: "100px 0 0 165px",
        },
        "& .action-button-wrapper": {
            "& button": {
                border: `solid 2px ${theme.palette.secondary.main}`,
                margin: 5,
                borderRadius: "25px",
                fontSize: 16,
                padding: "6px 20px",
                color: theme.palette.secondary.main,
                "&:hover": {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                },
            },
        },
        "& .section-title": {
            paddingLeft: 165,
            paddingTop: 100,
            marginBottom: 70,
            "& .section-title-container": {
                position: "relative",
                "& .section-title-dash": {
                    position: "absolute",
                    border: `solid 1px ${theme.palette.secondary.main}`,
                    top: -20,
                    bottom: -20,
                    left: 0,
                    [theme.breakpoints.down(600)]: {
                        display: "none",
                    },
                },
                "& .section-title-wrapper": {
                    fontSize: 70,
                    marginLeft: 30,
                    "& .title-top": {
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        marginBottom: 40,
                        fontSize: 50,
                        fontFamily: theme.palette.font.primary
                    },
                    "& .title-bottom": {
                        fontWeight: 300,
                        color: theme.palette.secondary.main,
                        fontFamily: theme.palette.font.primary,
                    },
                    [theme.breakpoints.down(1200)]: {
                        fontSize: 48,
                        "& .title-top": {
                            marginBottom: 30,
                        },
                        "& .title-top-small": {
                            fontSize: 32,
                            marginBottom: 30,
                        }
                    }
                },
            },
        },
        "& .desc-card-grid-row": {
            display: "flex",
        },
        "& .desc-card-group": {
            display: "grid",
            gridTemplateColumns: "50% 50%",
        },
        "& .desc-card-group-element-left": {
            gridColumn: "1 / span 1",
        },
        "& .desc-card-group-element-right": {
            gridColumn: "2 / span 1",
        },
        "& .desc-pix": {
            border: `solid 1px ${theme.palette.background.rgba_secondary}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "19vw",
            height: "19vw",
            "& img": {
                width: 75,
                height: 75,
            },
            "&:hover": {
                backgroundColor: theme.palette.background.rgba_secondary,
            },
        },
        "& .desc-text": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "19vw",
            height: "19vw",
            backgroundColor: theme.palette.background.rgba_primary,
            padding: 25,
            "& h3": {
                fontWeight: 400,
            },
            "& p": {
                color: theme.palette.primary.darkText,
            },
            "& .learn-more-link": {
                "& span": {
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: theme.palette.secondary.darkText,
                },
                "& .MuiSvgIcon-root": {
                    color: theme.palette.secondary.main,
                },
            },
        },
        "& .section-description": {
            padding: "0 400px 0 200px",
        },
        "& .section-content": {
            paddingLeft: 165,
            paddingRight: 165,
        },
        "& .section-content-grid-container": {
            padding: "50px 0",
        },
        "& .promo-section-bg": {
            padding: "0 0 0 75px",
        },
        "& .promo-section-desc-wrapper": {
            padding: "0 75px 0 0"
        },
        "& .promo-section-title": {
            "& h4": {
                fontSize: 24,
                fontWeight: 400,
            },
        },
        "& .promo-section-desc": {
            margin: "30px 0",
        },
        "& .promo-section-action-wrapper": {},
    },
    regModal: {
        "& .reg-modal-content": {
            minWidth: 585,
        },
        "& .reg-modal-header": {
            borderBottom: `1px solid ${theme.palette.primary.disabled}`,
            display: "flex",
            flexWrap: "nowrap",
            position: "relative",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
        "& .reg-modal-title": {
            padding: "0 15px",
            "& h4": {
                lineHeight: 1.42857143,
                fontSize: "2rem",
                fontWeight: 700,
                letterSpacing: "-0.0415625em",
            },
        },
        "& .reg-modal-body": {
            width: "100%",
        },
        "& .reg-modal-close-top": {
            fontSize: 16,
            fontWeight: 900,
            position: "absolute",
            top: 15,
            right: 10,
            cursor: "pointer",
            color: theme.palette.primary.disabled,
        },
        "& .reg-form-container": {
            padding: 20,
        },
        "& .reg-form-group": {
            margin: "10px 0",
        },
        "& .MuiInput-root": {
            padding: "4px 0",
        },
        "& .reg-form-submit-button": {
            backgroundColor: theme.palette.background.green,
            color: theme.palette.primary.lightText,
            padding: "10px 5px",
            "&:hover": {
                backgroundColor: theme.palette.background.green,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 16,
            },
        },
        "& .rupee-symbol": {
            fontSize: 22,
            padding: "0 0 0 7px",
            margin: "0 5px 0 0",
        },
        "& .reg-form-radio-group": {
            display: "flex !important",
            flexWrap: "nowrap",
            flexDirection: "row",
            "& .MuiFormControlLabel-root": {
                marginRight: 10,
            },
            "& .MuiTypography-root": {
                fontSize: 14,
            },
        },
        "& .bi-input-col": {
            "&.col-left": {
                paddingRight: 20,
            },
        },
        "& .contact-person-label": {
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                marginRight: 10,
                color: theme.palette.primary.darkText,
            },
        },
        "& .password-error": {
            color: theme.palette.primary.danger,
            width: "100%",
        },
    },
    dialogPaper: {
        "& .MuiInputBase-input": {
            minHeight: 35
        },
    }
}));

const RegInput = (props) => {
    return <TextField
        fullWidth
        InputProps={{
            startAdornment: (
                <InputAdornment position={props.position}>
                    {props.adornicon}
                </InputAdornment>
            )
        }}
        {...props}
    />
}

const LandingPage = (props) => {

    const classes = useStyles();
    const [openSignupForm, setOpenSignupForm] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const [dobDialogOpen, setDobDialogOpen] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState("");

    
    return (
        <div className={classes.root}>
            <div className="page-container">
                <div className="section-container">

                    <Grid container className="section-inner top-section-bg">
                        <Grid item md={6} sm={12} xs={12} className="top-caption-grid">
                            <div className="top-caption-wrapper">
                                <h1>
                                    <span>Business travel</span> <br />Made easy
                                </h1>
                            </div>

                            <div className="action-button-wrapper">
                                <Button
                                    onClick={() => setOpenSignupForm(!openSignupForm)}
                                >SIGN UP</Button>
                                <Button
                                    onClick={() => { props.history.push("/corporate_login") }}
                                >LOGIN</Button>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}></Grid>
                    </Grid>

                </div>

                <div className="section-container">

                    <div className="section-title">
                        <div className="section-title-container">
                            <div className="section-title-dash"></div>

                            <div className="section-title-wrapper">
                                <div className="title-top">TRAVEL WITHOUT THE</div>
                                <div className="title-bottom">PRICE TAG</div>
                            </div>
                        </div>
                    </div>

                    <div className="section-content">
                        <div className="desc-card-grid-row">
                            <div className="desc-card-group">
                                <div className="desc-card-group-element-left">
                                    <div className="desc-text">
                                        <h3>Accommodation</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                        <a className="learn-more-link" href="">
                                            <span>
                                                Learn more <ArrowRight />
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                <div className="desc-card-group-element-right">
                                    <div className="desc-pix">
                                        <img src={AccoIcon} />
                                    </div>
                                </div>
                            </div>

                            <div className="desc-card-group">
                                <div className="desc-card-group-element-left">
                                    <div className="desc-text">
                                        <h3>Flight</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                        <a className="learn-more-link" href="">
                                            <span>
                                                Learn more <ArrowRight />
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                <div className="desc-card-group-element-right">
                                    <div className="desc-pix">
                                        <img src={FlightIcon} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="desc-card-grid-row">
                            <div className="desc-card-group">
                                <div className="desc-card-group-element-left">
                                    <div className="desc-pix">
                                        <img src={CarIcon} />
                                    </div>
                                </div>

                                <div className="desc-card-group-element-right">
                                    <div className="desc-text">
                                        <h3>Car</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                        <a className="learn-more-link" href="">
                                            <span>
                                                Learn more <ArrowRight />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="desc-card-group">
                                <div className="desc-card-group-element-left">
                                    <div className="desc-pix">
                                        <img src={RailIcon} />
                                    </div>
                                </div>

                                <div className="desc-card-group-element-right">
                                    <div className="desc-text">
                                        <h3>Rail</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                        <a className="learn-more-link" href="">
                                            <span>
                                                Learn more <ArrowRight />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="section-container">

                    <div className="section-title">
                        <div className="section-title-container">
                            <div className="section-title-dash"></div>

                            <div className="section-title-wrapper">
                                <div className="title-top">BOOKING & MANAGING</div>
                                <div className="title-bottom">BUSINESS TRAVEL</div>
                            </div>
                        </div>
                    </div>

                    <div className="section-description">
                        <span>
                            Whether your company has a handful of travel organisers, or thousands of employees booking their own business travel, {AppConstant.name} for Business gives you the control that your business needs.
                        </span>
                    </div>

                    <div className="section-content">
                        <Grid container className="section-content-grid-container">
                            <Grid item md={6} sm={12} className="promo-section-bg">
                                <img src={IntutiveBooking} />
                            </Grid>

                            <Grid item md={6} sm={12} className="promo-section-desc-wrapper">
                                <div className="promo-section-title">
                                    <h4>Faster, more intuitive bookings</h4>
                                </div>

                                <div className="promo-section-desc">
                                    <p>
                                        {AppConstant.name} for Business automatically connects the different elements of your trip into a unified itinerary.
                                    </p>
                                    <p>
                                        With powerful AI technology that tailors search results to your preferences, you can book your whole work trip in less than two minutes with express checkout.
                                    </p>
                                    <p>
                                        Designed for efficiency, {AppConstant.name} for Business requires 56% fewer clicks and is 35% faster than the previous generation of online booking tools. So employees can focus on being there, not getting there.
                                    </p>
                                </div>

                                <div className="promo-section-action-wrapper action-button-wrapper">
                                    <Button
                                        onClick={() => setOpenSignupForm(!openSignupForm)}
                                    >CREATE YOUR FREE ACCOUNT</Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className="section-content-grid-container">
                            <Grid item md={6} sm={12} className="promo-section-desc-wrapper">
                                <div className="promo-section-title">
                                    <h4>Prompt Customer Support</h4>
                                </div>

                                <div className="promo-section-desc">
                                    <p>
                                        As a cloud-based travel solution, you will have world-class travel management anywhere you go.
                                    </p>
                                    <p>
                                        Make bookings from the road for a last minute meeting or convention from any device.
                                    </p>
                                    <p>
                                        Give your company a great travel experience every time, wherever they need to go. All backed by our great 24/7 customer support.
                                    </p>
                                </div>

                                <div className="promo-section-action-wrapper action-button-wrapper">
                                    <Button
                                        onClick={() => setOpenSignupForm(!openSignupForm)}
                                    >CREATE YOUR FREE ACCOUNT</Button>
                                </div>
                            </Grid>

                            <Grid item md={6} sm={12} className="promo-section-bg">
                                <img src={PromptResp} />
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </div>

            <Dialog open={openSignupForm} onClose={() => setOpenSignupForm(false)} className={classes.regModal}
                PaperProps={{ className: classes.dialogPaper }}
            >
                <div className="reg-modal-content">
                    <div className="reg-modal-header">
                        <div className="reg-modal-title">
                            <h4>Agent Registration</h4>
                        </div>

                        <span className="reg-modal-close-top"
                            onClick={() => setOpenSignupForm(false)}
                        >x</span>
                    </div>
                    <div className="reg-modal-body">
                        <OaFormAlerts
                            isSaving={isSaving}
                            isSuccess={isSuccess}
                            isError={isError}
                            infoText={infoText}
                            setIsError={setIsError}
                            setIsSuccess={setIsSuccess}
                        />
                        <Formik
                            initialValues={{
                                corpname: "",
                                username: "",
                                password: "",
                                firstname: "",
                                lastname: "",
                                mobilenumber: "",
                                email: "",
                                annualtravelbudget: 0,
                                gender: "",
                                dob: dateFnsFormat(new Date(), "dd-MM-yyyy"),
                                dobDisplay: new Date(),
                                commissionUserId: 0,
                            }}
                            validationSchema={Yup.object().shape({
                                corpname: Yup.string().required("Corporate Name is required"),
                                gender: Yup.string().required("Gender is required"),
                                dob: Yup.string().required("Date of Birth is required"),
                                mobilenumber: Yup.string().required("Contact Number is required").matches(phoneRegex, "Invalid contact number"),
                                email: Yup.string().required("Email is required").email("Invalid Email"),
                                annualtravelbudget: Yup.number().typeError("Annual Budget must be a number").positive("Annual Budget can't be negative"),
                                firstname: Yup.string()
                                    .required("First Name is required")
                                    .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid First Name"),
                                lastname: Yup.string()
                                    .required("Last Name is required")
                                    .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid Last Name"),
                            })}
                            onSubmit={(params, { setSubmitting, resetForm, enableReinitialize }) => {
                                
                                if (newPassword !== "" && passwordError == true) {
                                    return false;
                                }
                                
                                params.dob = dateFnsFormat(params.dobDisplay, "dd-MM-yyyy");
                                params.statename = "";
                                // console.log("registration params", params);
                                setSubmitting(true);

                                apiCall(WebApi.registerCorporateAgent, params, (response) => {
                                    setSubmitting(false);
                                    setIsSaving(false);
                                    let msg = "";
                                    if (response.success === true) {
                                        setIsError(false);
                                        setIsSuccess(true);
                                        msg = ". Please contact admin for approval";

                                        setTimeout(() => {
                                            setOpenSignupForm(false);
                                        }, 4000);
                                    } else {
                                        setIsError(true);
                                        setIsSuccess(false);
                                    }
                                    setInfoText(response.message+msg);
                                });
                            }}
                        >
                            {({ values, setFieldValue, enableReinitialize, resetForm }) =>
                                <Form>
                                    {/* {isError && <span style={{ color: "red" }}>{infoText}</span>}
                                    {isSuccess && <span style={{ color: "green" }}>{infoText}</span>} */}

                                    <div className="reg-form-container">
                                        <div className="reg-form-group">
                                            <Grid container>
                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col col-left">
                                                <RegInput
                                                    position="start"
                                                    adornicon={<AccountCircle />}
                                                    placeholder="Corporate Name"
                                                    name="corpname"
                                                    onChange={(ev) => {
                                                        setFieldValue("corpname", ev.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="corpname" component="div" className="error"/>
                                                </Grid>

                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col">
                                                <RegInput
                                                    position="start"
                                                    adornicon={<AssignmentIndRounded />}
                                                    placeholder="User Name"
                                                    name="username"
                                                    onChange={(ev) => {
                                                        setFieldValue("username", ev.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="corpname" component="div" className="error"/>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        {/* <div className="reg-form-group">
                                            <Grid container>
                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col col-left">
                                                <RegInput
                                                    position="start"
                                                    adornicon={<VpnKeyRounded />}
                                                    placeholder="Password"
                                                    name="password"
                                                    type="password"
                                                    onChange={(ev) => {
                                                        const npwd = ev.target.value
                                                        setNewPassword(npwd);
                                                        
                                                        if (npwd !== conPassword) {
                                                            setPasswordError(true);
                                                            setPasswordMessage("passwords do not match");
                                                        } else {
                                                            setPasswordError(false);
                                                            setPasswordMessage("");
                                                        }
                                                        
                                                        setFieldValue("password", npwd);
                                                    }}
                                                />
                                                </Grid>

                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col">
                                                <RegInput
                                                    position="start"
                                                    adornicon={<VpnKeyRounded />}
                                                    placeholder="Confirm Password"
                                                    onChange={(ev) => {
                                                        const cpwd = ev.target.value
                                                        
                                                        if (newPassword != "" && cpwd !== newPassword) {
                                                            setPasswordError(true);
                                                            setPasswordMessage("passwords do not match");
                                                        } else {
                                                            setPasswordError(false);
                                                            setPasswordMessage("");
                                                        }

                                                        setConPassword(cpwd);
                                                    }}
                                                />
                                                </Grid>

                                                <Grid item md={12} sm={12} xs={12}>
                                                <div className="password-error">
                                                    {passwordError && passwordError == true ? passwordMessage : ""}
                                                </div>
                                                </Grid>
                                            </Grid>
                                        </div> */}

                                        <div className="reg-form-group">
                                            <Grid container>
                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col col-left">
                                                    <label>Gender</label>
                                                    <RadioGroup
                                                        className="reg-form-radio-group"
                                                        name="gender"
                                                        value={values.gender}
                                                        onChange={(e) => {
                                                            // console.log("gender", e.target.value);
                                                            setFieldValue("gender", e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value="male"
                                                            control={<Radio color="secondary" />}
                                                            label="Male"
                                                        />
                                                        <FormControlLabel
                                                            value="female"
                                                            control={<Radio color="secondary" />}
                                                            label="Female"
                                                        />
                                                        <FormControlLabel
                                                            value="transgender"
                                                            control={<Radio color="secondary" />}
                                                            label="Transgender"
                                                        />
                                                    </RadioGroup>
                                                    <ErrorMessage name="gender" component="div" className="error"/>
                                                </Grid>

                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col">
                                                    <label>Date of birth</label>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            name="dobDisplay"
                                                            KeyboardButtonProps={{
                                                                onFocus: (e) => {
                                                                    setDobDialogOpen(true);
                                                                },
                                                            }}
                                                            PopoverProps={{
                                                                disableRestoreFocus: true,
                                                                onClose: () => {
                                                                    setDobDialogOpen(false);
                                                                },
                                                            }}
                                                            InputProps={{
                                                                onFocus: () => {
                                                                    setDobDialogOpen(true);
                                                                },
                                                            }}
                                                            open={dobDialogOpen}
                                                            onClose={() =>
                                                                setDobDialogOpen(false)
                                                            }
                                                            onOpen={() =>
                                                                setDobDialogOpen(true)
                                                            }
                                                            id="dobDateEl"
                                                            className="dob-date-picker"
                                                            fullWidth
                                                            color="secondary"
                                                            variant="inline"
                                                            format="dd-MM-yyyy"
                                                            margin="normal"
                                                            value={values.dobDisplay}
                                                            onChange={(e, v) => {
                                                                // console.log("dobDisplay", e);
                                                                setDobDialogOpen(false);
                                                                setFieldValue("dobDisplay", e);
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <ErrorMessage name="dob" component="div" className="error"/>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div className="reg-form-group">
                                            <Grid container>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <label className="contact-person-label">
                                                        <ContactPhone /> Contact Person
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col col-left">
                                                    <TextField
                                                        name="firstname"
                                                        value={values.firstname}
                                                        placeholder="First Name"
                                                        onChange={(ev) => {
                                                            setFieldValue("firstname", ev.target.value);
                                                        }}
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="firstname" component="div" className="error"/>
                                                </Grid>
                                                <Grid item md={6} sm={12} xs={12} className="bi-input-col">
                                                    <TextField
                                                        name="lastname"
                                                        value={values.lastname}
                                                        placeholder="Last Name"
                                                        onChange={(ev) => {
                                                            setFieldValue("lastname", ev.target.value);
                                                        }}
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="lastname" component="div" className="error"/>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div className="reg-form-group">
                                            <RegInput
                                                position="start"
                                                adornicon={<Phone />}
                                                placeholder="Contact Number"
                                                name="mobilenumber"
                                                onChange={(ev) => {
                                                    setFieldValue("mobilenumber", ev.target.value);
                                                }}
                                            />
                                            <ErrorMessage name="mobilenumber" component="div" className="error"/>
                                        </div>

                                        <div className="reg-form-group">
                                            <RegInput
                                                position="start"
                                                adornicon={<Email />}
                                                placeholder="Contact Email"
                                                name="email"
                                                onChange={(ev) => {
                                                    setFieldValue("email", ev.target.value);
                                                }}
                                            />
                                            <ErrorMessage name="email" component="div" className="error"/>
                                        </div>

                                        <div className="reg-form-group">
                                            <RegInput
                                                position="start"
                                                adornicon={<span className="rupee-symbol">₹</span>}
                                                placeholder="Annual Travel Budget"
                                                name="annualtravelbudget"
                                                onChange={(ev) => {
                                                    setFieldValue("annualtravelbudget", ev.target.value);
                                                }}
                                            />
                                            <ErrorMessage name="annualtravelbudget" component="div" className="error"/>
                                        </div>

                                        <div className="reg-form-group">
                                            <Button className="reg-form-submit-button"
                                                type="submit"
                                                fullWidth
                                            >
                                                Submit <ArrowForward />
                                            </Button>
                                        </div>
                                    </div>
                                </Form>}
                        </Formik>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default LandingPage;
