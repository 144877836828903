import React from "react";
import { Grid, Typography } from "@material-ui/core";
import offerCardBgImage from "assets/img/pass12.png";
import { makeStyles } from "@material-ui/core/styles";
import air from "assets/img/air.png";
import airlines from "assets/img/airlines.png";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        cursor: "pointer",
        padding: "3px 10px",
        "& .btn-hot-deal": {
            width:"20vw",
            background:"#ccb783", 
            color:"#FFF", 
            borderRadius: 0,
            marginBottom: 5, 
            marginRight: 30,
            "&:hover": {
                color: theme.palette.primary.main,
            }
        },
    }
}
));

const SpecialOfferCard = (props) => {

    const classes = useStyles();

    const handleClick = () => {
        // console.log("props offer *****************",props.offer);
        props.history.push("/fd/hotdeals/details", {
            data: props.offer
        });
    };
   
    return (
        <div className={classes.root} style={{padding: "3px 10px"}}>
            <Button className="btn-hot-deal" onClick={handleClick}>
                {props.offer.departureAirportCode} - {props.offer.arivalAirportCode}
            </Button>
        </div>
    );
};

export default withRouter(SpecialOfferCard);