import React, { useState } from "react";
import GridItem from "components/Grid/GridItem.js";
import { TextField, FormControl, InputAdornment, Box } from "@material-ui/core";
import { Field } from "formik";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    fieldWrapper: {
        "& .MuiIcon-root": {
            
        }
    },
    oaStandardInput: {
        width:'100%',
        "& .Mui-focused": {
            
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: theme.palette.primary. sapphire,
        },
        "& .MuiInputBase-root::before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        },
        "& .MuiInputBase-root::after": {
            borderBottom: `1px solid ${theme.palette.primary. sapphire}`,
        },
        "& .MuiInputBase-root:hover::after": {
            borderBottom: `1px solid ${theme.palette.primary. sapphire}`,
        }
    },
}));

const OaFormTextField = (props) => {
    let useGrid = props.md !== undefined;
    const classes = useStyles();
    const [iconColor, setIconColor] = useState("#000")

    const changeIconColor = (colorVal) => {
        setIconColor(colorVal)
    }

    function field() {
        return (
            <FormControl fullWidth={true} variant="standard">
                <div style={{ display: 'flex', alignItems: 'flex-end' }} className={classes.fieldWrapper}>
                    <Field
                        {...props}
                        type={props.type ? props.type : "input"}
                        
                        as={TextField}
                        variant='standard'
                        className={classes.oaStandardInput}
                        onFocus={() => changeIconColor("#2174ed")}
                        onBlur={() => changeIconColor("#000")}
                    />
                    {
                        props.adornmentIcon && 
                        <Icon style={{ color: iconColor, marginTop: 2, background: "none", marginLeft: "-27px" }}>
                            {props.adornmentIcon}
                        </Icon>
                    }
                </div>             
                                    
                {props.helperText !== null && props.helperText !== undefined && (
                    <label htmlFor="props.name" color="info"></label>
                )}
            </FormControl>
        );
    }
    return (
        <>
            {!useGrid && field()}
            {useGrid && (
                <GridItem xs={12} sm={12} md={props.md}>
                    {field()}
                </GridItem>
            )}
        </>
    );
};

export default OaFormTextField;


// function field() {
//     return (
//       <FormControl fullWidth={true}>
//         <Field
//           {...props}
//           variant='standard'
//           className={'oa-input-white'}
//           type='input'
//           as={TextField}
//         />
//         {props.helperText !== null && props.helperText !== undefined && (
//           <label htmlFor='props.name' color='info'></label>
//         )}
//       </FormControl>
//     );
//   }