import React, { useState, useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OaFormTextField from "oahoc/OaFormTextField";
import OaCard from "oahoc/OaCard";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { scrollToTop, inWords } from "oautils/oaCommonUtils";
import MasterPaymentOptions from "./MasterPaymentOptions";
import { TextField, makeStyles } from "@material-ui/core";
import OaFormikErrorAlert from "oahoc/OaFormikErrorAlert";
const MasterBalanceManagerNewOnline = (props) => {
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  let selBankId = 0;
  if (Object.keys(props.bankList).length > 0) {
    selBankId = props.bankList[Object.keys(props.bankList)[0]].value.toString();
  }

  // function _onKeyUp(event) {
  //   const { value } = event.target;
  //   setAmountInWord(inWords(value));
  // }

  // function loadScript(src) {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // }

  return (
    <OaCard>
      <div className={classes.root}>
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
        <Formik
          initialValues={{
            amount: 0,
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .integer()
              .required("Amount is required")
              .typeError("Amount must be a number")
              .positive("Must not be a negative value"),
          })}
          onSubmit={async (
            searchParams,
            { setSubmitting, resetForm, enableReinitialize }
          ) => {
            setIsError(false);
            setIsSuccess(false);
            setSubmitting(true);
            setIsSaving(true);
            scrollToTop(0);
            // setPaymentAmount(searchParams.amount)
            // displayRazorpay(searchParams.amount, setSubmitting, resetForm, enableReinitialize);
          }}
        >
          {({ values, setFieldValue, enableReinitialize, resetForm ,errors, touched}) => (
            <Form>
              <div className="amount-box">
                <label>Enter Recharge Amount</label>
                 <div className="text-field-div">
                  <OaFormTextField required={true} name="amount" />
                  <ErrorMessage name="amount" component="div" className="error" />
                 </div>
                <OaFormikErrorAlert name="amount" error={errors} touched={touched}/>
              </div>

              {/* <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} label={"Submit"} inReset={resetForm}/>  */}
              <div>
                <MasterPaymentOptions
                  {...props}
                  amount={values.amount}
                  isSaving={isSaving}
                  exReset={enableReinitialize}
                  inReset={resetForm}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </OaCard>
  );
};

export default MasterBalanceManagerNewOnline;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .amount-box": {
      display:'flex',
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 46,
      marginTop: 13,
      "& .text-field-div":{
        width:'100%',
      },
      " & label": {
        color: "#000",
        width:'246px',
      },
      "& .oa-input-white": {
        width: "36%",
        "& .MuiOutlinedInput-input": {
          textAlign: "right",
        },
      },
    },
  },
}));
