import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = (props) => {

  const previousMonth = (e, decreaseMonth) => {
    e.preventDefault();
    decreaseMonth(e);
  };

  const nextMonth = (e, increaseMonth) => {
    e.preventDefault();
    increaseMonth(e);
  };
  return (
    <>
      <ReactDatePicker
        {...props}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => (
          props.monthsShown == 1 ?
            <div>
              <button
                type="button"
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={{ visibility: "visible" }}
                onClick={(e) => previousMonth(e, decreaseMonth)}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                type="button"
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={{ visibility: "visible" }}
                onClick={(e) => nextMonth(e, increaseMonth)}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
            :
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                onClick={(e) => previousMonth(e, decreaseMonth)}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                onClick={(e) => nextMonth(e, increaseMonth)}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
        )}
      />
    </>
  );
};

export default CustomDatePicker;
