import React, { useEffect } from "react";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
  Tooltip,
  Modal,
  IconButton,
  Dialog,
} from "@material-ui/core";
import {
  AirlineSeatReclineNormalRounded,
  CloseOutlined,
} from "@material-ui/icons";
import { apiCall } from "oautils/oaDataUtils";
import { useHistory, withRouter } from "react-router-dom";
import { FlightShareDetailView } from "./view/FlightShareDetailsView";
import { FlighCardMoreDetailsVew } from "./view/FlighCardMoreDetailsVew";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import OaNetFare from "pages/components/OaNetFare";
import { AppConstant } from "appConstant";
import OaAlert from "oahoc/OaAlert";
import StrikeThroughLocalMall from "oahoc/StrikeThroughLocalMall";

function RoundTripFlightCard(props) {
  const [moreDetailsOpen, setMoreDetailsOpen] = React.useState(false);
  const [showMoreFares, setShowMoreFares] = React.useState(false);
  const [selectedFareId, setSelectedFareId] = React.useState(null);
  const [selectedFareDetail, setSelectedFareDetail] = React.useState(
    props.flightData.fares[0]
  );
  const [fareRules, setFareRules] = React.useState(
    props.flightData.fares[0].rule
  );

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  const useStyles = getStyles(showMoreFares, props.showNetFare);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const moreActionDiv = React.useRef();
  const carrierDetailsDiv = React.useRef();

  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  const sessionId = props?.sessionid;

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [flightData, setFlightData] = React.useState({
    departDay: "",
    departDate: "",
    departTime: "",
    departAirport: "",

    arrivalDay: "",
    arrivalDate: "",
    arrivalTime: "",
    arrivalAirport: "",

    stops: 0,
    stopAirports: 0,

    fares: [],

    journeyHours: 0,
    journeyMins: 0,
    segments: [],

    airlineCode: "",
    airlineName: "",
    flightCode: "",
  });

  const width = window.innerWidth;
  const breakpoint = 980;

  const toggleShowMoreFares = (showState) => {
    setShowMoreFares(showState);
  };

  const toggleMoreDetails = (event, moreDetailsOpenState) => {
    // setMoreDetailsOpen(moreDetailsOpenState);
    // setSelectedFlight(event, selectedFareDetail);

    if (moreDetailsOpenState) {
      setIsLoading(true);
      props.isloading(true);
      const params = {
        onwardFareId: props.isdepart ? selectedFareDetail.fareId : "",
        returnFareId:
          props.tripMode === "ROUND" && !props.isdepart
            ? selectedFareDetail.fareId
            : "",
        sessionId: sessionId,
      };
      apiCall(WebApi.getFlightFareRules, params, (response) => {
        if (response.isSuccess == true) {
          if (props.isdepart)
            setFareRules(response?.onwardResp[0]?.fares[0].rule);
          else setFareRules(response?.returnResp[0]?.fares[0].rule);
          props.isloading(false);
          setIsLoading(false);
          setMoreDetailsOpen(true);
        } else {
          props.isloading(false);
          setIsLoading(false);
        }
      });
    } else {
      setMoreDetailsOpen(moreDetailsOpenState);
    }
  };

  const toggleFareDetails = (event, moreDetailsOpenState) => {
    if (moreDetailsOpenState) {
      setIsLoading(true);

      const params = {
        sessionId: sessionId,
        onwardFareId: props.isdepart ? selectedFareDetail.fareId : "",
        returnFareId:
          props.tripMode === "ROUND" && !props.isdepart
            ? selectedFareDetail.fareId
            : "",
      };
      apiCall(WebApi.getFlightFareRules, params, (response) => {
        if (response.isSuccess == true) {
          if (props.isdepart)
            setFareRules(response?.onwardResp[0]?.fares[0].rule);
          else setFareRules(response?.returnResp[0]?.fares[0].rule);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const setSelectedFlight = (event, fareDetail) => {
    const fareId = fareDetail.fareId;
    setFareRules(fareDetail.rule);
    setSelectedFareDetail(fareDetail);
    setSelectedFareId(fareId);
    props.getSelectedFlight(fareId, props.flightData);

    setInfoText("");
    setIsError(false);
  };

  // let event = 0;
  // console.log(props.flightData);
  // setSelectedFlight(event, props.flightData.fares[0]);

  useEffect(() => {
    let event = 0;
    if (props.index === 0) {
      setSelectedFlight(event, props.flightData.fares[0]);
    }
  }, []);

  // useEffect(
  //   (() => {
  //     let event = 0;
  //     console.log(props.flightData);
  //     // setSelectedFlight(event, props.flightData.fares[0]);
  //   },
  //   [])
  // );

  const getReqDate = (daySpan) => {
    const reqDate = new Date();
    if (daySpan > 365 * 4) {
      daySpan += Math.floor(daySpan / 365 / 4);
    } else {
      if (daySpan < 0) {
        var tempDs = daySpan * -1;
        if (tempDs > 365 * 4) {
          tempDs += Math.floor(tempDs / 365 / 4);
        }
        daySpan = tempDs * -1;
      }
    }
    reqDate.setTime(reqDate.valueOf() + daySpan * 24 * 3600 * 1000);
    return reqDate;
  };

  const bookOnwardFlight = (repricingResponse) => {
    const paxInfo = {
      adultPax: [],
      childPax: [],
      infantPax: [],
      country_dial_code: "+91",
      phoneno: "",
      emailUser: "",
      gstCompany: "",
      gstCompanyAddress: "",
      gstCompanyNumber: "",
      gstEmail: "",
      gstNumber: "",
      discontCoupon: "",
      discontCouponAmount: 0,
      hasGst: false,
      isDomestic: props?.request?.isDomestic,
      adultCount: props?.request?.adultPax,
      childCount: props?.request?.childPax,
      infantCount: props?.request?.infantPax,
      seatStatus: [],
      mealStatus: [],
      baggStatus: [],
      seatStatusRt: [],
      mealStatusRt: [],
      baggStatusRt: [],
      additionalFare: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
      additionalFareRt: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
    };

    repricingResponse.onwardResp[0].segment.map((val, idx) => {
      paxInfo.seatStatus.push({ seatsFull: false });
      paxInfo.mealStatus.push({ mealsFull: false });
      idx == 0 && paxInfo.baggStatus.push({ baggsFull: false });
    });

    for (var i = 0; i < props.request.adultPax; i++) {
      const additionalOne = [];
      repricingResponse.onwardResp[0].segment &&
        repricingResponse.onwardResp[0].segment.map((val, idx) => {
          additionalOne.push({
            frequentAirline: val.carrierDetails.carrierCode,
            frequentAirlineName: val.carrierDetails.carrierName,
            airlineNo: val.carrierDetails.flightNumber,
            frequentFlyerNo: "",
            meal: null,
            baggage: null,
            seat: null,
          });
        });

      paxInfo.adultPax.push({
        initial: "-1",
        firstname: "",
        surname: "",
        visatype: "",
        passportno: "",
        passportidate: null,
        passportexpire: null,
        passporticountry: "",
        passporticobj: { name: "India", dial_code: "+91", code: "IN" },
        // dob: dateFnsFormat(new Date(getReqDate(-(365 * 13))), "yyyy-MM-dd"),
        // dobDisplay: getReqDate(-(365 * 13)),
        dob: "",
        dobDisplay: null,
        paxSave: false,
        additionalOnward: additionalOne,
        additionalReturn: [],
      });
    }

    if (props.request.childPax > 0) {
      for (var i = 0; i < props.request.childPax; i++) {
        const additionalOne = [];
        repricingResponse.onwardResp[0].segment &&
          repricingResponse.onwardResp[0].segment.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.childPax.push({
          initial: "-1",
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: [],
        });
      }
    }

    if (props.request.infantPax > 0) {
      for (var i = 0; i < props.request.infantPax; i++) {
        const additionalOne = [];
        repricingResponse.onwardResp[0].segment &&
          repricingResponse.onwardResp[0].segment.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.infantPax.push({
          initial: "-1",
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: [],
        });
      }
    }

    history.push({
      pathname: "/flight/booking",
      state: {
        request: repricingResponse?.request,
        requestObj: props?.history?.location?.state?.request,
        onwardResp: repricingResponse?.onwardResp[0],
        returnResp: null,
        oneSelectedFareId: repricingResponse?.onwardResp[0]?.fares[0]?.fareId,
        retSelectedFareId: null,
        sessionId: repricingResponse?.sessionId,
        availSessionId: sessionId,
        isPriceChanged: false,
        paxInfo: paxInfo,
      },
    });
  };

  const rePrice = () => {
    if (selectedFareId != null) {
      const params = {
        onwardFareId: selectedFareId,
        returnFareId: "",
        sessionId: sessionId,
      };

      props.isloading(true);
      WebApi.getFlightRepricing(
        params,
        (response) => {
          setIsLoading(false);
          props.isloading(false);
          if (response.isSuccess == true) {
            if (response.error == "Fare difference") {
              window.confirm("fares have changed, do you want to continue?") &&
                bookOnwardFlight(response);
            } else {
              bookOnwardFlight(response);
            }
          } else {
            setIsError(true);
            // setInfoText("Error on repricing! please retry booking.");
            // alert("Error on repricing! please choose another flight.");
            setPrimaryButtonText("OK");
            setIsAlertPrompt(true);
            setAlertMsg(
              "This fare is not available. Please choose another flight!"
            );
            setAlertTitle("Fare not available");
          }
        },
        (error) => console.log("error", error)
      );
    } else {
      //setInfoText("Please select fare");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
      setAlertMsg("Please select fare!");
      setAlertTitle("");
    }
  };

  React.useEffect(() => {
    if (props.flightData) {
      let segments = props.flightData.segment;
      let stops = segments.length - 1;

      let stopAirports = "";
      let totalJourneyTime = props.flightData.journeyTime;

      segments.map((value, index) => {
        if (index > 0) {
          let sep = segments.length - 1 === index ? "" : ", ";
          stopAirports += value.departDetails.airportCode + sep;
        }
      });

      let flightCode = segments[0].carrierDetails.carrierCode;
      let depDateRaw = new Date(segments[0].departDetails.dateTime);

      let ls = segments.length - 1;
      let arrDateRaw = new Date(segments[ls].arriveDetails.dateTime);

      setFlightData({
        departDay: days[depDateRaw.getDay()],
        departDate: dateFnsFormat(depDateRaw, "dd MMM"),
        departTime: dateFnsFormat(depDateRaw, "HH:mm"),
        departAirport: segments[0].departDetails.airportCode,

        arrivalDay: days[arrDateRaw.getDay()],
        arrivalDate: dateFnsFormat(arrDateRaw, "dd MMM"),
        arrivalTime: dateFnsFormat(arrDateRaw, "HH:mm"),
        arrivalAirport: segments[ls].arriveDetails.airportCode,

        stops: stops,
        stopAirports: stopAirports,

        fares: props.flightData.fares,

        journeyHours: Math.floor(totalJourneyTime / 60),
        journeyMins: Math.floor(totalJourneyTime % 60),
        segments: segments,

        airlineCode: flightCode + " " + segments[0].carrierDetails.flightNumber,
        airlineName: segments[0].carrierDetails.carrierName,
        flightCode: flightCode,
      });
    }
  }, [props.flightData]);

  return (
    // <div
    //   className={classes.root}
    //   style={{ width: props.fullWidth ? "100%" : "50%" }}
    // >
    <div
      className={clsx(
        classes.NonMUIFlightCard,
        ((props.type === "onward" && props.onwfareid === selectedFareId) ||
          (props.type === "return" && props.selected === selectedFareId)) &&
          "selected-card"
      )}
    >
      {/* <OaFormAlerts
        isLoading={isLoading}
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        style={{ width: "100%" }}
      /> */}
      {isError && (
        <div item md={12} xs={12}>
          <OaAlert
            //callback={transactionHelper}
            isPrompt={isAlertPrompt}
            //msg={isError}
            //title={infoText}
            msg={alertMsg}
            title={alertTitle}
            isError={isError}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            togglePrompt={() => {
              setIsAlertPrompt(!isAlertPrompt);
            }}
          ></OaAlert>
        </div>
      )}
      <div
        className={classes.cardContent}
        // style={{
        //   gridTemplateColumns:
        //     "repeat(" + (props.tripMode == "ONE" ? 4 : 3) + ",auto)",
        // }}
      >
        {/* 1 ------------------------ */}
        <div className={classes.airlineDetails}>
          <div className="carrier-details-div" ref={carrierDetailsDiv}>
            {/* <LazyLoad throttle={200} height={40} > */}
            <div className={classes.airline_image}>
              <img
                src={
                  image_base +
                  "?fileName=" +
                  flightData.flightCode +
                  ".png&type=AIRLINE_IMAGES"
                }
                alt=""
              />
              <div className={classes.f12r.pl5}>
                {flightData.airlineCode} -{" "}
                <span className={classes.seatsLeft}>
                  ({flightData?.fares?.[0]?.bookingClass})
                </span>
              </div>
            </div>
            {/* </LazyLoad> */}
            <div className={classes.airline_title}>
              <div className={classes.f14b}>{flightData.airlineName}</div>
            </div>
          </div>
          <div className="more-action-div" ref={moreActionDiv}>
            <div
              className={classes.airline_detailButton}
              onClick={(event) => {
                toggleMoreDetails(event, !moreDetailsOpen);
              }}
            >
              <span className={classes.f8r}>
                More Details {moreDetailsOpen ? "-" : "+"}
              </span>
            </div>
            {AppConstant.name === true && (
              <div className={classes.flightDetails_Share}>
                <FlightShareDetailView
                  segment={props.flightData.segment}
                  journeyTime={props.flightData.journeyTime}
                ></FlightShareDetailView>
              </div>
            )}
          </div>
        </div>

        {/* 2 ------------------------ */}
        <div className={classes.flightDetails}>
          <div className={classes.flightDetails_Timings}>
            <div className={classes.departure}>
              <div className={clsx(classes.f24b)}>{flightData.departTime}</div>
              <div className={clsx(classes.f12r)}>
                {flightData.departDay}, {flightData.departDate} [
                {flightData.departAirport}]
              </div>
            </div>
            <div className={classes.duration}>
              <div className={clsx(classes.f12r)}>
                {flightData.journeyHours}h {flightData.journeyMins}m
              </div>
              <div className={clsx(classes.f8r)}>
                {flightData.stops > 0
                  ? flightData.stops +
                    " stop" +
                    (flightData.stops > 1 ? "s " : " ") +
                    "- " +
                    flightData.stopAirports
                  : "Non-Stop"}
              </div>
              <div className={clsx(classes.f8r, classes.seatsLeft)}>
                ({flightData?.fares?.[0]?.availableSeats} Seats Left)
              </div>
              {/* {flightData.fares.map((s, i) => (
                <div className={clsx(classes.f8r)}>
                  ({s.availableSeats} Seats Left)
                </div>
              ))} */}
            </div>
            <div className={classes.arrival}>
              <div className={clsx(classes.f24b)}>{flightData.arrivalTime}</div>
              <div className={clsx(classes.f12r)}>
                {flightData.arrivalDay}, {flightData.arrivalDate} [
                {flightData.arrivalAirport}]
              </div>
            </div>
          </div>
          {props.tripMode === "ROUND" && (
            <div className={classes.flightDetails_Itinaries}>
              <div className="add-service-item">
                <Tooltip
                  title={selectedFareDetail.availableSeats + " seats available"}
                >
                  <span className="price-seat">
                    <AirlineSeatReclineNormalRounded />
                  </span>
                </Tooltip>
                {selectedFareDetail.isRefundable == "true" ? (
                  <Tooltip title="Refundable">
                    <span style={{ marginTop: "-3px", fontWeight: "bold" }}>
                      R
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Non-Refundable">
                    <span
                      style={{
                        marginTop: "0px",
                        fontWeight: "bold",

                        textDecoration: "line-through solid red 2px",
                        marginBottom: 5,
                      }}
                    >
                      R
                    </span>
                  </Tooltip>
                )}

                <Tooltip title={selectedFareDetail.baggage}>
                  <span className="price-baggage">
                    {selectedFareDetail.baggage !== "" &&
                    selectedFareDetail.baggage != null ? (
                      <LocalMallRoundedIcon />
                    ) : (
                      <StrikeThroughLocalMall />
                    )}
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        {/* 3 ------------------------ */}
        <div
          className={classes.priceDetails}
          // style={{
          //   height:
          //     showMoreFares === true
          //       ? "auto"
          //       : props.tripMode === "ONE"
          //       ? 102
          //       : 112,
          // }}
        >
          <FormControl
            component="fieldset"
            style={{ position: "relative", marginBottom: 19 }}
          >
            <RadioGroup
              // defaultValue="non-stop-only"
              aria-label="flight stops"
              name="flight-stop-radios"
              defaultValue={
                props.index === 0
                  ? props.flightData.fares[0].fareId
                  : '"non-stop-only"'
              }
            >
              {flightData.fares &&
                showMoreFares &&
                flightData.fares.map((s, i) => (
                  <FormControlLabel
                    className={classes.FlightPricesLabel}
                    value={s.fareId}
                    control={<FilterRadio />}
                    label={
                      <>
                        <p>
                          {"₹"} {s.baseFare + s.tax}
                          {props.tripMode !== "ROUND" && (
                            <>
                              <Tooltip
                                title={s.availableSeats + " seats available"}
                              >
                                <span className="price-seat">
                                  <AirlineSeatReclineNormalRounded />
                                </span>
                              </Tooltip>
                              {s.isRefundable == "true" ? (
                                <Tooltip title="Refundable">
                                  <span
                                    style={{
                                      marginTop: "-3px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    R
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Non-Refundable">
                                  <span
                                    style={{
                                      marginTop: "0px",
                                      fontWeight: "bold",
                                      textDecoration:
                                        "line-through solid red 2px",
                                      marginBottom: 5,
                                    }}
                                  >
                                    R
                                  </span>
                                </Tooltip>
                              )}
                              <Tooltip title={s.baggage}>
                                <span className="price-baggage">
                                  <LocalMallRoundedIcon />{" "}
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </p>
                        <span className="fare-type">
                          {s.fareType && s.fareType != ""
                            ? s.fareType
                            : "Published Fare"}
                        </span>
                        <OaNetFare
                          fareData={s}
                          showNetFare={props.showNetFare}
                        />
                      </>
                    }
                    onClick={(event) => {
                      // console.log()
                      if (moreDetailsOpen) {
                        setMoreDetailsOpen(false);
                      }
                      setSelectedFlight(event, s);
                    }}
                    // checked={
                    //   props.selected != null &&
                    //   selectedFareId == props.selected &&
                    //   selectedFareId === s.fareId
                    // }
                    key={i}
                  />
                ))}
              {flightData.fares &&
                !showMoreFares &&
                flightData.fares.slice(0, 2).map((s, i) => (
                  <FormControlLabel
                    className={classes.FlightPricesLabel}
                    value={s.fareId}
                    control={<FilterRadio />}
                    label={
                      <>
                        <p>
                          {"₹"} {s.baseFare + s.tax}
                          {props.tripMode !== "ROUND" && (
                            <>
                              <Tooltip
                                title={s.availableSeats + " seats available"}
                              >
                                <span className="price-seat">
                                  <AirlineSeatReclineNormalRounded />
                                </span>
                              </Tooltip>
                              {s.isRefundable == "true" ? (
                                <Tooltip title="Refundable">
                                  <span className="price-refundable">R</span>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Non-Refundable">
                                  <span
                                    className="price-refundable"
                                    style={{
                                      textDecoration:
                                        "line-through solid red 2px",
                                      marginBottom: 5,
                                    }}
                                  >
                                    R
                                  </span>
                                </Tooltip>
                              )}
                              <Tooltip title={s.baggage}>
                                <span className="price-baggage">
                                  <LocalMallRoundedIcon />{" "}
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </p>
                        <span className="fare-type">
                          {s.fareType && s.fareType != ""
                            ? s.fareType
                            : "Published Fare"}
                        </span>
                        <OaNetFare
                          fareData={s}
                          showNetFare={props.showNetFare}
                        />
                      </>
                    }
                    onClick={(event) => {
                      if (moreDetailsOpen) {
                        setMoreDetailsOpen(false);
                      }
                      setSelectedFlight(event, s);
                      //toggleFareDetails(event, moreDetailsOpen);
                    }}
                    checked={
                      props.selected != null &&
                      selectedFareId == props.selected &&
                      selectedFareId === s.fareId
                    }
                    key={i}
                  />
                ))}
            </RadioGroup>
          </FormControl>
          {flightData.fares && flightData.fares.length > 2 ? (
            <div
              className={classes.showMoreButton}
              onClick={() => toggleShowMoreFares(!showMoreFares)}
            >
              <span>show {showMoreFares ? "less" : "more"}</span>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* 4 ------------------------ */}
        {props.tripMode && props.tripMode == "ONE" && (
          <div className={classes.onwardAction}>
            <Box
              className={classes.onwardBookNowAction}
              onClick={rePrice}
              style={{
                background:
                  props.selected != null && selectedFareId == props.selected
                    ? theme.palette.primary.main
                    : theme.palette.secondary.sharkGray,
                cursor:
                  props.selected != null && selectedFareId == props.selected
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              <Typography>Book Now</Typography>
            </Box>
          </div>
        )}
      </div>

      {moreDetailsOpen && width > breakpoint && (
        <FlighCardMoreDetailsVew
          segments={props.flightData.segment}
          selectedFareDetail={selectedFareDetail}
          fareRules={fareRules}
        ></FlighCardMoreDetailsVew>
      )}
      {width < breakpoint && (
        <Dialog
          open={moreDetailsOpen}
          onClose={() => setMoreDetailsOpen(false)}
          fullScreen
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {" "}
              <IconButton onClick={() => setMoreDetailsOpen(false)}>
                <CloseOutlined />
              </IconButton>
            </Box>
            <FlighCardMoreDetailsVew
              segments={props.flightData.segment}
              selectedFareDetail={selectedFareDetail}
              fareRules={fareRules}
            ></FlighCardMoreDetailsVew>
          </Box>
        </Dialog>
      )}
    </div>
    // </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 10,
  boxShadow: 24,
  p: 2,
};
export default withRouter(RoundTripFlightCard);

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      ref={props.inref}
      {...props}
    />
  );
}

const radioStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: `${theme.palette.primary.main}40`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: `${theme.palette.primary.main}10`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
  },
}));

const getStyles = (showMoreFares, showNetFare) => {
  return makeStyles((theme) => ({
    root: {
      boxSizing: "border-box",
      width: "100%",
      boxShadow: "0 0 7px rgba(0,0,0,0.25)",
      "& .flex-dir-col": {
        flexDirection: "column !important",
        alignItems: "flex-start !important",
      },
    },
    NonMUIFlightCard: {
      // minHeight: "106px",
      minHeight: 140,
      maxWidth: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.light,
      boxShadow: `0px 0px 5px 0px rgba(145,143,145,0.6)`,
      padding: "15px",
      margin: "6px",
      borderRadius: "6px",
      [theme.breakpoints.down(1080)]: {},
      "&.selected-card": {
        background: theme.palette.background.lightFadedGray,
      },
    },
    cardContent: {
      // position: "relative",
      // display: "grid",
      // alignItems: "center",
      gap: "6px",
      // justifyContent: "space-between",
      // [theme.breakpoints.down(1080)]: {
      //   gridTemplateColumns: "repeat(2,auto)",
      //   gridairlineDetailsTemplateRows: "repeat(2,auto)",
      // },
      display: "flex",
      justifyContent: "space-between",
      // alignItems: "center",
      [theme.breakpoints.down(550)]: {
        // display: "grid",
        // gridTemplateColumns: "repeat(2,1fr)",
      },
    },
    /// 1 --------------------
    airlineDetails: {
      // width: "25%",
      padding: "15px 0 0 0",
      minWidth: 80,
      "& .carrier-details-div": {
        //display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
      },
      [theme.breakpoints.down(1350)]: {
        minWidth: 80,
        width: 80,
      },
      [theme.breakpoints.down(1080)]: {
        minWidth: 60,
        width: 60,
      },
      [theme.breakpoints.down(1010)]: {
        minWidth: 60,
        width: 60,
      },
      [theme.breakpoints.down(550)]: {
        width: 80,
        minWidth: 80,
      },
      [theme.breakpoints.down(450)]: {
        width: 68,
        minWidth: 68,
      },
      "& .more-action-div": {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down(1350)]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
        [theme.breakpoints.down(650)]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      },
    },

    airline_image: {
      width: "fit-content",
      marginRight: 5,
      display: "flex",
      flexDirection: "column",
      "& img": {
        height: "30px",
        width: "30px",
        marginRight: 3,
        [theme.breakpoints.down(1080)]: {
          height: "24px",
          width: "24px",
        },
      },
      "& div": {
        minWidth: 50,
        [theme.breakpoints.down(550)]: {
          fontSize: 10.3,
          fontWeight: 700,
        },
        [theme.breakpoints.down(550)]: {
          fontSize: 10.3,
          fontWeight: 700,
        },
      },
    },

    airline_title: {
      width: "fit-content",
      margin: "4px 0",
    },
    airline_detailButton: {
      cursor: "pointer",
      marginRight: 10,
      [theme.breakpoints.down(550)]: {
        marginRight: 0,
      },
      "& span": {
        //border: `2px solid ${theme.palette.primary.main}`,
        //borderRadius: "20px",
        //padding: "4px 8px",
        padding: "4px 0px",
        fontSize: "10px",
        color: "#F47008",
      },
    },

    /// 2 --------------------
    flightDetails: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 0 0 0",
      // justifyContent: "center",
      width: "35%",
      minWidth: 240,
      [theme.breakpoints.down(1350)]: {
        width: "40%",
        minWidth: 200,
      },
      [theme.breakpoints.down(1060)]: {
        width: "30%",
        minWidth: 180,
      },
      [theme.breakpoints.down(1020)]: {
        width: "30%",
        minWidth: 170,
      },
      [theme.breakpoints.down(550)]: {
        width: 160,
        minWidth: 160,
      },
    },
    flightDetails_Timings: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      [theme.breakpoints.down(1240)]: {
        gap: "10px",
        textAlign: "center",
      },
    },
    departure: {
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        textAlign: "left",
      },
    },
    duration: {
      minWidth: 50,
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        textAlign: "left",
      },
      [theme.breakpoints.down(400)]: {
        minWidth: 20,
        textAlign: "left",
      },
    },
    arrival: {
      textAlign: "right",
    },
    flightDetails_Itinaries: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateRows: "repeat(1,1fr)",
      "& svg": {
        height: "13px",
        [theme.breakpoints.down(1080)]: {
          height: "10px",
        },
      },
      "& .baggage": {
        width: 150,
        [theme.breakpoints.down(1080)]: {
          width: 50,
          whiteSpace: "nowrap",
        },
      },
      "& .add-service-item": {
        display: "flex",
        // width: 30,
        alignItems: "center",
        [theme.breakpoints.down(1080)]: {
          width: 50,
          whiteSpace: "nowrap",
        },
        "& span": {
          [theme.breakpoints.down(1080)]: {
            width: 50,
            whiteSpace: "nowrap",
          },

          // overflowX: "hidden",
        },
      },
    },
    flightDetails_Share: {
      [theme.breakpoints.down(650)]: {
        marginLeft: 5,
      },
      marginTop: 0,
      display: "flex",
      justifyContent: "flex-start",
      "& .share-dropdown-toggle-button": {
        cursor: "pointer",
        borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
      },
      "& .share-div": {
        marginTop: 5,
        position: "relative",
      },
      "& .share-dropdown": {
        position: "absolute",
        top: -36,
        left: -40,
        borderRadius: 3,
        padding: "2px 15px",
        backgroundColor: theme.palette.primary.disabled,
        "& i": {
          color: theme.palette.primary.main,
        },
      },
      "& .share-button": {
        margin: "2px 8px",
        cursor: "pointer",
        "& i": {
          fontSize: 18,
        },
      },
    },

    /// 3 --------------------
    priceDetails: {
      width: "25%",
      display: "flex",
      alignItems: "center",
      position: "relative",
      justifyContent: "flex-end",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      [theme.breakpoints.down(1010)]: {
        width: "30%",
      },
      [theme.breakpoints.down(1000)]: {
        marginLeft: 10,
      },
      [theme.breakpoints.down(990)]: {
        marginLeft: 2,
        width: "25%",
      },
      [theme.breakpoints.down(951)]: {
        marginLeft: 2,
        width: "25%",
      },
      // marginBottom:10,
      [theme.breakpoints.between(550, 960)]: {
        width: showNetFare ? 140 : "18%",
        justifyContent: showNetFare ? "flex-start" : "center",
      },
      [theme.breakpoints.down(550)]: {
        width: showNetFare ? 100 : "100%",
        // height: "60px !important",
      },
    },
    FlightPricesLabel: {
      //marginBottom: "16px",
      //marginTop:13,
      marginBottom: "10px",
      marginTop: 0,
      marginRight: 0,
      alignItems: "flex-start",
      "& .MuiTypography-root.MuiTypography-body1": {
        maxHeight: 62,
        // overflowY: "hidden",
        overflowX: "show",
        lineHeight: 1.25,
      },
      "& .MuiFormControlLabel-label": {
        "& p": {
          margin: 0,
          padding: 0,
          fontSize: "18px",
          fontWeight: "700",
          color: theme.palette.primary.darkText,
          position: "relative",
          "& span": {
            fontSize: 16,
            marginLeft: 15,
            position: "absolute",
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
            "&.price-seat": {
              top: 1,
              left: 55,
            },
            "&.price-refundable": {
              top: -3,
              left: 75,
            },
            "&.price-baggage": {
              top: 0,
              left: 93,
            },
          },
          [theme.breakpoints.down(1320)]: {
            fontSize: "12px",
          },
        },
        "& span.fare-type": {
          display: "block",
          margin: 0,
          padding: 0,
          fontSize: "12px",
          fontWeight: 600,
          width: 100,
          overflowWrap: "break-word",
          marginLeft: 0,
          color: theme.palette.text.secondary,
          // [theme.breakpoints.down(1320)]: {
          //   fontSize: "6px",
          // },
          // [theme.breakpoints.down(450)]: {
          //   width: 30,
          // },
        },
      },
      "& .nf-root": {
        marginLeft: -6,
        [theme.breakpoints.down(992)]: {
          marginLeft: showNetFare ? 0 : -6,
        },
      },
      "& .nf-span": {
        fontSize: "0.54rem",
        [theme.breakpoints.down(992)]: {
          marginLeft: showNetFare ? 0 : "unset",
          fontSize: 8,
        },
      },
      "& .add-sign": {
        margin: "0 4px",
      },
      "& .rupee-sign": {
        marginLeft: 6,
        marginRight: 3,
      },
      "& .base-fare": {
        color: theme.palette.primary.danger,
      },
      "& .earning": {
        color: theme.palette.primary.success,
      },
    },
    priceRefundable: {
      top: -3,
      left: 75,
    },
    // -----------------------------
    f24b: {
      fontSize: "20px",
      fontWeight: 700,
      [theme.breakpoints.down(1240)]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down(500)]: {
        fontSize: "13px",
      },
    },
    f20b: {
      fontSize: "20px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "16px",
      },
    },

    f16b: {
      fontSize: "16px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
    f14b: {
      fontSize: "14px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
    f12b: {
      fontSize: "12px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      },
    },
    f12r: {
      fontSize: "12px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "11px",
      },
    },
    f10r: {
      fontSize: "10.5px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "6px",
      },
    },
    f8r: {
      fontSize: "10px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      },
    },
    pl5: {
      paddingLeft: "5px !important",
    },

    showMoreButton: {
      cursor: "pointer",
      right: 15,
      position: "absolute",
      // top: showMoreFares === true ? "unset" : "100px",
      bottom: showMoreFares === false ? "0" : "-10px",
      [theme.breakpoints.down(992)]: {
        top: "unset",
        bottom: "-10px",
      },
      [theme.breakpoints.down(1320)]: {
        // width: "100%",
        right: 0,
      },
      [theme.breakpoints.between(1320, 1500)]: {
        bottom: showNetFare ? 0 : showMoreFares ? "-10px" : "unset",
        top: showNetFare ? "unset" : showMoreFares ? "unset" : "100px",
      },
      "& span": {
        fontSize: 10,
        fontWeight: 500,
        float: "right",
        textDecoration: "underline",
      },
    },
    seatsLeft: {
      color: "#DF3049",
      fontWeight: 500,
    },
    onwardAction: {
      width: "10%",
      [theme.breakpoints.down(550)]: {
        width: "100%",
      },
    },
    onwardBookNowAction: {
      boxShadow: "0 10px 10px -7px rgba(0,0,0,0.40)",
      width: "100%",
      background: theme.palette.buttons.bookNowBtn,

      // cursor: "pointer",
      color: theme.palette.primary.lightText,
      cursor: "not-allowed",
      padding: "12px 24px",
      borderRadius: "6px",
      cursor: "pointer",
      "& p": {
        textAlign: "center",
        fontWeight: "700",
        [theme.breakpoints.down(1120)]: {
          fontSize: 13,
        },
      },
      [theme.breakpoints.down(1161)]: {
        padding: "8px 10px",
      },
    },
  }));
};
