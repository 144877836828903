import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 
import Button from "components/CustomButtons/Button.js";

import { InputAdornment, FormControl } from '@material-ui/core';
import {Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import OaCard from 'oahoc/OaCard';
import OaFormFieldAgentList from 'components/OaFormFieldAgentList';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import AgentDetails from 'pages/agent/AgentDetails';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import {scrollToTop} from 'oautils/oaCommonUtils';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import {localforageGetItem} from 'oautils/oaForageUtils';

const B2bBalanceManagerFunds = (props) => {

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [balance, setBalance] = useState('-');
    const [validationRules, setValidationRules] = useState({});
    
    function updateBalanceField(agent){
        setBalance(agent.balance);
        //console.log(agent);
    }

    useEffect(() => {
        if(props.agencyId !== undefined){
            localforageGetItem('agency-list-1', function(error, slist){
                let list = JSON.parse(slist);
                if(!error && slist){
                    let data = list.find(o => o.agencyId === props.agencyId);
                    setBalance(data.balance);
                }
            });
            let validationRules = {
                amount: Yup.number()
                    .required('Amount is required')
                    .typeError('Amount must be a number')
                    .positive('Amount is not valid')
            }
            setValidationRules(validationRules);
        }else{
            let validationRules = {
                agencyId: Yup.mixed().required('Please select an Agent'),
                amount: Yup.number()
                    .required('Amount is required')
                    .typeError('Amount must be a number')
                    .positive('Amount is not valid')
            }
            setValidationRules(validationRules);
        }
    }, [props.agencyId])
    return (
        <GridContainer>
        {props.agencyId && 
            (
                <GridItem xs={12} sm={12} md={12}>
                <OaCard>
                    <AgentDetails agencyId={props.agencyId} />
                </OaCard>
                </GridItem>
            )
        }
        <GridItem xs={12} sm={12} md={6}>
        <OaCard> 
            <OaFormAlerts 
                isSaving={isSaving} 
                isSuccess={isSuccess} 
                isError={isError} 
                infoText={infoText} 
                setIsError={setIsError} 
                setIsSuccess={setIsSuccess} 
            />
            <Formik 
                initialValues={{
                    agencyId: '',
                    transactionType: 'credit',
                    amount: '',
                    remarks: ''
                }}
                validationSchema={Yup.object().shape(validationRules)}
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                    setIsError(false);
                    setIsSuccess(false);
                    setSubmitting(true);
                    setIsSaving(true);
                    scrollToTop(0);

                    if(props.agencyId) searchParams.agencyId = props.agencyId;

                    apiCall(WebApi.addB2bBalanceTransferRequest, searchParams, (response) => {
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        setInfoText(response.message);
                        if(response.success === true) {
                            enableReinitialize=true;
                            resetForm(true);
                        }
                    });
            }}>
                {({ values, isSubmitting, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>

                        {props.agencyId === undefined &&
                            <>
                            <FormControl fullWidth={true}>
                                <OaFormFieldAgentList label="Agency" setFieldValue ={setFieldValue} required={true} changeHandler={updateBalanceField}/>
                            </FormControl>
                            <ErrorMessage name="agency" component="div" className="error"/>
                            </>
                        }

                        <OaFormTextField label="Balance" value={`Rs. ${balance}`} disabled={true}/>

                        <OaFormSelectField 
                            label="Type" name="transactionType" value={values.transactionType}
                            options={[
                                {value: 'credit', label: 'Fund Transfer'},
                                {value: 'debit', label: 'Fund Reversal'}
                            ]}
                        />



                        <OaFormTextField 
                            required={true} label="Amount" name="amount" 
                            InputProps={{startAdornment: (<InputAdornment position="start">Rs. </InputAdornment>)}}/>
                        <ErrorMessage name="amount" component="div" className="error"/>

                        <OaFormTextField name="remarks" label="Remarks" multiline={true} rows="5" />

                        <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}/>
                    </Form>
                )}
            </Formik>
        </OaCard>
        </GridItem>
        </GridContainer>
    )
}

export default B2bBalanceManagerFunds;
