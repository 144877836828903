import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "./forms/LoginForm";
import {
    Grid,
    Tab,
    Tabs,
    AppBar,
    Box,
} from "@material-ui/core";
import { getQueryVar, getCurrentTab } from "oautils/oaCommonUtils.js";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
        position: "relative",
        "& .section-container": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            height: "auto",
            padding:'1rem',
           
        },
        "& .section-form-container": {
            maxWidth: 1200,
            padding: "20px 40px 30px",
            background: theme.palette.background.light,
        },
        "& .login-form-wrapper": {
            width: "100%",
            boxShadow: "0px 0px 25px 0px rgba(125,124,125,1)",
            MozBoxShadow: "0px 0px 25px 0px rgba(125,124,125,1)",
            WebkitBoxShadow: "0px 0px 25px 0px rgba(125,124,125,1)",
            "& .login-form-inner-container": {
                "& .login-form-tabs-wrapper": {
                    backgroundColor: theme.palette.background.light,
                    minWidth: 450,
                    [theme.breakpoints.down(960)]: {
                        minWidth: "100%",
                    },
                    "& .MuiPaper-elevation4": {
                        boxShadow: "none",
                    },
                    "& .MuiTabs-fixed": {
                        backgroundColor: theme.palette.primary.main,
                        overflow: "visible",
                    },
                    "& .MuiTabs-flexContainer": {
                        backgroundColor: theme.palette.background.light,
                        [theme.breakpoints.down(769)]: {
                            justifyContent: "center",
                        },
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: theme.palette.secondary.darkOrange,
                        height: 48,
                        transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s",
                        transform: "translate3d(0px, 0px, 0px)",
                        boxShadow: "0 16px 26px -10px rgb(215 156 253), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(244 67 54 / 20%)!important",
                        // borderRadius: 4,
                        width: 150,
                        [theme.breakpoints.down(769)]: {
                            display: "none",
                        },
                    },
                    "& .MuiTab-root": {
                        minWidth: 150,
                        minHeight: 48,
                        backgroundColor: theme.palette.primary.disabled,
                        // backgroundColor: "#ef6614",
                        overflow: "visible",
                        [theme.breakpoints.down(769)]: {
                            width: 100,
                        },
                        [theme.breakpoints.down(345)]: {
                            width: 80,
                            "& .MuiTab-wrapper": {
                                fontSize: 10,
                            },
                        },
                    },
                    "& .Mui-selected": {
                        "& span": {
                            color: theme.palette.primary.lightText,
                            // backgroundColor: theme.palette.secondary.darkOrange,
                            zIndex: 1,
                        },
                        [theme.breakpoints.down(769)]: {
                            backgroundColor: theme.palette.secondary.darkOrange,
                        },
                    },
                    "& .MuiTouchRipple-root": {
                        display: "none",
                    },
                    "& .tabs-content-wrapper": {
                        minHeight: 285,
                        maxHeight: 330,
                        [theme.breakpoints.down(1024)]: {
                            height: 310,
                            width: 400,
                        },
                        [theme.breakpoints.down(960)]: {
                            height: 310,
                            width: "100%",
                        },
                        [theme.breakpoints.down(500)]: {
                            "& .MuiBox-root": {
                                margin: 0,
                            },
                        },
                        [theme.breakpoints.down(345)]: {
                            width: 275,
                        },
                    },
                },
                [theme.breakpoints.down(1024)]: {
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
            [theme.breakpoints.down(1024)]: {
                width: "100%",
            },
        }
    }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box m={4}>{children}</Box>}
        </div>
    );
}

const LoginSection = (props) => {

    const classes = useStyles();
    const defaultTab = "agent";
    const queryVar = getQueryVar({ ...props, q: "priTab" });
    const currentTabName = getCurrentTab(queryVar, defaultTab);

    const getCurrentTabIndex = (key) => {
        switch (key) {
            case "agent":
                return 0;

            case "distributor":
                return 1;

            case "admin":
                return 2;

            default:
                return 0;
        }
    };
    const [inputData, setInputData] = useState({
        currentTab: getCurrentTabIndex(currentTabName),
    });
    
    return (
        <div className={classes.root}>
            <div className="section-container">
                <div className="login-form-wrapper">
                    <Grid container className="login-form-inner-container">
                        <Grid item className="login-form-tabs-wrapper">
                            <Box className="tabs-nav-wrapper">
                                <AppBar
                                    position="static"
                                    color="default"
                                    className="login-tabs-appbar"
                                >
                                    <Tabs
                                        value={inputData.currentTab}
                                        onChange={(e, i) =>
                                            setInputData({ ...inputData, currentTab: i })
                                        }
                                        className="login-tabs"
                                    >
                                        <Tab label="Agent" />

                                        <Tab label="Distributor" />

                                        {/* <Tab label="Admin" /> */}
                                    </Tabs>
                                </AppBar>
                            </Box>

                            <Box className="tabs-content-wrapper">
                                <TabPanel value={inputData.currentTab} index={0}>
                                    <LoginForm className="login-form" currentTab={inputData.currentTab} history={props.history} />
                                </TabPanel>

                                <TabPanel value={inputData.currentTab} index={1}>
                                    <LoginForm className="login-form" currentTab={inputData.currentTab} />
                                </TabPanel>

                                {/* <TabPanel value={inputData.currentTab} index={2}>
                                    <LoginForm className="login-form" currentTab={inputData.currentTab} />
                                </TabPanel> */}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginSection);
