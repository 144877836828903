import React, { useState } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { Collapse, Grid, makeStyles, MenuItem, Popper, TextField, Typography } from "@material-ui/core";
import { ErrorMessage } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import countries from "json/country_dial_codes.json";
import dateFnsFormat from "date-fns/format";

const useStyles = makeStyles((theme) => ({
    containerRoot: {
        "& .pax-input-container": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            marginLeft:40,
            marginTop: 15
        },
        "& .pax-input-form-group": {
            width: "23%",
            padding: "5px 10px",
            marginRight: "5px",
        },
        "& .pax-input-25p": {
            width: "25%",
        },
        "& .pax-input-16p": {
            width: "16.6%",
        },
        "& .pax-input-12p": {
            width: "17.9%",
            marginTop:"8px",
        },
        "& .pax-input-8p": {
            width: "8%",
        },
    },
    gridRoot: {
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: theme.palette.primary.main
        },
    },
    heading: {
        color: theme.palette.primary.main,
    },
    text1: {
        color: theme.palette.primary.main,
        fontSize: "large",
    },
    alignItems: {
        // textAlign: "center",
        // width:"100%"
    },
    button: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            background: theme.palette.primary.sub,
            color: theme.palette.primary.contrastText,
        },
        "&:disabled": {
            background: theme.palette.primary.disabled,
            cursor: "no-drop",
            color: theme.palette.primary.lightText,
            "&:hover": {
                background: theme.palette.primary.disabled,
                cursor: "no-drop",
            },
        },
    },
}));

const Passenger = (props) => {

    const classes = useStyles();
    const domInt = props.flightdetails.domesticInternational;
    const [openAddMoreDetail, setOpenAddMoreDetail] = useState(false);

    const visatypes = [
        { visatype: "-1", visalabel: "Select Visa Type" },
        { visatype: "business", visalabel: "Business" },
        { visatype: "student", visalabel: "Student" },
        { visatype: "tourist", visalabel: "Tourist" },
        { visatype: "work", visalabel: "Work" },
    ];

    const customPopper = function (props) {

        return (
            <Popper
                {...props}
                // anchorEl={departAutocompleteEl.current.children[0]}
                placement="bottom"
                style={{}}
            />
        );
    };

    const createDate = (days, months, years) => {
        var date = new Date(props.flightdetails.arrivalDate);
        date.setDate(date.getDate() - days);
        date.setMonth(date.getMonth() - months);
        date.setFullYear(date.getFullYear() - years);
        return date;
    }

    return (
        <>
            {props.paxcategory && props.paxcategory == "adult" &&
                <div key={props.index} className={classes.containerRoot} style={{ width: "100%" }}>
                    <div className="pax-input-container">
                        <div className="pax-input-form-group pax-input-8p">
                            <TextField
                                select
                                label="Title"
                                fullWidth
                                name={`adult[${props.index}].title`}
                                onChange={props.handlechange}
                                variant="outlined"
                                className="select"
                                style={{ width: 66, marginLeft: -7 }}
                                InputProps={{
                                    className: classes.gurjeet,
                                }}
                            >
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                            </TextField>
                            <ErrorMessage
                                name={`adult[${props.index}].title`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">
                            <TextField
                                label="First Name"
                                fullWidth
                                name={`adult[${props.index}].firstName`}
                                onChange={props.handlechange}
                                variant="outlined"
                                className={classes.inputLabel}
                            />
                            <ErrorMessage
                                name={`adult[${props.index}].firstName`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">
                            <TextField
                                label="Last Name"
                                fullWidth
                                name={`adult[${props.index}].lastName`}
                                onChange={props.handlechange}
                                variant="outlined"
                            />
                            <ErrorMessage
                                name={`adult[${props.index}].lastName`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">

                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    name={`adult[${props.index}].dob`}
                                    label="DOB"
                                    inputVariant="outlined"
                                    disableToolbar
                                    disableFuture
                                    autoOk={true}
                                    value={props.values.adult[props.index].dob}
                                    onChange={(date) =>
                                        props.setfieldvalue(
                                            `adult[${props.index}.dob]`,
                                            date
                                        )
                                    }
                                    maxDate={createDate(-1, 0, 12)}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    style={{ width: "100%" }}
                                />

                            </MuiPickersUtilsProvider>
                        </div>
                        {domInt && domInt != "Domestic" &&
                            <div className="pax-input-form-group">
                                <a onClick={() => setOpenAddMoreDetail(!openAddMoreDetail)} style={{ cursor: "pointer" }}>Add More Details</a>
                            </div>}
                    </div>
                    {domInt && domInt != "Domestic" &&
                        <div>
                            <Collapse in={openAddMoreDetail}>
                                <div className="pax-input-container">
                                    <div className="pax-input-form-group pax-input-25p">
                                        <Autocomplete
                                            fullWidth
                                            name={`adult[${props.index}].visatype`}
                                            PopperComponent={customPopper}
                                            options={visatypes || []}
                                            getOptionLabel={(o) =>
                                                o
                                                    ? `${o.visalabel}`
                                                    : ""
                                            }
                                            // defaultValue={visatypes && visatypes.filter(visas => { return visas.visatype === "-1" })[0]}
                                            value={props.values.adult[props.index].visatype}
                                            getOptionSelected={(option, value) =>
                                                option.code == props.value.visatype
                                            }
                                            onChange={(event, newValue) => {
                                                // console.log(newValue);
                                                if (newValue != null) {
                                                    props.setFieldValue(`adult[${props.index}].visatype`, newValue.visatype);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    color="secondary"
                                                    label="Visa Type"
                                                />
                                            )}
                                            disablePortal={true}
                                        />
                                    </div>

                                    <div className="pax-input-form-group pax-input-16p">
                                        <TextField
                                            name={`adult[${props.index}].passportno`}
                                            value={props.values.adult[props.index].passportno}
                                            onChange={e => {
                                                props.setFieldValue(`adultPax[${props.index}].passportno`, e.target.value)
                                            }}
                                            fullWidth
                                            label="Passport No."
                                        />
                                    </div>

                                    <div className="pax-input-form-group pax-input-12p">
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                name={`adult[${props.index}].passportidate`}
                                                minDate={createDate(0, 0, 10)}
                                                disableFuture
                                                className={classes.paxInfoDatePicker}
                                                fullWidth
                                                color="secondary"
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                autoOk={true}
                                                value={props.values.adult[props.index].passportidate}
                                                onChange={(e, v) => {
                                                    const formatted = dateFnsFormat(new Date(e), "dd-MMM-yyyy");
                                                    props.setFieldValue(`adult[${props.index}].passportidate`, formatted);
                                                }}
                                                label="Issuing Date"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="pax-input-form-group pax-input-12p">
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                name={`adult[${props.index}].passportexpire`}
                                                maxDate={createDate(0, 0, -10)}
                                                disablePast
                                                className={classes.paxInfoDatePicker}
                                                fullWidth
                                                color="secondary"
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                autoOk={true}
                                                value={props.values.adult[props.index].passportexpire}
                                                onChange={(e, v) => {
                                                    const formatted = dateFnsFormat(new Date(e), "dd-MMM-yyyy");
                                                    props.setFieldValue(`adult[${props.index}].passportexpire`, formatted);
                                                }}
                                                label="Expiry Date"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="pax-input-form-group pax-input-25p">
                                        <Autocomplete
                                            fullWidth
                                            name={`adult[${props.index}].passporticountry`}
                                            PopperComponent={customPopper}
                                            options={countries || []}
                                            getOptionLabel={(o) =>
                                                o
                                                    ? `${o.name} (${o.code})`
                                                    : ""
                                            }
                                            defaultValue={countries.filter(ctry => { return ctry.code === "IN" })[0]}
                                            getOptionSelected={(option, value) =>
                                                option.code == value.passporticountry
                                            }
                                            onChange={(event, newValue) => {
                                                // console.log(newValue);
                                                if (newValue != null) {
                                                    props.setFieldValue(`adult[${props.index}].passporticountry`, newValue.code);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    color="secondary"
                                                    label="Issuing Country"
                                                />
                                            )}
                                            disablePortal={true}
                                        />
                                    </div>
                                </div>
                            </Collapse>
                        </div>}
                </div>}

            {props.paxcategory && props.paxcategory == "child" &&
               <div key={props.index} className={classes.containerRoot} style={{ width: "100%" }}>
                   <div className="pax-input-container">
                   <div className="pax-input-form-group pax-input-8p">
                            <TextField
                                select
                                label="Title"
                                fullWidth
                                name={`child[${props.index}].title`}
                                onChange={props.handlechange}
                                variant="outlined"
                                className="select"
                                style={{ width: 66, marginLeft: -7 }}
                                InputProps={{
                                    className: classes.gurjeet,
                                }}
                            >
                                <MenuItem value="Mas">Master</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                            </TextField>
                            <ErrorMessage
                                name={`child[${props.index}].title`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">
                            <TextField
                                label="First Name"
                                fullWidth
                                name={`child[${props.index}].firstName`}
                                onChange={props.handlechange}
                                variant="outlined"
                                className={classes.inputLabel}
                            />
                            <ErrorMessage
                                name={`child[${props.index}].firstName`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">
                            <TextField
                                label="Last Name"
                                fullWidth
                                name={`child[${props.index}].lastName`}
                                onChange={props.handlechange}
                                variant="outlined"
                            />
                            <ErrorMessage
                                name={`child[${props.index}].lastName`}
                                component="div"
                                className="error"
                            />
                        </div>
                         <div className="pax-input-form-group">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    name={`child[${props.index}].dob`}
                                    label="DOB"
                                    inputVariant="outlined"
                                    disableToolbar
                                    disableFuture
                                    autoOk={true}
                                    value={props.values.child[props.index].dob}
                                    onChange={(date) =>
                                        props.setfieldvalue(
                                            `child[${props.index}.dob]`,
                                            date
                                        )
                                    }
                                    maxDate={createDate(-1, 0, 2)}
                                    minDate={createDate(0, 0, 12)}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    style={{ width: "100%" }}
                                />

                            </MuiPickersUtilsProvider>
                        </div>
                        {domInt && domInt != "Domestic" &&
                           <div className="pax-input-form-group">
                                <a onClick={() => setOpenAddMoreDetail(!openAddMoreDetail)} style={{ cursor: "pointer" }}>Add More Details</a>
                            </div>}
                    </div>
                    {domInt && domInt != "Domestic" &&
                       <div>
                            <Collapse in={openAddMoreDetail}>
                            <div className="pax-input-container">
                            <div className="pax-input-form-group pax-input-25p">
                                        <Autocomplete
                                            fullWidth
                                            name={`child[${props.index}].visatype`}
                                            PopperComponent={customPopper}
                                            options={visatypes || []}
                                            getOptionLabel={(o) =>
                                                o
                                                    ? `${o.visalabel}`
                                                    : ""
                                            }
                                            // defaultValue={visatypes && visatypes.filter(visas => { return visas.visatype === "-1" })[0]}
                                            value={props.values.child[props.index].visatype}
                                            getOptionSelected={(option, value) =>
                                                option.code == props.value.visatype
                                            }
                                            onChange={(event, newValue) => {
                                                // console.log(newValue);
                                                if (newValue != null) {
                                                    props.setFieldValue(`child[${props.index}].visatype`, newValue.visatype);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    color="secondary"
                                                    label="Visa Type"
                                                />
                                            )}
                                            disablePortal={true}
                                        />
                                    </div>

                                    <div className="pax-input-form-group pax-input-16p">
                                            <TextField
                                                name={`child[${props.index}].passportno`}
                                                value={props.values.child[props.index].passportno}
                                                onChange={e => {
                                                    props.setFieldValue(`child[${props.index}].passportno`, e.target.value)
                                                }}
                                                fullWidth
                                                label="Passport No."
                                            />
                                        </div>
                                    

                                        <div className="pax-input-form-group pax-input-12p">
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <KeyboardDatePicker
                                                    name={`child[${props.index}].passportidate`}
                                                    minDate={createDate(0, 0, 10)}
                                                    disableFuture
                                                    className={classes.paxInfoDatePicker}
                                                    fullWidth
                                                    color="secondary"
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    autoOk={true}
                                                    value={props.values.child[props.index].passportidate}
                                                    onChange={(e, v) => {
                                                        const formatted = dateFnsFormat(new Date(e), "dd-MMM-yyyy");
                                                        props.setFieldValue(`child[${props.index}].passportidate`, formatted);
                                                    }}
                                                    label="Issuing Date"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    

                                        <div className="pax-input-form-group pax-input-12p">
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <KeyboardDatePicker
                                                    name={`child[${props.index}].passportexpire`}
                                                    maxDate={createDate(0, 0, -10)}
                                                    disablePast
                                                    className={classes.paxInfoDatePicker}
                                                    fullWidth
                                                    color="secondary"
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    autoOk={true}
                                                    value={props.values.child[props.index].passportexpire}
                                                    onChange={(e, v) => {
                                                        const formatted = dateFnsFormat(new Date(e), "dd-MMM-yyyy");
                                                        props.setFieldValue(`child[${props.index}].passportexpire`, formatted);
                                                    }}
                                                    label="Expiry Date"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    

                                        <div className="pax-input-form-group pax-input-25p">
                                            <Autocomplete
                                                fullWidth
                                                name={`child[${props.index}].passporticountry`}
                                                PopperComponent={customPopper}
                                                options={countries || []}
                                                getOptionLabel={(o) =>
                                                    o
                                                        ? `${o.name} (${o.code})`
                                                        : ""
                                                }
                                                defaultValue={countries.filter(ctry => { return ctry.code === "IN" })[0]}
                                                getOptionSelected={(option, value) =>
                                                    option.code == value.passporticountry
                                                }
                                                onChange={(event, newValue) => {
                                                    // console.log(newValue);
                                                    if (newValue != null) {
                                                        props.setFieldValue(`child[${props.index}].passporticountry`, newValue.code);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        color="secondary"
                                                        label="Issuing Country"
                                                    />
                                                )}
                                                disablePortal={true}
                                            />
                                        </div>
                                    
                                </div>
                            </Collapse>
                        </div>}
                </div>}

            {props.paxcategory && props.paxcategory == "infant" &&
               <div key={props.index} className={classes.containerRoot} style={{ width: "100%" }}>
                    <div className="pax-input-container">
                    <div className="pax-input-form-group pax-input-8p">
                            <TextField
                                select
                                label="Title"
                                fullWidth
                                name={`infant[${props.index}].title`}
                                onChange={props.handlechange}
                                variant="outlined"
                                className="select"
                                style={{ width: 66, marginLeft: -7 }}
                                InputProps={{
                                    className: classes.gurjeet,
                                }}
                            >
                                <MenuItem value="Mas">Master</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                            </TextField>
                            <ErrorMessage
                                name={`infant[${props.index}].title`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">
                            <TextField
                                label="First Name"
                                fullWidth
                                name={`infant[${props.index}].firstName`}
                                onChange={props.handlechange}
                                variant="outlined"
                                className={classes.inputLabel}
                            />
                            <ErrorMessage
                                name={`infant[${props.index}].firstName`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">
                            <TextField
                                label="Last Name"
                                fullWidth
                                name={`infant[${props.index}].lastName`}
                                onChange={props.handlechange}
                                variant="outlined"
                            />
                            <ErrorMessage
                                name={`infant[${props.index}].lastName`}
                                component="div"
                                className="error"
                            />
                        </div>
                        <div className="pax-input-form-group">

                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    name={`infant[${props.index}].dob`}
                                    label="DOB"
                                    inputVariant="outlined"
                                    disableToolbar
                                    disableFuture
                                    autoOk={true}
                                    value={props.values.infant[props.index].dob}
                                    onChange={(date) =>
                                        props.setfieldvalue(
                                            `infant[${props.index}.dob]`,
                                            date
                                        )
                                    }
                                    maxDate={createDate(0, 0, 2)}
                                    minDate={createDate(3, 0, 0)}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    style={{ width: "100%" }}
                                />

                            </MuiPickersUtilsProvider>
                        </div>
                        {domInt && domInt != "Domestic" &&
                            <div className="pax-input-form-group">
                                <a onClick={() => setOpenAddMoreDetail(!openAddMoreDetail)} style={{ cursor: "pointer" }}>Add More Details</a>
                            </div>}
                    </div>
                    {domInt && domInt != "Domestic" &&
                       <div>
                            <Collapse in={openAddMoreDetail}>
                            <div className="pax-input-container">
                            <div className="pax-input-form-group pax-input-25p">
                                        <Autocomplete
                                            fullWidth
                                            name={`infant[${props.index}].visatype`}
                                            PopperComponent={customPopper}
                                            options={visatypes || []}
                                            getOptionLabel={(o) =>
                                                o
                                                    ? `${o.visalabel}`
                                                    : ""
                                            }
                                            // defaultValue={visatypes && visatypes.filter(visas => { return visas.visatype === "-1" })[0]}
                                            value={props.values.infant[props.index].visatype}
                                            getOptionSelected={(option, value) =>
                                                option.code == props.value.visatype
                                            }
                                            onChange={(event, newValue) => {
                                                // console.log(newValue);
                                                if (newValue != null) {
                                                    props.setFieldValue(`infant[${props.index}].visatype`, newValue.visatype);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    color="secondary"
                                                    label="Visa Type"
                                                />
                                            )}
                                            disablePortal={true}
                                        />
                                    </div>

                                    <div className="pax-input-form-group pax-input-16p">
                                            <TextField
                                                name={`infant[${props.index}].passportno`}
                                                value={props.values.infant[props.index].passportno}
                                                onChange={e => {
                                                    props.setFieldValue(`infant[${props.index}].passportno`, e.target.value)
                                                }}
                                                fullWidth
                                                label="Passport No."
                                            />
                                        </div>
                                    

                                        <div className="pax-input-form-group pax-input-12p">
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <KeyboardDatePicker
                                                    name={`infant[${props.index}].passportidate`}
                                                    minDate={createDate(0, 0, 10)}
                                                    disableFuture
                                                    className={classes.paxInfoDatePicker}
                                                    fullWidth
                                                    color="secondary"
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    autoOk={true}
                                                    value={props.values.infant[props.index].passportidate}
                                                    onChange={(e, v) => {
                                                        const formatted = dateFnsFormat(new Date(e), "dd-MMM-yyyy");
                                                        props.setFieldValue(`infant[${props.index}].passportidate`, formatted);
                                                    }}
                                                    label="Issuing Date"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    

                                        <div className="pax-input-form-group pax-input-12p">
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <KeyboardDatePicker
                                                    name={`infant[${props.index}].passportexpire`}
                                                    maxDate={createDate(0, 0, -10)}
                                                    disablePast
                                                    className={classes.paxInfoDatePicker}
                                                    fullWidth
                                                    color="secondary"
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    autoOk={true}
                                                    value={props.values.infant[props.index].passportexpire}
                                                    onChange={(e, v) => {
                                                        const formatted = dateFnsFormat(new Date(e), "dd-MMM-yyyy");
                                                        props.setFieldValue(`infant[${props.index}].passportexpire`, formatted);
                                                    }}
                                                    label="Expiry Date"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    

                                        <div className="pax-input-form-group pax-input-25p">
                                            <Autocomplete
                                                fullWidth
                                                name={`infant[${props.index}].passporticountry`}
                                                PopperComponent={customPopper}
                                                options={countries || []}
                                                getOptionLabel={(o) =>
                                                    o
                                                        ? `${o.name} (${o.code})`
                                                        : ""
                                                }
                                                defaultValue={countries.filter(ctry => { return ctry.code === "IN" })[0]}
                                                getOptionSelected={(option, value) =>
                                                    option.code == value.passporticountry
                                                }
                                                onChange={(event, newValue) => {
                                                    // console.log(newValue);
                                                    if (newValue != null) {
                                                        props.setFieldValue(`infant[${props.index}].passporticountry`, newValue.code);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        color="secondary"
                                                        label="Issuing Country"
                                                    />
                                                )}
                                                disablePortal={true}
                                            />
                                        </div>
                                    
                                </div>
                            </Collapse>
                            </div>}
                </div>}
        </>
    );
}

export default Passenger;
