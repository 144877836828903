import React, {useState, useEffect} from 'react';
import OaCard from 'oahoc/OaCard';
import MobileSearchForm from './MobileSearchForm';


const MobileSearch = (props) => {
    useEffect(() =>{

    },[]);
    return (
        // <OaCard className='oa-card-rounded-top'  style={{'minHeight': 200}}>
             <MobileSearchForm />
        // </OaCard>
    )
}

export default MobileSearch;
