import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

const OaTextFieldAdorned = props => {
    return(
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                startAdornment: (
                    <InputAdornment position={props.adornpos}>
                        {props.adornico}
                    </InputAdornment>
                )
            }}
        />
    );
};

export default OaTextFieldAdorned;
