import React from "react";
import dateFnsFormat from "date-fns/format";
import { Box, makeStyles } from "@material-ui/core";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import { ArrowForward } from "@material-ui/icons";
import { useState } from "react";

const HotelItinary = (props) => {
  const classes = useStyles();
  const [hotelData, setHotelData] = useState(props.hotelData);

  console.log(props.hotelData);

  return (
    hotelData && (
      <>
        <div className={classes.root}>
          <Box className="topContainer">
            <div className="itin-group">
              <div className="itin-header">
                <span>Hotel Details</span>
              </div>

              <div className="itin-row">
                <div className="fieldName">Hotel Name:</div>
                <div className="fieldValue">{hotelData.hotelName}</div>
              </div>
              <div className="itin-row">
                <div className="fieldName">Address:</div>
                <div>{hotelData.addressLine1}</div>
              </div>
              <div className="itin-row">
                <div className="fieldName">City:</div>
                <div>{hotelData.city}</div>
              </div>
            </div>

            <div className="itin-group roomDetails">
              <div className="itin-header">
                <span>Room Details</span>
              </div>

              <div className="itin-row">
                <div className="fieldName">Number of Rooms:</div>
                <div>{hotelData.noOfRooms}</div>
              </div>
              <div className="itin-row">
                <div className="fieldName">Number of Guests:</div>
                <div>
                  {hotelData.hotelRoomsDetails[0].adultCount +
                    hotelData.hotelRoomsDetails[0].childCount}
                </div>
              </div>
              <div className="itin-row">
                <div className="fieldName">Type Of Room:</div>
                <div>{hotelData.hotelRoomsDetails[0].roomTypeName}</div>
              </div>
              <div className="itin-row">
                <div className="fieldName">Booking Date:</div>
                <div>
                  {hotelData.bookingDate
                    .slice(0, 10)
                    .split("-")
                    .reverse()
                    .join("-")}
                </div>
              </div>
            </div>
          </Box>
          <Box className="BottomContainer">
            <div className="itin-group">
              <div className="itin-header">
                <span>Passenger Details</span>
              </div>

              <Box className="passengerDetails">
                <div className="itin-row guestNameDetails">
                  <div className="fieldName">Guest Names:</div>
                  <Box className="nameContainer">
                    <div>
                      {hotelData.hotelRoomsDetails[0].hotelPassenger[0].title}{" "}
                      {
                        hotelData.hotelRoomsDetails[0].hotelPassenger[0]
                          .firstName
                      }{" "}
                      {
                        hotelData.hotelRoomsDetails[0].hotelPassenger[0]
                          .lastName
                      }
                    </div>
                  </Box>
                  <Box className="nameContainer">
                    <div>
                      {hotelData.hotelRoomsDetails[0].hotelPassenger[0].title}{" "}
                      {
                        hotelData.hotelRoomsDetails[0].hotelPassenger[0]
                          .firstName
                      }{" "}
                      {
                        hotelData.hotelRoomsDetails[0].hotelPassenger[0]
                          .lastName
                      }
                    </div>
                  </Box>
                </div>
                <Box>
                  <div className="itin-row">
                    <div className="fieldName">Booking Id:</div>
                    <div>{hotelData.hotelRoomsDetails[0].roomTypeName}</div>
                  </div>
                  <div className="itin-row">
                    <div className="fieldName">Status:</div>
                    <div>{hotelData.hotelBookingStatus}</div>
                  </div>
                  <div className="itin-row">
                    <div className="fieldName">Checkin Date:</div>
                    <div>
                      {hotelData.checkInDate
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    </div>
                  </div>
                  <div className="itin-row">
                    <div className="fieldName">Checkout Date:</div>
                    <div>
                      {hotelData.checkOutDate
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    </div>
                  </div>
                  <div className="itin-row">
                    <div className="fieldName">Amount Paid:</div>
                    <div>
                      ₹
                      {
                        hotelData.hotelRoomsDetails[0].price
                          .publishedPriceRoundedOff
                      }
                    </div>
                  </div>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </>
    )
  );
};

export default HotelItinary;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .topContainer": {
      display: "flex",
      justifyContent: "space-between",
      "& .itin-group": {
        width: "49%",

        marginBottom: 10,
        marginTop: 20,
      },
    },

    "& .BottomContainer": {
      width: "100%",
      "& .itin-group": {
        width: "100%",
        // marginTop: 10,
      },
    },
    "& p": {
      fontWeight: 400,
      fontSize: 13,
      margin: "0 0 0 0",
      "&.bold": {
        fontWeight: 700,
      },
    },
    "& .itin-group": {
      border: `solid 1px ${theme.palette.primary.disabled}`,
      padding: "10px 20px",
      borderRadius: 5,
    },
    "& .passengerDetails": {
      display: "flex",
      "& .fieldName": {
        minWidth: 100,
      },
      "& .guestNameDetails": {
        marginRight: 45,
        flexDirection: "column",
        "& .nameContainer": {
          display: "flex",
        },
      },
    },
    "& .itin-header": {
      margin: "5px 0 15px",
      "& span": {
        fontWeight: 600,
        fontSize: "1.1rem",
      },
    },
    "& .itin-row": {
      fontWeight: 700,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      [theme.breakpoints.down(768)]: {
        flexWrap: "wrap",
      },
      "& .fieldName": {
        fontWeight: 500,
        fontSize: 14,
        marginRight: 10,
        minWidth: 80,
      },
    },

    "& .itin-col-3": {
      width: "33.3%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-4": {
      width: "25%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-20": {
      width: "20%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-30": {
      width: "30%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-40": {
      width: "40%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-50": {
      width: "50%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-60": {
      width: "60%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-70": {
      width: "70%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-80": {
      width: "80%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .itin-col-100": {
      width: "100%",
    },
    "& .carrier-identity": {
      display: "flex",
      flexDirection: "row",
      marginBottom: 20,
      "& .carrier-icon": {
        margin: "0 8px 0 0",
        "& img": {
          height: 40,
        },
      },
      "& .carrier-name": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        fontWeight: 500,
      },
    },
    "& .carrier-itin": {
      "& .carrier-arrow": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 16,
        [theme.breakpoints.down(768)]: {
          paddingBottom: 16,
        },
      },
      "& .carrier-depart, .carrier-arrive": {
        textAlign: "center",
      },
    },
    "& .table-container": {
      marginBottom: 20,
      "& table": {
        padding: 0,
        width: "50%",
        [theme.breakpoints.down(1500)]: {
          width: "70%",
        },
        [theme.breakpoints.down(992)]: {
          width: "90%",
          overflowX: "auto",
        },
      },
      "& tr": {
        margin: "5px 0",
      },
      "& td": {
        fontWeight: 500,
      },
      "& td, th": {
        padding: "0 10px 0 0",
        textAlign: "left",
        fontSize: "0.83rem",
        [theme.breakpoints.down(768)]: {
          verticalAlign: "text-top",
        },
      },
    },
    "& .other-details": {
      "& span": {
        fontWeight: 600,
        marginLeft: 10,
      },
    },
  },
}));
