function buildErrorResponse(error){
    let response = {};
    response.data = [];
    response.message = error.toString();
    response.success = false;
    return response;
}

export const apiCall = (fx, request, callback) => {
    fx(request, (response) => {
        callback(response);
    },(error)=>{
        callback(buildErrorResponse(error));
    })
}

export const apiCallByApiName = (fx, api, request, callback) => {
    fx(api, request, (response) => {
        callback(response);
    },(error)=>{
        callback(buildErrorResponse(error));
    })
}