import React, {useState, useEffect} from 'react';
import WebApi from "api/ApiConstants";
import BankAccountCard from 'pages/management/bank-account-details/BankAccountCard';
import OaCard from 'oahoc/OaCard';
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaTabBar from 'oahoc/OaTabBar';
import { apiCall } from 'oautils/oaDataUtils';
import BankAccountResult from 'pages/management/bank-account-details/BankAccountResult';

const BankAccount = (props) => {

    const [response, setResponse] = useState({});
    const [bankList, setBankList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    
    let tabs = [
        { name: 'bank-accounts', label: 'Bank Accounts', url: '/admin/management/bank-account' },
    ];
    let currentTab = 'bank-accounts';

    useEffect(() => {
        if(isLoading){
            apiCall(WebApi.getReceiveBankList, {}, (response) => {
                setResponse(response);
                setBankList(response.data);
                setIsLoading(false);
                setIsError(response.success===false);
            });
        }
    }, [isLoading]);

    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            {isLoading && <OaLoading />}
            {isError && <OaError details={response.message} />}
            {!isLoading && !isError && 
            <BankAccountCard bankList={bankList} isReceiver={true}/>
            }
        </OaCard>
    )
}
export default BankAccount;