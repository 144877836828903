import React, {useState, useRef} from 'react'
import {Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputAdornment } from '@material-ui/core';
import dateFnsFormat from 'date-fns/format';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateField from 'oahoc/OaFormDateField';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaCard from 'oahoc/OaCard';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {scrollToTop, inWords} from 'oautils/oaCommonUtils';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import OaAmountInWords from 'oahoc/OaAmountInWords';

const MasterBalanceManagerNewCheque = (props) => {

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [amountInWord, setAmountInWord] = useState('');
    const [num, setNum] = useState(null);

    let selBankId = 0;
    if(Object.keys(props.bankList).length > 0){
        selBankId = props.bankList[Object.keys(props.bankList)[0]].value.toString();
    }

    function _onKeyUp(event){
        const { value } = event.target;
        setAmountInWord(inWords(value));
        setNum(value);
    }
    
    return (
        <OaCard> 
            <OaFormAlerts 
                isSaving={isSaving} 
                isSuccess={isSuccess} 
                isError={isError} 
                infoText={infoText} 
                setIsError={setIsError} 
                setIsSuccess={setIsSuccess} 
            />
            <Formik 
                initialValues={{
                    depositBankId: selBankId,
                    chqddNo: '',
                    chqBank: '',
                    chqBankBranch: '',
                    amount: '',
                    depositedDateDisplay: new Date(),
                    depositedDate: dateFnsFormat(new Date(), 'dd-MM-yyyy'),
                    remarks: ''
                }}
                validationSchema={Yup.object().shape({
                    depositBankId: Yup.string().required('Please select a bank'),
                    chqddNo: Yup.string().required('Required'),
                    chqBank: Yup.string().required('Required'),
                    chqBankBranch: Yup.string().required('Required'),
                    amount: Yup.number()
                        .required('Amount is required')
                        .typeError('Amount must be a number')
                        .positive('Must not be a negative value'),
                    depositedDate: Yup.string().required('Please select a date')
                })}
                
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                    setIsError(false);
                    setIsSuccess(false);
                    setSubmitting(true);
                    setIsSaving(true);
                    scrollToTop(0);
                    searchParams.balanceRequestType = 'cheque';
                    searchParams.depositedDate = dateFnsFormat(searchParams.depositedDateDisplay, 'dd-MM-yyyy');
                    apiCall(WebApi.addBalanceTransferRequest, searchParams, (response) => {
                    //apiCall(WebApi.getError, searchParams, (response) => {
                        
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        setInfoText(response.message);
                        if(response.success === true) {
                            setInfoText('Deposit request created with ID: ' + response.data.uuid);
                            enableReinitialize=true;
                            resetForm(true);
                        }
                    });
                }}
            >
                {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>
                        <OaFormSelectField 
                            label="Bank Name"
                            name="depositBankId"
                            value={values.depositBankId}
                            options={props.bankList}
                        />

                        <OaFormTextField required={true} label="Cheque/DD No" name="chqddNo" />
                        <ErrorMessage name="chqddNo" component="div" className="error"/>

                        <OaFormTextField required={true} label="Cheque/DD Bank" name="chqBank"/>
                        <ErrorMessage name="chqBank" component="div" className="error"/>

                        <OaFormTextField required={true} label="Cheque/DD Bank Branch" name="chqBankBranch"/>
                        <ErrorMessage name="chqBankBranch" component="div" className="error"/>

                        <OaFormTextField 
                            required={true} label="Amount" name="amount"
                            InputProps={{startAdornment: (<InputAdornment position="start">Rs. </InputAdornment>)}} onKeyUp={_onKeyUp}/>
                            <OaAmountInWords num={num} />
                        <ErrorMessage name="amount" component="div" className="error"/>
                        
                        <OaFormDateField 
                            value={values.depositedDateDisplay}
                            selected={values.depositedDateDisplay}
                            onChange={date => setFieldValue('depositedDateDisplay', date)}
                            label="Cheque/DD Date"
                            name="depositedDate"
                            minDate={values.dateFromDisplay}
                        />
                        <ErrorMessage name="depositedDate" component="div" className="error"/>

                        <OaFormTextField name="remarks" label="Remarks" multiline={true} rows={1}/>
                        
                        <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} label={"Submit"} inReset={resetForm}/>
                    </Form>
                )}
            </Formik>
        </OaCard>
    )
}

export default MasterBalanceManagerNewCheque;
