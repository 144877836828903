import React, { useState } from "react";
import { Grid, Button, Typography, TextField, Modal } from "@material-ui/core";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
// import _ from "lodash";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import GridContainer from "components/Grid/GridContainer.js";

import OaFormTextField from "oahoc/OaFormTextField";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OaCard from "oahoc/OaCard";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaFormSelectField from "oahoc/OaFormSelectField";
import OaFormSaveButton from "oahoc/OaFormSaveButton";

const CancelTicket = (props) => {

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [alertTitle, setAlertTitle] = useState('');
    const [tid, setTid] = useState('0');
    const [isReasonVisible, setIsReasonVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const [trnType, setTrnType] = useState(1);
    const classes = useStyles();

    const body = (
        <div className={classes.modalStyle}>
            <div>
                <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClose} style={{ display: 'flex', marginLeft: 'auto', marginTop: -20, marginRight: -30 }}>
                    <CloseIcon />
                </IconButton>
            </div>
            <OaCard>
                <OaFormAlerts
                    isSaving={isSaving}
                    isSuccess={isSuccess}
                    isError={isError}
                    infoText={infoText}
                    alertTitle={alertTitle}
                    setIsError={setIsError}
                    setIsSuccess={setIsSuccess}
                />
                <Formik
                    initialValues={{ cancellationReason: "", cancellationReason: "" }}
                    validationSchema={Yup.object().shape({
                        cancellationReason: Yup.string().required('Cancellation reason is required'),
                        otherReason: Yup.string().required('other reason is required')
                    })}

                    onSubmit={(searchParams, { setSubmitting, resetForm, enableReinitialize }) => {
                        setIsSaving(true);
                        searchParams.tid = tid;
                        apiCall(WebApi.sendSuccessPNRCancellationRequest, searchParams, (response) => {
                            // console.log(response);
                            setIsError(!response.success);
                            setIsSuccess(response.success);
                            setSubmitting(false);
                            setIsSaving(false);
                            setInfoText(response.message);

                            if (response.success === true) {
                                setAlertTitle('Processed successfully');
                                // enableReinitialize=true;
                                // resetForm(true);
                            }
                        });

                    }
                    }
                >
                    {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                        <Form>
                            <OaFormSelectField
                                label="Reason For Cancellation*"
                                name="cancellationReason"
                                value={values.cancellationReason}
                                options={[
                                    { value: 'Fraud Credential', label: 'Fraud Credential' },
                                    { value: 'User request for Cancellation', label: 'User request for Cancellation' },
                                    { value: 'Other', label: 'Other' }
                                ]}
                                onChange={value => {
                                    setFieldValue('cancellationReason', value);
                                    setFieldValue('otherReason', value);
                                    cancellationReasonOnChange(value)
                                }}
                            />
                            <ErrorMessage name="cancellationReason" component="div" className="error" />

                            {isReasonVisible &&
                                <>
                                    <OaFormTextField label="Reason*" name="otherReason" />
                                    <ErrorMessage name="otherReason" component="div" className="error" />
                                </>
                            }

                            <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} label={'Confirm'} inReset={resetForm} />
                        </Form>
                    )}
                </Formik>
            </OaCard>
        </div>
    );

    const handleClose = () => {
        setIsSuccess(false);
        setIsError(false);
        setOpen(false);
    };

    const cancellationReasonOnChange = function (value) {
        if (value == 'Other') {
            setIsReasonVisible(true);
        } else {
            setIsReasonVisible(false);
        }
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    refId: "",
                    lastName: "",
                    email: "",
                    trType: "1",
                }}
                validationSchema={Yup.object().shape({
                    refId: Yup.string().required("Transaction id required"),
                    lastName: Yup.string().required("LastName is required"),
                    email: Yup.string()
                        .email()
                        .required("Email is required"),
                })}
                onSubmit={(
                    searchParams,
                    { setSubmitting, resetForm, enableReinitialize }
                ) => {
                    setIsSaving(true);
                    apiCall(WebApi.getPrintEticket, searchParams, (response) => {
                        // console.log("#######responsce", response);
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        const ticketType = (key) => {
                            switch (key) {
                                case "1":
                                    //window.gotoFlightTicketDetails(data.flightApplicationId);
                                    setTrnType(0);
                                    break;
                                // case "3":
                                //     // return "print/busticket";
                                //     setTrnType(2);
                                //     break;
                                case "2":
                                    // return "print/hotelticket";
                                    setTrnType(1);
                                    break;

                                default:
                                    break;
                            }
                        };
                        setInfoText(response.message);
                        if (response.success === true) {
                            // window.gotoFlightTicketDetails(response.data);
                            // enableReinitialize = true;
                            // resetForm(true);
                            // setTid(response.data);
                            // console.log("props", props);
                            //setOpen(true);
                            //props.history.push("/b2c/mybookings");
                            props.history.push({
                                pathname: "/b2c/mybookings",
                                state: {
                                    loggedInUserId: 0,
                                    transactionId: response.data,
                                    userType: "GUEST",
                                    trType: trnType,
                                }
                            });
                        }
                        // console.log("response", response);
                        // console.log("props", props);
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    enableReinitialize,
                    resetForm,
                }) => (
                    <Form>
                        <div className={classes.div}>
                            <GridContainer
                                className={classes.Grid}
                                md={4}
                                xs={12}
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography
                                        variant="h3"
                                        style={{ fontSize: 24 }}
                                        gutterBottom
                                    >
                                        Booked Ticket
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={4} justifyContent="space-between">
                                        <Grid item className={classes.checkItem}>
                                            <span className={classes.checkInput}>
                                                <Field type="radio" name="trType" value="1" />
                                            </span>
                                            <span className={classes.checkLabel}>Flight</span>
                                        </Grid>
                                        {/* <Grid item className={classes.checkItem}>
                                            <span className={classes.checkInput}>
                                                <Field type="radio" name="trType" value="2" />
                                            </span>
                                            <span className={classes.checkLabel}>Hotel</span>
                                        </Grid>
                                        <Grid item className={classes.checkItem}>
                                            <span className={classes.checkInput}>
                                                <Field type="radio" name="trType" value="4" />
                                            </span>
                                            <span className={classes.checkLabel}>Bus</span>
                                        </Grid> */}
                                    </Grid>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1">
                                        Transaction ID / Reference ID
                                    </Typography>
                                    {/* <OaFormTextField name="refId" /> */}
                                    <TextField
                                        name="refId"
                                        variant="outlined"
                                        value={values.refId}
                                        onChange={e => setFieldValue('refId', e.target.value)}
                                        fullWidth
                                    />
                                    <ErrorMessage
                                        name="refId"
                                        component="div"
                                        className="error"
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1">Passenger's Last Name</Typography>
                                    {/* <OaFormTextField name="lastName" /> */}
                                    <TextField
                                        name="lastName"
                                        variant="outlined"
                                        value={values.lastName}
                                        onChange={e => setFieldValue('lastName', e.target.value)}
                                        fullWidth
                                    />
                                    <ErrorMessage
                                        name="lastName"
                                        component="div"
                                        className="error"
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1">
                                        Passenger's E-mail Address
                                    </Typography>
                                    {/* <OaFormTextField name="email" /> */}
                                    <TextField
                                        name="email"
                                        variant="outlined"
                                        value={values.email}
                                        onChange={e => setFieldValue('email', e.target.value)}
                                        fullWidth
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="error"
                                    />
                                </Grid>
                                <Grid item md={12} container justifyContent="flex-start" xs={12}>
                                    <Button
                                        type="submit"
                                        className={classes.Button}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
                                <div className="field-error">{errors}</div>
                                )}
                                <div className={"row"}>
                                <div className={"col-12"}>
                                    <code>
                                    <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                                    </code>
                                </div>
                                <div className={"col-12"}>
                                    <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                                </div>
                                <div className={"col-12"}>
                                    <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                                </div>
                                </div> */}
                            </GridContainer>
                        </div>
                    </Form>
                )}
            </Formik>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

export default CancelTicket;

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.palette.font.primary,
        minHeight: "66vh",
        "& .MuiInputBase-input": {
            minHeight: 35
        },
    },
    div: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 50,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        "& .MuiOutlinedInput-root ": {
            position: "relative",
            borderRadius: "4px",
            height: "26px",
            background: theme.palette.background.light,
            borderColor: "none",
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    Button: {
        background: theme.palette.buttons.primary,
        letterSpacing: "1px",
        color: theme.palette.buttons.primaryContrastText,
        border: `solid 1px ${theme.palette.buttons.primary}`,
        fontSize: "14px",
        textTransform: "none",
        "&:hover": {
            color: theme.palette.buttons.primary,
            backgroundColor: theme.palette.buttons.primaryContrastText,
        },
    },
    typo: {
        color: theme.palette.primary.danger,
    },
    Grid: {
        //   marginLeft: "120px",
        //   marginRight: "120px",
        background: theme.palette.background.light,
        marginTop: "50px",
        // boxShadow: "0px 0px 15px -1px #989898",
        padding: 30,
        borderRadius: 20,
    },
    checkLabel: {
        marginLeft: "7px",
    },
    checkItem: {
        height: "50px",
    },
    checkInput: {
        marginRight: "3px",
    },
    modalStyle: {},
}));
