import React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  offer: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.5),
    wordSpacing: theme.typography.pxToRem(2),
    color: theme.palette.primary.lightText,
    backgroundColor: theme.palette.primary.tango,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    width: theme.typography.pxToRem(80),
    "clip-path": "polygon(86% 0, 100% 50%, 86% 100%, 0 100%, 0 0)",
  },
}));

const Offer = ({ discount }) => {
  const classes = useStyles();
  return (
    <Typography component="p" variant="caption" className={classes.offer}>
      {discount}% Off
    </Typography>
  );
};

export default Offer;
