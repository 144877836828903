import React, {useState, useEffect} from 'react';
import OaCard from 'oahoc/OaCard';
import OaPriTabs from 'oahoc/OaPriTabs';
import OaError from 'pages/components/OaError';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import AgentList from './AgentList';

const Agent = (props) => {

    const tabs = [
        {name: 'active', label: 'Active Agents', url: '/admin/user/agents/active'},
        {name: 'inactive', label: 'Inactive Agents', url: '/admin/user/agents/inactive'}
    ];
    const queryVar = getQueryVar({...props, q: 'priTab'});
    let currentTab = getCurrentTab(queryVar, 'active');

    useEffect(() =>{

    },[]);

    return(
        <>
            <OaCard className={'oa-card-primary'}>
                <OaPriTabs tabs={tabs} currentTab={currentTab}/>
                <AgentList {...props} currentTab={currentTab}/>
            </OaCard>
        </>
    )
}

export default Agent;