import React, { useState } from "react";
import {
  Popper,
  makeStyles,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .oa-form-element-card": {
      width: "100%",
      // padding: 6,
      border: "1px solid #d3d3d3",
      borderRadius: 5,
      overflow: "hidden",
      marginTop: 5,
      padding: "0 10px",
    },
    "& .oa-form-element-label": {
      color: theme.palette.primary.darkGray,
      fontWeight: 400,
    },
    "& .oa-form-element-primary": {
      // marginTop: -4,
      // marginBottom: 10,
    },
    "& .oa-form-element-secondary": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: theme.palette.primary.darkGray,
      fontWeight: 400,
    },
    "& .disabled-text": {
      margin: "10px 0 10px",
      width: 160,
    },
    "& .MuiInputBase-input": {
      fontSize: "26px !important",
      fontWeight: 400,
      color: theme.palette.primary.darkText,
      padding: "3px 0 2px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child": {
      padding: "2px 0",
    },

    " & .passenger-value": {
      fontSize: "18px",
    },
  },
  travelersSubLabels: {
    color:theme.palette.primary.darkText,
    fontWeight: 500,
    margin: "2px 5px",
    textTransform: "uppercase",
  },
}));

const OaSwitchInput = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      <div className="oa-form-element-card">
        <div className="oa-form-element-primary">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginTop: 20.3,
              ...(isMobileScreen && {
                justifyContent: "space-between",
                minWidth: "210px",
              }),
            }}
          >
            <div>
              <span className={classes.travelersSubLabels}>Adult</span>
              <div>
                <IconButton
                  size="small"
                  style={props.iconbuttonstyle}
                  onClick={props.remadt}
                >
                  -
                </IconButton>
                <span className="passenger-value">{props.travelers.adult}</span>
                <IconButton
                  size="small"
                  style={props.iconbuttonstyle}
                  onClick={props.addadt}
                >
                  +
                </IconButton>
              </div>
            </div>
            <div style={{ margin: "0px 10px" }}>
              <span className={classes.travelersSubLabels}>Child</span>
              <div>
                <IconButton
                  size="small"
                  style={props.iconbuttonstyle}
                  onClick={props.remchd}
                >
                  -
                </IconButton>
                <span className="passenger-value">{props.travelers.child}</span>
                <IconButton
                  size="small"
                  style={props.iconbuttonstyle}
                  onClick={props.addchd}
                >
                  +
                </IconButton>
              </div>
            </div>
            <div>
              <span className={classes.travelersSubLabels}>Infant</span>
              <div>
                <IconButton
                  size="small"
                  style={props.iconbuttonstyle}
                  onClick={props.reminf}
                >
                  -
                </IconButton>
                <span className="passenger-value">
                  {props.travelers.infant}
                </span>
                <IconButton
                  size="small"
                  style={props.iconbuttonstyle}
                  onClick={props.addinf}
                >
                  +
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OaSwitchInput;
