import React, { useState } from "react";
import clsx from "clsx";
import dateFnsFormat from "date-fns/format";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import FlightDetailsCardMobile from "./FlightDetailsCardMobile";
import { Grid, IconButton, Collapse } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

export const FlighCardMoreDetailsVew = (props) => {
  const width = window.innerWidth;
  const breakpoint = 980;
  const [openDiv, setOpenDiv] = useState({
    openOnwardDetails: true,
    openFareDetails: true,
    openBaggageDetails: true,
  });
  const useStyles = makeStyles((theme) => ({
    f12b: {
      fontSize: "12px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "10px",
      },
    },
    f16b: {
      fontSize: "16px",
      fontWeight: 700,
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
    f12r: {
      fontSize: "13px",
      fontWeight: 500,
      [theme.breakpoints.down(1080)]: {
        fontSize: "11.33px",
      },
      [theme.breakpoints.down(480)]: {
        fontSize: "9.33px",
      },
    },
    collapseIcon: {
      marginTop: -5,
    },
    labelContainer: {
      padding: "10px 0",
      borderBottom: `2px solid ${theme.palette.background.lightGray}`,
    },
    baggageLabelContainer: {
      padding: "10px 0",
      borderBottom: `2px solid ${theme.palette.background.lightGray}`,
      borderTop: `1px solid ${theme.palette.background.lightGray}`,
    },
    elementLabel: {
      fontSize: "14px",
      fontWeight: "600",
      textAlign: "center",
      margin: "0",
    },
    elementLabelText: { color: theme.palette.primary.darkText, padding: 5 },

    // -----------------------------
    moreDetailsContent: {
      padding: "4px 12px",
      fontSize: "12px !important",
      width: "100%",
      // width:600,
      // height:"auto",
      // boxSizing:'border-box',
      overflowX: "auto",
      // [theme.breakpoints.down(780)]: {
      //   width:336,
      //   overflowX:'hidden',
      //   overflowX:'scroll',
      //   minWidth:"100%",
      //   overflowX:'auto',
      //   fontSize: "6px",
      // },
      [theme.breakpoints.down("sm")]: {
        // width: 336,
      },
      [theme.breakpoints.down(400)]: {
        height: "calc(100% - 65px)",
        width: "100%",
        overflowX: "hidden",
      },
      [theme.breakpoints.down(376)]: {
        padding: 0,
        // fontSize: "6px",
        // width: 290,
      },
      [theme.breakpoints.down(283)]: {
        // width: 273,
      },
    },
    moreDetailNav: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      margin: "20px 0 0",
      "& > div": {
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
          fontSize: 10,
        },
      },
      [theme.breakpoints.down("sm")]: {
        //width: 463,
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        width: "100%",
        margin: 0,
      },
      // [theme.breakpoints.down(283)]: {
      //   width:275,
      // },
    },
    activeMoreDetailNav: {
      fontWeight: 700,
      position: "relative",
      width: 120,
      // color: theme.palette.quarternary.contrastText,
      color: theme.palette.primary.contrastText,

      border: `solid 1px ${theme.palette.quarternary.main}`,
      //  backgroundColor: theme.palette.quarternary.main,
      backgroundColor: theme.palette.primary.main,

      padding: "2px 6px",
      "&::after": {
        position: "absolute",
        display: "block",
        content: "''",
        top: "24px",
        left: 0,
        height: "2px",
        width: 120,
        // [theme.breakpoints.down("sm")]: {
        //   width:"230%",
        // },
        borderRadius: "2px",
      },
    },
    notActiveMoreDetailNav: {
      fontWeight: 500,
      width: 120,
      padding: "2px 6px",
      border: `solid 1px ${theme.palette.primary.fadedLight}`,
    },

    moreDetailBody: {
      border: `solid 1px ${theme.palette.quarternary.main}`,
      padding: "10px 15px",
      width: "100%",
      minHeight: 200,
      [theme.breakpoints.down(480)]: {
        padding: 0,
      },
      [theme.breakpoints.down("sm")]: {
        // width: "346%",
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        width: "100%",
        height: "calc(100% - 27px)",
        overflowX: "scroll",
      },
    },

    // ----
    flightDetailElements: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down(400)]: {
        width: "100%",
      },
    },
    flightDetailElement: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    flightDetailLayover: {
      margin: "0 0 15px",
      "& span": {
        padding: "4px 12px",
        backgroundColor: `${theme.palette.primary.main}20`,
        borderRadius: "6px",
        margin: "6px 12px",
      },
    },
    // ----
    FareBreakupTreeView: {
      height: "auto",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        width: 290,
      },
      [theme.breakpoints.down(400)]: {
        // padding: "0px 10px",
        width: "100%",
        marginBottom: 20,
        "& li .MuiTreeItem-content": {
          display: "block",
        },
      },
      maxWidth: 400,
      "& li .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
      },
      "& li .MuiCollapse-wrapperInner .MuiTypography-root": {
        fontSize: "12px",
      },
      "& > li:last-child .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    // ----
    fareRulesElements: { height: "100%" },
    fareRulesElement: {
      display: "flex",
      flexDirection: "row",
      maxHeight: 300,
      maxWidth: "100%",
      overflowY: "auto",
      "& ul": { padding: 0 },
      "& li": {
        wordWrap: "break-word",
      },
      [theme.breakpoints.down(980)]: {
        padding: "0px 10px",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      },
      [theme.breakpoints.down(400)]: {
        padding: "0px 10px",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      },
      "& .row": {
        marginRight: -15,
        marginLeft: -15,
        display: "flex",
      },
      "& .col-xs-12": {
        width: "100%",
      },
      "& .col-lg-6, .col-md-6": {
        width: "50%",
        [theme.breakpoints.down(992)]: {
          width: "100%",
        },
      },
      "& .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        float: "left",
      },
      "& .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        position: "relative",
        minHeight: 1,
        paddingRight: 15,
        paddingLeft: 15,
      },
      [theme.breakpoints.down(992)]: {
        "& .col-md-6": {
          width: "50%",
        },
        "& .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9": {
          float: "left",
        },
      },
      "& .table-bordered": {
        border: "1px solid #CDCDCD",
      },
      "& .table": {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 20,
      },
      "& table": {
        backgroundColor: "transparent",
      },
      "& table": {
        borderCollapse: "collapse",
        borderSpacing: 0,
        display: "table",
        boxSizing: "border-box",
        textIndent: "initial",
        borderSpacing: 2,
        borderColor: "gray",
      },
      "& .table-striped>tbody>tr:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& tr": {
        display: "table-row",
        verticalAlign: "inherit",
        borderColor: "inherit",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th": {
        border: "1px solid #CDCDCD",
      },
      "& td, th": {
        padding: 0,
      },
      "& .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th": {
        padding: 8,
        lineHeight: 1.42857143,
        verticalAlign: "top",
        borderTop: "1px solid #CDCDCD",
      },
      "& td": {
        display: "table-cell",
        verticalAlign: "inherit",
      },
    },
    fareRule_title: {
      marginBottom: "8px",
    },
    fareRule_desc: {
      "& span": {
        lineHeight: "1em !important",
      },
    },
    // ----
    baggageDetailsElements: {
      [theme.breakpoints.down(400)]: {
        paddingTop: 10,
        // borderTop: `2px solid ${theme.palette.background.lightGray}`,
      },
    },
    baggageDetailsElement: {
      [theme.breakpoints.down(400)]: {
        padding: 5,
      },
    },
    baggageContent: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      margin: "12px 0",
    },
    baggageContentMobile: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      margin: "12px 0",
      justifyContent: "center",
      alignItems: "center",
    },
    baggageFlex: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
    },
  }));

  const [moreDetailNavState, setMoreDetailNavState] = React.useState(0);
  const [selectedFareDetail, setSelectedFareDetail] = React.useState(
    props.selectedFareDetail
  );
  const [fareRules, setFareRules] = React.useState(props.fareRules);
  const segments = props.segments;

  // const image_base = BASE_URL_UPLOAD + "agency/view-file";
  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  const classes = useStyles();
  const theme = useTheme();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  React.useEffect(() => {
    if (props.selectedFareDetail) {
      // console.log("props.selectedFareDetail",props.selectedFareDetail)
      setSelectedFareDetail(props.selectedFareDetail);
    }
  }, [props.selectedFareDetail]);

  React.useEffect(() => {
    if (props.fareRules) {
      // console.log("(props.fareRules",props.fareRules)
      setFareRules(props.fareRules);
    }
  }, [props.fareRules]);

  const FareRuleComponent = () => {
    return (
      <ul>
        {fareRules &&
          fareRules.split("|").map((rule, ind) => {
            if (rule != "" && rule != null) {
              const uriDecoded = decodeURIComponent(rule);
              return (
                <li
                  key={ind}
                  dangerouslySetInnerHTML={{
                    __html: uriDecoded.replaceAll("+", " "),
                  }}
                ></li>
              );
            } else {
              return "";
            }
          })}
      </ul>
    );
  };
  const FareDetailComponent = () => {
    return (
      <TreeView
        className={classes.FareBreakupTreeView}
        defaultExpanded={["3"]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 4 }} />}
      >
        <StyledTreeItem
          nodeId="1"
          labelText="Base Fare"
          labelInfo={"₹ " + selectedFareDetail.baseFare}
        />
        <StyledTreeItem
          nodeId="2"
          labelText="Airline Tax and Fee"
          labelInfo={"₹ " + selectedFareDetail.tax}
        ></StyledTreeItem>
        <StyledTreeItem
          nodeId="3"
          labelText="Convenience Fee"
          labelInfo={"₹ " + selectedFareDetail.tmarkup}
        ></StyledTreeItem>
        <StyledTreeItem
          nodeId="11"
          labelText="Grand Total"
          labelInfo={`₹ ${selectedFareDetail.baseFare +
            selectedFareDetail.tax +
            selectedFareDetail.tmarkup}`}
        />
      </TreeView>
    );
  };
  const BaggageComponent = ({ value }) => {
    return (
      <div className={classes.baggageDetailsElement}>
        <div className={clsx(classes.baggageLocation, classes.f12b)}>
          {value.departDetails.airportName} to {value.arriveDetails.airportName}
        </div>
        <div className={classes.baggageContent}>
          <div className={classes.baggageFlex}>
            <BusinessCenterRoundedIcon fontSize="small" />
            <div>
              <div className={classes.f16b}>Cabin</div>
              <div className={classes.f12r}>7kg / person</div>
            </div>
          </div>
          <div className={classes.baggageFlex}>
            <LocalMallIcon fontSize="small" />
            <div>
              <div className={classes.f16b}>Check-in</div>
              <div className={classes.f12r}>
                {selectedFareDetail && selectedFareDetail.baggage} / person
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.moreDetailsContent}>
      <div className={classes.moreDetailNav}>
        <div
          className={clsx(
            moreDetailNavState === 0
              ? classes.activeMoreDetailNav
              : classes.notActiveMoreDetailNav
          )}
          onClick={() => setMoreDetailNavState(0)}
        >
          Flight Details
        </div>
        <div
          className={clsx(
            moreDetailNavState === 1
              ? classes.activeMoreDetailNav
              : classes.notActiveMoreDetailNav
          )}
          onClick={() => setMoreDetailNavState(1)}
        >
          Fare Details
        </div>
        <div
          className={clsx(
            moreDetailNavState === 2
              ? classes.activeMoreDetailNav
              : classes.notActiveMoreDetailNav
          )}
          onClick={() => setMoreDetailNavState(2)}
        >
          Fare Rules
        </div>
        {width > breakpoint && (
          <div
            className={clsx(
              moreDetailNavState === 3
                ? classes.activeMoreDetailNav
                : classes.notActiveMoreDetailNav
            )}
            onClick={() => setMoreDetailNavState(3)}
          >
            Baggage Details
          </div>
        )}
      </div>
      <div className={classes.moreDetailBody}>
        {/* Flight Details */}
        {moreDetailNavState === 0 &&
          (width > breakpoint ? (
            <div className={classes.flightDetailElements}>
              <table className="table1">
                <tbody>
                  <tr className="tr1 gray thead1">
                    <th className="th1">
                      <p className="p1">S.No</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Flight No</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Departure</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Arrival</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Flight Time</p>
                    </th>
                    <th className="th1">
                      <p className="p1">Layover Time</p>
                    </th>
                  </tr>
                </tbody>
                {segments.map((value, index) => (
                  <tbody key={index}>
                    <tr >
                      <td className="td1">{index + 1}</td>
                      <td className="td1">
                        <img
                          style={{ width: "25px", height: "25px" }}
                          src={
                            image_base +
                            "?fileName=" +
                            value.carrierDetails.carrierCode +
                            ".png&type=AIRLINE_IMAGES"
                          }
                        ></img>
                        <div className={classes.f12b}>
                          {value.carrierDetails.carrierName}
                        </div>
                        <div className={classes.f8r}>
                          {value.carrierDetails.carrierCode}-
                          {value.carrierDetails.flightNumber}
                        </div>
                      </td>
                      <td className="td1">
                        <span
                          className={classes.f16b}
                          style={{ marginRight: "6px" }}
                        >
                          {dateFnsFormat(
                            new Date(value.departDetails.dateTime),
                            "HH:mm"
                          )}
                        </span>
                        <span className={classes.f12r}>
                          {
                            days[
                            new Date(value.departDetails.dateTime).getDay()
                            ]
                          }
                          ,
                          {dateFnsFormat(
                            new Date(value.departDetails.dateTime),
                            "dd MMM"
                          )}
                        </span>
                        <div>
                          {value.departDetails.airportName} (
                          {value.departDetails.airportCode})
                          {value.departDetails.terminal != "" && (
                            <span>Terminal {value.departDetails.terminal}</span>
                          )}
                        </div>
                      </td>
                      <td className="td1">
                        <span
                          className={classes.f16b}
                          style={{ marginRight: "6px" }}
                        >
                          {dateFnsFormat(
                            new Date(value.arriveDetails.dateTime),
                            "HH:mm"
                          )}
                        </span>
                        <span className={classes.f12r}>
                          {
                            days[
                            new Date(value.arriveDetails.dateTime).getDay()
                            ]
                          }
                          ,
                          {dateFnsFormat(
                            new Date(value.arriveDetails.dateTime),
                            "dd MMM"
                          )}
                        </span>
                        <div>
                          {value.arriveDetails.airportName} (
                          {value.arriveDetails.airportCode})
                          {value.arriveDetails.terminal != "" && (
                            <span>Terminal {value.arriveDetails.terminal}</span>
                          )}
                        </div>
                      </td>
                      <td className="td1">
                        <span>
                          {Math.floor(value.flightTime / (24 * 60)) > 0
                            ? Math.floor(value.flightTime / (24 * 60)) + "d "
                            : ""}
                          &nbsp;
                          {Math.floor(value.flightTime / 60) <= 9
                            ? "0" + Math.floor(value.flightTime / 60)
                            : Math.floor(value.flightTime / 60)}
                          h &nbsp;
                          {Math.floor(value.flightTime % 60) <= 9
                            ? "0" + Math.floor(value.flightTime % 60)
                            : Math.floor(value.flightTime % 60)}
                        </span>
                      </td>
                      <td className="td1">
                        {value.layover && value.layover > 0 ? (
                          <span>
                            {Math.floor(value.layover / (24 * 60)) > 0
                              ? Math.floor(value.layover / (24 * 60)) +
                              "day" +
                              (Math.floor(value.layover / (24 * 60)) > 1
                                ? "s "
                                : " ")
                              : ""}
                            &nbsp;
                            {Math.floor(value.layover / 60)} hrs{" "}
                            {Math.floor(value.layover % 60)} min
                          </span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          ) : (
            <Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.labelContainer}
              >
                <Grid item className={classes.elementLabel}>
                  <span className={classes.elementLabelText}>
                    {segments[0].departDetails.airportName} -{" "}
                    {segments[segments.length - 1].arriveDetails.airportName}
                  </span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() =>
                      setOpenDiv({
                        ...openDiv,
                        openOnwardDetails: !openDiv?.openOnwardDetails,
                      })
                    }
                    className={classes.collapseIcon}
                  >
                    {openDiv?.openOnwardDetails == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openOnwardDetails} timeout={0}>
                {segments.map((value) => (
                  <FlightDetailsCardMobile value={value} />
                ))}
              </Collapse>
            </Grid>
          ))}
        {/* Fare Details */}
        {moreDetailNavState === 1 && (
          <div>
            {width > breakpoint ? (
              <FareDetailComponent />
            ) : (
              <Grid>
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.labelContainer}
                  >
                    <Grid item className={classes.elementLabel}>
                      <span className={classes.elementLabelText}>
                        Fare Details
                      </span>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="clear price"
                        size="small"
                        onClick={() =>
                          setOpenDiv({
                            ...openDiv,
                            openFareDetails: !openDiv?.openFareDetails,
                          })
                        }
                        className={classes.collapseIcon}
                      >
                        {openDiv?.openFareDetails == true ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={openDiv?.openFareDetails} timeout={0}>
                    <FareDetailComponent />
                  </Collapse>
                </Grid>
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.baggageLabelContainer}
                  >
                    <Grid item className={classes.elementLabel}>
                      <span className={classes.elementLabelText}>
                        Baggage Details
                      </span>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="clear price"
                        size="small"
                        onClick={() =>
                          setOpenDiv({
                            ...openDiv,
                            openBaggageDetails: !openDiv?.openBaggageDetails,
                          })
                        }
                        className={classes.collapseIcon}
                      >
                        {openDiv?.openBaggageDetails == true ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={openDiv?.openBaggageDetails} timeout={0}>
                    <div className={classes.baggageDetailsElements}>
                      {segments.map((value, index) => (
                        <BaggageComponent key={index} value={value} />
                      ))}
                    </div>
                  </Collapse>
                </Grid>
              </Grid>
            )}
          </div>
        )}

        {/* Fare Rules */}
        {moreDetailNavState === 2 && (
          <div className={classes.fareRulesElements}>
            <div className={classes.fareRulesElement}>
              <FareRuleComponent />
            </div>
          </div>
        )}
        {/* Baggage Details */}
        {moreDetailNavState === 3 && width > breakpoint && (
          <div className={classes.baggageDetailsElements}>
            {segments.map((value, index) => (
              <BaggageComponent key={index} value={value} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="secondary">
            {labelInfo}
          </Typography>
        </div>
      }
      {...other}
    />
  );
}
StyledTreeItem.propTypes = {
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};
const useTreeItemStyles = makeStyles((theme) => {
  return {
    root: {
      color: theme.palette.action.hover,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    content: {
      color: theme.palette.action.hover,
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  };
});
