import { SET_B2C_BUS_LIST } from "redux/action";


export const busList=(state={},action)=>{
    switch (action.type) {
        case SET_B2C_BUS_LIST:
          state = Object.assign({}, state);
          state.busList = action.payload;
          return state;
        default:
          return state;
      }
}