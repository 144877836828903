import { Button, Divider, Grid, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "pages/b2c/component/Menu";
import Profile from "pages/b2c/component/Profile";
import CarrierImage from "assets/img/6E.png";
import { withRouter } from "react-router";
import moment from "moment";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "100%",
        margin: "auto",
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    typo1: {
        fontSize: "11px",
        fontWeight: 500,
        lineHeight: "1.5",
        color: "#212529",
        marginLeft: 5,
        letterSpacing: "0.5px !important",
    },
    typo2: {
        fontSize: "13px",
        paddingRight: "10px",
        fontWeight: 700,
        color: "#212529",
        letterSpacing: "0.5px !important",
        borderRight: "1px solid",
    },
    typo3: {
        fontSize: "13px",
        fontWeight: 700,
        color: "#212529",
        letterSpacing: "0.5px !important",
        paddingLeft: "10px",
    },
    typo4: {
        fontSize: 12,
        color: "#000",
        fontWeight: 500,
    },
    typo5: {
        fontSize: 15,
        color: "#000",
        fontWeight: 500,
    },
    typo6: {
        fontSize: 16,
        color: "#000",
        fontWeight: 500,
    },
    typo7: {
        fontSize: 12,
        color: "grey",
        fontWeight: 400,
    },
    typo8: {
        fontSize: 15,
        fontWeight: 500,
        color: "grey",
    },
    typo9: {
        fontSize: 12,
        color: "grey",
        fontWeight: 500,
    },
    button: {
        background: "#2b5a85",
        color: "#fff",
        fontSize: 13,
        textAlign: "center",
        padding: 8,
        borderRadius: 10,
        textTransform: "capitalize",
        "&:hover": {
            background: "#2b5a85",
        },
    },
    backButton: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main,
        "& .MuiSvgIcon-root": {
            fontSize: 16,
            marginRight: 5,
        },
        "&:hover": {
            color: theme.palette.primary.sub,
        }
    }
}));

const FareDetailsHotel = (props) => {

    return(
        <div>Fare Details hotel</div>
    );
}

export default withRouter(FareDetailsHotel);