import React, {useState, useRef} from 'react'
import {Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputAdornment } from '@material-ui/core';
import dateFnsFormat from 'date-fns/format';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormPasswordField from 'oahoc/OaFormPasswordField';

import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaCard from 'oahoc/OaCard';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {scrollToTop} from 'oautils/oaCommonUtils';

import AgentDetails from 'pages/agent/AgentDetails';
import OaTabBar from 'oahoc/OaTabBar';
import {getQueryVar, getCurrentTab, getAgencyId} from 'oautils/oaCommonUtils.js';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 
import {localforageGetItem} from 'oautils/oaForageUtils';

import {  Redirect } from "react-router-dom";

const AgentChangePassword = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');

    let tabs = [
        { name: 'change-password', label: 'Change Password', url: '/admin/user/agents/change-password' },
    ];
    let currentTab = 'change-password';

    return (
        <OaCard className={'oa-card-primary'}>
            <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
            <GridContainer>
                {props.match.params.agencyId && 
                    (<GridItem xs={12} sm={12} md={12}>
                        <OaCard>
                            <AgentDetails agencyId={props.match.params.agencyId} />
                        </OaCard>
                        </GridItem>
                    )
                }
                <GridItem xs={12} sm={12} md={6} >
                    <OaCard> 
                        <OaFormAlerts 
                            isSaving={isSaving} 
                            isSuccess={isSuccess} 
                            isError={isError} 
                            infoText={infoText} 
                            setIsError={setIsError} 
                            setIsSuccess={setIsSuccess} 
                        />
                        <Formik 
                            initialValues={{currentPassword:"", newPassword: "", newPasswordConfirmation:""}}
                            validationSchema={Yup.object().shape({
                                currentPassword: Yup.string().required('Current password is required'),
                                newPassword: Yup.string().required('Password is required'),
                                newPasswordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                            })}
                            
                            onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                                localforageGetItem('user-details', function (err, value) {
                                    
                                    searchParams.loggedInUserId = value.userId;
                                   
                                    apiCall(WebApi.updateAgencyPassword, searchParams, (response) => {
                                        setIsError(!response.success);
                                        setIsSuccess(response.success);
                                        setSubmitting(false);
                                        setIsSaving(false);
                                        setInfoText(response.message);
                                        if(response.success === true) {
                                            enableReinitialize=true;
                                            resetForm(true);
                                        }
                                    });
                                });
                                
                                }
                            }
                        >
                            {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                                <Form>
                                    <OaFormPasswordField required={true} label="Current Password" name="currentPassword"/>
                                    <ErrorMessage name="currentPassword" component="div" className="error"/>
                                    <OaFormPasswordField required={true} label="Combination of Alphabets And Numbers" name="newPassword"/>
                                    <ErrorMessage name="newPassword" component="div" className="error"/>
                                    <OaFormPasswordField required={true} label="Confirm Password" name="newPasswordConfirmation"/>
                                    <ErrorMessage name="newPasswordConfirmation" component="div" className="error"/>
                                    <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}/>
                                </Form>
                            )}
                        </Formik>
                    </OaCard>
               </GridItem> 
            </GridContainer>
            {isSuccess && <Redirect to="/auth/login-page" />}
        </OaCard> 
    )
}

export default AgentChangePassword;
