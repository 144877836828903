import React, {useState, useEffect} from 'react';

import OaCard from 'oahoc/OaCard';
import OaPriTabs from 'oahoc/OaPriTabs';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import VerifyOtpForm from './VerifyOtpForm';


const VerifyOtp = (props) => {
   
    const tabs = [
        {name: 'verifyOtp', label: 'Verify OTP', url: '/moneytransfer/verify-otp'},
    ];
    let currentTab = getCurrentTab(queryVar, 'verifyOtp');
    const queryVar = getQueryVar({...props, q: 'priTab'});
    useEffect(() =>{

    },[]);
    return (
        < >
            <OaPriTabs tabs={tabs} currentTab={currentTab}/>
            <OaCard>
                <VerifyOtpForm {...props}/>
            </OaCard>
        </>
        
    )
}

export default VerifyOtp;
