import React, { Component} from 'react';
import ReactTable from "react-table";
import WebApi from "api/ApiConstants";
import {toTitleCase, currencyFormatter} from 'oautils/oaCommonUtils';


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 

/**
 * Opening balance = balance - credit + debit
 */
class MasterBalanceManagerSentSearchResult extends Component {

    voucherUrl = '/admin/voucher/transaction/';
    constructor(props){
        super(props);
        this.state = {
            data: [],
            tabledata: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " "
        }
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
    })

    filterGreaterThan(filter, row){
        return row[filter.id] >= filter.value;
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
    }

    componentDidMount() {
        this.tableLoader();
    }

    tableLoader() {
        WebApi.getMasterSentRequestList(this.props.searchParams, (resp) => {
            if(resp != null && resp.success){
                var tableData = [];
                var data = [];
                if(resp.data.hasOwnProperty("tableData")){
                    tableData = resp.data.tableData;
                    data = resp.data;
                }else{
                    tableData = resp.data;
                }

                this.setState({tabledata: tableData});
                this.setState({data: data});
                this.setState({
                    rowCount: tableData.length === 0 ? 4 : tableData.length,
                    noDataText: tableData.length === 0 ? "No results" : tableData.length,
                    loading: false,
                    finishedLoading: true
                });

            } 
            },(error)=>{
                this.setState({
                    data: [],
                    rowCount: 4,
                    // noDataText: "Something went wrong", //@TODO: for production environment
                    noDataText: "ERROR ", // + error.response.data.status + ": " + error.response.data.error,
                    loading: false,
                    finishedLoading: true
                });
                // console.log(error);
        });
    }

    getDescription(props){
        let value = props.original;

        switch (value.balanceRequestType) {
            case 'CASH':
                return (
                    <div >
                        <GridContainer className="bottom-border">
                            <GridItem> Mode of Payment: Cash</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>Deposit Date: {value.depositOn}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> Bank: {value.bank}</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>Receipt No: {value.depositSlip}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> Remarks: {value.bank}</GridItem>
                        </GridContainer>
                    </div>
                )
                break;
                case 'CHEQUE':
                return (
                    <div >
                         <GridContainer className="bottom-border">
                            <GridItem> Mode of Payment: Cheque/DD</GridItem>
                        </GridContainer>
                        {/* <GridContainer>
                            <GridItem>Deposit Date: {value.depositOn}</GridItem>
                        </GridContainer> */}
                         <GridContainer className="bottom-border">
                            <GridItem> Bank: {value.bank}</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem> CHEQUE/DD No: {value.chqddNo}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> CHEQUE/DD Bank: {value.chqddBank}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> CHEQUE/DD Branch: {value.chqddBranch}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> Remarks: {value.remarks}</GridItem>
                        </GridContainer>
                    </div>
                )
                break;
                
            case 'ETRANSFER':
                return (
                    <div >
                         <GridContainer className="bottom-border">
                            <GridItem> Mode of Payment: e-transfer</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>Deposit Date: {value.depositOn}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> Bank: {value.bank}</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem> E-TRANSFER ID: {value.etransferId}</GridItem>
                        </GridContainer>
                        <GridContainer className="bottom-border">
                            <GridItem> Remarks: {value.remarks}</GridItem>
                        </GridContainer>
                    </div>
                )
                break;             
            default:
                return (<span>default</span>)
                break;
            }
            
        }

    render(){
        //console.log(this.props);
        return(
            <>
            <ReactTable
                columns={[
                    {
                        Header: '#',
                        Cell: props => <span>{props.index + 1}</span>,
                        width: 35,
                        filterable: false
                    },{
                        Header: "Request Date",
                        accessor: "createdOn",
                        width: 160
                    },{
                        Header: "Tx Number",
                        accessor: "uuid",
                        Cell: props => <span >{props.value}</span>,
                        width: 200,
                    },
                    {
                        Header: "Description",
                        accessor: "balanceRequestType",
                        width: 260,
                        Cell: props => (
                            this.getDescription(props)
                        ),
                        filterable: false
                    },
                    {
                        Header: "Fund Transfer Remark",
                        accessor: "remarks",
                        filterable: false,
                        width: 200,
                      },                    
                    
                    {
                        Header: "Amount",
                        accessor: "amount",
                        width: 110,
                        Cell: props => <span>{currencyFormatter(props.value)}</span>,
                        style: {textAlign: 'right'}
                    },{
                        Header: "Status",
                        accessor: "status",
                        Cell: props => <span>{toTitleCase(props.value)}</span>,
                        width: 100
                    },
                    {
                        Header: "Company Remarks",
                        accessor: "CompanyRemarks",
                        width: 200
                    }
                ]}
                data={this.state.tabledata}
                defaultFilterMethod={this.filterMethod}
                filterable={true}
                showPagination={false}
                minRows={this.state.rowCount}
                loading= {this.state.loading}
                pageSize={this.state.rowCount}
                noDataText={this.state.noDataText}       
            />
            </>
        )
    }
}

export default MasterBalanceManagerSentSearchResult;
