import React, { useEffect, useState } from "react";
import OaTable from "components/OaTable";
import WebApi, {
  DOWNLOAD_RECHARGE_PDF,
  DOWNLOAD_RECHARGE_XLS,
} from "api/ApiConstants";
import { currencyFormatter } from "oautils/oaCommonUtils";
import OaButtonPDF from "pages/components/OaButtonPDF";
import OaButtonXls from "pages/components/OaButtonXls";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { withRouter } from "react-router-dom";
import { localforageSetItem, localforageGetItem } from "oautils/oaForageUtils";
import _ from "lodash";
import { apiCall } from "oautils/oaDataUtils";
import GridContainer from "components/Grid/GridContainer";

import OaFormAlerts from "pages/components/OaFormAlerts";
import GridItem from "components/Grid/GridItem";

import Checkbox from "@material-ui/core/Checkbox";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

const BusReportSearchResult = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [passengers, setPassengers] = useState([]);
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [cancellationChargePerSeat, setCancellationChargePerSeat] = useState(0);
  const [tid, setTid] = useState(0);
  const [open, setOpen] = useState(false);
  const [allSeatSelected, setAllSeatSelected] = useState(true);

  const [remarks, setRemarks] = useState("");

  const handleClose = () => {
    setOpen(false);
    setPassengers([]);
  };

  const handleConfirmClose = () => {
    let _seatIds = [];
    passengers.map((value) => {
      if (value.checked) _seatIds.push(value.SeatId);
    });

    if (remarks.length == 0) {
      alert("Please add remarks");
    } else if (_seatIds.length == 0) {
      alert("Please select atleast one seat");
    } else {
      setIsLoading(true);
      apiCall(
        WebApi.doBusSeatCancel,
        { tid: tid, seatIds: _seatIds, remarks: remarks },
        (response) => {
          setIsLoading(false);
          setOpen(false);
          if (response.success === true) {
            setInfoText(response.message);
            setIsSuccess(true);

            setTimeout(() => {
              props.target(props.searchParams);
            }, 5000);
          } else {
            setIsError(true);
            setInfoText(response.message);
          }
        }
      );
    }
  };

  function onSelectMenu(event, data) {
    //console.log("data",data)

    switch (event) {
      case "User Invoice":
        props.history.push("/admin/voucher/bus-user/" + data.transactionId);
        break;
      case "Agent Invoice":
        props.history.push("/admin/voucher/bus-agent/" + data.transactionId);
        break;
      case "Ticket":
        props.history.push(
          "/admin/booking-report/bus/ticket/" + data.transactionId
        );
        break;
      case "Details":
        props.history.push(
          "/admin/booking-report/bus/details/" + data.transactionId
        );
        break;
      case "Rollback":
        if (data.isRolledback) {
          alert("Already rolledback");
        } else if (data.isCancelled) {
          alert("Already cancelled");
        } else if (!data.pnr) {
          apiCall(
            WebApi.doBusTicketRollback,
            { tid: data.transactionId },
            (response) => {
              setIsLoading(false);

              if (response.success === true) {
                setInfoText(response.message);
                setIsSuccess(true);
              } else {
                setIsError(true);
                setInfoText(response.message);
              }
            }
          );
        } else {
          alert("Cannot be rolledback, please process cancellation");
        }
        break;
      case "Cancel":
        setRemarks("");
        if (data.isCancelled) {
          alert("Already cancelled");
        } else if (data.isRolledback) {
          alert("Already rolledback");
        } else if (data.pnr) {
          let _passengers = [...data.passengers];
          _passengers.map((value, key) => {
            value.checked = true;
          });
          setPassengers(_passengers);
          setCancellationPolicy(data.cancellationPolicy);
          setCancellationChargePerSeat(data.cancellationChargePerSeat);
          setTid(data.transactionId);
          setOpen(true);
        } else {
          alert("Cannot be cancelled, please process rollback");
        }
        break;
    }
  }

  const dropDownMenu = [
    "User Invoice",
    "Agent Invoice",
    "Ticket",
    "Cancel",
    // "Details",
  ];

  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
      decimals
    );
  }

  useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      if (err == null && value != null) {
        if (value.userType === "distributor" || value.userType === "company") {
        }
        if (value.userType === "agent") {
        }
      }
    });
  }, []);

  const [total, setTotal] = useState(0);

  const doTotal = (amt) => {
    let totalAmt = total + amt;
    setTotal(totalAmt);
    return amt;
  };

  let cols = [];
  cols.push(
    {
      Header: "#",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.index + 1}
        </div>
      ),
      width: 35,
      filterable: false,
    },
    {
      Header: "Transaction ID",
      accessor: "transactionReferenceNo",
      width: 200,
      Cell: (props) => (
        <div style={{ height: 44, verticalAlign: "middle" }}>
          <CustomDropdown
            buttonText={props.value}
            buttonProps={{
              fullWidth: true,
              style: { height: 32, padding: 4 },
              color: "rose",
            }}
            onClick={(event) => {
              onSelectMenu(event, props.original);
            }}
            dropPlacement="bottom-start"
            dropdownList={dropDownMenu}
          />
        </div>
      ),
    },
    {
      Header: "Tx Date",
      width: 210,
      accessor: "transactionDate",
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    },
    {
      Header: "Journey Date",
      width: 210,
      accessor: "dateOfJourney",
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    }
  );

  cols.push({
    Header: "Ticket No",
    accessor: "ticketNo",
    width: 100,
    Cell: (props) => (
      <div
        style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
      >
        {props.value}
      </div>
    ),
  });
  cols.push({
    Header: "Ticket PNR",
    accessor: "pnr",
    width: 100,
    Cell: (props) => (
      <div
        style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
      >
        {props.value}
      </div>
    ),
  });

  cols.push(
    {
      Header: "Operator",
      accessor: "operatorName",
      width: 150,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    },
    {
      Header: "Acknowledgement No",
      accessor: "busAcknowledgementNo",
      width: 170,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
    },
    {
      Header: "Status",
      width: 150,
      accessor: "transactionStatus",
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.value}
        </div>
      ),
      Footer: (info) => (
        <span style={{ fontWeight: "bold" }}>
          Transactions: {info.data.length}
        </span>
      ),
    },

    {
      Header: "Transaction Amount (INR)",
      width: 150,
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {round(
            parseFloat(props.original.amount) -
              parseFloat(props.original.supplierCommission),
            2
          )}
        </div>
      ),
      Footer: (info) => (
        <span
          style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
        >
          {currencyFormatter(
            _.sum(
              _.map(
                info.data,
                (d) => d._original.amount - d._original.supplierCommission
              )
            )
          )}
        </span>
      ),
    },
    {
      Header: "Operator Share (INR)",
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {round(
            parseFloat(props.original.companyCharges) +
              parseFloat(props.original.supplierCharges) +
              parseFloat(props.original.distributorCharges),
            2
          )}
        </div>
      ),
      Footer: (info) => (
        <span
          style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
        >
          {currencyFormatter(
            _.sum(
              _.map(
                info.data,
                (d) =>
                  d._original.companyCharges +
                  d._original.supplierCharges +
                  d._original.distributorCharges
              )
            )
          )}
        </span>
      ),
    },
    {
      Header: "Agent Share (INR)",
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {round(parseFloat(props.original.agentCharges), 2)}
        </div>
      ),
      Footer: (info) => (
        <span
          style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
        >
          {currencyFormatter(
            _.sum(_.map(info.data, (d) => d._original.agentCharges))
          )}
        </span>
      ),
    },
    {
      Header: "Total Amount (INR)	",
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {currencyFormatter(
            round(
              parseFloat(props.original.amount) +
                parseFloat(props.original.totalCommission) -
                parseFloat(props.original.supplierCommission),
              2
            )
          )}
        </div>
      ),
      Footer: (info) => (
        <span
          style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
        >
          {currencyFormatter(
            _.sum(
              _.map(
                info.data,
                (d) =>
                  d._original.amount +
                  d._original.totalCommission -
                  d._original.supplierCommission
              )
            )
          )}
        </span>
      ),
    },
    {
      Header: "Refund Amount (INR)	",
      filterable: false,
      Cell: (props) => (
        <div
          style={{ height: 44, display: "table-cell", verticalAlign: "middle" }}
        >
          {props.original.refundAmount
            ? currencyFormatter(
                round(parseFloat(props.original.refundAmount), 2)
              )
            : ""}
        </div>
      ),
      Footer: (info) => (
        <span
          style={{ fontWeight: "bold", display: "block", textAlign: "right" }}
        >
          {currencyFormatter(
            _.sum(
              _.map(info.data, (d) =>
                d._original.refundAmount ? d._original.refundAmount : 0
              )
            )
          )}
        </span>
      ),
    }
  );

  const checkOnClick = (SeatId) => {
    alert("Only full cancellation is allowed at this moment");
  };

  const checkAllOnClick = () => {
    alert("Only full cancellation is allowed at this moment");
  };

  return (
    <>
      <GridContainer>
        <GridItem md={12}>
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
        </GridItem>
      </GridContainer>
      <OaButtonPDF
        api={DOWNLOAD_RECHARGE_PDF}
        searchParams={props.searchParams}
      />
      <OaButtonXls
        api={DOWNLOAD_RECHARGE_XLS}
        searchParams={props.searchParams}
      />
      <OaTable
        columns={cols}
        search={props.searchParams}
        loader={WebApi.getBusReport}
        filterable={true}
        rows
      />

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Passenger Name
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Seat No
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Price
            </GridItem>
            <GridItem
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Checkbox
                color="primary"
                checked={allSeatSelected}
                onChange={checkAllOnClick}
              />
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            passengers &&
            passengers.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={5}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.Title} {value.FirstName} {value.LastName}
                  </GridItem>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.SeatName}
                  </GridItem>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    &#x20B9;{value.BookingAmount}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {value.isCancelled ? (
                      <span>{"Cancelled"}</span>
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={value.checked || false}
                        onClick={() => {
                          checkOnClick(value.SeatId);
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              );
            })}
          <Divider />
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation Remarks
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
                fontWeight: "bold",
              }}
            >
              Cancellation Policy
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Charge
            </GridItem>
            <GridItem md={3} style={{ display: "flex", alignItems: "center" }}>
              Charge Type
            </GridItem>
            <GridItem md={7} style={{ display: "flex", alignItems: "center" }}>
              Policy
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            cancellationPolicy &&
            cancellationPolicy.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationCharge}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationChargeTypeString}
                  </GridItem>
                  <GridItem
                    md={7}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.policyString} + &#8377;{cancellationChargePerSeat}{" "}
                    max handling charge
                  </GridItem>
                </GridContainer>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={handleClose}
            color="primary"
            disabled={isLoading}
          >
            Disagree
          </Button>
          <Button
            startIcon={
              isLoading ? <CircularProgress size="24px" /> : <DoneIcon />
            }
            onClick={handleConfirmClose}
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(BusReportSearchResult);
