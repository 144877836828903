import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop,
  Modal,
  withWidth,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import RoomModal from "./RoomModal";

import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import * as Yup from "yup";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setHotelCountry, setHotelCity, setHotelSearch } from "redux/action";
import _ from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import dateFnsFormat from "date-fns/format";
import { AppStateContext } from "layouts/AppStateProvider";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { localforageGetItem } from "oautils/oaForageUtils";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0",
    padding: "5px 15px",
    "& .MuiFormLabel-root": {
      color:theme.palette.secondary.sub,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.secondary.sub,
    },
    "& .MuiSvgIcon-root": {
      color:theme.palette.secondary.sub,
    },

    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      margin: "auto",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      [theme.breakpoints.down(600)]: {
        borderBottom: `solid 1px ${theme.palette.primary.defaulText} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none"
    },
    "& .hotel-section-root-box": {
      minWidth: 860,
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
      }
    }
  },

  roomModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.lightGray,
    borderRadius: "8px",
  },

  searchButtonBox: {
    width: 80,
    height: 30,
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
      },
    },
  },
  HotelSection_itinDet: {
    "& .location-input": {
      paddingRight: 15,
      paddingLeft: 15,
    },
    "& .date-input": {
      paddingTop: "14px",
      paddingRight: 15,
      paddingLeft: 15,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.orange,
      }
    },
  },
  HotelSection_actions: {},
  HotelSection_idenDet: {
    borderTop: `solid 0.5px ${theme.palette.secondary.chineseGray}`,
    marginTop: 15,
    "& .location-input": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },
  HotelSection_quicklinks: {
    borderTop: `solid 0.5px ${theme.palette.secondary.chineseGray}`,
    padding: "10px 25px 0",
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
        border: `solid 1px ${theme.palette.primary.aqua}`,
        padding: "3px 10px",
        color: theme.palette.primary.aqua,
        borderRadius: 4,
        cursor: "pointer",
        "&:hover": {
          border: `solid 1px ${ theme.palette.secondary.active}`,
          color: theme.palette.secondary.active,
        },
        "& .qlink-icon-wrapper": {
          textAlign: "center",
          "& i": {
            fontSize: 15,
          },
        },
        "& .qlink-label-wrapper p": {
          fontSize: 10,
        },
    }
  },
}));

// function HotelSection(props) {
//   const classes = useStyles();
//   const locationAutocompleteRef = useRef(null);

//   const [data, setData] = useState({
//     country: null,
//     location: null,
//     checkinDate: new Date(),
//     checkoutDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
//     nationality: { title: "Indian", value: "indian" },
//     rooms: [
//       {
//         id: 12345,
//         name: "Room 1",
//         guest: { adult: 1, child: [] },
//       },
//     ],
//   });

//   const changeGuestCount = (id, guest, val) => {
//     setData((p) => {
//       return {
//         ...p,
//         rooms: p.rooms.map((room) =>
//           room.id === id
//             ? guest == "adult"
//               ? {
//                   ...room,
//                   guest: { ...room.guest, adult: room.guest[guest] + val },
//                 }
//               : val === -1
//               ? {
//                   ...room,
//                   guest: {
//                     ...room.guest,
//                     child: room.guest[guest].slice(
//                       0,
//                       room.guest[guest].length - 1
//                     ),
//                   },
//                 }
//               : {
//                   ...room,
//                   guest: {
//                     ...room.guest,
//                     child: [...room.guest[guest], DefChildCount],
//                   },
//                 }
//             : room
//         ),
//       };
//     });
//   };
//   const addRoom = () => {
//     setData((p) => ({
//       ...p,
//       rooms: [
//         ...p.rooms,
//         {
//           id: Math.floor(Math.random() * 99999),
//           name: `Room ${p.rooms.length + 1}`,
//           guest: { adult: 1, child: [] },
//         },
//       ],
//     }));
//   };
//   const deleteRoom = (id) =>
//     setData((p) => ({ ...p, rooms: p.rooms.filter((room) => room.id !== id) }));
//   const changeChildAge = (id, idx, val) => {
//     setData((p) => ({
//       ...p,
//       rooms: p.rooms.map((room) =>
//         room.id === id
//           ? {
//               ...room,
//               guest: {
//                 ...room.guest,
//                 child: room.guest.child.map((v, i) => (i == idx ? val : v)),
//               },
//             }
//           : room
//       ),
//     }));
//   };
//   const editRoomTitle = (id, val) => {
//     setData((p) => ({
//       ...p,
//       rooms: p.rooms.map((room) =>
//         room.id == id ? { ...room, name: val } : room
//       ),
//     }));
//   };

//   const [guestCount, setGuestCount] = useState(1);
//   const [checkinDateOpen, setCheckinDateOpen] = useState(false);
//   const [checkoutDateOpen, setCheckoutDateOpen] = useState(false);

export class RoomObj {
  constructor() {
    this.noOfAdults = 1;
    this.noOfChild = 0;
    this.childAge = [];
  }
}

function HotelSection(props) {
  const classes = useStyles();

  const locationAutocompleteRef = useRef(null);
  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  const [arrivalDateDialogOpen, setArrivalDateDialogOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [roomModalOpen, setRoomModalOpen] = useState(false);
  const formikRef = React.useRef();
  const { setSecLogo } = useContext(AppStateContext);
  const [refreshLocation, setRefreshLocation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);

  let baseUrl = "/";

  let optionsHotel = [
    {
      name: "searchHotel",
      label: <Typography className={classes.typo1}>Search Hotel</Typography>,
      url: baseUrl + "hotels",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "hotelReport",
      label: <Typography className={classes.typo1}>Hotel Report</Typography>,
      url: "/admin/booking-report/hotel",
      icon: <i className="fas fa-dollar-sign"></i>,
    },
  ];

  useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoading) {
      // console.log(0);
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;

      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };

        formikRef.current.setFieldValue("countryCode", countryCodeObj);
        formikRef.current.setFieldValue("guestNationality", {
          name: "India",
          code: "IN",
        });

        setIsLoading(false);
      } else {
        // console.log(1);
        apiCall(WebApi.getHotelCountry, {}, (response) => {
          if (response.success === true) {
            setIsLoading(false);
            props.setHotelCountry(response.data.countryList.country);
            // setHotelCountry(response.data.countryList.country);
            formikRef.current.setFieldValue("countryCode", {
              name: "India",
              code: "IN",
            });
            formikRef.current.setFieldValue("guestNationality", {
              name: "India",
              code: "IN",
            });
            cityHotelChangeHandler(1, {
              name: "India",
              code: "IN",
            });
          } else {
            setIsError(!response.success);
            setInfoText(response.message);
          }
        });
      }
    }
  }, [isLoading]);

  const cityHotelChangeHandler = (searchType, obj) => {
    let countryCode = "";
    if (obj != null) {
      countryCode = obj.code;
    } else {
      const countryCodeObj = formikRef.current.values.countryCode;
      countryCode = countryCodeObj?.code;
    }

    // console.log(
    //   "################################ cityHotelChangeHandler ##################################"
    // );

    apiCall(
      WebApi.getHotelCity,
      {
        searchType: searchType,
        countryCode: countryCode || "IN",
      },
      (response) => {
        if (response.success === true) {
          props.setHotelCity(response.data.destinations);
          //   setHotelCity(response.data.destinations);
          // setRefreshLocation(true);
          // console.log(
          //   "################################ countryCode change response ##################################",
          //   response.data.destinations
          // );
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
      }
    );
  };

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    props.history.push({
      pathname: url,
    });
  }

  return (
    <Box
      className={classes.root}
      style={{ overflow: roomModalOpen && "hidden" }}
    >
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          countryCode: {
            name: "India",
            code: "IN",
          },
          cityId: "",
          checkInDate: new Date(),
          checkOutDate: new Date(new Date().setDate(new Date().getDate() + 1)),
          preferredCurrency: "INR",
          roomGuests: [new RoomObj()],
          guestNationality: {
            name: "India",
            code: "IN",
          },
        }}
        validationSchema={Yup.object().shape({
          countryCode: Yup.string().required("country Field is Required"),
          cityId: Yup.string().required("Location Field is Required"),
          checkInDate: Yup.string().required("Check In Date Field is Required"),
          checkOutDate: Yup.string().required(
            "Check Out Date Field is Required"
          ),
          // preferredCurrency: Yup.string().required(
          //   "Preferred Currency Field is Required"
          // ),
          guestNationality: Yup.string().required(
            "Guest Nationality Field is Required"
          ),
        })}
        onSubmit={(values) => {
          values.countryCode = values.countryCode.code;
          const cityObj = values.cityId;
          values.cityId = cityObj.destinationId;
          values.city = cityObj.cityName;
          values.guestNationality = values.guestNationality.code;

          values.noOfNights = differenceInDays(
            values.checkOutDate,
            values.checkInDate
          );
          values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;
          values.checkInDate = dateFnsFormat(values.checkInDate, "dd/MM/yyyy");
          values.checkOutDate = dateFnsFormat(
            values.checkOutDate,
            "dd/MM/yyyy"
          );
          values.noOfRooms = values.roomGuests.length;

          //  searchParams.resultCount = 10;
          values.maxRating = 5;
          values.minRating = 0;
          values.isTBOMapped = true;
          //  searchParams.isTBOMapped = 1;
          //  searchParams.isCompactdata = 1;

          props.setHotelSearch(values);
          setSecLogo(true);
          props.history.push("/hotel/search");
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={classes.root1}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className="hotel-section-root-box">
                <Box className={classes.HotelSection_itinDet}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name={`countryCode`}
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name}
                        value={values.countryCode}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        onChange={
                          (_, newValue) => {
                            setFieldValue("countryCode", newValue);
                            cityHotelChangeHandler(1, newValue);
                          }
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   country: newValue,
                          // }))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Country"
                          />
                        )}
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`countryCode`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                    {/* <Grid item xs={0} sm={2} style={{ textAlign: "center" }}>
                .
              </Grid> */}
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name={`cityId`}
                        options={props.hotelCity || []}
                        getOptionLabel={(a) =>
                          a &&
                          a.cityName +
                            ", " +
                            a.stateProvince +
                            ", " +
                            a.countryCode 
                            
                        }
                        value={values.cityId}
                        getOptionSelected={(option, value) =>
                          option.value == value.destinationId
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("cityId", newValue);
                          setDepartureDateDialogOpen(true);
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   location: newValue,
                          // }))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Location"
                            inputRef={locationAutocompleteRef}
                          />
                        )}
                        openOnFocus
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`cityId`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3} className="date-input">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="checkInDate"
                          className={classes.checkinDatePicker}
                          fullWidth
                          disablePast
                          color="secondary"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Check In Date"
                          value={values.checkInDate}
                          onChange={(e, v) => {
                            setFieldValue("checkInDate", e);
                            setDepartureDateDialogOpen(false);
                            setArrivalDateDialogOpen(true);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setDepartureDateDialogOpen(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setDepartureDateDialogOpen(false);
                            },
                          }}
                          InputProps={{
                            onFocus: () => {
                              setDepartureDateDialogOpen(true);
                            },
                          }}
                          open={departureDateDialogOpen}
                          onClose={() => setDepartureDateDialogOpen(false)}
                          onOpen={() => setDepartureDateDialogOpen(true)}
                        />
                      </MuiPickersUtilsProvider>

                      <ErrorMessage
                        name={`checkInDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3} className="date-input">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="checkOutDate"
                          className={classes.checkoutDatePicker}
                          fullWidth
                          minDate={
                            new Date(
                              new Date(values.checkInDate).getTime() + 86400000
                            )
                          }
                          color="secondary"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Check Out Date"
                          value={values.checkOutDate}
                          onChange={(e, v) => {
                            setFieldValue("checkOutDate", e);
                            setArrivalDateDialogOpen(false);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setArrivalDateDialogOpen(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setArrivalDateDialogOpen(false);
                            },
                          }}
                          InputProps={{
                            onFocus: () => {
                              setArrivalDateDialogOpen(true);
                            },
                          }}
                          open={arrivalDateDialogOpen}
                          onClose={() => setArrivalDateDialogOpen(false)}
                          onOpen={() => setArrivalDateDialogOpen(true)}
                        />
                      </MuiPickersUtilsProvider>

                      <ErrorMessage
                        name={`checkOutDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                </Box>
                
                <Box className={classes.HotelSection_idenDet}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name="guestNationality"
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name}
                        value={values.guestNationality}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        onChange={(_, newValue) => {
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   nationality: newValue,
                          // }))

                          setFieldValue("guestNationality", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Nationality"
                          />
                        )}
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`guestNationality`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                    {/* <Grid item xs={2} sm={0}>
                .
              </Grid> */}
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <TextField
                        label="Rooms"
                        color="secondary"
                        value={`${values.roomGuests.length} ${
                          values.roomGuests.length == 1 ? "Room" : "Rooms"
                        },  ${values.roomGuests.reduce(function(a, b) {
                          return (
                            Number(a) +
                            Number(b.noOfAdults) +
                            Number(b.noOfChild)
                          );
                        }, 0)} Guests`}
                        fullWidth
                        onClick={() => setRoomModalOpen(true)}
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3}></Grid>

                    <Grid item xs={10} sm={5} md={3}>
                      <Box className={classes.HotelSection_actions} my={2} mx={4}>
                        <Grid
                          container
                          direction="row"
                          // justifyContent="center"
                          justifyContent={props.width === "xs" ? "center" : "flex-end"}
                          alignItems="center"
                        >
                          <Box className={classes.searchButtonBox}>
                            <Button
                              size="large"
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {userType && userType == "agent" && corporateType && corporateType != "corporate" && 
                <Box className={classes.HotelSection_quicklinks}>
                  {optionsHotel && optionsHotel.map((val, ind) => (
                    <div item className="quick-link-item" key={ind} onClick={() => openQuickLink(val.url)}>
                      <div className="qlink-icon-wrapper">
                        {val.icon}
                      </div>
                      <div className="qlink-label-wrapper">{val.label}</div>
                    </div>
                  ))}
                </Box>}
              </Box>
              
            </Grid>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.roomModal}
              open={roomModalOpen}
              onClose={() => setRoomModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={roomModalOpen}>
                <div className={classes.paper}>
                  <RoomModal
                    values={values}
                    setFieldValue={setFieldValue}
                    rooms={values.roomGuests}
                    closeModal={() => setRoomModalOpen(false)}
                  />
                </div>
              </Fade>
            </Modal>

            {/* {console.log("values: ", values)}
            {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function mapStateToProps(state, props) {
  return {
    hotelCountry: state.hotel.hotelCountry,
    hotelCity: state.hotel.hotelCity,
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelCountry: (hotelCountry) => dispatch(setHotelCountry(hotelCountry)),
    setHotelCity: (hotelCity) => dispatch(setHotelCity(hotelCity)),
    setHotelSearch: (hotelSearch) => dispatch(setHotelSearch(hotelSearch)),
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withWidth()(HotelSection))
);

const countryList = [
  { title: "India", value: "india" },
  { title: "Sweden", value: "sweden" },
  { title: "Japan", value: "japan" },
  { title: "Indonesia", value: "indonesia" },
];
const locationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Chennai", value: "chennai" },
  { title: "Kolkata", value: "kolkata" },
  { title: "Delhi", value: "delhi" },
];
const nationalityList = [
  { title: "Indian", value: "indian" },
  { title: "American", value: "american" },
  { title: "European", value: "european" },
  { title: "African", value: "african" },
];
const DefChildCount = 6;
