import React, {useState, useEffect} from 'react'
import CommissionAndChargeForm from './CommissionAndChargeForm';
import OaCard from 'oahoc/OaCard';

import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import OaPriTabs from 'oahoc/OaPriTabs';
import OaError from 'pages/components/OaError';

export const CommissionAndCharge = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const queryVar = getQueryVar({...props, q: 'priTab'});
   let currentTab = getCurrentTab(queryVar, 'distributor');
   let agentId = getQueryVar({...props, q: 'agentId'});
   let status = getQueryVar({...props, q: 'status'});
  

    let tabs = [
        { name: 'distributor', label: 'Markup', url: '/admin/module/recharge/commission/distributor' },
    ];

    function renderTab(currentTab){
       
        switch(currentTab){
            case 'distributor':
                return <CommissionAndChargeForm currentTab={currentTab} agentId={agentId}/>
            default:
                return <OaError />
        }
    }
    
    return (
        <>
        {!isLoading &&
             <OaCard className={'oa-card-primary'}>
                <OaPriTabs tabs={tabs} currentTab={currentTab}/>
                    {renderTab(currentTab)}
            </OaCard>
        }
        </>
    )
}
export default CommissionAndCharge;