import React, {useState, useRef, useEffect} from 'react'
import {Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputAdornment } from '@material-ui/core';
import dateFnsFormat from 'date-fns/format';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormRadio from 'oahoc/OaFormRadio';
import OaFormPasswordField from 'oahoc/OaFormPasswordField';

import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaCard from 'oahoc/OaCard';
import OaFormAlerts from 'pages/components/OaFormAlerts';

import OaError from 'pages/components/OaError';
import {scrollToTop} from 'oautils/oaCommonUtils';

import AgentDetails from 'pages/agent/AgentDetails';
import OaTabBar from 'oahoc/OaTabBar';
import {getQueryVar, getCurrentTab, getAgencyId} from 'oautils/oaCommonUtils.js';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 
import {localforageGetItem} from 'oautils/oaForageUtils';

import {  Redirect } from "react-router-dom";
import BankAccount from './BankAccount';

import { Checkbox } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import OaLoading from 'pages/components/OaLoading';

const BankAccountForm = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isActive, setIsActive] = useState(false);

    const bankId = getQueryVar({...props, q: 'bankId'});

    useEffect(() => {
        // console.log(bankId);
        if(bankId>0){
            apiCall(WebApi.getBankList, {bankId:bankId}, (response) => {
                const data = response.data.filter((value) => {
                    return(value.bankId == bankId)
                });
                
                setData(data[0]);
                setIsActive( data[0].isActive);
                // console.log(data);
                
                setIsError(response.success===false);
                setIsLoading(false);
                
            });
        } else {
            setIsLoading(false);
        }
    }, [bankId]);

    

    return (<>
        {isLoading && <OaLoading />}
           
            {!isLoading  && 
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} >
                    <OaCard> 
                        <OaFormAlerts 
                            isSaving={isSaving} 
                            isSuccess={isSuccess} 
                            isError={isError} 
                            infoText={infoText} 
                            setIsError={setIsError} 
                            setIsSuccess={setIsSuccess} 
                        />  

                        <Formik 
                        initialValues={{accountName:data.accountName, accountNumber: data.accountNumber, bankAddress:data.bankAddress, 
                            bankName:data.bankName, branchName: data.branchName, ifscCode:data.ifscCode, isActivated:data.isActive, remarks: data.remarks}}
                            validationSchema={Yup.object().shape({
                                accountName: Yup.string().required('Account Name is required'),
                                accountNumber: Yup.string().max(18).required('Account Number is required'),
                                bankAddress: Yup.string().required('Bank Address is required'),
                                bankName: Yup.string().required('Bank Name is required'),
                                branchName: Yup.string().required('Branch Name is required'),
                                ifscCode: Yup.string().required('IFSC Code is required')
                            })}
                        
                        onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                            
                            localforageGetItem('user-details', function (err, value) {
                                
                                searchParams.loggedInUserId = value.userId;
                                searchParams.isActivated = isActive;
                                var ws="";
                                if(bankId>0){
                                    searchParams.bankId = bankId;
                                    ws = WebApi.updateBank
                                } else {
                                    ws = WebApi.addBank
                                }

                                apiCall(ws, searchParams, (response) => {
                                    setIsError(!response.success);
                                    setIsSuccess(response.success);
                                    setSubmitting(false);
                                    setIsSaving(false);
                                    setInfoText(response.message);
                                    if(response.success === true) {
                                        enableReinitialize=true;
                                        resetForm(true);
                                    }
                                });
                            });
                            
                            }
                        }
                    >
                        {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                            <Form>
                                <OaFormTextField required={true} label="Account Name" name="accountName"/>
                                <ErrorMessage name="accountName" component="div" className="error"/>

                                <OaFormTextField required={true} label="Account Number" name="accountNumber"/>
                                <ErrorMessage name="accountNumber" component="div" className="error"/>

                                <OaFormTextField required={true} label="Bank Address" name="bankAddress" multiline={true} rows="5"/>
                                <ErrorMessage name="bankAddress" component="div" className="error"/>

                                <OaFormTextField required={true} label="Bank Name" name="bankName"/>
                                <ErrorMessage name="bankName" component="div" className="error"/>

                                <OaFormTextField required={true} label="Branch Name" name="branchName"/>
                                <ErrorMessage name="branchName" component="div" className="error"/>

                                <OaFormTextField required={true} label="IFSC Code" name="ifscCode"/>
                                <ErrorMessage name="ifscCode" component="div" className="error"/>

                                <OaFormRadio label="Active" name="isActivated" value="isActivated" checked={isActive} onChange={()=>{setIsActive(!isActive);}}/> 
                                
                                <OaFormTextField name="remarks" label="Remarks" multiline={true} rows="5" />

                                <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}/>
                            </Form>
                        )}
                    </Formik>
                    </OaCard>
               </GridItem> 
            </GridContainer>}
            </>
    )
}

export default BankAccountForm;
