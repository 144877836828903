import React, { useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import TopSectionBg from "assets/img/corporate/book-illustration.png";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { ArrowBack, Home } from "@material-ui/icons";
import LoginBg from "assets/img/corporate/login-bg.png";
import {Formik, Form, ErrorMessage } from 'formik';
import OaFormAlerts from "pages/components/OaFormAlerts";
import * as Yup from "yup";
import BusCommissionAndCharge from "pages/module/bus/commission/CommissionAndCharge";
import HotelIcon from "@material-ui/icons/Hotel";
import { HotelReport } from "pages/report/hotel/HotelReport";
import HotelCommissionAndCharge from "pages/module/hotel/commission/CommissionAndCharge";
import { AppStateContext } from "layouts/AppStateProvider";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import {
    localforageSetItem,
    localforageClear,
    localforageGetItem,
    localforageSetDSAItem, 
    localforageGetDSAItem, 
    localforageClearDSA
} from "oautils/oaForageUtils";
import PublishIcon from "@material-ui/icons/Publish";
import { Flight as FlightReport } from "pages/report/flight/Flight";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import FlightMarkup from "pages/management/commission-and-markup/flight/Markup";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import { MoneyTransfer } from "pages/report/moneytransfer/MoneyTransfer";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { RechargeReport } from "pages/report/recharge/RechargeReport";
import RechargeCommissionAndCharge from "pages/module/recharge/commission/CommissionAndCharge";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import { BusReport } from "pages/report/bus/BusReport";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiInputBase-input": {
            minHeight: 35
        },
        "& .section-container": {
            padding: 0,
            margin: 0,
            fontFamily: FONTS.PRIMARY_FONT,
        },
        "& .breadcrumb-link-wrapper": {
            display: "flex",
            alignItems: "center",
        },
        "& .breadcrumb-indicator": {
            marginTop: 6,
            "& .MuiSvgIcon-root": {
                marginRight: 5,
                fontSize: 18,
            },
        },
        "& .breadcrumb-link": {
            display: "flex",
            alignItems: "center",
            color: COLORS.DEFAULT_TEXT_ACTIVE,
            cursor: "pointer",
            "& .MuiSvgIcon-root": {
                marginRight: 5,
                fontSize: 18,
            },
        },
        "& .section-title": {
            textAlign: "center",
            margin: "25px 0 35px",
            "& h3": {
                color: COLORS.DEFAULT_TEXT_ACTIVE,
                fontWeight: 400,
            }
        },
        "& .login-form-container": {
            fontFamily: FONTS.PRIMARY_FONT
        },
        "& .login-form-group": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            padding: "10px 0",
            "& .grid-row": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
            },
            "& .MuiFormControlLabel-label, label, .login-helper-link": {
                fontSize: 14,
                fontWeight: 700,
                color: COLORS.DEFAULT_TEXT_DARK,
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: 25,
                padding: "3px 5px",
            },
            "& .login-submit-button": {
                color: COLORS.PRIMARY_BG_LIGHT,
                border: `1px solid ${COLORS.BTN_BG_PRIMARY}`,
                backgroundColor: COLORS.BTN_BG_PRIMARY,
                borderRadius: 25,
                "&:hover": {
                    backgroundColor: COLORS.PRIMARY_BG_LIGHT,
                    color: COLORS.DEFAULT_TEXT_ACTIVE,
                },
            },
        },
        "& .login-section-column-left": {
            padding: "100px 150px 100px 150px",
        },
        "& .login-section-column-right": {
            padding: "50px 20px 0 0",
            "& img": {
                width: "100%",
            },
        },
    },
}));

const LoginPage = (props) => {

    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [alertTitle, setAlertTitle] = useState("");
    const [rememberUser, setRememberUser] = useState(false);
    const { routes, setRoutes } = useContext(AppStateContext);

    const rememberUserForLater = (rememberFlag) => {
        setRememberUser(rememberFlag);
        // console.log("remember user", rememberFlag);
    }

    React.useEffect(() => {
        localforageGetItem("user-details", function (err, value) {
			if (value) {

				if (value.userTypeAbv == "S" && value.corporateType == "corporate") {
					props.history.push("/flights");
				}
			}
		});
    }, []);

    return (
        <div className={classes.root}>
            <div className="section-container">
                <Grid container className="base-container">
                    <Grid item md={6} xs={12} className="login-section-column-left">
                        <div className="breadcrumb-link-wrapper">
                            <span className="breadcrumb-indicator">
                                <ArrowBack />
                            </span>
                            <a className="breadcrumb-link"
                                onClick={() => {props.history.push("/corporate")}}
                            >
                                <Home /> Home
                            </a>
                        </div>
                        
                        <div className="section-title">
                            <h3>SIGN IN</h3>
                        </div>

                        <div className="section-content">
                            <OaFormAlerts
                                isSaving={isSaving}
                                isSuccess={isSuccess}
                                isError={isError}
                                infoText={infoText}
                                setIsError={setIsError}
                                setIsSuccess={setIsSuccess}
                                alertTitle={alertTitle}
                            />
                            <Formik
                                initialValues={{ 
                                    username: "", 
                                    password: "", 
                                }}
                                validationSchema={Yup.object().shape({
                                    username: Yup.string().required("Username is required"),
                                    password: Yup.string().required("Password is required"),
                                })}
                                onSubmit={(params, { setSubmitting, resetForm, enableReinitialize }) => {
                                    // console.log("login params", params);
                                    setIsSaving(true);
                                    apiCall(WebApi.postLogin, params, (response) => {
                                        setAlertTitle("Error logging");
                                        setSubmitting(false);
                                        setIsSaving(false);
                                
                                        if (response != null && response.success === true) {
                                            setIsError(false);
                                            setIsSuccess(true);
                                            setAlertTitle("Success logging");
                                            setInfoText(response.message);
                                
                                            localforageSetItem("user-id", response.data.userId);
                                            localforageSetItem("access-key", response.accesskey);
                                            localforageSetItem("user-details", response.data, () => {
                                
                                              
                                                if (routes[1].views.length === 0) {
                                                  
                                                    const tempRoutes = [...routes];
                                                    response.data.serviceOption.map((options) => {
                                                        if (options.serviceValue === "flights") {
                                                            tempRoutes[1].views.push({
                                                            path: "/booking-report/flight",
                                                            name: "Flight",
                                                            icon: PublishIcon,
                                                            component: FlightReport,
                                                            layout: "/admin",
                                                            });
                                                            tempRoutes[6].views.push({
                                                            path: "/markup/flight",
                                                            name: "Flight Markup",
                                                            icon: MoneyOffIcon,
                                                            component: FlightMarkup,
                                                            layout: "/admin",
                                                            });
                                                        } else if (options.serviceValue === "moneytransfer") {
                                                            tempRoutes[1].views.push({
                                                            path: "/booking-report/moneytransfer",
                                                            name: "DMT",
                                                            icon: AttachMoneyOutlinedIcon,
                                                            component: MoneyTransfer,
                                                            layout: "/admin",
                                                            });
                                                        } else if (options.serviceValue === "recharge") {
                                                            tempRoutes[1].views.push({
                                                            path: "/booking-report/recharge",
                                                            name: "Recharge",
                                                            icon: PhoneAndroidIcon,
                                                            component: RechargeReport,
                                                            layout: "/admin",
                                                            });
                                                            tempRoutes[6].views.push({
                                                            path: "/commission/recharge",
                                                            name: "Recharge Markup",
                                                            icon: PhoneAndroidIcon,
                                                            component: RechargeCommissionAndCharge,
                                                            layout: "/admin",
                                                            });
                                                        } else if (options.serviceValue === "buses") {
                                                            tempRoutes[1].views.push({
                                                            path: "/booking-report/bus",
                                                            name: "Bus",
                                                            icon: DirectionsBusIcon,
                                                            component: BusReport,
                                                            layout: "/admin",
                                                            });
                                                            tempRoutes[6].views.push({
                                                            path: "/commission/bus",
                                                            name: "Bus Markup",
                                                            icon: DirectionsBusIcon,
                                                            component: BusCommissionAndCharge,
                                                            layout: "/admin",
                                                            });
                                                        } else if (options.serviceValue === "hotels") {
                                                            tempRoutes[1].views.push({
                                                            path: "/booking-report/hotel",
                                                            name: "Hotel",
                                                            icon: HotelIcon,
                                                            component: HotelReport,
                                                            layout: "/admin",
                                                            });
                                                            tempRoutes[6].views.push({
                                                            path: "/commission/hotel",
                                                            name: "Hotel Markup",
                                                            icon: HotelIcon,
                                                            component: HotelCommissionAndCharge,
                                                            layout: "/admin",
                                                            });
                                                        }
                                                    });
                                                    setRoutes(tempRoutes);
                                                }
                                                // props.history.push("/corporate/landingpage/flights");
                                                props.history.push("/flights");
                                            });
                                        } else if (response != null) {
                                            setAlertTitle("Error logging");
                                            setInfoText(response.message);
                                            setIsError(true);
                                            setIsSuccess(false);
                                        }
                                    });
                                }}
                            >
                                {({ values, setFieldValue, enableReinitialize, resetForm }) => 
                                <Form>
                                    {/* {isError && <span style={{ color: "red" }}>{infoText}</span>}
                                    {isSuccess && <span style={{ color: "green" }}>{infoText}</span>} */}

                                    <div className="login-form-container">
                                        <div className="login-form-group">
                                            <label>Email</label>
                                            <TextField 
                                                variant="outlined"
                                                placeholder="User ID"
                                                name="username"
                                                onChange={(e) => setFieldValue("username", e.target.value)}
                                                fullWidth
                                            />
                                        </div>

                                        <div className="login-form-group">
                                            <label>Password</label>
                                            <TextField 
                                                variant="outlined"
                                                placeholder="************"
                                                name="username"
                                                type="password"
                                                onChange={(e) => setFieldValue("password", e.target.value)}
                                                fullWidth
                                            />
                                        </div>

                                        <div className="login-form-group">
                                            <div className="grid-row">
                                                <div className="row-element-left">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={rememberUser}
                                                                onChange={() => rememberUserForLater(!rememberUser)}
                                                                value={rememberUser}
                                                            />
                                                        }
                                                        label="Remember Me"
                                                    />
                                                </div>

                                                <div className="row-element-right">
                                                    <a className="login-helper-link" href="">
                                                        Forgot Password ?
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="login-form-group">
                                            <Button
                                                fullWidth
                                                className="login-submit-button"
                                                type="submit"
                                            >
                                                LOGIN
                                            </Button>
                                        </div>

                                        <div className="login-form-group grid-row">
                                            <div className="grid-row">
                                                <div className="row-element-left">
                                                    <a className="login-helper-link" href="">
                                                        Not registered yet ?
                                                    </a>
                                                </div>

                                                <div className="row-element-right">
                                                    <a className="login-helper-link" href="">
                                                        Create an account
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                }
                            </Formik>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12} className="login-section-column-right">
                        <div className="section-image">
                            <img src={LoginBg} />
                        </div>
                    </Grid>
                </Grid>
                
            </div>
            
        </div>
    );
}

export default LoginPage;
