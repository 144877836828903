import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./BusResultShow.css";
import Link from "@material-ui/core/Link";
import dateFnsFormat from "date-fns/format";
import Collapse from "@material-ui/core/Collapse";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import BusSeatLayout from "pages/module/bus/BusSeatLayout";
//import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { currencyFormatter } from "oautils/oaCommonUtils";
import { makeStyles } from "@material-ui/core/styles";

const BusResultShow = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedSeatLayout, setExpandedSeatLayout] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = (event) => {
    setExpanded(!expanded);
    event.preventDefault();
  };

  const getDuration = (diffTime) => {
    const diffHrs = Math.ceil(diffTime / 60);
    const diffMin = Math.ceil(diffTime % 60);

    return diffHrs + "h " + diffMin + "m";
  };

  const selectSeatOnClick = () => {
    setExpandedSeatLayout(!expandedSeatLayout);
  };

  const width = window.innerWidth;
  const breakpoint = 760;

  return (
    <Paper className={classes.root}>
      <Grid container className="comp">
        <Grid item xs={12} md={3} className="trvlr">
          <Grid container>
            <Grid item xs={12}>
              <span className="result-item-travel-name">
                {props.TravelName}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="result-item-bus-type">{props.BusType}</span>
            </Grid>
            <Grid item align="right" className="bus-details-label">
              <Link href="#" onClick={handleExpandClick} variant="body2">
                <span className="result-item-details">Bus Details</span>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} align="center">
          <Grid container direction="row" className="ic">
            <Grid item xs={4} md={4}>
              <Grid container>
                <Grid item xs={12} className="bus-itin-text-grid-left">
                  <span className="result-item-city-name">
                    {props.BoardingPointsDetails[0].CityPointName}
                  </span>
                </Grid>
                <Grid item xs={12} className="bus-itin-text-grid-left">
                  {" "}
                  <span className="result-item-city-time">
                    {dateFnsFormat(
                      new Date(props.DepartureTime),
                      "do MMM yyyy "
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} className="bus-itin-text-grid-left">
                  {" "}
                  <span className="result-item-city-time">
                    {dateFnsFormat(new Date(props.DepartureTime), "hh:mm a")}
                  </span>
                </Grid>
                {/* <Grid item xs={12} align="right">  <span className="result-item-city-time">{dateFnsFormat(new Date(props.DepartureTime), 'do MMM yyyy hh:mm a')}</span></Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
              <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Grid item xs={12} className="bus-itin-text-grid-center">
                  <span className="result-item-duration">
                    {getDuration(props.durationInMinute)}
                  </span>
                </Grid>
                <Grid item xs={12} className="bus-itin-text-grid-center">
                  <div align="center">
                    <div className="arrow-container">
                      <span className="arrow-right"></span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} className="bus-itin-text-grid-center">
                  <span className="result-item-seat-left ">
                    {props.AvailableSeats} Seats left
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
              <Grid container>
                <Grid item xs={12} className="bus-itin-text-grid-right">
                  <span className="result-item-city-name">
                    {props.DroppingPointsDetails &&
                      props.DroppingPointsDetails[
                        props.DroppingPointsDetails.length - 1
                      ].CityPointName}
                  </span>
                </Grid>
                <Grid item xs={12} className="bus-itin-text-grid-right">
                  {" "}
                  <span className="result-item-city-time">
                    {dateFnsFormat(new Date(props.ArrivalTime), "do MMM yyyy")}
                  </span>
                </Grid>
                {/* <Grid item xs={12} align="left"> <span className="result-item-city-time">{dateFnsFormat(new Date(props.ArrivalTime), 'do MMM yyyy hh:mm a')}</span></Grid> */}
                <Grid item xs={12} className="bus-itin-text-grid-right">
                  {" "}
                  <span className="result-item-city-time">
                    {dateFnsFormat(new Date(props.ArrivalTime), "hh:mm a")}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2} className="price">
          <Grid container>
            {width < breakpoint ? (
              <>
                <Grid item sm={12} xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ paddingBottom: "5px", alignItems: "center" }}
                  >
                    {props.IdProofRequired && (
                      <Grid item>
                        <span className="result-item-smallest-text">
                          Id Proof Required
                        </span>
                      </Grid>
                    )}
                    {props.IsDropPointMandatory && (
                      <Grid item>
                        <span className="result-item-smallest-text">
                          Drop Point Mandatory
                        </span>
                      </Grid>
                    )}
                    {props.LiveTrackingAvailable && (
                      <Grid item>
                        <span className="result-item-smallest-text">
                          Live Tracking Available
                        </span>
                      </Grid>
                    )}
                    {props.MTicketEnabled && (
                      <Grid item>
                        <span className="result-item-smallest-text">
                          MTicket Enabled
                        </span>{" "}
                      </Grid>
                    )}
                    {props.MaxSeatsPerTicket > 0 && (
                      <Grid item>
                        <span className="result-item-smallest-text">
                          {props.MaxSeatsPerTicket} Max Seats Per Ticket
                        </span>{" "}
                      </Grid>
                    )}
                    {props.PartialCancellationAllowed && (
                      <Grid item>
                        <span className="result-item-smallest-text">
                          Partial Cancellation Allowed
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} align="right">
                  <span className="result-item-price-title">
                    Price starts from
                  </span>
                </Grid>
                <Grid item xs={12} align="right">
                  {" "}
                  {props.isSNF && (
                    <span className="result-item-commission">
                      &#x20B9;
                      {(
                        props.BusPrice.CommissionCharge.grossProductPrice -
                        (props.BusPrice.CommissionCharge.agentIncome +
                          props.BusPrice.CommissionCharge.agentMarkup)
                      ).toFixed(2)}{" "}
                      Net Cost + &#x20B9;
                      {props.BusPrice.CommissionCharge.agentIncome +
                        props.BusPrice.CommissionCharge.agentMarkup}{" "}
                      commission + markup
                    </span>
                  )}
                  <span
                    className="result-item-price"
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                  >
                    {currencyFormatter(
                      props.BusPrice.CommissionCharge.grossProductPrice
                    )}
                  </span>
                </Grid>
              </>
            )}

            {/* <Grid item xs={12} align={`${width < breakpoint ? "left" : "right"}`}>
              <span className="result-item-price-title">
                Price starts from
              </span>
            </Grid>
            <Grid item xs={12} align={`${width < breakpoint ? "left" : "right"}`}>
              {" "}
              {props.isSNF && (
                <span className="result-item-commission">
                  &#x20B9;
                  {(
                    props.BusPrice.CommissionCharge.grossProductPrice -
                    props.BusPrice.CommissionCharge.agentIncome
                  ).toFixed(2)}{" "}
                  Net Cost + &#x20B9;
                  {props.BusPrice.CommissionCharge.agentIncome + props.BusPrice.CommissionCharge.agentMarkup} commission + markup
                </span>
              )}

              <span className="result-item-price" style={{ fontFamily: "Roboto", fontWeight: "500" }}>
                {currencyFormatter(
                  props.BusPrice.CommissionCharge.grossProductPrice
                )}
              </span>

            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          md={8}
          xs={8}
          style={{ alignItems: `flex-end`, display: "flex" }}
        >
          {width > breakpoint ? (
            <>
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ paddingBottom: "5px" }}
              >
                {props.IdProofRequired && (
                  <Grid item>
                    <span className="result-item-smallest-text">
                      Id Proof Required
                    </span>
                  </Grid>
                )}
                {props.IsDropPointMandatory && (
                  <Grid item>
                    <span className="result-item-smallest-text">
                      Drop Point Mandatory
                    </span>
                  </Grid>
                )}
                {props.LiveTrackingAvailable && (
                  <Grid item>
                    <span className="result-item-smallest-text">
                      Live Tracking Available
                    </span>
                  </Grid>
                )}
                {props.MTicketEnabled && (
                  <Grid item>
                    <span className="result-item-smallest-text">
                      MTicket Enabled
                    </span>{" "}
                  </Grid>
                )}
                {props.MaxSeatsPerTicket > 0 && (
                  <Grid item>
                    <span className="result-item-smallest-text">
                      {props.MaxSeatsPerTicket} Max Seats Per Ticket
                    </span>{" "}
                  </Grid>
                )}
                {props.PartialCancellationAllowed && (
                  <Grid item>
                    <span className="result-item-smallest-text">
                      Partial Cancellation Allowed
                    </span>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container>
                <Grid item xs={12} align="left">
                  <span className="result-item-price-title">
                    Price starts from
                  </span>
                </Grid>
                <Grid item xs={12} align="left">
                  {" "}
                  {props.isSNF && (
                    <span className="result-item-commission">
                      &#x20B9;
                      {(
                        props.BusPrice.CommissionCharge.grossProductPrice -
                        props.BusPrice.CommissionCharge.agentIncome
                      ).toFixed(2)}{" "}
                      Net Cost + &#x20B9;
                      {props.BusPrice.CommissionCharge.agentIncome +
                        props.BusPrice.CommissionCharge.agentMarkup}{" "}
                      commission + markup
                    </span>
                  )}
                  <span
                    className="result-item-price"
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                  >
                    {currencyFormatter(
                      props.BusPrice.CommissionCharge.grossProductPrice
                    )}
                  </span>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item align="right" md={4} xs={4} style={{}}>
          {!expandedSeatLayout ? (
            <Button
              //  variant="contained"
              className={
                !expandedSeatLayout
                  ? "result-item-select-button"
                  : "result-item-select-button-over"
              }
              align="right"
              onClick={selectSeatOnClick}
            >
              {/* {!expandedSeatLayout ? "Select Seats" : "Close Seatlayout"} */}
              Select Seats
            </Button>
          ) : (
            <span onClick={selectSeatOnClick} className="close-btn">
              <i className="far fa-times-circle"></i>
            </span>
          )}
        </Grid>
      </Grid>

      <Collapse in={expandedSeatLayout} timeout="auto" unmountOnExit>
        <div style={{ margin: 20 }}>
          <Divider />
        </div>
        <BusSeatLayout {...props}></BusSeatLayout>
      </Collapse>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="BOARDING POINTS" {...a11yProps(0)} />
          <Tab label="DROPING POINTS" {...a11yProps(1)} />
          <Tab label="PRICE DETAILS" {...a11yProps(2)} />
          <Tab label="CANCELLATION POLICY" {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          {/* <table-detail >
                    <tbody>
                        <tr>
                            <td>BOARDING POINTS</td>
                            <td>TIME</td>
                        </tr>
                        {
                            props.BoardingPointsDetails.map((value, index) => 
                                <tr key={index}>
                                    <td>{value.CityPointName}</td>
                                    <td>{dateFnsFormat(new Date(value.CityPointTime), 'do MMM yyyy hh:m a')}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table-detail> */}

          <Grid item md={12} sx={12} className="">
            <Table className="table-detail table-detail-bordered" size="small">
              <TableHead className="thead-dark">
                <TableRow>
                  <TableCell scope="col">Boarding Points</TableCell>
                  <TableCell scope="col" align="center">
                    Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.BoardingPointsDetails.map((value, index) => (
                  <TableRow key={index}>
                    <TableCell>{value.CityPointName}</TableCell>
                    <TableCell align="center">
                      {dateFnsFormat(
                        new Date(value.CityPointTime),
                        "do MMM yyyy | hh:mm a"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* <table-detail border={3}>
                    <tbody>
                        <tr>
                            <td>DROPING POINTS</td>
                            <td>TIME</td>
                        </tr>
                        {
                            props.DroppingPointsDetails && props.DroppingPointsDetails.map((value, index) => 
                                <tr key={index}>
                                    <td>{value.CityPointName}</td>
                                    <td>{dateFnsFormat(new Date(value.CityPointTime), 'do MMM yyyy hh:m a')}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table-detail> */}
          <Grid item md={12} className="">
            <Table className="table-detail table-detail-bordered" size="small">
              <TableHead className="thead-dark">
                <TableRow>
                  <TableCell scope="col">DROPING POINTS</TableCell>
                  <TableCell scope="col" align="center">
                    TIME
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.DroppingPointsDetails &&
                  props.DroppingPointsDetails.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell>{value.CityPointName}</TableCell>
                      <TableCell align="center">
                        {dateFnsFormat(
                          new Date(value.CityPointTime),
                          "do MMM yyyy | hh:mm a"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2}>
          {/* <table-detail border={3} >
                    <tbody>
                        <tr>
                            <td colSpan={2}>PRICE DETAILS</td>
                        </tr>
                        <tr>
                            <td>{'Base Price'}</td>
                            <td>{props.BusPrice.BasePrice}</td>
                        </tr>
                        <tr>
                            <td>{'Agent Commission'}</td>
                            <td>{props.BusPrice.AgentCommission}</td>
                        </tr>
                        <tr>
                            <td>{'Agent MarkUp'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'Discount'}</td>
                            <td>{props.BusPrice.Discount}</td>
                        </tr>
                        <tr>
                            <td>{'OfferedPrice'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'OfferedPriceRoundedOff'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'OtherCharges'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'Agent MarkUp'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'PublishedPrice'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'PublishedPriceRoundedOff'}</td>
                            <td>{props.BusPrice.AgentMarkUp}</td>
                        </tr>
                        <tr>
                            <td>{'TDS'}</td>
                            <td>{props.BusPrice.TDS}</td>
                        </tr>
                        <tr>
                            <td>{'Tax'}</td>
                            <td>{props.BusPrice.Tax}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>GST DETAILS</td>
                        </tr>
                        <tr>
                            <td>{'CGSTAmount'}</td>
                            <td>{props.BusPrice.GST.CGSTAmount}</td>
                        </tr>
                        <tr>
                            <td>{'CGSTRate'}</td>
                            <td>{props.BusPrice.GST.CGSTRate}</td>
                        </tr>
                        <tr>
                            <td>{'CessAmount'}</td>
                            <td>{props.BusPrice.GST.CessAmount}</td>
                        </tr>
                        <tr>
                            <td>{'CessAmount'}</td>
                            <td>{props.BusPrice.GST.CessRate}</td>
                        </tr>
                        <tr>
                            <td>{'IGSTAmount'}</td>
                            <td>{props.BusPrice.GST.IGSTAmount}</td>
                        </tr>
                        <tr>
                            <td>{'IGSTRate'}</td>
                            <td>{props.BusPrice.GST.IGSTRate}</td>
                        </tr>
                        <tr>
                            <td>{'SGSTAmount'}</td>
                            <td>{props.BusPrice.GST.SGSTAmount}</td>
                        </tr>
                        <tr>
                            <td>{'SGSTRate'}</td>
                            <td>{props.BusPrice.GST.SGSTRate}</td>
                        </tr> 
                        <tr>
                            <td>{'TaxableAmount'}</td>
                            <td>{props.BusPrice.GST.TaxableAmount}</td>
                        </tr> 
                    </tbody>
                </table-detail> */}

          <Grid item md={12}>
            <Table className="table-detail table-detail-bordered" size="small">
              <TableHead className="thead-dark">
                <TableRow>
                  <TableCell scope="col">PRICE DETAILS</TableCell>
                  <TableCell scope="col"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="col">{"Base Price"}</TableCell>
                  <TableCell scope="col">{props.BusPrice.BasePrice}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"Agent Commission"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentCommission}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"Agent MarkUp"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"Discount"}</TableCell>
                  <TableCell scope="col">{props.BusPrice.Discount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"OfferedPrice"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"OfferedPriceRoundedOff"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"OtherCharges"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"Agent MarkUp"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"PublishedPrice"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">
                    {"PublishedPriceRoundedOff"}
                  </TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.AgentMarkUp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"TDS"}</TableCell>
                  <TableCell scope="col">{props.BusPrice.TDS}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"Tax"}</TableCell>
                  <TableCell scope="col">{props.BusPrice.Tax}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"CGSTAmount"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.CGSTAmount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"CGSTRate"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.CGSTRate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"CessAmount"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.CessAmount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"CessRate"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.CessRate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"IGSTAmount"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.IGSTAmount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"IGSTRate"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.IGSTRate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"SGSTAmount"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.SGSTAmount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"SGSTRate"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.SGSTRate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="col">{"TaxableAmount"}</TableCell>
                  <TableCell scope="col">
                    {props.BusPrice.GST.TaxableAmount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={3}>
          {/* <table-detail border={3}>
                    <tbody>
                        <tr>
                            <td colSpan={6}>CANCELLATION POLICY</td>
                        </tr>
                        <tr>
                            <td>Policy</td>
                            <td>Time Before Dept</td>
                            <td>From Date</td>
                            <td>To Date</td>
                            <td>Cancellation Charge Type</td>
                            <td>Cancellation Charge</td>
                        </tr>
                        {
                            props.CancellationPolicies && props.CancellationPolicies.map((value, key) => 
                                <tr key={key}>
                                    <td>{value.PolicyString}</td>
                                    {/* <td>{value.TimeBeforeDept}</td> */}
          {/* <td>{value.TimeBeforeDept.replace('$', ' hrs to ')} hrs</td>
                                    <td>{dateFnsFormat(new Date(value.FromDate), 'do MMM yyyy hh:m a')}</td>
                                    <td>{dateFnsFormat(new Date(value.ToDate), 'do MMM yyyy hh:m a')}</td>
                                    <td>{value.CancellationChargeType}</td>
                                    <td>{value.CancellationCharge}</td>
                                </tr> */}
          {/* )
                        } */}
          {/* </tbody>
                </table-detail> */}

          <Grid item xs={12}>
            <Table className="table-detail table-detail-bordered" size="small">
              <TableHead className="thead-dark">
                <TableRow>
                  <TableCell scope="col " align="center">
                    Policy
                  </TableCell>
                  <TableCell scope="col " align="center">
                    Time Before Dept
                  </TableCell>
                  <TableCell scope="col " align="center">
                    From Date
                  </TableCell>
                  <TableCell scope="col " align="center">
                    To Date
                  </TableCell>
                  <TableCell scope="col " align="center">
                    Cancellation Charge Type
                  </TableCell>
                  <TableCell scope="col " align="center">
                    Cancellation Charge
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.CancellationPolicies &&
                  props.CancellationPolicies.map((value, key) => (
                    <TableRow key={key}>
                      <TableCell>{value.PolicyString}</TableCell>
                      <TableCell align="center">
                        {value.TimeBeforeDept.replace("$", " hrs to ")} hrs
                      </TableCell>
                      <TableCell align="center">
                        {dateFnsFormat(
                          new Date(value.FromDate),
                          "do MMM yyyy hh:mm a"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {dateFnsFormat(
                          new Date(value.ToDate),
                          "do MMM yyyy hh:mm a"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {value.CancellationChargeType === 1
                          ? "Amount"
                          : value.CancellationChargeType === 2
                          ? "Percentage"
                          : "Nights"}
                      </TableCell>
                      <TableCell align="center">
                        {value.CancellationCharge}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </TabPanel>
      </Collapse>
    </Paper>
  );
};

export default BusResultShow;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    padding: 10,
    borderRadius: "10px",
    border: "1px solid #b1b1b1",
    "& .result-item-price": {
      color: theme.palette.secondary.main,
    },
    "& .close-btn": {
      fontSize: "30px",
      "& i": {
        color: theme.palette.secondary.main,
      },
    },
    "& .result-item-select-button": {
      background: `${theme.palette.buttons.tertiary}!important`,
      color: `${theme.palette.buttons.tertiaryContrastText}!important`,
    },
    "& .result-item-select-button-over": {
      color: `${theme.palette.buttons.tertiary}!important`,
      background: `none!important`,
      border: "none",
      // borderColor: theme.palette.secondary.main,
    },
    "& .result-item-select-button-r0-over": {
      color: theme.palette.secondary.main,
      background: `${theme.palette.secondary.contrastText}!important`,
      borderColor: theme.palette.secondary.main,
    },
    "& .bus-details-label": {
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    "& .result-item-details": {
      color: theme.palette.secondary.main,
    },
    "& .search-result-layout-selected-seat-price": {
      color: theme.palette.secondary.main,
    },
    "& .bus-ladies-seat": {
      borderColor: theme.palette.secondary.main,
    },
    "& .from-to-selection ,&.date-content ,& .MuiIconButton-root": {
      color: theme.palette.secondary.main,
    },
    "& .result-item-seat-left": {
      color: theme.palette.secondary.main,
    },

    "& .result-item-smallest-text": {
      backgroundColor: theme.palette.buttons.tertiary,
      color: theme.palette.buttons.tertiaryContrastText,
    },
    "& .bus-itin-text-grid-left": {
      textAlign: "right",
      [theme.breakpoints.down(768)]: {
        textAlign: "left",
      },
    },
    "& .result-item-city-name": {
      [theme.breakpoints.down(960)]: {
        fontSize: "14px",
      },
    },
    "& .bus-itin-text-grid-center": {
      textAlign: "center",
    },
    "& .bus-itin-text-grid-right": {
      textAlign: "left",
      [theme.breakpoints.down(768)]: {
        textAlign: "right",
      },
    },
    "& .trvlr": {
      textAlign: "left",
    },
    "& .result-item-travel-name": {
      [theme.breakpoints.down(960)]: {
        fontSize: "14px",
      },
    },
  },
}));
