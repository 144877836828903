import React, { useState, useEffect } from "react";

import OaCard from "oahoc/OaCard";
import OaPriTabs from "oahoc/OaPriTabs";
import { getQueryVar, getCurrentTab } from "oautils/oaCommonUtils";
import TransactionForm from "./TransactionForm";

const Transaction = (props) => {
  const tabs = [
    { name: "transaction", label: "Money Transfer", url: "/moneytransfer/transaction" },
  ];
  let currentTab = getCurrentTab(queryVar, "transaction");
  const queryVar = getQueryVar({ ...props, q: "priTab" });
  useEffect(() => {}, []);
  return (
    <div style={{   width:900 }}>
      
      <TransactionForm {...props} />
    
    </div>
  );
};

export default Transaction;
