import React from "react";
import WebApi from "api/ApiConstants";
import { connect } from "react-redux";
import {
  setHotelSearchResult,
  setTimerInitialMinute,
  setTimerInitialSecond,
  setHotelBookingInitialTime,
  setHotelFilters,
} from "redux/action";
import HotelSearchResultPage from "pages/module/hotel/HotelSearchResultPage";
import HotelSearchProgressPage from "pages/module/hotel/HotelSearchProgressPage";
import { useHistory } from "react-router-dom";
import { AppConstant } from "appConstant";

const HotelSearchProcessPage = (props) => {
  const [resultsFound, setResultsFound] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const history = useHistory();

  const redirectToSearchEngine = () => {
    setTimeout(() => {
      history.push("/hotels");
    }, 1500);
  };

  React.useEffect(() => {
    if (resultsFound === "failed") {
      redirectToSearchEngine();
    }
  }, [resultsFound]);

  React.useEffect(() => {
    if (resultsFound === "") {
      WebApi.getHotelSearch(
        props.hotelSearch,
        (response) => {
          if (response.success || response.message === "No Result Found") {
            if(!AppConstant.hotelRatingsLowToHigh) {
              response.data.hotelSearchResult.hotelResults = response.data.hotelSearchResult?.hotelResults.sort(function(a, b) {
                if(a.starRating > b.starRating) { return -1 }
                if(a.starRating < b.starRating) { return 1 }
                return 0
              });
            }
            // console.log("sorted result", response.data.hotelSearchResult.hotelResults);
            props.setHotelSearchResult(response.data.hotelSearchResult);
            props.setHotelFilters(response.data.filter);
            props.setTimerInitialMinute(0);
            props.setTimerInitialSecond(0);
            //  props.setInitialSessionTime(new Date().getTime() + 15 * 60 * 1000);
            props.setHotelBookingInitialTime(
              new Date().getTime() + 15 * 60 * 1000
            );
            window.sessionStorage.setItem("service", "hotel");
            setResultsFound("success");
            // history.push("/hotel/searchResult");
          } else {
            setIsError(!response.success);
            setInfoText(response.message + ". redirecting home ...");
            setResultsFound("failed");
          }
        },
        (error) => {
          // setResultsFound("failed");
          setResultsFound("success");
          props.setHotelSearchResult([]);
          console.log("internal error", error);
          // setInfoText("internal error! please contact admin");
          // setIsError(true);
          // setResultsFound("failed");
        }
      );
    }
  }, [resultsFound]);

  return resultsFound === "success" ? (
    <HotelSearchResultPage {...props} setResultsFound={setResultsFound} />
  ) : (
    <HotelSearchProgressPage
      {...props}
      isSaving={isSaving}
      isSuccess={isSuccess}
      isError={isError}
      infoText={infoText}
      setIsError={setIsError}
      setIsSuccess={setIsSuccess}
    />
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelSearchResult: (hotelSearchResult) =>
      dispatch(setHotelSearchResult(hotelSearchResult)),
    setHotelFilters: (hotelFilters) => dispatch(setHotelFilters(hotelFilters)),
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),

    setHotelBookingInitialTime: (hotelBookingInitialTime) =>
      dispatch(setHotelBookingInitialTime(hotelBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelSearchProcessPage);
