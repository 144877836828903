import WebApi from "api/ApiConstants";
//import { POST_CCAVENUE } from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

export async function submitDataToCcAvenue(request) {
    return new Promise(resolve => {
        apiCall(WebApi.postPayGoalOrderTransact, request, (response) => {
            // console.log(response)
            if (response.success) {
                var formData = {
                    encRequest: response.data.encRequest,//v2,v1
                    access_code: "AVQV87GI55AF72VQFA",//v2,v1
                    // request_type: "STRING", //v1
                    // response_type: "JSON", //v1
                    // reference_no: request.txnId, //v1
                    // amount: (Math.round(request.amount * 100) / 100).toFixed(2), //v1
                    // version: '1.1' //v1
                };
                submitData(formData, request.checkoutUrl);
            } else {
                console.log("error", response);
                resolve(response);
            }
        });
    });
}

function submitData(data, checkoutUrl){
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    // formElement.setAttribute("action", POST_CCAVENUE+"?command=confirmOrder");//v1
    formElement.setAttribute("action", checkoutUrl+"?command=initiateTransaction");//v2
    formElement.setAttribute("target", "_parent");

    for (let key1 in data) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key1);
      hiddenField.setAttribute("value", data[key1]);
      formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
}
