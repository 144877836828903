import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BtnGrp from "../../btnGroup/BtnGrp";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { GiHotMeal } from "react-icons/gi";
import { MdFastfood } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 160,
    // height: '260px' ,
    padding: "0rem 1rem",
    "& .card": {
      width: "100%",
      height: 65,
      [theme.breakpoints.down(600)]: {
        height: 126,
      },
      display: "flex",
      alignItems: "center",
      // padding: '0 2rem',
      justifyContent: "space-between",
      // gap:'0.5rem',
      // margin:'0.4rem 0',
      // background:'#eee',
      // boxShadow:'0 0 3px #000',
      borderBottom: `0.5px solid ${theme.palette.primary.defaultText}`,
    },
    "& .card__item ": {
      padding: "1rem",
      borderRadius: "6px",
      [theme.breakpoints.down(400)]: {
        padding: 5,
        width: 100,
      },
    },
    "& .card__item h3": {
      padding: "0rem",
      // fontSize: "1rem",
      fontSize: 13,
      margin: "0",
      lineHeight: "1.2",
      fontWeight: "normal",
      height: 30,
      marginTop: 10,
      overflow: "hidden",
      [theme.breakpoints.down(400)]: {
        fontSize: 12,
        padding: 5,
      },
      [theme.breakpoints.down(360)]: {
        fontSize: 11,
        padding: 5,
      },
    },
    "& .card__item p": {
      padding: "0rem",
      margin: "0.5rem 0",
      fontWeight: "bold",
    },
    "& .addRemove": {
      color: theme.palette.primary.defaultText,
      fontSize: "1.3rem",
      fontWeight: "bold",
      display: "inline-block",
      cursor: "pointer",
    },
    "& span": {
      gap: "1.2rem",
      fontSize: "1rem",
    },

    "& .qty_show": {
      margin: "0 0.3rem",
      fontSize: "1rem",
    },
    "&  .meal__icon": {
      fontSize: "2rem",
      // marginRight: "0.5rem",
    },
  },

  btn__grp: {
    minWidth: "120px",
    height: "43px",
    cursor: "default",
  },
}));

const MealCard = (props) => {
  const [qty, setQty] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();

  const IncreQty = () => {
    props.setselectedmeal(props.data, true);
    setQty(qty + 1);
  };

  const DecrQty = () => {
    props.setselectedmeal(props.data, false);
    setQty(qty > 0 ? qty - 1 : 0);
  };

  const setMealStatus = () => {
    var arr = props.isreturn ? props.selectedmealrt : props.selectedmeal;
    if (arr.length > 0) {
      for (let [ind, meal] of arr.entries()) {
        if (meal?.code == props.data.code) {
          setQty(meal.qty);
          return;
        }
      }
    }
  };

  React.useEffect(() => {
    setMealStatus();
  }, []);

  React.useEffect(() => {
    setMealStatus();
  }, [props.selectedmeal, props.selectedmealrt]);

  return (
    <div className={classes.root}>
      <div className="card">
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="meal__icon">
            <GiHotMeal />
          </span>
          <div className="card__item">
            <h3>{props.data.airlineDescription}</h3>
            <p>₹ {props.data.price}</p>
          </div>
        </div>
        <Button
          size="medium"
          variant="outlined"
          className={classes.btn__grp}
          style={{
            background: `${qty === 0 ? "" : theme.palette.buttons.tertiary}`,
          }}
        >
          <span
            className="addRemove"
            style={{
              color: `${
                qty === 0
                  ? theme.palette.primary.defaultText
                  : theme.palette.buttons.tertiaryContrastText
              }`,
            }}
            onClick={(e) => DecrQty()}
            title="remove meal"
          >
            {" "}
            {qty === 0 ? "" : "-"}
          </span>
          <span
            className="qty_show"
            style={{
              color: `${
                qty === 0
                  ? theme.palette.primary.defaultText
                  : theme.palette.buttons.tertiaryContrastText
              }`,
            }}
          >
            {qty === 0 ? "Add" : qty}
          </span>
          {!props.disableinc ? (
            <span
              className="addRemove"
              style={{
                color: `${
                  qty === 0
                    ? theme.palette.primary.defaultText
                    : theme.palette.buttons.tertiaryContrastText
                }`,
              }}
              onClick={(e) => IncreQty()}
              title="add meal"
            >
              {"+"}
            </span>
          ) : (
            <span
              className="addRemove"
              style={{
                color: `${
                  qty === 0
                    ? theme.palette.primary.defaultText
                    : theme.palette.buttons.tertiaryContrastText
                }`,
              }}
            >
              {"+"}
            </span>
          )}
        </Button>
        {/* <BtnGrp/> */}
      </div>
    </div>
  );
};

export default MealCard;
