import {
  SET_FLIGHT_DOMESTIC_CARRIERS,
  SET_FLIGHT_INTERNATIONAL_CARRIERS,
  SET_FLIGHT_AIRPORTS,
} from "redux/action";

export const flight = (state = {}, action) => {
  switch (action.type) {
    case SET_FLIGHT_DOMESTIC_CARRIERS:
      state = Object.assign({}, state);
      state.domCarriers = action.payload;
      return state;
    case SET_FLIGHT_INTERNATIONAL_CARRIERS:
      state = Object.assign({}, state);
      state.intlCarriers = action.payload;
      return state;
    case SET_FLIGHT_AIRPORTS:
      state = Object.assign({}, state);
      state.airports = action.payload;
      return state;

    default:
      return state;
  }
};
