import React from 'react';
import { AppStateContext } from "layouts/AppStateProvider";

const BusSeat = (props) => {
    const { SetMessage } = React.useContext(AppStateContext);
    const onSeatClick=(event, seatType, data)=> {

        let selectedSeat = [...props.selectedSeatIndex];

        if(data.SeatStatus && selectedSeat.length < props.MaxSeatsPerTicket && !selectedSeat.includes(data)) {
            event.target.style.background="#78B72A";
            event.target.style.color="#FFFFFF";

            selectedSeat.push(data)
            props.setSelectedSeatIndex(selectedSeat);
            props.setTotalFare(props.totalFare + data.Price.CommissionCharge.grossProductPrice);

        } else if (selectedSeat.includes(data)) {
            event.target.style.background="#F4F4F4";
            event.target.style.color="#BCBCBC"; 

            const index = selectedSeat.indexOf(data);
            
            if (index > -1) {
                selectedSeat.splice(index, 1);
            }
            
            props.setSelectedSeatIndex(selectedSeat);
            props.setTotalFare(props.totalFare - data.Price.CommissionCharge.grossProductPrice);
        } else if(!data.SeatStatus) {
            SetMessage('Seat already occupied')
        } else if(selectedSeat.length >= props.MaxSeatsPerTicket){
            SetMessage('Max booking cannot be greater than '+props.MaxSeatsPerTicket)
        }
    }

    // const onSeatClick1=(event, seatType, data)=> {

    //     let selectedSeat = [...props.selectedSeatIndex];

    //     if(data.SeatStatus && selectedSeat.length < props.MaxSeatsPerTicket && !selectedSeat.includes(data.SeatIndex)) {
    //         event.target.style.background="#78B72A";
    //         event.target.style.color="#FFFFFF";

    //         selectedSeat.push(data.SeatIndex)
    //         props.setSelectedSeatIndex(selectedSeat);
    //         props.setTotalFare(props.totalFare + data.Price.CommissionCharge.grossProductPrice);

    //     } else if (selectedSeat.includes(data.SeatIndex)) {
    //         event.target.style.background="#F4F4F4";
    //         event.target.style.color="#BCBCBC"; 

    //         const index = selectedSeat.indexOf(data.SeatIndex);
            
    //         if (index > -1) {
    //             selectedSeat.splice(index, 1);
    //         }
            
    //         props.setSelectedSeatIndex(selectedSeat);
    //         props.setTotalFare(props.totalFare - data.Price.CommissionCharge.grossProductPrice);
    //     } else if(!data.SeatStatus) {
    //         alert('Seat already occupied')
    //     } else if(selectedSeat.length >= props.MaxSeatsPerTicket){
    //         alert('max booking cannot be greater than '+props.MaxSeatsPerTicket)
    //     }
    // }
   
    return (// seat = 1, sleeper = 2, seatCumSleeper = 3, UpperBerth = 4,LowerBerth = 5
        <>

        {
            props.data  || props.data ==="ESC"? <>
                { props.data.SeatType === 1 && 
                    <div  style={{width:`${20 * props.data.Height}px`, height: `${20 * props.data.Width}px`, left: `${20 * props.data.RowNo}px`, top: `${20 * props.data.ColumnNo}px`}} className={(props.data.IsMalesSeat? 'bus-gents-seat' : props.data.IsLadiesSeat? 'bus-ladies-seat': '') + (props.data.SeatStatus ? ' bus-available-seat': ' bus-booked-seat') + ' bus-seat'} onClick={(event)=>{onSeatClick(event, 'seat', props.data)}}>{props.data.SeatName}</div>
                }

                {props.data && props.data.SeatType === 2 && 
                    <div style={{width:`${20 * props.data.Height + 10}px`, height: `${20 * props.data.Width}px`, left: `${20 * props.data.RowNo}px`, top: `${20 * props.data.ColumnNo}px`}} className={(props.data.IsMalesSeat? 'bus-gents-seat' : props.data.IsLadiesSeat? 'bus-ladies-seat': '') + ( props.data.SeatStatus ? ' bus-available-seat': ' bus-booked-seat')+ ' bus-seat sleeper'} onClick={(event)=>{onSeatClick(event, 'sleeper', props.data)}}>{props.data.SeatName}</div>
                }


                {props.data && props.data.SeatType === 3 && 
                    <div style={{width:`${20 * props.data.Height}px`, height: `${20 * props.data.Width}px`, left: `${20 * props.data.RowNo}px`, top: `${20 * props.data.ColumnNo}px`}} className={(props.data.IsMalesSeat? 'bus-gents-seat' : props.data.IsLadiesSeat? 'bus-ladies-seat': '') + ( props.data.SeatStatus ? ' bus-available-seat': ' bus-booked-seat')+ ' bus-seat seat-cum-sleeper'} onClick={(event)=>{onSeatClick(event, 'seatCumSleeper', props.data)}}>{props.data.SeatName}</div>
                }


                {props.data && props.data.SeatType === 4 && 
                    <div  style={{width:`${20 * props.data.Height}px`, height: `${20 * props.data.Width}px`, left: `${20 * props.data.RowNo}px`, top: `${20 * props.data.ColumnNo}px`}} className={(props.data.IsMalesSeat? 'bus-gents-seat' : props.data.IsLadiesSeat? 'bus-ladies-seat': '') + ( props.data.SeatStatus ? ' bus-available-seat': ' bus-booked-seat')+ ' bus-seat upper-berth'} onClick={(event)=>{onSeatClick(event, props.data.SeatIndex, 'UpperBerth', props.data.SeatFare)}}>{props.data.SeatName}</div>
                }


                {props.data && props.data.SeatType === 5 && 
                    <div  style={{width:`${20 * props.data.Height}px`, height: `${20 * props.data.Width}px`, left: `${20 * props.data.RowNo}px`, top: `${20 * props.data.ColumnNo}px`}} className={(props.data.IsMalesSeat? 'bus-gents-seat' : props.data.IsLadiesSeat? 'bus-ladies-seat': '') + ( props.data.SeatStatus ? ' bus-available-seat': ' bus-booked-seat')+ ' bus-seat lower-berth'} onClick={(event)=>{onSeatClick(event, props.data.SeatIndex, 'LowerBerth', props.data.SeatFare)}}>{props.data.SeatName}</div>
                }
            </> :
            props.data ==="ESC" ?<></>: <div style={{width:20, height: 20, margin:5}}></div>
        }
            
        <style>{`
        .bus-seat {
            min-height: 20px;
        }
        `}</style>    
        </>
    )
}

export default BusSeat;