import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import OaFormAlerts from "pages/components/OaFormAlerts";
import {
  localforageSetItem,
  localforageGetItem,
  localforageClear,
} from "oautils/oaForageUtils";
import popup from "assets/lookMyTicket/images/popup.jpg";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import dateFnsFormat from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  modalRoot: {
    "& .modal-content": {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      // padding: "30px 10px 0",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(960)]: {
        padding: "180px 10px 0",
      },
    },
    "& .modal-body": {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.background.default,
      borderRadius: 5,
      width: 900,

      border: "7px solid #fff",
      borderRadius: 30,

      [theme.breakpoints.down(600)]: {
        width: "90vw",
      },
    },
    "& .mainContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      backgroundImage: `url(${popup})`,
      backgroundSize: "cover",
      padding: 20,
      borderRadius: 30,
    },
    "& .formContainer": {
      width: 300,
      backgroundColor: "#fff",
      borderRadius: 10,
      "& .locationContainer": {
        display: "flex",
        "& .enquiry-form-item": {
          width: "50%",
        },
      },
      "& .dateContainer": {
        display: "flex",
        "& .enquiry-form-item": {
          width: "50%",
        },
      },
    },
    "& .form-top-section": {
      position: "relative",
      //   padding: "10px 15px 20px",
      textAlign: "center",
      "& .titleHeader": {
        marginTop: 10,
        marginBottom: 10,
        fontSize: 20,
        fontWeight: 500,
      },
      //   borderBottom: "1px solid #616060",
      "& h3": {
        color: theme.palette.primary.defaultText,
        lineHeight: 1.58,
        marginTop: 0,
        marginBottom: 0,
      },
      "& button.form-close-button": {
        position: "absolute",
        height: 30,
        width: 30,
        borderRadius: "50%",
        border: "none",
        background: "rgba(0, 0, 0, 0.8)",
        color: "#FFF",
        fontSize: 22,
        top: -16,
        right: -16,
        cursor: "pointer",
      },
    },
    "& .form-mid-section": {
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .form-bottom-section": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      height: 50,
      //   borderTop: "1px solid #616060",
      padding: 15,

      paddingTop: 5,
    },
    "& .enquiry-form-row": {
      //   display: "grid",
      //   gap: 30,
      display: "flex",
      flexDirection: "column",
    },
    "& .enquiry-form-item": {
      "& .MuiInputBase-root": {
        padding: "0px !important",
      },
      "& .MuiInputBase-input": {
        fontSize: 12,
      },
      "& .MuiFormControl-root": {
        marginTop: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: 30,
      },
      "& .oa-form-element-primary": {
        height: 20,
        "& .MuiInputBase-input": {
          fontSize: 13,
        },
      },
      padding: "5px 10px",
      "&.item-left": {
        gridColumn: "1 / span 1",
        [theme.breakpoints.down(600)]: {
          gridColumn: "1 / span 2",
        },
      },
      "&.item-right": {
        gridColumn: "2 / span 1",
        [theme.breakpoints.down(600)]: {
          gridColumn: "1 / span 2",
        },
      },
    },
    "& .enquiry-form-item label": {
      color: theme.palette.primary.defaultText,
      fontSize: 12,
    },
    "& .enquiry-form-input": {
      width: "100%",
      border: "none",
      border: "solid 2px #eee",
      borderRadius: 5,
      padding: 5,
      background: "none",
      color: "#000",
      "&:-webkit-autofill,-webkit-autofill:focus": {
        transition: "background-color 600000s 0s, color 600000s 0s",
      },
    },
    "& .button-enquiry-form": {
      cursor: "pointer",
      padding: "8px 40px",
      border: "none",
      margin: "5px 0 5px 10px",
      borderRadius: "25px",
    },
    "& .button-submit": {
      background: "#ffb270",
      color: "#fff",
      "&:hover": {
        background: "#a26632",
      },
    },
    "& .button-close": {
      background: "#ef2424",
      color: "#fff",
      "&:hover": {
        background: "#810b0b",
      },
    },
  },
}));

const IdleTimeoutEnquiryForm = (props) => {
  const classes = useStyles();
  const [openEnquiryForm, setOpenEnquiryForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [airportList, setAirportList] = useState(popularCities);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const initialValues = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: "",

    departureDate: new Date(),
    returnDate: new Date(),
    departure: airportList[0],
    destination: airportList[1],
  };

  const fetchAirports = (searchParams) => {
    // setFetchingAirports(true);
    if (searchParams.length >= 3) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams.trim(),
        },
        (response) => {
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        },
        (error) => console.log("fetchAirportList error", error)
      );
    }
  };

  const validationSchema = Yup.object().shape({
    // firstname: Yup.string().required("First Name is Required"),
    // email: Yup.string()
    //   .email("Must be a valid email")
    //   .max(255)
    //   .required("e-Mail address is Required"),
    // message: Yup.string().required("Message/Comment is Required"),
  });

  const onFormSubmit = (values, { setSubmitting, resetForm }) => {
    values.deviceName = "phone";
    console.log(isMobile);
    // console.log(new Date(values.departureDate));

    let valueObj = {
      departDate: dateFnsFormat(values.departureDate, "yyyy-MM-dd"),
      returnDate: dateFnsFormat(values.returnDate, "yyyy-MM-dd"),
      // departDate: "22/11/2023",
      // returnDate: "22/11/2023",
      destination: values.destination.airportCode,
      origin: values.departure.airportCode,
      phone: values.phone,
      deviceName: isMobile ? "phone" : "website",
      email: values.email,
      partner_Id: 0,
      message: values.message,
      firstname: values.firstname,
      lastname: values.lastname,
    };
    console.log("values", valueObj);

    // setIsError(false);
    // setIsSuccess(false);
    // setSubmitting(true);
    // setIsSaving(true);

    // console.log("The search params are ", searchParams);
    apiCall(WebApi.getB2CCustomerQuery, valueObj, (response) => {
      // console.log(response);
      // setIsError(!response.success);
      setIsSuccess(response.success);
      setSubmitting(false);
      // setIsSaving(false);
      setInfoText(response.message);
      resetForm();
    });
  };

  const showEnquiry = () => {
    setOpenEnquiryForm(true);
  };

  const hideEnquiry = () => {
    setOpenEnquiryForm(false);
    startIdleTimer();
  };

  const setIdleTimeout = (millis, onIdle) => {
    var timeout = 0;
    startTimer();

    function startTimer() {
      timeout = setTimeout(onExpires, millis);
      document.addEventListener("mousemove", onActivity);
      document.addEventListener("keypress", onActivity);
    }

    function onExpires() {
      console.log("you are idle for " + millis + " ms, displaying query form");
      timeout = 0;
      onIdle();
    }

    function onActivity() {
      if (timeout) clearTimeout(timeout);
      //since the mouse is moving, we turn off our event hooks for 1 second
      document.removeEventListener("mousemove", onActivity);
      document.removeEventListener("keypress", onActivity);
      setTimeout(startTimer, 1000);
    }
  };

  const startIdleTimer = () => {
    setIdleTimeout(10000, function() {
      showEnquiry();
    });
  };

  useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      if (
        value &&
        (value.userType != "agent" && value.userType != "direct_agent")
      ) {
        // console.log("user-details",value);
        startIdleTimer();
      }
    });
  }, []);

  return (
    <Modal
      className={classes.modalRoot}
      open={openEnquiryForm}
      onClose={hideEnquiry}
    >
      <div className="modal-content">
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            onFormSubmit(values, { setSubmitting, resetForm })
          }
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <div className="mainContainer">
                  <div className="formContainer">
                    <div className="form-top-section">
                      <button
                        className="form-close-button"
                        onClick={hideEnquiry}
                      >
                        <span>&times;</span>
                      </button>
                      <div className="titleHeader">Excellent Deals</div>
                    </div>

                    <div className="form-mid-section">
                      <div className="enquiry-form-wrapper">
                        <div className="enquiry-form-row">
                          <div className="enquiry-form-item item-left">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="enquiry-form-input"
                              placeholder="enter your first name"
                              name="firstname"
                              value={values.firstname}
                              onChange={(event) => {
                                setFieldValue("firstname", event.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className="error"
                            />
                          </div>

                          <div className="enquiry-form-item item-right">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="enquiry-form-input"
                              placeholder="enter your last name"
                              name="lastname"
                              value={values.lastname}
                              onChange={(event) => {
                                setFieldValue("lastname", event.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="lastname"
                              component="div"
                              className="error"
                            />
                          </div>
                          <div className="locationContainer">
                            <div className="enquiry-form-item item-left ">
                              <label>Departure</label>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={(option) =>
                                  option.cityName.toString()
                                }
                                onKeyUp={(event) =>
                                  fetchAirports(event.target.value.trim())
                                }
                                value={values.departure}
                                options={airportList || []}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    setAirportList(props.popularcities);
                                  } else {
                                    // changeHandler("from", newValue?.countryCode);
                                    setFieldValue(`departure`, newValue);
                                    // props.setfieldvalue(
                                    //   `departure`,
                                    //   newValue.airportCode
                                    // );
                                  }
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                              <ErrorMessage
                                name="lastname"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="enquiry-form-item item-right">
                              <label>Destination</label>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={(option) =>
                                  option.cityName.toString()
                                }
                                value={values.destination}
                                onKeyUp={(event) =>
                                  fetchAirports(event.target.value.trim())
                                }
                                options={airportList || []}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    setAirportList(props.popularcities);
                                  } else {
                                    // changeHandler("from", newValue?.countryCode);
                                    setFieldValue(`destination`, newValue);
                                    // props.setfieldvalue(
                                    //   `departure`,
                                    //   newValue.airportCode
                                    // );
                                  }
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                              <ErrorMessage
                                name="lastname"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="dateContainer">
                            <div className="enquiry-form-item item-right">
                              <label>Departure Date</label>
                              <OaOutlinedDatePicker
                                showDisabledMonthNavigation
                                className="date-picker"
                                name={"departureDate"}
                                minDate={new Date()}
                                customInput={
                                  <TextField
                                    id="standard-basic"
                                    variant="standard"
                                    // fullWidth={isMobileScreen}
                                  />
                                }
                                selected={values.departureDate}
                                onChange={(value) => {
                                  console.log(value);
                                  setFieldValue("departureDate", value);
                                }}
                                monthsShown={1}
                                dateFormat="dd MMM yyyy"
                                // labeltxt="DEPARTURE DATE"
                                // secondarytxt={
                                //   days[props.values.sectors[0].departDateDisplay.getDay()]
                                // }
                                showinput={false}
                              />
                              <ErrorMessage
                                name="lastname"
                                component="div"
                                className="error"
                              />
                            </div>
                            <div className="enquiry-form-item item-right">
                              <label>Return Date</label>
                              <OaOutlinedDatePicker
                                showDisabledMonthNavigation
                                className="date-picker"
                                name="returnDate"
                                minDate={new Date()}
                                customInput={
                                  <TextField
                                    id="standard-basic"
                                    variant="standard"
                                    // fullWidth={isMobileScreen}
                                  />
                                }
                                selected={values.returnDate}
                                onChange={(value) =>
                                  setFieldValue("returnDate", value)
                                }
                                monthsShown={1}
                                dateFormat="dd MMM yyyy"
                                // labeltxt="DEPARTURE DATE"
                                // secondarytxt={
                                //   days[props.values.sectors[0].departDateDisplay.getDay()]
                                // }
                                showinput={false}
                              />
                              <ErrorMessage
                                name="lastname"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="enquiry-form-row">
                          <div className="enquiry-form-item item-left">
                            <label>Phone</label>
                            <input
                              type="number"
                              className="enquiry-form-input"
                              placeholder="enter your phone number"
                              name="phone"
                              value={values.phone}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error"
                            />
                          </div>

                          <div className="enquiry-form-item item-right">
                            <label>Email</label>
                            <input
                              type="text"
                              className="enquiry-form-input"
                              placeholder="enter your email"
                              name="email"
                              value={values.email}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="enquiry-form-row">
                          <div className="enquiry-form-item">
                            <label>Message</label>
                            <textarea
                              className="enquiry-form-input multiline-input"
                              rows="4"
                              placeholder="enter your message/query"
                              name="message"
                              value={values.message}
                              onChange={(event) => {
                                setFieldValue("message", event.target.value);
                              }}
                            ></textarea>
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-bottom-section">
                      <button
                        className="button-enquiry-form button-submit"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button
                        className="button-enquiry-form button-close"
                        onClick={hideEnquiry}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default IdleTimeoutEnquiryForm;

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];
