import React from 'react'
import GridItem from 'components/Grid/GridItem';

const OaCardGridItem = (props) => {
    return (
        <GridItem xs={12} sm={12} md={props.md}> 
            {props.children}
        </GridItem>
    )
}

export default OaCardGridItem;
