import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import AddBeneficiary from "pages/module/dmt/AddBeneficiary";
import AddSender from "pages/module/dmt/AddSender";
import BeneficiaryList from "pages/module/dmt/BeneficiaryList";
import TransactionList from "pages/module/dmt/TransactionList";
import VerifyOtp from "pages/module/dmt/VerifyOtp";
import Transaction from "pages/module/dmt/Transaction";
import CommissionManager from "pages/module/dmt/CommissionManager";
import Refund from "pages/module/dmt/Refund";
import MobileSearch from "pages/module/dmt/MobileSearch";
import { Box, makeStyles } from "@material-ui/core";
import TransactionResponseList from "pages/module/dmt/TransactionResponseList";
import { COLORS } from "assets/css/CssConstants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import UndoOutlinedIcon from "@material-ui/icons/UndoOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import { localforageGetItem } from "oautils/oaForageUtils";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            color: theme.palette.primary.darkText,
        },
        // "& .MuiOutlinedInput-root": {
        //     borderBottom: `solid 0.5px ${COLORS.DEFAULT_TEXT_DARK}`,
        // },
        "& .MuiInputBase-input": {
            color:theme.palette.primary.darkText,
        },
        "& .MuiAutocomplete-popupIndicator": {
            display: "none"
        },
        [theme.breakpoints.down(768)]: {
            minWidth: "100%",
            maxWidth: "100%",
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
            padding: "0 25px",
            margin: "0 !important",
        },
        "& .dmt-content-wrapper": {
            minWidth: 860,
            padding: 15,
            [theme.breakpoints.down(960)]: {
                minWidth: "100vw",
                padding: 20,
            },
            "& .dmt-content-bordered": {
                borderBottom: `solid 0.5px ${theme.palette.secondary.chineseGray}`,
                padding: "0 0 0 0",
            },
            "& .dmt-content": {
                padding: "0 0 0 0",
            },
            "& .oa-input-white:focus-within": {
                backgroundColor: theme.palette.primary.lightText,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderBottom: `solid 1.5px ${theme.palette.primary.defaultText}`,
                borderRadius: 0,
            },
            "& .MuiInputBase-root": {
                "& .MuiInputBase-input": {
                    paddingBottom: 6,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 0,
                    borderBottom: `solid 2px ${theme.palette.primary.defaultText}`,
                },
            },
            "& .oa-input-white, .input-landing-page": {
                background: `${theme.palette.primary.lightText} !important`,
                borderRadius: 0,
            },
        },
    },
    DmtSection_quicklinks: {
        borderTop: `solid 0.5px ${theme.palette.secondary.chineseGray}`,
        padding: "10px 25px 0",
        marginTop: 10,
        display: "flex",
        justifyContent: "flex-start",
        [theme.breakpoints.down(600)]: {
            flexWrap: "wrap",
            justifyContent: "center",
        },
        "& .quick-link-item": {
            margin: 5,
            border: `solid 1px ${theme.palette.primary.lightText}`,
            padding: "3px 10px",
            color: theme.palette.primary.active,
            borderRadius: 4,
            cursor: "pointer",
            width: 105,
            [theme.breakpoints.down(600)]: {
                width: 97,
            },
            "&:hover": {
                border: `solid 1px ${theme.palette.secondary.active}`,
                color: theme.palette.secondary.active,
            },
            "& .qlink-icon-wrapper": {
                textAlign: "center",
                "& .MuiSvgIcon-root": {
                    fontSize: 20,
                },
            },
            "& .qlink-label-wrapper": {
                fontSize: 10,
                textAlign: "center",
                whiteSpace: "nowrap",
                [theme.breakpoints.down(600)]: {
                    whiteSpace: "break-spaces",
                },
            },
        }
    },
}));

const DMTSection = (props) => {

    const classes = useStyles();
    // console.log("DMT props", props);
    const [userType, setUserType] = useState(null);
    const [corporateType, setCorporateType] = useState(null);

    useEffect(() => {
        localforageGetItem("user-details", function (err, details) {
            if (details != null) {
                setUserType(details.userType);
                setCorporateType(details.corporateType);
            }
        });
    }, []);

    const openQuickLink = (url) => {
        // console.log("quicklink", url);
        props.history.push({
            pathname: url,
        });
    }

    const innerPageUrl = "/moneytransfer/";
    const optionsDMT = [
        {
            name: "searchRemitter",
            label: "Search Remitter",
            url: innerPageUrl + "search-sender",
            icon: <SearchOutlinedIcon />,
        },
        {
            name: "querytransaction",
            label: "Query Transaction",
            url: innerPageUrl + "refund",
            icon: <InfoOutlinedIcon />,
        },
        {
            name: "refundtransaction",
            label: "Refund Transaction",
            url: innerPageUrl + "refund",
            icon: <UndoOutlinedIcon />,
        },
        {
            name: "dmtreport",
            label: "DMT Report",
            url: "/admin/booking-report/moneytransfer",
            icon: <AttachMoneyOutlinedIcon />,
        },
    ];

    return (
        <div className={classes.root}>
            <div className="dmt-content-wrapper">
                <div className="dmt-content">
                    <Switch>
                        <Route
                            path="/moneytransfer/add-beneficiary"
                            exact
                            component={AddBeneficiary}
                        />
                        <Route
                            path="/moneytransfer/add-sender"
                            exact
                            component={AddSender}
                        />
                        <Route
                            path="/moneytransfer/beneficiary-list"
                            exact
                            component={BeneficiaryList}
                        />
                        <Route
                            path="/moneytransfer/verify-otp"
                            exact
                            component={VerifyOtp}
                        />
                        <Route
                            path="/moneytransfer/transaction"
                            exact
                            component={Transaction}
                        />
                        <Route
                            path="/moneytransfer/commission"
                            exact
                            component={CommissionManager}
                        />
                        <Route
                            path="/moneytransfer/transaction-list"
                            exact
                            component={TransactionList}
                        />
                        <Route path="/moneytransfer/refund" exact component={Refund} />
                        <Route
                            path="/moneytransfer/search-sender"
                            exact
                            component={MobileSearch}
                        />
                        <Route
                            path="/moneytransfer/transaction/:serialNo"
                            exact
                            component={TransactionResponseList}
                        />

                        <Route
                            path="/moneytransfer"
                            exact
                            component={MobileSearch}
                        />
                    </Switch>
                </div>
            </div>
            {userType && userType == "agent" && corporateType && corporateType != "corporate" && 
            <Box className={classes.DmtSection_quicklinks}>
                {optionsDMT && optionsDMT.map((val, ind) => (
                    <div item className="quick-link-item" key={ind} onClick={() => openQuickLink(val.url)}>
                        <div className="qlink-icon-wrapper">
                            {val.icon}
                        </div>
                        <div className="qlink-label-wrapper">{val.label}</div>
                    </div>
                ))}
            </Box>}
        </div>
    );
}

export default DMTSection;
