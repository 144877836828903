import React, {useState, useEffect} from 'react'
import OaCard from 'oahoc/OaCard';
import {getAgencyId} from 'oautils/oaCommonUtils.js';
import OaTabBar from 'oahoc/OaTabBar';
import AgentDetails from 'pages/agent/AgentDetails';

import HotelReportSearchForm from 'pages/report/hotel/HotelReportSearchForm';
import HotelReportSearchResult from 'pages/report/hotel/HotelReportSearchResult';
import dateFnsFormat from 'date-fns/format';

export const HotelReport = (props) => {

    const [agencyId, setAgencyId] = useState(getAgencyId(props));
    const [isLoading, setIsLoading] = useState(true);

    let d = new Date;
    let fromDate = d; //.setDate(d.getDate() - 7);
    let today = new Date();

    let initialParams = {
        agencyId: agencyId,
        hotelBookingFromDateDisplay: fromDate,
        hotelBookingToDateDisplay: today,
        hotelBookingFromDate: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
        hotelBookingToDate: dateFnsFormat(today, 'dd-MM-yyyy'),
        accountNo:'',
        bookingStatus: 'All'
    };

    const [searchParams, setSearchParams] = useState(initialParams);
    const [tableKey, setTableKey] = useState(0);
    
    function hotelReportLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    let tabs = [
        { name: 'hotel', label: 'Hotel', url: '/admin/booking-report/hotel' },
    ];

    let currentTab = 'hotel';

    useEffect(() => {
        let aId = getAgencyId(props)
        setAgencyId(aId);
        setSearchParams({...searchParams, agencyId: aId});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (
        <>
        {!isLoading &&
            <OaCard className={'oa-card-primary'}>
                <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
                {agencyId && 
                    (
                        <OaCard>
                            <AgentDetails agencyId={agencyId} />
                        </OaCard>
                    )
                }
                <OaCard>
                    <HotelReportSearchForm 
                        target={hotelReportLoader} 
                        agencyId={agencyId}
                        searchParams={searchParams}
                        setIsLoading={setIsLoading}
                    />
                </OaCard>

                <OaCard>
                    <HotelReportSearchResult key={tableKey} searchParams={searchParams} agencyId={agencyId} target={hotelReportLoader} />
                </OaCard>
            </OaCard>
        }
        </>
    )
}
export default HotelReport;