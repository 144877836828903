import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OaCard from 'oahoc/OaCard';
import { Formik, Form, ErrorMessage } from 'formik';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormRadio from 'oahoc/OaFormRadio';
import Collapse from '@material-ui/core/Collapse';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';
import OaFormFileUpload from 'oahoc/OaFormFileUploadNew';
import OaTabBar from 'oahoc/OaTabBar';
import {scrollToTop} from 'oautils/oaCommonUtils';
import dateFnsFormat from 'date-fns/format';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';

import {localforageGetItem} from 'oautils/oaForageUtils';

import axios from 'axios';
import * as Yup from 'yup';
// check

import { red } from '@material-ui/core/colors';
import { AppConstant } from "appConstant";

const EditProfile = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [stateNames, setStateNames] = useState([]);
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isIATARegister, setIsIATARegister] = useState(false);

    const [showFormFlag, setShowFormFlag] = useState(true);
    const [showFormSubmitMessageFlag, setShowFormSubmitMessageFlag] = useState(false);

    const [isPanCardImage, setIsPanCardImage] = useState(false);
    const [isAadhaarCardImage, setIsAadhaarCardImage] = useState(false);

    const [uploadedPanImageName, setUploadedPanImageName] = useState('')
    const [uploadedAddressImageName, setUploadedAddressImageName] = useState('')
    const [uploadedLogoImageName, setUploadedLogoImageName] = useState('')
    const [data, setData] = useState({});

    let tabs = [
      { name: 'agent-registration', label: 'Edit Profile', url: '/admin/user/edit-profile' },
    ];
    let currentTab = 'agent-registration';

    const [collapsed, setCollapsed] = useState(true);
    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    const stateOnChange=function(value){
      for (let i = 0; i < stateNames.length; i++) {
          if (stateNames[i].stateName == value) {
              setCities(stateNames[i].cities);
          }
      }
  }

  useEffect(() => {
    if(isLoading){
        apiCall(WebApi.getStateCity, {}, (response) => {
            if(response.success === true) {
                setStateNames(response.data);
            } else {
                setIsError(true);
            }
        });
    }
  }, [isLoading]);

  useEffect(()=>{
    if( stateNames) {
      localforageGetItem('user-id', function (err, user) {
        apiCall(WebApi.getAgentDetails, {'agentId': user}, (response) => {
          if(response.success === true) {
            stateOnChange(response.data.statename);
            setData(response.data);
            setIsIATARegister(response.data.isIATARegister);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setIsError(true);
          }
        });
      });
    }
      
  }, [stateNames]);

    return(
        <>
         {
           !isLoading && 
        <GridItem xs={12} sm={12} md={8}>
        <OaCard className={'oa-card-primary'}>
          <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>

          <Collapse in={showFormSubmitMessageFlag}>
            <OaCard>
              <h4>A BIG Thank You!!</h4>
              <div>
                We thank you for choosing to work with us. 
              </div>
              <div style={{marginTop: "10px"}}>
                We appreciate your business and promise you a complete secure honest and transparent business deal. We have received you request, our operations team will evaluate the contents of the form and respond back. You may receive a call from us to verify a few details, should the team feel the need for the same. A mail has also been shared on the email id provided by you during the registration process. We will keep you updated with the progress of your application. Should you have any queries meanwhile you can choose to reach out to us on the contact details provided on the main page.
              </div>
              <div style={{marginTop: "10px"}}>
                Thanks once again, and hope to be working soon
              </div>
              <div style={{fontWeight: "bold", marginTop: "10px"}}>Team {AppConstant.name}</div>
            </OaCard>
          </Collapse>
          <Collapse in={showFormFlag}>
          <OaCard>
            <OaFormAlerts 
                    isSaving={isSaving} 
                    isSuccess={isSuccess} 
                    isError={isError} 
                    infoText={infoText} 
                    setIsError={setIsError} 
                    setIsSuccess={setIsSuccess} 
                />
            <Formik 
                initialValues={{
                    agentId: data.agentId,
                    username: data.username,
                    pannumber: data.pannumber,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    gender: data.gender,
                    dob: dateFnsFormat(new Date(data.dob=="0000-00-00" ? "1991-01-01":data.dob), 'yyyy-MM-dd'),
                    dobDisplay: new Date(data.dob=="0000-00-00" ? "1991-01-01":data.dob),
                    corpname: data.corpname,
                    address: data.address,
                    statename: data.statename,
                    cityname: data.cityname,
                    pincode: data.pincode,
                    landlinenumber: data.landlinenumber,
                    mobilenumber: data.mobilenumber,
                    website: data.website,
                    email: data.email,
                    currentTurnover: '',
                    expectedVolume: '',
                    officeSpaceType: data.officeSpaceType
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string().required('User Name is required'),
                  pannumber: Yup.string()
                    .required('PAN is required')
                    .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
                  // firstname: Yup.string().required('First Name is required'),
                  // lastname: Yup.string().required('Last Name is required'),
                  // gender: Yup.string().required('Gender is required'),
                  // dob: Yup.string().required("Date of Birth is required"),
                  // corpname: Yup.string().required('Company name is required'),
                  // address: Yup.string().required('Office address is required'),
                  // statename: Yup.string().required('State is required'),
                  // cityname: Yup.string().required("City is required"),
                  // landlinenumber: Yup.string().matches(phoneRegex, 'Invalid phone number'),
                  // mobilenumber: Yup.string()
                  //     .required("Mobile Number is required")
                  //     .matches(phoneRegex, 'Invalid mobile number'),
                  // currentTurnover: Yup.number()
                  //     .typeError('Amount must be a number')
                  //     .positive('Turnover can\'t be negative'),
                  // pincode: Yup.string()
                  //   .required("PIN code is required")
                  //   .matches(/^[0-9]{6}$/, "Invalid PIN code"),
                  // website: Yup.string()
                  //     .url("Invalid website address"),
                  // email: Yup.string()    
                  //     .required("Email is required")
                  //     .email("Invalid Email"),
                  // expectedVolume: Yup.number()
                  //     .required('Expected business volume is required')
                  //     .typeError('Amount must be a number') 
                  //     .positive('Must not be a negative value'),
                  // officeSpaceType: Yup.string().required('Office space type is required'),
                        
                })}
                onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                  setIsPanCardImage(false);
                  setIsAadhaarCardImage(false);
                  // if(!uploadedPanImageName){
                  //   setIsPanCardImage(true);
                  //   return false;
                  // }

                  // if(!uploadedAddressImageName){
                  //   setIsAadhaarCardImage(true);
                  //   return false;
                  // }
                  setIsError(false);
                  setIsSuccess(false);
                  setSubmitting(true);
                  setIsSaving(true);
                  scrollToTop(0);
                  searchParams.dob = dateFnsFormat(searchParams.dobDisplay, 'yyyy-MM-dd');
                  searchParams.country = "India";
                  searchParams.logoImage = uploadedLogoImageName;
                  // searchParams.panImage =uploadedPanImageName;
                  // searchParams.addressImage =uploadedAddressImageName;
                  searchParams.isIATARegister =isIATARegister;
                  //console.log("The search params are ", searchParams);
                  apiCall(WebApi.updateAgentDetails, searchParams, (response) => {
                        setIsError(!response.success);
                        setIsSuccess(response.success);
                        setSubmitting(false);
                        setIsSaving(false);
                        setInfoText(response.message);
                        if(response.success === true) {
                          // setShowFormFlag(false);
                          // setShowFormSubmitMessageFlag(true);
                            setInfoText('Profile successfully updated');
                            // enableReinitialize=true;
                            // resetForm(true);
                        }
                    });
              }}
                
                >
                {({ values, isSubmitting, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>
                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Login Details</h4>

                        <GridContainer>
                            <GridItem  md={6}>
                              <OaFormTextField name="username" label="User Name*" disabled={true}/>
                              <ErrorMessage name="username" component="div" className="error" />
                            </GridItem>
                            <GridItem  md={6}>
                              <OaFormTextField name="pannumber" label="PAN*" disabled={true}  />  
                              <ErrorMessage name="pannumber" component="div" className="error"/>
                            </GridItem> 
                          </GridContainer>
                      </OaCard>

                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Ownership Details</h4>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormTextField label="First Name*" name="firstname" disabled={true}/>
                            <ErrorMessage name="firstname" component="div" className="error" />
                          </GridItem>  
                          <GridItem  md={6}>
                            <OaFormTextField label="Last Name*" name="lastname"  disabled={true}  />  
                            <ErrorMessage name="lastname" component="div" className="error" />
                          </GridItem>          
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormSelectField 
                                  label="Gender*" 
                                  name="gender" 
                                  value={values.gender} 
                                  options={[
                                      {value: 'M', label: 'Male'},
                                      {value: 'F', label: 'Female'},
                                      {value: 'T', label: 'Transgender'}
                                  ]}
                                  disabled={true}
                              />
                              <ErrorMessage name="gender" component="div" className="error" />
                          </GridItem>
                          
                          <GridItem  md={6}>
                            <OaFormDateField 
                                  value={values.dobDisplay}
                                  selected={values.dobDisplay}
                                  onChange={date => setFieldValue('dobDisplay', date)}
                                  label="Date of Birth*"
                                  name="dobDisplay"
                                  disabled={true}
                            />
                            <ErrorMessage name="dob" component="div" className="error" />
                          </GridItem>
                          
                        </GridContainer>
                      </OaCard>

                      <OaCard>
                        <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Communication Details</h4>
                        <GridContainer>
                          <OaFormTextField label="Company Name*" name="corpname" md={12} disabled={true}/>
                          <GridItem md={5}>
                            <ErrorMessage name="corpname" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <OaFormTextField label="Office Address*" name="address" md={12} disabled={true}/>
                          <GridItem md={5}>
                            <ErrorMessage name="address" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormSelectField 
                                label="State*"
                                name="statename"
                                value={values.statename}
                                options={stateNames}
                                optionValue={'stateName'}
                                optionLabel={'stateName'}
                                onChange={value => {
                                    setFieldValue('statename', value);
                                    stateOnChange(value)
                                }}
                                disabled={true}
                            />
                            <ErrorMessage name="statename" component="div" className="error" />
                          </GridItem>
                        
                          <GridItem  md={6}>
                            <OaFormSelectField 
                              label="City*"
                              name="cityname"
                              value={values.cityname}
                              options={cities}
                              optionValue={'cityName'}
                              optionLabel={'cityName'}
                              disabled={true}
                            />
                            <ErrorMessage name="cityname" component="div" className="error" />
                          </GridItem>
                              
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormTextField label="PIN Code*" name="pincode" disabled={true}/>
                            <ErrorMessage name="pincode" component="div" className="error" />
                          </GridItem>
                          <GridItem  md={6}>
                            <OaFormTextField label="Phone Number" name="landlinenumber" disabled={true}/>
                            <ErrorMessage name="landlinenumber" component="div" className="error" />
                          </GridItem>
                          
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                            <OaFormTextField label="Mobile Number*" name="mobilenumber" disabled={true}/>
                            <ErrorMessage name="mobilenumber" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField label="Website(E.g..https://www.google.com)" name="website" disabled={true}/>
                            <ErrorMessage name="website" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={6}>
                            <OaFormTextField label="Email*" name="email" disabled={true}  />
                            <ErrorMessage name="email" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={6}>
                            <OaFormTextField name="currentTurnover" label="Current Turnover" disabled={true}/>
                            <ErrorMessage name="currentTurnover" component="div" className="error" />
                          </GridItem>
                          <GridItem md={6}>
                            <OaFormTextField label="Expected Business Volume(Rs.)*" name="expectedVolume" disabled={true}/>
                            <ErrorMessage name="expectedVolume" component="div" className="error" />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem  md={6}>
                              <OaFormSelectField 
                                    label="Office Space*" 
                                    name="officeSpaceType" 
                                    value={values.officeSpaceType} 
                                    options={[
                                        {value: 'rented', label: 'Rented'},
                                        {value: 'owned', label: 'Owned'}
                                    ]}
                                    disabled={true}
                                />
                                <ErrorMessage name="officeSpaceType" component="div" className="error" />
                            </GridItem>
                            <GridItem md={6}>
                              <OaFormRadio label="IATA Registration" name="isIATARegister" value="isIATARegister" checked={isIATARegister} onChange={()=>{setIsIATARegister(!isIATARegister);}} disabled={true}/> 
                              <ErrorMessage name="isIATARegister" component="div" className="error" disabled={true}/>
                            </GridItem>
                          </GridContainer>
                      </OaCard>
                      <OaCard>
                        {/* <OaCard>
                          <OaFormFileUpload 
                            label={"PAN CARD*"}
                            type="PAN_CARD" 
                            setUploadedImageName={setUploadedPanImageName}
                            disabled={true}
                          />
                        </OaCard>
                        <Collapse in={isPanCardImage}>
                          <div name="panImage" className="error">PAN Card is required for KYC</div>
                        </Collapse>
                        <OaCard>
                          <OaFormFileUpload 
                            label={"ADDRESS PROOF*"}
                            type="AADHAR_CARD" 
                            setUploadedImageName={setUploadedAddressImageName}
                            disabled={true}
                          />
                        </OaCard>
                        <Collapse in={isAadhaarCardImage}>
                          <div name="aadhaarCardImage" className="error">Address Proof is required for KYC</div>
                        </Collapse> */}
                        <OaCard>
                          <OaFormFileUpload 
                            label={"LOGO IMAGE"}
                            type="AGENT_LOGO" 
                            setUploadedImageName={setUploadedLogoImageName}
                          />
                        </OaCard>
                      </OaCard>
                      <OaFormSaveButton isSaving={isSaving} exReset={enableReinitialize} label={'UPDATE'} inReset={resetForm}/>
                    </Form>
                )}
            </Formik>
           
        </OaCard>
        </Collapse>
        </OaCard>
        </GridItem> 
         }
        </>
    )
}

export default EditProfile;
