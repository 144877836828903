import React, { useState, useEffect } from "react";

import OaCard from "oahoc/OaCard";
import { Formik, Form, ErrorMessage,Field } from "formik";

import OaFormSelectField from "oahoc/OaFormSelectField";

import { scrollToTop } from "oautils/oaCommonUtils";
import { makeStyles } from "@material-ui/core/styles";

import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import _ from "lodash";
import * as Yup from "yup";
import { Grid, Typography,TextField ,Button, FormControl,NativeSelect} from "@material-ui/core";

import ListSubheader from '@material-ui/core/ListSubheader';

import MenuItem from '@material-ui/core/MenuItem';


// background:"#d52731"

const useStyles = makeStyles((theme) => ({
  
  Button: {
    background: theme.palette.background.red,
    color: theme.palette.primary.lightText,
    "&:hover": {
      background: theme.palette.background.red,
    },
    "&:disabled": {
      background: theme.palette.secondary.gray,
      cursor: "no-drop",
      color: theme.palette.primary.lightText,
      "&:hover": {
        background: theme.palette.secondary.gray,
        cursor: "no-drop",
      },
    },
  },
}));

const occupations=[{
  
}]


const EnquiryForm = () => {
  const classes=useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  

  const [isLoading, setIsLoading] = useState(true);

  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  
  useEffect(() => {
    if (isLoading) {
      apiCall(WebApi.getStateCity, {}, (response) => {
        if (response.success) {
          setStates(response.data);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      });
    }
  }, [isLoading]);

  const stateOnChange = function (value) {
    for (let i = 0; i < states.length; i++) {
      if (states[i].stateName == value) {
        setCities(states[i].cities);
      }
    }
  }

 
  return (
    <div>
     

      <Grid container direction="row"
  justifyContent="center"
  alignItems="center" >
          
        <Grid item style={{marginLeft:"30%",marginRight:"30%",marginBottom:"5%"}}>
        <OaFormAlerts
        alertTitle={"Sent successfully"}
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
        <OaCard >


  

 

  
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              state:"",
              city_of_residence:"",
              email:"",
              contact_number:"",
              current_occupation:"",
              experiance_years:"",
              partner_type:"",
             
            }}
            validationSchema={Yup.object().shape({
              first_name:Yup.string().required("firstname is required"),
              last_name: Yup.string().required("lastname is required"),
              state: Yup.string().required('State is required'),
              city_of_residence: Yup.string().required('City is required'),
              email:Yup.string()
              .required("Email is required")
              .email("Invalid Email"),
              contact_number:Yup.string()
              .required("Mobile Number is required")
              .matches(phoneRegex, 'Invalid mobile number'),
              current_occupation:Yup.string().required('occupation is required'),
              experiance_years:Yup.number().typeError("enter a number").required('experience is required'),
              partner_type:Yup.string().required('please select your plan'),
            })}

            onSubmit= {(values, {setSubmitting, resetForm, enableReinitialize}) => {
              values.partner_id= 1002;
              setIsSaving(true);

              apiCall(WebApi.registerEnquiry, values, (response) => {
                  setIsError(!response.success);
                  setIsSuccess(response.success);
                  setSubmitting(false);
                  setIsSaving(false);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setInfoText(response.message);
                  if(response.success === true) {
                      enableReinitialize=true;
                      resetForm(true);
                    
                  }
              });
              }
          }
          >
            {({
              values,
              isSubmitting,
              errors,
              touched,
              setFieldValue,
              enableReinitialize,
              isValid,
              resetForm,
            }) => <Form>
                
                  <Grid container>
                      <Grid item md={12}>
                          <Typography>First Name</Typography>
                          <Field as={TextField} name="first_name" fullWidth variant="outlined"/>
                          <ErrorMessage name="first_name" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12}>
                          <Typography>Last Name</Typography>
                          <Field as={TextField} name="last_name" fullWidth variant="outlined"/>
                          <ErrorMessage name="last_name" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12}>
                          <Typography>State</Typography>
                          <OaFormSelectField
                            
                            name="state"
                            value={values.state}
                            options={states}
                            optionValue={'stateName'}
                            optionLabel={'stateName'}
                            onChange={value => {
                              setFieldValue('state', value);
                              stateOnChange(value)
                            }}
                          />
                          <ErrorMessage name="state" component="div" className="error" />
                      </Grid>
                      <Grid item md={12}>
                          <Typography>City</Typography>
                          <OaFormSelectField
                          
                            name="city_of_residence"
                            value={values.city_of_residence}
                            options={cities}
                            optionValue={'cityName'}
                            optionLabel={'cityName'}
                          />
                          <ErrorMessage name="city_of_residence" component="div" className="error" />
                      </Grid>
                      <Grid item md={12}>
                          <Typography>Email</Typography>
                          <Field as={TextField} name="email" type="email" fullWidth variant="outlined"/>
                          <ErrorMessage name="email" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12}>
                          <Typography>Contact Number</Typography>
                          <Field as={TextField} name="contact_number" fullWidth variant="outlined"/>
                          <ErrorMessage name="contact_number" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12}>
                          <Typography>Current Occupation</Typography>
                          {/* <OaFormSelectField
                           
                            name="current_occupation"
                            value={values.current_occupation}
                            options={[
                              { value: 'option1', label: 'option1' },
                              { value: 'option2', label: 'option2' },
                              { value: 'option3', label: 'option3' }
                            ]}
                          /> */}
                          <FormControl fullWidth={true}>
       
          <Field
          
            name="current_occupation"
            value={values.current_occupation}
            variant='outlined'
            className={'oa-input-white'}
            type='input'
            as={TextField}
            select={true}
            // onChange={(event) => {
            //   const { value } = event.target;
            //   // props.onChange(value);
            // }}
          >
           

           <ListSubheader disableSticky={true}>Healthcare Practitioners and Technical Occupations</ListSubheader>
<MenuItem value="Chiropractor">Chiropractor</MenuItem>
<MenuItem value="Dentist">-Dentist</MenuItem>
<MenuItem value="Dietitian or Nutritionist">Dietitian or Nutritionist</MenuItem>
<MenuItem value="Optometrist">Optometrist</MenuItem>
<MenuItem value="Pharmacist">Pharmacist</MenuItem>
<MenuItem value="Physician">Physician</MenuItem>
<MenuItem value="Physician Assistant">Physician Assistant</MenuItem>
<MenuItem value="Podiatrist">Podiatrist</MenuItem>
<MenuItem value="Registered Nurse">Registered Nurse</MenuItem>
<MenuItem value="Veterinarian">Veterinarian</MenuItem>
<MenuItem value="Health Technologist or Technician">Health Technologist or Technician</MenuItem>
<MenuItem value="Other Healthcare Practitioners and Technical Occupation">Other Healthcare Practitioners and Technical Occupation</MenuItem>
<ListSubheader disableSticky={true}>Healthcare Support Occupations:</ListSubheader>
<MenuItem value="Nursing, Psychiatric, or Home Health Aide">Nursing, Psychiatric, or Home Health Aide</MenuItem>
<MenuItem value="Occupational and Physical Therapist Assistant or Aide">Occupational and Physical Therapist Assistant or Aide</MenuItem>
<MenuItem value="Other Healthcare Support Occupation">Other Healthcare Support Occupation</MenuItem>
<ListSubheader disableSticky={true}>Business, Executive, Management, and Financial Occupations:</ListSubheader>
<MenuItem value="Chief Executive">Chief Executive</MenuItem>
<MenuItem value="General and Operations Manager">General and Operations Manager</MenuItem>
<MenuItem value="Advertising, Marketing, Promotions, Public Relations, and Sales Manager">Advertising, Marketing, Promotions, Public Relations, and Sales Manager</MenuItem>
<MenuItem value="Operations Specialties Manager (e.g., IT or HR Manager)">Operations Specialties Manager (e.g., IT or HR Manager)</MenuItem>
<MenuItem value="Construction Manager">Construction Manager</MenuItem>
<MenuItem value="Engineering Manager">Engineering Manager</MenuItem>
<MenuItem value="Accountant, Auditor">Accountant, Auditor</MenuItem>
<MenuItem value="Business Operations or Financial Specialist">Business Operations or Financial Specialist</MenuItem>
<MenuItem value="Business Owner">Business Owner</MenuItem>
<MenuItem value="Other Business, Executive, Management, Financial Occupation">Other Business, Executive, Management, Financial Occupation</MenuItem>
<ListSubheader disableSticky={true}>Architecture and Engineering Occupations:</ListSubheader>
<MenuItem value="Architect, Surveyor, or Cartographer">Architect, Surveyor, or Cartographer</MenuItem>
<MenuItem value="Engineer">Engineer</MenuItem>
<MenuItem value="Other Architecture and Engineering Occupation">Other Architecture and Engineering Occupation</MenuItem>
<ListSubheader disableSticky={true}>Education, Training, and Library Occupations:</ListSubheader>
<MenuItem value="Postsecondary Teacher (e.g., College Professor)">Postsecondary Teacher (e.g., College Professor)</MenuItem>
<MenuItem value="Primary, Secondary, or Special Education School Teacher">Primary, Secondary, or Special Education School Teacher</MenuItem>
<MenuItem value="Other Teacher or Instructor">Other Teacher or Instructor</MenuItem>
<MenuItem value="Other Education, Training, and Library Occupation">Other Education, Training, and Library Occupation</MenuItem>
<ListSubheader disableSticky={true}>Other Professional Occupations:</ListSubheader>
<MenuItem value="Arts, Design, Entertainment, Sports, and Media Occupations">Arts, Design, Entertainment, Sports, and Media Occupationsr</MenuItem>
<MenuItem value="Computer Specialist, Mathematical Science">Computer Specialist, Mathematical Science</MenuItem>
<MenuItem value="Counselor, Social Worker, or Other Community and Social Service Specialist">Counselor, Social Worker, or Other Community and Social Service Specialist</MenuItem>
<MenuItem value="Lawyer, Judge">Lawyer, Judge</MenuItem>
<MenuItem value="Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)">Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)</MenuItem>
<MenuItem value="Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)">Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)</MenuItem>
<MenuItem value="Religious Worker (e.g., Clergy, Director of Religious Activities or Education)">Religious Worker (e.g., Clergy, Director of Religious Activities or Education)</MenuItem>
<MenuItem value="Social Scientist and Related Worker">Social Scientist and Related Worker</MenuItem>
<MenuItem value="Other Professional Occupation">Other Professional Occupation</MenuItem>
<ListSubheader disableSticky={true}>Office and Administrative Support Occupations:</ListSubheader>
<MenuItem value="Supervisor of Administrative Support Workers">Supervisor of Administrative Support Workers</MenuItem>
<MenuItem value="Financial Clerk">Financial Clerkr</MenuItem>
<MenuItem value="Secretary or Administrative Assistant">Secretary or Administrative Assistant</MenuItem>
<MenuItem value="Material Recording, Scheduling, and Dispatching Worker">Material Recording, Scheduling, and Dispatching Worker</MenuItem>
<MenuItem value="Other Office and Administrative Support Occupation">Other Office and Administrative Support Occupation</MenuItem>
<ListSubheader disableSticky={true}>Services Occupations:</ListSubheader>
<MenuItem value="Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)">Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)</MenuItem>
<MenuItem value="Chef or Head Cook">Chef or Head Cook</MenuItem>
<MenuItem value="Cook or Food Preparation Worker">Cook or Food Preparation Worker</MenuItem>
<MenuItem value="Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)">Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)r</MenuItem>
<MenuItem value="Building and Grounds Cleaning and Maintenance">Building and Grounds Cleaning and Maintenance</MenuItem>
<MenuItem value="Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)">Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)</MenuItem>
<MenuItem value="Sales Supervisor, Retail Sales">Sales Supervisor, Retail Sales</MenuItem>
<MenuItem value="Retail Sales Worker">Retail Sales Worker</MenuItem>
<MenuItem value="Insurance Sales Agent">Insurance Sales Agent</MenuItem>
<MenuItem value="Sales Representative">Sales Representative</MenuItem>
<MenuItem value="Real Estate Sales Agent">Real Estate Sales Agent</MenuItem>
<MenuItem value="Other Services Occupation">Other Services Occupation</MenuItem>
<ListSubheader disableSticky={true}>Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:</ListSubheader>
<MenuItem value="Construction and Extraction (e.g., Construction Laborer, Electrician)">Construction and Extraction (e.g., Construction Laborer, Electrician)</MenuItem>
<MenuItem value="Farming, Fishing, and Forestry">Farming, Fishing, and Forestry</MenuItem>
<MenuItem value="Installation, Maintenance, and Repair">Installation, Maintenance, and Repair</MenuItem>
<MenuItem value="Production Occupations">Production Occupations</MenuItem>
<MenuItem value="Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation">Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</MenuItem>
<ListSubheader disableSticky={true}>Transportation Occupations:</ListSubheader>
<MenuItem value="Aircraft Pilot or Flight Engineer">Aircraft Pilot or Flight Engineer</MenuItem>
<MenuItem value="Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)">Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)</MenuItem>
<MenuItem value="Other Transportation Occupation">Other Transportation Occupation</MenuItem>
<ListSubheader disableSticky={true}>Other Occupations:</ListSubheader>
<MenuItem value="Military">Military</MenuItem>
<MenuItem value="Homemaker">Homemaker</MenuItem>
<MenuItem value="Other Occupation">Other Occupation</MenuItem>
<MenuItem value="Don't Know">Don't Know</MenuItem>
<MenuItem value="Not Applicable">Not Applicable</MenuItem>
      
          </Field>
          </FormControl>

          

     

                          <ErrorMessage name="current_occupation" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12}>
                          <Typography>Any work experience in travel industry</Typography>
                          <Field type="number" name="experiance_years" as={TextField} fullWidth variant="outlined"/>
                          <ErrorMessage name="experiance_years" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12}>
                          <Typography>Preferd Plan</Typography>
                          <OaFormSelectField
                           
                           name="partner_type"
                           value={values.preferdPlan}
                           options={[
                             { value: 'ChannelPartner', label: 'Channel Partner' },
                             { value: 'SuperPartner', label: 'Super Partner' },
                             { value: 'B-Storepartner', label: 'B-Store partner' }
                           ]}
                         />
                          <ErrorMessage name="partner_type" component="div" className="error"/>
                      </Grid>
                      <Grid item md={12} container justifyContent="center"  style={{marginTop:15}}>
                          <Button className={classes.Button} type="submit" disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)} isSaving={isSaving} exReset={enableReinitialize} inReset={resetForm}>SUBMIT</Button>
                      </Grid>
                  </Grid>
                
                {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div className={"row"}>
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
                </Form>}

          </Formik>
        </OaCard>
        </Grid>
      
      </Grid>
    </div>
  );
};


export default EnquiryForm