import React, {useState} from 'react'
import OaCard from 'oahoc/OaCard';
import dateFnsFormat from 'date-fns/format';
import MasterBalanceManagerSentSearchForm from './MasterBalanceManagerSentSearchForm';
import MasterBalanceManagerSentSearchResult from './MasterBalanceManagerSentSearchResult';


export const MasterBalanceManagerSent = (props) => {
    let today = new Date;
    
    const [tableKey, setTableKey] = useState(0);
    const [searchParams, setSearchParams] = useState({
        dateFromDisplay: today,
        dateToDisplay: today,
        dateFrom: dateFnsFormat(today, 'dd-MM-yyyy'),
        dateTo: dateFnsFormat(today, 'dd-MM-yyyy'),
        status: 'P'
    });

    function MasterBalanceManagerSentSearchResultLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
        //console.log(searchParams);
    }

    return (
        <OaCard className={'oa-card-primary'}>
             <OaCard> 
                <MasterBalanceManagerSentSearchForm target={MasterBalanceManagerSentSearchResultLoader} searchParams={searchParams}/>
            </OaCard>
            <OaCard>
            <MasterBalanceManagerSentSearchResult  key={tableKey} searchParams={searchParams}/>
            </OaCard>
        </OaCard> 
    )
}

export default MasterBalanceManagerSent;
{/**
            <OaCard> 
                <MasterBalanceManagerSentSearchForm target={MasterBalanceManagerSentSearchResultLoader} searchParams={searchParams}/>
            </OaCard>
*/}