import WebApi from "api/ApiConstants";
//mport { PROCESS_PAYMENT_PAYGOAL } from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

export async function submitDataToPaygoal(request) {
  return new Promise(resolve => {
    WebApi.postPayGoalOrderTransact(request, (response) => {
        if(response.success){
           submitData(response.data.payGoal, request.checkoutUrl);//
        }else{
            resolve(response)
        }
    }, error => console.log("error paygoal order", error));
  });
}

function submitData(data, checkoutUrl){
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action",checkoutUrl);
    formElement.setAttribute("target", "_parent");

    for (var key1 in data) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key1);
      hiddenField.setAttribute("value", data[key1]);
      formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
}
