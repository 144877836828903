import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      "&:last-of-type": {
        marginBottom: theme.typography.pxToRem(0),
      },
    },
    hr: {
      margin: theme.spacing(2, 0, 1.3),
      color:theme.palette.secondary.darkBlack,
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0),
      },
    },
    img: {
      borderRadius: theme.typography.pxToRem(5),
      width: "95%",
      height: "80%",
      textAlign: "right",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(15),
      fontWeight: 500,
      letterSpacing: theme.typography.pxToRem(1.3),
      textTransform: "uppercase",
      color: theme.palette.background.carulean,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(21),
        lineHeight: theme.typography.pxToRem(26),
        letterSpacing: theme.typography.pxToRem(1.8256),
      },
    },
    subHeading: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(12),
      letterSpacing: theme.typography.pxToRem(0.297),
      color: theme.palette.secondary.darkGray,
      margin: theme.spacing(0.5, 0, 1, 0),

      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(15),
        letterSpacing: theme.typography.pxToRem(0.3575),
      },
    },

    guestRating: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      letterSpacing: theme.typography.pxToRem(2),
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: "500",
    },
    options: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 700,
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: theme.typography.pxToRem(0.20267),
      color: theme.palette.secondary.darkShadedGray,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(15),
        letterSpacing: theme.typography.pxToRem(0.24321),
      },
    },
    originalCost: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: "normal",
      lineHeight: theme.typography.pxToRem(18),
      letterSpacing: theme.typography.pxToRem(0.297),
      textDecoration: "line-through",
      color: theme.palette.secondary.grandmaGray
,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(12),
        letterSpacing: theme.typography.pxToRem(0.3575),
      },
    },

    tagline: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(18),
      letterSpacing: theme.typography.pxToRem(0.25),
      textTransform: "capitalize",
      color: theme.palette.secondary.darkBlack,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(12),
        letterSpacing: theme.typography.pxToRem(0.357),
      },
    },
    inr: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(21),
      lineHeight: theme.typography.pxToRem(26),
      letterSpacing: theme.typography.pxToRem(1.33),
      color: theme.palette.primary.peach,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(25),
        lineHeight: theme.typography.pxToRem(37),
        letterSpacing: theme.typography.pxToRem(1.9),
      },
    },
    btn: {
      borderRadius: theme.typography.pxToRem(8),
      backgroundColor:theme.palette.background.peach,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(15),
      letterSpacing: theme.typography.pxToRem(0.76),
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: theme.typography.pxToRem(1.0133),
        padding: theme.spacing(1, 3),
      },
    },
    starIcon: {
      color: theme.palette.secondary.medallion,
      fontSize: theme.typography.pxToRem(16),
      margin: theme.spacing(0),
      marginBottom: 5,
    },
    carouselGrid: {
      "& .carousel-root .carousel": {
        "& .slide img": {
          width: "auto !important",
        },
        "& button": {
          "&.control-prev.control-arrow:before": {
            borderRight: `8px solid  ${theme.palette.secondary.turquoise}`,
          },
          "&.control-next.control-arrow:before": {
            borderLeft: `8px solid  ${theme.palette.secondary.turquoise}`,
          },
        },
        "& .slider-wrapper.axis-horizontal .slider .slide": {
          background: theme.palette.primary.contrastText,
        },
      },
    },
    moreButton: {
      color: theme.palette.secondary.indigo
,
    },
    lowestRate: {
      backgroundColor: theme.palette.background.lightFadedGray,
      border: `1px solid ${theme.palette.background.lightFadedGray}`,
      padding: "5px 10px",
      opacity: 0.8,
      fontSize: 12,
      textAlign: "center",
    },
    checkboxLabel: {
      alignSelf: "center",
      padding: 5,
    },
    bookButton: {
      color: theme.palette.primary.lightText,
      fontSize: 12,
      borderRadius: 10,
      padding: "6px 8px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      // "&:hover": {
      //   backgroundColor: theme.palette.secondary.medallion,
      // },
    },
    viewMapButton: {
      color: theme.palette.secondary.indigo
,
      margin: "5px 3px",
    },
    modalHeading: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 500,
      letterSpacing: theme.typography.pxToRem(1.3),
      textTransform: "uppercase",
      color: theme.palette.background.carulean,
      marginBottom: 5,
    },
    hotelSubHeading: {
      fontWeight: 500,
    },
    modalTabs: {
      width: "100%",
      marginTop: 10,
      boxShadow: `1px 1px 3px 0px ${theme.palette.secondary. taupeGray}`,
    },
    modalCarousel: {
      margin: "3% 22%",
    },
  };
});

export default useStyles;
