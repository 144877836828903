import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

import { Formik, Form, ErrorMessage } from "formik";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";
import OaFormDateField from "oahoc/OaFormDateField";
import dateFnsFormat from "date-fns/format";
import sub from "date-fns/sub";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaAlert from "oahoc/OaAlert";
import FormControl from "@material-ui/core/FormControl";
import OaFormSelectComboField from "oahoc/OaFormSelectComboField";
import { makeStyles,useTheme } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { COLORS } from "assets/css/CssConstants";
import { AppConstant } from "appConstant";

const useStyles = makeStyles(theme => ({
  addSenderRoot: {
    padding: 15,
    "& .MuiOutlinedInput-adornedStart": {
      paddingTop: 11,
    },
    "& .form-item": {
      padding: "10px 15px",
    },
    "& .button-landing-page": {
      width: 100,
      height: 30,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const AddSenderForm = (props) => {
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const theme=useTheme()
  const [collapsed, setCollapsed] = useState(true);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [mobile, setMobile] = useState(
    props.location.state ? props.location.state.mobile : ""
  );
  const [isAlertPrompt, setIsAlertPrompt] = useState(false);
  const [alertCallbackParam, setAlertCallbackParam] = useState({});
  const [alertAdditionalData, setAlertAdditionalData] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [primaryButtonText, setPrimaryButtonText] = useState("");
  const [secondaryButtonText, setSecondaryButtonText] = useState("");
  const [dialogRef, setDialogRef] = useState("");
  const [hideSecondaryButton, setHideSecondaryButton] = useState(false);
  const classes = useStyles();

  function transactionHelper(
    buttonText,
    alertCallbackParam,
    additionalData,
    dialogRef
  ) {
    if (dialogRef == "enterMobile" && buttonText == "YES") {
    }

    if (dialogRef == "senderadded" && buttonText == "YES") {
      props.history.push("/moneytransfer/beneficiary-list", additionalData);
    }

    if (buttonText == "NO") {
      setIsSaving(false);
      setIsError(false);
      setIsSuccess(false);
      setMobile("");
    }
  }

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }

  function sendOTP(mobile) {
    if (!mobile) {
      setAlertTitle(AppConstant.name);
      setHideSecondaryButton(true);
      setAlertCallbackParam(alertCallbackParam);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
      setDialogRef("enterMobile");
      setAlertMsg("Please enter mobile number");
    } else {
      apiCall(WebApi.sendDMTOtp, { mobile: mobile }, (response) => {
        setIsError(!response.success);
        setIsSuccess(response.success);

        setIsSaving(false);
        setInfoText(response.message);
        setAlertTitle("Error sending");

        if (response.success && response.data) {
          setAlertTitle("Sent successfully");
          setIsError(!response.success);
          setIsSuccess(response.success);
          setInfoText(response.data.message);
        }

        setTimeout(() => {
          setIsSaving(false);
          setIsError(false);
          setIsSuccess(false);
        }, 5000);
      });
    }
  }

  return (
    <div className={classes.addSenderRoot}>
      <h4
        className={"oa-form-header"}
        onClick={collapse}
        style={{ transform: " scale(0.85)", transformOrigin: "left" }}
      >
        Enter Sender Details
      </h4>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        alertTitle={alertTitle}
      />
      <OaAlert
        isPrompt={isAlertPrompt}
        callback={transactionHelper}
        callbackParam={alertCallbackParam}
        msg={alertMsg}
        title={alertTitle}
        additionalData={alertAdditionalData}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        hideSecondaryButton={hideSecondaryButton}
        dialogRef={dialogRef}
        togglePrompt={() => {
          setIsAlertPrompt(!isAlertPrompt);
        }}
      ></OaAlert>{console.log("add sender props",props)}
      <Formik
        initialValues={{
          mobile: mobile,
          firstname: props.location.state.data
            ? props.location.state.data.fname
            : "",
          lastname: props.location.state.data
            ? props.location.state.data.lname
            : "",
          address: "",
          pincode: "",
          stateresp: props.location.state.stateresp,
          otp:
            props.location.state.stateresp === "FOR_LOCAL_SAVE" ? "123456" : "",
          dob: dateFnsFormat(sub(new Date(), { years: 18 }), "dd-MM-yyyy"),
          dobDisplay: sub(new Date(), { years: 18 }),
          gstState: "23",
        }}
        validationSchema={Yup.object().shape({
          mobile: Yup.string()
            .required("Sender's Mobile Number is required")
            .matches(phoneRegex, "Invalid mobile number")
            .test(
              "len",
              "Must be exactly 10 digits",
              (val) => val.length === 10
            ),
          firstname: Yup.string()
            .required("First Name is required")
            .matches(
              /^.[a-zA-Z]+$/,
              "First Name should not contain space or special character"
            ),
          lastname: Yup.string()
            .required("Last Name is required")
            .matches(
              /^.[a-zA-Z]+$/,
              "Last Name should not contain space or special character"
            ),
          address: Yup.string().required("Address is required"),
          dob: Yup.string().required("Date of Birth is required"),
          pincode: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Pincode must be exactly 6 digits")
            .max(6, "Pincode must be exactly 6 digits")
            .typeError("Please enter valid pincode")
            .required("Pincode is required"),
          otp: Yup.string().required("Sender's Mobile Number OTP is required"),
        })}
        onSubmit={(
          searchParams,
          { setSubmitting, resetForm, enableReinitialize }
        ) => {
          setSubmitting(true);
          setIsSaving(true);

          searchParams.dob = dateFnsFormat(
            searchParams.dobDisplay,
            "yyyy-M-dd"
          );

          apiCall(WebApi.addDMTSender, searchParams, (response) => {
            setIsError(!response.success);
            setSubmitting(false);
            setIsSaving(false);
            setInfoText(response.message);
            setAlertTitle("Error registration");

            if (props.location.state.data) {
              response.data = props.location.state.data;
            }
            if (response.data && response.data.status) {
              setAlertTitle(response.data.message);
              setAlertCallbackParam(searchParams);
              setAlertAdditionalData({
                data: response.data.data,
                mobile: searchParams.mobile,
              });
              setPrimaryButtonText("Beneficiaries");
              setSecondaryButtonText("Add Sender");
              setDialogRef("senderadded");
              setIsAlertPrompt(true);
              setHideSecondaryButton(false);
              setAlertMsg(
                "Sender has been successfully added to the system. For one time transaction upto INR 5000 no kyc is needed. Sender can send INR 25000 per month. Add beneficiary for DMT transaction or add another sender. "
              );
              enableReinitialize = true;
              resetForm(true);
            }
          });
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="firstname"
                  label="First Name *"
                />
                <ErrorMessage
                  name="firstname"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="lastname"
                  label="Last Name *"
                />
                <ErrorMessage
                  name="lastname"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormDateField
                  className="input-landing-page"
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  value={values.dobDisplay}
                  selected={values.dobDisplay}
                  onChange={(date) => setFieldValue("dobDisplay", date)}
                  label="Date of Birth*"
                  name="dobDisplay"
                  maxDate={sub(new Date(), { years: 18 })}
                />
                <ErrorMessage name="dob" component="div" className="error" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormSelectComboField
                  label="GST State *"
                  name="gstState"
                  value={values.gstState}
                  options={[
                    { name: "JAMMU AND KASHMIR", gstCode: "01" },
                    { name: "HIMACHAL PRADESH", gstCode: "02" },
                    { name: "PUNJAB", gstCode: "03" },
                    { name: "CHANDIGARH", gstCode: "04" },
                    { name: "UTTARAKHAND", gstCode: "05" },
                    { name: "HARYANA", gstCode: "06" },
                    { name: "DELHI", gstCode: "07" },
                    { name: "RAJASTHAN", gstCode: "08" },
                    { name: "UTTAR PRADESH", gstCode: "09" },
                    { name: "BIHAR", gstCode: "10" },
                    { name: "SIKKIM", gstCode: "11" },
                    { name: "ARUNACHAL PRADESH", gstCode: "12" },
                    { name: "NAGALAND", gstCode: "13" },
                    { name: "MANIPUR", gstCode: "14" },
                    { name: "MIZORAM", gstCode: "15" },
                    { name: "TRIPURA", gstCode: "16" },
                    { name: "MEGHLAYA", gstCode: "17" },
                    { name: "ASSAM", gstCode: "18" },
                    { name: "WEST BENGAL", gstCode: "19" },
                    { name: "JHARKHAND", gstCode: "20" },
                    { name: "ODISHA", gstCode: "21" },
                    { name: "CHATTISGARH", gstCode: "22" },
                    { name: "MADHYA PRADESH", gstCode: "23" },
                    { name: "GUJARAT", gstCode: "24" },
                    {
                      name:
                        "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)",
                      gstCode: "26",
                    },
                    { name: "MAHARASHTRA", gstCode: "27" },
                    { name: "ANDHRA PRADESH(BEFORE DIVISION)", gstCode: "28" },
                    { name: "KARNATAKA", gstCode: "29" },
                    { name: "GOA", gstCode: "30" },
                    { name: "LAKSHWADEEP", gstCode: "31" },
                    { name: "KERALA", gstCode: "32" },
                    { name: "TAMIL NADU", gstCode: "33" },
                    { name: "PUDUCHERRY", gstCode: "34" },
                    { name: "ANDAMAN AND NICOBAR ISLANDS", gstCode: "35" },
                    { name: "TELANGANA", gstCode: "36" },
                    { name: "ANDHRA PRADESH (NEWLY ADDED)", gstCode: "37" },
                    { name: "LADAKH (NEWLY ADDED)", gstCode: "38" },
                  ]}
                  optionValue={"gstCode"}
                  optionLabel={["name", "gstCode"]}
                  required={true}
                  setFieldValue={setFieldValue}
                  // changeHandler={changeBank}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  label="Address*"
                  name="address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor:theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  label="Pincode*"
                  name="pincode"
                />
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="mobile"
                  label="Sender's Mobile Number *"
                />
                <ErrorMessage name="mobile" component="div" className="error" />
              </Grid>

              {props.location.state.stateresp === "FOR_LOCAL_SAVE" ? (
                <Grid item xs={12} sm={12} md={6} className="form-item"></Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} className="form-item">
                  <OaFormTextField
                    style={{ backgroundColor: theme.palette.background.brightWhisper }}
                    className="input-landing-page"
                    name="otp"
                    label="Sender's Mobile Number OTP *"
                  />
                  <ErrorMessage name="otp" component="div" className="error" />
                </Grid>
              )}
            </Grid>
            <Grid container style={{ marginTop: 20 }}>
              {!props.location.state.stateresp && (
                <Grid item className="form-item">
                  <Button
                    className="button-landing-page"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => sendOTP(values.mobile)}
                  >
                    {values.mobile ? "Re-Send OTP" : "Send OTP"}
                  </Button>
                </Grid>
              )}

              <Grid item className="form-item">
                <Button
                  className="button-landing-page"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Add Sender
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSenderForm;
