import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import waitgif from "assets/gif/XVo6.gif";

const OaButtonDownloadPDF = (props) => {
    const classes = useStyles();

    const [isDownloading, setIsDownloading] = useState(false);

    function downloadPDF(){
        
        setIsDownloading(true);
        axios({
            method: 'POST',
            url: props.api,
            data: props.searchParams,
            responseType: 'blob',
        }).then(response => {
            let fileName = response.headers["content-disposition"] ? 
            response.headers["content-disposition"].split("filename=")[1]: props.defaultFileName;
            const tempurl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = tempurl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
            setTimeout(() => {
                document.body.removeChild(link);
            }, 2000);
        }).catch(error => {
            console.error("error catch downloading pdf", error);
            setIsDownloading(false);
        });

    }

    return (
        <>
            <Button 
                className={classes.btnRoot}  
                onClick={()=>{downloadPDF()}}
            >
                {isDownloading ? <span><img src={waitgif} /> please wait ..</span> : props.btnLabel}
            </Button>
        </>
    )
}

export default OaButtonDownloadPDF;

const useStyles = makeStyles(theme => ({
    btnRoot: {
        float: "right",
        boxShadow: `0 2px 2px 0 rgba(248, 150, 5, 0.14), 0 3px 1px -2px rgba(248, 150, 5, 0.2), 0 1px 5px 0 rgba(248, 150, 5, 0.12)`,
        // backgroundColor: theme.palette.buttons.primary,
        // color: theme.palette.buttons.primaryContrastText,
        backgroundColor: "#F89605",
        color: "#000",
        margin: "0.3125rem 1px 0.3125rem 2px",
        padding: "10px 30px",
        fontSize: 12,
        textAlign: "center",
        transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        fontWeight: 400,
        lineHeight: 1.42857143,
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        touchAction: "manipulation",
        borderRadius: 3,
        verticalAlign: "middle",
        width: 177,
        height: 41,
        "&:hover": {
            // backgroundColor: theme.palette.buttons.primary,
            backgroundColor: "#F89605",
            boxShadow: `0 14px 26px -12px rgba(248, 150, 5, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 150, 5, 0.2)`,
        },
        "& img": {
            height: 12,
            marginBottom: 1
        },
    }
}));
