import React, { useState, useEffect } from "react";

import OaCard from "oahoc/OaCard";
import OaPriTabs from "oahoc/OaPriTabs";
import { getQueryVar, getCurrentTab } from "oautils/oaCommonUtils";
import AddSenderForm from "./AddSenderForm";

const AddSender = (props) => {
  const tabs = [
    { name: "addSender", label: "Add Sender", url: "/moneytransfer/add-sender" },
  ];
  let currentTab = getCurrentTab(queryVar, "addSender");
  const queryVar = getQueryVar({ ...props, q: "priTab" });
  useEffect(() => {}, []);
  return (
    // <OaCard className="oa-card-rounded-top" style={{ minHeight: 200 }}>
      <AddSenderForm {...props} />
    // </OaCard>
  );
};

export default AddSender;
