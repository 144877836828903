import React, {useState, useEffect} from 'react';

import OaCard from 'oahoc/OaCard';
import OaPriTabs from 'oahoc/OaPriTabs';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import AddCommissionForm from './AddCommissionForm';


const CommissionManager = (props) => {
    const tabs = [
        {name: 'commissionSetting', label: 'Commission Setting', url: ''},
    ];
    let currentTab = getCurrentTab(queryVar, 'commissionSetting');
    const queryVar = getQueryVar({...props, q: 'priTab'});
    useEffect(() =>{

    },[]);
    return (
        <OaCard className={'oa-card-primary'}>
            <OaPriTabs tabs={tabs} currentTab={currentTab}/>
            <OaCard>
                <AddCommissionForm/>
            </OaCard>
        </OaCard>
        
    )
}

export default CommissionManager;
