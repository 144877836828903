import React, { useState, useEffect } from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import OaCard from "oahoc/OaCard";

import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Box,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
} from "formik";
import _ from "lodash";
import Button from "components/CustomButtons/Button.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem } from "oautils/oaForageUtils";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FlightDetailCard from "./FlightDetailCard";
import { setHotelRoomParam } from "redux/action";
import OaFormAlerts from "pages/components/OaFormAlerts";
import Passenger from "./Passenger";
import Collapse from "@material-ui/core/Collapse";
import { isValid } from "date-fns";
import { AppConstant } from "appConstant";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import OaModel from "oahoc/OaModel";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .contact-detail-label": {
      textAlign: "right",
    },
    "& .contact-detail": {
      textAlign: "left",
    },
    "& .MuiGrid-container": {
      marginTop: -19,
    },
  },
  heading: {
    color: theme.palette.primary.main,
    marginLeft: -32,
    marginTop: -32,
  },
  text1: {
    color: theme.palette.primary.active,
    fontSize: "large",
  },
  alignItems: {
    // textAlign: "center",
    // width:"100%"
  },
  button: {
    background: theme.palette.primary.parrotGreen,
    color: theme.palette.primary.lightText,
    "&:hover": {
      background: theme.palette.primary.parrotGreen,
    },
    "&:disabled": {
      background: theme.palette.primary.disabled,
      cursor: "no-drop",
      color: theme.palette.primary.lightText,
      "&:hover": {
        background: theme.palette.primary.disabled,
        cursor: "no-drop",
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PassengerDetailCard = ({
  passengerCount,
  travelOfferId,
  history,
  flightDetails,
  openn,
  setopenn,
  triggersubmit,
  processpayment,
  paymentmode,
  accepttnc
}) => {
  const classes = useStyles();
  const initialValues = {
    adult: [],
    child: [],
    infant: [],
    gst: "false",
    mobile: "",
    email: "",
    comment: "",
  };
  const [totalFare, setTotalFare] = useState(0);
  const [showTNC, setShowTNC] = useState(false);
  const [showFareRates, setShowFareRates] = useState(false);
  const formikRef = React.useRef();
  const [isError, setIsError] = useState(false);
  const [isErrorM, setIsErrorM] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [param, setParam] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessM, setIsSuccessM] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [infoText, setInfoText] = useState("");
  const departureDate = flightDetails?.departureDate;
  const [userType, setUserType] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [pgMethod, setPGMethod] = React.useState(null);
  const [responseMessage, setResponseMessage] = useState(
    "Booking flight, Please wait"
  );

  const handleClose = () => {
    // setOpen(false);
    setopenn(false);
  };
  const handleChangeNew = (event, newValue) => {
    setValue(newValue);
  };
  const handlePGChange = (event) => {
    const method = event.target.value;
    setPGMethod(method);
  };

  const handleOK = () => {
    if (accepttnc) {
      setopenn(false);
      processpayment(paymentmode.method);
    } else {
      setIsErrorM(true);
      setInfoText("Please accept the terms and conditions");
    }
  };

  const submitData = (param) => {
    setIsSaving(true);

    apiCall(WebApi.getFxdDepBookTicket, param, (response) => {
      // console.log(response)
      if (response.data && response.data.tid > 0) {
        history.push("/print/flightticket/" + response.data.tid);
      } else {
        setIsSaving(false);
        setIsError(true);
        setInfoText(response.data.message);
      }
    });
  };

  const createDate = (days, months, years) => {
    var date = new Date(flightDetails.arrivalDate);
    date.setDate(date.getDate() - days);
    date.setMonth(date.getMonth() - months);
    date.setFullYear(date.getFullYear() - years);
    return date;
  };

  useEffect(() => {
    console.log("trigger submit", triggersubmit)
    if(triggersubmit) {
      formikRef.current.handleSubmit();
    }
  }, [triggersubmit]);

  useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-id", function(err, value) {
        apiCall(
          WebApi.getAgentExtendedDetails,
          { agentId: value },
          (response) => {
            setIsLoading(false);

            if (response.success) {
              setData(response.data);

              formikRef.current.setFieldValue(
                "mobile",
                response.data.mobilenumber
              );
              formikRef.current.setFieldValue("email", response.data.email);
              // formikRef.current.setFieldValue(
              // 	"mobile",
              // 	response.data.gstNumber
              // );
              // formikRef.current.setFieldValue("gstName", response.data.gstName);
              // formikRef.current.setFieldValue(
              // 	"gstMobile",
              // 	response.data.gstMobile
              // );
              // formikRef.current.setFieldValue(
              // 	"gstAddress",
              // 	response.data.gstAddress
              // );
              // formikRef.current.setFieldValue(
              // 	"gstEmail",
              // 	response.data.gstEmail
              // );
            } else {
              setIsError(true);
            }
          }
        );
      });

      for (let i = 0; i < passengerCount.adult; i++) {
        initialValues.adult.push({
          title: "",
          firstName: "",
          lastName: "",
          dob: createDate(-1, 0, 12),
        });
      }

      if (passengerCount.child > 0) {
        for (let i = 0; i < passengerCount.child; i++) {
          initialValues.child.push({
            title: "",
            firstName: "",
            lastName: "",
            dob: createDate(-1, 0, 2),
          });
        }
      }

      if (passengerCount.infant > 0) {
        for (let i = 0; i < passengerCount.infant; i++) {
          initialValues.infant.push({
            title: "",
            firstName: "",
            lastName: "",
            dob: new Date(3, 0, 0),
          });
        }
      }
    }
  }, [isLoading]);

  return (
    <div style={{ borderRadius: 0, padding: 20 }} xs={12} sm={12}>
      {/* <CardBody> */}
      <Grid container spacing={2} className={classes.gridRoot}>
        <Grid item md={12}>
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
        {/* <Grid
					item
					container
					direction="row"
					alignItems="center"
					justifyContent="flex-start"
				>
					<Grid item xs={12} md={4}>
						<Typography variant="h6" className={classes.heading}>
							Traveller Details
						</Typography>
					</Grid>
				</Grid> */}
        <hr style={{ width: "100%", margin: 0 }} />
        <Grid item xs={12}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              mobile: Yup.string().required("Mobile is required"),
              email: Yup.string().required("Email is required"),
              adult: Yup.array()
                .of(
                  Yup.object().shape({
                    title: Yup.string()
                      .min(1, "too short")
                      .required("Required"), // these constraints take precedence
                    firstName: Yup.string()
                      .min(1, "too short")
                      .required("Required"), // these constraints take precedence
                    lastName: Yup.string()
                      .min(1, "too short")
                      .required("Required"), // these constraints take precedence
                  })
                )
                .required("Must have adult") // these constraints are shown if and only if inner constraints are satisfied
                .min(1, "Minimum of 1 adult"),

              child: Yup.array().of(
                Yup.object().shape({
                  title: Yup.string()
                    .min(1, "too short")
                    .required("Required"), // these constraints take precedence
                  firstName: Yup.string()
                    .min(1, "too short")
                    .required("Required"), // these constraints take precedence
                  lastName: Yup.string()
                    .min(1, "too short")
                    .required("Required"), // these constraints take precedence
                })
              ),
              infant: Yup.array().of(
                Yup.object().shape({
                  title: Yup.string()
                    .min(1, "too short")
                    .required("Required"), // these constraints take precedence
                  firstName: Yup.string()
                    .min(1, "too short")
                    .required("Required"), // these constraints take precedence
                  lastName: Yup.string()
                    .min(1, "too short")
                    .required("Required"), // these constraints take precedence
                })
              ),
            })}
            onSubmit={(values) => {
              // console.log(values);

              let passenger = [];
              values.acceptTNC = accepttnc;

              values.adult.map((value, index) => {
                // console.log(index)
                // console.log(index == 0)
                passenger.push({
                  address: "NA",
                  country: "NA",
                  identityCardExpiryDate: "0000-00-00T00:00:00.000Z",
                  identityCardNo: "NA",
                  identityCardType: "NA",
                  dob: value.dob,
                  firstName: value.firstName,
                  isLeadPassenegr: index == 0 ? true : false,
                  lastName: value.lastName,
                  middleName: "",
                  passenegerType: "ADULT",
                  title: value.title,
                });
              });

              values.child.map((value, index) => {
                passenger.push({
                  address: "NA",
                  country: "NA",
                  identityCardExpiryDate: "0000-00-00T00:00:00.000Z",
                  identityCardNo: "NA",
                  identityCardType: "NA",
                  dob: value.dob,
                  firstName: value.firstName,
                  isLeadPassenegr: index == 0 ? true : false,
                  lastName: value.lastName,
                  middleName: "",
                  passenegerType: "CHILD",
                  title: value.title,
                });
              });

              values.infant.map((value, index) => {
                passenger.push({
                  address: "NA",
                  country: "NA",
                  identityCardExpiryDate: "0000-00-00T00:00:00.000Z",
                  identityCardNo: "NA",
                  identityCardType: "NA",
                  dob: value.dob,
                  firstName: value.firstName,
                  isLeadPassenegr: index == 0 ? true : false,
                  lastName: value.lastName,
                  middleName: "",
                  passenegerType: "INFANT",
                  title: value.title,
                });
              });

              let param = {
                pnrId: travelOfferId,
                comment: values.comment,
                emailAddress: values.email,
                isOptedForEmail: false,
                isOptedForReferal: false,
                isoptedForSMS: false,
                mobileNo: values.mobile,
                passeneger: passenger,
              };
              submitData(param);
              // setParam(param)
              // setOpen(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              dirty,
              isValid,
            }) => (
              <Form>
                <Grid container>
                  <Grid
                    item
                    container
                    direction="row"
                    style={{ display: "none" }}
                  >
                    <RadioGroup
                      aria-label="gender"
                      name="gst"
                      row
                      value={values.gst}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <Grid item xs={6}>
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Continue without GST"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Continue with GST"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grid>
                <FieldArray
                  name="adult"
                  render={(arrayHelpers) => (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>Adults</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {values.adult &&
                          values.adult.map((adult, index) => (
                            <Passenger
                              paxcategory="adult"
                              index={index}
                              handlechange={handleChange}
                              values={values}
                              flightdetails={flightDetails}
                              setfieldvalue={setFieldValue}
                              key={index}
                            />
                          ))}
                      </Grid>
                    </Grid>
                  )}
                />
                <FieldArray
                  name="child"
                  render={(arrayHelpers) => (
                    <Grid container style={{ marginTop: 20 }}>
                      {values.child &&
                        values.child.map((child, index) => (
                          <Passenger
                            paxcategory="child"
                            index={index}
                            handlechange={handleChange}
                            values={values}
                            flightdetails={flightDetails}
                            setfieldvalue={setFieldValue}
                            key={index}
                          />
                        ))}
                    </Grid>
                  )}
                />
                <FieldArray
                  name="infant"
                  render={(arrayHelpers) => (
                    <Grid container style={{ marginTop: 20 }}>
                      {values.infant &&
                        values.infant.map((child, index) => (
                          <Passenger
                            paxcategory="infant"
                            index={index}
                            handlechange={handleChange}
                            values={values}
                            flightdetails={flightDetails}
                            setfieldvalue={setFieldValue}
                            key={index}
                          />
                        ))}
                    </Grid>
                  )}
                />
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item container direction="row" alignItems="center">
                    <Grid item xs={4} md={2} className="contact-detail-label">
                      Mobile Number
                    </Grid>
                    <Grid item xs={8} md={4} className="contact-detail">
                      <Field
                        variant="outlined"
                        className="passenger-field"
                        name="mobile"
                        label="Mobile"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="mobile"
                        className="error"
                        component="div"
                      />
                    </Grid>
                    <Grid item xs={4} md={2} className="contact-detail-label">
                      Email
                    </Grid>
                    <Grid item xs={8} md={4} className="contact-detail">
                      <Field
                        label="Email"
                        variant="outlined"
                        className="passenger-field"
                        type="email"
                        name="email"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="email"
                        className="error"
                        component="div"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Button className={classes.button} type="submit"
								style={{
									background: theme.palette.primary.main,
									cursor: "pointer",
								}}  disabled={!(isValid && dirty)}>Submit</Button> */}

                <Dialog
                  // fullScreen={fullScreen}
                  open={openn}
                  onClose={handleClose}
                  aria-labelledby="fixed-departure-confirm-dialog-title"
                >
                  <DialogTitle id="fixed-departure-confirm-dialog-title">
                    {"Confirm Booking Details?"}
                  </DialogTitle>
                  <DialogContent>
                    <OaFormAlerts
                      isSaving={isSaving}
                      isSuccess={isSuccessM}
                      isError={isErrorM}
                      infoText={infoText}
                      setIsError={setIsErrorM}
                      setIsSuccess={setIsSuccessM}
                    />
                    {flightDetails !== null && (
                      <FlightDetailCard
                        flightDetails={flightDetails}
                        isHideBackButton={true}
                      />
                    )}
                    {param.passenger && (
                      <Card style={{ padding: 10 }}>
                        <Grid container>
                          <Divider style={{ color: "red" }} />
                          <Grid
                            item
                            style={{
                              fontSize: "1.25rem",
                              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                              fontWeight: 500,
                              lineHeight: 1.6,
                              color: "#80CC4B",
                            }}
                          >
                            Passenger Details{" "}
                          </Grid>

                          <hr style={{ width: "100%", margin: 0 }} />
                          <Grid container>
                            {param.passenger.map((value) => {
                              return (
                                <>
                                  {/* <Grid container style={{ padding: 20 }}>
																	<Grid item md={6}>Name</Grid>
																	<Grid item md={6}>{`${value.title} ${value.firstName} ${value.lastName}`}</Grid>
																</Grid>
																<Grid container>
																	<Grid item md={6}>PAX TYPE</Grid>
																	<Grid item md={6}>{value.passenegerType}</Grid>
																	</Grid>
																	<Grid container>
																	<Grid item md={6}>DOB</Grid>
																	<Grid item md={6}>{`${value.dob}`}</Grid>
																	</Grid>

																																	{/* 
																	<Grid item>Name</Grid>
																	<Grid item>{value.title}</Grid>
																	<Grid item>{value.firstName}</Grid>

																	<Grid item>{value.lastName}</Grid>
																	<Grid item>{value.passenegerType}</Grid>
																	<Grid item>{value.dob}</Grid> */}
                                </>
                              );
                            })}
                          </Grid>

                          <hr style={{ width: "100%", margin: 0 }} />

                          <Grid container>
                            <Grid
                              item
                              md={12}
                              style={{
                                fontSize: "1.25rem",
                                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: "#80CC4B",
                              }}
                            >
                              Communication Details
                            </Grid>
                          </Grid>
                          <hr style={{ width: "100%", margin: 0 }} />
                          <Grid container style={{ padding: 20 }}>
                            <Grid item md={6}>
                              Email
                            </Grid>
                            <Grid item md={6}>
                              {param.emailAddress}
                            </Grid>
                          </Grid>
                          <Grid container style={{ padding: 20 }}>
                            <Grid item md={6}>
                              Mobile
                            </Grid>
                            <Grid item md={6}>
                              {param.mobileNo}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    )}
                    {/* <Grid item container xs={12} spacing={2}>
											<Grid item md={12}>
												<Divider style={{ marginTop: 10, marginBottom: 10 }} />
											</Grid>
											<Grid item xs={12}>
												<Typography
													component="h2"
													variant="h6"
													className={classes.sectionHeading1}
												>
													PAYMENT DETAILS
												</Typography>
											</Grid>


											<Box sx={{ width: '100%' }}>
												<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
													<Tabs value={value} onChange={handleChangeNew} aria-label="basic tabs example" className={classes.tabDiv}>
														{
															paymentOptions && paymentOptions.map((value, index) => (
																<Tab label={value.methodLabel} {...a11yProps(index)} />
															))}
													</Tabs>
												</Box>

												{
													paymentOptions && paymentOptions.map((value1, index1) => (

														<TabPanel value={value} index={index1} style={{ paddingTop: 25 }} className={classes.tabContDiv}>
															{
																value1.gateway && value1.gateway.length > 0 ?
																	<>
																		{value1.gateway && (
																			<FormControl component="fieldset">
																				<FormLabel component="legend">
																					Select Payment Method
																				</FormLabel>
																				<RadioGroup
																					aria-label="method"
																					name="method"
																					value={pgMethod}
																					onChange={handlePGChange}
																				>
																					{value1.gateway.map((value, index) => (
																						<FormControlLabel
																							value={value.method}
																							control={<Radio />}
																							label={value.methodOwnChargeHandle ? `${value.method_label}` : `${value.method_label
																								} payment gateway charges ${value.charge_type === "A" ? " Rs." : ""
																								}${value.charge}${value.charge_type === "A" ? " Only" : "%"
																								}, excluding GST ${value.gstRate}% on PG charge`}
																						/>
																					))}
																				</RadioGroup>
																			</FormControl>
																		)}
																	</>
																	:
																	<>
																		{
																			value1.method === 'cash' ?
																				<Grid item md={12}>
																					{!isLoading && data.balance >= totalFare ? (
																						<>
																							<Field
																								type="radio"
																								name="cashPayment"
																								id="cashPayment"
																								className={
																									"form-check-input " +
																									(errors.cashPayment && touched.cashPayment
																										? " is-invalid"
																										: "")
																								}
																							/>
																							<label
																								htmlFor="cashPayment"
																								className="form-check-label payment-info-title"
																							>
																								{" "}
																								Use Wallet(Current Cash Balance is {data.balance})
																							</label>
																						</>
																					) : (
																						<div
																							onClick={() => {
																								if (!isLoading) setIsLoading(true);
																							}}
																							style={{ display: "flex", cursor: "pointer" }}
																						>
																							<span className="payment-info-title">
																								{" "}
																								Insufficient Fund (Current Cash Balance is{" "}
																								{data.balance ? data.balance : 0})
																							</span>
																							<AutorenewIcon />
																						</div>
																					)}
																				</Grid>
																				:
																				<>
																					{value1.methodLabel}
																				</>
																		}
																	</>
															}
														</TabPanel>
													))}
											</Box>

											<Grid item md={12}>
												<Divider style={{ marginTop: 20, marginBottom: 10 }} />
											</Grid>
											<Grid item md={12}>
												<Typography
													component="h2"
													variant="h6"
													className={classes.sectionHeading1}
												>
													Note
												</Typography>
											</Grid>

											<Grid item md={12}>
												<Divider style={{ marginTop: 10, marginBottom: 10 }} />
											</Grid>

											<Grid item md={12}>
												<span className="payment-info-title">
													This fare is not guaranteed until the Flight ticket is generated. Generation of a
													Transaction ID (TID) is the confirmation of booking request
													and payment received by {AppConstant.domainName} and under no
													circumstances, is it equivalent to {AppConstant.domainName}' final
													purchase price of respective Hotel voucher. There might be
													situations, where the fare displayed may increase by the
													time purchase is made with the respective Hotel. In this
													event, {AppConstant.domainName} will revert to you with the new fare
													or alternate options regarding your flight booking.
												</span>
											</Grid>
											<Grid item md={12}>
												<span className="payment-info-title " style={{ color: "#D0342C", }}>
													*There will be a TDS on the discounts / commissions on every
													transaction @ 5% for Permanent Account Number (PAN) holders
													and 20% if the agent is not having a PAN. Kindly update your
													PAN to save 15% on TDS.
												</span>
											</Grid>

											<Grid item md={12}>
												<Divider style={{ marginTop: 10, marginBottom: 10 }} />
											</Grid>

											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															style={{ color: "#1374B3" }}
															value={values.acceptTNC}
															onChange={(event) => {
																setFieldValue("acceptTNC", event.target.checked);
															}}
														/>
													}
													label={
														<div className={classes.termsandCond}>
															I have read and accepted{" "}
															<a style={{ color: "#D0342C" }}>
																<span onClick={() => setShowTNC(true)}>
																	TERMS AND CONDITIONS
																</span>{" "}
																<OaModel
																	show={showTNC}
																	onHide={() => setShowTNC(false)}
																	heading="Terms And Conditions"
																	body={
																		<>
																			<h4>Centered Modal</h4>
																			<p>
																				Cras mattis consectetur purus sit amet
																				fermentum. Cras justo odio, dapibus ac facilisis
																				in, egestas eget quam. Morbi leo risus, porta ac
																				consectetur ac, vestibulum at eros.
																			</p>
																		</>
																	}
																/>
															</a>{" "}
															and{" "}
															<a style={{ color: "#D0342C" }}>
																<span onClick={() => setShowFareRates(true)}>
																	FARE RATES
																</span>
																<OaModel
																	show={showFareRates}
																	onHide={() => setShowFareRates(false)}
																	heading="Fare Rates"
																	body={
																		<>
																			<h4>Centered Modal</h4>
																			<p>
																				Cras mattis consectetur purus sit amet
																				fermentum. Cras justo odio, dapibus ac facilisis
																				in, egestas eget quam. Morbi leo risus, porta ac
																				consectetur ac, vestibulum at eros.
																			</p>
																		</>
																	}
																/>
															</a>
														</div>
													}
													className={classes.payment__info}
												/>
												<ErrorMessage
													component="div"
													className="error"
													name="acceptTNC"
												/>
											</Grid>
										</Grid> */}
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    {!isLoading &&
                      (data.balance >= totalFare ||
                        userType === "b2c_user") && (
                        <Button
                          onClick={handleOK}
                          color="primary"
                          type="submit"
                          autoFocus
                        >
                          Ok
                        </Button>
                      )}
                  </DialogActions>
                </Dialog>

                {/* {errors &&
 									_.isString(errors) &&
 									touched &&
 									_.isArray(touched) && (
 										<div className="field-error">{errors}</div>
 									)}
 								<div className={"row"}>
 									<div className={"col-12"}>
 										<code>
 											<pre>Values: {JSON.stringify(values, null, 2)}</pre>
 										</code>
 									</div>
 									<div className={"col-12"}>
 										<pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
 									</div>
 									<div className={"col-12"}>
 										<pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
 									</div>
 								</div> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {/* </CardBody> */}
    </div>
  );
};

export default withRouter(PassengerDetailCard);
