import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
const styles = theme => ({
  root: {
    marginBottom: 16,
    backgroundColor: "transparent",
    marginLeft: 15,
    marginRight: 15,
    [theme.breakpoints.down('sm')]:{
      marginLeft: 0,
      marginRight: 0,
    }
  },
  rowContainer: {
    // backgroundColor : 'rgba(0,0,0,0.71)',
    padding: 25,
    [theme.breakpoints.down('sm')]:{
      padding: 0,
    }
    // height:166
  },
  itemContainer: {},
  firstIcon: {},

  iconLabelWrapper: {
    flexDirection: "column",
    color: "white",
    height: 105,
    backgroundColor: theme.palette.secondary.darkBlue,
    borderRadius: 5,
  },
  iconLabelWrapperActive: {
    flexDirection: "column",
    color: "white",
    height: 105,
    backgroundColor: "rgba(216,216,216,0.37)",
  },

  tabStyle: {
    backgroundColor: "transparent",
    color: "white",
    width: "100%",
    opacity: 1,
  },
  activeTabStyle: {
    backgroundColor: theme.palette.secondary.medallion,
    color: "white",
    width: "100%",
    opacity: 1,
  },
});

const OaActionButton = (props) => {
  function handleChange(event, row) {
    props.history.push(row.url);
  }

  return (
    <div className={props.classes.root}>
      <div className={props.classes.rowContainer}>
        <Grid container direction="row" justifyContent="center" alignItems="stretch">
          {props.options.map((row, id) => {
            return (
              <Grid item md={2} xs={6} className={props.classes.itemContainer}>
                <Tab
                  key={row.name}
                  value={row.name}
                  component={Link}
                  onClick={(event) => {
                    handleChange(event, row);
                  }}
                  classes={{
                    wrapper: props.classes.iconLabelWrapper,
                    labelContainer: props.classes.labelContainer,
                    indicator: props.classes.indicator,
                  }}
                  icon={row.icon}
                  label={row.label}
                  className={props.classes.tabStyle}
                  to={"#"}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

OaActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(OaActionButton));
