import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {Typography,Button, useTheme} from "@material-ui/core";
import OaCard from "oahoc/OaCard";
import GridContainer from "components/Grid/GridContainer";


const useStyles = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
  },
  typo:{
      color:theme.palette.secondary.gray,
      fontWeight:"bold"
  }
}));

const AgentReview = (props) => {
  // console.log(props.location.state);
  const theme=useTheme();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <OaCard>
          <div>
        <OaCard>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography className={classes.typo}>Login Details</Typography>
            </Grid>

            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.username}
                label="UserName"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                    color:theme.palette.primary.defaultText
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.pannumber}
                label="Pan Number"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
          </Grid>
        </OaCard>
        </div>

        <div>
        <OaCard>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography className={classes.typo}>Ownership Details</Typography>
            </Grid>

            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.firstname}
                label="Firstname"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.lastname}
                label="Lastname"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.gender}
                label="Gender"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.dob}
                label="DOB"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
          </Grid>
        </OaCard>
        </div>

        <div>
        <OaCard>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography  className={classes.typo}>Communication Details</Typography>
            </Grid>

            <Grid item md={12}>
              <TextField
                fullWidth
                value={props.location.state.corpname}
                label="Company Name"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                value={props.location.state.address}
                label="Address"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.statename}
                label="State"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.cityname}
                label="City Code"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.pincode}
                label="Pin Code"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.landlinenumber}
                label="Landline Number"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.mobilenumber}
                label="Mobile Number"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.website}
                label="Website"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.email}
                label="email"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.confirmEmail}
                label="Confirm Email"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.currentTurnover}
                label="Current Turnover"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.expectedVolume}
                label="Expected Volume"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                value={props.location.state.officeSpaceType}
                label="Office SpaceType"
                InputProps={{
                  readOnly: true,
                  autoFocus: false,
                  style: {
                    borderBottom: "1px solid black",
                    outline: "none",
                  },
                }}
              />
            </Grid>
          </Grid>
        </OaCard>
        </div>
        <GridContainer justifyContent="center" style={{marginTop:20}} spacing={5}>
            <Grid item >
                <Button style={{background:theme.palette.background.green,color:theme.palette.primary.lightText}}>Register</Button>
            </Grid>
            <Grid item >
            <Button style={{background:theme.palette.secondary.darkBlue,color:theme.palette.primary.lightText}} onClick={()=>props.history.goBack()}>Back</Button>
            </Grid>
        </GridContainer>
        
      </OaCard>
    </div>
  );
};

export default AgentReview;
