import localforage from 'localforage';

export const localforageSetItem = (key, value, callback) => {
    key = window.appNameContext + '-' + key;

    localforage.setItem(key, value, () => {
        if(callback) callback();
    });
}

export const localforageGetItem = (key, callback) => {
    key = window.appNameContext + '-' + key;

    localforage.getItem(key, (err, value) => {
        if(callback) callback(err, value);
    });
}