import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";

import { TextField, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";

const useStyles = makeStyles((theme) => ({
  departDate: {
    "& .MuiInputBase-input": {
      fontSize: 14,
    },
    "& .departDateControl": {
      marginTop: 7,
    },
    "& .oa-form-element-card": {
      border: "1px solid #000",
    },
    "& .react-datepicker__month-container": {
      // float: "none",
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  TextField: {
    fontSize: "14px",
    lineHeight: "1.42857",
    color: theme.palette.secondary.dimGray,
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.primary.silverWhite}`,
    display: "block",
    width: "100%",
    borderColor: theme.palette.secondary.periglacialBlue,
    boxShadow: "none",
    borderRadius: "4px",
    height: "35px",
    padding: "9px",
    marginBottom: "15px",
    "&:focus": {
      borderColor: theme.palette.secondary.skyBlue,
      outline: 0,
      WebkitBoxShadow:
        "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
      boxShadow:
        "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
    },
  },
  typo: {
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "1.42857",
    color: theme.palette.secondary.dimGray,
    marginBottom: "5px",
  },
  TextField1: {
    width: "100%",
    fontSize: "14px",
    lineHeight: "1.42857",
    color: theme.palette.secondary.dimGray,
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.primary.silverWhite}`,
    display: "block",

    borderColor: theme.palette.secondary.periglacialBlue,
    boxShadow: "none",
    borderRadius: "4px",
  },
  errors: {
    color: "red",
    fontSize: 12,
  },
  button: {
    marginTop: 10,
    padding: "10px 19px",
    color: theme.palette.primary.lightText,
    background: theme.palette.background.primary,
    border: `1px solid ${theme.palette.background.brightRed}`,
    fontWeight: "bold",
    WebkitTransition: "all 0.3s ease-in-out",
    MozTransition: "all 0.3s ease-in-out",
    OTransition: "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
    fontSize: "14px",
    lineHeight: "1.42857",
    whiteSpace: "nowrap",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme.palette.background.brightRed,
      color: theme.palette.primary.lightText,
    },
  },
  radio: {
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "1.42857",
    color: theme.palette.secondary.dimGray,
  },
  span: {
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "1.42857",
    color: theme.palette.secondary.dimGray,
    marginBottom: "5px",
  },
}));

const PackageTabForm = (props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(props.packageId)

  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  return (
    <>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />

      <Formik
        initialValues={{
          name: "",
          mobileNo: "",
          email: "",
          adult: "",
          confirmEmail: "",
          hotelCategory: "",
          nationality: "",
          specificRemarks: "",
          child: "",
          infant: "",
          passportOriginCountry: "",
          specificDemands: "",
          optForAirTravel: false,
          departureDateString: new Date(),
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          mobileNo: Yup.string()
            .required("Required")
            .matches(phoneRegExp, "Phone number is not valid")
            .min(10, "to short")
            .max(10, "to long"),
          email: Yup.string()
            .typeError("You must specify a email")
            .required("Email is required")
            .email("Enter a valid email"),
          passportOriginCountry: Yup.string().required("Required"),
          adult: Yup.string()
            .typeError("You must specify no of adult")
            .required("Choose No of Adults"),
          child: Yup.string()
            .typeError("You must specify no of child")
            .required("Choose No of Child"),
          infant: Yup.string()
            .typeError("You must specify no of infant")
            .required("Choose No of infant"),
          hotelCategory: Yup.string()
            .typeError("You must Choose")
            .required("ChooseType Of Hotel"),
          nationality: Yup.string().required("Required"),
          confirmEmail: Yup.string()
            .oneOf([Yup.ref("email"), null], "Email must match")
            .required("Email is required")
            .email("Enter a valid email"),
          departureDateString: Yup.date().required("please choose date"),
          specificDemands: Yup.string().required("Required"),
          specificRemarks: Yup.string().required("Required"),
        })}
        onSubmit={(
          values,
          { setSubmitting, resetForm, enableReinitialize }
        ) => {
          // console.log(values);
          setIsSaving(true);

          values.packageId = props.packageId;
          apiCall(WebApi.setPackageRequest, values, (response) => {
            // console.log("respo", response);
            setIsSaving(false);
            setIsError(!response.success);
            setIsSuccess(response.success);
            setInfoText(response.message);
            setSubmitting(false);
            setIsSaving(false);
            if (response.success === true) {
              enableReinitialize = true;
              resetForm(true);
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          setFieldValue,
          enableReinitialize,
          resetForm,
        }) => (
          <Form>
            <Grid container spacing={isMobile ? 1 : 2}>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>Name</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      type="name"
                      className={classes.TextField}
                      name="name"
                      fullwidth
                    />
                    <ErrorMessage
                      name="name"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>Mobile</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      className={classes.TextField}
                      name="mobileNo"
                      fullwidth
                    />
                    <ErrorMessage
                      name="mobileNo"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>Email</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      type="email"
                      className={classes.TextField}
                      name="email"
                      fullwidth
                    />
                    <ErrorMessage
                      name="email"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>
                      Confirm Email
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      type="email"
                      className={classes.TextField}
                      name="confirmEmail"
                      fullwidth
                    />
                    <ErrorMessage
                      name="confirmEmail"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>Adult</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="adult"
                      as="select"
                      className={classes.TextField}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </Field>
                    <ErrorMessage
                      name="adult"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>Child</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="child"
                      as="select"
                      className={classes.TextField}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </Field>
                    <ErrorMessage
                      name="child"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>INFANT</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="infant"
                      as="select"
                      className={classes.TextField}
                      style={{ marginTop: 7, height: 40 }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </Field>
                    <ErrorMessage
                      name="infant"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container className={classes.departDate}>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>DEPART</Typography>
                  </Grid>
                  <Grid item md={12} xs={12} className="departDateControl">
                    <OaOutlinedDatePicker
                      showDisabledMonthNavigation
                      className="date-picker"
                      // name={`sectors[0].departureDateString`}
                      minDate={new Date()}
                      customInput={
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          // fullWidth={isMobileScreen}
                        />
                      }
                      selected={values.departureDateString}
                      onChange={(value) =>
                        setFieldValue("departureDateString", value)
                      }
                      monthsShown={1}
                      dateFormat="dd MMM yyyy"
                      // labeltxt="DEPARTURE DATE"
                      // secondarytxt={
                      //   days[props.values.sectors[0].departDateDisplay.getDay()]
                      // }
                      showinput={false}
                    />
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        id="date-picker-inline"
                        fullWidth
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={values.departureDateString}
                        onChange={(value) =>
                          setFieldValue("departureDateString", value)
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessage
                      name="departureDateString"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid> */}
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>
                      NATIONALITY
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <label>
                      <Field type="radio" name="nationality" value="indian" />
                      indian
                    </label>
                    <label>
                      <Field type="radio" name="nationality" value="other" />
                      Other(US,UK,etc..)
                    </label>
                    <ErrorMessage
                      name="nationality"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>PASSPORT</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <label>
                      <Field
                        type="radio"
                        name="passportOriginCountry"
                        value="indian"
                      />
                      indian
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="passportOriginCountry"
                        value="other"
                      />
                      Other(US,UK,etc..)
                    </label>
                    <ErrorMessage
                      name="passportOriginCountry"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>
                      {" "}
                      Preferred Hotel category
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="hotelCategory"
                      as="select"
                      className={classes.TextField}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Budget">Budget</option>
                      <option value="Standard">Standard</option>
                      <option value="Deluxe">Deluxe</option>
                    </Field>
                    <ErrorMessage
                      name="hotelCategory"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography
                      className={classes.typo}
                      style={{ display: "none" }}
                    >
                      Opt For Air Travel
                    </Typography>
                  </Grid>
                  <Grid item md={12} style={{ marginTop: 30 }} xs={12}>
                    <Field type="checkbox" name="optForAirTravel" />
                    <span
                      style={{
                        color: theme.palette.primary.darkGray,
                        fontSize: 12,
                      }}
                    >
                      would you like us to quote for air travel
                    </span>
                    <ErrorMessage
                      name="optForAirTravel"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>
                      Any Specific sightseing
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      className={classes.TextField1}
                      name="specificDemands"
                      as="textarea"
                      rows="5"
                    />
                    <ErrorMessage
                      name="specificDemands"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.typo}>
                      {" "}
                      Any other Specific remark
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      className={classes.TextField1}
                      name="specificRemarks"
                      as="textarea"
                      rows="5"
                    />
                    <ErrorMessage
                      name="specificRemarks"
                      className={classes.errors}
                      component="div"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Button
                type="submit"
                disabled={isSaving}
                exReset={enableReinitialize}
                inReset={resetForm}
                className={classes.button}
              >
                Submit
              </Button>
            </Grid>
            {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div className={"row"}>
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PackageTabForm;
