import React, {useState} from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core';

export default function OaSwitch(props){
  const [state, setState] = useState(props.checked);
  const [dialogOpen, setDialogOpen] = useState(false);

  const stateChangeHandler = event => {
    setDialogOpen(true);
  };

  const dialogCloseHandler = event => {
    if(event.target.innerHTML === 'Yes'){
      setState(!state);
    }
    setDialogOpen(false);
  }
  const theme = useTheme();
  return (
    <>
      <div style={{borderLeft: `1px solid ${theme.palette.secondary.chineseGray}`, padding: "10px 10px 5px 10px"}}>
      <Grid component="label" container alignItems="center" style={{fontWeight: "bold", color: theme.palette.primary.darkGray}}>
          {props.label}
      </Grid>
        <Grid component="label" container alignItems="center">
          <Grid item>Inactive</Grid>
          <Grid item>
            <Switch 
              checked={state} 
              value={props.label} 
              onChange={stateChangeHandler}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item>Active</Grid>
        </Grid>
        <Dialog open={dialogOpen} onClose={dialogCloseHandler}>
          <DialogTitle>Change Status ({props.label})</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={dialogCloseHandler} color="primary">Yes</Button>
            <Button onClick={dialogCloseHandler} color="primary" autoFocus>No</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}