import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  makeStyles,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { RoomObj } from "./HotelSection";

const useStyles = makeStyles({
  root: {
    padding: "12px",
  },
  submitButton: {
    "& .MuiButton-label": {
      margin: "0 20px",
    },
  },
});

const RoomModal = ({
  values,
  rooms,
  closeModal,

  setFieldValue,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <FieldArray
        name="roomGuests"
        render={(arrayHelpers) => (
          <div>
            {rooms && rooms.length > 0 && (
              <>
                <Box style={{ maxHeight: "80vh", overflow: "scroll" }}>
                  {rooms.map((room, index) => (
                    <Room
                      index={index}
                      values={values}
                      room={room}
                      arrayHelpers={arrayHelpers}
                      disableDelRoom={rooms.length <= MinRooms}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </Box>
                <Box className={classes.roomModalActions}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box m={2} mr={1}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          arrayHelpers.insert(
                            values.roomGuests.length + 1,
                            new RoomObj()
                          );
                        }}
                        disabled={rooms.length >= MaxRooms}
                      >
                        Add Room
                      </Button>
                    </Box>
                    <Box m={2} mr={1}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.submitButton}
                        onClick={closeModal}
                      >
                        Done
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </>
            )}
          </div>
        )}
      ></FieldArray>
    </Box>
  );
};

// -------------------------------------------------------------
// #############################################################
// -------------------------------------------------------------

const useRoomStyles = makeStyles({
  root: {},
  roomTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  countAdultChild: {
    textAlign: "center",
    fontSize: "14px",
    padding: "0 8px",
    margin: "auto",
    fontWeight: "500",
  },
  btnBG: {
    backgroundColor: "rgba(29, 51, 108, 0.04)",
    borderRadius: "4px",
  },
  childAgeSelect: {
    "& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled": {
      transform: "translateY(6px)",
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
  },
  roomCount: {
    paddingLeft: "8px",
  },
});

const Room = ({
  room,
  changeGuestCount,
  disableDelRoom,
  values,
  changeChildAge,
  index,
  arrayHelpers,
  setFieldValue,
}) => {
  const roomStyles = useRoomStyles();

  const [isRoomMin, setIsRoomMin] = useState(false);

  return (
    <Box m={2} className={roomStyles.root}>
      <Box className={roomStyles.roomTitle}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <IconButton
                color="secondary"
                size="small"
                onClick={() => setIsRoomMin(!isRoomMin)}
                style={{ paddingTop: "8px" }}
              >
                {isRoomMin ? (
                  <KeyboardArrowUpRoundedIcon />
                ) : (
                  <KeyboardArrowDownRoundedIcon />
                )}
              </IconButton>
              {/* {editStatus ? (
                <TextField
                  color="secondary"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                />
              ) : (
                <p style={{ paddingTop: "8px" }}>{room.name}</p>
              )} */}
              <p style={{ paddingTop: "8px" }}>{"Room " + (index + 1)}</p>
            </Grid>
          </Box>
          <Box>
            <IconButton
              color="secondary"
              size="small"
              disabled={disableDelRoom}
              onClick={() => arrayHelpers.remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
        {isRoomMin && (
          <span
            style={{ fontSize: "12px", paddingLeft: "32px" }}
          >{`${room.noOfAdults} Adult, ${room.noOfChild} Child`}</span>
        )}
      </Box>

      {!isRoomMin && (
        <Box className={roomStyles.roomCount}>
          <Grid
            key={`${index}`}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "8px" }}
          >
            <Box>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "auto",
                  textTransform: "capitalize",
                }}
              >
                Adult
              </p>
            </Box>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box className={roomStyles.btnBG}>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() =>
                      setFieldValue(
                        `roomGuests[${index}].noOfAdults`,
                        room.noOfAdults - 1
                      )
                    }
                    disabled={room.noOfAdults <= MinAdult}
                  >
                    <RemoveIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Box width={40}>
                  <p className={roomStyles.countAdultChild}>
                    {padZero(room.noOfAdults)}
                  </p>
                </Box>
                <Box className={roomStyles.btnBG}>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      // console.log("Add adult clicked");
                      setFieldValue(
                        `roomGuests[${index}].noOfAdults`,
                        room.noOfAdults + 1
                      );
                      // changeGuestCount(index, 'adult', 1)
                    }}
                    disabled={room.noOfAdults >= MaxAdult}
                  >
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid
            key={`${index}`}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "8px" }}
          >
            <Box>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "auto",
                  textTransform: "capitalize",
                }}
              >
                Child
              </p>
            </Box>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box className={roomStyles.btnBG}>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setFieldValue(
                        `roomGuests[${index}].noOfChild`,
                        room.noOfChild - 1
                      );

                      setFieldValue(
                        `roomGuests[${index}].childAge`,
                        room.childAge.splice(-1)
                      );
                    }}
                    disabled={room.noOfChild <= MinChild}
                  >
                    <RemoveIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Box width={40}>
                  <p className={roomStyles.countAdultChild}>
                    {padZero(room.noOfChild)}
                  </p>
                </Box>
                <Box className={roomStyles.btnBG}>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      // console.log("Add child clicked");
                      setFieldValue(
                        `roomGuests[${index}].noOfChild`,
                        room.noOfChild + 1
                      );

                      setFieldValue(`roomGuests[${index}].childAge`, [
                        ...room.childAge,
                        0,
                      ]);
                    }}
                    disabled={room.noOfChild >= MaxChild}
                  >
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}

      <Box className={roomStyles.roomChildAges}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FieldArray
            name="room.childAge"
            render={(arrayHelpers) => (
              <>
                {room.childAge && room.childAge.length > 0 && (
                  <>
                    {room.childAge.map((age, i) => (
                      <Grid item container xs={6} spacing={1}>
                        <FormControl
                          variant="filled"
                          color="secondary"
                          fullWidth
                          className={roomStyles.childAgeSelect}
                        >
                          <InputLabel>{`Child ${i + 1} Age`}</InputLabel>
                          <Select
                            value={age}
                            onChange={(e) => {
                              setFieldValue(
                                `roomGuests[${index}].childAge[${i}]`,
                                e.target.value
                              );
                              // changeChildAge(index, i, e.target.value)
                            }}
                          >
                            {[...Array(12).keys()].map((a) => (
                              <MenuItem value={a + 1}>{a + 1}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}
          />
        </Grid>
      </Box>
      <Divider style={{ marginTop: "16px" }} />
    </Box>
  );
};

export default RoomModal;

const padZero = (v) => (v <= 9 ? `0${v}` : `${v}`);
const MaxRooms = 5;
const MinRooms = 1;
const MinChild = 0;
const MaxChild = 2;
const MinAdult = 1;
const MaxAdult = 5;
