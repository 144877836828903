import React, { Component} from 'react';
import ReactTable from "react-table";

class OaTable extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " "
        } 
    }

    componentDidMount() {
        this.tableLoader();
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        if(loading !== prevState.loading){
            var table = document.getElementsByClassName("rt-table")[0];
            var footer = document.getElementsByClassName("rt-tfoot")[0];
            if(footer){
                table.insertBefore(footer, table.childNodes[0]);
            }
        }
    }

    tableLoader() {
        this.props.loader(this.props.search, (resp) => {
            // console.log(resp)
            if(resp != null && resp.success){
                var tableData = [];
                if(resp.data.hasOwnProperty("tableData")){
                    tableData = resp.data.tableData;
                }else{
                    tableData = resp.data;
                }
                // console.log(tableData)

                this.setState({data: tableData});
                this.setState({
                    rowCount: tableData.length === 0 ? 4 : tableData.length,
                    noDataText: tableData.length === 0 ? "No results" : tableData.length,
                    loading: false,
                    finishedLoading: true
                });

            } 
            },(error)=>{
                this.setState({
                    data: [],
                    rowCount: 4,
                    // noDataText: "Something went wrong", //@TODO: for production environment
                    noDataText: "ERROR ", // + error.response.data.status + ": " + error.response.data.error,
                    loading: false,
                    finishedLoading: true
                });
                // console.log(error);
        });
    }


    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                //console.log('Row clicked');
                //this.setRowDetails(rowInfo);
            }
        }
    }

    render(){
        return (
            <ReactTable
                {...this.props}
                defaultFilterMethod={this.filterMethod}
                data={this.state.data}
                filterable={this.props.filterable}
                columns={this.props.columns}
                showPagination={false}
                //defaultPageSize={500}
                minRows={this.state.rowCount}
                loading= {this.state.loading}
                pageSize={this.state.rowCount}
                className="-striped -highlight"
                getTrProps={this.onRowClick}
                noDataText={this.state.noDataText}
            />
        )
    }
}

export default OaTable;


{/**
this.state.finishedLoading && !this.state.loading ? "No results" : ""


TO FIX TABLE HEIGHT
                style={{
                    height: "400px"
                  }}
*/}