import React from 'react';
import GridItem from 'components/Grid/GridItem.js';
import { TextField, FormControl } from '@material-ui/core';
import { Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';

const OaFormSelectField = (props) => {
  let useGrid = props.md !== undefined;
  let optionvalue = props.optionValue;
  let optionlabel = props.optionLabel;

  function buildMenuOptions() {
    if (props.options !== undefined) {
      const options = props.options.map((row) =>
        optionvalue && optionlabel ? (
          <MenuItem key={row[optionvalue]} value={row[optionvalue]}>
            {row[optionlabel]}
          </MenuItem>
        ) : (
          <MenuItem key={row.value} value={row.value}>
            {row.label}
          </MenuItem>
        ),
      );
      return options;
    } else {
      // console.log('ERROR building menu options');
      // console.log(props);
      return <MenuItem key='0' />;
    }
  }

  function field() {
    return (
      <FormControl fullWidth={true}>
        {props.onChange ? (
          <Field
            {...props}
            label={props.label}
            name={props.name}
            value={props.value}
            variant='outlined'
            className={'oa-input-white'}
            type='input'
            as={TextField}
            select={true}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange(value);
            }}
          >
            {buildMenuOptions()}
          </Field>
        ) : (
          <Field
            {...props}
            label={props.label}
            name={props.name}
            value={props.value}
            variant='outlined'
            className={'oa-input-white'}
            type='input'
            as={TextField}
            select={true}
          >
            {buildMenuOptions()}
          </Field>
        )}
      </FormControl>
    );
  }

  return (
    <>
      {!useGrid && field()}
      {useGrid && (
        <GridItem xs={12} sm={12} md={props.md}>
          {field()}
        </GridItem>
      )}
    </>
  );
};

export default OaFormSelectField;
