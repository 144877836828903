import React, { useState, useEffect } from "react";

import OaCard from "oahoc/OaCard";
import BeneficiaryListHeading from "./BenficiaryListHeading";
import BeneficiaryListTable from "./BeneficiaryListTable";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";

const BeneficiaryList = props => {
  const [data, setData] = useState({});
  const [mobile, setMobile] = useState("");
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    let dataData = "";
    let mobileData = "";
    if (props.location.query) {
      dataData = props.location.query.data;
      mobileData = props.location.query.mobile;
    } else {
      dataData = props.location.state.data;
      mobileData = props.location.state.mobile;
    }

    setMobile(mobileData);

    if (dataData) {
      setData(dataData);
      setIsloading(true);
    } else {
      apiCall(WebApi.getDMTSender, { mobile: mobileData }, response => {
        if (response.success === true && response.data) {
          if (response.data.status === true) {
            setData(response.data.data);
            setIsloading(true);
          }
        }
      });
    }
  }, [isloading]);

  return (
    <>
      {isloading && (
        <>
          <BeneficiaryListHeading {...props} mobile={mobile} data={data} />
          <BeneficiaryListTable {...props} mobile={mobile} data={data} />
        </>
      )}
    </>
  );
};
export default BeneficiaryList;
