import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  Popper,
  Select,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import OaFormSelectField from "oahoc/OaFormSelectField";
import OaFormDateField from "oahoc/OaFormDateField";
import dateFnsFormat from "date-fns/format";
import OaFormTextField from "oahoc/OaFormTextField";
import countries from "json/country_dial_codes.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OaFormikErrorAlert from "oahoc/OaFormikErrorAlert";

import _ from "lodash";
import sub from "date-fns/sub";
import * as Yup from "yup";
import { BsBank } from "react-icons/bs";
import OaAlert from "oahoc/OaAlert";

const customPopper = function(props) {
  return <Popper {...props} placement="bottom" style={{}} />;
};

let countryList = countries.slice(1);

const InsurancePaxDetails = (props) => {
  const classes = useStyles();
  const { request, result, session_id } = props.location.state;

  const [expanded, setExpanded] = useState("panel1");
  const [grandTotal, setGrandTotal] = useState(0);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  // const [sameAddress, setSameAddress] = useState({
  //   city: "",
  //   address: "",
  //   country: "",
  //   pinCode: "",
  //   state: "",
  //   pinCode: "",
  // });
  const formikRef = React.useRef(null);
  const initialValues = {
    clientType: "",
    ipAddress: "",
    paymentMode: "",
    sessionId: "",
    sid: "",
    paxCount: request.paxCount,
    travelers: Array(request.paxCount).fill({
      addressLine1: "",
      addressLine2: "",
      beneficiaryName: "",
      beneficiaryLastName: "",
      beneficiaryTitle: "Mr",
      city: "",
      country: "India",
      dob: "16-Jan-1989",
      emailId: "",
      firstName: "",
      gender: "M",
      lastName: "",
      majorDestination: "India",
      passportNo: "",
      phoneNumber: "",
      pinCode: "",
      relationShipToInsured: "",
      relationToBeneficiary: "",
      state: "",
      title: "Mr",
      sameAddress: false,
    }),
  };

  const validationSchema = Yup.object().shape({
    travelers: Yup.array(
      Yup.object({
        addressLine1: Yup.string().required("address is required"),
        beneficiaryName: Yup.string().required("First Name is required"),
        beneficiaryLastName: Yup.string().required("Last Name is required"),
        beneficiaryTitle: Yup.string().required("Title is required"),
        relationShipToInsured: Yup.string().required("Field is required"),
        relationToBeneficiary: Yup.string().required("Field is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        emailId: Yup.string().required("Email Id is required"),
        firstName: Yup.string().required("First Name is required"),
        gender: Yup.string().required("Gender is required"),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber: Yup.string()
          .required("Phone number is required")
          .min(10, "Must Be only 10 Characters")
          .max(10, "Must be only 10 Characters"),
        pinCode: Yup.string()
          .required("Pin code is required")
          .min(5, "Must Be only 6 Characters")
          .max(6, "Max only 6 Characters allowed"),
        state: Yup.string().required("State is required"),
        // majorDestination: Yup.string().required(
        //   "Major Destination is required"
        // ),
      })
    ),
  });

  /*const handleChangee = (panel, event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSubmit = (values, searchParams) => {
    props.history.push({
      pathname: "/insurance/review",
      state: { values, result, request, session_id },
    });
  };
*/

  const validatePaxDetails = (values) => {
    let noErrorFound = true;

    // console.log("values",values);
    let travelers = values.travelers;

    noErrorFound = validateIndividualPaxDetails(
      travelers,
      travelers.length,
      "passenger"
    );

    return noErrorFound;
  };

  const validateStringValues = (
    fieldName,
    valueToCheck,
    stringNumber,
    paxIndex
  ) => {
    let validateField = true;

    //console.log("paxInfo ", fieldName + "," + valueToCheck + "," + stringNumber + "," + paxIndex);

    if (valueToCheck === "") {
      setAlertMsg(
        "Please Enter the " + fieldName + " of the passenger " + paxIndex
      );
      validateField = false;
    }
    if (stringNumber === "Mobile" || stringNumber === "pinCode") {
      if (validateField) {
        // console.log("valueToCheck", !/^[0-9]*$/.test(valueToCheck));
        if (!/^[0-9]*$/.test(valueToCheck)) {
          setAlertMsg(
            fieldName + " should contain Numbers only for passenger " + paxIndex
          );
          validateField = false;
        }
        if (validateField) {
          //  console.log("valueToCheck.length ", valueToCheck.length);
          if (stringNumber === "Mobile") {
            if (valueToCheck.length !== 10) {
              setAlertMsg(
                fieldName +
                  " should be 10 digits Numbers only for passenger " +
                  paxIndex
              );
              validateField = false;
            }
          } else if (stringNumber === "pinCode") {
            if (valueToCheck.length < 5 || valueToCheck.length > 6) {
              setAlertMsg(
                fieldName +
                  " should be 5 or 6 digits Numbers only for passenger " +
                  paxIndex
              );
              validateField = false;
            }
          }
        }
      }
    } else if (stringNumber === "DOB" || stringNumber === "Passport") {
    } else if (stringNumber === "Email") {
      if (validateField) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueToCheck)) {
          setAlertMsg("Invalid " + fieldName + " of passenger " + paxIndex);
          validateField = false;
        }
      }
    } else {
      if (validateField && stringNumber !== "DOB") {
        if (!/^[A-Za-z ]*$/.test(valueToCheck)) {
          setAlertMsg(
            fieldName +
              " should contains Alphabates and space only of the passenger " +
              paxIndex
          );
          validateField = false;
        }
      }
      if (validateField) {
        if (!/^[^\s][A-Za-z ]*$/.test(valueToCheck)) {
          setAlertMsg(
            fieldName + " cant be start with space of the passenger " + paxIndex
          );
          validateField = false;
        }
      }
    }

    return validateField;
  };

  const validateIndividualPaxDetails = (paxInfo, totalPax, paxType) => {
    let allFieldNotOk = true;

    for (let k = 0; k < totalPax; k++) {
      // console.log("values.isDomestic" , paxInfo[k]);

      let paxTitle = paxInfo[k].title;
      let paxFname = paxInfo[k].firstName;
      let paxLname = paxInfo[k].lastName;
      let beneficiaryName = paxInfo[k].beneficiaryName;
      let beneficiaryLastName = paxInfo[k].beneficiaryLastName;
      let dob = paxInfo[k].dob;
      let relationShipToInsured = paxInfo[k].relationShipToInsured;
      let relationToBeneficiary = paxInfo[k].relationToBeneficiary;
      let gender = paxInfo[k].gender;
      let majorDestination = paxInfo[k].majorDestination;
      let passportNo = paxInfo[k].passportNo;
      let country = paxInfo[k].country;
      let addressLine1 = paxInfo[k].addressLine1;
      let city = paxInfo[k].city;
      let pinCode = paxInfo[k].pinCode;
      let state = paxInfo[k].state;
      let phoneNumber = paxInfo[k].phoneNumber;
      let emailId = paxInfo[k].emailId;

      //  console.log("paxInfo ", paxInfo[k]);

      allFieldNotOk = validateStringValues(
        "First Name",
        paxFname,
        "String",
        k + 1
      );

      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Last Name",
          paxLname,
          "String",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Beneficiary FirstName",
          beneficiaryName,
          "String",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Beneficiary LastName",
          beneficiaryLastName,
          "String",
          k + 1
        );
      }

      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "RelationShip To Insured",
          relationShipToInsured,
          "String",
          k + 1
        );
      }

      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Relation To Beneficiary",
          relationToBeneficiary,
          "String",
          k + 1
        );
      }

      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Date Of Birth",
          dob,
          "DOB",
          k + 1
        );
      }

      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Major Destination",
          majorDestination,
          "String",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "passportNo",
          passportNo,
          "Passport",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "country",
          country,
          "String",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Address",
          addressLine1,
          "String",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues("City", city, "String", k + 1);
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "pinCode",
          pinCode,
          "pinCode",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues("state", state, "String", k + 1);
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "Mobile No",
          phoneNumber,
          "Mobile",
          k + 1
        );
      }
      if (allFieldNotOk) {
        allFieldNotOk = validateStringValues(
          "emailId",
          emailId,
          "Email",
          k + 1
        );
      }

      if (!allFieldNotOk) {
        break;
      }
    }
    return allFieldNotOk;
  };

  const handleChangee = (panel, event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSubmit = (values, searchParams) => {
    if (validatePaxDetails(values)) {
      props.history.push({
        pathname: "/insurance/review",
        state: { values, result, request, session_id },
      });
    } else {
      setAlertTitle("Fields Mandatory");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
    }
  };

  // console.log("ggg",sameAddress);
  // console.log(isTrue?sameAddress.country:"");

  const toggleSameAddress = (e, ind) => {
    console.log("same address", e.target.checked);
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        `travelers[${ind}].sameAddress`,
        e.target.checked
      );
      if (e.target.checked) {
        formikRef.current.setFieldValue(
          `travelers[${ind}].addressLine1`,
          formikRef.current.values.travelers[0].addressLine1
        );
        formikRef.current.setFieldValue(
          `travelers[${ind}].city`,
          formikRef.current.values.travelers[0].city
        );
        formikRef.current.setFieldValue(
          `travelers[${ind}].country`,
          formikRef.current.values.travelers[0].country
        );
        formikRef.current.setFieldValue(
          `travelers[${ind}].pinCode`,
          formikRef.current.values.travelers[0].pinCode
        );
        formikRef.current.setFieldValue(
          `travelers[${ind}].state`,
          formikRef.current.values.travelers[0].state
        );
        // formikRef.current.setFieldValue(
        //   `travelers[${ind}].phoneNumber`,
        //   formikRef.current.values.travelers[0].phoneNumber
        // );
        // formikRef.current.setFieldValue(
        //   `travelers[${ind}].emailId`,
        //   formikRef.current.values.travelers[0].emailId
        // );
      } else {
        formikRef.current.setFieldValue(`travelers[${ind}].addressLine1`, "");
        formikRef.current.setFieldValue(`travelers[${ind}].city`, "");
        formikRef.current.setFieldValue(`travelers[${ind}].country`, "");
        formikRef.current.setFieldValue(`travelers[${ind}].pinCode`, "");
        formikRef.current.setFieldValue(`travelers[${ind}].state`, "");
        // formikRef.current.setFieldValue(`travelers[${ind}].phoneNumber`, "");
        // formikRef.current.setFieldValue(`travelers[${ind}].emailId`, "");
      }
    }
  };

  useEffect(() => {
    setGrandTotal(result.premuimList[0]?.commissionCharge?.grossProductPrice);
  }, []);

  return (
    <>
      {isError && (
        <Grid item md={12} xs={12}>
          <OaAlert
            isPrompt={isAlertPrompt}
            msg={alertMsg}
            title={alertTitle}
            isError={isError}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            togglePrompt={() => {
              setIsAlertPrompt(!isAlertPrompt);
            }}
          ></OaAlert>
        </Grid>
      )}
      <div className={classes.root}>
        {/* <OaAlert
        //callback={transactionHelper}
        isPrompt={isAlertPrompt}
        //msg={isError}
        //title={infoText}
        msg={alertMsg}
        title={alertTitle}
        isError={true}
        // primaryButtonText={primaryButtonText}
        // secondaryButtonText={secondaryButtonText}
        togglePrompt={() => {
          setIsAlertPrompt(!isAlertPrompt);
        }}
      ></OaAlert> */}

        <Grid className="detailForm">
          <Grid className="plan">
            <Grid className="plansUpper">
              <Grid className="planNameContainer">
                <Box className="planTitle">{result.planName}</Box>
                <Box>India</Box>
              </Grid>
              <Box className="plansDateContainer">
                <Grid className="planDates">
                  <Box style={{ marginRight: 10 }} className="startDate">
                    Start:{" "}
                    {request.travelStartDate
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}
                  </Box>
                  <Box className="endDate">
                    End:{" "}
                    {request.travelEndDate
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid className="plansLower">
              <Grid className="planDetails">
                <Box
                  onClick={() => {
                    props.history.push("/insurances");
                  }}
                  style={{ marginRight: 10 }}
                >
                  Choose another plan
                </Box>
              </Grid>
              <Box>No of Pax:- {request.paxCount}</Box>
            </Grid>
          </Grid>
          <Formik
            initialValues={initialValues}
            //validationSchema={validationSchema}
            onSubmit={(values, searchParams) => {
              let isDuplicateName =
                new Set(values.travelers.map((item) => item["firstName"]))
                  .size !== values.travelers.length;
              if (isDuplicateName) {
                setAlertMsg("Same First name can't be use again");
                setAlertTitle("Duplicate Name");
                setIsAlertPrompt(true);
                return;
              }
              handleSubmit(values, searchParams);
            }}
            innerRef={formikRef}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form className="detailForm__form">
                {/* {console.log("ajsgda", values)} */}

                <FieldArray
                  name="travelers"
                  render={(arrayHelpers) => (
                    <Grid container className="detailForm__container">
                      <Box className="detailForm__passengers">
                        Passengers Details
                      </Box>
                      {values.travelers.map((val, ind) => (
                        <Accordion
                          expanded={expanded === `panel${ind + 1}`}
                          onChange={(event, newExpanded) =>
                            handleChangee(`panel${ind + 1}`, event, newExpanded)
                          }
                          key={ind}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Box className="detailForm__passengerHeader">
                              <Box>Passenger {ind + 1}</Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container className="detailForm__passenger">
                              <Grid className="detailForm__passengerContainer">
                                <Grid
                                  container
                                  className="detailForm__subcontainer"
                                >
                                  {/* INSURED NAME */}
                                  {/* <div>Hello</div> */}
                                  <Box className="detailForm__field">
                                    <label>Insured Name</label>
                                    <div className="pax-name-group">
                                      <div className="detailForm__field pax-title-input">
                                        <OaFormTextField
                                          defaultValue={
                                            values.travelers[0].title
                                          }
                                          select
                                          fullWidth
                                          // label="Title"
                                          name={`travelers[${ind}].title`}
                                          onChange={handleChange}
                                          variant="outlined"
                                        >
                                          <MenuItem value="Mr">Mr</MenuItem>
                                          <MenuItem value="Mrs">Mrs</MenuItem>
                                          <MenuItem value="Miss">Miss</MenuItem>
                                          <MenuItem value="Ms">Ms</MenuItem>
                                        </OaFormTextField>
                                        <ErrorMessage
                                          name={`travelers[${ind}].title`}
                                          component="div"
                                          className="error"
                                        />
                                        {/* <OaFormikErrorAlert
                                        name={`travelers[${ind}].title`}
                                        error={props.error}
                                        touched={props.touched}
                                      /> */}
                                      </div>

                                      <div className="detailForm__field pax-name-input first-name">
                                        <TextField
                                          fullWidth
                                          defaultValue={
                                            values.travelers[0].firstName
                                          }
                                          // label="First Name"
                                          name={`travelers[${ind}].firstName`}
                                          onChange={handleChange}
                                          variant="outlined"
                                          // className={classes.inputLabel}
                                        />
                                        <ErrorMessage
                                          name={`travelers[${ind}].firstName`}
                                          component="div"
                                          className="error"
                                        />
                                        {/* <OaFormikErrorAlert
                                        name={`travelers[${ind}].firstName`}
                                        error={props.error}
                                        touched={props.touched}
                                      /> */}
                                      </div>

                                      <div className="detailForm__field pax-name-input last-name">
                                        <TextField
                                          fullWidth
                                          defaultValue={
                                            values.travelers[0].lastName
                                          }
                                          // label="Last Name"
                                          name={`travelers[${ind}].lastName`}
                                          onChange={handleChange}
                                          variant="outlined"
                                          className={classes.inputLabel}
                                        />
                                        <ErrorMessage
                                          name={`travelers[${ind}].lastName`}
                                          component="div"
                                          className="error"
                                        />
                                        {/* <OaFormikErrorAlert
                                        name={`travelers[${ind}].lastName`}
                                        error={props.error}
                                        touched={props.touched}
                                      /> */}
                                      </div>
                                    </div>
                                  </Box>

                                  {/* RELAIONN TO INSURED */}

                                  <Box className="detailForm__field detailForm__field--select">
                                    <label>Relation to Insured</label>
                                    <TextField
                                      defaultValue={
                                        values.travelers[0]
                                          .relationShipToInsured
                                      }
                                      select
                                      fullWidth
                                      name={`travelers[${ind}].relationShipToInsured`}
                                      onChange={handleChange}
                                      variant="outlined"
                                    >
                                      <MenuItem selected value="brother">
                                        Brother
                                      </MenuItem>
                                      <MenuItem value="child">Child</MenuItem>
                                      <MenuItem value="employer">
                                        Employer
                                      </MenuItem>
                                      <MenuItem value="father">Father</MenuItem>
                                      <MenuItem value="legal guardian">
                                        Legal Guardian
                                      </MenuItem>
                                      <MenuItem value="mother">Mother</MenuItem>
                                      <MenuItem value="sister">Sister</MenuItem>
                                      <MenuItem value="spouse">Spouse</MenuItem>
                                      <MenuItem value="spouse">Self</MenuItem>
                                    </TextField>
                                    <ErrorMessage
                                      name={`travelers[${ind}].relationShipToInsured`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].relationShipToInsured`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Box>

                                  {/* DOB */}
                                  <Grid className="detailForm__field detailForm__field--date">
                                    <label>Date of Birth</label>
                                    <OaFormDateField
                                      value={values.travelers[0].dob}
                                      selected={values.travelers[0].dob}
                                      onChange={(datee) => {
                                        var event = new Date(datee);
                                        let date = event
                                          .toLocaleDateString("en-GB", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                          })
                                          .replace(/ /g, "-");

                                        setFieldValue(
                                          `travelers[${ind}].dob`,
                                          date
                                        );
                                      }}
                                      name={`travelers[${ind}].dob`}
                                      maxDate={sub(new Date(), { years: 1 })}
                                      // style={{ marginTop: "2px !important" }}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].dob`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].dob`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* SAME ADDRESS */}

                                  <Grid className="detailForm__field">
                                    {ind > 0 && (
                                      <FormControl
                                        component="fieldset"
                                        className={classes.formControl}
                                      >
                                        <FormGroup>
                                          <FormControlLabel
                                            style={{
                                              margin: 0,
                                              textAlign: "unset",
                                            }}
                                            label={
                                              <Typography
                                                style={{
                                                  color: "#000",
                                                  margin: 0,
                                                }}
                                              >
                                                Check if same address
                                              </Typography>
                                            }
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.travelers[ind]
                                                    .sameAddress
                                                }
                                                onChange={(e) =>
                                                  toggleSameAddress(e, ind)
                                                }
                                                name={`travelers[${ind}].sameAddress`}
                                              />
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                  </Grid>

                                  {/* MAJOR DESINTATION */}
                                  <Grid className="detailForm__field">
                                    <label>Major Destination *</label>
                                    <Autocomplete
                                      fullWidth
                                      name={`travelers[${ind}].majorDestination`}
                                      PopperComponent={customPopper}
                                      options={countryList || []}
                                      getOptionLabel={(o) =>
                                        o ? `${o.name} (${o.code})` : ""
                                      }
                                      defaultValue={
                                        countryList.filter((ctry) => {
                                          return ctry.code === "IN";
                                        })[0]
                                      }
                                      getOptionSelected={(option, value) =>
                                        option.code == value.country
                                      }
                                      onChange={(event, newValue) => {
                                        // console.log("countries", newValue);
                                        if (newValue != null) {
                                          setFieldValue(
                                            `travelers[${ind}].majorDestination`,
                                            newValue.code
                                          );
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          color="secondary"
                                          variant="outlined"
                                        />
                                      )}
                                      disablePortal={true}
                                    />
                                  </Grid>

                                  <Grid className="detailForm__field">
                                    <label>Country: *</label>
                                    <TextField
                                      value={values.travelers[ind].country}
                                      fullWidth
                                      variant="outlined"
                                      name={`travelers[${ind}].country`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `travelers[${ind}].country`,
                                          e.target.value
                                        );
                                        handleChange(e);
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].country`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].country`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* CITY */}
                                  <Grid className="detailForm__field">
                                    <label>City: </label>
                                    <TextField
                                      fullWidth
                                      value={values.travelers[ind].city || ""}
                                      name={`travelers[${ind}].city`}
                                      // onChange={handleChange}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(
                                          `travelers[${ind}].city`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].city`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].city`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* STATE */}
                                  <Grid item className="detailForm__field">
                                    <label>State*</label>
                                    <TextField
                                      value={values.travelers[ind].state}
                                      fullWidth
                                      name={`travelers[${ind}].state`}
                                      // onChange={handleChange}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(
                                          `travelers[${ind}].state`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].state`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].state`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  className="detailForm__subcontainer beneficiary-box"
                                >
                                  {/* BENEFICIRARY NAME */}
                                  <Grid>
                                    <Box className="detailForm__field">
                                      <label> Beneficiary Name</label>
                                      <div className="pax-name-group">
                                        <div className="detailForm__field pax-title-input">
                                          <OaFormTextField
                                            defaultValue={
                                              values.travelers[0]
                                                .beneficiaryTitle
                                            }
                                            select
                                            fullWidth
                                            // label="Title"
                                            name={`travelers[${ind}].beneficiaryTitle`}
                                            onChange={handleChange}
                                            variant="outlined"
                                          >
                                            <MenuItem value="Mr">Mr</MenuItem>
                                            <MenuItem value="Mrs">Mrs</MenuItem>
                                            <MenuItem value="Miss">
                                              Miss
                                            </MenuItem>
                                            <MenuItem value="Ms">Ms</MenuItem>
                                          </OaFormTextField>
                                          <ErrorMessage
                                            name={`travelers[${ind}].beneficiaryTitle`}
                                            component="div"
                                            className="error"
                                          />
                                          {/* <OaFormikErrorAlert
                                          name={`travelers[${ind}].beneficiaryTitle`}
                                          error={props.error}
                                          touched={props.touched}
                                        /> */}
                                        </div>

                                        <div className="detailForm__field pax-name-input first-name">
                                          <TextField
                                            name={`travelers[${ind}].beneficiaryName`}
                                            defaultValue={
                                              values.travelers[0]
                                                .beneficiaryName
                                            }
                                            // label="First Name"
                                            onChange={handleChange}
                                            variant="outlined"
                                          />
                                          <ErrorMessage
                                            name={`travelers[${ind}].beneficiaryName`}
                                            component="div"
                                            className="error"
                                          />
                                          {/* <OaFormikErrorAlert
                                          name={`travelers[${ind}].beneficiaryName`}
                                          error={props.error}
                                          touched={props.touched}
                                        /> */}
                                        </div>

                                        <div className="detailForm__field pax-name-input last-name">
                                          <TextField
                                            defaultValue={
                                              values.travelers[0]
                                                .beneficiaryLastName
                                            }
                                            name={`travelers[${ind}].beneficiaryLastName`}
                                            // label="Last Name"
                                            onChange={handleChange}
                                            variant="outlined"
                                          />

                                          <ErrorMessage
                                            name={`travelers[${ind}].beneficiaryLastName`}
                                            component="div"
                                            className="error"
                                          />
                                          {/* <OaFormikErrorAlert
                                          name={`travelers[${ind}].beneficiaryLastName`}
                                          error={props.error}
                                          touched={props.touched}
                                        /> */}
                                        </div>
                                      </div>
                                    </Box>
                                  </Grid>

                                  <Box className="detailForm__field detailForm__field--select">
                                    <label>Relation to Beneficiary</label>
                                    <TextField
                                      defaultValue={
                                        values.travelers[0]
                                          .relationToBeneficiary
                                      }
                                      select
                                      fullWidth
                                      name={`travelers[${ind}].relationToBeneficiary`}
                                      onChange={handleChange}
                                      variant="outlined"
                                    >
                                      <MenuItem selected value="brother">
                                        Brother
                                      </MenuItem>
                                      <MenuItem value="child">Child</MenuItem>
                                      <MenuItem value="employer">
                                        Employer
                                      </MenuItem>
                                      <MenuItem value="father">Father</MenuItem>
                                      <MenuItem value="legal guardian">
                                        Legal Guardian
                                      </MenuItem>
                                      <MenuItem value="mother">Mother</MenuItem>
                                      <MenuItem value="sister">Sister</MenuItem>
                                      <MenuItem value="spouse">Spouse</MenuItem>
                                      {/* <MenuItem value="spouse">Self</MenuItem> */}
                                    </TextField>
                                    <ErrorMessage
                                      name={`travelers[${ind}].relationToBeneficiary`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].relationToBeneficiary`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Box>

                                  {/* GENDER */}
                                  <Grid item className="detailForm__field">
                                    <label>Gender</label>
                                    <TextField
                                      fullWidth
                                      select
                                      defaultValue={values.travelers[0].gender}
                                      name={`travelers[${ind}].gender`}
                                      onChange={handleChange}
                                      variant="outlined"
                                    >
                                      <MenuItem value="0" disabled>
                                        Not Specified
                                      </MenuItem>
                                      <MenuItem value="M">Male</MenuItem>
                                      <MenuItem value="F">Female</MenuItem>
                                      <MenuItem value="T">Transgender</MenuItem>
                                    </TextField>
                                  </Grid>

                                  {/* PASSPORT */}
                                  <Grid item className="detailForm__field">
                                    <label>Passport No</label>
                                    <TextField
                                      fullWidth
                                      // style={{ width: "100%", border: '1px solid gray', padding: '5px' }}
                                      variant="outlined"
                                      onChange={handleChange}
                                      defaultValue={
                                        values.travelers[0].passportNo
                                      }
                                      // component={TextField}

                                      // className="passenger-field"
                                      name={`travelers[${ind}].passportNo`}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].passportNo`}
                                      className="error"
                                      component="div"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].passportNo`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* ADDRESS */}
                                  <Grid item className="detailForm__field">
                                    <label>Address</label>
                                    <TextField
                                      fullWidth
                                      // onBlur={handleChange}
                                      // style={{ width: "100%", border: '1px solid gray', padding: '5px' }}
                                      variant="outlined"
                                      value={values.travelers[ind].addressLine1}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `travelers[${ind}].addressLine1`,
                                          e.target.value
                                        );
                                        handleChange(e);
                                      }}
                                      name={`travelers[${ind}].addressLine1`}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].addressLine1`}
                                      className="error"
                                      component="div"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].addressLine1`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* PIN CODE */}
                                  <Grid item className="detailForm__field">
                                    <label>PinCode</label>
                                    <TextField
                                      value={values.travelers[ind].pinCode}
                                      // defaultValue={values.travelers[0].pinCode}
                                      name={`travelers[${ind}].pinCode`}
                                      // onChange={handleChange}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(
                                          `travelers[${ind}].pinCode`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].pinCode`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].pinCode`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* MOBILE */}
                                  <Grid item className="detailForm__field">
                                    <label>Mobile No*</label>
                                    <TextField
                                      fullWidth
                                      defaultValue={
                                        values.travelers[0].phoneNumber
                                      }
                                      name={`travelers[${ind}].phoneNumber`}
                                      // className={classes.inputLabel}
                                      //onChange={handleChange}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(
                                          `travelers[${ind}].phoneNumber`,
                                          e.target.value
                                        );
                                      }}
                                      variant="outlined"
                                      // style={{ width:"100%"}}
                                    />

                                    <ErrorMessage
                                      name={`travelers[${ind}].phoneNumber`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].phoneNumber`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>

                                  {/* EMAIL */}
                                  <Grid item className="detailForm__field">
                                    <label>Email ID</label>
                                    <TextField
                                      variant="outlined"
                                      defaultValue={values.travelers[0].emailId}
                                      name={`travelers[${ind}].emailId`}
                                      //onChange={handleChange}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(
                                          `travelers[${ind}].emailId`,
                                          e.target.value
                                        );
                                      }}
                                      type="email"
                                      fullWidth
                                      style={{ width: "100%" }}
                                    />
                                    <ErrorMessage
                                      name={`travelers[${ind}].emailId`}
                                      component="div"
                                      className="error"
                                    />
                                    {/* <OaFormikErrorAlert
                                    name={`travelers[${ind}].emailId`}
                                    error={props.error}
                                    touched={props.touched}
                                  /> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                      {/* <Button
                      className="detailForm__addBtn"
                      onClick={() => {
                        arrayHelpers.push({
                          addressLine1: "",
                          addressLine2: "",
                          beneficiaryName: "",
                          city: "",
                          country: "",
                          dob: new Date(),
                          emailId: "",
                          firstName: "",
                          gender: "",
                          lastName: "",
                          majorDestination: "",
                          passportNo: "",
                          phoneNumber: "",
                          pinCode: "",
                          relationShipToInsured: "",
                          relationToBeneficiary: "",
                          state: "",
                          title: "",
                        });
                      }}
                    >
                      Add Pax
                    </Button> */}
                      {/* PAYMENT MODE */}
                      {/* <Grid item md={4}>
                      <h4>Payment Mode</h4>
                      <Select name="paymentMode" value={values.paymentMode}>
                        <MenuItem value="online">wallet</MenuItem>
                        <MenuItem value="wallet">
                          Debit card/credit card
                        </MenuItem>
                        <MenuItem value="wallet">Netbanking</MenuItem>
                      </Select>
                    </Grid> */}
                    </Grid>
                  )}
                />
                <Grid className="detailForm__submitBtn">
                  <Button className="" type="submit" variant="contained">
                    Proceed to booking review{" "}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid className="detailTotal">
          <Box className="detailTotal__header">Price</Box>
          <Box className="detailTotal__ageDetails">
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Age Group</Box>
              <Box className="detailTotal__price">Price</Box>
            </Box>
            {/* <Box className="detailTotal__subTotal">
            <Box className="detailTotal__label">
              {" "}
              {result.premuimList[0].minAge} - {result.premuimList[0].maxAge}{" "}
              Years
            </Box>
            <Box className="detailTotal__price">
              {" "}
              ₹{result.premuimList[0].commissionCharge.grossProductPrice}
            </Box>
          </Box> */}
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">
                {result.premuimList[0].minAge} - {result.premuimList[0].maxAge}{" "}
                Years x ({request.paxCount})
              </Box>
              <Box className="detailTotal__price">
                ₹{result.premuimList[0].commissionCharge.grossProductPrice}
              </Box>
            </Box>
          </Box>
          <Box className="detailTotal__subTotals">
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Total</Box>
              <Box className="detailTotal__price">
                ₹
                {(
                  result.premuimList[0].commissionCharge.grossProductPrice -
                  (result.premuimList[0].commissionCharge.agentIncome +
                    result.premuimList[0].commissionCharge.agentMarkup)
                ).toFixed(2)}
              </Box>
            </Box>
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Commission</Box>
              <Box className="detailTotal__price">
                ₹
                {result.premuimList[0].commissionCharge.agentIncome +
                  result.premuimList[0].commissionCharge.agentMarkup}
              </Box>
            </Box>
            <Box className="detailTotal__subTotal">
              <Box className="detailTotal__label">Tds</Box>
              <Box className="detailTotal__price">
                {" "}
                ₹{result.premuimList[0].commissionCharge.agentTDSOnIncome}
              </Box>
            </Box>
          </Box>
          <Box className="detailTotal__grandTotal">
            <Box className="detailTotal__label">GrandTotal</Box>
            <Box className="detailTotal__price">INR {grandTotal}</Box>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default InsurancePaxDetails;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // flexWrap:'wrap',
    maxWidth: "1236px",
    margin: "0px auto",
    [theme.breakpoints.down(760)]: {
      flexWrap: "wrap",
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.background.light,
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
      backgroundColor: "white",
    },
    "&. total-payable": {
      background: theme.palette.background.disabled,
      borderBottom: `none`,
      "& p": {
        color: theme.palette.background.disabledContrast,
      },
    },
    "& .detailForm": {
      width: "75%",
      [theme.breakpoints.down(760)]: {
        width: "100%",
      },
      "& .plan": {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "#f9f9f9",
        // borderRadius: 20,
        padding: 20,
        marginBottom: 5,
      },
      "& .planNameContainer": {
        [theme.breakpoints.down(600)]: {
          width: "50%",
        },
      },
      "& .plansDateContainer": {
        [theme.breakpoints.down(600)]: {
          width: "50%",
        },
      },
      "& .plansUpper": {
        display: "flex",
        marginBottom: 10,
        justifyContent: "space-between",
        "& .planTitle": {
          fontSize: 20,
          fontWeight: 700,
          color: theme.palette.primary.main,
          [theme.breakpoints.down(500)]: {
            // width: "50%",
          },
        },
        "& .planDates": {
          display: "flex",
          fontSize: 14,
          fontWeight: 500,
          [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            width: "100%",
            textAlign: "end",
          },
          // textDecoration: "underline",
          "& .startDate": {
            marginRight: 10,
            [theme.breakpoints.down(600)]: {
              marginRight: "0px !important",
            },
          },
        },
      },
      "& .plansLower": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 500,

        "& .planDetails": {
          display: "flex",
          textDecoration: "underline",
          cursor: "pointer",
        },
        "& .submit": {
          height: 30,
          width: 120,
          fontSize: 12,
          fontWeight: 500,
          backgroundColor: theme.palette.primary.main,
        },
      },
      "& .detailForm__container": {
        display: "flex",
        border: "1px solid #e0e0e0",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
        "& .MuiAccordionSummary-expandIcon": {
          // backgroundColor: theme.palette.primary.main,
          // borderRadius: 0,
        },
        "& .MuiIconButton-root": {
          padding: 8.5,
          position: "absolute",
          right: 20,
        },

        "& .detailForm__passengerHeader": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // backgroundColor: theme.palette.primary.main,
          padding: "5px 15px",
          borderBottom: "2px solid rgba(0,0,0,0.125)",
          boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
          borderTop: "2px solid rgba(0,0,0,0.125)",
          width: "100%",
          padding: "10px 20px",
          fontSize: 20,
          fontWeight: 500,
          color: theme.palette.text.secondary,
        },
        "& .detailForm__passengers": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          width: "100%",
          padding: "20px 20px",
          marginBottom: 10,
          fontSize: 20,
          fontWeight: 500,
        },
        "& .MuiAccordion-root": {
          width: "100%",
          margin: 0,
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "5px 0px",
          },

          "& .MuiAccordionSummary-root": {
            padding: 0,
            height: 50,
          },
          "& .MuiAccordionDetails-root": {
            padding: 0,
          },
        },
        "& .detailForm__passenger": {
          // margin: "5px 5px",
          margin: 20,
          borderRadius: 5,
          backgroundColor: theme.palette.background.overlay,
        },
        "& .detailForm__addBtn": {
          margin: "10px 20px",
          width: 100,
          backgroundColor: theme.palette.primary.main,
        },
        "& .detailForm__removeBtn": {
          // margin: "10px 20px",
          backgroundColor: theme.palette.background.lightFadedGray,
        },
      },
      "& .detailForm__submitBtn": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 10,

        "& button": {
          background: theme.palette.buttons.bookNowBtn,
          color: theme.palette.primary.lightText,
        },
      },

      "& .detailForm__passengerContainer": {
        width: "100%",
        display: "flex",
        // border: "1px solid #e0e0e0",
        padding: "10px 2px",
        [theme.breakpoints.down(768)]: {
          flexDirection: "column",
        },

        "& .beneficiary-box": {
          [theme.breakpoints.down(768)]: {
            marginTop: 23,
          },
        },
      },
      "& .detailForm__subcontainer": {
        width: "50%",
        [theme.breakpoints.down(768)]: {
          width: "100%",
        },

        display: "flex",
        flexDirection: "column",
        padding: "0px 20px",

        "& .detailForm__field": {
          marginBottom: 10,
          display: "flex",
          flexDirection: "column",
        },
        "& .pax-name-group": {
          marginTop: 5,
          gap: 5,
          display: "flex",
          [theme.breakpoints.down(768)]: {
            flexWrap: "wrap",
          },
        },
        "& .pax-title-input": {
          flexGrow: 1,
          "& .MuiFormControl-root": {
            marginTop: 0,
          },
        },
        "& .pax-name-input": {
          width: "46%",
          minWidth: 80,
          "& .MuiFormControl-root": { marginTop: 0 },
          [theme.breakpoints.down(760)]: {
            minWidth: "100%",
          },
        },
        "& .detailForm__field--select": {
          "& .MuiOutlinedInput-root": { marginTop: 5 },
        },
        "& .detailForm__field--date": {
          "& .MuiOutlinedInput-root": { marginTop: 0 },
          "& .MuiFormControl-root": { marginTop: 3 },
        },
      },
    },
    "& .detailTotal": {
      marginLeft: 20,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      [theme.breakpoints.down(760)]: {
        width: "100%",
        marginTop: 16,
        marginLeft: 0,

        // marginBottom: 25,
      },
      //   backgroundColor: "#434",
      width: "25%",
      height: "100%",
      border: "1px solid #e0e0e0",
      "& .detailTotal__header": {
        fontSize: 20,
        fontWeight: 500,
        // backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        borderBottom: "2px solid rgba(0,0,0,0.125)",
        // borderTop: "2px solid rgba(0,0,0,0.125)",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
        padding: 10,
      },
      "& .detailTotal__label": {
        fontSize: 16,
        fontWeight: 500,
      },
      "& .detailTotal__price": {
        fontSize: 16,
        fontWeight: 500,
      },
      "& .detailTotal__subTotals": {
        padding: "5px 5px",
        backgroundColor: "#f9f9f9",
      },
      "& .detailTotal__ageDetails": {
        padding: "5px 5px",
        backgroundColor: "#fff",
      },
      "& .detailTotal__subTotal": {
        fontSize: 14,
        display: "flex",
        marginTop: 5,
        justifyContent: "space-between",
      },
      "& .detailTotal__subTotal": {
        fontSize: 14,
        display: "flex",
        marginTop: 5,
        justifyContent: "space-between",
      },
      "& .detailTotal__grandTotal": {
        fontSize: 16,
        fontWeight: 500,
        display: "flex",
        padding: 5,
        justifyContent: "space-between",
        backgroundColor: "#e0e0e0",
      },
    },
  },
}));
