import React from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PaymentOptions = (props) => {
  const [walletCriteria, setWalletCriteria] = React.useState(false);
  const [odExpired, setOdExpired] = React.useState(false);
  // console.log(props.selectedpaymentmethod, "PAYMENTAB VALUE");

  const [balanceStr, setBalanceStr] = React.useState(false);

  React.useEffect(() => {
    //    console.log("props.data",props.data)
    if (props.data) {
      if (
        props.data?.isBaseCreditSet &&
        new Date() < new Date(props.data?.creditValidity)
      ) {
        if (props.data.availableCreditAmount >= props.grandtotal) {
          setWalletCriteria(true);
          setBalanceStr(
            "Current Balance is : " +
              props.data.balance +
              ", Available OD : " +
              props.data.availableCreditAmount
          );
        } else {
          setWalletCriteria(props.data.balance >= props.grandtotal);
          setBalanceStr("Current Balance is : " + props.data.balance);
        }
      } else {
        setWalletCriteria(props.data.balance >= props.grandtotal);
        setBalanceStr("Current Balance is : " + props.data.balance);
      }
      setOdExpired(new Date() > new Date(props.data?.creditValidity));
    }
  }, [props.data]);

  return (
    <div
      style={{ width: "100%", display: "flex" }}
      className="tabbed-div-container"
    >
      <div className="tab-container">
        <Tabs
          value={props.paymenttab}
          onChange={props.switchpaymenttabs}
          aria-label="payment tabs"
          className="tab-div"
          orientation="vertical"
          variant="scrollable"
        >
          {props.paymentmodes &&
            props.paymentmodes.map((value, index) => (
              <Tab
                label={value.methodLabel}
                {...a11yProps(index)}
                key={index}
              />
            ))}
        </Tabs>
      </div>

      <div className="tab-panel-container">
        {props.paymentmodes &&
          props.paymentmodes.map((payMode, payModeIdx) => (
            <TabPanel
              value={props.paymenttab}
              index={payModeIdx}
              className="tab-content-div"
              key={payModeIdx}
            >
              {payMode.method === "cash" ? (
                <>
                  <Grid item md={12} key={payModeIdx}>
                    {!props.isloading && walletCriteria ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="method"
                          name="method"
                          value={props.selectedpaymentmethod}
                          onChange={(event) =>
                            props.setselectedpaymentmethod(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value="cashPayment"
                            control={<Radio />}
                            label={`Use Wallet(${balanceStr})`}
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <div
                        onClick={() => {
                          if (!props.isloading) props.setisloading(true);
                        }}
                        style={{ display: "flex", cursor: "pointer" }}
                      >
                        <span className="payment-info-title">
                          {" "}
                          Insufficient Fund (Current Cash Balance is{" "}
                          {props.data.balance ? props.data.balance : 0})
                        </span>
                        <AutorenewIcon />
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                payMode.gateway &&
                payMode.gateway.length > 0 && (
                  <>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="method"
                        name="method"
                        value={props.selectedpaymentmethod}
                        onChange={(event) => {
                          console.log(event.target.value);
                          props.setselectedpaymentmethod(event.target.value);
                        }}
                      >
                        {payMode.gateway.map((gateway, gateIdx) => (
                          <FormControlLabel
                            value={gateway.method}
                            control={<Radio />}
                            label={
                              gateway.methodOwnChargeHandle
                                ? `${gateway.method_label}`
                                : `${
                                    gateway.method_label
                                  } payment gateway charges ${
                                    gateway.charge_type === "A" ? " Rs." : ""
                                  }${gateway.charge}${
                                    gateway.charge_type === "A" ? " Only" : "%"
                                  }, excluding GST ${
                                    gateway.gstRate
                                  }% on PG charge`
                            }
                            key={gateIdx}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </>
                )
              )}
            </TabPanel>
          ))}
      </div>
    </div>
  );
};

export default PaymentOptions;
