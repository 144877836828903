import React from "react";
import dateFnsFormat from "date-fns/format";
import OaSwitchInput from "oahoc/OaSwitchInput";
import CardBody from "components/Card/CardBody";
import CustomDatePicker from "oahoc/CustomDatePicker";
import WebApi from "api/ApiConstants";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { SwapHoriz } from "@material-ui/icons";
import {
  Box,
  Grid,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Popper,
  ListItemText,
  Checkbox,
  FormHelperText,
  Typography,
  IconButton,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import {
  setDomesticCarriers,
  setInternationalCarriers,
  setAirports,
} from "redux/action";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";

import { allAirlines, lccAirlines, gdsAirlines } from "api/ApiConstants";

const FlightModifyForm = (propes) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const propState = propes?.history?.location?.state;
  const [tripType, setTripType] = React.useState("ONE");
  const [travelers, setTravelers] = React.useState({
    child: propState?.requestObj?.childPax,
    adult: propState?.requestObj?.adultPax,
    infant: propState?.requestObj?.infantPax,
  });
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedAirLine, setSelectedAirLine] = React.useState("");
  const [dropdownTraveler, setDropdownTraveler] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(
    new Date(propState?.requestObj?.returnDate)
  );
  const [isFetching, setIsFetching] = React.useState(false);
  const [tripMode, setTripMode] = React.useState("ONE");
  const [airportList, setAirportList] = React.useState(popularCities);
  const departAutocompleteEl = React.useRef(null);
  const arriveAutocompleteEl = React.useRef(null);

  const [fromCountry, setFromCountry] = React.useState("");
  const [toCountry, setToCountry] = React.useState("");

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [carrier, setCarrier] = React.useState(propes.domCarriers);
  const [userType, setUserType] = React.useState(null);
  const [corporateType, setCorporateType] = React.useState(null);
  const [airlineSelectOpen, setAirlineSelectOpen] = React.useState(false);
  const [clientType, setClientType] = React.useState(null);
  const [partnerId, setPartnerId] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const [fareType, setFareType] = React.useState(
    propState?.requestObj?.fareType
  );

  const formikRef = React.useRef();
  const ArrayHelperRef = React.useRef();

  const calenderMonth = isMobileScreen ? 1 : 2;
  const getAirlineData = () => {
    if (
      propes.domCarriers === undefined ||
      propes.intlCarriers === undefined ||
      propes.airports === undefined
    ) {
      apiCall(WebApi.getAirports, {}, (response) => {
        if (response.success === true) {
          propes.setAirports(response.data.airports);
          propes.setDomesticCarriers(response.data.domCarriers);
          propes.setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      });
    }
  };

  const fetchAirports = (searchParams) => {
    if (searchParams.length >= 3) {
      setIsFetching(true);
      apiCall(
        WebApi.fetchAirportList,
        {
          airportCode: searchParams,
        },
        (response) => {
          // console.log("response", response);
          setIsFetching(false);
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        }
      );
    }
  };

  const IconButtonStyle = {
    padding: "1px 6px",
    fontSize: 14,
    background: theme.palette.primary.gainsboro,
    color: theme.palette.primary.defaultText,
    margin: "0px 3px",
  };

  const handleTripTypeChange = (event) => {
    // console.log("tripType", event.target.value);
    setTripType(event.target.value);
  };

  const addRemoveTravelerHandler = (person, value) => {
    switch (person) {
      case "child": {
        return setTravelers((prev) => ({
          ...travelers,
          child: prev.child + value,
        }));
      }
      case "adult": {
        return setTravelers((prev) => ({
          ...travelers,
          adult: prev.adult + value,
        }));
      }
      case "infant": {
        return setTravelers((prev) => ({
          ...travelers,
          infant: prev.infant + value,
        }));
      }
      default:
        return;
    }
  };

  const handleClassChange = (event) => setSelectedClass(event.target.value);
  const handleAirLineChange = (event) => setSelectedAirLine(event.target.value);
  const handleSubmitTravelData = (values) => {
    // console.log(values, "VALUES");
    values.fareType = fareType;
    values.adultPax = travelers?.adult;
    values.childPax = travelers?.child;
    values.infantPax = travelers?.infant;
    window.sessionStorage.setItem("fetching", true);
    propes.history.push({
      pathname: "/flight/search-progress",
      state: values,
    });
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const submitModify = () => {
    if (formikRef.current) {
      validateSectorsForNonMulticity();
      formikRef.current.handleSubmit();
    }
    propes.updateModify(false);
  };

  const validateSectorsForNonMulticity = () => {
    if (formikRef.current) {
      if (
        formikRef.current.values.mode !== "MULTICITY" &&
        formikRef.current.values.sectors.length > 1
      ) {
        formikRef.current.setFieldValue(
          `sectors`,
          formikRef.current.values.sectors.slice(0, 1)
        );
      }
    }
  };

  const onChangeTripMode = (event) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("mode", event.target.value);
    }
    setTripMode(event.target.value);
  };

  React.useEffect(() => {
    propes.initialvalues.mode == "ONE" &&
      setEndDate(propes.initialvalues.returnDateDisplay);
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        // console.log("user details", details);
        setUserType(details.userType);
        setCorporateType(details.corporateType);
        setPartnerId(details.partnerId);

        switch (details.userTypeAbv) {
          case "S": {
            setClientType("Corporate");
            break;
          }
          case "G": {
            setClientType("Normal");
            break;
          }
          default: {
            setClientType("Normal");
            break;
          }
        }
      } else {
        propes.history.push("/logout");
      }
    });
  }, []);

  React.useEffect(() => {
    getAirlineData();
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(propes.domCarriers);
      } else {
        setCarrier(propes.intlCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  /* React.useEffect(() => {
    validateSectorsForNonMulticity();
  }, [tripMode]); */
  const Travelers = (props) => {
    const PassengerCount = ({ title, type, val, setVal, totalPax }) => {
      const incrCount = () => setVal(type, 1);
      const decrCount = () => setVal(type, -1);
      return (
        <div className={classes.paxRoot}>
          <span className={classes.paxLabel}>
            {title === "Adults" ? (
              <p>
                Adult<span>(18+ years)</span>
              </p>
            ) : title === "Children" ? (
              <p>
                Children<span>(2-12 years)</span>
              </p>
            ) : (
              <p>
                Infants<span>(0-2 years)</span>
              </p>
            )}
          </span>
          <Box className={classes.mainBox}>
            {/* <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                
              </Grid> */}

            <Box className={classes.incrButton}>
              <IconButton
                aria-label="decrement"
                onClick={decrCount}
                disabled={val <= (type === "adult" ? 1 : 0)}
              >
                <RemoveIcon className="icon" fontSize="small" />
              </IconButton>
            </Box>
            <Box className={classes.countVal}>
              <Typography variant="h6">{val}</Typography>
            </Box>
            <Box className={classes.decrButton}>
              <IconButton
                aria-label="increament"
                onClick={incrCount}
                disabled={totalPax >= 9}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </div>
      );
    };
    return (
      <div className={classes.travDiv}>
        <div className="pax-input-div">
          <div
            className="passenger_div"
            onClick={() => setDropdownTraveler(!dropdownTraveler)}
          >
            {/*<p className="trvl">TRAVELLER & CLASS</p>*/}
            <p className="trvl-count">
              <span className="trval-tnum">
                {Number(props.values.adultPax) +
                  Number(props.values.childPax) +
                  Number(props.values.infantPax)}
              </span>
              <span className="trval-txt">Traveller(s)</span>{" "}
              <span className="trvl-class">
                {props.values.preferredclass === "E"
                  ? "Eco"
                  : props.values.preferredclass === "ER"
                    ? "Prem Eco"
                    : props.values.preferredclass === "B"
                      ? "Business"
                      : ""}
              </span>
              {dropdownTraveler ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </p>
          </div>

          {dropdownTraveler && (
            <div className="passenger_div-show">
              <Grid item sm={12} className={classes.FlightSection_passdet}>
                <Grid
                  className={classes.gridRespColPassDet}
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <Box className="counter-box">
                    <PassengerCount
                      title="Adults"
                      type="adult"
                      val={props.values.adultPax}
                      setVal={addRemoveTravelerHandler}
                      name="adultPax"
                      totalPax={
                        props.values.adultPax +
                        props.values.childPax +
                        props.values.infantPax
                      }
                    />
                    <ErrorMessage
                      name="adultPax"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box className="counter-box">
                    <PassengerCount
                      title="Children"
                      type="child"
                      val={props.values.childPax}
                      setVal={addRemoveTravelerHandler}
                      name="childPax"
                      totalPax={
                        props.values.adultPax +
                        props.values.childPax +
                        props.values.infantPax
                      }
                    />
                    <ErrorMessage
                      name="childPax"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box className="counter-box">
                    <PassengerCount
                      title="Infants"
                      type="infant"
                      val={props.values.infantPax}
                      setVal={addRemoveTravelerHandler}
                      name="infantPax"
                      totalPax={
                        props.values.adultPax +
                        props.values.childPax +
                        props.values.infantPax
                      }
                    />
                    <ErrorMessage
                      name="noInfant"
                      component="div"
                      className="error"
                    />
                  </Box>
                </Grid>

                <div className="cabinType-dev">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="cabinType"
                      value={props.values.preferredclass}
                      name="preferredclass"
                      onChange={(e, v) =>
                        v && props.setFieldValue("preferredclass", v)
                      }
                    >
                      <FormControlLabel
                        value="E"
                        control={<Radio />}
                        label="Economy"
                      />
                      <FormControlLabel
                        value="ER"
                        control={<Radio />}
                        label="Premium Economy"
                      />
                      <FormControlLabel
                        value="B"
                        control={<Radio />}
                        label="Business"
                      />
                    </RadioGroup>
                    <ErrorMessage
                      name="preferredclass"
                      component="div"
                      className="error"
                    />
                  </FormControl>
                </div>
                <div className={classes.fareTypeContent} style={{ display: "none" }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="method"
                      name="method"
                      // value={values.fareType}
                      value={fareType}
                      onChange={
                        (event) => {
                          setFareType(event.target.value);
                          console.log(event.target.value, "VALUE");
                        }
                        // props.setselectedpaymentmethod(event.target.value)
                        // setFieldValue("fareType", event.target.value)
                        // console.log("first")
                      }
                    >
                      <FormControlLabel
                        value="Regular"
                        control={<Radio />}
                        label={`Regular Fare`}
                      />
                      <FormControlLabel
                        value="Student"
                        control={<Radio />}
                        label={`Student Fare`}
                      />
                      <FormControlLabel
                        value="Senior"
                        control={<Radio />}
                        label={`Senior Fare`}
                      />
                    </RadioGroup>
                  </FormControl>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => setDropdownTraveler(!dropdownTraveler)}
                    >
                      Done
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Formik
                innerRef={formikRef}
                initialValues={propes.initialvalues}
                // enableReinitialize
                onSubmit={(values) => {
                  handleSubmitTravelData(values);
                }}
                render={(props) => (
                  <Form onSubmit={props.handleSubmit} className="f-12">
                    <FormControl style={{ width: "35%" }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="mode"
                        value={props.values.mode}
                        onChange={onChangeTripMode}
                        className={classes.radioGroup}
                      >
                        <FormControlLabel
                          value="ONE"
                          control={<Radio />}
                          label="One-way"
                        />
                        <FormControlLabel
                          value="ROUND"
                          control={<Radio />}
                          label="Round"
                        />
                        <FormControlLabel
                          value="MULTICITY"
                          control={<Radio />}
                          label="Multi-City"
                        />
                      </RadioGroup>
                      <CardBody style={{ padding: 0 }}>
                        {props.values.mode !== "MULTICITY" && (
                          <Grid
                            container
                            className={classes.modifycontainer}
                            style={{
                              // marginBottom: 10,
                              flexWrap: "nowrap",
                            }}
                          >
                            <Grid item md={5.5} xs={12} className="form-group">
                              <Autocomplete
                                name={`sectors[0].originObj`}
                                options={airportList || []}
                                value={props.values.sectors[0].originObj || ""}
                                PopperComponent={customPopperFrom}
                                getOptionLabel={(o) =>
                                  o
                                    ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                    : ""
                                }
                                getOptionSelected={(option, value) =>
                                  option.airportCode == value.airportCode
                                }
                                onKeyUp={(event) =>
                                  fetchAirports(event.target.value)
                                }
                                onChange={(event, newValue, reason) => {
                                  // console.log("origin obj", newValue);
                                  if (reason === "clear") {
                                    setAirportList(popularCities);
                                  } else {
                                    changeHandler(
                                      "from",
                                      newValue?.countryCode
                                    );
                                    // arriveAutocompleteEl.current.focus();

                                    // setData({ ...data, from: newValue });
                                    props.setFieldValue(
                                      `sectors[0].originObj`,
                                      newValue
                                    );
                                    props.setFieldValue(
                                      `sectors[0].origin`,
                                      newValue.airportCode
                                    );
                                    // !dataError.from &&
                                    // arriveAutocompleteEl.current.focus();
                                  }
                                }}
                                ref={departAutocompleteEl}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    // fullWidth
                                    placeholder="Departure Airport"
                                  />
                                )}
                                renderOption={(optionsData, propes) =>
                                  renderOptionsComp(optionsData, propes)
                                }
                                forcePopupIcon={false}
                              />
                              <ErrorMessage
                                name={`sectors[0].originObj`}
                                component="div"
                                className="error"
                              />
                            </Grid>
                            <Grid
                              item
                              md={1}
                              xs={12}
                              className="form-group swapIcon"
                            >
                              {" "}
                              <SwapHoriz
                                onClick={() => {
                                  props.setFieldValue(
                                    `sectors[0].originObj`,
                                    props.values.sectors[0].destinationObj
                                  );
                                  props.setFieldValue(
                                    `sectors[0].origin`,
                                    props.values.sectors[0].destination
                                  );
                                  props.setFieldValue(
                                    `sectors[0].destinationObj`,
                                    props.values.sectors[0].originObj
                                  );
                                  props.setFieldValue(
                                    `sectors[0].destination`,
                                    props.values.sectors[0].origin
                                  );
                                }}
                              />
                            </Grid>

                            <Grid item md={5.5} xs={12} className="form-group">
                              <Autocomplete
                                name={`sectors[0].destinationObj`}
                                options={airportList || []}
                                getOptionLabel={(o) =>
                                  o
                                    ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                    : ""
                                }
                                PopperComponent={customPopperTo}
                                value={
                                  props.values.sectors[0].destinationObj || ""
                                }
                                getOptionSelected={(option, value) =>
                                  option.airportCode == value.airportCode
                                }
                                onKeyUp={(event) =>
                                  fetchAirports(event.target.value)
                                }
                                onChange={(event, newValue, reason) => {
                                  // console.log(newValue);
                                  if (reason === "clear") {
                                    setAirportList(popularCities);
                                  } else {
                                    changeHandler("to", newValue?.countryCode);
                                    // arriveAutocompleteEl.current.focus();

                                    // setData({ ...data, from: newValue });
                                    props.setFieldValue(
                                      `sectors[0].destinationObj`,
                                      newValue
                                    );
                                    props.setFieldValue(
                                      `sectors[0].destination`,
                                      newValue.airportCode
                                    );
                                    // !dataError.from &&
                                    //   arriveAutocompleteEl.current.focus();
                                  }
                                }}
                                ref={arriveAutocompleteEl}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Arrival Airport"
                                  />
                                )}
                                renderOption={(optionsData, propes) =>
                                  renderOptionsComp(optionsData, propes)
                                }
                                forcePopupIcon={false}
                              />
                              <ErrorMessage
                                name={`sectors[0].destinationObj`}
                                component="div"
                                className="error"
                              />
                            </Grid>
                          </Grid>
                        )}
                        {props.values.mode === "MULTICITY" &&
                          props.values.sectors.map((data, index) => (
                            <div key={index}>
                              <Grid
                                container
                                className={classes.modifycontainer}
                                style={{
                                  //marginBottom: 10,
                                  // gap: 8,
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Grid
                                  item
                                  md={5.5}
                                  xs={12}
                                  className="form-group"
                                >
                                  <Autocomplete
                                    PopperComponent={customPopperFrom}
                                    name={`sectors[${index}].originObj`}
                                    options={airportList || []}
                                    value={
                                      props.values.sectors[index].originObj ||
                                      ""
                                    }
                                    getOptionLabel={(o) =>
                                      o
                                        ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                        : ""
                                    }
                                    getOptionSelected={(option, value) =>
                                      option.airportCode == value.airportCode
                                    }
                                    onKeyUp={(event) =>
                                      fetchAirports(event.target.value)
                                    }
                                    onChange={(event, newValue, reason) => {
                                      // console.log(newValue);
                                      if (reason === "clear") {
                                        setAirportList(popularCities);
                                      } else {
                                        changeHandler(
                                          "from",
                                          newValue?.countryCode
                                        );
                                        // arriveAutocompleteEl.current.focus();

                                        // setData({ ...data, from: newValue });
                                        props.setFieldValue(
                                          `sectors[${index}].originObj`,
                                          newValue
                                        );
                                        props.setFieldValue(
                                          `sectors[${index}].origin`,
                                          newValue.airportCode
                                        );
                                        // !dataError.from &&
                                        // arriveAutocompleteEl.current.focus();
                                      }
                                    }}
                                    ref={departAutocompleteEl}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        placeholder="Departure Airport"
                                        variant="outlined"
                                      />
                                    )}
                                    renderOption={(optionsData, propes) =>
                                      renderOptionsComp(optionsData, propes)
                                    }
                                    disablePortal={true}
                                    forcePopupIcon={false}
                                  />
                                  <ErrorMessage
                                    name={`sectors[${index}].originObj`}
                                    component="div"
                                    className="error"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={1}
                                  xs={12}
                                  className="form-group swapIcon"
                                >
                                  {" "}
                                  {index === 0 ? (
                                    <SwapHoriz
                                      onClick={() => {
                                        props.setFieldValue(
                                          `sectors[0].originObj`,
                                          props.values.sectors[0].destinationObj
                                        );
                                        props.setFieldValue(
                                          `sectors[0].origin`,
                                          props.values.sectors[0].destination
                                        );
                                        props.setFieldValue(
                                          `sectors[0].destinationObj`,
                                          props.values.sectors[0].originObj
                                        );
                                        props.setFieldValue(
                                          `sectors[0].destination`,
                                          props.values.sectors[0].origin
                                        );
                                      }}
                                    />
                                  ) : (
                                    <span style={{ width: 30 }}></span>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  md={5.5}
                                  xs={12}
                                  className="form-group"
                                >
                                  <Autocomplete
                                    PopperComponent={customPopperTo}
                                    name={`sectors[${index}].destinationObj`}
                                    options={airportList || []}
                                    getOptionLabel={(o) =>
                                      o
                                        ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                        : ""
                                    }
                                    value={
                                      props.values.sectors[index]
                                        .destinationObj || ""
                                    }
                                    getOptionSelected={(option, value) =>
                                      option.airportCode == value.airportCode
                                    }
                                    onKeyUp={(event) =>
                                      fetchAirports(event.target.value)
                                    }
                                    onChange={(event, newValue, reason) => {
                                      // console.log(newValue);
                                      if (reason === "clear") {
                                        setAirportList(popularCities);
                                      } else {
                                        changeHandler(
                                          "to",
                                          newValue?.countryCode
                                        );
                                        // arriveAutocompleteEl.current.focus();

                                        // setData({ ...data, from: newValue });
                                        props.setFieldValue(
                                          `sectors[${index}].destinationObj`,
                                          newValue
                                        );
                                        props.setFieldValue(
                                          `sectors[${index}].destination`,
                                          newValue.airportCode
                                        );
                                        // !dataError.from &&
                                        //   arriveAutocompleteEl.current.focus();
                                      }
                                    }}
                                    ref={arriveAutocompleteEl}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        placeholder="Arrival Airport"
                                        variant="outlined"
                                      />
                                    )}
                                    renderOption={(optionsData, propes) =>
                                      renderOptionsComp(optionsData, propes)
                                    }
                                    disablePortal={true}
                                    forcePopupIcon={false}
                                  />
                                  <ErrorMessage
                                    name={`sectors[${index}].destinationObj`}
                                    component="div"
                                    className="error"
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        {props.values.mode === "MULTICITY" &&
                          props.values.sectors.length - 1 < 4 && (
                            <Grid
                              container
                              className="actionIcon"
                            // style={{
                            //   width: "100vw",
                            //   position: "relative",
                            //   left: "calc(-50vw + 50%)",
                            //   height: "auto",
                            //   display: "flex",
                            //   justifyContent: "center",
                            //   alignItems: "center",
                            // }}
                            >
                              <IconButton
                                onClick={() => {
                                  setIndex(index + 1);
                                  ArrayHelperRef.current.push({
                                    originObj:
                                      index > 0
                                        ? props.values.sectors[index]
                                          .destinationObj
                                        : props.values.sectors[0]
                                          .destinationObj,
                                    origin:
                                      index > 0
                                        ? props.values.sectors[index]
                                          .destinationObj.airportCode
                                        : props.values.sectors[0].destinationObj
                                          .airportCode,
                                    destinationObj:
                                      props.values.sectors[0].originObj,
                                    destination:
                                      props.values.sectors[0].originObj
                                        .airportCode,
                                    departDateDisplay:
                                      index > 0
                                        ? props.values.sectors[index]
                                          .departDateDisplay
                                        : props.values.sectors[0]
                                          .departDateDisplay,
                                    departDate:
                                      index > 0
                                        ? dateFnsFormat(
                                          props.values.sectors[index]
                                            .departDateDisplay,
                                          "yyyy-MM-dd"
                                        )
                                        : dateFnsFormat(
                                          props.values.sectors[0]
                                            .departDateDisplay,
                                          "yyyy-MM-dd"
                                        ),
                                  });
                                }}
                                variant="contained"
                              >
                                <AddIcon />
                              </IconButton>
                              <p>Add another flight (upto 5 flights)</p>
                            </Grid>
                          )}
                      </CardBody>
                    </FormControl>

                    <div className="form-contents">
                      <FieldArray
                        name="sectors"
                        style={{ width: "100%", height: "100%" }}
                        render={({ push, remove }) => {
                          ArrayHelperRef.current = { push };
                          return (
                            <CardBody
                              className="cardContainer"
                              style={{ width: "100%", height: "100%" }}
                            >
                              {props.values.mode !== "MULTICITY" && (
                                <div style={{ width: "30%", height: "100%" }}>
                                  <Grid
                                    container
                                    className={classes.modifycontainer}
                                    style={{
                                      // marginBottom: 10,
                                      // width: "100%",
                                      flexWrap: "nowrap",
                                      height: "100%",
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                      className="form-group input-date-picker"
                                    >
                                      <label>Departure</label>
                                      <CustomDatePicker
                                        showDisabledMonthNavigation
                                        name={`sectors[0].departDateDisplay`}
                                        minDate={startDate}
                                        customInput={
                                          <TextField
                                            id="standard-basic"
                                            variant="outlined"
                                            fullWidth
                                          />
                                        }
                                        selected={
                                          props.values.sectors[0]
                                            .departDateDisplay
                                        }
                                        onChange={(date) => {
                                          props.setFieldValue(
                                            `sectors[0].departDateDisplay`,
                                            date
                                          );
                                          props.setFieldValue(
                                            `sectors[0].departDate`,
                                            dateFnsFormat(date, "yyyy-MM-dd")
                                          );
                                          setEndDate(date);
                                          // setStartDate(date);
                                        }}
                                        monthsShown={calenderMonth}
                                        dateFormat="dd MMM yyyy"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                      className="form-group input-date-picker"
                                    >
                                      <label
                                        className={
                                          props.values.mode === "ONE"
                                            ? "disabled-label1"
                                            : ""
                                        }
                                      >
                                        Arrival
                                      </label>
                                      <CustomDatePicker
                                        showDisabledMonthNavigation
                                        minDate={endDate}
                                        disabled={props.values.mode === "ONE"}
                                        customInput={
                                          <TextField
                                            id="standard-basic"
                                            variant="outlined"
                                            fullWidth
                                            disabled={
                                              props.values.mode === "ONE"
                                            }
                                            className={
                                              props.values.mode === "ONE"
                                                ? "disabled-input"
                                                : ""
                                            }
                                          />
                                        }
                                        selected={
                                          props.values.mode === "ROUND" &&
                                          endDate
                                        }
                                        onChange={(date) => {
                                          setEndDate(date);
                                          props.setFieldValue(
                                            "returnDateDisplay",
                                            date
                                          );
                                          props.setFieldValue(
                                            "returnDate",
                                            dateFnsFormat(date, "yyyy-MM-dd")
                                          );
                                        }}
                                        monthsShown={calenderMonth}
                                        dateFormat="dd MMM yyyy"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                              {props.values.mode === "MULTICITY" && (
                                <div style={{ width: "30%", height: "100%" }}>
                                  <Grid
                                    container
                                    className={classes.modifycontainer}
                                    style={{
                                      //marginBottom: 10,
                                      // gap: 8,
                                      flexWrap: "nowrap",
                                      height: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={12}
                                      xs={12}
                                      className="form-group input-date-picker"
                                      style={{
                                        margin: 0,
                                        gap: 0,
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop: 10,
                                          display: "flex",

                                          width: "100%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginTop: 8,
                                            marginLeft: 5,
                                            color: "black",
                                            width: "50%",
                                          }}
                                        >
                                          Departure
                                        </p>
                                        <p
                                          style={{
                                            marginTop: 8,
                                            marginLeft: 5,
                                            color: "black",
                                            width: "50%",
                                            opacity: 0.4,
                                            cursor: "default",
                                          }}
                                        >
                                          Arrival
                                        </p>
                                      </div>
                                      <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        className="form-group input-date-picker"
                                        style={{
                                          margin: 0,
                                          gap: 14,
                                          width: "100%",
                                        }}
                                      >
                                        {props.values.sectors.map(
                                          (data, index) => (
                                            <Grid
                                              container
                                              key={index}
                                              className={
                                                classes.modifycontainer
                                              }
                                              style={{
                                                margin: "1px 0px",
                                                flexWrap: "nowrap",
                                                width: "100%",
                                                justifyContent: "space-evenly",
                                                gap: 5,
                                              }}
                                            >
                                              <Grid
                                                item
                                                style={{ width: "46%" }}
                                              >
                                                <CustomDatePicker
                                                  showDisabledMonthNavigation
                                                  name={`sectors[${index}].departDateDisplay`}
                                                  minDate={
                                                    props.values.sectors[index]
                                                      .departDateDisplay
                                                  }
                                                  customInput={
                                                    <TextField
                                                      id="standard-basic"
                                                      variant="outlined"
                                                      fullWidth
                                                      disabled={true}
                                                    />
                                                  }
                                                  selected={
                                                    props.values.sectors[index]
                                                      .departDateDisplay
                                                  }
                                                  onChange={(date) => {
                                                    props.setFieldValue(
                                                      `sectors[${index}].departDateDisplay`,
                                                      date
                                                    );
                                                    props.setFieldValue(
                                                      `sectors[${index}].departDate`,
                                                      dateFnsFormat(
                                                        date,
                                                        "yyyy-MM-dd"
                                                      )
                                                    );
                                                    setEndDate(date);
                                                    // setStartDate(date);
                                                  }}
                                                  monthsShown={calenderMonth}
                                                  dateFormat="dd MMM yyyy"
                                                />
                                              </Grid>

                                              <Grid style={{ width: "46%" }}>
                                                <Grid
                                                  className="form-group"
                                                  style={{
                                                    display: "flex",
                                                    height: "100%",
                                                  }}
                                                >
                                                  {index > 0 &&
                                                    index ===
                                                    props.values.sectors
                                                      .length -
                                                    1 ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        // marginTop: 30,
                                                        // paddingTop:
                                                        //   isMobileScreen && 12,
                                                        gap: 10,
                                                      }}
                                                      className="actionIcon"
                                                    >
                                                      {/*index < 4 && (
                                                        <Button
                                                          onClick={() =>
                                                            push({
                                                              originObj:
                                                                index > 0
                                                                  ? props.values
                                                                      .sectors[
                                                                      index
                                                                    ]
                                                                      .destinationObj
                                                                  : props.values
                                                                      .sectors[0]
                                                                      .destinationObj,
                                                              origin:
                                                                index > 0
                                                                  ? props.values
                                                                      .sectors[
                                                                      index
                                                                    ]
                                                                      .destinationObj
                                                                      .airportCode
                                                                  : props.values
                                                                      .sectors[0]
                                                                      .destinationObj
                                                                      .airportCode,
                                                              destinationObj:
                                                                props.values
                                                                  .sectors[0]
                                                                  .originObj,
                                                              destination:
                                                                props.values
                                                                  .sectors[0]
                                                                  .originObj
                                                                  .airportCode,
                                                              departDateDisplay:
                                                                index > 0
                                                                  ? props.values
                                                                      .sectors[
                                                                      index
                                                                    ]
                                                                      .departDateDisplay
                                                                  : props.values
                                                                      .sectors[0]
                                                                      .departDateDisplay,
                                                              departDate:
                                                                index > 0
                                                                  ? dateFnsFormat(
                                                                      props
                                                                        .values
                                                                        .sectors[
                                                                        index
                                                                      ]
                                                                        .departDateDisplay,
                                                                      "yyyy-MM-dd"
                                                                    )
                                                                  : dateFnsFormat(
                                                                      props
                                                                        .values
                                                                        .sectors[0]
                                                                        .departDateDisplay,
                                                                      "yyyy-MM-dd"
                                                                    ),
                                                            })
                                                          }
                                                          variant="contained"
                                                          color="secondary"
                                                          style={{
                                                            width: "50%",
                                                          }}
                                                        >
                                                          Add
                                                        </Button>
                                                        )*/}
                                                      {index !== 0 && (
                                                        <IconButton
                                                          onClick={() => {
                                                            setIndex(index - 1);
                                                            remove(index);
                                                          }}
                                                          variant="contained"
                                                        >
                                                          <CloseIcon />
                                                        </IconButton>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      {index === 0 && (
                                                        <CustomDatePicker
                                                          showDisabledMonthNavigation
                                                          minDate={endDate}
                                                          disabled={true}
                                                          customInput={
                                                            <TextField
                                                              id="standard-basic"
                                                              variant="outlined"
                                                              fullWidth
                                                              disabled={
                                                                props.values
                                                                  .mode ===
                                                                "ONE"
                                                              }
                                                              className="disabled-input"
                                                            />
                                                          }
                                                          selected={
                                                            props.values
                                                              .mode ===
                                                            "ROUND" && endDate
                                                          }
                                                          onChange={(date) => {
                                                            setEndDate(date);
                                                            props.setFieldValue(
                                                              "returnDateDisplay",
                                                              date
                                                            );
                                                            props.setFieldValue(
                                                              "returnDate",
                                                              dateFnsFormat(
                                                                date,
                                                                "yyyy-MM-dd"
                                                              )
                                                            );
                                                          }}
                                                          monthsShown={
                                                            calenderMonth
                                                          }
                                                          dateFormat="dd-MMM-yyyy"
                                                        />
                                                      )}
                                                    </div>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}

                              <Grid
                                className={classes.modifycontainer}
                                container
                                style={{
                                  //marginBottom: 10,
                                  flexWrap: "nowrap",
                                  width: "55%",
                                }}
                              >
                                <Grid
                                  item
                                  md={5}
                                  xs={12}
                                  className="form-group classDiv"
                                >
                                  <label>Travelers & Class</label>
                                  <Travelers
                                    setFieldValue={props.setFieldValue}
                                    values={{
                                      adultPax: travelers.adult,
                                      childPax: travelers.child,
                                      infantPax: travelers.infant,
                                      preferredclass:
                                        props.values.preferredclass,
                                    }}
                                  />
                                  {/*<OaSwitchInput
                                  labeltxt="TRAVELERS"
                                  iconbuttonstyle={IconButtonStyle}
                                  remadt={() => {
                                    if (travelers.adult === 0) return;
                                    addRemoveTravelerHandler("adult", -1);
                                  }}
                                  addadt={() => {
                                    addRemoveTravelerHandler("adult", 1);
                                  }}
                                  remchd={() => {
                                    if (travelers.child === 0) return;
                                    addRemoveTravelerHandler("child", -1);
                                  }}
                                  addchd={() => {
                                    addRemoveTravelerHandler("child", 1);
                                  }}
                                  reminf={() => {
                                    if (travelers.infant === 0) return;
                                    addRemoveTravelerHandler("infant", -1);
                                  }}
                                  addinf={() => {
                                    addRemoveTravelerHandler("infant", 1);
                                  }}
                                  travelers={travelers}
                                />*/}
                                </Grid>
                                {/*<Grid
                                item
                                md={6}
                                xs={12}
                                className="form-group classDiv"
                              >
                                <label>Class</label>
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    value={props.values.preferredclass}
                                    // onChange={handleClassChange}
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "preferredclass",
                                        event.target.value
                                      )
                                    }
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"E"}>Economy</MenuItem>
                                    <MenuItem value={"ER"}>
                                      Premium Economy
                                    </MenuItem>
                                    <MenuItem value={"B"}>Business</MenuItem>
                                  </Select>
                                </FormControl>
                                  </Grid>*/}
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="form-group classDiv"
                                >
                                  <label>Stops</label>
                                  <FormControl variant="outlined" fullWidth>
                                    <Select
                                      value={props.values.isNonStop || false}
                                      // onChange={handleClassChange}
                                      onChange={(event) => {
                                        //    console.log(event.target.value);

                                        props.setFieldValue(
                                          "isNonStop",
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem value={false}>Stop</MenuItem>
                                      <MenuItem value={true}>Non Stop</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  md={4}
                                  xs={12}
                                  className="form-group classDiv"
                                >
                                  <label>Airlines</label>
                                  <FormControl variant="outlined" fullWidth>
                                    <Select
                                      multiple
                                      fullWidth
                                      name="airline"
                                      open={airlineSelectOpen}
                                      value={props.values.preferredAirline}
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      onChange={(e) => {
                                        // setData({ ...data, airlines: e.target.value });
                                        // setDataError({ ...dataError, airlines: false });
                                        // console.log(e.target.value);
                                        props.setFieldValue(
                                          `preferredAirline`,
                                          e.target.value
                                        );
                                        // setAirlineSelectOpen(false);
                                      }}
                                      renderValue={(selected) => {
                                        let initialValue = "";

                                        if (selected.includes(allAirlines)) {
                                          initialValue =
                                            initialValue + ", " + "All";
                                        }
                                        if (selected.includes(lccAirlines)) {
                                          initialValue =
                                            initialValue + ", " + "LCC Only";
                                        }
                                        if (selected.includes(gdsAirlines)) {
                                          initialValue =
                                            initialValue + ", " + "GDS Only";
                                        }
                                        initialValue =
                                          carrier &&
                                          carrier.reduce(
                                            (previousValue, currentValue) =>
                                              selected.includes(
                                                currentValue.name
                                              )
                                                ? previousValue +
                                                ", " +
                                                currentValue.description
                                                : previousValue,
                                            initialValue
                                          );

                                        return (
                                          initialValue &&
                                          initialValue.substring(
                                            1,
                                            initialValue.length
                                          )
                                        );
                                      }}
                                      onOpen={() => setAirlineSelectOpen(true)}
                                      onClose={() => {
                                        // setDataError({
                                        //   ...dataError,
                                        //   airlines: true,
                                        // });

                                        setAirlineSelectOpen(false);
                                      }}
                                    >
                                      <MenuItem
                                        key={allAirlines}
                                        value={allAirlines}
                                        checked
                                      >
                                        <ListItemText primary={"All"} />
                                        <Checkbox
                                          checked={
                                            props.values.preferredAirline.indexOf(
                                              allAirlines
                                            ) > -1
                                          }
                                        />
                                      </MenuItem>

                                      <MenuItem
                                        key={lccAirlines}
                                        value={lccAirlines}
                                      >
                                        <ListItemText primary={"LCC Only"} />
                                        <Checkbox
                                          checked={
                                            props.values.preferredAirline.indexOf(
                                              lccAirlines
                                            ) > -1
                                          }
                                        />
                                      </MenuItem>
                                      <MenuItem
                                        key={gdsAirlines}
                                        value={gdsAirlines}
                                      >
                                        <ListItemText primary={"GDS Only"} />
                                        <Checkbox
                                          checked={
                                            props.values.preferredAirline.indexOf(
                                              gdsAirlines
                                            ) > -1
                                          }
                                        />
                                      </MenuItem>

                                      {carrier &&
                                        carrier.map((a) => (
                                          <MenuItem key={a.name} value={a.name}>
                                            <ListItemText
                                              primary={a.description}
                                            />
                                            <Checkbox
                                              checked={
                                                props.values.preferredAirline.indexOf(
                                                  a.name
                                                ) > -1
                                              }
                                            />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    {props.values.preferredAirline.length ===
                                      0 && (
                                        <FormHelperText>
                                          Please choose an airline
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid
                                className={classes.modifycontainer}
                                container
                                style={{
                                  //marginBottom: 10,

                                  flexWrap: "nowrap",
                                  width: "15%",
                                  // alignItems: "flex-end",
                                }}
                              >
                                {/*
                                 */}

                                <Grid
                                  item
                                  md={10}
                                  xs={12}
                                  className={classes.modifyActions}
                                >
                                  {/*<a
                                  className={classes.action_reset}
                                  onClick={propes.cancelModify}
                                >
                                  <span>Cancel</span>
                            </a>*/}
                                  <a
                                    className={classes.action_update}
                                    onClick={submitModify}
                                  >
                                    <span>Search</span>
                                  </a>
                                </Grid>
                              </Grid>
                            </CardBody>
                          );
                        }}
                      />
                    </div>
                  </Form>
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapPropsToState = (state, props) => {
  return {
    domCarriers: state.flight.domCarriers,
    intlCarriers: state.flight.intlCarriers,
    airports: state.flight.airports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDomesticCarriers: (domCarriers) =>
      dispatch(setDomesticCarriers(domCarriers)),
    setInternationalCarriers: (intlCarriers) =>
      dispatch(setInternationalCarriers(intlCarriers)),
    setAirports: (airports) => dispatch(setAirports(airports)),
  };
};

export default withRouter(
  connect(
    mapPropsToState,
    mapDispatchToProps
  )(FlightModifyForm)
);

const renderOptionsComp = (optionsData, propes) => {
  // console.log("render options", optionsData);
  // console.log("render options props", propes);
  return (
    <li
      selected={propes.selected}
      className="ac-options-list"
      style={{ width: 250, overflow: "hidden" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <span className="ac-option-left">
          {optionsData.cityName} ({optionsData.airportCode})
        </span>
        <span className="ac-option-right">{optionsData.countryCode}</span>
      </div>
      <p style={{ whiteSpace: "nowrap" }}>{optionsData.airportDesc}</p>
    </li>
  );
};

const customPopperFrom = function (props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={departAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const customPopperTo = function (props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={arriveAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const flightWayList = [
  { val: "ONE", text: "One Way" },
  { val: "ROUND", text: "Round Trip" },
  { val: "ROUNDSP", text: "Round Trip Special" },
  { val: "MULTICITY", text: "Multi City" },
];

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const bkClass = [
  { value: "None", label: "Select Class", type: "default-null" },
  { value: "E", label: "Economy", type: "value" },
  { value: "ER", label: "Premium Economy", type: "value" },
  { value: "B", label: "Business", type: "value" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: "15px 0px",
    width: 1440,
    minHeight: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    textAlign: "left",

    "& .cardContainer": {
      padding: 0,
      display: "flex",
    },

    noBorder: {
      border: "none",
    },
    "& .form-contents": {
      width: "65%",
    },
    "& .passenger-value": {
      color: "#000000 !important",
    },
    "& .MuiFormControl-root": { marginTop: 0 },
    "& h1": {
      textShadow: "-1px -1px 25px rgb(0 0 0 / 10%)",
      color: "#000000",
      fontWeight: 700,
      marginBottom: "1.5rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "4.5rem",
        marginTop: 0,
      },
      [theme.breakpoints.down(1500)]: {
        fontSize: "3.3rem",
        marginTop: 0,
        marginBottom: "0.5rem",
      },
    },
    "& .react-datepicker__month-container": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },

    "& .MuiAutocomplete-listbox": {
      overflowY: "auto !important",
      width: "320px !important",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.buttons.default,
        borderRadius: 8,
        transition: "ease-in-out 1s",
        "&:hover": {
          opacity: 1,
          background: theme.palette.buttons.default,
        },
      },
      "& .ac-options-list": {
        overflow: "hidden",
        width: "100%",
        "& span": {
          marginBottom: 0,
          color: theme.palette.buttons.defaultContrast,
          whiteSpace: "nowrap",
        },
        "& div": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          flexWrap: "nowrap",
          "& .ac-option-left": {
            textAlign: "left",
            color: theme.palette.buttons.defaultContrast,
            fontWeight: 600,
            flexGrow: 1,
          },
          "& .ac-option-right": {
            textAlign: "right",
            color: theme.palette.buttons.defaultContrast,
          },
        },
      },
    },
    "& .react-datepicker-popper": {
      zIndex: 9,
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .input-date-picker": {
      flexWrap: "nowrap",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      gap: 2,
      margin: 0,
      "& .disabled-input": { opacity: 0.4, cursor: "not-allowed" },
      "& .disabled-label": { opacity: 0.4 },
      "& label": {
        padding: "10px 0",
        color: theme.palette.buttons.defaultContrast,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        border: "none",
      },
      "& .form-group": {
        margin: 0,
      },
      "& .MuiOutlinedInput-root": {
        borderColor: theme.palette.buttons.defaultContrast,
        borderWidth: 1,
        borderStyle: "solid",
      },
      "& .MuiOutlinedInput-input": {
        height: 19,
        padding: 9,
      },
    },
    "& .swapIcon": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px !important",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        width: 30,
        height: 30,
        borderColor: theme.palette.buttons.defaultContrast,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "50%",
        color: theme.palette.buttons.defaultContrast,
      },
    },
    "& .actionIcon": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& p": {
        margin: 0,
        marginLeft: 10,
        color: theme.palette.buttons.defaultContrast,
      },
      "& .MuiIconButton-root": {
        width: 25,
        height: 25,
        borderColor: theme.palette.buttons.defaultContrast,
        borderStyle: "solid",
        borderWidth: 1,
      },
    },
    "& .form-group": {
      margin: "8px 5px",
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        borderColor: theme.palette.buttons.defaultContrast,
        borderWidth: 1,
        borderStyle: "solid",
      },
    },
    "& .classDiv": {
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      gap: 2,
      height: "fit-content",
      "& .MuiOutlinedInput-root": {
        borderColor: theme.palette.buttons.defaultContrast,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
      },
      "& .MuiOutlinedInput-input": {
        height: 19,
        padding: 9,
      },
      "& label": {
        padding: "10px 0",
        color: theme.palette.buttons.defaultContrast,
      },
    },
  },

  container: {
    padding: 0,
    maxWidth: "100%",
    "& .f-12": {
      display: "flex",
      width: "100%",
    },
  },
  radioGroup: {
    "& .MuiFormControlLabel-label, .MuiIconButton-label": {
      color: theme.palette.buttons.defaultContrast,
      //fontWeight: 600,
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      "& .MuiIconButton-label": {
        color: theme.palette.buttons.defaultContrast,
      },
    },
  },
  modifyActions: {
    width: "20%",
    margin: "8px 5px",
    marginTop: 49,
    display: "flex",
    // justifyContent: "flex-end",
    // height: "40px",
    alignItems: "flex-end",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "700",
    gap: "20px",
    height: "fit-content",
    // [theme.breakpoints.down(1080)]: {
    //   fontSize: "12px",
    //   height: "30px",
    // },
  },
  modifycontainer: {
    display: "flex",
    [theme.breakpoints.down(700)]: {
      display: "initial",
    },
    "& label": {
      padding: "10px 5px",
      color: theme.palette.buttons.defaultContrast,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.buttons.defaultContrast,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    '& .form-group .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
      borderColor: theme.palette.buttons.defaultContrast,
      "& .MuiAutocomplete-input": {
        padding: 9,
      },
    },
    "& .MuiSvgIcon-root, .swapIcon .MuiSvgIcon-root, .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root": {
      fill: `${theme.palette.buttons.defaultContrast} !important`,
      color: `${theme.palette.buttons.defaultContrast} !important`,
      borderColor: `${theme.palette.buttons.defaultContrast} !important`,
    },
    "& .MuiIconButton-root, .MuiIconButton-root svg": {
      color: `${theme.palette.buttons.defaultContrast} !important`,
    },
    "& p": {
      color: `${theme.palette.buttons.defaultContrast} !important`,
    },
  },

  action_reset: {
    height: 40,
    cursor: "pointer",
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "4px",
    padding: "3px 8px",
    display: "table",
    "& span": {
      display: "table-cell",
      verticalAlign: "middle",
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& span": {
        color: theme.palette.primary.contrastText,
      },
    },
    // [theme.breakpoints.down(1620)]: {
    //   display: "none",
    // },
  },
  action_update: {
    height: 40,
    width: "100%",
    cursor: "pointer",
    color: theme.palette.buttons.tertiaryContrastText,
    background: theme.palette.buttons.secondary,
    border: `2px solid ${theme.palette.buttons.secondary}`,
    borderRadius: "4px",
    // padding: "10px",
    display: "table",
    "& span": {
      display: "table-cell",
      verticalAlign: "middle",
    },
    "&:hover": {
      backgroundColor: theme.palette.buttons.tertiary,
      "& span": {
        color: theme.palette.buttons.tertiaryContrastText,
      },
    },
  },

  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.background.defaultContrast,
      backgroundColor: theme.palette.background.default,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.background.primaryContrast,
      backgroundColor: theme.palette.background.primary,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.primary,
        color: theme.palette.background.primaryContrast,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiToggleButton-sizeSmall": {
      height: 24,
      minWidth: 75,
      [theme.breakpoints.down(840)]: {
        minWidth: 0,
      },
      [theme.breakpoints.down(614)]: {
        minWidth: 75,
      },
      [theme.breakpoints.down(358)]: {
        marginBottom: 20,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      [theme.breakpoints.down(358)]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },

  // passengers
  travDiv: {
    // backgroundColor: "white",
    color: theme.palette.buttons.defaultContrast,
    "& .trvl-count": {
      // padding: 12,
      display: "flex",
      height: 19,
      justifyContent: "space-around",
      alignItems: "center",
      borderRadius: 5,
      margin: 0,
    },
    "& .pax-input-div": {
      position: "relative",
      borderColor: theme.palette.buttons.defaultContrast,
      borderWidth: 1,
      borderStyle: "solid",
      padding: 9,
      borderRadius: 5,
    },
    "& .passenger_div-show": {
      position: "absolute",
      zIndex: 1,
      top: 38,
      left: 0,
      width: "fit-content",
      padding: 20,
      paddingBottom: 10,
      background: "white",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.buttons.defaultContrast,
    },
    FlightSection_passdet: {
      margin: 10,
    },
    "& .cabinType-dev": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& label": {
        padding: 0,
        margin: 0,
      },
      "& fieldset": { width: "100%" },
      "& .MuiFormGroup-root": {
        margin: "0 -15px",
      },
      "& .MuiTypography-root": { width: "100%", fontSize: 14 },
      "& .MuiRadio-root": { padding: 5 },
      "& .MuiButton-root": {
        zIndex: 1,
        background: theme.palette.buttons.tertiary,
        color: theme.palette.buttons.tertiaryContrastText,
        margin: "10px 0",
        padding: 5,
        width: 70,
      },
    },
  },

  paxRoot: {
    display: "flex",
    width: "180px",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "0.5rem",
    marginBottom: "13px",
  },
  mainBox: {
    // width: "155px",
    // borderRadius: "8px",
    // backgroundColor: "#ffffff66",
    // marginTop: 3,
    borderRadius: "4px",
    border: "1px solid #dcdcdc",
    display: "flex",
    [theme.breakpoints.down(840)]: {
      width: 100,
    },
    [theme.breakpoints.down(400)]: {
      width: "50vw",
    },

    "& .MuiIconButton-root": {
      width: "26px",
      height: "31px",
      border: 0,
      background: "none",
      fontSize: "12px",
      cursor: "pointer",
      color: "#000",
      fontFamily: theme.palette.font.primary,
    },
  },

  incrButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  countVal: {
    width: "40px",
    borderLeft: "1px solid #dcdcdc",
    fontFamily: theme.palette.font.primary,
    borderRight: "1px solid #dcdcdc",
    backgroundColor: "#f6f9ff",
    textAlign: "center",
    "& .MuiTypography-h6": {
      fontSize: "13px",
      fontFamily: theme.palette.font.primary,
      paddingTop: "8px",
      // fontSize: "1em",
    },
  },
  decrButton: {
    "& .MuiSvgIcon-root": {
      // height: 12,
      fontSize: "12px",
    },
  },
  paxLabel: {
    fontFamily: theme.palette.font.primary,
    display: "block",
    "& p": {
      fontSize: "14px",
      fontWeight: "600",
      margin: 0,
      lineHeight: "1.2",
      textAlign: "center",
      "& span": {
        display: "block",
        fontSize: "11px",
      },
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
      width: "40vw",
    },
  },
  fareTypeContent: {
    marginTop: 5,
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee",

    // display: "flex",
    // justifyContent: "center",
    "& .MuiButton-root": {
      zIndex: 1,
      background: theme.palette.buttons.tertiary,
      color: theme.palette.buttons.tertiaryContrastText,
      margin: "10px 0",
      padding: 5,
      width: 70,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
    "& label": {
      height: 35,
    },
    "& .MuiIconButton-root": {
      paddingLeft: 0,
    },
  },
}));
