import React from 'react';
import GridContainer from '../components/Grid/GridContainer.js';
import GridItem from '../components/Grid/GridItem.js';
import Card from '../components/Card/Card.js';
import CardBody from '../components/Card/CardBody.js';
const OaCard = (props) => {
  return (
    <GridContainer className="oa-card-grid-container">
      <GridItem xs={12} sm={12} md={12}>
        <Card className={props.className} style={props.style}>
          <CardBody style={props.bodystyle}>{props.children}</CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OaCard;
