import React from "react";
import OaFormDateField from "oahoc/OaFormDateField";
import OaFormSelectField from "oahoc/OaFormSelectField";
import OaFormTextField from "oahoc/OaFormTextField";
import OaLoading from "pages/components/OaLoading";
import dateFnsFormat from "date-fns/format";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Form, Formik } from "formik";
import { Button, Grid, makeStyles } from "@material-ui/core";

const PanApplicationReportSearchForm = (props) => {
    const classes = useStyles();
    const [isLoading] = React.useState(false);
    const [agencyId, setAgencyId] = React.useState(props.agencyId);

    const submitReportSearch = (searchParams, { }) => {
        props.setIsLoading(true);
        props.target(searchParams);
        props.setIsLoading(false);
    };

    React.useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId]);

    return (
        <>
            {isLoading && <OaLoading />}
            {!isLoading &&
                <>
                    <h4 className={"oa-form-header"} style={{ transform: ' scale(0.85)', transformOrigin: 'left' }} >Search PAN Application Report</h4>
                    <Formik
                        initialValues={props.searchParams}
                        onSubmit={submitReportSearch}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className={classes.root}>
                                <GridContainer>
                                    <OaFormTextField name="panApplicationReference" label="Application Reference" md={4} />

                                    <OaFormDateField
                                        value={values.fromDateDisplay}
                                        selected={values.fromDateDisplay}
                                        onChange={date => {
                                            setFieldValue('fromDateDisplay', date);
                                            setFieldValue('fromDate', dateFnsFormat(date, 'yyyy-MM-dd'),)
                                        }}
                                        label="From Date"
                                        name="fromDateDisplay"
                                        md={4}
                                    />

                                    <OaFormDateField
                                        value={values.toDateDisplay}
                                        selected={values.toDateDisplay}
                                        onChange={date => {
                                            setFieldValue('toDateDisplay', date);
                                            setFieldValue('toDate', dateFnsFormat(date, 'yyyy-MM-dd'),)
                                        }}
                                        label="To Date"
                                        name="toDateDisplay"
                                        md={4}
                                    />

                                    <GridItem xs={12} sm={12} md={4}>
                                        <OaFormSelectField
                                            label="Status"
                                            name="appliStatus"
                                            value={values.appliStatus}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: 'SUCCESS', label: 'Success' },
                                                { value: 'Pending', label: 'Pending' }
                                            ]}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <OaFormSelectField
                                            label="Type"
                                            name="appliType"
                                            value={values.appliType}
                                            options={appliTypes}
                                        />
                                    </GridItem>

                                    <OaFormTextField name="ackNo" label="Acknowledgement No." md={4} />
                                </GridContainer>

                                <GridContainer>
                                    <Grid item xs={12} sm={12} md={4} className="action-grid">
                                        <Button color="primary" type="submit"
                                            className="submit-button"
                                        >Search</Button>
                                    </Grid>
                                </GridContainer>
                            </Form>
                        )}
                    </Formik>
                </>
            }
        </>
    );
};

export default PanApplicationReportSearchForm;

const appliTypes = [
    { label: "New PAN - Indian Citizen (Form 49A)", value: "A" },
    { label: "New PAN - Foreign Citizen (Form 49AA)", value: "AA" },
    { label: "Changes or Correction in existing PAN Data/Reprint of PAN Card (No changes in existing PAN Data", value: "CR" }
];

const appliCategories = [
    { label: "Individual", value: "A" },
    { label: "Association of Persons", value: "AA" },
    { label: "BODY OF INDIVIDUALS", value: "B" },
    { label: "COMPANY", value: "C" },
    { label: "TRUST", value: "T" },
    { label: "LIMITED LIABILITY PARTNERSHIP", value: "E" },
    { label: "FIRM", value: "F" },
    { label: "GOVERNMENT", value: "G" },
    { label: "HINDU UNDIVIDED FAMILY", value: "H" },
    { label: "ARTIFICIAL JURIDICAL PERSON", value: "J" },
    { label: "LOCAL AUTHORITY", value: "L" }
];

const useStyles = makeStyles(theme => ({
    root: {
        "& .action-grid": {
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10
        },
        "& .submit-button": {
            minHeight: "auto",
            minWidth: "auto",
            backgroundColor: theme.palette.buttons.quarternary,
            color: theme.palette.buttons.quarternaryContrastText,
            boxShadow: "0 14px 26px -12px rgba(248, 150, 5, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 150, 5, 0.2)",
            border: "none",
            borderRadius: "3px",
            position: "relative",
            padding: "12px 30px",
            margin: ".3125rem 1px",
            fontSize: "12px",
            fontWeight: "400",
            textTransform: "uppercase",
            letterSpacing: "0",
            willChange: "box-shadow, transform",
            transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
            lineHeight: "1.42857143",
            textAlign: "center",
            whiteSpace: "nowrap",
            verticalAlign: "middle",
            touchAction: "manipulation",
            cursor: "pointer"
        }
    }
}));
