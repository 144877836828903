import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import WebApi from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateTimeField from 'oahoc/OaFormDateTimeField';
import OaFormSaveButton from 'oahoc/OaFormSaveButton';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import { InputAdornment } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import OaCard from 'oahoc/OaCard';


const CommissionEditForm = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [agencyId, setAgencyId] = useState(props.agencyId);


    useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId])

    return(        
    <>
        {isLoading && <OaLoading />}
        {!isLoading && 
        <>
        <h4 className={"oa-form-header"}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}} >
            Update Commission
            </h4>
            <GridItem xs={12} sm={12} md={8}> 

    <OaFormAlerts 
        isSaving={isSaving} 
        isSuccess={isSuccess} 
        isError={isError} 
        infoText={infoText} 
        setIsError={setIsError} 
        setIsSuccess={setIsSuccess} 
    />
        <Formik 
            initialValues={{
                carrierName: props.initialParams.carrierName,
                commissionType: props.initialParams.commissionType == "P" ? "Fixed Percentage":"Variable",
                validFrom: props.initialParams.startDate,
                validTo: props.initialParams.endDate,
                onGross: props.initialParams.Tp_discount_gross,
                onBasic: props.initialParams.Tp_discount_basic,
                actualDiscount: props.initialParams.TA_Discount,
                dsaShare:props.initialParams.Tdsa_share,
                maxOnGross: props.initialParams.Cp_discount_gross,
                maxOnBasic: props.initialParams.Cp_discount_basic,
                maxOnActualDiscount:props.initialParams.CA_Discount,
                maxDsaShare:props.initialParams.Cdsa_share,
            }}
            validationSchema={Yup.object().shape({
                onGross: Yup.number()
                    .required('Discount is required')
                    .typeError('Invalid discount')
                    .min(0, 'Invalid discount')
                    .max(
                        props.initialParams.Cp_discount_gross, 
                        `Discount should not be greater than ${props.initialParams.Cp_discount_gross}%`
                    ),

                    onBasic: Yup.number()
                    .required('Discount is required')
                    .typeError('Invalid discount')
                    .min(0, 'Invalid discount')
                    .max(
                        props.initialParams.CA_Discount, 
                        `Discount should not be greater than ${props.initialParams.CA_Discount}%`
                    ),

                    actualDiscount: Yup.number()
                    .required('Actual Discount is required')
                    .typeError('Invalid discount')
                    .min(0, 'Invalid discount')
                    .max(
                        props.initialParams.Cp_discount_basic, 
                        `Actual Discount should not be greater than Rs. ${props.initialParams.Cp_discount_basic}`
                    ),

                    dsaShare: Yup.number()
                    .required('Share is required')
                    .typeError('Invalid share')
                    .min(0, 'Invalid share')
                    .max(
                        props.initialParams.Cdsa_share, 
                        `Share should not be greater than ${props.initialParams.Cdsa_share}%`
                    ),              
                    
            })}
            onSubmit= {(searchParams, {setSubmitting, resetForm}) => {
                var requestParams = {
                    "actualAmount": props.initialParams.A_Amount,
                    "agentDiscountId": props.initialParams.agentDiscountId,
                    "agentId": agencyId,
                    "cId": props.initialParams.cId,
                    "dsaShare": searchParams.dsaShare,
                    "onActualDiscount": searchParams.actualDiscount,
                    "onBasicDiscount": searchParams.onBasic,
                    "onGrossDiscount": searchParams.onGross,
                    "requestFlag": props.requestFlag,
                    "uId": props.initialParams.uId
                }
                // console.log(requestParams);

                apiCall(WebApi.updateAgentDiscount, requestParams, (response) => {
                    setIsError(!response.success);
                    setIsSuccess(response.success);
                    setSubmitting(false);
                    setIsSaving(false);
                    setInfoText(response.message);
                    if(response.success === true) {
                        /**Go back to previous page */
                    }
                });
                
            }}>
            {({ values, isSubmitting, setFieldValue, handleChange }) => (
                <Form>
                    <OaCard>
                    <GridContainer>
                        <OaFormTextField 
                        name="carrierName" 
                        label="Carrier"
                        value={values.carrierName}
                        disabled={true}
                        md={6}/>

                        <OaFormTextField 
                        name="commissionType" 
                        label="Commission Type"
                        value={values.commissionType}
                        disabled={true}
                        md={6}/>

                        <OaFormTextField 
                            value={values.validFrom}
                            selected={values.validFrom}
                            label="Valid from"
                            name="validFrom"
                            disabled={true}
                            md={6}
                            InputProps={{startAdornment: (<InputAdornment position="start"><CalendarTodayIcon /></InputAdornment>)}}
                        />

                        <OaFormTextField 
                            value={values.validTo}
                            selected={values.validTo}
                            label="Valid Till"
                            name="validTo"
                            disabled={true}
                            md={6}
                            InputProps={{startAdornment: (<InputAdornment position="start"><CalendarTodayIcon /></InputAdornment>)}}
                        />
                        </GridContainer>
                        </OaCard>
                        <OaCard>
                            <GridContainer>
                        {props.initialParams.commissionType === 'P'
                            && 
                            <>
                            <GridItem xs={12} sm={12} md={6}>
                            <OaFormTextField 
                                name='onGross'
                                label={`On Gross (max. ${values.maxOnGross}%)` }
                                value={values.onGross}  
                                InputProps={{startAdornment: (<InputAdornment position="start">%</InputAdornment>)}}
                            />
                            <ErrorMessage name="onGross" component="div" className="error"/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                            <OaFormTextField 
                                name="onBasic"
                                label={`On Basic (max. ${values.maxOnBasic}%)` } 
                                value={values.onBasic}
                                InputProps={{startAdornment: (<InputAdornment position="start">%</InputAdornment>)}}
                            />
                            <ErrorMessage name="onBasic" component="div" className="error"/>
                            </GridItem>
                            
                            <GridItem xs={12} sm={12} md={6}>
                            <OaFormTextField 
                                name="actualDiscount"
                                label={`Actual Discount (max. Rs. ${values.maxOnActualDiscount})` } 
                                value={values.actualDiscount}
                                InputProps={{startAdornment: (<InputAdornment position="start">Rs.</InputAdornment>)}}
                            />
                            <ErrorMessage name="actualDiscount" component="div" className="error"/>
                            </GridItem>
                            </>
                        }

                    {props.initialParams.commissionType === 'V' && 
                        <GridItem xs={12} sm={12} md={6}>
                            <OaFormTextField 
                                name="dsaShare" 
                                label={`Agent Share (max. ${values.maxDsaShare}%)`}
                                value={values.dsaShare}
                                InputProps={{startAdornment: (<InputAdornment position="start">%</InputAdornment>)}}
                            />
                            <ErrorMessage name="dsaShare" component="div" className="error"/>
                        </GridItem>
                        }   
                    </GridContainer>
                    </OaCard>
                    <OaFormSaveButton isSaving={isSaving}  label={'Update'} />
                </Form>
            )}
        </Formik>
        </GridItem>
        </>
        }
    </>
    )
}

export default CommissionEditForm;