import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import funnel from "assets/icons/funnel.png";
import { makeStyles } from "@material-ui/core/styles";
import FlightSearchFilter from "./flightSearchFilter";
import { Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMdDevice = useMediaQuery(theme.breakpoints.between(991, 1500));

  const onCloseDrawer = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (
      isMdDevice &&
      props.tripMode == "ROUND" &&
      props.showNetFare 
    ) {
      setOpen(true);
    }
  }, [props.tripMode, props.showNetFare]);

  return (
    <div>
      <React.Fragment>
        <Button
          onClick={() => setOpen(true)}
          disabled={props.disabled || false}
          className={classes.filterButton}
        >
          <>
            <img src={funnel} />
          </>
        </Button>
        {/* <Drawer
          open={open}
          onClose={onCloseDrawer}
          className="flight-filter-drawer-root"
          style={{ height: "100vh" }}
        > */}
        <Grid
          className="flight-filter-drawer-root"
          style={{
            display: !open && "none",
            position: "fixed",
            height: "100vh",
            top: 0,
            zIndex: 100,
            backgroundColor: "#fff",
          }}
        >
          <div className="drawer-close-btn-holder">
            <IconButton onClick={onCloseDrawer} className="drawer-close-button">
              <Close />
            </IconButton>
          </div>
          {/* {list()} */}
          <FlightSearchFilter {...props} />
          <Grid
            onClick={onCloseDrawer}
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            className={classes.applyBtn}
          >
            APPLY
          </Grid>
        </Grid>
        {/* )} */}
        {/* </Drawer> */}
      </React.Fragment>
      <style>
        {`
          .flight-filter-drawer-root .MuiPaper-root.MuiDrawer-paper {
            position: relative;
            height: 100vh,
            "& .applyBtn": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.background.default,
              fontWeight: 700,
              height: 50,
              alignItems: "center",
            },
          }
          .drawer-close-btn-holder {
            width: 100%;
            text-align: left;
            display: flex;
            justify-content: flex-end;
            padding: 2px;
          }
          .drawer-close-button {
           
            cursor: pointer;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            z-index: 3;
          }
          .drawer-close-button:hover {
            color: ${theme.palette.secondary.main}
          }
          @media all and (max-width: 1499px) and (min-width: 992px) {
            .drawer-close-button {
              display: flex;
            }
          }
        `}
      </style>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  applyBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontWeight: 700,
    height: 50,
    alignItems: "center",
  },
  filterButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 30px 30px 0",
    color: theme.palette.primary.contrastText,
    width: 160,
    // marginTop: 30,
    "& svg": {
      color: theme.palette.primary.contrastText,
      fontSize: "2rem",
    },
    [theme.breakpoints.up(991)]: {
      display: "none",
      borderRadius: "50%",
      width: 45,
      height: 45,
      boxShadow: `0px 0px 30px 0px rgba(140,140,140,1)`,
      backgroundColor: "rgba(255,255,255,0.6)",
      "& svg": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.9) !important",
      },
      "&.MuiButton-root": {
        minWidth: 45,
      },
    },
    [theme.breakpoints.down(400)]: {
      width: 100,
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.sub,
    },
  },
}));
