import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import{useTheme} from '@material-ui/core';
const OaError = (props)  => {
    // console.log(props);
    const theme=useTheme();
    return (
        <div>
        <div style={{padding: "20px 0 0 0", display: "inline-block", fontWeight: "600", color: theme.palette.primary. brightRed}}>
            <WarningIcon color="inherit" size={20} style={{padding: 0}}/> 
            <span style={{display: "inline-block", verticalAlign: "top", fontSize: "16px"}}>There was an error</span> 
        </div>
        <div style={{fontSize: "10px"}}>{props.details}</div>
        </div>
    )
}

export default OaError;