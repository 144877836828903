import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import teerathgarh from "assets/EasyTravelsOnline/img/teerathgarh_waterfalls.jpg";
import narayanpal from "assets/EasyTravelsOnline/img/narayanpal_temple.jpg";
import kanger from "assets/EasyTravelsOnline/img/kanger_national_park.jpg";
import kailash from "assets/EasyTravelsOnline/img/kailash_caves.jpg";

const useStyles = makeStyles(theme => ({
    pageRoot: {
        background:theme.palette.primary.contrastText,
        boxSizing: "border-box",
        fontFamily: 'Roboto',
        fontWeight: 500,
        [theme.breakpoints.down(1200)]: {
            marginTop: 40,
        },
        "& .section-container": {
            transition: "background .3s,border .3s,border-radius .3s,box-shadow .3s",
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            position: "relative",
            display: "block",
        },
        "& .info-section": {
            paddingBottom: 60,
            paddingTop: 40,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .divider-comp": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 4,
            padding: "15px 0",
            "& .divider-dot": {
                height: 4,
                width: 4,
                borderRadius: "50%",
                margin: "0 5px 0 0",
                backgroundColor: theme.palette.background.darkSkyBlue,
            },
            "& .divider-line": {
                height: 4,
                width: 30,
                borderRadius: 25,
                backgroundColor: theme.palette.background.darkSkyBlue,
            },
        },
        "& .stours-images": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px 15vw",
            textAlign: "center",
            backgroundColor: theme.palette.background.aliceBlue,
            [theme.breakpoints.down(1280)]: {
                padding: "30px 15px",
            },
            [theme.breakpoints.down(825)]: {
                flexDirection: "column",
            }
        },
        "& .stours-image-wrap": {
            width: "25%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: 200,
            margin: 10,
            position: "relative",
            [theme.breakpoints.down(825)]: {
                width: "100%",
            },
            "&:hover .stours-image-overlay": {
                display: "flex",
            },
        },
        "& .stours-image-overlay": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,0.5)",
            transition: "all .3s ease .1s",
            "& p": {
                color:theme.palette.primary.contrastText,
            },
            "& .img-title": {
                fontSize: 18,
                fontWeight: 600,
            },
        },
        "& .stours-title-caption": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:theme.palette.background.aliceBlue,

            padding: "35px 125px",
            [theme.breakpoints.down(600)]: {
                padding: "35px 15px",
            },
            "& .stours-title-wrapper": {
                textAlign: "center",
                margin: "20px 20px 10px",
                "& span": {
                    fontSize: 36,
                    fontWeight: 500,
                    color: theme.palette.primary.defaultText,
                    lineHeight: "45px",
                    [theme.breakpoints.down(959)]: {
                        fontSize: 22,
                        lineHeight: "28px",
                    },
                },
            },
            "& .stours-caption-wrapper": {
                textAlign: "center",
                margin: "20px 20px 0",
            },
        },
        "& .section-info-title-wrap": {
            textAlign: "center",
        },
        "& .section-info-desc-wrap": {
            display: "flex",
            maxWidth: 1280,
            [theme.breakpoints.down(550)]: {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
        },
        "& .section-info-desc-col": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: 20,
            textAlign: "center",
        },
        "& .section-info-icon": {
            background:theme.palette.primary.defaultText,
            borderRadius: "50%",
            width: 70,
            height: 70,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            "& i": {
                color:theme.palette.primary.contrastText,
                fontSize: 20,
            },
        },
        "& .section-info-title": {
            fontSize: 20,
            fontWeight: 500,
            padding: 8,
        },
        "& .section-info-text": {
            padding: "8px 30px",
            [theme.breakpoints.down(825)]: {
                padding: 8,
            },
        },
    },
    sectionTitle: {
        display: "flex",
        fontFamily: theme.palette.font.primary,
        position: "relative",
        paddingTop: 120,
        paddingBottom: 70,
        [theme.breakpoints.down(1200)]: {
            paddingTop: 60,
            paddingBottom: 60,
        },
        [theme.breakpoints.down(600)]: {
            paddingTop: 20,
            paddingBottom: 30,
        },
        "& .row-left": {
            paddingLeft: "20vw",
            [theme.breakpoints.down(960)]: {
                paddingLeft: 20,
            },
            [theme.breakpoints.down(600)]: {
                padding: 10,
            },
            "& .section-title-container": {
                position: "relative",
                "& .section-title-dash": {
                    position: "absolute",
                    border: `solid 1px ${theme.palette.secondary.babyPink}`,
                    top: -20,
                    bottom: -20,
                    left: 0,
                    [theme.breakpoints.down(960)]: {
                        display: "none",
                    },
                },
                "& .section-title-wrapper": {
                    fontSize: 70,
                    marginLeft: 30,
                    [theme.breakpoints.down(960)]: {
                        textAlign: "center",
                    },
                    "& .title-top": {
                        fontWeight: 600,
                        color:theme.palette.primary.veryLightPink,
                        marginBottom: 50,
                    },
                    "& .title-top-small": {
                        fontSize: 48,
                        fontWeight: 600,
                        color:theme.palette.primary.veryLightPink,
                        marginBottom: 50,
                    },
                    "& .title-bottom": {
                        fontWeight: 300,
                        color:theme.palette.primary.active,
                    },
                    [theme.breakpoints.down(1200)]: {
                        fontSize: 48,
                        "& .title-top": {
                            marginBottom: 30,
                        },
                        "& .title-top-small": {
                            fontSize: 32,
                            marginBottom: 30,
                        }
                    }
                },
            },
            "& .section-description": {
                marginTop: 60,
                marginLeft: 30,
                fontSize: 14,
                padding: "0 20vw 0 0",
                color: theme.palette.primary.darkText,
                [theme.breakpoints.down(1200)]: {
                    fontSize: 12,
                    marginTop: 45,
                },
                [theme.breakpoints.down(960)]: {
                    padding: "0 20px 0",
                    textAlign: "center",
                },
                [theme.breakpoints.down(600)]: {
                    padding: 10,
                    marginLeft: 0,
                }
            },
            [theme.breakpoints.down(600)]: {
                padding: 0, 
            }
        },
        "& .row-right": {
            position: "absolute",
            top: 150,
            right: 0,
            "& span": {
                fontFamily: theme.palette.font.primary,
                fontSize: 215,
                fontWeight: 900,
                color: theme.palette.text.light,
                [theme.breakpoints.down(1500)] : {
                    fontSize: 140,
                }
            },
            [theme.breakpoints.down(1200)]: {
                top: 96,
            },
            [theme.breakpoints.down(960)]: {
                display: "none",
            }
        }
    },
}));

const DividerComp = () => {
    return (
        <div className="divider-comp">
            <span className="divider-dot"></span>
            <span className="divider-dot"></span>
            <span className="divider-dot"></span>
            <span className="divider-line"></span>
        </div>
    );
}

const BastarPageSectionTitle = ({titleUpper, titleLower, titleDescription}) => {
    const titleClasses = useStyles();

    return (
        <div>
            
            <div className={titleClasses.sectionTitle}>
                <div className="row-left">
                    <div className="section-title-container">
                        <div className="section-title-dash"></div>

                        <div className="section-title-wrapper">
                            <div className="title-top">
                                {titleUpper}
                            </div>
                            <div className="title-bottom">
                                {titleLower}
                            </div>
                        </div>
                    </div>

                    <div className="section-description">
                        <span>
                            {titleDescription}
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

const BastarPage = (props) => {

    const classes = useStyles();

    const bastarShortDesc = "Bastar is a tribal region in Chhattisgarh, blessed with an exceptional scenic beauty that beckons the lovers of nature to explore the forests, waterfalls, wildlife, ancient temples, tribal dances and music. Located at a distance of 264 km from Raipur, Bastar is one of the most significant eco-friendly destinations of the country. It has plenty to offer to its visitors with its valuable tribal art and culture that lie in and around the lush green city of Jagdalpur. One can choose from National Parks, waterfalls, natural caves, palaces, museums and places of religious significance"

    return (
        <div className={classes.pageRoot}>
            <div className="section-container">
                <BastarPageSectionTitle
                    titleUpper="BASTAR,"
                    titleLower="Chhattisgarh"
                    titleDescription={bastarShortDesc}
                />
            </div>

            <div className="section-container">
                <div className="stours-title-caption">
                    <div className="stours-title-wrapper">
                        <span>
                            WHAT TO SEE IN BASTAR
                        </span>
                    </div>
                    <div className="stours-caption-wrapper">
                        <p>
                            Chhattisgarh was the region known as Dakshin Koshal, which finds mention in both the Ramayana and Mahabharata
                        </p>
                    </div>
                    
                    <DividerComp />
                </div>
            </div>

            <div className="section-container">
                <div className="stours-images">

                    <div className="stours-image-wrap"
                        style={{backgroundImage: `url(${narayanpal})`}}
                    >
                        <div className="stours-image-overlay">
                            <p className="img-title">Narayanpal Temple</p>
                            <p>Jagdalpur</p>
                        </div>
                    </div>

                    <div className="stours-image-wrap"
                        style={{backgroundImage: `url(${kailash})`}}
                    >
                        <div className="stours-image-overlay">
                            <p className="img-title">Kailash Cave</p>
                            <p>Kanger Valley</p>
                        </div>
                    </div>

                    <div className="stours-image-wrap"
                        style={{backgroundImage: `url(${teerathgarh})`}}
                    >
                        <div className="stours-image-overlay">
                            <p className="img-title">Teerathgarh Waterfalls</p>
                            <p>Jagdalpur</p>
                        </div>
                    </div>

                    <div className="stours-image-wrap"
                        style={{backgroundImage: `url(${kanger})`}}
                    >
                        <div className="stours-image-overlay">
                            <p className="img-title">Kanger National Park</p>
                            <p>Kanger Valley</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-container info-section">
                
                <div className="section-info-title-wrap">
                    <div className="section-info-title">
                        <span>HOW TO REACH HERE</span>
                    </div>
                    <div className="section-info-text">
                        <p>
                            Chhattisgarh was the region known as Dakshin Koshal, which finds mention in both the Ramayana and Mahabharata.
                        </p>
                    </div>
                </div>

                <div className="section-info-desc-wrap">
                    <div className="section-info-desc-col">
                        <div className="section-info-icon">
                            <span>
                                <i className="fas fa-train"></i>
                            </span>
                        </div>
                        <div className="section-info-title">
                            <span>By Rail</span>
                        </div>
                        <div className="section-info-text">
                            <p>
                                Raipur Railway Station is one of the main station on Mumbai-Kolkata Train Route.
                            </p>
                        </div>
                    </div>

                    <div className="section-info-desc-col">
                        <div className="section-info-icon">
                            <span>
                                <i className="fas fa-bus"></i>
                            </span>
                        </div>
                        <div className="section-info-title">
                            <span>By Road</span>
                        </div>
                        <div className="section-info-text">
                            <p>
                                Raipur City is a major city on Mumbai-Kolkata Highway (NH 6).
                            </p>
                        </div>
                    </div>

                    <div className="section-info-desc-col">
                        <div className="section-info-icon">
                            <span>
                                <i className="fas fa-plane"></i>
                            </span>
                        </div>
                        <div className="section-info-title">
                            <span>By Air</span>
                        </div>
                        <div className="section-info-text">
                            <p>
                                Swami Vivekanand Airport Mana is located at a distance of 15 kms from Raipur City.
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default BastarPage;
