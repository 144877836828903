import {
  Box,
  Grid,
  Icon,
  Modal,
  Tab,
  Tabs,
  Typography,
  useTheme
} from "@material-ui/core";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import OaLoading from "pages/components/OaLoading";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import StarRating from "../StarRating/StarRating";
import useStyles from "./HotelListViewStyle";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} sx={{ height: 280, overflowY: "auto" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const HotelMoreDetails = (props) => {
  const {
    open,
    anchorEl,
    handleClose,
    hotel,
    resultIndex,
    hotelCode,
    traceId,
    categoryId,
  } = props;
  const classes = useStyles();
  const [images, setImages] = useState([{ src: hotel.hotelPicture, alt: "" }]);
  const [hotelInfo, setHotelInfo] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: theme.palette.background.light,
    border: `1px solid ${theme.palette.background.fortressGray}`,
    borderRadius: 12,
    boxShadow: 24,
    p: 4,
    width: 700,
    padding: 20,
  };

  useEffect(() => {
    // console.log("HOTEL MORE DETAIL MODAL", traceId);
    setIsLoading(true);
    apiCall(
      WebApi.getHotelInfo,
      {
        resultIndex: resultIndex,
        hotelCode: hotelCode,
        traceId: traceId,
        categoryId: categoryId,
      },
      (response) => {
        if (response.success === true) {
          let images = [];
          response.data.hotelSearchResult.hotelDetails.images &&
            response.data.hotelSearchResult.hotelDetails.images.map(
              (url, index) => {
                images.push({ src: url, alt: "" });
              }
            );

          setImages(images);
          //  console.log("%%%%%%%%",response.data)
          let hotelFacilities =
            (response.data.hotelSearchResult.hotelDetails.hotelFacilities &&
              response.data.hotelSearchResult.hotelDetails.hotelFacilities
                .length == 1) ||
              0
              ? response.data.hotelSearchResult.hotelDetails.hotelFacilities[0]?.split(
                ","
              )
              : response.data.hotelSearchResult.hotelDetails.hotelFacilities;
          setFacilities(hotelFacilities);

          setHotelInfo(response.data.hotelSearchResult);
          setIsLoading(false);
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
          setIsLoading(false);
        }
      }
    );
  }, []);

  // console.log("first", images, hotelInfo, facilities);
  return (
    <Modal
      open={open}
      // anchorEl={anchorEl}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        item
        container
        xs={12}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "#fff",
          // border: `1px solid ${theme.palette.secondary.silver}`,
          border: `1px solid #b8b8b8`,
          borderRadius: 12,
          boxShadow: 24,
          p: 4,
          width: 700,
          padding: 20,
        }}
      >
        {isLoading && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <OaLoading />
          </div>
        )}
        {!isLoading && (
          <>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.modalHeading}>
                  {`${hotelInfo?.hotelDetails?.hotelName}, ${hotelInfo?.hotelDetails?.starRating} star hotel`}
                </span>
                <div
                  style={{
                    borderRadius: "50px",
                    background: theme.palette.background.fortressGray,
                    height: 25,
                    width: 23,
                  }}
                >
                  <Icon onClick={handleClose} style={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <StarRating rating={hotelInfo?.hotelDetails?.starRating} />
              </div>
              <span className={classes.hotelSubHeading}>
                {hotelInfo?.hotelDetails?.address}
              </span>
              <span className={classes.hotelSubHeading}>
                Pin Code:- {hotelInfo?.hotelDetails?.pinCode}
              </span>
              <span className={classes.hotelSubHeading}>
                Contact No.:- {hotelInfo?.hotelDetails?.hotelContactNo}
              </span>
            </Grid>
            <Box className={classes.modalTabs}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Description"
                  {...a11yProps(0)}
                  sx={{ width: 200 }}
                />
                <Tab label="Photos" {...a11yProps(1)} />
                <Tab label="Amenities" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: hotelInfo?.hotelDetails?.description,
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid item className={classes.modalCarousel}>
                  <Carousel
                    autoPlay
                    infiniteLoop
                    useKeyboardArrows
                    showThumbs={false}
                    showIndicators={false}
                    stopOnHover={true}
                  >
                    {images &&
                      images.map((value, index) => {
                        return (
                          <div>
                            <img src={value.src} height="200" width="400" />
                          </div>
                        );
                      })}
                  </Carousel>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid>
                  <span className={classes.modalHeading}>Hotel facilities</span>
                  <ul>
                    {hotelInfo?.hotelDetails?.hotelFacilities.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </Grid>
              </TabPanel>
            </Box>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: hotelInfo?.hotelDetails?.description,
              }}
            /> */}
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default HotelMoreDetails;
