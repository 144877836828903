import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BsBank } from "react-icons/bs";
import { ErrorMessage } from "formik";
import OaFormikErrorAlert from "oahoc/OaFormikErrorAlert";
import WebApi from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";

const GstDetails = (props) => {
  const classes = useStyles();
  const [isTrue, setIsTrue] = React.useState(false);
  const [savedGST, setSavedGST] = useState([]);

  const [partnerId, setPartnerId] = useState();
  // const [userType, setUserType] = useState();
  const [userData, setUserData] = useState();

  const getValuesFromLocalStorage = () => {
    // setIsLoading(true);
    localforageGetItem("user-details", function (err, value) {
      // console.log(value);
      setUserData(value);
      setPartnerId(value.partnerId);
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
    // console.log(props.paxinfo.isDomestic, "ISDOMESTIC");
  }, []);

  const onChangeGstNumber = (e) => {
    WebApi.getFlightSavedGST(
      {
        agentType: userData.userTypeAbv,
        domesticInternational: props.paxinfo.isDomestic === true ? "D" : "I",
        loggedInUserId: userData.userId,
        searchString: e.target.value,
        paxType: "",
      },
      (response) => {
        // console.log(response);
        // console.log(response.data.travelers);
        setSavedGST(response.data.gst);
        // resolve(response);
      },
      (error) => {
        console.log("trn create error", error);
        // reject(error);
      }
    );
    props.setFieldValue("gstNumber", e.target.value);
  };

  const onChangeGstCompany = (e) => {
    props.setFieldValue("gstCompany", e.target.value);
  };
  const onChangeCompanyNumber = (e) => {
    props.setFieldValue("gstCompanyNumber", e.target.value);
  };
  const onChangeCompanyAddress = (e) => {
    props.setFieldValue("gstCompanyAddress", e.target.value);
  };
  const onChangeGstEmail = (e) => {
    props.setFieldValue("gstEmail", e.target.value);
  };
  const onClickHasGST = (e) => {
    setIsTrue(e.target.checked);
    props.setFieldValue("hasGst", e.target.checked);
  };

  React.useEffect(() => {
    if (props.isGSTMandate) {
      setIsTrue(props.isGSTMandate);
    }
  }, [props.isGSTMandate]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12}>
          <div className={"no-bg-div"}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  label={
                    <Typography style={{ color: "#000" }}>
                      <span style={{ fontWeight: "bold", margin: "0.5rem" }}>
                        <BsBank />
                      </span>
                      GST
                    </Typography>
                  }
                  control={
                    <Checkbox
                      value={props.values?.hasGst}
                      checked={props.values?.hasGst}
                      onChange={onClickHasGST}
                      name="hasGst"
                      disabled={props?.isGSTMandate}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>

      <Collapse in={isTrue}>
        <Grid container className="input-section">
          <Grid item md={12}>
            <Box className={classes.paxInputSection}>
              <Grid container style={{ marginTop: 10 }}>
                <Grid item md={3} xs={12}>
                  <div className="form-group">
                    <TextField
                      name="gstNumber"
                      placeholder="GST Number"
                      fullWidth
                      variant="outlined"
                      value={props.values?.gstNumber}
                      onChange={onChangeGstNumber}
                    />
                    {savedGST.length > 0 && (
                      <div className="savedPaxsContainer">
                        {savedGST.map((gst) => (
                          <div className="savedPaxContainer">
                            <div
                              onClick={() => {
                                //     console.log(gst);
                                props.setFieldValue("gstNumber", gst.gstNumber);
                                props.setFieldValue(
                                  "gstCompany",
                                  gst.gstCompany
                                );
                                props.setFieldValue(
                                  "gstCompanyAddress",
                                  gst.gstAddress
                                );
                                props.setFieldValue("gstEmail", gst.gstEmail);
                                props.setFieldValue(
                                  "gstCompanyNumber",
                                  gst.gstMobile
                                );
                                // props.setFieldValue("gstMobile", gst.gstCompanyAddress);
                                setSavedGST([]);
                              }}
                            >
                              {gst.gstNumber}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <ErrorMessage
                      name="gstNumber"
                      component="div"
                      className="error"
                    />
                    <OaFormikErrorAlert
                      name="gstNumber"
                      error={props.error}
                      touched={props.touched}
                    />
                  </div>
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className="form-group">
                    <TextField
                      name="gstCompany"
                      placeholder="Company Name"
                      fullWidth
                      variant="outlined"
                      value={props.values?.gstCompany}
                      onChange={onChangeGstCompany}
                    />
                    <ErrorMessage
                      name="gstCompany"
                      component="div"
                      className="error"
                    />
                    <OaFormikErrorAlert
                      name="gstCompany"
                      error={props.error}
                      touched={props.touched}
                    />
                  </div>
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className="form-group">
                    <TextField
                      name="gstCompanyNumber"
                      placeholder="Company Contact"
                      fullWidth
                      variant="outlined"
                      value={props.values?.gstCompanyNumber}
                      onChange={onChangeCompanyNumber}
                    />
                    <ErrorMessage
                      name="gstCompanyNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                  <OaFormikErrorAlert
                    name="gstCompanyNumber"
                    error={props.error}
                    touched={props.touched}
                  />
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className="form-group">
                    <TextField
                      name="gstCompanyAddress"
                      placeholder="Company Address"
                      fullWidth
                      variant="outlined"
                      value={props.values?.gstCompanyAddress}
                      onChange={onChangeCompanyAddress}
                    />
                    <ErrorMessage
                      name="gstCompanyAddress"
                      component="div"
                      className="error"
                    />

                    <OaFormikErrorAlert
                      name="gstCompanyAddress"
                      error={props.error}
                      touched={props.touched}
                    />
                  </div>
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className="form-group">
                    <TextField
                      name="gstEmail"
                      placeholder="Company Email"
                      fullWidth
                      variant="outlined"
                      value={props.values?.gstEmail}
                      onChange={onChangeGstEmail}
                    />
                    <ErrorMessage
                      name="gstEmail"
                      component="div"
                      className="error"
                    />
                    <OaFormikErrorAlert
                      name="gstEmail"
                      error={props.error}
                      touched={props.touched}
                    />
                  </div>
                </Grid>

                {/* <Grid item md={3}>
                  <div className={clsx(classes.formGroup)}
                    style={{ display: "flex", flexWrap: "nowrap", cursor: "pointer", paddingTop: 20 }}
                    onClick={() => saveGstInfo(values)}
                  >
                    <span style={{ marginRight: 5, fontSize: 12 }}>
                      <i className={"fas fa-save"}></i>
                    </span>
                    <span style={{ cursor: "pointer", fontSize: 12, fontWeight: 400 }}>
                      Save GST info for future use
                    </span>
                  </div>
                </Grid> */}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};

export default GstDetails;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      // width: "25ch",
      width: "100%",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.main,
    },
    "& .form-group": {
      padding: "5px 0",
      position: "relative",
      "& .savedPaxsContainer": {
        position: "absolute",
        zIndex: 5,
        width: "90%",
        [theme.breakpoints.down("970")]: {
          width: "94%",
        },
        padding: 5,
        backgroundColor: "#fff",
        borderRadius: 10,
        left: 20,
        border: "1px solid #eee",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      },

      "& .savedPaxContainer": {
        backgroundColor: "#fff",
        padding: 5,

        "&:hover": {
          background: "#eee",
          cursor: "pointer",
        },
      },
    },
    "& .input-section": {
      marginTop: 5,
      marginBottom: 5,
    },
    "& .error-msgBox": {
      width: "60%",
      // height:'100vh',
      bottom: 0,
      position: "fixed",
      // position:'absolute',
      top: "10%",
      left: "20%",
      padding: "6px 13px",
      zIndex: 999,
      margin: "10px 0",
      "& .error-container": {
        position: "relative",
        width: "100%",
        padding: "6px 13px",
        margin: "10px 0",
      },
    },
  },
}));
