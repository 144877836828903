
import WebApi from "api/ApiConstants";
//import { POST_PAYU } from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

export async function submitDataToPayU(request) {
    return new Promise(resolve =>{
        apiCall(WebApi.postPayGoalOrderTransact, request, (response) => {
            if (response.success) {
                const formData = {
                    key: response.data.payU.key,
                    txnid: response.data.payU.txnid,
                    productinfo: response.data.payU.productinfo,
                    amount: response.data.payU.amount,
                    email: response.data.payU.email,
                    firstname: response.data.payU.firstname,
                    lastname: response.data.payU.lastname,
                    surl: response.data.payU.surl,
                    furl: response.data.payU.furl,
                    phone: response.data.payU.phone,
                    hash: response.data.payU.hash
                };
                
                submitForm(formData, request.checkoutUrl);
            } else {
                console.log("error", response);
                resolve(response);
            }
        });
    });
}

const submitForm = (formData, checkoutUrl) => {
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action", checkoutUrl);
    formElement.setAttribute("target", "_parent");

    for (let key1 in formData) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key1);
        hiddenField.setAttribute("value", formData[key1]);
        formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
};
