import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { currencyFormatter } from "oautils/oaCommonUtils";
import GridContainer from "components/Grid/GridContainer";
import {
  Divider,
  Typography,
  Button,
  useMediaQuery,
  Icon,
} from "@material-ui/core";
import { GET_FILE_UPLOAD } from "api/ApiConstants";

import "./table.css";
import PackageTabForm from "./PackageTabForm";
import _ from "lodash";
import { TabList } from "@material-ui/lab";
const { forwardRef, useRef, useImperativeHandle } = React;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-fixed": {
      [theme.breakpoints.down(620)]: {
        overflowX: "scroll !important",
      },
    },

    "& .MuiTabs-scroller": {
      [theme.breakpoints.down(620)]: {
        height: 50,
      },
    },
    "& .MuiTabs-indicator": {
      [theme.breakpoints.down(500)]: {
        // borderBottom: "1px solid black",
      },
    },

    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    marginBottom: 20,
    marginTop: "2.7%",
    paddingLeft: 10,
    paddingRight: 10,
    "& .MuiTab-Fixed": {
      [theme.breakpoints.down(620)]: {
        height: 50,
      },
    },
    "& .MuiTab-wrapper": {
      marginBottom: 4,
      [theme.breakpoints.down(500)]: {
        marginBottom: 0,
      },
    },
    "& .MuiTabs-root": {
      minHeight: 40,
    },

    "& .MuiTab-root": {
      minWidth: "70px",
      // borderBottom: "1px solid black",
      // minHeight:"20px",
      // height:41,
      borderRadius: " 10px 10px 0px 0px",
      border: `1px solid ${theme.palette.primary.main}`,
      textTransform: "none",
      color: theme.palette.primary.main,
      "&:hover": {
        background: theme.palette.background.primary,
        color: theme.palette.primary.lightText,
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: theme.palette.background.primary,
      borderRadius: " 10px 10px 0px 0px",

      color: theme.palette.primary.lightText,
      textTransform: "none",
    },
    "& .MuiBox-root": {
      padding: 20,
      background: theme.palette.primary.lightText,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 2,
      overflowX: "scroll",
    },
  },
  typo: {
    fontSize: "17px",
    fontWeight: 500,
    marginBottom: 5,
    marginTop: 10,
  },
  typo1: {
    fontSize: 16,
    color: theme.palette.secondary.gray,
    lineHeight: 1.4,
  },
}));

const PackageTabDetails = forwardRef((props, ref) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useImperativeHandle(ref, (e) => ({
    setValue,
  }));

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        // variant="scrollable"
        // scrollButtons
        // allowScrollButtonsMobile
        // aria-label="scrollable force tabs example"
        TabIndicatorProps={{
          style: { backgroundColor: "rgba(0,0,0,0)" },
        }}
      >
        <Tab label="Tour Highlights" {...a11yProps(0)} />
        <Tab label="Accomodation" {...a11yProps(1)} />
        <Tab label="Itinerary" {...a11yProps(2)} />
        <Tab label="Request Form" {...a11yProps(3)} />
        <Tab label="Terms & condition" {...a11yProps(4)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        {props.details.map((val) => {
          // console.log(val);
          return (
            <GridContainer style={{ paddingLeft: 15 }}>
              {val.tourHighlights &&
                val.tourHighlights.map((value, ind) => {
                  return (
                    <div key={ind}>
                      <Grid item md={12}>
                        <Typography className={classes.typo}>
                          {value.heading}
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Typography className={classes.typo1}>
                          <ul>
                            {value.description &&
                              value.description?.split("~#~").map((value) => (
                                <li>
                                  <Typography className={classes.typo1}>
                                    {value}
                                  </Typography>
                                </li>
                              ))}
                          </ul>
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}

              {val.packageFeatures && (
                <Grid container style={{ paddingTop: 50 }}>
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <span
                      style={{ margin: -22, background: "white", padding: 10 }}
                    >
                      Attractions
                    </span>
                  </Grid>

                  {val.packageFeatures.map((value, ind) => {
                    return (
                      <Grid
                        item
                        container
                        md={4}
                        xs={6}
                        justifyContent="center"
                        alignItems="center"
                        style={{ padding: 20 }}
                        key={ind}
                      >
                        <Grid container item xs={12} justifyContent="center">
                          {" "}
                          <Icon color={"secondary"}>{value.iconImageName}</Icon>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          {" "}
                          {value.description}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </GridContainer>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GridContainer>
          <Grid item md={12} xs={12}>
            {props.details && props.details.length > 0 && props.details.map((data) =>
              data.accomodation && data.accomodation.length > 0 && data.accomodation.map((value, ind) => {
                // console.log(value.tableInfo)
                return (
                  <div key={ind}>
                    <Typography
                      style={{
                        color: theme.palette.primary.darkGray,
                        fontSize: 14,
                        marginBottom: 10,
                        fontWeight: 600,
                        marginTop: 15,
                      }}
                    >
                      {value.description}
                    </Typography>
                    <table className="table table-bordered" id="students">
                      <thead>
                        <tr>
                          {value.tableInfo
                            .slice(0, value.noOfColoumn)
                            .map((TableValue) => {
                              return (
                                <th scope="rowgroup">
                                  {TableValue.headingDescriptionString?.split(
                                    "&#45;"
                                  )}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {_.chunk(
                          value.tableInfo.slice(value.noOfColoumn),
                          value.noOfColoumn
                        ).map((items, index) => {
                          return (
                            <tr>
                              {items.map((subItems, sIndex) => {
                                return (
                                  <td>
                                    {subItems.headingDescriptionString?.split(
                                      "&#45;"
                                    )}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })
            )}
          </Grid>
        </GridContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
          {props.details && props.details.length > 0 && props.details.map((data) =>
            data?.iternary && data?.iternary.length > 0 && data.iternary[0].descriptipn.map((val, ind) => {
              return (
                <Grid container style={{ padding: 20 }} key={ind}>
                  <Grid item md={12}>
                    <Typography
                      style={{
                        color: theme.palette.primary.defaultText,
                        paddingBottom: isMobile ? 5 : "15px",
                        fontSize: isMobile ? 14 : "17px",
                        marginBottom: "0px",
                        fontWeight: 500,
                      }}
                    >
                      {val.itneryDiscription}
                    </Typography>
                  </Grid>
                  {val.itneryDetailedDiscription &&
                    val.itneryDetailedDiscription.map((detail, index) => {
                      return (
                        <>
                          {index === 0 &&
                            detail.image &&
                            detail.image != "null" && (
                              <Grid item xs={12}>
                                <img
                                  src={
                                    GET_FILE_UPLOAD +
                                    "?fileName=" +
                                    detail.image +
                                    "&type=PROFILE_PHOTO"
                                  }
                                  alt="fight icon"
                                  style={{
                                    height: isMobile ? "288px" : "400px",
                                    width: "100%",
                                    float: "left",
                                    verticalAlign: "middle",
                                    borderStyle: "none",
                                  }}
                                />
                              </Grid>
                            )}

                          <Grid item md={12}>
                            <Typography className={classes.typo}>
                              {" "}
                              {detail.heading}
                            </Typography>
                          </Grid>
                          <Grid item md={12}>
                            <Typography className={classes.typo1}>
                              {" "}
                              {detail.description}
                            </Typography>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              );
            })
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GridContainer>
          <Grid item md={12}>
            <PackageTabForm packageId={props.packageId} />
          </Grid>
        </GridContainer>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <>
          <GridContainer style={{ padding: 15 }}>
            <Grid item>
              {props?.details?.map(
                (value) =>
                  value.termsAndCondition &&
                  value.termsAndCondition?.map((value, ind) => {
                    return (
                      <div key={ind}>
                        <Typography className={classes.typo}>
                          {value?.conditions}
                        </Typography>
                        <ul>
                          {value.impNotes?.split("~#~").map((data) => {
                            return (
                              <li>
                                <Typography className={classes.typo1}>
                                  {data}
                                </Typography>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })
              )}
            </Grid>
          </GridContainer>
        </>
      </TabPanel>
    </div>
  );
});

export default PackageTabDetails;
