import evening from "assets/icons/FilterTimings/cloudyNight.svg";
import dawn from "assets/icons/FilterTimings/dawn.svg";
import night from "assets/icons/FilterTimings/night.svg";
import morning from "assets/icons/FilterTimings/partlySunny.svg";
import afternoon from "assets/icons/FilterTimings/sunny.svg";
import dateFnsFormat from "date-fns/format";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import {
  Grid,
  IconButton,
  Slider,
  Input,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import GEN from "assets/icons/all.png";

let airlineStop = [];

const FlightSearchFilter = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [MIN, setMIN] = useState(2000);
  const [MAX, setMAX] = useState(100000);
  const [currentFilterByStop, setCurrentFilterByStop] = useState([]);
  const [currentFilterByStopRt, setCurrentFilterByStopRt] = useState([]);

  const [priceSliderValues, setPriceSliderValue] = useState([MIN, MAX]);
  const resetOnwardAirlinesList = props.filters?.onwardCarrierFilter?.map(
    (airline) => {
      return { ...airline, selected: false };
    }
  );
  const [airlinesList, setAirlinesList] = React.useState(
    resetOnwardAirlinesList
  );
  const [airlinesListRt, setAirlinesListRt] = React.useState(
    resetOnwardAirlinesList
  );
  const initialDepartureTime = {
    dawn: false,
    morning: false,
    afternoon: false,
    evening: false,
    //  night: false,
  };
  const initialArrivalTime = {
    dawn: false,
    morning: false,
    afternoon: false,
    evening: false,
    // night: false,
  };
  const [departureTime, setDepartureTime] = React.useState(
    initialDepartureTime
  );

  const [departureTimeRt, setDepartureTimeRt] = React.useState(
    initialDepartureTime
  );

  const [arrivalTime, setArrivaleTime] = React.useState(initialArrivalTime);

  const [arrivalTimeRt, setArrivaleTimeRt] = React.useState(initialArrivalTime);
  const [stops, setStops] = useState([]);
  const [stopsRt, setStopsRt] = useState([]);

  const [openDiv, setOpenDiv] = useState({
    openPriceFilter: true,
    openStopsFilter: true,
    openDepartFilter: true,
    openArriveFilter: true,
    openAirlinesFilter: true,
    openSort: true,
  });

  const [sorted, setSorted] = useState({
    price: false,
    stops: false,
    departureTime: false,
    arrivalTime: false,
    airlines: false,
    stopsRt: false,
    departureTimeRt: false,
    arrivalTimeRt: false,
    airlinesRt: false,
  });

  // filter test

  const [onwardFilter, setOnwardFilter] = useState({
    price: false,
    stops: false,
    departureTime: false,
    arrivalTime: false,
    airlines: false,
  });
  const [returnFilter, setReturnFilter] = useState({
    stops: false,
    departureTime: false,
    arrivalTime: false,
    airlines: false,
  });
  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";

  // onward filter
  useEffect(() => {
    props.setIsLoading(true);
    let filteredData = props?.combineresp;
    // console.log("onward filter", combineFilter);
    if (onwardFilter.price) {
      filteredData = filterByPrice(priceSliderValues, filteredData);
    }
    if (onwardFilter.stops) {
      filteredData = filterByStops(
        stops,
        filteredData,
        props.tripMode,
        "onward"
      );
    }
    if (onwardFilter.airlines) {
      filteredData = filterByAirlines(
        airlinesList,
        filteredData,
        props.tripMode,
        "onward"
      );
    }
    if (onwardFilter.arrivalTime) {
      filteredData = filterByArrivalTime(
        arrivalTime,
        filteredData,
        props.tripMode,
        "onward"
      );
    }
    if (onwardFilter.departureTime) {
      filteredData = filterByDepartureTime(
        departureTime,
        filteredData,
        props.tripMode,
        "onward"
      );
    }
    if (returnFilter.stops) {
      filteredData = filterByStops(
        stopsRt,
        filteredData,
        props.tripMode,
        "return"
      );
    }
    if (returnFilter.airlines) {
      filteredData = filterByAirlines(
        airlinesListRt,
        filteredData,
        props.tripMode,
        "return"
      );
    }
    if (returnFilter.arrivalTime) {
      filteredData = filterByArrivalTime(
        arrivalTimeRt,
        filteredData,
        props.tripMode,
        "return"
      );
    }
    if (returnFilter.departureTime) {
      filteredData = filterByDepartureTime(
        departureTimeRt,
        filteredData,
        props.tripMode,
        "return"
      );
    }
    props.setIsLoading(false);

    props.setmodifiedresponse(filteredData);
  }, [
    priceSliderValues,
    stops,
    airlinesList,
    arrivalTime,
    departureTime,
    stopsRt,
    airlinesListRt,
    arrivalTimeRt,
    departureTimeRt,
  ]);

  const TIME_OF_DAY = [
    { value: "dawn", label: "Before 06AM", icon: "dawn" },
    { value: "morning", label: "06AM - 12PM", icon: "morning" },
    { value: "afternoon", label: "12PM - 06PM", icon: "afternoon" },
    { value: "evening", label: "After 06PM", icon: "evening" },
    //{ value: "night", label: "Night (9PM - 3AM)", icon: night },
  ];

  // const firstArray = [{ "id": 4, "name": "Tata" }, { "id": 11, "name": "Maruti" }, { "id": 14, "name": "Mahindra" } ],
  //     secondArray = [{ "id": 4, "name": "Tata" }, { "id": 11, "name": "Maruti" }, { "id": 15, "name": "Hyundai" }, { "id": 21, "name": "Honda" } ],
  let filteredArrayOnw = TIME_OF_DAY.filter((o) =>
    props.filters.onwardTimeFilter.some(({ label }) => o.label === label)
  );

  let filteredArrayRet = TIME_OF_DAY.filter((o) =>
    props.filters?.returnTimeFilter?.some(({ label }) => o.label === label)
  );

  // console.log(filteredArrayOnw, filteredArrayRet);

  // console.log(filteredArray);

  // DATA
  const STOPS = props.filters.onwardStopsFilter;
  airlineStop = props.filters.onwardCarrierFilter;

  const sortByPrice = (arrayData) => {
    return arrayData.sort(function(a, b) {
      if (a.fares.length > 1) {
        a.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      if (b.fares.length > 1) {
        b.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      let sortKeyA = a.fares[0].baseFare + a.fares[0].tax;
      let sortKeyB = b.fares[0].baseFare + b.fares[0].tax;

      if (parseFloat(sortKeyA) > parseFloat(sortKeyB)) {
        return 1;
      } else if (parseFloat(sortKeyA) < parseFloat(sortKeyB)) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByStops = (arrayData, tripMode) => {
    return arrayData.slice().sort(function(a, b) {
      let sortKeyA =
        tripMode == "ROUND" ? a.returnResp.length : a.onwardResp.length;
      let sortKeyB =
        tripMode == "ROUND" ? b.returnResp.length : b.onwardResp.length;

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByDepTime = (arrayData, tripMode) => {
    return arrayData.slice().sort(function(a, b) {
      let sortKeyA =
        tripMode == "ROUND"
          ? new Date(a.returnResp[0].departDetails.dateTime).getTime()
          : new Date(a.onwardResp[0].departDetails.dateTime).getTime();
      let sortKeyB =
        tripMode == "ROUND"
          ? new Date(b.returnResp[0].departDetails.dateTime).getTime()
          : new Date(b.onwardResp[0].departDetails.dateTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByArrTime = (arrayData, tripMode) => {
    return arrayData.slice().sort(function(a, b) {
      let sortKeyA =
        tripMode == "ROUND"
          ? new Date(
              a.returnResp[a.returnResp.length - 1].arriveDetails.dateTime
            ).getTime()
          : new Date(
              a.onwardResp[a.onwardResp.length - 1].arriveDetails.dateTime
            ).getTime();
      let sortKeyB =
        tripMode == "ROUND"
          ? new Date(
              b.returnResp[b.returnResp.length - 1].arriveDetails.dateTime
            ).getTime()
          : new Date(
              b.onwardResp[b.onwardResp.length - 1].arriveDetails.dateTime
            ).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByAirlines = (arrayData, tripMode) => {
    return arrayData.slice().sort(function(a, b) {
      let sortKeyA =
        tripMode == "ROUND"
          ? a.returnResp[0].carrierDetails.carrierName
          : a.onwardResp[0].carrierDetails.carrierName;
      let sortKeyB =
        tripMode == "ROUND"
          ? b.returnResp[0].carrierDetails.carrierName
          : b.onwardResp[0].carrierDetails.carrierName;

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortData = (type, arrayData, tripMode) => {
    let sortedData = null;

    if (arrayData != null)
      switch (type) {
        case "price": {
          sortedData = sortByPrice(arrayData);
          setSorted({ ...sorted, price: true });
          break;
        }

        case "stops": {
          sortedData = sortByStops(arrayData, tripMode);
          tripMode == "ROUND"
            ? setSorted({ ...sorted, stopsRt: true })
            : setSorted({ ...sorted, stops: true });
          break;
        }

        case "dep-time": {
          sortedData = sortByDepTime(arrayData, tripMode);
          tripMode == "ROUND"
            ? setSorted({ ...sorted, departureTimeRt: true })
            : setSorted({ ...sorted, departureTime: true });
          break;
        }

        case "arr-time": {
          sortedData = sortByArrTime(arrayData, tripMode);
          tripMode == "ROUND"
            ? setSorted({ ...sorted, arrivalTimeRt: true })
            : setSorted({ ...sorted, arrivalTime: true });
          break;
        }

        case "airlines": {
          sortedData = sortByAirlines(arrayData, tripMode);
          tripMode == "ROUND"
            ? setSorted({ ...sorted, airlinesRt: true })
            : setSorted({ ...sorted, airlines: true });
          break;
        }
      }

    sortedData && props.setmodifiedresponse(sortedData);
  };

  const clearSort = (type, tripMode) => {
    props.setmodifiedresponse(props?.combineresp);

    if (tripMode == "ROUND") {
      switch (type) {
        case "stops": {
          setSorted({ ...sorted, stopsRt: false });
          break;
        }

        case "dep-time": {
          setSorted({ ...sorted, departureTimeRt: false });
          break;
        }

        case "arr-time": {
          setSorted({ ...sorted, arrivalTimeRt: false });
          break;
        }

        case "airlines": {
          setSorted({ ...sorted, airlinesRt: false });
          break;
        }

        case "all": {
          setSorted({
            ...sorted,
            stopsRt: false,
            departureTimeRt: false,
            arrivalTimeRt: false,
            airlinesRt: false,
          });
        }
      }
    } else {
      switch (type) {
        case "price": {
          setSorted({ ...sorted, price: false });
          break;
        }

        case "stops": {
          setSorted({ ...sorted, stops: false });
          break;
        }

        case "dep-time": {
          setSorted({ ...sorted, departureTime: false });
          break;
        }

        case "arr-time": {
          setSorted({ ...sorted, arrivalTime: false });
          break;
        }

        case "airlines": {
          setSorted({ ...sorted, airlines: false });
          break;
        }

        case "all": {
          setSorted({
            ...sorted,
            price: false,
            stops: false,
            departureTime: false,
            arrivalTime: false,
            airlines: false,
          });
        }
      }
    }
  };

  const filterByPrice = (priceRange, arrayData) => {
    const lowerLimit = parseFloat(priceRange[0]);
    const upperLimit = parseFloat(priceRange[1]);

    let filteredData = null;

    if (lowerLimit > 0 && upperLimit > 0) {
      setPriceSliderValue(priceRange);
      filteredData = arrayData
        .map((data) => {
          let totalFareRef = [];
          let fareArray = [];
          for (let i = 0; i < data?.fares?.length; i++) {
            let fareObj = data?.fares[i];
            const totalFare =
              parseFloat(fareObj?.baseFare) + parseFloat(fareObj?.tax);
            if (totalFare >= lowerLimit && totalFare <= upperLimit) {
              if (!totalFareRef.includes(totalFare)) {
                totalFareRef = totalFareRef.concat(totalFare);
                fareArray = fareArray.concat(fareObj);
              }
            }
          }
          fareArray = fareArray.sort((fare1, fare2) => {
            const totalFare1 =
              parseFloat(fare1?.baseFare) + parseFloat(fare1?.tax);
            const totalFare2 =
              parseFloat(fare2?.baseFare) + parseFloat(fare2?.tax);
            return totalFare1 - totalFare2;
          });
          return fareArray.length > 0 ? { ...data, fares: fareArray } : 0;
        })
        .filter((data) => data !== 0);
      /* filteredData = arrayData.filter((data) => {
        return (
          parseFloat(data?.fares[0]?.baseFare) +
            parseFloat(data?.fares[0]?.tax) >=
            lowerLimit &&
          parseFloat(data?.fares[0].baseFare) +
            parseFloat(data?.fares[0].tax) <=
            upperLimit
        );
      }); */

      // props.setmodifiedresponse(filteredData);
    } else {
      setPriceSliderValue([MIN, MAX]);
      // filteredData = props?.combineresp;
      filteredData = arrayData;
    }

    // props.setmodifiedresponse(filteredData);
    return filteredData;
  };

  const filterByStops = (stop, arrayData, tripMode, type) => {
    // console.log("length of array", stop);

    let filteredData = null;
    if (stop.length > 0) {
      filteredData = arrayData.filter((data) => {
        let flag = false;
        for (let i = 0; i < stop.length; i++) {
          if (stop[i] > 0) {
            if (stop < 3) {
              flag =
                type == "return"
                  ? data.returnResp.length === stop[i]
                  : data.onwardResp.length === stop[i];
              if (flag) break;
            } else {
              flag =
                type == "return"
                  ? data.returnResp.length >= stop[i]
                  : data.onwardResp.length >= stop[i];
              if (flag) break;
            }
          }
        }
        return flag;
      });
    } else {
      filteredData = arrayData;
    }
    /* if (stop > 0) {
      if (stop < 3) {
        filteredData = arrayData.filter((data) => {
          return tripMode == "ROUND"
            ? data.returnResp.length == stop
            : data.onwardResp.length == stop;
        });
      } else {
        filteredData = arrayData.filter((data) => {
          return tripMode == "ROUND"
            ? data.returnResp.length >= stop
            : data.onwardResp.length >= stop;
        });
      }
    } else {
      // filteredData = props?.combineresp;
      filteredData = arrayData;
    } */

    // props.setmodifiedresponse(filteredData);
    return filteredData;
  };

  const filterByDepartureTime = (timeSpan, arrayData, tripMode, type) => {
    if (timeSpan != null) {
      if (
        timeSpan.dawn == false &&
        timeSpan.morning == false &&
        timeSpan.afternoon == false &&
        timeSpan.evening == false
        //&& timeSpan.night == false
      ) {
        // props.setmodifiedresponse(props?.combineresp);
        return arrayData;
      }

      const filteredData = arrayData.filter((data) => {
        const departureTime = dateFnsFormat(
          new Date(
            type === "return"
              ? data.returnResp[0].departDetails.dateTime
              : data.onwardResp[0].departDetails.dateTime
          ),
          "HH:MM:SS"
        );
        const departureDate = new Date(
          type === "return"
            ? data.returnResp[0].departDetails.dateTime
            : data.onwardResp[0].departDetails.dateTime
        );
        const upperLimitNight = new Date(
          departureDate.getFullYear(),
          departureDate.getMonth(),
          departureDate.getDate() + 1,
          3,
          1
        ).getTime();
        const lowerLimitNight = new Date(
          departureDate.getFullYear(),
          departureDate.getMonth(),
          departureDate.getDate(),
          20,
          59
        ).getTime();

        const timeBucket = [
          {
            name: "dawn",
            upperLimit: "06:00:00",
            lowerLimit: "00:00:00",
            isEnabled: timeSpan.dawn,
          },
          {
            name: "morning",
            upperLimit: "12:00:00",
            lowerLimit: "06:00:00",
            isEnabled: timeSpan.morning,
          },
          {
            name: "afternoon",
            upperLimit: "18:00:00",
            lowerLimit: "12:00:00",
            isEnabled: timeSpan.afternoon,
          },
          {
            name: "evening",
            upperLimit: "23:59:59",
            lowerLimit: "18:00:00",
            isEnabled: timeSpan.evening,
          },
          // {
          //   name: "night",
          //   upperLimit: upperLimitNight,
          //   lowerLimit: lowerLimitNight,
          //   isEnabled: timeSpan.night,
          // },
        ];

        let returnFlag = false;

        for (var i = 0; i < timeBucket.length; i++) {
          const tb = timeBucket[i];

          if (
            (tb.isEnabled == true &&
              tb.name == "night" &&
              departureDate.getTime() < tb.upperLimit &&
              departureDate.getTime() > tb.lowerLimit) ||
            (tb.isEnabled == true &&
              tb.name != "night" &&
              departureTime <= tb.upperLimit &&
              departureTime >= tb.lowerLimit)
          ) {
            returnFlag = true;
            break;
          }
        }

        return returnFlag;
      });

      // props.setmodifiedresponse(filteredData);
      return filteredData;
    } else {
      // props.setmodifiedresponse(props?.combineresp);
      return arrayData;
    }
  };

  const filterByArrivalTime = (timeSpan, arrayData, tripMode, type) => {
    if (timeSpan != null) {
      if (
        timeSpan.dawn == false &&
        timeSpan.morning == false &&
        timeSpan.afternoon == false &&
        timeSpan.evening == false
        //&& timeSpan.night == false
      ) {
        // props.setmodifiedresponse(props?.combineresp);
        return arrayData;
      }

      const filteredData = arrayData.filter((data) => {
        // const lastIndex = data.length - 1;
        const arrivalTime = dateFnsFormat(
          new Date(
            type === "return"
              ? data.returnResp[data.returnResp.length - 1].arriveDetails
                  .dateTime
              : data.onwardResp[data.onwardResp.length - 1].arriveDetails
                  .dateTime
          ),
          "HH:MM:SS"
        );

        const arrivalDate = new Date(
          type === "return"
            ? data.returnResp[data.returnResp.length - 1].arriveDetails.dateTime
            : data.onwardResp[data.onwardResp.length - 1].arriveDetails.dateTime
        );
        // const upperLimitNight = new Date(
        //   arrivalDate.getFullYear(),
        //   arrivalDate.getMonth(),
        //   arrivalDate.getDate() + 1,
        //   3,
        //   1
        // ).getTime();
        // const lowerLimitNight = new Date(
        //   arrivalDate.getFullYear(),
        //   arrivalDate.getMonth(),
        //   arrivalDate.getDate(),
        //   20,
        //   59
        // ).getTime();

        const timeBucket = [
          {
            name: "dawn",
            upperLimit: "06:00:00",
            lowerLimit: "00:00:00",
            isEnabled: timeSpan.dawn,
          },
          {
            name: "morning",
            upperLimit: "12:00:00",
            lowerLimit: "06:00:00",
            isEnabled: timeSpan.morning,
          },
          {
            name: "afternoon",
            upperLimit: "18:00:00",
            lowerLimit: "12:00:00",
            isEnabled: timeSpan.afternoon,
          },
          {
            name: "evening",
            upperLimit: "23:59:50",
            lowerLimit: "18:00:00",
            isEnabled: timeSpan.evening,
          },
          // {
          //   name: "night",
          //   upperLimit: upperLimitNight,
          //   lowerLimit: lowerLimitNight,
          //   isEnabled: timeSpan.night,
          // },
        ];

        let returnFlag = false;

        for (var i = 0; i < timeBucket.length; i++) {
          const tb = timeBucket[i];

          if (
            (tb.isEnabled == true &&
              tb.name == "night" &&
              arrivalDate.getTime() < tb.upperLimit &&
              arrivalDate.getTime() > tb.lowerLimit) ||
            (tb.isEnabled == true &&
              tb.name != "night" &&
              arrivalTime <= tb.upperLimit &&
              arrivalTime >= tb.lowerLimit)
          ) {
            returnFlag = true;
            break;
          }
        }

        return returnFlag;
      });

      // props.setmodifiedresponse(filteredData);
      return filteredData;
    } else {
      // props.setmodifiedresponse(props?.combineresp);
      return arrayData;
    }
  };

  const filterByAirlines = (airlineList, arrayData, tripMode, type) => {
    // console.log("Airline List", airlineList);

    if (airlineList != null) {
      let filterFlag = false;
      const filteredData = arrayData.filter((data) => {
        const operatingAirlineCode =
          type === "return"
            ? data.returnResp[0].carrierDetails.carrierCode
            : data.onwardResp[0].carrierDetails.carrierCode;

        let returnFlag = false;
        for (var i = 0; i < airlineList.length; i++) {
          const airlinesObj = airlineList[i];
          if (airlinesObj.selected) {
            filterFlag = true;
            if (airlinesObj.value === "all") {
              returnFlag = true;
              break;
            }
            returnFlag = airlinesObj.carriers?.includes(operatingAirlineCode);
            if (returnFlag) break;
          }
        }
        return returnFlag;
      });
      return filterFlag ? filteredData : arrayData;

      /* const filteredData = arrayData.filter((data) => {
        // console.log(data);
        const operatingAirlineCode =
          data.onwardResp[0].carrierDetails.carrierCode;

        let returnFlag = false;

        let one_selected = false;
        for (var i = 0; i < airlineList.length; i++) {
          const airlinesObj = airlineList[i];

          if (airlinesObj.isSelected == true) {
            one_selected = true;
            break;
          }
        }
        if (!one_selected) {
          airlineList[0].checked = true;
        }

        for (var i = 0; i < airlineList.length; i++) {
          if (one_selected) {
            const airlinesObj = airlineList[i];
            // console.log(airlinesObj);
            if (airlinesObj.value == "all" && airlinesObj.isSelected == true) {
              returnFlag = true;
              break;
            }

            if (airlinesObj.value != "all" && airlinesObj.isSelected == true) {
              // console.log(airlinesObj.value);
              returnFlag = airlinesObj.carriers.includes(operatingAirlineCode);
              break;
            }
          } else {
            returnFlag = true;
            break;
          }
        }

        return returnFlag;
      });

      props.setmodifiedresponse(filteredData); */
    } else {
      // props.setmodifiedresponse(props?.combineresp);
      return arrayData;
    }
  };

  // price filter handle
  const handlePriceSliderValuesChange = (e, v) => {
    // filterByPrice(v, props?.combineresp);
    setPriceSliderValue(v);
    setOnwardFilter({
      ...onwardFilter,
      price: true,
    });
  };

  const handlePriceSliderValueMinChange = (e) => {
    // console.log(priceSliderValues);
    setPriceSliderValue([+e.target.value, priceSliderValues[1]]);
    setOnwardFilter({
      ...onwardFilter,
      price: true,
    });
    /* filterByPrice(
      [+e.target.value, priceSliderValues[1]],
      props.combineResponse
    ); */
  };

  const handlePriceSliderValueMaxChange = (e) => {
    setPriceSliderValue([priceSliderValues[0], +e.target.value]);
    setOnwardFilter({
      ...onwardFilter,
      price: true,
    });
    /* filterByPrice(
      [priceSliderValues[0], +e.target.value],
      props.combineResponse
    ); */
  };

  const handleBlur = () => {
    [0, 1].forEach((i) => {
      if (priceSliderValues[i] < MIN) {
        setPriceSliderValue([MIN, priceSliderValues[1]]);
      } else if (priceSliderValues[i] > MAX) {
        setPriceSliderValue([priceSliderValues[0], MAX]);
      }
    });
  };

  const handleDepartureTimeChange = (e) => {
    // console.log(e.target.name, e.target.checked);
    const alteredTimeSpan = {
      ...departureTime,
      [e.target.name]: e.target.checked,
    };
    // filterByDepartureTime(alteredTimeSpan, props?.combineresp, "ONE");
    setDepartureTime(alteredTimeSpan);
    if (
      JSON.stringify(initialDepartureTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setOnwardFilter({
        ...onwardFilter,
        departureTime: false,
      });
    } else {
      setOnwardFilter({
        ...onwardFilter,
        departureTime: true,
      });
    }
  };

  const handleDepartureTimeChangeRt = (e) => {
    const alteredTimeSpan = {
      ...departureTimeRt,
      [e.target.name]: e.target.checked,
    };
    // filterByDepartureTime(alteredTimeSpan, props?.combineresp, "ROUND");
    setDepartureTimeRt(alteredTimeSpan);
    if (
      JSON.stringify(initialDepartureTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setReturnFilter({
        ...returnFilter,
        departureTime: false,
      });
    } else {
      setReturnFilter({
        ...returnFilter,
        departureTime: true,
      });
    }
  };

  const handleArrivalTimeChange = (e) => {
    const alteredTimeSpan = {
      ...arrivalTime,
      [e.target.name]: e.target.checked,
    };
    // filterByArrivalTime(alteredTimeSpan, props?.combineresp, "ONE");
    setArrivaleTime(alteredTimeSpan);
    if (
      JSON.stringify(initialArrivalTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setOnwardFilter({
        ...onwardFilter,
        arrivalTime: false,
      });
    } else {
      setOnwardFilter({
        ...onwardFilter,
        arrivalTime: true,
      });
    }
  };

  const handleArrivalTimeChangeRt = (e) => {
    const alteredTimeSpan = {
      ...arrivalTimeRt,
      [e.target.name]: e.target.checked,
    };
    // filterByArrivalTime(alteredTimeSpan, props?.combineresp, "ROUND");
    setArrivaleTimeRt(alteredTimeSpan);
    if (
      JSON.stringify(initialArrivalTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setReturnFilter({
        ...returnFilter,
        arrivalTime: false,
      });
    } else {
      setReturnFilter({
        ...returnFilter,
        arrivalTime: true,
      });
    }
  };

  const handleAirlinesListChange = (e) => {
    // console.log("e.target.name", e.target.name);

    const alteredAirlineList = [...airlinesList];
    const selectedIndex = airlinesList.findIndex(
      (row) => row.value == e.target.name
    );

    alteredAirlineList[selectedIndex] = {
      ...alteredAirlineList[selectedIndex],
      selected: e.target.checked,
    };

    // filterByAirlines(alteredAirlineList, props?.combineresp, "ONE");
    setAirlinesList(alteredAirlineList);
    setOnwardFilter({
      ...onwardFilter,
      airlines: true,
    });
  };

  const handleAirlinesListChangeRt = (e) => {
    // console.log("e.target.name", e.target.name);

    const alteredAirlineList = [...airlinesListRt];
    const selectedIndex = airlinesListRt.findIndex(
      (row) => row.value == e.target.name
    );

    alteredAirlineList[selectedIndex] = {
      ...alteredAirlineList[selectedIndex],
      selected: e.target.checked,
    };

    // filterByAirlines(alteredAirlineList, props.returnData, "ROUND");
    setAirlinesListRt(alteredAirlineList);
    setReturnFilter({
      ...returnFilter,
      airlines: true,
    });
  };

  useEffect(() => {
    const sortedByPrice = sortByPrice(props?.combineresp);
    const maxPriceArrInd =
      sortedByPrice[sortedByPrice.length - 1].fares.length - 1;
    setMIN(
      parseFloat(sortedByPrice[0].fares[0].baseFare) +
        parseFloat(sortedByPrice[0].fares[0].tax)
    );
    setMAX(
      parseFloat(
        sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].baseFare
      ) +
        parseFloat(
          sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].tax
        )
    );

    setPriceSliderValue([
      parseFloat(sortedByPrice[0].fares[0].baseFare) +
        parseFloat(sortedByPrice[0].fares[0].tax),
      parseFloat(
        sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].baseFare
      ) +
        parseFloat(
          sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].tax
        ),
    ]);
    props.setmodifiedresponse(sortedByPrice);
  }, []);

  const [value, setValue] = useState("price");
  const [retvalue, setRetValue] = useState("price");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeRet = (event) => {
    setRetValue(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        className="filter-container"
      >
        {/* <Grid item className={classes.filter_title_root}>
          <span>Filter</span>
        </Grid> */}

        {/* price filter start */}
        <Grid item className="filter-element-container">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="filter-element-title-main">
                  <span>Price</span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() => {
                      setOpenDiv({
                        ...openDiv,
                        openPriceFilter: !openDiv.openPriceFilter,
                      });
                    }}
                    className="collapse-icon"
                  >
                    {openDiv.openPriceFilter == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={openDiv.openPriceFilter} timeout={0}>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className="tripmode-wrapper"
                >
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Onward</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear price"
                            size="small"
                            onClick={() => {
                              setPriceSliderValue([0, 0]);
                              setOnwardFilter({
                                ...onwardFilter,
                                price: false,
                              });
                              // filterByPrice([0, 0], props?.combineresp)
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <PriceSlider
                      value={priceSliderValues}
                      onChange={handlePriceSliderValuesChange}
                      valueLabelDisplay="auto"
                      min={MIN}
                      max={MAX}
                      valueLabelFormat={(x) => `${Math.floor(x / 1000)}k`}
                      aria-labelledby="range-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="stretch"
                    >
                      <Grid item>
                        <Input
                          className="price-slider-value-input"
                          color="secondary"
                          value={priceSliderValues[0]}
                          onChange={handlePriceSliderValueMinChange}
                          onBlur={handleBlur}
                          inputProps={{
                            step: STEP,
                            min: MIN,
                            max: MAX,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Grid>
                      <Grid item>to</Grid>
                      <Grid item>
                        <Input
                          className="price-slider-value-input"
                          color="secondary"
                          value={priceSliderValues[1]}
                          onChange={handlePriceSliderValueMaxChange}
                          onBlur={handleBlur}
                          inputProps={{
                            step: STEP,
                            min: MIN,
                            max: MAX,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Collapse>
          </Grid>
        </Grid>
        {/* price filter end */}

        {/* stop filter start */}
        <Grid item className="filter-element-container">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="filter-element-title-main">
                  <span>Stops</span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() =>
                      setOpenDiv({
                        ...openDiv,
                        openStopsFilter: !openDiv.openStopsFilter,
                      })
                    }
                    className="collapse-icon"
                  >
                    {openDiv.openStopsFilter == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={openDiv.openStopsFilter} timeout={0}>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className="tripmode-wrapper"
                >
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Onward</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear stops"
                            size="small"
                            onClick={() => {
                              setCurrentFilterByStop([]);
                              setStops([]);
                              setOnwardFilter({
                                ...onwardFilter,
                                stops: false,
                              });
                              // filterByStops(-1, null, "ONE");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl component="fieldset">
                      {STOPS.map((s, i) => (
                        <FormControlLabel
                          key={i}
                          label={s.label}
                          control={
                            <Checkbox
                              color="secondary"
                              size="small"
                              value={s.value}
                              checked={currentFilterByStop.includes(s.value)}
                              onClick={(event) => {
                                if (event.target.checked) {
                                  setCurrentFilterByStop([
                                    ...currentFilterByStop,
                                    event.target.value,
                                  ]);
                                  setStops([
                                    ...stops,
                                    parseInt(event.target.value) + 1,
                                  ]);
                                  setOnwardFilter({
                                    ...onwardFilter,
                                    stops: true,
                                  });
                                  /* filterByStops(
                                    parseInt(event.target.value) + 1,
                                    props?.combineresp,
                                    "ONE"
                                  ); */
                                } else {
                                  const checkedCurrentStops = currentFilterByStop.filter(
                                    (stop) => stop !== event.target.value
                                  );
                                  const checkedStops = stops.filter(
                                    (stop) =>
                                      stop !== parseInt(event.target.value) + 1
                                  );
                                  setCurrentFilterByStop(checkedCurrentStops);
                                  setStops(checkedStops);
                                  setOnwardFilter({
                                    ...onwardFilter,
                                    stops: true,
                                  });
                                  // filterByStops(-1, null, "ONE");
                                }
                              }}
                              name="flight-stop-filter"
                            />
                          }
                        />
                      ))}
                      {/* <RadioGroup
                        defaultValue="non-stop-only"
                        aria-label="flight stops"
                        name="flight-stop-radios"
                      >
                        {STOPS.map((s, i) => (
                          <FormControlLabel
                            value={s.value}
                            control={<FilterRadio />}
                            label={s.label}
                            checked={s.value == currentFilterByStop}
                            onClick={(event) => {
                              setCurrentFilterByStop(event.target.value);
                              filterByStops(
                                parseInt(event.target.value) + 1,
                                props?.combineresp,
                                "ONE"
                              );
                            }}
                            key={i}
                          />
                        ))}
                      </RadioGroup> */}
                    </FormControl>
                  </Grid>
                </Grid>

                {(props.tripMode == "ROUND" ||
                  props.tripMode == "ROUND-SP") && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Return</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear stops"
                            size="small"
                            onClick={() => {
                              setCurrentFilterByStopRt([]);
                              // filterByStops(-1, null, "ROUND");
                              setStopsRt([]);
                              setReturnFilter({
                                ...returnFilter,
                                stops: false,
                              });
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset">
                        <RadioGroup
                          defaultValue="non-stop-only"
                          aria-label="flight stops"
                          name="flight-stop-radios"
                        >
                          {STOPS.map((s, i) => (
                            <FormControlLabel
                              key={i}
                              label={s.label}
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  value={s.value}
                                  checked={currentFilterByStopRt.includes(
                                    s.value
                                  )}
                                  onClick={(event) => {
                                    if (event.target.checked) {
                                      setCurrentFilterByStopRt([
                                        ...currentFilterByStopRt,
                                        event.target.value,
                                      ]);
                                      setStopsRt([
                                        ...stopsRt,
                                        parseInt(event.target.value) + 1,
                                      ]);
                                      setReturnFilter({
                                        ...returnFilter,
                                        stops: true,
                                      });
                                      /* filterByStops(
                                        parseInt(event.target.value) + 1,
                                        props?.combineresp,
                                        "ROUND"
                                      ); */
                                    } else {
                                      const checkedCurrentStopsRt = currentFilterByStopRt.filter(
                                        (stop) => stop !== event.target.value
                                      );
                                      const checkedStopsRt = stopsRt.filter(
                                        (stop) =>
                                          stop !==
                                          parseInt(event.target.value) + 1
                                      );
                                      setCurrentFilterByStopRt(
                                        checkedCurrentStopsRt
                                      );
                                      setStopsRt(checkedStopsRt);
                                      setReturnFilter({
                                        ...returnFilter,
                                        stops: true,
                                      });
                                      //filterByStops(-1, null, "ROUND");
                                    }
                                  }}
                                  name="flight-stop-filter-rt"
                                />
                              }
                            />
                          ))}
                          {/* {STOPS.map((s, i) => (
                            <FormControlLabel
                              value={s.value}
                              control={<FilterRadio />}
                              label={s.label}
                              checked={s.value == currentFilterByStopRt}
                              onClick={(event) => {
                                setCurrentFilterByStopRt(event.target.value);
                                filterByStops(
                                  parseInt(event.target.value) + 1,
                                  props?.combineresp,
                                  "ROUND"
                                );
                              }}
                              key={i}
                            />
                          ))} */}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
            </Collapse>
          </Grid>
        </Grid>
        {/* stop filter end */}

        {/* depart time filter start */}
        <Grid item className="filter-element-container">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="filter-element-title-main">
                  <span>Departure Time</span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() =>
                      setOpenDiv({
                        ...openDiv,
                        openDepartFilter: !openDiv.openDepartFilter,
                      })
                    }
                    className="collapse-icon"
                  >
                    {openDiv.openDepartFilter == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={openDiv.openDepartFilter} timeout={0}>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className="tripmode-wrapper"
                >
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Onward</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear departure time"
                            size="small"
                            onClick={() => {
                              setDepartureTime({
                                dawn: false,
                                morning: false,
                                afternoon: false,
                                evening: false,
                                //night: false,
                              });
                              setOnwardFilter({
                                ...onwardFilter,
                                departureTime: false,
                              });
                              // filterByDepartureTime(null, null, "ONE");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        {filteredArrayOnw.map((v, i) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                size="small"
                                checked={departureTime[v.value]}
                                onChange={handleDepartureTimeChange}
                                name={v.value}
                              />
                            }
                            label={
                              <>
                                <span>
                                  <img
                                    src={getTimeSpanIcon(v.icon)}
                                    height={16}
                                    width={16}
                                    style={{ marginRight: "4px" }}
                                  />
                                </span>
                                <span>{v.label}</span>
                              </>
                            }
                            key={i}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                {(props.tripMode == "ROUND" ||
                  props.tripMode == "ROUND-SP") && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Return</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear departure time"
                            size="small"
                            onClick={() => {
                              setDepartureTimeRt({
                                dawn: false,
                                morning: false,
                                afternoon: false,
                                evening: false,
                                //night: false,
                              });
                              setReturnFilter({
                                ...returnFilter,
                                departureTime: false,
                              });
                              // filterByDepartureTime(null, null, "ROUND");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {filteredArrayRet.map((v, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  checked={departureTimeRt[v.value]}
                                  onChange={handleDepartureTimeChangeRt}
                                  name={v.value}
                                />
                              }
                              label={
                                <>
                                  <span>
                                    <img
                                      src={getTimeSpanIcon(v.icon)}
                                      height={16}
                                      width={16}
                                      style={{ marginRight: "4px" }}
                                    />
                                  </span>
                                  <span>{v.label}</span>
                                </>
                              }
                              key={i}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
            </Collapse>
          </Grid>
        </Grid>
        {/* depart time filter end */}

        {/* arrival time filter start */}
        <Grid item className="filter-element-container">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="filter-element-title-main">
                  <span>Arrival Time</span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() =>
                      setOpenDiv({
                        ...openDiv,
                        openArriveFilter: !openDiv.openArriveFilter,
                      })
                    }
                    className="collapse-icon"
                  >
                    {openDiv.openArriveFilter == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={openDiv.openArriveFilter} timeout={0}>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className="tripmode-wrapper"
                >
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Onward</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear arrival time"
                            size="small"
                            onClick={() => {
                              setArrivaleTime({
                                dawn: false,
                                morning: false,
                                afternoon: false,
                                evening: false,
                                //night: false,
                              });
                              setOnwardFilter({
                                ...onwardFilter,
                                arrivalTime: false,
                              });
                              // filterByArrivalTime(null, null, "ONE");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        {filteredArrayOnw.map((v, i) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                size="small"
                                checked={arrivalTime[v.value]}
                                onChange={handleArrivalTimeChange}
                                name={v.value}
                              />
                            }
                            label={
                              <>
                                <span>
                                  <img
                                    src={getTimeSpanIcon(v.icon)}
                                    height={16}
                                    width={16}
                                    style={{ marginRight: "4px" }}
                                  />
                                </span>
                                <span>{v.label}</span>
                              </>
                            }
                            key={i}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                {(props.tripMode == "ROUND" ||
                  props.tripMode == "ROUND-SP") && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Return</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear arrival time"
                            size="small"
                            onClick={() => {
                              setArrivaleTimeRt({
                                dawn: false,
                                morning: false,
                                afternoon: false,
                                evening: false,
                                //night: false,
                              });
                              setReturnFilter({
                                ...returnFilter,
                                arrivalTime: false,
                              });
                              // filterByArrivalTime(null, null, "ROUND");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {filteredArrayRet.map((v, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  checked={arrivalTimeRt[v.value]}
                                  onChange={handleArrivalTimeChangeRt}
                                  name={v.value}
                                />
                              }
                              label={
                                <>
                                  <span>
                                    <img
                                      src={getTimeSpanIcon(v.icon)}
                                      height={16}
                                      width={16}
                                      style={{ marginRight: "4px" }}
                                    />
                                  </span>
                                  <span>{v.label}</span>
                                </>
                              }
                              key={i}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
            </Collapse>
          </Grid>
        </Grid>
        {/* arrival time filter end */}

        {/* airlines filter start */}
        <Grid item className="filter-element-container">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="filter-element-title-main">
                  <span>Airlines</span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() =>
                      setOpenDiv({
                        ...openDiv,
                        openAirlinesFilter: !openDiv.openAirlinesFilter,
                      })
                    }
                    className="collapse-icon"
                  >
                    {openDiv.openAirlinesFilter == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={openDiv.openAirlinesFilter} timeout={0}>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className="tripmode-wrapper"
                >
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Onward</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear airline"
                            size="small"
                            onClick={() => {
                              setAirlinesList(resetOnwardAirlinesList);
                              setOnwardFilter({
                                ...onwardFilter,
                                airlines: false,
                              });
                              // filterByArrivalTime(null, null, "ONE");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        {airlinesList &&
                          airlinesList.map((v, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  checked={v.selected}
                                  onChange={handleAirlinesListChange}
                                  name={v.value}
                                />
                              }
                              label={
                                <span className="airline-filter-label">
                                  <img
                                    src={
                                      v.value !== "gds_only" &&
                                      v.value !== "lcc_only" &&
                                      v.value !== "all"
                                        ? image_base +
                                          "?fileName=" +
                                          v.value +
                                          ".png&type=AIRLINE_IMAGES"
                                        : GEN
                                    }
                                  />
                                  <span>{v.label}</span>
                                </span>
                              }
                              key={i}
                            />
                          ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                {(props.tripMode == "ROUND" ||
                  props.tripMode == "ROUND-SP") && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Return</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear airline"
                            size="small"
                            onClick={() => {
                              setAirlinesListRt(resetOnwardAirlinesList);
                              setReturnFilter({
                                ...returnFilter,
                                airlines: false,
                              });
                              // filterByArrivalTime(null, null, "ROUND");
                            }}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {airlinesListRt &&
                            airlinesListRt.map((v, i) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    checked={v.selected}
                                    onChange={handleAirlinesListChangeRt}
                                    name={v.value}
                                  />
                                }
                                label={
                                  <span className="airline-filter-label">
                                    <img
                                      src={
                                        v.value !== "gds_only" &&
                                        v.value !== "lcc_only" &&
                                        v.value !== "all"
                                          ? image_base +
                                            "?fileName=" +
                                            v.value +
                                            ".png&type=AIRLINE_IMAGES"
                                          : GEN
                                      }
                                    />
                                    <span>{v.label}</span>
                                  </span>
                                }
                                key={i}
                              />
                            ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
            </Collapse>
          </Grid>
        </Grid>
        {/* airlines filter end */}

        {/* airlines sort start */}
        {/*<Grid item className="filter-element-container">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="filter-element-title-main">
                  <span>Sort</span>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="clear price"
                    size="small"
                    onClick={() =>
                      setOpenDiv({ ...openDiv, openSort: !openDiv?.openSort })
                    }
                    className="collapse-icon"
                  >
                    {openDiv?.openSort == true ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={openDiv?.openSort} timeout={0}>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className="tripmode-wrapper"
                >
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Onward</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear airline"
                            size="small"
                            onClick={() => clearSort("all", "ONE")}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup value={value} onChange={handleChange}>
                        <FormControlLabel
                          value="price"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              // checked={sorted.price == true}
                              onChange={(event) => {
                                event.target.checked
                                  ? sortData("price", props?.combineresp, "ONE")
                                  : clearSort("price", "ONE");
                              }}
                            />
                          }
                          label="Price"
                        />

                        <FormControlLabel
                          value="stops"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              // checked={sorted.stops == true}
                              onChange={(event) => {
                                event.target.checked
                                  ? sortData("stops", props?.combineresp, "ONE")
                                  : clearSort("stops", "ONE");
                              }}
                            />
                          }
                          label="Stops"
                        />

                        <FormControlLabel
                          value="dep-time"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              // checked={sorted.departureTime == true}
                              onChange={(event) => {
                                event.target.checked
                                  ? sortData(
                                      "dep-time",
                                      props?.combineresp,
                                      "ONE"
                                    )
                                  : clearSort("dep-time", "ONE");
                              }}
                            />
                          }
                          label="Departure Time"
                        />

                        <FormControlLabel
                          value="arr-time"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              // checked={sorted.arrivalTime == true}
                              onChange={(event) => {
                                event.target.checked
                                  ? sortData(
                                      "arr-time",
                                      props?.combineresp,
                                      "ONE"
                                    )
                                  : clearSort("arr-time", "ONE");
                              }}
                            />
                          }
                          label="Arrival Time"
                        />

                        <FormControlLabel
                          value="airlines"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              // checked={sorted.airlines == true}
                              onChange={(event) => {
                                event.target.checked
                                  ? sortData(
                                      "airlines",
                                      props?.combineresp,
                                      "ONE"
                                    )
                                  : clearSort("airlines", "ONE");
                              }}
                            />
                          }
                          label="Airlines"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                {(props.tripMode == "ROUND" ||
                  props.tripMode == "ROUND-SP") && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    <Grid item className="tripmode-header">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="tripmode-title">
                          <span>Return</span>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="clear airline"
                            size="small"
                            onClick={() => clearSort("all", "ROUND")}
                            className="reset-filter"
                          >
                            Clear
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup value={retvalue} onChange={handleChangeRet}>
                          <FormControlLabel
                            value="stops"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.stopsRt == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "stops",
                                        props?.combineresp,
                                        "ROUND"
                                      )
                                    : clearSort("stops", "ROUND");
                                }}
                              />
                            }
                            label="Stops"
                          />

                          <FormControlLabel
                            value="dep-time"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.departureTimeRt == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "dep-time",
                                        props?.combineresp,
                                        "ROUND"
                                      )
                                    : clearSort("dep-time", "ROUND");
                                }}
                              />
                            }
                            label="Departure Time"
                          />

                          <FormControlLabel
                            value="arr-time"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.arrivalTimeRt == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "arr-time",
                                        props?.combineresp,
                                        "ROUND"
                                      )
                                    : clearSort("arr-time", "ROUND");
                                }}
                              />
                            }
                            label="Arrival Time"
                          />

                          <FormControlLabel
                            value="arrivals"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.airlinesRt == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "airlines",
                                        props?.combineresp,
                                        "ROUND"
                                      )
                                    : clearSort("airlines", "ROUND");
                                }}
                              />
                            }
                            label="Airlines"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
            </Collapse>
          </Grid>
        </Grid>*/}
        {/* airlines sort end */}
      </Grid>
    </div>
  );
};

export default FlightSearchFilter;

const STEP = 100;

// const TIME_OF_DAY = [
//   { value: "dawn", label: "Dawn (3AM - 7AM)", icon: dawn },
//   { value: "morning", label: "Morning (7AM - 12PM)", icon: morning },
//   { value: "afternoon", label: "Afternoon (12PM - 4PM)", icon: afternoon },
//   { value: "evening", label: "Evening (4PM - 9PM)", icon: evening },
//   { value: "night", label: "Night (9AM - 3AM)", icon: night },
// ];

const getTimeSpanIcon = (timeSpan) => {
  switch (timeSpan) {
    case "dawn": {
      return dawn;
    }

    case "morning": {
      return morning;
    }

    case "afternoon": {
      return afternoon;
    }

    case "evening": {
      return evening;
    }

    case "night": {
      return night;
    }
  }
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      height: "100vh",
      // marginTop: 20,
      backgroundColor: theme.palette.background.light,
      position: "sticky",
      top: 75,
      // top: 135,
      boxShadow: `0px 0px 5px 0px rgba(145,143,145,0.6)`,
      // maxHeight: "88vh",
      borderRadius: "5px",
      overflowY: "scroll",
      scrollbarWidth: "thin",
      paddingBottom: 600,
      [theme.breakpoints.down(600)]: {
        paddingBottom: 80,
      },
      "& .PrivateSwitchBase-root-66": {
        padding: "6px",
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      "& .reset-filter": {
        fontSize: "0.7rem",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      "& .filter-container": {
        // flexWrap: "nowrap",
        // paddingBottom: 100,
        [theme.breakpoints.down(1280)]: {
          flexWrap: "wrap",
        },
      },
      "& .filter-element-title-main": {
        fontSize: "14px",
        fontWeight: "600",
        textAlign: "center",
        margin: "8px 8px",
        "& span": {
          color: theme.palette.primary.darkText,
        },
      },
      "& .filter-element-container": {
        width: "100%",
        padding: "12px",
        backgroundColor: theme.palette.background.light,
        //"&:not(:last-child)": {
        //borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
        border: `solid 0.5px ${theme.palette.primary.disabled}`,
        borderRadius: "5px",
        margin: "3px",
        // },
        "& .MuiFormControlLabel-root": {
          color: theme.palette.primary.darkText,
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "12px",
        },
        "& .MuiFormControl-root": {
          marginTop: 0,
        },
        "& *": {
          transition: "0.2s ease all",
        },
        "& .MuiCheckbox-root": {
          padding: "4px",
        },
        "& .collapse-icon": {
          marginTop: -5,
        },
      },
      "& .tripmode-header": {
        marginTop: 10,
        border: `solid 0.5px ${theme.palette.primary.main}`,
        "& span": {
          color: theme.palette.quarternary.darkText,
        },
      },
      "& .tripmode-title": {
        height: "20px",
        maxWidth: "200px",
        "& span": {
          fontSize: "14px",
          fontWeight: "700",
          color: theme.palette.primary.darkText,
        },
      },
      "& .tripmode-wrapper": {
        padding: "0 20px 10px 10px",
      },
      "& .price-slider-value-input": {
        "& input, & input:active, & input:focus": {
          outline: "3px solid black",
          borderRadius: 3,
          padding: "6px",
          fontSize: 12,
          width: 70,
        },
      },
      "& .airline-filter-label": {
        display: "flex",
        // alignItems: "center",
        "& img": {
          height: 20,
          width: 20,
          marginRight: 5,
        },
      },
    },
    filter_title_root: {
      fontSize: "20px",
      fontWeight: "600",
      textAlign: "center",
      margin: "12px 0",
      "& span": {
        color: theme.palette.primary.darkText,
      },
    },
  };
});

const PriceSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 6,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.background.light,
    border: `4px solid ${theme.palette.primary.main}`,
    marginTop: -6,
    marginLeft: -6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  // valueLabel: {
  //   left: "calc(-50% + 4px)",
  // },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
}))(Slider);

const radioStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: `${theme.palette.primary.main}40`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: `${theme.palette.primary.main}10`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
  },
}));

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      {...props}
    />
  );
}
