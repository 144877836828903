import React from "react";
import HomePage from "components/LandingPage/LookMyTicket/HomePage";
import PlanYourTripMobile from "components/LandingPage/LookMyTicket/HomePageMobile";

const LandingPageBase = (props) => {
    const width = window.innerWidth;
    const breakpoint = 980;
    
    return (width < breakpoint ? <PlanYourTripMobile /> : <HomePage />);
};

export default LandingPageBase;
