import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
// import _ from "lodash";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import GridContainer from "components/Grid/GridContainer.js";
import OaAlert from "oahoc/OaAlert";

import OaFormTextField from "oahoc/OaFormTextField";
import { makeStyles } from "@material-ui/core/styles";
import { localforageGetItem } from "pages/components/oautils/oaForageUtils";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { AppConstant } from "appConstant";

const PrintTicket = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [loggedInUserId, setIsLoggedInUserId] = useState(0);
  const [userType, setUserType] = useState("");
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");



  console.log("serviceAvailed ", AppConstant.serviceAvailed);

  const busEnabled = (AppConstant.serviceAvailed.includes("4") ? true : false);
  const insuranceEnabled = (AppConstant.serviceAvailed.includes("6") ? true : false);


  const classes = useStyles();

  const getUserType = () => {
    localforageGetItem("user-details", function (err, value) {
      // console.log(value)
      if (err == null && value != null) {
        setUserType(value.userTypeAbv);
        setIsLoggedInUserId(value.userId);
      } else {
        console.log("err", err);
      }
    });
  };

  useEffect(() => {
    getUserType();
  }, []);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          refId: "",
          lastName: "",
          email: "",
          trType: "1",
        }}
        validationSchema={Yup.object().shape({
          refId: Yup.string().required("Transaction id required"),
          lastName: Yup.string().required("LastName is required"),
          email: Yup.string()
            .email()
            .required("Email is required"),
        })}
        onSubmit={(
          searchParams,
          { setSubmitting, resetForm, enableReinitialize }
        ) => {
          setIsSaving(true);
          apiCall(
            WebApi.getPrintEticket,
            searchParams,
            (response) => {
              // console.log("#######responsce", response);
              setIsError(!response.success);
              setIsSuccess(response.success);
              setSubmitting(false);
              setIsSaving(false);
              const ticketType = (key) => {
                switch (key) {
                  case "1":
                    return "print/flightticket";
                    // return "print/mybookingsflight";
                    break;
                  case "4":
                    // return "print/mybookingsbus";
                    return "print/busticket";
                    break;
                  case "2":
                    // return "print/mybookingshotel";
                    return "print/hotelticket";
                    break;
                  case "6":
                    // return "print/mybookinginsurance";
                    return "print/insuranceticket";
                    break;
                  default:
                    break;
                }
              };
              setInfoText(response.message);

              if (response.success === true) {
                // props.history.push(
                //   `/${ticketType(searchParams.trType)}/` + response.data,
                // );
                props.history.push({
                  pathname:
                    `/${ticketType(searchParams.trType)}/` + response.data,
                  state: {
                    loggedInUserId: loggedInUserId,
                    transactionId: searchParams.refId,
                    refId: response.data,
                    userType: userType,
                    // trType: searchParams.trType,
                    trType: 1,
                  },
                });
                if (response.data) {
                  enableReinitialize = true;
                  resetForm(true);
                } else {
                  setIsError(true);
                  setPrimaryButtonText("OK");
                  setIsAlertPrompt(true);
                  setAlertMsg("Cannot find itinerary !");
                  setAlertTitle("Cannot find itinerary");
                  //setInfoText("Cannot find itinerary !");
                }
              }
            },
            (error) => {
              setIsError(true);
              setPrimaryButtonText("OK");
              setIsAlertPrompt(true);
              //setInfoText("Cannot find itinerary !");
              setAlertMsg("Cannot find itinerary !");
              setAlertTitle("Cannot find itinerary");
            }
          );

          // props.history.push({
          //         pathname: `/print/mybookingflight/`,
          //         state: {
          //             loggedInUserId: loggedInUserId,
          //             transactionId: searchParams.refId,
          //             refId: searchParams.refId,
          //             userType: userType,
          //             // trType: searchParams.trType,
          //             trType: 1,
          //         }
          //     });
          //       enableReinitialize = true;
          //       resetForm(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          enableReinitialize,
          resetForm,
        }) => (
          <Form>
            <div className={classes.div}>
              <GridContainer
                className={classes.Grid}
                md={5}
                xs={12}
                spacing={2}
              >
                {isError && (
                  <Grid item md={12} xs={12}>
                    <OaAlert
                      //callback={transactionHelper}
                      isPrompt={isAlertPrompt}
                      //msg={isError}
                      //title={infoText}
                      msg={alertMsg}
                      title={alertTitle}
                      isError={isError}
                      primaryButtonText={primaryButtonText}
                      secondaryButtonText={secondaryButtonText}
                      togglePrompt={() => {
                        setIsAlertPrompt(!isAlertPrompt);
                      }}
                    ></OaAlert>
                    {/*                         
                    // <OaFormAlerts
                    //   isError={isError}
                    //   infoText={infoText}
                    //   setIsError={setIsError}
                    // /> */}
                  </Grid>
                )}

                <Grid item md={12} xs={12}>
                  <Typography
                    variant="h3"
                    style={{ fontSize: 24 }}
                    gutterBottom
                  >
                    Booked Ticket
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid container spacing={4} justifyContent="space-between">
                    <Grid item className={classes.checkItem}>
                      <span className={classes.checkInput}>
                        <Field type="radio" name="trType" value="1" />
                      </span>
                      <span className={classes.checkLabel}>Flight</span>
                    </Grid>
                    <Grid item className={classes.checkItem}>
                      <span className={classes.checkInput}>
                        <Field type="radio" name="trType" value="2" />
                      </span>
                      <span className={classes.checkLabel}>Hotel</span>
                    </Grid>

                    <Grid item className={classes.checkItem}>
                      {busEnabled && <>
                        <span className={classes.checkInput}>
                          <Field type="radio" name="trType" value="4" />
                        </span>
                        <span className={classes.checkLabel}>Bus</span>
                      </>
                      }
                    </Grid>


                    <Grid item className={classes.checkItem}>
                      {insuranceEnabled && <>
                        <span className={classes.checkInput}>
                          <Field type="radio" name="trType" value="6" />
                        </span>
                        <span className={classes.checkLabel}>Insurance</span>
                      </>
                      }
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography variant="body1">
                    Transaction ID / Reference ID
                  </Typography>
                  {/* <OaFormTextField name="refId" /> */}
                  <TextField
                    name="refId"
                    variant="outlined"
                    value={values.refId}
                    onChange={(e) => setFieldValue("refId", e.target.value)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="refId"
                    component="div"
                    className="error"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="body1">Passenger's Last Name</Typography>
                  {/* <OaFormTextField name="lastName" /> */}
                  <TextField
                    name="lastName"
                    variant="outlined"
                    value={values.lastName}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="body1">
                    Passenger's E-mail Address
                  </Typography>
                  {/* <OaFormTextField name="email" /> */}
                  <TextField
                    name="email"
                    variant="outlined"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  container
                  justifyContent="flex-start"
                  xs={12}
                >
                  <Button
                    type="submit"
                    className={classes.Button}
                    style={{ fontFamily: "Montserrat" }}
                  >
                    Submit
                  </Button>
                </Grid>
                {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div className={"row"}>
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
              </GridContainer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(PrintTicket);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "66vh",
    "& .MuiInputBase-input": {
      minHeight: 35
    },
  },
  div: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 50,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    "& .MuiOutlinedInput-root ": {
      position: "relative",
      borderRadius: "4px",
      height: "26px",
      background: "white",
      borderColor: "none",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  Button: {
    background: theme.palette.buttons.primary,
    letterSpacing: "1px",
    color: theme.palette.buttons.primaryContrastText,
    border: `solid 1px ${theme.palette.buttons.primary}`,
    fontSize: "14px",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.buttons.primary,
      backgroundColor: theme.palette.buttons.primaryContrastText,
    },
  },
  typo: {
    color: "#dd3c49",
  },
  Grid: {
    //   marginLeft: "120px",
    //   marginRight: "120px",
    background: "#fff",
    marginTop: "50px",
    // boxShadow: "0px 0px 15px -1px #989898",
    padding: 30,
    borderRadius: 20,
  },
  checkLabel: {
    marginLeft: "7px",
  },
  checkItem: {
    height: "50px",
  },
  checkInput: {
    marginRight: "3px",
  },
}));
