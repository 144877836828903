import React, {useState, useEffect} from 'react';
import OaFormTextField from 'oahoc/OaFormTextField';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

import { Formik, Form, ErrorMessage } from 'formik';

import GridItem from 'components/Grid/GridItem';
import * as Yup from 'yup';

import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaFormAlerts from 'pages/components/OaFormAlerts';
import FormControl from "@material-ui/core/FormControl";
import OaAlert from 'oahoc/OaAlert';


const RefundForm = (props) => {

    const [collapsed, setCollapsed] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [alertTitle, setAlertTitle] = useState('');

    const [mobile, setMobile] = useState('');
    const [ackno, setAckNo] = useState('');
    const [referenceno, setRefrenceno] = useState('');
    const [isRefund, setIsRefund] = useState(false);

    const [isAlertPrompt, setIsAlertPrompt] = useState(false);
    const [alertCallbackParam] = useState({});
    const [alertAdditionalData] = useState({});
    const [alertMsg, setAlertMsg] = useState('');
    const [primaryButtonText] = useState('');
    const [secondaryButtonText] = useState('');

    const [, setIsRefundable] = useState(false);
    

    useEffect(() => {
        if(isLoading){
            if(props.location.state ) {
                setMobile(props.location.state.data.mobile);
                setAckNo(props.location.state.data.ackno);
                setRefrenceno(props.location.state.data.referenceno);

                if(props.location.state.data.txnStatusCode === 5) {
                    setIsRefundable(true);
                }
            } else if(props.location.query ) {
                setMobile(props.location.query.data.mobile);
                setAckNo(props.location.query.data.ackno);
                setRefrenceno(props.location.query.data.referenceno);

                if(props.location.query.data.txnStatusCode === 5) {
                    setIsRefundable(true);
                }
            }
            setIsLoading(false);
        }
      }, [isLoading]);


    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    return (
        <>
        <GridContainer>
            <GridItem md={9} >
            <h4 className={"oa-form-header"} onClick={collapse}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}}>Enter Transaction Details</h4>
            </GridItem>
          </GridContainer>
       
      
        <OaFormAlerts 
            isSaving={isSaving} 
            isSuccess={isSuccess} 
            isError={isError} 
            infoText={infoText} 
            setIsError={setIsError} 
            setIsSuccess={setIsSuccess} 
            alertTitle={alertTitle}
        />

    {!isLoading &&
        <Formik 
            initialValues={{ 
                mobile: mobile,
                ackno: ackno,
                reference: referenceno,
            }}
            validationSchema={Yup.object().shape({
            })}
            onSubmit= {(searchParams, {}) => {
                setIsError(false);
                setIsSuccess(false);
                setIsSaving(true);

                if(!searchParams.reference && (!searchParams.ackno || !searchParams.mobile)) {
                    setIsAlertPrompt(true);
                    setAlertMsg('Reference or combination of acknowledgement and mobile/account no. is required');
                    return;
                } 
                    apiCall(WebApi.getRechargeTransactStatus, searchParams, (response) => {
                                    setIsSaving(false);
                                    setIsError(response.statusCode != 200);
                                    setIsSuccess(response.statusCode == 200);
                                    setInfoText('Current Status : ' + response.message);
                    });
                
            }}>
            {({ }) => (
                <Form>
                    <GridContainer  >
                        <GridItem  md={4}>
                            <OaFormTextField name="reference" label="Reference Number"  />
                            <ErrorMessage name="reference" component="div" className="error" />
                        </GridItem>
                    </GridContainer>
                    
                    <GridContainer  >
                        <GridItem md={1} style={{marginTop:10}}>or</GridItem>
                    </GridContainer>
                        
                    <GridContainer>
                        <GridItem md={4}>
                            <OaFormTextField name="mobile" label="Sender Mobile/Account Number"  />
                            <ErrorMessage name="mobile" component="div" className="error"/>
                        </GridItem> 
                        <GridItem  md={4}>
                            <OaFormTextField name="ackno" label="Acknowledgement Number"  />
                            <ErrorMessage name="ackno" component="div" className="error" />
                        </GridItem>
                    </GridContainer>

                    <GridContainer style={{marginTop: 20,marginBottom: 20}}>
                        <GridItem>
                            <FormControl>
                                <Button color="primary" type="submit" disabled={isSaving} style={{width:200}} onClick={()=>setIsRefund(false)}>Query Transaction</Button>
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                   {isRefund && 
                       <GridContainer>
                            <GridItem  md={4}>
                                <OaFormTextField name="otp" label="OTP"  />
                                <ErrorMessage name="otp" component="div" className="error" />
                            </GridItem>
                            <GridItem>
                                <FormControl>
                                    <Button color="primary" type="submit" disabled={isSaving} style={{width:200}}>Confirm</Button>
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                   }
                </Form>
            )}
        </Formik>}
        </>
    )
}

export default RefundForm;
