import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import WebApi from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useHistory } from "react-router-dom";
import dateFnsFormat from "date-fns/format";
import { allAirlines } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1200px",
    margin: " 10px auto",
    position: "relative",
    [theme.breakpoints.down(980)]: {
      width: "100%",
      padding: "0 30px",
    },
    [theme.breakpoints.down(1026)]: {
      width: "100%",
    },
    "& .section__header": {
      margin: "2.3rem 0.3rem",
      fontSize: "28px",
      color: theme.palette.primary.defaultText,
      [theme.breakpoints.down(980)]: {
        paddingLeft: "1rem",
      },
    },
    "& .flight__routes": {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down(980)]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      //gap: "0.3rem",
      border: "1px solid #dfe2e2",
      borderRadius: "3px",
      //padding: "1% 2%",
      padding:10,
      "& ul": {
        // flex:'0 0 300px',
        display: "flex",
        //display: "inline-flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        listStyle: "none",
        [theme.breakpoints.down(980)]: {
          width: "90%",
        },
        paddingLeft: "0.5rem",
        paddingRight: "1rem",
        margin: "0",
        "& li": {
          color: theme.palette.primary.defaultText,
         margin: "10px 0",
          display: "flex",
          margin:10,
          width: 356,
          justifyContent: "space-between",
          alignItems: "center",
          // width:'100%',
          borderLeft: "1px solid #eee",
          "&:nth-child(1)": {
            border: "none",
          },
          [theme.breakpoints.down("md")]: {
              width:'100%',
          },
          [theme.breakpoints.down(460)]: {
            fontSize: "12px",
          },
          "& p": {
            color: theme.palette.primary.defaultText,
            cursor: "pointer",
            margin: "0 30px 10px 0",
            [theme.breakpoints.down(409)]: {
              fontSize: "11px",
            },
          },
          "& .btn": {
            outline: "none",
            minWidth: "88px",
            minHeight: "36px",
            padding: "0.5rem",
            color: theme.palette.secondary.darkOrange,
            textAlign: "center",
            border: `1px solid ${theme.palette.secondary.darkOrange}`,
            cursor: "pointer",
            borderRadius: "4px",
            transition: "0.3s",
            [theme.breakpoints.down(409)]: {
              fontSize: "10px",
            },
            "&:hover": {
              backgroundColor: theme.palette.secondary.darkOrange,
              color: theme.palette.primary.lightText,
            },
          },
        },
      },
    },
  },
}));

const TopFlightRoutes = () => {
  const [routesItem, setRoutesItem] = React.useState([]);
  const [userType, setUserType] = React.useState("");
  const [dataIs, setDataIs] = React.useState(false);
  const isMounted = React.useRef(false);
  const history = useHistory();

  React.useEffect(() => {
    isMounted.current = true;
    localforageGetItem("user-details", function(err, value) {
      if (isMounted.current && value) {
        setUserType(value.userTypeAbv);

        WebApi.getPopularRoutes(
          {
            callFrom: "web",
          },
          (resp) => {
            if (resp != null && resp.success) {
              // console.log(resp.data, "get pop");
              setRoutesItem(resp.data);
              setDataIs(true);
            } else {
              console.log("error resp null", resp);
            }
          },
          (error) => console.log("error", error)
        );
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, []);

  const classes = useStyles();

  // const searchTopFlightRoute = (val) => {
  //   const values = {
  //     sectorArray: [
  //       {
  //         fromAirport: val.origin,
  //         toAirport: val.destination,
  //         fromAirportCity: `${val.originCity} (${val.origin})`,
  //         toAirportCity: `${val.destinationCity} (${val.destination})`,
  //         onwardDateDisplay: new Date(
  //           new Date().setDate(new Date().getDate() + 1)
  //         ),
  //         returnDateDisplay: new Date(
  //           new Date().setDate(new Date().getDate() + 1)
  //         ),
  //       },
  //     ],
  //     flightWay: "one-way",
  //     noAdult: "1",
  //     noChild: "0",
  //     noInfant: "0",
  //     cabinType: "E",
  //     airline: ["AI,9W,6E,SG,G8,IX,LB,UK,OP,I5"],
  //     isNonStop: false,
  //     userId: "0",
  //     username: null,
  //     partnerId: 1001,
  //     psaModule: "N",
  //     userType: `${userType}`,
  //   };
  //   window.openPageNew("searchProgressNew.do", values);
  // };

  const searchTopFlightRoute = (val) => {
    const values = {
      adultPax: 1,
      childPax: 0,
      infantPax: 0,
      mode: "ONE",
      preferredAirline: [
        allAirlines,
      ],
      preferredclass: val.classOfBooking,
      returnDate: dateFnsFormat(
        new Date(new Date().setDate(new Date().getDate() + 1)),
        "yyyy-MM-dd"
      ),
      returnDateDisplay: new Date(new Date().setDate(new Date().getDate() + 1)),
      sectors: [
        {
          departDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
          departDateDisplay: new Date(),
          destination: val.destination,
          destinationObj: {
            cityName: val.destinationCity,
            airportCode: val.destination,
            countryCode: val.destinationCountryCode,
            airportDesc: val.destinationCity,
          },
          origin: val.origin,
          originObj: {
            cityName: val.originCity,
            airportCode: val.origin,
            countryCode: val.originCountryCode,
            airportDesc: val.originCity,
          },
        },
      ],
    };

    history.push({
      pathname: "/flight/search-progress",
      state: values,
    });
  };

  return (
    <div className={classes.root}>
      {routesItem && routesItem.length > 0 && (
        <>
          <div className="section__header">Top Flight Routes</div>
          <div className="flight__routes">
            <ul>
              {routesItem?.map((val, index) => (
                <li key={index} >
                  <p>
                    {val.originCity} to {val.destinationCity} ({val.origin} -{" "}
                    {val.destination})
                  </p>
                  <span
                    className="btn"
                    onClick={() => searchTopFlightRoute(val)}
                  >
                    Search Flights
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
export default TopFlightRoutes;
