import React, { Component} from 'react';
import ReactTable from "react-table";
import {Link} from 'react-router-dom';
import WebApi, {DOWNLOAD_LEDGER_PDF, DOWNLOAD_LEDGER_XLS} from "api/ApiConstants";
import OaButtonPDF from 'pages/components/OaButtonPDF';
import OaButtonXls from 'pages/components/OaButtonXls';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

/**
 * Opening balance = balance - credit + debit
 */
class LedgerSearchResult extends Component {

    voucherUrl = '/admin/voucher/transaction/';
    constructor(props){
        super(props);
        this.state = {
            data: [],
            tabledata: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " "
        }
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
    })

    // onRowClick = (state, rowInfo, column, instance) => {
    //     return{
    //     onClick: e => {
    //         //console.log(rowInfo);
    //     }}
    // }

    filterGreaterThan(filter, row){
        return row[filter.id] >= filter.value;
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
    }

    componentDidMount() {
        //console.log('Component did Mount')
        this.tableLoader();
    }
    componentDidUpdate(prev) {
        if(prev.tableKey !== this.props.tableKey){
            //console.log('Component did update')
            //this.tableLoader();
        }
    }

    tableLoader() {
        WebApi.getLedgerDetails(this.props.searchParams, (resp) => {
            if(resp != null && resp.success){
                //console.log(resp)
                var tableData = [];
                var data = [];
                if(resp.data.hasOwnProperty("tableData")){
                    tableData = resp.data.tableData;
                    data = resp.data;
                }else{
                    tableData = resp.data;
                }

                this.setState({tabledata: tableData});
                this.setState({data: data});
                this.setState({
                    rowCount: tableData.length === 0 ? 4 : tableData.length,
                    noDataText: tableData.length === 0 ? "No results" : tableData.length,
                    loading: false,
                    finishedLoading: true
                });

            } 
            },(error)=>{
                this.setState({
                    data: [],
                    rowCount: 4,
                    // noDataText: "Something went wrong", //@TODO: for production environment
                    noDataText: "ERROR ", // + error.response.data.status + ": " + error.response.data.error,
                    loading: false,
                    finishedLoading: true
                });
                // console.log(error);
        });
    }

    getDescription(props){
        let value = JSON.parse(props.value);
        let flight = value.flight && JSON.parse(value.flight);
        let pax = value.pax && JSON.parse(value.pax);
        

        switch (value.category) {
            case 'Fund':
                return (
                    <div>
                         <GridContainer className="bottom-border">
                            <GridItem> Account Balance</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>Fund: {value.type}</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>From: {value.sender}({value.senderId})</GridItem> 
                            <GridItem>To: {value.beneficiary}({value.beneficiaryId})</GridItem> 
                        </GridContainer>
                    </div>
                )
                break;
                case 'Booking':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: Flight Booking</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {flight[0].DepartureDate}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
                    
                case 'Commission':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: Commission Cr.</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {flight[0].DepartureDate}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;

                    case 'Commission_R':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: Commission Recalled.</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {flight[0].DepartureDate}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
                
                case 'Discount':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: Discount Cr.</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {flight[0].DepartureDate}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
                case 'GST':
                case 'Booking_GST':
                    return (
                        <div>

                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: GST Db.</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {flight[0].DepartureDate}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
    
                case 'TDS':
                case 'Booking_TDS':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: TDS Db.</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {flight[0].DepartureDate}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;

                    case 'Booking_R':
                        return (
                            <div>
                                <GridContainer className="bottom-border">
                                    <GridItem> Flight </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem> Type: Cancellation / Refund</GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                    <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                    <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem> Travel Date: {value.dataTime}</GridItem>
                                </GridContainer>
                            </div>
                        )
                        break;

                        case 'Markup_R':
                        return (
                            <div>
                                <GridContainer className="bottom-border">
                                    <GridItem> Flight </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem> Type: Markup Recalled</GridItem>
                                    <GridItem> Txn Amount: {value.txnAmt}</GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                    <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                    <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem> Travel Date: {value.dataTime}</GridItem>
                                </GridContainer>
                            </div>
                        )
                        break;

                        case 'Markup_TDS_R':
                        case 'Booking_TDS_R':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Flight </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: TDS Cr.</GridItem>
                                <GridItem> Txn Amount: {value.txnAmt}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>PNR: {props.original.flightPNR == 'PNRNUM' ? '--': props.original.flightPNR}</GridItem>
                                <GridItem>Pax Name: {pax[0].title} {pax[0].firstName} {pax[0].lastName}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Sec: {flight[0].origin}-{flight[flight.length-1].destination}</GridItem>
                                <GridItem>Flight NO: {flight[0].flightNo}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Travel Date: {value.dataTime}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
                case 'CDN':
                    return (
                        <div >
                            <GridContainer className="bottom-border">
                                <GridItem> Credit Debit Note</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Fund: {value.type}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>From: {value.sender}({value.senderId})</GridItem> 
                                <GridItem>To: {value.beneficiary}({value.beneficiaryId})</GridItem> 
                            </GridContainer>
                            <GridContainer>
                                <GridItem>{props.original.remarks}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;

            case 'DMT':
                return (
                    <div>
                        <GridContainer className="bottom-border">
                            <GridItem> DMT </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem> Type: {value.type}</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>Remitter Mobile: {props.original.mobile}</GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem>{props.original.remarks}</GridItem>
                        </GridContainer>
                    </div>
                )
                break;

                case 'Recharge':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Recharge </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: {props.original.remarks.includes('TDS') ? 'TDS' : (props.original.remarks.includes('commission/charge')? 'Commission/Charge': (props.original.remarks.includes('supplier') ? 'Supplier charge' : 'Recharge Price'))}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Mobile/ Subscriber Number: {props.original.mobile}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>{props.original.remarks}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
                case 'Bus':
                    return (
                        <div>
                            <GridContainer className="bottom-border">
                                <GridItem> Bus </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem> Type: {props.original.remarks.includes('TDS') ? 'TDS' : (props.original.remarks.includes('commission/charge')? 'Commission/Charge': (props.original.remarks.includes('supplier') ? 'Supplier charge' : 'Ticket Price'))}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>Mobile No: {props.original.mobile}</GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>{props.original.remarks}</GridItem>
                            </GridContainer>
                        </div>
                    )
                    break;
            default:
                        return (<span>{value.category}</span>)
                break;
        }
        
    }

    render(){
        //console.log(this.props);
        const theme=this.props.theme
        return(
            <>
            {/* <div>{JSON.stringify(this.props.searchParams)}</div> */}
            <div>
                <OaButtonPDF api={DOWNLOAD_LEDGER_PDF} searchParams={this.props.searchParams} />
                <OaButtonXls api={DOWNLOAD_LEDGER_XLS} searchParams={this.props.searchParams} />
                <span style={{float: "right",fontWeight: "bold"}}>
                Opening balance as on {this.props.searchParams.dateFrom} 
                : {this.formatter.format(this.state.data.openingBalance)    }
                </span>
            </div>
            
            <ReactTable
               columns={
                [
                    {
                        Header: '#',
                        Cell: props => <div style={{height: 28, marginTop:14.5}}>{props.index + 1}</div>,
                        width: 35,
                        filterable: false
                    },{
                        Header: "TX Date",
                        accessor: "transactionDate",
                        width: 190,
                         filterable: false,
                        Cell: props => (
                            <div style={{height: 28, marginTop:14.5}}>{props.value}</div>
                        )
                        
                    },{
                        Header: "Reference",
                        accessor: "referenceNo",
                        width: 210,
                        Cell: props => (
                            <div style={{height: 28, marginTop:14.5}}>
                                {/* <Link to={`${this.voucherUrl}${props.value}`} className={'oa-row-action-primary'}>
                                    {props.value}
                                </Link> */}

                                {props.value}
                                
                            </div>
                            
                        ),
                    },{
                        Header: "Description",
                        accessor: "description",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            this.getDescription(props)
                        ),
                        Footer: (<span style={{fontWeight: "bold"}}><div style={{display: "block", textAlign: "right"}}>Total</div></span>)
                    },{
                        Header: "Dr.",
                        accessor: "debit",
                        width: 130,
                        style: {textAlign: 'right'},
                        Cell: props => (
                            <div style={{height: 28, marginTop:14.5}}>
                                {props.value === 0 ? '-' : props.value.toFixed(2)}
                            </div>
                        ),
                        filterMethod: this.filterGreaterThan,
                        Footer:  <span style={{fontWeight: "bold"}}>{this.formatter.format(this.state.data.totalDebit)}</span>
                    },{
                        Header: "Cr.",
                        accessor: "credit",
                        width: 130,
                        
                        style: {
                            textAlign: 'right',},
                        Cell: props => (
                            <div style={{height: 28, marginTop:14.5}}>
                                {props.value === 0 ? '-' : props.value.toFixed(2)}
                            </div>
                        ),
                        filterMethod: this.filterGreaterThan,
                        Footer: <span style={{fontWeight: "bold"}}>{this.formatter.format(this.state.data.totalCredit)}</span>
                    },{
                        Header: "Bal.",
                        accessor: "balance",
                        width: 130,
                        style: {
                            textAlign: 'right',
                            borderRight: `1px solid ${theme.palette.secondary.chineseGray}`,
                            fontWeight: 500
                        },
                        Cell: props => (
                            <div style={{height: 28, marginTop:14.5}}>
                                {props.value === 0 ? '-' : this.formatter.format(props.value)}
                            </div>
                        ),
                        filterMethod: this.filterGreaterThan,
                        Footer: <span style={{fontWeight: "bold"}}>{this.formatter.format(this.state.data.closingBalance)}</span>
                        
                    }
                ]}

                
                data={this.state.tabledata}
                defaultFilterMethod={this.filterMethod}
                filterable={true}
                showPagination={false}
                minRows={this.state.rowCount}
                loading= {this.state.loading}
                pageSize={this.state.rowCount}
                noDataText={this.state.noDataText}  
                className="-striped -highlight"
                getTdProps={this.onRowClick}
     
            />
            </>
        )
    }
}

export default LedgerSearchResult;