import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MdLuggage } from "react-icons/md";
import { FaLuggageCart } from "react-icons/fa";
import { Typography, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "1.3rem 2rem",

    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#b1b1b1",
      borderRadius: 8,
      transition: "ease-in-out 1s",
      "&:hover": {
        opacity: 1,
        background: "#b1b1b1",
      },
    },

    "& .baggage": {
      height: "66vh",
      // overflowY: "scroll",
      padding: "0 1rem ",
    },
    "& .bagItem": {
      display: "flex",
      // height: "80%",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.3rem 0rem",
      fontSize: "1rem",
      // borderBottom: `0.3px solid ${theme.palette.secondary.darkCharcoal}`,
      borderBottom: "0.5px solid #000",
      height: 65,
      [theme.breakpoints.down(600)]: {
        height: "90px",
      },
    },

    "& .bagItem p": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0rem",
      [theme.breakpoints.down(600)]: {
        padding: "0 9.6px",
      },
    },
    "& .addRemove": {
      color: theme.palette.primary.defaultText,
      fontSize: "1.3rem",
      fontWeight: "bold",
      display: "inline-block",
      cursor: "pointer",
    },
    "& .bagIcons": {
      fontSize: "3rem",
      // display: 'inline-block',
      marginRight: "1.3rem",
    },
    "& .bagInfo": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    "& span": {
      gap: "1.2rem",
      fontSize: "1rem",
    },

    "& .qty_show": {
      margin: "0 0.3rem",
      fontSize: "1rem",
    },
  },
  btn__grp: {
    width: "120px",
    height: "43px",
    cursor: "default",
  },
}));

const BagItem = (props) => {
  const [qty, setQty] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();

  const IncreQty = () => {
    props.setselectedbag(props.item, true);
    setQty(qty + 1);
  };

  const DecrQty = () => {
    props.setselectedbag(props.item, false);
    setQty(qty > 0 ? qty - 1 : 0);
  };

  const setBaggageStatus = () => {
    var arr = props.isreturn ? props.selectedbaggrt : props.selectedbagg;
    if (arr.length > 0) {
      for (let [ind, bagg] of arr.entries()) {
        if (bagg.code == props.item.code) {
          setQty(bagg.qty);
          return;
        }
      }
    }
  };

  React.useEffect(() => {
    setBaggageStatus();
  }, []);

  React.useEffect(() => {
    setBaggageStatus();
  }, [props.selectedbagg, props.selectedbaggrt]);

  return (
    <div className={classes.root}>
      <div className="bagItem">
        {/* <p> */}
          <span className="bagIcons">
            {props.item.weight < 10 ? <MdLuggage /> : <FaLuggageCart />}
          </span>
          <div className="bagInfo">
            <span>{`Weight: ${props.item.weight} ${
              new RegExp("Kg", "i").test(props.item.weight) ? "" : "Kg"
            }, Price: ₹ ${props.item.price}`}</span>
            {!!props.item.description && (
              <span style={{ fontSize: 12 }}>{props.item.description}</span>
            )}
          </div>
        {/* </p> */}
        <Button
          size="medium"
          variant="outlined"
          className={classes.btn__grp}
          style={{
            background: `${qty === 0 ? "" : theme.palette.buttons.tertiary}`,
          }}
        >
          <span
            className="addRemove"
            style={{
              color: `${
                qty === 0
                  ? theme.palette.primary.defaultText
                  : theme.palette.buttons.tertiaryContrastText
              }`,
            }}
            onClick={DecrQty}
            title="remove baggage"
          >
            {" "}
            {qty === 0 ? "" : "-"}
          </span>
          <span
            className="qty_show"
            style={{
              color: `${
                qty === 0
                  ? theme.palette.primary.defaultText
                  : theme.palette.buttons.tertiaryContrastText
              }`,
            }}
          >
            {qty === 0 ? "Add" : qty}
          </span>
          {!props.disableinc ? (
            <span
              className="addRemove"
              style={{
                color: `${
                  qty === 0
                    ? theme.palette.primary.defaultText
                    : theme.palette.buttons.tertiaryContrastText
                }`,
              }}
              onClick={IncreQty}
              title="remove baggage"
            >
              {"+"}
            </span>
          ) : (
            <span
              className="addRemove"
              style={{
                color: `${
                  qty === 0
                    ? theme.palette.primary.defaultText
                    : theme.palette.buttons.tertiaryContrastText
                }`,
              }}
            >
              {"+"}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default BagItem;
