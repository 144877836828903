import React, { useState, useEffect } from "react";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import GridContainer from "components/Grid/GridContainer";
import { useTheme } from "@material-ui/core/styles";

const OaFormFileUpload = (props) => {
  let useGrid = props.md !== undefined;
  const [imageUrlPreview, setImageUrlPreview] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageUploadError, setIsImageUploadError] = useState(false);
  const [imageUploadErrorText, setImageUploadErrorText] = useState("");
  const theme = useTheme();
  const closeErrorBar = () => {
    setIsImageUploadError(false);
    setImageUploadErrorText("");
  };

  const onFileSelect = (e) => {
    if (e.target.files.length === 0) return false;
    let imageFile = e.target.files[0];
    let imageUrl = URL.createObjectURL(imageFile);
    setImageUrlPreview(imageUrl);
    setIsImageUploading(true);
    doFileUpload(imageFile);
  };

  const onFileRemove = () => {
    setImageUrlPreview(null);
    setIsImageUploading(false);
    setIsImageUploaded(false);
    setIsImageUploadError(false);
    setImageUploadErrorText("");
  };

  const doFileUpload = (imageFile) => {
    // console.log(
    //   "File " + imageFile.name + " is " + imageFile.size + " bytes in size"
    // );

    if (imageFile.size < 3000000) {
      let fdata = new FormData();
      fdata.append("files", imageFile);
      fdata.append("type", props.type); // CASH_RECEIPT, PROFILE_PHOTO, PAN_CARD, AADHAR_CARD

      apiCall(WebApi.doFileUpload, fdata, function(response) {
        // console.log("fhdsfk", response);
        if (response.success === true) {
          props.setUploadedImageName(response.data.data);
          setIsImageUploading(false);
          setIsImageUploaded(true);
        } else {
          setIsImageUploading(false);
          setIsImageUploaded(false);
          setIsImageUploadError(true);
          setImageUploadErrorText(response.message);
        }
        // console.log(response);
      });
    } else {
      setIsImageUploading(false);
      setIsImageUploaded(false);
      setIsImageUploadError(true);
      setImageUploadErrorText("File size cannot be greater than 3MB");
    }
  };

  useEffect(() => {
    if (props.imageReinitialize) {
      setImageUrlPreview(null);
      setIsImageUploading(false);
      setIsImageUploaded(false);
      setIsImageUploadError(false);
      setImageUploadErrorText("");
    }
  }, [props.imageReinitialize]);

  function field() {
    return (
      <CardContent>
        {isImageUploadError && (
          <Alert
            onClose={closeErrorBar}
            severity="error"
            variant="outlined"
            style={{ marginBottom: "10px" }}
            elevation={6}
          >
            <AlertTitle>Error uploading</AlertTitle>
            {imageUploadErrorText}
          </Alert>
        )}
        {!isImageUploading && !isImageUploaded && (
          <>
            {props.disabled ? (
              <input
                accept="image/*"
                id={"outlined-button-file" + props.label}
                type="text"
                style={{ display: "none" }}
              />
            ) : (
              <input
                accept="image/*"
                id={"outlined-button-file" + props.label}
                type="file"
                style={{ display: "none" }}
                onChange={onFileSelect}
              />
            )}
            <label htmlFor={"outlined-button-file" + props.label}>
              {props.disabled ? (
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<PhotoCamera />}
                  disabled={true}
                >
                  {props.label
                    ? "Upload " + props.label
                    : "Upload cash receipt"}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  {props.label
                    ? "Upload " + props.label
                    : "Upload cash receipt"}
                </Button>
              )}
            </label>

            <div style={{ color: theme.palette.secondary.chineseGray }}>
              Upload size cannot be greater than 3MB
            </div>
          </>
        )}
        {isImageUploading && (
          <>
            <Typography paragraph>Uploading</Typography>
            <LinearProgress />
          </>
        )}
        {isImageUploaded && (
          <>
            <Typography paragraph>
              {props.label ? props.label : "Cash receipt"}
            </Typography>
            <GridContainer>
              <GridItem xs={11} sm={11}>
                <img src={imageUrlPreview} width="300" />
              </GridItem>
              <GridItem xs={1} sm={1}>
                <IconButton aria-label="delete" onClick={onFileRemove}>
                  <DeleteIcon />
                </IconButton>
              </GridItem>
            </GridContainer>
          </>
        )}
      </CardContent>
    );
  }

  return (
    <>
      {!useGrid && field()}
      {useGrid && (
        <GridItem xs={12} sm={12} md={props.md}>
          {field()}
        </GridItem>
      )}
    </>
  );
};

export default OaFormFileUpload;
