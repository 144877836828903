import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
//import { currencyFormatter } from "oautils/oaCommonUtils";
import { multiCurrencyFormatter } from "oautils/oaCommonUtils";


import GridContainer from "components/Grid/GridContainer";
import { Divider, Typography, Button, useMediaQuery } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import PackageTabDetails from "./PackageTabDetails";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AppBar from "@material-ui/core/AppBar";

import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
} from "react-share";
import { SERVER_URL } from "api/ApiConstants";
import { AppConstant } from "appConstant";
import { localforageGetItem } from "oautils/oaForageUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.light,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    "& .MuiBreadcrumbs-separator": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  Button: {
    background: theme.palette.background.primary,
    border: `1px solid ${theme.palette.primary.silverWhite}`,
    color: theme.palette.primary.lightText,
    padding: "6px 11px",
    borderRadius: "30px",
    fontSize: "13px",
    marginTop: "5px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.primary.silverWhite,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  Button2: {
    background: theme.palette.background.primary,
    border: `1px solid ${theme.palette.primary.silverWhite}`,
    color: theme.palette.primary.lightText,
    padding: "6px 11px",
    borderRadius: "30px",
    fontSize: "13px",
    marginTop: "5px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.secondary.skyBlue,
    },
  },
  typoroot: {
    "& .MuiTypography-root": {
      lineHeight: 1.4287,
      color: theme.palette.secondary.gray,
      fontWeight: 500,
      fontSize: 14,
      fontFamily: "sans-serif",
      padding: "5px 0px",
    },
  },
  appbar: {
    top: 104,
    background: theme.palette.background.light,
    padding: 6,
    zIndex: 10,
    // [theme.breakpoints.up("xl")]: {
    //   top: 125,
    // },
  },
  Grid: {
    marginLeft: "120px",
    marginRight: "120px",
    background: theme.palette.background.light,
    marginTop: "50px",
    boxShadow: `0px 0px 15px -1px ${theme.palette.background.lightFadedGray}`,
    padding: 30,
    borderRadius: 20,
    // [theme.breakpoints.down("sm")]:{
    //   marginLeft: "0px",
    // marginRight: "0px",
    // }
  },
  tabgrid: {
    marginTop: "4rem",
    [theme.breakpoints.down(940)]: {
      marginTop: 5,
    },
  },
  DemoButton: {
    paddingRight: 20,
  },
}));

const PackageInfo = (props) => {
  const classes = useStyles();

  const [packageDetails, setPackageDetails] = React.useState([]);
  const [packageId, setPackageId] = React.useState(props.location.state.data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userType, setUserType] = React.useState(null);
  const isMounted = React.useRef(false);
  const [commission, setCommission] = useState();

  React.useEffect(() => {
    localforageGetItem("user-details", function (err, value) {
      console.log(value.userType);
      setUserType(value.userType);
    });
  }, []);

  useEffect(() => {
    apiCall(
      WebApi.getPackageDetails,
      {
        packageId: packageId,
      },
      (response) => {
        if (userType === "agent" || userType === "direct_agent") {
          setCommission(response?.data[0]?.b2bCommission);
        } else {
          setCommission(response?.data[0]?.b2cCommission);
        }
      }
    );
  }, [userType]);

  // const canonical = document.querySelector("link[rel=canonical]");
  // let shareUrl = canonical ? canonical.href : document.location.href;

  var encodedString = btoa(packageId);
  // Encode the String

  let shareUrl = `${SERVER_URL}deal.do?offerId=${encodedString}`;

  const title = AppConstant.name;

  useEffect(() => {
    apiCall(WebApi.getPackageDetails, { packageId: packageId }, (response) => {
      //  console.log("hello111",response.data.tourHighlights)
      if (response.data) setPackageDetails(response.data);
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [packageId]);

  // console.log("infopack", packageId);

  const childRef = React.useRef();
  return (
    <GridContainer className={classes.root}>
      <GridContainer>
        <GridContainer>
          <Grid
            item
            md={4}
            style={{ paddingLeft: "3%", paddingRight: "3%" }}
            xs={12}
          >
            <AppBar
              position="sticky"
              style={{ boxShadow: "none" }}
              className={classes.appbar}
            >
              <GridContainer>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Typography
                    style={{
                      color: theme.palette.primary.defaultText,
                      fontSize: isMobile ? 12 : 13,
                      cursor: "pointer",
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                    onClick={() => props.history.push("/")}
                  >
                    Home
                  </Typography>
                  <Typography
                    style={{
                      color: theme.palette.primary.defaultText,
                      fontSize: isMobile ? 12 : 13,
                      cursor: "pointer",
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                    onClick={() => props.history.goBack()}
                  >
                    Destinations
                  </Typography>
                  <Typography
                    style={{
                      color: theme.palette.secondary.crimson,
                      fontSize: isMobile ? 12 : 13,
                      cursor: "pointer",
                      fontSize: 15,
                      fontWeight: 700,
                    }}
                    onClick={(e) => {
                      console.log(packageDetails);
                      e.preventDefault();
                    }}
                  >
                    {/* {packageDetails?.[0]?.packageName} */}
                    {props.location.state?.packageType
                      ?.toLowerCase()
                      ?.split(" ")
                      ?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      ?.join(" ")}
                  </Typography>
                </Breadcrumbs>
              </GridContainer>
            </AppBar>

            {packageDetails &&
              packageDetails?.map((category, ind) => {
                return (
                  <div key={ind}>
                    <Typography
                      align="left"
                      style={{
                        fontSize: isMobile ? 15 : "23px",
                        lineHeight: "30px",
                        fontWeight: "500",
                        marginBottom: "16px",
                        color: theme.palette.primary.defaultText,
                        paddingTop: "20px",
                      }}
                    >
                      {category.packageName}
                    </Typography>
                    <GridContainer
                      style={{
                        borderRadius: "10px",
                        display: "inline-block",
                        position: "relative",
                        marginBottom: "15px",
                        minHeight: "333px",
                        background: theme.palette.primary.lightText,
                        boxShadow: `0px 0px 5px -2px ${theme.palette.secondary.grayDove}`,
                      }}
                    >
                      <Grid item md={12}></Grid>
                      <Grid item md={12}>
                        <img
                          src={
                            SERVER_URL +
                            "packageadmin/ImageReaderArticle?path=/" +
                            category.packageType +
                            "/" +
                            category.packageCategoryId +
                            "/" +
                            category.packageId +
                            "/" +
                            category.image?.mainImagePath
                          }
                          width="100%"
                          style={{
                            borderRadius: "10px 10px 0px 0px",
                            height: 268,
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ padding: 20 }}
                        className={classes.typoroot}
                      >
                        <GridContainer>
                          <Grid item md={6} xs={6}>
                            <Typography align="left"> Start from</Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography align="right">
                              {" "}
                              {category.startFrom}
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Divider />
                          </Grid>
                        </GridContainer>
                        <GridContainer>
                          <Grid item md={6} xs={6}>
                            <Typography align="left"> Ends At</Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography align="right">
                              {" "}
                              {category.endAt}
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Divider />
                          </Grid>
                        </GridContainer>
                        <GridContainer>
                          <Grid item md={6} xs={6}>
                            <Typography align="left"> Valid from</Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography align="right">
                              {moment(category.validFrom).format("DD/MM/YYYY")}
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Divider />
                          </Grid>
                        </GridContainer>
                        <GridContainer>
                          <Grid item md={6} xs={6}>
                            <Typography align="left"> Valid till</Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography align="right">
                              {" "}
                              {moment(category.validTill).format("DD/MM/YYYY")}
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Divider />
                          </Grid>
                        </GridContainer>
                        <GridContainer>
                          <Grid item md={6} xs={6}>
                            <Typography align="left"> Duration</Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography align="right">
                              {" "}
                              {category.duration?.totalNight}Nights/
                              {category.duration?.toalDay}Days
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Divider />
                          </Grid>
                        </GridContainer>
                        <GridContainer>
                          <Grid item md={6} xs={6}>
                            <Typography align="left"> Price Onwards</Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography align="right">
                              {multiCurrencyFormatter(category.price_start, category.currency)}
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Divider />
                          </Grid>
                        </GridContainer>
                        {userType === "S" || userType === "C" &&
                          <GridContainer>
                            <Grid item md={6} xs={6}>
                              <Typography align="left">TSC</Typography>
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <Typography align="right">
                                {multiCurrencyFormatter(commission, category.currency)}
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider />
                            </Grid>
                          </GridContainer>
                        }
                        <GridContainer style={{ marginTop: "10px" }}>
                          <Grid item md={6} xs={6}>
                            <Button
                              className={classes.Button}
                              onClick={() => props.history.goBack()}
                            >
                              Change category
                            </Button>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            container
                            justifyContent="flex-end"
                            xs={6}
                          >
                            <Button
                              className={classes.Button2}
                              onClick={() => childRef.current.setValue(3)}
                            >
                              Request now
                            </Button>
                          </Grid>
                        </GridContainer>
                      </Grid>
                    </GridContainer>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      {/* window.open("/packages/fulldetails", "_blank") */}
                      <Grid item>
                        <WhatsappShareButton
                          url={shareUrl}
                          title={title}
                          separator=":: "
                          className={classes.DemoButton}
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </Grid>
                      <Grid item>
                        <EmailShareButton
                          url={shareUrl}
                          className={classes.DemoButton}
                        >
                          <EmailIcon size={32} round></EmailIcon>
                        </EmailShareButton>
                      </Grid>

                      {/* <Button variant="contained" className={classes.Button2} style={{marginTop:0}} onClick={()=>window.open("/packages/fulldetails/"+packageId, "_blank")}>Share</Button> */}
                    </Grid>
                  </div>
                );
              })}
          </Grid>
          <GridItem md={8} className={classes.tabgrid} xs={12} id="picture">
            <GridContainer>
              <Grid item md={12} xs={12}>
                <PackageTabDetails
                  details={packageDetails}
                  ref={childRef}
                  packageId={packageId}
                />
              </Grid>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridContainer>
      {/* {props?.location?.state?.categoryId && (
        <Grid
          item
          md={12}
          className={!isMobile && classes.Grid}
          style={{ padding: isMobile && 5 }}
        >
          <Grid container spacing={isMobile ? 0 : 2}>
            {props.location.state.categoryId.map((category, index) => {
              return (
                <Grid
                  item
                  md={4}
                  xs={12}
                  style={{ marginBottom: isMobile && 10 }}
                >
                  <GridContainer>
                    <Grid
                      item
                      className="destination-box"
                      onClick={() => {
                        setPackageId(category.packageId);
                      }}
                    >
                      <img
                        src={
                          SERVER_URL +
                          "packageadmin/ImageReaderArticle?path=/" +
                          props.location.state.packageType +
                          "/" +
                          props.location.state.packageCategoryId +
                          "/" +
                          category.packageId +
                          "/" +
                          category.packageImage
                        }
                        width="100%"
                      />
                      <p>
                        {" "}
                        {category.duration.toalDay}D/
                        {category.duration.totalNight}N
                      </p>
                      <div className="destination-main-text">
                        <p> {category.packageName}</p>
                        <span style={{ fontFamily: "Roboto" }}>
                          {currencyFormatter(category.priceStartingFrom)}
                        </span>
                      </div>
                    </Grid>
                  </GridContainer>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )} */}
    </GridContainer>
  );
};

export default PackageInfo;
