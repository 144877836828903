import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';

const OaPriTabs = (props) => {
    return (
        <Paper elevation={16} className={"oa-tabs-primary"}>
        <Tabs indicatorColor="secondary" textColor="primary" value={props.currentTab}>

        {props.tabs.map( (row, id) => {
            return(<Tab label={row.label} key={id} value={row.name} to={row.url} component={Link}/>);
        })}

        </Tabs>
        </Paper>
    )
}

export default OaPriTabs;