import React, { useState, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import OaFormSelectField from "oahoc/OaFormSelectField";

import { Formik, Form, ErrorMessage } from "formik";
import sub from "date-fns/sub";
import dateFnsFormat from "date-fns/format";
import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";

import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaFormSaveButton from "oahoc/OaFormSaveButton";
import FormControl from "@material-ui/core/FormControl";
import OaFormSelectComboField from "oahoc/OaFormSelectComboField";
import OaAlert from "oahoc/OaAlert";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import OaFormImageButton from "oahoc/OaFormImageButton";
import { SettingsBluetoothOutlined } from "@material-ui/icons";
import OaFormDateField from "oahoc/OaFormDateField";
import { makeStyles,useTheme } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  addSenderRoot: {
    "& .padd-t11": {
      paddingTop: 11,
    }
  },
}));

const AddBeneficiaryForm = (props) => {
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const [collapsed, setCollapsed] = useState(true);

  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const [ifsc, setIfsc] = useState("");
  const [branchDetails, setBranchDetails] = useState("");
  const [banknameDesc, setBankNameDesc] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = useState(false);
  const [alertCallbackParam, setAlertCallbackParam] = useState({});
  const [alertAdditionalData, setAlertAdditionalData] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [primaryButtonText, setPrimaryButtonText] = useState("");
  const [secondaryButtonText, setSecondaryButtonText] = useState("");
  const [beneName, setBenename] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (isLoading) {
      apiCall(WebApi.dmtBankList, {}, (response) => {
        if (response.success === true) {
          // console.log("response.data");
          // console.log(response.data);
          response.data.sort(function(a, b) {
            var nameA = a.bankName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.bankName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

          setBanks(response.data);
        } else {
          setIsError(true);
        }
      });
    }
  }, [isLoading]);

  function changeBank(event, selectedObject, setFieldValue) {
    setBranchDetails("");
    setBankNameDesc(selectedObject.bankName);
    setFieldValue("ifsccode", "");
    setIfsc(selectedObject.ifscCode);
    // getBranchDetails(selectedObject.ifsc);
    if (selectedObject.ifscCode)
      setFieldValue("ifsccode", selectedObject.ifscCode);
  }

  function transactionHelper(buttonText, alertCallbackParam, additionalData) {
    if (buttonText == "YES") {
      props.history.push("/moneytransfer/beneficiary-list", additionalData);
    }

    if (buttonText == "NO") {
      setIsSaving(false);
      setIsError(false);
      setIsSuccess(false);
    }
  }

  function backToBeneficaryList() {
    props.history.push(
      "/moneytransfer/beneficiary-list",
      props.location.query.data
    );
  }

  function getBranchDetails(ifscCode) {
    setBranchDetails("");
    apiCall(WebApi.dmtBranchDetails, { ifsc: ifscCode }, (response) => {
      // console.log(response);
      if (response.data && response.data.BANK) {
        setBranchDetails(
          response.data.BANK +
            ", " +
            response.data.BRANCH +
            ", " +
            response.data.ADDRESS +
            ", " +
            response.data.STATE
        );
      } else {
        setBranchDetails("Invalid IFSC");
      }
    });
  }

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }
  const theme=useTheme()
  return (
    <div className={classes.addSenderRoot}>
      <GridContainer>
        <GridItem md={9}>
          <h4
            className={"oa-form-header"}
            onClick={collapse}
            style={{ transform: " scale(0.85)", transformOrigin: "left" }}
          >
            Sender Details
          </h4>
        </GridItem>

        <GridItem md={3}>
          <FormControl>
            <OaFormImageButton
              color="primary"
              icon={<KeyboardReturnIcon style={{ fontSize: "24px" }} />}
              style={{ width: 200 }}
              onClick={() => {
                backToBeneficaryList();
              }}
              label={"Beneficiary List"}
            ></OaFormImageButton>
          </FormControl>
        </GridItem>
      </GridContainer>
      <OaAlert
        isPrompt={isAlertPrompt}
        callback={transactionHelper}
        callbackParam={alertCallbackParam}
        msg={alertMsg}
        title={alertTitle}
        additionalData={alertAdditionalData}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        togglePrompt={() => {
          setIsAlertPrompt(!isAlertPrompt);
        }}
      ></OaAlert>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        alertTitle={alertTitle}
      />
      <Formik
        initialValues={{
          mobile: props.location.query ? props.location.query.mobile : "",
          accno: "",
          confirmAccno: "",
          bankname: "",
          benename: beneName,
          ifsccode: ifsc,
          // gstState:'23',
          // dob: dateFnsFormat(sub(new Date(), { years: 18 }), "dd-MM-yyyy"),
          // dobDisplay: sub(new Date(), { years: 18 }),
          // address: "",
          // pincode: ""
        }}
        validationSchema={Yup.object().shape({
          mobile: Yup.string().required(
            "Sender mobile number cannot be blank, Please go back and try again"
          ),
          accno: Yup.string().required(
            "Please enter beneficiary account number"
          ),
          confirmAccno: Yup.string()
            .oneOf(
              [Yup.ref("accno"), null],
              "Beneficiary account number and Confirm account number do not match"
            )
            .required("Beneficiary account number confirm is required"),
          benename: Yup.string()
            .required("Please enter account holder name")
            .nullable(),
          bankname: Yup.string().required(
            "Please select beneficiary bank name"
          ),
          // ifsccode: Yup.string()
          //     .required("Please enter beneficiary bank IFSC Code"),
        })}
        onSubmit={(
          searchParams,
          { setSubmitting, resetForm, enableReinitialize }
        ) => {
          setIsError(false);
          setIsSuccess(false);
          setSubmitting(true);
          setIsSaving(true);

          searchParams.bankid = searchParams.bankname;
          if (isVerify) {
            searchParams.reference = searchParams.accno;
            apiCall(WebApi.dmtAccountVerify, searchParams, (response) => {
              setIsError(!response.success);
              setIsSuccess(response.success);
              setSubmitting(false);
              setIsSaving(false);

              setInfoText(response.message);
              setAlertTitle("Error verifying");
              if (response.success === true && response.data) {
                setIsError(!response.data.status);
                setIsSuccess(response.data.status);
                setInfoText(response.data.message);

                if (response.data.status === true) {
                  setBenename(response.data.benename);

                  setIsError(!response.data.benename);
                  setIsSuccess(response.data.benename);

                  setAlertTitle("Verified successfully");
                  setInfoText(
                    response.data.message +
                      (response.data.benename
                        ? ". Beneficiary name: " + response.data.benename
                        : ".")
                  );

                  // adding beneficiary once account detail verification is successful
                  // START BENE ADD

                  if (response.data.benename) {
                    setTimeout(() => {
                      setSubmitting(true);
                      setIsSaving(true);
                      searchParams.benename = response.data.benename;
                      searchParams.banknameDesc = banknameDesc;
                      searchParams.verified =
                        response.data.ackno == "NA" ? false : true;

                      apiCall(
                        WebApi.addDMTBeneficiary,
                        searchParams,
                        (response) => {
                          setIsError(!response.success);
                          setIsSuccess(response.success);
                          setSubmitting(false);
                          setIsSaving(false);

                          setInfoText(response.message);
                          setAlertTitle("Error adding");
                          // console.log(response);
                          if (response.success === true && response.data) {
                            setIsError(!response.data.status);
                            setInfoText(response.data.message);
                            if (response.data.status === true) {
                              setAlertTitle("Success adding");
                              setIsSuccess(false);
                              setBranchDetails("");
                              setIfsc("");
                              setAlertCallbackParam(searchParams);
                              setAlertAdditionalData(props.location.query.data);
                              setPrimaryButtonText("Back to Beneficiary List");
                              setSecondaryButtonText("Add More");
                              setIsAlertPrompt(true);
                              setAlertMsg(response.data.message);
                              enableReinitialize = true;
                              resetForm(true);
                            }
                          }
                        }
                      );
                    }, 5000);
                  }
                  // END BENE ADD
                }
              } else {
                if (response.message) {
                  setIsError(!response.success);
                  setIsSuccess(response.success);
                  setInfoText(response.message);
                }
              }
            });
          } else {
            searchParams.benename = beneName;
            searchParams.banknameDesc = banknameDesc;
            searchParams.verified = false;
            apiCall(WebApi.addDMTBeneficiary, searchParams, (response) => {
              setIsError(!response.success);
              setIsSuccess(response.success);
              setSubmitting(false);
              setIsSaving(false);

              // setInfoText("Please recheck entered data");
              setInfoText(response.message);
              setAlertTitle("Error adding");
              // console.log(response);
              if (response.success === true && response.data) {
                setIsError(!response.data.status);
                setIsSuccess(response.data.status);
                setInfoText(response.data.message);
                if (response.data.status === true) {
                  setAlertTitle("Success adding");
                  setIsSuccess(false);
                  setBranchDetails("");
                  setIfsc("");
                  setAlertCallbackParam(searchParams);
                  setAlertAdditionalData(props.location.query.data);
                  setPrimaryButtonText("Back to Beneficiary List");
                  setSecondaryButtonText("Add More");
                  setIsAlertPrompt(true);
                  setAlertMsg(response.data.message);
                  enableReinitialize = true;
                  resetForm(true);
                }
              }
            });
          }
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          enableReinitialize,
          resetForm,
        }) => (
          <Form>
            <GridContainer>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="mobile"
                  label="Sender Mobile Number *"
                  disabled={true}
                />
                <ErrorMessage name="mobile" component="div" className="error" />
              </GridItem>
            </GridContainer>
            <h4
              className={"oa-form-header"}
              onClick={collapse}
              style={{
                transform: " scale(0.85)",
                transformOrigin: "left",
                paddingTop: 30,
              }}
            >
              Enter Beneficiary Details
            </h4>
            <GridContainer>
              <GridItem md={4}>
                <OaFormSelectComboField
                  label="Bank *"
                  name="bankname"
                  value={values.bankname}
                  options={banks}
                  optionValue={"id"}
                  optionLabel={["bankName", "ifscCode"]}
                  required={true}
                  setFieldValue={setFieldValue}
                  changeHandler={changeBank}
                />
                <ErrorMessage
                  name="bankname"
                  component="div"
                  className="error"
                />
              </GridItem>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page padd-t11"
                  name="ifsccode"
                  label="IFSC *"
                  value={ifsc}
                  onChange={(event) => {
                    const ifscCode = event.target.value;
                    setFieldValue("ifsccode", ifscCode);
                    setIfsc(ifscCode);
                    setBranchDetails("");
                  }}
                />
                {branchDetails && <span>{branchDetails}</span>}
                <ErrorMessage
                  name="ifsccode"
                  component="div"
                  className="error"
                />
              </GridItem>
              <GridItem md={4}>
                <Button
                  color="info"
                  type="button"
                  disabled={isSubmitting}
                  style={{ width: 200 }}
                  onClick={() => {
                    getBranchDetails(ifsc);
                  }}
                >
                  Check IFSC
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor:theme.palette.background.brightWhisper }}
                  className="input-landing-page"
                  name="accno"
                  label="Account Number*"
                />
                <ErrorMessage name="accno" component="div" className="error" />
              </GridItem>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="confirmAccno"
                  label="Confirm Account Number *"
                />
                <ErrorMessage
                  name="confirmAccno"
                  component="div"
                  className="error"
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="benename"
                  label="Name of the Account Holder *"
                  value={beneName}
                  onChange={(e) => {
                    setBenename(e.target.value);
                    setFieldValue("benename", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="benename"
                  component="div"
                  className="error"
                />
              </GridItem>

              {/* <GridItem md={4}>
                <OaFormDateField
                  className="input-landing-page"
                  style={{ backgroundColor: "#ebebeb" }}
                  value={values.dobDisplay}
                  selected={values.dobDisplay}
                  onChange={(date) => setFieldValue("dobDisplay", date)}
                  label="Date of Birth"
                  name="dobDisplay"
                  maxDate={sub(new Date(), { years: 18 })}
                />
                <ErrorMessage name="dob" component="div" className="error" />
              </GridItem> */}
            </GridContainer>
            {/* <GridContainer>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: "#ebebeb" }}
                  className="input-landing-page"
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={(e) => {
                    setFieldValue("address", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </GridItem>

              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: "#ebebeb" }}
                  className="input-landing-page"
                  name="pincode"
                  label="Pincode"
                  value={values.pincode}
                  onChange={(e) => {
                    setFieldValue("pincode", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="error"
                />
              </GridItem>
            </GridContainer> */}
            {/* <GridContainer>

              <GridItem md={4}>
                <OaFormSelectComboField
                  label="GST State"
                  name="gstState"
                  value={values.gstState}
                  options={[{name: 'JAMMU AND KASHMIR', gstCode:	'01'},
                  {name: 'HIMACHAL PRADESH', gstCode:	'02'},
                  {name: 'PUNJAB', gstCode:	'03'},
                  {name: 'CHANDIGARH', gstCode:	'04'},
                  {name: 'UTTARAKHAND', gstCode:	'05'},
                  {name: 'HARYANA', gstCode:	'06'},
                  {name: 'DELHI', gstCode:	'07'},
                  {name: 'RAJASTHAN', gstCode:	'08'},
                  {name: 'UTTAR PRADESH', gstCode:	'09'},
                  {name: 'BIHAR', gstCode:	'10'},
                  {name: 'SIKKIM', gstCode:	'11'},
                  {name: 'ARUNACHAL PRADESH', gstCode:	'12'},
                  {name: 'NAGALAND', gstCode:	'13'},
                  {name: 'MANIPUR', gstCode:	'14'},
                  {name: 'MIZORAM', gstCode:	'15'},
                  {name: 'TRIPURA', gstCode:	'16'},
                  {name: 'MEGHLAYA', gstCode:	'17'},
                  {name: 'ASSAM', gstCode:	'18'},
                  {name: 'WEST BENGAL', gstCode:	'19'},
                  {name: 'JHARKHAND', gstCode:	'20'},
                  {name: 'ODISHA', gstCode:	'21'},
                  {name: 'CHATTISGARH', gstCode:	'22'},
                  {name: 'MADHYA PRADESH', gstCode:	'23'},
                  {name: 'GUJARAT', gstCode:	'24'},
                  {name: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)', gstCode:	'26'},
                  {name: 'MAHARASHTRA', gstCode:	'27'},
                  {name: 'ANDHRA PRADESH(BEFORE DIVISION)', gstCode:	'28'},
                  {name: 'KARNATAKA', gstCode:	'29'},
                  {name: 'GOA', gstCode:	'30'},
                  {name: 'LAKSHWADEEP', gstCode:	'31'},
                  {name: 'KERALA', gstCode:	'32'},
                  {name: 'TAMIL NADU', gstCode:	'33'},
                  {name: 'PUDUCHERRY', gstCode:	'34'},
                  {name: 'ANDAMAN AND NICOBAR ISLANDS', gstCode:	'35'},
                  {name: 'TELANGANA', gstCode:	'36'},
                  {name: 'ANDHRA PRADESH (NEWLY ADDED)', gstCode:	'37'},
                  {name: 'LADAKH (NEWLY ADDED)', gstCode:	'38'}]}
                 
                  optionValue={"gstCode"}
                  optionLabel={["name", "gstCode"]}
                  required={true}
                  setFieldValue={setFieldValue}
                  // changeHandler={changeBank}
                />
                <ErrorMessage
                  name="bankname"
                  component="div"
                  className="error"
                />
              </GridItem>
            </GridContainer> */}
            <GridContainer style={{ marginTop: 20 }}>
              <GridItem>
                <FormControl>
                  <Button
                    color="info"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 200, color: theme.palette.primary.lightText, }}
                    onClick={() => {
                      setIsVerify(true);
                    }}
                  >
                    Verify & Add (₹ 4.00)
                  </Button>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 200 }}
                    onClick={() => {
                      setIsVerify(false);
                    }}
                  >
                    Add Beneficiary
                  </Button>
                </FormControl>
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>
      {/* <Link to={{ pathname: '/admin/moneytransfer/verify-otp', query: { mobile: '8744027274' } }}>
        
                Verify OTP
        </Link> */}
    </div>
  );
};

export default AddBeneficiaryForm;
