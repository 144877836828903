import React, { useState, useEffect } from "react";

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import {
  Warning,
  AccountBalanceWallet,
  Pause,
  Schedule,
  Done,
  Flight,
  Train,
  AttachMoney,
  Hotel,
  DirectionsBus,
  PhoneAndroid,
  StoreMallDirectory,
} from "@material-ui/icons";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Info from "components/Typography/Info.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import OaCard from "oahoc/OaCard";
import OaLoading from "pages/components/OaLoading";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { currencyFormatter } from "oautils/oaCommonUtils";
import { Link } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
 

const localizer = momentLocalizer(moment);
const useStyles = styles;

export default function Dashboard() {
  

  const classes = useStyles();
  const [events, setEvents] = React.useState([]);
  let MyCustomHeader = ({ label }) => (
    <div style={{ padding: "10px 0 10px 0" }}>{label}</div>
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [serviceOptions, setServiceOptions] = useState([]);

  const CustomEvent = (event) => {
    return (
      <span>
        <strong>{event.title} </strong>( <strong>{event.event.count}</strong> )
        - <strong>&#x20B9; {event.event.amount}</strong>
      </span>
    );
  };

  const colorList=[{theme: 'warning', code: '#FD9811'}, {theme: 'info', code: '#0DB5CA'}, {theme: 'danger', code: '#ef5350'}, {theme: 'success', code: '#66bb6a'}];
  let colorCounter = -1;
  const getColor = (isChange) => {

      if(isChange === true && colorCounter === 3) {
        colorCounter = 0;
        const obj =  colorList.shift();
        colorList.push(obj)
     
      } else if(isChange === true || colorCounter === -1) {
        colorCounter++;
      }
  
      return colorList[colorCounter];
  }

  useEffect(() => {
    if (Object.keys(dashboardData).length === 0) {
      apiCall(WebApi.getDashboardData, {}, function(response) {
        setDashboardData(response.data);
      });

      apiCall(
        WebApi.getDashboardCalendarData,
        { date: moment(new Date()).format("YYYY-MM-DD") },
        function (response) {
          // console.log("calendar data " + response.data);
          moment().utcOffset("+05:30").format();
          let appointments = response.data.items;
          if (appointments)
            for (let i = 0; i < appointments.length; i++) {
              appointments[i].start = moment
                .utc(appointments[i].start)
                .toDate();
              appointments[i].end = moment.utc(appointments[i].end).toDate();
            }

          setEvents(appointments);
        }
      );
      setIsLoading(false);
    }
    localforageGetItem("user-details", function(err, value) {
      if (value) {
        setServiceOptions(value.serviceOption);
      }
    });
  }, [dashboardData]);
  const theme = useTheme();
  return (
    <>
      {isLoading && <OaLoading />}
      {!isLoading && (
        <OaCard
          className={"oa-card-primary"}
          style={{ background: theme.palette.primary.darkGray }}  
          // marginTop: "50px",
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3} style={{ marginTop: 20 }}>
              <Card>
                <CardHeader color={getColor(true).theme} stats icon>
                  <CardIcon color={getColor().theme}>
                    <Icon>
                      <AccountBalanceWallet />
                    </Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Wallet Balance</p>
                  <h4 className={classes.cardTitle}>
                    {currencyFormatter(dashboardData.mainAccountBalance)}
                  </h4>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Info>
                      <LibraryBooksIcon style={{ color: getColor().code }} />
                    </Info>
                    <Link to="/admin/ledger" style={{ color: getColor().code }}>
                      Ledger
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={6} lg={3} style={{ marginTop: 20 }}>
              <Card>
                <CardHeader color={getColor(true).theme} stats icon>
                  <CardIcon color={getColor().theme}>
                    <Icon>
                      <Schedule />
                    </Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Dept Scheduled</p>
                  <h4 className={classes.cardTitle}>{currencyFormatter(dashboardData.departureScheduleCountPrice)}({dashboardData.departureScheduleCount})</h4>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Info><LocalOffer style={{ color: getColor().code }}/></Info>
                    <Link to="/admin/booking-report/flight" style={{color: getColor().code}}>
                      Flight Report
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={6} lg={3} style={{ marginTop: 20 }}>
              <Card>
                <CardHeader color={getColor(true).theme} stats icon>
                  <CardIcon color={getColor().theme}>
                    <Icon> <Done/></Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Dept Completed</p>
                  <h4 className={classes.cardTitle}>{currencyFormatter(dashboardData.departureCompletedCountPrice)}({dashboardData.departureCompletedCount})</h4>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                  <Info><LocalOffer style={{ color: getColor().code }}/></Info>
                    <Link to="/admin/booking-report/flight" style={{color: getColor().code}}>
                      Flight Report
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            {serviceOptions.map((option, index) => {
              if (option.serviceValue === "flights") {
                return (
                  <GridItem key={index} xs={12} sm={6} md={6} lg={3} style={{ marginTop: 20 }}>
                    <Card>
                      <CardHeader color={getColor(true).theme} stats icon>
                        <CardIcon color={getColor().theme}>
                          <Icon>
                            {" "}
                            <Flight />
                          </Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>Flight</p>
                        <h4 className={classes.cardTitle}>
                          {currencyFormatter(
                            dashboardData.flightTicketBookedPrice
                          )}
                          ({dashboardData.flightTicketBookedCount}){" "}
                          <small></small>
                        </h4>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Info>
                            <LocalOffer style={{ color: getColor().code }} />
                          </Info>
                          <Link
                            to="/admin/booking-report/flight"
                            style={{ color: getColor().code }}
                          >
                            Flight Report
                          </Link>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              } else if (option.serviceValue === "moneytransfer") {
                // return (
                //   <GridItem
                //     xs={12}
                //     sm={6}
                //     md={6}
                //     lg={3}
                //     style={{ marginTop: 20 }}
                //     key={index}
                //   >
                //     <Card>
                //       <CardHeader color={getColor(true).theme} stats icon>
                //         <CardIcon color={getColor().theme}>
                //           <Icon>
                //             <AttachMoney />
                //           </Icon>
                //         </CardIcon>
                //         <p className={classes.cardCategory}>DMT</p>
                //         {!isLoading && (
                //           <h4 className={classes.cardTitle}>
                //             {currencyFormatter(dashboardData.dmtAccountBalance)}
                //             ({dashboardData.moneyTransferTransactionCount})
                //           </h4>
                //         )}
                //       </CardHeader>
                //       <CardFooter stats>
                //         <div className={classes.stats}>
                //           <Info>
                //             <LocalOffer style={{ color: getColor().code }} />
                //           </Info>
                //           <Link
                //             to="/admin/booking-report/moneytransfer"
                //             style={{ color: getColor().code }}
                //           >
                //             DMT Report
                //           </Link>
                //         </div>
                //       </CardFooter>
                //     </Card>
                //   </GridItem>
                // );
              } else if (option.serviceValue === "recharge") {
                  // return (
                  // <GridItem
                  //   xs={12}
                  //   sm={6}
                  //   md={6}
                  //   lg={3}
                  //   style={{ marginTop: 20 }}
                  //   key={index}
                  // >
                  //   <Card>
                  //     <CardHeader color={getColor(true).theme} stats icon>
                  //       <CardIcon color={getColor().theme}>
                  //         <Icon>phone_android</Icon>
                  //       </CardIcon>
                  //       <p className={classes.cardCategory}>Recharge</p>
                  //       {!isLoading && (
                  //         <h4 className={classes.cardTitle}>
                  //           {currencyFormatter(
                  //             dashboardData.rechargeCountPrice
                  //           )}
                  //           ({dashboardData.rechargeCount})
                  //         </h4>
                  //       )}
                  //     </CardHeader>
                  //     <CardFooter stats>
                  //       <div className={classes.stats}>
                  //         <Info>
                  //           <LocalOffer style={{ color: getColor().code }} />
                  //         </Info>
                  //         <Link
                  //           to="/admin/booking-report/recharge"
                  //           style={{ color: getColor().code }}
                  //         >
                  //           Recharge Report
                  //         </Link>
                  //       </div>
                  //     </CardFooter>
                  //   </Card>
                  // </GridItem>
                  // );
              } else if (option.serviceValue === "train") {
                // return (
                //   <GridItem
                //     xs={12}
                //     sm={6}
                //     md={6}
                //     lg={3}
                //     style={{ marginTop: 20 }}
                //     key={index}
                //   >
                //     <Card>
                //       <CardHeader color={getColor(true).theme} stats icon>
                //         <CardIcon color={getColor().theme}>
                //           <Icon>
                //             {" "}
                //             <Train />
                //           </Icon>
                //         </CardIcon>
                //         <p className={classes.cardCategory}>Train</p>
                //         {!isLoading && (
                //           <h4 className={classes.cardTitle}>
                //             {currencyFormatter(0)}(0)
                //           </h4>
                //         )}
                //       </CardHeader>
                //       <CardFooter stats>
                //         <div className={classes.stats}>
                //           <DateRange color="disabled" />
                //           To be implemented
                //         </div>
                //       </CardFooter>
                //     </Card>
                //   </GridItem>
                // );
              } else if (option.serviceValue === "buses") {
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    style={{ marginTop: 20 }}
                    key={index}
                  >
                    <Card>
                      <CardHeader color={getColor(true).theme} stats icon>
                        <CardIcon color={getColor().theme}>
                          <Icon>
                            {" "}
                            <DirectionsBus />
                          </Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>Bus</p>
                        {!isLoading && (
                          <h4 className={classes.cardTitle}>{currencyFormatter(dashboardData.busCountPrice)}({dashboardData.busCount})</h4>
                        )}
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Info><LocalOffer style={{ color: getColor().code }}/></Info>
                          <Link to="/admin/booking-report/bus" style={{color: getColor().code}}>
                            Bus Report
                          </Link>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              } else if (option.serviceValue === "hotels") {
                return (
                  <GridItem xs={12} sm={6} md={6} lg={3} style={{ marginTop: 20 }} key={index}>
                    <Card>
                      <CardHeader color={getColor(true).theme} stats icon>
                        <CardIcon color={getColor().theme}>
                          <Icon> <Hotel/></Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>Hotel</p>
                        {!isLoading &&
                          <h4 className={classes.cardTitle}>{currencyFormatter(dashboardData.hotelCountPrice)}({dashboardData.hotelCount})</h4>
                        }
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                        <Info><LocalOffer style={{ color: getColor().code }}/></Info>
                          <Link to="/admin/booking-report/hotel" style={{color: getColor().code}}>
                            Hotel Report
                          </Link>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              }
            })}
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody calendar>
                  <div style={{ padding: 10 }}>
                  <BigCalendar
                      localizer={localizer}
                      events={events}
                      defaultView="month"
                      defaultDate={new Date()}
                      views={["month", "week", "day"]}
                      components={{
                        event: CustomEvent,
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </OaCard>
      )}
    </>
  );
}
