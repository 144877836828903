import React from 'react'
import GridItem from "components/Grid/GridItem.js";
import { TextField, FormControl } from "@material-ui/core";
import { Field } from 'formik';

const OaFormPasswordField = (props) => {
    let useGrid = props.md !== undefined;

    function field(){
        return(
            <FormControl fullWidth={true}>
                <Field {...props}  
                    variant="outlined" 
                    className={"oa-input-white"}
                    type="password"
                    as={TextField} 
                />
            </FormControl>
        )
    }
    return (
        <>
            {!useGrid && field()}
            {useGrid &&         
                (
                    <GridItem xs={12} sm={12} md={props.md}>
                        {field()}
                    </GridItem>
                )
            }
        </>
    )


}

export default OaFormPasswordField;