import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WebApi from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import {
  localforageSetItem,
  localforageClear,
  localforageGetItem,
} from "oautils/oaForageUtils";

const useStyles = makeStyles(styles);
const jwt = require("jsonwebtoken");

function LoadingPage(props) {
  const [isloading, setIsloading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    var returnedValue = window.getInputValues();
    // return console.log('returnedValue', returnedValue)
    // console.log(returnedValue)
    // if (isloading) {
    //   // var returnedValue = {
    //   //   path: "landing",
    //   // };
    //   var returnedValue = window.getInputValues();
    //   console.log("returnedValue.path");
    //   console.log(returnedValue.path);
    //   history.push({
    //     pathname:
    //       "/" +
    //       (returnedValue && returnedValue.path
    //         ? returnedValue.path
    //         : "landing"),
    //     state: returnedValue.params,
    //   });
    //   setIsloading(false);
    // }

    // returnedValue = {
    //   //path:'flightticket/263',
    //   path: "landingpage/hotel",
    //   params: { transactionId: "220" },
    //   loggedInUserId: 1034,
    //   encKey: "*12952BEFDD63CDE71756266883C49F89715CFED3",
    //   userName: "UditAgent",
    // };

    // returnedValue = {
    //   path: "print/flightticket/52306",
    //   // path: "landingpage/hotel",
    //   params: { transactionId: "52306" },
    //   loggedInUserId: 1049,
    //   encKey: "*DC20A106F91B562B9EAB4E43F5E6C88047F60C28",
    //   userName: "oari",
    //   userType: "S"
    // };

    // returnedValue = {
    //   //path:'flightticket/263',
    //   path: "landingpage/flights",
    //   // params: { transactionId: "220" },
    //   loggedInUserId: 1049,
    //   encKey: "*47B150E012313114C04A1C9336709424085B6BD0",
    //   userName: "OariAgent",
    // };





    // returnedValue = {
    //   encKey: "null",
    // loggedInUserId: 0,
    // params: 1,
    // path: "landingpage",
    // userName: "null",
    // userType: "G"
    // };

    localforageGetItem("access-key", function (err, accessKey) {
      // console.log('access-key found')

      if (accessKey) {
        // console.log(accessKey)
        history.push({
          pathname: "/" + returnedValue.path,
          state: returnedValue.params,
        });
      }

      else if (!accessKey) {

        // console.log('access-key not found')

        // console.log(returnedValue.userType)
        if (returnedValue.userType && returnedValue.userType === 'G') {
          // console.log(returnedValue.userType)
          WebApi.getAgentInfo(
            { userType: returnedValue.userType },
            (response) => {
              // console.log(response)
              if (response != null && response.success) {
                localforageClear(() => {
                  localforageSetItem("user-id", 0);
                  localforageSetItem("user-details", response.data, () => {
                    localforageSetItem("access-key", response.accesskey, () => {
                      history.push({
                        pathname: "/" + returnedValue.path,
                        state: returnedValue.params,
                      });
                    });
                  });
                })


              }
            })

        } else if (
          returnedValue.loggedInUserId != 0 &&
          returnedValue.encKey &&
          returnedValue.userName
        ) {
          // console.log('returnedValue.userType')
          // console.log(returnedValue.userType)
          localforageGetItem("access-key", function (err1, token) {
            // console.log(token);
            jwt.verify(token, "shhhhh", function (err, decoded) {
              // console.log(err);
              // console.log(err.name);
              if (err && err.name == "JsonWebTokenError") {
                // console.log(err.name);
                localforageClear(() => {
                  // console.log("clear3");
                  var params = {
                    agencyId: returnedValue.loggedInUserId,
                    encKey: returnedValue.encKey,
                    userName: returnedValue.userName,
                    userType: returnedValue.userType
                  };
                  WebApi.getAgentInfo(
                    params,
                    (response) => {
                      if (response != null && response.success === true) {
                        localforageSetItem("user-id", response.data.userId);
                        localforageSetItem("access-key", response.accesskey);
                        localforageSetItem("user-details", response.data, () => {
                          history.push({
                            pathname: "/" + returnedValue.path,
                            state: returnedValue.params,
                          });
                        });
                        setIsloading(false);
                      }
                    },
                    (error) => {
                      setIsloading(false);
                    }
                  );
                });
              } else {
                // console.log('returnedValue.params else')
                // console.log(returnedValue.params)
                history.push({
                  pathname: "/" + returnedValue.path,
                  state: returnedValue.params,
                });
              }
            });
          });
        } else {
          localforageSetItem("user-id", 0, () => {
            history.push({
              pathname: "/" + returnedValue.path,
              state: returnedValue.params,
            });
          });
        }
      }

    });
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }}
    >
      <CircularProgress disableShrink />
    </div>
  );
}

export default LoadingPage;
