import React from "react";
import { makeStyles } from "@material-ui/core";

const OfficeLocationMap = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className="location-map-holder">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.795746152677!2d76.82061957626932!3d30.63971349012097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f931ebfffffc5%3A0x4c044d909c47582d!2sLookmyticket%20Tours%20and%20Travels%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1684824089951!5m2!1sen!2sin" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};

export default OfficeLocationMap;

const useStyles = makeStyles(theme => ({
    root: {
        "& .location-map-holder": {
            "& iframe": {
                width: 1200,
                height: 450,
                border: 0,
                [theme.breakpoints.down(1200)]: {
                    width: "100%",
                    height: "45vh"
                }
            }
        }
    }
}));
