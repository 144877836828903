import React, { useEffect, useState, useRef } from "react";
import Collapse from "@material-ui/core/Collapse";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { Formik, Form, ErrorMessage } from "formik";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";
import OaFormRadio from "oahoc/OaFormRadio";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import FormControl from "@material-ui/core/FormControl";
import OaAlert from "oahoc/OaAlert";
import OaFormImageButton from "oahoc/OaFormImageButton";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import OaDmtConfirmationAlert from "pages/components/OaDmtConfirmationAlert";
import OaFormDateField from "oahoc/OaFormDateField";
import dateFnsFormat from "date-fns/format";
import sub from "date-fns/sub";
import OaFormSelectComboField from "oahoc/OaFormSelectComboField";
import { makeStyles,useTheme } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { COLORS } from "assets/css/CssConstants";
import { AppConstant } from "appConstant";

const useStyles = makeStyles(theme => ({
  transactionFormRoot: {
    "& .form-item": {
      padding: "10px 15px",
    },
    "& .dmt-form-row": {
      display: "grid",
      gap: "10px",
    },
    "& .dmt-form-item-left": {
      gridColumn: "1 / span 1",
      padding: 10,
      [theme.breakpoints.down(600)]: {
        gridColumn: "1 / span 2",
      },
    },
    "& .dmt-form-item-right": {
      gridColumn: "2 / span 1",
      padding: 10,
      [theme.breakpoints.down(600)]: {
        gridColumn: "1 / span 2",
      }
    },
    "& .sender-details-wrapper": {
      padding: "7px 0",
    },
    "& .dmt-form-header-text": {
      fontSize: 18,
      fontWeight: 500,
      margin: "5px 0",
      transform: " scale(0.85)", 
      transformOrigin: "left"
    },
    "& .sender-mobile-wrapper": {
      margin: "8px 0 0 0",
      "& .sender-detail-item-label": {
        margin: "0 10px 0 0",
      },
      "& .sender-detail-item": {
        color: theme.palette.secondary.sub,
      },
    },
    "& .right-align": {
      textAlign: "end",
    },
  },
}));

const TransactionForm = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isIMPS, setIsIMPS] = useState(props.location.state.data.banktype !== 'NEFT');
  const [isNEFT, setIsNEFT] = useState(props.location.state.data.banktype === 'NEFT');
  const classes = useStyles();
  const theme=useTheme()


  const [limit, setLimit] = useState(props.location.state.maxLimit);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  //const [data, setData] = useState({});

  const [isAlertPrompt, setIsAlertPrompt] = useState(false);
  const [alertCallbackParam, setAlertCallbackParam] = useState({});
  const [alertAdditionalData, setAlertAdditionalData] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [primaryButtonText, setPrimaryButtonText] = useState("");
  const [secondaryButtonText, setSecondaryButtonText] = useState("");
  const [hideSecondaryButton, setHideSecondaryButton] = useState(false);
  const [dialogRef, setDialogRef] = useState("");
  const [isDmtConfirmationPrompt, setIsDmtConfirmationPrompt] = useState(false);
  const formikRef = useRef();


  //   useEffect(() => {

  //     console.log(props.location.state.data)
  //     if(isLoading){
  //         apiCall(WebApi.getBeneficiaryDetails, {accno:props.location.state.data.accno}, (response) => {
  //           console.log(response.data)
  //             setIsLoading(false);
  //            if(response.data) {
  //             formikRef.current.setFieldValue('gstState', response.data.gstState?response.data.gstState:'23');
  //             formikRef.current.setFieldValue('dob', response.data.dob? response.data.dob:dateFnsFormat(sub(new Date(), { years: 18 }), "dd-MM-yyyy"));
  //             formikRef.current.setFieldValue('dobDisplay', (response.data.dob ? new Date(response.data.dob.split("-")[2],response.data.dob.split("-")[1] - 1,response.data.dob.split("-")[0]):sub(new Date(), { years: 18 })));
  //             formikRef.current.setFieldValue('address', response.data.address);
  //             formikRef.current.setFieldValue('pincode', response.data.pincode);
  //            }
  //         });
  //     }
  // }, [isLoading]);

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }

  function transactionHelper(
    buttonText,
    alertCallbackParam,
    additionalData,
    dialogRef
  ) {
    if (dialogRef == "dialog1" && buttonText == "YES" && isSaving === true) {
      apiCall(WebApi.dmtTransact, alertCallbackParam, (response) => {
        setIsError(!response.success);
        setIsSuccess(response.success);
        setIsSaving(false);

        if (response && response.data && response.data.message) {
          setInfoText(response.data.message);
        } else if (response && response.message) {
          if (response.message.includes("Insufficient fund in your account")) {
            setInfoText("Could not transfer due to technical issue");
          } else {
            setInfoText(response.message);
          }
        } else {
          setInfoText("DMT server down. Please try after some time!");
        }

        setAlertTitle("Error sending");
        if (response.success === true && response.data) {
          setIsError(!response.data.status);
          setIsSuccess(response.data.status);
          setInfoText(response.data.message);
          if (response.data.status === true) {
            // console.log("extra data");
            // console.log(response.extraData);

            props.history.push(
              "/moneytransfer/transaction/" + `${response.extraData[0]}`
            );
          }
        } else {
          if (response.extraData) {
            setAlertTitle(AppConstant.name);
            setHideSecondaryButton(true);
            setAlertCallbackParam(alertCallbackParam);
            setAlertAdditionalData({
              serialNo: response.extraData[0],
              processedAmount: response.extraData[1],
            });
            setPrimaryButtonText("OK");
            setIsAlertPrompt(true);
            setIsDmtConfirmationPrompt(false);
            setDialogRef("dialog2");
            setAlertMsg(
              "Sorry we have processed only Rs." +
              response.extraData[1] +
              ". Total deduced amount from you wallet is Rs." +
              response.extraData[1] + `. ( ref- ${response.data.message} )`
            );
          }
        }
      });
    }
    if (dialogRef == "dialog2" && buttonText == "YES") {
      // console.log(additionalData);

      if (additionalData.processedAmount > 0) {
        props.history.push("/moneytransfer/transaction/" + `${additionalData.serialNo}`);
      }
    } else if (buttonText == "NO") {
      // console.log(buttonText);
      setIsSaving(false);
    }
  }

  function backToBeneficaryList() {
    props.history.push("/moneytransfer/beneficiary-list", {
      mobile: props.location.state.mobile,
    });
  }

  return (
    <div className={classes.transactionFormRoot}>
      <div className="dmt-form-row">
        <div className="dmt-form-item-left">
          <div className="sender-details-wrapper">
            <div
              className="dmt-form-header-text"
              onClick={collapse}
            >
              Sender Details
            </div>
            <div className="sender-mobile-wrapper">
              <span className="sender-detail-item-label">Mobile</span>
              <span className="sender-detail-item">{props.location.state.mobile}</span>
            </div>
          </div>
        </div>

        <div className="dmt-form-item-right right-align">
          <FormControl>
            <OaFormImageButton
              color="primary"
              icon={<KeyboardReturnIcon style={{ fontSize: "24px" }} />}
              style={{ width: 200 }}
              onClick={() => {
                backToBeneficaryList();
              }}
              label={"Beneficiary List"}
            ></OaFormImageButton>
          </FormControl>
        </div>
      </div>
      <OaAlert
        hideSecondaryButton={hideSecondaryButton}
        dialogRef={dialogRef}
        isPrompt={isAlertPrompt}
        callback={transactionHelper}
        additionalData={alertAdditionalData}
        callbackParam={alertCallbackParam}
        msg={alertMsg}
        togglePrompt={() => {
          setIsAlertPrompt(!isAlertPrompt);
        }}
      ></OaAlert>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        alertTitle={alertTitle}
      />
      <OaDmtConfirmationAlert
        isPrompt={isDmtConfirmationPrompt}
        callback={transactionHelper}
        callbackParam={alertCallbackParam}
        msg={alertMsg}
        title={alertTitle}
        dialogRef={dialogRef}
        additionalData={alertAdditionalData}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        hideSecondaryButton={hideSecondaryButton}
        togglePrompt={() => {
          setIsDmtConfirmationPrompt(!isDmtConfirmationPrompt);
        }}
      ></OaDmtConfirmationAlert>
      <Formik
        innerRef={formikRef}
        initialValues={{
          mobile: props.location.state.mobile,
          name: props.location.state.data.name,
          bankname: props.location.state.data.bankname,
          accno: props.location.state.data.accno,
          ifsc: props.location.state.data.ifsc,
          // gstState:'23',
          // dob: dateFnsFormat(sub(new Date(), { years: 18 }), "dd-MM-yyyy"),
          // dobDisplay: sub(new Date(), { years: 18 }),
          // address: props.location.state.data.address,
          // pincode: props.location.state.data.pincode,
          bankOneLimit: `${props.location.state.rimitterData.bank1_limit}`,
          bankTwoLimit: `${props.location.state.rimitterData.bank2_limit}`,
          bankThreeLimit: props.location.state.data.paytm ? `${props.location.state.rimitterData.bank3_limit}` : 0

        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number()
            .min(100, "Minimum amount is Rs. 100")
            .max(
              limit,
              "Maximum amount is Rs. " + limit
            )
            .required("Please enter the amount to transfer")
            .integer("Invalid amount")
            .test(
              "multiple-of-hundred",
              "Sorry, we cannot send this amount. Please increase or decrease it to nearest 100",
              (value) => {
                return value % 5000 >= 100 || value % 5000 === 0;
              }
            ),
        })}
        onSubmit={(
          searchParams,
          { setSubmitting, resetForm, enableReinitialize }
        ) => {
          setIsError(false);
          setIsSuccess(false);
          setIsSaving(true);

          // searchParams.dob = dateFnsFormat(
          //   searchParams.dobDisplay,
          //   "yyyy-M-dd"
          // );
          if (isIMPS) {
            searchParams.txntype = "IMPS";
          } else if (isNEFT) {
            searchParams.txntype = "NEFT";
          }



          searchParams.bene_id = props.location.state.data.bene_id;
          setIsAlertPrompt(false);
          setIsDmtConfirmationPrompt(true);
          setAlertCallbackParam(searchParams);
          setHideSecondaryButton(false);
          setDialogRef("dialog1");
          setAlertAdditionalData({
            header: {
              field1Label: "Actual Amount",
              field2Label: "Additional Service Charge",
              field3Label: "Total",
            },
            transactions: [
              {
                actualAmount: searchParams.amount,
                serviceCharge: parseFloat(
                  searchParams.amount * 0.01 < 10
                    ? 10
                    : searchParams.amount * 0.01
                ),
                total:
                  parseFloat(searchParams.amount) +
                  parseFloat(
                    searchParams.amount * 0.01 < 10
                      ? 10
                      : searchParams.amount * 0.01
                  ),
              },
            ],
            total: {
              totalAmount: searchParams.amount,
              serviceCharge: parseFloat(
                searchParams.amount * 0.01 < 10
                  ? 10
                  : searchParams.amount * 0.01
              ),
              total:
                parseFloat(searchParams.amount) +
                parseFloat(
                  searchParams.amount * 0.01 < 10
                    ? 10
                    : searchParams.amount * 0.01
                ),
            },
          });
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            {/* <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: "#ebebeb" }}
                  className="input-landing-page"
                  name="mobile"
                  label="Sender's Mobile"
                  disabled="true"
                />
              </Grid>
            </Grid> */}

            <Grid container>
              <Grid item className="form-item">
                <div
                  className="dmt-form-header-text"
                  onClick={collapse}
                >
                  Beneficiary Details
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="name"
                  label="Beneficiary Name"
                  disabled="true"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="bankname"
                  label="Beneficiary Bank"
                  disabled="true"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="accno"
                  label="Account Number"
                  disabled="true"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="ifsc"
                  label="Beneficiary Bank IFSC"
                  disabled="true"
                />
              </Grid>
            </Grid>


            {/* {!isLoading &&
            
            <>
             <Grid container>

              

              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: "#ebebeb" }}
                  className="input-landing-page"
                  name="address"
                  label="Address *"
                  value={values.address}
                  onChange={(e) => {
                    setFieldValue("address", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: "#ebebeb" }}
                  className="input-landing-page"
                  name="pincode"
                  label="Pincode *"
                  value={values.pincode}
                  onChange={(e) => {
                    setFieldValue("pincode", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid> */}

            {/* <Grid container>

            <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormDateField
                  className="input-landing-page"
                  style={{ backgroundColor: "#ebebeb" }}
                  value={values.dobDisplay}
                  selected={values.dobDisplay}
                  onChange={(date) => setFieldValue("dobDisplay", date)}
                  label="Date of Birth*"
                  name="dobDisplay"
                  maxDate={sub(new Date(), { years: 18 })}
                />
                <ErrorMessage name="dob" component="div" className="error" />
              </Grid>

              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormSelectComboField
                  label="GST State *"
                  name="gstState"
                  value={values.gstState}
                  options={[{name: 'JAMMU AND KASHMIR', gstCode:	'01'},
                  {name: 'HIMACHAL PRADESH', gstCode:	'02'},
                  {name: 'PUNJAB', gstCode:	'03'},
                  {name: 'CHANDIGARH', gstCode:	'04'},
                  {name: 'UTTARAKHAND', gstCode:	'05'},
                  {name: 'HARYANA', gstCode:	'06'},
                  {name: 'DELHI', gstCode:	'07'},
                  {name: 'RAJASTHAN', gstCode:	'08'},
                  {name: 'UTTAR PRADESH', gstCode:	'09'},
                  {name: 'BIHAR', gstCode:	'10'},
                  {name: 'SIKKIM', gstCode:	'11'},
                  {name: 'ARUNACHAL PRADESH', gstCode:	'12'},
                  {name: 'NAGALAND', gstCode:	'13'},
                  {name: 'MANIPUR', gstCode:	'14'},
                  {name: 'MIZORAM', gstCode:	'15'},
                  {name: 'TRIPURA', gstCode:	'16'},
                  {name: 'MEGHLAYA', gstCode:	'17'},
                  {name: 'ASSAM', gstCode:	'18'},
                  {name: 'WEST BENGAL', gstCode:	'19'},
                  {name: 'JHARKHAND', gstCode:	'20'},
                  {name: 'ODISHA', gstCode:	'21'},
                  {name: 'CHATTISGARH', gstCode:	'22'},
                  {name: 'MADHYA PRADESH', gstCode:	'23'},
                  {name: 'GUJARAT', gstCode:	'24'},
                  {name: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)', gstCode:	'26'},
                  {name: 'MAHARASHTRA', gstCode:	'27'},
                  {name: 'ANDHRA PRADESH(BEFORE DIVISION)', gstCode:	'28'},
                  {name: 'KARNATAKA', gstCode:	'29'},
                  {name: 'GOA', gstCode:	'30'},
                  {name: 'LAKSHWADEEP', gstCode:	'31'},
                  {name: 'KERALA', gstCode:	'32'},
                  {name: 'TAMIL NADU', gstCode:	'33'},
                  {name: 'PUDUCHERRY', gstCode:	'34'},
                  {name: 'ANDAMAN AND NICOBAR ISLANDS', gstCode:	'35'},
                  {name: 'TELANGANA', gstCode:	'36'},
                  {name: 'ANDHRA PRADESH (NEWLY ADDED)', gstCode:	'37'},
                  {name: 'LADAKH (NEWLY ADDED)', gstCode:	'38'}]}
                 
                  optionValue={"gstCode"}
                  optionLabel={["name","gstCode"]}
                  required={true}
                  setFieldValue={setFieldValue}
                  // changeHandler={changeBank}
                />
                <ErrorMessage
                  name="bankname"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid> 

            </>
            }*/}


            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="amount"
                  label={
                    "Enter Amount (₹) min 100 and max " +
                    limit
                  }
                />
                <ErrorMessage name="amount" component="div" className="error" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="form-item">
                <Grid container>
                  <Grid item md={6}>
                    <OaFormRadio
                      label="IMPS"
                      name="isIMPS"
                      value="isIMPS"
                      checked={isIMPS}
                      onChange={() => {
                        setIsIMPS(!isIMPS);
                        setIsNEFT(!isNEFT);
                      }}
                    />
                  </Grid>
                  <Grid item md={6} classNme="form-item">
                    <OaFormRadio
                      label="NEFT"
                      name="isNEFT"
                      value="isNEFT"
                      checked={isNEFT}
                      onChange={() => {
                        setIsNEFT(!isNEFT);
                        setIsIMPS(!isIMPS);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid container style={{ marginTop: 20 }}>
              <Grid item className="form-item">
                <Button
                  className="button-landing-page"
                  color="primary"
                  type="submit"
                  disabled={isSaving}
                  style={{ width: 200 }}
                >
                  Send Money
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TransactionForm;
