import React from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  makeStyles,
} from "@material-ui/core";

const InsurancePaymentOptions = (props) => {
  const classes = useStyles();

  const onChangePaymentMode = (event, newValue) => {
    props.setpaymodetabindex(newValue);

    let pgMode = props?.paymentmodes[newValue];
    props.setselectedpaymentmode(pgMode);
    //console.log(pgMode.method  , props.trnType);

    if (pgMode?.method === "cash") {
      props.setselectedpaymentmethod("cashPayment");
    } else {
      for (let gate of pgMode?.gateway) {
        if (gate.selected) {
          props.setselectedpaymentmethod(gate.method);
        }
      }
    }
  };

  const setInitialPaymentMethod = () => {
    if (props.paymentmodes) {
      for (let [payModeIdx, payMode] of props.paymentmodes.entries()) {
        if (payMode.selected) {
          props.setselectedpaymentmode(payMode);

          props.setpaymodetabindex(payModeIdx);

          if (payMode.method == "cash") {
            props.setselectedpaymentmethod("cashPayment");
          } else {
            for (let gate of payMode?.gateway) {
              if (gate.selected) {
                props.setselectedpaymentmethod(gate.method);
              }
            }
          }
        }
      }
    }
  };

  const handlePGChange = (event) => {
    const method = event.target.value;
    props.setselectedpaymentmethod(method);
  };

  React.useEffect(() => {
    setInitialPaymentMethod();
  }, [props.paymentmodes]);

  return (
    <div className={classes.root}>
      <div className="tab-container">
        <Tabs
          value={props.paymodetabindex}
          onChange={onChangePaymentMode}
          aria-label="payment tabs"
          className="tab-div"
          orientation="vertical"
          variant="scrollable"
        >
          {props.paymentmodes &&
            props.paymentmodes.map((value, index) => (
              <Tab
                label={value.methodLabel}
                {...a11yProps(index)}
                key={index}
              />
            ))}
        </Tabs>
      </div>

      <div className="tab-panel-container">
        {props.paymentmodes &&
          props.paymentmodes.map((payMode, payModeIdx) => (
            <TabPanel
              value={props.paymodetabindex}
              index={payModeIdx}
              className="tab-content-div"
              key={payModeIdx}
            >
              {payMode.method === "cash" ? (
                <>
                  <Grid item md={12} key={payModeIdx}>
                    {parseFloat(props.data.cashBalance) >=
                    parseFloat(props.totalfare) ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="method"
                          name="method"
                          value={props.selectedpaymentmethod}
                          onChange={(event) =>
                            props.setselectedpaymentmethod(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value="cashPayment"
                            control={<Radio />}
                            label={`Use Wallet(Current Cash Balance is ${props.data.cashBalance})`}
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <div
                        onClick={() => {
                          if (!props.isloading) props.setisloading(true);
                        }}
                        style={{ display: "flex", cursor: "pointer" }}
                      >
                        <span className="payment-info-title">
                          {" "}
                          Insufficient Fund (Current Cash Balance is{" "}
                          {props.data.cashBalance ? props.data.cashBalance : 0})
                        </span>
                        <AutorenewIcon />
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                payMode.gateway &&
                payMode.gateway.length > 0 && (
                  <>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="method"
                        name="method"
                        value={props.selectedpaymentmethod}
                        onChange={handlePGChange}
                      >
                        {payMode.gateway.map((gateway, gateIdx) => (
                          <FormControlLabel
                            value={gateway.method}
                            control={<Radio />}
                            label={
                              gateway.methodOwnChargeHandle
                                ? `${gateway.method_label}`
                                : `${
                                    gateway.method_label
                                  } payment gateway charges ${
                                    gateway.charge_type === "A" ? " Rs." : ""
                                  }${gateway.charge}${
                                    gateway.charge_type === "A" ? " Only" : "%"
                                  }, excluding GST ${
                                    gateway.gstRate
                                  }% on PG charge`
                            }
                            key={gateIdx}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </>
                )
              )}
            </TabPanel>
          ))}
      </div>
    </div>
  );
};

export default InsurancePaymentOptions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    "& .MuiTab-wrapper ": {
      alignItems: "baseline",
      [theme.breakpoints.down(639)]: {
        alignItems: "center",
      },
    },
    "& .tab-container": {
      "& .MuiTabs-scrollable": {
        minHeight: 300,
        "& .MuiButtonBase-root.MuiTab-root": {
          margin: "0 0 10px",
          borderRadius: "8px 0 0 8px",
          "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.lightText,
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: theme.palette.background.rgba_primary_active,
        },
      },
    },
    "& .tab-panel-container": {
      "& .MuiBox-root": {
        padding: "0 36px",
      },
      "& .tab-content-div": {
        width: "100%",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .section": {
      marginTop: 20,
      marginBottom: 20,
      paddingLeft: "3vw",
      background: theme.palette.background.light,
      [theme.breakpoints.down(960)]: {
        paddingRight: 10,
        paddingLeft: 10,
      },
    },
    "& .payment-method": {
      cursor: "pointer",
      margin: "15px 0",
      padding: "5px 20px",
    },
    "& .pay-method-title": {
      fontSize: 20,
      fontWeight: 600,
    },
    "& .payment-method-info": {
      fontSize: 12,
      fontWeight: 500,
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
    },
    "& .action-button": {
      background: theme.palette.buttons.tertiary,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.primary.contrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: "22px !important",
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
  },
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 400,
  },
}));
