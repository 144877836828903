import React, { useState, useEffect } from "react";
import { Button, Card, Divider, Grid, Typography,useMediaQuery } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles, withStyles,useTheme } from "@material-ui/core/styles";
import image from "assets/img/dummy-logo-four.png";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { AppStateContext } from "layouts/AppStateProvider";
import OaFormSelectField from "oahoc/OaFormSelectField";


const MuiAccordion = withStyles({
  root: {
    width: "100%",
    border: "0px",
    boxShadow: "none",
    "& .MuiExpansionPanelDetails-root": {
      display: "block",
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: "white",
    padding: 0,
    margin: 0,
    minHeight: "auto",
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    background: "white",
  },
}))(AccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  root1: {
    width: "100%",
  },
  Grid: {
    background: "#fff",
    textAlign: "center",
    border: "1px solid #bfb6b6",
    borderRadius: "5px",
  },
  griditem1: {
    background: "#fff",
    margin: "20px",
    border: "1px solid #dcdcdc",
    borderRadius: "5px",
    boxShadow: "0px 0px 6px -1px #b3b3b3",
  },

  griditem2: {
    background: "#e2e2e2",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 10px",
  },
  typo2: {
    fontSize: "13px",
    color: "grey",
    fontWeight: 500,
  },
  select: {
    fontSize: "14px",
    lineHeight: "1.42857",
    color: "#999 !important",
    backgroundColor: "white",
    border: "1px solid #cccccc",
    display: "block",
    width: "100%",
    borderColor: "#d3dbe4",
    boxShadow: "none",
    borderRadius: "4px",
    height: "44px",
    padding: "10px",
  },
  input: {
    fontSize: "14px",
    lineHeight: "1.42857",

    backgroundColor: "white",
    border: "1px solid #cccccc",
    color: "#999 !important",
    fontSize: "15px !important",
    width: "100%",
    borderColor: "#d3dbe4",
    boxShadow: "none",
    borderRadius: "4px",
    height: "44px",
    padding: "10px",
    [theme.breakpoints.down("sm")]:{
      width:"100%"
    }
  },
  typo3: {
    fontSize: "12px",
    color: "red",
    textAlign: "right",
    display: "block",
    marginTop: "6px",
  },
  textarea: {
    color: "#999 !important",
    border: "1px solid #e2e2e2 !important",
    fontWeight: "300 !important",
    fontSize: "15px !important",
    width: "100%",
    borderRadius: "4px",
    height: "50px",
  },
  button: {
    background: "#2b5a85",
    border: "1px solid #2b5a85",
    padding: "7px 17px",
    marginRight: "10px",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 300,
    color: "#fff",
    "&:hover": {
      background: "#2b5a85",
    },
  },
  heading: {
    fontSize: "14px !important",
    color: "grey !important",
  },
  typo4: {
    fontSize: "13px !important",
    color: "grey !important",
  },
  error: {
    fontSize: 12,
    color: "red  !important",
  },
}));
const EditProfile = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { userProfile } = React.useContext(AppStateContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(userProfile)

  useEffect(() => {
    if (isLoading) {
      apiCall(WebApi.getStateCity, {}, (response) => {
        if (response.success === true) {
          setStates(response.data);
        } else {
          setIsError(true);
        }
      });
    }


  }, [isLoading]);
 
 

  const stateOnChange = function(value) {
    for (let i = 0; i < states.length; i++) {
      if (states[i].stateName == value) {
        setCities(states[i].cities);
      }
    }
  };


  //  console.log(cities)
  const initialValues = {
    firstName: userProfile.profileDetails.firstName,
    lastName: userProfile.profileDetails.lastName,
    gender:userProfile.profileDetails.gender,
    email:userProfile.profileDetails.email,
    phoneNo:userProfile.profileDetails.mobileNumber,
    currentpassword:"",
    newpassword:"",
    confirmpassword: "",
    address:userProfile.profileDetails.address,

    country:"india",
    state:'',
    city:'',
    pincode:userProfile.profileDetails.pincode,
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("required"),
    lastName: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    phoneNo: Yup.string().required("required"),
    currentpassword: Yup.string(),
    newpassword: Yup.string(),
    address: Yup.string().required("required"),
    country: Yup.string().required("required"),
    state: Yup.string().required("required"),
    city: Yup.string().required("required"),
    pincode: Yup.string().required("required"),
    confirmpassword: Yup.string()
      .when("newpassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newpassword")],
          "New & Confirm password need to be the same"
        ),
      })
      ,
  });

  const onSubmit = (values) => {
    // console.log(values);
    apiCall(WebApi.updateUserDetails, values, (response) => {
      // console.log(response);
      setIsError(!response.success);
      setIsSuccess(response.success);
      setIsSaving(false);
      setInfoText(response.message);

      
      if(response.success === true) {
        setTimeout(()=>{
          props.showProfile(false)
        },2000)
    }
    });
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer className={classes.Grid}>
        <Grid item md={12} xs={12} className={classes.griditem1}>
          <Grid item md={12} xs={12} className={classes.griditem2}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
            <Typography align="left" className={classes.typo2}>
              MY PROFILE
            </Typography>
          </Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, status, touched, setFieldValue }) => (
              <Form>
                <GridContainer
                  justifyContent="space-between"
                  style={{
                    padding: "20px",
                    textAlign: "left",
                    alignItems: "center",
                  }}
                >
                  <GridContainer spacing={isMobile?1:3}>
                    <Grid item md={5} xs={12}>
                      <Typography className={classes.heading}>
                        FIRST NAME
                      </Typography>
                      <Field
                        type="text"
                        name="firstName"
                        className={classes.input}
                      />
                      <ErrorMessage
                        name="firstName"
                        className={classes.error}
                        component="div"
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Typography className={classes.heading}>
                        LAST NAME
                      </Typography>
                      <Field
                        type="text"
                        name="lastName"
                        className={classes.input}
                      />
                      <ErrorMessage
                        name="lastName"
                        className={classes.error}
                        component="div"
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Typography className={classes.heading}>
                        GENDER
                      </Typography>
                      <Field
                        as="select"
                        name="gender"
                        className={classes.select}
                      >
                        <option disabled></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </Field>
                      <ErrorMessage
                        name="gender"
                        className={classes.error}
                        component="div"
                      />
                    </Grid>
                  </GridContainer>
                  <GridContainer style={{ paddingTop: 20 }} spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Typography className={classes.heading}>
                        EMAIL:
                      </Typography>
                      <Field
                        type="email"
                        name="email"
                        className={classes.input}
                      />
                      <ErrorMessage
                        name="email"
                        className={classes.error}
                        component="div"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <GridContainer>
                        <Grid item md={12}>
                          <Typography className={classes.heading}>
                            PHONE NO:
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Field
                            type="text"
                            name="phoneNo"
                            className={classes.input}
                          />
                          <ErrorMessage
                            name="phoneNo"
                            className={classes.error}
                            component="div"
                          />
                        </Grid>
                      </GridContainer>
                    </Grid>
                  </GridContainer>
                  <GridContainer>
                    <Grid item md={12}>
                      <GridContainer>
                        <MuiAccordion
                          square
                          expanded={expanded}
                          onChange={() => setExpanded(!expanded)}
                        >
                          <MuiAccordionSummary>
                            <Grid container justifyContent="flex-end">
                              <Typography className={classes.typo3}>
                                Change Password
                              </Typography>
                            </Grid>
                          </MuiAccordionSummary>
                          <MuiAccordionDetails>
                            <Grid container spacing={3}>
                              <Grid item md={4}>
                                <Typography className={classes.heading}>
                                  CURRENT PASSWORD
                                </Typography>
                                <Field
                                  type="password"
                                  name="currentpassword"
                                  className={classes.input}
                                />
                                <ErrorMessage
                                  name="currentpassword"
                                  className={classes.error}
                                  component="div"
                                />
                              </Grid>
                              <Grid item md={4}>
                                <Typography className={classes.heading}>
                                  NEW PASSWORD
                                </Typography>
                                <Field
                                  type="password"
                                  name="newpassword"
                                  className={classes.input}
                                />
                                <ErrorMessage
                                  name="newpassword"
                                  className={classes.error}
                                  component="div"
                                />
                              </Grid>
                              <Grid item md={4}>
                                <Typography className={classes.heading}>
                                  CONFIRM PASSWORD
                                </Typography>
                                <Field
                                  type="password"
                                  name="confirmpassword"
                                  className={classes.input}
                                />
                                <ErrorMessage
                                  name="confirmpassword"
                                  className={classes.error}
                                  component="div"
                                />
                              </Grid>
                            </Grid>
                          </MuiAccordionDetails>
                        </MuiAccordion>
                      </GridContainer>
                    </Grid>
                  </GridContainer>
                  <GridContainer style={{ marginTop: 10 }}>
                    <Grid item md={12} xs={12}>
                      <Typography className={classes.heading}>
                        Address:*
                      </Typography>
                      <GridContainer style={{ marginTop: 20 }}>
                        <Grid item md={12} xs={12}> 
                          <Field
                            type="text"
                            as="textarea"
                            // row={6}
                            name="address"
                            className={classes.textarea}
                          />
                        </Grid>
                        <ErrorMessage
                          name="address"
                          className={classes.error}
                          component="div"
                        />
                      </GridContainer>
                      <GridContainer spacing={isMobile?1:3}>
                        <Grid item md={6} xs={12}>
                          <OaFormSelectField
                            label="State"
                            name="state"
                            value={values.state}
                            options={states}
                            optionValue={"stateName"}
                            optionLabel={"stateName"}
                            onChange={(value) => {
                              // console.log(value);
                              setFieldValue("state", value);
                              stateOnChange(value);
                            }}
                            // disabled={isDisabled}
                          />
                          <ErrorMessage
                            name="state"
                            className={classes.error}
                            component="div"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <OaFormSelectField
                            label="City"
                            name="city"
                            // value={values.city}
                            defaultValue={{ cityName: userProfile.profileDetails.cityName }}
                            options={cities}
                            optionValue={"cityName"}
                            optionLabel={"cityName"}
                            // disabled={isDisabled}
                          />
                          <ErrorMessage
                            name="city"
                            className={classes.error}
                            component="div"
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer spacing={isMobile?1:3}>
                        <Grid item md={6} xs={12}>
                          <Field
                            type="text"
                            name="pincode"
                            placeholder="Enter the Pincode"
                            className={classes.input}
                          />
                          <ErrorMessage
                            name="pincode"
                            className={classes.error}
                            component="div"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            as="select"
                            name="country"
                            className={classes.select}
                          >
                            <option disabled></option>
                            <option value="india">India</option>
                          </Field>
                          <ErrorMessage
                            name="country"
                            className={classes.error}
                            component="div"
                          />
                        </Grid>
                      </GridContainer>
                    </Grid>
                  </GridContainer>

                  {/* <GridContainer>
                  <Grid item md={12}>
                    <Typography className={classes.heading}>My Subscription:</Typography>
                  </Grid>
                  <Grid item md={12}>
                    <GridContainer spacing={2}>
                      <Grid item style={{marginTop:3}}>
                      <Field type="checkbox" name="checked" value="One" />
                      </Grid>
                      <Grid item>
                      <Typography className={classes.typo4}>SMS Alerts</Typography>
                      </Grid>
                    </GridContainer>
                    <GridContainer spacing={2}>
                      <Grid item style={{marginTop:3}}>
                      <Field type="checkbox" name="checked" value="Four" />
                      </Grid>
                      <Grid item>
                      <Typography className={classes.typo4}>Low Fare Alerts</Typography>
                      </Grid>
                    </GridContainer>
                    <GridContainer spacing={2}>
                      <Grid item style={{marginTop:3}}>
                      <Field type="checkbox" name="checked" value="Two" />
                      </Grid>
                      <Grid item>
                      <Typography className={classes.typo4}>I would also like to receive my booking details on WhatsApp.</Typography>
                      </Grid>
                    </GridContainer>
                    <GridContainer spacing={2}>
                      <Grid item style={{marginTop:3}}>
                      <Field type="checkbox" name="checked" value="Three" />
                      </Grid>
                      <Grid item>
                      <Typography className={classes.typo4}>Subscribe to receive special promotions and offers by Yatra.</Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                </GridContainer> */}
                  <GridContainer
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: 20 }}
                  >
                    <Button className={classes.button} type="submit">
                      submit
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => props.showProfile(false)}
                    >
                      cancel
                    </Button>
                  </GridContainer>
                  {/* <GridContainer>
                    <Typography>Deactivate your account</Typography>
                  </GridContainer> */}
                </GridContainer>

                {/* {errors &&
                  _.isString(errors) &&
                  touched &&
                  _.isArray(touched) && (
                    <div className="field-error">{errors}</div>
                  )}
                <div className={"row"}>
                  <div className={"col-12"}>
                    <code>
                      <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                    </code>
                  </div>
                  <div className={"col-12"}>
                    <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                  </div>
                  <div className={"col-12"}>
                    <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                  </div>
                </div> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </GridContainer>
    </div>
  );
};
export default EditProfile;
