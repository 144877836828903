import React, { useState } from "react";
import whatsappLogo from "assets/icons/whatsapp.png"
import { makeStyles } from "@material-ui/core/styles"
import { AppConstant } from "appConstant";

export default function WaFloatingButton(props) {
    const [chatMessage, setChatMessage] = useState("")
    const [chatText, setChatText] = useState("")
    const phoneNumber = AppConstant.countryDialCode+AppConstant.wacontact;
    const [openChat, setOpenChat] = useState("none")

    const classes = useStyles()

    const toggleWhatsappChat = () => {
        if (openChat === "none") {
            setOpenChat("block")
        } else {
            setOpenChat("none")
        }
    }

    const closeWhatsappChat = () => {
        setOpenChat("none");
    }

    const getChatMessage = (event) => {
        const textString = event.target.value
        const encoded = encodeURI(textString)
        setChatMessage(encoded)
        setChatText(textString)
    }
    
    return (
        <div className={classes.whatsappChatContainer}>
      
            <div className={classes.floatingWhatsappButtonWrap}>
                <img 
                    className={classes.whatsappButton} 
                    onClick={toggleWhatsappChat}
                    src={whatsappLogo}
                />
            </div>
            
            <div className={classes.chatBox} style={{display: openChat}}>
                <div className={classes.innerChatBox}>
                    <span className="close-icon" onClick={closeWhatsappChat} title="close">x</span>
                    <div className={classes.chatForm}>
                        <div className={classes.chatGreetingWrapper}>
                            <img src={props.companylogo}/>
                        </div>
                        <div className={classes.chatInputWrapper}>
                            <textarea 
                                type="text" 
                                value={chatText} 
                                onChange={(event) => getChatMessage(event)}
                                placeholder="Enter your query"
                            />
                        </div>
                        <div className={classes.btnGroup}>
                            <div className={classes.chatSendBtnWrapper}>
                                <a className={classes.whatsappSendBtn} href={"https://web.whatsapp.com/send?phone="+phoneNumber+"&text="+chatMessage} target="_blank" title="whatsapp web">
                                <i className="fa fa-globe"></i>
                                </a>
                            </div>
                            
                            <div className={classes.chatSendBtnWrapper}>
                                <a className={classes.whatsappSendBtn} href={"https://api.whatsapp.com/send?phone="+phoneNumber+"&text="+chatMessage} target="_blank" title="whatsapp application">
                                <i className="fab fa-whatsapp"></i>
                                </a>
                            </div>

                            <div className={classes.chatSendBtnWrapper}>
                                <a className={classes.whatsappSendBtn} title="clear" onClick={() => setChatText("")}>
                                    {/* <i className="fas fa-times"></i> */}
                                    Clear
                                </a>
                            </div>
                        </div>
                        
                    </div>
                
                </div>
            </div>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    whatsappChatContainer: {
        position: "fixed",
        left: 60,
        bottom: 0,
        zIndex: 9992,
    },
    floatingWhatsappButtonWrap: {
        position: "absolute",
        right: 0,
        bottom: 15,
    },
    whatsappButton: {
        cursor: "pointer",
        height: 50,
        width: 50,
        borderRadius: "50%",
        boxShadow: "0px 0px 30px -10px rgb(0 0 0 / 75%)",
        backgroundColor: theme.palette.background.rgba_primary,
        backdropFilter: "blur(4px)",
    },
    chatBox: {
        position: "absolute",
        bottom: 70,
        left: -55,
        [theme.breakpoints.down(600)]: {
            width: "92vw",
        },
    },
    innerChatBox: {
        background: theme.palette.background.wappbg,
        borderRadius: 5,
        backdropFilter: "blur(4px)",
        position: "relative",
        "& .close-icon": {
            cursor: "pointer",
            position: "absolute",
            top: -12,
            right: -12,
            width: 20,
            height: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.background.wappbg,
            color: theme.palette.background.wappbgContrastColor,
            borderRadius: "50%"
        }
    },
    chatForm: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // flexWrap: "nowrap",
        flexDirection: "column",
        boxShadow: "0px 40px 100px -60px rgb(0 0 0 / 75%)",
    },
    chatGreetingWrapper: {
        padding: 10,
        textAlign: "center",
        borderRadius: "5px 5px 0 0",
        background: "rgba(255, 255, 255, 0.7)",
        width: "100%",
        "& img": {
            width: 160,
        },
    },
    chatInputWrapper: {
        padding: 5,
        background: "rgba(0, 0, 0, 0.5)",
        "& textarea": {
            padding: "3px 5px",
            border: "none",
            background: "rgba(0, 0, 0, 0.1)",
            caretColor: theme.palette.primary.lightText,
            height: "30vh",
            minHeight: 60,
            width: 250,
            overflow: "auto",
            color: theme.palette.primary.lightText,
            resize: "none",
            fontWeight: 500,
            fontFamily: theme.palette.font.primary,
            [theme.breakpoints.down(600)]: {
                width: "90vw",
                maxHeight: "70vh",
            },
            "&::placeholder": {
                color: theme.palette.primary.lightText,
            },
        }
    },
    btnGroup: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-evenly"
    },
    chatSendBtnWrapper: {
        padding: "6px 10px 3px",
        borderRadius: 8,
        margin: 5,
        backgroundColor: theme.palette.primary.lightText,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.background.wappbg,
            "& a": {color: theme.palette.primary.lightText},
        },
    },
    whatsappSendBtn: {
        color: theme.palette.background.wappbg,
        fontSize: 20,
    },
}));
