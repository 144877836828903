import React, {useState, useEffect} from 'react'
import OaCard from 'oahoc/OaCard';
import dateFnsFormat from 'date-fns/format';
import B2bBalanceManagerUpdateSearchForm from './B2bBalanceManagerUpdateSearchForm';
import B2bBalanceManagerUpdateSearchResult from './B2bBalanceManagerUpdateSearchResult';
import AgentDetails from 'pages/agent/AgentDetails';

const B2bBalanceManagerUpdate = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    let d = new Date;
    //let fromDate = d.setDate(d.getDate() - 30);
    let fromDate = d;
    let today = new Date();
    
    const [tableKey, setTableKey] = useState(0);
    const [searchParams, setSearchParams] = useState({
        agencyId: props.agencyId,
        dateFromDisplay: fromDate,
        dateToDisplay: today,
        dateFrom: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
        dateTo: dateFnsFormat(today, 'dd-MM-yyyy'),
        status: 'P'
    });

    function B2bBalanceManagerUpdateSearchResultLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    useEffect(() => {
        setSearchParams({...searchParams, agencyId: props.agencyId});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (
        <>
            {!isLoading && <>
                {props.agencyId && 
                    (
                    <OaCard>
                        <AgentDetails agencyId={props.agencyId} />
                    </OaCard>
                    )
                }
                <OaCard> 
                    <B2bBalanceManagerUpdateSearchForm 
                        target={B2bBalanceManagerUpdateSearchResultLoader} 
                        agencyId={props.agencyId} 
                        searchParams={searchParams}
                        setIsLoading={setIsLoading}
                    />
                </OaCard>
                <OaCard>
                    <B2bBalanceManagerUpdateSearchResult 
                        key={tableKey} 
                        searchParams={searchParams}
                    />
                </OaCard>
            </>}
        </>
    )
}

export default B2bBalanceManagerUpdate;
