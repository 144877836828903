import React from "react"
import OaLoading from "pages/components/OaLoading"
import { makeStyles } from '@material-ui/core';

const oaStyles = makeStyles(theme=> ({
root:{
   display:"flex",
   alignItems:"center",
   justifyContent:"center",
   width:"100vw",
   height:"80vh",

}





}))

const submitprogress =()=>{


    
    const classes = oaStyles();

    return (
        <div className={classes.root}>
            <OaLoading className="loader" />
            <span className="loading-text">Please Wait....</span> 
        </div>
    )


}

export default submitprogress