import React from 'react'
import { apiCallByApiName } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { Button } from '@material-ui/core';
import download  from 'downloadjs';

const OaButtonPDF = (props) => {

    function downloadPDF(){
        apiCallByApiName(WebApi.doDownload, props.api, props.searchParams, (response) => {
            const fileName = response.headers["content-disposition"].split('=')[1];
            if(fileName!=null){
                download(response.data, fileName, "application/pdf")
            }
        })
    }

    return (
        <>
            <Button variant="contained" size="small"  onClick={()=>{downloadPDF()}}>PDF</Button>
        </>
    )
}

export default OaButtonPDF
