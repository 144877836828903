import React from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FormLabel from "@material-ui/core/FormLabel";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Field } from "formik";

const BusPaymentOptions = (props) => {
  const classes = useStyles();
  const [walletCriteria, setWalletCriteria] = React.useState(false);
  const [odExpired, setOdExpired] = React.useState(false);

  const [balanceStr, setBalanceStr] = React.useState(false);

  const onChangePaymentMode = (event, newValue) => {
    props.setpaymodetabindex(newValue);

    let pgMode = props?.paymentmodes[newValue];
    props.setselectedpaymentmode(pgMode);

    if (pgMode?.method === "cash") {
      props.setselectedpaymentmethod("cash");
    } else {
      for (let gate of pgMode?.gateway) {
        if (gate.selected) {
          props.setselectedpaymentmethod(gate.method);
        }
      }
    }
  };

  const handlePGChange = (event) => {
    const method = event.target.value;
    props.setselectedpaymentmethod(method);
  };

  const setInitialPaymentMethod = () => {
    if (props.paymentmodes) {
      for (let [payModeIdx, payMode] of props.paymentmodes.entries()) {
        if (payMode.selected) {
          props.setselectedpaymentmode(payMode);
          props.setpaymodetabindex(payModeIdx);

          if (payMode.method == "cash") {
            props.setselectedpaymentmethod("cashPayment");
          } else {
            for (let gate of payMode?.gateway) {
              if (gate.selected) {
                props.setselectedpaymentmethod(gate.method);
              }
            }
          }
        }
      }
    }
  };

  React.useEffect(() => {
    setInitialPaymentMethod();
  }, [props.paymentmodes]);

  React.useEffect(() => {
    if (props.data) {
      if (
        props.data?.isBaseCreditSet &&
        new Date() < new Date(props.data?.creditValidity)
      ) {
        if (props.data.availableCreditAmount >= props.ticketfare) {
          setWalletCriteria(true);
          setBalanceStr(
            "Current Balance is : " +
              props.data.balance +
              ", Available OD : " +
              props.data.availableCreditAmount
          );
        } else {
          setWalletCriteria(props.data.balance >= props.ticketfare);
          setBalanceStr("Current Balance is : " + props.data.balance);
        }
      } else {
        setWalletCriteria(props.data.balance >= props.ticketfare);
        setBalanceStr("Current Balance is : " + props.data.balance);
      }
      setOdExpired(new Date() > new Date(props.data?.creditValidity));
    }
  }, [props.data]);

  return (
    <Box sx={{ width: "100%" }} className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={props.paymodetabindex}
          onChange={onChangePaymentMode}
          aria-label="basic tabs example"
          className="tab-div"
          orientation="vertical"
          variant="scrollable"
        >
          {props.paymentmodes &&
            props.paymentmodes.map((value, index) => (
              <Tab
                label={value.methodLabel}
                key={index}
                style={{}}
                {...a11yProps(index)}
              />
            ))}
        </Tabs>
      </Box>

      <Box>
        {props.paymentmodes &&
          props.paymentmodes.map((payMode, payModeidx) => (
            <TabPanel
              value={props.paymodetabindex}
              index={payModeidx}
              key={payModeidx}
              //   style={{ paddingTop: 25 }}
              className="tab-content-div"
            >
              {payMode.method === "cash" ? (
                <Grid item md={12}>
                  {!props.isloading && walletCriteria ? (
                    <>
                      <Field
                        type="radio"
                        name="cashPayment"
                        id="cashPayment"
                        className={
                          "form-check-input " +
                          (props.errors.cashPayment && props.touched.cashPayment
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label
                        htmlFor="cashPayment"
                        className="form-check-label payment-info-title"
                      >
                        {" "}
                        Use Wallet({balanceStr})
                      </label>
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        if (!props.isloading) props.setisloading(true);
                      }}
                      style={{ display: "flex", cursor: "pointer" }}
                    >
                      <span className="payment-info-title">
                        {" "}
                        Insufficient Fund (Current Cash Balance is{" "}
                        {props.data.balance ? props.data.balance : 0})
                      </span>
                      <AutorenewIcon />
                    </div>
                  )}
                </Grid>
              ) : (
                payMode.gateway &&
                payMode.gateway.length > 0 && (
                  <div className="tab-item-div">
                    {payMode.gateway && (
                      <FormControl component="fieldset">
                        {/* <FormLabel
                          component="legend"
                          style={{ marginBottom: 5 }}
                        >
                          Payment Options
                        </FormLabel> */}
                        <RadioGroup
                          aria-label="method"
                          name="method"
                          value={props.selectedpaymentmethod}
                          onChange={handlePGChange}
                        >
                          {payMode.gateway.map((gateway, gateIdx) => (
                            <FormControlLabel
                              value={gateway.method}
                              control={<Radio />}
                              label={
                                gateway.methodOwnChargeHandle
                                  ? `${gateway.method_label}`
                                  : `${
                                      gateway.method_label
                                    } payment gateway charges ${
                                      gateway.charge_type === "A" ? " Rs." : ""
                                    }${gateway.charge}${
                                      gateway.charge_type === "A"
                                        ? " Only"
                                        : "%"
                                    }, excluding GST ${
                                      gateway.gstRate
                                    }% on PG charge`
                              }
                              key={gateIdx}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </div>
                )
              )}
            </TabPanel>
          ))}
      </Box>
    </Box>
  );
};

export default BusPaymentOptions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 10,
    padding: 15,
    "& .tab-div": {
      overflowX: "scroll",
      scrollbarWidth: "none",
      width: "100%",
      marginBottom: "5px",

      "& .MuiTabs-scroller": {
        height: "100%",
      },
      "& .MuiTab-textColorInherit": {
        background: `${theme.palette.primary.disabled} !important`,
        color: `${theme.palette.primary.darkText} !important`,
        marginBottom: "5px",
        borderRadius: "8px 0 0 8px",

        // "&:first-child": {
        //   borderRadius: "8px 0 0 8px"
        // },
        // "&:last-child": {
        //   borderRadius: "8px 0 0 8px"
        // },

        "&.Mui-selected": {
          background: `${theme.palette.buttons.secondary} !important`,
          color: `${theme.palette.buttons.secondaryContrastText} !important`,
          marginBottom: "5px",
        },
      },
      "& .MuiTabs-scroller": {
        overflowX: "scroll !important",
        scrollbarWidth: "none",
        height: 220,
        // display: "none",
      },
      "& .MuiTab-wrapper": {
        padding: "3px 0 0 0",
      },
      "& .MuiTab-root": {
        boxShadow: "0px 0px 2px 0px rgba(140,140,140,1)",
      },
    },
    "& .tab-content-div": {
      "& .MuiBox-root": {
        paddingLeft: "10px",
        paddingTop: 10,
        [theme.breakpoints.down(768)]: {
          padding: 0,
          paddingLeft: "10px",
        },
      },
      "& .tab-item-div": {
        padding: 0,
        "& .MuiFormControl-root": {
          marginTop: -5,
        },
      },
    },
  },
  tabDiv: {
    "& .MuiTab-textColorInherit": {
      background: `${theme.palette.primary.disabled} !important`,
      color: `${theme.palette.primary.darkText} !important`,
      borderRadius: "8px 8px 0 0",
      "&.Mui-selected": {
        background: `${theme.palette.buttons.tertiary} !important`,
        color: `${theme.palette.buttons.tertiaryContrastText} !important`,
      },
    },
  },
  "& .MuiTab-root": {
    minHeight: "0px !important",
  },
  tabContDiv: {},
}));
