import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button"
import { Typography } from "@material-ui/core";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import OaFormSelectField from "oahoc/OaFormSelectField";
import { AppStateContext } from "layouts/AppStateProvider";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Grid: {
    padding: 20,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    paddingBottom: 20,
  },
  input: {
    fontSize: "14px",
    lineHeight: "1.42857",

    backgroundColor: "white",
    border: `1px solid ${theme.palette.secondary.chineseGray}`,
    color: "#999 !important",
    fontSize: "15px !important",
    width: "100%",
    borderColor:theme.palette.secondary. periglacialBlue,
    boxShadow: "none",
    borderRadius: "4px",
    height: "44px",
    padding: "10px",
  },

  button: {
    background: theme.palette.background.navyBlue,
    marginLeft: 13,
    border: `1px solid ${theme.palette.background.navyBlue}`,
    padding: "7px 17px",
    marginRight: "10px",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 300,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.background.navyBlue,
    },
  },
  select: {
    fontSize: "14px",
    lineHeight: "1.42857",
    color: "#999 !important",
    backgroundColor: "white",
    border: `1px solid ${theme.palette.secondary.chineseGray}`,
    display: "block",
    width: "100%",
    borderColor:theme.palette.secondary. periglacialBlue,
    boxShadow: "none",
    borderRadius: "4px",
    height: "44px",
    padding: "10px",
  },
  heading: {
    fontWeight: "500 !important",
    lineHeight: "1.1 !important",
    color: theme.palette.secondary.darkGrayishBlue,
  },
  typo3:{
    fontSize: '1rem', fontWeight: 700, lineHeight: 1, color: 'gray', textShadow: `0 1px 0 ${theme.palette.priamry.contrastText}`,cursor:"pointer"
  }
}));

const GstModal = (props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { userProfile } = React.useContext(AppStateContext);
 const theme=useTheme();

    useEffect(() => {
      if (isLoading) {
        apiCall(WebApi.getStateCity, {}, (response) => {
          if (response.success === true) {
            setStates(response.data);
          } else {
            setIsError(true);
          }
        });
      }
    }, [isLoading]);
    // console.log("###########", states);
  
    const stateOnChange = function(value) {
      for (let i = 0; i < states.length; i++) {
        if (states[i].stateName == value) {
          setCities(states[i].cities);
        }
      }
    };

    

  const initialValues = {
    gstNumber: userProfile.gstDetails.gstNo,
    companyName: userProfile.gstDetails.companyName,
    country: '',
    phoneNo: userProfile.gstDetails.mobileNo===null?'':userProfile.gstDetails.mobileNo,
    email: userProfile.gstDetails.email,
    address: userProfile.gstDetails.address,
    city: "",
    cityCode: userProfile.gstDetails.cityCode,
    state:""
    
  };
  const validationSchema = Yup.object().shape({
    gstNumber: Yup.string().required('requierd'),
    companyName:Yup.string().required('requierd'),
    country: Yup.string().required(' required'),
    phoneNo: Yup.number().typeError('enter no').required('requierd'),
    email:Yup.string().email().required('requierd') ,
    address: Yup.string().required('requierd'),
    city: Yup.string().required('requierd'),
    cityCode: Yup.string().required('requierd'),
    state:Yup.string().required('requierd')
  });

  const onSubmit = (values) => {

    apiCall(WebApi.updateb2cGstDetails,values,(response)=>{
      // console.log(response)
      setIsError(!response.success);
      setIsSuccess(response.success);
      setIsSaving(false);
      setInfoText(response.message);


      if(response.success === true) {
        setTimeout(()=>{
          props.setOpen(false)
        },2000)
    }
     
    
    })
  };

  return (
    <div>
      <GridContainer justifyContent="center" alignItems="center" >
        <Grid item md={6} className={classes.paper}>
        <OaFormAlerts 
                            isSaving={isSaving} 
                            isSuccess={isSuccess} 
                            isError={isError} 
                            infoText={infoText} 
                            setIsError={setIsError} 
                            setIsSuccess={setIsSuccess} 
                        />
          <GridContainer
            justifyContent="space-between"
            style={{ padding: 20, borderBottom: `1px solid ${theme.palette.secondary. pattensBlue}`}}
          >
            
            <Grid item>
                <Typography className={classes.heading}>GST Details Update</Typography>
             </Grid>
            <Grid item>
            <Typography className={classes.typo3} onClick={()=>props.setOpen(false)}>X</Typography>
            </Grid>
          </GridContainer>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, status, touched,setFieldValue }) => (
              <Form>
                <GridContainer spacing={3} className={classes.Grid}>
                  <Grid item md={6}>
                     <Typography>Gst Number</Typography>
                    <Field type="text" name="gstNumber" className={classes.input} />
                    <ErrorMessage name="gstNumber" component="div" className="error"/>
                  </Grid>
                  <Grid item md={6}>
                  <Typography>Company Name</Typography>
                    <Field type="text" name="companyName" className={classes.input} />
                    <ErrorMessage name="companyName" component="div" className="error"/>
                  </Grid>
                  <GridContainer spacing={3} style={{padding:12}}>
                  <Grid item md={6}>
                   <GridContainer spacing={3}>
                       <Grid item md={6}>
                       <Typography>Country</Typography>
                       <Field as="select" name="country" className={classes.select}>
                       <option disabled></option>
                      <option value="India">India</option>
                     
                 
                    </Field>
                    <ErrorMessage name="country" component="div" className="error" />
                       </Grid>
                       <Grid item md={6}>
                       <Typography>Ph. Number</Typography>
                       <Field type="text" name="phoneNo" className={classes.input} />
                    <ErrorMessage name="phoneNo" component="div" className="error"/>
                       </Grid>
                   </GridContainer>
                  </Grid>
                  <Grid item md={6}>
                  <Typography>Email</Typography>
                    <Field type="text" name="email" className={classes.input} />
                    <ErrorMessage name="email" component="div" className="error"/>
                  </Grid>
                  </GridContainer>
                  <GridContainer style={{padding:11}}>
                      <Grid item md={12}>
                      <Typography>Address</Typography>
                      <Field type="text" name="address" className={classes.input} />
                    <ErrorMessage name="address" component="div" className="error"/>  
                      </Grid>
                  </GridContainer>
                </GridContainer>
                <GridContainer spacing={3}style={{paddingLeft:18,paddingRight:18}} >
                <Grid item md={4}>
                  <Typography>State</Typography>
                  <OaFormSelectField
                            label="State"
                            name="state"
                            value={values.state}
                            options={states}
                            optionValue={"stateName"}
                            optionLabel={"stateName"}
                            onChange={(value) => {
                              // console.log(value);
                              setFieldValue("state", value);
                              stateOnChange(value);
                            }}
                            // disabled={isDisabled}
                          />
                    <ErrorMessage name="state" component="div" className="error"/>
                  </Grid>
                  <Grid item md={4}>
                  <Typography>City</Typography>
                  <OaFormSelectField
                            label="City"
                            name="city"
                            value={values.city}
                            options={cities}
                            optionValue={"cityName"}
                            optionLabel={"cityName"}
                            // disabled={isDisabled}
                          />
                    <ErrorMessage name="city" component="div" className="error"/>
                  </Grid>
                  <Grid item md={4} style={{marginTop:9}}>
                  <Typography>City Code</Typography>
                    <Field type="text" name="cityCode" className={classes.input} />
                    <ErrorMessage name="cityCode" component="div" className="error"/>
                  </Grid>
                  <Button className={classes.button} type="submit">submit</Button>
                  </GridContainer>
                  {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div className={"row"}>
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </GridContainer>
    </div>
  );
};
export default GstModal;
