import React, { useState, useEffect } from "react";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { Grid, useTheme } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlightDetailCard from "./FlightDetailCard";
import PassengerDetailCard from "./PassengerDetailCard";
import FareBreakupCard from "./FareBreakupCard";
import GstDetails from "./GstDetails";
import { withRouter } from "react-router-dom"
import FlightSearchHeader from "./FlightSearchHeader";
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../../assets/css/CssConstants";
import { Field, ErrorMessage, Form } from "formik";
import { AppConstant } from "appConstant";
import OaModel from "oahoc/OaModel";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
	RadioGroup,
	FormControlLabel,
	Radio,
	Divider,
	Box,
	Tabs,
	Tab,
	FormControl,
	FormLabel,
	Checkbox
} from "@material-ui/core";
import { localforageGetItem } from "oautils/oaForageUtils";
import PaymentOptions from "./PaymentOptions";
import { localforageSetItem } from "oautils/oaForageUtils";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { submitDataToCcAvenue } from "pages/module/paymentgateway/CcAvenue";
import { submitDataToPaygoal } from "pages/module/paymentgateway/Paygoal";
import { submitDataToRazorpay } from "pages/module/paymentgateway/Razorpay";
import { POST_PAYGOAL_API_CALLBACK } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiButton-root": {

			[theme.breakpoints.down("xs")]: {
				fontSize: "9px",
				padding: 6,
			},
		},
		"& .MuiAccordionDetails-root": {
			flexDirection: "column",
		},
		"& .accordianButton": {
			display: "flex",
			justifyContent: "flex-end",
		},
		"& .MuiAccordion-root": {
			margin: "10px 0 10px",
		},
		"& .payment-options.tabbed-div-container": {
			"& .MuiTabs-root": {
				height: 270
			},
			"& .tab-content-div": {
				"& .MuiBox-root": {
					padding: "0 24px"
				}
			},
			"& .Mui-selected": {
				background: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText
			}
		}
	},
	heading: {
		fontSize: 20,
		fontWeight: 500,
	},
	btn: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: 10,
		"&:hover": {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},
	button: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: 10,
		"&:hover": {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},

	actionButton: {
		background: theme.palette.primary.main,
		padding: "5px",
		textAlign: "center",
		color: theme.palette.primary.contrastText,
		width: 100,
		height: 35,
		borderRadius: 5,
		fontSize: 14,
		fontWeight: 500,
		appearance: "none",
		MozAppearance: "none",
		WebkitAppearance: "none",
		border: "none !important",
		outline: "none !important",
		cursor: "pointer",
		margin: "0 0.5rem",
		"&:hover": {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},


}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const FlightDetails = (props) => {
	const [flightDetails, setFlightDetails] = useState(null);
	const [open, setOpen] = useState(false);
	const [expanded, setExpanded] = useState('pax-details');
	
	const [paymentTab, setPaymentTab] = React.useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [pgMethod, setPGMethod] = React.useState(null);
	
	const [data, setData] = useState({});
	const [totalFare, setTotalFare] = useState(0);
	const [paymentOptions, setPaymentOptions] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState(null);
	
	const [showTNC, setShowTNC] = useState(false);
	const [showFareRates, setShowFareRates] = useState(false);
	const [acceptTnc, setAcceptTnc] = useState(false);
	const [userType, setUserType] = useState(null);
	const [cashBalance, setCashBalance] = useState(0);
	const [triggerSubmit, setTriggerSubmit] = useState(false);

	const [isError, setIsError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [infoText, setInfoText] = useState("");
	const [grandTotal, setGrandTotal] = useState(0.00);

	const classes = useStyles();
	const theme = useTheme();

	async function showRazorPayWindow(trnResponse) {

        let filteredValue = paymentMethod &&
        paymentMethod.gateway &&
        paymentMethod.gateway.filter((value) => {
            return value.method === pgMethod;
        });
    
        let paymentOptions = filteredValue[0];

        let request = {
            paymentOptions: paymentOptions,
            trnResponse: trnResponse,
            selectedPaymentMethod: pgMethod,
            theme: theme
        };
        var response = await submitDataToRazorpay(request);
        setIsSaving(false);
        setIsError(response.isError);   
        setIsSuccess(response.isSucess);
        setInfoText(response.responseMessage);

        if(response.isSucess){
            setTriggerSubmit(true);
        }
    }

    async function showPaygoalWindow(trnResponse) {
        const request = {
            amount: grandTotal,
            encTxnId: trnResponse.uniqueTransactionId,
            EncTxnId: trnResponse.uniqueTransactionId,
            redirectUrl: "",
			siteUrl: POST_PAYGOAL_API_CALLBACK,
            txnId: trnResponse.transactionId,
            paymentMode: "PayGoal",
        };
        submitDataToPaygoal(request);
    }

    async function showCcAvenueWindow(trnResponse) {
        const request = {
            amount: grandTotal,
            encTxnId: trnResponse.uniqueTransactionId,
            EncTxnId: trnResponse.uniqueTransactionId,
            redirectUrl: POST_PAYGOAL_API_CALLBACK+"?paymentMode=ccavenue",
            siteUrl: "",
            txnId: trnResponse.transactionId,
            paymentMode: "CCAvenue",
        };
        submitDataToCcAvenue(request);
    }

	const toggleExpanded = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const switchPaymentTabs = (event, index) => {
		setPaymentTab(index);
        setPaymentMethod(paymentOptions[index]);
        
        if(paymentOptions[index].method == "cash") {
            setPGMethod("cashPayment");
        } else {
            for (let gate of paymentOptions[index]?.gateway) {
                if (gate.selected) {
                    setPGMethod(gate.method);
                }
            }
        }
	};

	const handlePGChange = (event) => {
		const method = event.target.value;
		console.log("paymentMethod", paymentMethod)
		// setPGMethod(method);
		// setPaymentMethod(method);
	};

	const displayPaymentWindow = async (trnResponse) => {

        if (paymentMethod.method === "razorpay") {
            showRazorPayWindow(trnResponse);
        }
        if (paymentMethod.method === "paygoal") {
            showPaygoalWindow(trnResponse);
        }
        if (paymentMethod.method === "ccavenue") {
            showCcAvenueWindow(trnResponse);
        }
    }

	const processPayment = (pmtMode) => {
		console.log("pmtMode", pmtMode)
		if(pmtMode == "cash") {
			setTriggerSubmit(true);
		} else {
			// displayPaymentWindow();
		}
	};

	const reviewBooking = () => {
		setOpen(!open);
	}

	const refreshCashBalance = (usrDetails) => {
		apiCall(WebApi.getDashboardData, {}, function (response) {
			let mainAccountBalance = response.data.mainAccountBalance;
			setCashBalance(mainAccountBalance);
			localforageSetItem("user-details", {
				...usrDetails,
				cashBalance: mainAccountBalance,
			});
			if(isLoading) {
				setIsLoading(false);
			}
		});
	};

	useEffect(() => {
		apiCall(
			WebApi.getFlightDetails,
			{
				travelOfferId: props.location.state.offerDetails.specialOfferDetailId,
			},
			(response) => {
				if (response.success) {
					setFlightDetails(response.data);
					setGrandTotal(
						parseFloat(response.data?.adultTotalfare) + 
						parseFloat(response.data?.childTotalFare)
					);
				}
			}
		);
	}, []);

	useEffect(() => {
		if (isLoading) {
			localforageGetItem("user-details", function (err, usrDetails) {
				if (usrDetails) {
					setUserType(usrDetails.userType ? usrDetails.userType : 'b2c_user')
					if(usrDetails.userType == 'agent' || usrDetails.userType == 'direct-agent') {
						refreshCashBalance(usrDetails);
					}
					apiCall(
						WebApi.getCompanyPGOptions,
						{
							partnerId: usrDetails.partnerId,
							serviceId: 1,
						},
						(response) => {
							setIsLoading(false);
							setPaymentOptions(response.data);
							setPaymentTab(0);
							setPaymentMethod(response.data[0]);
							
							if(response.data[0].method == "cash") {
								setPGMethod("cashPayment");
							} else {
								for (let gate of response.data[0]?.gateway) {
									if (gate.selected) {
										setPGMethod(gate.method);
									}
								}
							}
						}
					);
				}
			});
		}
	}, [isLoading]);

	// const dataHeader = {
	//   originAirport: props.history.location.state.offerDetails.originAirport,
	//   originAirportCode: props.history.location.state.offerDetails.originAirportCode,
	//   depDate: props.history.location.state.offerDetails.depDate,
	//   destinationAirport: props.history.location.state.offerDetails.destinationAirport,
	//   destinationAirportCode: props.history.location.state.offerDetails.destinationAirportCode,
	//   arrDate: props.history.location.state.offerDetails.arrDate,
	// };

	return (
		<div className={classes.root}>
			<Grid container style={{ width: "100%", marginTop: 100, padding: 5 }}>
				{/* <p style={{padding: 10}}>{JSON.stringify(props)}</p> */}
				{/* <FlightSearchHeader
        data={dataHeader}
        ismodifying={props?.history?.location?.state?.isModifying}
        setismodifying={flag => { }}
        {...props} /> */}
				<Grid container gap={10} style={{ padding: 20 }}>
					<Grid item xs={12} md={9}>
						<OaFormAlerts
							isSaving={isSaving}
							isSuccess={isSuccess}
							isError={isError}
							infoText={infoText}
							setIsError={setIsError}
							setIsSuccess={setIsSuccess}
						/>
					</Grid>
					<Grid item xs={12} md={9}>
						<div>
							<Grid item xs={12} md={12} container justifyContent="flex-end">
								{!props.isHideBackButton &&
									<Button className={classes.btn} onClick={() => props.history.goBack()}>Back to Search Page</Button>}

							</Grid>
							<Accordion expanded={expanded === 'flight-details'} onChange={toggleExpanded('flight-details')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
								>
									<Typography className={classes.heading}>Flight Details</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{flightDetails !== null && (
										<FlightDetailCard flightDetails={flightDetails} {...props} />
									)}

									<div className="accordianButton">
										<Button className={classes.actionButton} onClick={() => setExpanded('pax-details')} >
											Next
										</Button>

									</div>
								</AccordionDetails>
							</Accordion>
						</div>
						<div>
							<Accordion expanded={expanded === 'pax-details'} onChange={toggleExpanded('pax-details')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}

								>
									<Typography className={classes.heading}>Traveller Details</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{flightDetails !== null &&
										<PassengerDetailCard
											openn={open}
											setopenn={flag => setOpen(flag)}
											flightDetails={flightDetails}
											passengerCount={{
												adult: props.location.state.noOfAdults,
												child: props.location.state.noOfChildren,
												infant: props.location.state.noOfInfants,
											}}
											travelOfferId={props.location.state.offerDetails.specialOfferDetailId}
											triggersubmit={triggerSubmit}
											processpayment={processPayment}
											paymentmode={paymentMethod}
											accepttnc={acceptTnc}
										/>}
									<div className="accordianButton">
										<Button className={classes.actionButton} onClick={() => setExpanded('flight-details')} >
											Back
										</Button>
										<Button className={classes.actionButton} onClick={() => setExpanded('gst-details')} >
											Next
										</Button>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
						<div>
							<Accordion expanded={expanded === 'gst-details'} onChange={toggleExpanded('gst-details')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}

								>
									<Typography className={classes.heading}>GST Details</Typography>

								</AccordionSummary>
								<AccordionDetails>
									<GstDetails />
									<div className="accordianButton">
										<Button className={classes.actionButton} onClick={() => setExpanded('pax-details')} >
											Back
										</Button>
										<Button className={classes.actionButton} onClick={() => setExpanded('pmt-details')} >
											Next
										</Button>
									</div>

								</AccordionDetails>
							</Accordion>
						</div>
						<div>
						<Accordion expanded={expanded === 'pmt-details'} onChange={toggleExpanded('pmt-details')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
							>
								<Typography className={classes.heading}>Payment Details</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid item container xs={12} spacing={2}>
									<Grid item md={12}>
										<Divider style={{ marginTop: 10, marginBottom: 10 }} />
									</Grid>
									
									<PaymentOptions
										paymenttab={paymentTab}
										switchpaymenttabs={switchPaymentTabs}
										paymentmodes={paymentOptions}
										isloading={isLoading}
										data={{balance: cashBalance}}
										selectedpaymentmethod={pgMethod}
										setselectedpaymentmethod={setPGMethod}
										grandtotal={grandTotal}
										setisloading={setIsLoading}
										refreshcashbalance={refreshCashBalance}
									/>

									<Grid item md={12}>
										<Divider style={{ marginTop: 20, marginBottom: 10 }} />
									</Grid>
									<Grid item md={12}>
										<Typography
											component="h2"
											variant="h6"
											className={classes.sectionHeading1}
										>
											Note
										</Typography>
									</Grid>

									<Grid item md={12}>
										<Divider style={{ marginTop: 10, marginBottom: 10 }} />
									</Grid>

									<Grid item md={12}>
										<span className="payment-info-title">
											This fare is not guaranteed until the Flight ticket is generated. Generation of a
											Transaction ID (TID) is the confirmation of booking request
											and payment received by {AppConstant.domainName} and under no
											circumstances, is it equivalent to {AppConstant.domainName}' final
											purchase price of respective Hotel voucher. There might be
											situations, where the fare displayed may increase by the
											time purchase is made with the respective Hotel. In this
											event, {AppConstant.domainName} will revert to you with the new fare
											or alternate options regarding your flight booking.
										</span>
									</Grid>
									<Grid item md={12}>
										<span className="payment-info-title " style={{ color: "#D0342C", }}>
											*There will be a TDS on the discounts / commissions on every
											transaction @ 5% for Permanent Account Number (PAN) holders
											and 20% if the agent is not having a PAN. Kindly update your
											PAN to save 15% on TDS.
										</span>
									</Grid>

									<Grid item md={12}>
										<Divider style={{ marginTop: 10, marginBottom: 10 }} />
									</Grid>

									<Grid item xs={12}>
										<FormControlLabel
											control={
												<Checkbox
													style={{ color: theme.palette.secondary.main }}
													value={acceptTnc}
													onChange={(event) => {
														setAcceptTnc(event.target.checked);
													}}
												/>
											}
											label={
												<div className={classes.termsandCond}>
													I have read and accepted{" "}
													<a style={{ color: theme.palette.primary.danger }}>
														<span onClick={() => setShowTNC(true)}>
															TERMS AND CONDITIONS
														</span>{" "}
														<OaModel
															show={showTNC}
															onHide={() => setShowTNC(false)}
															heading="Terms And Conditions"
															body={
																<>
																	<h4>Centered Modal</h4>
																	<p>
																		Cras mattis consectetur purus sit amet
																		fermentum. Cras justo odio, dapibus ac facilisis
																		in, egestas eget quam. Morbi leo risus, porta ac
																		consectetur ac, vestibulum at eros.
																	</p>
																</>
															}
														/>
													</a>{" "}
													and{" "}
													<a style={{ color: theme.palette.primary.danger }}>
														<span onClick={() => setShowFareRates(true)}>
															FARE RATES
														</span>
														<OaModel
															show={showFareRates}
															onHide={() => setShowFareRates(false)}
															heading="Fare Rates"
															body={
																<>
																	<h4>Centered Modal</h4>
																	<p>
																		Cras mattis consectetur purus sit amet
																		fermentum. Cras justo odio, dapibus ac facilisis
																		in, egestas eget quam. Morbi leo risus, porta ac
																		consectetur ac, vestibulum at eros.
																	</p>
																</>
															}
														/>
													</a>
												</div>
											}
											className={classes.payment__info}
										/>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
						</div>
						<div>
							<Grid item xs={12} md={12} container justifyContent="flex-end">
								<Button className={classes.button} onClick={reviewBooking}>Submit</Button>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={12} md={3}>
						{flightDetails !== null && (
							<FareBreakupCard
								flightDetails={flightDetails}
								adult={props.location.state.noOfAdults}
								child={props.location.state.noOfChildren}
								infant={props.location.state.noOfInfants}
							/>
						)}
					</Grid>

				</Grid>
			</Grid>
		</div>
	);
};

export default FlightDetails;
