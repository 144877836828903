import React, { useState, useEffect, useContext } from "react";
import { Button, Divider, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Menu from "pages/b2c/component/Menu";
import Profile from "pages/b2c/component/Profile";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import { localforageGetItem } from "oautils/oaForageUtils";
import MyBookingBus from "./MyBookingsBus";
import { AppStateContext } from "layouts/AppStateProvider";
import MyBookingsHotel from "./MyBookingsHotel";
import MyBookingsInsurance from "./MyBookingsInsurance";

import MyBookingsFlight from "./MyBookingsFlight";
import { ArrowBack } from "@material-ui/icons";
//travlogy_common



const MyBookings = (props) => {
  const classes = useStyles();
  const [value1, setValue1] = React.useState(0);
  const [value, setValue] = useState("1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let currentTab = 0;
  const [userType, setUserType] = React.useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [userTypeAbv, setUserTypeAbv] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function (err, value) {
      if (value) {
        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        setUserTypeAbv(value.userTypeAbv);
      } else {
        console.log("err", err);
      }
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);


  //console.log("userType " ,userType);
  return (
    <>
      {userType && userTypeAbv &&
        <div className={classes.root}>
          <Grid container spacing={isMobile ? 0 : 2} justifyContent={userType === "GUEST" || userType === "agent" || userType === "direct_agent" ? "center" : "space-between"}>

            {userType != "GUEST" && userType != "agent" && userType != "direct_agent" &&
              <Grid item md={3} xs={12} style={{ padding: 0, marginTop: 7 }}>
                <>
                  <Menu />
                  <div style={{ marginLeft: 15, marginRight: 15, marginTop: 10 }}>
                    <Profile />
                  </div>
                </>
              </Grid>
            }
            <Grid item md={9} xs={12} style={{ padding: !isMobile && 15 }}>
              <GridContainer style={{ background: "#d8d8d8", borderRadius: 5 }}>

                {
                  (userType != "GUEST" && userType != "agent" && userType != "direct_agent") ? <>
                    <Tabs
                      className="b2c-tab-buttons"
                      value={value1}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    // TabIndicatorProps={{
                    //   style: { backgroundColor: "rgba(0,0,0,0)" },
                    // }}
                    >
                      {/* {userType != "GUEST" && userType != "agent" && userType != "direct_agent" &&
                   <>
                     <Tab label="Flight" {...a11yProps(0)} />
                    <Tab label="Hotel" {...a11yProps(1)} />
                    <Tab label="Bus" {...a11yProps(2)} />
                  </>
                  } */}

                      <Tab label="Flight" {...a11yProps(0)} />
                      <Tab label="Hotel" {...a11yProps(1)} />
                      <Tab label="Bus" {...a11yProps(2)} />
                      <Tab label="Insurance" {...a11yProps(3)} />



                      <a onClick={() => props.history.goBack()} className={classes.backButton} >
                        <ArrowBack /> Back
                      </a>
                    </Tabs>
                  </> : <>
                    <Tabs
                      className="b2c-tab-buttons"
                      value={value1}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Flight" {...a11yProps(0)} />
                      <a onClick={() => props.history.goBack()} className={classes.backButton} >
                        <ArrowBack /> Back
                      </a>
                    </Tabs>

                  </>
                }
                <TabPanel variant="div" className="b2c-tab-panel" value={value1} index={currentTab}>
                  <MyBookingsFlight {...props} usertype={userTypeAbv} />
                </TabPanel>
                <TabPanel variant="div" className="b2c-tab-panel" value={value1} index={1}>
                  <MyBookingsHotel {...props} usertype={userTypeAbv} />
                </TabPanel>
                <TabPanel variant="div" className="b2c-tab-panel" value={value1} index={2}>
                  <MyBookingBus {...props} usertype={userTypeAbv} />
                </TabPanel>
                <TabPanel variant="div" className="b2c-tab-panel" value={value1} index={3}>
                  <MyBookingsInsurance {...props} usertype={userTypeAbv} />
                </TabPanel>
              </GridContainer>

            </Grid>
          </Grid>
          <style>{`
      .triumph-b2c .oa-navbar-menu a {
        color: ${theme.palette.primary.darkText} !important;
      }
      .triumph-b2c .oa-navbar-sticky .oa-navbar-menu a {
        color: ${theme.palette.primary.lightText} !important;
      }
      `}</style>
        </div>
      }
    </>
  );
};

export default withRouter(MyBookings);



export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography variant="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    //paddingTop: "40px",
    paddingBottom: "40px",
    //marginTop: 100,
    "& .MuiInputBase-input": {
      minHeight: 35
    },
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiTab-textColorInherit": {
      "&.Mui-selected": {
        color: theme.palette.secondary.main,
      },
    },
    "& .PrivateTabIndicator-colorSecondary": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .b2c-tab-buttons": {
      background: theme.palette.secondary.disabled,
      position: "relative",
      width: "100%",
    },
    "& .b2c-tab-panel": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    }
  },
  backButton: {
    position: "absolute",
    right: 10,
    top: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.darkText,
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      marginRight: 5,
    },
    "&:hover": {
      color: theme.palette.primary.sub,
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: "#212529",
    marginLeft: 5,
    letterSpacing: "0.5px !important",
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    }
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  typo5: {
    fontSize: 11,
    color: "#000",
    fontWeight: 300,
    opacity: 0.7,
  },
  typo6: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
  },
  typo7: {
    fontSize: 12,
    color: "grey",
    fontWeight: 400,
  },
  typo8: {
    fontSize: 11,
    fontWeight: 300,
  },
  typo9: {
    color: "#6f6f6f",
    fontSize: 12,
  },
  typo10: {
    fontSize: 12,
    color: "#828080",
    fontWeight: 500,
  },
  button: {
    background: theme.palette.secondary.main,
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    padding: 8,
    borderRadius: 10,
    textTransform: "capitalize",
    "&:hover": {
      background: "#2b5a85",
    },
  },
}));
