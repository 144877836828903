import React from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OaCard from 'oahoc/OaCard';

import {Link} from 'react-router-dom';
import { BASE_URL_IMAGE_VIEW } from 'api/ApiConstants';
import { Button } from '@material-ui/core';

const BankAccountCard = (props) => {

    const bankList = props.bankList === undefined ? [] : props.bankList;
    const error = props.isError;
    // console.log(bankList);

    function AOBankCard(props) {
        return (
             <OaCard>
                 <GridContainer>
                     <GridItem xs={12} sm={12} md={9}>
                         <GridContainer>
                             <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Account Name</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.accountName}</GridItem>
                         </GridContainer>
                         <GridContainer>
                             <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Account Number</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.accountNumber}</GridItem>
                         </GridContainer>
                         <GridContainer>
                             <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Bank Address</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.bankAddress}</GridItem>
                         </GridContainer>
                         <GridContainer>
                             <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Bank Name</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.bankName}</GridItem>
                         </GridContainer>
                         <GridContainer>
                             <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Branch Name</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.branchName}</GridItem>
                         </GridContainer>
                         <GridContainer>
                             <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>IFSC</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.ifscCode}</GridItem>
                         </GridContainer>
                         <GridContainer>
                            <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Remarks</GridItem>
                             <GridItem xs={12} sm={12} md={8} style={{marginTop:5, marginBottom:5}}>{props.remarks}</GridItem>
                         </GridContainer>

                        {
                            props.bankLogo &&
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>Bank Logo</GridItem>
                             <GridItem xs={12} sm={12} md={3} style={{marginTop:5, marginBottom:5}}>
                                        <img
                                            alt="bankLogo"
                                            src={
                                            BASE_URL_IMAGE_VIEW +
                                            `agency/view-file?fileName=${props.bankLogo}&type=BANK_LOGO_QRCODE`
                                            }
                                            // src={"https://media.istockphoto.com/id/1382305677/vector/finance-logo-vector-illustration-in-trendy.jpg?s=612x612&w=0&k=20&c=37WbXhqdKzvIq7kmltVoGZaBHcUE_Mhll23cyQpgij8="}
                                            width={100}
                                            height={60}
                                            style={{ float: "right", borderRadius: 5 }}
                                        />
                             </GridItem>
                        

                         </GridContainer>

                        }

                        {
                            props.bankQRCode &&
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={4} style={{marginTop:5, marginBottom:5, fontWeight:'bold'}}>QR Code</GridItem>
                             <GridItem xs={12} sm={12} md={3} style={{marginTop:5, marginBottom:5}}>
                             <img
                                        alt="bankQRCode"
                                        src={
                                        BASE_URL_IMAGE_VIEW +
                                        `agency/view-file?fileName=${props.bankQRCode}&type=BANK_LOGO_QRCODE`
                                        }
                                        width={100}
                                        height={60}
                                        style={{ float: "right", borderRadius: 5 }}
                                    />
                             </GridItem>
                        

                         </GridContainer>

                        }  
                     </GridItem>
                     
                     <GridItem xs={12} sm={12} md={3}>
                         <GridContainer style={{height:'100%'}}>
                         <GridItem xs={12} sm={12} md={12} style={{ marginTop: "6px" }}>
                {props.isActive ? (
                  <div
                    style={{
                      float: "right",
                      background: "green",
                      borderRadius: 50,
                      padding: 5,
                      color: "white",
                    }}
                  >
                    Active
                  </div>
                ) : (
                  <div
                    style={{
                      float: "right",
                      background: "lightgrey",
                      borderRadius: 50,
                      padding: 5,
                    }}
                  >
                    Inactive
                  </div>
                )}
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={12} style={{ marginTop: "auto" }}>
                {props.isReceiver ? (
                  ""
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ float: "right",marginBottom:20, }}
                  >
                    <Link
                      style={{ color: "#fff" }}
                      to={`/admin/account/bank-accounts/form/${props.bankId}`}
                    >
                      Modify
                    </Link>
                  </Button>
                )
                
                
                }
              </GridItem> */}
                         </GridContainer>
                       
                     </GridItem>
                 </GridContainer>
             </OaCard>);
    }

    return (
        <div>
        {error ? (<OaCard>An error occured, please tray again later</OaCard>) : (
            <span>
                {bankList.length === 0 ? (<OaCard>No results</OaCard>) :(
                    <GridContainer>
                    {bankList.map( row=> { 
                        return(        
                            <GridItem xs={12} sm={12} md={8} key={row.bankName} >
                                {/* {props.isReceiver ?
                                    <AOBankCard accountLogo={row.accountLogo} accountName={row.accountName} remarks={row.remarks} bankName={row.bankName} branchName={row.branchName} accountNumber={row.accountNumber} ifscCode={row.ifscCode} isActive={row.isActive} bankAddress={row.bankAddress}
                                    bankId={row.bankId}
                                    bankQRCode={row.bankQRCode}
                                    bankLogo={row.bankLogo}
                                    ></AOBankCard>
                                :<Link  to={`/admin/account/bank-accounts/form/${row.bankId}`}>
                                    <AOBankCard accountLogo={row.accountLogo} accountName={row.accountName} remarks={row.remarks} bankName={row.bankName} branchName={row.branchName} accountNumber={row.accountNumber} ifscCode={row.ifscCode} isActive={row.isActive} bankAddress={row.bankAddress}></AOBankCard>
                                </Link>
                                } */}
                                  <AOBankCard
                                accountLogo={row.accountLogo}
                                accountName={row.accountName}
                                remarks={row.remarks}
                                bankName={row.bankName}
                                branchName={row.branchName}
                                accountNumber={row.accountNumber}
                                ifscCode={row.ifscCode}
                                isActive={row.isActive}
                                bankAddress={row.bankAddress}
                                bankId={row.bankId}
                                bankQRCode={row.bankQRCode}
                                bankLogo={row.bankLogo}
                                ></AOBankCard>
                            </GridItem>
                        )
                    })}
                    </GridContainer>
                )}
            </span>
        )}
        </div>
    )
}

export default BankAccountCard;
