import { combineReducers } from "redux";
import { SET_HOTEL_COUNTRY, SET_HOTEL_CITY } from "redux/action";
// import {
//     posts,
//     newPostForm,
//     deletePostModal,
//     editPostForm,
//     postsAreLoading
// } from './bus'
import { hotel } from "./hotel";

import { flight } from "./flight";
import { bus } from "./bus";
import { flightList } from "./B2CFlight";
import { hotelList } from "./B2cHotel";
import { busList } from "./B2CBus";
import { insuranceList } from "./B2CInsurance";

import { usertype } from "./UserType";

// function sortMethod(state = 'score', action) {
//   switch (action.type) {
//     case SET_HOTEL_COUNTRY:
//       return action.method
//     default:
//       return state
//   }
// }

// function toastMessage(state = '', action) {
//   switch (action.type) {
//     case SET_HOTEL_CITY:
//       const { message } = action
//       return message
//     default:
//       return state
//   }
// }

export default combineReducers({
  // posts,
  // newPostForm,
  // deletePostModal,
  // editPostForm,
  // postsAreLoading,
  // sortMethod,
  // toastMessage,
  hotel,
  flight,
  flightList,
  hotelList,
  busList,
  insuranceList,
  usertype,
  bus
});
