import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BagItem from "./BagItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.3rem 2rem",
    paddingTop: 40,
    [theme.breakpoints.down(600)]: {
      padding: "1rem  0 ",
    },
    "& .baggage": {
      borderTop: "1px solid #e1e1e1",

      height: "350px",
      overflowY: "auto",
      [theme.breakpoints.down(600)]: {
        marginTop: 26,
      },
      // overflowY: "scroll",
      // padding: "0 0.5rem ",
    },
  },
}));

export default function Baggage(props) {
  const classes = useStyles();

  const [currTime, setCurrTime] = React.useState(new Date());
  const [incDisable, setIncDisable] = React.useState(false);
  const [paxDetail, setPaxDetail] = React.useState(props.paxinfo);
  const [selectedBagg, setSelectedBagg] = React.useState([]);
  const [selectedBaggRt, setSelectedBaggRt] = React.useState([]);

  const PaxNum = props.servlimit;

  React.useEffect(() => {
    let baggsSelected = 0;
    if (props.isreturn) {
      paxDetail &&
        paxDetail.adultPax &&
        paxDetail.adultPax.map((pax) => {
          pax.additionalReturn &&
            pax.additionalReturn.map((val, addIdx) => {
              if (addIdx == 0 && val.baggage != null) {
                baggsSelected++;
              }
            });
        });

      if (paxDetail && paxDetail.childPax.length > 0) {
        paxDetail.childPax &&
          paxDetail.childPax.map((pax) => {
            pax.additionalReturn &&
              pax.additionalReturn.map((val, addIdx) => {
                if (addIdx == 0 && val.baggage != null) {
                  baggsSelected++;
                }
              });
          });
      }

      if (baggsSelected == PaxNum) {
        paxDetail.baggStatusRt[0].baggsFull = true;
        setIncDisable(true);
      }
      props.setpaxinfo(paxDetail, "return");
    } else {
      paxDetail &&
        paxDetail.adultPax &&
        paxDetail.adultPax.map((pax) => {
          pax.additionalOnward &&
            pax.additionalOnward.map((val, addIdx) => {
              if (addIdx == 0 && val.baggage != null) {
                baggsSelected++;
              }
            });
        });

      if (paxDetail && paxDetail.childPax.length > 0) {
        paxDetail.childPax &&
          paxDetail.childPax.map((pax) => {
            pax.additionalOnward &&
              pax.additionalOnward.map((val, addIdx) => {
                if (addIdx == 0 && val.baggage != null) {
                  baggsSelected++;
                }
              });
          });
      }

      if (baggsSelected == PaxNum) {
        paxDetail.baggStatus[0].baggsFull = true;
        setIncDisable(true);
      }
      props.setpaxinfo(paxDetail, "onward");
    }
  }, [paxDetail]);

  React.useEffect(() => {
    populateBaggage();
  }, []);

  const populateBaggage = () => {
    var entries = [...props.paxinfo.adultPax, ...props.paxinfo.childPax];
    let baggs = [];
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of props.isreturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (val.baggage !== null) {
          if (baggs.includes(val.baggage)) {
            for (let [ind, bag] of baggs.entries()) {
              if (bag.code === val.baggage.code) {
                bag.qty++;
              }
            }
          } else {
            val.baggage.qty = 1;
            baggs.push(val.baggage);
          }
        }
      }
    }
    if (props.isreturn) {
      setSelectedBaggRt(baggs);
    } else {
      setSelectedBagg(baggs);
    }
  };

  const addSelectedBaggage = (entries, paxInfo, data, isReturn) => {
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of isReturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (addIdx == 0 && val.baggage == null) {
          val.baggage = data;
          if (isReturn) {
            paxInfo.additionalFareRt.baggage =
              parseFloat(paxInfo.additionalFareRt.baggage) +
              parseFloat(data.price);
          } else {
            paxInfo.additionalFare.baggage =
              parseFloat(paxInfo.additionalFare.baggage) +
              parseFloat(data.price);
          }
          setPaxDetail(paxInfo);
          return;
        }
      }
    }
  };

  const removeSelectedBaggage = (entries, paxInfo, data, isReturn) => {
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of isReturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (
          addIdx == 0 &&
          val.baggage != null &&
          val.baggage.code == data.code
        ) {
          val.baggage = null;
          if (isReturn) {
            paxInfo.additionalFareRt.baggage =
              parseFloat(paxInfo.additionalFareRt.baggage) > 0
                ? parseFloat(paxInfo.additionalFareRt.baggage) -
                parseFloat(data.price)
                : 0;
            paxInfo.baggStatusRt[0].baggsFull = false;
          } else {
            paxInfo.additionalFare.baggage =
              parseFloat(paxInfo.additionalFare.baggage) > 0
                ? parseFloat(paxInfo.additionalFare.baggage) -
                parseFloat(data.price)
                : 0;
            paxInfo.baggStatus[0].baggsFull = false;
          }
          setPaxDetail(paxInfo);
          setCurrTime(new Date());
          setIncDisable(false);
          return;
        }
      }
    }
  };

  const onSelectBaggage = (data, flag) => {
    let paxInfo = { ...paxDetail };
    var pax = [...paxInfo.adultPax, ...paxInfo.childPax];

    if (data != null) {
      if (props.isreturn) {
        if (flag) {
          addSelectedBaggage(pax, paxInfo, data, true);
        } else {
          removeSelectedBaggage(pax, paxInfo, data, false);
        }
      } else {
        if (flag) {
          addSelectedBaggage(pax, paxInfo, data, false);
        } else {
          removeSelectedBaggage(pax, paxInfo, data, false);
        }
      }
    }
  };

  return (
    <Box className={classes.root}>
      <div className="baggage">
        {props.data &&
          props.data.length > 0 &&
          props.data.map(
            (item, index) =>
              index != 0 && (
                <BagItem
                  item={item}
                  key={index}
                  setselectedbag={(val, flag) => onSelectBaggage(val, flag)}
                  disableinc={incDisable}
                  selectedbagg={selectedBagg}
                  selectedbaggrt={selectedBaggRt}
                  isreturn={props.isreturn}
                />
              )
          )}
      </div>
    </Box>
  );
}
