import { Box, IconButton, useMediaQuery, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
// import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import age from "assets/insurance/icons/age.png";
import flag from "assets/insurance/icons/flag.png";
import numberOf from "assets/insurance/icons/numberOf.png";
import calendar from "assets/insurance/icons/calendar.png";
import trip from "assets/insurance/icons/trip.png";
import CloseIcon from "@material-ui/icons/Close";
// import closeBtn from "assets/insurance/icons/closeButton.png";
import InsuranceSearch from "./modify/InsuranceSearch";
import Drawer from "@material-ui/core/Drawer";
import MobileInsuranceSections from "./modify/MobileInsuranceSection";
import { ErrorOutlineRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    maxWidth: "1440px",
    position: "relative",
    padding: 10,
    "& .MuiPaper-root ": {
      "& .MuiDrawer-paper ": {},
      width: "100%",
    },

    "& .modify-div": {
      transition: "all 1.4s cubic-bezier(0.45,0.05,0.55,0.95)",
      animationDelay: "3s",
      width: "100%",

      "& .insurance-container": {
        margin: 0,
        marginBottom: "1.6rem",
      },

      "& .input-element": {
        "& .Mui-selected ": {
          backgroundColor: theme.palette.primary.main,
        },
      },

      // "& .drawer-close-btn":{
      //   position:'fixed',
      //   right:'20px',
      //   top:'12.3%',
      //   "& .MuiSvgIcon-root":{
      //     color:"#fff",
      //     fontSize:66,
      //   }
      // },
    },

    "& tr": {
      border: "1px solid #000",
    },
    "& th": {
      width: 150,
      backgroundColor: "#c2d87e",
      fontWeight: 500,
      fontSize: 16,
    },
    "& table": {
      border: "1px solid #e0e0e0",
    },
    "& td": {
      textAlign: "center",
      backgroundColor: "#fff",
      fontWeight: 500,
      fontSize: 14,
    },
    "& .coverDetails": {},
    "& .resultContainer": {
      minHeight: 400,
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(760)]: {
        flexDirection: "column",
        gap: "10px",
      },
    },

    "& .searchCriteriaContainer": {
      width: "30%",
      border: "1px solid #e0e0e0 ",
      padding: 15,
      [theme.breakpoints.down(760)]: {
        width: "100%",
      },

      "& .inputField": {
        backgroundColor: "#f9f9f9",
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
        paddingLeft: 10,
        paddingTop: 5,
        "& .inputIcon": {
          height: 25,
          width: 25,
          marginRight: 10,
        },
      },
      "& .searchCriteria": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 18,
        padding: "10px 10px",
      },
      "& .modifySection": {
        display: "flex",
        justifyContent: "flex-end",
      },
      "& .modifyBtn": {
        height: 30,
        // width: 120,
        fontSize: 12,
        fontWeight: 500,
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .plansContainer": {
      width: "69%",
      border: "1px solid #e0e0e0",
      padding: 15,
      // overflowX:'auto',
      [theme.breakpoints.down(760)]: {
        width: "100%",
        padding: 6,
      },
      "& .emailSelect": {
        display: "flex",
        justifyContent: "flex-end",
        "& .emailBtn": {
          height: 30,
          // width: 120,
          fontSize: 12,
          marginBottom: 15,
          fontWeight: 500,
          backgroundColor: theme.palette.background.darkerFadedGray,
        },
      },
      "& .plans": {
        // [theme.breakpoints.down("sm")]: {
        //   width:"max-content",
        //  },
        "& .plan": {
          [theme.breakpoints.down("sm")]: {
            // width:"126%",
            padding: 10,
          },
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: "#f9f9f9",
          borderRadius: 20,
          padding: 20,
          marginBottom: 10,
        },
        "& .plansUpper": {
          display: "flex",
          marginBottom: 10,
          justifyContent: "space-between",

          "& .planTitle": {
            fontSize: 20,
            fontWeight: 700,
            color: theme.palette.primary.main,
          },
          "& .priceDetails": {
            display: "flex",
            fontSize: 16,
            fontWeight: 500,
            textDecoration: "underline",
            "& .priceX": {
              marginRight: 10,
              [theme.breakpoints.down(760)]: {
                fontSize: 12.3,
                marginRight: 0,
              },
            },
          },
        },
        "& .plansLower": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          "& .planToggle": {},

          "& .planDetails": {
            display: "flex",
            fontSize: 14,
            fontWeight: 500,
            textDecoration: "underline",
            cursor: "pointer",
          },
          "& .submit": {
            height: 30,
            width: 120,
            fontSize: 12,
            color: theme.palette.primary.lightText,
            fontWeight: 500,
            [theme.breakpoints.down(760)]: {
              fontSize: 9,
              fontWeight: 900,
            },
            background: theme.palette.buttons.bookNowBtn,
          },
        },
      },
    },
    "& .modal": {
      // position: "fixed",
      display: "flex",
      // top: "42%",
      zindex: 999,
      width: "100%",
      overflowX: "auto",
      [theme.breakpoints.down(760)]: {
        width: "100%",
        flexDirection: "column",
      },

      // justifyContent: "center",
      "& .modal__modalContainer": {
        width: "fit-content",
        backgroundColor: "#fff",
        // boxShadow: "0 0 6px 6px rgb(0 0 0 / 0.2)",
        padding: 10,
        marginTop: 10,
        // paddingTop: 6,
        borderRadius: 10,
        [theme.breakpoints.down(760)]: {
          width: "160%",
        },
        // overflowX:'auto',
      },
      "& .modalHeader": {
        fontSize: 20,
        textAlign: "center",
        fontWeight: 600,
        marginBottom: 20,
        position: "relative",
        width: "100%",
        "& .closeIcon": {
          zindex: 999999999,
          cursor: "pointer",
          position: "absolute",
          right: -5,
          top: -2,
        },
      },
    },
  },
  noResultContainer: {
    flexWrap: "nowrap",
    width: "100%",

    minHeight: "calc(80vh - 200px)",
    justifyContent: "center",
    alignItems: "center",
  },
  noResults: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const InsuranceToggle = ({ coverDetailsData, priceBreakupData, request }) => {
  const [showCoverDetails, setShowCoverDetails] = useState(false);
  const [showPriceBreakup, setPriceBreakupDetails] = useState(false);
  // console.log(request);

  console.log(request.paxAge);

  return (
    <>
      <Grid className="plansToggle">
        <Grid className="planDetails">
          <Box
            onClick={() => {
              if (showCoverDetails == false) {
                setShowCoverDetails(true);
                setPriceBreakupDetails(false);
              } else {
                setShowCoverDetails(false);
                setPriceBreakupDetails(false);
              }
            }}
            style={{ marginRight: 10 }}
          >
            Cover Details
          </Box>

          <Box
            onClick={() => {
              if (showPriceBreakup == false) {
                setPriceBreakupDetails(true);
                setShowCoverDetails(false);
              } else {
                setPriceBreakupDetails(false);
                setShowCoverDetails(false);
              }
            }}
          >
            Price Break up
          </Box>
        </Grid>
        {showCoverDetails && (
          <Grid className="modal">
            <Grid className="modal__modalContainer">
              <Grid className="coverDetails">
                <table>
                  <tr>
                    <th>Coverage</th>
                    <th>Sum Insured</th>
                    <th>Excess</th>
                  </tr>
                  {coverDetailsData
                    ? coverDetailsData?.map((result) => (
                      <tr>
                        <td>{result.coverage ?? null}</td>
                        <td>{result.sumInsured ?? null}</td>
                        <td>{result.excess ?? null}</td>
                      </tr>
                    ))
                    : !coverDetailsData && (
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    )}
                </table>
              </Grid>
            </Grid>
          </Grid>
        )}
        {showPriceBreakup && (
          <Grid className="modal">
            <Grid className="modal__modalContainer">
              <table>
                <tr>
                  <th>Pax Count</th>
                  <th>Age</th>
                  <th>Group</th>
                  <th>Premium</th>
                </tr>
                {priceBreakupData ? (
                  priceBreakupData?.map((result) => (
                    <tr>
                      <td>{result.passengerCount}</td>
                      <td> {request.paxAge.toString().replace(",", ", ")}</td>
                      <td>
                        {result.minAge} - {result.maxAge}
                      </td>
                      <td>{result.commissionCharge.grossProductPrice}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </table>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const InsuranceSearchResult = (props) => {
  const classes = useStyles();
  const { result: results, request, session_id } = props.history.location.state;

  const [coverDetailsData, setCoverDetailsData] = useState();
  const [priceBreakupData, setPriceBreakupData] = useState();
  const [isModify, setIsModify] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // const [filterShow, setFilterShow] = useState(true);

  // const coverDetailHandler = (coverDetailsDataa) => {
  //   setCoverDetailsData(coverDetailsDataa);
  //   setShowCoverDetails(true);
  // };
  // const priceBreakupHandler = (priceBreakupDataa) => {
  //   setPriceBreakupData(priceBreakupDataa);
  //   setPriceBreakupDetails(true);
  // };

  // React.useEffect(() => {
  //   console.log("props", props);
  // }, []);

  const goToInsuranceDetails = (result) => {
    props.history.push({
      pathname: "/insurance/details",
      state: { result, request, session_id },
    });
  };
  const NoResultView = () => {
    return (
      <Grid container direction="row" className={classes.noResultContainer}>
        <Grid item className={classes.noResults}>
          <ErrorOutlineRounded />
          <Typography>No Results found</Typography>
        </Grid>
      </Grid>
    );
  };
  const isResults = results && results.length > 0;
  return (
    <div className={classes.root}>
      {/* {isModify && (
        <div className="modify-div">
          {isMobile ? (
            <Drawer
              open={isModify}
              onClose={() => setIsModify(false)}
              className="flight-filter-drawer-root"
            >
              <MobileInsuranceSections />
            </Drawer>
          ) : (
            <InsuranceSearch />
          )} */}
      <div className="modify-div">
        {!isMobile && <InsuranceSearch {...props} />}

        {isMobile && (
          <Drawer
            open={isModify}
            onClose={() => setIsModify(false)}
            className="flight-filter-drawer-root"
          >
            <div>
              <div
                className="drawer-close-btn"
                style={{
                  position: "fixed",
                  right: "3px",
                  top: "9.3%",
                  zIndex: 993,
                }}
              >
                <IconButton onClick={() => setIsModify(!isModify)}>
                  <CloseIcon
                    style={{
                      color: "#fff",
                      fontSize: 40.3,
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: 9,
                    }}
                  />
                </IconButton>
              </div>
              <MobileInsuranceSections {...props} />
            </div>
          </Drawer>
        )}
      </div>

      <Grid className="resultContainer">
        <Grid className="searchCriteriaContainer">
          {/* <TextField variant="outlined" fullWidth label="Search criteria" /> */}
          <Box className="inputField searchCriteria">Search Criteria</Box>
          {/* <Box className="inputField">
            {props.history.location.state.result[0].planName}
          </Box> */}
          <Box className="inputField">
            <img className="inputIcon" src={trip} />
            {request.planType === 1 ? "Single Trip" : "Round Trip"}
          </Box>
          <Box className="inputField">
            <img className="inputIcon" src={flag} />
            India
          </Box>
          <Box className="inputField">
            {" "}
            <img className="inputIcon" src={calendar} />
            {`${request.travelStartDate
              .substring(0, 10)
              .split("-")
              .reverse()
              .join("-")} - ${request.travelEndDate
                .substring(0, 10)
                .split("-")
                .reverse()
                .join("-")}`}
          </Box>
          <Box className="inputField">
            {" "}
            <img className="inputIcon" src={numberOf} />
            {request.paxCount}
          </Box>
          <Box className="inputField" style={{ display: "flex" }}>
            <img className="inputIcon" src={age} />
            <p style={{ display: "inline" }}>
              {request.paxAge.map((item) => item).join(", ")}
            </p>
          </Box>
          {isMobile && (
            <Box className="modifySection">
              <Button
                variant="contained"
                className="modifyBtn"
                // onClick={() => props.history.goBack()}
                onClick={() => setIsModify(!isModify)}
              >
                Modify Search
              </Button>
            </Box>
          )}
        </Grid>
        <Grid className="plansContainer">
          {/* <Grid className="emailSelect">
            <Button className="emailBtn">Email Selected Quotes</Button>
          </Grid> */}
          <Grid className="plans">
            {isResults ? (
              results.map((result, ind) => (
                <Grid className="plan" key={ind}>
                  <Grid className="plansUpper">
                    <Grid>
                      <Box className="planTitle">{result.planName}</Box>
                      {/* <Box>{props.history.location.state.result[0].planName}</Box> */}
                    </Grid>
                    <Box>
                      <Grid className="priceDetails">
                        <Box className="priceX">
                          ₹
                          {
                            result?.premuimList[0]?.commissionCharge
                              ?.grossProductPrice
                          }
                        </Box>
                        {/* <Box className="email">Email</Box> */}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid className="plansLower">
                    <InsuranceToggle
                      coverDetail={result?.coverageDetails}
                      priceBreakupData={result?.premuimList}
                      request={request}
                    />
                    <Button
                      className="submit"
                      onClick={() => goToInsuranceDetails(result)}
                    >
                      Choose This
                    </Button>
                  </Grid>
                </Grid>
              ))
            ) : (
              <NoResultView />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InsuranceSearchResult;
