import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  FlightTakeoff,
  FlightLand,
  Flight,
  Alarm,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import dateFnsFormat from "date-fns/format";
import { FaRupeeSign, AiOutlineArrowDown } from "react-icons/fa";
import cx from "classnames";

const BusSearchSort = (props) => {
  // const [sort, setSort] = useState("price");
  // const requestObj = props.location.state.requestObj;
  // const { mode, returnDate, isDomestic } = requestObj;
  // const onwDate = props.location.state.requestObj?.sectors[0]?.departDate;
  // const type = props.type;
  // const dateRef = type === "return" ? new Date(returnDate) : new Date(onwDate);
  // const [date, setDate] = useState(dateRef);
  const [selected, setSelected] = useState("highToLow");
  const [isLowPrice, setIsLowPrice] = useState(true);
  const [isLowRating, setIsLowRating] = useState(true);
  const [isLowSeats, setIsLowSeats] = useState(true);
  const [isLowDepTime, setIsLowDepTime] = useState(true);
  const [isLowArrTime, setIsLowArrTime] = useState(true);
  //   const [isLow]

  const width = window.innerWidth;
  const breakpoint = 980;
  const classes = useStyles();

  const handleChange = (value) => {
    setSelected(value);
    const label = value;
    const sortedData = sortData(label, props.data, props.tripMode);
    props.setData(sortedData);
  };

  const sortByLowDepTime = (arrayData) => {
    return arrayData?.slice()?.sort(function(a, b) {
      let sortKeyA = new Date(a.departureTime).getTime();
      let sortKeyB = new Date(b.departureTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const sortByHighDepTime = (arrayData) => {
    return arrayData?.slice()?.sort(function(a, b) {
      let sortKeyA = new Date(b.departureTime).getTime();
      let sortKeyB = new Date(a.departureTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByLowArrTime = (arrayData) => {
    return arrayData?.slice()?.sort(function(a, b) {
      let sortKeyA = new Date(a.ArrivalTime).getTime();
      let sortKeyB = new Date(b.ArrivalTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByHighArrTime = (arrayData) => {
    return arrayData?.slice()?.sort(function(a, b) {
      let sortKeyA = new Date(b.ArrivalTime).getTime();
      let sortKeyB = new Date(a.ArrivalTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByPriceAsc = (arrayData) => {
    // console.log(arrayData[0].busPrice?.commissionCharge?.grossProductPrice);
    // return;
    return arrayData?.slice().sort(function(a, b) {
      return (
        a?.busPrice?.commissionCharge?.grossProductPrice -
        b?.busPrice?.commissionCharge?.grossProductPrice
      );
    });
  };
  const sortByPriceDesc = (arrayData) => {
    return arrayData?.slice().sort(function(a, b) {
      return (
        b?.busPrice?.commissionCharge?.grossProductPrice -
        a?.busPrice?.commissionCharge?.grossProductPrice
      );
    });
  };

  const sortByHighSeats = (arrayData) => {
    // console.log(arrayData[0].AvailableSeats);
    return arrayData?.slice()?.sort(function(a, b) {
      return Number(b.AvailableSeats) - Number(a.AvailableSeats);
    });
  };
  const sortByLowSeats = (arrayData) => {
    // console.log(arrayData[0].AvailableSeats);
    return arrayData?.slice()?.sort(function(a, b) {
      return Number(a.AvailableSeats) - Number(b.AvailableSeats);
    });
  };

  const sortData = (type, arrayData, tripMode) => {
    let sortedData = [];
    switch (type) {
      case "Price": {
        if (isLowPrice) {
          sortedData = sortByPriceDesc(arrayData);
          setIsLowPrice(false);
        } else {
          sortedData = sortByPriceAsc(arrayData);
          setIsLowPrice(true);
        }

        break;
      }
      case "Seat": {
        if (isLowSeats) {
          sortedData = sortByHighSeats(arrayData);
          setIsLowSeats(false);
        } else {
          sortedData = sortByLowSeats(arrayData);
          setIsLowSeats(true);
        }

        break;
      }
      case "sortByDepTime": {
        if (isLowDepTime) {
          sortedData = sortByLowDepTime(arrayData);
          console.log(sortedData);
          setIsLowDepTime(false);
        } else {
          sortedData = sortByHighDepTime(arrayData);
          setIsLowDepTime(true);
        }
        break;
      }
      case "sortByArrTime": {
        if (isLowArrTime) {
          sortedData = sortByLowArrTime(arrayData);
          setIsLowArrTime(false);
        } else {
          sortedData = sortByHighArrTime(arrayData);
          setIsLowArrTime(true);
        }
        break;
      }

      default: {
        sortedData = props.data;
        break;
      }
    }

    return sortedData;
  };

  const GenerateButton = ({ value, icon }) => {
    const ref = {
      Seat: "Seat",
      sortByDepTime: "Dep Time",
      sortByArrTime: "Arr Time",
      Price: "Price",
    };
    const label = ref[value];
    return (
      <>
        <Button
          value={value}
          onClick={() => handleChange(value)}
          className={cx("sort-btn", selected === value ? "selected" : "")}
          size="small"
          endIcon={icon}
        >
          {label}
        </Button>
      </>
    );
  };

  // console.log(mode);
  const SortHeader = () => (
    <Grid container className={classes.sortHeaderSoloContainer}>
      <Grid item className={classes.sortHeaderItem}>
        <GenerateButton
          value={"Seat"}
          icon={isLowSeats ? <ArrowDownward /> : <ArrowUpward />}
        />
      </Grid>
      <Grid item className={classes.sortHeaderItem}>
        <GenerateButton
          value={"sortByDepTime"}
          icon={isLowDepTime ? <ArrowDownward /> : <ArrowUpward />}
        />
      </Grid>
      <Grid item className={classes.sortHeaderItem}>
        <GenerateButton
          value={"sortByArrTime"}
          icon={isLowArrTime ? <ArrowDownward /> : <ArrowUpward />}
        />
      </Grid>

      <Grid item className={[classes.sortHeaderItem, classes.airlines]}>
        <GenerateButton
          value={"Price"}
          icon={isLowPrice ? <ArrowDownward /> : <ArrowUpward />}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid className={classes.root}>
      <Grid container className={classes.sortContainer}>
        {width > breakpoint && <SortHeader />}
      </Grid>
    </Grid>
  );
};

export default BusSearchSort;
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down(500)]: {
      display: "none",
    },
  },
  sortContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    background: theme.palette.background.light,
    //background:theme.palette.background.rgba_tertiary,
    // marginLeft: "12px",
    width: "100%",
    borderRadius: 5,
    //background: theme.palette.primary.main,
    [theme.breakpoints.down(1280)]: {
      // padding: "10px 5px",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 11px",
      justifyContent: "space-between",
    },
  },
  prevNextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    background: theme.palette.background.light,
    //background:theme.palette.background.rgba_tertiary,
    marginLeft: "12px",
    width: "98%",
    borderRadius: 5,
    padding: "5px 20px",
    marginBottom: 10,
    fontWeight: 700,

    //background: theme.palette.primary.main,
  },
  sortContainerRt: {
    display: "flex",
    flexDirection: "column",

    padding: "0 10px",
    [theme.breakpoints.down(1280)]: {
      padding: "10px 5px",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 10px",
      justifyContent: "space-between",
    },
  },
  sortDropdown: {
    marginLeft: 10,
    marginRight: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sortLabel: {
    margin: 0,
    marginRight: 10,
  },
  sortHeaderContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      marginRight: 10,
      width: "100%",
      padding: "5px 10px",
    },

    "& .MuiButton-outlinedPrimary": {
      color: theme.palette.buttons.defaultContrast,
      borderColor: theme.palette.buttons.defaultContrast,
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-outlinedSecondary": {
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-textPrimary": {
      color: theme.palette.text.default,
    },
    "& .MuiButton-label": {
      fontSize: 12,
      fontWeight: "600",
      textDecorationLine: "underline",
    },
    "& .MuiButton-outlinedSizeSmall": {
      fontSize: 12,
      fontWeight: "400",
      textDecorationLine: "underline",
    },
  },
  sortHeaderItem: { 
    width: "20%",
    "& .sort-btn": {
      background: theme.palette.buttons.default,
      color: theme.palette.buttons.defaultContrast,
      "&.selected": {
        color: theme.palette.buttons.primary,
      }
    }
  },
  select: { padding: "5px !important" },
  dateLabel: { margin: 0 },
  nextButton: {
    width: 70,
    borderWidth: 1,
    padding: "5px 10px",
    outline: "none",
    borderColor: theme.palette.buttons.defaultContrast,
    borderRadius: "0 3px 3px 0",
    borderLeftColor: "transparent",
    borderLeftWidth: 0,
    borderStyle: "solid",
    backgroundColor: theme.palette.buttons.default,
    cursor: "pointer",
  },
  prevButton: {
    width: 70,
    padding: "5px 10px",
    borderWidth: 1,
    outline: "none",
    borderColor: theme.palette.buttons.defaultContrast,
    borderStyle: "solid",
    backgroundColor: theme.palette.buttons.default,
    cursor: "pointer",
    borderRadius: "3px 0 0 3px",
  },
  DateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiIconButton-root ": {
      padding: 0,
    },
  },
  sortHeaderSoloContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      marginRight: 10,
      width: "100%",
      padding: "5px 10px",
    },

    "& .MuiButton-outlinedPrimary": {
      color: theme.palette.buttons.defaultContrast,
      borderColor: theme.palette.buttons.defaultContrast,
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-outlinedSecondary": {
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-textPrimary": {
      color: theme.palette.text.default,
    },
    "& .MuiButton-label": {
      fontSize: 12,
      fontWeight: "600",
      textDecorationLine: "underline",
    },
    "& .MuiButton-outlinedSizeSmall": {
      fontSize: 12,
      fontWeight: "400",
      textDecorationLine: "underline",
    },
  },
  airlines: {
    width: "10%",
    [theme.breakpoints.down(1350)]: {
      width: "13%",
      marginLeft: 5,
    },
  },
  durationContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 50,
    [theme.breakpoints.down(1350)]: {
      marginLeft: 70,
    },
    [theme.breakpoints.down(1090)]: {
      marginLeft: 50,
      width: "39%",
      marginRight: 20,
    },
  },
  durationContainerRound: {
    display: "flex",
    justifyContent: "space-between",
    // marginLeft: 10,
    marginRight: 5,
    width: "40%",

    [theme.breakpoints.down(1350)]: {
      width: "40%",
      marginLeft: 0,
      marginRight: 10,
    },
    [theme.breakpoints.down(1250)]: {
      width: "40%",
      marginLeft: 0,
      marginRight: 10,
    },
  },
  priceRound: {
    marginRight: 5,
  },
}));
