import Collapse from "@material-ui/core/Collapse";
import ReactHtmlParser from "react-html-parser";
import dateFnsFormat from "date-fns/format";
import phoneIcon from "assets/icons/phone_icon.svg";
import envelopeIcon from "assets/icons/envelope.svg";
import FlightDetails from "./FlightDetails";
import WebApi, { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import React, { useContext, useEffect, useState } from "react";
import { AppStateContext } from "layouts/AppStateProvider";
import { localforageGetItem } from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const ReviewDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [onwardFlightDetails, setOnwardFlightDetails] = useState(null);
  const [returnFlightDetails, setReturnFlightDetails] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const { setConfirmation, isConfirmed, setIsConfirmed, setOpen } = useContext(
    AppStateContext
  );

  const [paxDetails, setPaxDetails] = useState(null);

  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const propState = props.history.location.state;
  const requestObj = propState?.request;
  const onwardObj = propState?.onwardResp;
  const returnObj = propState?.returnResp;
  const passengerInfo = propState?.paxInfo;

  console.log(passengerInfo, "PAXINFO");

  // console.log(propState, "PROP STATE-----------------");

  useEffect(() => {
    localforageGetItem("user-details", function(err, details) {
      if (details != null) {
        apiCall(
          WebApi.getCompanyPGOptions,
          {
            partnerId: details.partnerId,
            serviceId: 1,
          },
          (response) => {
            console.log(response.data, "PAYMENTOPTIONS FROM REVIEWDETAILS");
            setPaymentOptions(response.data);
            setIsLoading(false);
          }
        );
      }
    });

    // console.log("state flight review props", props)

    props.setInitFlag(true, "/flight/booking/review-details");
    const segmentsOnw = onwardObj?.segment;
    const lsOnw = segmentsOnw?.length - 1;

    const depDateRawOnw =
      segmentsOnw && new Date(segmentsOnw[0]?.departDetails.dateTime);
    const arrDateRawOnw =
      segmentsOnw && new Date(segmentsOnw[lsOnw]?.arriveDetails.dateTime);
    const flightCodeOnw =
      segmentsOnw && segmentsOnw[0]?.carrierDetails.carrierCode;
    const fareObjOnw = onwardObj.fares[0];

    let totalLayoverOnw = 0;
    let stopAirportsOnw = "";
    let totalJourneyTimeOnw = onwardObj.journeyTime;

    segmentsOnw &&
      segmentsOnw.map((value, index) => {
        totalLayoverOnw += parseInt(value.layover);
        if (index > 0) {
          const sep = segmentsOnw.length - 1 === index ? "" : ", ";
          stopAirportsOnw += value.departDetails.airportCode + sep;
        }
      });

    setOnwardFlightDetails({
      flightCode: flightCodeOnw,
      airLineName: segmentsOnw && segmentsOnw[0]?.carrierDetails.carrierName,
      flightNumber: segmentsOnw && segmentsOnw[0]?.carrierDetails.flightNumber,
      depAirportName: segmentsOnw && segmentsOnw[0]?.departDetails.airportName,
      depAirportCode: segmentsOnw && segmentsOnw[0]?.departDetails.airportCode,
      departTime: dateFnsFormat(depDateRawOnw, "HH:mm"),
      departDate: segmentsOnw && dateFnsFormat(depDateRawOnw, "dd MMM"),
      departDay: segmentsOnw && days[depDateRawOnw.getDay()],
      arrAirportName:
        segmentsOnw && segmentsOnw[lsOnw]?.arriveDetails.airportName,
      arrAirportCode:
        segmentsOnw && segmentsOnw[lsOnw]?.arriveDetails.airportCode,
      arrivalTime: dateFnsFormat(arrDateRawOnw, "HH:mm"),
      arrivalDate: segmentsOnw && dateFnsFormat(arrDateRawOnw, "dd MMM"),
      arrivalDay: segmentsOnw && days[arrDateRawOnw.getDay()],
      ruleArray: fareObjOnw && fareObjOnw?.rule?.split("|"),
      layover:
        totalLayoverOnw > 0
          ? (Math.floor(totalLayoverOnw / 60) > 0
              ? Math.floor(totalLayoverOnw / 60) + " hr "
              : "") +
            Math.floor(totalLayoverOnw % 60) +
            " min"
          : 0,
      journeyTime:
        totalJourneyTimeOnw > 0
          ? Math.floor(totalJourneyTimeOnw / 60) +
            " hr " +
            Math.floor(totalJourneyTimeOnw % 60) +
            " min"
          : 0,
      stopAirports: stopAirportsOnw,
    });

    if (
      (requestObj?.mode == "ROUND" || requestObj?.mode == "ROUND-SP") &&
      returnObj != null
    ) {
      const segmentsRet = returnObj?.segment;
      const lsRet = segmentsRet?.length - 1;

      const depDateRawRet =
        segmentsRet && new Date(segmentsRet[0]?.departDetails.dateTime);
      const arrDateRawRet =
        segmentsRet && new Date(segmentsRet[lsRet]?.arriveDetails.dateTime);
      const flightCodeRet =
        segmentsRet && segmentsRet[0]?.carrierDetails.carrierCode;
      const fareObjRet = returnObj.fares[0];

      let totalLayoverRet = 0;
      let stopAirportsRet = "";
      let totalJourneyTimeRet = returnObj.journeyTime;

      segmentsRet &&
        segmentsRet.map((value, index) => {
          // totalJourneyTimeRet += parseInt(value.flightTime);
          totalLayoverRet += parseInt(value.layover);
          if (index > 0) {
            const sep = segmentsRet.length - 1 === index ? "" : ", ";
            stopAirportsRet += value.departDetails.airportCode + sep;
          }
        });

      setReturnFlightDetails({
        flightCode: flightCodeRet,
        airLineName: segmentsRet && segmentsRet[0]?.carrierDetails.carrierName,
        flightNumber:
          segmentsRet && segmentsRet[0]?.carrierDetails.flightNumber,
        depAirportName:
          segmentsRet && segmentsRet[0]?.departDetails.airportName,
        depAirportCode:
          segmentsRet && segmentsRet[0]?.departDetails.airportCode,
        departTime: dateFnsFormat(depDateRawRet, "HH:mm"),
        departDate: segmentsRet && dateFnsFormat(depDateRawRet, "dd MMM"),
        departDay: segmentsRet && days[depDateRawRet.getDay()],
        arrAirportName:
          segmentsRet && segmentsRet[lsRet]?.arriveDetails.airportName,
        arrAirportCode:
          segmentsRet && segmentsRet[lsRet]?.arriveDetails.airportCode,
        arrivalTime: dateFnsFormat(arrDateRawRet, "HH:mm"),
        arrivalDate: segmentsRet && dateFnsFormat(arrDateRawRet, "dd MMM"),
        arrivalDay: segmentsRet && days[arrDateRawRet.getDay()],
        ruleArray: fareObjRet && fareObjRet?.rule?.split("|"),
        layover:
          totalLayoverRet > 0
            ? (Math.floor(totalLayoverRet / 60) > 0
                ? Math.floor(totalLayoverRet / 60) + " hr "
                : "") +
              Math.floor(totalLayoverRet % 60) +
              " min"
            : 0,
        journeyTime:
          totalJourneyTimeRet > 0
            ? Math.floor(totalJourneyTimeRet / 60) +
              " hr " +
              Math.floor(totalJourneyTimeRet % 60) +
              " min"
            : 0,
        stopAirports: stopAirportsRet,
      });
    }

    const paxInfoArray = [];

    let sl = 1;

    console.log(passengerInfo, "'PASSNEGERINFo");

    passengerInfo &&
      passengerInfo.adultPax &&
      passengerInfo.adultPax.map((pax) => {
        paxInfoArray.push({
          sr: sl,
          title: pax.initial,
          firstname: pax.firstname,
          lastname: pax.surname,
          dob: requestObj.isDomestic == true ? "" : pax.dobDisplay,
          paxType: "Adult",
          studentDocNumber: pax.studentId,
        });
        sl++;
      });

    if (
      passengerInfo &&
      passengerInfo.childPax &&
      passengerInfo.childPax.length > 0
    ) {
      passengerInfo &&
        passengerInfo.childPax &&
        passengerInfo.childPax.map((pax) => {
          paxInfoArray.push({
            sr: sl,
            title: pax.initial,
            firstname: pax.firstname,
            lastname: pax.surname,
            dob: pax.dobDisplay,
            paxType: "Child",
          });
          sl++;
        });
    }

    if (
      passengerInfo &&
      passengerInfo.infantPax &&
      passengerInfo.infantPax.length > 0
    ) {
      passengerInfo &&
        passengerInfo.infantPax &&
        passengerInfo.infantPax.map((pax) => {
          paxInfoArray.push({
            sr: sl,
            title: pax.initial,
            firstname: pax.firstname,
            lastname: pax.surname,
            dob: pax.dobDisplay,
            paxType: "Infant",
          });
          sl++;
        });
    }
    console.log(paxInfoArray, "PAXINFOARRAY");
    setPaxDetails(paxInfoArray);

    props.setCalcFlightConv({
      flag: "revcalc",
      onwConvenienceFees: 0,
      retConvenienceFees: 0,
    });

    // console.log("paxInfoArray", paxInfoArray);
  }, []);

  useEffect(() => {
    if (isConfirmed) {
      setIsConfirmed(false);
      const url = "/flight/booking/payment-details";

      props.history.push({
        pathname: url,
        state: {
          ...propState,
          paymentOptions: paymentOptions,
          convFeesAdded: true,
        },
      });
      props.setInitFlag(true, url);
    }
  }, [isConfirmed]);

  const reviewDetailsSubmit = () => {
    setConfirmation("Are you sure want to proceed?");
    setOpen(true);
  };

  const getLayoverTime = (segmentArray, index) => {
    return segmentArray[index].layover;
  };

  return (
    <>
      <div className="section-container">
        <div className="section-title">
          <Typography className="heading">Flight Details</Typography>
        </div>
        <div className="accordion__section">
          {/* <FlightTravelDetails flightDetailsData={SeatsMealBagData} {...props} /> */}
          <FlightDetails {...props} />
          {/* <Grid container className="button-holder">
                        <Button className="action-button" onClick={() => setExpanded('panel2')}>
                            Next
                        </Button>
                    </Grid> */}
        </div>
      </div>

      <div className={classes.section}>
        <Grid container className="section-container">
          <Grid item md={12} sm={12} xs={12} className="section-title">
            <Typography>Passengers</Typography>
          </Grid>

          <Grid item md={12} sm={12} xs={12} className="section-content">
            <table className="pax-table">
              <thead>
                <tr className="pax-thead">
                  <th>Sr</th>
                  <th>Title</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th className="dob-date">D.O.B.</th>
                  <th>Pax Type</th>
                </tr>
              </thead>

              <tbody>
                {paxDetails &&
                  paxDetails.map((pax, index) => (
                    <tr className="pax-tbody" key={index}>
                      <td>{pax.sr}</td>
                      <td>{pax.title}</td>
                      <td>{pax.firstname}</td>
                      <td>{pax.lastname}</td>
                      <td className="dob-date">
                        {pax.dob != "" && pax.dob != null
                          ? dateFnsFormat(pax.dob, "dd/MM/yyyy")
                          : ""}
                      </td>
                      <td>{pax.paxType}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>

      <div className={classes.section}>
        <Grid container className="section-container">
          <Grid item md={12} sm={12} xs={12} className="section-title">
            <Typography>Contact Details</Typography>
          </Grid>

          <Grid item md={12} sm={12} xs={12} className="section-content">
            <div className="contact-info">
              <div>
                <img src={phoneIcon} style={{ height: 14 }} />
              </div>
              <div>
                {passengerInfo && passengerInfo.country_dial_code}{" "}
                {passengerInfo && passengerInfo.phoneno}
              </div>
            </div>

            <div className="contact-info">
              <div>
                <img src={envelopeIcon} />
              </div>
              <div>{passengerInfo && passengerInfo.emailUser}</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Grid container className="button-holder">
          {!isLoading && (
            <button
              className="action-button"
              onClick={() => reviewDetailsSubmit()}
            >
              Proceed to Pay
            </button>
          )}
        </Grid>
      </div>
    </>
  );
};

export default ReviewDetails;

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: 20,
    marginBottom: 20,
    // paddingLeft: "3vw",
    [theme.breakpoints.down(960)]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
    [theme.breakpoints.down(370)]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    "& .flightInfoContainer": {
      [theme.breakpoints.down(480)]: {
        justifyContent: "space-between",
      },
    },
    "& .flight-identity": {
      padding: "10px 0",
      "& img": {
        height: 40,
      },
    },
    "& .departure-section": {
      padding: "10px 0",
    },
    "& .arrival-section": {
      padding: 10,
    },
    "& .duration-section": {
      padding: 10,
      width: 200,
      [theme.breakpoints.down(480)]: {
        width: 100,
      },
    },
    "& .dash-line": {
      background: theme.palette.primary.darkText,
      height: 1,
      width: "100%",
      margin: "10px 0",
    },
    "& .font1": {
      "& p": {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "20px",
      },
    },
    "& .font2": {
      "& p": {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "20px",
      },
    },
    "& .font3": {
      "& p": {
        fontSize: 13,
        fontWeight: 500,
      },
    },
    "& .font4": {
      "& p": {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: "20px",
      },
    },
    "& .addon-box": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    "& .addon-icon": {
      marginRight: 10,
      "& img": {
        height: 15,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 15,
        marginTop: 2,
        color: theme.palette.primary.defaultText,
      },
    },
    "& .addon-text-p1": {
      fontSize: 13,
    },
    "& .addon-text-p2": {
      fontSize: 11,
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
      [theme.breakpoints.down(480)]: {
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: 15,
      },
    },
    "& .action-button": {
      //background: theme.palette.primary.main,
      background: theme.palette.buttons.tertiary,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.buttons.tertiaryContrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: 22,
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
    "& .airline-tnc": {
      cursor: "pointer",
    },
    "& .section-title": {
      padding: "20px 0",
      "& p": {
        fontSize: 20,
        fontWeight: 600,
      },
    },
    "& .pax-table": {
      width: "100%",
      "& .dob-date": {
        [theme.breakpoints.down(360)]: {
          display: "none",
        },
      },
    },
    "& .pax-thead": {
      "& th": {
        textAlign: "left",
        fontWeight: 600,
        fontSize: 15,
        [theme.breakpoints.down(460)]: {
          fontSize: 13,
        },
      },
    },
    "& .pax-tbody": {
      "& td": {
        textAlign: "left",
        fontWeight: 500,
        fontSize: 15,
        [theme.breakpoints.down(460)]: {
          fontSize: 13,
        },
      },
    },
    "& .contact-info": {
      display: "flex",
      fontWeight: 500,
      fontSize: 14,
      marginBottom: 10,
      "& img": {
        height: 14,
        width: 15,
        marginRight: 30,
      },
    },
    "& .flight-segments": {},
    "& .flight-segment-wrapper": {
      display: "grid",
      gridTemplateColumns: "repeat(3,auto)",
      gap: "6px",
      justifyContent: "left",
      margin: "10px 0",
    },
    "& .flight-icons-wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 20,
    },
    "& .segment-dep-arr-wrapper": {
      display: "flex",
      flexDirection: "row",
    },
    "& .dep-arr-info-wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginRight: "5vw",
    },
    "& .departure-itin": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .arrival-itin": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .itin-separator": {
      height: 20,
    },
    "& .flight-itin-icon": {
      "& img": {
        height: 25,
      },
    },
    "& .flight-itin-separator": {
      height: 30,
      borderRight: `solid 0.5px ${theme.palette.primary.darkText}`,
      margin: "6px 0",
    },
    "& .flight-time": {
      "& .itin-time": {
        fontSize: 18,
        fontWeight: 500,
      },
      "& .itin-date": {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    "& .flight-airport": {
      "& .airport-name": {
        fontSize: 14,
        fontWeight: 500,
        marginRight: 6,
      },
      "& .airport-terminal": {
        fontSize: 11,
        fontWeight: 500,
      },
    },
    "& .farerule-container": {
      height: 250,
      scrollbarWidth: "thin",

      overflowY: "auto",
      paddingRight: 10,
      border: `1px solid #e1e1e1`,

      borderRadius: 5,
      [theme.breakpoints.down(450)]: {
        width: 380,
        height: "90%",
      },
      [theme.breakpoints.down(400)]: {
        width: 335,
      },
    },
    "& .flight-layover-wrapper": {
      display: "grid",
      gridTemplateColumns: "repeat(3,auto)",
      gap: "6px",
      justifyContent: "left",
      margin: "10px 0",
      alignItems: "center",
    },
    "& .segment-separator": {
      height: 100,
      borderRight: `dashed 0.5px ${theme.palette.primary.darkText}`,
      marginLeft: 10,
      marginRight: 25,
    },
    "& .flight-layover": {
      padding: "5px 0",
      fontSize: 12,
      fontWeight: 500,
    },
    "& .airline-journey-time": {
      marginRight: "5vw",
    },
    "& .airline-id-info-wrapper": {
      display: "flex",
      flexDirection: "row",
      margin: "10px 0",
      "& img": {
        height: 30,
        width: 30,
        marginRight: 10,
      },
      "& span": {
        fontSize: 10,
        fontWeight: 500,
      },
      "& .airline-name": {
        fontSize: 13,
        fontWeight: 500,
      },
    },
    "& .flight-time-info-wrapper": {
      fontSize: 12,
      fontWeight: 500,
      "& img": {
        height: 14,
      },
    },
    "& .additional-wrapper": {
      display: "flex",
      flexDirection: "column",
      margin: "6px 0",
      "& img": {
        height: 12,
        width: 20,
      },
      "& span": {
        fontSize: 10,
        fontWeight: 500,
      },
    },
    "& .journey-label": {
      fontSize: 14,
      fontWeight: 500,
      margin: "20px 0",
    },
  },
}));
