import GridContainer from "components/Grid/GridContainer";
import React, { useState, useEffect, useContext } from "react";
//import data from "json/data.json";
import { Divider, Typography } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
// import image from "assets/img/hotel_image.jpg";
import Button from "@material-ui/core/Button";
import Carousel, { consts } from "react-elastic-carousel";
import { currencyFormatter } from "oautils/oaCommonUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withRouter } from "react-router-dom";
import { AppStateContext } from "layouts/AppStateProvider";

import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";

import OfferPage from "./Offer";
import { SERVER_URL } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    "& .MuiOutlinedInput-root ": {
      position: "relative",
      borderRadius: "4px",
      height: "26px",
      background: theme.palette.background.light,
      borderColor: "none",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  Button: {
    backgroundColor: theme.palette.background.cyanBlue,
    fontSize: "10px",
    textTransform: "none",
    color: theme.palette.primary.lightText,
    "&:hover": {
      backgroundColor: theme.palette.background.cyanBlue,
      fontSize: "10px",
      textTransform: "none",
      color: theme.palette.primary.lightText,
    },
  },
  typo: {
    color: theme.palette.primary.pink,
  },
  Grid: {
    marginLeft: "120px",
    marginRight: "120px",
    background: theme.palette.primary.lightText,
    marginTop: "50px",
    boxShadow: `0px 0px 15px -1px ${theme.palette.background.lightFadedGray}`,
    padding: 30,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px !important",
      marginRight: "10px !important",
    },
    // [theme.breakpoints.up('md')]: {
    //   backgroundColor: theme.palette.primary.main,
    // },
  },
  Heading: {
    paddingLeft: "2%",
    marginBottom: "0.5rem",
    fontSize: "30px",
    color: theme.palette.primary.grayishBlue,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  typo1: {
    marginBottom: "0.5rem",
    color: theme.palette.primary.grayishBlue,
    textAlign: "left",
    fontWeight: 700,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.78rem",
    },
  },
  typo2: {
    paddingLeft: "2%",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.78rem",
    },
  },
}));

const HotelPackages = (props) => {
  const classes = useStyles();
  const [country, setCountry] = React.useState("");
  const [pakageCategory, setPackageCategory] = useState([]);
  const { setSecLogo } = useContext(AppStateContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event) => {
    setCountry(event.target.value);
  };
  function HotelDetail(name, packageType, index, advertise, packageId) {
    if (advertise === true) {
      props.history.push("/packages/packageinfo", { data: packageId });
    } else {
      props.history.push({
        pathname: "/packages/details",
        state: { name, packageType, index },
      });
    }
    setSecLogo(true);
  }
  useEffect(() => {
    let today = new Date();

    apiCall(WebApi.getPackageCategory, { date: today }, (response) => {
      if (response.data) {
        setPackageCategory(response.data);
      }
    });
  }, []);

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
    return (
      <p onClick={onClick} disabled={isEdge} className="arrow-crousel">
        {pointer}
      </p>
    );
  }

  // data=pakageCategory
  // console.log(pakageCategory.packagesCategories)
  // console.log(pakageCategory.packagesCategories)

  // console.log(pakageCategory)
  return (
    <GridContainer style={{ paddingBottom: "50px" }}>
      <>
        <OfferPage />
      </>
      {pakageCategory &&
        pakageCategory.map((category) => {
          return (
            <>
              <GridContainer className={classes.Grid}>
                <Grid item md={12} xs={12}>
                  <Typography className={classes.Heading}>
                    {category.packageTitle}
                  </Typography>
                  <Typography
                    style={{ paddingLeft: "2%", marginBottom: "0.5rem" }}
                  >
                    {category.packageDescription}
                  </Typography>
                </Grid>
                <Grid item md={6} container justifyContent="flex-end"></Grid>

                <GridContainer>
                  <Carousel
                    itemsToShow={isMobile ? 1 : 4}
                    itemPadding={[0, 10, 0, 0]}
                    pagination={false}
                    renderArrow={myArrow}
                    style={{ margin: 5 }}
                  >
                    {category.packagesCategories.map((value, index) => (
                      <div>
                        <div
                          style={{
                            height: isMobile ? "" : "303px",
                            boxShadow: "inset 0 0 4px 0px rgba(0, 0, 0, 0.25)",
                            borderRadius: 10,
                            maxWidth: 370,
                          }}
                          onClick={() =>
                            HotelDetail(
                              value.packageCategoryId,
                              category.packageType,
                              index,
                              category.advertise,
                              value.packageId
                            )
                          }
                        >
                          {/* <div style={{backgroundImage:`url(${ "https://www.indianyatri.com/packageadmin/ImageReaderArticle?path=/" +
                            category.packageType +
                            "/" +
                            value.packageCategoryId +
                            "/" +
                            value.packageId +
                            "/" +
                            value.packageCategoryImage})`}}>

                        </div> */}

                          <img
                            src={
                              SERVER_URL +
                              "packageadmin/ImageReaderArticle?path=/" +
                              category.packageType +
                              "/" +
                              value.packageCategoryId +
                              "/" +
                              value.packageId +
                              "/" +
                              value.packageCategoryImage
                            }
                            style={{
                              borderRadius: isMobile
                                ? "10px 10px 0px 0px"
                                : "10px 0px 50px 0px",
                              cursor: "pointer",
                              width: isMobile ? "100%" : "50%",
                              height: isMobile ? "100%" : "60%",
                            }}
                          />

                          <Grid
                            container
                            justifyContent="space-between"
                            style={{
                              paddingTop: 30,
                              height: "40%",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            <Grid item md={12}>
                              <Typography className={classes.typo1}>
                                {" "}
                                {value.packageCategoryName.split(",")[0]}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  color: theme.palette.secondary.main,
                                }}
                              >
                                {category.advertise === true
                                  ? value.packageInfo
                                  : `${value.packageCategoryCount} Options`}{" "}
                                - Starts from{" "}
                                {currencyFormatter(
                                  value.packageCategoryStartingPrice
                                )}{" "}
                                Per Person
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </GridContainer>
              </GridContainer>
            </>
          );
        })}
    </GridContainer>
  );
};

export default withRouter(HotelPackages);
