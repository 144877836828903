import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme, makeStyles } from '@material-ui/core';

const OaLoading = (props) => {
    
    const theme = useTheme();
    const classes = useStyles();
    const [loaderText, setLoaderText] = useState("Loading...");

    useEffect(() => {
        if (props.loadertext && props.loadertext != null && props.loadertext != "") {
            setLoaderText(props.loadertext);
        }
    }, []);

    return (
        <div className={classes.root}>
            <CircularProgress size={20} className="loader"/> 
            <span className="loading-text">{loaderText}</span> 
        </div>
    )
}

export default OaLoading;

const useStyles = makeStyles(theme => ({
    root: {
        padding: "20px 0 0 0", 
        display: "inline-block", 
        fontWeight: "600", 
        color: theme.palette.primary.main,
        "& .loader": {
            padding: 0,
        },
        "& .loading-text": {
            display: "inline-block", verticalAlign: "top", fontSize: "16px", marginLeft: "5px"
        },
        "& .MuiCircularProgress-svg": {
            color: theme.palette.primary.main,
            "& circle": {
                boxShadow: `0px 0px 5px 0px rgba(${theme.palette.rgb.secondary},1)`,
            },
        },
    },
}));
