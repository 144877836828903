import React from "react";
import OaCard from 'oahoc/OaCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import * as Yup from 'yup';
import OaPopperAlert from "oahoc/OaPopperAlert";
import WebApi, { GET_FILE_UPLOAD } from "api/ApiConstants";
import { makeStyles, useTheme } from "@material-ui/core";
import { Formik, Form, Field, FieldArray } from 'formik';

const CommissionAndChargeForm = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    
    const [severity, setSeverity] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const [alertMessage, setAlertMessage] = React.useState("");
    const [collapsed, setCollapsed] = React.useState(true);
    const [supplier, setSupplier] = React.useState([]);
    const [service, setService] = React.useState([]);
    const [category, setCategory] = React.useState([]);
    const [subCategory, setSubCategory] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [commission, setCommission] = React.useState([]);
    const [supplierId, setSupplierId] = React.useState(0);
    const [serviceId, setServiceId] = React.useState(0);
    const [categoryId, setCategoryId] = React.useState(0);
    const [userId, setUserId] = React.useState(props.agentId == undefined ? 0:props.agentId);

    function collapse(){
        collapsed ? setCollapsed(false) : setCollapsed(true);
    }

    const populateAllDD = (data) => {
        let supplier = data, service;
        for (let i = 0; i < supplier.length; i++) {
            if (supplier[i].id == 7) {
                setService(supplier[i].service);

                setSupplierId(supplier[i].id);
                service = supplier[i].service;

                for (let j = 0; j < service.length; j++) {
                    if (service[j].id == 24) {
                        setServiceId(service[j].id);
                        service[j].category.sort(function (a, b) {
                            if (a.id < b.id) return -1;
                            if (a.id > b.id) return 1;
                            return 0;
                        });

                        setCategoryId(service[j].category[0].id);
                        setCategory(service[j].category);
                        setSubCategoryData(service[j].category, 0, supplier[i].id, service[j].id, service[j].category[0].id);
                    }
                }

            }
        }
    }

    const submitCommission = (values) => {
        WebApi.updateDistributorCommissionCharge(values, (response) => {
            setAlertMessage(response.data.message);
            setSeverity({
                ...severity,
                issuccess: response.data.success,
                iserror: !response.data.success
            });
        }, error => { 
            console.log("error submitting commission modifications", error);
            setAlertMessage("Failed submitting commision details");
            setSeverity({
                ...severity,
                iserror: true
            });
        });
    }

    const supplierOnChange=function(value){
        for (let i = 0; i < supplier.length; i++) {
            if (supplier[i].id == value) {
                setService(supplier[i].service);

                setSupplierId(supplier[i].id);
            }
        }
    }

    const serviceOnChange=function(value){
        for (let i = 0; i < service.length; i++) {
            if (service[i].id == value) {
                setServiceId(service[i].id);
                service[i].category.sort(function(a, b) {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                  });
                setCategoryId(service[i].category[0].id);
                setCategory(service[i].category);
                setSubCategoryData(service[i].category, 0, supplierId, service[i].id, service[i].category[0].id);
            }
        }
    }

    const categoryOnChange=function(event, newTabIndex){
        setValue(newTabIndex);

        setCategoryId(category[newTabIndex].id);
        
        setSubCategoryData(category, newTabIndex, supplierId, serviceId, category[newTabIndex].id);
    }

    const setSubCategoryData =(category, newTabIndex, supplierId, serviceId, categoryId) => {
        setIsLoading(true);
        
        WebApi.getDistributorCommissionCharge({
            "categoryId": category[newTabIndex].id,
            "serviceId": serviceId,
            "supplierId": supplierId,
            "userId": userId
        }, (response) => {
            
            if (response.success) {
                let subCategories = category[newTabIndex].subCategory;

                subCategories.sort(function(a, b) {
                  if (a.id < b.id) return -1;
                  if (a.id > b.id) return 1;
                  return 0;
                });

                let commissionCharge =[];

                for (let index = 0; index < subCategories.length; index++) {
                    let commissionIndex = response.data.findIndex((obj => obj.subCategoryId == subCategories[index].id))
                    let companyToDistributor = response.data[commissionIndex];
                    subCategories[index].companyToDistributor = companyToDistributor;
                    if(companyToDistributor)
                        commissionCharge.push(new Commission(companyToDistributor.id, supplierId, serviceId, categoryId, subCategories[index].id, userId, companyToDistributor.chargePercentage, companyToDistributor.chargeFixed, companyToDistributor.chargePercentageMinimum, companyToDistributor.chargeFixedMinimum, subCategories[index].subCategoryName) );
                    else
                       commissionCharge.push(new Commission(null, supplierId, serviceId, categoryId, subCategories[index].id, userId, null, null, null, null, subCategories[index].subCategoryName) );
                }

                // console.log(subCategories)
                setCommission(commissionCharge);
                setSubCategory(subCategories);

                setIsLoading(false);
            } else { console.log("failed getting commission charge") }
        }, (error) => {
            console.log("error getting commission charge", error);
        });
    }

    React.useEffect(() => {
        setIsLoading(true);

        WebApi.getSupplier({}, (response) => {
            if (response.success) {
                setSupplier(response.data);
                populateAllDD(response.data);
            }
            setIsLoading(false);
        }, error => { 
            console.log("error getting supplier", error) 
            setAlertMessage("Failed to fetch supplier");
            setSeverity({
                ...severity,
                iserror: true
            });
        });
    }, []);

    return (
        <>
            <OaCard style={{ display: 'none' }}>
                <h4 className={"oa-form-header"} onClick={collapse} style={{ transform: ' scale(0.85)', transformOrigin: 'left' }} >Search commission</h4>
                <Collapse in={collapsed}>
                    <Formik>
                        <GridContainer>
                            <OaFormSelectField
                                label="Supplier"
                                name="supplierId"
                                options={supplier}
                                optionValue={'id'}
                                optionLabel={'supplierName'}
                                onChange={(value) => {
                                    supplierOnChange(value)
                                }}
                                md={4}
                            />
                            {service.length > 0 &&
                                <OaFormSelectField
                                    label="Service"
                                    name="serviceId"
                                    options={service}
                                    optionValue={'id'}
                                    optionLabel={'serviceDescription'}
                                    onChange={(value) => {
                                        serviceOnChange(value)
                                    }}
                                    md={4}
                                />
                            }
                        </GridContainer>
                    </Formik>
                </Collapse>
            </OaCard>

            {category.length > 0 &&
                <OaCard>
                    <AppBar position="static" color="default" className="oa-tabs-primary">
                        <Tabs
                            value={value}
                            onChange={categoryOnChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {category.map((category, index) => {
                                return (<Tab icon={<img className="plane-icon" src={GET_FILE_UPLOAD + "?fileName=" + category.icon + "&type=EMAIL_IMAGES"} />}
                                    label={category.categoryName}   {...a11yProps(index)} />)
                            })}
                        </Tabs>
                    </AppBar>

                    {category.map((category, index) => {
                        return (<TabPanel value={value} index={index}>
                            {subCategory.length > 0 && <>
                                <GridContainer style={{ borderBottom: `solid ${theme.palette.secondary.darkShadedGray}`, padding: 4 }}>

                                    <GridItem md={4}  ></GridItem>

                                    <GridItem md={8}>
                                        <GridContainer >
                                            <GridItem md={12} align={'center'} className="oa-head-primary">Markup<span style={{ paddingBottom: 10 }}>*</span></GridItem>
                                        </GridContainer>
                                        <GridContainer >
                                            <GridItem md={6} align={'center'} className="oa-head-primary">Variable(%)</GridItem>
                                            <GridItem md={6} align={'center'} className="oa-head-primary">Fixed(&#x20b9;)</GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>

                                {!isLoading &&
                                    <Formik
                                        initialValues={{ commission: commission }}
                                        validationSchema={validateSchema}
                                        onSubmit={submitCommission}
                                        render={({ values, errors, touched, handleReset }) => {
                                            return (
                                                <Form>

                                                    <input type="text" name="testHidden" hidden />

                                                    <FieldArray
                                                        name="commission"
                                                        render={({ }) => (
                                                            <>
                                                                {values.commission.length > 0 &&
                                                                    values.commission.map((commission, index) => (

                                                                        <GridContainer key={index} style={{ backgroundColor: index % 2 === 0 ? '#efefef' : '#ffffff', padding: "10px" }} className="vertical-center-commision">
                                                                            <GridItem md={4} align={'left'} >
                                                                                {commission.subCategoryName}

                                                                                <Field
                                                                                    className="form-control"
                                                                                    name={`commission.${index}.id`}
                                                                                    placeholder="id"
                                                                                    type="input"
                                                                                    style={{ display: 'none' }}
                                                                                />

                                                                                <Field
                                                                                    className="form-control"
                                                                                    name={`commission.${index}.supplierId`}
                                                                                    placeholder="supplierId"
                                                                                    type="input"
                                                                                    style={{ display: 'none' }}
                                                                                />

                                                                                <    Field
                                                                                    className="form-control"
                                                                                    name={`commission.${index}.serviceId`}
                                                                                    placeholder="serviceId"
                                                                                    type="input"
                                                                                    style={{ display: 'none' }}
                                                                                />


                                                                                <Field
                                                                                    className="form-control"
                                                                                    name={`commission.${index}.categoryId`}
                                                                                    placeholder="categoryId"
                                                                                    type="input"
                                                                                    style={{ display: 'none' }}
                                                                                />


                                                                                <Field
                                                                                    className="form-control"
                                                                                    name={`commission.${index}.subCategoryId`}
                                                                                    placeholder="subCategoryId"
                                                                                    type="input"
                                                                                    style={{ display: 'none' }}
                                                                                />


                                                                                <Field
                                                                                    className="form-control"
                                                                                    name={`commission.${index}.userId`}
                                                                                    placeholder="userId"
                                                                                    type="input"
                                                                                    style={{ display: 'none' }}
                                                                                />
                                                                            </GridItem>

                                                                            <GridItem md={8}>
                                                                                <GridContainer >
                                                                                    <GridItem md={6} >
                                                                                        <GridContainer >
                                                                                            <GridItem md={12} >
                                                                                                <OaFormTextField name={`commission.${index}.chargePercentage`}
                                                                                                    style={{ display: values.commission[index].chargePercentageMinimum === null ? 'none' : '' }}
                                                                                                    placeholder='Percentage'
                                                                                                />

                                                                                                {errors &&
                                                                                                    errors.commission &&
                                                                                                    errors.commission[index] &&
                                                                                                    errors.commission[index].chargePercentage &&
                                                                                                    touched &&
                                                                                                    touched.commission &&
                                                                                                    touched.commission[index] &&
                                                                                                    touched.commission[index].chargePercentage && (
                                                                                                        <div className="field-error">
                                                                                                            {errors.commission[index].chargePercentage}
                                                                                                        </div>
                                                                                                    )}
                                                                                            </GridItem>
                                                                                        </GridContainer >

                                                                                    </GridItem>
                                                                                    <GridItem md={6} >
                                                                                        <GridContainer >
                                                                                            <GridItem md={12} >
                                                                                                <OaFormTextField name={`commission.${index}.chargeFixed`}
                                                                                                    style={{ display: values.commission[index].chargeFixedMinimum === null ? 'none' : '' }}
                                                                                                    placeholder='Fixed'
                                                                                                />

                                                                                                {errors &&
                                                                                                    errors.commission &&
                                                                                                    errors.commission[index] &&
                                                                                                    errors.commission[index].chargeFixed &&
                                                                                                    touched &&
                                                                                                    touched.commission &&
                                                                                                    touched.commission[index] &&
                                                                                                    touched.commission[index].chargeFixed && (
                                                                                                        <div className="field-error">
                                                                                                            {errors.commission[index].chargeFixed}
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </GridItem>
                                                                                        </GridContainer >
                                                                                    </GridItem>
                                                                                </GridContainer>
                                                                            </GridItem>
                                                                        </GridContainer>

                                                                    ))}

                                                            </>
                                                        )}
                                                    />


                                                    <div style={{ marginLeft: -15, marginTop: 10 }}>
                                                        <Button variant="contained" color="primary"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                handleReset();
                                                            }}
                                                        >
                                                            Reset
                                                        </Button>
                                                        <Button variant="contained" color="primary" type="submit" className="btn btn-block btn-primary">
                                                            Submit
                                                        </Button>
                                                        <br />*When fixed markup and variable markup both are given, we prefer fixed markup in place of variable.
                                                    </div>

                                                </Form>
                                            );
                                        }}
                                    />
                                }

                            </>}
                        </TabPanel>)
                    })}
                </OaCard>
            }

            <OaPopperAlert
                alertmessage={alertMessage}
                severity={severity}
                setseverity={setSeverity}
            />
        </>
    );
};

export default CommissionAndChargeForm;

export class Commission {
    constructor(id, supplierId, serviceId, categoryId, subCategoryId,
        userId, chargePercentage, chargeFixed,
        chargePercentageMinimum, chargeFixedMinimum, subCategoryName) {
        this.id = id;

        this.chargePercentage = chargePercentage;
        this.chargeFixed = chargeFixed;

        this.chargePercentageMinimum = chargePercentageMinimum;
        this.chargeFixedMinimum = chargeFixedMinimum;

        this.supplierId = supplierId;
        this.serviceId = serviceId;
        this.categoryId = categoryId;
        this.subCategoryId = subCategoryId;
        this.userId = userId;
        this.subCategoryName = subCategoryName;
    }
}

export const validateSchema = Yup.object().shape({
    commission: Yup.array()
        .of(
            Yup.object().shape({
                chargePercentage: Yup.number()
                    .nullable(true)
                    .when('chargePercentageMinimum', {
                        is: (chargePercentage) => chargePercentage != null,
                        then: Yup.number().required('Charge variable is required').typeError('You must specify a number'),
                        otherwise: Yup.number().typeError('You must specify a number')
                    })
                // .test(
                //     "min_charge_percentage_test",
                //     "Invalid charge",
                //     function() {
                //       const { chargePercentage,chargePercentageMinimum } = this.parent;
                //       return chargePercentage >= chargePercentageMinimum  || chargePercentageMinimum === null;
                // })
                ,

                chargeFixed: Yup.number()
                    .nullable(true)
                    .when('chargeFixedMinimum', {
                        is: true, is: (chargeFixedMinimum) => chargeFixedMinimum != null,
                        then: Yup.number().required('Charge fixed is required').typeError('You must specify a number'),
                        otherwise: Yup.number().typeError('You must specify a number')
                    })
                // .test(
                //     "min_charge_fixed_test",
                //     "Invalid charge",
                //     function() {
                //       const { chargeFixed,chargeFixedMinimum } = this.parent;
                //       return chargeFixed >= chargeFixedMinimum  || chargeFixedMinimum === null;
                // })
                ,
            })
        )
        .min(1, "Need at least a commission")
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: 50,
        backgroundColor: theme.palette.background.paper,
        "& .field-error": {
            color: theme.palette.primary.danger
        },
        "& .vertical-center-commision": {
            display: "flex",
            alignItems: "center"
        }
    },
}));
