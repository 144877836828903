import React from "react";
import dateFnsFormat from "date-fns/format";
import { makeStyles } from "@material-ui/core";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import { ArrowForward } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const FlightItinerary = (props) => {
    const classes = useStyles();
    const history = useHistory();

    return(
        props.flightdata &&
        <>
            <div className={classes.root}>
                <div className="itin-group">
                    <div className="itin-header">
                        <span>Flight Details (onward)</span>
                    </div>

                    {props.flightdata.onwardJourney && props.flightdata.onwardJourney.map((journey, idx) =>
                        <div className="itin-row" key={idx}>
                            <div className="itin-col-20">
                                <div className="carrier-identity">
                                    <div className="carrier-icon">
                                        <img src={BASE_URL_IMAGE_VIEW + "agency/view-file?fileName=" + journey.flightName + ".png&type=AIRLINE_IMAGES"} alt={journey.flightName + ' icon'} />
                                    </div>
                                    <div className="carrier-name">
                                        <span>{journey.carrierName}</span>
                                        <span>{journey.flightName}-{journey.flightCode}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="itin-col-80 itin-row carrier-itin">
                                <div className="itin-col-3 carrier-depart">
                                    <p className="bold">{journey.originCityName} (<span>{journey.departureStation}</span>)</p>
                                    <p>{journey.originAirportDesc}</p>
                                    <p>{dateFnsFormat(new Date(journey.departureDateTimeRaw), "do MMM yyyy, HH:mm")} hr</p>
                                </div>

                                <div className="itin-col-3 carrier-arrow">
                                    <p>{journey.journeyTime}</p>
                                    <ArrowForward/>
                                </div>

                                <div className="itin-col-3 carrier-arrive">
                                    <p className="bold">{journey.destinationCityName} (<span>{journey.arrivalStation}</span>)</p>
                                    <p>{journey.destinationAirpotDesc}</p>
                                    <p>{dateFnsFormat(new Date(journey.arrivalDateTimeRaw), "do MMM yyyy, HH:mm")} hr</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {props.flightdata.returnJourney && props.flightdata.returnJourney.length > 0 &&
                <div className="itin-group">
                    <div className="itin-header">
                        <span>Flight Details (return)</span>
                    </div>

                    {props.flightdata.returnJourney && props.flightdata.returnJourney.map((journey, idx) =>
                        <div className="itin-row" key={idx}>
                            <div className="itin-col-20">
                                <div className="carrier-identity">
                                    <div className="carrier-icon">
                                        <img src={BASE_URL_IMAGE_VIEW + "agency/view-file?fileName=" + journey.flightName + ".png&type=AIRLINE_IMAGES"} alt={journey.flightName + ' icon'} />
                                    </div>
                                    <div className="carrier-name">
                                        <span>{journey.carrierName}</span>
                                        <span>{journey.flightName}-{journey.flightCode}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="itin-col-80 itin-row carrier-itin">
                                <div className="itin-col-3 carrier-depart">
                                    <p className="bold">{journey.originCityName} (<span>{journey.departureStation}</span>)</p>
                                    <p>{journey.originAirportDesc}</p>
                                    <p>{dateFnsFormat(new Date(journey.departureDateTimeRaw), "do MMM yyyy, HH:mm")} hr</p>
                                </div>

                                <div className="itin-col-3 carrier-arrow">
                                    <p>{journey.journeyTime}</p>
                                    <ArrowForward/>
                                </div>

                                <div className="itin-col-3 carrier-arrive">
                                    <p className="bold">{journey.destinationCityName} (<span>{journey.arrivalStation}</span>)</p>
                                    <p>{journey.destinationAirpotDesc}</p>
                                    <p>{dateFnsFormat(new Date(journey.arrivalDateTimeRaw), "do MMM yyyy, HH:mm")} hr</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>}

                <div className="itin-group itin-row">
                    <div className="itin-col-50">
                        <div className="itin-header">
                            <span>Passenger Details</span>
                        </div>

                        <div className="pax-group table-container">
                            {props.flightdata.passengers && props.flightdata.passengers.length > 0 &&
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        {history.location.pathname !== "/flight/booking/payment-success" 
                                        && <th>DOB</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.flightdata.passengers.map((pax, idx) =>
                                        <tr key={idx}>
                                            <td>{(idx+1)}</td>
                                            <td class="name-column">{pax.title} {pax.firstName} {pax.lastName}</td>
                                            <td>{pax.type}</td>
                                            <td>{pax.dob && pax.dob !== ""  ? dateFnsFormat(new Date(pax.dob), "do MMM yyyy") : ""}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>}
                        </div>
                    </div>

                    <div className="itin-col-50">
                        <div className="itin-header">
                            <span>Other Details</span>
                        </div>

                        <div className="pax-group other-details">
                            <p>Contact Number: <span>{props.flightdata.mobileNumber}</span></p>
                            <p>Email: <span>{props.flightdata.emailId}</span></p>
                            <p>
                                Booking Status: 
                                <span>
                                    {
                                        (
                                            props.flightdata.bookingStatus === 'SUCCESSCan' || 
                                            props.flightdata.bookingStatus === 'SUCCESS' ||
                                            props.flightdata.bookingStatus === 'pending'
                                        ) ?
                                        'Not Confirmed' : 'Confirmed'
                                    }
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlightItinerary;

const useStyles = makeStyles(theme => ({
    root: {
        "& p": {
            fontWeight: 400,
            fontSize: 13,
            margin: '0 0 0 0',
            "&.bold": {
                fontWeight: 700
            }
        },
        "& .itin-group": {
            margin: '25px 0',
            border: `solid 1px ${theme.palette.primary.disabled}`,
            padding: '10px 20px',
            borderRadius: 5
        },
        "& .itin-header": {
            margin: "5px 0 15px",
            "& span": {
                fontWeight: 600,
                fontSize: '1.1rem'
            }
        },
        "& .itin-row": {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            [theme.breakpoints.down(768)]: {
                flexWrap: 'wrap'
            },
        },
        "& .itin-col-3": {
            width: "33.3%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-4": {
            width: "25%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-20": {
            width: "20%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-30": {
            width: "30%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-40": {
            width: "40%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-50": {
            width: "50%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-60": {
            width: "60%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-70": {
            width: "70%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-80": {
            width: "80%",
            [theme.breakpoints.down(768)]: {
                width: "100%"
            }
        },
        "& .itin-col-100": {
            width: "100%",
        },
        "& .carrier-identity": {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            "& .carrier-icon": {
                margin: '0 8px 0 0',
                "& img": {
                    height: 40
                }
            },
            "& .carrier-name": {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                fontWeight: 500,
            },
        },
        "& .carrier-itin": {
            "& .carrier-arrow": {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 16,
                [theme.breakpoints.down(768)]: {
                    paddingBottom: 16
                }
            },
            "& .carrier-depart, .carrier-arrive": {
                textAlign: 'center',
            }
        },
        "& .table-container": {
            marginBottom: 20,
            "& table": {
                padding: 0,
                width: "50%",
                [theme.breakpoints.down(1500)]: {
                    width: "70%",
                },
                [theme.breakpoints.down(992)]: {
                    width: "90%",
                    overflowX: 'auto'
                }
            },
            "& tr": {
                margin: "5px 0"
            },
            "& td": {
                fontWeight: 500
            },
            "& td, th": {
                padding: "0 10px 0 0",
                textAlign:"left",
                fontSize: '0.83rem',
                [theme.breakpoints.down(768)]: {
                    verticalAlign: 'text-top'
                }
            }
        },
        "& .other-details": {
            "& span": {
                fontWeight: 600,
                marginLeft: 10
            }
        },
        "& .name-column": {
            width: 600,
            [theme.breakpoints.down(1200)]: {
                width: 300
            },
            [theme.breakpoints.down(768)]: {
                width: 'auto'
            }
        }
    }
}));
