import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    

    "& h3,p":{
      margin:0,
    },


    "& .offer-card":{
      width:'236px',
      border: "1px solid #bcc8d1",
      boxShadow: "0px 2px 4px 0px rgb(0 0 0 / 15%)",
      borderRadius: "4.6px",

       "& .p-img":{
        width:'100%',
        height: '163px',
        "& img":{
          maxWidth: "100%",
          height:'100%',
        },
       },

       "& .txt-content":{
          // height:'123px',
          padding:'10px',
          "& .offer-heading":{
            "& h3":{
              fontWeight:400,
              fontSize:17,
            }
          },

          "& .offer-desc":{
            fontSize: '12px',
            padding: "7px 0 11px",
            fontWeight:400,
            lineHeight:1.3,
          },

          "& .pCode-n-valid":{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            // gap:'10px',
            "& .p-code-sec":{
             width:'37%',
             padding:"1px 2px",
             fontSize:11,
             border:`1.3px dashed ${theme.palette.secondary.main}`,
             textAlign: 'center',
             float:'left',
            lineHeight:1.46,
             "& .p-title":{
              color:theme.palette.secondary.main,
             },
             "& .p-value":{
              textTransform:'uppercase',
              fontWeight:600,
              cursor: 'pointer',
             }
            },
            "& .valid-date":{
              float:'right',
              fontSize:12,
            }
          },


       }

    }










  },
}));

const OfferCard = (props) => {
  const classes = useStyles();
  const [copyText, setCopyText] = useState("");
  
  const handleCopy = (e, val) => {
    setCopyText(val);
    navigator.clipboard.writeText(copyText);
  };

  const offerValue = props.offer;

  
  return (
    <div className={classes.root}>
      <div className="offer-card">

        <div className="offer-content">
          <div className="p-img">
            <img
              alt="p-img"
              src={
                BASE_URL_IMAGE_VIEW +
                `agency/view-file?fileName=${offerValue.promoImage}&type=PROMO_IMAGES`
              }
            />
          </div>

          <div className="txt-content">
            <div className="offer-heading">
              <h3>{offerValue.promoHeading}</h3>
            </div>
            <div className="offer-desc">
              <p>
                {offerValue.description}
              </p>
            </div>
            <div className="pCode-n-valid">
              <div className="p-code-sec">
                <div className="p-title">Promocode</div>
                <div className="p-value" title="copy" onClick={(e) => handleCopy(e, offerValue.promoCode)}>{offerValue.promoCode}</div>
              </div>
              <div className="valid-date">
                {offerValue.validTo}
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default OfferCard;
