import React from "react";
import Grid from "@material-ui/core/Grid";

import "components/HotelPage/HotelComponents/Scheduler/Scheduler.css";

import LocationIcon from "assets/img/dashicons-location.png";
import DateIcon from "assets/img/Hotelassets/Icon Shape_11.png";
import Icon from "assets/img/Hotelassets/Vector.png";
import Icon1 from "assets/img/Hotelassets/IconPath_8.png";
import { makeStyles } from "@material-ui/core";

export default function Scheduler(props) {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.root}
      >
        <Grid item md={3} xs={12}>
          <div className="selectedText">
            <img
              src={LocationIcon}
              style={{ margin: 5, height: 25, float: "left" }}
            />
            <span>{props.selectedData.city}</span>
          </div>
        </Grid>
        <Grid item md={2} xs={12}>
          <div className="selectedText">
            <img
              src={DateIcon}
              style={{ margin: 5, height: 25, float: "left" }}
            />
            <span>{props.selectedData.checkInDate}</span>
          </div>
        </Grid>
        <Grid item md={2} xs={12}>
          <div className="selectedText">
            <img
              src={DateIcon}
              style={{ margin: 5, height: 25, float: "left" }}
            />
            <span>{props.selectedData.checkOutDate}</span>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className="selectedText">
            <img src={Icon} style={{ margin: 5, height: 25, float: "left" }} />
            <span>
              {` ${
                props.selectedData.roomGuests.length
              } ROOMS ${props.selectedData.roomGuests.reduce(function(a, b) {
                return Number(a) + Number(b.noOfAdults) + Number(b.noOfChild);
              }, 0)} GUESTS`}{" "}
            </span>
            {/* <img src={Icon1} style={{ margin: 5, float: "right" }} /> */}
          </div>
        </Grid>
        {/* <Grid item md={2} xs={12}>
          <div className="selectedText">&nbsp;</div>
          <div
            className="selectedButton"
            onClick={() => {
              props.setModifyHotelSearch(!props.modifyHotelSearch);
            }}
          >
            <span>Update Search1</span>
          </div>
        </Grid> */}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .selectedButton": {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      cursor: "pointer",
      "& span:hover": {
        background: theme.palette.secondary.main,
      },
    },
  },
}));
