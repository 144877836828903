import React, { useState, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import closeBtn from "../../../../assets/easyTravels/images/closeButton.png";
import couponIcon from "../../../../assets/easyTravels/images/icons/coupon_icon.png";

const useStyles = makeStyles((theme) => ({
  coupons: {
    display: "flex",
    gap: "1.5rem",
    margin: "0.8rem 0",
    // padding: "13px 9px 0",
    padding: "6px 15px 6px 3px",

    // backgroundColor: theme.palette.primary.shadedLightText,
    background: " #f4f4f4",
    width: "100%",
    height: 90,
    boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 23%)",
    borderRadius: 10,

    position: "relative",
    [theme.breakpoints.down(767)]: {
      width: "100%",
    },

    "& .modal": {
      display: "flex",
      justifyContent: "center",
      position: "fixed",
      zIndex: 99999,
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      alignItems: "center",
      margin: "0 auto",
      "& .modalContainer": {
        backgroundColor: "#dddddd",
        boxShadow: "0 0 0 50vmax rgba(0,0,0,.5)",
        padding: 30,
        paddingTop: 50,
        scrollPadding: "30px 0 0 0",
        // marginTop: 50,
        width: "50%",

        // minHeight: 490,
        height: "60%",
        borderRadius: 25,
        backgroundColor: "#fff",
        position: "relative",
      },
      "& .contentContainer": {
        height: "100%",
        overflowY: "scroll",
        scrollPaddingTop: "20px",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          // display: "none",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          borderRadius: 10,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          // outline: "1px solid slategrey",
          borderRadius: 10,
        },
        // paddingTop: 50,
      },
      "& .modalHeader": {
        fontSize: 20,
        textAlign: "center",
        fontWeight: 600,
        marginBottom: 10,
        "& .closeIcon": {
          zindex: 999999999,
          cursor: "pointer",
          position: "absolute",
          right: 27,
          top: 15,
        },
      },
      "& .dataContainer": {
        marginBottom: 15,
        marginLeft: 10,
      },
      "& .headerContainer": {
        display: "flex",
        alignItems: "center",
        // alignItems: "felx-",
      },
      "& .dataHeader": {
        fontSize: 18,
        fontWeight: 600,
        // textDecoration: "underline",
        textTransform: "uppercase",
      },
      "& .modalHeaderr": {
        background: "linear-gradient(98deg, #fffcce 4%, #ffcb6b 99%)",
        padding: "10px 20px",
        marginBottom: 20,
        marginTop: 10,
      },
      "& .dataContent": {
        marginLeft: 10,
        // fontWeight: 600,
        fontSize: 14,
      },
      "& .dataHeaderContent": {
        marginLeft: 10,
        fontWeight: 600,
        fontSize: 15,
      },
    },

    "& .coupon_desc": {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight:10,
      margin: "0.1rem 0",
      "& .coupon_price": {
        fontSize: "1rem",
        fontWeight: "bold",
      },
      "& .coupon_tc": {
        textDecoration: "underline !important",
        cursor: "pointer",
        color: theme.palette.primary.info,
        // color: theme.palette.primary.danger,
      },
    },

    "& .coupon_desc p": {
      fontSize: "14px",
      lineHeight: "15px",
    },
    "& .coupons_name": {
      color: theme.palette.primary.defaultText,
      fontSize: "1.1rem",
      fontWeight: "bold",
      paddingTop: 6,
    },
    "& .c_btn": {
      background: "none",
      outline: "none",
      fontSize: "1rem",
      border: "none",
      cursor: "pointer",
      color: theme.palette.primary.info,
      margin: "0",
    },
    "& .coupon_icon": {
      // fontSize: "2.3rem",
      position: "absolute",
      top: 6,
      right: 20,
      "& img": {
        width: 24,
        height: 24,
      },
    },

    "& .check_input": {
      borderRadius: 50,
      cursor: "pointer",
      // width: 26,
      // height: 26,
    },
    
    "& .c_details":{
      width:'90%'
    },
    "& .desc":{
     margin:'0.3rem 0',
     textOverflow: "ellipsis",
     overflow:'hidden',
     height:'18px',
     width:'100%',
     paddingRight:'10px',
     whiteSpace: "nowrap"
    //  padding:'0 10px',
    },

  },
}));

const Coupons = (props) => {
  // const {item,promoadd} = props
  const item = props.item;
  // console.log(item);
  const promoAdd = props.promoadd;

  const [showModal, setShowModal] = useState(false);

  const classes = useStyles();

  const [coupon, setCoupon] = useState([]);

  const addCoupon = (ev, couponObj) => {
    if (ev.target.checked) {
      // setCoupon([...coupon, price]);
      props.applycoupon(couponObj);
    } else {
      // setCoupon(values => values.filter(obj => { return obj.price != coupon.price }));
      props.applycoupon({
        voucher_code: "",
        voucher_value: 0,
        description: "",
      });
    }
  };

  const removeCoupon = (ev, couponObj) => {};

  // useEffect(() => {
  //     promoAdd(coupon)
  // }, [coupon]);

  return (
    <div className={classes.coupons}>
      {showModal && (
        <Box className="modal">
          <Box className="modalContainer">
            <Box className="contentContainer">
              <Box className="modalHeader">
                {/* <div>-</div> */}
                <img
                  onClick={() => setShowModal(false)}
                  className="closeIcon"
                  width={30}
                  height={30}
                  src={closeBtn}
                />
              </Box>
              <Box className="modalHeaderr">
                <Box className="headerContainer">
                  <Box className="dataHeader">COUPON CODE:</Box>
                  <Box className="dataHeaderContent">
                    {" "}
                    {item?.voucher_code ?? ""}
                  </Box>
                </Box>
                <Box className="headerContainer">
                  <Box className="dataHeader">DESCRIPTION:</Box>
                  <Box className="dataHeaderContent">
                    {" "}
                    {item?.description ?? ""}
                  </Box>
                </Box>
              </Box>

              <Box className="dataContainer">
                <Box>
                  <Box className="dataHeader">Terms & condition:</Box>
                  {item?.terms_condition
                    ?.split(" /$#/ ")
                    .map((data) => (
                      <Box className="dataContent">• {data ?? ""}</Box>
                    ))}
                </Box>
              </Box>
              <Box className="dataContainer">
                <Box className="dataHeader">
                  Terms & condition Cancellation:
                </Box>
                <Box className="dataContent">
                  {item?.cancellationTermsConditions
                    ?.split(" /$#/ ")
                    .map((data) => <div>• {data ?? ""}</div>)}
                </Box>
              </Box>
              <Box className="dataContainer">
                <Box className="dataHeader">Offer Details:</Box>
                <Box className="dataContent">
                  {item?.offerDetails
                    ?.split(" /$#/ ")
                    .map((data) => <div>• {data ?? ""}</div>)}
                </Box>
              </Box>
              <Box className="dataContainer">
                <Box className="dataHeader">what Do You Get:</Box>
                <Box className="dataContent">
                  {item?.whatDoYouGet
                    ?.split(" /$#/ ")
                    .map((data) => <div>• {data ?? ""}</div>)}
                </Box>
              </Box>
              <Box className="dataContainer">
                <Box className="dataHeader">How Do You Get:</Box>
                <Box className="dataContent">
                  {item?.howDoYouGet
                    ?.split(" /$#/ ")
                    .map((data) => <div>• {data ?? ""}</div>)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <input
        type="radio"
        onChange={(ev) => addCoupon(ev, item)}
        className="check_input"
        name="discount-coupon"
        checked={
          props?.selectedcoupon?.voucher_code == props?.item?.voucher_code
        }
      />
      <div className="c_details">
        <div className="coupons_name">{item.voucher_code}</div>
         <div className="desc">{item.description}</div>    
        <div className="coupon_desc">
        <p className="coupon_price">Rs {item.voucher_value}</p>
          <Box>
            <Box onClick={() => setShowModal(true)} className="coupon_tc">
              {" "}
              Terms & conditions
            </Box>
          </Box>
        </div>
        <Button
          className="c_btn"
          onClick={(ev) => {
            removeCoupon(ev, item);
          }}
        >
          {coupon.length > 0 ? "Remove" : ""}
          {/* Remove */}
        </Button>
      </div>
      <div className="coupon_icon">
        <span>
          <img src={couponIcon} alt="" />
          {/* 💳 */}
        </span>
      </div>
    </div>
  );
};

export default Coupons;
