import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

import { Formik, Form, ErrorMessage } from "formik";

import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";

import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaFormSaveButton from "oahoc/OaFormSaveButton";
import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router";
import { useTheme } from "@material-ui/core";


const MobileSearchForm = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const theme=useTheme()
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }
  return (
    <>
      <h4
        className={"oa-form-header"}
        onClick={collapse}
        style={{ transform: " scale(0.85)", transformOrigin: "left" }}
      >
        Verify OTP
      </h4>
      <Collapse in={collapsed}>
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
          alertTitle={alertTitle}
        />
        <Formik
          initialValues={{
            mobile: props.mobile,
          }}
          validationSchema={Yup.object().shape({
            otp: Yup.number().required("Please enter OTP"),
          })}
          onSubmit={(
            searchParams,
            { setSubmitting, resetForm, enableReinitialize }
          ) => {
            setSubmitting(true);
            setIsSaving(true);
            apiCall(WebApi.verifyDMTOtp, searchParams, (response) => {
              setIsError(!response.success);
              setIsSuccess(response.success);
              setSubmitting(false);
              setIsSaving(false);

              setInfoText(response.message);
              setAlertTitle("Error verification");
              if (response.success === true && response.data) {
                setInfoText(response.data.message);
                if (response.data.success === true) {
                  setAlertTitle("verified successfully");

                  // props.history.push('/admin/moneytransfer/beneficiary-list',{ data: response.data })
                  // enableReinitialize=true;
                  // resetForm(true);
                } else {
                  // props.history.push('/admin/moneytransfer/verify-otp',{ mobile: searchParams.mobile })
                }
              }
            });
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              {props.location &&
                props.location.query &&
                props.location.query.mobile}
              <GridContainer>
                <GridItem md={4}>
                  <OaFormTextField
                    style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                    className="input-landing-page"
                    name="otp"
                    label="Enter the OTP received on the mobile number"
                  />
                  <ErrorMessage name="otp" component="div" className="error" />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ marginTop: 20 }}>
                <GridItem>
                  <Button
                    className="button-landing-page"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 200 }}
                  >
                    Resend OTP
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    className="button-landing-page"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 200 }}
                  >
                    Verify OTP
                  </Button>
                </GridItem>
              </GridContainer>
            </Form>
          )}
        </Formik>
      </Collapse>
    </>
  );
};

export default MobileSearchForm;
