import React from "react";
import clsx from "clsx";
import dateFnsFormat from "date-fns/format";
import {
  Button,
  Grid,
  Hidden,
  Radio,
  IconButton,
  Dialog,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlightModifyForm from "./FlightModifyForm";
import { ToggleButton } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { CloseOutlined, ArrowRightAlt, SwapHoriz } from "@material-ui/icons";
import MobileFlightSection from "components/LandingPage/for-mobile/MobileFlightSection";

const FlightSearchHeader = (props) => {
  const classes = useStyles();
  const width = window.innerWidth;
  const breakpoint = 980;
  const [isModifying, setIsModifying] = React.useState(false);
  const [
    flightSearchHeaderDetails,
    setFlightSearchHeaderDetails,
  ] = React.useState(null);
  const [arrayModify, setArrayModify] = React.useState(null);
  const rootModRef = React.useRef();
  const rootDiv = React.useRef();

  let intitvals = props.history.location.state.requestObj;

  console.log(intitvals, "INITVALS");

  const updateModify = (updateFlag) => {
    // UPDATE STATE
    setIsModifying(updateFlag);
  };

  const cancelModify = () => {
    setIsModifying(false);
    props.isCancelModify(true);
  };

  const toggleNetFareDisplay = () => {
    props.toggleShowNetFare(!props.showNetFare);
  };

  const scrollFunction = () => {
    if (window.scrollY >= 10) {
      rootDiv &&
        rootDiv.current &&
        rootDiv.current.classList.add("fixed-header");
    } else {
      rootDiv &&
        rootDiv.current &&
        rootDiv.current.classList.remove("fixed-header");
    }
  };

  React.useEffect(() => {
    // console.log("header Props", props);

    if (intitvals.mode == "ONE") {
      intitvals.returnDate = intitvals.sectors[0].departDate;
      intitvals.returnDateDisplay = intitvals.sectors[0].departDateDisplay;
    }
    /* else
    {
      intitvals.returnDate = intitvals.sectors[0].returnDate;
      intitvals.returnDateDisplay = intitvals.sectors[0].returnDateDisplay;

    }*/
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const propState = props.history.location.state;

    const requestObj = propState?.requestObj;
    const tripMode = requestObj?.mode;
    const request = propState?.request;
    const adultPax = requestObj?.adultPax;
    const childPax = requestObj?.childPax;
    const infantPax = requestObj?.infantPax;
    const paxDet =
      adultPax +
      " Adult" +
      (adultPax > 1 ? "s " : " ") +
      (childPax > 0 ? ", " + childPax + " Child" : "") +
      (infantPax > 0
        ? ", " + infantPax + " Infant" + (infantPax > 1 ? "s" : "")
        : "");

    const prClass = requestObj?.preferredclass === "E" ? "Economy" : "Business";

    const depAirportName = requestObj?.sectors[0]?.originObj.cityName;
    const depAirportCode = requestObj?.sectors[0]?.originObj.airportCode;
    const depDateRaw =
      requestObj?.sectors && new Date(requestObj?.sectors[0].departDate);
    const departDate =
      requestObj?.sectors && dateFnsFormat(depDateRaw, "dd MMM yyyy");
    const departDay = requestObj?.sectors && days[depDateRaw.getDay()];

    //console.log("requestObj " , requestObj);

    let arrAirportName =
      requestObj?.sectors[requestObj?.sectors.length - 1 || 0]?.destinationObj
        .cityName;
    let arrAirportCode =
      requestObj?.sectors[requestObj?.sectors.length - 1 || 0]?.destinationObj
        .airportCode;
    let arrDateRaw = requestObj && new Date(requestObj?.returnDate);
    let arrDate = requestObj && dateFnsFormat(arrDateRaw, "dd MMM yyyy");
    let arrDay = requestObj && days[arrDateRaw.getDay()];

    let headerArray = [
      { title: "From", value: depAirportName + " (" + depAirportCode + ")" },
      { title: "To", value: arrAirportName + " (" + arrAirportCode + ")" },
      { title: "Depart", value: departDate + ", " + departDay },
      tripMode === "ROUND" && {
        title: "Arrival",
        value: arrDate + "," + arrDay,
      },
      { title: "Passengers", value: paxDet },
      { title: "Class", value: prClass },
    ];

    setFlightSearchHeaderDetails(headerArray);
    //console.log(arrDate + "," + arrDateRaw + "," + arrDay);
    setArrayModify({
      depAirportName: depAirportName,
      depAirportCode: depAirportCode,
      depDateRaw: depDateRaw,
      departDate: departDate,
      departDay: departDay,
      arrAirportName: arrAirportName,
      arrAirportCode: arrAirportCode,
      arrDateRaw: arrDateRaw,
      arrivalDate: arrDate,
      arrivalDay: arrDay,
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", scrollFunction());

    return () => {
      window.removeEventListener("scroll", scrollFunction());
    };
  }, []);

  React.useEffect(() => {
    setIsModifying(props.isModifyingVal);
  }, [props.isModifyingVal]);
  const passengers =
    flightSearchHeaderDetails &&
    flightSearchHeaderDetails[4].value
      .replace(/Adult|Child|Infant/gi, (pax) => {
        switch (pax) {
          case "Adult":
            return "ADT";
            break;
          case "Child":
            return "CHD";
            break;
          case "Infant":
            return "INF";
            break;
        }
      })
      .split(",")
      .map((p) => p.trim())
      .join(", ");
  return width > breakpoint ? (
    <div className={classes.rootMod} ref={rootModRef}>
      <FlightModifyForm
        multiCity={false}
        cancelModify={cancelModify}
        updateModify={(updateFlag) => updateModify(updateFlag)}
        arrayModify={arrayModify}
        initialvalues={intitvals}
        {...props}
      />
    </div>
  ) : (
    <div className={classes.root} ref={rootDiv}>
      <Dialog open={isModifying} onClose={cancelModify} fullScreen>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {" "}
            <IconButton onClick={cancelModify}>
              <CloseOutlined />
            </IconButton>
          </Box>

          <MobileFlightSection
            initialvalues={intitvals}
            isModifying={isModifying}
          />
        </Box>
      </Dialog>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/eb67f5d49a.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      {flightSearchHeaderDetails && (
        <Grid
          container
          direction="column"
          className={classes.flightSearchElements}
        >
          {/*headerDetails.map((e, i) => (
          <Grid
            item
            className={classes.flightSearchElement}
            // onClick={() => setIsModifying(!isModifying)}
            key={i}
          >
            <div className={classes.flightSearchElement_title}></div>
            <div className={classes.flightSearchElement_value}>
              <span>{e.value}</span>
            </div>
          </Grid>
        ))*/}

          <Grid
            item
            className={classes.flightSearchElement}
            md={12}
            xs={12}
            // onClick={() => setIsModifying(!isModifying)}
          >
            <div className={classes.flightSearchElement_value}>
              <span className={classes.flightSearchElementText}>
                {flightSearchHeaderDetails[0].value}
              </span>
            </div>
            <span className={classes.flightSearchElementIcon}>
              {" "}
              {intitvals?.mode === "ROUND" ? (
                <SwapHoriz />
              ) : (
                <ArrowRightAlt />
              )}{" "}
            </span>
            <div className={classes.flightSearchElement_value}>
              <span className={classes.flightSearchElementText}>
                {flightSearchHeaderDetails[1].value}
              </span>
            </div>
          </Grid>
          {
            <Grid
              item
              md={12}
              xs={12}
              className={classes.flightSearchElement}
              // onClick={() => setIsModifying(!isModifying)}
            >
              {intitvals?.mode === "ROUND" ? (
                <div className={classes.flightSearchElement_value}>
                  <span>
                    Dep:&nbsp;{" "}
                    {flightSearchHeaderDetails[2].value.split(",")[0]}{" "}
                    &nbsp;&nbsp;|&nbsp;&nbsp; Arr: &nbsp;
                    {flightSearchHeaderDetails[3].value.split(",")[0]}{" "}
                    &nbsp;&nbsp;|&nbsp;&nbsp; {passengers}
                  </span>
                  {/* <span>{flightSearchHeaderDetails[2].value}</span>
                  <span>
                    {" "}
                    <SwapHoriz />{" "}
                  </span>
              <span>{flightSearchHeaderDetails[3].value}</span>*/}
                </div>
              ) : (
                <div className={classes.flightSearchElement_value}>
                  {/*<span>{flightSearchHeaderDetails[2].value}</span>*/}
                  <span>
                    Dep:&nbsp;{flightSearchHeaderDetails[2].value.split(",")[0]}{" "}
                    &nbsp;&nbsp;|&nbsp; &nbsp;{passengers}
                  </span>
                </div>
              )}
              {/*<span className={classes.flightSearchElementSeparator}> | </span>
              <div className={classes.flightSearchElement_value}>
                <span>{flightSearchHeaderDetails[4].value}</span>
              </div>*/}
            </Grid>
          }
          {/*<Grid
            item
            md={12}
            xs={12}
            className={classes.flightSearchElement1}
            style={{ display: "flex", flexDirection: "column" }}
            // onClick={() => setIsModifying(!isModifying)}
          >
            <div className={classes.flightSearchElement_value2}>
              <span>Departure</span>
              <span className={classes.flightSearchElementSeparator}> - </span>
              <span>{flightSearchHeaderDetails[2].value}</span>
            </div>
            {intitvals?.mode === "ROUND" && (
              <div className={classes.flightSearchElement_value2}>
                <span>Arrival</span>
                <span className={classes.flightSearchElementSeparator}>
                  {" "}
                  -{" "}
                </span>
                <span>{flightSearchHeaderDetails[3].value}</span>
              </div>
            )}

            <div className={classes.flightSearchElement_value2}>
              <span>{flightSearchHeaderDetails[4].value}</span>
              <span className={classes.flightSearchElementSeparator}> | </span>
              <span>{flightSearchHeaderDetails[5].value}</span>
            </div>
          </Grid>*/}

          {intitvals.partnerId === 1002 && (
            <Grid
              item
              className={clsx(classes.flightSearchElement, "horz-flex")}
              md={12}
              xs={12}
            >
              <ToggleButton
                value={true}
                selected={props.showNetFare}
                onChange={toggleNetFareDisplay}
                title={props.showNetFare ? "hide net fare" : "show net fare"}
                className={"tnf-button " + (props.showNetFare ? "active" : "")}
              >
                {props.showNetFare ? "HNF1111" : "SNF"}
              </ToggleButton>
            </Grid>
          )}
          {/*<Grid item className={classes.modifyButton}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.flightSearchCTA}
              onClick={() => setIsModifying(!isModifying)}
            >
              <span>Modify</span>
            </Grid>
              </Grid>*/}
        </Grid>
      )}
    </div>
  );
};

export default FlightSearchHeader;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 10,
  boxShadow: 24,
  p: 2,
};
function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      {...props}
    />
  );
}

const radioStyles = makeStyles((theme) => {
  return {
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: `${theme.palette.quarternary.contrastText}`,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: `1px auto ${theme.palette.quarternary.contrastText}`,
        outlineOffset: 1,
      },
      "input:hover ~ &": {
        backgroundColor: `${theme.palette.quarternary.contrastText}90`,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: `${theme.palette.quarternary.contrastText}10`,
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.quarternary.contrastText,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: `radial-gradient(${theme.palette.quarternary.main},${theme.palette.quarternary.main} 28%,transparent 32%)`,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: `${theme.palette.quarternary.contrastText}90`,
      },
    },
  };
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // backgroundColor: theme.palette.quarternary.main,
      background: theme.palette.buttons.default,
      width: "100%",
      padding: "15px 60px",
      maxWidth: "1440px !important",
      // color: theme.palette.secondary.darkOrange,
      color: theme.palette.buttons.defaultContrast,
      border: "1px solid #b1b1b1",
      borderRadius: "10px",
      // visibility: "hidden",
      // maxHeight: 40,

      marginTop: 75,

      "&.fixed-header": {
        boxShadow: " 0 8px 8px rgba(0,0,0,0.30)",
        marginTop: 0,
        maxHeight: 120,
        visibility: "visible",
        // top: 100,
        // left: 0,
        // position: "fixed",
        [theme.breakpoints.down(480)]: {
          // top: 0,
        },
      },
      // top: 0,
      zIndex: "102",
      "& .MuiFormControlLabel-label": {
        color: theme.palette.background.primaryContrast,
      },
      [theme.breakpoints.down(1400)]: {
        padding: "12px",
        borderRadius: 5,
      },
    },
    rootMod: {
      [theme.breakpoints.down(1200)]: {
        width: "100vw",
        left: "calc(-50vw + 50%)",
        padding: "15px 0",
      },
      borderRadius: "5px",
      background: theme.palette.buttons.default,
      //background: theme.palette.primary.main,

      width: "100%",
      position: "relative",
      left: 0,
      height: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // padding: "16px 60px",
      color: theme.palette.buttons.defaultContrast,
      // position: "absolute",
      //left: 0,
      marginTop: 65,
      padding: "15px 0 15px 10px",
      zIndex: "1",
      marginBottom: "13px",
      // 1px solid #d3d3d3
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      //paddingTop: 20,
      [theme.breakpoints.down(1400)]: {
        padding: "10px",
        // paddingTop: 30,
      },
    },
    flightSearchElements: {
      display: "flex",

      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "center",
      "& .tnf-button": {
        fontWeight: 600,
        fontFamily: theme.palette.font.secondary,
        color: "auto",
        padding: "0 5px",
        background: theme.palette.buttons.default,
        color: theme.palette.buttons.defaultContrast,
        "&.active": {
          color: theme.palette.buttons.default,
          background: theme.palette.buttons.defaultContrast,
        },
      },
      "& .filter-toggle-rt": {
        minWidth: 40,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        display: "none",
      },
      "& .filter-rt-desktop-only": {
        display: "none",
        [theme.breakpoints.between(992, 1500)]: {
          display: "flex",
          alignItems: "center",
          marginLeft: 10,
        },
      },
      // [theme.breakpoints.down(960)]: {
      //   display: "grid",
      //   gridTemplateColumns: "repeat(3, 1fr)",
      // },
      // [theme.breakpoints.down(600)]: {
      //   display: "grid",
      //   gridTemplateColumns: "repeat(3, 1fr)",
      // },
    },
    flightSearchElementIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 5px",
    },
    flightSearchElement: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&.horz-flex": {
        display: "flex",
      },
      [theme.breakpoints.down(550)]: {
        "&.horz-flex": {
          gridColumn: "1/span 3",
          width: "100% !important",
          justifyContent: "center",
          borderTop: `solid 0.1px`,
          margin: "2px 0 -5px 0",
          padding: "5px 0 0 0",
        },
      },
    },
    flightSearchElement_title: {
      marginBottom: "3px",
      "& span": {
        fontSize: "12px",
        fontWeight: "600",
        [theme.breakpoints.down(1080)]: {
          fontSize: "8px",
        },
      },
    },
    flightSearchElement_value: {
      display: "flex",
      justifyContent: "space-between",
      /*height: "24px",
      textDecoration: "underline",
      textUnderlineOffset: "5px",
      color: theme.palette.quarternary.contrastText,*/
      [theme.breakpoints.down(960)]: {
        justifyContent: "center",
      },
      //cursor: "pointer",
      "& span": {
        fontSize: "20px",
        fontWeight: "600",
        [theme.breakpoints.down(960)]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down(500)]: {
          fontSize: "12px",
        },
      },
      "& .MuiInputBase-root": {
        color: theme.palette.buttons.defaultContrast,
        "& .MuiInputBase-input": {
          fontSize: "20px",
          fontWeight: "400",
          [theme.breakpoints.down(1080)]: {
            fontSize: "16px",
          },
        },
      },
    },
    flightSearchElement_value2: {
      display: "flex",
      justifyContent: "flex-start",
      /*height: "24px",
      textDecoration: "underline",
      textUnderlineOffset: "5px",
      color: theme.palette.quarternary.contrastText,*/

      //cursor: "pointer",
      "& span": {
        fontSize: "20px",
        fontWeight: "600",
        [theme.breakpoints.down(960)]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down(500)]: {
          fontSize: "12px",
        },
      },
      "& .MuiInputBase-root": {
        color: theme.palette.buttons.defaultContrast,
        "& .MuiInputBase-input": {
          fontSize: "20px",
          fontWeight: "400",
          [theme.breakpoints.down(1080)]: {
            fontSize: "16px",
          },
        },
      },
    },
    flightSearchElementText: { fontSize: "16px !important" },
    flightSearchElementSeparator: { margin: "0 5px" },

    modifyButton: {
      width: "10%",
      minWidth: 100,
      [theme.breakpoints.down(1565)]: {
        width: "14%",
        // height: "60px !important",
      },
      [theme.breakpoints.down(1170)]: {
        width: "10%",
        // height: "60px !important",
      },
      [theme.breakpoints.down(550)]: {
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        marginLeft: 50,
        width: "40%",
      },
    },
    flightSearchCTA: {
      height: "40px",
      width: "160px",
      background: theme.palette.buttons.tertiary,
      borderRadius: "4px",
      cursor: "pointer",

      "& span": {
        fontSize: "20px",
        fontWeight: "600",
        color: theme.palette.buttons.tertiaryContrastText,
        textAlign: "center",
        [theme.breakpoints.down(1080)]: {
          fontSize: "16px",
        },
      },
    },
  };
});
