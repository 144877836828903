import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';

const OaTabBar = (props) => {

    let classname = "oa-tabs-primary";
    let indicator = 'secondary';
    let color = 'primary';

    switch(props.type){
        case 'secondary':
            classname = 'oa-tabs-secondary'
            indicator = 'primary';
            break;
        case 'primary':
        default:
            break;
            
    }

    return (
        <Paper elevation={16} className={classname}>
        <Tabs indicatorColor={indicator} textColor={color} value={props.currentTab}>
            {props.tabs.map( (row, id) => {
                return(<Tab label={row.label} key={id} value={row.name} to={row.url} component={Link}/>);
            })}
        </Tabs>
        </Paper>
    )
}

export default OaTabBar
