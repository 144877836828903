import React, { useState } from "react";
import OaLoading from "pages/components/OaLoading";
import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  ErrorOutlineRounded,
  CheckCircle,
  List,
  ListAltOutlined,
} from "@material-ui/icons";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

const BusPaymentStatus = (props) => {

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [refId, setRefId] = React.useState(0);
  const [trnCreationError, setTrnCreationError] = React.useState(false);

  const goToTicketPrint = () => {
    props.history.push({
      pathname: "/bus/bus-ticket",
      state: props?.history?.location?.state,
    });
  };

  React.useEffect(() => {
    setIsLoading(true);
    setRefId(props.match.params.tid);
    // if (props?.match?.params?.status == "success") {
    if (props?.match?.params?.status.slice(0, 7) == "success") {
      setIsLoading(false);
      setBookingStatus(true);
      setPaymentStatus("Success");
    } else {
      //props?.match?.params?.tid == 0 &&
      setTrnCreationError(true);
      setIsLoading(false);
      setBookingStatus(false);
      setPaymentStatus("Failed");
    }
  }, [props.match.params.tid, props.match.params.status]);

  React.useEffect(() => {
    if (props?.match?.params?.status.slice(0, 7).toLowerCase() == "success") {

      apiCall(WebApi.getBusSeatBookingDetails, { tid: props.match.params.tid }, (response) => {
        if (response.success) {
          setIsLoading(false);
          console.log(response.data);
        } else {
          setBookingStatus(false);
          setIsLoading(false);
        }
      })
    }
    else {
      setBookingStatus(false);
      setIsLoading(false);
    }
  }, []);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        <OaLoading loadertext="please wait ..." />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {!isLoading && bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <CheckCircle />
            <Typography>
              Reference id : <b>{refId}</b>
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>
            <Typography>
              Booking Successful. Please click the button below to go to voucher
              details.
            </Typography>
          </div>
          <div className="action-group">
            <Button onClick={goToTicketPrint}>
              <ListAltOutlined /> Ticket
            </Button>
          </div>
        </div>
      )}

      {!isLoading && !bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <ErrorOutlineRounded style={{ color: "orange" }} />
            <Typography>
              Booking Failed. Please check after some time
              {!trnCreationError &&
                ` or contact administrator with reference id (${refId})`}
              .
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="section-container">
          {" "}
          <LoaderView />
        </div>
      )}
    </div>
  );
};

export default BusPaymentStatus;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: 10,
      },
    },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: "60vh"
    },
    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "50px 0",
      "& .MuiSvgIcon-root": {
        fontSize: "10vw",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 20,
      },
    },
    "& .action-group": {
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));
