const PRI_COLOR = "#390044"; //dark purple
const SEC_COLOR = "#792779"; //theme purple lighter
const TER_COLOR = "#EF95E4"; //pink normal
const QUA_COLOR = "#ED4722"; //orange
const QUI_COLOR = "#16011A"; //darker purple
const SEN_COLOR = "#F2E4F3"; //baby pink light
const SEP_COLOR = "#D43ED2"; //dark pink
const OCT_COLOR = "#504050"; //faded dark pink
const NON_COLOR = "#100018"; //dark violet 
const DEN_COLOR = "#EE4723"; //orange lighter
const UNY_COLOR = "#FFCEF2"; //baby pink
const DOY_COLOR = "#DDC8DD"; //baby pink lighter
const TEY_COLOR = "#F44336"; //dark orange previous orange FF0000
const QUY_COLOR = "#C9E1FF"; //faded blue
const QIY_COLOR = "#FFF0C9"; //faded yellow
const SEY_COLOR = "#B7F6F5"; //faded mint
const SPY_COLOR = "#F3EDFF"; // faded purple
const OCY_COLOR = "#7936A4"; //purple for login button
const NNY_COLOR = "#FA9EEA"; //pink button color
const DDY_COLOR = "#2575FC"; //blue normal

const PRI_WHITE = "#FFFFFF";
const SEC_WHITE = "#F8F8F8";
const TER_WHITE = "#EEEEEE";
const QUA_WHITE = "#E8E8E8";
const QUI_WHITE = "#44444445";
const SEN_WHITE = "#b8b8b8";

const PRI_BLACK = "#000000";
const SEC_BLACK = "#030303";
const TERT_BLACK = "#333333";
const QUA_BLACK = "#727272";
const QUI_BLACK = "#666666";
const SEN_BLACK = "#CCCCCC";
const SEP_BLACK = "#555555";

const LIGHT_GRAY = "#E8E8E8";
const LIGHT_GRAY_SEC = "#F7F9FB";
const LIGHT_FADED_BLUE = "#FAFAFA";
const LIGHT_FADED_RED = "#FCF0F0";

const PRI_NAVY_BLUE = "#012F89";

const PRI_GREEN = "#22BB33";
const SEC_GREEN = "#199c28";

const PRI_RED = "#BB2124";
const SEC_RED = "#EC6161";

const RGBA_PRIMARY = "rgba(1, 47, 137, 1)";
const RGBA_PRIMARY_LIGHT = "rgba(1, 47, 137, 0.5)";
const RGBA_PRIMARY_LIGHT03 = "rgba(1, 47, 137, 0.03)";
const RGBA_SECONDARY = "rgba(255, 0, 0, 0.02)"; 
const RGBA_TERTIARY = "rgba(0, 255, 0, 0.02)";
const RGBA_QUARTERNARY = "rgba(0, 0, 255, 0.02)";
const RGBA_QUINARY = "rgba(0, 0, 0, 0.54)";
const RGBA_THEME = "rgba(121, 39, 121, 0.2)";
const RGBA_THEME7 = "rgba(121, 39, 121, 0.7)";

export const COLORS = {
  PRIMARY_COLOR: PRI_COLOR,
  THEME_COLOR: SEC_COLOR,
  ACTION_BUTTON_BG: TER_COLOR,
  SEARCH_RESULT_BG: QUA_COLOR,
  HIGHLIGHT_COLOR: QUI_COLOR,
  DARK_BG_COLOR: NON_COLOR,
  HIGHLIGHT_COLOR_OR: QUA_COLOR,
  UNDENARY_COLOR: UNY_COLOR,
  DUDENARY_COLOR: DOY_COLOR,
  HIGHLIGHT_COLOR_SECONDARY: TEY_COLOR,
  HIGHLIGHT_COLOR_TERTIARY: DDY_COLOR,

  // component colors
  PRIMARY_WHITE: PRI_WHITE,
  PRIMARY_BLACK: PRI_BLACK,
  SECONDARY_WHITE: SEC_WHITE,
  SECONDARY_BLACK: SEC_BLACK,
  TERTIARY_WHITE: TER_WHITE,
  TERTIARY_BLACK: TERT_BLACK,
  QUARTERNARY_WHITE: QUA_WHITE,
  QUARTERNARY_BLACK: QUA_BLACK,
  QUINARY_BLACK: SEN_BLACK,
  PRIMARY_NAVY_BLUE: PRI_NAVY_BLUE,
  
  // rgba colors
  CARD_COMP_PRIMARY: RGBA_SECONDARY, 
  CARD_COMP_SECONDARY: RGBA_TERTIARY,
  CARD_COMP_TERTIARY: RGBA_QUARTERNARY,
  CARD_COMP_QUARTERNARY: RGBA_PRIMARY_LIGHT03, 
  
  // text colors
  WELCOME_TEXT_COLOR: SEC_COLOR,
  WELCOME_TEXT_SEC_COLOR: PRI_COLOR,
  DEFAULT_TEXT_COLOR: TERT_BLACK,
  DEFAULT_TEXT_LIGHT: PRI_WHITE,
  DEFAULT_TEXT_DARK: PRI_BLACK,
  DEFAULT_TEXT_DARK_COL: QUI_COLOR,
  DEFAULT_TEXT_ACTIVE: SEC_COLOR,
  LIGHT_GRAY_TEXT: QUA_BLACK,
  LIGHT_GRAY_TEXT_SEC: QUI_BLACK,
  LIGHT_GRAY_TEXT_TER: RGBA_QUINARY,
  LIGHT_GRAY_TEXT_QUA: SEP_BLACK,
  TEXT_INFO: PRI_NAVY_BLUE,
  TEXT_SUCCESS: PRI_GREEN,
  TEXT_DANGER: PRI_RED,
  TEXT_DANGER_SEC: SEC_RED,
  TEXT_FOOTER_UP: SEN_COLOR,
  TEXT_SUCCESS_SEC: SEC_GREEN,

  // background colors
  PRIMARY_BG_LIGHT: PRI_WHITE,
  SECONDARY_BG_LIGHT: SEC_WHITE,
  TERTIARY_BG_LIGHT: TER_WHITE,
  DEFAULT_TEXT_BG: QUI_WHITE,
  PRIMARY_BG_DARK: PRI_COLOR,
  BG_LIGHT_GRAY: LIGHT_GRAY,
  BG_LIGHT_GRAY_SEC: LIGHT_GRAY_SEC,
  BG_FADED_PRIMARY: QUY_COLOR,
  BG_FADED_SECONDARY: QIY_COLOR,
  BG_FADED_TERTIARY: SEY_COLOR,
  BG_FADED_QUINARY: SPY_COLOR,
  BG_WA: RGBA_THEME7,
  BG_FADED_BLUE: LIGHT_FADED_BLUE,
  BG_FADED_RED: LIGHT_FADED_RED,
  BG_SUCCESS: PRI_GREEN,
  BG_SUCCESS_SEC: SEC_GREEN,

  // buttons
  BTN_BG_PRIMARY: SEC_COLOR,
  BTN_BG_SECONDARY: PRI_COLOR,
  BTN_BG_TERTIARY: QUI_COLOR,
  BTN_BG_QUARTERNARY: PRI_NAVY_BLUE,
  BTN_BG_LIGHT: PRI_WHITE,
  BTN_BG_DISABLED: QUA_BLACK,
  BTN_BG_DANGER: SEC_RED,
  BTN_BG_DEN: DEN_COLOR,
  BTN_BG_SEN: SEN_COLOR,
  BTN_BG_SEP: OCY_COLOR,
  BTN_BG_OCT: NNY_COLOR,
  BTN_BG_WA: RGBA_THEME,

  // progress icons
  PROGRESS_ICON_ACTIVE: RGBA_PRIMARY,
  PROGRESS_ICON_INACTIVE: RGBA_PRIMARY_LIGHT,
  PROGRESS_ICON_DISABLED: QUA_WHITE,
  
  // separator
  SEPARATOR_LIGHT: SEN_WHITE,
  SEPARATOR_DARK: QUA_BLACK,

  // borders
  PRIMARY_BORDER: SEC_COLOR,
  PRIMARY_BORDER_DARK: PRI_COLOR,

  // menu color
  ACTIVE_MENU_COLOR: SEP_COLOR,
  INACTIVE_MENU_COLOR: OCT_COLOR,
};

export const BUS_COLORS={
  PRIMARY_COLOR:"#792779",
  THEME_COLOR: "#792779",
}

export const FONTS={
  PRIMARY_FONT: "'Roboto', Sans-serif",
  SECONDARY_FONT: "'Lato', Sans-serif",
  TERTIARY_FONT: "Bauhaus",
}

// export const COLORS = {
//     THEME_COLOR: '#792779',
//     ACTION_BUTTON_BG: '#51595c',
//     SEARCH_RESULT_BG: '#F4F4F4',
//     HIGHLIGHT_COLOR: '#dd9762',
//     DEFAULT_TEXT_COLOR: '#333333',
//     DEFAULT_TEXT_BG: '#44444445'

// };