import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import OaFormSelectField from "oahoc/OaFormSelectField";

import { Formik, Form, ErrorMessage } from "formik";

import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";
import { useTheme } from "@material-ui/core";

const AddCommissionForm = (props) => {
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  
  const [collapsed, setCollapsed] = useState(true);

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }
  const theme=useTheme()
  return (
    <>
      <h4
        className={"oa-form-header"}
        onClick={collapse}
        style={{ transform: " scale(0.85)", transformOrigin: "left" }}
      >
        Enter Beneficiary Details
      </h4>
      <Formik
        initialValues={{
          senderMobileNumber: "",
          firstName: "",
          lastName: "",
        }}
        validationSchema={Yup.object().shape({
          mobileNumber: Yup.string()
            .required("Please enter beneficiary mobile number")
            .matches(phoneRegex, "Invalid mobile number"),
          accountNumber: Yup.string().required(
            "Please enter beneficiary account number"
          ),
          confirmAccountNumber: Yup.string().required(
            "Please confirm beneficiary account number"
          ),
          accountHolder: Yup.string().required(
            "Please enter account holder name"
          ),
          bank: Yup.string().required("Please select beneficiary bank name"),
          ifsc: Yup.string().required(
            "Please enter beneficiary bank IFSC Code"
          ),
        })}
        onSubmit={(searchParams, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          props.target(searchParams);
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <GridContainer>
              <GridItem md={4}>
                <OaFormSelectField
                  label="Bank*"
                  name="bank"
                  value={["ICICI", "HDFC"]}
                  options={["ICICI", "HDFC"]}
                  optionValue={"bankId"}
                  optionLabel={"bankName"}
                />
                <ErrorMessage name="bank" component="div" className="error" />
              </GridItem>
              <GridItem md={4}>
                <OaFormTextField name="ifsc" label="IFSC *" />
                <ErrorMessage name="ifsc" component="div" className="error" />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="accountNumber"
                  label="Account Number*"
                />
                <ErrorMessage
                  name="accountNumber"
                  component="div"
                  className="error"
                />
              </GridItem>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="confirmAccountNumber"
                  label="Confirm Account Number *"
                />
                <ErrorMessage
                  name="confirmAccountNumber"
                  component="div"
                  className="error"
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor: theme.palette.background.brightWhisper }}
                  className="input-landing-page"
                  name="accountHolder"
                  label="Name of the Account Holder *"
                />
                <ErrorMessage
                  name="accountHolder"
                  component="div"
                  className="error"
                />
              </GridItem>
              <GridItem md={4}>
                <OaFormTextField
                  style={{ backgroundColor:theme.palette.background.brightWhisper  }}
                  className="input-landing-page"
                  name="mobileNumber"
                  label="Beneficiary Mobile Number *"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="error"
                />
              </GridItem>
            </GridContainer>

            <GridContainer style={{ marginTop: 20 }}>
              <GridItem>
                <Button
                  color="seconday"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: 200 }}
                >
                  Verify (₹ 4.00)
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: 200 }}
                >
                  Add Beneficiary
                </Button>
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>
      <Link to="/moneytransfer/verify-otp">Verify OTP</Link>
    </>
  );
};

export default AddCommissionForm;
