import React, {useState, useEffect} from 'react';

import OaCard from 'oahoc/OaCard';
import {getQueryVar, getCurrentTab} from 'oautils/oaCommonUtils';
import TransactionListHeading from './TransactionListHeading';
import TransactionResponseListTable from './TransactionResponseListTable';
import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";

const TransactionResponseList = (props) => {
    const serialNo = getQueryVar({...props, q: 'serialNo'});
   
    useEffect(() =>{
    },[]);

    return (
     
            <TransactionResponseListTable {...props} serialNo={serialNo}/>
       
    )
}
export default TransactionResponseList
