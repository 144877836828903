import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      backgroundColor: `${theme.palette.background.default} !important`,
      color: theme.palette.primary.defaultText,
      // marginTop: 60,
      zIndex: 0,
      width: "100%",

      "& .MuiAppBar-colorPrimary": {
        backgroundColor: theme.palette.background.lightFadedGray,
      },
      "& a": {
        color: theme.palette.primary.lightText,
        fontWeight: 500,
        position: "relative",
      },
    },
    fixedMobileNav: { marginTop: 0, transition: "all 0.3s ease" },

    mobileNavContainer: {
      height: 80,
      display: "flex",
      // marginTop: 70,
      overflow: "hidden",
      position: "relative",
      borderBottom: "1px solid #e0dede",
      alignItems: "center",
      position: "relative",
      width: "100%",
      "& .MuiContainer-root": {
        padding: "0 !important",
      },
    },
    mobileNavItems: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: -20,
      right: -20,
      width: "100%",

      overflow: "scroll",
      color: "#000",
      display: "flex",
      alignItems: "center",

      transition: "all ease-in-out 0.3s",
    },

    mobileNavItem: {
      marginLeft: 10,
      flex: "0 0 15%",
      marginBottom: "0 !important",
      height: "100%",
      borderBottomWidth: 7,
      borderBottomColor: theme.palette.background.default,
      borderBottomStyle: "solid",
      "& .mobile-nav-button": {
        border: "none",
        outline: "none",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
      },
      "& .mobile-nav-menu-icon": {
        width: 50,
        height: 34,
        margin: "0 auto",
        padding: 10,
      },
      "& .mobile-nav-menu-label": {
        padding: 10,
        fontSize: 12,
      },
    },
    activeMenuItem: {
      borderBottomColor: theme.palette.background.secondary,
      marginBottom: "0 !important",
    },
  };
});

export default useStyles;
