import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "assets/css/CssConstants";
import { withRouter } from "react-router";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "components/CustomButtons/Button.js";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import backgroundImage from "assets/img/banner-fix.jpg";
import moment from "moment";
import SpecialOfferCard from "./SpecialOfferCard";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import image from "assets/img/tabel-background.jpg";
import { localforageGetItem } from "oautils/oaForageUtils";

const useStyles = makeStyles((theme) => ({
    root1: {
        "& .MuiButton-root": {
            [theme.breakpoints.down("xs")]: {
                fontSize: "9px",
            },
        },
        "& .sector-header": {
          padding: "0 0 0 10px",
          "& span": {
            fontSize: 16,
            fontWeight: 500,
          }
        },
    },
    Grid1: {
        marginBottom: 20,
        marginTop: "5%",
        [theme.breakpoints.down("xs")]: {
            // marginTop:"10%",
        },
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: "50px",
        fontWeight: 700,
        "& span": {
          color: theme.palette.primary.main,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    subheading: {
      color: theme.palette.secondary.main,
      fontSize: "50px",
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
      },
  },
    hr: {
        color: theme.palette.secondary.main,
        width: "17%",
        border: "1px solid",
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
            width: "12%",
        },
    },
    offerName: {
        textAlign: "center",
        color: "white",
        fontSize: "larger",
    },
    startsfrom: {
        textAlign: "center",
        color: "white",
        fontSize: "small",
    },
    card: {
        boxShadow: "none",
        background: theme.palette.primary.active,
        cursor: "pointer",
    },
    Button: {
        marginLeft: 25,
        borderRadius: 50,
        height: 35,
    },
    datepicker: {
        background: theme.palette.background.light,
        height: 35,
    },
    calendar: {
        background: theme.palette.background.light,
        marginTop: 346,
        [theme.breakpoints.up("xs")]: {
            marginTop: 145,
        },
        [theme.breakpoints.up("md")]: {
            marginTop: 346,
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: 346,
        },
    },
}));

const SpecialOffers = (props) => {
    const classes = useStyles();
    const [airports, setAirports] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedSector, setSelectedSector] = useState("");
    const [specialOffers, setSpecialOffers] = useState([]);
    const [sectorList, setSectorList] = useState([]);

    const getSpecialOffers = () => {

        apiCall(
            WebApi.getAvailableSectors,
            {},
            (response) => {
                if (response.success) {
                    setSpecialOffers(response.data);
                }
            }
        );
    };

    const getFdSectors = () => {
        localforageGetItem("user-details", function (err, value) {
            if (value) {
                apiCall(
                    WebApi.getDealSectors,
                    {
                        travel_offer_id: "",
                        // userType: value.userType
                        userType: "Agent",
                    },
                    response => {
        
                        if (response.success) {
        
                            const sectorData = response.data.sort((a, b) => (b.sectors[b.sectors.length - 1].travel_offer_id) - (a.sectors[a.sectors.length - 1].travel_offer_id));
                            // console.log("sorted sector data", sectorData);
        
                            setSectorList(sectorData);
                        }
        
                    }
                );
            }
        });

        
    }

    useEffect(() => {

        //getSpecialOffers();
        getFdSectors();

    }, []);

    const handleSearch = () => {
        getSpecialOffers();
    };

    const handleClick = (data) => {
        props.history.push("/flight/hotdeals/details", {
            data,
            date: selectedDate,
        });
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.departureCityName,
    });

    return (
        <Card
            style={{
                // boxShadow: "none",
                // backgroundImage: "url(" + image + ")",
                // backgroundSize: "100%",
                // marginTop: 0,
                // backgroundRepeat: "no-repeat",
            }}
            className={classes.root1}
        >
            <CardBody>
                <Grid container item className={classes.Grid1}>
                    <Grid item md={12} sm={12} xs={12}>
                        <Typography align="center" variant="h2" className={classes.heading}>
                            Pick <span>Destination</span>{" "}
                        </Typography>
                        <Grid container alignItems="center">
                            <hr className={classes.hr}></hr>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        style={{ paddingLeft: "5vw", paddingRight: "5vw", paddingTop: 50, paddingBottom: 50 }}
                    >

                        {/* {specialOffers && specialOffers.length > 0 ?
                            specialOffers.map((data, index) => (
                                data &&
                                <Grid container item md={3} xs={12} key={index}>
                                    <SpecialOfferCard offer={data} selectedDate={selectedDate} />
                                </Grid>
                            ))

                            :
                            <Grid container item md={6} xs={12} >
                                <Typography align="center" variant="h2" className={classes.heading} style={{ color: '#ccc' }}>
                                    Keep checking this area for Hot deals
                                </Typography>
                            </Grid>

                        } */}

                        {sectorList && sectorList.length > 0 ?
                            sectorList.map((sector, ind) =>
                                sector &&
                                <Grid container className="sector-container" key={ind}
                                    style={{ maxWidth: 1040 }}
                                >
                                    <Grid item md={12} className="sector-header">
                                        <span>{sector.departureAirport}</span>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div container style={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            width: "100%",
                                            padding: "25px 0"
                                        }}>
                                            {sector.sectors.map((sec, idx) =>
                                                <div key={ind}>
                                                    <SpecialOfferCard offer={sec} selectedDate={selectedDate} />
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                            :
                            <Grid container item md={6} xs={12} >
                                <Typography align="center" variant="h2" className={classes.subheading}>
                                    Keep checking this area for Hot deals
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    );
};

export default withRouter(SpecialOffers);