import React from "react";
import { makeStyles, Button, useTheme, useMediaQuery } from "@material-ui/core";
import ContactBanner from "assets/triumphTravel/images/contact-banner.jpg";
import pic1 from "assets/triumphTravel/images/pic1.png";
import pic2 from "assets/triumphTravel/images/pic2.png";
import pic3 from "assets/triumphTravel/images/pic3.png";
import slideImage from "assets/triumphTravel/images/slideImage.png";
import { useHistory } from "react-router-dom";

const AboutUs = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();

    const goToUrlLandingpage = () => {
        history.push("/flights");
    }

    return (
        <div className={classes.root}>
            <div className="section-container">
                <div className="banner-section">
                    <div className="section-title-container">
                        <div className="section-title">
                            <h2>About Us</h2>
                        </div>
                        <div className="section-bread-crumbs">
                            <a onClick={goToUrlLandingpage}>Home</a> | <a href="">About Us</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-container grid-container justify-center">
                <div className="grid-container md-1280 padding-tb100">
                    <div className="content-container md-50">
                        <div className="section-header">
                            <p>ABOUT US</p>
                        </div>
                        <div className="section-caption">
                            <p>YOU CAN NOT BUY HAPPINESS BUT CERTAINLY BUY HOLIDAY PACKAGE WITH HAPPINESS AT LOOKMYTICKET</p>
                        </div>
                        <div className="content-text">
                            <p>
                                <a onClick={goToUrlLandingpage}>Lookmyticket Tours and Travels (LMT) PVT LTD</a> is one of the leading travel websites that lets you find cheap flights, hotels, car rentals, and holiday packages. Our Customer support for an online business is managed by our contact center in Zirakpur, India. LookMyTicket works hard to attract new business and it’s to our team member’s credibility that we have managed to most of our customers. LookMyTicket offers you the newest in travel, along with the most affordable air tickets to India and the rest of the World. We continue to look for various values combined services to guarantee that you as our customer get the best travel option available in the country.
                            </p>
                            <br />
                            <p>
                                We have a faithful and awarded group of Professionals who understand your travel needs and requirements and take pride in helping the customers. Our dedication towards understanding our customers requires has brought about numerous effective goes of customers both in the USA and India.
                            </p>
                        </div>
                    </div>
                    <div className="content-container md-50">
                        <div className="content-Images">
                            <img src={pic1} />
                            <img src={pic2} />
                            <img src={pic3} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-container grid-container justify-center">
                <div className="grid-container md-1280 padding-tb100">
                    <div className="content-container md-100">
                        <div className="section-caption">
                            <p>Mission and Visions Of Lookmyticket</p>
                        </div>
                        <div className="content-text">
                            <p>
                                We will appropriate development as tools to make the travel brand of the choice, both globally and locally, for the intelligent corporate or private traveler.
                            </p>
                            <br />
                            <p>
                                To carry general satisfaction to our travelers, satisfying their demands with our dedicated staff.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-container grid-container justify-center">
                <div className="grid-container md-1280 padding-tb100">
                    <div className="content-container md-100">
                        <div className="section-caption">
                            <p>Our Values</p>
                        </div>
                        <div className="content-text">
                            <p>
                                The organization depends on a solid ethic that is incorporated with everything about the regular business. Our activities are driven by an admiration framework including.
                            </p>
                            <ul>
                                <li>
                                    <p>Our clients are our very own enlargement families and we review every possibility in supporting their calmness, security, and bliss.</p>
                                </li>
                                <li>
                                    <p>Our agents are given a protective and lovely workplace in which they can develop and feel appreciated for their duties.</p>
                                </li>
                                <li>
                                    <p>Together, we have and continue attempting to be a strength of our group – setting a point of reference for a genuine, reasonable and earth maintainable business.</p>
                                </li>
                                <li>
                                    <p>We are a quick, innovative and quality careful group of creative individuals with a dedication to being the best while never missing the key that our customers have appreciated us for our punctuality.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;

const useStyles = makeStyles(theme => ({

    root: {
        fontFamily: theme.palette.font.primary,
        "& .section-container": {
            margin: 0,
            padding: 0,
            width: "100%",
        },
        "& .grid-container": {
            display: "flex",
            [theme.breakpoints.down(600)]: {
                flexWrap: "wrap",
            }
        },
        "& .justify-center": {
            justifyContent: "center",
        },
        "& .padding-tb100": {
            padding: "25px 0",
        },
        "& .md-1280": {
            maxWidth: 1280,
            width: "100%",
        },

        "& .section-title-container": {
            padding: "150px 0 0 60px",
            [theme.breakpoints.down(600)]: {
                padding: "90px 0 0 60px",
            }
        },
        "& .section-title": {
            "& h2": {
                fontWeight: 700,
                color: theme.palette.primary.lightText,
                fontSize: 42,
                margin: 0,
            },
        },
        "& .section-bread-crumbs": {
            color: theme.palette.primary.lightText,
            "& a": {
                color: theme.palette.primary.lightText,
                cursor: "pointer"
            },
        },
        "& .banner-section": {
            padding: 20,
            height: 380,
            backgroundImage: `url(${ContactBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "norepeat",
            [theme.breakpoints.down(600)]: {
                height: 250,
            },
        },
        "& .content-container": {
            "& button": {
                background: theme.palette.buttons.tertiary,
                color: theme.palette.buttons.tertiaryContrastText,
                fontSize: 14,
                padding: "10px 20px",
                textTransform: "lowercase",
                "& .MuiSvgIcon-root": {
                    marginLeft: 4,
                    fontSize: 16,
                },
            },
            "&.md-50": {
                width: "50%",
                [theme.breakpoints.down(960)]: {
                  
                    width: "100%",
                },
            },
            "&.md-100": {
                width: "100%"
            },
            [theme.breakpoints.down(1330)]: {
                padding: "0 20px",
            },
            [theme.breakpoints.down(960)]: {
                padding: "0 30px",
            },
            [theme.breakpoints.down(600)]: {
                textAlign: "center",
                marginBottom: 10,
            },
            [theme.breakpoints.down(390)]: {
                padding: "0 15px",
            },
        },

        "& .testimonial-container": {
            margin: "20px 0 0 0",
            "& .content-text-name": {
                fontSize: 18,
                fontWeight: 600,
                fontFamily: theme.palette.font.primary,
                margin: 0,
                lineHeight: 1.5,
            },
            "& .content-text-occupation": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: theme.palette.font.primary,
                margin: 0,
                lineHeight: 1.5,
            },
            "& .content-image": {
                margin: "0 15px 0 0",
            },
            "& .testimonial-text": {
                width: 342,
                margin: "20px 0 0",
                "& .content-text-caption": {
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: theme.palette.font.primary,
                },
            },
            [theme.breakpoints.down(390)]: {
                padding: "0 15px",
            },
        },
        "& .section-header": {
            "& p": {
                color: theme.palette.secondary.main,
                paddingTop: 20,
                size: 14,
                fontWeight: 700,
            },
        },
        "& .section-caption": {
            "& p": {
                fontSize: 22,
                fontWeight: 800,
                lineHeight: 1.2,
                fontFamily: theme.palette.font.primary,
                color: theme.palette.primary.darkText,
                [theme.breakpoints.down(1330)]: {
                    fontSize: 32,
                },
            },
            "&.light-text": {
                color: theme.palette.primary.lightText,
                "& p": {
                    color: theme.palette.primary.lightText,
                    fontFamily: theme.palette.font.primary,
                },
            },
        },
        "& .content-text": {
            margin: "30px 0",
            "& p": {
                fontWeight: 500,
                fontSize: 15,
                lineHeight: 1.3,
                fontFamily: theme.palette.font.primary,
            },
            "& a": {
                cursor: "pointer",
                color: theme.palette.primary.info
            }
        },
        "& .content-Images": {
            textAlign: "center",
            "& img": {
                margin: 10,
                [theme.breakpoints.down(960)]: {
                    width: "100%",
                },
                [theme.breakpoints.down(390)]: {
                    width: "100%",
                    
                },
            },
        },
        "& .section-bg": {
            minHeight: 320,
            backgroundImage: `url(${slideImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "norepeat",
        },
        "& .inner-container": {
            maxWidth: 1280,
            width: "100%",
            "&.max-1440": {
                maxWidth: 1440,
            },
            "&.padding-tb100": {
                padding: "100px 0",
            },
        },
        "& .header-container": {
            padding: "0 0 0 70px",
            marginBottom: 30,
        },
        "& .section-carousel-wrapper": {
            width: "100%",
        },
        "& .rec-carousel": {
            [theme.breakpoints.down(600)]: {
                position: "relative",
            },
        },
        "& .arrow-crouselPackage": {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down(600)]: {
                position: "absolute",
                top: "35%",
            },
            "& img": {
                cursor: "pointer",
            },
            "&.left": {
                left: 0,
                zIndex: 1,
            },
            "&.right": {
                right: 0,
            },
        },
        "& .service-item": {
            background: theme.palette.background.light,
            width: "100%",
            textAlign: "center",
            padding: "30px 5px",
            color: theme.palette.primary.darkText,
        },
        "& .service-image": {
            width: "100%",
            margin: "0 0 20px",
            "& img": {
                width: 59,
                height: 59,
            },
        },
        "& .service-text-title": {
            fontSize: 16,
            fontWeight: 500,
            padding: "0 45px",
            fontFamily: theme.palette.font.primary,
        },
        "& .service-text": {
            color: theme.palette.primary.darkText,
            fontSize: 13.5,
            marginTop: 15,
        },
        "& .grid-column": {
            "&.grow-1": {
                flexGrow: 1,
            },
            "&.testimonial-grid": {
                padding: "60px 0 30px",
            },
            "&.image-column": {
                position: "relative",
            },
        },
        "& .content-navImage": {
            position: "absolute",
            bottom: -10,
            left: -60,
            "& a": {
                cursor: "pointer",
            },
        },
    },
}));
